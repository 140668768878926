import React, { useState } from 'react';
import PropTypes from 'prop-types';
import robotDesignOptions from 'shared/constants/fllChallengeRubricOptions/robotDesign';
import { updateJudgingRubric } from 'features/EventOverviewContainer/redux/actions/judgingRubric';
import { connect } from 'react-redux';
import TextBox from 'shared/components/TextBox';
import { useTranslation } from 'react-i18next';
import Instructions from './RubricSectionInstructions';
import RubricSection from './RubricSection'
import FormFieldLegend from './FormFieldLegend';
import Icon from 'shared/components/Icon';
import { Form, Radio } from 'antd';
import CoreValueGear from 'shared/components/Icon/pngs/gear-thin-CV-symbol.png';
import '../../../../../features/EventOverviewContainer/styles/judgingRubric/FLLRubricField.scss';

const radioOptions = [
  { value: 1, text: '1' },
  { value: 2, text: '2' },
  { value: 3, text: '3' },
  { value: 4, text: '4' },
]

const FormItem = Form.Item;
const moduleNameClassName = `h-2 heading--white`;
const moduleTitleClassName = `h-2 heading--charcoal m-t-12`;
const paragraphClassName = 'paragraph--large paragraph--charcoal paragraph--normal c-fll-rubric-form__sub-title p-r-40'


const RobotDesign = props => {
  const { updateRubric, rubric } = props;
  const [showContent, setShowContent] = useState(false);
  const [showRubric, setShowRubric] = useState(false);
  const [form] = Form.useForm();
  const initialFormValues = {};

  robotDesignOptions.map((v) => {
    v.fields.map((f) => {
      initialFormValues[f.id] = rubric[f.id];

      if (rubric[f.id] === 4) {
        initialFormValues[`${f.id}_justification`] = initialFormValues[`${rubric[f.id]}_justification`];
      }
    });
  });

  const handleChange = (field, e) => {
    const { target: { value } } = e;
    updateRubric(field, value)
  }

  const { t } = useTranslation();

  const toggleIcon = () => {
    setShowContent(show => !show)
  }

  const toggleRubricIcon = () => {
    setShowRubric(show => !show);
  }

  const InstructionToggleButton = (
    <div className="mobile-toggle-button" onClick={toggleIcon}>
      {showContent ? <Icon type="downArrowBlue" /> : <Icon type="upArrowBlue" />}
    </div>
  )

  const RubricToggleButton = (
    <div className="rubric-toggle-button" onClick={toggleRubricIcon}>
      {showRubric ? <Icon type="downArrowBlue" /> : <Icon type="upArrowBlue" />}
    </div>
  )

  return (
    <div>
      <h3
        className='h-1 h--400 heading--charcoal m-b-36 mobile-compatitable-rubric-title'
      >
        {t('robotDesignLabel')}
      </h3>
      <Instructions
        instructionsText={t('robotDesignInstructionsLabel')}
        button={InstructionToggleButton}
        collapsed={showContent}
      >
        <br />
        <div>
          <img
            style={{ width: 30 + 'px', display: 'inline' }}
            src={CoreValueGear}
            alt='Core Value'
          />
          <p style={{ display: 'inline', marginInlineStart: 10 + 'px' }} className='paragraph--medium paragraph--charcoal m-t-16'>{t('rubricCoreValueSymbolExplanation')}</p>
        </div>
      </Instructions>
      <RubricSection
        name={t('robotDesignLabel')}
        questionText={t('5questionsLabel')}
        button={RubricToggleButton}
      >
        {!showRubric && <Form hideRequiredMark name='RobotDesign'>
          {robotDesignOptions.map(v => (
            <div tabIndex={0} className='c-fll-rubric-form__section' key={v.id}>
              <div className="c-fll-rubric-form__section-name">
                <h3 className={moduleNameClassName}>{t(v.label)}</h3>
              </div>
              <div className='c-fll-rubric-form__section-content-wrapper'>
                <p className={moduleTitleClassName}>{t(v.title)}</p>
                <p className={paragraphClassName}>{t(v.description)}</p>
                {v.fields.map(f => {
                  const textAreaErrorClass = (rubric[f.id] === 4 && !rubric[`${f.id}_justification`]) ? 'text-content-error' : '';
                  return (
                    <div className='rubric-line-container'>
                      {f.isCoreValue && <div className='rubric-line-left'>
                        <img
                          style={{ width: 70 + 'px', marginInlineEnd: 0 + 'px' }}
                          src={CoreValueGear}
                          alt='Core Value'
                        />
                      </div>}
                      <div className='rubric-line-right'>
                        <fieldset className='c-fll-rubric-form__section-content-fieldset' key={f.id}>
                          <div className='c-fll-rubric-form__section-content-legend-wrapper'>
                            <div className='m-t-20 rubric-item-left'>
                              <legend className='c-fll-rubric-form__section-legend'>{t(f.legendTitle)}</legend>
                              <FormFieldLegend values={f.legendValues} />
                            </div>
                            <div className='rubric-item-right'>
                              <FormItem name={f.id}>
                                <Radio.Group
                                  buttonStyle="solid"
                                  optionType="button"
                                  className="c-radio-button-wrapper"
                                  name={`${t(f.legendTitle)}`}
                                  onChange={(e) => handleChange(f.id, e, true)}
                                  disabled={rubric.is_published || rubric.is_publishable}
                                  defaultValue={rubric[f.id]}
                                >
                                  {radioOptions.map(({ value, text, description }) => (
                                    <Radio.Button
                                      className={`c-radio-button ${f.isCoreValue ? 'core-values-background' : ''}`}
                                      buttonStyle="solid"
                                      key={value}
                                      value={value}
                                    >
                                      {text || t(description)}
                                    </Radio.Button>
                                  ))}

                                </Radio.Group>
                              </FormItem>
                            </div>

                          </div>
                          {rubric[f.id] === 4 ? (
                            <FormItem name={`${f.id}_justification`} initialValue={rubric[`${f.id}_justification`]} className={`c-fll-rubric-form__section-content-reason ${textAreaErrorClass}`} label={<span className='paragraph--charcoal paragraph--bold paragraph--large'>{t('reasonRequiredLabel')}</span>} rules={[{ required: 'true', message: 'Enter reason' }]}>
                              <TextBox
                                onChange={(e) => { handleChange(`${f.id}_justification`, e) }}
                                disabled={rubric.is_published || rubric.is_publishable}
                              />
                            </FormItem>

                          ) : (null)}

                        </fieldset>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          ))}

        </Form>}
      </RubricSection>
    </div>
  );
};

RobotDesign.propTypes = {
  form: PropTypes.object.isRequired,
  rubric: PropTypes.object.isRequired,
  updateRubric: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  updateRubric: updateJudgingRubric
}

const mapStateToProps = (state) => {
  const { judgingRubric: { fllChallengeRubric } } = state;

  return {
    rubric: fllChallengeRubric
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RobotDesign);