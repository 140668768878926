import React from 'react';

const Spreadsheet = () => {
  return (
    <svg width="30px" height="24px" viewBox="0 0 30 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      {/* <title>85417412-D431-4BF4-9DA1-CB23AD252D50</title> */}
      <g id="Volunteers" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="My-Volunteers---Add-Volunteers---Import-CSV---PDP-Admin" transform="translate(-916.000000, -113.000000)" fill="#52C41A">
          <g id="Group-25" transform="translate(139.000000, 10.000000)">
            <g id="Group-12" transform="translate(777.000000, 103.000000)">
              <polygon id="Path" points="24 0 24 6 30 6" />
              <polygon id="Path" points="22.5 1.5 1.5 1.5 1.5 22.5 28.5 22.5 28.5 7.5 30 7.5 30 24 0 24 0 0 22.5 0" />
              <path d="M4.5,7.5 L4.5,18 L25.5,18 L25.5,7.5 L4.5,7.5 Z M18,9 L18,12 L12,12 L12,9 L18,9 Z M6,9 L10.5,9 L10.5,12 L6,12 L6,9 Z M6,16.5 L6,13.5 L10.5,13.5 L10.5,16.5 L6,16.5 Z M12,16.5 L12,13.5 L18,13.5 L18,16.5 L12,16.5 Z M24,16.5 L19.5,16.5 L19.5,13.5 L24,13.5 L24,16.5 Z M24,12 L19.5,12 L19.5,9 L24,9 L24,12 Z" id="Shape" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Spreadsheet;