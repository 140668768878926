import * as judgingService from 'utils/services/judging';
import { notification } from 'antd';
import * as types from '../../types/judgingRubric';
import { updateSessionStatus } from './getSessionDetails';
import KeysOfRubricForJustificationValidation from 'shared/constants/fllChallengeRubricOptions/rubricKeys';

// JUDGING API CALLS FOR FLL CHALLENGE EVENTS

const getJudgingRubricBegin = () => {
  return {
    type: types.GET_FLL_CHALLENGE_JUDGING_RUBRIC_BEGIN
  }
}
const getJudgingRubricError = () => {
  return {
    type: types.GET_FLL_CHALLENGE_JUDGING_RUBRIC_ERROR
  }
}

const getJudgingRubricSuccess = (fllChallengeRubric) => {
  return {
    type: types.GET_FLL_CHALLENGE_JUDGING_RUBRIC_SUCCESS,
    payload: {
      fllChallengeRubric
    }
  }
}

const getJudgingRubricFLLESuccess = (fllEChallengeRubric) => {
  return {
    type: types.GET_FLL_E_CHALLENGE_JUDGING_RUBRIC_SUCCESS,
    payload: {
      fllEChallengeRubric
    }
  }
}

const setIsRubricNew = (isNew) => {
  return {
    type: types.SET_IS_FLL_CHALLENGE_RUBRIC_NEW,
    payload: {
      isNew
    }
  }
}

const setJudgingId = (judgingId) => {
  return {
    type: types.SET_FLL_CHALLENGE_RUBRIC_JUDGING_ID,
    payload: {
      judgingId
    }
  }
}

export const getJudgingRubric = (digital_rubric) => {
  return async dispatch => {
    dispatch(getJudgingRubricBegin())
    try {
      const { data } = await judgingService.getJudgingObjectBy(digital_rubric);

      dispatch(setIsRubricNew(false))
      const judgingObject = data || {};
      const {attributes, id} = judgingObject;
      dispatch(setJudgingId(id))
      dispatch(getJudgingRubricSuccess(attributes))
    } catch(err) {
      dispatch(getJudgingRubricError())
      throw err;
    }
  }
}

export const getJudgingRubricFLLE = (digital_rubric) => {
  return async dispatch => {
    dispatch(getJudgingRubricBegin())
    try {
      const res = await judgingService.getFLLEJudgingObjectBy(digital_rubric);
      dispatch(setIsRubricNew(false))
      dispatch(setJudgingId(res.sessionId))
      dispatch(getJudgingRubricFLLESuccess(res))
    } catch (err) {
      dispatch(getJudgingRubricError())
      throw err;
    }
  }
}

const updateRubric = (field, value) => {
  return {
    type: types.UPDATE_FLL_CHALLENGE_JUDGING_RUBRIC,
    payload: {
      field, value
    }
  }
}

const updateRubricFLLE = (field, value) => {
  return {
    type: types.UPDATE_FLL_E_CHALLENGE_JUDGING_RUBRIC,
    payload: {
      field, value
    }
  }
}

export const updateJudgingRubric = (field, value) => {
  return async dispatch => {
    dispatch(updateRubric(field, value))
  }
}

export const updateJudgingRubricFLLE = (field, value) => {
  return async dispatch => {
    dispatch(updateRubricFLLE(field, value))
  }
}

const saveExistingRubricBegin = () => {
  return {
    type: types.SAVE_EXISTING_FLL_CHALLENGE_JUDGING_RUBRIC_BEGIN
  }
}

const saveExistingRubricError = () => {
  return {
    type: types.SAVE_EXISTING_FLL_CHALLENGE_JUDGING_RUBRIC_ERROR
  }
}

const saveExistingRubricSuccess = () => {
  return {
    type: types.SAVE_EXISTING_FLL_CHALLENGE_JUDGING_RUBRIC_SUCCESS
  }
}

const validateCoreValueJustificationValue = (rubric)=>{
  let invalid = false;
  for(const id of KeysOfRubricForJustificationValidation){
    const value = rubric[id];
    const justification_key = id+"_justification";
    const justification = rubric[justification_key];
    if(value === 4 && !justification){
      invalid = true;
    }
  }
  return invalid;
}

const formatCamelcaseString = (stringVal) => {
  let text = stringVal;
  let array = text.match(/[A-Z0-9]/g);

  if (array < 1) {
    return stringVal;
  }

  text = text.split('');

  for (let i = 0; i < array.length; i++) {
    let index = text.indexOf(array[i]);
    text.splice(index, 1, '_', array[i].toLowerCase());
  }

  return text.join('')
}

const formatRubricObject = (obj) => {
  let newObj = {}
  for (var key in obj) {
    let convertedString = formatCamelcaseString(key)
    newObj[convertedString] = obj[key]
  }
  return newObj
}

export const submitFLLERubric = (sessionId, isPublishable, cb = () => { }) => {
  return async (dispatch, getState) => {
    await dispatch(saveExistingRubricBegin());
    const { judgingRubric: { fllEChallengeRubric, judgingId, sessionDetails: { digital_rubric } } } = getState();
    // const copyRubric = { ...fllEChallengeRubric }

    try {
      let data = fllEChallengeRubric;
      const attributes = {
        ...data,
      }
     

      // const invalid = validateCoreValueJustificationValue(copyRubric);
      // if (invalid) {
      //   notification.error({
      //     message: 'Error',
      //     description: 'Justification is necessary for any item scored with “4”.Please ensure justification is provided',
      //   });
      //   await dispatch(saveExistingRubricError());
      //   cb && cb();
      //   return;
      // }

      if (isPublishable !== undefined) {
        attributes['is_publishable'] = isPublishable
      }

      const payload = {
        data: {
          "type": "judge_rubric_for_fll_e",
          // id: judgingId,
          attributes
        }
      }
      
      let updatedData = await judgingService.updateFLLEJudgingObject(payload, digital_rubric);
      dispatch(getJudgingRubricFLLESuccess(updatedData))
      await dispatch(saveExistingRubricSuccess())
      await dispatch(updateSessionStatus(sessionId))
      if (isPublishable !== undefined) {
        await dispatch(updateRubricFLLE('is_publishable', isPublishable));
      }
      notification.success({
        message: 'Success',
        description: isPublishable === undefined
          ? 'Rubric successfully saved'
          : isPublishable
            ? 'Rubric successfully submitted'
            : 'Rubric successfully unpublished'
      });
      cb()
    } catch (err) {
      const description = (((err || {}).data || {}).error)
      if (err.status === 400) {
        notification.error({
          message: 'Error',
          description: description
        });
      } else {
        notification.error({
          message: 'Error',
          description: description
        });
      }
      await dispatch(saveExistingRubricError())
      throw err;
    }

  }
}


export const saveExistingJudgingRubric = (sessionId, isPublishable, cb = () => {}) => {
  return async (dispatch, getState) => {
    await dispatch(saveExistingRubricBegin());
    const {judgingRubric: {fllChallengeRubric, judgingId}} = getState();
    const copyRubric = {...fllChallengeRubric}
    try {
      const attributes = {
        ...copyRubric,
        session_id: sessionId
      }

      const invalid = validateCoreValueJustificationValue(copyRubric);
      if(invalid){
        notification.error({
          message: 'Error',
          description: 'Justification is necessary for any item scored with “4”.Please ensure justification is provided',
        });
        await dispatch(saveExistingRubricError());
        cb && cb();
        return;
      }

      if (isPublishable !== undefined) {
        attributes['is_publishable'] = isPublishable
      }

      const payload = {
        data: {
          type: 'judge',
          id: judgingId,
          attributes
        }
      }

      await judgingService.updateJudgingObject(payload, judgingId);
      await dispatch(saveExistingRubricSuccess())
      await dispatch(updateSessionStatus(sessionId))
      if (isPublishable !== undefined) {
        await dispatch(updateRubric('is_publishable', isPublishable));
      }
      notification.success({
        message: 'Success',
        description: isPublishable === undefined
          ? 'Rubric successfully saved'
          : isPublishable 
            ? 'Rubric successfully submitted'
            : 'Rubric successfully unpublished' 
      });
      cb()
    } catch(err) {
      const description = (((err||{}).data||{}).error)
      if (err.status === 400) {
        notification.error({
          message: 'Error',
          description: description
        });
      } else {
        notification.error({
          message: 'Error',
          description: description
        });
      }
      await dispatch(saveExistingRubricError())
      throw err;
    }

  }
}

