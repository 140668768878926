import * as types from './registrationTypes'

const defaultVerifyItem = {
  verified: false,
  error: false,
  email: '',
  code: ''
};

const defaultRegistrationState = {
  lastVerify: defaultVerifyItem,
  verifiedList: [],
  initialRegistrationDetails: defaultVerifyItem
};

export default (state = defaultRegistrationState, action) => {
  switch (action.type) {
    case types.SET_VERIFY_USER_DATA: {
      const { email, code } = action.payload;
      return {
        ...state,
        lastVerify: {
          verified: false,
          email,
          code
        }
      };
    }
    case types.VERIFY_USER_AUTH_EMAIL_SUCCESS: {
      const { lastVerify, verifiedList } = state;
      return {
        ...state,
        lastVerify: defaultVerifyItem,
        verifiedList: [...verifiedList, lastVerify]
      };
    };
    case types.VERIFY_USER_AUTH_EMAIL_ERROR: {
      const { lastVerify } = state;
      return {
        ...state,
        lastVerify: {
          ...lastVerify,
          error: true
        }
      };
    };
    default:
      return state;
  }
};
