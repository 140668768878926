import * as panelService from 'utils/services/panels';

import { notification } from 'antd';
import { resetAddToPanelList } from 'features/PanelManagement/redux/actions/judges';
import { resetAddToPanelList as resetTeamsAddToPanelList } from 'features/PanelManagement/redux/actions/teams';
import * as types from '../../types/scoringTypes';
import { getScoringTabData, getScoringData } from './getScoringPanels';

const createNewScoringPanelBegin = () => {
  return {
    type: types.CREATE_NEW_SCORING_PANEL_BEGIN
  }

}
const createNewScoringPanelError = () => {
  return {
    type: types.CREATE_NEW_SCORING_PANEL_ERROR
  }
}
const createNewScoringPanelSuccess = (formattedPanels, refereeOptions, sessionOptions) => {
  return {
    type: types.CREATE_NEW_SCORING_PANEL_SUCCESS,
    payload: {
      formattedPanels, refereeOptions, sessionOptions
    }
  }

}

const createNewScoringPanel = (payload, cb = () => { }) => {
  return async (dispatch, getState) => {
    const { panel_type_id, desired_number_of_panels } = payload;
    const { event: { eventDetails: { id, eventScheduleId } } } = getState();
    try {
      await dispatch(createNewScoringPanelBegin())
      let data = {
        panel_type_id, 
        desired_number_of_panels,
        schedule_id: eventScheduleId
      }
      const response = await panelService.batchCreatePanel(data);

      notification.success({
        message: 'Success',
        description: desired_number_of_panels > 1 ? 'Tables were successfully created.' : 'Table was successfully created.',
      }); 

      // try {
      //   // const { formattedPanels, refereeOptions, sessionOptions } = await getScoringTabData(id, eventScheduleId)
      //   const { formattedPanels, refereeOptions, sessionOptions } = await getScoringData(id, eventScheduleId, 1)
      //   await dispatch(createNewScoringPanelSuccess(formattedPanels, refereeOptions, sessionOptions))
      // } catch (error) {
      //   console.log(error);
      // }

      cb();
    } catch (err) {
      await dispatch(createNewScoringPanelError())
      if(err?.status == 400 && err?.data == 'Please provide a number >= 1 and <= 64 for desired number of panels to create.'){
        notification.error({
          message: 'Error',
          description: 'A maximum of 64 rooms/tables can be created',
        });
      }else{
        notification.error({
          message: 'Error',
          description: 'Error creating the table',
        });
      }
      throw err;
    }
  }

}


const createNew = (payload, cb = () => { }) => {
  return async (dispatch, getState) => {
    const { panelName, sessionIds, refereeIds } = payload;
    const { event: { eventDetails: { id, eventScheduleId } } } = getState();
    try {
      await dispatch(createNewScoringPanelBegin())
      const response = await panelService.createScoringPanel(panelName, sessionIds, refereeIds, eventScheduleId);

      try {
        // const { formattedPanels, refereeOptions, sessionOptions } = await getScoringTabData(id, eventScheduleId)
        const { formattedPanels, refereeOptions, sessionOptions } = await getScoringData(id, eventScheduleId, 1)
        await dispatch(createNewScoringPanelSuccess(formattedPanels, refereeOptions, sessionOptions))
      } catch (error) {
        console.log(error);
      }

      cb();
    } catch (err) {
      await dispatch(createNewScoringPanelError())
      notification.error({
        message: 'Error',
        description: 'Error creating the panel',
      });
      throw err;
    }
  }

}

export { createNew }
export default createNewScoringPanel;