import { API } from 'utils/api';
import { queryStringify } from 'utils/query';
import Organization from 'models/Organization';
import { formatOrganizationPayload, formatOrganizationPatchPayload, formatOrganizationResponse, formatDeleteRegionsFromOrgPayload } from './model';

//const { http } = API;
const baseUrl = '/api/organizations';

export const getOrganizations = async () => {
  const { data, included } = await API.GetInstance().http.get(baseUrl);
  return data.map((p) => new Organization(p, included));
};

export const getOrganizationsBy = async (filter, page) => {
  const queryStr = queryStringify({ filter, page });
  const url = `${baseUrl}${queryStr}`;
  const { data, included } = await API.GetInstance().http.get(url);
  return data.map((p) => new Organization(p, included));
};

export const getOrganizationByName = async (name) => {
  const filter = { name: `eq:${name}` };
  return await getOrganizationsBy(filter);
};

// not support like
export const getOrganizationsLikeName = async (name) => {
  const filter = { name: `like:${name}` };
  return await getOrganizationsBy(filter);
};

export const createOrganization = async (payload) => {
  const body = formatOrganizationPayload(payload);
  const { data, included } = await API.GetInstance().http.post(baseUrl, body);
  return new Organization(data, included);
};

export const findOrCreateOrganization = async (payload) => {
  const exist = await getOrganizationByName(payload.name);
  if (exist.length) return exist[0];
  return await createOrganization(payload);
};

export const getOrganizationById = async(organizationId) => {
  const {data, included} = await API.GetInstance().http.get(`${baseUrl}/${organizationId}`);
  return new Organization(data, included);
}

export const filterOrganizations = async (filter, page) => {
  const queryStr = queryStringify({ filter, page });
  const url = `${baseUrl}${queryStr}`;
  const { data, included, meta } = await API.GetInstance().http.get(url);
  const organizationsList = data.map((p) => new Organization(p, included));
  return {
    organizations: organizationsList,
    count: meta && meta.count
  };
}

export const deleteOrganization = async (organizationId) => {
  const url = `${baseUrl}/${organizationId}`;
  const {data, included} = await API.GetInstance().http.delete(url);
  return {
    data,
    included: included || []
  }
}

export const editOrganization = async (organizationId, organizationData) => {
  const payload = formatOrganizationPatchPayload(organizationData);
  const url = `${baseUrl}/${organizationId}`;
  const {data, included} = await API.GetInstance().http.patch(url, payload);
  const organization = formatOrganizationResponse(new Organization(data, included));
  return organization;
}

export const removeRegionsFromOrganization = async (organizationId, regions) => {
  const payload = formatDeleteRegionsFromOrgPayload(regions);
  const url = `${baseUrl}/${organizationId}/relationships/regions`;
  const res = await API.GetInstance().http.delete(url, {data: payload});
  return res
}

export const getAllOrganizationsByIds = (organizationIds) => {
  if (!organizationIds || !organizationIds.length) {
    return []
  }
  const filter = { id: `in:${organizationIds.join(',')}` };
  const page = organizationIds.length > 25  && {size: organizationIds.length, number: 1};
  const res = getOrganizationsBy(filter, page);
  return res
}