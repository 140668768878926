import React from 'react';
import PropTypes from 'prop-types';
import './page-layout.scss';

const PageLayout = props => {
  const {children} = props;
  return (
    <div className='page-layout'>
      <div className='page-layout__hero' />
      <div className='page-layout__content'>
        {children}
      </div>
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default PageLayout;