import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { deleteJudgingRubricComment } from 'features/EventOverviewContainer/redux/actions/judgingRubric';
import * as userTypes from 'shared/constants/userTypes';
import * as eventRoleTypes from 'shared/constants/eventRoleTypes';
import ConfirmationModal from 'shared/components/ConfirmModal';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const metaTextClassName = 'paragraph--medium paragraph--charcoal paragraph--normal'

const paragraphClassName = 'paragraph--xlarge paragraph--charcoal paragraph--normal'


const JudgingFeedbackComment = props => {
  const {
    fullName,
    dateTime,
    comment,
    deleteComment,
    signedInUserId,
    userId,
    commentId,
    deletingComment,
    onEdit,
    isPublished,
    userType,
    userEventAssignments,
    rubricEventId,
    eventName,
    use_24_hour_format
  } = props;

  const { t } = useTranslation();


  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const isUserTypeMatch = (roleID) => {
    let match = false
    userEventAssignments.forEach(data => {
      if (data.roleId == roleID && data.eventId == rubricEventId) {
        match = true
      }
    });

    return match
  }

  const handleDelete = () => {
    setShowDeleteModal(true)
  }

  const isAllowedToEditOrDelete = (userID) => {

    let isUserIsEventAdmin = isUserTypeMatch(eventRoleTypes.EVENT_ADMIN_ID)
    let isUserIsJudgeAdvisor = isUserTypeMatch(eventRoleTypes.JUDGE_ADVISOR_ID)

    if (userID == signedInUserId
      || userType === userTypes.PARTNER_ADMIN
      || userType === userTypes.FIRST_ADMIN
      || isUserIsEventAdmin
      || isUserIsJudgeAdvisor) {
      return true
    }

    return false
  }

  const formatDateTime = (date, is24Hour) => {
    return moment(date, 'h:mma M/D/YYYY').format(is24Hour ? 'HH:mm M/D/YYYY' : 'h:mma M/D/YYYY')
  }

  return (
    <div className='c-fll-feedback__comment-content'>
      <div className='m-b-12 judging-comments-meta-wrapper with-margin-right'>
        <p className={metaTextClassName}>{fullName}</p>
        <p className={metaTextClassName}>{formatDateTime(dateTime, use_24_hour_format)}</p>
      </div>
      <div>
        <p className={paragraphClassName}>{comment}</p>
      </div>
      {/* {(userId === signedInUserId && !isPublished) ? ( */}
      {(isAllowedToEditOrDelete(userId) && !isPublished) ? (
        <Button disabled={deletingComment} className='c-fll-feedback__comment-delete' shape='circle' type='link' onClick={() => {
          handleDelete()
          // deleteComment(commentId)
        }}>
          <CloseCircleOutlined />
        </Button>
      ) : null}
      {/* {onEdit && (userId === signedInUserId) && !isPublished */}
      {onEdit && (isAllowedToEditOrDelete(userId)) && !isPublished
        ? <EditOutlined
          className='c-fll-feedback__comment-edit'
          onClick={() => onEdit(commentId)}
        />
        : null}

      <ConfirmationModal
        visible={showDeleteModal}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onSubmit={() => {
          // deletePanel(id)
          deleteComment(commentId)
        }}
        title={(<div>
          <div className='team-name m-b-20'>{eventName}</div>
          <div className='title'>{t('deleteCommentLabel')}</div>
        </div>)}
        // title={`${t('DeleteTeamDeleteLabel')} ${panel.panelName}?`}
        description='This cannot be undone.'
      />
    </div>
  );
};

JudgingFeedbackComment.propTypes = {
  fullName: PropTypes.string.isRequired,
  deleteComment: PropTypes.func,
  signedInUserId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  comment: PropTypes.string,
  commentId: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  deletingComment: PropTypes.bool.isRequired,
  onEdit: PropTypes.func,
  userType: PropTypes.string
};

JudgingFeedbackComment.defaultProps = {
  comment: '',
  dateTime: '',
  deleteComment: () => { },
  userType: ''
}



const mapStateToProps = (state) => {
  const {
    judgingRubric: {
      isDeletingJudgingRubricComment,
      eventDetails: {
        eventId,
        eventName,
      }
    },
    auth: {
      userEventAssignments,
      userType,
      userInfo: {
        id,
        use_24_hour_format
      },
    } } = state;
  return {
    signedInUserId: id,
    deletingComment: isDeletingJudgingRubricComment,
    userType,
    rubricEventId: eventId,
    userEventAssignments,
    eventName,
    use_24_hour_format
  };
};


export default connect(mapStateToProps, { deleteComment: deleteJudgingRubricComment })(JudgingFeedbackComment);