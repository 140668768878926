/* eslint-disable import/prefer-default-export */
import * as teamService from 'utils/services/teams';
import * as userService from 'utils/services/users';
import * as countryService from 'utils/services/countries';
import * as regionService from 'utils/services/regions';
import { formatCoachToUser } from 'utils/services/teams/model';
import * as eventRoleService from 'utils/services/eventRoles';
import { notification } from 'antd';
import {COACH_ID} from 'shared/constants/eventRoleTypes';
import * as types from '../../types/teamsTypes';
import { inviteCoachBegin, inviteCoachSuccess, inviteCoachError } from './manually';


export const setCoachesSuccessfullyInvited = (coaches = [], errors = [], coachesNotInvited = []) => {
  return {
    type: types.SET_COACHES_SUCCESSFULLY_INVITED,
    payload: {
      coaches,
      errors,
      coachesNotInvited
    }
  }
}

export const getTeamId = async (team, programId) => {
  // check if the team is already a team in the system
  /*
    Check against the combination of team name, number, region and country. If any of those values are different, create a new team
  */
  
  let teamId = await teamService.verifyTeamExist(team);
  if (!teamId) {

    const countriesFilter = {
      'name': `eq:${team.country}`,
    }
    const teamCountries = await countryService.getCountriesBy(countriesFilter);
    const teamCountry = teamCountries.length ? teamCountries[0].id : null;
    let teamRegions = [];
    if (team.region) {
      const regionsFilter = {
        'program.id': `eq:${programId}`,
        'name': `eq:${team.region}`
      }
      teamRegions = await regionService.getRegionsBy(regionsFilter)
    }
    const teamRegion = teamRegions.length ? teamRegions[0].id : null;
    const newTeamPayload = {
      number: team.number,
      name: team.name,
      country: teamCountry,
      region: teamRegion
    }

    // if the selected team is not an existing team add the team to the db
    // get the team's id and use the id for the subsequent api requests
    let newTeam;
    if (teamCountry) {
      // if the user entered a region, validate that the region exists before adding a team with that region.
      // if the user specified a region for the team and that team is not a part of that region generate an error.
      if ((team.region && teamRegion) || (!team.region)) {
        newTeam = await teamService.createNewTeam(newTeamPayload);
      }else{
        throw({error: 'Wrong Region', team: team});
      }
    } else {
      throw({error: 'Wrong Country', team: team});
    }
    teamId = newTeam && newTeam.id;
  }
  return teamId;
};

export const handleInviteImportList = (coaches, eventId, cb = () => { }) => {
  return async (dispatch, getState) => {
    const { event: { eventDetails: { eventProgramId } } } = getState();
    await dispatch(inviteCoachBegin());
    try {
      const coachesSuccessfullyInvited = [];
      const inviteErrors = [];
      const coachesNotInvited = [];
      // const teamsNotInvited = [];

      const getTeamId = async (team, programId) => {
        // check if the team is already a team in the system
        /*
          Check against the combination of team name, number, region and country. If any of those values are different, create a new team
          
        */
        
        let teamId = await teamService.verifyTeamExist(team);
        if (!teamId) {
      
          const countriesFilter = {
            'name': `eq:${team.country}`,
          }
          const teamCountries = await countryService.getCountriesBy(countriesFilter);
          const teamCountry = teamCountries.length ? teamCountries[0].id : null;
          if (!teamCountry) {
            inviteErrors.push({
              text: `You tried to create a team in country ${team.country}. We currently do not support creating teams for this country. Please try with another country. (Remember countries and regions are only supported in English)`
            })
          }
          let teamRegions = [];
          if (team.region) {
            const regionsFilter = {
              'program.id': `eq:${programId}`,
              'name': `eq:${team.region}`
            }
            teamRegions = await regionService.getRegionsBy(regionsFilter)
          }
          const teamRegion = teamRegions.length ? teamRegions[0].id : null;
          if (!teamRegion) {
            inviteErrors.push({
              text: `You tried to create a team in region ${team.region}. We currently do not support creating teams for this region. Please try with another region. (Remember countries and regions are only supported in English)`
            })
          }
          const newTeamPayload = {
            number: team.number,
            name: team.name,
            country: teamCountry,
            region: teamRegion
          }
      
         
          
          // if the selected team is not an existing team add the team to the db
          // get the team's id and use the id for the subsequent api requests
          let newTeam;
          if (teamCountry) {
      
            // if the user entered a region, validate that the region exists before adding a team with that region.
            // if the user specified a region for the team and that team is not a part of that region generate an error.
            if ((team.region && teamRegion) || (!team.region)) {
              newTeam = await teamService.createNewTeam(newTeamPayload);
            }
          }
          teamId = newTeam && newTeam.id;
        }
        return teamId;
      };
      
      for (let j = 0; j < coaches.length; j+= 1) {
        const coach = coaches[j];
        const formattedTeams = [];

        const userId = await userService.getOrCreateUserId(formatCoachToUser(coach));
        for (let i = 0; i < coach.teams.length; i++) {
          const team = coach.teams[i];
          const teamId = await getTeamId(team, eventProgramId);
          if (teamId) {
            formattedTeams.push({ id: teamId });
          }
        }
        const inviteImportedCoach = async (coachUserId, coachTeams, coachEventId) => {
          if (coachTeams.length) {
            try {
              const eventRoleFilter = {
                'user.id': `eq:${userId}`,
                'event.id': `eq:${eventId}`,
                'role.id': `eq:${COACH_ID}`
              }
              const eventRoleAssignmentRes = await eventRoleService.getUserEventRolesBy(eventRoleFilter, 50);
              const eventRoleData = eventRoleAssignmentRes.data;
              if (eventRoleData.length) {
                const eventRoleAssignmentId = eventRoleData[0].id
                const assignedTeams = coachTeams.map(t => ({type: 'team', id: t.id}));
                const addTeamPayload = {
                  data: assignedTeams
                }
                // console.log(assignedTeams)
              
                const addTeamToCoachRes = await eventRoleService.addTeamsToUserEventRoleAssignment(eventRoleAssignmentId, addTeamPayload)
                
                notification.success({
                  message: 'Success',
                  description: 'Invitation successfully sent.',
                });
              } else {
                const data = await eventRoleService.createUserEventRole({ userId: coachUserId, teams: coachTeams, eventId: coachEventId });
                if (data && data.id) {
                  await eventRoleService.sendInvitations([data.id]);
                  notification.success({
                    message: 'Success',
                    description: 'Invitation successfully sent.',
                  });
                }
              }
              coachesSuccessfullyInvited.push(coach)
            } catch (err) {
             coachesNotInvited.push(coach);
             if (err && 'data' in err && err.data.statusCode === 451) {
              notification.error({
                message: 'Error',
                description: 'Volunteer has not been screened and cannot serve as coach or volunteer.',
              });
            }
              else if (err.status === 409) {
                notification.error({
                  message: 'Error',
                  description: 'The user has already been assigned the role of coach for this event.',
                });
              } else {
                notification.error({
                  message: 'Error',
                  description: 'Error sending the invitation.',
                });
              }
              console.error(err);
            }
          } else {
            // IF THE COACH DOES NOT HAVE ANY PROPERLY FORMATTED TEAMS SHOW AN ERROR
            notification.error({
              message: 'Error',
              description: 'Error creating the team for the coach.',
            });
            coachesNotInvited.push(coach)
          }
        }
  
       await inviteImportedCoach(userId, formattedTeams, eventId);

      }
      
      await dispatch(setCoachesSuccessfullyInvited(coachesSuccessfullyInvited, inviteErrors, coachesNotInvited))
      await dispatch(inviteCoachSuccess());
      cb();

    } catch(err) {
      await dispatch(setCoachesSuccessfullyInvited([], [], []))
      await dispatch(inviteCoachError());
      notification.error({
        message: 'Error',
      });
    }
  }
};
