/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
import log from 'loglevel';
import { notification } from 'antd';
import { Auth0Client } from '@auth0/auth0-spa-js';
import store from 'features/store';
import {
  resetLoadingUserState,
  setAuth0AuthenticatedStatus,
} from 'features/Authentication/redux/authActions';
//import { useConfig } from './configuration/useConfig';

//const audience = process.env.REACT_APP_AUTH_AUDIENCE;
//const domain = process.env.REACT_APP_AUTH_DOMAIN;
//const clientID = process.env.REACT_APP_AUTH_CLIENT_ID;
//const clientSecret = process.env.REACT_APP_AUTH_CLIENT_SECRET;
//const redirectUri = process.env.REACT_APP_AUTH_REDIRECT_URI;
//const googleInitializeRedirectUri = process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URI;
const PopupTimeoutInSeconds = 300;
class Auth {
  constructor(config) {
    this.audience = config.REACT_APP_AUTH_AUDIENCE;
    this.domain = config.REACT_APP_AUTH_DOMAIN;
    this.clientID = config.REACT_APP_AUTH_CLIENT_ID;
    this.redirectUri = config.REACT_APP_AUTH_REDIRECT_URI;
    this.googleInitializeRedirectUri = config.REACT_APP_GOOGLE_AUTH_REDIRECT_URI;
    this.myAuth = new Auth0Client({
      domain: this.domain,
      client_id: this.clientID,
      redirect_uri: this.redirectUri,
      audience: this.audience,
    });
  }

  initializeAccountWithGoogle = (callback) => {
    const selectedLanguage = localStorage.getItem('i18nextLng');
    this.myAuth
      .loginWithPopup(
        {
          connection: 'google-oauth2',
          prompt: 'select_account',
          redirectUri: this.googleInitializeRedirectUri,
          ui_locales: selectedLanguage,
          appState: this.redirectUri,
        },
        {
          timeoutInSeconds: PopupTimeoutInSeconds,
        }
      )
      .then((res) => {
        log.info(`Success initializeAccountWithGoogle loginWithPopup`);
        store.dispatch(setAuth0AuthenticatedStatus(true));
        if (callback && typeof callback === 'function') callback();
      })
      .catch((e) => {
        log.error(`Error initializeAccountWithGoogle loginWithPopup ${e.message}`);
        store.dispatch(resetLoadingUserState());
      });
  };

  googleAuthentication = (callback) => {
    const selectedLanguage = localStorage.getItem('i18nextLng');
    this.myAuth
      .loginWithPopup(
        {
          connection: 'google-oauth2',
          ui_locales: selectedLanguage,
          prompt: 'select_account',
          appState: this.redirectUri,
        },
        {
          timeoutInSeconds: PopupTimeoutInSeconds,
        }
      )
      .then((res) => {
        log.info(`Success googleAuthentication loginWithPopup`);
        store.dispatch(setAuth0AuthenticatedStatus(true));
        if (callback && typeof callback === 'function') callback();
      })
      .catch((e) => {
        log.error(`Error googleAuthentication loginWithPopup ${e.message}`);
        store.dispatch(resetLoadingUserState());
      });
  };

  accountAuthentication = (callback) => {
    const selectedLanguage = localStorage.getItem('i18nextLng');
    this.myAuth
      .loginWithPopup(
        {
          connection: 'Username-Password-Authentication',
          ui_locales: selectedLanguage,
          appState: this.redirectUri,
          prompt: 'select_account',
        },
        {
          timeoutInSeconds: PopupTimeoutInSeconds,
        }
      )
      .then((res) => {
        log.info(`Success accountAuthentication loginWithPopup`);
        store.dispatch(setAuth0AuthenticatedStatus(true));
        if (callback && typeof callback === 'function') callback();
      })
      .catch((e) => {
        log.error(`Error accountAuthentication loginWithPopup ${e.message}`);
        store.dispatch(resetLoadingUserState());
      });
  };

  handleAuthentication = async () => {
    try {
      // used for loginWithRedirect method, if using loginWithPopup omit this
      // const result = await this.myAuth.handleRedirectCallback();
      const token = await this.myAuth.getTokenSilently({
        detailedResponse: true,
        audience: this.audience,
      });
      log.info(`Success handleAuthentication`);
      token.idToken = token.id_token;
      token.accessToken = token.access_token;
      return token;
    } catch (err) {
      log.error(`Error handleAuthentication  ${err.message}`);
      if (err) {
        notification.error({
          message: 'Error',
          description: err.description || err.message,
        });
      }
      throw err;
    }
  };

  handleRefreshToken = async () => {
    let token;
    const isAuthenticated = await this.myAuth.isAuthenticated();
    if (isAuthenticated) {
      token = await this.myAuth.getTokenSilently({
        detailedResponse: true,
        audience: this.audience,
      });
      log.info(`Success handleRefreshToken-authenticated`);
      token.idToken = token.id_token;
      token.accessToken = token.access_token;
      return token;
    } else {
      const selectedLanguage = localStorage.getItem('i18nextLng');
      this.myAuth
        .loginWithPopup(
          {
            connection: 'Username-Password-Authentication',
            ui_locales: selectedLanguage,
            appState: this.redirectUri,
            prompt: 'none',
          },
          {
            timeoutInSeconds: PopupTimeoutInSeconds,
          }
        )
        .then((res) => {
          log.info(`Success handleRefreshToken-notAuthenticated loginWithPopup`);
          store.dispatch(setAuth0AuthenticatedStatus(true));
        })
        .catch((e) => {
          log.error(`Error handleRefreshToken-notAuthenticated loginWithPopup ${e.message}`);
          store.dispatch(resetLoadingUserState());
        });
    }
  };

  signOut = () => {
    this.myAuth.logout({
      returnTo: this.redirectUri,
    });
  };

  static authInstance = null;
  static CreateInstance(config) {
    this.authInstance = new Auth(config);
  }

  static GetInstance() {
    return this.authInstance;
  }
}

export default Auth;
