import { Row, Col } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import Tooltip from 'shared/components/Tooltip';
import Tabs from 'shared/components/Tabs/ModalTabs';
import { useTranslation } from 'react-i18next';
import {
  setPartnerOrgName,
  setPartnerProgRegion,
  setPartnerPrograms,
  setPartnerOrganization
} from 'features/AdminDashboard/redux/actions/partnersActions';
import { fetchRegionsBy } from 'features/AdminDashboard/redux/actions/programsActions';
import { PartnerUser, PartnerOrganization, InviteesList } from './ManualTabContent';

import './AddPartner.scss';

const rowProps = {
  type: 'flex',
  gutter: 20
};

const colProps = {
  sm: 24, md: 8
};

const RsCol = ({ children }) => (
  <Col {...colProps}>{children}</Col>
);

const AddPartnerForm = (props) => {
  const { selectedPartner, programOptions } = props;
  const { t } = useTranslation()

  const filteredProgramOptions = programOptions.filter(p=>p.shortName!=='FTC')

  return (
    <div>
      <div className='h-2 heading--charcoal'>
        {t('addPartnerLabel')}
      </div>
      <div className='add-partner__content'>
        <Tabs
          tabBarGutter={48}
          def
          overrides={{
            tabBarExtraContent: <Tooltip placement='left' text={t('partnerInviteInstructionLabel')} />
          }}
        >
          <Tabs.TabPane tab={t('manuallyLabel')} key='Manually'>
            <Row {...rowProps}>
              <RsCol>
                <PartnerUser />
              </RsCol>
              <RsCol>
                <PartnerOrganization
                  partner={selectedPartner}
                  programOptions={filteredProgramOptions}
                  setPartnerProgRegion={props.setPartnerProgRegion}
                  handleChangePrograms={props.setPartnerPrograms}
                  setPartnerOrgName={props.setPartnerOrgName}
                  setPartnerOrganization={props.setPartnerOrganization}
                  fetchRegionsBy={props.fetchRegionsBy}
                />
              </RsCol>
              <RsCol>
                <InviteesList />
              </RsCol>
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

const mapStateToProps = ({ adminBoard }) => {
  const { partners: { selectedPartner }, programs: { list } } = adminBoard;
  return {
    programOptions: list,
    selectedPartner
  };
};

const mapDispatchToProps = {
  setPartnerProgRegion,
  setPartnerPrograms,
  setPartnerOrgName,
  setPartnerOrganization,
  fetchRegionsBy
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPartnerForm);
