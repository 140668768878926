import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BackToBoard from '../../BackToBoard';

const FTCAwards = props => {
  const {
    eventCode
  } = props;

  const awardsLink = `https://ftc-scoring.firstinspires.org/event/${eventCode}/awards/give_awards`

  return (
    <div className='c-event-overview-awards'>
      <div className='c-event-overview-awards__actions'>
        <a className='c-event-overview-awards__ftc-link' href={awardsLink} target='_blank' rel="noreferrer">
          Assign Awards
        </a>
      </div>
    </div>
  );
};

FTCAwards.propTypes = {
  eventCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

const mapStateToProps = ({ eventOverview }) => {
  const { eventData: {
    attributes: { code }
  } } = eventOverview;

  return {
    eventCode: code
  };
};

export default connect(mapStateToProps)(FTCAwards);
