const m03Section = {
  id: 'm03',
  name: '2025ScoresheetM03Sub1Label',
  description: null,
  fields: [
    {
      scoring: '2025ScoresheetM03Sub2Label',
      id: 'm03_scoring_1',
      options: [
          {
              description: 'noLabel',
              value: false
          },
          {
              description: 'yesLabel',
              value: true
          },
      ]
    },
    {
        scoring: '2025ScoresheetM03Sub3Label',
        id: 'm03_scoring_2',
        options: [
            {
                description: '0',
                value: 0
            },
            {
                description: '1',
                value: 1
            },
            {
                description: '2',
                value: 2
            },
            {
                description: '3',
                value: 3
            },
        ]
    },
  ]
}

export default m03Section;