import * as panelService from 'utils/services/panels';
import { notification } from 'antd';
import {formatRemoveUsersFromPanel, formatRemoveSessionsFromPanel} from 'utils/services/panels/model';
import * as types from '../../types/judgingTypes';
import {getJudgingTabData} from './getJudgingPanels';

const editJudgingPanelBegin = () => {
  return {
    type: types.EDIT_JUDGING_PANEL_BEGIN
  }
}
const editJudgingPanelError = () => {
  return {
    type: types.EDIT_JUDGING_PANEL_ERROR
  }
}
const editJudgingPanelSuccess = (judgingPanels, judgeOptions, sessionOptions) => {
  return {
    type: types.EDIT_JUDGING_PANEL_SUCCESS,
    payload: {
      judgingPanels, judgeOptions, sessionOptions
    }
  }
}

const setJudgingPanelData = (sessionIds, judgeIds) => {
  return {
    type: types.SET_SELECTED_JUDGING_PANEL_DATA,
    payload: {
      sessionIds, judgeIds
    }
  }
}

const handleEditJudgingPanel = (panelId, payload, cb = () => {}) => {
  return async (dispatch, getState) => {
    const {event: {eventDetails: {id, eventScheduleId}}} = getState();
    try {
      await dispatch(editJudgingPanelBegin())
      const {
        removedJudges,
        removedSessions,
        addedJudges,
        addedSessions,
        formJudges,
        formSessions,
        panelName
      } = payload;
  
      if (removedSessions.length) {
        const removeSessionsPayload = formatRemoveSessionsFromPanel(removedSessions);
        
        await panelService.deleteSessionsFromPanel(panelId, removeSessionsPayload)
      }
      if (removedJudges.length) {
        const removedJudgesPayload = formatRemoveUsersFromPanel(removedJudges);
        await panelService.deleteJudgesFromPanel(panelId, removedJudgesPayload)
      }
      // if (addedJudges.length || addedSessions.length) {
        const updatePayload = {
          data: {
            type: 'panel',
            id: panelId,
            attributes: {
              name: panelName
            },
            relationships: {
              sessions: {
                data: addedSessions.map(s => {return {type: 'session', id: s}})
              },
              judges: {data: addedJudges.map(s => {return {type: 'user', id: s}})} 
            }
          }
        }
        await panelService.updatePanel(panelId, updatePayload)
      // }
      const {formattedPanels, judgeOptions, sessionOptions} = await getJudgingTabData(id, eventScheduleId)
      await dispatch(setJudgingPanelData(formSessions, formJudges))
      notification.success({
        message: 'Success',
        description: 'Room successfully saved',
      })
      await dispatch(editJudgingPanelSuccess(formattedPanels, judgeOptions, sessionOptions));
      cb && cb()
    } catch(err) {
      await dispatch(editJudgingPanelError())
      notification.error({
        message: 'Error',
        description: 'Error saving panel.',
      });
      throw err;
    }
  }

}


const handleDeleteJudgingPanel = (panelId, payload, cb = () => { }) => {
  return async (dispatch, getState) => {
    const { event: { eventDetails: { id, eventScheduleId } } } = getState();
    await panelService.deleteSessionsFromPanel(panelId, payload)

    const { formattedPanels, judgeOptions, sessionOptions } = await getJudgingTabData(id, eventScheduleId, 1)
    await dispatch(editJudgingPanelSuccess(formattedPanels, judgeOptions, sessionOptions))
  }
}

const handleDeleteRefereePanel = (panelId, payload, cb = () => { }) => {
  return async (dispatch, getState) => {
    const { event: { eventDetails: { id, eventScheduleId } } } = getState();
    await panelService.deleteJudgesFromPanel(panelId, payload)

    const { formattedPanels, judgeOptions, sessionOptions } = await getJudgingTabData(id, eventScheduleId, 1)
    await dispatch(editJudgingPanelSuccess(formattedPanels, judgeOptions, sessionOptions))
  }
}

const handleUpdateJudgingPanel = (panelId, payload, cb = () => { }) => {
  return async (dispatch, getState) => {
    const { event: { eventDetails: { id, eventScheduleId } } } = getState();
    await panelService.updatePanel(panelId, payload)

    const { formattedPanels, judgeOptions, sessionOptions } = await getJudgingTabData(id, eventScheduleId, 1)
    await dispatch(editJudgingPanelSuccess(formattedPanels, judgeOptions, sessionOptions))
  }
}

export { handleDeleteJudgingPanel, handleUpdateJudgingPanel, handleDeleteRefereePanel }
export default handleEditJudgingPanel

