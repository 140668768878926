const m18Section = {
  id: 'GP',
  name: '2022ScoresheetGraciousProfessionalismLabel',
  fields: [
    {
      scoring: '2022ScoresheetGraciousProfessionalismSub1Label',
      id: 'm18_scoring_1',
      options: [
        {
          description: '2022ScoresheetProfessionalismDevelopingLabel',
          value: 'developing'
        },
        {
          description: '2022ScoresheetProfessionalismAccomplishedLabel',
          value: 'accomplished'
        },
        {
          description: '2022ScoresheetProfessionalismExceedsLabel',
          value: 'exceeds'
        },
      ]
    },
  ]
}

export default m18Section;