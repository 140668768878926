import React from 'react';
import PropTypes from 'prop-types';

const PlusCircle = ({fill}) => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      <defs>
        <path d="M10,0 C4.4765625,0 0,4.4765625 0,10 C0,15.5234375 4.4765625,20 10,20 C15.5234375,20 20,15.5234375 20,10 C20,4.4765625 15.5234375,0 10,0 Z M16.0761719,16.0761719 C15.2871094,16.8652344 14.3671875,17.4863281 13.34375,17.9179688 C12.2851562,18.3671875 11.1601562,18.59375 10,18.59375 C8.83984375,18.59375 7.71484375,18.3671875 6.65625,17.9199219 C5.6328125,17.4863281 4.71484375,16.8671875 3.92382812,16.078125 C3.13476562,15.2890625 2.51367188,14.3691406 2.08203125,13.3457031 C1.6328125,12.2851562 1.40625,11.1601562 1.40625,10 C1.40625,8.83984375 1.6328125,7.71484375 2.08007812,6.65625 C2.51367188,5.6328125 3.1328125,4.71484375 3.921875,3.92382812 C4.7109375,3.13476562 5.63085938,2.51367188 6.65429688,2.08203125 C7.71484375,1.6328125 8.83984375,1.40625 10,1.40625 C11.1601562,1.40625 12.2851562,1.6328125 13.34375,2.08007812 C14.3671875,2.51367188 15.2851562,3.1328125 16.0761719,3.921875 C16.8652344,4.7109375 17.4863281,5.63085938 17.9179688,6.65429688 C18.3671875,7.71484375 18.59375,8.83984375 18.59375,10 C18.59375,11.1601562 18.3671875,12.2851562 17.9199219,13.34375 C17.4863281,14.3671875 16.8671875,15.2871094 16.0761719,16.0761719 Z M14.296875,9.30664062 L10.7128906,9.30664062 L10.7128906,5.72265625 C10.7128906,5.33398438 10.3984375,5.01953125 10.0097656,5.01953125 L10.0097656,5.01953125 C9.62109375,5.01953125 9.30664062,5.33398438 9.30664062,5.72265625 L9.30664062,9.30664062 L5.703125,9.30664062 C5.31445312,9.30664062 5,9.62109375 5,10.0097656 L5,10.0097656 C5,10.3984375 5.31445312,10.7128906 5.703125,10.7128906 L9.30664062,10.7128906 L9.30664062,14.2773438 C9.30664062,14.6660156 9.62109375,14.9804688 10.0097656,14.9804688 L10.0097656,14.9804688 C10.3984375,14.9804688 10.7128906,14.6660156 10.7128906,14.2773438 L10.7128906,10.7128906 L14.296875,10.7128906 C14.6855469,10.7128906 15,10.3984375 15,10.0097656 L15,10.0097656 C15,9.62109375 14.6855469,9.30664062 14.296875,9.30664062 Z" id="path-112" />
      </defs>
      <g id="Dashboards" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dashboard---FIRST---Partners" transform="translate(-1058.000000, -200.000000)">
          <g id="Group-11" transform="translate(270.000000, 199.000000)">
            <g id="Group-7" transform="translate(788.000000, 0.000000)">
              <g id="Icon/Plus-circle" transform="translate(0.000000, 1.000000)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-112" />
                </mask>
                <use id="Mask" fill={fill} xlinkHref="#path-112" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

PlusCircle.propTypes = {
  fill: PropTypes.string
};

PlusCircle.defaultProps = {
  fill: 'currentColor'
}
export default PlusCircle;