import React from 'react';
import PropTypes from 'prop-types';
import CardsList from 'shared/components/CardsList';
import { useTranslation } from 'react-i18next';
import PartnerTile from './PartnerTile';

const PartnersList = props => {
  const { partners, loading, isEditButtonVisible, onEditClick } = props;
  const { t } = useTranslation()

  return (
    <CardsList
      list={partners}
      loading={loading}
      renderTile={(partner) => <PartnerTile {...partner} isEditButtonVisible={isEditButtonVisible} onEditClick={() => onEditClick(partner.userId)} />}
      tabName={t('partnersLabel')}
    />
  );
};

PartnersList.propTypes = {
  list: PropTypes.array,
  partners: PropTypes.array,
  loading: PropTypes.bool,
  isEditButtonVisible: PropTypes.bool,
  onEditClick: PropTypes.func
};

PartnersList.defaultProps = {
  loading: false,
  list: [],
  partners: [],
  isEditButtonVisible: false,
  onEditClick: () => {}
};

export default PartnersList;
