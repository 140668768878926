/* eslint-disable no-use-before-define */
/* eslint-disable no-console */

import { createStore, applyMiddleware } from 'redux'
// import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { composeWithDevTools } from 'redux-devtools-extension'
import Thunk from 'redux-thunk'
import { persistReducer, createTransform } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { resetAuth, /*refreshTokenSuccess,*/ loginRequestError } from 'features/Authentication/redux/authActions'
//import auth from 'Auth';
import reducers from './reducers';
//import jwtDecode from 'jwt-decode'

//let authResultPending = false;
//const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const authenticationTransform = createTransform(
  (inboundState) => {
    return inboundState
  },
  (outboundState) => {
    if (outboundState.idToken) {
      store.dispatch({ type: 'AUTH_PERSISTED' })
    }
    return outboundState
  },
  { whitelist: ['auth'] }
);

const persistConfig = {
  key: 'root',
  storage,
  transforms: [authenticationTransform],
  whitelist: ['auth']
};

const store = createStore(
  persistReducer(persistConfig, reducers), 
  composeWithDevTools(
    applyMiddleware(Thunk)
  )
);

export const getAuth0Token = async () => {
  const { expiresAt, idToken, /*refreshToken,*/ loading } = store.getState().auth
  const timestamp = Math.floor(Date.now() / 1000);
  const errorMsg = 'Your session has expired due to inactivity. Please sign in again.';

  // If in the process of logging in or refreshing the token, return
  if (loading || !idToken) {
    return false;
  }
  
  if (expiresAt > timestamp) {
    return idToken;
  } else {
    try {
      // if multiple requests called at the same time, only refresh once
      store.dispatch(resetAuth());
      store.dispatch(loginRequestError(errorMsg));
      return;
    } catch (err) {
      //authResultPending = false;
      store.dispatch(resetAuth());
      store.dispatch(loginRequestError(err && err.description));
      return;
    }
  }
}

export default store;
