import React from 'react';
import PropTypes from 'prop-types';
import '../../../../../../features/PanelManagement/styles/JudgingPanelCard.scss';
import {
  FTC_ID
} from 'shared/constants/programIds';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';


const TeamsList = props => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const {panelSessions, eventProgramId} = props;


  const panelByTeams = _.groupBy(panelSessions, 'teamId');
  const numberOfTeams = Object.keys(panelByTeams).length;
  const subTitle = numberOfTeams === 0 ? t('2022EmptyPanelTeamAssignedLabel') : numberOfTeams === 1? t('2022EmptyPanelTeamAssigned3Label', { '0': 1 }) : t('2022EmptyPanelTeamAssigned2Label', { '0': numberOfTeams });
  const className = numberOfTeams === 0 ? "waring-text" : "sub-title";

  const sortedPanelSessions = _.sortBy(panelSessions, ['teamName', 'sessionTypeId', 'matchNumber'])

  return (
    <div className='judging-panel-card__teams'>
      <p className={className}>{subTitle}</p>
      <div>
        <div>
          {sortedPanelSessions.map(s => (
            <p className='judging-panel-card__teams-item'>

              {(eventProgramId === FTC_ID) ? (
                      `${s.teamName} (${s.sessionTypeName})`
                    ) : (
                      s.teamName
                      )}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

TeamsList.propTypes = {
  panelSessions: PropTypes.array,
  eventProgramId: PropTypes.string.isRequired
};

TeamsList.defaultProps = {
  panelSessions: [],
}

const mapStateToProps = (state) => {
  const {event: {eventDetails}} = state;
  const {eventProgramId} = eventDetails;
  return {
    eventProgramId
  }
}

export default connect(mapStateToProps)(TeamsList);