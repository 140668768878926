export const GET_COACH_JUDGING_TIMESTAMPS_BEGIN = 'GET_COACH_JUDGING_TIMESTAMPS_BEGIN';
export const GET_COACH_JUDGING_TIMESTAMPS_ERROR = 'GET_COACH_JUDGING_TIMESTAMPS_ERROR';
export const GET_COACH_JUDGING_TIMESTAMPS_SUCCESS = 'GET_COACH_JUDGING_TIMESTAMPS_SUCCESS';

export const GET_JUDGING_DOCUMENT_TYPES_BEGIN = 'GET_JUDGING_DOCUMENT_TYPES_BEGIN'
export const GET_JUDGING_DOCUMENT_TYPES_ERROR = 'GET_JUDGING_DOCUMENT_TYPES_ERROR'
export const GET_JUDGING_DOCUMENT_TYPES_SUCCESS = 'GET_JUDGING_DOCUMENT_TYPES_SUCCESS'

export const UPLOAD_JUDGING_SESSION_DOCUMENT_BEGIN = 'UPLOAD_JUDGING_SESSION_DOCUMENT_BEGIN';
export const UPLOAD_JUDGING_SESSION_DOCUMENT_ERROR = 'UPLOAD_JUDGING_SESSION_DOCUMENT_ERROR';
export const UPLOAD_JUDGING_SESSION_DOCUMENT_SUCCESS = 'UPLOAD_JUDGING_SESSION_DOCUMENT_SUCCESS';
export const UPLOAD_JUDGING_SESSION_DOCUMENT_RETRY = 'UPLOAD_JUDGING_SESSION_DOCUMENT_RETRY';

export const REMOVE_JUDGING_SESSION_DOCUMENT_BEGIN = 'REMOVE_JUDGING_SESSION_DOCUMENT_BEGIN';
export const REMOVE_JUDGING_SESSION_DOCUMENT_ERROR = 'REMOVE_JUDGING_SESSION_DOCUMENT_ERROR';
export const REMOVE_JUDGING_SESSION_DOCUMENT_SUCCESS = 'REMOVE_JUDGING_SESSION_DOCUMENT_SUCCESS';

export const GET_COACH_JUDGING_SESSION_RUBRIC_BEGIN = 'GET_COACH_JUDGING_SESSION_RUBRIC_BEGIN';
export const GET_COACH_JUDGING_SESSION_RUBRIC_ERROR = 'GET_COACH_JUDGING_SESSION_RUBRIC_ERROR';
export const GET_COACH_JUDGING_SESSION_RUBRIC_SUCCESS = 'GET_COACH_JUDGING_SESSION_RUBRIC_SUCCESS';

export const GET_COACH_JUDGING_OBJECT_BEGIN = 'GET_COACH_JUDGING_OBJECT_BEGIN';
export const GET_COACH_JUDGING_OBJECT_ERROR = 'GET_COACH_JUDGING_OBJECT_ERROR';
export const GET_COACH_JUDGING_OBJECT_SUCCESS = 'GET_COACH_JUDGING_OBJECT_SUCCESS';

export const SET_IS_COACH_JUDGING_OBJECT_PUBLISHED = 'SET_IS_COACH_JUDGING_OBJECT_PUBLISHED';
export const SET_IS_COACH_JUDGING_SESSION_RUBRIC_PUBLISHED = 'SET_IS_COACH_JUDGING_SESSION_RUBRIC_PUBLISHED';

export const SET_COACH_SELECTED_TEAM_JUDGING_SESSION = 'SET_COACH_SELECTED_TEAM_JUDGING_SESSION';