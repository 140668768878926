import React from 'react';
import PropTypes from 'prop-types';

const LeftArrow = ({fill}) => {
  return (
    <svg viewBox="0 0 8 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      <g id="Volunteers-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="My-Volunteers---Add-Volunteers---Import-CSV-Successful---PDP-Admin" transform="translate(-234.000000, -85.000000)" fill={fill}>
          <g id="Group-18" transform="translate(0.000000, 61.000000)">
            <g id="Group-17" transform="translate(234.000000, 20.000000)">
              <g id="Icons/FIRST/Drop-Arrow" transform="translate(4.000000, 11.000000) rotate(-270.000000) translate(-4.000000, -11.000000) translate(-3.000000, 7.000000)">
                <polygon id="Path" points="1.645 0 7 4.94466937 12.355 0 14 1.52226721 7 8 0 1.52226721" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

LeftArrow.propTypes = {
  fill: PropTypes.string
};

LeftArrow.defaultProps = {
  fill: 'currentColor'
}

export default LeftArrow;