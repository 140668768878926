import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TitleCard from 'shared/components/TitleCard';
import UploadInstructions from 'shared/components/UploadInstructions';
import { Row, Col } from 'antd';
import getJudgingTimestamps from 'features/EventOverviewContainer/redux/actions/coachJudging/getJudgingTimestamps';
import { connect } from 'react-redux';
import TimestampCard from 'shared/components/ScheduleCard/CompleteTimestamp';
import SessionCard from 'shared/components/ScheduleCard/CompleteSession';
import getDocumentTypes from 'features/EventOverviewContainer/redux/actions/coachJudging/getDocumentTypes';
import { parseDateString, parseTime } from 'utils/dateTimeUtils';
import ListLoading from 'shared/components/Spinner/ListLoading';
import { useTranslation } from 'react-i18next';
import JudgingResultsAndFeedback from './JudgingResults';
import DocumentsAndMaterials from './DocumentsMaterials';
import { getJudgeComments, getJudgeCommentsForFLLE } from 'features/EventOverviewContainer/redux/actions/judgingRubric';
import { changeFileUploadingStatus } from 'features/EventOverviewContainer/redux/actions/uploadingFileStatus';
import { updateGlobalUploadModalProps } from 'features/GlobalUploadModal/redux/actions'
import { sessionTypesTranslations } from 'shared/constants/sessionTypesTranslations';
import { FLL_EXPLORE_ID } from 'shared/constants/programIds';

const CoachJudging = props => {
  const { t } = useTranslation()

  const {
    time_zone,
    data,
    getTimestamps,
    teamId,
    teamName, teamNumber, teamLocation,
    changeFileUploadingStatus,
    updateGlobalUploadModalProps,
    use_24_hour_format,
    is_FLLC_event,
    eventProgramId,
  } = props;

  const isFLLE = eventProgramId == FLL_EXPLORE_ID;

  useEffect(() => {
    getTimestamps();
    if (teamId) {
      props.getDocumentTypes(teamId)
    }
  }, [teamId])

  // Upload modal, disallows user interaction while upload request is on-going

  const setUploadModalVisibility = (value) => {
    updateGlobalUploadModalProps({ visible: value })
  }

  const setUploadProgress = (value) => {
    updateGlobalUploadModalProps({ uploadProgress: value })
  }

  const onModalCancel = () => {
    updateGlobalUploadModalProps({
      visible: false,
      uploadProgress: 0,
    })
  }

  useEffect(() => {
    updateGlobalUploadModalProps({
      onCancel: onModalCancel,
      onUploadStatusChanged: changeFileUploadingStatus
    })
  }, [onModalCancel, changeFileUploadingStatus])

  const {
    judgingTimestamps,
    documentTypes,
    sessionDocuments,
    selectedTeamJudgingSession,
    isFetchingDocumentTypes
  } = data;

  const {
    digital_rubric,
    sessionId,
    sessionTypeName,
    endIsoString,
    startIsoString,
    sessionTypeId,
  } = selectedTeamJudgingSession

  useEffect(() => {
    if (digital_rubric) {
      if (is_FLLC_event){
        props.getJudgeComments(digital_rubric);
      }
      
    }
  }, [digital_rubric])

  const startTime = parseTime(startIsoString, time_zone, use_24_hour_format);
  const startDate = parseDateString(startIsoString, time_zone);
  const endTime = parseTime(endIsoString, time_zone, use_24_hour_format);

  const sessionTranslatedTitle = sessionTypesTranslations(is_FLLC_event)[sessionTypeId];
  const displayedSessionName = sessionTranslatedTitle ? t(sessionTranslatedTitle) : sessionTypeName;
  return (
    <div>
      <Row type='flex' justify='space-between' align='top' className='m-t-24'>
        <Col xs={24} md={6}>
          <TitleCard
            title={is_FLLC_event ? t('judgingLabel') : t('nonChallengeJudgingLabel')}
            teamNumber={teamNumber ? `Team ${teamNumber}` : undefined}
            teamName={teamName}
            location={teamLocation}
          />
        </Col>
        <Col xs={24} md={18}>
          <UploadInstructions
            instructions={(
              <>
                <p>{is_FLLC_event ? t('judgingInstructionsText') : t('nonChallengeJudgingInstructionsText')}</p>
                <br />
                <p>{t('acceptedFileTypesLabel')}</p>
              </>
            )}
          />
        </Col>
      </Row>
      <Row gutter={20} className='m-t-48'>
        <Col xs={24} md={8}>
          {judgingTimestamps.map(j => {
            const judgingDate = parseDateString(j.targetDateTime, time_zone);
            const judgingTime = parseTime(j.targetDateTime, time_zone);

            return (
              <TimestampCard targetDateString={judgingDate} targetTimeString={judgingTime} title={t(sessionTypesTranslations(is_FLLC_event)[j.timestampTypeId]) || j.description} key={j.id} id={j.id} />
            )
          })}
          {(sessionId && !isFetchingDocumentTypes) ? (
            <SessionCard
              title={displayedSessionName}
              startTimeString={startTime}
              endTimeString={endTime}
              dateString={startDate}
            />
          ) : (null)}
        </Col>
        <Col xs={24} md={isFLLE ? 6 : 16}>
          <JudgingResultsAndFeedback />
        </Col>
      </Row>
      {isFetchingDocumentTypes ? (
        <ListLoading loading text='Loading Documents & Materials' />
      ) : (
        <DocumentsAndMaterials
          sessionDocuments={sessionDocuments}
          documentTypes={documentTypes}
          setUploadModalVisibility={setUploadModalVisibility}
          setUploadProgress={setUploadProgress}
        />
      )}
    </div>
  );
};

CoachJudging.propTypes = {
  data: PropTypes.object.isRequired,
  getTimestamps: PropTypes.func.isRequired,
  getDocumentTypes: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
  teamLocation: PropTypes.string,
  teamName: PropTypes.string,
  teamNumber: PropTypes.string
};

CoachJudging.defaultProps = {
  teamLocation: '',
  teamName: '',
  teamNumber: ''
}

const mapDispatchToProps = {
  getTimestamps: getJudgingTimestamps,
  getDocumentTypes,
  getJudgeComments,
  getJudgeCommentsForFLLE,
  changeFileUploadingStatus,
  updateGlobalUploadModalProps
}

const mapStateToProps = (state) => {
  const { auth: { userInfo: { use_24_hour_format } }, coachJudging, eventOverview } = state;
  const { coachDetails, selectedTeam, time_zone, is_FLLC_event, eventProgramId } = eventOverview;
  const { id, teamName, teamNumber, teamLocation } = selectedTeam;

  return {
    is_FLLC_event,
    time_zone,
    data: coachJudging,
    coachDetails,
    teamId: id,
    teamName, teamNumber, teamLocation,
    use_24_hour_format,
    eventProgramId
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoachJudging);