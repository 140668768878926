import { REFEREE_ID } from 'shared/constants/eventRoleTypes';
import * as types from '../../types/scoringTypes';
import * as userEventRoleService from 'utils/services/eventRoles';

const getRefereesBegin = () => {
  return {
    type: types.GET_EVENT_REFEREES_BEGIN
  }
}

const getRefereesError = () => {
  return {
    type: types.GET_EVENT_REFEREES_ERROR
  }
}

const getRefereesSuccess = (eventRefereesData, eventRefereesIncluded) => {
  return {
    type: types.GET_EVENT_REFEREES_SUCCESS,
    payload: {
      eventRefereesData, eventRefereesIncluded
    }
  }
}

const handleEventReferees = (eventId) => {
  return async dispatch => {
    try {
      await dispatch(getRefereesBegin());
      const filter = {
        'role.id': `eq:${REFEREE_ID}`,
        'event.id': `eq:${eventId}`
      };
      const { data, included } = await userEventRoleService.getAllUserEventRolesBy(filter);
      await dispatch(getRefereesSuccess(data, included))
    } catch(err) {
      await dispatch(getRefereesError())
      throw err;
    }
  }
}

export default handleEventReferees;
