import {
  IMAGE_EXTENSIONS,
  VIDEO_EXTENSIONS,
  DOCUMENT_EXTENSIONS
} from './fileExtensions';

const anyFileTypeAccepted = [...IMAGE_EXTENSIONS, ...VIDEO_EXTENSIONS, DOCUMENT_EXTENSIONS].join(',')

const videoFiles = VIDEO_EXTENSIONS.join(',');

const documentFiles = DOCUMENT_EXTENSIONS.join(',')


const fllChallengeDocumentTypes = [
  {
    documentTypeId: '6a0397bb-f378-4ccb-82fa-ae982979316e',
    maximumFileCount: 6,
    accepted: anyFileTypeAccepted,
    description: 'Coach/Mentor Award Nomination Form'
  },
  {
    documentTypeId: '1d435b41-5231-4dfd-9fbe-4e100f2f74e2',
    maximumFileCount: 6,
    accepted: anyFileTypeAccepted,
    description: 'Interactive Engineering Notebook Submission'
  },
  {
    documentTypeId: '691bdb05-02f1-45b1-9c01-23bcacb20601',
    maximumFileCount: 1,
    accepted: videoFiles,
    description: 'Innovation Project Presentation'
  },
  {
    documentTypeId: 'eb237e10-c90f-4980-9e03-03e7e3db5b8a',
    maximumFileCount: 6,
    accepted: anyFileTypeAccepted,
    description: 'Robot Design'
  },
]

const fllExploreDocumentTypes = [
  {
    documentTypeId: '671eda71-7c9d-456a-92a7-8400aeb9fea9',
    maximumFileCount: 6,
    accepted: anyFileTypeAccepted,
    description: 'Team Materials' 
  },
  {
    documentTypeId: '1d435b41-5231-4dfd-9fbe-4e100f2f74e2',
    maximumFileCount: 6,
    accepted: anyFileTypeAccepted,
    description: 'Interactive Engineering Notebook submission ' 
  },
]

const fllDiscoverDocumentTypes = [
  {
    documentTypeId: '55cf5e4d-3c97-425c-a5f9-dde5bd3317a2',
    maximumFileCount: 6,
    accepted: anyFileTypeAccepted,
    description: 'Team Materials' 
  },
  {
    documentTypeId: '07eaac17-61b8-4f50-8768-552fb9ea78ba',
    maximumFileCount: 6,
    accepted: anyFileTypeAccepted,
    description: 'Engineering Notebook submission (PDF)' 
  }
]

const ftcDocumentTypes = [
  {
    documentTypeId: '6edefe6f-98c9-49a3-bb8b-6a4556dac157',
    maximumFileCount: 1,
    accepted: anyFileTypeAccepted,
    description: 'Control Awards Submission Form' 
  },
  {
    documentTypeId: '25fd5cd4-6ace-4d3b-913d-5f286b6c6bdb',
    maximumFileCount: 6,
    accepted: anyFileTypeAccepted,
    description: 'Engineering Portfolio'
  },
  {
    documentTypeId: 'd5b428e0-b719-4969-a569-fb631a092af6',
    maximumFileCount: 1,
    accepted: anyFileTypeAccepted,
    description: 'Judging Feedback Request Form'
  },
  {
    documentTypeId: 'f6da959e-d3a4-43ff-8df0-6c1f8356cb9d',
    maximumFileCount: 1,
    accepted: documentFiles,
    description: 'Reviewing Questions'

  }
]
const documentTypesValidation = [
  ...fllChallengeDocumentTypes,
  ...fllExploreDocumentTypes,
  ...fllDiscoverDocumentTypes,
  ...ftcDocumentTypes
];

export default documentTypesValidation;