const m15Section = {
  id: 'm15',
  name: '2025ScoresheetM15Sub1Label',
  fields: [
    {
      scoring: '2025ScoresheetM15Sub2Label',
      id: 'm15_scoring_1',
      options: [
        {
          description: '0',
          value: 0
        },
        {
          description: '1',
          value: 1
        },
        {
          description: '2',
          value: 2
        },
        {
          description: '3',
          value: 3
        },
        {
          description: '4',
          value: 4
        },
        {
          description: '5',
          value: 5
        },
        {
            description: '6',
            value: 6
        },
      ]
      },
      {
      scoring: '2025ScoresheetM15Sub3Label',
      id: 'm15_scoring_2',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        },
      ]
    },
  ]
}

export default m15Section;

