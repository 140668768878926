import { getAttributesPayload } from 'utils/query';

export const formatSessionDocumentFile = (uploadedFile) => {
  const {attributes, id, type} = uploadedFile;
  return  {
    eventFileId: id,
    contentType: attributes.content_type,
    fileName: attributes.original_file_name,
    rawUrl: attributes.raw_url,
    remoteUrl: attributes.remote_url,
    type,
    status: attributes.status,
  }
}

export const formatSessionFiles = (uploadedFiles, included) => {
  const sessionFiles = []; 
  uploadedFiles.forEach(f => {
    const selectedFile = included.find(i => i.id === f.id);
    if (selectedFile) {
      const fileDetails = formatSessionDocumentFile(selectedFile)
      sessionFiles.push(fileDetails);
    }
  })
  return sessionFiles;
}

export const formatPatchSessionDocument = (payload, id) => {
  const attributes = getAttributesPayload(payload);
  return {
    data: {
      type: 'session_document',
      id,
      attributes
    }
  };
};
