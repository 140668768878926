import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SessionPanelUsers from 'shared/components/SessionPanelUsers';
import StatusBadge from 'shared/components/StatusBadge';
import fetchAssignedMatches, { handlePageChange } from 'features/EventOverviewContainer/redux/actions/getAssignedMatches';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import matchStatuses, { NotSetStatus } from 'shared/constants/matchStatuses';
import { useTranslation } from 'react-i18next';
import { getPageFilter } from 'shared/components/Pagination';
import _ from 'lodash';
import { Switch, Table } from 'antd';
import Icon from '@ant-design/icons';
import SharedIcon from 'shared/components/Icon';
import { officialMatchType, practiceMatchType } from 'shared/constants/matchTypes';
import { parseTimeAndDate, parseDate, formatDate, formatDateShort } from 'utils/dateTimeUtils';
import SearchBar from 'features/AdminDashboard/components/SearchBar';
import Button from 'shared/components/Button/BtnSecondary';
import '../../styles/EventOverviewAssignedScoringSessions.scss';
import EventModal from 'features/EventOverviewContainer/components/EventModal';
import SessionPaneSelect from 'shared/components/SessionPanelSelect';
import MatchesFilter from '../MatchesFilter';
import moment from 'moment';

const MATCHES_PER_PAGE = 6;

const ItemWrapper = ({ ...props }) => <div {...props} className="table-item-wrapper" />

const AssignedScoringSessions = props => {
  const {
    assignedScoringSessions,
    time_zone,
    use_24_hour_format
  } = props;

  const { t } = useTranslation()

  const { eventId } = useParams();

  const {
    assignedPanels,
    assignedMatches,
    isFetchingAssignedMatches,
    count,
    currentPage
  } = assignedScoringSessions

  const [transition, setTransition] = useState(0);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [expandedKeysForTeam, setExpandedKeysForTeam] = useState([]);
  const [currentSize, setCurrentSize] = useState(MATCHES_PER_PAGE);
  const [order, setOrder] = useState('');
  const [search, setSearch] = useState('')
  const [fullscreen, setFullscreen] = useState(false);
  const [prevPage, setPrevPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState("all")
  const [currentPanelFilter, setCurrentPanelFilter] = useState([]);
  const tracker = useRef(null);
  const thumb = useRef(null);
  const [tableViewType, setTableViewType] = useState('timeAndDate');

  const debouncedFetchAssignedMatches = useCallback(
    _.debounce((filter, page, sorter) => props.fetchAssignedMatches(filter, page, sorter), 1000),
    []
  )

  useEffect(() => {
    const page = getPageFilter(currentPage, currentSize);
    const filter = search ? { name: `like:${search}` } : {};
    debouncedFetchAssignedMatches(filter, page, order)
  }, [currentPage, order, currentSize]);

  // useEffect(() => {
  //   const page = getPageFilter(currentPage, currentSize);
  //   const filter = search ? { name: `like:${search}` } : {};
  //   debouncedFetchAssignedMatches(filter, page, order)
  // }, [currentPage, search, order, currentSize]);

  const sortByDate = (myArray) => {
    return myArray.sort(function compare(a, b) {
      var dateA = new Date(a.startTime);
      var dateB = new Date(b.startTime);
      return dateA - dateB;
    });

  }

  const uniqueID = () => {
    return Math.floor(Math.random() * Date.now())
  }

  const dataSource = _.orderBy(assignedMatches, ['matchTeam', "matchTypeName", "matchNumber"], ["asc", "desc", "asc"]);

  dataSource.forEach(d => {
    d.date = parseDate(d.startTime)
  });

  const sortByTeamNumberAndName = (myArray) => {
    return myArray.sort(function compare(a,b){
      const aStr = a?.teamTitle ?? '';
      const bStr = b?.teamTitle ?? '';
      return aStr.localeCompare(bStr, undefined, { numeric: true, sensitivity: 'variant'});
    })
  }

  if (tableViewType == 'timeAndDate') {
    sortByDate(dataSource)
  } else {
    sortByTeamNumberAndName(dataSource);
  }

  const groupMatchesIntoDates = () => {
    const groupedData = _.groupBy(dataSource, 'date');
    const keys = Object.keys(groupedData);
    let result = [];
    keys.forEach(key => {
      const list = groupedData[key];
      const data = list[0] || {};
      const { matchTeam, matchTeamId, startIsoString, startTime, teamNumber } = data;
      const formattedData = {
        matchTeamId,
        matchTeam,
        date: startTime,
        data: list,
        key: matchTeamId + startIsoString,
        uniqueId: matchTeamId + startIsoString
      }
      result.push(formattedData);
    })
    return result;
  }

  const groupMatchesIntoTeams = () => {
    const groupedData = _.groupBy(dataSource, 'matchTeamId');
    const keys = Object.keys(groupedData);
    let result = [];
    keys.forEach(key => {
      const list = groupedData[key];
      const data = list[0] || {};
      const { matchTeam, matchTeamId, startIsoString, teamNumber } = data;
      const formattedData = {
        teamNumber,
        matchTeamId,
        matchTeam,
        data: list,
        key: matchTeamId + startIsoString,
        uniqueId: "teams-" + matchTeamId + startIsoString
      }
      result.push(formattedData);
    })
    return result;
  }

  const onSortChange = (pagination = {}, filter = {}, sorter) => {
    const order = (sorter || {}).order || '';
    if (order === 'ascend') {
      setOrder(`{encodeURIComponent('+team_number')},{encodeURIComponent('+name')}`)
    } else if (order === 'descend') {
      setOrder('-team_number,-name')
    } else {
      setOrder('');
    }
  }

  const expandRowOnKey = (key) => {
    setExpandedKeys([...expandedKeys, key])
  }

  const collapseRowOnKey = (key) => {
    setExpandedKeys(expandedKeys.filter(k => k !== key))
  }


  const expandRowOnKeyForTeam = (key) => {
    setExpandedKeysForTeam([...expandedKeysForTeam, key])
  }

  const collapseRowOnKeyForTeam = (key) => {
    setExpandedKeysForTeam(expandedKeysForTeam.filter(k => k !== key))
  }


  const filterByMatchType = () => {
    if (currentFilter == 'all') {
      return formattedDataMain;
    }

    let clonedData = [...formattedDataMain]

    clonedData.forEach(items => {
      let filteredData = [];
      items.data.forEach(item => {
        // practiceMatchType.matchTypeId
        if (item.matchTypeId == practiceMatchType.matchTypeId && currentFilter == 0) {
          filteredData.push(item)
          return
        }

        if (item.matchNumber == currentFilter && item.matchTypeId != practiceMatchType.matchTypeId) {
          filteredData.push(item)
        }
      });
      items.data = filteredData;
    });

    return clonedData;
  };

  const filterByMatchPanel = (data) => {
    if (currentPanelFilter.length <= 0) {
      return data;
    }

    let clonedData = [...data]

    clonedData.forEach(items => {
      let filteredData = [];
      items.data.forEach(item => {
        if (currentPanelFilter.includes(item.panelId)) {
          filteredData.push(item)
        }
      });
      items.data = filteredData;
    });

    return clonedData;
  };

  const filterByTeamName = (data) => {
    if (search == '') {
      return data;
    }

    var serachRegx = new RegExp(search, 'gi');

    let clonedData = [...data]

    clonedData.forEach(items => {
      let filteredData = [];
      items.data.forEach(item => {
        if (item.matchTeam.match(serachRegx) || item.teamNumber.match(serachRegx)) {
          filteredData.push(item)
        }
      });
      items.data = filteredData;
    });

    return clonedData;
  };

  const clearEmpty = () => {
    let newData = [];
    let clonedData = [...formattedDataMain]
    clonedData.forEach((item, index) => {
      if (item.data.length > 0) {
        newData.push(item)
      }
    });

    return newData
  }

  // const formattedData = groupMatchesIntoTeams()
  const formattedDataMain = tableViewType == 'timeAndDate' ? groupMatchesIntoDates() : groupMatchesIntoTeams()
  let formattedData = tableViewType == 'timeAndDate' ? groupMatchesIntoDates() : groupMatchesIntoTeams()

  formattedData = filterByMatchType()
  formattedData = filterByMatchPanel(formattedData)
  formattedData = filterByTeamName(formattedData)
  formattedData = clearEmpty()

  const ExpandArrow = (props) => {
    const { matchId } = props;
    const isExpanded = expandedKeys.includes(matchId);
    const handleOnClick = isExpanded ? collapseRowOnKey : expandRowOnKey;
    const iconType = isExpanded ? "smallRightArrowBlue" : "smallDownBlue";
    return (
      <ItemWrapper>
        <a className="expanded-arrow" onClick={() => handleOnClick(matchId)}>
          <SharedIcon type={iconType} />
          {props.children}
        </a>
      </ItemWrapper>
    )
  }

  const ExpandArrowForTeam = (props) => {
    const { matchId } = props;
    const isExpanded = expandedKeysForTeam.includes(matchId);
    const handleOnClick = isExpanded ? collapseRowOnKeyForTeam : expandRowOnKeyForTeam;
    const iconType = isExpanded ? "smallRightArrowBlue" : "smallDownBlue";
    return (
      <ItemWrapper>
        <a className="expanded-arrow" onClick={() => handleOnClick(matchId)}>
          <SharedIcon type={iconType} />
          {props.children}
        </a>
      </ItemWrapper>
    )
  }

  const expandAllRecord = () => {
    const keys = formattedData.map(d => d.uniqueId);
    setExpandedKeys(keys);
  }

  const collapseAllRecord = () => {
    setExpandedKeys([])
  }

  const expandAllRecordForTeam = () => {
    const keys = formattedData.map(d => d.uniqueId);
    setExpandedKeysForTeam(keys);
  }

  const collapseAllRecordForTeam = () => {
    setExpandedKeysForTeam([])
  }


  const onChangeMatchFilter = (data) => {
    // console.log(data);
    // console.log(formattedData);
    setCurrentFilter(data)
  }

  const handlePanelFilter = (data) => {
    let panelIds = data.map((item) => item.id)
    setCurrentPanelFilter(panelIds)
  }

  //FILTER BY TEAM NAME AND NUMBER COLUMN

  // const formatDate = (date) => {
  //   if (date === undefined || date == null) return 'Not Set'
  //   return moment(date).format("dddd, MMMM DD");
  // }

  const formatTime = (date, time_zone, is24Hour = false) => {
    if (date === undefined || date == null) return 'Not Set'
    // return moment(date).format("h:mm a");
    return time_zone ? moment.utc(date).tz(time_zone).format(is24Hour ? 'HH:mm' : 'h:mm a') : moment.utc(date).format(is24Hour ? 'HH:mm' : 'h:mm a')
  }

  const columns = [
    {
      title: t('MatchesTimeandDateLabel'),
      dataIndex: 'timeAndDate',
      width: 200,
      // sorter: (a, b) => a.matchTeam.localeCompare(b.matchTeam),
      render: (val, record) => {
        // const { matchTeamId, matchTeam } = record;
        const { matchTeamId, matchNumber, data, matchTeam, teamNumber, uniqueId } = record;

        const isExpanded = expandedKeysForTeam.includes(uniqueId) ? false : true;
        const p_number = data.filter(d => d.matchTypeId === practiceMatchType.matchTypeId).length;
        const o_number = data.filter(d => d.matchTypeId === officialMatchType.matchTypeId).length;

        if (isExpanded) {
          const result = data.map(d => {
            const { matchTypeId, startTime, matchId, startIsoString } = d || {};
            const formattedTime = startTime ? parseTimeAndDate(startIsoString, time_zone) : "Not set";
            if (matchTypeId === practiceMatchType.matchTypeId) {
              return <ItemWrapper key={matchId}><span>
                <span className='time-only m-r-10'>{formatTime(startTime, time_zone, use_24_hour_format)}</span> <span className='date-only'>{formatDateShort(startTime, time_zone)}</span>
              </span></ItemWrapper>
            } else if (matchTypeId === officialMatchType.matchTypeId) {
              return <ItemWrapper key={matchId}><span>
                <span className='time-only m-r-10'>{formatTime(startTime, time_zone, use_24_hour_format)}</span> <span className='date-only'>{formatDateShort(startTime, time_zone)}</span>
              </span></ItemWrapper>
            }
          })


          return (
            <>
              <div className={`${tableViewType == 'teamAndNumber' ? 'white-row-bg-team-number' : 'white-row-bg'}`}></div>
              <div className="team-title">
                <ExpandArrowForTeam matchId={uniqueId}>
                  <div className="title-text">
                    {teamNumber} - {matchTeam}
                  </div>
                </ExpandArrowForTeam>
              </div>
              <div className="referees-times-wrapper table-first-row">{result}</div>
            </>
          )
        }

        return (
          <>
            <div className={`${tableViewType == 'teamAndNumber' ? 'white-row-bg-team-number' : 'white-row-bg'}`}></div>
            <div className="team-title">
              <ExpandArrowForTeam matchId={uniqueId}>
                <div className="title-text long-title">
                  {teamNumber} - {matchTeam}
                </div>
              </ExpandArrowForTeam>
            </div>
          </>
        )
      }
    },
    {
      title: t('teamNumberAndNameLabel'),
      dataIndex: 'matchTeamId',
      className: 'team-name-number selected-column',
      width: 170,
      // sorter: (a, b) => a.matchTeam.localeCompare(b.matchTeam),
      render: (val, record) => {
        const { data, uniqueId } = record;
        const isExpanded = expandedKeysForTeam.includes(uniqueId) ? false : true;
        const p_number = data.filter(d => d.matchTypeId === practiceMatchType.matchTypeId).length;
        const o_number = data.filter(d => d.matchTypeId === officialMatchType.matchTypeId).length;
        if (isExpanded) {
          const result = data.map(d => {
            const { matchTypeId, startTime, matchId, startIsoString } = d || {};
            if (matchTypeId === practiceMatchType.matchTypeId) {
              return <ItemWrapper key={matchId}>
                <span className='elipsis'>{d.teamNumber} - {d.matchTeam}</span>
              </ItemWrapper>
            } else if (matchTypeId === officialMatchType.matchTypeId) {
              return <ItemWrapper key={matchId}>
                <span className='elipsis'>{d.teamNumber} - {d.matchTeam}</span>
              </ItemWrapper>
            }
          })
          return <div className="referees-times-wrapper table-custom-row">{result}</div>
        }
      }
    },
    {
      title: t('matchLabel'),
      dataIndex: 'matchType',
      width: 100,
      render: (val, record) => {
        const { matchTeamId, matchNumber, data, uniqueId } = record;
        const isExpanded = expandedKeysForTeam.includes(uniqueId) ? false : true;
        const p_number = data.filter(d => d.matchTypeId === practiceMatchType.matchTypeId).length;
        const o_number = data.filter(d => d.matchTypeId === officialMatchType.matchTypeId).length;

        if (isExpanded) {
          const result = data.map(d => {
            const { matchTypeId, startTime, matchId, startIsoString } = d || {};
            const formattedTime = startTime ? parseTimeAndDate(startIsoString, time_zone) : "Not set";
            if (matchTypeId === practiceMatchType.matchTypeId) {
              return <ItemWrapper key={matchId}><span className='elipsis'>{`Practice`}</span></ItemWrapper>
            } else if (matchTypeId === officialMatchType.matchTypeId) {
              return <ItemWrapper key={matchId}><span className='elipsis'>{`Match ${d.matchNumber}`}</span></ItemWrapper>
            }
          })
          return <div className="referees-times-wrapper table-custom-row">{result}</div>
        }
        else {
          return <ItemWrapper><p className="referees-time">{p_number > 0 ? `${p_number} Practice, ` : ''}{o_number > 0 ? `${o_number} Official ` : ''}{p_number + o_number > 0 ? 'Matches' : ''}</p></ItemWrapper>
        }
      }
    },
    {
      title: t('MatchesLocationLabel'),
      dataIndex: 'location',
      render: (val, record) => {
        const { matchTeamId, matchNumber, data, uniqueId } = record;
        const isExpanded = expandedKeysForTeam.includes(uniqueId) ? false : true;
        const p_number = data.filter(d => d.matchTypeId === practiceMatchType.matchTypeId).length;
        const o_number = data.filter(d => d.matchTypeId === officialMatchType.matchTypeId).length;
        if (isExpanded) {
          const result = data.map(d => {
            // console.log(d);
            const { matchTypeId, startTime, matchId, startIsoString } = d || {};
            const formattedTime = startTime ? parseTimeAndDate(startIsoString, time_zone) : "Not set";
            // if (matchTypeId === practiceMatchType.matchTypeId) {
            return <ItemWrapper key={matchId}><span>{d?.panelName ? d.panelName : '-'}</span></ItemWrapper>
            // } else if (matchTypeId === officialMatchType.matchTypeId) {
            // return <ItemWrapper key={matchId}><span>{data?.panelName ? '-' : data?.panelName}</span></ItemWrapper>
            // }
          })
          return <div className="referees-times-wrapper table-custom-row">{result}</div>
        }
        // else {
        //   return <ItemWrapper><p className="referees-time">{p_number > 0 ? `${p_number} Practice, ` : ''}{o_number > 0 ? `${o_number} Official, ` : ''}{p_number + o_number > 0 ? 'Matches' : ''}</p></ItemWrapper>
        // }
      }
    },
    {
      title: t('statusLabel'),
      dataIndex: 'matchStatus',
      render: (val, row) => {
        const { matchTeamId, data, uniqueId } = row;
        const isExpanded = expandedKeysForTeam.includes(uniqueId) ? false : true;
        if (isExpanded) {
          const result = data.map(d => {
            const { matchStatusId, matchId } = d;
            const sessionStatus = matchStatuses.find(s => s.statusId === matchStatusId) || NotSetStatus;
            const { badgeType, statusName } = sessionStatus;
            return <ItemWrapper key={matchId}><StatusBadge badgeType={badgeType} statusText={t(statusName)} key={matchId} /></ItemWrapper>
          })
          return <div className="referees-status-wrapper expanded table-custom-row">{result}</div>
        }
        else {
          const result = data.map(d => {
            const { matchStatusId, matchTotalScore, matchId } = (d || {});
            const sessionStatus = matchStatuses.find(s => s.statusId === matchStatusId) || NotSetStatus;
            const { badgeType } = sessionStatus;
            return <ItemWrapper key={matchId}><StatusBadge badgeType={badgeType} statusText={matchTotalScore || matchTotalScore === 0 ? matchTotalScore : '-'} /></ItemWrapper>
          });
          return (
            <div className="referees-status-wrapper unexpanded">
              {result}
            </div>
          )
        }
      },
    },
    {
      title: t('scoresheetLabel'),
      dataIndex: 'scoresheet',
      width: 80,
      render: (val, row) => {
        const { matchTeamId, data, uniqueId } = row;
        const isExpanded = expandedKeysForTeam.includes(uniqueId) ? false : true;
        if (isExpanded) {
          const result = data.map(d => {
            const { matchId } = d;
            return <ItemWrapper key={matchId}><Link to={`/event-overview/${eventId}/scoresheet/${matchId}`} href={`/event-overview/${eventId}/scoresheet/${val}`} className='paragraph--blue paragraph--normal'>View</Link></ItemWrapper>
          })
          return <div className="referees-times-wrapper table-custom-row">{result}</div>
        }
        // else {
        //   return null
        // }
      }
    },
    {
      title: <div className='score-label-title'>{t('scoreLabel')}</div>,
      dataIndex: 'matchScore',
      width: 80,
      fixed: 'right',
      render: (val, row) => {
        const { matchTeamId, data, uniqueId } = row;
        const isExpanded = expandedKeysForTeam.includes(uniqueId) ? false : true;
        if (isExpanded) {
          const result = data.map(d => {
            const { matchTotalScore, matchId } = d;
            return <ItemWrapper key={matchId}>{matchTotalScore >= 0 ? matchTotalScore : '-'}</ItemWrapper>
          })
          return <div className="referees-scores-wrapper table-custom-row">{result}</div>
        }
        // else {
        //   return <div className="referees-scores-wrapper"></div>
        // }
      }
    }
  ];




  //FILTER BY TEAM TIME AND DATE COLUMN TABLE

  const columnsTimeAndData = [
    {
      title: t('MatchesTimeandDateLabel'),
      dataIndex: 'timeAndDate',
      key: 'timeAndDate',
      width: 150,
      className: 'team-name-number selected-column',
      // sorter: (a, b) => a.matchTeam.localeCompare(b.matchTeam),
      render: (val, record) => {
        // const { matchTeamId, matchTeam } = record;

        const { matchTeamId, matchNumber, data, matchTeam, uniqueId } = record;
        const isExpanded = expandedKeys.includes(uniqueId) ? false : true;
        const p_number = data.filter(d => d.matchTypeId === practiceMatchType.matchTypeId).length;
        const o_number = data.filter(d => d.matchTypeId === officialMatchType.matchTypeId).length;

        if (isExpanded) {
          const result = data.map(d => {
            const { matchTypeId, startTime, matchId, startIsoString } = d || {};
            const formattedTime = startTime ? parseTimeAndDate(startIsoString, time_zone) : "Not set";
            if (matchTypeId === practiceMatchType.matchTypeId) {
              return <ItemWrapper key={matchId}><span>
                <span className='time-only m-r-10'>{formatTime(startTime, time_zone, use_24_hour_format)}</span>
              </span></ItemWrapper>
            } else if (matchTypeId === officialMatchType.matchTypeId) {
              return <ItemWrapper key={matchId}><span>
                <span className='time-only m-r-10'>{formatTime(startTime, time_zone, use_24_hour_format)}</span>
              </span></ItemWrapper>
            }
          })
          return (
            <>
              <div className={`${tableViewType == 'teamAndNumber' ? 'white-row-bg-team-number' : 'white-row-bg'}`}></div>
              <div className="team-title">
                <ExpandArrow matchId={uniqueId}>
                  <div className="title-text">
                    {`${formatDate(record.date, time_zone)} (${data.length})`}
                  </div>
                </ExpandArrow>
              </div>
              <div className="referees-times-wrapper table-first-row">{result}</div>
            </>
          )
        }

        return (
          <>
            <div className={`${tableViewType == 'teamAndNumber' ? 'white-row-bg-team-number' : 'white-row-bg'}`}></div>
            <div className="team-title">
              <ExpandArrow matchId={uniqueId}>
                <div className="title-text">
                  {`${formatDate(record.date, time_zone)} (${data.length})`}
                </div>
              </ExpandArrow>
            </div>
          </>
        )
      }
    },
    {
      title: t('teamNumberAndNameLabel'),
      dataIndex: 'matchTeamId',
      key: 'matchTeamId',
      className: '',
      width: 200,
      // sorter: (a, b) => a.matchTeam.localeCompare(b.matchTeam),
      render: (val, record) => {
        const { matchTeamId, matchNumber, data, uniqueId } = record;
        const isExpanded = expandedKeys.includes(uniqueId) ? false : true;
        const p_number = data.filter(d => d.matchTypeId === practiceMatchType.matchTypeId).length;
        const o_number = data.filter(d => d.matchTypeId === officialMatchType.matchTypeId).length;
        if (isExpanded) {
          const result = data.map(d => {
            const { matchTypeId, startTime, matchId, startIsoString } = d || {};
            const formattedTime = startTime ? parseTimeAndDate(startIsoString, time_zone) : "Not set";
            if (matchTypeId === practiceMatchType.matchTypeId) {
              return <ItemWrapper key={matchId}>
                <span className='elipsis'>{d.teamNumber} - {d.matchTeam}</span>
              </ItemWrapper>
            } else if (matchTypeId === officialMatchType.matchTypeId) {
              return <ItemWrapper key={matchId}>
                <span className='elipsis'>{d.teamNumber} - {d.matchTeam}</span>
              </ItemWrapper>
            }
          })
          return <div className="referees-times-wrapper table-custom-row">{result}</div>
        }
        // else {
        //   return <ItemWrapper><p className="referees-time">{p_number > 0 ? `${p_number} Practice, ` : ''}{o_number > 0 ? `${o_number} Official, ` : ''}{p_number + o_number > 0 ? 'Matches' : ''}</p></ItemWrapper>
        // }
      }
    },
    {
      title: t('matchLabel'),
      dataIndex: 'matchType',
      key: 'matchType',
      width: 100,
      render: (val, record) => {
        const { matchTeamId, matchNumber, data, uniqueId } = record;
        const isExpanded = expandedKeys.includes(uniqueId) ? false : true;
        const p_number = data.filter(d => d.matchTypeId === practiceMatchType.matchTypeId).length;
        const o_number = data.filter(d => d.matchTypeId === officialMatchType.matchTypeId).length;
        if (isExpanded) {
          const result = data.map(d => {
            const { matchTypeId, startTime, matchId, startIsoString } = d || {};
            const formattedTime = startTime ? parseTimeAndDate(startIsoString, time_zone) : "Not set";
            if (matchTypeId === practiceMatchType.matchTypeId) {
              return <ItemWrapper key={matchId}><span className='elipsis'>{`Practice`}</span></ItemWrapper>
            } else if (matchTypeId === officialMatchType.matchTypeId) {
              return <ItemWrapper key={matchId}><span className='elipsis'>{`Match ${d.matchNumber}`}</span></ItemWrapper>
            }
          })
          return <div className="referees-times-wrapper table-custom-row">{result}</div>
        }
        else {
          return null
        }
      }
    },
    {
      title: t('MatchesLocationLabel'),
      dataIndex: 'location',
      key: 'location',
      render: (val, record) => {
        const { matchTeamId, uniqueId, matchNumber, data } = record;
        const isExpanded = expandedKeys.includes(uniqueId) ? false : true;
        const p_number = data.filter(d => d.matchTypeId === practiceMatchType.matchTypeId).length;
        const o_number = data.filter(d => d.matchTypeId === officialMatchType.matchTypeId).length;
        if (isExpanded) {
          const result = data.map(d => {
            const { matchTypeId, startTime, matchId, startIsoString } = d || {};
            const formattedTime = startTime ? parseTimeAndDate(startIsoString, time_zone) : "Not set";
            // if (matchTypeId === practiceMatchType.matchTypeId) {
            // return <ItemWrapper key={matchId}><span>{data?.panelName ? '-' : data?.panelName}</span></ItemWrapper>
            // } else if (matchTypeId === officialMatchType.matchTypeId) {
            return <ItemWrapper key={matchId}><span className='elipsis'>{d?.panelName ? d.panelName : '-'}</span></ItemWrapper>
            // }
          })
          return <div className="referees-times-wrapper table-custom-row">{result}</div>
        }
      }
    },
    {
      title: t('statusLabel'),
      dataIndex: 'matchStatus',
      key: 'matchStatus',
      render: (val, row) => {
        const { matchTeamId, uniqueId, data } = row;
        const isExpanded = expandedKeys.includes(uniqueId) ? false : true;
        if (isExpanded) {
          const result = data.map(d => {
            const { matchStatusId, matchId } = d;
            const sessionStatus = matchStatuses.find(s => s.statusId === matchStatusId) || NotSetStatus;
            const { badgeType, statusName } = sessionStatus;
            return <ItemWrapper key={matchId}><StatusBadge badgeType={badgeType} statusText={t(statusName)} key={matchId} /></ItemWrapper>
          })
          return <div className="referees-status-wrapper expanded table-custom-row">{result}</div>
        } else {
          return null;
        }

      },
    },
    {
      title: t('scoresheetLabel'),
      dataIndex: 'scoresheet',
      key: 'scoresheet',
      width: 80,
      render: (val, row) => {
        const { matchTeamId, uniqueId, data } = row;
        const isExpanded = expandedKeys.includes(uniqueId) ? false : true;
        if (isExpanded) {
          const result = data.map(d => {
            const { matchId } = d;
            return <ItemWrapper key={matchId}><Link to={`/event-overview/${eventId}/scoresheet/${matchId}`} href={`/event-overview/${eventId}/scoresheet/${val}`} className='paragraph--blue paragraph--normal'>View</Link></ItemWrapper>
          })
          return <div className="referees-times-wrapper table-custom-row">{result}</div>
        }
        // else {
        //   return null
        // }
      }
    },
    {
      title: <div className='score-label-title'>{t('scoreLabel')}</div>,
      dataIndex: 'matchScore',
      key: 'matchScore',
      width: 80,
      fixed: 'right',
      render: (val, row) => {
        const { matchTeamId, uniqueId, data } = row;
        const isExpanded = expandedKeys.includes(uniqueId) ? false : true;
        if (isExpanded) {
          const result = data.map(d => {
            const { matchTotalScore, matchId } = d;
            return <ItemWrapper key={matchId}><div >{matchTotalScore >= 0 ? matchTotalScore : '-'}</div></ItemWrapper>
          })
          return <div className="referees-scores-wrapper table-custom-row">{result}</div>
        }
        // else {
        //   return <div className="referees-scores-wrapper"></div>
        // }
      }
    }
  ];



  const onTableScroll = (e) => {
    const { scrollWidth, clientWidth, scrollLeft } = (e || {}).target || {};
    const trackWidth = ((tracker || {}).current || {}).clientWidth;
    const thumbWidth = ((thumb || {}).current || {}).clientWidth;
    const maxMovement = scrollWidth - clientWidth;
    const maxTransition = trackWidth - thumbWidth;
    setTransition(scrollLeft / maxMovement * maxTransition);
  }

  const scrollbar = () => {
    const activeColor = '#4B4B4B';
    const inActiveColor = '#C6C4C6';
    const reachingLeft = transition === 0;
    const reachingRight = transition + ((thumb || {}).current || {}).clientWidth === ((tracker || {}).current || {}).clientWidth;
    return (
      <div className="scrollbar">
        <Icon type="left" style={{ fontSize: '7.5px', color: reachingLeft ? inActiveColor : activeColor }} />
        <div className="scrollbar-wrapper" ref={tracker}>
          <div className="scrollbar-thumb" style={{ transform: `translate(${transition}px)` }} ref={thumb} />
        </div>
        <Icon type="right" style={{ fontSize: '7.5px', color: reachingRight ? inActiveColor : activeColor }} />
      </div>
    )
  }

  const enterTablefullscreen = () => {
    setFullscreen(!fullscreen);
  }

  const toggleCurrentPageSize = () => {
    const showingAll = currentSize > MATCHES_PER_PAGE;
    const size = showingAll ? MATCHES_PER_PAGE : count;
    if (showingAll) {
      props.handlePageChange(prevPage);
    } else {
      setPrevPage(currentPage);
      props.handlePageChange(1)
    }
    setCurrentSize(size);
  }

  const ShowTotalBlock = (total, range) => {
    const hasMore = total > MATCHES_PER_PAGE;
    const showMoreTitle = currentSize > MATCHES_PER_PAGE ? "Show Less" : "Show All";
    return (
      <div className="showing-total-wrapper">
        <div className="text">{`Showing ${range[0]} - ${range[1]} of ${total}`}</div>
        <div className="divider">|</div>
        {hasMore ? <a onClick={toggleCurrentPageSize}>{showMoreTitle}</a> : null}
      </div>
    )
  }


  return (
    <div className='m-t-48'>
      <h2 className='h-2--normal heading--charcoal m-b-24'>{t('assignedMatchesLabel')}</h2>
      <div className='m-b-40'>
        <SessionPaneSelect panelType={'referees'} fullData={(e) => { handlePanelFilter(e) }} onChange={() => { }} panelList={assignedPanels}></SessionPaneSelect>
      </div>
      {/* {assignedPanels.map(p => (
          <div key={p.id} className='m-b-24'>
            <SessionPanelUsers panelName={p.name} users={p.referees} hideAvatar />
          </div>
        ))} */}

      <div>
        {
          fullscreen ?
            <EventModal
              onCancel={enterTablefullscreen}
              visible={fullscreen}
            >
              <>
                <div className="scoring-table-control-row">
                  <div className='buttons-left'>
                    <SearchBar setSearchText={setSearch} searchText={search} placeholder="Search" hideSearchOnType={true} />
                    <Button className="first-button" type="link" onClick={tableViewType == "timeAndDate" ? expandAllRecord : expandAllRecordForTeam}>{t('CollapseAllLabel')}</Button>
                    <Button className="first-button" type="link" onClick={tableViewType == "timeAndDate" ? collapseAllRecord : collapseAllRecordForTeam}>{t('ExpandAllLabel')}</Button>
                  </div>
                  <div className="buttons-right">
                    <Button onClick={enterTablefullscreen} className="fullscreen" type="link"><Icon style={{ fontSize: 20 }} type="fullscreen" /></Button>
                  </div>
                </div>

                <div className='m-t-24 score-table-row-mobile' onScroll={onTableScroll}>

                  <Table
                    loading={isFetchingAssignedMatches}
                    columns={tableViewType == 'teamAndNumber' ? columns : columnsTimeAndData}
                    dataSource={formattedData}
                    scroll={{ x: 970 }}
                    pagination={{
                      pageSize: currentSize,
                      total: formattedData.length,
                      showTotal: ShowTotalBlock,
                    }}
                    className="referee-sessions-table"
                    // rowClassName={(record, index) => index % 2 === 1 ? 'even-row' : 'odd-row'}
                    rowClassName={(record, index) => 'each-row'}
                    footer={scrollbar}
                    onChange={onSortChange}
                  />

                </div>
              </>
            </EventModal>
            :
            <>
              <div className="scoring-table-control-row">
                <div className='buttons-left'>
                  <SearchBar setSearchText={setSearch} searchText={search} placeholder="Search" hideSearchOnType={true} />
                  <Button className="first-button" type="link" onClick={tableViewType == "timeAndDate" ? expandAllRecord : expandAllRecordForTeam}>{t('CollapseAllLabel')}</Button>
                  <Button className="first-button" type="link" onClick={tableViewType == "timeAndDate" ? collapseAllRecord : collapseAllRecordForTeam}>{t('ExpandAllLabel')}</Button>
                </div>

                <div className="buttons-right">
                  <Button onClick={enterTablefullscreen} className="fullscreen" type="link"><Icon style={{ fontSize: 20 }} type="fullscreen" /></Button>
                </div>
              </div>
              <MatchesFilter onChange={(e) => onChangeMatchFilter(e)}></MatchesFilter>

              <div className='sort-section m-t-40 m-b-30'>
                <div className='m-r-10'>Sort by: <span className=''><strong>{t('MatchesTimeandDateLabel')}</strong></span> </div>
                <div className=''><Switch className='custom-switch-session' defaultChecked={false} onChange={(e) => {
                  setTableViewType(e ? 'teamAndNumber' : 'timeAndDate')
                }} /></div>
                <div className='m-l-10'><strong>Team Number and Name</strong></div>
              </div>
              <div className='m-t-24 score-table-row-mobile' onScroll={onTableScroll}>

                <Table
                  loading={isFetchingAssignedMatches}
                  columns={tableViewType == 'teamAndNumber' ? columns : columnsTimeAndData}
                  dataSource={formattedData}
                  scroll={{ x: 970 }}
                  pagination={{
                    pageSize: currentSize,
                    total: formattedData.length,
                    showTotal: ShowTotalBlock,
                  }}
                  className="referee-sessions-table"
                  // rowClassName={(record, index) => index % 2 === 1 ? 'even-row' : 'odd-row'}
                  rowClassName={(record, index) => 'each-row'}
                  footer={scrollbar}
                  onChange={onSortChange}
                />
              </div>
            </>
        }
      </div>
    </div >
  );
};


AssignedScoringSessions.propTypes = {
  assignedScoringSessions: PropTypes.object.isRequired,
  fetchAssignedMatches: PropTypes.func.isRequired,
  handlePageChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { auth: { userInfo: { use_24_hour_format } }, eventOverview: { time_zone }, assignedScoringSessions, eventOverview } = state;

  return {
    time_zone,
    assignedScoringSessions,
    use_24_hour_format
  }
}

export default connect(mapStateToProps, { fetchAssignedMatches, handlePageChange })(AssignedScoringSessions);