import React from 'react';
import PropTypes from 'prop-types';
import EventModal from 'shared/components/EventModal';
import Container from 'shared/components/Container';
import FirstText from 'shared/components/FirstText';
import ModalContent from './EditPartnerModalContent';

const EditOrganizationModal = props => {
  const { onCancel, visible } = props;

  return (
    <EventModal
      visible={visible}
      onCancel={onCancel}
      renderHeader={() => (
        <span>
          Edit
          {' '}
          Partner
        </span>
)}
    >
      <Container>
        <h1>
          Edit
          {' '}
          Partner
        </h1>

      </Container>
      <ModalContent 
        closeModal={onCancel}
      />
    </EventModal>
  );
};

EditOrganizationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

EditOrganizationModal.defaultProps = {
}

export default EditOrganizationModal;