
export default class UserEventRoleData {
  constructor(responseData, responseIncluded = []) {
    this.eventRoleAssignmentId = responseData.id;
    this.userId = responseData.relationships.user.data.id;
    this.roleId = responseData.relationships.role.data.id;
    this.eventId = responseData.relationships.event.data.id;
    this.status = responseData.attributes.status;
    this.verificationCodeTimeout = responseData.attributes.verification_code_timeout;
    this.teams = responseData.relationships.teams.data
    this.included = responseIncluded || [];
  }

  getUserData = () => {
    const userInfo = this.included.find(d => d.id === this.userId);
    return userInfo;
  }

  formatUserData = () => {
    const userInfo = this.getUserData();
    if (!userInfo) return undefined;
    
      const formattedUserData = {
        email: userInfo.attributes.email,
        firstName: userInfo.attributes.first_name,
        lastName: userInfo.attributes.last_name,
      }
      return formattedUserData;
  }
  
  userFullNameString = () => {
    const userData = this.formatUserData();
    if (!userData) return undefined;
    return  `${userData.firstName} ${userData.lastName}`;
  }

  userEmailString = () => {
    const userData = this.formatUserData();

    if (!userData) return undefined;

    return  userData.email;
  }

  getEventsIds = () => {
    const eventsList = [];
    this.included.forEach(i => {
      if (i.type === 'event') {
        eventsList.push(i.id)
      }
    })
    return eventsList;
  }

  getTeamsIds = () => {
    const teamsList = [];
    this.included.forEach(i => {
      if (i.type === 'team') {
        teamsList.push(i.id)
      }
    })
    return teamsList;
  }

}