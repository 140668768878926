import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import SearchBar from 'features/AdminDashboard/components/SearchBar';
import Pagination, { getPageFilter } from 'shared/components/Pagination';
import TeamsList from './MyTeams/TeamsList';
import '../styles/VolunteerDashboard.scss';
import { useTranslation } from 'react-i18next';

const TEAMS_PAGE_SIZE = 6;

const MyTeams = (props) => {
  const { loading, count, teams, included, fetchTeams, sortBy} = props;
  const { t } = useTranslation();
  const [current, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');

  const fetchPageTeams = useCallback((number) => {
    const page = getPageFilter(number, TEAMS_PAGE_SIZE);
    fetchTeams(search, page, sortBy);
  }, [search, sortBy]);

  useEffect(()=>{
    setCurrentPage(1);
  },[search])

  useEffect(()=>{
    let timeout = setTimeout(() => {
        fetchPageTeams(current)
      }, 500);
    return ()=>clearTimeout(timeout);
  },[search, sortBy, current])

  return (
    <>
      <div className="volunteer-dashboard__search-bar-left">
        <SearchBar
          placeholder={t('teamNameLabel')}
          isLinkVisible={false}
          searchText={search}
          setSearchText={setSearch}
        />
      </div>
      <section className='volunteer-dashboard__teams'>
        <div>
          <TeamsList teams={teams} included={included} loading={loading} />
          <Pagination
            total={count}
            current={current}
            onChange={setCurrentPage}
            pageSize={TEAMS_PAGE_SIZE}
            loading={loading}
          />
        </div>
      </section>
    </>
  );
};

MyTeams.propTypes = {
  teams: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  included: PropTypes.array.isRequired
};

export default MyTeams;
