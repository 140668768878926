const truncateString = (str, maxLength = 50) => {

  const finalString = (str.length > maxLength) ? `${str.substr(0, maxLength - 1)}...` : str

  return finalString
}

export default truncateString;

export const addEllipsisMiddle = (str, maxLength = 40, startIndex = 15, endIndex = 23) => {
  if (!str) {
    return str;
  }
  if (str.length > maxLength) {
    return `${str.substr(0, startIndex)}...${str.substr(str.length-endIndex, str.length)}`
  }

  return str
}

