import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { connect } from 'react-redux';
import * as userTypes from 'shared/constants/userTypes';
import * as eventRoleTypes from 'shared/constants/eventRoleTypes';
import '../../../styles/ScheduleContainer/EventScheduleSection.scss';
import { getScheduleMeetingSessions } from 'features/EventContainer/redux/actions/scheduleActions/scheduleMeeting';
import MeetingSessionModal from './MeetingSessionModal';
import ScheduleAccordion from '../ScheduleAccordion';
import MeetingSessions from './MeetingSessions';
import OverlayLoading from 'shared/components/Spinner/OverlayLoading';


const EventScheduleSection = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation()
  const {
    scheduleId,
    meetingSectionData,
    fetchMeetingSessions,
    userEventRoleIds,
    isFetchingMeetingSection,
    isLoadingMeetings,
    userType,
    data
  } = props;

  const closeModal = () => {
    setModalVisible(false);
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const canEditSchedule = userType === userTypes.FIRST_ADMIN ||
    userType === userTypes.PARTNER_ADMIN ||
    userEventRoleIds.includes(eventRoleTypes.EVENT_ADMIN_ID);
  return (
    <div className='event-schedule-section'>
      <MeetingSessionModal visible={modalVisible} onCancel={closeModal} title={t('newMeetingLabel')} />
      <h3 className='h-3 h--400'>{t('meetingsLabel')}</h3>
      <div className='event-schedule-section__cards'>
        <div>
          <ScheduleAccordion disabled={isFetchingMeetingSection || isLoadingMeetings} data={data} sessionsCount={data.length} panelTitle={t('generalMeetingsLabel')}>
            <MeetingSessions fetchMeetingSessions={fetchMeetingSessions} scheduleId={scheduleId}/>
          </ScheduleAccordion>
        </div>
        {canEditSchedule ? (
          <div className='event-schedule-section__new-meeting'>
            <Button type='link' icon={<PlusOutlined />} onClick={openModal} className='link--large'>
              {t('newMeetingLabel')}
            </Button>
          </div>
        ) : (null)}
      </div>
    </div>
  );
};

EventScheduleSection.propTypes = {
  meetingSectionData: PropTypes.array,
  fetchMeetingSessions: PropTypes.func.isRequired,
  userEventRoleIds: PropTypes.array.isRequired,
  userType: PropTypes.string.isRequired,
};

EventScheduleSection.defaultProps = {
  meetingSectionData: [],
}

const mapStateToProps = (state) => {
  const {
    auth: { userType },
    event: {
      eventSchedule: {isFetchingMeetingSection, meetingSectionData, meetings: {isLoadingMeetings}},
      eventDetails
    } } = state;
  const {
    userEventRoleIds,
  } = eventDetails
  return {
    meetingSectionData,
    userEventRoleIds,
    userType,
    isFetchingMeetingSection,
    isLoadingMeetings
  }
}

const mapDispatchToProps = {
  fetchMeetingSessions: getScheduleMeetingSessions
}

export default connect(mapStateToProps, mapDispatchToProps)(EventScheduleSection);