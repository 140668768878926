import log from 'loglevel';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import version from '../package.json';
import './index.scss';
import App from './App';
import { dynamicConfigUrl } from './configuration/config';
import ConfigProvider from './configuration/useConfig';
import axios from 'axios';

/*
    Read the config.json here for configuration of Sentry
*/

let configUrl = window.location.protocol + '//' + window.location.host + '/' + dynamicConfigUrl;

axios
  .get(configUrl)
  .then((response) => {
    // only init sentry if uat, staging, or production
    if (response.data.REACT_APP_ENVIRONMENT !== 'development') {
      log.setDefaultLevel(log.levels.INFO);
      log.info(`Initialize Sentry for ${response.data.REACT_APP_ENVIRONMENT}`);
      Sentry.init({
        dsn:
          'https://942bbbb34e544421911312c24d90311c@o4504610903097344.ingest.sentry.io/4504610919874560',
        integrations: [new BrowserTracing()],
        release: `first@${version}`,
        tracesSampleRate: 0.3,
        environment: response.data.REACT_APP_ENVIRONMENT, //  update with environment from pipeline variables
      });
    } else {
      log.setDefaultLevel(log.levels.DEBUG);
      log.info(`Skip initialization of Sentry for ${response.data.REACT_APP_ENVIRONMENT}`);
    }
    return (
      <ConfigProvider>
        <App />
      </ConfigProvider>
    );
  })
  .catch((ex) => {
    log.error(`Exception while fetching global config ${ex.message}`);
    return <p style={{ color: 'red', textAlign: 'center' }}>Error while fetching global config</p>;
  })
  .then((reactElement) => {
    log.info('Initial render');
    ReactDOM.render(reactElement, document.getElementById('root'));
  });
