/* eslint-disable import/prefer-default-export */
import checkEmail from './checkEmail';
import checkPhone from './checkPhone';

const validateRegion = () => {

  return [];
}

const validateCountry = () => {

  return []
}


const checkRequiredFields = (coach, line, withCoach) => {
  const errorsArray = [];
  if(withCoach){
    if(!coach.CoachEmail) {
      errorsArray.push({message: `Email address missing, line ${line}`})
    }
    if(!coach.CoachFirstName) {
      errorsArray.push({message: `First name missing, line ${line}`})
    }
    if(!coach.CoachLastName) {
      errorsArray.push({message: `Last name missing, line ${line}`})
    }
  }
  if(!coach.TeamCountry) {
    errorsArray.push({message: `Country missing, line ${line}`})
  }
  if(!coach.TeamRegion) {
    errorsArray.push({message: `Region missing, line ${line}`})
  }
  if(!coach.TeamName) {
    errorsArray.push({message: `Team Name missing, line ${line}`})
  }
  if(!coach.TeamNumber) {
    errorsArray.push({message: `Team Number missing, line ${line}`})
  }
  
  return errorsArray;

}

export const validateImportedCoach = (coach, line, withCoach) => {

  const requiredFieldsErrors = checkRequiredFields(coach, line, withCoach);
  const emailErrors = withCoach ? checkEmail(coach.CoachEmail, line) : [];

  const phoneErrors = checkPhone(coach.CoachPhone, line);
  
  const countryErrors = validateCountry();

  const regionErrors = validateRegion();

  const totalErrors = [...requiredFieldsErrors, ...emailErrors, ...phoneErrors, ...countryErrors, ...regionErrors];

  return totalErrors;


}