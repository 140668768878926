import * as userService from 'utils/services/users';
import { PARTNER_ADMIN } from 'shared/constants/userTypes';
import * as types from '../types';

const getPartnerAdminDetailsBegin = () => {
  return {
    type: types.GET_PARTNER_ADMIN_DETAILS_BEGIN
  }
}

const getPartnerAdminDetailsError = () => {
  return {
    type: types.GET_PARTNER_ADMIN_DETAILS_ERROR
  }
}

const getPartnerAdminDetailsSuccess = (details) => {
  return {
    type: types.GET_PARTNER_ADMIN_DETAILS_SUCCESS,
    payload: {
      details
    }
  }
}

const getPartnerAdminDetails = () => {
  return async (dispatch, getState) => {
    const { auth: { userType, userId } } = getState();
    if (userType !== PARTNER_ADMIN) {
      await dispatch(getPartnerAdminDetailsError())
    } else {
      await dispatch(getPartnerAdminDetailsBegin())
      try {
        const partnerAdminDetails = await userService.getPartnerAdminDetails(userId);
        const { details } = partnerAdminDetails;
        await dispatch(getPartnerAdminDetailsSuccess(details))
      } catch (err) {
        await dispatch(getPartnerAdminDetailsError())
        throw err;
      }
    }
  };
};

export default getPartnerAdminDetails;
