import { notification } from 'antd';
import * as types from '../../types/teamsTypes';
import * as teamService from 'utils/services/teams';
import _ from 'lodash';
import { handleInviteAllTeamsWithoutCoaches, handleResetSelectedTeamDetails, inviteTeamToEvent, setSelectedTeam, setSelectedTeamDetails } from './manually';

const createNewTeamBegin = () => {
  return {
    type: types.CREATE_NEW_TEAM_BEGIN,
    payload: {
      isCreatingNewTeam: true
    }
  };
};

const createNewTeamError = () => {
  return {
    type: types.CREATE_NEW_TEAM_ERROR,
    payload: {
      isCreatingNewTeam: false
    }
  };
};

const createNewTeamSuccess = () => {
  return {
    type: types.CREATE_NEW_TEAM_SUCCESS,
    payload: {
      isCreatingNewTeam: false
    }
  };
};

const updateSelectedCoach = (fieldName, value) => {
  return {
    type: types.UPDATE_SELECTED_COACH,
    payload: {
      fieldName, value
    }
  };
};

export const handleCreateNewTeam = (teamPayload, cb) => {
  return async (dispatch, getState) => {
    const { event: { eventTeams: { selectedCoach } } } = getState();
    try {
      await dispatch(createNewTeamBegin())
      const formattedTeam = await teamService.createNewTeam(teamPayload);
      const updatedTeams = [...selectedCoach.teams, formattedTeam];
      await dispatch(updateSelectedCoach('teams', updatedTeams))
      notification.success({
        message: 'Success',
        description: 'New team successfully created.',
      });
      cb();
      await dispatch(createNewTeamSuccess())
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error creating the new team',
      });
      await dispatch(createNewTeamError())
      throw err
    }
  };
};

export const handleAddNewTeamToEvent = (teamPayload, newTeam = true, cb, successMsg = '', failedMsg = '') => {
  return async (dispatch, getState) => {
    try {
      if (newTeam) {
        await dispatch(createNewTeamBegin())
        const formattedTeam = await teamService.createNewTeamWithoutFormat(teamPayload);
        await dispatch(setSelectedTeam(formattedTeam.teamId));
        await dispatch(setSelectedTeamDetails(formattedTeam));
      }
      const { event: { eventTeams: { selectedTeamDetails } } } = getState();
      await dispatch(inviteTeamToEvent(selectedTeamDetails, () => { }, false));
      notification.success({
        message: 'Success',
        description: 'Team successfully added.',
      });
      cb();
      await dispatch(createNewTeamSuccess())
    } catch (err) {

      notification.error({
        message: 'Error',
        description: err.status === 409 ? successMsg : 'Error sending invitation',
      });
      await dispatch(createNewTeamError())
      throw err
    }
  };
};

export const updateAddedTeamList = (teams) => {
  return {
    type: types.SET_ADDED_TEAM_LIST,
    payload: teams
  }
}

export const setAddedTeamList = () => async (dispatch, getState) => {
  try {
    const { event: { eventTeams: { selectedTeamDetails, addedTeamList } } } = getState();
    const teamId = selectedTeamDetails?.teamId;
    const newTeams = teamId ? [...addedTeamList, selectedTeamDetails] : addedTeamList;
    const uniqTeams = _.uniqBy(newTeams, 'teamId');
    await dispatch(updateAddedTeamList(uniqTeams));
    await dispatch(handleResetSelectedTeamDetails())
  } catch (err) {
    throw err;
  }
}