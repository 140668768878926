import { API } from 'utils/api';
import { queryStringify } from 'utils/query';
import { FIRST_ADMIN, PARTNER_ADMIN } from 'shared/constants/userTypes';
import {
  formatNewUserPayload,
  formatNewAccountPayload,
  formatAssignRolePayload,
  formatVerifyInvitePayload,
  formatSendResetPayload,
  formatChangePassPayload,
  formatUserResData,
  formatPatchUserPayload,
  formatResendUserInvitation,
  formatPatchPartnerAdminPayload
} from './model';
import { getOrganizationsBy, getOrganizationById, getAllOrganizationsByIds } from '../organizations';

//const { http } = API;
const baseUrl = '/api/users';

export const getUsersBy = async (filter) => {
  const queryStr = queryStringify({ filter });
  const url = `${baseUrl}${queryStr}`;
  const { data, included } = await API.GetInstance().http.get(url);
  return {
    data,
    included: included || [],

  };
};

export const getInvitationLink = async (userId, eventId) => {
  const url = `${baseUrl}/${userId}/event/${eventId}/invitation_link`;
  const res = await API.GetInstance().http.get(url);
  return res
};


export const getUserBySortFilter = async (filter, page, sort) => {
  const sortQuery = sort ? `&sort=${encodeURIComponent(sort)}` : '';
  const queryStr = queryStringify({ filter, page });
  const { 
    data, 
    included, 
    meta } = await API.GetInstance().http.get(`${baseUrl}${queryStr}${sortQuery}`);
    return {
      data,
      included: included || [],
      meta
    }
}
export const getUserDetailsByEmail = async (email) => {
  const filter = { email: `eq:${email}` };
  const { data, included } = await getUsersBy(filter);
  return {
    data,
    included: included || []
  };
};

export const getUserByEmail = async (email) => {
  const { data } = await getUserDetailsByEmail(email);
  return data;
};

export const getUsersByFilter = async (filter, page, detail, sort) => {
  try {
    const { 
      data, 
      included, 
      meta } = await getUserBySortFilter(filter, page, sort);
    const users = data.map(formatUserResData);
    if (detail) {

      let partnerAdminOrganizations = [];
     
      const organizationIds = included
        .filter(({ type }) => type === 'organization')
        .map(({ id }) => id)
        .join(',');

     
      if (organizationIds) {
        partnerAdminOrganizations = await getOrganizationsBy({
          id: `in:${organizationIds}`
        });
      }
    
        users.forEach(user => {
          if (user.organizationIds && Array.isArray(user.organizationIds)) {
            const userPartnerOrganizations = [];
            const userRegions = []
            user.organizationIds.forEach(orgId => {
              const organization = partnerAdminOrganizations.find((org) => org.id === orgId);
              userPartnerOrganizations.push(organization);
          
              organization.programs.forEach(orgProgram => {
                userRegions.push(orgProgram)
              })
         
              Object.assign(user, {
                organization: organization.name,
                partnerOrganizationsList: userPartnerOrganizations.map(p => { return {name: p.name, organizationId: p.id}}),
                partnerOrganizationNames: userPartnerOrganizations.map(({ name }) => name).join(', '),
                regions: userRegions.map(({ name }) => name).join(', ')
              })
            })
      
          }
        });
    }
    return { users, count: meta && meta.count };
  } catch (err) {
    console.error(err)
    return { users: [], count: 0 };
  }
};

export const getUsersLikeEmail = async (email, size = 25) => {
  if (!email) return [];
  const filter = { email: `like:${email}` };
  const page = { size, number: 1 };
  const { users } = await getUsersByFilter(filter, page);
  return users;
};

export const getUsersByUserType = async (userType, page, detail) => {
  const filter = { user_type: `eq:${userType}` };
  return await getUsersByFilter(filter, page, detail);
};

export const verifyUserExist = async (email) => {
  try {
    const data = await getUserByEmail(email);
    if (data.length) {
      const userId = data[0].id;
      return userId;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const getUserIdAndType = async (email) => {
  try {
    const data = await getUserByEmail(email);
    if (data.length) {
      const userId = data[0].id;
      const userType = data[0].attributes.user_type
      return {
        userId,
        userType
      }
    }
    return {};
  } catch (err) {
    return {};
  }
};

export const createNewUser = async (user, organizationId) => {
  const body = formatNewUserPayload(user, organizationId);
  const { data } = await API.GetInstance().http.post(baseUrl, body);
  return data;
};

export const createPartnerAdmin = async (user, orientationId) => {
  return await createNewUser(
    { ...user, user_type: PARTNER_ADMIN },
    orientationId
  );
};

export const createFirstAdmin = async (user) => {
  return await createNewUser({ ...user, user_type: FIRST_ADMIN });
};

export const updateUser = async (id, user) => {
  const body = formatPatchUserPayload(id, user);
  const { data } = await API.GetInstance().http.patch(`${baseUrl}/${id}`, body);
  return data;
};

export const updatePartnerUser = async (id, user, organizationIds) => {
  const body = formatPatchPartnerAdminPayload(id, user, organizationIds);
  const { data } = await API.GetInstance().http.patch(`${baseUrl}/${id}`, body);
  return data;
}

export const acceptUserTerms = async (id) => {
  return await updateUser(id, {
    has_accepted_terms: true
  });
};

export const getOrCreateUserId = async (user, organizationId) => {
  // check if it is already a user in the system
  let userId = await verifyUserExist(user.email);
  if (!userId) {
    // if the selected user is not an existing user add the user to the db
    // get the user's id and use the id for the subsequent api requests
    const newUser = await createNewUser(user, organizationId);
    userId = newUser && newUser.id;
  }else{
    await updateUser(userId, user);
  }
  return userId;
};

export const getUsersByIds = async (usersIds) => {
  if (usersIds) {
    const url = `${baseUrl}?filter[id]=in:${usersIds}&page[size]=500&page[number]=1`;
    const response = await API.GetInstance().http.get(url);
    const { data } = response;
    return data.map(formatUserResData);
  }
  return {
    data: [],
  };
};

export const verifyInvitation = async (payload) => {
  const body = formatVerifyInvitePayload(payload);
  const { data } = await API.GetInstance().http.post(`${baseUrl}/commands`, body);
  return data;
};

export const createNewAccount = async (payload) => {
  const body = formatNewAccountPayload(payload);
  const { data } = await API.GetInstance().http.post(`${baseUrl}/commands`, body);
  return data;
};

export const assignUserRole = async (payload) => {
  try {
    const body = formatAssignRolePayload(payload);
    const { data } = await API.GetInstance().http.post(`${baseUrl}/commands`, body);
    return data;
  } catch (e) {
    return false;
  }
};

export const sendResetPassEmail = async (payload) => {
  const body = formatSendResetPayload(payload);
  const { data } = await API.GetInstance().http.post(`${baseUrl}/commands`, body);
  return data;
};

export const changePassword = async (payload) => {
  const body = formatChangePassPayload(payload);
  const { data } = await API.GetInstance().http.post(`${baseUrl}/commands`, body);
  return data;
};

export const getPartnerAdminDetails = async (userId) => {
  const response = await API.GetInstance().http.get(`${baseUrl}/${userId}`);
  const { included } = response;
  const includedArr = included || [];
  const partnerOrganizationIds = includedArr.filter(({ type }) => type === 'organization').map(i => i.id);

  const organizationsRes = await getAllOrganizationsByIds(partnerOrganizationIds);

  const regionsList = [];
  organizationsRes.forEach(org => {

    org.programs.forEach(p => {
      regionsList.push({
      programId: p.programId,
      regionId: p.id,
      regionName: p.name
    })
  
  });

  })
  return {
    details: regionsList
  };
};

export const getUserIdTypeDetails = async (email) => {
  try {
    const { data, included } = await getUserDetailsByEmail(email);
    if (data.length) {
      const userData = formatUserResData(data[0]);
      const { userType } = userData;
      if (userType === PARTNER_ADMIN) {
       
        const partnerOrganizationIds = included.filter(({ type }) => type === 'organization').map(i => i.id);

        const organizationsRes = await getAllOrganizationsByIds(partnerOrganizationIds);
    
        const partnerRegionsPrograms = []
        
        organizationsRes.forEach(org => {

          org.programs.forEach(p => {
            partnerRegionsPrograms.push({
            programId: p.programId,
            regionId: p.id,
            regionName: p.name
          })
        
        });

        })

        return {
          ...userData,
          partnerRegionsPrograms
        };
      }
      return userData;
    }
    return {};
  } catch (err) {
    return {};
  }
};


export const reinviteUser = async (userId) => {
  const body = formatResendUserInvitation(userId);
  const { data } = await API.GetInstance().http.post(`${baseUrl}/commands`, body);
  return data;
}

export const deleteUser = async (userId) => {
  const url = `${baseUrl}/${userId}`;
  const res = await API.GetInstance().http.delete(url);
  return res;
}

export const removeOrganizationsFromUser = async (userId, organizationIds) => {
  const url = `${baseUrl}/${userId}/relationships/organizations`;
  const payload = {
    data: organizationIds.map(orgId =>  { return {type: 'organization', id: orgId} })
  }
  const res = await API.GetInstance().http.delete(url, {data: payload});
  return res;
}
