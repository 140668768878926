const m16Section = {
  id: 'm16',
  name: '2022ScoresheetM16CargoConnectLabel',
  fields: [
    {
      scoring: '2022ScoresheetM16Sub1Label',
      id: 'm16_scoring_1',
      options: [
        {
          description: '0',
          value: 0
        },
        {
          description: '1',
          value: 1
        },
        {
          description: '2',
          value: 2
        },
        {
          description: '3',
          value: 3
        },
        {
          description: '4',
          value: 4
        },
        {
          description: '5',
          value: 5
        },
        {
          description: '6',
          value: 6
        },
        {
          description: '7',
          value: 7
        },
        {
          description: '8',
          value: 8
        },
      ]
    },
    {
      scoring: '2022ScoresheetM16Sub2Label',
      id: 'm16_scoring_2',
      options: [
        {
          description: '0',
          value: 0
        },
        {
          description: '1',
          value: 1
        },
        {
          description: '2',
          value: 2
        },
        {
          description: '3',
          value: 3
        },
        {
          description: '4',
          value: 4
        },
        {
          description: '5',
          value: 5
        },
        {
          description: '6',
          value: 6
        },
        {
          description: '7',
          value: 7
        },
        {
          description: '8',
          value: '8'
        },
      ]
    },
    {
      scoring: '2022ScoresheetM16Sub3Label',
      id: 'm16_scoring_3',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        }
      ]
    },
    {
      scoring: '2022ScoresheetM16Sub4Label',
      id: 'm16_scoring_4',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        }
      ]
    },
    {
      scoring: '2022ScoresheetM16Sub5Label',
      id: 'm16_scoring_5',
      options: [
        {
          description: '0',
          value: 0
        },
        {
          description: '1',
          value: 1
        },
        {
          description: '2',
          value: 2
        },
        {
          description: '3',
          value: 3
        },
        {
          description: '4',
          value: 4
        },
        {
          description: '5',
          value: 5
        },
        {
          description: '6',
          value: 6
        }
      ]
    },
  ]
}

export default m16Section;

