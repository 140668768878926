import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../../../styles/EventInvitedList.scss';
import InviteTile from './ImportInviteTile';

const InvitedList = props => {
  const {volunteers, errorsList, coachesNotInvited, withoutCoach} = props;
  const { t } = useTranslation()

  return (
    <div className='event-invited-list-container'>
      <ul className='event-invited-list__errors'>
        {errorsList.map((err, i) => (
          <li key={`${i+1}-error`}>
            <div className='teams-coaches-invite-tile__error-message-row'>
              <span className='paragraph--red paragraph--default paragraph--bold'>{err.text}</span>
            </div>
          </li>
        ))}
      </ul>
      {volunteers.length ? (
        <>
          <p className='paragraph--darkGray paragraph--small'>{withoutCoach ? "These teams have been invited:" : t('peopleHaveBeenInvitedLabel')}</p>
          <div className='event-invited-list'>
            {volunteers.map (v => (
              <InviteTile key={v.id} coach={v} isInvited withoutCoach={withoutCoach}/>
          ))}
          </div>
        </>
      ) : (
        <p className='paragraph--default paragraph--charcoal paragraph--bold'>{withoutCoach ? "No teams were invited:" : "No invitations were successfully sent."}</p>
      )}
      {coachesNotInvited.length ? (
        <div className='m-t-36'>
          <p className='paragraph--darkGray paragraph--small'>{withoutCoach ? "These teams were not invited:" : "These people were not invited:"}</p>
          <div className='event-invited-list'>
            {coachesNotInvited.map (v => (
              <InviteTile key={v.id} coach={v} isInvited  withoutCoach={withoutCoach}/>
                ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

InvitedList.propTypes = {
  volunteers: PropTypes.array,
  errorsList: PropTypes.array,
  coachesNotInvited: PropTypes.array
};

InvitedList.defaultProps = {
  volunteers: [],
  errorsList: [],
  coachesNotInvited: []
}

export default InvitedList;