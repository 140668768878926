import React from 'react';
import PropTypes from 'prop-types';
import PanelCardInperson from './PanelCard/CardInPerson';
import PanelCardRemote from './PanelCard/CardRemote';
import PanelCard2 from './PanelCard';
import { connect } from 'react-redux';
import {
  FLL_CHALLENGE_ID
} from 'shared/constants/programIds';

const PanelsContainer = props => {
  const { panels, eventSessions, eventJudges, panelType, panelTypeId, assignedJudges, isInPerson, eventProgramId } = props;

  return (
    <>
      {(eventProgramId == FLL_CHALLENGE_ID) ? (
        isInPerson ? (
          <div className="panels-cards-wrapper">
            {panels.map((p) => (
              <PanelCardInperson
                isInPerson={isInPerson}
                panel={p}
                key={p.panelId}
                id={p.panelId}
              />
            ))}
          </div>
        ) : (
          <div className="panels-cards-wrapper">
            {panels.map((p) => (
              <PanelCardRemote
                isInPerson={isInPerson}
                panel={p}
                key={p.panelId}
                id={p.panelId}
              />
            ))}
          </div>
        )
      ) : (
        <div className="panels-cards-wrapper">
          {panels.map((p) => (
            <PanelCard2
              isInPerson={isInPerson}
              panel={p}
              key={p.panelId}
              id={p.panelId}
            />
          ))}
        </div>
      )}
    </>
  );
};

PanelsContainer.propTypes = {
  panels: PropTypes.array,
  eventSessions: PropTypes.array,
  eventJudges: PropTypes.array,
  assignedJudges: PropTypes.array,
  panelType: PropTypes.string.isRequired,
  panelTypeId: PropTypes.string.isRequired,
};

PanelsContainer.defaultProps = {
  panels: [],
  eventSessions: [],
  eventJudges: [],
  assignedJudges: []
}

const mapDispatchToProps = {

};


const mapStateToProps = (state) => {
  const { event: { eventVolunteers, eventDetails: { eventProgramId } } } = state;
  return {
    eventVolunteers,
    eventProgramId
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelsContainer);
// export default PanelsContainer;