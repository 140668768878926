export default class PanelModel {

    constructor(panel, type) {
        this.id = panel.id;
        this.name = panel.name;
        this.panelType = panel.panelType.id;
        this.judges = type == 'judge' ? panel.roleAssignments.map(role => ({ type: 'user', id: role.userId })) : [];
        this.referees = type == 'score' ? panel.roleAssignments.map(role => ({ type: 'user', id: role.userId })) : [];
        this.sessions = panel.sessions;
        this.panelTypeName = panel?.panelType?.description
        this.included = [];
    }

    getPanelSessionsIds = () => {
        const panelSessions = this.sessions.map(s => s.id);
        return panelSessions;
    }

    getPanelJudgesIds = () => {
        const panelJudgesIds = this.judges.map(j => j.id);
        return panelJudgesIds;
    }

    getPanelRefereesIds = () => {
        const panelRefereesIds = this.referees.map(j => j.id);
        return panelRefereesIds;
    }


    getPanelTypeName = () => {
        return this.panelDescription
        // const panelType = this.included.find(i => i.id === this.panelType);
        // if (!panelType) {
        //   return undefined;
        // }
        // const panelTypeName = panelType.attributes.description;
        // return panelTypeName;
    }
}