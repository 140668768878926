/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TabsDashboard from 'shared/components/Tabs/PageTabs';
import * as eventRoleTypes from 'shared/constants/eventRoleTypes';
import VolunteersContainer from 'features/AdminDashboard/components/VolunteersContainer';
import TeamsContainer from 'features/AdminDashboard/components/TeamsContainer';
import EventsContainer from 'features/AdminDashboard/components/EventsContainer';
import UsersContainer from 'features/AdminDashboard/components/UsersContainer';
import PartnersContainer from 'features/AdminDashboard/components/PartnersContainer';
import OrganizationsContainer from 'features/AdminDashboard/components/OrganizationsContainer';
import * as userTypes from 'shared/constants/userTypes';
import './styles/AdminDashboard.scss';

const whiteTextClassName = 'paragraph--large paragraph--bold paragraph--white';

const AdminDashboard = (props) => {
  const history = useHistory();
  const { tabId } = useParams();
  const [defaultTabKey, setDefaultTabKey] = useState('events');
  const { t } = useTranslation();


  useEffect(() => {
    if (tabId) {
      setDefaultTabKey(tabId)
    }
  }, [tabId])

  const {
    canAccessAdminDashboard,
    userEventRoles,
    userType
  } = props

  if (!canAccessAdminDashboard) {
    return <p className={whiteTextClassName}>{t('noPermissionLabel')}</p>
  }

  const handleTabClick = (tabKey) => {
    history.replace(`/admin/${tabKey}`);
  }

  // First Admin,  PDP Admin, Event Admin can see the Volunteers and Teams tabs
  const isFirstAdmin = userType === userTypes.FIRST_ADMIN;
  const canViewTeamsTab = isFirstAdmin || userType === userTypes.PARTNER_ADMIN || userEventRoles.includes(eventRoleTypes.EVENT_ADMIN_ID);
  const canViewVolunteersTab = canViewTeamsTab;

  return (
    <TabsDashboard
      onTabClick={handleTabClick}
      activeKey={defaultTabKey}
    >
      <TabsDashboard.TabPane tab={t('eventsLabel')} key='events'>
        <EventsContainer />
      </TabsDashboard.TabPane>
      {canViewTeamsTab ? (
        <TabsDashboard.TabPane tab={t('teamsLabel')} key='teams'>
          <TeamsContainer canEdit={canViewTeamsTab} />
        </TabsDashboard.TabPane>
      ) : (null)}
      {canViewVolunteersTab ? (
        <TabsDashboard.TabPane tab={t('volunteersLabel')} key='volunteers'>
          <VolunteersContainer />
        </TabsDashboard.TabPane>
      ) : (null)}
      {isFirstAdmin ? (
        <TabsDashboard.TabPane tab={t('usersLabel')} key='users'>
          <UsersContainer />
        </TabsDashboard.TabPane>
      ) : (null)}
      {isFirstAdmin ? (
        <TabsDashboard.TabPane tab={t('partnersLabel')} key='partners'>
          <PartnersContainer />
        </TabsDashboard.TabPane>
      ) : (null)}
      {isFirstAdmin ? (
        <TabsDashboard.TabPane tab='Organizations' key='organizations'>
          <OrganizationsContainer />
        </TabsDashboard.TabPane>
      ) : (null)}
    </TabsDashboard>
  );
};

AdminDashboard.propTypes = {
  canAccessAdminDashboard: PropTypes.bool,
  userEventRoles: PropTypes.array,
  userType: PropTypes.string
};

AdminDashboard.defaultProps = {
  canAccessAdminDashboard: false,
  userEventRoles: [],
  userType: ''
};

function mapStateToProps(state) {
  const { auth } = state;
  const { canAccessAdminDashboard, userEventRoles, userType } = auth;
  return {
    canAccessAdminDashboard,
    userEventRoles,
    userType
  }
}

export default connect(mapStateToProps)(AdminDashboard);
