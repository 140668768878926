import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Tabs from 'shared/components/Tabs/ModalTabs';
import { fetchVolunteerEvents, fetchTeams } from 'features/VolunteerDashboard/redux/actions/volunteerDashboard';
import {eventsSortBy, teamsSortBy} from 'shared/constants/sortOptions';
import MyTeams from 'features/VolunteerDashboard/components/MyTeams';
import MyEvents from 'features/VolunteerDashboard/components/MyEvents';
import Dropdown from 'shared/components/Dropdown';
import SearchBar from 'features/AdminDashboard/components/SearchBar';
import './styles/VolunteerDashboard.scss';


const { Option } = Dropdown;
const whiteTextClassName = 'paragraph--large paragraph--bold paragraph--white';
const My_Events_Key = "#my-events";
const My_Teams_Key = "#my-teams";

const VolunteerDashboard = (props) => {
  const { eventsList, isFetchingTeams, isFetchingEvents, teamsData, teamsIncluded, canAccessVolunteerDashboard } = props;
  const { t, i18n } = useTranslation();
  const [activeKey, setActiveKey] = useState(My_Events_Key);
  const [sortBy, setSortBy] = useState('');
  const direction = i18n.dir();

  if (!canAccessVolunteerDashboard) {
    return (
      <p className={whiteTextClassName}>
        {t('noPermissionLabel')}
      </p>
    );
  }

  const handleClick = (tabKey) =>{
    if(tabKey !== activeKey){
      setActiveKey(tabKey);
      setSortBy('');
    }
  }

  const onSelect = (value) =>{
    setSortBy(value);
  }

  const customRenderTabBar = (props, DefaultTabBar) => {
    return (
      <DefaultTabBar {...props} direction={direction}/>
    )
  }

  const SortComponent = (props)=>{
    const {activeKey} = props;
    const fieldOptions = activeKey === My_Events_Key ? eventsSortBy : activeKey === My_Teams_Key ? teamsSortBy : [];
    return (
      <Dropdown showSearch optionFilterProp='children' placeholder={t('sortByLabel')} additionalClassNames='dropdown--240 mobile-compatible' onChange={onSelect} value={sortBy?sortBy:undefined}>
        {fieldOptions.map(f => (
          <Option value={f.value} key={f.value}>{f.description}</Option>
        ))}
      </Dropdown>
    )
  }

  return (
    <div className='volunteer-dashboard'>
      <Tabs  overrides={
        {
          onChange: handleClick,
          renderTabBar: customRenderTabBar,
          tabBarExtraContent: <SortComponent activeKey={activeKey}/>
        }
      }>
      <Tabs.TabPane tab={t('myEventsLabel')} key="#my-events">
        <MyEvents
          sortBy={sortBy}
          events={eventsList}
          loading={isFetchingEvents}
          count={props.eventsCount}
          fetchEvents={props.fetchVolunteerEvents}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('myTeamsLabel')} key="#my-teams">
        <MyTeams
          sortBy={sortBy}
          teams={teamsData}
          included={teamsIncluded}
          count={props.teamsCount}
          fetchTeams={props.fetchTeams}
          loading={isFetchingTeams}
        />
      </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

VolunteerDashboard.propTypes = {
  eventsList: PropTypes.array,
  isFetchingEvents: PropTypes.bool,
  fetchVolunteerEvents: PropTypes.func,
  teamsData: PropTypes.array,
  teamsIncluded: PropTypes.array,
  canAccessVolunteerDashboard: PropTypes.bool
};

VolunteerDashboard.defaultProps = {
  eventsList: [],
  isFetchingEvents: false,
  fetchVolunteerEvents: () => { },
  teamsData: [],
  teamsIncluded: [],
  canAccessVolunteerDashboard: false
};

function mapStateToProps(state) {
  const { 
    auth, 
    volunteerDashboard: { 
      eventsList, 
      eventsCount, 
      isFetchingEvents, 
      isFetchingTeams, 
      teamsCount, 
      teamsData, 
      teamsIncluded
    }
  } = state;
  const { canAccessVolunteerDashboard } = auth;
  return {
    canAccessVolunteerDashboard,
    eventsList,
    eventsCount,
    isFetchingEvents,
    isFetchingTeams,
    teamsCount,
    teamsData,
    teamsIncluded
  };
}

export default connect(mapStateToProps, { fetchVolunteerEvents, fetchTeams })(VolunteerDashboard);
