import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../../../styles/eventScoringMatch/RefereeScoresheetNotes.scss';
import PlusCircle from 'shared/components/Icon/icons/PlusCircle';
import CommentTile from 'shared/components/CommentTile';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import { connect } from 'react-redux';
import { deleteJudgingRubricComment, deleteJudgingRubricCommentFLLE } from 'features/EventOverviewContainer/redux/actions/judgingRubric'
import JudgingCommentModal from './JudgingCommentModal';
import { useTranslation } from 'react-i18next';

const titleClassName = `h-2 heading--charcoal m-b-8`;
const paragraphClassName = 'paragraph--medium paragraph--charcoal paragraph--normal'


const JudgingComments = props => {
  const [visible, setVisible] = useState(false);
  const [commentId, setCommentId] = useState(undefined);
  const { t } = useTranslation();

  const {
    judgingComments,
    deleteComment,
    deleteFlleComment,
    isUploadedRubricPublishable,
    isUploadedRubricPublished,
    is_FLLC_event
  } = props;

  const openModal = (id) => {
    id && setCommentId(id)
    setVisible(true)
  }

  const closeModal = (id) => {
    id && setCommentId(undefined)
    setVisible(false)
  }

  return (
    <div className='c-rubric-upload__judging-comments__container'>
      <JudgingCommentModal
        visible={visible}
        onCancel={closeModal}
        commentId={commentId}
      />
      <div className='c-rubric-upload__judging-comments__header'>
        <div className='c-rubric-upload__judging-comments__title'>
          <h3 className={titleClassName}>Comments</h3>
          <Button
            onClick={e => openModal(commentId)}
            type='link'
            className='c-rubric-upload__judging-comments__add'
            disabled={isUploadedRubricPublishable && !isUploadedRubricPublished}
          >
            {' '}
            <Icon className='m-r-8' component={PlusCircle} />
            {' '}
            {t('ChallengeRubricsAddCommentLabel')}
          </Button>
        </div>
        <p className={paragraphClassName}>These notes will be shared with teams at the end of the event.</p>
      </div>
      <ul className='c-rubric-upload__judging-comments__notes'>
        {judgingComments.map(c => (
          <li key={c.commentId} className="c-rubric-upload__judging-comments__block">
            <CommentTile
              commentId={c.commentId}
              userId={c.userId}
              deleteClick={() => { is_FLLC_event ? deleteComment(c.commentId) : deleteFlleComment(c.commentId) }}
              commentType='judging'
              fullName={c.fullName}
              comments={c.comment}
              onEdit={openModal}
              disableEdit={isUploadedRubricPublishable}
              dateTime={c.dateTime}
              isEditable={true}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

JudgingComments.propTypes = {
  judgingComments: PropTypes.array,
  deleteComment: PropTypes.func.isRequired,
  deleteFlleComment: PropTypes.func.isRequired,
};
JudgingComments.defaultProps = {
  judgingComments: []
};

const mapStateToProps = (state) => {
  const { eventOverview: { is_FLLC_event }, judgingRubric: {
    judgingRubricComments,
  } } = state;

  return {
    judgingComments: judgingRubricComments,
    is_FLLC_event
  }
}

const mapDispatchToProps = {
  deleteComment: deleteJudgingRubricComment,
  deleteFlleComment: deleteJudgingRubricCommentFLLE
}



export default connect(mapStateToProps, mapDispatchToProps)(JudgingComments);