export const ADD_FIRST_USER_TO_MANUAL_LIST = 'ADD_FIRST_USER_TO_MANUAL_LIST';

export const DELETE_FIRST_USER_FROM_MANUAL_LIST = 'DELETE_FIRST_USER_FROM_LIST';

export const RESET_FIRST_USER_MANUAL_FORM = 'RESET_FIRST_USER_MANUAL_FORM';

export const UPDATE_SELECTED_FIRST_USER = 'UPDATE_SELECTED_FIRST_USER';

export const RESET_FIRST_USERS_MANUAL_LIST = 'RESET_MANUAL_FIRST_USERS_LIST';

export const SET_IS_AUTOCOMPLETE_FIRST_USER_SELECTED = 'SET_IS_AUTOCOMPLETE_FIRST_USER_SELECTED';

export const SET_SELECTED_FIRST_USER = 'SET_SELECTED_FIRST_USER';