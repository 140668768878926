import React from 'react';

const Lock = () => {
  return (
    <svg width="18px" height="20px" viewBox="0 0 18 20" version="1.1">
      <title>CF9334B1-4FFE-4D80-9954-41925B3478C0</title>
      <g id="Events" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Event-Management---Single-Event---Messaging---PDP-Admin" transform="translate(-284.000000, -554.000000)" fill="#231F20">
          <g id="Group-7" transform="translate(250.000000, 523.000000)">
            <g id="Group-21" transform="translate(34.000000, 31.000000)">
              <g id="noun_Lock_747566" transform="translate(0.775068, 0.000000)">
                <path d="M15.9524571,9.99244444 C15.9517714,8.77844444 14.9355429,7.79088889 13.6857143,7.79088889 L13.5830857,7.79088889 L13.5798857,5.59711111 C13.5782857,2.51088889 10.9945143,0 7.81714286,0 C4.64114286,0.00155555556 2.05874286,2.51488889 2.06011429,5.60311111 L2.06331429,7.80955556 C0.930057143,7.93022222 0.0454857143,8.86666667 0.04594268,10 L0.0475428571,17.7984444 C0.0482285714,19.0122222 1.06445714,20 2.31314286,20 L2.31451429,20 L13.6898286,19.9944444 C14.9392,19.9937778 15.9549714,19.0051111 15.9542861,17.7908889 L15.9524571,9.99244444 Z M7.82011429,0.851111111 C10.512,0.851111111 12.7033143,2.98022222 12.7046857,5.598 L12.7083429,8.09266667 L11.6221714,8.09266667 L11.6185143,5.598 C11.6173714,3.56288889 9.9136,1.90711111 7.82034286,1.90711111 L7.81828571,1.90711111 C6.80525714,1.90755556 5.85188571,2.29266667 5.13417143,2.99111111 C4.41622857,3.68955556 4.02125714,4.61666667 4.02171429,5.60222222 L4.02537143,8.09266667 L2.9392,8.10044444 L2.93554221,5.60222222 C2.93417143,2.98377778 5.12411429,0.852444444 7.82011429,0.851111111 Z M10.7428571,5.59888889 L10.7460571,7.79222222 L4.90011429,7.79511111 L4.89691377,5.60133333 C4.89645714,4.84377778 5.20068571,4.13022222 5.75314286,3.59244444 C6.3056,3.05466667 7.03908571,2.75844444 7.81851429,2.758 L7.82011429,2.33244444 L7.82011429,2.758 C9.43108571,2.758 10.7421714,4.03222222 10.7428571,5.59888889 Z M13.6891429,19.1433333 L2.31382857,19.1488889 L2.31314286,19.5744444 L2.31314286,19.1488889 C1.54697143,19.1488889 0.923428571,18.5428889 0.922971429,17.7982222 L0.921142794,9.99977778 C0.920914286,9.25444444 1.54422857,8.64777778 2.31062857,8.64755556 L12.9426286,8.64733333 L12.9426286,8.64222222 L13.6866286,8.642 C14.4528,8.642 15.0763429,9.248 15.0768,9.99266667 L15.0784006,17.7911111 C15.0790857,18.5362222 14.4557714,19.1428889 13.6891429,19.1433333 Z" id="Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};


export default Lock;
