import { Row, Col, Form } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createRefereeNote } from 'features/EventOverviewContainer/redux/actions/matchScoresheet';
import TextBox from 'shared/components/TextBox';
import BtnPrimary from 'shared/components/Button/BtnPrimary';

const FormItem = Form.Item;

const rowProps = {
  type: 'flex',
  gutter: 20,
  className: 'm-t-20'
};

const colProps = {
  xs: 24, md: 16
};

const paragraphClassName = 'paragraph--xlarge paragraph--charcoal paragraph--normal'


const AddRefereeNoteForm = (props) => {
  const {
    onCancel,
    firstName,
    lastName,
    creatingNote,
    callback
  } = props;
  const [form] = Form.useForm();
  const submitCallBack = () => {
    form.resetFields()
    callback && callback()
  }

  const handleSubmit = async(e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields().then(async (values, err) => {
      if (err) {
        throw err;
      }
      const { comment } = values;
      const fullName = `${firstName} ${lastName}`
      props.createRefereeNote(comment, fullName, submitCallBack)
    });
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  return (
    <Form hideRequiredMark onFinish={handleSubmit} onFinishFailed={onFinishFailed} name='CreateNewRefereeNote' form={form}>
      <Row {...rowProps}>
        <Col {...colProps}>
          <p className={paragraphClassName}>
            {firstName}
            {' '}
            {lastName}
          </p>
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col {...colProps}>
          <FormItem name='comment' label={<span>Note</span>}>
            <TextBox 
              size='large' 
              placeholder='Note'
              rows={4}
            />
          </FormItem>
        </Col>
      </Row>
      <Row {...rowProps} justify="end">
        <BtnPrimary loading={creatingNote} htmlType='submit'>Add</BtnPrimary>
      </Row>
    </Form>
  );
};

AddRefereeNoteForm.propTypes = {
  form: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  createRefereeNote: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  creatingNote: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  const {
    auth: {
      userInfo: {
        firstName,
        lastName
      }
    },
    matchScoresheet: {
      isCreatingScoreInputComment
    }

  } = state;
  return {
    firstName,
    lastName,
    creatingNote: isCreatingScoreInputComment
  };
};

const mapDispatchToProps = {
  createRefereeNote
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddRefereeNoteForm);
