import { API } from 'utils/api';
import { queryStringify } from 'utils/query';

//const { http } = API;
const baseUrl = '/api/judge_comments';
const FLLEbaseUrl = '/api/judge_rubric/fll_e';

// export const getJudgeComments

export const createJudgeComment = async (payload) => {
  const url = `${baseUrl}`;
  const {data, included} = await API.GetInstance().http.post(url, payload);
  return {
    data,
    included: included || []
  }
}

export const createJudgeCommentForFLLE = async (payload) => {
  const url = `api/judge_rubric_comments/fll_e`;
  const res = await API.GetInstance().http.post(url, payload);
  return res
}

export const editJudgeComment = async (payload, id) => {
  const url = `${baseUrl}/${id}`;
  const response = await API.GetInstance().http.patch(url, payload);
  return {
    data: response.data,
    included: response.included || []
  }
}

export const editJudgeCommentFLLE = async (payload, id) => {
  const url = `/api/judge_rubric_comments/fll_e/${id}`;
  const response = await API.GetInstance().http.patch(url, payload);
  return response
}

export const getJudgeCommentsBy = async(id) => {
  const url = `/api/judging/fll/${id}/judge_comments`;
  const { data, included } = await API.GetInstance().http.get(url);
  return { data, included: included || [] };
}

export const getJudgeFLLECommentsBy = async (id) => {
  // const url = `/api/judging/fll/${id}/judge_comments`;
  const res = await API.GetInstance().http.get(`${FLLEbaseUrl}/${id}/judge_comments`);
  return res
}


export const deleteJudgeComment = async (judgeCommentId) => {
  const url = `${baseUrl}/${judgeCommentId}`;
  const {data, included} = await API.GetInstance().http.delete(url);
  return { data, included: included || [] };
}

export const deleteJudgeCommentFLLE = async (judgeCommentId) => {
  const url = `/api/judge_rubric_comments/fll_e/${judgeCommentId}`;
  const { data, included } = await API.GetInstance().http.delete(url);
  return { data, included: included || [] };
}