import { Modal } from 'antd';
import CloseOutlined from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import './Modal.scss';

const AddModal = (props) => {
  const {
    onCancel,
    visible,
    noTitle,
    maxWidth = 1160,
    fullTabs = false,
    iconColor = 'white',
    renderHeader,
    renderLogos,
    children,
    extraClass,
    ..._props
  } = props;
  const isXlScreen = useMediaQuery({ query: '(min-width: 1200px)' });

  return (
    (<Modal
      title={!noTitle && (
        <>
          <div className='modal__title-left'>
            {renderHeader()}
          </div>
          <div className='modal__title-right'>
            {renderLogos()}
          </div>
        </>
      )}
      open={visible}
      maskStyle={{
        background: 'rgba(0,0,0,0.65)'
      }}
      width={isXlScreen ? `${maxWidth}px` : '95%'}
      wrapClassName={!noTitle && `modal ${fullTabs && 'modal--full'} ${extraClass ? extraClass : ''}`}
      footer={null}
      destroyOnClose
      onCancel={onCancel}
      // closeIcon={<CloseOutlined />}
      {..._props}
    >
      {children}
    </Modal>)
  );
};

AddModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  renderHeader: PropTypes.func,
  renderLogos: PropTypes.func
};

AddModal.defaultProps = {
  renderHeader: () => null,
  renderLogos: () => null
};

export default AddModal;
