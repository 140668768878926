import { API } from 'utils/api';

//const { http } = API;

const baseUrl = '/api/judge_rubric_export'

export const exportJudging = async (eventId) => {
  if (!eventId) return '';
  const res = await API.GetInstance().http.get(`${baseUrl}/${eventId}`);
  const csvContent = `data:text/csv;charset=utf-8,${res}`;
  const encodedUri = encodeURI(csvContent);
  return encodedUri;
};

export const exportFllChallengeJudging = async (eventId, resultType) => {
  if (!eventId || !resultType) return '';
  const url = `${baseUrl}/${eventId}/${resultType}`;
  // const res = await API.GetInstance().http.get(`${baseUrl}/${eventId}/${resultType}`);
  const res = await API.GetInstance().http.request({
    method: 'get',
    url,
    responseType: 'blob',
  })


  const blob = new Blob([res], { type: 'text/csv', encoding: 'UTF-8' });

  const newUrl = window.URL.createObjectURL(blob);

  return newUrl;
};