import React from 'react';
import { connect } from 'react-redux';

import Modal from 'shared/components/EventModal';
import ModalHeader from './EventModalHeader';

const EventModal = (props) => {
  const { eventProgramId, ..._props } = props;

  return (
    <Modal
      programId={eventProgramId}
      renderHeader={() => <ModalHeader />}
      {..._props}
    />
  );
};

const mapStateToProps = (state) => {
  const { eventOverview: { eventProgramId } } = state;
  return { eventProgramId };
};

export default connect(mapStateToProps)(EventModal);
