import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import {handleScoringTimestamps} from 'features/EventContainer/redux/actions/scheduleActions/scheduleDetails';
import missingFieldValues from 'features/EventContainer/utils/checkMissingSessionData';
import CompleteTimestampCard from '../timestampCards/CompleteTimestampCard';
import '../../../styles/ScheduleContainer/EventScheduleSection.scss';
import ScheduleAccordion from '../ScheduleAccordion';
import ScoringSessions from './ScoringSessions';

const EventScheduleSection = (props) => {
  const { t } = useTranslation()

  const {
    data,
    scheduleId,
    scoringTimestamps,
    getScoringTimestamps,
    isLoadingScoringSessions,
    isLoadingScoringSection
  } = props;

  const hasDetailsNotSet = () => {
    let errorCount = 0;
    
    if (!data.length) {
      return false;
    }
    data.forEach(d => {
      const isMissingData = missingFieldValues(d.startTime, d.endTime, d.endTime);
      if (isMissingData) {
        errorCount += 1;
      

      }
    })

    if (errorCount) {
      return {
        errorCount: true,
        totalIncomplete: errorCount
      }
    }

    return {
      errorCount: false,
      totalIncomplete: errorCount
    };
  }

  const {errorCount, totalIncomplete} = hasDetailsNotSet();

  const modalCallback = async() => {
    await getScoringTimestamps(scheduleId)
  }
  
  return (
    <div className="event-schedule-section">
      <h3 className="h-3 h--400">{t('scoringLabel')}</h3>
      <div className="event-schedule-section__cards">
        <div className="event-schedule-section__cards-section">
          {scoringTimestamps.map((s) => (
            <CompleteTimestampCard
              key={s.id}
              cb={modalCallback}
              data={s}
              iconType="ballot"
              cardType="scoring"
            />
          ))}
        </div>
        <div>
          <ScheduleAccordion
            disabled={isLoadingScoringSessions||isLoadingScoringSection}
            incompleteSessionsCount={totalIncomplete}
            data={data}
            panelTitle={t('refereeReviewSessionsLabel')}
            iconType="score"
            sessionsCount={data.length}
            missingDetails={errorCount}
          >
            <ScoringSessions scheduleId={scheduleId} />
          </ScheduleAccordion>
        </div>
      </div>
    </div>
  );
};

EventScheduleSection.propTypes = {
  data: PropTypes.array,
  scheduleId: PropTypes.string.isRequired,
  scoringTimestamps: PropTypes.array,
  getScoringTimestamps: PropTypes.func,
  userEventRoleIds: PropTypes.array.isRequired,
  userType: PropTypes.string.isRequired,
};

EventScheduleSection.defaultProps = {
  data: [],
  scoringTimestamps: [],
  getScoringTimestamps: () => {}
}


const mapDispatchToProps = {
  getScoringTimestamps: handleScoringTimestamps
}

const mapStateToProps = (state) => {
  const { event: { 
    eventSchedule: {sessions, isLoadingScoringSection}
  }} = state;

  const {
    isLoadingScoringSessions,
  } = sessions;
  return {isLoadingScoringSessions, isLoadingScoringSection };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventScheduleSection);