import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../../../../../features/PanelManagement/styles/JudgingPanelCard.scss';
import { useTranslation } from 'react-i18next';
import { officialRefereeReviewSessionType, practiceRefereeReviewSessionType } from 'shared/constants/timestampTypes';
import _ from 'lodash';
import { Col, Row, Select } from 'antd';
import Icon from 'shared/components/Icon';
import CloseCircle from 'shared/components/Icon/icons/CloseCircle';
import { connect } from 'react-redux';
import { handleDeleteJudgingPanel, handleUpdateJudgingPanel } from "features/EventContainer/redux/actions/judgingActions/editJudgingPanel";

const { Option } = Select;

const TeamAssignments = props => {
    const { t } = useTranslation();

    // eslint-disable-next-line no-unused-vars
    const { panelSessions, isInPerson, data, included, sessionOptions, panelId, panelName, handleDeleteJudgingPanelData, handleUpdateJudgingPanelData } = props;
    const [newAddedTeams, setNewAddedTeams] = useState([])
    const [defaultTeamSelected, setDefaultTeamSelected] = useState("");
    let formattedData = [];

    const groupedData = _.groupBy(data, 'teamId');
    const keys = Object.keys(groupedData);


    keys.forEach(key => {
        const teamData = groupedData[key];
        const teamName = ((teamData || [])[0] || {}).teamName;
        let practiceMatchPanel, match1Panel, match2Panel, match3Panel, matchPanelId;
        let practiceMatchId, match1Id, match2Id, match3Id;
        teamData.forEach(d => {
            const { sessionTypeId, panelName, matchNumber, sessionId, panelId } = d;
            if (sessionTypeId === practiceRefereeReviewSessionType) {
                practiceMatchPanel = panelName;
                practiceMatchId = sessionId;
            } else if (sessionTypeId === officialRefereeReviewSessionType) {
                switch (matchNumber) {
                    case 1:
                        match1Panel = panelName;
                        match1Id = sessionId;
                        matchPanelId = panelId
                        break;
                    case 2:
                        match2Panel = panelName;
                        match2Id = sessionId;
                        matchPanelId = panelId
                        break;
                    case 3:
                        match3Panel = panelName;
                        match3Id = sessionId;
                        matchPanelId = panelId
                        break;
                    default:
                        break;
                }
            }
        })

        formattedData.push({ key: key, teamName, practiceMatchPanel, match1Panel, match2Panel, match3Panel, practiceMatchId, match1Id, match2Id, match3Id, matchPanelId })
    })

    // const filteredTeam = formattedData.filter(d => d.teamName.toLowerCase().includes(search.toLowerCase()))
    const filteredTeam = formattedData

    const getTeams = () => {
        return included.filter((data) => data.type == 'team')
    }

    let allTeams = getTeams()

    // const handleTeamAdd = (teamId) => {
    //     if (teamId == "") {
    //         return
    //     }
    //     let team = allTeams.filter(team => team.id == teamId)

    //     setNewAddedTeams([...newAddedTeams, {
    //         matchNumber: 1,
    //         panelId: panelId,
    //         sessionId: Date.now(),
    //         sessionTypeId: null,
    //         sessionTypeName: null,
    //         teamId: teamId,
    //         teamName: `${team[0].attributes.team_number} - ${team[0].attributes.name}`
    //     }])
    // }

    const handleDeletePanelSession = async (id) => {
        let data = {
            data: [
                {
                    id: id,
                    type: "session"
                }
            ]
        }

        handleDeleteJudgingPanelData(panelId, data)
        // var filtered = combiledPanels.filter((el) => { return el.sessionId != id; });
    }

    const handleUpdatePanelSession = async (id, oldId) => {

        if (id == "") {
            return
        }

        if (oldId != null) {
            handleDeletePanelSession(oldId)
        }


        if (id == 'default') {
            return
        }
        let data = {
            data: {
                attributes: {
                    name: panelName
                },
                id: panelId,
                type: "panel",
                relationships: {
                    judges: { data: [] },
                    sessions: {
                        data: [
                            {
                                id: id,
                                type: "session"
                            }
                        ]
                    }
                }
            }
        }
        setDefaultTeamSelected(id)
        await handleUpdateJudgingPanelData(panelId, data)
        setDefaultTeamSelected("")
        
        // var filtered = combiledPanels.filter((el) => { return el.sessionId != id; });
    }

    const isDisabledAndAssignedToOtherPanel = (panelId, assignedToPanel) => {
        if (assignedToPanel != null && assignedToPanel !== undefined) {
            return true
        }
        return false
    }


    const panelByTeams = _.groupBy(panelSessions, 'teamId');

    const numberOfTeams = Object.keys(panelByTeams).length;
    const subTitle = numberOfTeams === 0 ? t('2022EmptyPanelTeamAssignedLabel') : numberOfTeams === 1 ? t('2022EmptyPanelTeamAssigned3Label', { '0': 1 }) : t('2022EmptyPanelTeamAssigned2Label', { '0': numberOfTeams });
    const className = numberOfTeams === 0 ? "waring-text" : "sub-title";

    const sortedPanelSessions = _.sortBy(panelSessions, ['teamName', 'sessionTypeId', 'matchNumber'])

    // let combiledPanels = [...panelSessions, ...newAddedTeams]

    const sortByName = (data) => {
        let array = [...data]
        return array.sort((a, b) => a.teamName.localeCompare(b.teamName));
    }

    let allTeamsSorted = sortByName(sessionOptions)

    return (
        <div className='judging-panel-card__teams-box'>
            <div>
                {panelSessions.map(session => {
                    return (
                        <Row align="middle" gutter={[16, 16]}>
                            <Col span={1}>
                                <CloseCircle onClick={() => { handleDeletePanelSession(session.sessionId) }} className="m-t-5 cursor"></CloseCircle>
                            </Col>
                            <Col span={8}>
                                {/* <Select style={{ width: '100%' }} defaultValue={session.sessionId} className='m-r-20' onSelect={(e) => { handleUpdatePanelSession(e, session.sessionId) }} suffixIcon={<Icon type='downArrowBlue' />}>
                                    {sessionOptions.map(teamOpt => (
                                        <Option
                                            value={teamOpt.sessionId}
                                            disabled={isDisabledAndAssignedToOtherPanel(panelId, teamOpt.panelId)}>
                                            {`${teamOpt.teamName} (${teamOpt.sessionTypeName})`}
                                            {isDisabledAndAssignedToOtherPanel(panelId, teamOpt.panelId) && (
                                                <>
                                                    <br></br>
                                                    <span className='assigned-to-panel'>Assigned to {teamOpt.panelName}</span>
                                                </>
                                            )}
                                        </Option>
                                    ))}
                                </Select> */}
                                <div className='team-box'>
                                    {allTeamsSorted.map(teamOpt => (
                                        session.sessionId == teamOpt.sessionId && (`${teamOpt.teamName}`)
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    )
                })}


                <Row>
                    <Col span={1}></Col>
                    <Col span={23}>
                        <Select style={{ width: '200px' }}
                            value={defaultTeamSelected}
                            className='panel-select-dropdown m-r-20'
                            onSelect={(e) => {
                                // handleTeamAdd(e)
                                handleUpdatePanelSession(e, null)
                            }}
                            suffixIcon={<Icon type='downArrowBlue' />}>
                            <Option value="">{t('addTeamLabel')}</Option>
                            {allTeamsSorted.map(teamOpt => (
                                <Option
                                    value={teamOpt.sessionId}
                                    disabled={isDisabledAndAssignedToOtherPanel(panelId, teamOpt.panelId)}
                                >
                                    {/* {`${teamOpt.teamName} (${teamOpt.sessionTypeName})`} */}
                                    {`${teamOpt.teamName}`}
                                    {isDisabledAndAssignedToOtherPanel(panelId, teamOpt.panelId) && (
                                        <>
                                            <br></br>
                                            <span className='assigned-to-panel'>Assigned to {teamOpt.panelName}</span>
                                        </>
                                    )}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

TeamAssignments.propTypes = {
    panelSessions: PropTypes.array,
    panelName: PropTypes.string,
    panelId: PropTypes.number,
    data: PropTypes.array,
};

TeamAssignments.defaultProps = {
    panelSessions: [],
    data: [],
    panelId: 0,
}

const mapDispatchToProps = {
    handleDeleteJudgingPanelData: handleDeleteJudgingPanel,
    handleUpdateJudgingPanelData: handleUpdateJudgingPanel
}

const mapStateToProps = (state) => {
    const { event: { eventDetails: { attributes, included }, eventJudging: { sessionOptions } } } = state;

    return {
        attributes,
        sessionOptions,
        included
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TeamAssignments);
// export default TeamAssignments;