/* eslint-disable import/prefer-default-export */
import { notification } from 'antd';
import * as userService from 'utils/services/users';
import * as types from 'features/EventContainer/redux/types/teamsTypes';

const editCoachInfoBegin = () => {
  return {
    type: types.EDIT_TEAM_COACH_INFO_BEGIN
  }
}

const editCoachInfoError = () => {
  return {
    type: types.EDIT_TEAM_COACH_INFO_ERROR
  }
}

const editCoachInfoSuccess = (payload) => {
  return {
    type: types.EDIT_TEAM_COACH_INFO_SUCCESS,
    payload
  }
}

const editCoachInfo = (userId, values) => {
  return async (dispatch) => {
    try {
      await dispatch(editCoachInfoBegin())
      await userService.updateUser(userId, values);
      await dispatch(editCoachInfoSuccess({ userId, values }));
      notification.success({
        message: 'Success',
        description: 'Coach information successfully saved.',
      });
    } catch(err) {
      await dispatch(editCoachInfoError())
      notification.error({
        message: 'Error',
        description: 'Error saving coach information.',
      });
    }
  }
}

export default editCoachInfo
