import { connect } from 'react-redux';
import InviteesForm from 'shared/components/InviteesForm';
import {
  deleteFirstUserFromManualList,
  handleInviteAllFirstUsers
} from 'features/AdminDashboard/redux/actions/usersAction';

const mapStateToProps = ({ adminBoard }) => {
  const { users: { manualFirstUsersList } } = adminBoard;
  return {
    users: manualFirstUsersList
  }
};

const mapDispatchToProps = {
  deleteUser: deleteFirstUserFromManualList,
  handleInviteAllUsers: handleInviteAllFirstUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteesForm);
