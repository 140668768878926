import * as usersTypes from '../types/usersTypes';

const DEFAULT_SELECTED_EDIT_FIRST_USER = {
  id: "",
  userId: "",
  email: "",
  firstName: "",
  lastName: "",
  phone: null
}
const defaultUserState = {
  firstUsers: [],
  count: 0,
  isFetchingUsers: false,
  newUserInvited: false,
  manualFirstUsersList: [],
  selectedFirstUser: {},
  isInvitingFirstUser: false,
  isFirstUserSelected: false,
  isReinvitingFirstUser: false,
  selectedEditUser: DEFAULT_SELECTED_EDIT_FIRST_USER,
  isDeletingFirstUser: false,
  isUpdatingUser: false
};

export default (state = defaultUserState, action) => {
  switch (action.type) {
    case usersTypes.FETCH_FIRST_ADMINS_BEGIN: {
      return {
        ...state,
        isFetchingUsers: true,
        newUserInvited: false
      };
    }
    case usersTypes.FETCH_FIRST_ADMINS_SUCCESS: {
      const { users, count, total } = action.payload;
      return {
        ...state,
        count,
        total,
        firstUsers: users,
        isFetchingUsers: false
      };
    }
    case usersTypes.FETCH_FIRST_ADMINS_ERROR: {
      return {
        ...state,
        isFetchingUsers: false
      };
    }
    case usersTypes.FIRST_USER_INVITE_SUCCESS: {
      return {
        ...state,
        newUserInvited: true
      };
    }
    case usersTypes.ADD_FIRST_USER_TO_MANUAL_LIST: {
      const manualFirstUsersList = [...state.manualFirstUsersList, action.payload];
      return {
        ...state,
        manualFirstUsersList
      };
    }
    case usersTypes.DELETE_FIRST_USER_FROM_MANUAL_LIST: {
      const { manualFirstUsersList } = state;
      const userId = action.payload;
      return {
        ...state,
        manualFirstUsersList: manualFirstUsersList.filter((invitee) =>
          invitee.id !== userId && invitee.email !== userId
        )
      };
    }
    case usersTypes.UPDATE_SELECTED_FIRST_USER: {
      return {
        ...state,
        selectedFirstUser: {
          ...state.selectedFirstUser,
          [action.payload.fieldName]: action.payload.value
        }
      };
    }
    case usersTypes.RESET_FIRST_USERS_MANUAL_LIST: {
      return {
        ...state,
        manualFirstUsersList: []
      };
    }
    case usersTypes.RESET_FIRST_USER_MANUAL_FORM: {
      return {
        ...state,
        selectedFirstUser: {},
        isFirstUserSelected: false
      };
    }
    case usersTypes.SET_IS_AUTOCOMPLETE_FIRST_USER_SELECTED: {
      return {
        ...state,
        isFirstUserSelected: action.payload
      };
    }
    case usersTypes.SET_SELECTED_FIRST_USER: {
      return {
        ...state,
        selectedFirstUser: action.payload
      };
    }
    case usersTypes.REINVITE_DASHBOARD_FIRST_USER_BEGIN:
      return {
        ...state,
        isReinvitingFirstUser: true
      }
    case usersTypes.REINVITE_DASHBOARD_FIRST_USER_ERROR:
      return {
        ...state,
        isReinvitingFirstUser: false
      }
    case usersTypes.REINVITE_DASHBOARD_FIRST_USER_SUCCESS:
      return {
        ...state,
        isReinvitingFirstUser: false,
        firstUsers: action.payload.updatedFirstUsersList
      }
    case usersTypes.SET_SELECTED_EDIT_FIRST_USER:
      return {
        ...state,
        selectedEditUser: {
          ...DEFAULT_SELECTED_EDIT_FIRST_USER,
          ...action.payload.data
        }
      }
    case usersTypes.RESET_SELECTED_EDIT_FIRST_USER:
      return {
        ...state,
        selectedEditUser: {
          ...DEFAULT_SELECTED_EDIT_FIRST_USER,
        }
      }
    case usersTypes.DELETE_SELECTED_FIRST_USER_BEGIN:
      return {
        ...state,
        isDeletingFirstUser: true
      }
    case usersTypes.DELETE_SELECTED_FIRST_USER_ERROR:
      return {
        ...state,
        isDeletingFirstUser: false,
      }
    case usersTypes.DELETE_SELECTED_FIRST_USER_SUCCESS:
      return {
        ...state,
        isDeletingFirstUser: false,
        firstUsers: action.payload.updatedFirstUsersList
      }
    case usersTypes.EDIT_FIRST_USER_BEGIN:
      return {
        ...state,
        isUpdatingFirstUser: true
      }
    case usersTypes.EDIT_FIRST_USER_ERROR:
      return {
        ...state,
        isUpdatingFirstUser: false
      }
    case usersTypes.EDIT_FIRST_USER_SUCCESS:
      return {
        ...state,
        isUpdatingFirstUser: false,
        firstUsers: action.payload.updatedFirstUsersList
      }
    default:
      return state;
  }
};
