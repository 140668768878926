import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../../styles/coachJudging/JudgingResults.scss';
import {connect} from 'react-redux';
import * as programTypeIds from 'shared/constants/programIds';
import FLLChallenge from './FLLChallenge';
import UploadedJudgingRubric from './UploadedJudgingRubric';
import JudgingComments from '../JudgingComments';
import { Col, Row } from 'antd';
import FLLExplore from './FLLExplore';

const JudgingResults = (props) => {
  const boldText = 'paragraph--default paragraph--charcoal paragraph--bold'
  const {
    eventProgramId,
    judgingSessionId,
    digital_rubric,
    judgingRubricComments,
    is_FLLC_event
  } = props;

  const {t} = useTranslation();
  const isFLLC = eventProgramId === programTypeIds.FLL_CHALLENGE_ID;
  const isFLLE = eventProgramId === programTypeIds.FLL_EXPLORE_ID;

  if(isFLLC){
    return (
      <div className='judging-results-feedback'>
        <p className={boldText}>{t('resultsAndFeedbackLabel')}</p>
        <FLLChallenge digitalRubric={digital_rubric} is_FLLC_event={isFLLC}/>
      </div>
    )
  }
  if(isFLLE){
    return (
      <div className='judging-results-feedback'>
        <p className={boldText+' centered'}>{t('resultsAndFeedbackLabel')}</p>
        <FLLExplore digitalRubric={digital_rubric}/>
      </div>
    )
  }

  return (
    <Row gutter={20}>
      <Col xs={24} md={8}>
        <p className={boldText}>{t('resultsAndFeedbackLabel')}</p>
        <div className="judging-results-feedback-with-comments">
          <div className='results-and-feedback'>
            <UploadedJudgingRubric judgingSessionId={judgingSessionId} />
          </div>
        </div>
      </Col>
      <Col xs={24} md={14}>
        <p className={boldText}>{is_FLLC_event ? "Judges' Comments" : "Reviewers' Comments"}</p>
        <div className="judges-comments-wrapper">
          <JudgingComments comments={judgingRubricComments}/>
        </div>
      </Col>
    </Row>
  );
};

JudgingResults.propTypes = {
  eventProgramId: PropTypes.string.isRequired,
  judgingSessionId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
  const { event: { eventDetails: { is_FLLC_event } }, coachJudging, eventOverview, judgingRubric: {judgingRubricComments}} = state;
  const {eventProgramId} = eventOverview;
  const {
    selectedTeamJudgingSession,
  } = coachJudging;

  const {sessionId, digital_rubric} = selectedTeamJudgingSession;

  return {
    judgingRubricComments,
    eventProgramId,
    judgingSessionId: sessionId,
    digital_rubric,
    is_FLLC_event
  }
}

export default connect(mapStateToProps)(JudgingResults);