import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {Button} from 'antd';
import ArrowLeftOutlined from '@ant-design/icons';
import './BackLink.scss';

const BackLink = props => {
  const { t, i18n } = useTranslation()
  const rtl_direction = i18n.dir() === 'rtl';
  const {additionalClassNames, backClick, text} = props;
  const displayText = text && text.length ? text : t('backLabel')
  if(rtl_direction){
    return (
      <div className='back-link__container'>
        <Button type='link' onClick={backClick} className={`back-link ${additionalClassNames}`}>
          <p className='back-link__text'>{displayText}</p>
          <ArrowLeftOutlined className='back-link__icon' />
        </Button>
      </div>
    );
  }
  return (
    <div className='back-link__container'>
      <Button type='link' onClick={backClick} className={`back-link ${additionalClassNames}`}>
        <ArrowLeftOutlined className='back-link__icon' />
        <p className='back-link__text'>{displayText}</p>
      </Button>
    </div>
  );
};

BackLink.propTypes = {
  text: PropTypes.string,
  additionalClassNames: PropTypes.string,
  backClick: PropTypes.func
};

BackLink.defaultProps = {
  additionalClassNames: '',
  backClick: () => {}
};

export default BackLink;
