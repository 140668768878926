export const VOLUNTEER_INVITE_BEGIN = 'VOLUNTEER_INVITE_BEGIN';
export const VOLUNTEER_INVITE_ERROR = 'VOLUNTEER_INVITE_ERROR';
export const VOLUNTEER_INVITE_SUCCESS = 'VOLUNTEER_INVITE_SUCCESS';

export const VOLUNTEER_ADD_TO_LIST_BEGIN = 'VOLUNTEER_ADD_TO_LIST_BEGIN';
export const VOLUNTEER_ADD_TO_LIST_ERROR = 'VOLUNTEER_ADD_TO_LIST_ERROR';
export const VOLUNTEER_ADD_TO_LIST_SUCCESS = 'VOLUNTEER_ADD_TO_LIST_SUCCESS';

export const DELETE_IMPORT_VOLUNTEER_BEGIN = 'DELETE_IMPORT_VOLUNTEER_BEGIN';
export const DELETE_IMPORT_VOLUNTEER_ERROR = 'DELETE_IMPORT_VOLUNTEER_ERROR';
export const DELETE_IMPORT_VOLUNTEER_SUCCESS = 'DELETE_IMPORT_VOLUNTEER_SUCCESS';

export const IMPORT_VOLUNTEERS_BEGIN = 'IMPORT_VOLUNTEERS_BEGIN';
export const IMPORT_VOLUNTEERS_ERROR = 'IMPORT_VOLUNTEERS_ERROR';
export const IMPORT_VOLUNTEERS_SUCCESS = 'IMPORT_VOLUNTEERS_SUCCESS';

export const DELETE_MANUAL_VOLUNTEER_BEGIN = 'DELETE_MANUAL_VOLUNTEER_BEGIN';
export const DELETE_MANUAL_VOLUNTEER_ERROR = 'DELETE_MANUAL_VOLUNTEER_ERROR';
export const DELETE_MANUAL_VOLUNTEER_SUCCESS = 'DELETE_MANUAL_VOLUNTEER_SUCCESS';



export const SET_SELECTED_AUTOCOMPLETE_VOLUNTEER = 'SET_SELECTED_AUTOCOMPLETE_VOLUNTEER';
export const RESET_SELECTED_AUTOCOMPLETE_VOLUNTEER = 'RESET_SELECTED_AUTOCOMPLETE_VOLUNTEER';

export const UPDATE_SELECTED_VOLUNTEER = 'UPDATE_SELECTED_VOLUNTEER';

export const RESET_SELECTED_VOLUNTEER = 'RESET_SELECTED_VOLUNTEER';

export const RESET_MANUAL_VOLUNTEERS_LIST = 'RESET_MANUAL_VOLUNTEERS_LIST';

export const GET_EVENT_VOLUNTEERS_BEGIN = 'GET_EVENT_VOLUNTEERS_BEGIN';
export const GET_EVENT_VOLUNTEERS_SUCCESS = 'GET_EVENT_VOLUNTEERS_SUCCESS';
export const GET_EVENT_VOLUNTEERS_ERROR = 'GET_EVENT_VOLUNTEERS_ERROR';

export const RESET_ADD_VOLUNTEER_MODAL = 'RESET_ADD_VOLUNTEER_MODAL';

export const REINVITE_EVENT_VOLUNTEER_USER_BEGIN = 'REINVITE_EVENT_VOLUNTEER_USER_BEGIN';
export const REINVITE_EVENT_VOLUNTEER_USER_ERROR = 'REINVITE_EVENT_VOLUNTEER_USER_ERROR';
export const REINVITE_EVENT_VOLUNTEER_USER_SUCCESS = 'REINVITE_EVENT_VOLUNTEER_USER_SUCCESS';

export const SET_EVENT_IMPORT_VOLUNTEERS_INVITED = 'export const SET_EVENT_IMPORT_VOLUNTEERS_INVITED';

export const EDIT_EVENT_VOLUNTEER_BEGIN = 'EDIT_EVENT_VOLUNTEER_BEGIN';
export const EDIT_EVENT_VOLUNTEER_ERROR = 'EDIT_EVENT_VOLUNTEER_ERROR';
export const EDIT_EVENT_VOLUNTEER_SUCCESS = 'EDIT_EVENT_VOLUNTEER_SUCCESS';

export const EDIT_VOLUNTEER_INFO_BEGIN = 'EDIT_VOLUNTEER_INFO_BEGIN';
export const EDIT_VOLUNTEER_INFO_ERROR = 'EDIT_VOLUNTEER_INFO_ERROR';
export const EDIT_VOLUNTEER_INFO_SUCCESS = 'EDIT_VOLUNTEER_INFO_SUCCESS';

export const DELETE_EVENT_VOLUNTEER_BEGIN = 'DELETE_EVENT_VOLUNTEER_BEGIN';
export const DELETE_EVENT_VOLUNTEER_ERROR = 'DELETE_EVENT_VOLUNTEER_ERROR';
export const DELETE_EVENT_VOLUNTEER_SUCCESS = 'DELETE_EVENT_VOLUNTEER_SUCCESS';
