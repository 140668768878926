import * as eventService from 'utils/services/events';
import {notification} from 'antd';
import * as types from '../../types/eventScheduleTypes';
import {getEventDetailsSuccess} from '../eventActions'


const editEventBegin = () => {
  return {
    type: types.EDIT_EVENT_GENERAL_INFO_BEGIN
  }
}

const editEventError = () => {
  return {
    type: types.EDIT_EVENT_GENERAL_INFO_ERROR
  }
}

const editEventSuccess = () => {
  return {
    type: types.EDIT_EVENT_GENERAL_INFO_SUCCESS
  }
}



const editEventGeneralInfo = (values, eventId, cb = () => {}) => {
  return async dispatch => {
    try {
      await dispatch(editEventBegin())
      const payload = {
        data: {
          id: eventId,
          type: 'event',
          attributes: {
            ...values
          }
        }
      }
  
      const {data, included} = await eventService.editEvent(eventId, payload);
      await dispatch(editEventSuccess())
      await dispatch(getEventDetailsSuccess(data, included));
      cb();
      notification.success({
        message: 'Success',
        description: 'Event successfully saved.'
      })

    } catch(err) {
      await dispatch(editEventError())
      notification.success({
        message: 'Error',
        description: 'Event unsuccessfully saved.'
      })
      throw err;
    }

  }


}

export default editEventGeneralInfo;