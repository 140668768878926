import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import '../../../../../../shared/components/Scoresheet/styles/MatchScoresheetForm.scss';
import {Radio} from 'antd';
import InputNumber from 'shared/components/InputNumber'
import { useTranslation } from 'react-i18next';

/*
The second question asks how many cubes are in your Frame/Target.
If the answer to the
second question is zero, the third question must be NO.

*/
const moduleNameClassName = `h-2 heading--white`;
const moduleTitleClassName = `h-2 heading--charcoal`;
const paragraphClassName = 'paragraph--large paragraph--charcoal paragraph--normal'
const errorClassName = 'paragraph--large paragraph--dark-red paragraph--normal'

const ERROR_MESSAGE = 'Answer must be NO because no cubes are in your Frame/Target'
const SIXTEEN_MAX = 16;
const EIGHTEEN_MAX = 18;
const ModuleSection = props => {
  const [moduleError, setModuleError] = useState('');
  const [maxNumberOfCubes, setMaxNumberOfCubes] = useState(EIGHTEEN_MAX);
  const {moduleName, moduleTitle, fields, scoreInput} = props;
  const [form] = Form.useForm();
  const {t} = useTranslation();

  useEffect(() => {
    if (scoreInput.m08_scoring_1) {
      setMaxNumberOfCubes(SIXTEEN_MAX)
    } else {
      setMaxNumberOfCubes(EIGHTEEN_MAX)
    }
  }, [])
  
  const validateRadioOption = (rule, value, callback) => {
    const {field} = rule;
    const module8Question2 = form.getFieldValue('m08_scoring_2')
    if (field === 'm08_scoring_1' && value) {
      setMaxNumberOfCubes(SIXTEEN_MAX);
      callback();
    } else if (field === 'm08_scoring_1' && !value) {
      setMaxNumberOfCubes(EIGHTEEN_MAX);
      callback();
    } else if (field === 'm08_scoring_3' && !module8Question2 && value) {
      setModuleError(ERROR_MESSAGE);
      callback(ERROR_MESSAGE);
    } else {
      setModuleError('');
      callback();
    }

  }

  const validateInputNumber = (rule, value, callback) => {
    const module8Question3 = form.getFieldValue('m08_scoring_3')

    if (!value && module8Question3) {
      setModuleError(ERROR_MESSAGE);
      callback();
    } else {
      callback();
      setModuleError('');

    }
  }

  

  return (
    <div className="scoresheet-module">
      <div className="scoresheet-module__name">
        <h3 className={moduleNameClassName}>{moduleName}</h3>
      </div>

      <div className="m-l-30 m-t-8 scoresheet-module__content">
        <h2 className={moduleTitleClassName}>{moduleTitle}</h2>
        <div className="scoresheet-module__fields-wrapper">
          {fields.map((f) => (
            <div className="scoresheet-module__field-container">
              <div key={f.id} className="scoresheet-module__field-module-eight">
                <span className={`${paragraphClassName} scoresheet-module__field-label`}>
                  {t(f.scoring)}
                  :
                </span>
                {f.type !== 'integer' ? (
                  <span>
                    {form.getFieldDecorator( f.id, {
                    initialValue: scoreInput[f.id],
                    rules: (f.id === 'm08_scoring_3' || f.id === 'm08_scoring_1') ? [{
                      validator: validateRadioOption
                    }] : []
                  }
                  )(
                    <Radio.Group
                      buttonStyle="solid"
                      className="match-scoresheet-form__radio-wrapper"
                      optionType="button"
                      disabled={scoreInput.is_published}
                    >
                      {f.options.map((o) => (
                        <Radio.Button
                          buttonStyle="solid"
                          className="match-scoresheet-form__radio-btn"
                          key={`${f.id}-${o.value}`}
                          value={o.value}
                        >
                          {t(o.description)}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  )}

                  </span>
              ) : (
                <span>
                  {form.getFieldDecorator( f.id, {
                    initialValue: scoreInput[f.id],
                    rules: [{
                      validator: validateInputNumber
                    }]
                }
                )(
                  <InputNumber size='large' min={f.min} max={maxNumberOfCubes} disabled={scoreInput.is_published}/>
                )}
                </span>
              )}

              </div>
              {(f.id === 'm08_scoring_3' && moduleError) ? (
                <p className={errorClassName}>{moduleError}</p>

                ): null }
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

ModuleSection.propTypes = {
  moduleName: PropTypes.string,
  moduleTitle: PropTypes.string,
  fields: PropTypes.array,
  form: PropTypes.object.isRequired,
  scoreInput: PropTypes.object
};

ModuleSection.defaultProps = {
  moduleName: '',
  moduleTitle: '',
  fields: [],
  scoreInput: {}
};

export default ModuleSection;