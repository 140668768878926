import { combineReducers } from 'redux'
import eventVolunteers from './volunteersReducer';
import eventTeams from './teamsReducer';
import eventRoles from './eventRolesReducer';
import eventDetails from './eventDetailsReducer';
import eventSchedule from './eventScheduleReducer';
import eventJudging from './eventJudgingReducer';
import eventScoring from './eventScoringReducer';
import eventAwards from './eventAwardsReducer';
import eventSponsors from './eventSponsorsReducer';

const eventReducer = combineReducers({
  eventDetails,
  eventVolunteers,
  eventTeams,
  eventRoles,
  eventSchedule,
  eventJudging,
  eventScoring,
  eventAwards,
  eventSponsors
});

export default eventReducer;
