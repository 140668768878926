export const PrivacyPDFs = {
  "ar": "",
  "de": "",
  "es": "",
  "en": "24-25_EVENT_HUB_Privacy_Policy_Final.pdf",
  "fr": "",
  "he": "",
  "ja": "",
  "ko": "",
  "nl": "",
  "pt-BR": "",
  "pt": "",
  "tr": "",
}

export const TermsPDFs = {
  "ar": "",
  "de": "",
  "es": "",
  "en": "24-25_EVENT_HUB_Terms_of_Use_Final.pdf",
  "fr": "",
  "he": "",
  "ja": "",
  "ko": "",
  "nl": "",
  "pt-BR": "",
  "pt": "",
  "tr": "",
}

export default {PrivacyPDFs, TermsPDFs};
