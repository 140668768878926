import * as types from '../types/eventRolesTypes'

const DEFAULT_EVENT_ROLE_TYPES = {
  eventRoleTypes: [],
  isFetchingEventRoleTypes: false
}

export default (state = DEFAULT_EVENT_ROLE_TYPES, action) => {
  const {type} = action;
  switch(type) {
    case types.FETCH_EVENT_ROLE_TYPES_BEGIN:
      return {
        ...state,
        isFetchingEventRoleTypes: action.payload.isFetchingEventRoleTypes
      }
    case types.FETCH_EVENT_ROLE_TYPES_ERROR:
      return {
        ...state,
        isFetchingEventRoleTypes: action.payload.isFetchingEventRoleTypes,
        eventRoleTypes: action.payload.eventRoleTypes
      }
    case types.FETCH_EVENT_ROLE_TYPES_SUCCESS:
      return {
        ...state,
        isFetchingEventRoleTypes: action.payload.isFetchingEventRoleTypes,
        eventRoleTypes: action.payload.eventRoleTypes
      }
    
      default:
        return state
  }
}