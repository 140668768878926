import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {getJudgingObject} from 'features/EventOverviewContainer/redux/actions/coachJudging/feedback';
import {connect} from 'react-redux';
import RubricModal from '../../CoachJudging/JudgingResults/RubricModal';
import { getJudgeComments } from 'features/EventOverviewContainer/redux/actions/judgingRubric';

const DEFAULT_STATE = {
  isInnovationProjectForm: true,
  isRobotDesignForm: true,
  isCoreValuesForm: true
}
const FLLChallenge = props => {
  const {getRubric, loadingFeedback, digitalRubric, getJudgeComments, rubric} = props;
  const [state, setState] = useState(DEFAULT_STATE);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!rubric.id && digitalRubric && visible) {
      getRubric(digitalRubric);
      getJudgeComments(digitalRubric);
    }
  }, [digitalRubric, visible])

  const openModal = () => {
    setVisible(true);
  }

  const closeModal = () => {
    setVisible(false);
    setState(DEFAULT_STATE)
  }

  return (
    <div>
      <RubricModal
        onCancel={closeModal}
        visible={visible}
        {...state}
        loading={loadingFeedback}
        rubric={rubric}
      />
      <a onClick={openModal} disabled={!digitalRubric}>View Rubric</a>
    </div>
  );
};

FLLChallenge.propTypes = {
  getRubric: PropTypes.func.isRequired,
  isRubricPublished: PropTypes.bool.isRequired,
  loadingFeedback: PropTypes.bool.isRequired,
  digitalRubric: PropTypes.string.isRequired
};

const mapStateToProps = ({coachJudging}) => {
  const {
    isCoachJudgingObjectPublished,
    isFetchingCoachJudgingObject,
  } = coachJudging;

  return {
    isRubricPublished: isCoachJudgingObjectPublished,
    loadingFeedback: isFetchingCoachJudgingObject,
    
  }
}

export default connect(mapStateToProps, {getRubric: getJudgingObject, getJudgeComments})(FLLChallenge);