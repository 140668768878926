import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import {handleJudgingTimestamps} from 'features/EventContainer/redux/actions/scheduleActions/scheduleDetails';
import missingFieldValues from 'features/EventContainer/utils/checkMissingSessionData';
import CompleteTimestampCard from '../timestampCards/CompleteTimestampCard';
import '../../../styles/ScheduleContainer/EventScheduleSection.scss';
import ScheduleAccordion from '../ScheduleAccordion';
import JudgingSessionsContainer from './JudgingSessions';

const EventScheduleSection = (props) => {
  const { t } = useTranslation()

  const {
    data,
    scheduleId,
    judgingTimestamps,
    getJudgingTimestamps,
    isLoadingJudgingSessions,
    isLoadingJudgingSection,
    is_FLLC_event
  } = props;

  const hasDetailsNotSet = () => {
    let errorCount = 0;

    if (!data.length) {
      return false;
    }
    data.forEach(d => {
      const isMissingData = missingFieldValues(d.startTime, d.endTime, d.endTime);
      if (isMissingData) {
        errorCount += 1;
      }
    })

    if (errorCount) {
     
      return {
        errorCount: true,
        totalIncomplete: errorCount
      }
    }

    return {
      errorCount: false,
      totalIncomplete: errorCount
    };
  }

  const {errorCount, totalIncomplete} = hasDetailsNotSet();

  const closeModalCallback = async() => {
    await getJudgingTimestamps(scheduleId)
  }

  return (
    <div className="event-schedule-section">
      <h3 className="h-3 h--400">{ is_FLLC_event ? t('judgingLabel') : t('nonChallengeJudgingLabel')}</h3>
      <div className="event-schedule-section__cards">
        <div className="event-schedule-section__cards-section">
          {judgingTimestamps.map((j) => (
            <CompleteTimestampCard
              key={j.id}
              cb={closeModalCallback}
              data={j}
              title={is_FLLC_event ? t('judgeDeliberationLabel') : t('nonChallengeJudgeDeliberationLabel')}
              cardType="judging"
              iconType="law"
            />
          ))}
        </div>
        <div>
          <ScheduleAccordion
            incompleteSessionsCount={totalIncomplete}
            disabled={isLoadingJudgingSessions||isLoadingJudgingSection}
            data={data}
            sessionsCount={data.length}
            panelTitle={is_FLLC_event ? t('judgeSessionsLabel') : t('nonChallengeJudgingSessionLabel')}
            missingDetails={errorCount}
          >
            <JudgingSessionsContainer scheduleId={scheduleId} />
          </ScheduleAccordion>
        </div>
      </div>
    </div>
  );
};

EventScheduleSection.propTypes = {
  data: PropTypes.array,
  scheduleId: PropTypes.string.isRequired,
  judgingTimestamps: PropTypes.array,
  getJudgingTimestamps: PropTypes.func,
  userEventRoleIds: PropTypes.array.isRequired,
  userType: PropTypes.string.isRequired,
  isLoadingJudgingSessions: PropTypes.bool
};

EventScheduleSection.defaultProps = {
  data: [],
  judgingTimestamps: [],
  getJudgingTimestamps: () => {},
  isLoadingJudgingSessions: false
}

const mapDispatchToProps = {
  getJudgingTimestamps: handleJudgingTimestamps
}

const mapStateToProps = (state) => {
  const { 
    event: {
      eventDetails: { is_FLLC_event },
      eventSchedule: {sessions, isLoadingJudgingSection,}
    },
  
  } = state;

  const {
    isLoadingJudgingSessions,
  } = sessions;
  return { isLoadingJudgingSessions, isLoadingJudgingSection, is_FLLC_event };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventScheduleSection);