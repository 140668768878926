import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FTC_ID
} from 'shared/constants/programIds';
import { useTranslation } from 'react-i18next';
import handleEditJudgingPanel from 'features/EventContainer/redux/actions/judgingActions/editJudgingPanel';
import { Avatar, Row, Col, Button, Form } from 'antd';
import { connect } from 'react-redux';
import ListLoading from 'shared/components/Spinner/ListLoading';
import fetchSelectedPanel from 'features/EventContainer/redux/actions/judgingActions/selectedPanel';
import Select from 'shared/components/FormDropdown';
import PanelTitle from './PanelTitle';
import Input from 'shared/components/Input';



const FormItem = Form.Item;
const SelectOption = Select.Option;

const rowProps = {
  type: 'flex', gutter: 20,
  className: 'm-t-20'
};

const colProps = {
  xs: 24,
  md: 16
}

const PanelModalContent = props => {
  const { t } = useTranslation()
  const [form] = Form.useForm();

  const {
    panelId,
    getPanel,
    eventJudging,
    closeModal,
    eventProgramId,
    is_FLLC_event
  } = props;

  const {
    selectedPanel,
    isFetchingSelectedPanel,
    judgeOptions, sessionOptions,
    isUpdatingJudgingPanel,
    judgingPanels
  } = eventJudging;

  useEffect(() => {
    if (panelId) {
      getPanel(panelId)
    }
  }, [panelId])

  if (isFetchingSelectedPanel) {
    return (
      <ListLoading loading text='Loading Panel' />
    );
  }
  const findUniquePanelNames = (rule, value, callback) => {

    if (value === undefined || value.trim().toLowerCase() == selectedPanel.name.trim().toLowerCase()) callback()

    let match = false;

    judgingPanels.map((panel) => {
      let panelName = panel.panelName.trim().toLowerCase();
      if (panelName == value.trim().toLowerCase()) {
        match = true;
      }
    })

    if (match) {
      callback('Room name already exists')
    } else {
      callback();
    }
  }


  const validateSelectedJudgesCount = (rule, value, callback) => {

    if (!Array.isArray(value) || value.length <= 1) {
      callback('At least two judges must be assigned to the panel.')
    } else {
      callback()
    }
  }


  const handleSave = (e) => {
      if (e && 'preventDefault' in e) e.preventDefault();
      form.validateFields().then(async (values, err) => {
        const formJudges = values.judges || [];
        const formSessions = values.sessions || [];

        const removedSessions = [];
        const addedSessions = [];

        const removedJudges = [];
        const addedJudges = [];

        const initialJudges = [...selectedPanel.judgesIds];
        const initialSessions = [...selectedPanel.sessionsIds];
        formSessions.forEach(selectedSession => {
          if (!initialSessions.includes(selectedSession)) {
            addedSessions.push(selectedSession)
          }
        })

        initialSessions.forEach(initialSession => {
          if (!formSessions.includes(initialSession)) {
            removedSessions.push(initialSession)
          }
        })

        formJudges.forEach(selectedJudge => {
          if (!initialJudges.includes(selectedJudge)) {
            addedJudges.push(selectedJudge)
          }
        })

        initialJudges.forEach(initialJudge => {
          if (!formJudges.includes(initialJudge)) {
            removedJudges.push(initialJudge)
          }
        })
        const payload = {
          removedJudges,
          removedSessions,
          addedJudges,
          addedSessions,
          formJudges,
          formSessions,
          panelName: values.roomName
        }

        props.handleEditJudgingPanel(panelId, payload, closeModal)
      });
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  }

  const generatePanelsCommaSeparated = (panels) => {
    const panelsArray = panels.map((panel) => {
      return panel.panelName
    })

    let renderPanels = panelsArray.join(", ")
    return renderPanels
  }

  return (
    <div>
      {/* <PanelTitle instructions="Judges and teams added to this panel will be automatically assigned to the corresponding Judging Session on the activity schedule." title={`${t('editLabel')} ${selectedPanel.name || selectedPanel.panelTypeName}`} /> */}
      <PanelTitle 
        instructions={is_FLLC_event ? "Judges and teams added to this panel will be automatically assigned to the corresponding Judging Session on the activity schedule." : "Reviewers and teams added to this panel will be automatically assigned to the corresponding Reviewing Session on the activity schedule."} 
        title={`${t('Editing Room')}`} />
      <Form hideRequiredMark onFinish={handleSave} onFinishFailed={onFinishFailed} name='Edit-Judging-Panel' form={form} initialValues={{roomName: selectedPanel.roomName, judges: selectedPanel.judgesIds, session: selectedPanel.sessionsIds }}>
        <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem name='roomName' label={<span>{t('Room name')}</span>} rules={[{ required: true, message: t('Enter room name') }, { validator: findUniquePanelNames }]}>
              <Input size='large' placeholder={'Name'} />
            </FormItem>
          </Col>
        </Row>

        <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem name='judges' label={is_FLLC_event ? t('judgesLabel') : t('nonChallengeJudgesLabel')}>
                <Select
                  placeholder={is_FLLC_event ? t('selectJudgeLabel') : t('nonChallengeSelectJudgeLabel')}
                  allowClear
                  showArrow={false}
                  showSearch
                  mode='multiple'
                  optionLabelProp="label"
                  optionFilterProp='label'
                  className='judging-modal-judges-tab__add-select'
                >
                  {judgeOptions.map(j => (
                    <SelectOption value={j.userId} label={`${j.fullName}`}>
                      <span aria-label={`${j.fullName}`} className='judging-modal-judges-tab__add-option'>
                        <div>
                          <p className='paragraph--medium paragraph--bold paragraph--charcoal'>{`${j.fullName}`}</p>
                          {j?.panels?.length > 0 ? (
                            <p className='paragraph--small paragraph--blue'>{generatePanelsCommaSeparated(j.panels)}</p>
                          ) : (
                            <p className='paragraph--small paragraph--red'>{t('notAssignedLabel')}</p>
                          )}
                        </div>
                      </span>
                    </SelectOption>
                  ))}
                </Select>
            </FormItem>

          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem name='sessions' label={t('teamsLabel')}>
                <Select
                  showArrow={false}
                  showSearch
                  mode='multiple'
                  size="large"
                  className="judging-panel-modal__add-select"
                  placeholder={t('selectTeamsLabel')}
                  allowClear
                  optionLabelProp="label"
                  optionFilterProp='label'
                  popUpContainerElementId='edit-judging-panel-team'
                >
                  {sessionOptions.map((teamOpt) => (
                    <SelectOption
                      value={teamOpt.sessionId}
                      label={(eventProgramId === FTC_ID) ? (
                        `${teamOpt.teamName} (${teamOpt.sessionTypeName})`
                      ) : (
                        teamOpt.teamName
                      )}
                      disabled={teamOpt.panelId && (teamOpt.panelId !== panelId)}
                    // disabled={(eventProgramId !== FTC_ID) && teamOpt.panelId}
                    >
                      <span
                        aria-label={teamOpt.teamName}
                        className="judging-modal-judges-tab__add-option"
                      >
                        <div>
                          <p className="paragraph--medium paragraph--bold paragraph--charcoal">
                            {(eventProgramId === FTC_ID) ? (
                              `${teamOpt.teamName} (${teamOpt.sessionTypeName})`
                            ) : (
                              teamOpt.teamName
                            )}
                          </p>
                          {teamOpt.panelId ? (
                            <p className="paragraph--small paragraph--blue">{teamOpt.panelName}</p>
                          ) : (
                            <p className="paragraph--small paragraph--red">{t('notAssignedLabel')}</p>
                          )}
                        </div>
                      </span>
                    </SelectOption>
                  ))}
                </Select>
            </FormItem>

          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <Button loading={isUpdatingJudgingPanel} additionalClassNames='add-event-modal__btn' htmlType='submit'>{t('saveLabel')}</Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

PanelModalContent.propTypes = {
  panelId: PropTypes.string.isRequired,
  getPanel: PropTypes.func.isRequired,
  eventJudging: PropTypes.object.isRequired,
  judgingSessionsData: PropTypes.array,
  judgingSessionsIncluded: PropTypes.array,
  judgingSessionsTeams: PropTypes.array,
  closeModal: PropTypes.func,
  handleEditJudgingPanel: PropTypes.func
};

PanelModalContent.defaultProps = {
  judgingSessionsData: [],
  judgingSessionsIncluded: [],
  judgingSessionsTeams: [],
  closeModal: () => { },
  handleEditJudgingPanel: () => { }
}
const mapDispatchToProps = {
  getPanel: fetchSelectedPanel,
  handleEditJudgingPanel
}

const mapStateToProps = (state) => {
  const { event: { eventDetails, eventJudging, eventSchedule } } = state;
  const { eventProgramId, is_FLLC_event } = eventDetails;

  const {
    judgingSessionsData,
    judgingSessionsIncluded,
    judgingSessionsTeams,
    isLoadingJudgingSessions
  } = eventSchedule.sessions;

  return {
    judgingSessionsData,
    judgingSessionsIncluded,
    judgingSessionsTeams,
    isLoadingJudgingSessions,
    eventJudging,
    eventProgramId,
    is_FLLC_event
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelModalContent);