import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MatchEvidence from './MatchEvidenceRow';
import { officialMatchType, practiceMatchType } from 'shared/constants/matchTypes';

const Evidence = props => {
  const { t } = useTranslation()
  const {
    matches,
    setUploadModalVisibility,
    setUploadProgress,
  } = props;

  return (
    <div className='m-t-40 m-b-18'>
      {matches.map(m => {
        const fileCount = Array.isArray(m.files) ? m.files.length : 0;
        let title_label;
        if (officialMatchType.matchTypeId === m.matchTypeId) title_label = officialMatchType.label;
        if (practiceMatchType.matchTypeId === m.matchTypeId) title_label = practiceMatchType.label;
        const title = title_label ? t(title_label) : m.matchTypeName;
        return (
          <MatchEvidence
            key={m.matchId}
            matchId={m.matchId}
            title={`${title} ${m.matchNumber}`}
            matchStatus={m.matchStatusName}
            matchTypeId={m.matchTypeId}
            matchStatusId={m.matchStatusId}
            files={m.files}
            fileCount={fileCount}
            matchNumber={m.matchNumber}
            totalScore={m.totalScore}
            scoreInputId={m.scoreId}
            loading={m.loading}
            setUploadModalVisibility={setUploadModalVisibility}
            setUploadProgress={setUploadProgress}
          />
)
      })}
    </div>
  );
};

Evidence.propTypes = {
  matches: PropTypes.array,
  setUploadModalVisibility: PropTypes.func,
  setUploadProgress: PropTypes.func,
};

Evidence.defaultProps = {
  matches: [],
}

export default Evidence;