import PropTypes from 'prop-types';
import React from 'react';
import programLogos from 'shared/constants/programLogos';
import './eventProgramLogos.scss';
import firstLegoLeagueSeasonLogoCurrent from '../../../shared/components/Icon/pngs/first-lego-league-season-logo-current-reverse.png';

const EventProgramLogos = props => {
  const { programId } = props;
  if (!programId) return null;

  const { ProgramLogo, /*WhitePlayMarker,*/ PresentedBy } = programLogos[programId];
  return (
    <div className='c-event-program-logos'>
      <img src={firstLegoLeagueSeasonLogoCurrent} alt="FIRST LEGO League Current Season Logo" className="c-event-fll-current-logo"/>
      {PresentedBy&&<PresentedBy />}
      <ProgramLogo />
    </div>
  );
};

EventProgramLogos.propTypes = {
  programId: PropTypes.string
};

export default EventProgramLogos;
