import * as types from '../types/scoringTypes';

const DEFAULT_SELECTED_PANEL = {
  name: '',
  panelRefereesList: [],
  panelSessionsList: [],
  sessions: [],
  id: null,
  judgesIds: [],
  refereesIds: [],
  sessionsIds: [],
  panelTypeId: '',
  panelTypeName: ''
}

const DEFAULT_SCORING_STATE = {
  eventRefereesData: [],
  eventRefereesIncluded: [],
  eventRefereesError: false,
  isLoadingEventReferees: true,
  eventScoringSessionsData: [],
  eventScoringSessionsIncluded: [],
  isLoadingEventScoringSessions: [],
  eventScoringPanelsData: [],
  eventScoringPanelsIncluded: [],
  isFetchingScoringPanels: true,
  errorScoringPanels: false,
  isFetchingSelectedPanel: false,
  selectedPanel: DEFAULT_SELECTED_PANEL,
  scoringSessionTeams: [],
  isAddingTeamToPanel: false,
  isRemovingRefereeFromPanel: false,
  isAddingRefereesToPanel: false,
  formattedPanels: [],
  refereeOptions: [],
  sessionOptions: [],
  isCreatingNewRefereePanel: false,
  isEditingRefereePanel: false,
  expendables: []
}

export default (state = DEFAULT_SCORING_STATE, action) => {
  const {type} = action;
  switch(type) {
    case types.GET_EVENT_REFEREES_BEGIN:
      return {
        ...state,
        isLoadingEventReferees: true
      }
    case types.GET_EVENT_REFEREES_ERROR:
      return {
        ...state,
        isLoadingEventReferees: false,
        eventRefereesError: true
      }
    case types.GET_EVENT_REFEREES_SUCCESS:
      return {
        ...state,
        eventRefereesError: false,
        isLoadingEventReferees: false,
        eventRefereesData: action.payload.eventRefereesData,
        eventRefereesIncluded: action.payload.eventRefereesIncluded
      }
      case types.GET_EVENT_SCORING_PANELS_BEGIN:
        return {
          ...state,
          eventJudgesError: false,
          isFetchingScoringPanels: true,
        }
      case types.GET_EVENT_SCORING_PANELS_ERROR:
        return {
          ...state,
          errorScoringPanels: true,
          isFetchingScoringPanels: false,
        }
      case types.GET_EVENT_SCORING_PANELS_SUCCESS:
        return {
          ...state,
          errorScoringPanels: false,
          isFetchingScoringPanels: false,
          formattedPanels: action.payload.formattedPanels,
          refereeOptions: action.payload.refereeOptions,
          sessionOptions: action.payload.sessionOptions
        }
      case types.FETCH_SELECTED_SCORING_PANEL_BEGIN:
        return {
          ...state,
          isFetchingSelectedPanel: true,
        }
      case types.FETCH_SELECTED_SCORING_PANEL_ERROR:
        return {
          ...state,
          isFetchingSelectedPanel: false,
        }
      case types.FETCH_SELECTED_SCORING_PANEL_SUCCESS:
        return {
          ...state,
          isFetchingSelectedPanel: false,
          selectedPanel: {
            ...state.selectedPanel,
            ...action.payload.selectedPanel
          },
        }
      case types.RESET_SELECTED_SCORING_PANEL:
        return {
          ...state,
          selectedPanel: DEFAULT_SELECTED_PANEL 
        }
      case types.UPDATE_SELECTED_SCORING_PANEL:

        return {
          ...state,
          selectedPanel: {
            ...state.selectedPanel,
            [action.payload.field]: action.payload.value
          }
        }
      case types.REMOVE_REFEREE_FROM_SCORING_PANEL_BEGIN:
        return {
          ...state,
          isRemovingRefereeFromPanel: true
        }
      case types.REMOVE_REFEREE_FROM_SCORING_PANEL_ERROR:
        return {
          ...state,
          isRemovingRefereeFromPanel: false

        }
      case types.REMOVE_REFEREE_FROM_SCORING_PANEL_SUCCESS:
        return {
          ...state,
          isRemovingRefereeFromPanel: false
        }
      case types.SET_SCORING_SESSION_TEAMS:
        return {
          ...state,
          scoringSessionTeams: action.payload.scoringSessionTeams
        }
      case types.ADD_REFEREES_TO_SCORING_PANEL_BEGIN:
        return {
          ...state,
          isAddingRefereesToPanel: true
        }
      case types.ADD_REFEREES_TO_SCORING_PANEL_ERROR:
        return {
          ...state,
          isAddingRefereesToPanel: false
        }
      case types.ADD_REFEREES_TO_SCORING_PANEL_SUCCESS:
        return {
          ...state,
          isAddingRefereesToPanel: false
        }
      case types.CREATE_NEW_SCORING_PANEL_BEGIN:
        return {
          ...state,
          isCreatingNewRefereePanel: true
        }
      case types.CREATE_NEW_SCORING_PANEL_ERROR:
        return {
          ...state,
          isCreatingNewRefereePanel: false
        }
      case types.CREATE_NEW_SCORING_PANEL_SUCCESS:
        return {
          ...state,
          isCreatingNewRefereePanel: false,
          formattedPanels: action.payload.formattedPanels,
          refereeOptions: action.payload.refereeOptions,
          sessionOptions: action.payload.sessionOptions
        }
      case types.EDIT_SCORING_PANEL_BEGIN:
        return {
          ...state,
          isEditingRefereePanel: true
        }
      case types.EDIT_SCORING_PANEL_ERROR:
        return {
          ...state,
          isEditingRefereePanel: false
        }
      case types.EDIT_SCORING_PANEL_SUCCESS:
        return {
          ...state,
          isEditingRefereePanel: false,
          formattedPanels: action.payload.formattedPanels,
          refereeOptions: action.payload.refereeOptions,
          sessionOptions: action.payload.sessionOptions
        }
      case types.SET_SELECTED_SCORING_PANEL_DATA:
        return {
          ...state,
          selectedPanel: {
            ...state.selectedPanel,
            sessionsIds: action.payload.sessionIds,
            refereesIds: action.payload.refereeIds
          }
        }
    case types.SET_SCORING_PANELS_EXPANDS:
      return {
        ...state,
        expendables: action.payload.panels
      }
    default:
      return state;
  }

}