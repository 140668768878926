import React from 'react';
import PropTypes from 'prop-types';
import { Divider as AntDivider } from 'antd';
import './divider.scss';

const Divider = props => {
  const { type, width, color, margin } = props;

  const className = `divider--${type}`;

  const style = {};
  
  if (width) {
    style.height = `${width}px`;
  }

  if (color) {
    style.background = color;
  }

  if (margin) {
    style.margin = `${margin}px 0`;
  }

  return (
    <AntDivider
      type={type}
      style={style}
      className={className}
    />
  );
};

Divider.propTypes = {
  type: PropTypes.string
};

Divider.defaultProps = {
  type: 'horizontal',
  color: '#EBEAEB'
};

export default Divider;
