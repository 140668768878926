import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmModal from 'shared/components/ConfirmModal';

const LeaderboardConfirmModal = (props) => {
  const { isPublished } = props;
  const {t} = useTranslation();
  const title = isPublished ? 'Unpublish Leaderboard' : t('publishLeaderboardWarningLabel');
  const description = isPublished ? 'When you Unpublish, the leaderboard will be unpublished to all event participants.' : t('publishLeaderboardWarningText')
  return (
    <ConfirmModal
      title={title}
      description={description}
      {...props}
    />
  );
};

export default LeaderboardConfirmModal;
