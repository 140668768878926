export const formatNewSponsorPayload = (sponsor) => {
  const { sponsorName, scheduleId } = sponsor;
  return {
    data: {
      type: 'sponsor',
      attributes: {
        name: sponsorName
      },
      relationships: {
        schedule: {
          data: {
            type: 'schedule',
            id: scheduleId
          }
        },
      }
    }
  };
};

export const formatEditSponsorPayload = ({ id, name }) => {
  return {
    data: {
      type: 'sponsor',
      id,
      attributes: {
        name
      }
    }
  };
};
