import React from 'react';
import PropTypes from 'prop-types';
import PanelCard1 from './PanelCard';
import PanelCard from './PanelCard/Card';

const PanelsContainer = props => {
  const { panels, eventSessions, eventJudges, panelType, panelTypeId } = props;

  return (
    <>
      <div className="panels-cards-wrapper">
        {panels.map((p) => (
          <PanelCard
            panel={p}
            key={p.panelId}
            id={p.panelId}
          />
        ))}
      </div>
      {/* <div className="panels-cards-wrapper">
        {panels.map((p) => (
          <PanelCard1
            panel={p}
            key={p.panelId}
            id={p.panelId}
          />
        ))}
      </div> */}
    </>
  );
};

PanelsContainer.propTypes = {
  panels: PropTypes.array,
  eventSessions: PropTypes.array,
  eventJudges: PropTypes.array,
  panelType: PropTypes.string.isRequired,
  panelTypeId: PropTypes.string.isRequired,
};

PanelsContainer.defaultProps = {
  panels: [],
  eventSessions: [],
  eventJudges: []
}

export default PanelsContainer;