import React from 'react';
import PropTypes from 'prop-types';
import EventProgramLogos from 'shared/components/EventProgramLogos';
import './EventPageHeader.scss';

const EventHeader = props => {
  const {
    title,
    subHeading,
    hasSubheading,
    eventLocation,
    dateString,
    programId,
    showBgColor,
    loading
  } = props;

  return (
    <div className={`event-page-header ${showBgColor ? 'event-page-header' : ''}`}>
      {loading ? (null) : (
        <>
          <div className='event-page-header__title'>
            <h1 className='h-2 h--bold heading--white event-page-header__title-text'>{title}</h1>
            <EventProgramLogos programId={programId} />
          </div>
          
          <div className='event-page-header__subtitle'>
            <h2 className='h-4 h--300 heading--white'>
              {hasSubheading && subHeading ? subHeading : ''}
            </h2>
            <span className='event-page-header__subtitle-info'>
              <h3 className='paragraph--white paragraph--large paragraph--300'>{dateString}</h3>
              <span className='event-header__point'>&#8226;</span>
              <h3 className='paragraph--white paragraph--large paragraph--300'>{eventLocation}</h3>
            </span>
          </div>
        </>
      )}
    </div>
  );
};

EventHeader.propTypes = {
  title: PropTypes.string,
  eventLocation: PropTypes.string,
  dateString: PropTypes.string,
  subHeading: PropTypes.string,
  hasSubheading: PropTypes.bool,
  showBgColor: PropTypes.bool,
  loading: PropTypes.bool
};

EventHeader.defaultProps = {
  title: '',
  eventLocation: '',
  dateString: '',
  subHeading: '',
  hasSubheading: false,
  showBgColor: true,
  loading: false
};

export default EventHeader;
