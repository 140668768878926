
import {REFEREE_REVIEW_PANEL_TYPE_ID, JUDGING_REVIEW_PANEL_TYPE_ID} from 'shared/constants/panelTypes';


export const formatCreateJudgingPanelPayload = (panelName, sessionIds, judgeIds, scheduleId) => {
  const data = {
    type: 'panel',
    attributes: {
      name: panelName
    },
    relationships: {
      schedule: {
        data: {
          type: 'schedule',
          id: scheduleId
        }
      },
      panel_type: {
        data: {
          type: 'panel_type',
          id: JUDGING_REVIEW_PANEL_TYPE_ID,
        }
      },
      sessions: {
        data: sessionIds.map(s => ({type: 'session', id: s}))
      },
      judges: {
        data: judgeIds.map(j => ({type: 'user', id: j}))
      }
    }
  }

  return {data}
}

export const formatCreateScoringPanelPayload = (panelName, sessionIds, refereeIds, scheduleId) => {
  const data = {
    type: 'panel',
    attributes: {
      name: panelName
    },
    relationships: {
      schedule: {
        data: {
          type: 'schedule',
          id: scheduleId
        }
      },
      panel_type: {
        data: {
          type: 'panel_type',
          id: REFEREE_REVIEW_PANEL_TYPE_ID,
        }
      },
      sessions: {
        data: sessionIds.map(s => ({type: 'session', id: s}))
      },
      referees: {
        data: refereeIds.map(r => ({type: 'user', id: r}))
      }
    }
  }
  return {data}
}

export const formatRemoveUsersFromPanel = (userIds) => {
  const data = userIds.map(userId => {
    return {
      type: 'user',
      id: userId
    }
  })

  return {
    data
  }
}

export const formatRemoveSessionsFromPanel = (sessionIds) => {
  const data = sessionIds.map(sessionId => {
    return {
      type: 'session',
      id: sessionId
    }
  })
  
  return {
    data
  }
}