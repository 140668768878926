import * as scoreInputService from 'utils/services/scoreInput';
import * as scoreCommentService from 'utils/services/scoreComment';
import * as scoreService from 'utils/services/score';
import { notification } from 'antd';
import * as userService from 'utils/services/users';
import * as types from '../../types/matchScoresheet';
import { upDateMatchDetails } from './getMatchDetails';
import { convertTimeToTimeAndDateFormat } from 'utils/dateTimeUtils';
import { CURRENT_SCORE_INPUT_TYPE, CURRENT_SCORE_INPUT_RESPONSE_TYPE } from 'shared/constants/scoreConstants';
import { CURRENT_EVENT_SEASON } from 'shared/constants/eventConstants';

const setIsScoreInputNew = (isNewScoreInput, scoreInputId = null) => {
  return {
    type: types.SET_IS_MATCH_SCORE_INPUT_NEW,
    payload: {
      isNewScoreInput,
      scoreInputId
    }
  }
}

const createNewScoreInputBegin = () => {
  return {
    type: types.CREATE_NEW_SCORE_INPUT_BEGIN
  }
}

const createNewScoreInputError = () => {
  return {
    type: types.CREATE_NEW_SCORE_INPUT_ERROR
  }
}

const createNewScoreInputSuccess = () => {
  return {
    type: types.CREATE_NEW_SCORE_INPUT_SUCCESS
  }
}

export const handleNewScoreInput = (payload, cb = () => { }) => {
  return async (dispatch, getState) => {
    await dispatch(createNewScoreInputBegin())
    const { matchScoresheet: { matchDetails: { matchId } } } = getState();
    try {
      const { data, included } = await scoreInputService.createNewScoreInput(payload, matchId);
      const scoresheetScore = included.find(i => i.type === 'score');
      const totalScore = scoresheetScore ? scoresheetScore.attributes.overall_score : 0;

      await dispatch(setIsScoreInputNew(false, data.id))
      // TODO SET THE SCORE INPUT AND THE IS NEW SCORE INPUT VALUES
      await dispatch(createNewScoreInputSuccess())
      cb();
      notification.success({
        message: 'Success',
        description: 'Scoresheet saved',
      });
      return data.id;
    } catch (err) {
      if (err && 'data' in err >= 400) {
        notification.error({
          message: 'Error',
          description: err.data.error,
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Error saving the scoresheet.',
        });
      }
      await dispatch(createNewScoreInputError())
      throw err;
    }

  }
}

const getScoreInputBegin = () => {
  return {
    type: types.GET_MATCH_SCORE_INPUT_BEGIN
  }
}

const getScoreInputError = () => {
  return {
    type: types.GET_MATCH_SCORE_INPUT_ERROR
  }
}

const getScoreInputSuccess = (data) => {
  const keys = Object.keys(data);
  const convertedKeys = ['m11_scoring_1', 'm12_scoring_1', 'm12_scoring_2']
  keys.forEach(key => {
    // if (convertedKeys.includes(key)) {
    //   if (data[key] === null) {
    //     data[key] = false
    //   }
    // }
  });
  return {
    type: types.GET_MATCH_SCORE_INPUT_SUCCESS,
    payload: {
      data
    }
  }
}

export const setScoreInputComments = (data) => {
  return {
    type: types.SET_MATCH_SCORE_COMMENTS,
    payload: {
      data
    }
  }
}

const setMatchScoreData = (overallScore = 0, scoreId = null) => {
  return {
    type: types.SET_MATCH_SCORE_DATA,
    payload: {
      overallScore,
      scoreId
    }
  }
}

const setIsMatchScoreInputPublished = (isPublished) => {
  return {
    type: types.SET_IS_MATCH_SCORE_INPUT_PUBLISHED,
    payload: {
      isPublished
    }
  }
}

export const getMatchScoreInput = (scoreId) => {
  return async (dispatch, getState) => {
    const { matchScoresheet: { eventDetails: { season } } } = getState();
    await dispatch(getScoreInputBegin());
    let scoreResponse;
    try {
      if (season == CURRENT_EVENT_SEASON) {
        scoreResponse = await scoreService.getScoreById2023(scoreId)
      } else {
        scoreResponse = await scoreService.getScoreById(scoreId)
      }

      const score_input_id = (((((scoreResponse || {}).data || {}).relationships || {})[CURRENT_SCORE_INPUT_RESPONSE_TYPE] || {}).data || {}).id || '';

      let data = {}
      if (season == CURRENT_EVENT_SEASON) {
        let responseData = score_input_id ? await scoreInputService.getScoreInputById2023(score_input_id) : {};
        data = responseData.data;
        if (responseData.data.attributes.is_initial_score_input) {
          responseData.data.attributes.m00_scoring_1 = null;
          responseData.data.attributes.m01_scoring_1 = null;
          responseData.data.attributes.m01_scoring_2 = null;
          responseData.data.attributes.m01_scoring_3 = null;
          responseData.data.attributes.m02_scoring_1 = null;
          responseData.data.attributes.m02_scoring_2 = null;
          responseData.data.attributes.m03_scoring_1 = null;
          responseData.data.attributes.m03_scoring_2 = null;
          responseData.data.attributes.m04_scoring_1 = null;
          responseData.data.attributes.m04_scoring_2 = null;
          responseData.data.attributes.m05_scoring_1 = null;
          responseData.data.attributes.m06_scoring_1 = null;
          responseData.data.attributes.m07_scoring_1 = null;
          responseData.data.attributes.m08_scoring_1 = null;
          responseData.data.attributes.m09_scoring_1 = null;
          responseData.data.attributes.m09_scoring_2 = null;
          responseData.data.attributes.m10_scoring_1 = null;
          responseData.data.attributes.m10_scoring_2 = null;
          responseData.data.attributes.m11_scoring_1 = null;
          responseData.data.attributes.m12_scoring_1 = null;
          responseData.data.attributes.m13_scoring_1 = null;
          responseData.data.attributes.m14_scoring_1 = null;
          responseData.data.attributes.m14_scoring_2 = null;
          responseData.data.attributes.m14_scoring_3 = null;
          responseData.data.attributes.m14_scoring_4 = null;
          responseData.data.attributes.m15_scoring_1 = null;
          responseData.data.attributes.m15_scoring_2 = null;
          responseData.data.attributes.precision_tokens = null;
          responseData.data.attributes.gracious_professionalism = 3;
        }
      } else {
        let responseData = score_input_id ? await scoreInputService.getScoreInputById(score_input_id) : {};
        data = responseData.data
      }

      if ((data || {}).attributes) {
        const { attributes, id, relationships } = data;
        await dispatch(setIsMatchScoreInputPublished(Boolean(attributes.is_published)))
        let totalScore = (((scoreResponse || {}).data || {}).attributes || {}).overall_score;
        if (!totalScore) totalScore = 0;

        const scoreComments = ((relationships || {}).score_comments || {}).data || [];
        let formattedScoreComments = [];
        if (scoreComments.length) {
          let scoreInputComments = [];
          const scoreCommentIds = scoreComments.map(s => s.id);

          if (season == CURRENT_EVENT_SEASON) {
            scoreInputComments = await scoreCommentService.getAllScoreComments2023(scoreCommentIds)
          } else {
            scoreInputComments = await scoreCommentService.getAllScoreComments(scoreCommentIds)
          }

          const refereeCommenters = scoreInputComments.map(c => {
            return ((c || {}).attributes || {}).referee_id;
          });
          // IF THERE ARE ALREADY COMMENTS ASSOCIATED WITH THE SCORE INPUT
          if (refereeCommenters.length) {
            const userFilter = {
              'id': `in:${refereeCommenters.join(',')}`
            }

            const usersResponse = await userService.getUsersBy(userFilter);
            const formattedRefereeCommenters = usersResponse.data.map(d => {
              return {
                firstName: d.attributes.first_name,
                lastName: d.attributes.last_name,
                userId: d.id
              }
            });

            formattedScoreComments = scoreInputComments.map(c => {
              const refereeId = c.attributes.referee_id;
              const commenter = formattedRefereeCommenters.find(r => r.userId === refereeId);
              let time = ((c || {}).attributes || {}).updated_at || ((c || {}).attributes || {}).created_at;
              if (time) {
                time = convertTimeToTimeAndDateFormat(time);
              }
              return {
                comment: c.attributes.comment,
                fullName: commenter ? `${commenter.firstName} ${commenter.lastName}` : '',
                refereeId,
                commentId: c.id,
                dateTime: time,
              }
            })
          }
        }
        await dispatch(setScoreInputComments(formattedScoreComments))
        await dispatch(setMatchScoreData(totalScore, scoreId))
        await dispatch(getScoreInputSuccess(attributes))
        await dispatch(setIsScoreInputNew(false, id))
      } else {
        // IF THERE IS NO SCORE INPUT FOR THE MATCH YET
        await dispatch(setIsScoreInputNew(true, null))
        await dispatch(setScoreInputComments([]))
        await dispatch(getScoreInputError())
        await dispatch(setMatchScoreData(0, null))
        await dispatch(setIsMatchScoreInputPublished(false))
      }

    } catch (err) {
      await dispatch(setScoreInputComments([]))
      await dispatch(setMatchScoreData(0, scoreId))
      await dispatch(getScoreInputError())
      throw err;
    }

  }

}

const updateScoreInputBegin = () => {
  return {
    type: types.UPDATE_SCORE_INPUT_BEGIN
  }
}
const updateScoreInputError = () => {
  return {
    type: types.UPDATE_SCORE_INPUT_ERROR
  }
}
const updateScoreInputSuccess = () => {
  return {
    type: types.UPDATE_SCORE_INPUT_SUCCESS
  }
}


export const updateScoreInput = (payload, scoreInputId, successMessage, cb = () => { }) => {
  return async (dispatch, getState) => {
    await dispatch(updateScoreInputBegin());
    const { matchScoresheet: { matchDetails: { matchId }, eventDetails: { season } } } = getState();
    try {
      let data = {}
      if (season == CURRENT_EVENT_SEASON) {
        let responseData = await scoreInputService.updateScoreInput2023(payload, scoreInputId);
        data = responseData.data
      } else {
        let responseData = await scoreInputService.updateScoreInput(payload, scoreInputId);
        data = responseData.data
      }

      if (data && data.attributes) {
        const { attributes } = data;
        await dispatch(getScoreInputSuccess(attributes))
        await dispatch(setIsMatchScoreInputPublished(Boolean(attributes.is_published)))
      }
      if (data && data.relationships) {
        const scoreId = ((((data || {}).relationships || {}).score || {}).data || {}).id;
        if (scoreId) {
          let scoreResponse
          if (season == CURRENT_EVENT_SEASON) {
            scoreResponse = await scoreService.getScoreById2023(scoreId)
          } else {
            scoreResponse = await scoreService.getScoreById(scoreId)
          }

          let totalScore = (((scoreResponse || {}).data || {}).attributes || {}).overall_score || 0;
          await dispatch(setMatchScoreData(totalScore, scoreId))
        }
      }
      /* fetch most recent match status after updating scoresheet */
      await dispatch(upDateMatchDetails(matchId));

      await dispatch(updateScoreInputSuccess());
      cb();
      notification.success({
        message: 'Success',
        description: successMessage,
      });
    } catch (err) {
      await dispatch(updateScoreInputError());
      if (err.status === 400) {
        notification.error({
          message: 'Error',
          description: err.data,
        });
      } else {
        notification.error({
          message: 'Error',
        });
      }
      throw err;
    }

  }
}

const createRefereeNoteBegin = () => {
  return {
    type: types.CREATE_NEW_SCORE_COMMENT_BEGIN
  }
}

const createRefereeNoteError = () => {
  return {
    type: types.CREATE_NEW_SCORE_COMMENT_ERROR
  }
}

const createRefereeNoteSuccess = () => {
  return {
    type: types.CREATE_NEW_SCORE_COMMENT_SUCCESS
  }
}

export const createRefereeNote = (comment, fullName, cb = () => { }) => {
  return async (dispatch, getState) => {

    const {
      auth: {
        userId
      },
      matchScoresheet: {
        scoreInputId,
        matchScoreComments,
        eventDetails: {
          season
        },
        matchDetails: {
          matchId,
          scoreId
        }
      } } = getState();
    await dispatch(createRefereeNoteBegin());
    const matchScoreCommentsCopy = [...matchScoreComments];
    try {
      const payload = {
        data: {
          type: season == CURRENT_EVENT_SEASON ? 'score_comment_2025' : 'score_comment_2022',
          attributes: {
            match_id: matchId,
            comment,
            referee_id: userId,
          },
          relationships: {
            [season == CURRENT_EVENT_SEASON ? 'score_input_2025' : 'score_input_2022']: {
              data: {
                type: season == CURRENT_EVENT_SEASON ? 'score_input_2025' : 'score_input_2022',
                id: scoreInputId
              }
            }
          }
        }
      }

      let data;
      if (season == CURRENT_EVENT_SEASON) {
        const responseData = await scoreCommentService.createNewScoreComment2023(payload)
        data = responseData.data
      } else {
        const responseData = await scoreCommentService.createNewScoreComment(payload)
        data = responseData.data
      }

      const { attributes, id } = data;
      await dispatch(createRefereeNoteSuccess());
      let time = (attributes || {}).updated_at || (attributes || {}).created_at;
      if (time) {
        time = convertTimeToTimeAndDateFormat(time);
      }
      const newNote = {
        fullName,
        refereeId: attributes.referee_id,
        comment: attributes.comment,
        commentId: id,
        dateTime: time,
      }

      matchScoreCommentsCopy.push(newNote);
      await dispatch(setScoreInputComments(matchScoreCommentsCopy))
      notification.success({
        message: 'Success',
        description: 'Note successfully saved.',
      });
      cb();
    } catch (err) {
      notification.error({
        message: 'Error',
      });
      await dispatch(createRefereeNoteError());
      throw err;
    }
  }

}

// const deleteRefereeNoteBegin = () => {}
// const deleteRefereeNoteError = () => {}
// const deleteRefereeNoteSuccess = () => {}

export const deleteRefereeNote = (commentId) => {
  return async (dispatch, getState) => {
    const {
      matchScoresheet: {
        matchScoreComments,
        eventDetails: { season }
      } } = getState();
    const matchCommentsCopy = [...matchScoreComments];
    try {
      const updatedCommentsList = matchCommentsCopy.filter(comment => comment.commentId !== commentId);

      await dispatch(setScoreInputComments(updatedCommentsList))

      if (season == CURRENT_EVENT_SEASON) {
        await scoreCommentService.deleteScoreComment2023(commentId);
      } else {
        await scoreCommentService.deleteScoreComment(commentId);
      }


      notification.success({
        message: 'Success',
        description: 'Note successfully deleted.'
      });
    } catch (err) {
      await dispatch(setScoreInputComments(matchCommentsCopy))
      notification.error({
        message: 'Error',
      });
      console.log(err)

      throw err;
    }

  }

}



const recalculateScoreBegin = () => {
  return {
    type: types.RECALCULATE_MATCH_SCORE_BEGIN
  }
}

const recalculateScoreError = () => {
  return {
    type: types.RECALCULATE_MATCH_SCORE_ERROR
  }
}

const recalculateScoreSuccess = (updatedScore) => {
  return {
    type: types.RECALCULATE_MATCH_SCORE_SUCCESS,
    payload: {
      updatedScore
    }
  }
}

// function timeout(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

export const handleRecalculateScore = (payload, scoreInputId = undefined) => {
  return async (dispatch, getState) => {
    
    const { matchScoresheet: { matchDetails: { matchId }, eventDetails: { season } } } = getState();
    try {
      await dispatch(recalculateScoreBegin());
      let totalScore;
      if (scoreInputId) {
        let data;
        let included;
        if (season == CURRENT_EVENT_SEASON) {
          let responseData = await scoreInputService.updateScoreInput2023(payload, scoreInputId);
          data = responseData.data
          included = responseData.included
        } else {
          let responseData = await scoreInputService.updateScoreInput(payload, scoreInputId);
          data = responseData.data
          included = responseData.included
        }


        const scoresheetScoreId = data.relationships.score.data.id
        let scoreRes
        if (season == CURRENT_EVENT_SEASON) {
          scoreRes = await scoreService.getScoreById2023(scoresheetScoreId);
        } else {
          scoreRes = await scoreService.getScoreById(scoresheetScoreId);
        }

        totalScore = scoreRes.data.attributes ? scoreRes.data.attributes.overall_score : '';
      }
      if (!scoreInputId) {
        //console.log('inside scoreinput.js handleNewScoreInput !scoreinput');
        const { data, included } = await scoreInputService.createNewScoreInput(payload, matchId);
        const scoresheetScore = included.find(i => i.type === 'score');
        totalScore = scoresheetScore ? scoresheetScore.attributes.overall_score : 0;
        await dispatch(setIsScoreInputNew(false, data.id));
      }
      // await timeout(5000)
      await dispatch(recalculateScoreSuccess(totalScore))


    } catch (err) {
      await dispatch(recalculateScoreError())
      throw err;
    }

  }
}

export const handleCalculateScoresheetScore = (payload) => {
  return async dispatch => {
    await dispatch(recalculateScoreBegin());
    let totalScore;
    let included;

    try {
      const formated_data = {
        "data": {
          "type": "generate_public_score",
          "attributes": { ...payload }
        }
      }
      const data = await scoreInputService.getPublicScoringResult2023(formated_data);
      console.log(data)
      const overallScore = ((data || {}).attributes || {}).overall_score || 0;
      await dispatch(recalculateScoreSuccess(overallScore))
    } catch (e) {
      console.log('recalculating score eeee', e)
      await dispatch(recalculateScoreError());
    }
  }
}

const setIsScoresheetEdited = (isScoresheetEdited) => {
  return {
    type: types.SET_IS_MATCH_SCORESHEET_EDITED,
    payload: {
      isScoresheetEdited
    }
  }
}

export const resetScoreInput = () => {
  return {
    type: types.RESET_SCORE_INPUT
  }
}

export const handleSetIsScoresheetEdited = (isEdited) => {
  return async dispatch => {
    await dispatch(setIsScoresheetEdited(isEdited))
  }
}