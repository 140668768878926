import React from 'react';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { Avatar} from 'antd';
import './styles/UserAutocompleteOption.scss';


const UserAutocompleteOption = (props) => {

  const {
    name,
    email
  } = props;

  return (
    <div className='user-autocomplete-option'>
      <div>
        <Avatar size={48} icon={<UserOutlined />} className='user-autocomplete-option__avatar' />
      </div>
      <div>
        <p className='user-autocomplete-option__name'>{name}</p>
        <p className='link--email'>{email}</p>
      </div>
    
    </div>
  );
};

UserAutocompleteOption.propTypes ={
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
}

export default UserAutocompleteOption;