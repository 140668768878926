import React from 'react';

export default () => (
  <svg width="69px" height="61px" viewBox="0 2 116 101">
    <title>First Tech Challenge</title>
    <defs>
      <polygon id="path-13" points="0.038426501 0.0194230769 65.5114203 0.0194230769 65.5114203 27.1923077 0.038426501 27.1923077" />
    </defs>
    <g id="Dashboards" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Dashboard---PDP-Admin" transform="translate(-1013.000000, -651.000000)">
        <g id="Group-18" transform="translate(310.000000, 238.000000)">
          <g id="Group-17" transform="translate(0.000000, 64.000000)">
            <g id="Group-10" transform="translate(0.000000, 349.000000)">
              <g id="Logos/FTC-White-Orange-Field" transform="translate(703.000000, 0.000000)">
                <g id="Group-43" transform="translate(0.000000, 2.000000)" fill="#F57E25" fillRule="nonzero">
                  <rect id="Rectangle" x="0" y="0" width="116" height="101" />
                </g>
                <g id="FIRSTTech_IconVert_OneColor_Reverse" transform="translate(11.768116, 0.316667)">
                  <line x1="30.2275666" y1="19.87175" x2="30.2275666" y2="19.87175" id="Fill-2" fill="#FFFFFE" />
                  <polyline id="Fill-4" fill="#FFFFFE" points="33.4381008 50.57575 29.954098 50.57575 29.954098 59.7376154 26.9113596 59.7376154 26.9113596 50.57575 23.4273568 50.57575 23.4273568 47.9296795 33.4381008 47.9296795 33.4381008 50.57575" />
                  <polyline id="Fill-5" fill="#FFFFFE" points="33.7961077 47.9296795 43.1049275 47.9296795 43.1049275 50.3938205 36.8388461 50.3938205 36.8388461 52.4610833 42.5643948 52.4610833 42.5643948 54.8423526 36.8388461 54.8423526 36.8388461 57.0921923 43.2688806 57.0921923 43.2688806 59.7376154 33.7961077 59.7376154 33.7961077 47.9296795" />
                  <path d="M51.7720248,52.1470769 C51.6093527,50.9564423 50.6922402,50.3277821 49.4164803,50.3277821 C47.454167,50.3277821 46.7010076,52.080391 46.7010076,53.833 C46.7010076,55.5875513 47.454167,57.3401603 49.4164803,57.3401603 C50.8401822,57.3401603 51.6580262,56.5127372 51.7720248,55.156359 L54.8154037,55.156359 C54.6514507,58.1999551 52.4438482,59.9855833 49.4818054,59.9855833 C45.9491291,59.9855833 43.6589096,57.2572885 43.6589096,53.833 C43.6589096,50.4100064 45.9491291,47.6817115 49.4818054,47.6817115 C52.0006625,47.6817115 54.7340676,49.3022436 54.8154037,52.1470769 L51.7720248,52.1470769" id="Fill-6" fill="#FFFFFE" />
                  <g id="Group-10" transform="translate(0.000000, 47.910256)">
                    <mask id="mask-21" fill="white">
                      <use xlinkHref="#path-13" />
                    </mask>
                    <g id="Clip-8" />
                    <polyline id="Fill-7" fill="#FFFFFE" mask="url(#mask-21)" points="55.4180594 0.0194230769 58.4601573 0.0194230769 58.4601573 4.31969231 62.4680414 4.31969231 62.4680414 0.0194230769 65.5114203 0.0194230769 65.5114203 11.827359 62.4680414 11.827359 62.4680414 6.96511538 58.4601573 6.96511538 58.4601573 11.827359 55.4180594 11.827359 55.4180594 0.0194230769" />
                    <path d="M8.11439614,19.3538013 C7.95044306,18.1631667 7.03461146,17.5345064 5.75885162,17.5345064 C3.7965383,17.5345064 3.04337888,19.2877628 3.04337888,21.0403718 C3.04337888,22.7936282 3.7965383,24.5468846 5.75885162,24.5468846 C7.18255349,24.5468846 8.00039752,23.720109 8.11439614,22.3630833 L11.157775,22.3630833 C10.9938219,25.4066795 8.78493858,27.1923077 5.82417667,27.1923077 C2.29021946,27.1923077 0,24.4633654 0,21.0403718 C0,17.6173782 2.29021946,14.8884359 5.82417667,14.8884359 C8.34303382,14.8884359 11.075158,16.5096154 11.157775,19.3538013 L8.11439614,19.3538013" id="Fill-9" fill="#FFFFFE" mask="url(#mask-21)" />
                  </g>
                  <polyline id="Fill-11" fill="#FFFFFE" points="11.7591498 63.0466603 14.8025286 63.0466603 14.8025286 67.3469295 18.8104127 67.3469295 18.8104127 63.0466603 21.8537916 63.0466603 21.8537916 74.8545962 18.8104127 74.8545962 18.8104127 69.993 14.8025286 69.993 14.8025286 74.8545962 11.7591498 74.8545962 11.7591498 63.0466603" />
                  <path d="M26.3003782,70.4552692 L28.9511663,70.4552692 L27.6581146,66.3220385 L27.625452,66.3220385 L26.3003782,70.4552692 Z M26.104403,63.0466603 L29.1964555,63.0466603 L33.5642678,74.8545962 L30.3908792,74.8545962 L29.6703823,72.7381282 L25.5978137,72.7381282 L24.8606653,74.8545962 L21.7359503,74.8545962 L26.104403,63.0466603 L26.104403,63.0466603 Z" id="Fill-12" fill="#FFFFFE" />
                  <polyline id="Fill-13" fill="#FFFFFE" points="33.463078 63.0466603 36.5064569 63.0466603 36.5064569 72.2085256 41.9540538 72.2085256 41.9540538 74.8545962 33.463078 74.8545962 33.463078 63.0466603" />
                  <polyline id="Fill-14" fill="#FFFFFE" points="42.4753734 63.0466603 45.5174714 63.0466603 45.5174714 72.2085256 50.9650683 72.2085256 50.9650683 74.8545962 42.4753734 74.8545962 42.4753734 63.0466603" />
                  <polyline id="Fill-15" fill="#FFFFFE" points="51.4870283 63.0466603 60.7958482 63.0466603 60.7958482 65.5114487 54.5304072 65.5114487 54.5304072 67.5787115 60.2553154 67.5787115 60.2553154 69.9599808 54.5304072 69.9599808 54.5304072 72.2085256 60.9598012 72.2085256 60.9598012 74.8545962 51.4870283 74.8545962 51.4870283 63.0466603" />
                  <polyline id="Fill-16" fill="#FFFFFE" points="61.6764555 63.0466603 64.8011705 63.0466603 68.8577281 70.3231923 68.8903906 70.3231923 68.8903906 63.0466603 71.7531649 63.0466603 71.7531649 74.8545962 68.62845 74.8545962 64.5718923 67.4951923 64.5398703 67.4951923 64.5398703 74.8545962 61.6764555 74.8545962 61.6764555 63.0466603" />
                  <path d="M83.6275942,74.8545962 L81.664,74.8545962 L81.4853168,73.5649038 C80.6834838,74.7056859 79.3084555,75.1025641 78.2293112,75.1025641 C74.695354,75.1025641 72.4051346,72.3736218 72.4051346,68.9506282 C72.4051346,65.5276346 74.695354,62.7986923 78.2293112,62.7986923 C80.6508213,62.7986923 83.2350035,64.1052179 83.5129551,67.0652949 L80.4708571,67.0652949 C80.3715887,66.0565897 79.3910725,65.4447628 78.1639862,65.4447628 C76.200392,65.4447628 75.4485135,67.1980192 75.4485135,68.9506282 C75.4485135,70.7038846 76.200392,72.457141 78.1639862,72.457141 C79.6690242,72.457141 80.6834838,71.7449615 80.8467964,70.6702179 L78.7192491,70.6702179 L78.7192491,68.3886538 L83.6275942,68.3886538 L83.6275942,74.8545962" id="Fill-17" fill="#FFFFFE" />
                  <polyline id="Fill-18" fill="#FFFFFE" points="84.5908185 63.0466603 93.8983575 63.0466603 93.8983575 65.5114487 87.6329165 65.5114487 87.6329165 67.5787115 93.359746 67.5787115 93.359746 69.9599808 87.6329165 69.9599808 87.6329165 72.2085256 94.0623106 72.2085256 94.0623106 74.8545962 84.5908185 74.8545962 84.5908185 63.0466603" />
                  <polyline id="Fill-19" fill="#FFFFFE" points="23.0879227 31.1656218 32.4313264 31.1656218 31.5513596 34.8022692 27.0733913 34.8022692 26.6302057 36.7135 31.3022277 36.7135 30.4888668 40.0834038 25.8776867 40.0834038 24.770363 44.8711923 19.9439945 44.8711923 23.0879227 31.1656218" />
                  <polyline id="Fill-20" fill="#FFFFFE" points="33.7013223 31.1656218 38.5276908 31.1656218 35.3837626 44.8711923 30.5573941 44.8711923 33.7013223 31.1656218" />
                  <path d="M43.3553402,37.7001923 C44.9052091,37.7001923 45.9901173,36.7750064 45.9901173,35.4600641 C45.9901173,34.7614808 45.4803257,34.4118654 44.4844389,34.4118654 C44.1962402,34.4118654 44.0412533,34.4325833 43.7312795,34.4940897 L43.0005355,37.6587564 C43.1561629,37.6794744 43.1779379,37.7001923 43.3553402,37.7001923 Z M39.7906418,31.2478462 L40.1006156,31.2064103 C41.6504845,31.0212436 43.5762926,30.8982308 44.6394258,30.8982308 C46.4102471,30.8982308 47.783354,31.1041154 48.8240718,31.5353077 C50.1523478,32.0901603 50.9496977,33.3021603 50.9496977,34.7614808 C50.9496977,36.0149167 50.5289275,37.2068462 49.7315776,38.1313846 C49.0450242,38.9322628 48.4250766,39.3226667 47.140991,39.7137179 L46.8310173,39.8160128 C46.9642291,39.9189551 47.0084196,39.960391 47.0750255,40.0218974 C47.4509648,40.4530897 48.5800635,42.6725 49.5324003,44.8711923 L44.2186556,44.8711923 C43.5321021,42.9191731 43.3329248,42.405109 42.4254189,40.1863462 L41.3622857,44.8711923 L36.6684886,44.8711923 L39.7906418,31.2478462 L39.7906418,31.2478462 Z" id="Fill-21" fill="#FFFFFE" />
                  <polyline id="Fill-22" fill="#FFFFFE" points="62.4071994 31.1656218 74.1183161 31.1656218 73.300472 35.133109 69.8241546 35.133109 67.5217667 44.8711923 62.6953982 44.8711923 64.9977861 35.133109 61.2300676 35.133109 62.4071994 31.1656218" />
                  <path d="M69.7485825,43.5724359 C69.8536149,43.5724359 69.9125355,43.5219359 69.9125355,43.4254679 C69.9125355,43.3335321 69.858098,43.2823846 69.7530656,43.2823846 C69.7306501,43.2823846 69.726167,43.2823846 69.7031111,43.2875641 L69.7031111,43.5679038 C69.7216839,43.5724359 69.7306501,43.5724359 69.7485825,43.5724359 Z M69.3252505,43.0020449 C69.3848116,42.9975128 69.3937778,42.9929808 69.5122595,42.9839167 C69.6301008,42.9748526 69.6806957,42.9696731 69.7306501,42.9696731 C70.1174769,42.9696731 70.3083285,43.1218205 70.3083285,43.4118718 C70.3083285,43.5866795 70.2359586,43.6922115 70.0809717,43.7517756 L70.0630393,43.7569551 C70.0764886,43.7660192 70.0809717,43.7705513 70.0854548,43.7796154 C70.1264431,43.8165192 70.2628571,44.0236987 70.3768558,44.2121026 L69.9355914,44.2121026 C69.8446487,44.0418269 69.8171097,43.995859 69.7031111,43.8029231 L69.7031111,44.2121026 L69.3252505,44.2121026 L69.3252505,43.0020449 Z M69.7806046,44.6536538 C70.3537999,44.6536538 70.813637,44.1887949 70.813637,43.6138718 C70.813637,43.0344167 70.3537999,42.5695577 69.7806046,42.5695577 C69.2118923,42.5695577 68.7520552,43.0344167 68.7520552,43.6093397 C68.7520552,44.1939744 69.2074092,44.6536538 69.7806046,44.6536538 Z M69.7806046,42.4225897 C70.4312933,42.4225897 70.9590173,42.9515449 70.9590173,43.6093397 C70.9590173,44.2716667 70.4357764,44.8012692 69.7806046,44.8012692 C69.1209496,44.8012692 68.6066749,44.2807308 68.6066749,43.6093397 C68.6066749,42.9470128 69.1254327,42.4225897 69.7806046,42.4225897 L69.7806046,42.4225897 Z" id="Fill-23" fill="#FFFFFE" />
                  <path d="M50.007608,44.5228718 C51.3640635,44.930109 52.8479669,45.1586538 54.2345231,45.1586538 C56.1167812,45.1586538 57.5558537,44.7274615 58.7957488,43.8229936 C60.1682153,42.8369487 61.0539462,41.3161218 61.0539462,39.9396731 C61.0539462,39.0144872 60.6555914,38.1721731 59.9472629,37.5765321 C59.3932809,37.1246218 58.8175238,36.7750064 57.6442346,36.2201538 L56.6925383,35.7682436 C56.3819241,35.6037949 56.2275776,35.4393462 56.2275776,35.2541795 C56.2275776,34.7614808 56.8468847,34.3917949 57.6884251,34.3917949 C58.3179793,34.3917949 59.0679365,34.5763141 60.5018854,35.0502372 L61.4907274,31.3404295 C60.418628,31.0458462 59.3740676,30.8982308 58.3301477,30.8982308 C54.38951,30.8982308 51.3563782,33.0762051 51.3563782,35.8912564 C51.3563782,37.3706474 52.33049,38.4188462 54.7660897,39.5699872 C55.9169634,40.1041218 56.1167812,40.2478526 56.1167812,40.6175385 C56.1167812,41.172391 55.5186087,41.5220064 54.5444969,41.5220064 C53.6811815,41.5220064 52.5738578,41.2546154 51.0688199,40.6997628 L51.0668986,40.7004103 L50.007608,44.5228718" id="Fill-24" fill="#FFFFFE" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
