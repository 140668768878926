const rd3Section = {
  id: 'RD3',
  label: 'rd3Label',
  title: 'createLabel',
  description: 'create1DescriptionLabel',
  fields: [
    {
      legendTitle: 'ChallengeRubricsFunctionalityLabel',
      id: 'robot_design_3_create_purpose',
      isCoreValue: false,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricsFunctionality1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricsFunctionality2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricsFunctionality3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricsFunctionality4Label',
      }]
    },
    {
      legendTitle: 'ChallengeRubricsModelLabel',
      id: 'robot_design_3_create_code_sensor',
      isCoreValue: false,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricsModel1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricsModel2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricsModel3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricsModel4Label',
      }]
    },
  ]
}

export default rd3Section;

