import { CURRENT_SCORE_INPUT_RESPONSE_TYPE } from "shared/constants/scoreConstants";

/* eslint-disable import/prefer-default-export */
export const formatScoreRes = (data) => {
  const {attributes, id, relationships, type} = data;

  return {
    scoreInputId: (((relationships||{})[CURRENT_SCORE_INPUT_RESPONSE_TYPE]||{}).data||{}).id,
    matchId: (((relationships||{}).match||{}).data||{}).id,
    scoreId: id,
    type,
    totalScore: (attributes||{}).overall_score
  }
}