import { Modal } from 'antd';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import ScreenLoading from 'shared/components/Spinner/ScreenLoading';
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';

const PDFModal = (props)=>{
  const {file, visible, onCancel} = props;
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoaded = ({numPages:page})=>{
    setNumPages(page);
  }

  if(!file) return null;
  
  return (
    (<Modal 
      open={visible} 
      onCancel={onCancel} 
      footer={null} 
      width="800px" 
      bodyStyle={{ 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Document
        class="evidence_pdf_modal"
        file={file.src}
        loading={<ScreenLoading loading title="Loading PDF" subTitle={file.fileName} />}
        onLoadSuccess={onDocumentLoaded}
        externalLinkTarget='_blank'
      >
        {Array.from(
          new Array(numPages),
          (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ),
        )}
      </Document>
    </Modal>)
  );
}

export default PDFModal;