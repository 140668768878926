import React from 'react';
import Paragraph from 'shared/components/Paragraph';
import './styles/ListLoading.scss';

export default ({ loading, text }) => {
  return loading ? (
    <div className='c-listloading__container'>
      <div className='c-listloading__spinner'>
        <div className='c-listloading__spinner-wrapper'>

          <div />
          <div />
          <div />
        </div>
      </div>
      <Paragraph size='large' className='m-t-24'>
        {text}
      </Paragraph>
    </div>
  ) : null;
};
