import { stringify, parse } from 'qs';
import { mapKeys, camelCase } from 'lodash';

export const queryStringify = ({ filter, page }) => {
  if (!filter && !page) return '';
  const filters = {
    ...(filter && { filter }),
    ...(page && { page }),
  };
  return stringify(filters, {
    encode: true, 
    addQueryPrefix: true,
    encodeValuesOnly: true
  });
};

export const queryParse = parse;

export const formatAttributes = (attributes) => {
  return mapKeys(attributes, (v, key) => camelCase(key));
};

export const getAttributesPayload = (data, attributes = []) => {
  const result = {};
  if (!attributes.length) {
    return mapKeys(data, (v, key) => key.split(/(?=[A-Z])/).join('_').toLowerCase());
  }
  attributes.forEach(attr => {
    const key = camelCase(attr);
    result[attr] = data[attr] || data[key];
  });
  return result;
};

export const formatDataResult = (data) => {
  const { id, attributes } = data;
  const attr = formatAttributes(attributes);
  return { id, ...attr };
};

export const parallelRequest = async (requests) => {
  return await new Promise((resolve, reject) => {
    Promise.all(requests)
    .then((values) => {
      resolve(values);
    })
    .catch((errors) => {
      reject(errors);
    });
  });
};
