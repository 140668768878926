import * as types from '../types/coachScoringTypes';

const DEFAULT_SCORE_INPUT_STATE = {
  event_id: undefined,
  is_published: undefined,
    m00_scoring_1: undefined,
    m01_scoring_1: undefined,
    m02_scoring_1: undefined,
    m02_scoring_2: undefined,
    m03_scoring_1: undefined,
    m04_scoring_1: undefined,
    m04_scoring_2: undefined,
    m05_scoring_1: undefined,
    m06_scoring_1: undefined,
    m06_scoring_2: undefined,
    m07_scoring_1: undefined,
    m08_scoring_1: undefined,
    m09_scoring_1: undefined,
    m09_scoring_2: undefined,
    m10_scoring_1: undefined,
    m11_scoring_1: undefined,
    m12_scoring_1: undefined,
    m12_scoring_2: undefined,
    m13_scoring_1: undefined,
    m13_scoring_2: undefined,
    m14_scoring_1: undefined,
    m14_scoring_2: undefined,
    m15_scoring_1: undefined,
    m16_scoring_1: undefined,
    gracious_professionalism: undefined,
  match_id: undefined,
  precision_tokens: 0,
  score_id: undefined,
  totalScore: 0
}
const DEFAULT_STATE = {
  scoringTimestamps: [],
  isFetchingScoringTimestamps: false,
  coachMatches: [],
  isFetchingCoachMatches: false,
  isUploadingFile: false,
  uploadRetry: 0,
  isRemovingFile: false,
  selectedScoreInput: DEFAULT_SCORE_INPUT_STATE,
  isFetchingSelectedScoreInput: false,
}


export default (state = DEFAULT_STATE, action) => {
  const {type} = action;
  switch(type) {
    case types.GET_COACH_SCORING_TIMESTAMPS_BEGIN:
      return {
        ...state,
        isFetchingScoringTimestamps: true
      }
    case types.GET_COACH_SCORING_TIMESTAMPS_ERROR:
      return {
        ...state,
        isFetchingScoringTimestamps: false,
        scoringTimestamps: []
      }
    case types.GET_COACH_SCORING_TIMESTAMPS_SUCCESS:
      return {
        ...state,
        isFetchingScoringTimestamps: false,
        scoringTimestamps: action.payload.scoringTimestamps
      }
    case types.GET_COACH_MATCHES_BEGIN:
      return {
        ...state,
        isFetchingCoachMatches: true
      }
    case types.GET_COACH_MATCHES_ERROR:
      return {
        ...state,
        isFetchingCoachMatches: false,
        coachMatches: []
      }
    case types.GET_COACH_MATCHES_SUCCESS:
      return {
        ...state,
        isFetchingCoachMatches: false,
        coachMatches: action.payload.coachMatches
      }
    case types.UPLOAD_COACH_MATCH_FILE_BEGIN:
      return {
        ...state,
        isUploadingFile: true,
        coachMatches: action.payload.coachMatches,
        uploadRetry: 0,
      }
    case types.UPLOAD_COACH_MATCH_FILE_ERROR:
      return {
        ...state,
        isUploadingFile: false,
        coachMatches: action.payload.coachMatches,
        uploadRetry: 0,
      }
    case types.UPLOAD_COACH_MATCH_FILE_SUCCESS:
      return {
        ...state,
        isUploadingFile: false,
        coachMatches: action.payload.coachMatches,
        uploadRetry: 0,
      }
    case types.UPLOAD_COACH_MATCH_FILE_RETRY:
      return {
        ...state,
        uploadRetry: action.payload.uploadRetry
      }
    case types.REMOVE_COACH_MATCH_FILE_BEGIN:
      return {
        ...state,
        isRemovingFile: true
      }
    case types.REMOVE_COACH_MATCH_FILE_ERROR:
      return {
        ...state,
        isRemovingFile: false
      }
    case types.REMOVE_COACH_MATCH_FILE_SUCCESS:
      return {
        ...state,
        isRemovingFile: false,
        coachMatches: action.payload.coachMatches
      }
    case types.GET_SELECTED_MATCH_SCORE_INPUT_BEGIN:
      return {
        ...state,
        isFetchingSelectedScoreInput: true
      }
    case types.GET_SELECTED_MATCH_SCORE_INPUT_ERROR:
      return {
        ...state,
        isFetchingSelectedScoreInput: false
      }
    case types.GET_SELECTED_MATCH_SCORE_INPUT_SUCCESS:
      return {
        ...state,
        isFetchingSelectedScoreInput: false,
        selectedScoreInput: {
          ...DEFAULT_SCORE_INPUT_STATE,
          ...action.payload.scoreInputData
        }
      }
    case types.RESET_SELECTED_MATCH_SCORE_INPUT:
      return {
        ...state,
        selectedScoreInput: DEFAULT_SCORE_INPUT_STATE
      }
    default:
      return state;
  }
}