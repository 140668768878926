import { CURRENT_SCORE_INPUT_TYPE, SCORE_INPUT_TYPE_2025 } from 'shared/constants/scoreConstants';
import { API } from 'utils/api';
import { queryStringify } from 'utils/query';

//const { http } = API;

const baseUrl = '/api/score_input';
const baseUrl2023 = '/api/score_input';
const publicScoreBaseUrl = '/api/score_input_2022/commands';


export const getScoreInputById = async (score_input_id) => {
  const url = `${baseUrl2023}/${score_input_id}`;
  const response = await API.GetInstance().http.get(url);
  const { data, included } = response;
  return {
    data,
    included: included || []
  }
}

export const getScoreInputById2023 = async (score_input_id) => {
  const url = `${baseUrl2023}/${score_input_id}`;
  const response = await API.GetInstance().http.get(url, { headers: { 'api-version': 2.0 } });
  const { data, included } = response;
  return {
    data,
    included: included || []
  }
}

export const getScoreInputsBy = async (filter) => {
  const queryStr = queryStringify({ filter });
  const url = `${baseUrl}${queryStr}`;
  const { data, included } = await API.GetInstance().http.get(url);
  return { data, included: included || [] };
}

export const createNewScoreInput = async (payload, matchId) => {
  const url = `${baseUrl}/${matchId}`;
  //console.log('Testing createNewScoreInput');
  //console.log(url);
  //console.log(payload);
  const { data, included } = await API.GetInstance().http.post(url, payload, { headers: { 'api-version': 2.0 } });
  return {
    data: data || {},
    included: included || []
  }
}

export const updateScoreInput = async (payload, scoreInputId) => {
  const url = `${baseUrl2023}/${scoreInputId}`;
  const { data, included } = await API.GetInstance().http.patch(url, payload);
  return {
    data: data || {},
    included: included || []
  }
}

export const updateScoreInput2023 = async (payload, scoreInputId) => {
  payload.data.type = SCORE_INPUT_TYPE_2025
  const url = `${baseUrl2023}/${scoreInputId}`;
  const { data, included } = await API.GetInstance().http.patch(url, payload, { headers: { 'api-version': 2.0 } });
  return {
    data: data || {},
    included: included || []
  }
}

export const getPublicScoringResult = async (payload) => {
  const url = `${publicScoreBaseUrl}`;
  const { data } = await API.GetInstance().http.post(url, payload)
  return data;
}

export const getPublicScoringResult2023 = async (payload) => {
  const url = `${baseUrl2023}/commands`;
  const { data } = await API.GetInstance().http.post(url, payload, { headers: { 'api-version': 2.0 } })
  return data;
}