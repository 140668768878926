import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PageLayout from 'shared/components/Layouts/SubpageLayout';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import store from 'features/store';
import {Form} from 'antd';

import {
  getEventDetails,
  getMatchDetails,
  getMatchScoreInput,
  handleSetIsScoresheetEdited,
  resetMatchDetails
} from 'features/EventOverviewContainer/redux/actions/matchScoresheet';
import { newFllScoringOptions } from 'shared/constants/2025Scoresheet';
import { newFllScoringOptions as OldFllScoringOptions } from 'shared/constants/newScoresheetOptions';
import Container from 'shared/components/Container';
import ScoringMatchHeader from './ScoringMatchHeader';
import EvidenceCarousel from './EvidenceCarousel';
import ModuleSection from './ScoresheetForm/FormModuleSection';
import '../../../../shared/components/Scoresheet/styles/MatchScoresheet.scss';
import RefereeNotes from './ScoresheetForm/RefereeNotes';
import { RESET_MATCH_DETAILS } from 'features/EventOverviewContainer/redux/types/matchScoresheet';
import { CURRENT_EVENT_SEASON } from 'shared/constants/eventConstants';
import NoEquipmentContraintSymbol from 'shared/components/Icon/pngs/NoEquipmentConstraintSymbol.png';


const titleClassName = 'heading--charcoal h-2 h--normal m-t-36 m-b-36';
const subTitleClassName = 'heading--charcoal h-4 h--normal m-b-36';
const ScoringMatch = props => {
  const { pageDetails, loadingScoreInput, handleSetIsScoresheetEdited, resetMatchDetails, season } = props;

  let seasonData = season == CURRENT_EVENT_SEASON ? newFllScoringOptions : OldFllScoringOptions;

  const { eventId, matchId } = useParams();
  const {
    eventDetails,
    matchDetails,
    isFetchingEvent,
    isFetchingMatch,
    teamDetails,
    isFetchingMatchScoreInput,
  } = pageDetails;
  const {
    files,
    scoreId,
    matchStatusName,
    matchTypeName,
    matchNumber,
    matchTypeId,
    matchStatusId
  } = matchDetails;

  const {
    teamName,
    teamNumber
  } = teamDetails

    const symbolDivStyle = {
        display: 'flex',
        flexDirection: 'row',
    };
    const imgStyle = {
        objectFit: 'contain',
        marginBottom: 'auto'
    };

  const { t } = useTranslation();
  const [showError, setShowError] = useState(false); // control error status for m15 and m16
  const [form] = Form.useForm();

  useEffect(() => {
    resetMatchDetails()
    handleSetIsScoresheetEdited(false)
  }, [])

  useEffect(() => {
    if (eventId) {
      props.getEventDetails(eventId)
      window.scrollTo(0, 0)
    }
  }, [eventId])
  useEffect(() => {
    if (matchId) {
      props.getMatchDetails(matchId);
    }
  }, [matchId])

  return (
    <PageLayout
      loading={Boolean(isFetchingEvent || isFetchingMatch || isFetchingMatchScoreInput)}
      headerData={{
        title: eventDetails.eventName,
        eventLocation: eventDetails.eventLocation,
        dateString: eventDetails.eventDate,
        programId: eventDetails.programId,
        hasSubheading: true,
        subHeading: teamNumber ? `${teamNumber} - ${teamName}` : `${teamName}`
      }}
    >
      <ScoringMatchHeader eventId={eventId} form={form}/>
      {files.length ? (
        <EvidenceCarousel files={files} matchId={matchId} />
      ) : (null)}
      <Container className='match-scoresheet__form'>
        <h2 className={titleClassName}>{t('scoresheetHeaderLabel')}</h2>
            <div style={symbolDivStyle}><img
            src={NoEquipmentContraintSymbol}
            alt='NoEquipmentContraintSymbol'
            className='m-r-10'
            style={imgStyle}
        /><h1 className={subTitleClassName}>{t('noEquipmentContraintHeaderLabel')}</h1></div>
        <Form form={form} onChange={() => handleSetIsScoresheetEdited(true)} className="match-scoresheet-referee">
          {seasonData.map(i => (
            <li key={i.id}>
              <Form.Item>
                <ModuleSection form={form} moduleName={i.id} moduleTitle={i.name} fields={i.fields} showError={showError} setShowError={setShowError} />
              </Form.Item>
            </li>
          ))}
        </Form>
        <RefereeNotes extraClass="match-scoresheet__referee-notes" />

      </Container>
    </PageLayout>
  );
};
ScoringMatch.propTypes = {
  match: PropTypes.object.isRequired,
  getEventDetails: PropTypes.func.isRequired,
  getMatchDetails: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  getMatchScoreInput: PropTypes.func.isRequired,
  loadingScoreInput: PropTypes.bool.isRequired
};
const mapDispatchToProps = {
  getEventDetails,
  getMatchDetails,
  getMatchScoreInput,
  handleSetIsScoresheetEdited,
  resetMatchDetails
}
const mapStateToProps = (state) => {
  const { matchScoresheet } = state;
  const { isFetchingMatchScoreInput } = matchScoresheet;
  const { eventDetails: { season } } = matchScoresheet;
  return {
    pageDetails: matchScoresheet,
    loadingScoreInput: isFetchingMatchScoreInput,
    season
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
 ScoringMatch
);