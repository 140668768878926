import { combineReducers } from 'redux'
import partners from './partnersReducer';
import users from './usersReducer';
import events from './eventsReducer';
import programs from './programsReducer';
import teams from './teamsReducer';
import volunteers from './volunteersReducer';
import organizations from './organizationsReducer';

export default combineReducers({
  partners,
  users,
  events,
  programs,
  teams,
  volunteers,
  organizations
});
