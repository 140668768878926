import { connect } from 'react-redux';
import UserForm from 'shared/components/UserForm';

import {
  updatePartnerUser,
  setPartnerUser,
  addPartnerToInvitees,
  clearSelectedPartner,
  setIsPartnerSelected,
  handleInvitePartnerNow
} from 'features/AdminDashboard/redux/actions/partnersActions';

const mapDispatchToProps = {
  updateUser: updatePartnerUser,
  handleUserSelect: setPartnerUser,
  setIsAutocompleteUserSelected: setIsPartnerSelected,
  addUserToList: addPartnerToInvitees,
  resetManualForm: clearSelectedPartner,
  handleInviteUserNow: handleInvitePartnerNow
};

const mapStateToProps = ({ adminBoard }) => {
  const { partners: {
    selectedPartner,
    isPartnerUserSelected
  } } = adminBoard;
  const { programs } = selectedPartner;
  const disableInvite = !programs.length || programs.find(({ regions }) => {return !regions || !regions.length});
  return {
    selectedUser: selectedPartner,
    isAutocompleteSelected: isPartnerUserSelected,
    disableInvite: Boolean(disableInvite)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserForm);
