import DashboardContainer from 'features/DashboardContainer';

import React from 'react';

const Home = (props) => {
  return (
    <DashboardContainer {...props} />
  );
};

export default Home;
