import React, {useState} from 'react';
import PropTypes from 'prop-types';
import '../../../../../../shared/components/Scoresheet/styles/MatchScoresheetForm.scss';
import {Radio} from 'antd';
import InputNumber from 'shared/components/InputNumber'
import { useTranslation } from 'react-i18next';

const moduleNameClassName = `h-2 heading--white`;
const moduleTitleClassName = `h-2 heading--charcoal`;
const paragraphClassName = 'paragraph--large paragraph--charcoal paragraph--normal'
const errorClassName = 'paragraph--large paragraph--dark-red paragraph--normal'

const ERROR_MESSAGE = `It is not possible for there to be
any combination greater than 8`

const ModuleSection = props => {
  const [moduleError, setModuleError] = useState('');
  const {moduleName, moduleTitle, fields, scoreInput} = props;
  const {t} = useTranslation();
  const [form] = Form.useForm();

  const validateRadioOption = (rule, value, callback) => {
    const module14QuestionOneResponse = form.getFieldValue('m14_scoring_1') || 0;
    const healthUnitsSum = value + module14QuestionOneResponse;

   if (healthUnitsSum > 8) {
      setModuleError(ERROR_MESSAGE);
      callback(ERROR_MESSAGE);
    } else {
      setModuleError('');
      callback();
    }
  }

  const validateInputNumber = (rule, value, callback) => {
    const module14QuestionTwoResponse = form.getFieldValue('m14_scoring_2') || 0;
    const healthUnitsSum = value + module14QuestionTwoResponse;

    if (healthUnitsSum > 8) {
      setModuleError(ERROR_MESSAGE);
      callback(ERROR_MESSAGE);
    } else {
      setModuleError('');
      callback();
    }
  }


  return (
    <div className="scoresheet-module">
      <div className="scoresheet-module__name">
        <h3 className={moduleNameClassName}>{moduleName}</h3>
      </div>

      <div className="m-l-30 m-t-8 scoresheet-module__content">
        <h2 className={moduleTitleClassName}>{moduleTitle}</h2>
        <div className="scoresheet-module__fields-wrapper">
          {fields.map((f) => (

            <div key={f.id} className="scoresheet-module__field">
              <span className={`${paragraphClassName} scoresheet-module__field-label`}>
                {t(f.scoring)}
                :
              </span>
              {f.type !== 'integer' ? (
                <span>
                  {form.getFieldDecorator( f.id, {
                    initialValue: scoreInput[f.id],
                    rules: [{
                      validator: validateRadioOption
                    }]
                  }
                  )(
                    <Radio.Group
                      buttonStyle="solid"
                      className="match-scoresheet-form__radio-wrapper"
                      optionType="button"
                      disabled={scoreInput.is_published}
                    >
                      {f.options.map((o) => (
                        <Radio.Button
                          buttonStyle="solid"
                          className="match-scoresheet-form__radio-btn"
                          key={`${f.id}-${o.value}`}
                          value={o.value}
                        >
                          {t(o.description)}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  )}
                </span>
              ) : (
                <span>
                  {form.getFieldDecorator( f.id, {
                  initialValue: scoreInput[f.id],
                  rules: [{
                    validator: validateInputNumber
                  }]
              }
              )(
                <InputNumber size='large' min={f.min} max={f.max} disabled={scoreInput.is_published}/>
              )}
                </span>
              )}
            </div>
          ))}
        </div>
        <p className={errorClassName}>{moduleError}</p>

      </div>
    </div>
  );
};

ModuleSection.propTypes = {
  moduleName: PropTypes.string,
  moduleTitle: PropTypes.string,
  fields: PropTypes.array,
  form: PropTypes.object.isRequired
};

ModuleSection.defaultProps = {
  moduleName: '',
  moduleTitle: '',
  fields: []
};

export default ModuleSection;