import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import Icon from '@ant-design/icons';
import Spreadsheet from 'shared/components/Icon/icons/Spreadsheet';
import RubricsPdfIcon from 'shared/components/Icon/icons/RubricsPdfIcon';

import { connect } from 'react-redux';
import { getSessionRubric } from 'features/EventOverviewContainer/redux/actions/coachJudging/feedback';
import { getSessionFile } from 'utils/services/sessionDocuments';
import DownloadFeedbackFile from 'shared/components/DownloadFeedbackFile';
import { ClipLoader } from 'react-spinners';

import get from 'lodash/get'
import find from 'lodash/find'
import Event from 'models/Event';
import { useTranslation } from 'react-i18next';

import {
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FTC_ID,
  FLL_CHALLENGE_ID
} from 'shared/constants/programIds';

const normalText = 'paragraph--default paragraph--charcoal paragraph--normal';

const UploadedJudgingRubric = props => {
  const [rubricUrl, setRubricUrl] = useState('')
  const { t } = useTranslation();
  const {
    getRubric,
    uploadedRubric,
    loadingRubric,
    isRubricPublished,
    judgingSessionId,
    eventData,
    eventIncluded,
    eventProgramId,
    is_FLLC_event
  } = props;

  const eventDetail = new Event(eventData, eventIncluded);
  const extraText = <p className="event-name-location">{eventDetail.name}<br />{eventDetail.getEventLocation()}</p>

  useEffect(() => {
    if (judgingSessionId) {
      getRubric(judgingSessionId)
    }
  }, [judgingSessionId])

  useEffect(() => {
    const downloadRubric = async () => {
      const notificationKey = 'download-rubric'
      try {
        notification.open({
          key: notificationKey,
          message: 'Loading Feedback...',
          icon: FLL_EXPLORE_ID === eventProgramId ? <Icon component={RubricsPdfIcon} /> : <Icon component={Spreadsheet} />,
          duration: 0,
        })
        const response = await getSessionFile(uploadedRubric.sessionDocumentId, uploadedRubric.eventFileId);
        const presignedUrlObject = response.included && find(response.included, res => res.type === 'pre-signed_url')
        const presignedUrl = get(presignedUrlObject, ['attributes', 'url'], '')
        setRubricUrl(presignedUrl)
        setTimeout(() => {
          notification.destroy(notificationKey);
        }, 5000);
        return;
      } catch (err) {
        notification.error({
          key: notificationKey,
          message: 'Loading cancelled',
          description: 'Error loading feedback',
        });
        throw err;
      }
    }

    if (uploadedRubric.eventFileId && isRubricPublished) {
      downloadRubric();
    }
  }, [judgingSessionId, uploadedRubric.eventFileId])

  if (loadingRubric) {
    return (
      <div className='judging-results-feedback-inner'>
        <ClipLoader size={36} color='#0066B3' />
      </div>
    )
  }

  if (!uploadedRubric.eventFileId || !isRubricPublished) {
    return (
      <div className='judging-results-feedback-inner'>
        <p className={normalText}>
          {is_FLLC_event ? t('emptyFeedbackLabel') : t('nonChallengeEmptyFeedbackLabel')}
        </p>
      </div>
    )
  }



  return (
    <div className='judging-results-feedback-inner'>
      <DownloadFeedbackFile
        downloadClick={() => { }}
        text={uploadedRubric.fileName}
        rubricUrl={rubricUrl}
        extraText={extraText}
        programTypeExplorer={FLL_EXPLORE_ID === eventProgramId ? true : false}
      />
    </div>
  );
};

UploadedJudgingRubric.propTypes = {
  getRubric: PropTypes.func.isRequired,
  uploadedRubric: PropTypes.object.isRequired,
  loadingRubric: PropTypes.bool.isRequired,
  isRubricPublished: PropTypes.bool.isRequired,
  judgingSessionId: PropTypes.string.isRequired,
};

const mapStateToProps = ({ coachJudging, eventOverview: { eventData, eventIncluded, eventProgramId, is_FLLC_event } }) => {
  const {
    uploadedJudgingSessionRubric,
    isFetchingCoachJudgingSessionRubric,
    isCoachJudgingSessionRubricPublished,
  } = coachJudging;
  return {
    is_FLLC_event,
    eventData,
    eventIncluded,
    eventProgramId,
    uploadedRubric: uploadedJudgingSessionRubric,
    loadingRubric: isFetchingCoachJudgingSessionRubric,
    isRubricPublished: isCoachJudgingSessionRubricPublished
  }
}

export default connect(mapStateToProps, { getRubric: getSessionRubric })(UploadedJudgingRubric);