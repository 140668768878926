export const DASHBOARD_REQUEST_VOLUNTEERS_BEGIN = 'DASHBOARD_REQUEST_VOLUNTEERS_BEGIN';
export const DASHBOARD_REQUEST_VOLUNTEERS_SUCCESS = 'DASHBOARD_REQUEST_VOLUNTEERS_SUCCESS';
export const DASHBOARD_REQUEST_VOLUNTEERS_ERROR = 'DASHBOARD_REQUEST_VOLUNTEERS_ERROR';

export const REINVITE_USER_DASHBOARD_VOLUNTEERS_TAB_BEGIN = 'REINVITE_USER_DASHBOARD_VOLUNTEERS_TAB_BEGIN';
export const REINVITE_USER_DASHBOARD_VOLUNTEERS_TAB_ERROR = 'REINVITE_USER_DASHBOARD_VOLUNTEERS_TAB_ERROR';
export const REINVITE_USER_DASHBOARD_VOLUNTEERS_TAB_SUCCESS = 'REINVITE_USER_DASHBOARD_VOLUNTEERS_TAB_SUCCESS';

export const EDIT_ADMINBOARD_VOLUNTEER_BEGIN = 'EDIT_ADMINBOARD_VOLUNTEER_BEGIN';
export const EDIT_ADMINBOARD_VOLUNTEER_ERROR = 'EDIT_ADMINBOARD_VOLUNTEER_ERROR';
export const EDIT_ADMINBOARD_VOLUNTEER_SUCCESS = 'EDIT_ADMINBOARD_VOLUNTEER_SUCCESS';

export const DELETE_ADMINBOARD_VOLUNTEER_BEGIN = 'DELETE_ADMINBOARD_VOLUNTEER_BEGIN';
export const DELETE_ADMINBOARD_VOLUNTEER_ERROR = 'DELETE_ADMINBOARD_VOLUNTEER_ERROR';
export const DELETE_ADMINBOARD_VOLUNTEER_SUCCESS = 'DELETE_ADMINBOARD_VOLUNTEER_SUCCESS';
