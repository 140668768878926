const m03Section = {
  id: 'm03',
  name: 'SlideLabel',
  description: null,
  fields: [
    {
      scoring: 'm03sub1Label',
      id: 'm03_scoring_1',
      options: [
          {
              description: 'noLabel',
              value: false
          },
          {
              description: 'yesLabel',
              value: true
          }
      ]
    },
  ]
}

export default m03Section;