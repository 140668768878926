const m12Section = {
  id: 'm12',
  name: '2025ScoresheetM12Sub1Label',
  fields: [
    {
      scoring: '2025ScoresheetM12Sub2Label',
      id: 'm12_scoring_1',
      options: [
        {
          description: '0',
          value: 0
        },
        {
            description: '1',
            value: 1
        },
        {
            description: '2',
            value: 2
        },
        {
            description: '3',
            value: 3
        },
        {
            description: '4',
            value: 4
        },
        {
            description: '5',
            value: 5
        }
      ]
    },
  ]
}

export default m12Section;