import * as types from './authTypes'

export const setAuth0AuthenticatedStatus = (value)=>{
  return ({
    type: types.SET_AUTH0_AUTHENTICATED_STATUS,
    payload: value
  })
}

export const resetLoadingUserState = ()=>({
  type: types.RESET_LOADING_USER
})

export const loginRequestBegin = () => ({
  type: types.LOGIN_REQUEST_BEGIN
})

export const loginRequestSuccess = (response) => ({
  type: types.LOGIN_REQUEST_SUCCESS,
  payload: response
})

export const refreshTokenSuccess = (response) => ({
  type: types.REFRESH_TOKEN_SUCCESS,
  payload: response
})

export const loginRequestError = (error) => ({
  type: types.LOGIN_REQUEST_ERROR,
  payload: error,
  error: true
})

export const clearLoginError = () => ({
  type: types.CLEAR_LOGIN_ERROR,
})

export const logout = () => ({
  type: types.LOGOUT
})

export const logoutRequestBegin = () => ({
  type: types.LOGOUT_REQUEST_BEGIN,
})

export const logoutRequestError = () => ({
  type: types.LOGOUT_REQUEST_ERROR,
})

export const logoutRequestSuccess = () => ({
  type: types.LOGOUT_REQUEST_SUCCESS,
})

export const resetAuth = () => ({
  type: types.RESET_AUTH
})

export const setAuthUserData = (payload) => ({
  type: types.SET_AUTH_USER_DATA,
  payload
})

export const setUserId = (userId) => ({
  type: types.SET_USER_ID,
  payload: {
    userId
  }
})

export const setUserType = (userType) => ({
  type: types.SET_USER_TYPE,
  payload: {
    userType
  }
})

export const setCanAccessVolunteerDashboard = (canAccessVolunteerDashboard) => {
  return {
    type: types.SET_CAN_ACCESS_VOLUNTEER_DASHBOARD,
    payload: {
      canAccessVolunteerDashboard
    }
  }
}

export const setCanAccessAdminDashboard = (canAccessAdminDashboard) => {
  return {
    type: types.SET_CAN_ACCESS_ADMIN_DASHBOARD,
    payload: {
      canAccessAdminDashboard
    }
  }
}

export const setUserEventRoles = (userEventRoles) => {
  return {
    type: types.SET_USER_EVENT_ROLES,
    payload: {
      userEventRoles
    }
  }
}

export const setPartnerAdminRegionsPrograms = (partnerAdminRegionsPrograms) => {
  return {
    type: types.SET_PARTNER_ADMIN_REGIONS_AND_PROGRAMS,
    payload: {
      partnerAdminRegionsPrograms
    }
  }
}

export const setEventIdsAssociatedWithUser = (eventIdsAssociatedWithUser) => {
  return {
    type: types.SET_EVENT_IDS_ASSOCIATED_WITH_USER,
    payload: {
      eventIdsAssociatedWithUser
    }
  }
}

export const setUserEventAssignments = (userEventAssignments) => {
  return {
    type: types.SET_USER_EVENT_ASSIGNMENTS,
    payload: {
      userEventAssignments
    }
  }
}

export const updateUserProfile = (userData) => {
  return {
    type: types.UPDATE_USER_INFO,
    payload: {
      userData
    }
  }
}
