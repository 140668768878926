import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const AddRefereeNote = props => {
  const {
    eventDetails
  } = props;

  const {
    eventLocation,
    programId,
    eventDate,
    eventName
  } = eventDetails;

  return (
    <div className='event-modal-header'>
      <p className='paragraph--white  event-modal-header__text'>{eventName}</p>
      <span className='event-modal-header__point'>&#8226;</span>
      <p className='paragraph--white event-modal-header__text event-modal-header__text--right'>
        {eventDate}
      </p>
      <span className='event-modal-header__point'>&#8226;</span>
      <p className='paragraph--white event-modal-header__text event-modal-header__text--right'>
        {eventLocation}
      </p>
    </div>
  );
};

AddRefereeNote.propTypes = {
  eventDetails: PropTypes.object.isRequired
};


const mapStateToProps = (state) => {
  const {matchScoresheet: {eventDetails}} = state;
  return {
    eventDetails
  }
}


export default connect(mapStateToProps)(AddRefereeNote);