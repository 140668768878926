import React, { useState } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import PanelModal from '../PanelModal';
import JudgesList from './PanelJudgesList';
import TeamsList from './PanelTeamsList';
import TeamAssignments from './TeamAssignments'
import '../../../../../../features/PanelManagement/styles/JudgingPanelCard.scss';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'shared/components/ConfirmModal';
import { connect } from 'react-redux';
import * as panelService from 'utils/services/panels';
import { notification } from 'antd';
import fetchJudgingPanels from 'features/EventContainer/redux/actions/judgingActions/getJudgingPanels';
import CloseCircle from 'shared/components/Icon/icons/CloseCircle';
import { Col, Row, Select } from 'antd';
import Icon from 'shared/components/Icon'
import _ from 'lodash';
import PanelJudgesListCard from './PanelJudgesListCard';
import { FLL_CHALLENGE_ID } from 'shared/constants/programIds';

const { Option } = Select;

const Card = (props) => {
    const { t } = useTranslation();
    const { hasError, id, panel, eventSessions, eventJudges, judgeOptions, isInPerson, sessionOptions, expendables } = props;
    const [visible, setVisible] = useState(false);
    const [deleteConfirmModalVisible, setDeleteConfirmModalVisiable] = useState(false);
    const { attributes, getJudgingPanels, eventProgramId } = props
    const { title } = attributes

    const panelByTeams = _.groupBy(panel.panelSessions, 'teamId');
    const numberOfTeams = Object.keys(panelByTeams).length;

    const sortedSessionOptions = _.orderBy(sessionOptions, ['teamName', 'sessionTypeName', 'matchNumber'], ['asc', 'desc', 'asc'])

    let className = '';
    if (hasError) {
        className = 'judging-panel-card--incomplete'
    }
    if (!hasError) {
        className = 'judging-panel-card--complete'
    }

    const openModal = () => {
        setVisible(true);
    }

    const closeModal = () => {
        setVisible(false);
    }

    const deletePanel = async (panelId) => {
        await panelService.deletePanelByID(panelId)
        setDeleteConfirmModalVisiable(false)
        notification.success({
            message: 'Success',
            description: 'The room was successfully deleted.',
        });
        getJudgingPanels();
    }

    const isExpanded = () => {
        return expendables.includes(id)
    }



    return (
        <>
            <div className='judging-panel-card-container'>
                <PanelModal panelId={id} isEdit visible={visible} onCancel={closeModal} judgeOptions={judgeOptions} isInPerson={isInPerson} />
                {/* <div className='judging-panel-card__btn-container'>
                    <Button type='link' className='judging-panel-card__btn link' onClick={openModal}>{t('editLabel')}</Button>
                </div> */}
                <div className={`judging-panel-card ${className}`}>
                    {(
                        <PanelJudgesListCard
                            onEdit={openModal}
                            panelId={id}
                            panelTitle={panel.panelName}
                            panelJudges={panel.panelJudges}
                            numberOfTeams={numberOfTeams}
                            hasError={panel.panelJudges.length < 1} />
                    )}
                    {/* <TeamsList panelSessions={panel.panelSessions} isInPerson={isInPerson} /> */}
                    <div onClick={() => { setDeleteConfirmModalVisiable(true) }} className='m-t-10 danger cursor panel-delete-button'><CloseCircle></CloseCircle></div>
                </div>
            </div>

            {!isExpanded() && (
                <TeamAssignments panelSessions={panel.panelSessions} data={sortedSessionOptions} panelName={panel.panelName} panelId={id}></TeamAssignments>
            )}

            {/* <TeamsList panelSessions={panel.panelSessions} isInPerson={isInPerson} /> */}
            <ConfirmationModal
                visible={deleteConfirmModalVisible}
                onCancel={() => {
                    setDeleteConfirmModalVisiable(false);
                }}
                onSubmit={() => {
                    deletePanel(id)
                }}
                title={(<div>
                    <div className='team-name m-b-20'>{title}</div>
                    <div className='title'>{`${t('DeleteTeamDeleteLabel')} ${panel.panelName}?`}</div>
                </div>)}
                // title={`${t('DeleteTeamDeleteLabel')} ${panel.panelName}?`}
                description='This can not be undone.'
            />

            <hr className='hr-line m-t-40'></hr>
        </>
    );
};

Card.propTypes = {
    hasError: PropTypes.bool,
    id: PropTypes.string,
    panel: PropTypes.object.isRequired,
    eventSessions: PropTypes.array,
    eventJudges: PropTypes.array,
    event: PropTypes.object,
    getJudgingPanels: PropTypes.func.isRequired,
}

Card.defaultProps = {
    hasError: false,
    id: '',
    eventSessions: [],
    eventJudges: [],
    event: null,
    sessionOptions: [],
}

const mapDispatchToProps = {
    getJudgingPanels: fetchJudgingPanels
}

const mapStateToProps = (state) => {
    const { event: { eventDetails: { attributes, eventProgramId }, eventJudging: { sessionOptions, judgingPanels, expendables } } } = state;

    return {
        attributes,
        sessionOptions,
        expendables,
        eventProgramId
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Card);
// export default PanelCard;