import { API } from 'utils/api';
import ProgramType from 'models/ProgramType';

//const { http } = API;

export const getPrograms = async () => {
  const url = '/api/programs';
  const { data } = await API.GetInstance().http.get(url);
  return data.map((p) => new ProgramType(p));
};
