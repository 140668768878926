import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import { Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import AutoComplete from 'shared/components/FormAutoComplete';
import Input from 'shared/components/Input';
import text from 'shared/translations/en/volunteersModal';
import Icon from '@ant-design/icons';
import { connect } from 'react-redux';
import {
    handleAddCoachToList,
    handleUpdateSelectedCoach,
    handleIsAddTeamDisabled,
    handleResetSelectedTeam,
    handleResetSelectedCoach,
    handleSetCoachInAutocomplete,
    handleInviteCoachNow,
    handleResetCoachInAutocomplete
} from 'features/EventContainer/redux/actions/teams';
import '../../../../styles/TeamsCoachesModal.scss';
import { useParams } from 'react-router-dom';
import validateEmail from 'utils/validateEmail';
import UserOption from 'shared/components/UserAutocomplete/UserAutocompleteOption';
import * as userService from 'utils/services/users';
import { phoneRegexPattern } from 'shared/constants/regexPatterns';
import Heading from 'shared/components/Heading';

const FormItem = Form.Item;

const { Option } = AutoComplete;

const CoachAddForm = props => {
    const [disabledForm, setDisabledForm] = useState(true);
    const [users, setUsers] = useState([])
    // eslint-disable-next-line no-unused-vars
    const {
        addSelectedCoachToList,
        onFieldChange,
        coach,
        handleAddBtn,
        resetSelectedCoach,
        resetSelectedTeam,
        setSelectedAutocompleteCoach,
        coachSelectedInAutocomplete,
        handleInviteNow,
        resetAutocompleteCoach,
        isInvitingCoach,
        emailLanguage,
        defaultTeams,
        onAddedSucceed,
    } = props;

    const { t, i18n } = useTranslation();
    const rtl_direction = i18n.dir() === 'rtl';
    const { teams } = coach;
    const [form] = Form.useForm();

    const btnDisabled = teams.length < 1;

    const isEmailTouched = form.isFieldTouched('email');
    const isFirstNameTouched = Boolean(form.getFieldValue('firstName'));
    const isLastNameTouched = Boolean(form.getFieldValue('lastName'));
    const isPhoneTouched = Boolean(form.getFieldValue('phone'));

    const clearForm = () => {
        form.resetFields();
        setDisabledForm(true)
    }

    const isFilled = (v) => Boolean(v) === true;

    const checkCoachProperties = () => {
        if (coachSelectedInAutocomplete) {
            handleAddBtn(false)
            return;
        }
        const currentKeys = ['email', 'firstName', 'lastName']
        const valuesObj = form.getFieldsValue(currentKeys);
        const valuesArr = currentKeys.map(k => valuesObj[k]);
        const isFormFilled = valuesArr.every(isFilled);
        handleAddBtn(!isFormFilled)
    }

    const { id: eventId } = useParams();

    const validateForm = async () => {
        let formValues = {};
        if (err) {
            throw err;
        }
        formValues = values;
        return formValues
    }

    const successCallback = () => {
        clearForm();
        resetAutocompleteCoach();
        resetSelectedTeam();
        onAddedSucceed && onAddedSucceed();
    }

    const inviteNow = async (e) => {
        if (e && 'preventDefault' in e) e.preventDefault();
        form.validateFields().then(async (values, err) => {
            coach.culture = emailLanguage;
            coach.teams = defaultTeams;
            handleInviteNow(coach, eventId, successCallback);   
        });
    }

    const addToList = async (e) => {
        if (e && 'preventDefault' in e) e.preventDefault();
        form.validateFields().then(async (values, err) => {
            const volunteerData = {
                ...values,
                id: uid(values),
                culture: emailLanguage,
                teams: defaultTeams,
            }
            await addSelectedCoachToList(volunteerData)
            clearForm();
            await resetSelectedCoach();
            await resetSelectedTeam();
            await resetAutocompleteCoach();
        });
    }

    const handleEmailChange = (value) => {
        onFieldChange('email', value)
        const isValidEmail = validateEmail(value);
        if (isValidEmail) {
            setDisabledForm(false)
        }

        if (!isValidEmail) {
            setDisabledForm(true)
        }

        if (coachSelectedInAutocomplete) {
            resetAutocompleteCoach();
        }
    }

    const handleFieldChange = (fieldName, e) => {
        const { value } = e.target;
        onFieldChange(fieldName, value);
        checkCoachProperties()
    }

    checkCoachProperties();

    const handleUserSearch = async (input) => {
        try {
            const formattedUsers = await userService.getUsersLikeEmail(input);
            setUsers(formattedUsers)
        } catch (err) {
            throw err;
        }
    }

    const handleUserSelect = async (e) => {
        const selectedUser = users.find(u => u.id === e);
        await form.resetFields();
        setDisabledForm(false)
        setSelectedAutocompleteCoach(selectedUser)
    }

    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };

    return (
        <div className='manual__form'>
            <Heading tag={2} type='primary' weight='bold'>{t('Add Coach')}</Heading>
            <Form hideRequiredMark className='m-t-20' name='AddCoachesTeams' form={form} initialValues={{email: coach.email, firstName: coach.firstName, lastName: coach.lastName, phone: coach.phone}}>
                <FormItem name='email' label={<span className='form__label--visible'>{t('emailLabel')}</span>} rules={[{ required: true, message: t('enterEmailLabel') },{ type: 'email', message: t('enterValidEmailLabel') },]}>
                    <AutoComplete
                        placeholder={t('emailLabel')}
                        onChange={handleEmailChange}
                        onSearch={handleUserSearch}
                        onSelect={handleUserSelect}
                        dataSource={users.map(u => (
                            <Option key={u.id} value={u.id} text={u.email}>
                                <UserOption name={`${u.firstName} ${u.lastName}`} email={u.email} id={u.id} />
                            </Option>
                        ))}
                    />
                </FormItem>
                <FormItem name='firstName' label={<span className='form__label--visible' >{t('firstNameLabel')}</span>} rules={[{ required: true, message: t('enterFirstNameLabel') }]}>
                    <Input size='large' placeholder={t('firstNameLabel')} disabled={disabledForm || coachSelectedInAutocomplete} onChange={(e) => handleFieldChange('firstName', e)} />
                </FormItem>
                <FormItem name='lastName' label={<span className='form__label--visible' rules={[{ required: true, message: t('enterLastNameLabel') }]}>{t('lastNameLabel')}</span>}>
                    <Input size='large' placeholder={t('lastNameLabel')} disabled={disabledForm || coachSelectedInAutocomplete} onChange={(e) => handleFieldChange('lastName', e)} />
                </FormItem>
                {/*  
                <FormItem name='phone' label={<span className='form__label--visible'>{t('phoneOptionalLabel')}</span>} rules={[{ pattern: phoneRegexPattern, message: 'Enter valid phone number.' }]}>
                */}
                <FormItem name='phone' label={<span className='form__label--visible'>{t('phoneOptionalLabel')}</span>}>
                    <Input size='large' placeholder={t('phoneOptionalLabel')} disabled={disabledForm || coachSelectedInAutocomplete} onChange={(e) => handleFieldChange('phone', e)} />
                </FormItem>
                <div className='teams-coaches-modal__manual-buttons-wrapper'>
                    <FormItem>
                        <Button 
                        loading={isInvitingCoach} 
                        // disabled={btnDisabled} 
                        disabled={disabledForm} 
                        onClick={inviteNow}>{t('Add Now')}</Button>
                    </FormItem>
                    <p className={btnDisabled ? 'paragraph--silver paragraph--small' : 'paragraph--charcoal paragraph--small'}>{` ${t('orLabel')} `}</p>
                    <FormItem>
                        <Button
                            type='default'
                            styleType='secondary'
                            size='large'
                            additionalClassNames='p-r-14 p-l-14'
                            onClick={addToList}
                            // disabled={btnDisabled || isInvitingCoach}
                            disabled={disabledForm || isInvitingCoach}
                        >
                            {`${t('Add to List')}`}
                            {' '}
                            <Icon type={rtl_direction ? "left" : "right"} />
                        </Button>
                    </FormItem>
                </div>
            </Form>
        </div>
    );
};

CoachAddForm.propTypes = {
    form: PropTypes.object.isRequired,
    addSelectedCoachToList: PropTypes.func.isRequired,
    onFieldChange: PropTypes.func.isRequired,
    coach: PropTypes.object.isRequired,
    handleAddBtn: PropTypes.object.isRequired,
    resetSelectedCoach: PropTypes.func.isRequired,
    resetSelectedTeam: PropTypes.func.isRequired,
    setSelectedAutocompleteCoach: PropTypes.func.isRequired,
    coachSelectedInAutocomplete: PropTypes.bool.isRequired,
    handleInviteNow: PropTypes.func.isRequired,
    resetAutocompleteCoach: PropTypes.func.isRequired,
    isInvitingCoach: PropTypes.bool.isRequired
};

const mapDispatchToProps = {
    addSelectedCoachToList: handleAddCoachToList,
    onFieldChange: handleUpdateSelectedCoach,
    handleAddBtn: handleIsAddTeamDisabled,
    resetSelectedTeam: handleResetSelectedTeam,
    resetSelectedCoach: handleResetSelectedCoach,
    setSelectedAutocompleteCoach: handleSetCoachInAutocomplete,
    handleInviteNow: handleInviteCoachNow,
    resetAutocompleteCoach: handleResetCoachInAutocomplete

}

function mapStateToProps(state) {
    const { event: { eventTeams } } = state;
    const { selectedCoach, coachSelectedInAutocomplete, isInvitingCoach } = eventTeams;
    return {
        coach: selectedCoach,
        coachSelectedInAutocomplete,
        isInvitingCoach,

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CoachAddForm);