import React from 'react';
import PropTypes from 'prop-types';
import EventModal from 'features/EventContainer/components/EventModal';
import EditTeamModalContent from './EditTeamModalContent';


const EditTeamModal = props => {
  const {onCancel, visible, selectedTeamId, onCoachDeletedCallback} = props;

  return (
    <EventModal
      visible={visible}
      onCancel={onCancel}
      extraClass="edit-event-info-modal"
    >
      {selectedTeamId ? (
        <EditTeamModalContent teamId={selectedTeamId} onCancel={onCancel} onCoachDeletedCallback={onCoachDeletedCallback} showDeleteOption/>
      ) : (null)}
    </EventModal>
  );
};

EditTeamModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedTeamId: PropTypes.string
};

EditTeamModal.defaultProps = {
  selectedTeamId: null
}

export default EditTeamModal;