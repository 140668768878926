import React from 'react';
import PropTypes from 'prop-types';
import {CheckOutlined} from '@ant-design/icons';


const CoachOption = (props) => {

  const {
    coach,
    selected,
  } = props;

  const {firstName, lastName, phone, email} = coach || {};

  const name = firstName + " " + lastName;
  const style = {
    paddingRight: '4rem',
    textOverflow: 'ellipsis',
    width: '100%'
  }
  return (
    <div className='user-autocomplete-option'>
      <span style={style}>{name ? name : '-'}</span>
      <span style={style}>{email ? email : '-'}</span>
      <span style={style}>{phone ? phone : '-'}</span>
      <span style={style}>{selected && <CheckOutlined style={{color:"#52C41A"}}/>}</span>
    </div>
  );
};

CoachOption.propTypes ={
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
}

export default CoachOption;