export const firstUsersSortBy = [
  {
    description: 'First Name (A-Z)',
    value: '+first_name',
  },
  {
    description: 'First Name (Z-A)',
    value: '-first_name',
  },
  {
    description: 'Last Name (A-Z)',
    value: '+last_name',
  },
  {
    description: 'Last Name (Z-A)',
    value: '-last_name',
  },
]

export const partnersSortBy = [
  {
    description: 'First Name (A-Z)',
    value: '+first_name',
  },
  {
    description: 'First Name (Z-A)',
    value: '-first_name',
  },
  {
    description: 'Last Name (A-Z)',
    value: '+last_name',
  },
  {
    description: 'Last Name (Z-A)',
    value: '-last_name',
  }
]

export const volunteersSortBy = []

export const eventsSortBy = [
  {
    description: 'Date (upcoming first)',
    value: '-start_date',
  },
  {
    description: 'Date (upcoming last)',
    value: '+start_date',
  },
  {
    description: 'Event Name (A-Z)',
    value: '+title',
  },
  {
    description: 'Event Name (Z-A)',
    value: '-title',
  },
  {
    description: 'Program Type (A-Z)',
    value: '+program_shortname',
  },
  {
    description: 'Program Type (Z-A)',
    value: '-program_shortname',
  },
  {
    description: 'Event Type (test first)',
    value: '-event_type'
  },
  {
    description: 'Event Type (test last)',
    value: '+event_type'
  }
]

export const teamsSortBy = [
  {
    description: 'Team Name (A-Z)',
    value: '+name',
  },
  {
    description: 'Team Name (Z-A)',
    value: '-name',
  },
  {
    description: 'Team Number (1-9)',
    value: '+team_number',
  },
  {
    description: 'Team Number (9-1)',
    value: '-team_number',
  }
]