import React from 'react';
import './no-file.scss';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const NoFileChosen = ({text}) => {
  const { t } = useTranslation()
  const displayText = text && text.length ? text : t('noFileChosenLabel')
  return (
    <div className='no-file-chosen'>
      <p>{displayText}</p>
    </div>
  );
};

NoFileChosen.propTypes = {
  text: PropTypes.string
};


export default NoFileChosen;