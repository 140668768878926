import React from 'react';
import { Popover, Menu, Switch } from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';

import '../styles/RoleSelection.scss';
import { setDashboardRole } from 'features/DashboardContainer/redux/roleActions';
import { connect } from 'react-redux';
import * as userTypes from 'shared/constants/userTypes';

const RoleSelection = (props) => {
  const {
    dashboard,
    selectRole,
    canAccessVolunteerDashboard,
    canAccessAdminDashboard,
    userType

  } = props;
  const history = useHistory();
  const { t } = useTranslation();



  const roles = [
    {
      name: t('adminDashboardLabel'),
      id: 'AdminDashboard',
      path: 'admin',
      disabled: !canAccessAdminDashboard,
    },
    {
      name: t('volunteerDashboardLabel'),
      id: 'VolunteerDashboard',
      path: 'volunteer',
      disabled: !canAccessVolunteerDashboard
    }
  ]
  // const selectedLanguage = localStorage.getItem('i18nextLng');

  const onSelect = (e) => {
    const { key } = e;

    const role = roles.find(r => r.id === key)
    selectRole(role.name, key, role.path)
    history.push(`/${role.path}`)
  }


  const menu = (
    <Menu className='role-selection__menu' onSelect={onSelect} defaultSelectedKeys={[dashboard.selectedRoleId]}>
      <p className='role-selection__sub-title'>{t('switchToLabel')}</p>
      {roles.map(r => (
        <Menu.Item key={r.id} className='role-selection__menu-item' disabled={r.disabled}>
          <p className='role-selection__item'>{r.name}</p>
        </Menu.Item>
      ))}

    </Menu>
  )

  const selectedDashboard = roles.find(r => r.id === dashboard.selectedRoleId) || {}

  return (
    <div className='role-selection__title white'>
      
      <h1 className='role-selection__title-text'>{selectedDashboard.name || 'Dashboard'}</h1>
      <Popover content={menu}><DownOutlined className='role-selection__icon'/></Popover>
    </div>
  );
};

RoleSelection.propTypes = {
  selectRole: PropTypes.func.isRequired,
  dashboard: PropTypes.object.isRequired,
  canAccessVolunteerDashboard: PropTypes.bool.isRequired,
  canAccessAdminDashboard: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired
}

const mapDispatchToProps = {
  selectRole: setDashboardRole
}

const mapStateToProps = (state) => {
  const { dashboard, auth } = state;
  const {
    canAccessVolunteerDashboard,
    canAccessAdminDashboard,
    userType
  } = auth;
  return {
    dashboard,
    canAccessVolunteerDashboard,
    canAccessAdminDashboard,
    userType
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleSelection);