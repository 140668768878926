import React from 'react';
import PropTypes from 'prop-types';

const Ballot = props => {
  const {fill} = props;

  return (
    <svg viewBox="0 0 31 37" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      <title>FAAA98B2-BB3F-41AB-959E-B9B60CF4BB2A</title>
      <g id="Event" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Event-Management---Single-Event---PDP-Admin" transform="translate(-273.000000, -811.000000)" fill={fill}>
          <g id="Group-50" transform="translate(250.000000, 513.000000)">
            <g id="Group-34" transform="translate(0.000000, 204.000000)">
              <g id="Group-33" transform="translate(0.000000, 42.000000)">
                <g id="Group-32" transform="translate(10.000000, 11.000000)">
                  <g id="Group-15" transform="translate(1.000000, 0.000000)">
                    <g id="ballot" transform="translate(12.000000, 41.000000)">
                      <path d="M28.2201075,0 L6.55084791,0 C6.24659889,0 6,0.24976024 6,0.558188948 C6,0.866338229 6.24659889,1.11637782 6.55084791,1.11637782 L28.2201075,1.11637782 C29.1452672,1.11637782 29.8977525,1.87850963 29.8977525,2.81552949 L29.8977525,29.4418111 C29.8977525,29.7499604 30.1443514,30 30.4488763,30 C30.7531253,30 31,29.7499604 31,29.4418111 L31,2.81552949 C30.9997242,1.26304907 29.7529377,0 28.2201075,0 Z" id="Path" />
                      <path d="M30.4426609,31.5535431 C30.1345475,31.5535431 29.8850427,31.8059082 29.8850427,32.1175537 L29.8850427,34.1553879 C29.8850427,35.1018982 29.1236901,35.872261 28.1876283,35.872261 L2.81237169,35.872261 C1.876589,35.872261 1.11495734,35.1018982 1.11495734,34.1553879 L1.11495734,2.84489445 C1.11495734,1.89810181 1.876589,1.12802124 2.81237169,1.12802124 L3.86565056,1.12802124 C4.17348479,1.12802124 4.42326874,0.875373822 4.42326874,0.564010656 C4.42326874,0.252365148 4.17348479,0 3.86565056,0 L2.81237169,0 C1.2617577,0 0,1.27622221 0,2.84489445 L0,34.1553879 C0,35.7237778 1.2617577,37 2.81237169,37 L28.1876283,37 C29.7385214,37 31,35.7237778 31,34.1553879 L31,32.1175537 C31,31.8059082 30.7504952,31.5535431 30.4426609,31.5535431 Z" id="Path" />
                      <path d="M7.49985158,3 C5.01873039,3 3,5.01873046 3,7.49985166 C3,9.98126961 5.01873039,12 7.49985158,12 C9.98126961,12 12,9.98126961 12,7.49985166 C12,5.01873046 9.98126961,3 7.49985158,3 Z M7.49985158,10.8140479 C5.67254739,10.8140479 4.18565542,9.32745261 4.18565542,7.50014842 C4.18565542,5.67254739 5.67254739,4.18595219 7.49985158,4.18595219 C9.32715577,4.18595219 10.8140478,5.67254739 10.8140478,7.50014842 C10.8140478,9.32745261 9.32715577,10.8140479 7.49985158,10.8140479 Z" id="Shape" />
                      <path d="M13.546053,6 L25.4536736,6 C25.7552742,6 26,5.77602601 26,5.5 C26,5.22372369 25.7552742,5 25.4536736,5 L13.546053,5 C13.2444524,5 13,5.22372369 13,5.5 C13,5.77602601 13.2444524,6 13.546053,6 Z" id="Path" />
                      <path d="M13.5622659,8 L22.4374525,8 C22.7482896,8 23,7.77627631 23,7.5 C23,7.22397399 22.7482896,7 22.4374525,7 L13.5622659,7 C13.2517105,7 13,7.22397399 13,7.5 C13,7.77627631 13.2517105,8 13.5622659,8 Z" id="Path" />
                      <path d="M13.5704763,11 L24.4295237,11 C24.744614,11 25,10.776026 25,10.5 C25,10.2237238 24.744614,10 24.4295237,10 L13.5704763,10 C13.255386,10 13,10.2237238 13,10.5 C13,10.776026 13.255386,11 13.5704763,11 Z" id="Path" />
                      <path d="M13.546053,16 L25.4536736,16 C25.7552742,16 26,15.776026 26,15.5 C26,15.2237237 25.7552742,15 25.4536736,15 L13.546053,15 C13.2444524,15 13,15.2237237 13,15.5 C13,15.776026 13.2444524,16 13.546053,16 Z" id="Path" />
                      <path d="M13.5622659,19 L22.4374525,19 C22.7482896,19 23,18.7762763 23,18.5 C23,18.223974 22.7482896,18 22.4374525,18 L13.5622659,18 C13.2517105,18 13,18.223974 13,18.5 C13,18.7762763 13.2517105,19 13.5622659,19 Z" id="Path" />
                      <path d="M13.5704763,21 L24.4295237,21 C24.744614,21 25,20.776026 25,20.5 C25,20.223974 24.744614,20 24.4295237,20 L13.5704763,20 C13.255386,20 13,20.223974 13,20.5 C13,20.776026 13.255386,21 13.5704763,21 Z" id="Path" />
                      <path d="M7.49985158,25 C5.01873039,25 3,27.0187304 3,29.4998516 C3,31.9812696 5.01873039,34 7.49985158,34 C9.98126961,34 12,31.9812696 12,29.4998516 C12,27.0187304 9.98126961,25 7.49985158,25 Z M7.49985158,32.8140478 C5.67254739,32.8140478 4.18565542,31.3274526 4.18565542,29.4998516 C4.18565542,27.6725474 5.67254739,26.1859522 7.49985158,26.1859522 C9.32715577,26.1859522 10.8140478,27.6725474 10.8140478,29.4998516 C10.8140478,31.3274526 9.32715577,32.8140478 7.49985158,32.8140478 Z" id="Shape" />
                      <path d="M13.546053,27 L25.4536736,27 C25.7552742,27 26,26.7760261 26,26.5 C26,26.223974 25.7552742,26 25.4536736,26 L13.546053,26 C13.2444524,26 13,26.223974 13,26.5 C13,26.7760261 13.2444524,27 13.546053,27 Z" id="Path" />
                      <path d="M13.5622659,29 L22.4374525,29 C22.7482896,29 23,28.7762202 23,28.4998748 C23,28.2237798 22.7482896,28 22.4374525,28 L13.5622659,28 C13.2517105,28 13,28.2237798 13,28.4998748 C13,28.7762202 13.2517105,29 13.5622659,29 Z" id="Path" />
                      <path d="M13.5704763,32 L24.4295237,32 C24.744614,32 25,31.7760261 25,31.5 C25,31.2237237 24.744614,31 24.4295237,31 L13.5704763,31 C13.255386,31 13,31.2237237 13,31.5 C13,31.7760261 13.255386,32 13.5704763,32 Z" id="Path" />
                      <path d="M6.53520963,16.7173283 C6.31301273,16.4914742 5.9523701,16.4914742 5.7301732,16.7173283 C5.5079763,16.9431825 5.5079763,17.3094717 5.7301732,17.535326 L7.13400182,18.9622612 C7.24538509,19.0751882 7.39095256,19.1316518 7.53652004,19.1316518 C7.68208751,19.1316518 7.82793984,19.0751882 7.93903825,18.9622612 L10.8754558,15.9775111 C10.8783045,15.9746155 10.8808683,15.97172 10.883717,15.9691139 L12.8333523,13.9873882 C13.0555492,13.761534 13.0555492,13.3952448 12.8333523,13.1693906 C12.6111554,12.9435365 12.2505127,12.9435365 12.0283158,13.1693906 L10.5395966,14.6829033 C9.7268687,13.7560324 8.56546261,13.2191944 7.31916587,13.2191944 C4.93767088,13.2191944 3,15.1887588 3,17.609742 C3,20.0304357 4.93767088,22 7.31916587,22 C9.70094578,22 11.6386167,20.0304357 11.6386167,17.609742 C11.6386167,17.2900715 11.3836599,17.0312078 11.0694507,17.0312078 C10.7549566,17.0312078 10.5002848,17.2900715 10.5002848,17.609742 C10.5002848,19.3925423 9.07309703,20.8429316 7.31916587,20.8429316 C5.56523471,20.8429316 4.13804696,19.3925423 4.13804696,17.609742 C4.13804696,15.8266521 5.56523471,14.3762628 7.31916587,14.3762628 C8.25865998,14.3762628 9.13177984,14.7911975 9.73199635,15.5035068 L7.53652004,17.7354096 L6.53520963,16.7173283 Z" id="Path" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Ballot.propTypes = {
  fill: PropTypes.string
};

Ballot.defaultProps = {
  fill: 'currentColor'
}
export default Ballot;