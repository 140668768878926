import React from 'react';
import { useTranslation } from 'react-i18next';
import Heading from 'shared/components/Heading';
import EventSponsors from 'shared/components/EventSponsors';
import CustomLink from 'shared/components/Link';
import EditIcon from 'shared/components/Icon/icons/EditIcon';

const SponsorsPreview = (props) => {
  const { editSponsors, sponsors, programId, hide } = props;
  const { t } = useTranslation()

  return (
    <div className={`c-event-sponsors--preview ${hide ? 'c-event-sponsors--hide' : ''}`}>
      <div className='c-event-sponsors__thanks'>
        <Heading tag={3} weight='normal'>{t('thanksText')}</Heading>
      </div>
      <EventSponsors
        seeAllDefault
        hideErrorImage={false}
        sponsors={sponsors}
        programId={programId}
        renderHeader={() => (
          <div className='c-event-sponsors__edit-sponsors'>
            <CustomLink
              customText={t('editSponsorLabel')}
              onClick={editSponsors}
              LinkIcon={EditIcon}
            />
          </div>
        )}
      />
    </div>
  );
};

export default SponsorsPreview;
