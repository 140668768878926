import { connect } from 'react-redux';
import UserForm from 'shared/components/UserForm';
import {
  updateSelectedFirstUser,
  setSelectedFirstUser,
  addFirstUserToList,
  resetManualForm,
  setIsAutocompleteUserSelected,
  handleInviteFirstUserNow
} from 'features/AdminDashboard/redux/actions/usersAction';

const mapDispatchToProps = {
  updateUser: updateSelectedFirstUser,
  handleUserSelect: setSelectedFirstUser,
  setIsAutocompleteUserSelected,
  addUserToList: addFirstUserToList,
  resetManualForm: resetManualForm,
  handleInviteUserNow: handleInviteFirstUserNow
};

const mapStateToProps = ({ adminBoard }) => {
  const { users: {
    selectedFirstUser,
    isFirstUserSelected
  } } = adminBoard;
  return {
    selectedUser: selectedFirstUser,
    isAutocompleteSelected: isFirstUserSelected
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserForm);
