const ip5Section = {
  id: 'IP5',
  label: 'ipv5Label',
  title: 'communicateLabel',
  description: 'communicateDescriptionLabel',
  fields: [
    {
      legendTitle: 'ChallengeRubricsSolutionLabel',
      id: 'innovation_project_5_communicate_solution',
      isCoreValue: true,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricsSolution1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricsSolution2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricsSolution3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricsSolution4Label',
      }]
    },
    {
      legendTitle: 'ChallengeRubricsPresentationLabel',
      id: 'innovation_project_5_communicate_presentation',
      isCoreValue: true,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricsPresentation1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricsPresentation2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricsPresentation3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricsPresentation4Label',
      }]
    }
  ]
}

export default ip5Section;