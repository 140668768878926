import * as types from '../types/eventAwardsTypes';
import { PENDING, PUBLISHABLE, PUBLISHED } from 'shared/constants/publishStatus';

const DEFAULT_STATE = {
  eventId: null,
  scheduleId: null,
  isFLLC: false,
  awards: [],
  teams: [],
  sessions: [],
  judgings: [],
  status: PENDING,
  isFetchingAwards: false,
  isPublishingAwards: false,
  isFetchingJudgings: false,
  isPublishingJudgings: false
};

export default (state = DEFAULT_STATE, action) => {
  const {type} = action;
  switch(type) {
    case types.GET_EVENT_AWARD_TEAMS_BEGIN: {
      return {
        ...state,
        teams: [],
        isFetchingTeams: true
      };
    }
    case types.GET_EVENT_AWARD_TEAMS_SUCCESS: {
      return {
        ...state,
        teams: action.payload,
        isFetchingTeams: false
      };
    }
    case types.GET_EVENT_AWARD_TEAMS_FAIL: {
      return {
        ...state,
        teams: [],
        isFetchingTeams: false
      };
    }
    case types.GET_EVENT_JUDGING_FEEDBACKS_BEGIN: {
      const { scheduleId, isFLLC } = action.payload;
      return {
        ...state,
        scheduleId,
        isFLLC,
        sessions: [],
        isFetchingJudgings: true
      };
    }
    case types.GET_EVENT_JUDGING_FEEDBACKS_SUCCESS: {
      return {
        ...state,
        judgings: action.payload,
        isFetchingJudgings: false
      };
    }
    case types.GET_EVENT_JUDGING_FEEDBACKS_FAIL: {
      return {
        ...state,
        scheduleId: null,
        isFLLC: false,
        judgings: [],
        sessions: [],
        isFetchingJudgings: false
      };
    }
    case types.SET_EVENT_FEEDBACK_SESSIONS: {
      return {
        ...state,
        sessions: action.payload
      };
    }
    case types.PUBLISH_EVENT_JUDGING_FEEDBACKS_BEGIN: {
      return {
        ...state,
        isPublishingJudgings: true
      };
    }
    case types.PUBLISH_EVENT_JUDGING_FEEDBACKS_SUCCESS: {
      const { results, isPublished } = action.payload;
      return {
        ...state,
        isPublishingJudgings: false,
        judgings: state.judgings.map(judging => {
          if (results.includes(judging.id)) {
            return { ...judging, isPublished };
          } else {
            return judging;
          }
        })
      };
    }
    case types.PUBLISH_EVENT_JUDGING_FEEDBACKS_FAIL: {
      return {
        ...state,
        isPublishingJudgings: false
      };
    }
    case types.RESET_EVENT_AWARDS: {
      return {
        ...DEFAULT_STATE,
        eventId: action.payload
      };
    }
    case types.GET_EVENT_AWARDS_BEGIN:
      return {
        ...DEFAULT_STATE,
        eventId: action.payload,
        isFetchingAwards: true
      };
    case types.GET_EVENT_AWARDS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetchingAwards: false
      };
    case types.GET_EVENT_AWARDS_FAIL:
      return {
        ...state,
        awards: [],
        eventId: null,
        isFetchingAwards: false
      };
    case types.PUBLISH_EVENT_AWARDS_BEGIN:
      return {
        ...state,
        isPublishingAwards: true
      };
    case types.PUBLISH_EVENT_AWARDS_SUCCESS:
      const unpublish = action.payload;
      const updatedAwards = state.awards.map((award) => {
        const { assignments } = award;
        return {
          ...award,
          assignments: assignments.map((assign) => {
            return { ...assign, isPublished: !unpublish };
          })
        };
      });
      return {
        ...state,
        awards: updatedAwards,
        status: unpublish ? PUBLISHABLE : PUBLISHED,
        isPublishingAwards: false
      };
    case types.PUBLISH_EVENT_AWARDS_FAIL:
      return {
        ...state,
        isPublishingAwards: false
      };
    default:
      return state;
  }
};
