/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import Tooltip from 'shared/components/Tooltip';
import { useTranslation } from 'react-i18next';
import Tabs from 'shared/components/Tabs/ModalTabs';
import FirstText from 'shared/components/FirstText';
import { FirstUser, InviteesList } from './ManualTabContent'
import './AddFirstUser.scss';

const AddFirstUser = () => {
  const { t } = useTranslation()
  const infoText = (
    <span>
      {/* When using this form to add a new user, 
      an invitation will be sent to the new user to access the Event Hub 
      as a 
      {' '}
      <FirstText />
      {' '}
      employee administrator. */}
      {t('firstAdminHelpLabel')}
    </span>
  );

  return (
    <div className='add-first-user'>
      <div className='add-first-user__heading'>
        <h1 className='h-2 heading--charcoal h--bold'>
          {t('addLabel')} <FirstText /> {t('userLabel')}
        </h1>
      </div>
      <div className='add-first-user__content'>
        <Tabs
          tabBarGutter={48}
          def
          overrides={{
            tabBarExtraContent: <Tooltip text={infoText} />,
          }}
        >
          <Tabs.TabPane tab={t('manuallyLabel')}>
            <div className='add-first-user__manual-tab'>
              <div className='add-first-user__manual-col add-first-user__manual-col--form'>
                <FirstUser />
              </div>
              <div className='add-first-user__manual-col'>
                <InviteesList />
              </div>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default AddFirstUser;
