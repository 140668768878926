export const formatOrganizationPayload = ({ name, regions }) => {
  const data = {
    type: 'organization',
    attributes: {
      name
    }
  };
  if (regions.length) {
    const relationships = {
      regions: {
        data: regions.map(regionId => ({
          type: 'region',
          id: regionId
        }))
      }
    };
    data.relationships = relationships;
  }
  return { data };
};

export const formatOrganizationResponse = (organization) => {
  const organizationPrograms = organization.programs.map(p => {
    return {
      regionId: p.id,
      programId: p.programId,
      regionName: p.name
    }
  })
  return {
    organizationId: organization.id,
    name: organization.name,
    programs: organizationPrograms
  }
}

export const formatOrganizationPatchPayload = ({ name, regions, organizationId }) => {
  const data = {
    type: 'organization',
    id: organizationId,
    attributes: {
      name
    }
  };
  if (regions.length) {
    const relationships = {
      regions: {
        data: regions.map(regionId => ({
          type: 'region',
          id: regionId
        }))
      }
    };
    data.relationships = relationships;
  }
  return { data };
};


export const formatDeleteRegionsFromOrgPayload = (regions) => {
  const data = {
    data: regions.map(regionId => {
      return {
        type: 'region',
        id: regionId
      }
    })
  }
  return data
}