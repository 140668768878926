import React from 'react';
import PropTypes from 'prop-types';
import iconMap from './iconMap';

const Icon = props => {
  const { type } = props;
  const SelectedIcon = iconMap[type];

  return (
    <SelectedIcon {...props} />
  );
};

Icon.propTypes = {
  type: PropTypes.string.isRequired
};

export default Icon;
