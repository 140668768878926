import PropTypes from 'prop-types';
import { Modal, notification } from 'antd';
import Icon from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import RegistrationLayout from 'shared/components/Layouts/RegistrationPageLayout';
import RegistrationForm from 'shared/components/Layouts/Registration/FormContainer';
import * as userService from 'utils/services/users';
import auth from '../../../../Auth';
import {
  setCanAccessAdminDashboard,
  setCanAccessVolunteerDashboard,
  resetAuth,
  loginRequestError
} from 'features/Authentication/redux/authActions';
import SignUpStepperHeader from './SignUpStepperHeader';
import RegistrationOptions from './SignupOptions';
import { 
  setSignupUserData, 
  setAssignRole, 
  verifySignUpSuccess,
  setRegistrationAuthQueryDetails
} from '../../redux/registrationActions';
import CreateAccount from '../Forms/CreateAccount';
import TermsAndConditions from './TermsAndConditionsSteps';
import { processAuthResult } from 'utils/authorize';
import {
  COACH_ID,
  HEAD_REFEREE_ID,
  EVENT_ADMIN_ID,
  JUDGE_ADVISOR_ID,
  REFEREE_ID,
  JUDGE_ID
} from 'shared/constants/eventRoleTypes';
import jwtDecode from 'jwt-decode';
// event admin, head referee, judge advisor
const ADMIN_EVENT_ROLE_TYPE_IDS = [
  HEAD_REFEREE_ID,
  EVENT_ADMIN_ID,
  JUDGE_ADVISOR_ID,
];

// coach, judge, referee, event admin, head referee, judge advisor
const VOLUNTEER_EVENT_ROLE_TYPE_IDS = [
  COACH_ID,
  JUDGE_ID,
  REFEREE_ID,
  HEAD_REFEREE_ID,
  EVENT_ADMIN_ID,
  JUDGE_ADVISOR_ID,
];

const TOTAL_STEPS_COUNT = 4;

const SignUpContainer = (props) => {
  const { history, location: { search }, setSignupData, setRegistrationQuery, authorized, resetAuthDetails,
  setAdminDashboardAccess, setVolunteerDashboardAccess, initialStep} = props;
  const [createnew, setCreate] = useState(false);
  const [currentStep, setCurrentStep] = useState(initialStep == null ? 1 : initialStep);
  const [query, setQuery] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const { email: queryEmail } = query;

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const code = urlParams.get('code');
    const email = urlParams.get('email');
    const id = urlParams.get('id');
    const error = urlParams.get('error');
    const verification = urlParams.get('verify_user');
    if (error) {
      notification.error({
        message: 'Error',
        description: error,
      });
      return;
    }
    if (verification === 'true' && code && email && id) {
      const payload = {
        code,
        userId: id,
        email: email.replace(/\s/g, '+'),
      };
      setRegistrationQuery(payload)
      setSignupData(payload);
      setQuery(payload);
    }
  }, [search]);
  
  const handleCreateNew = async (password, successCb = () => {}) => {
    if (queryEmail && password) {
      try {
        await userService.createNewAccount({ ...query, password });
        successCb()
      } catch (e) {
        console.log(e);
        notification.error({
          message: 'Error',
          description: 'Your new account setup failed.',
        });
        
      }
    }
  };

  const googleAuthError = () => {
    Modal.error({
      title: <h1 className='paragraph--charcoal paragraph--large paragraph--bold'>Email does not match the email that was invited to the FIRST Remote Hub.</h1>,
    });
  }

  const googleAuthSuccess = (authResultValue) => {
    Modal.success({
      title: <h1 className='paragraph--charcoal paragraph--large paragraph--bold'>Success!</h1>,
      content: (<p className='paragraph--charcoal paragraph--medium'>
        Please click
        {' '}
        <b>Next</b>
        {' '}
        for a few last things.
                </p>),
      okText: 'Next',
      onOk: async () => {
        try{
          await userService.verifyInvitation(query);
          await userService.assignUserRole(query)
          await props.verifySignUpSuccess();
          await props.setAssignRole();
          const roleData = await processAuthResult(authResultValue);
          if(roleData){
            const canAccessAdminDashboard = ADMIN_EVENT_ROLE_TYPE_IDS.some(i => roleData.userEventRoles.includes(i)) || roleData.userType !== 'User';
            const canAccessVolunteerDashboard = VOLUNTEER_EVENT_ROLE_TYPE_IDS.some(i => roleData.userEventRoles.includes(i));
            setAdminDashboardAccess(canAccessAdminDashboard);
            setVolunteerDashboardAccess(canAccessVolunteerDashboard);
          }
          setCurrentStep(3);
        }catch(err){
          if(err && err.data && err.data.error && err.data.error === 'Entity not found.'){
            notification.error({
              message: 'Error',
              description: "Code expired",
              onClose: ()=>history.push('login'),
              duration: 3000,
            })
          }
        }
      }
    });
  }
  
  const handlePostGoogleAuth = async ()=>{
    try{
      const authResult = await auth.GetInstance().handleAuthentication();
      const idTokenPayload = authResult.idTokenPayload || jwtDecode(authResult.idToken) || {};
          
      if ((query.email && idTokenPayload.email) && query.email.toLowerCase() === idTokenPayload.email.toLowerCase()) {
        setErrorMessage(null)
        googleAuthSuccess(authResult)
      } else {
        googleAuthError()
        setErrorMessage('Email does not match the email that was invited to the FIRST Remote Hub')
      }
    } catch (err) {
      googleAuthError()
      throw err
    }
  }

  const handleActivate = async () => {
    if (queryEmail) {
      auth.GetInstance().initializeAccountWithGoogle(handlePostGoogleAuth);
    }
  };

  const googleInfo = () => {
    Modal.confirm({
      title: <h1 className='paragraph--charcoal paragraph--large paragraph--bold'>
        For successful account completion use the Google Account matching
        {' '}
        {queryEmail}
        .
             </h1>,
      content: (
        <div>
          <p className='paragraph--charcoal paragraph--medium'>If the wrong account is selected account setup may not be completed.</p>
        </div>
      ),
      icon: <Icon type="question-circle" />,
      onOk: handleActivate,
      okText: 'Continue to Google',
      cancelText: 'Go Back',
      onCancel() {}
    });
  }

  const options = [
    {
      text:'Complete with your email address',
      handleClick: () => {
        setCreate(true)
        setCurrentStep(2)
    },
    isBtnDisabled: !queryEmail
    },
    // { 
    //   text:  'Complete with Google', 
    //   handleClick:  googleInfo,
    //   isBtnDisabled: !queryEmail
    // }
  ];

  const handleBack = () => setCurrentStep(currentStep - 1)

  const renderContent = () => {
    if (currentStep === 3 || currentStep === 4) {
      return (
        <TermsAndConditions
          history={history}
          goToFinalStep={() => {setCurrentStep(4)}}
        />
      )
    } if (createnew && currentStep === 2) {
      return (
        <CreateAccount
          handleCreateNew={handleCreateNew}
          email={queryEmail}
          setNextStep={() => {setCurrentStep(3)}}
        />
      )
    }
    return (
      <div className='registration__signup-form'>
        <RegistrationOptions
          options={options}
          email={queryEmail}
        />
      </div>
    )
  }

  return (
    <RegistrationLayout isSigningUp>
      <SignUpStepperHeader
        currentStep={currentStep}
        totalSteps={TOTAL_STEPS_COUNT}
        handleBack={handleBack}
      />
      <RegistrationForm title="Complete Account Setup" error={errorMessage}>
        {renderContent()}
      </RegistrationForm>
    </RegistrationLayout>
  );
};

SignUpContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  authorized: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  const {
    auth: { authorized, loading, expiresAt, auth0Authorized }
  } = state;
  return {
    authorized,
    expiresAt,
    loading,
    auth0Authorized
  };
};

const mapDispatchToProps = {
  setSignupData: setSignupUserData,
  setAssignRole,
  verifySignUpSuccess,
  setAdminDashboardAccess: setCanAccessAdminDashboard,
  setVolunteerDashboardAccess: setCanAccessVolunteerDashboard,
  setRegistrationQuery: setRegistrationAuthQueryDetails,
  resetAuthDetails: resetAuth,
  loginRequestError
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpContainer);
