



/* eslint-disable no-unused-vars */
import * as sessionService from 'utils/services/sessions';
import * as panelService from 'utils/services/panels';
import * as userService from 'utils/services/users';

import * as matchService from 'utils/services/matches';
import { REFEREE_REVIEW_PANEL_TYPE_ID } from 'shared/constants/panelTypes';
import * as types from '../types/assignedMatches';
import _ from 'lodash';
import { formatMatchData, formatMatchResponse } from 'utils/services/matches/model';
import Panel from 'models/Panel';
import PanelModel from 'models/PanelClassModel';

const sortMatches = (a, b) => {
  const matchA = a.matchTeam.toUpperCase();
  const matchB = b.matchTeam.toUpperCase();
  let comparison = 0;
  if (matchA === matchB) {
    return b.matchTypeName.localeCompare(a.matchTypeName)
  }
  if (matchA > matchB) {
    comparison = 1;
  } else if (matchA < matchB) {
    comparison = -1;
  }
  return comparison;
}
const getScoringSessionsBegin = () => {
  return {
    type: types.GET_ASSIGNED_MATCHES_BEGIN
  }
}

const getScoringSessionsError = () => {
  return {
    type: types.GET_ASSIGNED_MATCHES_ERROR
  }
}

const getScoringSessionsSuccess = (assignedMatches, assignedPanels, matchesCount, loading = false) => {
  return {
    type: types.GET_ASSIGNED_MATCHES_SUCCESS,
    payload: {
      assignedMatches, assignedPanels,
      count: matchesCount,
      loading
    }
  }
}

const stopLoadingScoringSessions = () => {
  return async (dispatch, getState) => {
    const { assignedScoringSessions: { assignedMatches, assignedPanels } } = getState();
    dispatch(getScoringSessionsSuccess(assignedMatches, assignedPanels, assignedMatches.length, false))
  }
}

const setCurrentPage = (currentPage) => {
  return {
    type: types.SET_ASSIGNED_MATCHES_CURRENT_PAGE,
    payload: {
      currentPage
    }
  }
}

export const handlePageChange = (pageNumber) => {
  return async dispatch => {
    await dispatch(setCurrentPage(pageNumber))
  }
}

const updateAssignedMatchesAndPanelData = (matchesData, panelsData, matchesCount, panelCount) => {
  return async (dispatch, getState) => {
    const { assignedScoringSessions: { assignedMatches, assignedPanels } } = getState();
    const newMatchesData = _.uniqBy([...assignedMatches, ...matchesData], 'matchId');
    const newPanelsData = _.uniqBy([...assignedPanels, ...panelsData], 'id');
    const loading = newMatchesData.length !== matchesCount || newPanelsData.length !== panelCount;
    dispatch(getScoringSessionsSuccess(newMatchesData, newPanelsData, matchesCount, loading))
  }
}

const setTeamsCountForAssignedMatches = (count) => {
  return ({
    type: types.SET_ASSIGNED_TEAMS_COUNT,
    payload: count
  })
}

const getSpecificMatch = (matches, id) => {
  let data = []
  matches.forEach(match => {
    if (match.id == id) {
      data.push(match);
    }
  });

  return data[0]
}

const getSpecificMatchIncludes = (match, includes) => {
  let includeData = [];

  for (let relation in match?.relationships) {

    let relationID = match?.relationships[relation]?.data.id;
    includes.forEach(include => {
      if (include?.id == relationID) {
        includeData.push(include)
      }
    });
  }

  // console.log("includesincludes", includeData);
  return includeData
}

const fetchAssignedScoringSessions = (filter, page, sorter = "", refereeOnly = true) => {

  return async (dispatch, getState) => {
    await dispatch(getScoringSessionsBegin())
    try {
      const { eventOverview: { eventData, eventScheduleId } } = getState();

      const season = eventData?.attributes?.season;
      const { id } = eventData;

      const allMatches = await matchService.getMatchByEventId(id)
      const allScores = await matchService.getMatchScoresByEventId(id, season);
      const scoresSet = {};
      allScores.forEach(score => {
        scoresSet[score.id] = score
      })
      const formatMatches = allMatches.map(match => formatMatchData(match, scoresSet));


      let panelsList = await sessionService.getPanelsData(eventScheduleId, 'score');
      let panelListData = []

      panelsList.forEach(panel => {
        if (panel?.panelType?.id == REFEREE_REVIEW_PANEL_TYPE_ID) {
          panelListData.push(panel)
        }
      });

      let panelDataObj = panelListData.map(panel => new PanelModel(panel, 'score'))

      const panelsUsersId = [];
      panelsList.forEach(panel => {
        panel.roleAssignments.forEach(role=>{
          if(role.userId){
            panelsUsersId.push(role.userId);
          }
        })
      })

      const uniqUserIds = _.uniq(panelsUsersId).join(',');

      // GET THE DATA OF ALL THE REFEREES ON THE PANELS
      const usersResponse = await userService.getUsersByIds(uniqUserIds);

      const assignedPanels = panelDataObj.map(d => panelService.formatPanelData(d));
      const formattedPanelsList = assignedPanels.map(p => {
        const panelObj = {}
        const panelJudges = [];
        p.refereesIds.forEach(j => {
          const currentJudge = usersResponse.find(u => u.id === j);
          if (currentJudge) {
            const formattedJudge = {
              ...currentJudge,
              fullName: `${currentJudge.firstName} ${currentJudge.lastName}`
            }
            panelJudges.push(formattedJudge);
          }
        })
        panelObj.referees = panelJudges;
        panelObj.id = p.id;
        panelObj.name = p.name;
        panelObj.panelType = p.panelTypeName;

        return panelObj;
      })
      const sortedPanels = _.sortBy(formattedPanelsList, ['name']);
      await dispatch(getScoringSessionsSuccess(formatMatches, sortedPanels, formatMatches.length, false))
    } catch (err) {
      await dispatch(getScoringSessionsError())
      throw err
    }
  }
}

const getScoringSessionsForAwardsBegin = () => {
  return {
    type: types.GET_ASSIGNED_MATCHES_FOR_AWARDS_BEGIN
  }
}

const getScoringSessionsForAwardsError = () => {
  return {
    type: types.GET_ASSIGNED_MATCHES_FOR_AWARDS_ERROR
  }
}

const getScoringSessionsForAwardsSuccess = (assignedMatches, loading = false, scoreInputObject = {}) => {
  return {
    type: types.GET_ASSIGNED_MATCHES_FOR_AWARDS_SUCCESS,
    payload: {
      assignedMatches,
      scoreInputObject,
      loading
    }
  }
}

const updateAssignedMatchesForAwards = (matchesData, matchesCount) => {
  return async (dispatch, getState) => {
    const { assignedAwards: { assignedScoringSessions } } = getState();
    const newScoringSessions = _.uniqBy([...assignedScoringSessions, ...matchesData], 'matchId');
    const loading = newScoringSessions.length !== matchesCount
    dispatch(getScoringSessionsForAwardsSuccess(newScoringSessions, loading))
  }
}

export const fetchAssignedScoringSessionsOnAwardsPage = (filter, page, sorter) => {
  return async (dispatch, getState) => {
    await dispatch(getScoringSessionsForAwardsBegin())
    try {
      const { eventOverview: { eventData } } = getState();
      const season = eventData?.attributes?.season;
      const { id } = eventData;

      const allMatches = await matchService.getMatchByEventId(id)
      const allScores = await matchService.getMatchScoresByEventId(id, season);
      const scoresSet = {};
      allScores.forEach(score => {
        scoresSet[score.id] = score;
      })
      const formatMatches = allMatches.map(match => formatMatchData(match, scoresSet));
      await dispatch(getScoringSessionsForAwardsSuccess(formatMatches, false, scoresSet))
    } catch (err) {
      await dispatch(getScoringSessionsForAwardsError())
      throw err
    }
  }
}

export default fetchAssignedScoringSessions;