import React from 'react';
import Checkbox from 'shared/components/Checkbox';
import Paragraph from 'shared/components/Paragraph';
import {
  JUDGE_ADVISOR_ID,
  JUDGE_ID,
  HEAD_REFEREE_ID,
  REFEREE_ID,
  EVENT_ADMIN_ID,
  COACH_ID
} from 'shared/constants/eventRoleTypes';

const cls = 'c-event-messaging-form';

export const ROLE_TYPES = {
  [JUDGE_ADVISOR_ID]: 'judgeAdvisor',
  [JUDGE_ID]: 'judgeLabel',
  [HEAD_REFEREE_ID]: 'headRefereeLabel',
  [REFEREE_ID]: 'refereeLabel',
  [EVENT_ADMIN_ID]: 'eventAdminLabel',
  [COACH_ID]: 'coachLabel'
};

export const NON_FLLC_ROLE_TYPES = {
  [JUDGE_ADVISOR_ID]: 'nonChallengeJudgeAdvisor',
  [JUDGE_ID]: 'nonChallengeJudgeLabel',
  [EVENT_ADMIN_ID]: 'eventAdminLabel',
  [COACH_ID]: 'coachLabel'
};

export const ALL = 'ALL';

const ROLE_COLS = [
  [JUDGE_ADVISOR_ID, JUDGE_ID],
  [HEAD_REFEREE_ID, REFEREE_ID],
  [EVENT_ADMIN_ID, COACH_ID]
];

const NON_FLLC_ROLES  = [
  [JUDGE_ADVISOR_ID, JUDGE_ID],
  [EVENT_ADMIN_ID, COACH_ID]
];

const UserRolesWithAccess = (props) => {
  const { t, value, onChange, isFLLC } = props;

  const roles_data = isFLLC ? ROLE_COLS : NON_FLLC_ROLES

  const handleChange = (e) => {
    const allRoles = Object.keys(isFLLC ? ROLE_TYPES : NON_FLLC_ROLE_TYPES);
    let roles = e;
    if ((!value || !value.includes(ALL)) && e.includes(ALL)) {
      roles = [ALL, ...allRoles];
    } else if (value && value.includes(ALL) && !e.includes(ALL)) {
      roles = [];
    } else if (e.length <= allRoles.length && e.includes(ALL)) {
      roles.splice(0, 1);
    } else if (e.length === allRoles.length && !e.includes(ALL)) {
      roles = [ALL, ...allRoles];
    }
    onChange(roles);
  };

  return (
    <Checkbox.Group
      className={`${cls}__roles`}
      onChange={handleChange}
      value={value}
    >
      <Checkbox value={ALL}>
        <Paragraph size='large'>
          {t('selectAllLabel')}
        </Paragraph>
      </Checkbox>
      {roles_data.map((col, i) => (
        <div key={i}>
          {col.map((roleId) => (
            <Checkbox key={roleId} value={roleId}>
              <Paragraph size='large'>
                {t(ROLE_TYPES[roleId])}
              </Paragraph>
            </Checkbox>
          ))}
        </div>
      ))}
    </Checkbox.Group>
  );
};

export default UserRolesWithAccess;
