import React from 'react';
import PropTypes from 'prop-types';
import EventModal from 'features/EventOverviewContainer/components/EventModal';
import RubricModalContent from './RubricModalContent';
import BlockLoading from 'shared/components/Spinner/BlockLoading';

const RubricModal = props => {
  const {
    visible,
    onCancel,
    isInnovationProjectForm,
    isRobotDesignForm,
    isFeedbackForm,
    loading,
    rubric,
  } = props;

  return (
    <EventModal
      visible={visible}
      onCancel={onCancel}
      wrapClassName='teams-coaches-modal'
    >
      <BlockLoading loading={loading}/>
      <RubricModalContent
        rubric={rubric}
        isInnovationProjectForm={isInnovationProjectForm}
        isRobotDesignForm={isRobotDesignForm}
        isFeedbackForm={isFeedbackForm}
      />
    </EventModal>
  );
};

RubricModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  isInnovationProjectForm: PropTypes.bool,
  isRobotDesignForm: PropTypes.bool,
  isFeedbackForm: PropTypes.bool
};

RubricModal.defaultProps = {
  isInnovationProjectForm: false,
  isRobotDesignForm: false,
  isFeedbackForm: false
}

export default RubricModal;
