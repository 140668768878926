import React from 'react';
import PropTypes from 'prop-types';
import './SessionStatus.scss';

const SessionStatus = props => {
  const {
    statusText,
    badgeType
  } = props
  const badgeClassName = `c-session-status__badge c-session-status__badge--${badgeType}`;
  const wrapperClassName = `c-session-status c-session-status--${badgeType}`;

  return (
    <div className={wrapperClassName}>
      <span className={badgeClassName} />
      <p className='m-l-16 paragraph--large paragraph--charcoal paragraph--normal'>{statusText}</p>
    </div>
  );
};

SessionStatus.propTypes = {
  statusText: PropTypes.string,
  badgeType: PropTypes.string
};

SessionStatus.defaultProps = {
  statusText: '',
  badgeType: ''
}

export default SessionStatus;