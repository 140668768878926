import React from 'react';
import PropTypes from 'prop-types';
import SearchBar from 'features/AdminDashboard/components/SearchBar';

import '../styles/event-nav.scss';

const TopBar = (props) => {
  return (
    <div className='event-nav'>
      <SearchBar {...props} />
    </div>
  );
};

TopBar.propTypes = {
  addClick: PropTypes.func,
  addClickTeamOnly: PropTypes.func,
  hideAddBtn: PropTypes.bool
};

TopBar.defaultProps = {
  addClick: () => { },
  addClickTeamOnly: () => { },
  hideAddBtn: false
};

export default TopBar;
