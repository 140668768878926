import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {Form} from 'antd';
import _ from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import TermsWrapper from './TermsWrapper';
// import OtherSections from '../../TermsAndConditions/OtherConditions/Sections';
import Checkbox from 'shared/components/Checkbox';

const FormItem = Form.Item;

const OtherConditions = (props) => {
  const { handleSubmit } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [values, setValues] = useState({
    "user": false,
    "age_accept": false,
    "policy_accept": false,
    "data_accept": false,
    "content_accept": false,
    "material_accept": false,
    "follow_accept": false,
});
  const disabled = !Object.values(values).length || Object.values(values).includes(false);
  
  return (
    <Form name='OtherConditions' form={form}>
      <TermsWrapper
        title='Accept Terms & Conditions'
        btnText={`${t('acceptAndFinishLabel')}`}
        disabled={disabled}
        handleSubmit={handleSubmit}
      >
        <video width="448" controls controlsList="nodownload" onContextMenu={(e)=>e.preventDefault()} >
          <source src="https://first-prod-s3.s3.eu-central-1.amazonaws.com/miscellaneous/FLL_Data_Consent.mp4" type="video/mp4" />
        </video>
        <div>
          <FormItem name='user'>
            <Checkbox checked={values.user} onChange={e => setValues({ ...values, 'user': e.target.checked })}>
              <p>
                {t('onboardingCheckbox1')}
              </p>
            </Checkbox>
          </FormItem>
          <FormItem name='age_accept'>
            <Checkbox checked={values.age_accept} onChange={e => setValues({ ...values, 'age_accept': e.target.checked })}>
              <p>
                <Trans
                  t={t}
                  i18nKey='onboardingCheckbox2'
                >
                  <Link to='/terms-and-conditions'>{{website: t('termsAndConditionsLabel')}}</Link>
                </Trans>
              </p>
            </Checkbox>
          </FormItem>
          <FormItem name='policy_accept'>
            <Checkbox checked={values.policy_accept} onChange={e => setValues({ ...values, 'policy_accept': e.target.checked })}>
              <p>
                <Trans
                  t={t}
                  i18nKey='onboardingCheckbox3'
                >
                  <Link to='/privacy-policy'>{{website: t('privacyPolicyLabel')}}</Link>
                </Trans>
              </p>
            </Checkbox>
          </FormItem>
          <FormItem name='data_accept'>
            <Checkbox checked={values.data_accept} onChange={e => setValues({ ...values, 'data_accept': e.target.checked })}>
              <p>
                {t('onboardingCheckbox4')}
              </p>
            </Checkbox>
          </FormItem>
          <FormItem name='content_accept'>
            <Checkbox checked={values.content_accept} onChange={e => setValues({ ...values, 'content_accept': e.target.checked })}>
              <p>
                {t('onboardingCheckbox5')}
              </p>
            </Checkbox>
          </FormItem>
          <FormItem name='material_accept'>
            <Checkbox checked={values.material_accept} onChange={e => setValues({ ...values, 'material_accept': e.target.checked })}>
              <p>
                {t('onboardingCheckbox6')}
              </p>
            </Checkbox>
          </FormItem>
          <FormItem name='follow_accept'>
            <Checkbox checked={values.follow_accept} onChange={e => setValues({ ...values, 'follow_accept': e.target.checked })}>
              <p>
                <Trans
                  t={t}
                  i18nKey='onboardingCheckbox7'
                >
                  <a href='https://www.firstinspires.org/robotics/fll/core-values'
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    {{website: t('websiteLabel')}}
                  </a>
                </Trans>
              </p>
            </Checkbox>
          </FormItem>
        </div>
      </TermsWrapper>
    </Form>
  );
};

export default (OtherConditions);
