import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Pagination, { getPageFilter, initialPage } from 'shared/components/Pagination';
import TitleSortRow from 'shared/components/TitleSortRow';
import VolunteersList from 'shared/components/CardsList/Volunteers';
import SearchBar from 'features/AdminDashboard/components/SearchBar';
import { handleRequestVolunteers, resendUserInvitation } from 'features/AdminDashboard/redux/actions/volunteersActions';
import VolunteerEventRoles from 'features/EventContainer/models/VolunteerEventRoles';
import { volunteersSortBy } from 'shared/constants/sortOptions';
import EditVolunteerModal from './EditVolunteerModal';

const VolunteersContainer = (props) => {
  const [current, setCurrentPage] = useState(initialPage);
  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [selectedVolunteer, setSelectedVolunteer] = useState({});
  const [editModalVisible, setEditModalVisible] = useState(false);
  const { t } = useTranslation();

  const {
    volunteers,
    included,
    count,
    isFetchingVolunteers,
    needRefreshVolunteer,
    requestVolunteers,
    reinviteUser
  } = props;

  const fetchVolunteers = (pageNumber, nosearch) => {
    // should sort and group by name
    const page = getPageFilter(pageNumber);
    requestVolunteers(page, !nosearch && searchText, sortBy);
  };

  const handleSearch = (nosearch) => {
    if (current !== initialPage) {
      setCurrentPage(initialPage);
    } else if (searchText) {
      fetchVolunteers(initialPage, nosearch);
    }
  };

  useEffect(() => {
    fetchVolunteers(current);
  }, [current, sortBy]);

  useEffect(() => {
    if (needRefreshVolunteer) {
      setCurrentPage(pre => {
        if (pre === initialPage) {
          fetchVolunteers(initialPage);
        }
        return initialPage;
      });
    }
  }, [needRefreshVolunteer])

  const onSortBySelected = (value) => {
    setSortBy(value);
  };

  let formattedVolunteersList = [];
  if (volunteers.length) {
    formattedVolunteersList = volunteers.map(volunteer => {
      const volunteerInfo = new VolunteerEventRoles(volunteer, included);
      const volunteerRolesList = volunteerInfo.getUserEventRolesList();
      const volunteerUser = volunteerInfo.formatUserData();
      const volunteerObj = {
        ...volunteerUser,
        roles: volunteerRolesList,
        handleReinviteClick: () => { reinviteUser(volunteerUser.id) }
      };
      return volunteerObj;
    })
  } else {
    formattedVolunteersList = [];
  }

  const closeEditModal = () => {
    setEditModalVisible(false);
    setSelectedVolunteer({});
  };

  const onEditClick = (userId) => {
    const selectedUser = formattedVolunteersList.find(v => v.id === userId);
    if (selectedUser) {
      setSelectedVolunteer(selectedUser);
      setEditModalVisible(true);
    }
  };

  return (
    <div className='admin-dashboard'>
      <SearchBar
        placeholder='Volunteer'
        searchText={searchText}
        setSearchText={setSearchText}
        handleSearch={handleSearch}
      />
      <EditVolunteerModal
        visible={editModalVisible}
        onCancel={closeEditModal}
        selectedVolunteer={selectedVolunteer}
      />
      <div className='admin-dashboard__list'>
        <TitleSortRow title={t('volunteersLabel')} options={volunteersSortBy} onSelect={onSortBySelected} />
        <VolunteersList
          loading={isFetchingVolunteers}
          volunteers={formattedVolunteersList}
          isEditButtonVisible
          onEditClick={onEditClick}
        />
        <Pagination
          total={count}
          current={current}
          onChange={setCurrentPage}
          loading={isFetchingVolunteers}
        />
      </div>
    </div>
  );
};

VolunteersContainer.propTypes = {
  requestVolunteers: PropTypes.func.isRequired,
  reinviteUser: PropTypes.func.isRequired,
  volunteers: PropTypes.array,
  isFetchingVolunteers: PropTypes.bool.isRequired,
  included: PropTypes.array,
};

VolunteersContainer.defaultProps = {
  volunteers: [],
  included: []
};

const mapStateToProps = (state) => {
  const { adminBoard: { volunteers: { 
    volunteers, included, count, isFetchingVolunteers, needRefreshVolunteer
  } } } = state;
  return {
    volunteers,
    included,
    count,
    isFetchingVolunteers,
    needRefreshVolunteer
  };
};

const mapDispatchToProps = {
  requestVolunteers: handleRequestVolunteers,
  reinviteUser: resendUserInvitation
};

export default connect(mapStateToProps, mapDispatchToProps)(VolunteersContainer);
