import { parseDayDateTime, parseDateString, parseTime } from 'utils/dateTimeUtils';

export default class ScheduleSessionData {
  constructor(responseData, responseIncluded = [], sessionTeams = [], scheduleId = null, apiVersion = 1) {
    this.apiVersion = apiVersion
    this.sessionId = responseData.id;
    //this.sessionTitle = responseData.attributes.title;
    this.sessionTitle = this.apiVersion == 1 ? responseData.attributes.title : responseData.sessionTypeDesc;
    this.sessionStatus = responseData.sessionStatus;
    this.sessionStartTime = this.apiVersion == 1 ? responseData.attributes.start_time : responseData.startTime;
    this.sessionEndTime = this.apiVersion == 1 ? responseData.attributes.end_time : responseData.endTime;
    this.sessionUrl = this.apiVersion == 1 ? responseData.attributes.session_url : responseData.session_url;
    this.notes = this.apiVersion == 1 ? responseData.attributes.notes : responseData.notes;
    this.scheduleId = this.apiVersion == 1 ? responseData.id : scheduleId
    this.sessionTypeId = this.apiVersion == 1 ? responseData.relationships.session_type.data.id : responseData.sessionTypeId;
    // this.panel = (((responseData||{}).relationships||{}).panel||{}).data;
    // this.panel = responseData.panel;
    this.panel = this.apiVersion == 1 ? (((responseData || {}).relationships || {}).panel || {}).data : responseData.panelId ? responseData.panel : null
    this.panelId = this.apiVersion == 1 ? ((((responseData || {}).relationships || {}).panel || {}).data || {}).id : responseData.panelId
    // this.teamId = ((((responseData||{}).relationships||{}).team||{}).data||{}).id;
    this.teamId = this.apiVersion == 1 ? ((((responseData || {}).relationships || {}).team || {}).data || {}).id : responseData.teamId;
    this.teamName = responseData?.team?.name;
    this.teamNumber = responseData?.team?.teamNumber;
    this.included = responseIncluded || [];
    this.sessionTeams = sessionTeams;
    // this.sessionStatusId = (responseData.relationships.session_status && responseData.relationships.session_status.data) ? responseData.relationships.session_status.data.id : undefined;
    this.sessionStatusId = this.apiVersion == 1 ? (responseData.relationships.session_status && responseData.relationships.session_status.data) ? responseData.relationships.session_status.data.id : undefined : responseData.sessionStatusId
    // this.externalLinkId = (responseData.relationships.external_link && responseData.relationships.external_link.data) ? responseData.relationships.external_link.data.id : undefined;
    this.externalLinkId = this.apiVersion == 1 ? (responseData.relationships.external_link && responseData.relationships.external_link.data) ? responseData.relationships.external_link.data.id : undefined : responseData.externalLinkId
    this.externalLinkUrl = responseData.externalLinkUrl
    this.digital_rubric = apiVersion === 1 ? ((((responseData || {}).relationships || {}).digital_rubric || {}).data || {}).id : responseData.judgeRubricId;
    this.matchId = this.apiVersion == 1 ? ((((responseData || {}).relationships || {}).matches || {}).data || []) : null
  }

  getMatchNumber = () => {
    const matchId = (this.matchId[0] || {}).id;
    if (!matchId) return undefined;
    const match = this.included.find(i => (i.type === 'match' && i.id === matchId));
    if (!match) return undefined;
    return ((match || {}).attributes || {}).match_number
  }

  getSessionTypeName = () => {
    if(this.apiVersion == 2){
      return this.sessionTitle;
    }

    const sessionType = this.included.find(i => i.id === this.sessionTypeId);
    if (!sessionType) {
      return undefined;
    }
    const sessionTypeName = sessionType.attributes.description;
    return sessionTypeName
  }

  getScheduleData = () => {
    const sessionSchedule = this.included.find(i => i.id === this.scheduleId);
    return sessionSchedule
  }

  getScheduleStartTime = () => {
    const sessionSchedule = this.getScheduleData();
    if (!sessionSchedule) {
      return undefined
    }
    const startTime = sessionSchedule.attributes.start_time;
    return startTime
  }

  getScheduleEndTime = () => {
    const sessionSchedule = this.getScheduleData();
    if (!sessionSchedule) {
      return undefined
    }
    const endTime = sessionSchedule.attributes.end_time;
    return endTime
  }

  getScheduleTimeZone = () => {
    const sessionSchedule = this.getScheduleData();
    if (!sessionSchedule) {
      return undefined
    }
    const timeZone = sessionSchedule.attributes.time_zone;
    return timeZone
  }

  parseStartDate = (time_zone) => {
    if (!this.sessionStartTime) {
      return undefined;
    }
    const dateString = time_zone ? parseDateString(this.sessionStartTime, time_zone) : parseDateString(this.sessionStartTime);
    return dateString;
  }

  parseStartTime = (time_zone, format = false) => { //true = 24, false = 12
    if (!this.sessionStartTime) {
      return undefined;
    }
    const targetTimeString = time_zone ? parseTime(this.sessionStartTime, time_zone, format) : parseTime(this.sessionStartTime, format);
    return targetTimeString;
  }

  parseEndDate = (time_zone) => {
    if (!this.sessionEndTime) {
      return undefined;
    }
    const dateString = time_zone ? parseDateString(this.sessionEndTime, time_zone) : parseDateString(this.sessionEndTime);
    return dateString;
  }

  parseEndTime = (time_zone, format = false) => { //true = 24, false = 12
    if (!this.sessionEndTime) {
      return undefined;
    }
    const targetTimeString = time_zone ? parseTime(this.sessionEndTime, time_zone, format) : parseTime(this.sessionEndTime, format);
    return targetTimeString;
  }

  getTeamNumberName = () => {

    if (!this.teamId) {
      return null;
    }
    const selectedTeam = this.sessionTeams.find(t => t.id === this.teamId) || {};

    const teamNameString = selectedTeam.teamNumber ? `${selectedTeam.teamNumber} - ${selectedTeam.teamName}` : `${selectedTeam.teamName}`;

    return teamNameString;
  }

  getSessionPanel = () => {
    if (!this.panel) {
      return undefined;
    }

    const panelId = this.panel.id;
    const selectedPanel = this.included.find(i => i.id === panelId);
    return selectedPanel;

  }

  getSessionPanelName = () => {
    const sessionPanel = this.getSessionPanel();
    if (!sessionPanel) {
      return undefined;
    }
    return sessionPanel
  }

  parseSessionStartDayDateTime = () => {
    const timeString = parseDayDateTime(this.sessionStartTime);
    // Mon, Oct 26, 11:53 am
    return timeString;
  }

  getSessionStatusName = () => {

    if (this.apiVersion == 2) {
      return this.sessionStatus;
    }

    if (!this.sessionStatusId) {
      return undefined;
    }

    const sessionStatusObj = this.included.find(i => i.id === this.sessionStatusId);

    if (sessionStatusObj) {
      const statusName = sessionStatusObj.attributes.name;
      return statusName
    }

    return undefined;

  }

  getPanelName = () => {
    if(this.apiVersion == 2){
      return this.panelId ? this.panel.name : undefined
    }

    const panel = this.getSessionPanel();
    const { attributes } = (panel || {})
    const { name } = attributes || {};
    return name;
  }

  getTeamName = () => {

    if(this.apiVersion == 2){
      return (this.teamName && this.teamNumber) ? `${this.teamNumber} - ${this.teamName}` : `${this.teamName}`;
    }

    let sessionTeamName = ''
    const sessionTeam = this.included.find(i => i.id === this.teamId);
    if (sessionTeam) {
      const sessionTeamDetails = sessionTeam.attributes || false;
      if (sessionTeamDetails) {
        const { attributes: { name, team_number } } = sessionTeam;
        sessionTeamName = (team_number && name) ? `${team_number} - ${name}` : `${name}`
      }
    }

    return sessionTeamName;
    
  }

  parseTeamNumber = () => {
    let sessionTeamNumber = ''
    const sessionTeam = this.included.find(i => i.id === this.teamId);
    if (sessionTeam) {
      const sessionTeamDetails = sessionTeam.attributes || false;
      if (sessionTeamDetails) {
        const { attributes: { team_number } } = sessionTeam;
        sessionTeamNumber = team_number || ''
      }

    }
    return sessionTeamNumber;
  }

  parseTeamName = () => {
    let sessionTeamNumber = ''
    const sessionTeam = this.included.find(i => i.id === this.teamId);
    if (sessionTeam) {
      const sessionTeamDetails = sessionTeam.attributes || false;
      if (sessionTeamDetails) {
        const { attributes: { name } } = sessionTeam;
        sessionTeamNumber = name || ''
      }

    }
    return sessionTeamNumber;
  }

  getExternalLink = () => {

    if(this.apiVersion == 2){
      return this.externalLinkUrl
    }

    let externalLink;
    if (!this.externalLinkId) {
      return externalLink
    }

    const externalLinkDetails = this.included.find(i => i.id === this.externalLinkId);
    if (!externalLinkDetails) {
      return externalLink;
    }
    externalLink = externalLinkDetails.attributes ? externalLinkDetails.attributes.url : '';
    return externalLink
  }
}

