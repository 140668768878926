import * as sessionService from 'utils/services/sessions';
import { notification } from 'antd';
import * as types from '../../types/eventScheduleTypes';

const createSessionUrlBegin = () => {
  return {
    type: types.CREATE_NEW_SESSION_LINK_BEGIN
  }
}

const createSessionUrlSuccess = () => {
  return {
    type: types.CREATE_NEW_SESSION_LINK_SUCCESS
  }
}

const createSessionUrlError = () => {
  return {
    type: types.CREATE_NEW_SESSION_LINK_ERROR
  }
}


const handleCreateSessionUrl = (sessionId, sessionUrl, cb = () => {}) => {
  return async dispatch => {
    await dispatch(createSessionUrlBegin())
    try {
      await sessionService.createExternalLink(sessionId, sessionUrl);
      await dispatch(createSessionUrlSuccess());
      cb(sessionUrl);
      notification.success({
        message: 'Success',
        description: 'New Session URL Created',
      });
    } catch(err) {
      notification.error({
        message: 'Error',
        description: 'Error creating new Session URL'
      });
      await dispatch(createSessionUrlError())
      throw err;
    }
  }

}

export default handleCreateSessionUrl;