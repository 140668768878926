import React from 'react';
import { useHistory } from 'react-router-dom';
import BackLink from 'shared/components/BackLink';
import { useTranslation } from 'react-i18next';


export default () => {
  const browserHistory = useHistory();
  const { t } = useTranslation()
  const backClick = () => {
    browserHistory.push('/volunteer');
  };

  return (
    <BackLink
      backClick={backClick} 
      text={t('backToAllEventsLabel')}
    />
  );
};
