import React from 'react';
import { ClipLoader } from 'react-spinners';
import './styles/PageLoading.scss';

export default ({ loading }) => {
  return loading ? (
    <div className='c-pageloading'>
      <ClipLoader size={36} color='#0066B3' />
    </div>
  ) : null;
};
