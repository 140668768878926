import React from 'react';
import Paragraph from 'shared/components/Paragraph';
import Divider from 'shared/components/Divider';

const NominatedList = ({ assignments }) => {
  return (
    <div className='c-event-awards__nominated-list'>
      {assignments.map(({ id, place, teamNumberName }, i) => (
        <React.Fragment key={id}>
          <div className='c-event-awards__nominated-team'>
            <Paragraph size='large' >{place}</Paragraph>
            <Paragraph size='large' weight='bold'>{teamNumberName}</Paragraph>
          </div>
          {(i + 1) < assignments.length && <Divider margin={12} />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default NominatedList;
