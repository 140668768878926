/* eslint-disable import/prefer-default-export */
const pendingFile = {
  status: 'Pending',
  description: 'File is pending. Please try again later.'
}

const uploadedFile = {
  status: 'Uploaded',
  description: 'File scan in progress. Please try again later.'
}

const uploadFailed = {
  status: 'UploadFailed',
  description: 'File upload was unsuccessful. Notify uploader to try again with a new file.'
}

const virusScanFailed = {
  status: 'VirusScanFailed',
  description: 'Insecure File detected. Notify uploader to try again with a new file.'
}

const processing = {
  status: 'Processing',
  description: 'File is processing. Please try again later.'
}

const processingFailed = {
  status: 'ProcessingFailed',
  description: 'Unable to process file. Notify uploader to try again with a new file.'
}

const available = {
  status: 'Available',
  description: 'File is available.'
}

const fileStatuses = [
  pendingFile,
  uploadedFile,
  uploadFailed,
  virusScanFailed,
  processing,
  processingFailed,
  available
]

export {fileStatuses};