import React from 'react';
import PropTypes from 'prop-types';
import EventModal from 'shared/components/EventModal';
import Container from 'shared/components/Container';
import { Col, Row, Form, Button } from 'antd';
import Input from 'shared/components/Input';
import { useTranslation } from 'react-i18next';

const rowProps = {
  type: 'flex', gutter: 20,
  className: 'm-t-20'
};

const colProps = {
  xs: 24,
  md: 16
}
const FormItem = Form.Item;

const EditCoachModal = props => {
  const {
    onCancel,
    visible,
    selectedCoach: {
      id, email, firstName, lastName, phone
    },
    updateCoach,
    isUpdatingCoach
  } = props;

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onSaveClick = async (e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields().then(async (values, err) => {
      await updateCoach(id, values);
    });
  };

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  return (
    <EventModal
      onCancel={onCancel}
      visible={visible}
      renderHeader={() => (<span>Edit User</span>)}
    >
      <Container>
        <h1>Edit User</h1>
      </Container>
      <Container type='medium'>
        <Form hideRequiredMark onFinish={onSaveClick} onFinishFailed={onFinishFailed} form={form} name='EditCoachInfo' initialValues={{email: email, firstName: firstName, lastName: lastName, phone: phone || ''}}>
          <Row {...rowProps}>
            <Col xs={24} md={16}>
              <FormItem name='email' label={t('emailLabel')}>
                <Input required disabled />
              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col {...colProps}>
              <FormItem name='firstName' label={t('firstNameLabel')} rules={[{ required: true, message: t('enterFirstNameLabel') }]}>
                <Input size='large' required />
              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col {...colProps}>
              <FormItem name='lastName' label={t('lastNameLabel')} rules={[{ required: true, message: t('enterLastNameLabel') }]}>
                <Input size='large' required />
              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col {...colProps}>
              <FormItem name='phone' label={t('phoneOptionalLabel')}>
                <Input size='large' />
              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col xs={24} md={16} className='admin-dashboard__edit-modal-buttons'>
              <Button
                loading={isUpdatingCoach}
                htmlType='submit'
              >
                {t('saveLabel')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </EventModal>
  );
};

EditCoachModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default EditCoachModal;
