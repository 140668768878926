import { Tooltip, Form, Button } from 'antd';
import React, { useEffect } from 'react';
import Heading from 'shared/components/Heading';
import Input from 'shared/components/Input';
import InfoOutlined from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import EventModal from 'features/EventContainer/components/EventModal';
import UserRolesWithAccess, { ALL } from '../UserRolesWithAccess';

const FormItem = Form.Item;

const cls = 'c-event-messaging-form';
const formName = 'AddMessageChannel';

const AddMessageChannel = (props) => {
  const {
    onCancel, 
    visible, 
    handleSave,
    loading,
    channel: { id, title, description, link, roles },
    isFLLC
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  
  const handleSubmit = async (e) => {
    if (e && e.preventDefault) e.preventDefault();
    form.validateFields().then(async (values) => {
      const updatedRoles = values.roles.filter(v => v !== ALL);
      const roleProps = id && {
        id,
        newRoles: updatedRoles.filter((role) => !roles.includes(role)),
        delRoles: roles.filter((role) => !updatedRoles.includes(role))
      };
      await handleSave({ ...values, roles: updatedRoles, ...roleProps });
      onCancel();
    }).catch((err) => {console.log(err)});
  };

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const validateRoles = (rule, value, callback) => {
    if (!value || !value.length) {
      callback(t('selectUserRoles'));
    } else {
      callback();
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  return (
    <EventModal {...props}>
      <div className='modal__title'>
        <Heading tag={2} type='primary' weight='bold'>
          {id ? 'Edit Messaging Contact' : t('addNewMessagingContactLabel')}
        </Heading>
        <Tooltip title={t('messagingChannelTooltip')} placement='left'>
          <span><InfoOutlined style={{ color: '#0066B3' }} /></span>
        </Tooltip>
      </div>
      <Form hideRequiredMark className={cls} onFinish={handleSubmit} onFinishFailed={onFinishFailed} name={formName} form={form}>
        <div>
          <FormItem name='title' label={t('nameLabel')} rules={[{ required: true, message: t('enterMessagingTitleLabel') }]} initialValue={title}>
            <Input size='large' placeholder={t('nameLabel')} />
          </FormItem>
          <FormItem name='description' label={t('purposeLabel')} initialValue={description}>
            <Input size='large' placeholder={t('descriptionLabel')} />
          </FormItem>
          <FormItem name='link' label={t('linkOrEmailLabel')} rules={[{ required: true, message: t('enterLinkEmailLabel') }]} initialValue={link}>
            <Input size='large' placeholder={t('linkOrEmailLabel')} />
          </FormItem>
          <FormItem name='roles' label={t('userRolesWithAccessLabel')} rules={[{ validator: validateRoles }]} initialValue={roles}>
            <UserRolesWithAccess t={t} isFLLC={isFLLC}/>
          </FormItem>
        </div>
        <div className={`${cls}__actions`}>
          <Button key='back' onClick={onCancel} additionalClassNames='m-r-20'>
            {t('cancelLabel')}
          </Button>
          <Button htmlType='submit' loading={loading}>
            {t('saveLabel')}
          </Button>
        </div>
      </Form>
    </EventModal>
  );
};

export default (AddMessageChannel);
