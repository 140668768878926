import React, {useState} from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import JoinBtn from 'shared/components/Button/JoinBtn';
import * as userTypes from 'shared/constants/userTypes';
import * as eventRoleTypes from 'shared/constants/eventRoleTypes';
import MeetingIcon from 'shared/components/Icon/icons/Meeting';
import MeetingSessionModal from './MeetingSessionModal';
import { parseDateString, parseTime } from 'utils/dateTimeUtils';
import {sanitizeUrl as sanitize} from '@braintree/sanitize-url'



const MeetingCard = props => {
  const [modalVisible, setModalVisible] = useState(false);

  const {
    endTime,
    startTime,
    title,
    date,
    meetingId,
    url,
    userEventRoleIds,
    userType,
    isJoinLinkDisabled,
    time_zone,
    use_24_hour_format
  } = props;

  const closeModal = () => {
    setModalVisible(false);
   
  }

  const openModal = () => {
    setModalVisible(true);
  }

  const canEditSchedule = userType === userTypes.FIRST_ADMIN ||
  userType === userTypes.PARTNER_ADMIN ||
  userEventRoleIds.includes(eventRoleTypes.EVENT_ADMIN_ID);

  const converted_startTime = parseTime(startTime, time_zone, use_24_hour_format);
  const converted_endTime = parseTime(endTime, time_zone, use_24_hour_format);
  const converted_date = parseDateString(startTime, time_zone)

  return (
    <>
      <MeetingSessionModal
        visible={modalVisible}
        onCancel={closeModal}
        formType='edit'
        meetingId={meetingId}
        title='Edit Meeting'
      />

      <button disabled={!canEditSchedule} type='button' className='event-schedule-card-btn' onClick={openModal}>
        <div className='event-session-card event-session-card--complete'>
          <div className="event-session-card__rectangle event-session-card__rectangle--meeting">
            <Icon component={MeetingIcon} className='event-session-card__event-icon' />
          </div>
          <div className='event-session-card__data event-session-card__data--complete'>
            <p className='paragraph--default paragraph--bold paragraph--charcoal event-session-card__data__title'>{title}</p>
            <div className='event-session-card__data__date-time'>
              <span>
                <p className='paragraph--medium paragraph--charcoal'>{converted_date}</p>
                <p className='paragraph--medium paragraph--charcoal'>
                  {converted_startTime}
                  {' '}
                  -
                  {' '}
                  {converted_endTime}
                </p>
              </span>
              {url ? (
                <JoinBtn isDisabled={isJoinLinkDisabled} url={sanitize(url)} />
              ): null}
            </div>
          </div>
        </div>

      </button>
    </>
  );
};

MeetingCard.propTypes = {
  endTime: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  meetingId: PropTypes.string.isRequired,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userEventRoleIds: PropTypes.array.isRequired,
  userType: PropTypes.string.isRequired
};

MeetingCard.defaultProps = {
  url: null
}

const mapStateToProps = (state) => {
  const { event: { eventDetails }, auth: { userType, userInfo: { use_24_hour_format } } } = state;
  const {
    userEventRoleIds,
    time_zone,
  } = eventDetails
  return { userEventRoleIds, userType, time_zone, use_24_hour_format };
};

export default connect(mapStateToProps)(MeetingCard);