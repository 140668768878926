/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import timezoneOptions from 'shared/constants/timezones';
import Dropdown from 'shared/components/Dropdown';

import EventOverviewCollapse from './EventOverviewCollapse';
import BackToBoard from '../BackToBoard';
import '../../styles/EventOverviewSchedule.scss';

const { Option } = Dropdown;

const EventOverviewSchedule = (props) => {
  const {
    scheduleIncluded,
    scheduleData,
    eventDetails,
    handleTabClick,
  } = props;
  const {time_zone} = eventDetails
  const { t } = useTranslation();
  return (
    <>
      <div className='event-overview-schedule'>
        <div className='event-overview-schedule__title'>
          <h1 className='heading--charcoal h-1'>{t('scheduleLabel')}</h1>
          <Dropdown additionalClassNames='event-overview-schedule__dropdown' value={time_zone?time_zone: undefined} disabled={true} showArrow={false}>
            {timezoneOptions.map(to => (
              <Option value={to.id} key={to.id}>{to.description}</Option>
            ))}
          </Dropdown>
        </div>
        <div>
          <EventOverviewCollapse
            scheduleIncluded={scheduleIncluded}
            scheduleData={scheduleData}
            handleTabClick={handleTabClick}
            time_zone={time_zone}
          />
        </div>
      </div>
    </>
  );
};

EventOverviewSchedule.propTypes = {
  scheduleData: PropTypes.array,
  scheduleIncluded: PropTypes.array,
  eventDetails: PropTypes.object.isRequired
};

EventOverviewSchedule.defaultProps = {
  scheduleData: [],
  scheduleIncluded: [],
};

export default EventOverviewSchedule;
