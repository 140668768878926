import {formatMatchFiles} from 'utils/services/eventFiles/model';

export default class Match {
  constructor(responseData, responseIncluded = []) {
    const { id, relationships } = responseData;
    this.matchId = id;
    this.sessionId = relationships.session.data.id;
    this.matchStatusId = relationships.match_status.data.id;
    this.matchTypeId = relationships.match_type.data.id;
    this.files = relationships.files.data || [];
    this.included = responseIncluded;
    this.matchNumber = responseData.attributes ? responseData.attributes.match_number : undefined;
    this.scoreId = (((relationships||{}).score||{}).data||{}).id
  }

  getSessionData = () => {
    if (!this.sessionId) return undefined;
    const matchPanel = this.included.find(i => i.id === this.sessionId);
    return matchPanel;
  }

  getSessionName = () => {
    const panelData = this.getSessionData();
    if (!panelData) return undefined;
    const {name} = panelData.attributes;
    return name;
  }

  getMatchStatusData = () => {
    if (!this.matchStatusId) return undefined;
    const matchStatus = this.included.find(i => i.id === this.matchStatusId);
    return matchStatus;
  }

  getMatchStatusName = () => {
    const matchStatusData = this.getMatchStatusData();
    if (!matchStatusData) return undefined;
    const {name} = matchStatusData.attributes;
    return name;
  }

  getMatchTypeData = () => {
    if (!this.matchTypeId) return undefined;
    const matchStatus = this.included.find(i => i.id === this.matchTypeId);
    return matchStatus;
  }

  getMatchTypeName = () => {
    const matchTypeData = this.getMatchTypeData();
    if (!matchTypeData) return undefined;
    const {name} = matchTypeData.attributes;
    return name;
  }

  getFiles = () => {
    const sessionFiles = formatMatchFiles(this.files, this.included); 
    
    return sessionFiles;
  }
}