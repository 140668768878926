import React from 'react';
import PropTypes from 'prop-types';
import { Button as AntButton } from 'antd';
import './styles/BtnPrimary.scss';

const BUTTON_SIZES = ['small', 'large', 'medium']

const Button = (props) => {
  const { additionalClassNames, children, disabled, htmlType, size, onClick, ..._props } = props;

  const className = `btn-primary btn--${size} ${additionalClassNames}`
  return (
    <div className='btn-primary-container'>
      <AntButton htmlType={htmlType} type='primary' disabled={disabled} className={className} onClick={onClick} {..._props}>
        {children}
      </AntButton>
    </div>
  );
};

Button.propTypes = {
  htmlType: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  styleType: PropTypes.string,
  additionalClassNames: PropTypes.string,
  size: PropTypes.oneOf(BUTTON_SIZES),
  onClick: PropTypes.func
};

Button.defaultProps = {
  htmlType: 'button',
  disabled: false,
  type: 'primary',
  styleType: 'primary',
  additionalClassNames: '',
  size: 'large',
  onClick: () => { }
};

export default Button;
