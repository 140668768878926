import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import EventHeader from 'features/EventContainer/components/EventHeader';
import Tabs from 'shared/components/Tabs/EventTabs';
import VolunteersContainer from 'features/EventContainer/components/EventVolunteersContainer';
import PageLayout from 'shared/components/Layouts/PageLayout';
import ScreenLoading from 'shared/components/Spinner/ScreenLoading';
import SponsorsContainer from 'features/EventContainer/components/SponsorsContainer';
import AwardsContainer from 'features/EventContainer/components/AwardsContainer';
import TeamsContainer from 'features/EventContainer/components/TeamsContainer';
import JudgingContainer from 'features/EventContainer/components/JudgingContainer';
import ScoringContainer from 'features/EventContainer/components/ScoringContainer';
import LeaderboardContainer from 'features/LeaderboardContainer';
import MessagingContainer from 'features/MessagingContainer';
import {
  handleEventDetails, 
  getUserEventAssignments,
} from 'features/EventContainer/redux/actions/eventActions';
import {
  handleEventSchedules,
  cleanUpScoringSessionsData
} from 'features/EventContainer/redux/actions/scheduleActions';
import { 
  fetchEventLeaderboard
} from 'features/LeaderboardContainer/redux/leaderboardActions';
import { 
  fetchEventAwards,
  resetEventAwards
} from 'features/EventContainer/redux/actions/awardsActions';
import { PUBLISHED } from 'shared/constants/publishStatus';
import SingleEvent from 'models/Event';
import Container from 'shared/components/Container';
import history from 'history/browser';
import * as userTypes from 'shared/constants/userTypes';
import * as eventRoleTypes from 'shared/constants/eventRoleTypes';
import {
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FLL_CHALLENGE_ID,
  FTC_ID
} from 'shared/constants/programIds';
import ScheduleContainer from './components/ScheduleContainer';

const LIMITED_SECTIONS_PROGRAM_TYPES = [
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FTC_ID
];

const { TabPane } = Tabs;

const EventContainer = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const {hash} = location;
  const [defaultTabKey, setDefaultTabKey] = useState(hash?hash:'#1');
  const { id: eventId } = useParams();
  const { eventDetails, userType, partnerAdminRegionsPrograms, awardIsPublished, leaderboardIsPublished, leaderboardId } = props;
  const whiteTextClassName = 'paragraph--large paragraph--bold paragraph--white m-t-24';

  const {
    error,
    isFetchingEventDetails,
    included,
    isFetchingUserEventRoles,
    userEventRoleIds,
    eventProgramId,
    eventRegionId,
    scheduleError,
    time_zone,
    attributes
  } = eventDetails;

  const {event_type} = attributes;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [defaultTabKey])

  const isAdminUser = userType === userTypes.FIRST_ADMIN || userType === userTypes.PARTNER_ADMIN;

  const canViewAllTabs = isAdminUser || userEventRoleIds.includes(eventRoleTypes.EVENT_ADMIN_ID);
  const canPublishAwards = isAdminUser ||
    userEventRoleIds.includes(eventRoleTypes.EVENT_ADMIN_ID);

  const canViewJudgingTab = isAdminUser ||
    userEventRoleIds.includes(eventRoleTypes.EVENT_ADMIN_ID) ||
    userEventRoleIds.includes(eventRoleTypes.JUDGE_ADVISOR_ID);

  const canViewScoringTab = isAdminUser ||
    userEventRoleIds.includes(eventRoleTypes.EVENT_ADMIN_ID) ||
    userEventRoleIds.includes(eventRoleTypes.HEAD_REFEREE_ID);

  const eventHasScoringTab = !LIMITED_SECTIONS_PROGRAM_TYPES.includes(eventProgramId);
  const showLeaderboard = [FLL_CHALLENGE_ID, FTC_ID].includes(eventProgramId);
  const isFTC = FTC_ID === eventProgramId;
  const isFLLC = FLL_CHALLENGE_ID === eventProgramId;

  const isUserHeadRefereeOrJudgeAdvisor = userEventRoleIds.includes(eventRoleTypes.HEAD_REFEREE_ID) || 
    userEventRoleIds.includes(eventRoleTypes.JUDGE_ADVISOR_ID)
  const isLoading = isFetchingEventDetails || (isFetchingUserEventRoles && userType === userTypes.USER);

  const canViewHeadRefereeOrJudgeAdvisor = canViewAllTabs || isUserHeadRefereeOrJudgeAdvisor;

  const handleTabClick = (tabKey) => {
    history.replace(`/events/${eventId}${tabKey}`);
    setDefaultTabKey(tabKey)
  };

  useEffect(() => {
    props.getEventDetails(eventId);
    props.getUserEventAssignments(eventId);
    return ()=>props.cleanUpScoringSessionsData();
  }, [eventId]);

  const scheduleId = ((((eventDetails||{}).relationships||{}).schedule||{}).data||{}).id;

  useEffect(()=>{
    scheduleId&&props.getEventSchedules(scheduleId);
  },[scheduleId])

  useEffect(() => {
    if (leaderboardId && (isFTC || isFLLC)) {
      props.fetchEventLeaderboard(leaderboardId);
    }
  }, [leaderboardId, eventProgramId])
  useEffect(() => {
    if (eventProgramId) {
      if (!isFTC) {
        // props.fetchEventAwards(eventId);
      } else {
        props.resetEventAwards(eventId);
      }
    }
  }, [eventId, eventProgramId, isFTC]);

  if (isLoading) {
    return (
      <ScreenLoading loading subTitle={t('loadingEventLabel')} />
    );
  }

  if (error) {
    return (
      <PageLayout>
        <Container>
          <p className={whiteTextClassName}>Error loading the event.</p>
        </Container>
      </PageLayout>
    )
  }

  if (scheduleError) {
    return (
      <PageLayout>
        <Container>
          <p className={whiteTextClassName}>{t('scheduleNotAvailableLabel')}</p>
          <p className={whiteTextClassName}>{t("tryAgainLabel")}</p>
        </Container>
      </PageLayout>
    )
  }

  if (userType === userTypes.USER && !userEventRoleIds.length) {
    return (
      <PageLayout>
        <Container>
          <p className={whiteTextClassName}>{t('noPermissionLabel')}</p>
        </Container>
      </PageLayout>
    )
  }

  if (userType === userTypes.PARTNER_ADMIN && !partnerAdminRegionsPrograms.map(e => e.regionId).includes(eventRegionId)) {
    return (
      <PageLayout>
        <Container>
          <p className={whiteTextClassName}>{t('noPermissionLabel')}</p>
        </Container>
      </PageLayout>
    )
  }

  const eventPage = new SingleEvent(eventDetails, included);
  const eventDateRange = eventPage.parseStartEndTimeShortenedString();
  const eventLocation = eventPage.getEventLocation();

  return (
    <PageLayout>
      <EventHeader
        eventTitle={eventPage.name}
        eventDateRange={eventDateRange}
        eventLocation={eventLocation}
        eventProgramId={eventProgramId}
      />
      <Tabs activeKey={defaultTabKey} onTabClick={handleTabClick}>
        {(canViewHeadRefereeOrJudgeAdvisor) && (
          <TabPane tab={t('scheduleLabel')} key="#1" id='event-schedule'>
            <ScheduleContainer
              eventId={eventId}
              canView
              canDeleteEvent={isAdminUser}
              eventName={eventPage.name}
            />
          </TabPane>
        )}
        {(canViewHeadRefereeOrJudgeAdvisor) && (
          <TabPane tab={t('teamsLabel')} key="#2">
            <TeamsContainer 
              eventId={eventId}
              canViewJudgingTab={canViewJudgingTab}
              canViewScoringTab={canViewScoringTab}
              canAddTeam={canViewAllTabs}
              canView
            />
          </TabPane>
        )}
        {(canViewHeadRefereeOrJudgeAdvisor) && (
          <TabPane tab={t('volunteersLabel')} key="#3">
            <VolunteersContainer 
              eventId={eventId}
              canAddVolunteer={canViewAllTabs}
              canViewJudgingTab={canViewJudgingTab}
              canViewScoringTab={canViewScoringTab}
            />
          </TabPane>
        )}
        {(canViewAllTabs) && (
          <TabPane tab={t('messagingLabel')} key="#4">
            <MessagingContainer eventId={eventId} canEdit={canPublishAwards} isFLLC={isFLLC}/>
          </TabPane>
        )}
        {canViewJudgingTab && (
          <TabPane tab={isFLLC ? t('judgingLabel') : t('nonChallengeJudgingLabel')} key="#5">
            <JudgingContainer eventId={eventId} />
          </TabPane>
        )}
        {(canViewScoringTab && eventHasScoringTab) && (
          <TabPane tab={t('scoringLabel')} key="#6">
            <ScoringContainer eventId={eventId} />
          </TabPane>
        )}
        {canViewAllTabs && (
        <TabPane tab={t('sponsorsLabel')} key="#7">
          <SponsorsContainer eventId={eventId} />
        </TabPane>
        )}
        {((isFTC && canPublishAwards) ||
          (!isFTC && canViewAllTabs || awardIsPublished || isUserHeadRefereeOrJudgeAdvisor)) && (
          <TabPane tab={isFTC ? "Feedback" : t('awardsLabel')} key="#8">
            <AwardsContainer
              eventId={eventId}
              canEdit={canPublishAwards}
              isFLLC={isFLLC}
              isFTC={isFTC}
            />
          </TabPane>
        )}
        {((canViewAllTabs || leaderboardIsPublished) && showLeaderboard) && (
          <TabPane tab={t('leaderboardLabel')} key="#9">
            <LeaderboardContainer
              eventId={eventId}
              isFTC={isFTC}
              canEdit={canPublishAwards}
            />
          </TabPane>
        )}
      </Tabs>
    </PageLayout>
  );
};

EventContainer.propTypes = {
  getEventDetails: PropTypes.func.isRequired,
  eventDetails: PropTypes.object.isRequired,
  getUserEventAssignments: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
  partnerAdminRegionsPrograms: PropTypes.array.isRequired
};

const mapDispatchToProps = {
  getEventDetails: handleEventDetails,
  getEventSchedules: handleEventSchedules,
  getUserEventAssignments,
  fetchEventAwards,
  fetchEventLeaderboard,
  resetEventAwards,
  cleanUpScoringSessionsData
};

const mapStateToProps = (state) => {
  const {
    eventLeaderboard: { leaderboard: { isPublished }, leaderboardId },
    event: { eventDetails, eventAwards },
    auth: { userType, partnerAdminRegionsPrograms }
  } = state;

  return {
    eventDetails,
    userType,
    partnerAdminRegionsPrograms,
    awardIsPublished: eventAwards.status === PUBLISHED,
    leaderboardIsPublished: isPublished,
    leaderboardId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventContainer);
