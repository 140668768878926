

/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import NoFileChosen from 'shared/components/NoFileChosen';
import FileChosen from 'shared/components/FileChosen';
import ChangeFile from 'shared/components/ChangeFile';
import ChooseFile from 'shared/components/ChooseCSVFile';
import truncateString from 'utils/truncateString';
import './import-view.scss';
import Papa from 'papaparse';
import { connect } from 'react-redux';
import ButtonPrimary from 'shared/components/Button/BtnPrimary';
import ButtonSecondary from 'shared/components/Button/BtnSecondary';
import { handleGetEventRoles } from 'features/EventContainer/redux/actions/eventRoles';
import { handleImportVolunteers, handleInviteImportList } from 'features/EventContainer/redux/actions/volunteersActions';
import { validateImportedVolunteer } from 'utils/importCSV/validateVolunteersCSV';
import FileError from 'shared/components/FileError';
import FileSuccess from 'shared/components/FileSuccess';
import { useParams } from 'react-router-dom';
import InviteList from './InviteList';
import InvitedList from './InvitedList';


import {
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FLL_CHALLENGE_ID,
  FTC_ID
} from 'shared/constants/programIds';
import { JUDGE_ADVISOR_ID, JUDGE_ID, roleTypeMap } from 'shared/constants/eventRoleTypes';


const EXPECTED_HEADERS = ["Email", "FirstName", "LastName", "Phone", "EventRole"];

const ImportCSV = (props) => {
  const { t } = useTranslation()
  // const [isFileUploaded, setIsFileUploaded] = useState(false);
  // const [isFileImported, setIsFileImported] = useState(false);
  const [fileName, setFileName] = useState('');
  const [invalidImportFile, setInvalidImportFile] = useState(false);
  const [fileUpload, setFileUpload] = useState('noFileChosen');
  const [totalErrors, setTotalErrors] = useState(0);

  const { id: eventId } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { addVolunteers, invitees, eventRoleTypes, isFetchingEventRoleTypes, getEventRoles, inviteImportedVolunteers, isInviting, csvImport, eventDetails } = props;
  const { eventProgramId, is_FLLC_event } = eventDetails
  const {
    successfullyInvited,
    notInvited
  } = csvImport;
  const getVolunteersFromFile = (fileResults) => {


    let errorCount = 0;
    if (fileResults.length > 0) {

      const uploadedVolunteers = fileResults.map((r, i) => {
        const lineNumber = i + 1;
        const errors = validateImportedVolunteer(r, lineNumber, is_FLLC_event);

        errorCount += errors.length;


        return {
          ...r,
          id: i + 1,
          errors
        }
      })

      const filteredData = uploadedVolunteers.filter((data) => {
        if ((eventProgramId == FLL_DISCOVER_ID || eventProgramId == FLL_EXPLORE_ID) && (data.EventRole == "Referee" || data.EventRole == "Head Referee")) {
          return false
        }

        return true
      })

      addVolunteers(filteredData)
    }
    setTotalErrors(errorCount);
  }

  const isValidTemplate = (headers) => {
    if (headers.length !== EXPECTED_HEADERS.length) {
      return false
    }

    for (let i = 0; i < EXPECTED_HEADERS.length; i += 1) {
      if (headers[i] !== EXPECTED_HEADERS[i]) return false
    }

    return true;
  }

  useEffect(() => {
    getEventRoles()
  }, [])


  const onChange = (args) => {
    const { file } = args;
    // setIsFileImported(false);
    // setFileUpload('noFileChosen')

    if (file.status !== 'uploading') {
      setFileName(truncateString(file.name, 65))
      // setIsFileUploaded(true)
      setFileUpload('importReady');

      Papa.parse(file.originFileObj, {
        download: true,
        header: true,
        skipEmptyLines: 'greedy',
        complete: (results) => {
          // error handling for when the upload csv file does not match the expected headers
          const headers = results.meta.fields;
          const validHeaders = isValidTemplate(headers);
          if (validHeaders) {
            getVolunteersFromFile(results.data);
            setInvalidImportFile(false);
            setFileUpload('importReady');
          }
          if (!validHeaders) {
            setInvalidImportFile(true);
            setFileUpload('importError');
          }

        }
      });

    }
  }

  const handleImport = () => {
    if (totalErrors > 0) {
      setFileUpload('inviteError')
    }
    if (totalErrors === 0) {
      setFileUpload('inviteReady')
    }
  }

  const resetTab = () => {
    setFileName('');
    setFileUpload('noFileChosen');
    setTotalErrors(0);
  }

  const onDelete = (errorNum) => {
    if(errorNum == 0) {
      setFileUpload('inviteReady');
    }
    setTotalErrors(errorNum);
  }

  const renderFileUploadField = () => {
    switch (fileUpload) {
      case 'importReady':
        return <NoFileChosen text={t('readyForImportLabel', { '0': fileName })} />;
      case 'importError':
        return <FileError text="Headers do not match the template." />;
      case 'inviteReady':
        return <FileChosen text={t('readyToInviteLabel', { '0': fileName })} />;
      case 'inviteError':
        return <FileError text={`"${fileName}" has ${totalErrors} error${totalErrors !== 1 ? 's' : ''}.`} />
      case 'noFileChosen':
        return <NoFileChosen />;
      case 'inviteSuccess':
        return <FileSuccess text={t('importedAndInvitedLabel', { '0': fileName })} />;
      default:
        return null;
    }
  }

  const renderInviteList = () => {
    switch (fileUpload) {
      case 'importReady':
        return null;
      case 'noFileChosen':
        return null;
      case 'importError':
        return null;
      case 'inviteSuccess':
        return <InvitedList volunteers={successfullyInvited} volunteersNotInvited={notInvited} csvImport={csvImport} resetTab={resetTab} />;
      default:
        return <InviteList errors={Boolean(totalErrors)} volunteers={invitees} resetTab={resetTab} onDelete={onDelete}/>;
    }
  }

  const renderChangeFileBtn = () => {

    if (fileUpload === 'importReady' || fileUpload === 'importError' || fileUpload === 'inviteReady' || fileUpload === 'inviteError') {
      return (
        <ChangeFile onChange={onChange} />
      )
    }
    return null;
  }

  const successCallback = () => {
    setFileUpload('inviteSuccess');

  }
  
  const handleInvite = () => {

    const volunteersInviteList = [];
    invitees.forEach(invitee => {
      const roleId = roleTypeMap[invitee.EventRole.toLowerCase()];
      if (invitee.errors.length === 0) {
        const volunteerAlreadyAddedIndex = volunteersInviteList.findIndex(i => i.email === invitee.Email);
        const isVolunteerAlreadyAdded = volunteerAlreadyAddedIndex > -1;

        if (isVolunteerAlreadyAdded) {
          let selectedVolunteer = volunteersInviteList[volunteerAlreadyAddedIndex];
          selectedVolunteer = {
            ...selectedVolunteer,
            eventRoles: [...selectedVolunteer.eventRoles, roleId]
          }
          volunteersInviteList[volunteerAlreadyAddedIndex] = selectedVolunteer;
        } else {
          const volunteer = {};
          volunteer.eventRoles = [roleId];
          volunteer.firstName = invitee.FirstName;
          volunteer.lastName = invitee.LastName;
          volunteer.email = invitee.Email;
          volunteer.phone = invitee.Phone;
          volunteersInviteList.push(volunteer);
        }

      }
    })

    inviteImportedVolunteers(volunteersInviteList, eventId, successCallback)

  }

  const renderFileUploadBtn = () => {
    switch (fileUpload) {
      case 'noFileChosen':
        return <ChooseFile onChange={onChange} />;
      case 'importReady':
        return <ButtonSecondary disabled={invalidImportFile} onClick={handleImport} additionalClassNames='btn--180'>{t('importLabel')}</ButtonSecondary>;
      case 'importError':
        return <ButtonSecondary disabled={invalidImportFile} onClick={handleImport} additionalClassNames='btn--180'>{t('importLabel')}</ButtonSecondary>;
      case 'inviteReady':
        return <ButtonPrimary loading={isInviting} additionalClassNames='btn--180' onClick={handleInvite}>{t('inviteLabel')}</ButtonPrimary>;
      case 'inviteError':
        return <ButtonPrimary loading={isInviting} additionalClassNames='btn--180' onClick={handleInvite}>{t('inviteLabel')}</ButtonPrimary>;
      case 'inviteSuccess':
        return <ChooseFile onChange={onChange} text='Import Another' />;
      default:
        return null;
    }
  }


  return (
    <div className='import'>
      <div className='import__row'>
        {renderFileUploadField()}
        <span className='import__choose-file'>
          {renderFileUploadBtn()}
        </span>
      </div>
      {renderChangeFileBtn()}
      {renderInviteList()}
    </div>
  );
};

ImportCSV.propTypes = {
  addVolunteers: PropTypes.func.isRequired,
  invitees: PropTypes.array.isRequired,
  eventRoleTypes: PropTypes.array.isRequired,
  getEventRoles: PropTypes.func.isRequired,
  inviteImportedVolunteers: PropTypes.func.isRequired,
  isFetchingEventRoleTypes: PropTypes.bool,
  isInviting: PropTypes.bool,
  csvImport: PropTypes.object.isRequired
};

ImportCSV.defaultProps = {
  isFetchingEventRoleTypes: false,
  isInviting: false
}

function mapStateToProps(state) {
  const { event: { eventDetails } } = state;
  const { event: { eventRoles, eventVolunteers } } = state;
  const { eventRoleTypes, isFetchingEventRoleTypes } = eventRoles;
  const { addVolunteersImportList, isInvitingVolunteer, csvImport } = eventVolunteers;
  return {
    eventDetails,
    invitees: addVolunteersImportList,
    eventRoleTypes, isFetchingEventRoleTypes,
    isInviting: isInvitingVolunteer,
    csvImport
  }
}

const mapDispatchToProps = {
  getEventRoles: handleGetEventRoles,
  addVolunteers: handleImportVolunteers,
  inviteImportedVolunteers: handleInviteImportList

}

export default connect(mapStateToProps, mapDispatchToProps)(ImportCSV);