import { CreateClient } from 'utils/apiClient';
import { getAuth0Token } from  'features/store'
import i18next from 'i18next';

export class API extends CreateClient {
  constructor (runtimeConfig) {
    super({
      baseUrl: runtimeConfig.REACT_APP_API_BASE_URL,
      getToken: getAuth0Token,
      showLog: process.env.NODE_ENV === 'development'
    })
    this.http.interceptors.request.use(config => {
      config.headers["Accept-Language"] = i18next.language;
      return config
    })
    this.http.interceptors.response.use(
      response => {
        // console.log('RESPONSE API', response)
        return response.data},
      error => Promise.reject(error)
    )
  }

  static apiInstance = null;
  static CreateInstance(runtimeConfig) {
    this.apiInstance = new API(runtimeConfig);
  }

  static GetInstance(){
    return this.apiInstance;
  }
}


//export default API
