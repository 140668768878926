import PropTypes from 'prop-types';
import { notification } from 'antd';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RegistrationLayout from 'shared/components/Layouts/RegistrationPageLayout';
import RegistrationForm from 'shared/components/Layouts/Registration/FormContainer';
import RegistrationOptions from 'shared/components/Layouts/Registration/RegistrationOptions';
import {
  verifyUserAuth, setVerifyUserData
} from 'features/RegistrationContainer/redux/registrationActions';
import {
  loginRequestBegin,
  loginRequestError,
  clearLoginError,
  setCanAccessAdminDashboard,
  setCanAccessVolunteerDashboard,
  resetAuth,
  resetLoadingUserState
} from 'features/Authentication/redux/authActions';
//import auth from 'Auth';
import { handleRoleAccess } from 'utils/access';
import { processAuthResult } from 'utils/authorize';
import LoginLoadingOverlay from './LoginLoadingOverlay';
import Auth from '../../../../Auth';

const LoginContainer = (props) => {
  const { 
    history, 
    expiresAt,
    error,
    loadingUser,
  } = props;
  const { t } = useTranslation();
  const {href} = window.location||{};

  const Url = new URL(href);
  const code = Url.searchParams.get('code');
  const email = Url.searchParams.get('email');
  const urlError = Url.searchParams.get('error');
  const verification = Url.searchParams.get('verification');

  const handleAuthRedirection = async ()=>{
    try{
      const authResult = await Auth.GetInstance().handleAuthentication();
      if(authResult){
        const roleData = await processAuthResult(authResult);
        if (!roleData) return;
        handleRoleAccess({
          ...props,
          ...roleData
        });
      }
    }catch(err){
      props.loginRequestError();
    }
  }

  useEffect(()=>{
    props.resetLoadingUserState()
  },[])

  useEffect(() => {
    if (urlError) {
      notification.error({
        message: 'Error',
        description: urlError,
        // used to clear error message in the url after notification is closed.
        onClose: ()=>{
          const {location} = history||{};
          const {pathname} = location||{};
          if(pathname && history){
            history.push({pathname, search:''})
          }
        }
      });
    }
    if (verification === 'true' && code && email) {
      const payload = {
        code,
        email: email.replace(/\s/g, '+'),
        verified: false
      };
      if (expiresAt) {
        props.verifyUserAuth(payload, history);
      } else {
        props.setVerifyUserData(payload);
      }
    }
  }, [code, email, urlError, verification, expiresAt]);

  const handleGoogleSignIn = () => {
    props.loginRequestBegin();
    Auth.GetInstance().googleAuthentication(handleAuthRedirection);
  };

  // if (authorized) {
  //   return <Redirect to='/volunteer' />
  // }

  const handleAccountSignIn = () => {
    props.loginRequestBegin();
    Auth.GetInstance().accountAuthentication(handleAuthRedirection);
  };

  const options = [
    { text: `${t('signinGoogleLabel')}`, handleClick: handleGoogleSignIn },
    { text: `${t('signinUniqueLabel')}`, handleClick: handleAccountSignIn }
  ];

  return (
    <RegistrationLayout>
      <RegistrationForm
        error={error}
        removeError={props.clearLoginError}
        title={t('signInLabel')}
      >
        <div className='registration__signin-form'>
          <RegistrationOptions
            options={options}
          />
          <LoginLoadingOverlay loading={loadingUser}/>
        </div>
      </RegistrationForm>
    </RegistrationLayout>
  );
};

LoginContainer.propTypes = {
  loadingUser: PropTypes.bool.isRequired,
  authorized: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  const {
    auth: { authorized, loadingUser, expiresAt, error, auth0Authorized}
  } = state;
  return {
    authorized,
    expiresAt,
    loadingUser,
    error,
    auth0Authorized
  };
};

const mapDispatchToProps = {
  verifyUserAuth,
  setVerifyUserData,
  loginRequestBegin,
  loginRequestError,
  clearLoginError,
  setAdminDashboardAccess: setCanAccessAdminDashboard,
  setVolunteerDashboardAccess: setCanAccessVolunteerDashboard,
  resetAuthenticationState: resetAuth,
  resetLoadingUserState
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
