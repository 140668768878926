import * as sessionService from 'utils/services/sessions';
import Team from 'models/Team';
import { notification } from 'antd';
import {getTeamDetails} from 'utils/services/teams';
import { initiateSessionFileUpload, uploadSessionFile, editSessionDocument, editSessionFile } from 'utils/services/sessionDocuments';
import SessionDocument from 'models/SessionDocument';
import {formatSessionDocumentFile} from 'utils/services/sessionDocuments/model';

import {formatSessionResData} from 'utils/services/sessions/model';
import ScheduleSession from 'models/ScheduleSession';
import {formatTeamModelRes} from 'utils/services/teams/model';
import {removeEventFile} from 'utils/services/eventFiles';
import * as types from '../../types/judgingRubric';
import get from 'lodash/get'

const getSessionBegin = () => {
  return {
    type: types.GET_RUBRIC_JUDGING_SESSION_BEGIN
  }
}

const getSessionError = () => {
  return {
    type: types.GET_RUBRIC_JUDGING_SESSION_ERROR
  }
}

const getSessionSuccess = (sessionDetails) => {
  return {
    type: types.GET_RUBRIC_JUDGING_SESSION_SUCCESS,
    payload: {
      sessionDetails
    }
  }
}

const setTeamData = (teamDetails) => {
  return {
    type: types.SET_RUBRIC_TEAM_DETAILS,
    payload: {
      teamDetails
    }
  }
}

const getSessionRubricBegin = () => {
  return {
    type: types.GET_JUDGING_SESSION_RUBRIC_TEMPLATE_BEGIN
  }
}
const getSessionRubricError = () => {
  return {
    type: types.GET_JUDGING_SESSION_RUBRIC_TEMPLATE_ERROR
  }
}
const getSessionRubricSuccess = (judgingRubricTemplate) => {
  return {
    type: types.GET_JUDGING_SESSION_RUBRIC_TEMPLATE_SUCCESS,
    payload: {
      judgingRubricTemplate
    }
  }
}

const setUploadedRubric = (uploadedRubricFile, isFileUploaded) => {
  return {
    type: types.SET_UPLOADED_RUBRIC_FILE,
    payload: {
      uploadedRubricFile,
      isFileUploaded
    }
  }
}

const setIsUploadedRubricPublished = (isPublished) => {
  return {
    type: types.SET_IS_UPLOADED_JUDGING_RUBRIC_PUBLISHED,
    payload: {
      isPublished
    }
  }
}


const setIsUploadedRubricPublishable = (isPublishable) => {
  return {
    type: types.SET_IS_UPLOADED_JUDGING_RUBRIC_PUBLISHABLE,
    payload: {
      isPublishable
    }
  }
}

const updateUploadedRubricBegin = () => {
  return {
    type: types.UPDATE_UPLOADED_JUDGING_SESSION_RUBRIC_BEGIN
  }
}

const updateUploadedRubricError = () => {
  return {
    type: types.UPDATE_UPLOADED_JUDGING_SESSION_RUBRIC_ERROR
  }
}

const updateUploadedRubricSuccess = () => {
  return {
    type: types.UPDATE_UPLOADED_JUDGING_SESSION_RUBRIC_SUCCESS
  }
}

export const editUploadedRubricPublishStatus = (sessionDocumentId, payload, sessionId) => {
  return async dispatch => {
    await dispatch(updateUploadedRubricBegin());
    try {
      const {data} = await editSessionDocument(sessionDocumentId, payload);
      if (data.attributes) {
        const {attributes} = data;
        dispatch(setIsUploadedRubricPublishable(attributes.is_publishable))
        await dispatch(updateUploadedRubricSuccess())
        await dispatch(updateSessionStatus(sessionId));
        notification.success({
          message: 'Success',
          description: attributes.is_publishable ? 'Rubric successfully submitted' : 'Rubric successfully unpublished',
        });
      }

    } catch(err) {
      await dispatch(updateUploadedRubricError());
      notification.error({
        message: 'Error',
      });
      throw err;
    }

  }
}

export const getSessionRubric = (sessionId) => {
  return async dispatch => {
    await dispatch(getSessionRubricBegin())
    try {
      const {data, included} = await sessionService.getSessionRubrics(sessionId);

      if (data.length) {
        const sessionDocumentId = data[0].id;
        const sessionDocumentObj = data[0];
        if (sessionDocumentObj.attributes) {
          const {attributes} = sessionDocumentObj;
          dispatch(setIsUploadedRubricPublishable(attributes.is_publishable))
          dispatch(setIsUploadedRubricPublished(attributes.is_published))
        }

        const sessionDocumentData = data[0];
        const rubricDetails = new SessionDocument(sessionDocumentData, included);
        const rubricFiles = rubricDetails.getFiles();
        
        const uploadedFile = rubricFiles[0] || {};
        const isFileUploaded = rubricFiles[0] ? true : false;
        dispatch(setUploadedRubric(uploadedFile, isFileUploaded));

        const selectedRubricTemplate = included.find(i => i.type === 'document_type');
        if (selectedRubricTemplate) {
          const {attributes} = selectedRubricTemplate;
          dispatch(getSessionRubricSuccess({
            link: attributes.link,
            name: attributes.name,
            notes: attributes.notes,
            documentTypeId: sessionDocumentId
          }))
        }

      } else {
        dispatch(getSessionRubricSuccess({
          link: null,
          name: null,
          notes: null,
          id: null
        }))
        dispatch(setUploadedRubric({
          contentType: null,
          eventFileId: null,
          fileName: '',
        }, false))
      }
    } catch(err) {
      dispatch(getSessionRubricError())
      throw err;
    }
  }

}

const uploadRubricBegin = () => {
  return {
    type: types.UPLOAD_JUDGING_SESSION_RUBRIC_BEGIN
  }
}

const uploadRubricError = () => {
  return {
    type: types.UPLOAD_JUDGING_SESSION_RUBRIC_ERROR
  }
}

const uploadRubricSuccess = (uploadedRubricFile, isFileUploaded) => {
  return {
    type: types.UPLOAD_JUDGING_SESSION_RUBRIC_SUCCESS,
    payload: {
      uploadedRubricFile, isFileUploaded
    }
  }
}

const uploadRubricRetry = (uploadRetry) => {
  return {
    type: types.UPLOAD_JUDGING_SESSION_RUBRIC_RETRY,
    payload: {
      uploadRetry,
    }
  }
}

export const uploadSessionRubricFile = (sessionDocumentId, file, onProgress, onErrorFallback) => {
  return async (dispatch, getState) => {
    const { judgingRubric: { uploadRetry } } = getState()
    dispatch(uploadRubricBegin());
    let fileId
    try {
      // Initiate file upload and receive S3 presigned url + fileId
      const initFileUploadResponse = await initiateSessionFileUpload(sessionDocumentId, file)
      const presignedUrl = initFileUploadResponse.included && initFileUploadResponse.included[0]
        ? get(initFileUploadResponse.included[0], ['attributes', 'url'], undefined)
        : undefined
      fileId = get(initFileUploadResponse, ['data', 'id'], undefined)
      if (!presignedUrl || !fileId) throw 'missingUrl'

      // Upload file with S3 presigned url, attach caculateProgress callback for progress bar modal
      await uploadSessionFile(presignedUrl, file, onProgress)

      // Edit session file to have 'Uploaded' status
      const editSessionFileResponse = await editSessionFile(sessionDocumentId, fileId, { status: 'Uploaded' })

      const newSessionFile = formatSessionDocumentFile(editSessionFileResponse);
      await dispatch(uploadRubricSuccess(newSessionFile, true))
    } catch(error) {
      // Retry up to 3 times if upload request stalls or times out
      if (get(error, ['config', 'onUploadProgress'], false) && uploadRetry < 3) {
        dispatch(uploadRubricError())
        dispatch(uploadRubricRetry(uploadRetry + 1))
        if (fileId) await editSessionFile(sessionDocumentId, fileId, { status: 'UploadFailed' })
        await dispatch(uploadSessionRubricFile(sessionDocumentId, file, onProgress, onErrorFallback))
      } else {
        if (error === 'missingUrl') console.warn('Missing presigned url')
        dispatch(uploadRubricError())
        if (fileId) await editSessionFile(sessionDocumentId, fileId, { status: 'UploadFailed' })
        onErrorFallback(error)
      }
    }
  }
}

const deleteRubricFileBegin = () => {
  return {
    type: types.DELETE_UPLOADED_RUBRIC_FILE_BEGIN
  }
}
const deleteRubricFileError = () => {
  return {
    type: types.DELETE_UPLOADED_RUBRIC_FILE_ERROR
  }
}
const deleteRubricFileSuccess = () => {
  return {
    type: types.DELETE_UPLOADED_RUBRIC_FILE_SUCCESS
  }
}

export const deleteRubricFile = (fileId) => {
  return async dispatch => {
    dispatch(deleteRubricFileBegin())
    try {
      await removeEventFile(fileId);
      dispatch(deleteRubricFileSuccess())

    } catch(err) {
      dispatch(deleteRubricFileError())

      throw err;
    }

  }

}

export const updateSessionStatus = (sessionId) =>{
  return async dispatch => {
    try {
      const {included, data} = await sessionService.getSessionById(sessionId);
      const sessionDetails = formatSessionResData(new ScheduleSession(data, included));
      await dispatch(getSessionSuccess(sessionDetails))
    } catch(err) {
      await dispatch(getSessionError())
      throw err;
    }
  }
}



const getSessionDetails = (sessionId) => {
  return async dispatch => {
    await dispatch(getSessionBegin());
    try {
      const {included, data} = await sessionService.getSessionById(sessionId);

      const sessionTeam = included.find(i => i.type === 'team');
      const teamId = sessionTeam ? sessionTeam.id : undefined;

      const teamRes = await getTeamDetails(teamId);

      const sessionDetails = formatSessionResData(new ScheduleSession(data, included));
     
      const teamData = formatTeamModelRes(new Team(teamRes.data, teamRes.included));
      dispatch(setTeamData(teamData))

      await dispatch(getSessionSuccess(sessionDetails))
    } catch(err) {
      await dispatch(getSessionError())
      throw err;
    }
  }
}

export default getSessionDetails;