export const FETCH_ORGANIZATIONS_BEGIN = 'FETCH_ORGANIZATIONS_BEGIN';
export const FETCH_ORGANIZATIONS_ERROR = 'FETCH_ORGANIZATIONS_ERROR';
export const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS';

export const EDIT_ORGANIZATION_BEGIN = 'EDIT_ORGANIZATION_BEGIN';
export const EDIT_ORGANIZATION_ERROR = 'EDIT_ORGANIZATION_ERROR';
export const EDIT_ORGANIZATION_SUCCESS = 'EDIT_ORGANIZATION_SUCCESS';

export const DELETE_ORGANIZATION_BEGIN = 'DELETE_ORGANIZATION_BEGIN';
export const DELETE_ORGANIZATION_ERROR = 'DELETE_ORGANIZATION_ERROR';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';

export const SET_SELECTED_EDIT_ORGANIZATION = 'SET_SELECTED_EDIT_ORGANIZATION';

export const UPDATE_SELECTED_EDIT_ORGANIZATION = 'UPDATE_SELECTED_EDIT_ORGANIZATION';

export const RESET_EDIT_ORGANIZATION_FORM = 'RESET_EDIT_ORGANIZATION_FORM';

export const UPDATE_EDIT_ORGANIZATION_PROGRAMS = 'UPDATE_EDIT_ORGANIZATION_PROGRAMS';

export const FETCH_ORGANIZATION_REGIONS_BY_PROGRAM_BEGIN = 'FETCH_ORGANIZATION_REGIONS_BY_PROGRAM_BEGIN'
export const FETCH_ORGANIZATION_REGIONS_BY_PROGRAM_ERROR = 'FETCH_ORGANIZATION_REGIONS_BY_PROGRAM_ERROR'
export const FETCH_ORGANIZATION_REGIONS_BY_PROGRAM_SUCCESS = 'FETCH_ORGANIZATION_REGIONS_BY_PROGRAM_SUCCESS'
