export const FETCH_PROGRAMS_BEGIN = 'FETCH_PROGRAMS_BEGIN';
export const FETCH_PROGRAMS_SUCCESS = 'FETCH_PROGRAMS_SUCCESS';
export const FETCH_PROGRAMS_ERROR = 'FETCH_PROGRAMS_ERROR';

export const FETCH_PROGRAM_REGIONS_BEGIN = 'FETCH_PROGRAM_REGIONS_BEGIN';
export const FETCH_PROGRAM_REGIONS_SUCCESS = 'FETCH_PROGRAM_REGIONS_SUCCESS';
export const FETCH_PROGRAM_REGIONS_ERROR = 'FETCH_PROGRAM_REGIONS_ERROR';

export const GET_COUNTRIES_BEGIN = 'GET_COUNTRIES_BEGIN';
export const GET_COUNTRIES_ERROR = 'GET_COUNTRIES_ERROR';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
