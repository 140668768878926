import * as types from '../types/assignedAwards';
import { PENDING, PUBLISHABLE } from 'shared/constants/publishStatus';
import * as judgingTypes from '../types/assignedJudgingSessionsTypes';
import * as scoringTypes from '../types/assignedMatches';

const DEFAULT_STATE = {
  teams: [],
  awards: [],
  eventId: null,
  status: PENDING,
  meta: {
    isFetchingTeams: false,
    isFetchingAwards: false,
    isCreatingAward: false,
    isAddingTeam: false,
    isUpdatingPlace: false,
    isDeletingTeam: false,
    isSubmittingAwards: false
  },
  isFetchingRankData: false,
  isFetchingChampionShipRank: false,
  isFetchingGameScoreRank: false,
  isFetchingJudingRank: false,
  ranking: {},
  isFetchingRubricObjects: false,
  rubricObjects: {},
  isFetchingJudgingSessions: false,
  assignedJudgingSessions: [],
  isFetchScoringSessions: false,
  assignedMatches: [],
  scoreInputObject: {},
};

export default (state = DEFAULT_STATE, action) => {
  const { type } = action;
  switch (type) {
    case types.GET_EVENT_AWARD_TEAMS_BEGIN: {
      return {
        ...state,
        teams: [],
        meta: {
          ...state.meta,
          isFetchingTeams: true
        }
      };
    }
    case types.GET_EVENT_AWARD_TEAMS_SUCCESS: {
      return {
        ...state,
        teams: action.payload,
        meta: {
          ...state.meta,
          isFetchingTeams: false
        }
      };
    }
    case types.GET_EVENT_AWARD_TEAMS_FAIL: {
      return {
        ...state,
        teams: [],
        eventId: null,
        meta: {
          ...state.meta,
          isFetchingTeams: false
        }
      };
    }
    case types.GET_EVENT_AWARDS_BEGIN: {
      return {
        ...state,
        awards: [],
        eventId: action.payload,
        meta: {
          ...state.meta,
          isFetchingAwards: true
        }
      };
    }
    case types.GET_EVENT_AWARDS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        meta: {
          ...state.meta,
          isFetchingAwards: action.loading
        }
      };
    }
    case types.GET_EVENT_AWARDS_FAIL: {
      return {
        ...state,
        awards: [],
        meta: {
          ...state.meta,
          isFetchingAwards: false
        }
      };
    }
    case types.CREATE_EVENT_AWARD_BEGIN: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isCreatingAward: true
        }
      };
    }
    case types.CREATE_EVENT_AWARD_SUCCESS: {
      const { awards } = state;
      const newAward = {
        ...action.payload,
        assignments: []
      };
      return {
        ...state,
        awards: [...awards, newAward],
        meta: {
          ...state.meta,
          isCreatingAward: false
        }
      };
    }
    case types.CREATE_EVENT_AWARD_FAIL: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isCreatingAward: false
        }
      };
    }
    case types.ADD_TEAM_TO_AWARD_BEGIN: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isAddingTeam: true
        }
      };
    }
    case types.ADD_TEAM_TO_AWARD_SUCCESS: {
      const { awards } = state;
      const { awardId, ...assign } = action.payload;
      const updatedAwards = awards.map((award) => {
        if (award.id === awardId) {
          const { assignments } = award;
          return {
            ...award,
            assignments: [...assignments, assign]
          };
        }
        return award;
      });
      return {
        ...state,
        awards: updatedAwards,
        meta: {
          ...state.meta,
          isAddingTeam: false
        }
      };
    }
    case types.ADD_TEAM_TO_AWARD_FAIL: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isAddingTeam: false
        }
      };
    }
    case types.UPDATE_TEAM_AWARD_PLACE_BEGIN: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isUpdatingPlace: true
        }
      };
    }
    case types.UPDATE_TEAM_AWARD_PLACE_SUCCESS: {
      const { awards } = state;
      const { awardId, assignmentId, ...rest } = action.payload;
      const updatedAwards = awards.map((award) => {
        if (award.id === awardId) {
          const { assignments } = award;
          return {
            ...award,
            assignments: assignments.map((assign) => {
              if (assign.id === assignmentId) {
                return { ...assign, ...rest };
              }
              return assign;
            })
          };
        }
        return award;
      });
      return {
        ...state,
        awards: updatedAwards,
        meta: {
          ...state.meta,
          isUpdatingPlace: false
        }
      };
    }
    case types.UPDATE_TEAM_AWARD_PLACE_FAIL: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isUpdatingPlace: false
        }
      };
    }
    case types.REMOVE_TEAM_FROM_AWARD_BEGIN: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isDeletingTeam: true
        }
      };
    }
    case types.REMOVE_TEAM_FROM_AWARD_FAIL: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isDeletingTeam: false
        }
      };
    }
    case types.REMOVE_TEAM_FROM_AWARD_SUCCESS: {
      const { awards } = state;
      const { id, awardId } = action.payload;
      const updatedAwards = awards.map((award) => {
        if (award.id === awardId) {
          const { assignments } = award;
          return {
            ...award,
            assignments: assignments.filter((assign) => assign.id !== id)
          };
        }
        return award;
      });
      return {
        ...state,
        awards: updatedAwards,
        meta: {
          ...state.meta,
          isDeletingTeam: false
        }
      };
    }
    case types.SUBMIT_EVENT_AWARDS_BEGIN: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isSubmittingAwards: true
        }
      };
    }
    case types.SUBMIT_EVENT_AWARDS_SUCCESS: {
      const unsubmit = action.payload;
      const { awards } = state;
      const updatedAwards = awards.map((award) => {
        const { assignments } = award;
        return {
          ...award,
          assignments: assignments.map((assign) => {
            return { ...assign, isPublishable: !unsubmit };
          })
        };
      });
      return {
        ...state,
        awards: updatedAwards,
        status: unsubmit ? PENDING : PUBLISHABLE,
        meta: {
          ...state.meta,
          isSubmittingAwards: false,
        }
      };
    }
    case types.SUBMIT_EVENT_AWARDS_FAIL: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isSubmittingAwards: false
        }
      };
    }
    case types.GET_EVENT_RANKING_BEGIN: {
      return {
        ...state,
        isFetchingRankData: true,
        isFetchingChampionShipRank: true,
        isFetchingGameScoreRank: true,
        isFetchingJudingRank: true,
      }
    }

    case types.GET_CHAMPIONSHIP_RANKING_LOADING: {
      return {
        ...state,
        isFetchingChampionShipRank: action.payload,
      }
    }
    case types.GET_GAME_SCORE_RANKING_LOADING: {
      return {
        ...state,
        isFetchingGameScoreRank: action.payload,
      }
    }
    case types.GET_JUDGING_RANKING_LOADING: {
      return {
        ...state,
        isFetchingJudingRank: action.payload,
      }
    }

    case types.GET_EVENT_RANKING_FAIL: {
      return {
        ...state,
        isFetchingRankData: false
      }
    }
    case types.GET_EVENT_RANKING_COMPLETE: {
      return {
        ...state,
        isFetchingRankData: false
      }
    }
    case types.GET_EVENT_RANKING_SUCCESS: {
      return {
        ...state,
        ranking: action.payload,
      }
    }
    case types.GET_EVENT_OPTIONAL_AWARDS_BEGIN: {
      return {
        ...state,
        isFetchingRubricObjects: true
      }
    }
    case types.GET_EVENT_OPTIONAL_AWARDS_FAIL: {
      return {
        ...state,
        isFetchingRubricObjects: false
      }
    }
    case types.GET_EVENT_OPTIONAL_AWARDS_COMPLETE: {
      return {
        ...state,
        isFetchingRubricObjects: false
      }
    }
    case types.GET_EVENT_OPTIONAL_AWARDS_SUCCESS: {
      return {
        ...state,
        rubricObjects: action.data,
        isFetchingRubricObjects: action.loading
      }
    }case judgingTypes.GET_ASSIGNED_JUDGING_SESSIONS_FOR_AWARDS_BEGIN:
    return {
      ...state,
      isFetchingJudgingSessions: true
    }
  case judgingTypes.GET_ASSIGNED_JUDGING_SESSIONS_FOR_AWARDS_ERROR:
    return {
      ...state,
      isFetchingJudgingSessions: false
    }
  case judgingTypes.GET_ASSIGNED_JUDGING_SESSIONS_FOR_AWARDS_SUCCESS:
    return {
      ...state,
      isFetchingJudgingSessions: action.payload.loading,
      assignedJudgingSessions: action.payload.assignedSessions,
    }
  case judgingTypes.UPDATE_ASSIGNED_JUDGING_SESSIONS_FOR_AWARDS_COUNT:
    return {
      ...state,
      assignedJudgingSessionsCount: action.payload
    }
  case scoringTypes.GET_ASSIGNED_MATCHES_FOR_AWARDS_BEGIN:
    return {
      ...state,
      isFetchScoringSessions: true
    }
  case scoringTypes.GET_ASSIGNED_MATCHES_FOR_AWARDS_ERROR:
    return {
      ...state,
      isFetchScoringSessions: false,
      assignedScoringSessions: [],
    }
  case scoringTypes.GET_ASSIGNED_MATCHES_FOR_AWARDS_SUCCESS:
    return {
      ...state,
      isFetchScoringSessions: action.payload.loading,
      assignedScoringSessions: action.payload.assignedMatches,
      scoreInputObject: action.payload.scoreInputObject,
    }
    default:
      return state;
  }
};
