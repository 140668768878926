export const GET_EVENT_MESSAGING_CHANNELS_BEGIN = 'GET_EVENT_MESSAGING_CHANNELS_BEGIN';
export const GET_EVENT_MESSAGING_CHANNELS_SUCCESS = 'GET_EVENT_MESSAGING_CHANNELS_SUCCESS';
export const GET_EVENT_MESSAGING_CHANNELS_FAIL = 'GET_EVENT_MESSAGING_CHANNELS_FAIL';

export const SAVE_EVENT_MESSAGING_CHANNEL_BEGIN = 'SAVE_EVENT_MESSAGING_CHANNEL_BEGIN';
export const SAVE_EVENT_MESSAGING_CHANNEL_SUCCESS = 'SAVE_EVENT_MESSAGING_CHANNEL_SUCCESS';
export const SAVE_EVENT_MESSAGING_CHANNEL_FAIL = 'SAVE_EVENT_MESSAGING_CHANNEL_FAIL';

export const DELETE_EVENT_MESSAGING_CHANNEL_BEGIN = 'DELETE_EVENT_MESSAGING_CHANNEL_BEGIN';
export const DELETE_EVENT_MESSAGING_CHANNEL_SUCCESS = 'DELETE_EVENT_MESSAGING_CHANNEL_SUCCESS';
export const DELETE_EVENT_MESSAGING_CHANNEL_FAIL = 'DELETE_EVENT_MESSAGING_CHANNEL_FAIL';
