import React from 'react';
import { ClipLoader } from 'react-spinners';
import Paragraph from 'shared/components/Paragraph';
import './styles/BlockLoading.scss';

export default ({ loading, text }) => {
  return loading ? (
    <div className='c-blockloading'>
      <ClipLoader size={24} color='#0066B3' />
      <Paragraph size='medium'>{text}</Paragraph>
    </div>
  ) : null;
};
