import React, {useState} from 'react';
import PropTypes from 'prop-types';
import '../../../styles/ScheduleContainer/EventScheduleCard.scss';
import Icon from '@ant-design/icons';
import * as userTypes from 'shared/constants/userTypes';
import * as eventRoleTypes from 'shared/constants/eventRoleTypes';
import LawIcon from 'shared/components/Icon/icons/Law';
import Ballot from 'shared/components/Icon/icons/Ballot';
import {parseDateString, parseTime} from 'utils/dateTimeUtils';
import { ExclamationCircleOutlined, TrophyOutlined } from '@ant-design/icons';
import {connect} from 'react-redux';
import IncompleteTimestampCard from './IncompleteTimestampCard';
import TimestampModal from '../TimestampModal';
import { sessionTypesTranslations } from 'shared/constants/sessionTypesTranslations';
import { useTranslation } from 'react-i18next';

const EventScheduleCard = (props) => {

  const {t} = useTranslation();
  const [visible, setVisible] = useState(false);

  const {
    data,
    cardType,
    iconType,
    cb,
    userEventRoleIds,
    userType,
    time_zone,
    is_FLLC_event,
    use_24_hour_format
  } = props;
  const translatedCardTitle = sessionTypesTranslations(is_FLLC_event)[data.timestampTypeId];
  const cardTitle = translatedCardTitle ? t(translatedCardTitle) : data.getTimestampDescription();
  
  const timestamp = data.targetDateTime;
  const timestampId = data.id;

  // const isUserHeadRefereeOrJudgeAdvisor = userEventRoleIds.includes(eventRoleTypes.HEAD_REFEREE_ID) || userEventRoleIds.includes(eventRoleTypes.JUDGE_ADVISOR_ID)

  const canEditSchedule = userType === userTypes.FIRST_ADMIN ||
  userType === userTypes.PARTNER_ADMIN ||
  userEventRoleIds.includes(eventRoleTypes.EVENT_ADMIN_ID);

  if (!timestamp) {
    return (
      <IncompleteTimestampCard
        is_FLLC_event={is_FLLC_event}
        data={data}
        cardType={cardType}
        iconType={iconType}
        cardTitle={cardTitle}
        cb={cb}
        canEditSchedule={canEditSchedule}
        time_zone={time_zone}
      />
    )
  }

  const renderIcon = () => {
    switch(iconType) {
      case 'ballot':
        return Ballot;
      case 'trophy':
        return Trophy;
      case 'opening':
        return GrandOpening;
      default:
        return LawIcon
    }

  }


  const openModal = () => {
    setVisible(true)
  }

  const closeModal = () => {
    setVisible(false);
    cb();
  }

  const targetDateString = parseDateString(timestamp, time_zone);
  const targetTimeString = parseTime(timestamp, time_zone, use_24_hour_format)

  return (
    <>
      <TimestampModal timestampId={timestampId} title={cardTitle} visible={visible} onCancel={closeModal} time_zone={time_zone}/>
      <button disabled={!canEditSchedule} type='button' className='event-schedule-card-btn' onClick={openModal}>
        <div className="event-schedule-card event-schedule-card--complete">
          <div className={`event-schedule-card__rectangle event-schedule-card__rectangle--complete event-schedule-card__rectangle--${cardType}`}>
            <Icon component={renderIcon()} className='event-schedule-card__event-icon' />
          </div>
          <div className='event-schedule-card__data event-schedule-card__data--complete'>
            <p className='paragraph--default paragraph--bold paragraph--charcoal event-schedule-card__data__title'>{cardTitle}</p>
            <div className='event-schedule-card__data__date-time'>
              <p className='paragraph--medium paragraph--charcoal'>{targetDateString}</p>
              <p className='paragraph--medium paragraph--charcoal'>{targetTimeString}</p>
            </div>
          </div>
        </div>
      </button>
    </>
  );
};

EventScheduleCard.propTypes = {
  data: PropTypes.object,
  cardType: PropTypes.string,
  iconType: PropTypes.string,
  cb: PropTypes.func,
  userEventRoleIds: PropTypes.array.isRequired,
  userType: PropTypes.string.isRequired
}

EventScheduleCard.defaultProps = {
  data: {},
  cardType: 'judging',
  iconType: 'ballot',
  cb: () => {}
}

const mapStateToProps = (state) => {
  const { event: { eventDetails }, auth: { userType, userInfo: { use_24_hour_format} } } = state;
  const {
    userEventRoleIds,
    time_zone,
    is_FLLC_event
  } = eventDetails
  return { userEventRoleIds, userType, time_zone, use_24_hour_format,is_FLLC_event };
};


export default connect(mapStateToProps)(EventScheduleCard);