import React from 'react';
import PropTypes from 'prop-types';
import '../../../../styles/judgingRubric/FLLRubricInstructions.scss'
import { useTranslation } from 'react-i18next';

const RubricFLLEInstructions = props => {
    const {
        instructionsText,
        children,
        button,
        collapsed
    } = props;

    const { t } = useTranslation();

    return (

        <div className='c-fll-rubric-instructions'>
            <div className="title-with-button">
                <p className='h-2--normal heading--charcoal'>{t('instructionsLabel')}</p>
                {button}
            </div>
            {!collapsed && <p className='paragraph--medium paragraph--charcoal m-t-16' dangerouslySetInnerHTML={{ __html: instructionsText }} />}
            {!collapsed && <div>
                {children}
            </div>}
        </div>

    );
};

RubricFLLEInstructions.propTypes = {
    instructionsText: PropTypes.string,
    children: PropTypes.node
};

RubricFLLEInstructions.defaultProps = {
    instructionsText: '',
    children: null
};

export default RubricFLLEInstructions;