import { Button, Modal } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';

const CommentBlock = (data, timeFormat, is_FLLC_event) => {
  const { comment, dateTime, commentId } = (data || {})

  const formatDateTime = (date, is24Hour) => {
    return moment(date, 'h:mma M/D/YYYY').format(is24Hour ? 'HH:mm M/D/YYYY' : 'h:mma M/D/YYYY')
  }

  if (!commentId) {
    return <div className="empty-comment-placeholder">{is_FLLC_event ? 'Any comments from the judges will appear here.' : 'Any comments from the reviewers will appear here.'}</div>
  }
  return (
    <div className="judges-comments-content-wrapper">
      <div className="judges-comments-title">
        <div>{dateTime ? formatDateTime(dateTime, timeFormat) : ''}</div>
      </div>
      <div className="judges-comments-content">
        {comment}
      </div>
    </div>
  )
}

const JudgingComments = (props) => {
  const { comments, use_24_hour_format, is_FLLC_event } = props;
  const [visible, setVisible] = useState(false);

  let comments_data = (comments || []);
  const comments_length = comments_data.length;
  const first_comment = comments_data[0]

  const CommentsModal = (
    <Modal
      onCancel={() => setVisible(false)}
      open={visible}
      footer={null}
      title="Judges' Comments"
      width={"590px"}
      bodyStyle={{ height: '676px', width: '590px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <div className="judges-comments-scroll-section">
        {comments_data.map(m => CommentBlock(m, use_24_hour_format, is_FLLC_event))}
      </div>
    </Modal>
  )

  return (
    <div>
      {CommentsModal}
      {CommentBlock(first_comment, use_24_hour_format, is_FLLC_event)}
      {comments_length > 0 ? <div className="judges-comments-see-more">
        <Button type="link" onClick={() => setVisible(true)}>{`See More (${comments_length})`}</Button>
      </div> : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    event: { eventDetails: { is_FLLC_event }},
    auth: {
      userInfo: {
        use_24_hour_format
      }
    } } = state;
  return {
    use_24_hour_format,
    is_FLLC_event
  };
};

export default connect(mapStateToProps)(JudgingComments);