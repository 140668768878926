import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../../../../../features/PanelManagement/styles/JudgingPanelCard.scss';
import { useTranslation } from 'react-i18next';
import { officialRefereeReviewSessionType, practiceRefereeReviewSessionType } from 'shared/constants/timestampTypes';
import _ from 'lodash';
import { Col, Row, Select } from 'antd';
import Icon from 'shared/components/Icon';
import CloseCircle from 'shared/components/Icon/icons/CloseCircle';
import { connect } from 'react-redux';
import { handleDeletScoringPanel, handleUpdateScoringPanel } from "features/EventContainer/redux/actions/scoringActions/editScoringPanel";
import { sessionTypesTranslations } from 'shared/constants/sessionTypesTranslations';

const { Option } = Select;

const TeamAssignments = props => {
    const { t } = useTranslation();

    // eslint-disable-next-line no-unused-vars
    const { panelSessions,is_FLLC_event, isInPerson, data, included, sessionOptions, panelId, panelName, handleDeletScoringPanelData, handleUpdateScoringPanelData, isEditingRefereePanel } = props;
    const [newAddedTeams, setNewAddedTeams] = useState([])
    let formattedData = [];

    const groupedData = _.groupBy(data, 'teamId');
    const keys = Object.keys(groupedData);

    const sortSessions = () => {
        let data = _.orderBy(sessionOptions, ['matchNumber'], ['asc']);
        return data
    }

    let sortedSession = sortSessions();


    keys.forEach(key => {
        const teamData = groupedData[key];
        const teamName = ((teamData || [])[0] || {}).teamName;
        let practiceMatchPanel, match1Panel, match2Panel, match3Panel, matchPanelId;
        let practiceMatchId, match1Id, match2Id, match3Id;
        teamData.forEach(d => {
            const { sessionTypeId, panelName, matchNumber, sessionId, panelId } = d;
            if (sessionTypeId === practiceRefereeReviewSessionType) {
                practiceMatchPanel = panelName;
                practiceMatchId = sessionId;
            } else if (sessionTypeId === officialRefereeReviewSessionType) {
                switch (matchNumber) {
                    case 1:
                        match1Panel = panelName;
                        match1Id = sessionId;
                        matchPanelId = panelId
                        break;
                    case 2:
                        match2Panel = panelName;
                        match2Id = sessionId;
                        matchPanelId = panelId
                        break;
                    case 3:
                        match3Panel = panelName;
                        match3Id = sessionId;
                        matchPanelId = panelId
                        break;
                    default:
                        break;
                }
            }
        })

        formattedData.push({ key: key, teamName, practiceMatchPanel, match1Panel, match2Panel, match3Panel, practiceMatchId, match1Id, match2Id, match3Id, matchPanelId })
    })

    // const filteredTeam = formattedData.filter(d => d.teamName.toLowerCase().includes(search.toLowerCase()))
    const filteredTeam = formattedData

    const getTeams = () => {
        return included.filter((data) => data.type == 'team')
    }

    let allTeams = getTeams()

    const handleTeamAdd = (teamId) => {
        if (teamId == "") {
            return
        }
        let team = allTeams.filter(team => team.id == teamId)

        setNewAddedTeams([...newAddedTeams, {
            matchNumber: 1,
            panelId: panelId,
            sessionId: Date.now(),
            sessionTypeId: null,
            sessionTypeName: null,
            teamId: teamId,
            teamName: `${team[0].attributes.team_number} - ${team[0].attributes.name}`
        }])
    }

    const handleDeletePanelSessionWithoutSession = (id) => {
        var filtered = newAddedTeams.filter((el) => { return el.sessionId != id });
        setNewAddedTeams(filtered)
    }

    const handleDeletePanelSession = async (id) => {

        let data = {
            data: [
                {
                    id: id,
                    type: "session"
                }
            ]
        }

        handleDeletScoringPanelData(panelId, data)
        // var filtered = combiledPanels.filter((el) => { return el.sessionId != id; });
    }

    const handleUpdatePanelSession = async (id, oldSession) => {

        if (oldSession.sessionTypeId != null) {
            handleDeletePanelSession(oldSession.sessionId)
        }


        if (id == 'default') {
            return
        }
        let data = {
            data: {
                attributes: {
                    name: panelName
                },
                id: panelId,
                type: "panel",
                relationships: {
                    referees: { data: [] },
                    sessions: {
                        data: [
                            {
                                id: id,
                                type: "session"
                            }
                        ]
                    }
                }
            }
        }

        await handleUpdateScoringPanelData(panelId, data)
        handleDeletePanelSessionWithoutSession(oldSession.sessionId);
        // var filtered = combiledPanels.filter((el) => { return el.sessionId != id; });
    }

    const isDisabledAndAssignedToOtherPanel = (panelId, assignedToPanel, session, option) => {
        
        if ((assignedToPanel != null && assignedToPanel !== undefined) && (session.sessionId != option.sessionId && session.teamId == option.teamId)) {
            return true
        }
        return false
    }


    const panelByTeams = _.groupBy(panelSessions, 'teamId');

    const numberOfTeams = Object.keys(panelByTeams).length;
    const subTitle = numberOfTeams === 0 ? t('2022EmptyPanelTeamAssignedLabel') : numberOfTeams === 1 ? t('2022EmptyPanelTeamAssigned3Label', { '0': 1 }) : t('2022EmptyPanelTeamAssigned2Label', { '0': numberOfTeams });
    const className = numberOfTeams === 0 ? "waring-text" : "sub-title";

    const sortedPanelSessions = _.sortBy(panelSessions, ['teamName', 'sessionTypeId', 'matchNumber'])

    let combiledPanels = [...panelSessions, ...newAddedTeams]

    const sortByName = (data) => {
        let array = [...data]
        return array.sort((a, b) => a.attributes.team_number.localeCompare(b.attributes.team_number));
    }

    let allTeamsSorted = sortByName(allTeams)

    return (
        <div className='judging-panel-card__teams-box'>
            <div>
                {combiledPanels.map(session => {
                    return (
                        <Row align="middle" gutter={[16, 16]}>
                            <Col span={1}>
                                <CloseCircle onClick={() => { session.sessionTypeId != null ? handleDeletePanelSession(session.sessionId) : handleDeletePanelSessionWithoutSession(session.sessionId) }} className="m-t-5 cursor"></CloseCircle>
                            </Col>
                            <Col span={8}>
                                {/* <Select style={{ width: '100%' }} defaultValue={session.teamId} className='m-r-20' onSelect={(e) => { }} suffixIcon={<Icon type='downArrowBlue' />}>
                                    {allTeams.map(team => (
                                        <Option value={team.id} disabled={false}>{`${team.attributes.team_number} - ${team.attributes.name}`}</Option>
                                    ))}
                                </Select> */}

                                <div className='team-box'>
                                    {allTeamsSorted.map(team => (
                                        session.teamId == team.id && (`${team.attributes.team_number} - ${team.attributes.name}`)
                                    ))}
                                </div>
                            </Col>

                            <Col span={8}>
                                <Select
                                    style={{ width: '100%' }}
                                    value={session.sessionTypeId != null ? session.sessionId : 'default'}
                                    className='m-r-20'
                                    loading={isEditingRefereePanel}
                                    onSelect={(e) => { handleUpdatePanelSession(e, session)}}
                                    suffixIcon={isEditingRefereePanel ? null : <Icon type='downArrowBlue' />}
                                >
                                    <Option value="default">No matches selected</Option>
                                    {sortedSession.map(option => {
                                      let sessionTypeName = sessionTypesTranslations(is_FLLC_event)[option.sessionTypeId];
                                      sessionTypeName = sessionTypeName ? t(sessionTypeName) : option.sessionTypeName;
                                        return (option.teamId == session.teamId &&
                                            <Option
                                                value={option.sessionId}
                                                disabled={isDisabledAndAssignedToOtherPanel(session.panelId, option.panelId, session, option) || isEditingRefereePanel}
                                            >
                                                {sessionTypeName} {option.sessionTypeId != practiceRefereeReviewSessionType ? option.matchNumber : ''}
                                                {isDisabledAndAssignedToOtherPanel(session.panelId, option.panelId, session, option) && (
                                                    <>
                                                        <br></br>
                                                        <span className='assigned-to-panel'>Assigned to {option.panelName}</span>
                                                    </>
                                                )}
                                            </Option>)
                                    })}
                                </Select>
                            </Col>
                        </Row>
                    )
                })}


                <Row>
                    <Col span={1}></Col>
                    <Col span={23}>
                        <Select style={{ width: '200px' }} defaultValue="" className='panel-select-dropdown m-r-20' onSelect={(e) => { handleTeamAdd(e) }} suffixIcon={<Icon type='downArrowBlue' />}>
                            <Option value="">{t('addTeamLabel')}</Option>
                            {allTeamsSorted.map(team => (
                                <Option value={team.id} disabled={false}>{`${team.attributes.team_number} - ${team.attributes.name}`}</Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

TeamAssignments.propTypes = {
    panelSessions: PropTypes.array,
    panelName: PropTypes.string,
    panelId: PropTypes.number,
    data: PropTypes.array,
};

TeamAssignments.defaultProps = {
    panelSessions: [],
    data: [],
    panelId: 0,
}

const mapDispatchToProps = {
    handleDeletScoringPanelData: handleDeletScoringPanel,
    handleUpdateScoringPanelData: handleUpdateScoringPanel
}

const mapStateToProps = (state) => {
    const { event: { eventDetails: { attributes, included, is_FLLC_event }, eventScoring: { isCreatingNewRefereePanel, refereeOptions, sessionOptions, formattedPanels, isEditingRefereePanel } } } = state;

    return {
        attributes,
        sessionOptions,
        included,
        is_FLLC_event,
        isEditingRefereePanel
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TeamAssignments);
// export default TeamAssignments;