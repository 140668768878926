import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './SessionPanelUsers.scss';
import UserRow from 'shared/components/UserRow';

const SessionPanelUsers = props => {

  const { panelName, users, noPanelsText, hideEmail, hideAvatar, isPanelAssigned } = props;
  const { t } = useTranslation()
  const displayText = noPanelsText && noPanelsText.length ? noPanelsText : t('noRefereePanelChosenLabel')

  if (!users.length) {
    return (
      <div className='session-panel-users'>
        <p className='session-panel-users__title'>{panelName != '' ? panelName : 'Table'}</p>
        <p className='session-panel-users__subtitle'>{!isPanelAssigned && displayText}</p>
      </div>
    )
  }
  return (
    <div className='session-panel-users'>
      <p className='session-panel-users__title'>{panelName}</p>
      <div className='session-panel-users__content'>
        {users.map(d => (
          <div className='session-panel-users__user' key={d.id}>
            <UserRow hideEmail={hideEmail} hideAvatar={hideAvatar} fullName={d.fullName} email={d.email} />
          </div>
        ))}
      </div>
    </div>
  );
};

SessionPanelUsers.propTypes = {
  users: PropTypes.array,
  panelName: PropTypes.string,
  noPanelsText: PropTypes.string,
  hideEmail: PropTypes.bool,
  isPanelAssigned: PropTypes.bool
};

SessionPanelUsers.defaultProps = {
  users: [],
  panelName: '',
  hideEmail: false,
  isPanelAssigned: true
}

export default SessionPanelUsers;