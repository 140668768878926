export const IMAGE_EXTENSIONS = ['.jpg', '.jpeg', '.png', '.svg', '.bmp', '.gif', '.tiff', '.ico'];

export const VIDEO_EXTENSIONS = ['.mp4', '.mov', '.avi', '.wmv', '.webm'];

export const DOCUMENT_EXTENSIONS = ['.pdf'];

export const EXTENSION_CONTENT_TYPE_MAPPING = {
  webm: 'video/webm',
  mp4: 'video/mp4',
  mov: 'video/quicktime',
  avi: 'video/x-msvideo',
  wmv: 'video/x-ms-wmv',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  svg: 'image/svg+xml',
  bmp: 'image/bmp',
  gif: 'image/gif',
  tiff: 'image/tiff',
  ico: 'image/vnd.microsoft.icon',
  pdf: 'application/pdf',
}