import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/eventScoringMatch/MatchScoresheetButton.scss'
import { useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';;
import WarningIcon from './ButtonWarningIcon';
import RefreshIcon from './ButtonRefreshIcon';

const scoreClassName = 'h-1 heading--charcoal h--normal'
const boldClassName = 'paragraph--large paragraph--bold paragraph--charcoal'

const MatchScoreButton = props => {
  const { t } = useTranslation()

  const {
    disabled,
    loading,
    score,
    onRecalculateClick
  } = props;

  const buttonClassName = disabled ? `c-scoresheet-btn--disabled` : `c-scoresheet-btn` 

  if (!disabled) {
    return (
      <button disabled={loading} onClick={onRecalculateClick} type='button' className={buttonClassName}> 
        <Icon className='c-scoresheet-btn__warning-icon' component={WarningIcon} />
        <p className={boldClassName}>{`${t('scoreLabel')}:`}</p>
        <Icon component={RefreshIcon} />
      </button>
    )
  }

  return (
    <button type='button' className={buttonClassName} disabled={true}>
      <p className={boldClassName}>{`${t('scoreLabel')}:`}</p>
      <p className={scoreClassName}>{score}</p>
    </button>
  );
};

MatchScoreButton.propTypes = {
  onRecalculateClick: PropTypes.func
};


MatchScoreButton.defaultProps = {
  onRecalculateClick: () => {}
}
export default MatchScoreButton;