import Languages from 'utils/i18n/languages/languages.json';
import shortLangaugeKeys from 'utils/shortLangaugeKeys';

const codes = Object.keys(Languages);

const languages = codes.map(key=>{
  const language = Languages[key];
  const displayText = language['languageDisplayLabel'];
  const shortLang = shortLangaugeKeys(key);
  return ({code: key, text: displayText, value: shortLang})
})

export default languages;

