import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Heading from 'shared/components/Heading';
import ListLoading from 'shared/components/Spinner/ListLoading';
import Table from 'shared/components/Table';
import Paragraph from 'shared/components/Paragraph';
import TeamAvatar from 'shared/components/TeamAvatar';
import { ASC } from 'shared/constants/sortOrder';
import { Link } from 'react-router-dom';

const renderItem = (v, r, w) => (
  <Paragraph size='large' weight={w ? 'bold' : 'normal'}>
    {v}
  </Paragraph>
);


const defaultSort = { field: 'rank', order: ASC };

const LeaderboardTable = (props) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('rankLabel'),
      dataIndex: 'rank',
      align: 'center',
      defaultSortOrder: ASC,
      sorter: (a, b) => a.rank - b.rank
    },
    
  ];
  
  const fllCols = [
    {
      title: t('teamNumberAndNameLabel'),
      dataIndex: 'numberName',
      render: (v) => (
        <TeamAvatar text={v} />
      )
    },
    {
      title: t('highScoreLabel'),
      dataIndex: 'highScore',
      align: 'center',
      render: (v, r) => renderItem(v, r, true)
    },
    {
      title: t('match1label'),
      dataIndex: 'match1Score',
      align: 'center',
      render: renderItem
    },
    {
      title: t('match2label'),
      dataIndex: 'match2Score',
      align: 'center',
      render: renderItem
    },
    {
      title: t('match3label'),
      dataIndex: 'match3Score',
      align: 'center',
      render: renderItem
    }
  ];
  
  const ftcCols = [
    {
      title: 'Team Name/Number',
      dataIndex: 'teamNumber',
      render: (v) => (
        <TeamAvatar text={v} />
      )
    },
    {
      title: 'Ranking Points',
      dataIndex: 'sortOrder1',
      align: 'center',
    },
    {
      title: 'Tie Breaker 1',
      dataIndex: 'sortOrder2',
      align: 'center',
    },
    {
      title: 'Tie Breaker 2',
      dataIndex: 'sortOrder3',
      align: 'center',
    },
    {
      title: t('highScoreLabel'),
      dataIndex: 'sortOrder6',
      align: 'center',
      render: (v, r) => renderItem(v, r, true)
    },
    {
      title: 'Played',
      dataIndex: 'matchesPlayed',
      align: 'center',
    },
    {
      title: 'Counted',
      dataIndex: 'matchesCounted',
      align: 'center',
    }
  ];
  const { teams, isFTC, loading, leaderboardId, showPublicButton, eventId } = props;
  console.log(eventId)
  const filteredColumns = useMemo(() =>
    [...columns, ...(isFTC ? ftcCols : fllCols)], [columns, isFTC, ftcCols, fllCols]);
  return (
    <div>
      <Heading additionalClassNames='m-b-36'>{t('leaderboardLabel')}</Heading>
      {showPublicButton&&(<div className="public-link-button"><Link to={`/leaderboard/${eventId}/${leaderboardId}`} target="_blank" disabled={!leaderboardId}>Public Leaderboard</Link></div>)}
      <ListLoading loading={loading} text='Loading Leaderboard' />
      {!loading && (
      <Table
        rowKey='rank'
        columns={filteredColumns}
        dataSource={teams}
        pagination={false}
        defaultSort={defaultSort}
        locale={{emptyText: ""}}
      />
)}
    </div>
  );
};

export default LeaderboardTable;
