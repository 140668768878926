import React from 'react';
import EventProgramLogos from '../EventProgramLogos';
import AddModal from '../Modal';

const EventModal = (props) => {
  const { programId } = props;
  return (
    <AddModal
      {...props}
      renderLogos={() => <EventProgramLogos programId={programId} />}
    />
  );
};

export default EventModal;
