import React from 'react';
import PropTypes from 'prop-types';

const UpArrow = ({fill}) => {
  return (
    <svg width="14px" height="8px" viewBox="0 0 14 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      <g id="Teams/Coaches" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
        <g id="My-Teams---Add-Teams/Coaches---Email-Selected---Team-Two-Added----PDP-Admin" transform="translate(-823.000000, -401.000000)" fill={fill}>
          <g id="Group-25" transform="translate(140.000000, 10.000000)">
            <g id="Group-15-Copy" transform="translate(430.000000, 364.000000)">
              <g id="Group-13" transform="translate(20.000000, 20.000000)">
                <g id="Icons/FIRST/Drop-Arrow" transform="translate(240.000000, 11.000000) scale(1, -1) translate(-240.000000, -11.000000) translate(233.000000, 7.000000)">
                  <polygon id="Path" points="1.645 0 7 4.94466937 12.355 0 14 1.52226721 7 8 0 1.52226721" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

UpArrow.propTypes = {
  fill: PropTypes.string
};

UpArrow.defaultProps = {
  fill: 'currentColor'
}
export default UpArrow;