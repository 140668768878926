import _ from 'lodash';
import { API } from 'utils/api';
import { parallelRequest, queryStringify } from 'utils/query';
import { formatPatchJudgingObject } from './model';

//const { http } = API;
const baseUrl = '/api/judging/fll';
const rubricUrl = '/api/event_rubrics'
const flleRubricUrl = '/api/judge_rubric/fll_e'

export const getJudgingObjectByRequest = (digital_rubric) => {
  const url = `${baseUrl}/${digital_rubric}`;
  return API.GetInstance().http.get(url);
}

export const getJudgingObjectBy = async (digital_rubric) => {
  const url = `${baseUrl}/${digital_rubric}`;
  const { data, included } = await API.GetInstance().http.get(url);
  return { data, included: included || [] }; 
}

export const getFLLEJudgingObjectBy = async (digital_rubric) => {
  const url = `${flleRubricUrl}/${digital_rubric}`;
  const res = await API.GetInstance().http.get(url);
  return res;
}

export const getJudgingObjectByEventId = async (eventId) => {
  const url = `${rubricUrl}/${eventId}`;
  const data = await API.GetInstance().http.get(url);
  if(Array.isArray(data)){
    const formattedData = data.map(d=>{
      const keys = Object.keys(d);
      const newD = {};
      keys.forEach(k=>{
        const underScorekey = _.snakeCase(k);
        newD[underScorekey] = d[k]
      })
      return newD;
    })
    return formattedData
  }
  return data;
}


export const getAllJudgingObjectByIds = async (digital_rubrics)=>{
  const validIds = digital_rubrics.filter(d=>d)
  const result = await parallelRequest(validIds.map((digital_rubric)=>{
    const url = `${baseUrl}/${digital_rubric}`;
    return API.GetInstance().http.get(url)
  }))
  return result;
}

export const updateJudgingObject = async (payload, judgingId) => {
  const url = `${baseUrl}/${judgingId}`;
  const response = await API.GetInstance().http.patch(url, payload);
  const {data, included} = response;
  return {
    data,
    included: included || []
  }
}

export const updateFLLEJudgingObject = async (payload, judgingId) => {
  const url = `${flleRubricUrl}/${judgingId}`;
  const response = await API.GetInstance().http.patch(url, payload);
  return response
}

export const publishJudgingObjects = async (judgings, unpublish) => {
  if (!judgings.length) return;
  const results = [];
  const attr = { isPublished: !unpublish };
  for (let i = 0; i < judgings.length; i++) {
    const { id, isPublishable, isPublished } = judgings[i];
    if ((unpublish && isPublished) || (!unpublish && isPublishable && !isPublished)) {
      const url = `${baseUrl}/${id}`;
      const payload = formatPatchJudgingObject(attr, id);
      await API.GetInstance().http.patch(url, payload);
      results.push(id);
    }
  }
  return results;
};
