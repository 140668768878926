export const GET_MATCH_DETAILS_BEGIN = 'GET_MATCH_DETAILS_BEGIN';
export const GET_MATCH_DETAILS_ERROR = 'GET_MATCH_DETAILS_ERROR';
export const GET_MATCH_DETAILS_SUCCESS = 'GET_MATCH_DETAILS_SUCCESS';
export const RESET_MATCH_DETAILS = 'RESET_MATCH_DETAILS';


export const GET_MATCH_EVENT_DETAILS_BEGIN = 'GET_MATCH_EVENT_DETAILS_BEGIN';
export const GET_MATCH_EVENT_DETAILS_ERROR = 'GET_MATCH_EVENT_DETAILS_ERROR';
export const GET_MATCH_EVENT_DETAILS_SUCCESS = 'GET_MATCH_EVENT_DETAILS_SUCCESS';


export const CREATE_NEW_SCORE_INPUT_BEGIN = 'CREATE_NEW_SCORE_INPUT_BEGIN';
export const CREATE_NEW_SCORE_INPUT_ERROR = 'CREATE_NEW_SCORE_INPUT_ERROR';
export const CREATE_NEW_SCORE_INPUT_SUCCESS = 'CREATE_NEW_SCORE_INPUT_SUCCESS';

export const UPDATE_SCORE_INPUT_BEGIN = 'UPDATE_SCORE_INPUT_BEGIN';
export const UPDATE_SCORE_INPUT_ERROR = 'UPDATE_SCORE_INPUT_ERROR';
export const UPDATE_SCORE_INPUT_SUCCESS = 'UPDATE_SCORE_INPUT_SUCCESS';

export const GET_MATCH_SCORE_INPUT_BEGIN = 'GET_MATCH_SCORE_INPUT_BEGIN';
export const GET_MATCH_SCORE_INPUT_ERROR = 'GET_MATCH_SCORE_INPUT_ERROR';
export const GET_MATCH_SCORE_INPUT_SUCCESS = 'GET_MATCH_SCORE_INPUT_SUCCESS';

export const SET_IS_MATCH_SCORE_INPUT_NEW = 'SET_IS_MATCH_SCORE_INPUT_NEW';

export const RESET_SCORE_INPUT = 'RESET_SCORE_INPUT';

export const CREATE_NEW_SCORE_COMMENT_BEGIN = 'CREATE_NEW_SCORE_COMMENT_BEGIN';
export const CREATE_NEW_SCORE_COMMENT_ERROR = 'CREATE_NEW_SCORE_COMMENT_ERROR';
export const CREATE_NEW_SCORE_COMMENT_SUCCESS = 'CREATE_NEW_SCORE_COMMENT_SUCCESS';

export const SET_MATCH_SCORE_COMMENTS = 'SET_MATCH_SCORE_COMMENTS';

export const SET_MATCH_TEAM_DATA = 'SET_MATCH_TEAM_DATA';

export const SET_MATCH_SCORE_DATA = 'SET_MATCH_SCORE_DATA';

export const SET_IS_MATCH_SCORE_INPUT_PUBLISHED = 'SET_IS_MATCH_SCORE_INPUT_PUBLISHED';

export const RECALCULATE_MATCH_SCORE_BEGIN = 'RECALCULATE_MATCH_SCORE_BEGIN'
export const RECALCULATE_MATCH_SCORE_ERROR = 'RECALCULATE_MATCH_SCORE_ERROR'
export const RECALCULATE_MATCH_SCORE_SUCCESS = 'RECALCULATE_MATCH_SCORE_SUCCESS'

export const SET_IS_MATCH_SCORESHEET_EDITED = 'SET_IS_MATCH_SCORESHEET_EDITED';