import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EventModal from 'features/EventContainer/components/EventModal';
import SessionModalContent from './SessionModalContent';
import '../../../styles/ScheduleContainer/SessionModal.scss';
import { connect } from 'react-redux';

const SessionModal = (props) => {
  const {
    visible,
    onCancel,
    title,
    sessionId,
    showPanelInfo,
    iconType,
    time_zone,
    cb,
    is_FLLC_event
  } = props;
  const { t } = useTranslation()

  const displayText = title && title.length ? title : t('sessionLabel')
  return (
    <EventModal
      visible={visible}
      onCancel={onCancel}
    >
      <div className='schedule-session-modal'>
        <SessionModalContent is_FLLC_event={is_FLLC_event} cb={cb} time_zone={time_zone} closeCallback={onCancel} iconType={iconType} showPanelInfo={showPanelInfo} sessionId={sessionId} title={displayText} />
      </div>
    </EventModal>
  );
};

SessionModal.defaultProps = {
  visible: false,
  onCancel: () => { },
  showPanelInfo: true,
  iconType: 'judging',
};

const mapStateToProps = (state) => {
  const {event: {eventDetails: {is_FLLC_event}}} = state;
  return {is_FLLC_event}
}

export default connect(mapStateToProps)(SessionModal);
