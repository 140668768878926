import { Table, Checkbox } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { officialRefereeReviewSessionType, practiceRefereeReviewSessionType } from 'shared/constants/timestampTypes';
import '../../../styles/RefereePanel/RefereeAssignmentPanel.scss'
import SearchBar from 'features/AdminDashboard/components/SearchBar';

const DEFAULT_SIZE_PER_PAGE = 12

const PanelAssignmentTable = (props) => {
  const { data, panelName, form } = props;
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSize, setCurrentSize] = useState(DEFAULT_SIZE_PER_PAGE);
  const [prevPage, setPrevPage] = useState(1);

  let formattedData = [];

  const groupedData = _.groupBy(data, 'teamId');
  const keys = Object.keys(groupedData);
  keys.forEach(key => {
    const teamData = groupedData[key];
    const teamName = ((teamData || [])[0] || {}).teamName;
    let practiceMatchPanel, match1Panel, match2Panel, match3Panel, matchPanelId;
    let practiceMatchId, match1Id, match2Id, match3Id;
    teamData.forEach(d => {
      const { sessionTypeId, panelName, matchNumber, sessionId, panelId } = d;
      if (sessionTypeId === practiceRefereeReviewSessionType) {
        practiceMatchPanel = panelName;
        practiceMatchId = sessionId;
      } else if (sessionTypeId === officialRefereeReviewSessionType) {
        switch (matchNumber) {
          case 1:
            match1Panel = panelName;
            match1Id = sessionId;
            matchPanelId = panelId
            break;
          case 2:
            match2Panel = panelName;
            match2Id = sessionId;
            matchPanelId = panelId
            break;
          case 3:
            match3Panel = panelName;
            match3Id = sessionId;
            matchPanelId = panelId
            break;
          default:
            break;
        }
      }
    })
    
    formattedData.push({ key: key, teamName, practiceMatchPanel, match1Panel, match2Panel, match3Panel, practiceMatchId, match1Id, match2Id, match3Id, matchPanelId })
  })

  const onCheckboxChanged = (e, id) => {
    const value = e.target.checked;
    const sessions = form.getFieldValue('sessions') || [];
    if (value) {
      form.setFieldsValue({ sessions: [...sessions, id] })
    } else {
      form.setFieldsValue({ sessions: [...sessions.filter(sessionId => sessionId !== id)] })
    }
  }

  const currentSelectedSessions = form.getFieldValue('sessions') || [];

  console.log("currentSelectedSession==============s,", currentSelectedSessions);

  const columCheckbox = (panel, sessionId, panelId) => {
    const sessionUndefined = sessionId === undefined;

    let assignedToOtherPanel;


    if(props.isEdit){
      assignedToOtherPanel = (panel && (panel !== props?.selectedPanel?.name));
    }else{
      assignedToOtherPanel = (panel && (panel !== props?.selectedPanel?.name) && !props.isEdit);
    }

    // const assignedToOtherPanel = (panelId && panelId != props?.selectedPanel?.id);
    const assignedToCurrentPanel = currentSelectedSessions.includes(sessionId);
    const checked = assignedToOtherPanel || assignedToCurrentPanel && panelName != "";
    const displayText = assignedToOtherPanel ? panel : assignedToCurrentPanel ? panelName == "" ? t('notAssignedLabel') : panelName : sessionUndefined ? '-' : t('notAssignedLabel')
    const wrapperClassname = assignedToOtherPanel ? '' : assignedToCurrentPanel ? 'assigned' : 'unassigned';
    return <div className={`checkbox-wrapper ${wrapperClassname}`}><Checkbox onChange={(e) => onCheckboxChanged(e, sessionId)} disabled={assignedToOtherPanel || sessionUndefined || panelName == ""} checked={checked}>{displayText}</Checkbox></div>
  }
  
  const columns = [
    {
      title: t('teamNumberAndNameLabel'),
      key: 'teamName',
      dataIndex: 'teamName',
      sorter: (a, b) => a.teamName.localeCompare(b.teamName),
      render: (text, record) => text
    },
    {
      title: t('practiceMatchLabel'),
      key: 'practiceMatchPanel',
      dataIndex: 'practiceMatchPanel',
      render: (text, record) => {
        const { practiceMatchId, matchPanelId } = record;
        return columCheckbox(text, practiceMatchId, matchPanelId)
      }
    },
    {
      title: t('match1label'),
      key: 'match1Panel',
      dataIndex: 'match1Panel',
      render: (text, record) => {
        const { match1Id, matchPanelId } = record;
        return columCheckbox(text, match1Id, matchPanelId)
      }
    },
    {
      title: t('match2label'),
      key: 'match2Panel',
      dataIndex: 'match2Panel',
      render: (text, record) => {
        const { match2Id, matchPanelId } = record;
        return columCheckbox(text, match2Id, matchPanelId)
      }
    },
    {
      title: t('match3label'),
      key: 'match3Panel',
      dataIndex: 'match3Panel',
      render: (text, record) => {
        const { match3Id, matchPanelId } = record;
        return columCheckbox(text, match3Id, matchPanelId)
      }
    },
  ]

  const filteredTeam = search ? formattedData.filter(d => d.teamName.toLowerCase().includes(search.toLowerCase())) : formattedData
  console.log("=========================", filteredTeam);

  const toggleCurrentPageSize = () => {
    const length = filteredTeam.length;
    const showingAll = currentSize > DEFAULT_SIZE_PER_PAGE;
    const size = showingAll ? DEFAULT_SIZE_PER_PAGE : length;
    if (showingAll) {
      setCurrentPage(prevPage);
    } else {
      setPrevPage(currentPage);
      setCurrentPage(1)
    }
    setCurrentSize(size);
  }

  const ShowTotalBlock = (total, range) => {
    const hasMore = total > DEFAULT_SIZE_PER_PAGE;
    const showMoreTitle = currentSize > DEFAULT_SIZE_PER_PAGE ? "Show Less" : "Show All";
    return (
      <div className="showing-total-wrapper">
        <div className="text">{`Showing ${range[0]} - ${range[1]} of ${total}`}</div>
        <div className="divider">|</div>
        {hasMore ? <a onClick={toggleCurrentPageSize}>{showMoreTitle}</a> : null}
      </div>
    )
  }

  return (
    <div className="referee-assignment-table-wrapper">
      <div className="search-bar-wrapper">
        <SearchBar searchText={search} setSearchText={setSearch} placeholder={t('searchTeamLabel')} />
      </div>
      <Table
        columns={columns}
        dataSource={filteredTeam}
        rowClassName={(record, index) => index % 2 === 1 ? 'even-row' : 'odd-row'}
        className="referee-assignment-table"
        pagination={{
          pageSize: currentSize,
          current: currentPage,
          onChange: (page) => setCurrentPage(page),
          total: filteredTeam.length,
          showTotal: ShowTotalBlock
        }}
      />
    </div>
  )
}

export default PanelAssignmentTable;