import * as sessionService from 'utils/services/sessions';
import * as panelService from 'utils/services/panels';
import * as userService from 'utils/services/users';
import { formatSessionResData } from 'utils/services/sessions/model';
import { JUDGING_REVIEW_PANEL_TYPE_ID } from 'shared/constants/panelTypes';

import Panel from 'models/Panel';
import ScheduleSession from 'models/ScheduleSession';
import {
  JUDGE_ADVISOR_ID,
  JUDGE_ID,
} from 'shared/constants/eventRoleTypes';
import { FTC_ID } from 'shared/constants/programIds';
import get from 'lodash/get'
import * as types from '../types/assignedJudgingSessionsTypes';
import _ from 'lodash';
import { getOptionalAwardsNomination } from './assignedAwards';
import PanelModel from 'models/PanelClassModel';

const sortJudgingSessions = (a, b) => {
  const judgingSessionA = get(a, ['teamTitle'], '').toUpperCase();
  const judgingSessionB = get(b, ['teamTitle'], '').toUpperCase();

  let comparison = 0;
  if (judgingSessionA > judgingSessionB) {
    comparison = 1;
  } else if (judgingSessionA < judgingSessionB) {
    comparison = -1;
  }
  return comparison;
}

const getJudgingSessionsBegin = () => {
  return {
    type: types.GET_ASSIGNED_JUDGING_SESSIONS_BEGIN
  }
}

const getJudgingSessionsError = () => {
  return {
    type: types.GET_ASSIGNED_JUDGING_SESSIONS_ERROR
  }
}

const getJudgingSessionsSuccess = (assignedSessions, assignedPanels, loading = true) => {
  return {
    type: types.GET_ASSIGNED_JUDGING_SESSIONS_SUCCESS,
    payload: {
      assignedSessions, assignedPanels, loading
    }
  }
}

const updateAssignedSessionCount = (count) => {
  return {
    type: types.UPDATE_ASSIGNED_JUDGING_SESSIONS_COUNT,
    payload: count
  }
}

const updateJudgingSessionData = (sessionData, panelData, sessionsCount, panelsCount) => {
  return async (dispatch, getState) => {
    const { assignedJudgingSessions: { assignedSessions, assignedPanels } } = getState();
    const newSessions = _.uniqBy([...assignedSessions, ...sessionData], 'sessionId');
    const newPanels = _.uniqBy([...assignedPanels, ...panelData], 'id');
    const sortedPanels = _.sortBy(newPanels, ['name']);
    const loading = newSessions.length !== sessionsCount || sortedPanels.length !== panelsCount;
    dispatch(getJudgingSessionsSuccess(newSessions, sortedPanels, loading))
  }
}

const getAssignedJudgingSessions = (page = { size: 500, number: 1 }) => {
  return async (dispatch, getState) => {
    await dispatch(getJudgingSessionsBegin());
    try {
      await dispatch(getJudgingSessionsSuccess([], []))
      const { auth: { userId }, eventOverview: { eventProgramId, eventScheduleId, userEventRoleIds, isAdminRole } } = getState();

      const { count, judging_sessions: response } = await sessionService.getJudgingSessions(eventScheduleId, page);

      // const {data, included, meta} = response;
      // const { count } = response; //change count 
      dispatch(updateAssignedSessionCount(count))
      
      // const sessionsList = data.filter(i => i.type === 'session');
      const sessionsList = response;

      const formattedSessionsList = sessionsList.map(s => formatSessionResData(new ScheduleSession(s, [], [], eventScheduleId, 2)));

      const formattedSessionsTeams = formattedSessionsList.map(s => {
        return {
          ...s,
          teamTitle: s.teamName,
        }
      }).sort(sortJudgingSessions)
      dispatch(updateJudgingSessionData(formattedSessionsTeams, [], sessionsList.length, 0))

      // const panelsList = included.filter(i => i.type === 'panel');
      let panelsList = await sessionService.getPanelsData(eventScheduleId, 'judge');
      const panelListData = []

      panelsList.forEach(panel => {
        if (panel?.panelType?.id == JUDGING_REVIEW_PANEL_TYPE_ID) {
          panelListData.push(panel)
        }
      });

      let panelDataObj = panelListData.map(panel => new PanelModel(panel, 'judge'))

      // FOR JUDGE ADVISORS GET ALL OF THE JUDGING SESSIONS
      if (userEventRoleIds.includes(JUDGE_ADVISOR_ID) || eventProgramId === FTC_ID || isAdminRole) {
        // const panelsFilter = {
        //   'sessions.schedule.id': `eq:${eventScheduleId}`,
        //   'panel_type.id': `eq:${JUDGING_REVIEW_PANEL_TYPE_ID}`
        // }

        const panelsUsersId = [];
        panelsList.forEach(panel => {
          panel.roleAssignments.forEach(role=>{
            if(role.userId){
              panelsUsersId.push(role.userId);
            }
          })
        })

        const uniqUserIds = _.uniq(panelsUsersId).join(',');
        // GET THE DATA OF ALL THE REFEREES ON THE PANELS
        const usersResponse = await userService.getUsersByIds(uniqUserIds);

        const assignedPanels = panelDataObj.map(d => panelService.formatPanelData(d));
        const formattedPanelsList = assignedPanels.map(p => {
          const panelObj = {}
          const panelJudges = [];
          p.judgesIds.forEach(j => {
            const currentJudge = usersResponse.find(u => u.id === j);
            if (currentJudge) {
              const formattedJudge = {
                ...currentJudge,
                fullName: `${currentJudge.firstName} ${currentJudge.lastName}`
              }
              panelJudges.push(formattedJudge);
            }
          })
          panelObj.judges = panelJudges;
          panelObj.id = p.id;
          panelObj.name = p.name;
          panelObj.panelType = p.panelTypeName;

          return panelObj;
        })
        await dispatch(updateJudgingSessionData([], formattedPanelsList, sessionsList.length, assignedPanels.length))

      } else if (userEventRoleIds.includes(JUDGE_ID)) {
        // const panelsFilter = {
        //   'sessions.schedule.id': `eq:${eventScheduleId}`,
        //   'judges.id': `eq:${userId}`,
        //   'panel_type.id': `eq:${JUDGING_REVIEW_PANEL_TYPE_ID}`
        // }
        const panelsUsersId = panelsList.map(panel => panel.roleAssignments.map(i => i.userId).join(','));
        // GET THE DATA OF ALL THE REFEREES ON THE PANELS
        const usersResponse = await userService.getUsersByIds(panelsUsersId);

        const assignedPanels = panelDataObj.map(d => panelService.formatPanelData(d));
        
        const formattedPanelsList = assignedPanels.map(p => {
          const panelObj = {}
          const panelJudges = [];
          p.judgesIds.forEach(j => {
            const currentJudge = usersResponse.find(u => u.id === j);
            if (currentJudge) {
              const formattedJudge = {
                ...currentJudge,
                fullName: `${currentJudge.firstName} ${currentJudge.lastName}`
              }
              panelJudges.push(formattedJudge);
            }
          })
          panelObj.judges = panelJudges;
          panelObj.id = p.id;
          panelObj.name = p.name;
          panelObj.panelType = p.panelTypeName;

          return panelObj;
        })

        await dispatch(updateJudgingSessionData([], formattedPanelsList, sessionsList.length, assignedPanels.length))
      } else {
        await dispatch(getJudgingSessionsSuccess([], []))

      }

    } catch (err) {
      await dispatch(getJudgingSessionsError())
      throw err;
    }

  }
}

const getJudgingSessionsForAwardsBegin = () => {
  return {
    type: types.GET_ASSIGNED_JUDGING_SESSIONS_FOR_AWARDS_BEGIN
  }
}

const getJudgingSessionsForAwardsError = () => {
  return {
    type: types.GET_ASSIGNED_JUDGING_SESSIONS_FOR_AWARDS_ERROR
  }
}

const getJudgingSessionsForAwardsSuccess = (assignedSessions, loading = true) => {
  return {
    type: types.GET_ASSIGNED_JUDGING_SESSIONS_FOR_AWARDS_SUCCESS,
    payload: {
      assignedSessions, loading
    }
  }
}

const updateAssignedSessionForAwardsCount = (count) => {
  return {
    type: types.UPDATE_ASSIGNED_JUDGING_SESSIONS_FOR_AWARDS_COUNT,
    payload: count
  }
}

const setCurrentPage = (currentPage) => {
  return {
    type: types.SET_ASSIGNED_JUDGES_CURRENT_PAGE,
    payload: {
      currentPage
    }
  }
}

export const handlePageChange = (pageNumber) => {
  return async dispatch => {
    await dispatch(setCurrentPage(pageNumber))
  }
}


export const getAssignedJudgingSessionsForAwards = (page = { size: 500, number: 1 }) => {
  return async (dispatch, getState) => {
    await dispatch(getJudgingSessionsForAwardsBegin());
    try {
      await dispatch(getJudgingSessionsForAwardsSuccess([], []))
      const { eventOverview: { eventScheduleId } } = getState();

      const { count, judging_sessions: response } = await sessionService.getJudgingSessions(eventScheduleId, page);
      const sessionsList = response;
      // const formattedSessionsList = sessionsList.map(s => formatSessionResData(new ScheduleSession(s, [], [], eventScheduleId, 2)));

      // const {data, included, meta} = response;
      // const { count } = response;  //change count
      dispatch(updateAssignedSessionForAwardsCount(count))
      // const sessionsList = data.filter(i => i.type === 'session');
      const formattedSessionsList = sessionsList.map(s => formatSessionResData(new ScheduleSession(s, [], [], eventScheduleId, 2)));

      const formattedSessionsTeams = formattedSessionsList.map(s => {
        return {
          ...s,
          teamTitle: s.teamName,
        }
      }).sort(sortJudgingSessions)
      dispatch(getJudgingSessionsForAwardsSuccess(formattedSessionsTeams, false))
      dispatch(getOptionalAwardsNomination(formattedSessionsList));


    } catch (err) {
      await dispatch(getJudgingSessionsForAwardsError())
      throw err;
    }

  }
}

export default getAssignedJudgingSessions;