import * as programService from 'utils/services/programs';
import * as regionService from 'utils/services/regions';
import * as countryService from 'utils/services/countries';
import {
  FETCH_PROGRAMS_BEGIN,
  FETCH_PROGRAMS_SUCCESS,
  FETCH_PROGRAMS_ERROR,
  FETCH_PROGRAM_REGIONS_BEGIN,
  FETCH_PROGRAM_REGIONS_SUCCESS,
  FETCH_PROGRAM_REGIONS_ERROR,
  GET_COUNTRIES_BEGIN,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR
} from '../types/programsTypes';
import Country from 'models/Country';
import Region from 'models/Region';

const getProgramsBegin = () => ({
  type: FETCH_PROGRAMS_BEGIN
});

const getProgramsSuccess = (payload) => ({
  type: FETCH_PROGRAMS_SUCCESS,
  payload
});

const getProgramsError = () => ({
  type: FETCH_PROGRAMS_ERROR
});

export const fetchPrograms = () => async (dispatch, getState) => {
  try {
    const { adminBoard: { programs: { list } } } = getState();
    if (list.length) return;

    await dispatch(getProgramsBegin());
    const data = await programService.getPrograms();
    const payload = data.map((program) => {
      const programName = program.name && program.name.replace(/FIRST/g, '').replace(/®/g, '');
      return { ...program, name: programName };
    });
    await dispatch(getProgramsSuccess(payload));
  } catch (err) {
    dispatch(getProgramsError());
    throw err;
  }
};

const getRegionsByBegin = () => ({
  type: FETCH_PROGRAM_REGIONS_BEGIN
});

const getRegionsBySuccess = (payload) => ({
  type: FETCH_PROGRAM_REGIONS_SUCCESS,
  payload
});

const getRegionsByError = () => ({
  type: FETCH_PROGRAM_REGIONS_ERROR
});

export const fetchRegionsBy = (programId, input) => async (dispatch) => {
  try {
    await dispatch(getRegionsByBegin());
    const regions = await regionService.getRegionsByProgram(programId, input);
    await dispatch(getRegionsBySuccess({ programId, regions }));
  } catch (err) {
    dispatch(getRegionsByError());
    throw err;
  }
};

const getCountriesBegin = () => ({
  type: GET_COUNTRIES_BEGIN
});

const getCountriesSuccess = (payload) => ({
  type: GET_COUNTRIES_SUCCESS,
  payload
});

const getCountriesError = () => ({
  type: GET_COUNTRIES_ERROR
});

export const fetchCountries = (input) => async (dispatch) => {
  try {
    await dispatch(getCountriesBegin());
    const data = await countryService.getCountries(input);
    await dispatch(getCountriesSuccess(data));
  } catch (err) {
    dispatch(getCountriesError());
    throw err;
  }
};

export const updateCountries = (data) => async (dispatch) => {
  try {
    let country = data.map((c) => new Country(c));
    await dispatch(getCountriesSuccess(country));
  } catch (err) {
    dispatch(getCountriesError());
    throw err;
  }
}

export const updateRegions = (data, programId) => async (dispatch) => {
  try {
    let regions = data.map((r) => new Region(r));
    await dispatch(getRegionsBySuccess({ programId, regions }));
  } catch (err) {
    dispatch(getRegionsByError());
    throw err;
  }
}
