import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams, useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PageLayout from 'shared/components/Layouts/PageLayout';
import Container from 'shared/components/Container';

import Event from 'models/Event';
import Tabs from 'shared/components/Tabs/ModalTabs';
import * as eventRoleTypes from 'shared/constants/eventRoleTypes';
import {
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FTC_ID,
  FLL_CHALLENGE_ID
} from 'shared/constants/programIds';
import * as userTypes from 'shared/constants/userTypes';
import ScreenLoading from 'shared/components/Spinner/ScreenLoading';
import LeaderboardContainer from 'features/LeaderboardContainer';
import EventLayout from 'features/EventOverviewContainer/components/EventOverviewLayout';
import EventOverviewHeader from 'features/EventOverviewContainer/components/EventOverviewHeader';
import EventOverviewSchedule from 'features/EventOverviewContainer/components/EventOverviewSchedule';
import getEventOverview from 'features/EventOverviewContainer/redux/actions/getEventOverview';
import EventResources from 'features/EventOverviewContainer/components/EventOverviewSchedule/MessagingAndResources/Resources';
import getUserEventRoles from 'features/EventOverviewContainer/redux/actions/userEventOverviewRoles';
import { 
  fetchEventAwards
} from 'features/EventOverviewContainer/redux/actions/assignedAwards';
import { 
  fetchEventLeaderboard
} from 'features/LeaderboardContainer/redux/leaderboardActions';
import { PUBLISHED } from 'shared/constants/publishStatus';
import FTCAwards from 'features/EventOverviewContainer/components/AssignedAwards/FTCAwards';
import AssignedJudgingSessions from './components/AssignedJudgingSessions';
import AssignedScoringSessions from './components/AssignedScoringSessions';
import AssignedAwards from './components/AssignedAwards';
import CoachJudging from './components/CoachJudging';
import MatchesAndScoring from './components/CoachScoring';
import './styles/EventOverviewContainer.scss';
import CoachMessaging from './components/CoachMessaging';
import BackToBoard from './components/BackToBoard';
import EventModal from 'features/EventOverviewContainer/components/EventModal';

const whiteTextClassName = 'paragraph--large paragraph--bold paragraph--white';

const LIMITED_SCORING_TABS_PROGRAM_TYPES = [
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FTC_ID
];

const AVAILABLE_EVENT_OVERVIEW_HASHES = [
  '#schedule',
  '#judging-sessions',
  '#scoring-sessions',
  '#matches',
  '#judging',
  '#awards',
  '#leaderboard',
  '#resources',
  '#message',
];

const EventOverview = (props) => {
  const { t, i18n } = useTranslation();
  const rtl_direction = i18n.dir();
  const {
    getEventDetails,
    eventOverview,
    canViewPage,
    awardIsPublished,
    leaderboardIsPublished,
    userType,
    leaderboardId,
    userEventAssignments
  } = props;

  const {
    isFetchingEventSchedule,
    errorFetchingEventSchedule,
    eventIncluded,
    eventData,
    eventScheduleData,
    eventScheduleIncluded,
    userEventRoleIds,
    isFetchingUserEventRoles,
    eventProgramId,
    coachDetails,
    scheduleError,
    isAdminRole
  } = eventOverview;

  const scheduleId = (eventScheduleData||{}).id

  const isFTC = FTC_ID === eventProgramId;
  const isFLLC = FLL_CHALLENGE_ID === eventProgramId;

  const history = useHistory();
  const { eventId } = useParams();
  const location = useLocation();
  const { hash } = location;

  const [defaultTabKey, setDefaultTabKey] = useState(hash?hash:'#schedule');
  const [descriptionVisible, setDesicriptionVisible] = useState(false);
  const [direction, setDirection] = useState('ltr');

  useEffect(()=>{
    setDirection(rtl_direction)
  },[rtl_direction])

  // Judge and Judge Advisor can view judging sessions
  const judgingSessionsRoles = [eventRoleTypes.JUDGE_ID, eventRoleTypes.JUDGE_ADVISOR_ID];
  const canViewJudgingSessions = isAdminRole || judgingSessionsRoles.some(i => userEventRoleIds.includes(i));

  // Judge Advisor can edit awards assignments
  const canEditJudgingAwards = isAdminRole || userEventRoleIds.includes(eventRoleTypes.JUDGE_ADVISOR_ID);

  // Coach can view the Matches & Scoring tab
  const matchesScoringRoles = [eventRoleTypes.COACH_ID];
  const canViewMatchesAndScoring =  matchesScoringRoles.some(i => userEventRoleIds.includes(i));

  // Coach can view the Judging tab
  const judgingTabRoles = [eventRoleTypes.COACH_ID];
  const canViewJudgingTab = judgingTabRoles.some(i => userEventRoleIds.includes(i));

  // Referee and Head referee can view the scoring sessions
  const scoringSessionsRoles = [eventRoleTypes.REFEREE_ID, eventRoleTypes.HEAD_REFEREE_ID];
  const canViewScoringSessions = isAdminRole || scoringSessionsRoles.some(i => userEventRoleIds.includes(i));

  useEffect(() => {
    if (eventId) {
      getEventDetails(eventId);
      props.fetchEventAwards(eventId);
    }
  }, [eventId]);

  useEffect(() => {
    if (leaderboardId && (isFTC || isFLLC)) {
      props.fetchEventLeaderboard(leaderboardId);
    }
  }, [leaderboardId, eventProgramId])

  useEffect(() => {
    if (hash && AVAILABLE_EVENT_OVERVIEW_HASHES.includes(hash)) {
      setDefaultTabKey(hash);
    } else if (hash && !AVAILABLE_EVENT_OVERVIEW_HASHES.includes(hash)) {
      history.replace(`/event-overview/${eventId}#schedule`);
    }
    window.scrollTo(0, 0);
  }, [eventId, hash, userEventRoleIds]);

  const handleTabClick = useCallback((tabKey) => {
    history.replace(`/event-overview/${eventId}${tabKey}`);
  }, [history, eventId]);


  if (isFetchingEventSchedule || isFetchingUserEventRoles) {
    return <ScreenLoading loading subTitle={t('loadingEventLabel')} />
  }

  if (errorFetchingEventSchedule) {
    return <p>Error</p>;
  }

  if (scheduleError) {
    return (
      <PageLayout>
        <Container>
          <p className={`${whiteTextClassName} m-t-36`}>{t('scheduleNotAvailableLabel')}</p>
          <p className={whiteTextClassName}>{t('tryAgainLabel')}</p>
        </Container>
      </PageLayout>
    )
  }

  const canViewScoring = !LIMITED_SCORING_TABS_PROGRAM_TYPES.includes(eventProgramId);

  if (!userEventRoleIds.length && userType === userTypes.USER) {
    return (
      <EventLayout
        currentTab={defaultTabKey}
      >
        <p className={whiteTextClassName}>{t('noPermissionLabel')}</p>
      </EventLayout>
    );
  }

  const eventDetails = new Event(eventData, eventIncluded);

  const renderDescriptionModal = ()=>{
    if(!eventDetails.description) return null;
    return (
      <EventModal visible={descriptionVisible} onCancel={() => setDesicriptionVisible(false)}>
        <div>{eventDetails.description}</div>
      </EventModal>
    )
  }

  const BackButton = ()=>(
    <div className="event-overview-back-row">
      <BackToBoard/>
      <div className="event-overview-back-row-link">
      {eventDetails.description ? (
          <div onClick={()=>setDesicriptionVisible(true)}>
            <a className='link'>Event Description</a>
          </div>
        ) : (null)}
        {eventDetails.url ? (
          <div>
            <a className='link' href={eventDetails.url} target="_blank"> Partner Website</a>
          </div>
        ) : (
          null
        )}
        </div>
    </div>
  )

  const customRenderTabBar = (props, DefaultTabBar) => {
    return (
      <div>
        <DefaultTabBar {...props} direction={direction}/>
        <BackButton />
      </div>
    )
  }

  return (
    <EventLayout
      currentTab={defaultTabKey}
      scheduleId={scheduleId}
      programId={eventProgramId}
    >
      {renderDescriptionModal()}
      <EventOverviewHeader coachDetails={coachDetails} eventDetails={eventDetails} activeTab={defaultTabKey}/>
      <section className='event-overview-container'>
        <Tabs
          overrides={
            { onTabClick: handleTabClick, activeKey: defaultTabKey, renderTabBar: customRenderTabBar}
          }
        >
          <Tabs.TabPane tab={t('scheduleLabel')} key='#schedule'>
            <EventOverviewSchedule
              scheduleData={eventScheduleData}
              scheduleIncluded={eventScheduleIncluded}
              eventDetails={eventDetails}
              handleTabClick={handleTabClick}
            />
          </Tabs.TabPane>
          {canViewJudgingSessions && (
            <Tabs.TabPane tab={isFLLC ? t('judgingSessionsLabel') : t('nonChallengeJudgingSessionsLabel')} key='#judging-sessions'>
              <AssignedJudgingSessions />
            </Tabs.TabPane>
          )}
          {(canViewScoringSessions && canViewScoring) && (
            <Tabs.TabPane tab={t('scoringSessionsLabel')} key='#scoring-sessions'>
              <AssignedScoringSessions />
            </Tabs.TabPane>
          )}
          {(canViewMatchesAndScoring && canViewScoring) && (
            <Tabs.TabPane tab={t('matchesAndScoringLabel')} key='#matches'>
              <MatchesAndScoring />
            </Tabs.TabPane>
          )}
          {canViewJudgingTab && (
            <Tabs.TabPane tab={isFLLC ? t('judgingLabel'): t('nonChallengeJudgingLabel')} key='#judging'>
              <CoachJudging />
            </Tabs.TabPane>
          )}
          <Tabs.TabPane tab={t('resourcesLabel')} key='#resources'>
            <EventResources />
          </Tabs.TabPane>


          <Tabs.TabPane tab={t('messagingLabel')} key='#message'>
            <CoachMessaging scheduleId={scheduleId}/>
          </Tabs.TabPane>

          {(canViewJudgingSessions || awardIsPublished) && (
            <Tabs.TabPane tab={t('awardsLabel')} key='#awards'>
              {(eventProgramId === FTC_ID) ? (
                <FTCAwards />
              ) : (
                <AssignedAwards canEdit={canEditJudgingAwards} canViewAwardTable={canViewJudgingSessions}/>
              )}
            </Tabs.TabPane>
          )}
          {(leaderboardIsPublished && (isFTC || isFLLC)) && (
            <Tabs.TabPane tab={t('leaderboardLabel')} key='#leaderboard'>
              <LeaderboardContainer
                readOnly
                eventId={eventId}
                isFTC={FTC_ID === eventProgramId}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </section>
    </EventLayout>
  );
};

const mapDispatchToProps = {
  getEventDetails: getEventOverview,
  getEventRoles: getUserEventRoles,
  fetchEventAwards,
  fetchEventLeaderboard
};

const mapStateToProps = (state) => {
  const { auth, eventOverview, assignedAwards, eventLeaderboard: { leaderboard: { isPublished } , leaderboardId} } = state;
  const { canAccessVolunteerDashboard, userType, userEventAssignments } = auth;
  return {
    eventOverview,
    userType,
    canViewPage: canAccessVolunteerDashboard,
    awardIsPublished: assignedAwards.status === PUBLISHED,
    leaderboardIsPublished: isPublished,
    leaderboardId,
    userEventAssignments
  };
};

EventOverview.propTypes = {
  getEventDetails: PropTypes.func,
  eventOverview: PropTypes.object,
  canViewPage: PropTypes.bool,
  userType: PropTypes.string.isRequired
};

EventOverview.defaultProps = {
  getEventDetails: () => { },
  eventOverview: {},
  canViewPage: false
};

export default connect(mapStateToProps, mapDispatchToProps)(EventOverview);
