import * as timestampService from 'utils/services/timestamps'
import * as types from '../../types/coachJudgingTypes';

const getJudgingTimestampsBegin = () => {
  return {
    type: types.GET_COACH_JUDGING_TIMESTAMPS_BEGIN
  }
}

const getJudgingTimestampsError = () => {
  return {
    type: types.GET_COACH_JUDGING_TIMESTAMPS_ERROR
  }
}

const getJudgingTimestampsSuccess = (judgingTimestamps) => {
  return {
    type: types.GET_COACH_JUDGING_TIMESTAMPS_SUCCESS,
    payload: {
      judgingTimestamps
    }
  }
}

const getJudgingTimestamps = () => {
  return async (dispatch, getState) => {
    const {eventOverview: {eventScheduleId}} = getState()
    await dispatch(getJudgingTimestampsBegin())
    try {
      const response = await timestampService.getJudgingTimestamps(eventScheduleId);
      await dispatch(getJudgingTimestampsSuccess(response))
    } catch(err) {
      await dispatch(getJudgingTimestampsError())
      throw err;
    }


  }


}

export default getJudgingTimestamps;