import { API } from 'utils/api';
import { queryStringify, parallelRequest } from 'utils/query';
import { COACH_ID } from 'shared/constants/eventRoleTypes';
import {
  formatCreateEventRoleBody,
  formatSendInvitationsBody,
  formatVerifyInvitationBody,
  formatAssignRoleBody,
  formatVolunteers,
  formatVolunteersNew
} from './model';

//const { http } = API.GetInstance();
const baseUrl = '/api/user_event_roles';
const baseUrlEvent = '/api/events';

const emptyResponse = {
  data: [],
  included: [],
  count: 0
};

export const getEventVolunteers = async (eventId, filter, page, sort) => {
  const sortQuery = sort ? `&sort=${encodeURIComponent(sort)}` : '';
  const queryStr = queryStringify({ filter, page });
  const url = `/api/events/${eventId}/volunteers${queryStr}${sortQuery}`;
  const { data, included, meta } = await API.GetInstance().http.get(url);
  return {
    data,
    included: included || [],
    count: meta.count
  };
}


export const getUserEventRolesBy = async (filter, page, sort) => {
  const sortQuery = sort ? `&sort=${encodeURIComponent(sort)}` : '';
  const queryStr = queryStringify({ filter, page });
  const url = `${baseUrl}${queryStr}${sortQuery}`;
  const { data, included, meta } = await API.GetInstance().http.get(url, { headers: { 'api-version': 2.0 } });
  return {
    data,
    included: included || [],
    count: meta.count
  };
};

export const getAllUserEventRolesBy = async (filter, page) => {
  const initialPageSize = 50;
  const res = await getUserEventRolesBy(filter, { size: initialPageSize, number: 1 });
  const { count } = res;
  if (count > initialPageSize) {
    return await getUserEventRolesBy(filter, { size: count, number: 1 });
  }
  return res;
};

// model method
const formatEventRoleData = (r) => {
  const eventRole = {};
  eventRole.status = r.status;
  eventRole.id = r.eventRoleAssignmentId;
  eventRole.teamsIds = r.getTeamsIds();
  eventRole.eventsIds = r.getEventsIds();
  return eventRole;
};

export const getCoachRolesByUserId = async (userId) => {
  const filter = {
    'role.id': `eq:${COACH_ID}`,
    'user.id': `eq:${userId}`
  };
  return await getUserEventRolesBy(filter);
};

export const getVolunteerEventRoles = async (page, searchFilter, sort) => {
  const filter = {
    'role.id': `ne:${COACH_ID}`,
    ...searchFilter
  };
  const response = await getUserEventRolesBy(filter, page, sort);
  return {
    ...response,
    data: formatVolunteers(response.data)
  };
};

export const getAllVolunteerEventRolesById = async (userId) => {
  const filter = {
    'role.id': `ne:${COACH_ID}`,
    'user.id': `eq:${userId}`
  };
  const { data } = await getAllUserEventRolesBy(filter);
  return data.map(({ id }) => id);
};

export const getVolunteerRolesByEventId = async (eventId, page, searchFilter) => {
  if (!eventId) {
    return emptyResponse;
  }
  const filter = {
    ...searchFilter
  };
  const response = await getEventVolunteers(eventId, filter, page);
  return {
    ...response,
    data: formatVolunteersNew(response.data, response.included)
  };
};

export const getUserEventRolesByUserId = async (userId) => {
  const filter = { 'user.id': `eq:${userId}` };
  return await getAllUserEventRolesBy(filter);
};

export const getUserEventRoleById = async (roleId) => {
  const url = `${baseUrl}?filter[role.id]=eq:${roleId}`;
  const { data } = await API.GetInstance().http.get(url);
  return data;
};

/**
 * @param {userId, eventId, roleTypeId} payload 
 */
export const createUserEventRole = async (payload) => {
  const body = formatCreateEventRoleBody(payload);
  const { data } = await API.GetInstance().http.post(baseUrl, body);
  return data;
};

const requestEventRolesCommands = async (body) => {
  const url = `${baseUrl}/commands`;
  const { data } = await API.GetInstance().http.post(url, body);
  return data;
};

export const sendInvitations = async (assignmentIds) => {
  const body = formatSendInvitationsBody(assignmentIds);
  return await requestEventRolesCommands(body);
};

export const verifyInvitation = async (code, email) => {
  const body = formatVerifyInvitationBody(code, email);
  return await requestEventRolesCommands(body);
};

export const assignRole = async (userId, password) => {
  const body = formatAssignRoleBody(userId, password);
  return await requestEventRolesCommands(body);
};

export const getUserEventRolesByUserIdAndEventId = async (userId, eventId) => {
  if (!userId || !eventId) {
    return emptyResponse;
  }
  const filter = {
    'event.id': `eq:${eventId}`,
    'user.id': `eq:${userId}`
  };
  return await getAllUserEventRolesBy(filter);
};

export const addTeamsToUserEventRoleAssignment = async (userEventRoleAssignmentId, payload) => {
  const url = `${baseUrl}/${userEventRoleAssignmentId}/relationships/teams`;

  // const body = formatCreateEventRoleBody(payload);
  const { data } = await API.GetInstance().http.post(url, payload);
  return data;
}

export const removeTeam = async (eventId, teamId) => {
  const url = `${baseUrlEvent}/${eventId}/teams/${teamId}`;
  const res = await API.GetInstance().http.delete(url, {});
  return res;
}


export const removeTeamFromCoach = async (userEventRoleAssignmentId, payload) => {
  const url = `${baseUrl}/${userEventRoleAssignmentId}/relationships/teams`;
  const res = await API.GetInstance().http.delete(url, { data: payload });
  return res;
}

export const removeUserEventRole = async (userEventRoleAssignmentId) => {
  const url = `${baseUrl}/${userEventRoleAssignmentId}`;
  const res = await API.GetInstance().http.delete(url);
  return res;
}

export const removeMultiUserEventRoles = async (userEventRoleAssignmentIds) => {
  return await parallelRequest(userEventRoleAssignmentIds.map((assignmentId) => {
    return API.GetInstance().http.delete(`${baseUrl}/${assignmentId}`);
  }))
}
