import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../../styles/eventScoringMatch/RefereeScoresheetNotes.scss';
import PlusCircle from 'shared/components/Icon/icons/PlusCircle';
import CommentTile from 'shared/components/CommentTile';
import {Button} from 'antd';
import Icon from '@ant-design/icons';
import {connect} from 'react-redux';
import {deleteRefereeNote} from 'features/EventOverviewContainer/redux/actions/matchScoresheet'
import AddRefereeNote from './AddRefereeNoteModal';

const titleClassName = `h-2 heading--charcoal m-b-8`;
const paragraphClassName = 'paragraph--medium paragraph--charcoal paragraph--normal'


const RefereeNotes = props => {
  const [visible, setVisible] = useState(false);
  const [commentId, setCommentId] = useState(undefined);
  const { t } = useTranslation()

  const { matchScoreComments, deleteNote, scoreInputId, isPublished, extraClass } = props;
  const openModal = (id) => {
    id && setCommentId(id)
    setVisible(true)
  }

  const closeModal = (id) => {
    id && setCommentId(undefined)
    setVisible(false)
  }

  const deleteComment = (id) => {
    deleteNote(id)
  }

  let subtitle = '';
  if (isPublished) {
    subtitle = t('Notes cannot be added to a published scoresheet.');
  } else if (!scoreInputId) {
    subtitle = t('The scoresheet must be saved before adding notes.');
  } else {
    subtitle = t('refereeNotesInstructionsLabel');

  }

  return (
    <div className="referee-scoresheet-notes__container">
      <AddRefereeNote visible={visible} onCancel={closeModal} commentId={commentId} extraClass={extraClass} />
      <div className="referee-scoresheet-notes__header">
        <div className="referee-scoresheet-notes__title">
          <h3 className={titleClassName}>{t('refereeNotesLabel')}</h3>
          <Button disabled={!scoreInputId || isPublished} onClick={openModal} type="link" className="referee-scoresheet-notes__add">
            {' '}
            <Icon className="m-r-8" component={PlusCircle} />
            {' '}
            {t('addNoteLabel')}
          </Button>
        </div>
        <p className={paragraphClassName}>
          {subtitle}
        </p>
      </div>
      <ul className="referee-scoresheet-notes__notes">
        {matchScoreComments.map((c) => (
          <li key={c.commentId} className="referee-scoresheet-notes__comment-wrapper">
            <CommentTile
              commentId={c.commentId}
              userId={c.refereeId}
              key={c.commentId}
              fullName={c.fullName}
              comments={c.comment}
              dateTime={c.dateTime}
              deleteClick={() => { deleteComment(c.commentId) }}
              onEdit={openModal}
              isEditable={false}
              disableEdit={isPublished}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

RefereeNotes.propTypes = {
  matchScoreComments: PropTypes.array.isRequired,
  deleteNote: PropTypes.func.isRequired,
  isPublished: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {
    matchScoresheet: {
      matchScoreComments,
      scoreInputId,
      isMatchScoresheetPublished
    }
  } = state;
  return {
    matchScoreComments,
    scoreInputId,
    isPublished: isMatchScoresheetPublished,
  }

}

export default connect(mapStateToProps, { deleteNote: deleteRefereeNote })(RefereeNotes);