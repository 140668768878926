import * as types from './eventMessageTypes';
import * as messageService from 'utils/services/message';
import { notification } from 'antd';

const getMessageChannelsBegin = (payload) => {
  return {
    type: types.GET_EVENT_MESSAGING_CHANNELS_BEGIN,
    payload
  };
};

const getMessageChannelsError = () => {
  return {
    type: types.GET_EVENT_MESSAGING_CHANNELS_FAIL
  };
};

const getMessageChannelsSuccess = (payload) => {
  return {
    type: types.GET_EVENT_MESSAGING_CHANNELS_SUCCESS,
    payload
  };
};

export const fetchMessageChannels = (eventId, scheduleId) => async (dispatch) => {
  try {
    dispatch(getMessageChannelsBegin(eventId));
    const data = await messageService.getEventMessageChannels(scheduleId);
    dispatch(getMessageChannelsSuccess(data));
  } catch (e) {
    dispatch(getMessageChannelsError());
  }
};

const saveMessageChannelBegin = (payload) => {
  return {
    type: types.SAVE_EVENT_MESSAGING_CHANNEL_BEGIN,
    payload
  };
};

const saveMessageChannelError = () => {
  return {
    type: types.SAVE_EVENT_MESSAGING_CHANNEL_FAIL
  };
};

const saveMessageChannelSuccess = (payload) => {
  return {
    type: types.SAVE_EVENT_MESSAGING_CHANNEL_SUCCESS,
    payload
  };
};

export const saveMessageChannel = (eventId, channel) => async (dispatch) => {
  try {
    dispatch(saveMessageChannelBegin());
    const { id } = channel;
    const res = !id
      ? await messageService.createMessageChannel({ eventId, ...channel })
      : await messageService.updateMessageChannel(channel);
    notification.success({
      message: 'Success',
      description: 'Messaging channel saved successful.'
    });
    dispatch(saveMessageChannelSuccess(res));
  } catch (e) {
    dispatch(saveMessageChannelError());
  }
};
