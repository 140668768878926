import React, {useState}  from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../../styles/ScheduleContainer/EventScheduleCard.scss';
import Icon from '@ant-design/icons';
import AlertIcon from '../../../../../shared/components/Icon/icons/AlertIcon.js'

import SessionModal from 'features/EventContainer/components/ScheduleContainer/SessionModal'
import {connect} from 'react-redux';
import * as userTypes from 'shared/constants/userTypes';
import * as eventRoleTypes from 'shared/constants/eventRoleTypes';
import OverlayLoading from 'shared/components/Spinner/OverlayLoading';

const EventScheduleCardIncomplete = (props) => {
  const [modalVisible, setModalVisible] = useState(false);

  const {
    sessionId,
    endTime,
    startTime,
    title,
    iconType,
    date,
    cb,
    userEventRoleIds,
    userType,
    time_zone,
    loading
  } = props;

  const { t } = useTranslation()
  const displayText = title || ''

  const closeModal = () => {
    setModalVisible(false);
    cb();
  }

  
  const canEditSchedule = userType === userTypes.FIRST_ADMIN ||
  userType === userTypes.PARTNER_ADMIN ||
  userEventRoleIds.includes(eventRoleTypes.EVENT_ADMIN_ID);
  
  const openModal = () => {
    if (canEditSchedule) {
      setModalVisible(true);
    }
  }

  return (
    <>
      <SessionModal time_zone={time_zone} showPanelInfo={false} iconType={iconType} sessionId={sessionId} visible={modalVisible} onCancel={closeModal} />
      <button disabled={!canEditSchedule} type='button' className='event-schedule-card-btn' onClick={openModal}>

        <div className='event-schedule-card'>
          <div className='event-schedule-card__rectangle event-schedule-card__rectangle--incomplete'>
            <Icon component={AlertIcon}  className='event-schedule-card__alert-icon' />
          </div>
          <div className='event-schedule-card__data event-schedule-card__data--incomplete'>
            <p className='paragraph--default paragraph--bold paragraph--charcoal'>{displayText}</p>
            <div className='event-schedule-card__data__date-time'>
              {date ? (
                <p className='paragraph--medium paragraph--charcoal'>{date}</p>
          ) : (
            <p className='paragraph--medium paragraph--red'>{t('setDateLabel')}</p>
          ) }
              {(endTime && startTime) ? (
                <p className='paragraph--medium paragraph--charcoal'>
                  {startTime}
                  {' '}
                  -
                  {' '}
                  {endTime}
                </p>
          ) : (
            <p className='paragraph--medium paragraph--red'>{t('setTimeLabel')}</p>

          )}
            </div>
          </div>
          <OverlayLoading loading={loading}/>
        </div>
      </button>
    </>
  );
};

EventScheduleCardIncomplete.propTypes = {
  title: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  sessionId: PropTypes.string,
  iconType: PropTypes.string,
  date: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  cb: PropTypes.func,
  userEventRoleIds: PropTypes.array.isRequired,
  userType: PropTypes.string.isRequired
}

EventScheduleCardIncomplete.defaultProps = {
  startTime: undefined,
  endTime: undefined,
  sessionId: '',
  iconType: 'opening',
  date: undefined,
  cb: () => {}
}

const mapStateToProps = (state) => {
  const { event: { eventDetails }, auth: { userType } } = state;
  const {
    userEventRoleIds,
    time_zone
  } = eventDetails
  return { userEventRoleIds, userType, time_zone };
};

export default connect(mapStateToProps)(EventScheduleCardIncomplete);