const ip1Section = {
  id: 'IP1',
  label: 'ipv1Label',
  title: 'identifyLabel',
  description: 'identifyDescriptionLabel',
  fields: [
    {
      legendTitle: 'ChallengeRubricsProblemLabel',
      id: 'innovation_project_1_identify_problem',
      isCoreValue: false,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricsProblem1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricsProblem2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricsProblem3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricsProblem4Label',
      }]
    },
    {
      legendTitle: 'ChallengeRubricsResearchLabel',
      id: 'innovation_project_1_identify_research',
      isCoreValue: true,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricsResearch1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricsResearch2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricsResearch3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricsResearch4Label',
      }]
    },
  ]
}

export default ip1Section;