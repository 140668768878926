import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Input from 'shared/components/Input';
import Dropdown from 'shared/components/FormDropdown';
import {
  fetchRegionsBy,
  fetchCountries
} from 'features/AdminDashboard/redux/actions/programsActions';
import '../../../styles/CreateNewTeamForm.scss';
import { Button, Form, Select } from 'antd';

const { Option } = Dropdown;
const FormItem = Form.Item;

const CreateNewTeamForm = (props) => {
  const {
    count,
    setCount,
    eventProgramId,
    regions,
    countries,
    isFetchingCountries,
    isFetchingRegions,
    withCoach
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const values = form.getFieldsValue();
  const { teamName, teamNumber, country, region } = values||{};
  const regionsOptions = regions.map(region => ({
    value: region.id,
    label: region.name
  }));

  const countriesOptions = countries.map(country => ({
    value: country.id,
    label: country.name
  }));

  const [teamFormInvalid, setTeamFormInvalid] = useState(!teamName || !teamNumber || !country || !region);
  
  const handleTeamFormInvalid = ()=>{
    const values = form.getFieldsValue();
    const { teamName, teamNumber, country, region } = values||{};
    if (!teamName || !teamNumber || !country || !region) setTeamFormInvalid(true)
    else setTeamFormInvalid(false)
  }

  const handleSearchRegions = (input) => {
    if (!input) return;
    props.fetchRegionsBy(eventProgramId, input);
  };

  const handleAddTeam = () => {
    form.validateFields().then(async (values) => {
      const team = values;
      team.id = count;
      setCount(count-1);
      const {country, region} = team;
      const countryName = (countries.find(c=>c.id == country)||{}).name;
      const regionName = (regions.find(c=>c.id == region)||{}).name;
      props.onSubmit(withCoach ? [{...team, countryName, regionName}] : [...props.selectedTeam, {...team, countryName, regionName}]);
      form.resetFields();
      setTeamFormInvalid(true);
    }).then(err => {console.log(err)});
  }
  
  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  }

  return (
    <div className={`create-team`}>
      <Form hideRequiredMark name='CreateNewTeamForm' onFinishFailed={onFinishFailed} form={form}>
        <FormItem name='teamName' rules={[{ required: true, message: 'Enter Team Name.' }]}>
          <Input size='large' onChange={handleTeamFormInvalid} placeholder={t('teamNameLabel')} allowClear/>
        </FormItem>
        <FormItem name='teamNumber' rules={[{ required: true, message: 'Enter Team Number.' }]}>
          <Input size='large' onChange={handleTeamFormInvalid} placeholder={t('teamNumberLabel')} allowClear/>
        </FormItem>
        <FormItem name='country' rules={[{ required: true, message: 'Select Country.' }]}>
          <Select  
            showSearch
            placeholder='Country' 
            options={countriesOptions} 
            onChange={handleTeamFormInvalid}
            onSearch={props.fetchCountries}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            getPopupContainer={() => document.getElementById('country-autocomplete')}
          />
        </FormItem>
        <FormItem name='region' rules={[{ required: true, message: t('selectRegionLabel') }]}>
          <Select  
            showSearch
            placeholder='Region' 
            options={regionsOptions} 
            onSearch={(input) => {
              if (!input) return;
              props.fetchRegionsBy(eventProgramId, input);
            }}
            onChange={handleTeamFormInvalid}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            getPopupContainer={() => document.getElementById('region-autocomplete')}
          />
        </FormItem>
        <div className='add-button'>
          <Button onClick={handleAddTeam} disabled={teamFormInvalid} type='primary'>{t('addTeamLabel')}</Button>
        </div>
      </Form>
    </div>
  );
};

CreateNewTeamForm.propTypes = {
  onCancel: PropTypes.func,
  eventProgramId: PropTypes.string,
  fetchRegionsBy: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func.isRequired,
};

CreateNewTeamForm.defaultProps = {
  onCancel: () => { }
};

const mapDispatchToProps = {
  fetchRegionsBy,
  fetchCountries
};

const mapStateToProps = (state) => {
  const {
    event: {
      eventDetails: {
        eventProgramId
      }
    },
    adminBoard: {
      programs: {
        countries, regions, isFetchingRegions, isFetchingCountries
      }
    }
  } = state;
  return {
    eventProgramId,
    countries,
    regions,
    isFetchingRegions,
    isFetchingCountries
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewTeamForm);
