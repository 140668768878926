import * as userService from 'utils/services/users';
import * as teamService from 'utils/services/teams';
import * as eventRoleService from 'utils/services/eventRoles';
import { notification } from 'antd';
import { COACH_ID } from 'shared/constants/eventRoleTypes';
import * as types from '../../types/teamsTypes';
import Team from 'models/Team';
import _ from 'lodash';
import { editEvent } from 'utils/services/events';
import { setAddedTeamList, updateAddedTeamList } from './addTeam';
import { getTeamId, setCoachesSuccessfullyInvited } from './import';

const setSelectedCoachBegin = () => {
  return {
    type: types.SET_SELECTED_COACH_BEGIN
  }
}
const setSelectedCoachError = () => {
  return {
    type: types.SET_SELECTED_COACH_ERROR
  }

}
const setSelectedCoachSuccess = (fieldName, value) => {
  return {
    type: types.SET_SELECTED_COACH_SUCCESS,
    payload: {
      fieldName, value
    }
  }
}

const updateSelectedCoach = (fieldName, value) => {
  return {
    type: types.UPDATE_SELECTED_COACH,
    payload: {
      fieldName, value
    }
  }
}

const addCoachToListBegin = () => {
  return {
    type: types.COACH_ADD_TO_LIST_BEGIN,
    payload: {
      isAddingCoachToList: true
    }
  }
}

const addCoachToListError = () => {
  return {
    type: types.COACH_ADD_TO_LIST_ERROR,
    payload: {
      isAddingCoachToList: false
    }
  }
}

const addCoachToListSuccess = (addCoachesManuallyList) => {
  return {
    type: types.COACH_ADD_TO_LIST_SUCCESS,
    payload: {
      isAddingCoachToList: false,
      addCoachesManuallyList
    }
  }
}

export const handleAddCoachToList = (payload) => {
  const { culture } = payload;
  return async (dispatch, getState) => {
    const { event: { eventTeams: { addCoachesManuallyList, selectedCoach } } } = getState();
    selectedCoach.culture = culture;
    try {
      const updatedList = [...addCoachesManuallyList, selectedCoach]
      await dispatch(addCoachToListBegin())
      await dispatch(addCoachToListSuccess(updatedList))
    } catch (err) {
      await dispatch(addCoachToListError())
      throw err;
    }
  }

}

export const inviteCoachBegin = () => {
  return {
    type: types.COACH_INVITE_BEGIN
  }
}

export const inviteCoachError = () => {
  return {
    type: types.COACH_INVITE_ERROR
  }
}
export const inviteCoachSuccess = () => {
  return {
    type: types.COACH_INVITE_SUCCESS
  }
}

export const inviteTeamBegin = () => {
  return {
    type: types.TEAM_INVITE_BEGIN
  }
}

export const inviteTeamError = () => {
  return {
    type: types.TEAM_INVITE_ERROR
  }
}
export const inviteTeamSuccess = () => {
  return {
    type: types.TEAM_INVITE_SUCCESS
  }
}

// helper/service method, not action
export const inviteCoach = async (userId, teams, eventId) => {
  try {
    const eventRoleFilter = {
      'user.id': `eq:${userId}`,
      'event.id': `eq:${eventId}`,
      'role.id': `eq:${COACH_ID}`
    }
    const eventRoleAssignmentRes = await eventRoleService.getUserEventRolesBy(eventRoleFilter, 50);
    const eventRoleData = eventRoleAssignmentRes.data;
    if (eventRoleData.length) {
      const eventRoleAssignmentId = eventRoleData[0].id
      const assignedTeams = teams.map(t => ({ type: 'team', id: t.id }));
      const addTeamPayload = {
        data: assignedTeams
      }

      await eventRoleService.addTeamsToUserEventRoleAssignment(eventRoleAssignmentId, addTeamPayload)
    } else {
      const data = await eventRoleService.createUserEventRole({ userId, teams, eventId });
      if (data && data.id) {
        await eventRoleService.sendInvitations([data.id]);
      }

    }

  } catch (err) {
    if (err && 'data' in err && err.data.statusCode === 451) {
      notification.error({
        message: 'Error',
        description: 'Volunteer has not been screened and cannot serve as coach or volunteer.',
      });
    }
    else if (err.status === 409) {
      notification.error({
        message: 'Error',
        description: 'The user has already been assigned the role of coach for this event.',
      });
    } else {
      notification.error({
        message: 'Error',
        description: 'Error sending the invitation.',
      });
    }
    throw(err);
    console.error(err);
  }
}
export const handleInviteTeamAndCoaches = (teams, coaches, cb = () => {}) => async (dispatch, getState) => {
  try {
    await dispatch(inviteCoachBegin())
    const {event: {eventDetails: {id, eventProgramId}, eventTeams: {eventTeamsListAll}}} = getState();
    const formattedTeams = [];
    for(let i=0; i<teams.length; i++){
      const team = teams[i];
      if(team?.id && team?.id < 0) {
        const {teamName, teamNumber, regionName, countryName} = team || {};
        const payload = {name: teamName, number: teamNumber, country: countryName, region: regionName};
        const teamId = await getTeamId(payload, eventProgramId);
        team.id = teamId;
      }
      formattedTeams.push(team)
    }

    const filteredList = formattedTeams.filter(team => !eventTeamsListAll.find(t => t.id === team.id));

    const teamIds = filteredList.map(t=>({type: 'team', id: t.id}))
    
    const payload = {
      data: {
        id: id,
        type: 'event',
        relationships: {
          teams: {
            data: teamIds
          }
        }
      }
    }
    await editEvent(id, payload);
  
    if(coaches.length > 0) {
      for (let i = 0; i < coaches.length; i++) {
        const coach = coaches[i];
        const userId = await userService.getOrCreateUserId(coach);
        if (userId) {
          await inviteCoach(userId, formattedTeams, id);
        }
      }
    }
    await dispatch(inviteCoachSuccess());
      cb && cb();
    const description = teams.length > 1 ? 'Teams successfully added.' : 'Team successfully added.'
    notification.success({
      message: 'Success',
      description: description,
    });
  } catch (err) {
    const description = teams.length > 1 ? 'Error sending invitations.' : 'Error sending invitation.';
    notification.error({
      message: 'Error',
      description: description,
    });
    await dispatch(inviteCoachError())
    throw err;
  }
}

export const handleInviteCoachNow = (coach, eventId, cb = () => { }) => async dispatch => {
  await dispatch(inviteCoachBegin());
  try {
    const userId = await userService.getOrCreateUserId(coach);
    if (userId) {
      await inviteCoach(userId, coach.teams, eventId);
      await dispatch(inviteCoachSuccess())
    }
    cb();
  } catch (err) {
    notification.error({
      message: 'Error',
      description: 'Error sending invitation',
    });
    await dispatch(inviteCoachError())
    throw err;
  }
}

export const handleInviteAllCoaches = (coaches, eventId, cb = () => { }) => async dispatch => {
  try {
    await dispatch(inviteCoachBegin())

    for (let i = 0; i < coaches.length; i++) {
      const coach = coaches[i];
      const userId = await userService.getOrCreateUserId(coach);
      if (userId) {
        await inviteCoach(userId, coach.teams, eventId);
      }
    }
    await dispatch(inviteCoachSuccess());
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Error',
      description: 'Error sending invitation',
    });
    await dispatch(inviteCoachError())
    throw err;
  }
}

export const inviteTeamToEvent = (team, cb = () => { }, showNotification = true) => async (dispatch, getState) => {
  try {
    await dispatch(inviteTeamBegin())
    const { teamId } = team;
    const { event: { eventDetails: { id } } } = getState();
    const payload = {
      data: {
        id: id,
        type: 'event',
        relationships: {
          teams: {
            data: [{ type: 'team', id: teamId }]
          }
        }
      }
    }
    await editEvent(id, payload);
    await dispatch(inviteTeamSuccess());
    await dispatch(setSelectedTeamDetails(undefined));
    await dispatch(updateAddedTeamList([]));
    cb && cb();
  } catch (err) {
    if (showNotification) {
      notification.error({
        message: 'Error',
        description: 'Error sending invitation',
      });
    }
    await dispatch(inviteTeamError())
    throw err;
  }
}


export const inviteTeamsOnlyFromImport = (teams, cb = () => { }) => async (dispatch, getState) => {
  try {
    const { event: { eventTeams: { eventTeamsListAll }, eventDetails: { id, eventProgramId } } } = getState();
    await dispatch(inviteCoachBegin())
    const teamIds = [];
    for(let i = 0; i<teams.length; i++){
      const data = teams[i];
      const {TeamNumber, TeamName, TeamCountry, TeamRegion} = data||{};
      const payload = {name: TeamName, number: TeamNumber, country: TeamCountry, region: TeamRegion};
      const teamId = await getTeamId(payload, eventProgramId);
      teamIds.push({id: teamId, data});
    }
    const uniqTeams = _.uniq(teamIds, 'id');
    const newTeams = [];
    const existingTeams = []
    uniqTeams.forEach(team=>{
      const existsInEventTeam = eventTeamsListAll.find(t => t.id == team.id);
      if(existsInEventTeam){
        existingTeams.push(team);
      }else{
        newTeams.push(team);
      }
    })
    const teamsPayload = newTeams.map(team => ({
      id: team.id,
      type: 'team',
    }))
    const payload = {
      data: {
        id: id,
        type: 'event',
        relationships: {
          teams: {
            data: teamsPayload
          }
        }
      }
    }
    teamsPayload.length > 0 && await editEvent(id, payload);
    const coachesInvited = newTeams.map(t=>t.data);
    const coachesNotInvited = existingTeams.map(t=>{t.data.existing = true; return t.data});
    await dispatch(setCoachesSuccessfullyInvited(coachesInvited, [], coachesNotInvited))
    await dispatch(inviteCoachSuccess());
    await dispatch(setSelectedTeamDetails(undefined));
    await dispatch(updateAddedTeamList([]));
    notification.success({
      message: 'Success',
      description: newTeams.length > 1 ? 'Teams successfully added.': 'Team successfully added.',
    });
    cb && cb();
  } catch (err) {
    const {error, team} = err || {};
    let description = 'Error sending invitation';
    if(error == 'Wrong Country'){
      description = `You tried to create a team (${team.name}-${team.number}) in country ${team.country}. We currently do not support creating teams for this country. Please try with another country. (Remember countries and regions are only supported in English)`;
    } else if (error == 'Wrong Region'){
      description = `You tried to create a team (${team.name}-${team.number}) in region ${team.region}. We currently do not support creating teams for this region. Please try with another region. (Remember countries and regions are only supported in English)`;
    }
    notification.error({
      message: 'Error',
      description: description,
    });
    await dispatch(setCoachesSuccessfullyInvited([], [], teams))

    await dispatch(inviteCoachError())
    throw err;
  }
}

export const handleInviteAllTeamsWithoutCoaches = (cb = () => { }) => async (dispatch, getState) => {
  try {
    await dispatch(inviteTeamBegin())
    const { event: { eventTeams: { eventTeamsListAll, addedTeamList }, eventDetails: { id } } } = getState();
    const addedList = addedTeamList.map(a => ({ type: 'team', id: a.teamId }));
    const filteredList = addedList.filter(team => !eventTeamsListAll.find(t => t.id === team.id));
    const teamsPayload = filteredList.map(team => ({
      id: team.id,
      type: 'team',
    }))
    const payload = {
      data: {
        id: id,
        type: 'event',
        relationships: {
          teams: {
            data: teamsPayload
          }
        }
      }
    }
    
    await editEvent(id, payload);
    await dispatch(inviteTeamSuccess());
    await dispatch(setSelectedTeamDetails(undefined));
    await dispatch(updateAddedTeamList([]));
    notification.success({
      message: 'Success',
      description: filteredList.length > 1 ? 'Teams successfully added.': 'Team successfully added.',
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Error',
      description: 'Error sending invitation',
    });
    await dispatch(inviteTeamError())
    throw err;
  }
}

export const handleUpdateSelectedCoach = (fieldName, value) => {
  return async dispatch => {
    await dispatch(updateSelectedCoach(fieldName, value))
  }
}

export const handleSetSelectedCoach = (fieldName, value) => {
  return async dispatch => {
    try {
      await dispatch(setSelectedCoachBegin());
      await dispatch(setSelectedCoachSuccess(fieldName, value))

    } catch (err) {
      await dispatch(setSelectedCoachError())
      throw err;
    }
  }
}

export const setSelectedTeam = (selectedTeam) => {
  return {
    type: types.SET_SELECTED_TEAM_FOR_COACH_SUCCESS,
    payload: {
      selectedTeam
    }
  }
}

export const setSelectedTeamDetails = (selectedTeamDetails) => {
  return {
    type: types.SET_SELECTED_TEAM_DETAILS,
    payload: {
      selectedTeamDetails
    }
  }
}

export const handleSelectedTeam = (teamId, cb = () => { }) => {
  return async (dispatch) => {
    try {
      const team = await teamService.getTeamDetails(teamId);
      let teamObject = new Team(team.data, team.included)
      await dispatch(setSelectedTeam(teamId))
      await dispatch(setSelectedTeamDetails(teamObject));
      cb && cb(teamObject)
    } catch (err) {
      throw err
    }

  }
}

export const handleAddTeamToCoach = (cb = () => { }) => {
  return async (dispatch, getState) => {
    const { event: { eventTeams: { selectedTeam, selectedCoach } } } = getState();
    // TODO: check the id of the selected coach and the id of the selected team.
    // TODO: check that a coach is selected and confirm that the team is not already added to the coach.
    // add the team to the teams array of the selected coach

    const team = await teamService.getTeamById(selectedTeam);
    const updatedTeams = [...selectedCoach.teams, team];
    console.log("updatedTeams", updatedTeams);
    await dispatch(updateSelectedCoach('teams', updatedTeams));
    cb();
  };
};

export const handleAddTeam = (data) => {
  return async (dispatch) => {
    const team = await teamService.createNewTeamWithoutFormat(data)
    await dispatch(setSelectedTeamDetails(team));
    await dispatch(setAddedTeamList());
  };
};

const setIsAddTeamDisabled = (isAddTeamDisabled) => {
  return {
    type: types.SET_IS_ADD_TEAM_DISABLED,
    payload: {
      isAddTeamDisabled
    }
  };
};

export const handleIsAddTeamDisabled = (isDisabled) => {
  return async (dispatch) => {
    await dispatch(setIsAddTeamDisabled(isDisabled))
  }
}

const resetSelectedTeam = () => {
  return {
    type: types.RESET_SELECTED_TEAM_FOR_COACH
  }
}

const resetSelectedCoach = () => {
  return {
    type: types.RESET_SELECTED_COACH
  }
}

export const handleResetSelectedTeam = () => {
  return async (dispatch) => {
    await dispatch(resetSelectedTeam())
  }
}

export const handleResetSelectedCoach = () => {
  return async (dispatch) => {
    await dispatch(resetSelectedCoach())
  }
}

const resetManuallyAddedCoachesList = () => {
  return {
    type: types.RESET_MANUALLY_ADDED_COACHES_LIST
  }
}

export const handleResetManuallyAddedCoachesList = () => {
  return async (dispatch) => {
    await dispatch(resetManuallyAddedCoachesList())
  }
}

const setSelectedCoachInAutocomplete = (selectedCoach) => {
  return {
    type: types.SET_SELECTED_COACH_IN_AUTOCOMPLETE,
    payload: {
      selectedCoach
    }
  }
}

export const handleSetCoachInAutocomplete = (coach) => {
  return async (dispatch) => {
    try {
      await dispatch(setSelectedCoachInAutocomplete(coach))
    } catch (err) {
      throw err
    }
  }
}


const resetSelectedCoachInAutocomplete = () => {
  return {
    type: types.RESET_SELECTED_COACH_IN_AUTOCOMPLETE,
  }
}

export const handleResetCoachInAutocomplete = () => {
  return async (dispatch) => {
    await dispatch(resetSelectedCoachInAutocomplete())
  }
}

const resetAddCoachTeamModal = () => {
  return {
    type: types.RESET_ADD_COACH_TEAM_MODAL
  }
}

const resetAddCoachOnlyModal = () => {
  return {
    types: types.RESET_ADD_COACH_ONLY_MODAL
  }
}

export const handleResetAddCoachOnly = () => {
  return async (dispatch) => {
    await dispatch(resetAddCoachOnlyModal())
  }
}

const resetSelectedTeamDetails = () => {
  return {
    type: types.SET_SELECTED_TEAM_DETAILS_RESET
  }
}

export const handleResetAddCoachTeamModal = () => {
  return async dispatch => {
    await dispatch(resetAddCoachTeamModal())
  }
}

export const handleResetSelectedTeamDetails = () => {
  return async dispatch => {
    await dispatch(resetSelectedTeamDetails())
  }
}
