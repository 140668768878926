import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SingleEvent from 'models/Event';
import '../../../features/EventContainer/styles/EventModalHeader.scss';

const ModalHeader = (props) => {
  const {
    eventOverview
  } = props;

  const {
    eventIncluded,
    eventData,
  } = eventOverview;

  const eventPage = new SingleEvent(eventData, eventIncluded);
  const { name } = eventPage;
  const eventDate = eventPage.parseStartEndTimeShortenedString();
  const eventLocation = eventPage.getEventLocation();

  return (
    <div className='event-modal-header'>
      <p className='paragraph--white  event-modal-header__text'>{name}</p>
      <div className="event-date-wrapper">
      <span className='event-modal-header__point'>&#8226;</span>
      <p className='paragraph--white event-modal-header__text event-modal-header__text--right'>
        {eventDate}
      </p>
      </div>
      <div className="event-location-wrapper">
      <span className='event-modal-header__point'>&#8226;</span>
      <p className='paragraph--white event-modal-header__text event-modal-header__text--right'>
        {eventLocation}
      </p>
      </div>
    </div>
  );
};

ModalHeader.propTypes = {
  eventOverview: PropTypes.object,
};

ModalHeader.defaultProps = {
  eventOverview: {},
};

const mapStateToProps = (state) => {
  const { eventOverview } = state;
  return { eventOverview }
};

export default connect(mapStateToProps)(ModalHeader);
