import React from 'react';
import PropTypes from 'prop-types';
import SessionCard from 'features/EventOverviewContainer/components/EventOverviewSchedule/EventOverviewSessionCard';


const EventOverviewDaySessions = props => {
  const {
    sessions,
    handleTabClick
  } = props;

  return (
    <div>
      {sessions.map(s => (
        <SessionCard id={s.id} key={s.id} type={s.type} data={s} handleTabClick={handleTabClick}/>
      ))}

    </div>
  );
};

EventOverviewDaySessions.propTypes = {
  sessions: PropTypes.array
};

EventOverviewDaySessions.defaultProps = {
  sessions: []
}

export default EventOverviewDaySessions;