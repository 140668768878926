import { Row, Col } from 'antd';
import ExclamationCircleOutlined from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Paragraph from 'shared/components/Paragraph';
import FileCard from 'shared/components/FileCard';
import CustomLink from 'shared/components/Link';
import { getSponsorFile } from 'utils/services/eventFiles';
import get from 'lodash/get'
import find from 'lodash/find'

const rowProps = {
  type: 'flex',
  gutter: 60
};

const SponsorCard = (props) => {
  const { id, index, name, editSponsor, fileStatus } = props;
  const { t } = useTranslation()
  const [url, setUrl] = useState('')

  useEffect(() => {
    const getFile = async () => {
      try {
        const response = await getSponsorFile(id);
        const presignedUrlObject = response.included && find(response.included, res => res.type === 'pre-signed_url')
        const presignedUrl = get(presignedUrlObject, ['attributes', 'url'], '')
        setUrl(presignedUrl);
      } catch (e) {
        // console.log(e)
        if (e?.status == '404') {
          // hideErrorImage && setError('not found') 
        }
      }
    };
    getFile();
  }, [id, fileStatus]);

  const getStatus = () => {
    let matchedStatus = fileStatus.filter((data) => data.sponsorId == id)
    return matchedStatus[0] !== undefined ? matchedStatus[0] : null
  }

  return (
    <div className='c-event-sponsors__card'>
      <div className='c-event-sponsors__card-header'>
        <Paragraph size='large' color='white'>Sponsor {index}</Paragraph>
        <Paragraph size='large' color='white' onClick={() => editSponsor(id)}>
          {t('editLabel')}
        </Paragraph>
      </div>
      <div className='c-event-sponsors__card-content'>
        <Row {...rowProps}>
          <Col xs={24} md={10}>
            <Paragraph size='large' weight='bold' className='m-b-8'>
              {t('sponsorNameLabel')}
            </Paragraph>
            <div className='c-event-sponsors__sponsor-name'>
              <Paragraph size='large'>{name}</Paragraph>
            </div>
          </Col>
          <Col xs={24} md={14}>
            <Paragraph size='large' weight='bold' className='m-b-8'>
              {t('sponsorLogoLabel')}
            </Paragraph>
            <div className='c-event-sponsors__sponsor-logo'>
              
              <FileCard status={getStatus()} imageUrl={url} iconType='image' fileName={`${name}.jpg`} wide />
              <Paragraph size='medium' className='c-event-sponsors__image-instruction'>
                {t('imageInstructionTextLabel')}
                <div className='m-t-10'>
                  {(getStatus() != null && getStatus()?.fileStatus == 3) && (<div>
                    <ExclamationCircleOutlined style={{ fontSize: '24px', color: '#C50E14' }} theme="outlined" />
                    <div className='danger m-t-10' style={{fontSize: '14px'}}>{t('sponsorUploadFailedLabel')}</div>
                  </div>)}
                </div>
              </Paragraph>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SponsorCard;
