import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InviteTile from './InviteTile';

const InviteList = props => {
  const { t } = useTranslation()
  const {resetTab, volunteers, errors, onDelete} = props;
  return (
    
    <div className='import__list-wrapper'>
      <p className='paragraph--darkGray paragraph--small'>{`${t('willBeInvitedLabel')}:`}</p>
      <div className={errors ? 'import__list import__list--errors' : 'import__list'}>
        {volunteers.map (v => (
          <InviteTile errors={v.errors} name={`${v.FirstName} ${v.LastName}`} email={v.Email} role={v.EventRole} key={`${v.email}-${v.id}`} id={v.id} resetTab={resetTab} onDelete={onDelete}/>
      ))}
      </div>
    </div>
  );
};

InviteList.propTypes = {
  volunteers: PropTypes.array,
  resetTab: PropTypes.func,
  errors: PropTypes.bool
};

InviteList.defaultProps = {
  volunteers: [],
  resetTab: () => {},
  errors: false
}

export default InviteList;