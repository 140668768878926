import React from 'react';
import PropTypes from 'prop-types';
import './heading.scss';

const tags = [1, 2, 3, 4, 5, 6, '1', '2', '3', '4', '5', '6'];
const types = ['primary'];
const colors = ['black', 'white', 'charcoal'];

const Heading = props => {
  const {
    children,
    tag,
    type,
    color,
    weight,
    textColor,
    additionalClassNames,
  } = props;

  const HeadingTag = `h${tag}`
  const className = `
    h-${tag}
    h--${weight}
    heading--${type || textColor || color}
    ${additionalClassNames}
  `;

  return (
    <HeadingTag className={className}>
      {children}
    </HeadingTag>
  );
};

Heading.propTypes = {
  tag: PropTypes.oneOf(tags),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  additionalClassNames: PropTypes.string,
  color: PropTypes.oneOf(colors),
};

Heading.defaultProps = {
  additionalClassNames: '',
  tag: 1,
  weight: 'bold',
  type: '',
  textColor: '',
  color: 'charcoal'
};

export default Heading;
