import React from 'react';
import PropTypes from 'prop-types';
import { Tabs as AntTabs } from 'antd';
import './styles/modal-tabs.scss';


const Tabs = props => {
  const {children, overrides} = props;
  return (
    <AntTabs className='modal-tabs' gutter={48} animated={false} {...overrides}>
      {children}
    </AntTabs>
  );
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  overrides: PropTypes.object
};

Tabs.defaultProps = {
  overrides: {}
}

Tabs.TabPane = AntTabs.TabPane;

export default Tabs;