import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'shared/components/EventModal';
import {connect} from 'react-redux';
import ModalHeader from '../../ModalHeader';
import JudgingFeedbackForm from './JudgingFeedbackForm';
import { useTranslation } from 'react-i18next';

const JudgingFeedbackModal = props => {
  const {
    visible,
    onCancel,
    programId,
    isGreatJobComment,
    isThinkAboutComment,
    commentSection,
    categoryLabel,
    helperText,
    commentId,
    extraClass
  } = props;

  const {t} = useTranslation();

  const formTitle = isGreatJobComment ? t('greatJobLabel') : t('thinkAboutLabel');
  const commentType = isGreatJobComment ? 'positive' : 'negative';
  
  return (
    <Modal
      onCancel={onCancel}
      visible={visible}
      programId={programId}
      renderHeader={() => <ModalHeader />}
      extraClass={extraClass}
    >
      <JudgingFeedbackForm
        categoryLabel={categoryLabel}
        helperText={helperText}
        formTitle={formTitle}
        commentSection={commentSection}
        commentType={commentType}
        commentId={commentId}
        callback={onCancel}
      />
    </Modal>
  );
};

JudgingFeedbackModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  isGreatJobComment: PropTypes.bool,
  isThinkAboutComment: PropTypes.bool,
  commentSection: PropTypes.string,
  commentId: PropTypes.string,
};

JudgingFeedbackModal.defaultProps = {
  isGreatJobComment: false,
  isThinkAboutComment: false,
  commentSection: ''
}
const mapStateToProps = (state) => {
  const {judgingRubric: {eventDetails}} = state;
  const {
    programId
  } = eventDetails
  return {
    programId
  }
}

export default connect(mapStateToProps)(JudgingFeedbackModal);