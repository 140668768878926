import React, {useState} from 'react';
import PropTypes from 'prop-types';
import '../../../../../../shared/components/Scoresheet/styles/MatchScoresheetForm.scss';
import moduleOptions from 'shared/constants/scoresheetOptions/m03';
import {Radio} from 'antd';
import { useTranslation } from 'react-i18next';

const ERROR_MESSAGE = 'Only one question in M03 can be Yes when there is 1 slide figure';

const moduleNameClassName = `h-2 heading--white`;
const moduleTitleClassName = `h-2 heading--charcoal`;
const paragraphClassName = 'paragraph--large paragraph--charcoal paragraph--normal'
const errorClassName = 'paragraph--large paragraph--dark-red paragraph--normal'

const questionOne = moduleOptions.fields[0];
const questionTwo = moduleOptions.fields[1];
const questionThree = moduleOptions.fields[2];

const ModuleSection = props => {

  const {t} = useTranslation();
  const [form] = Form.useForm();
  const {moduleName, moduleTitle, fields, scoreInput} = props;
  const [questionTwoDisabled, setQuestionTwoDisabled] = useState(false);
  const [moduleError, setModuleError] = useState('')

  const handleQuestionOneChange = (e) => {
    const {value} = e.target;
    if (!value) {
      setQuestionTwoDisabled(true)
      form.setFieldsValue({'m03_scoring_2': false})
      form.setFieldsValue({'m03_scoring_3': false})
    }
    if (value) {
      setQuestionTwoDisabled(false)
    }
    if (value === 2) {
      setModuleError('')

    }
  }

  const validateQuestionOne = (rule, value, callback) => {
    const questionTwoValue = form.getFieldValue(questionTwo.id);
    const questionThreeValue = form.getFieldValue(questionThree.id)
    if (value === 1 && questionTwoValue && questionThreeValue) {
      setModuleError(ERROR_MESSAGE)
      callback(ERROR_MESSAGE);
    } else {
      setModuleError('')
      callback();
    }
  }

  const validateQuestionTwo= (rule, questionTwoValue, callback) => {
    const questionOneValue = form.getFieldValue(questionOne.id);
    const questionThreeValue = form.getFieldValue(questionThree.id)

    if (questionOneValue === 1 && questionTwoValue && questionThreeValue) {
      setModuleError(ERROR_MESSAGE);
      callback(ERROR_MESSAGE);
    } else {
      setModuleError('')
      callback()
    }
  }

  const validateQuestionThree= (rule, questionThreeValue, callback) => {
    const questionOneValue = form.getFieldValue(questionOne.id);
    const questionTwoValue = form.getFieldValue(questionTwo.id)

    if (questionOneValue === 1 && questionTwoValue && questionThreeValue) {
      setModuleError(ERROR_MESSAGE);
      callback(ERROR_MESSAGE);
    } else {
      setModuleError('')
      callback()

    }
  }

  return (
    <div className="scoresheet-module">
      <div className="scoresheet-module__name">
        <h3 className={moduleNameClassName}>{moduleName}</h3>
      </div>

      <div className="m-l-30 m-t-8 scoresheet-module__content">
        <h2 className={moduleTitleClassName}>{moduleTitle}</h2>

        <div className="scoresheet-module__fields-wrapper">
          <div key={questionOne.id} className="scoresheet-module__field">
            <span className={`${paragraphClassName} scoresheet-module__field-label`}>
              {t(questionOne.scoring)}
              :
            </span>
            <span>
              {form.getFieldDecorator( questionOne.id, {
                initialValue: scoreInput[questionOne.id],
                rules: [
                  {
                    validator: validateQuestionOne
                  }
                ]
              }
                  )(
                    <Radio.Group
                      buttonStyle="solid"
                      className="match-scoresheet-form__radio-wrapper"
                      optionType="button"
                      onChange={(e) => handleQuestionOneChange(e, questionOne.id)}
                      disabled={scoreInput.is_published}
                    >
                      {questionOne.options.map((o) => (
                        <Radio.Button
                          buttonStyle="solid"
                          className="match-scoresheet-form__radio-btn"
                          key={`${questionOne.id}-${o.value}`}
                          value={o.value}
                        >
                          {t(o.description)}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  )}
            </span> 
          </div>

          <div key={questionTwo.id} className="scoresheet-module__field">
            <span className={`${paragraphClassName} scoresheet-module__field-label`}>
              {t(questionTwo.scoring)}
              :
            </span>
            <span>
              {form.getFieldDecorator( questionTwo.id, {
                initialValue: scoreInput[questionTwo.id],
                rules: [
                  {
                    validator: validateQuestionTwo
                  }
                ]
              }
                  )(
                    <Radio.Group
                      buttonStyle="solid"
                      className="match-scoresheet-form__radio-wrapper"
                      optionType="button"
                    >
                      {questionTwo.options.map((o) => (
                        <Radio.Button
                          buttonStyle="solid"
                          className="match-scoresheet-form__radio-btn"
                          key={`${questionTwo.id}-${o.value}`}
                          value={o.value}
                          disabled={questionTwoDisabled}

                        >
                          {t(o.description)}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  )}
            </span>
          </div>

          <div key={questionThree.id} className="scoresheet-module__field">
            <span className={`${paragraphClassName} scoresheet-module__field-label`}>
              {t(questionThree.scoring)}
              :
            </span>
            <span>
              {form.getFieldDecorator( questionThree.id, {
                initialValue: scoreInput[questionThree.id],
                rules: [
                  {
                    validator: validateQuestionThree
                  }
                ]
              }
                  )(
                    <Radio.Group
                      buttonStyle="solid"
                      className="match-scoresheet-form__radio-wrapper"
                      optionType="button"
                      disabled={scoreInput.is_published}
                    >
                      {questionThree.options.map((o) => (
                        <Radio.Button
                          buttonStyle="solid"
                          className="match-scoresheet-form__radio-btn"
                          key={`${questionThree.id}-${o.value}`}
                          value={o.value}
                          disabled={questionTwoDisabled}

                        >
                          {t(o.description)}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  )}
            </span>
          </div>




        </div>


        <p className={errorClassName}>{moduleError}</p>
      </div>
    </div>
  );
};

ModuleSection.propTypes = {
  moduleName: PropTypes.string,
  moduleTitle: PropTypes.string,
  fields: PropTypes.array,
  form: PropTypes.object.isRequired,
  scoreInput: PropTypes.object

};

ModuleSection.defaultProps = {
  moduleName: '',
  moduleTitle: '',
  fields: [],
  scoreInput: {}
};


export default (ModuleSection);