import * as types from './roleTypes';

const setSelectedDashboardRole = (selectedRoleName, selectedRoleId, selectedRolePath) => {
  return {
    type: types.SET_SELECTED_DASHBOARD_ROLE,
    payload: {
      selectedRoleName, selectedRoleId, selectedRolePath
    }
  }
}

export const setDashboardRole = (name, id, path) => {
  return async dispatch => {
    dispatch(setSelectedDashboardRole(name, id, path))
  }
}

const resetSelectedDashboardRole = () => {
  return {
    type: types.RESET_SELECTED_DASHBOARD_ROLE
  }
}

export const resetDashboardRole = () => {
  return async dispatch => {
    dispatch(resetSelectedDashboardRole())
  }
}