import SessionDocument from 'models/SessionDocument';
import * as sessionService from 'utils/services/sessions';
import {formatSessionDocumentsRes} from 'utils/services/sessions/model'
import * as types from '../../types/judgingRubric';


const getSessionDocumentsBegin = () => {
  return {
    type: types.GET_RUBRIC_JUDGING_SESSION_DOCUMENTS_BEGIN
  }
}

const getSessionDocumentsError = () => {
  return {
    type: types.GET_RUBRIC_JUDGING_SESSION_DOCUMENTS_ERROR
  }
}

const getSessionDocumentsSuccess = (evidenceFiles) => {
  return {
    type: types.GET_RUBRIC_JUDGING_SESSION_DOCUMENTS_SUCCESS,
    payload: {
      evidenceFiles
    }
  }
}

const getSessionDocuments = (sessionId) => {
  return async dispatch => {
    await dispatch(getSessionDocumentsBegin());
    try {
      const {data, included} = await sessionService.getSessionDocuments(sessionId);
      const documentsData = data.map(d => formatSessionDocumentsRes(new SessionDocument(d, included)));
      const evidenceFiles = []
      documentsData.forEach(d => {
        d.files.forEach(f => {
          evidenceFiles.push({
            ...f,
            sessionDocumentId: d.sessionDocumentId
          })
        })
      })
     
      await dispatch(getSessionDocumentsSuccess(evidenceFiles))
    } catch(err) {
      dispatch(getSessionDocumentsError())
      throw err;
    }

  }

}

export default getSessionDocuments