import * as types from '../types/teams';

const addNewTeamToList = (addTeamsToPanelList) => {
  return {
    type: types.ADD_NEW_TEAM_TO_PANEL_LIST,
    payload: {
      addTeamsToPanelList
    }
  }
}

const deleteTeamFromAddToPanelList = (addTeamsToPanelList) => {
  return {
    type: types.DELETE_TEAM_FROM_ADD_TO_PANEL_LIST,
    payload: {
      addTeamsToPanelList
    }
  }
}

export const resetAddToPanelList = () => {
  return {
    type: types.RESET_TEAMS_ADD_TO_PANEL_LIST,
  }
}


export const handleAddTeamToPanelList = (newTeam) => {
  return (dispatch, getState) => {
    const {panelManagement: {teams: {addTeamsToPanelList}}} = getState();

    const updatedList = [...addTeamsToPanelList, newTeam];
    dispatch(addNewTeamToList(updatedList));

  }
}

export const handleDeleteTeamFromAddList = (id) => {
  return (dispatch, getState) => {
    const {panelManagement: {teams: {addTeamsToPanelList}}} = getState();

    const updatedList = addTeamsToPanelList.filter(t => t.id !== id)
    dispatch(deleteTeamFromAddToPanelList(updatedList))
  }
}

export const handleResetTeamsAddToPanelList = () => {
  return dispatch => {
    dispatch(resetAddToPanelList())
  }
}