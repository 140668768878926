import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PageLayout from 'shared/components/Layouts/SubpageLayout';
import { connect } from 'react-redux';
import { getEventDetails, getSessionDetails, getJudgeComments, getJudgeCommentsForFLLE, getJudgingRubric, getJudgingRubricFLLE, getSessionDocuments, getSessionRubric } from 'features/EventOverviewContainer/redux/actions/judgingRubric';
import { useParams } from 'react-router-dom';
import * as programTypes from 'shared/constants/programIds';
import RubricHeader from './RubricHeader';
import RubricUpload from './RubricUploadForm';
import FLLChallenge from './FLLChallengeRubric';
import RubricEvidence from './RubricEvidence';
import RubricForm from './RubricForm';

const JudgingRubric = props => {
  const {
    pageDetails,
    getEvent,
    getSession,
    getComments,
    getFLLEComments,
    getDocuments,
    getRubric,
    getFLLERubric,
    getSessionRubricDetails,
    is_FLLC_event
  } = props;

  const {
    eventDetails,
    isFetchingSession,
    isFetchingEvent,
    isLoadingRubric,
    isFetchingRubricEvidenceDocuments,
    isLoadingSessionRubric,
    teamDetails,
    sessionDetails,
  } = pageDetails;

  const {
    teamName,
    teamNumber
  } = teamDetails

  const { programId } = eventDetails;
  const teamNameNumber = teamNumber ? `${teamNumber} - ${teamName}` : `${teamName}`;
  const { digital_rubric } = sessionDetails;

  const { eventId, sessionId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (eventId) {
      getEvent(eventId);
    }
  }, [eventId])

  useEffect(() => {
    if (sessionId && programId) {
      getSession(sessionId);
      getDocuments(sessionId);

      if (programId !== programTypes.FLL_CHALLENGE_ID || programId !== programTypes.FLL_EXPLORE_ID ) {
        getSessionRubricDetails(sessionId);
      }
    }
  }, [sessionId, programId])

  useEffect(() => {
    if (digital_rubric && programId) {

      if(programId === programTypes.FLL_EXPLORE_ID){
        getFLLEComments(digital_rubric)
      }else{
        getComments(digital_rubric);
      }

      if (programId === programTypes.FLL_CHALLENGE_ID) {
        getRubric(digital_rubric);
      }

      if (programId === programTypes.FLL_EXPLORE_ID){
        getFLLERubric(digital_rubric);
      }
    }
  }, [programId, digital_rubric])

  const loading = isFetchingEvent || isFetchingSession || isLoadingRubric || isFetchingRubricEvidenceDocuments || isLoadingSessionRubric;

  return (
    <PageLayout
      loading={loading}
      headerData={{
        title: eventDetails.eventName,
        eventLocation: eventDetails.eventLocation,
        dateString: eventDetails.eventDate,
        programId,
        hasSubheading: true,
        subHeading: `Team: ${teamNameNumber}`
      }}
    >
      <RubricHeader />
      <RubricEvidence />

      {(programId === programTypes.FLL_CHALLENGE_ID) ? (
        <FLLChallenge eventId={eventId} is_FLLC_event={is_FLLC_event}/>
      ) : (
          programId === programTypes.FLL_EXPLORE_ID ? <RubricForm /> : <RubricUpload />
        // <RubricUpload />
      )}
    </PageLayout>
  );
};

JudgingRubric.propTypes = {
  pageDetails: PropTypes.object.isRequired,
  getEvent: PropTypes.func.isRequired,
  getSession: PropTypes.func.isRequired,
  getComments: PropTypes.func.isRequired,

};

const mapDispatchToProps = {
  getEvent: getEventDetails,
  getSession: getSessionDetails,
  getComments: getJudgeComments,
  getFLLEComments: getJudgeCommentsForFLLE,
  getRubric: getJudgingRubric,
  getFLLERubric: getJudgingRubricFLLE,
  getDocuments: getSessionDocuments,
  getSessionRubricDetails: getSessionRubric

}
const mapStateToProps = (state) => {

  const { judgingRubric, eventOverview: {is_FLLC_event} } = state;
  return {
    pageDetails: judgingRubric,
    is_FLLC_event
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(JudgingRubric);