/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../../../../../features/PanelManagement/styles/JudgingPanelCard.scss';
import UserRow from 'shared/components/UserRow';
import MissingJudge from './MissingJudge';
import { Button, Input } from 'antd';
import { Col, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from 'shared/components/Icon'
import { connect } from 'react-redux';
import { handleUpdateJudgingPanel, handleDeleteRefereePanel } from "features/EventContainer/redux/actions/judgingActions/editJudgingPanel";
import CloseCircle from 'shared/components/Icon/icons/CloseCircle';
import { handleExpands } from 'features/EventContainer/redux/actions/judgingActions/getJudgingPanels';

const { Option } = Select;


const PanelJudgesListCard = props => {
    const ref = useRef();
    const { panelJudges, panelTitle, hasError, judgeOptions, panelId, numberOfTeams, expendables, handleUpdateJudgingPanelData, handleDeleteRefereePanelData, manageExpands, judgingPanels, is_FLLC_event } = props;
    const [isEditing, setIsEditing] = useState(false)
    const [editText, setEditText] = useState('')
    const { t } = useTranslation();
    const [panelNameExists, setPanelNameExists] = useState(false)
    const [isUpdatingName, setIsUpdatingName] = useState(false)
    const [isUpdatingJudge, setIsUpdatingJudge] = useState(false)

    const subTitle = numberOfTeams === 0 ? t('2022EmptyPanelTeamAssignedLabel') : numberOfTeams === 1 ? t('2022EmptyPanelTeamAssigned3Label', { '0': 1 }) : t('2022EmptyPanelTeamAssigned2Label', { '0': numberOfTeams });

    useOnClickOutside(ref, () => {
        setIsEditing(false)
        setPanelNameExists(false)
    });

    const findUniquePanelNames = (value, currentName) => {

        let match = false;
        judgingPanels.map((panel) => {
            let panelName = panel.panelName.trim().toLowerCase();
            if (panelName == value.trim().toLowerCase() && panelName != currentName.trim().toLowerCase()) {
                match = true;
            }
        })

        if (match) {
            setPanelNameExists(true)
        } else {
            setPanelNameExists(false)
        }
    }

    const handleUpdatePanelReferee = async (id) => {
        if(id == ""){
            return
        }
        setIsUpdatingJudge(true)
        let data = {
            data: {
                attributes: {
                    name: panelTitle
                },
                id: panelId,
                type: "panel",
                relationships: {
                    judges: {
                        data: [
                            {
                                id: id,
                                type: "user"
                            }
                        ]
                    },
                    sessions: {
                        data: []
                    }
                }
            }
        }

        await handleUpdateJudgingPanelData(panelId, data)
        setIsUpdatingJudge(false)
        // var filtered = combiledPanels.filter((el) => { return el.sessionId != id; });
    }

    const handleDeletePanelReferee = async (id) => {
        let data = {
            data: [
                {
                    id: id,
                    type: "user"
                }
            ]
        }


        handleDeleteRefereePanelData(panelId, data)
        // var filtered = combiledPanels.filter((el) => { return el.sessionId != id; });
    }

    const handleEdtClick = () => {
        setIsEditing(true)
        setEditText(panelTitle)
    }

    const handleEditName = (value, currentValue) => {
        setEditText(value);
        findUniquePanelNames(value, currentValue)
    }

    const updatePanelName = async () => {
        setIsUpdatingName(true)
        let data = {
            data: {
                attributes: {
                    name: editText
                },
                id: panelId,
                type: "panel",
                relationships: {
                    judges: { data: [] },
                    sessions: { data: [] }
                }
            }
        }
        await handleUpdateJudgingPanelData(panelId, data)
        setIsUpdatingName(false)
        setIsEditing(false)
    }

    const isExpanded = () => {
        return expendables.includes(panelId)
    }

    const handleSingleExpandCollapse = () => {

        let data = [...expendables]
        if (data.includes(panelId)) {
            data.splice(data.indexOf(panelId), 1)
        } else {
            data.push(panelId)
        }

        manageExpands(data)
    }

    let currentJudgeId;

    const judgeMatch = (judge) => {
        return judge.userId == currentJudgeId
    }

    const isRefereeSelected = (id) => {
        currentJudgeId = id
        let match = panelJudges.some(judgeMatch, id)
        return match
    }


    return (
        <div>
            <div className='judging-panel-card__judges'>
                <div className='panel-card-title-container'>
                    <div ref={ref} className='panel-card-title'>
                        <div className='cursor' onClick={() => { handleSingleExpandCollapse() }}><Icon type={isExpanded() ? 'downArrowBlue' : 'upArrowBlue'} /></div>
                        <h4 className='h-4 heading--charcoal m-r-20 m-l-10'>
                            {isEditing ? (
                                <div>
                                    <Input className='' type='text' value={editText} onChange={(e) => { handleEditName(e.target.value, panelTitle) }}></Input>
                                    {panelNameExists && (
                                        <div className='error-text'>Room name already exists</div>
                                    )}

                                </div>) : panelTitle}
                            {/* {panelTitle} */}
                        </h4>
                        <div className='judging-panel-card__btn-container'>
                            {!isEditing ? (
                                <Button loading={isUpdatingName} type='link' className='judging-panel-card__btn link' onClick={(e) => { handleEdtClick() }}>{t('editLabel')}</Button>
                            ) : (
                                <div><Button loading={isUpdatingName} disabled={panelNameExists} type='link' className='judging-panel-card__btn link' onClick={() => { updatePanelName() }}>{t('Save')}</Button></div>
                            )}

                        </div>
                    </div>
                    <div className=''>
                        {subTitle}
                    </div>
                </div>
            </div>

            {/* {console.log(judgeOptions)} */}

            {!isExpanded() && (
                <div >
                    <div className='judging-panel-card__judges-list'>
                        <Select
                            defaultValue=""
                            className={`${is_FLLC_event ? 'panel-select-dropdown' : 'panel-select-dropdown-lg' } ?  m-r-20`}
                            onSelect={(e) => { handleUpdatePanelReferee(e) }}
                            loading={isUpdatingJudge}
                            suffixIcon={isUpdatingJudge ? null : <Icon type='downArrowBlue' />}
                        >
                            <Option value="">{is_FLLC_event ? t('judgingAddJudgeLabel') : t('nonChallengeJudgingAddJudgeLabel')}</Option>
                            {judgeOptions.map(referee => (
                                <Option value={referee.userId} disabled={isRefereeSelected(referee.userId) ? true : false}>{referee.fullName}</Option>
                            ))}
                        </Select>
                        {
                            panelJudges.map(j => (
                                <div className='judging-panel-card__judges-user delete_action' key={j.userId}>
                                    <CloseCircle onClick={() => { handleDeletePanelReferee(j.userId) }} className="m-t-5 cursor user-delete-button"></CloseCircle>
                                    <UserRow fullName={j.fullName} email={j.email} hideAvatar={true} />
                                </div>
                            ))
                        }

                        {/* {hasError && (
              <MissingJudge />
            )} */}
                    </div>
                </div>
            )}

        </div>
    );
};

PanelJudgesListCard.propTypes = {
    panelJudges: PropTypes.array,
    panelTitle: PropTypes.string,
    numberOfTeams: PropTypes.string,
    panelId: PropTypes.number,
    hasError: PropTypes.bool
};

PanelJudgesListCard.defaultProps = {
    panelJudges: [],
    numberOfTeams: 0,
    panelTitle: '',
    panelId: 0,
    hasError: false
}

const mapDispatchToProps = {
    // getScoringPanels: fetchScoringPanels
    handleUpdateJudgingPanelData: handleUpdateJudgingPanel,
    handleDeleteRefereePanelData: handleDeleteRefereePanel,
    manageExpands: handleExpands
}

const mapStateToProps = (state) => {
    const { event: { eventDetails: { is_FLLC_event }, eventJudging: { judgeOptions, expendables, judgingPanels } } } = state;

    return {
        judgeOptions,
        expendables,
        judgingPanels,
        is_FLLC_event
    }
}

// Hook
function useOnClickOutside(ref, handler) {
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.


        [ref, handler]
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(PanelJudgesListCard);
// export default JudgesList;