
import React from 'react';
// import PropTypes from 'prop-types';

const UpArrowBlue = () => {
  return (
    <svg width="14px" height="8px" viewBox="0 0 14 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      {/* <title>196FE0F4-21C1-40A5-8B79-62CC18F60BED</title> */}
      <g id="Judging-and-Awards" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="View-Rubrics---FLL---New" transform="translate(-1159.000000, -255.000000)" fill="#0066B3">
            <g id="Group-25" transform="translate(139.000000, 10.000000)">
                <g id="Group" transform="translate(111.000000, 161.000000)">
                    <g id="Group-53" transform="translate(0.000000, 64.000000)">
                        <g id="Group" transform="translate(892.000000, 0.000000)">
                            <g id="Icons/FIRST/Drop-Arrow" transform="translate(24.000000, 24.000000) scale(1, -1) translate(-24.000000, -24.000000) translate(17.000000, 20.000000)">
                                <polygon id="Path" points="1.645 0 7 4.94466937 12.355 0 14 1.52226721 7 8 0 1.52226721"></polygon>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
    </svg>
  );
};

UpArrowBlue.propTypes = {
  
};

export default UpArrowBlue;