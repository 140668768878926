export default class Panel {
  constructor(responseData, responseIncluded = []) {
    this.id = responseData.id;
    this.name = responseData.attributes.name;
    this.panelType = responseData.relationships.panel_type.data.id;
    this.judges = responseData.relationships.judges.data;
    this.referees = responseData.relationships.referees.data;
    this.sessions = responseData.relationships.sessions.data;
    this.included = responseIncluded || [];
  }

  getPanelSessionsIds = () => {
    const panelSessions = this.sessions.map(s => s.id);
    return panelSessions;
  }

  getPanelJudgesIds = () => {
    const panelJudgesIds = this.judges.map(j => j.id);
    return panelJudgesIds;
  }

  getPanelRefereesIds = () => {
    const panelRefereesIds = this.referees.map(j => j.id);
    return panelRefereesIds;
  }


  getPanelTypeName = () => {
    const panelType = this.included.find(i => i.id === this.panelType);
    if (!panelType) {
      return undefined;
    }
    const panelTypeName = panelType.attributes.description;
    return panelTypeName;
  }
}