import React, { useState } from 'react';
import { Table as AntTable } from 'antd';
import SortField from 'shared/components/SortField';
import './Table.scss';

const Table = props => {
  const { defaultSort, ..._props } = props;
  const [fieldName, setFieldName] = useState(defaultSort.field);
  const [fieldOrder, setFieldOrder] = useState(defaultSort.order);
  const { columns } = props;

  const handleChange = (page, filter, sorter) => {
    const { order, field } = sorter;
    setFieldOrder(order);
    setFieldName(field);
  };

  /**
   * if [sorter] is true
   * [sortField] is required in column and same with [title]
   **/
  columns.forEach(col => {
    const { title, sorter, dataIndex } = col;
    if (sorter) {
      const order = dataIndex === fieldName ? fieldOrder : null;
      col.sortField = col.sortField || title;
      col.title = <SortField sortField={col.sortField} sortOrder={order} />;
    }
  });

  return (
    <AntTable 
      className='c-table'
      onChange={handleChange}
      {..._props}
    />
  );
};

Table.defaultProps = {
  defaultSort: {}
};

export default Table;
