import { notification } from 'antd';
import * as types from '../../types/teamsTypes';
import * as teamService from 'utils/services/teams';

const updateNewTeamBegin = () => {
  return {
    type: types.UPDATE_TEAM_BEGIN,
    payload: {
      isCreatingNewTeam: true
    }
  };
};

const updateNewTeamError = () => {
  return {
    type: types.UPDATE_TEAM_ERROR,
    payload: {
      isCreatingNewTeam: false
    }
  };
};

const updateNewTeamSuccess = () => {
  return {
    type: types.UPDATE_TEAM_SUCCESS,
    payload: {
      isCreatingNewTeam: false
    }
  };
};

const updateTeamsList = (data = [], count = 0) =>{
  return {
    type: types.UPDATE_EVENT_TEAMS_ON_REMOVING_COACH,
    payload: {
      data,
      count
    }
  }
}

export const handleUpdateTeam = (teamPayload, successCb, errorCb) => {
  return async (dispatch) => {
    try {
      await dispatch(updateNewTeamBegin())
      await teamService.updateExistingTeam(teamPayload);
      notification.success({
        message: 'Success',
        description: 'Team successfully updated.',
      });
      successCb();
      await dispatch(updateNewTeamSuccess())
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error updating team',
      });
      errorCb();
      await dispatch(updateNewTeamError())
      throw err
    }
  };
};
