const m13Section = {
  id: 'm13',
  name: '2022ScoresheetM13Sub1Label',
  fields: [
    {
      scoring: "2022ScoresheetM13Sub2Label",
      id: 'm13_scoring_1',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true,
        }
      ]
    },
    {
      scoring: "2022ScoresheetM13Sub3Label",
      id: 'm13_scoring_2',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true,
        }
      ]
    },
  ]
}

export default m13Section;

