import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmModal from 'shared/components/ConfirmModal';

const DeleteConfirmModal = (props) => {
  const { delTeam, ..._props } = props;
  const { name } = delTeam || {};
  const { t } = useTranslation()
  return (
    <ConfirmModal
      title={t('randomizeConfirmLable')}
      description={<span>Please confirm to delete team <b>{name}</b> nomination.</span>}
      visible={Boolean(delTeam)}
      {..._props}
    />
  );
};

export default DeleteConfirmModal;
