import React from 'react';
import PropTypes from 'prop-types';

const Image = ({fill}) => {
  return (
    <svg viewBox="0 0 61 47" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      <g id="Scoring-and-Refereeing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Teams---Single-Event---Scoring---Coach" transform="translate(-688.000000, -2104.000000)" fill={fill}>
          <g id="Group-22" transform="translate(250.000000, 465.000000)">
            <g id="Group-18" transform="translate(0.000000, 1543.000000)">
              <g id="Group-17" transform="translate(0.000000, 48.000000)">
                <g id="Group-15" transform="translate(220.000000, 16.000000)">
                  <g id="Group-39-Copy-18" transform="translate(167.000000, 0.000000)">
                    <g id="noun_Image_1570203" transform="translate(51.000000, 32.000000)">
                      <path d="M4,-4.05882353e-05 C1.80709993,-4.05882353e-05 0,1.83363441 0,4.05878294 L0,41.94119 C0,44.1663047 1.80709993,46.0000135 4,46.0000135 L56,46.0000135 C58.1929,46.0000135 60,44.1663047 60,41.94119 L60,4.05878294 C60,1.83362765 58.1929,-4.05882353e-05 56,-4.05882353e-05 L4,-4.05882353e-05 Z M4,2.70584176 L56,2.70584176 C56.7616933,2.70584176 57.3333333,3.28588824 57.3333333,4.05878294 L57.3333333,36.4025871 L45.6458333,23.4228076 C45.37648,23.1322635 44.9755267,22.9727518 44.5833333,23.0000135 C44.2464867,23.0189547 43.9189267,23.1737312 43.6875,23.4228076 L34.1458333,33.6967047 L20.3333333,18.0321962 C20.0384,17.6914174 19.5665733,17.5180718 19.125,17.5882624 C18.8312333,17.63663 18.55494,17.7881459 18.3541667,18.0110565 L2.6666666,35.1130312 L2.6666666,4.05878294 C2.6666666,3.28588824 3.2383066,2.70584176 4,2.70584176 L4,2.70584176 Z M34.6666667,9.47061529 C31.73694,9.47061529 29.3333333,11.9095691 29.3333333,14.88238 C29.3333333,17.8551909 31.73694,20.2941447 34.6666667,20.2941447 C37.5963933,20.2941447 40,17.8551909 40,14.88238 C40,11.9095691 37.5963933,9.47061529 34.6666667,9.47061529 Z M34.6666667,12.1764976 C36.15522,12.1764976 37.3333333,13.3719362 37.3333333,14.88238 C37.3333333,16.3928238 36.15522,17.5882624 34.6666667,17.5882624 C33.1781133,17.5882624 32,16.3928238 32,14.88238 C32,13.3719362 33.1781133,12.1764976 34.6666667,12.1764976 Z M19.3125,20.9494756 L39.0208333,43.2941312 L4,43.2941312 C3.2383066,43.2941312 2.6666666,42.7140576 2.6666666,41.94119 L2.6666666,39.0872959 L19.3125,20.9494756 Z M44.6458333,26.3400871 L57.3333333,40.4191312 L57.3333333,41.94119 C57.3333333,42.7140576 56.7616933,43.2941312 56,43.2941312 L42.6041667,43.2941312 L35.9375,35.7261165 L44.6458333,26.3400871 Z" id="Shape" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Image.propTypes = {
  fill: PropTypes.string
};

Image.defaultProps = {
  fill: 'currentColor'
}

export default Image;