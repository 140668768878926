import { Table, Button, notification, Select } from 'antd';
import {FullscreenExitOutlined} from '@ant-design/icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Icon from 'shared/components/Icon';
import EventModal from 'shared/components/EventModal';
import EventModalHeader from 'features/EventOverviewContainer/components/EventModalHeader';
import Checkbox from 'shared/components/Checkbox';
import Input from 'shared/components/Input';
import Dropdown from 'shared/components/FormDropdown';
import ConfirmModal from 'shared/components/ConfirmModal';
import ScoresheetContent from 'features/EventOverviewContainer/components/CoachScoring/ScoresheetModal/ScoresheetContent';
import { NUMERICAL_AWARD_PLACES } from 'shared/constants/awards';
import { connect } from 'react-redux';
import { 
  addTeamToAward,
  removeTeamFromAward, 
  updateTeamAwardPlace,
  updateTeamAdvanceInAwardsTable,
  addTeamToNewlyCreatedAward
} from 'features/EventOverviewContainer/redux/actions/assignedAwards';
import AwardTeamRubricModal from './AwardTeamRubricModal';
import { officialMatchType, practiceMatchType } from 'shared/constants/matchTypes';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
// import Select from 'shared/components/Dropdown';

const {Option} = Dropdown;

const Indicator = (num)=>{
  const list = [];
  const upLimit = (num + 1) * 4;
  for(let i = 0; i < upLimit; i++){
    list.push(<div>|</div>)
  }
  return <div className="title-indicator-wrapper">{list}</div>
}

const DropdownOverlay = (props)=>{
  const {menu, value, setOpen, data} = props;
  const [awardName, setAwardName] = useState('');
  const [visible, setVisible] = useState(false);

  const addNewAward = async ()=>{
    const {teamName} = data;
    await props.addTeamToNewAward(awardName);
    setOpen(false);
    setAwardName('')
    notification.success({
      message: 'New award added successfully',
      description: `"${awardName}" awarded to Team ${teamName}`,
      className: 'new-award-added',
      duration: 0,
    })
  }

  const confirmRemoveTeamFromAward = ()=>{
    const {id, awardId} = data;
    props.removeTeamFromAward(id, awardId);
    setVisible(false)
  }

  const removeAward = ()=>{
    setOpen(false);
    setVisible(true);
  }

  const onAwardInput = (e)=>{
    setAwardName(e.target.value)
  }

  const disableAdd = !!value;
  const disableButton = disableAdd || !awardName;
  const disableRemove = !value;
  const removeTitle = disableRemove ? "Remove" : `Remove "${value}"`;
  const fill_color = disableRemove ? "#BBBBBB" : "#C50E14";
  return (
    <div className="award-dropdown-pop-up" onClick={e=>e.stopPropagation()}>
      <ConfirmModal 
        subTitle={value}
        title="Remove award?"
        description="This can not be undone."
        visible={visible} 
        onSubmit={confirmRemoveTeamFromAward}
        onCancel={()=>setVisible(false)}
        extraClass="remove-award-content"
        />
      <div className="add-section">
        <div className="title">Custom Award</div>
        <div className="add-input-row">
          <Input disabled={disableAdd} placeholder="New award name" onChange={onAwardInput}  value={awardName}/>
          <Button disabled={disableButton} type="primary" onClick={addNewAward}>Add</Button>
        </div>
      </div>
      <div className="menu-section">
        <div className="title">Existing awards</div>
        {menu}
      </div>
      <div className={`remove-section ${disableRemove ? "disabled" : ""}`}>
        <a onClick={removeAward} disabled={disableRemove}>
          <Icon type="closeCircle" fill_one={fill_color} fill_two={"white"}/>
          <div className="title">{removeTitle}</div>
        </a>
      </div>
    </div>
  )
}

const AwardDropdown = (props)=>{
  const {data, awardData, disable, eventId, userId, assignedAward} = props;
  const {awardName, teamId} = data;
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);
  
  useEffect(()=>{
    const clickOutsideDiv = (event)=>{
      const outsideClick = dropdownRef && !dropdownRef.current.contains(event.target);
      if(outsideClick && open){
        setOpen(false);
      }
    }
    window.addEventListener('click', clickOutsideDiv);
    return ()=>window.removeEventListener('click', clickOutsideDiv);
  },[open, dropdownRef])

  const onWrapperClicked = (e)=>{
    if(disable) return;
    setOpen(!open);
  }

  const onItemSelected = (id)=>{
    setOpen(false);
    if(data.id && data.awardId) props.removeTeamFromAward(data.id, data.awardId);
    props.addTeamToAward({eventId, userId, awardId: id, teamId})
    //body = { eventId, awardId, teamId: selTeam, userId }
  }

  const addTeamToNewAward = (awardName)=>{
    const award = {awardName};
    props.addTeamToNewlyCreatedAward({award, eventId, teamId, userId})
  }

  const displayedDropdownValue = awardName ? awardName : 'Add';
  const awardDropdownClass = awardName ? '' : 'add-new-dropdown';

  const assignedAwardIds = assignedAward.map(a=>a.awardId);
  const filteredAwardOptions = awardData.filter(a => !assignedAwardIds.includes(a.id));

  return (
    <div onClick={onWrapperClicked} ref={dropdownRef} key={data.id} className={awardDropdownClass}>
      <Dropdown
        listHeight={180}
        disabled={disable}
        dropdownClassName="award-dropdown-wrapper"
        dropdownMatchSelectWidth={false} 
        open={open}
        dropdownRender={
          (menu) => <DropdownOverlay 
                      menu={menu}
                      value={awardName}
                      setOpen={setOpen}
                      data={data}
                      addTeamToAward={props.addTeamToAward}
                      removeTeamFromAward={props.removeTeamFromAward}
                      addTeamToNewAward={addTeamToNewAward}
                      />
        }
        value={displayedDropdownValue}
        onChange={onItemSelected}
        >
        {
          filteredAwardOptions.map(a=>{
            const {awardName,id} = a;
            return <Option value={id} key={id}>{awardName}</Option>
          })
        }
      </Dropdown>
    </div>
  )
}

const mapStateToProps = ({ auth, eventOverview }) => {
  const { eventData: {id} } = eventOverview;
  const { userId } = auth;
  return {
    userId,
    eventId: id
  };
};

const mapDispatchToProps = {
  addTeamToAward,
  removeTeamFromAward, 
  addTeamToNewlyCreatedAward
};

const ConnectedAwardDropdown = connect(mapStateToProps, mapDispatchToProps)(AwardDropdown)


const AwardTable = (props)=>{
  const {loading, enterFullScreen, fullscreen, programId, editable, awards, teams, assignedSessions, ranking, rubricObjects, assignedMatches, scoreInputObject} = props;
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [scoreVisible, setScoreVisible] = useState(false);
  const [scoreDetail, setScoreDetail] = useState({});
  const [sortBy, setSortBy] = useState('robotGameRank');
  const [sortOrder, setSortOrder] = useState('asc');
  const {t} = useTranslation();

  const toggleRow = (key)=>{
    const isExpanded = expandedKeys.includes(key);
    if(isExpanded){
      const newKeys = [...expandedKeys].filter(k=>k!=key);
      setExpandedKeys(newKeys)
    }else{
      const newKeys = [...expandedKeys, key];
      setExpandedKeys(newKeys)
    }
  }

  const updateTeamAwardPlaceOnChange = (data, place)=>{
    const {awardId, id} = data;
    props.updateTeamAwardPlace({ awardId, assignmentId:id, place })
  }

  const convertMatchToScoreModalDetail = (match)=>{
    const {
      matchTypeId, 
      matchTypeName, 
      matchNumber, 
      scoreId, 
      matchTeam, 
      matchTotalScore, 
      scoreInput,
    } = match;
    let title_label = '';
    if (officialMatchType.matchTypeId === matchTypeId) title_label = officialMatchType.label;
    if (practiceMatchType.matchTypeId === matchTypeId) title_label = practiceMatchType.label;
    const title = title_label ? t(title_label) : matchTypeName;
    return {
      scoreInputId: scoreId,
      scoredScore: matchTotalScore,
      coachDetails: {
        teamName: matchTeam,
      },
      title: `${title} ${matchNumber}`,
      teamDetail: matchTeam,
      scoreInput,
    }
  }

  const handleAdvance = (e, record)=>{
    const value = e.target.checked;
    const {digital_rubric} = record;
    props.updateTeamAdvance(digital_rubric, value);
  }

  const onMatchScoreClicked = (data)=>{
    const scoresheetProps = convertMatchToScoreModalDetail(data);
    setScoreDetail(scoresheetProps);
    setScoreVisible(true);
  }

  const onModalClosed = ()=>{
    setScoreDetail({})
    setScoreVisible(false)
  }

  const columns = [
    {title: "Team Information", children: [
      {title: "Team Number", key: 'teamNumber', dataIndex: 'teamNumber', render: (text, record)=>{
        const {key} = record;
        const isExpanded = expandedKeys.includes(key);
        return <div onClick={()=>toggleRow(key)} className="toggle-wrapper"><Icon type={isExpanded ? "smallDownBlue" : "smallRightArrowBlue"}/> <div className="inner-text">{text}</div></div>
      }},
      {title: "Team Name", key: 'teamName', dataIndex: 'teamName', render: (text)=><div className="team-name-wrapper">{text}</div>},
    ]},
    {title: "Robot Game",children: [
      {width: 65, title: "Details", key: 'details', dataIndex: 'details', render: (text, record)=>{
        const {key, details} = record;
        const isExpanded = expandedKeys.includes(key);
        if(isExpanded){
          let maxScore;
          if(details.length > 0){
            maxScore = _.max(details.map(a=>a.matchTotalScore))
          }
          const maxTitle = maxScore !== undefined ? `Max: ${maxScore}` : '';
          return (
            <div>
              <div className="row-replacement" />
              {details.map(m=>{
                const { matchNumber, matchTotalScore} = m;
                return (<div><a onClick={()=>onMatchScoreClicked(m)}>{`R${matchNumber}: ${matchTotalScore?matchTotalScore:'0'}`}</a></div>)
              })}
              <div>{maxTitle}</div>
            </div>
          )
        }else{
          return null
        }
      }},
      {title: "Robot Game Rank", key: 'robotGameRank', dataIndex: 'robotGameRank', },
    ]},
    {title: "Judging", children: [
      {title: "Core Values Rank", key: 'coreValuesRank', dataIndex: 'coreValuesRank', },
      {title: "Innovation Project Rank", key:'innovationProjectRank' , dataIndex: 'innovationProjectRank' ,render: (text, record)=>{
        const {key, digital_rubric, rubric} = record;
        const isExpanded = expandedKeys.includes(key);
        const extraPart = isExpanded && digital_rubric ? <div className="view-rubric-link"><AwardTeamRubricModal digitalRubric={digital_rubric} rubric={rubric}/></div> : null;
        return (
          <div className="innovation-project-rank-wrapper">
            {text}
            {extraPart}
          </div>
        )
      }},
      {title: "Robot Design Rank", key:'robotDesignRank' , dataIndex: 'robotDesignRank' , },
    ]},
    {title: "Overall", children: [
      {children:[
        {children: [
          {title: "Champion's", children: [
            {title: 'Score', key: 'score', dataIndex: 'score', },
            {title: 'Rank', key: 'rank', dataIndex: 'rank', },
          ]},
        ]}
      ]},
      {title: "Breakthrough", children:[
        {width: 45, title: ()=>Indicator(2), key:'breakthrough' , dataIndex: 'breakthrough', render: (text, record)=><Checkbox disabled checked={record.breakthrough}/>},
        {title: "Rising All-Star", children: [
          {width: 45, title: ()=>Indicator(1), key:'risingAllStart' , dataIndex: 'risingAllStart', render: (text, record)=><Checkbox disabled checked={record.risingAllStart}/>},
          {title: "Motivate", children:[
            {width: 45, title: ()=>Indicator(0), key:'motivate' , dataIndex: 'motivate', render: (text, record)=><Checkbox disabled checked={record.motivate}/>}
          ]}
        ]},
      ]},
    ]},
    {title: "Award & Advancement", children: [
      {width: 140,title: "Award", key: 'awardName', dataIndex: 'awardName', render: (text, record)=>{
        const {award, key} = record;
        const isExpanded = expandedKeys.includes(key);
        if(!isExpanded){
          const award_len = (award||{}).length || 0;
          const award_title = award_len > 1 ? award_len > 2 ? `${award_len - 1} awards` : '1 award' : "None";
          const award_class = award_len > 1 ? 'awards-title' : '';
          return <div className={award_class}>{award_title}</div>
        }
        return (
          <div className="award-wrapper">
            {award.map(a=><ConnectedAwardDropdown assignedAward={award} data={a} awardData={awards} disable={!editable}/>)}
          </div>
        )
      }},
      {width: 60,title: "Place", key: 'place', dataIndex: 'award', render: (text, record, index)=>{
        const {award, key} = record;
        const isExpanded = expandedKeys.includes(key);
        if(!isExpanded){
          const places = award.map(a=>a.place);
          const valid_places = places.filter(p=>p);
          const place_title = valid_places.length > 0 ? valid_places.join(', ') : '--';
          return <div className="place-title">{place_title}</div>
        }
        return (
          <div className="place-wrapper">
            {award.map(a=>{
              const {place, awardName} = a;
              const disabled = !editable || !awardName;
              return <Select usePopupContainer={false} className={!place && 'place-empty-title'} onChange={(i)=>updateTeamAwardPlaceOnChange(a, i)} notFoundContent="--" value={place ? place : '--'} disabled={disabled}>{NUMERICAL_AWARD_PLACES.map(p=><Option value={p.place} key={p.place}>{p.place}</Option>)}</Select>
            })}
          </div>
        )
      }},
      {children:[
        {title: ()=><div className="advance-title-wrapper">Advance?</div>, children:[
          {title: ()=>Indicator(1), key: 'advance', dataIndex: 'advance',render: (text, record)=><Checkbox checked={record.advance} onChange={(e) => handleAdvance(e, record)}/>},
        ]}
      ]},
    ]}
  ]

  const data = teams.map(team=>{
    const addAwardItem = {awardName: null, place:null, teamId: team.id, teamName: team.teamName};
    let award = [];
    awards.forEach(a=>{
      const {id, awardName, assignments} = a||{};
      if(assignments && assignments.length > 0){
        assignments.forEach(assignment=>{
          const {teamId} = assignment;
          if(team.id === teamId){
            award.push({...assignment, awardId: id, awardName, value: awardName, teamName: team.teamName})
          }
        })
      }
    })
    award.push(addAwardItem);
    const judgingSession = assignedSessions.find(a=>a.teamId === team.id);
    const match = assignedMatches ? _.orderBy(assignedMatches.filter(a=>a.matchTeamId === team.id && a.matchTypeId === officialMatchType.matchTypeId), ['matchNumber'], ['asc']) : [];
    const matchWithScoreInput = match.map(m=>({...m, scoreInput: scoreInputObject[m.scoreId]}));
    const sessionId = (judgingSession||{}).sessionId;
    const digital_rubric = (judgingSession||{}).digital_rubric;
    const rubric = (rubricObjects[digital_rubric]||{});
    const rankData = (ranking[team.id] || {});
    const rank = ((rankData||{}).championship_rank||{}).rank;
    const score = ((rankData||{}).championship_rank||{}).score;
    const coreValuesRank = (rankData.judging_rank||{}).core_value_rank;
    const innovationProjectRank = (rankData.judging_rank||{}).innovation_project_rank;
    const robotDesignRank = (rankData.judging_rank||{}).robot_design_rank;
    const robotGameRank = (rankData.game_score_rank||{}).rank;
    return ({
      key: team.id,
      ...team,
      details: matchWithScoreInput, 
      robotGameRank: robotGameRank, 
      coreValuesRank: coreValuesRank, 
      innovationProjectRank: innovationProjectRank,
      robotDesignRank: robotDesignRank, 
      score: score, 
      rank: rank, 
      breakthrough: rubric.breakthrough_award, 
      risingAllStart: rubric.rising_all_star_award, 
      motivate: rubric.motivate_award, 
      award,
      sessionId: sessionId, 
      digital_rubric: digital_rubric,
      advance: rubric.advance,
      rubric: rubric,
    })
  });

  const expandAllRecord = ()=>{
    const keys = data.map(d=>d.key);
    setExpandedKeys(keys);
  }

  const collapseAllRecord = ()=>{
    setExpandedKeys([])
  }

  const sortOptions = {
    "robotGameRank": "Robot Game Rank",
    "coreValuesRank": "Core Values Rank",
    "innovationProjectRank": "Innovation Project Rank",
    "robotDesignRank": "Robot Design Rank",
    "score": "Champion's Score/Rank",
  }

  const sortedData = sortBy ? _.orderBy(data, [sortBy], [sortOrder]) : data;

  const handleSortOrder = ()=>{
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  }

  return (
    <div>
      <div className="table-title">Results and Rankings</div>
      <div className="award-table-control-row">
        <div className="buttons-left">
          <Button className="first-button" type="link" onClick={collapseAllRecord}>{t('CollapseAllLabel')}</Button>
          <Button className="first-button" type="link" onClick={expandAllRecord}>{t('ExpandAllLabel')}</Button>
          <div className="award-table-sort-by-row">
            <div className="sort-by-title">{t('sortByLabel')}: </div>
            <Select value={sortBy} onChange={setSortBy} additionalClassNames="sort-by-dropdown">
            {
              Object.keys(sortOptions).map(key=>{
                const value = sortOptions[key]
                return <Select.Option value={key}>{value}</Select.Option>
              })
            }
            </Select>

            <Button className="sort-by-direction-button" type="link" onClick={handleSortOrder}>
              <Icon type={sortOrder === 'asc' ? 'upArrowBlue' : 'downArrowBlue'} />
            </Button>

          </div>
        </div>
        <div className="buttons-right">
          {/* <Button className="fullscreen-button" type="link" onClick={enterFullScreen}><Icon style={{fontSize: "20px"}} type={fullscreen ? "fullscreen-exit" :"fullscreen"}/></Button> */}
          <Button className="fullscreen-button" type="link" onClick={enterFullScreen}>
              {/* <Icon type={fullscreen ? 'fullscreen-exit' :'downArrowBlue'} /> */}
              <FullscreenExitOutlined />
          </Button>
        </div>
      </div>
      <EventModal extraClass="scoresheet-modal" visible={scoreVisible} onCancel={onModalClosed} renderHeader={()=><EventModalHeader/>} programId={programId}>
        <ScoresheetContent {...scoreDetail}/>
      </EventModal>
      <Table
        columns={columns}
        dataSource={sortedData}
        className="award-table"
        rowClassName={(record, index)=>{
          const {key} = record;
          const isExpanded = expandedKeys.includes(key);
          const extraClassOne = index%2 === 0 ? 'even-row' : 'odd-row'
          const extraClassTwo = isExpanded ? 'expanded-row' : ''
          return `${extraClassOne} ${extraClassTwo}`;
        }}
        pagination={false}
        loading={loading}
      />
    </div>
  )
}

const mapStateToTableProps = (state)=>{
  const {assignedAwards: {assignedJudgingSessions, assignedScoringSessions, ranking, rubricObjects, scoreInputObject}} = state;
  return {
    assignedSessions: assignedJudgingSessions,
    ranking,
    rubricObjects,
    assignedMatches: assignedScoringSessions,
    scoreInputObject,
  }
}

const mapDispatchToTableProps = {
  updateTeamAwardPlace: updateTeamAwardPlace,
  updateTeamAdvance: updateTeamAdvanceInAwardsTable
}

const ConnectedAwardTable = connect(mapStateToTableProps, mapDispatchToTableProps)(AwardTable);

const AwardTableContainer = (props)=>{
  const {programId, awards, teams, editable, loading} = props;
  const [fullScreen, setFullScreen] = useState(false);

  const enterFullScreen = ()=>{
    setFullScreen(!fullScreen);
  }

  const exitFullScreen = ()=>{
    setFullScreen(false);
  }

  const extraClass = fullScreen ? "fullscreen" : '';

  if(fullScreen){
    return (
      <EventModal width={"95%"} visible={fullScreen} onCancel={exitFullScreen} extraClass={`award-table-wrapper ${extraClass}`} renderHeader={()=><EventModalHeader/>} programId={programId}>
        <ConnectedAwardTable loading={loading} enterFullScreen={enterFullScreen} fullscreen={fullScreen} programId={programId} awards={awards} teams={teams} editable={editable}/>
      </EventModal>
    )
  }

  return (
    <div className={`award-table-wrapper ${extraClass}`}>
      <ConnectedAwardTable loading={loading} enterFullScreen={enterFullScreen} fullscreen={fullScreen} awards={awards} teams={teams} editable={editable}/>
    </div>
  )
}

export default AwardTableContainer;