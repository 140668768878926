const m09Section = {
  id: 'm09',
  name: '2025ScoresheetM09Sub1Label',
  fields: [
    {
      scoring: '2025ScoresheetM09Sub2Label',
      id: 'm09_scoring_1',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        }
      ]
    },
    {
      scoring: '2025ScoresheetM09Sub3Label',
      id: 'm09_scoring_2',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        }
      ]
    },
  ]
}

export default m09Section;