import React from 'react';
import PropTypes from 'prop-types';
import { Tabs as AntTabs } from 'antd';
import './styles/event-tabs.scss';

const Tabs = props => {
  const {children} = props;
  return (
    <AntTabs className='event-tabs' gutter={48} animated={false} {...props}>
      {children}
    </AntTabs>
  );
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
};

Tabs.TabPane = AntTabs.TabPane;

export default Tabs;
