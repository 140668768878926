import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../../../EventContainer/styles/TeamsContainer/EditEventTeamModal.scss';
import Container from 'shared/components/Container';
import programLogos from 'shared/constants/programLogos';
import { connect } from 'react-redux';
import Team from 'models/Team';
import FirstText from 'shared/components/FirstText';
import { useTranslation } from 'react-i18next';
import CardTile from 'shared/components/CardsList/CardTile';
import { Row, Col, Button } from 'antd';
import {
  handleReinviteCoachUser,
  deleteRehTeam,
  deleteTeamCoach
} from 'features/AdminDashboard/redux/actions/teamsActions';
import { handleUpdateCoachInfo } from 'features/EventContainer/redux/actions/teams';
import ConfirmationModal from 'shared/components/ConfirmModal';
import AddModal from 'shared/components/Modal';
import {
  FLL_DISCOVER_ID,
  FLL_CHALLENGE_ID,
  FLL_EXPLORE_ID,
  FTC_ID
} from 'shared/constants/programIds';
import EditTeamInfoForm from 'features/EventContainer/components/TeamsContainer/EditTeamModal/EditTeamInfoForm';
import EditCoachModal from 'features/EventContainer/components/TeamsContainer/EditCoachModal';
import Checkbox from 'shared/components/Checkbox';

const EditTeamModalContent = props => {
  const {
    programId,
    teamId,
    teamsData,
    teamsIncluded,
    handleReinvite,
    showDeleteOption,
    onCoachDeletedCallback,
    onCancel,
    isUpdatingCoach
  } = props;

  const [selectedCoachId, setSelectedCoachId] = useState(null)
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [deleteChecked, setDeleteChecked] = useState(false);
  const [deleteConfirmModalVisible, setDeleteConfirmModalVisiable] = useState(false);
  const [editTeamModalVisible, setEditTeamModalVisible] = useState(false);
  const [editCoachModalVisible, setEditCoachModalVisible] = useState(false);
  const [selectedCoachUser, setSelectedCoachUser] = useState({});
  const [deletingTeam, setDeletingTeam] = useState(false);

  const { t } = useTranslation()

  let allCoaches = [];
  let teamLocation = '';
  let teamTitle = '';
  let events = [];

  const selectedTeam = teamsData.find(team => team.id === teamId);

  const selectedTeamDetails = new Team(selectedTeam, teamsIncluded);

  if (selectedTeamDetails) {
    events = selectedTeamDetails.events;
    teamLocation = selectedTeamDetails.getTeamLocation();
    const teamCoaches = selectedTeamDetails.getTeamCoachesList();
    const { teamName, teamNumber } = selectedTeamDetails;
    teamTitle = (teamName && teamNumber) ? `${teamNumber} - ${teamName}` : teamName

    allCoaches = teamCoaches.map(tc => {
      const { attributes } = tc;
      return {
        id: tc.id,
        userId: tc.id,
        email: attributes.email,
        firstName: attributes.first_name,
        lastName: attributes.last_name,
        userVerificationCodeTimeout: attributes.verification_code_timeout,
        userStatus: attributes.status,
        phone: attributes.mobile_phone,
        handleReinviteClick: () => { handleReinvite(tc.id) }
      }
    });
  }

  const [teamCoachesList, setTeamCoachesList] = useState(allCoaches);

  const { color } = programLogos[FLL_DISCOVER_ID];

  const programNameMap = {
    [FLL_CHALLENGE_ID]: 'Lego League Challenge',
    [FLL_DISCOVER_ID]: 'Lego League Discover',
    [FLL_EXPLORE_ID]: 'Lego League Explore',
    [FTC_ID]: 'Tech Challenge'
  }

  const onCheckedChanged = (e) => {
    setDeleteChecked(e.target.checked)
  }

  const successCallback = () => {
    const updatedCoachesList = teamCoachesList.filter(c => c.id !== selectedCoachId);
    if (updatedCoachesList.length === 0) {
      onCoachDeletedCallback && onCoachDeletedCallback()
    }
    setTeamCoachesList(updatedCoachesList);
    setConfirmationModalVisible(false);
    setSelectedCoachId(null)
    onCancel && onCancel();
  }

  const errorCallBack = () => {
    setConfirmationModalVisible(false);
    setSelectedCoachId(null)
  }

  const removeCoachFromEvent = async () => {
    await props.deleteTeamCoach({
      userId: selectedCoachId,
      teamId
    }, successCallback, errorCallBack);
  }

  const closeEditCoach = () => {
    setSelectedCoachUser({});
    setEditCoachModalVisible(false);
  };

  const onEditCoach = (c) => {
    setSelectedCoachUser(c);
    setEditCoachModalVisible(true);
  };

  const updateCoach = async (id, values) => {
    const updatedCoaches = teamCoachesList.map((coach) => {
      return coach.id === id ? { ...coach, ...values } : coach;
    });
    const userPayload = {
      'first_name': values.firstName,
      'last_name': values.lastName,
      'mobile_phone': values.phone
    };
    await props.handleUpdateCoachInfo(id, userPayload);
    setTeamCoachesList(updatedCoaches);
    closeEditCoach();
  };

  const closeEditTeamModal = () => {
    setEditTeamModalVisible(false)
  };

  const handleDeleteTeam = async () => {
    const coachesId = teamCoachesList.map(({ id }) => id);
    setDeletingTeam(true);
    await props.deleteRehTeam(
      { teamId, coachesId },
      () => {
        setDeleteConfirmModalVisiable(true);
        onCoachDeletedCallback && onCoachDeletedCallback();
        onCancel && onCancel();
      },
      () => setDeleteConfirmModalVisiable(true)
    );
    setDeletingTeam(false);
  }

  let removeEventTeamMsg = '';
  if (events.length) {
    removeEventTeamMsg = `and removed from ${events.length} event${events.length > 1 ? 's' : ''}`;
  }

  return (
    <Container
      type='small' 
      additionalclassname={showDeleteOption ? 'c-container--delete-option' : ''}
    >
      <ConfirmationModal
        visible={confirmationModalVisible}
        onCancel={() => {
          setConfirmationModalVisible(false);
          setSelectedCoachId(null)
        }}
        onSubmit={removeCoachFromEvent}
        title='Remove Coach?'
        description='Are you sure you want to remove this coach from this team?'
      />
      <ConfirmationModal
        visible={deleteConfirmModalVisible}
        onCancel={() => {
          setDeleteConfirmModalVisiable(false);
        }}
        loading={deletingTeam}
        onSubmit={handleDeleteTeam}
        title={t('DeleteTeamConfirmLabel')}
        description={`This team will be deleted ${removeEventTeamMsg}.`}
      />
      <AddModal
        noTitle
        visible={editTeamModalVisible}
        onCancel={closeEditTeamModal}
        maxWidth={940}
        // iconColor='#9A989A'
        wrapClassName='edit-team-modal'>
        <EditTeamInfoForm 
          data={selectedTeamDetails} 
          refreshTeamData={onCoachDeletedCallback}
          onCancel={closeEditTeamModal}
        />
      </AddModal>
      <EditCoachModal
        visible={editCoachModalVisible}
        onCancel={closeEditCoach}
        updateCoach={updateCoach}
        selectedCoach={selectedCoachUser}
        isUpdatingCoach={isUpdatingCoach}
      />
      <div className={`edit-event-team-content edit-event-team-content--${color}`}>
        <div className="edit-event-team-content__header">
          <div className="edit-event-team-content__header_inner">
            <div className='m-b-8'>
              <span className='paragraph--default paragraph--bold paragraph--charcoal'>
                <FirstText />
                <span className='p-l-4'>{programNameMap[programId]}</span>
              </span>
            </div>
            <p className='heading--primary h--bold m-b-8'>{teamTitle}</p>
            <p className='paragraph--default paragraph--bold paragraph--charcoal'>{teamLocation}</p>
          </div>
          <Button type="link" className="team-info-edit-button" onClick={() => setEditTeamModalVisible(true)}>{t('editLabel')}</Button>
        </div>
        <div className='m-t-36'>
          <p className='paragraph--default paragraph--normal paragraph--charcoal'>{t('coachesLabel')}</p>
          <Row type='flex' gutter={20} className='m-t-12'>
            {
              teamCoachesList.map(c => (
                <Col xs={24} sm={12} key={c.email}>
                  <CardTile
                    {...c}
                    closePosition="top-right"
                    showDeleteBtn
                    handleDelete={
                      () => {
                        setConfirmationModalVisible(true);
                        setSelectedCoachId(c.userId);
                      }
                    }
                    renderInfo={() => (
                      <div className='volunteer-tile__roles'>
                        <p className='paragraph--xsmall paragraph--darkGray'>{t('coachLabel')}</p>
                        {c.phone ? (
                          <p className='paragraph--xsmall paragraph--darkGray'>{c.phone}</p>
                        ) : null}
                        <Button type='link' onClick={() => onEditCoach(c)}>{t('editLabel')}</Button>
                      </div>
                    )}
                  />
                </Col>
              ))
            }
          </Row>
        </div>
      </div>
      {showDeleteOption ? <div className="edit-event-delete-option-section">
        <div className="title">Delete Team:</div>
        <div className="delete-checkbox-wrapper">
          <Checkbox checked={deleteChecked} onChange={onCheckedChanged}>
            <div className="checkbox-text">Check to confirm delete.</div>
          </Checkbox>
        </div>
        <div className="button-wrapper">
          <Button
            type="primary"
            ghost
            disabled={!deleteChecked}
            onClick={() => setDeleteConfirmModalVisiable(true)}
          >
            Delete
          </Button>
        </div>
      </div> : null}
    </Container>
  );
};

EditTeamModalContent.propTypes = {
  programId: PropTypes.string,
  teamId: PropTypes.string.isRequired,
  teamsData: PropTypes.array,
  teamsIncluded: PropTypes.array,
  handleReinvite: PropTypes.func.isRequired
};

EditTeamModalContent.defaultProps = {
  programId: FLL_DISCOVER_ID
}

EditTeamModalContent.defaultProps = {
  teamsData: [],
  teamsIncluded: []
}
const mapStateToProps = (state) => {
  const {
    adminBoard: { teams: { teams, included } },
    event: { eventTeams: { isUpdatingCoach } }
  } = state;
  return {
    teamsData: teams,
    teamsIncluded: included,
    isUpdatingCoach
  }
}

export default connect(
  mapStateToProps,
  {
    handleReinvite: handleReinviteCoachUser,
    handleUpdateCoachInfo,
    deleteTeamCoach,
    deleteRehTeam
  }
)(EditTeamModalContent);
