import React from 'react';

export default () => (
  <svg width="61px" height="62px" viewBox="0 0 61 62">
      <title>21D3B176-AC89-4B0B-BAAE-B41A9E212E38</title>
      <g id="Judging-and-Awards" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Judging-Assignments---Awards---FLLC/FTC---Judge" transform="translate(-541.000000, -592.000000)" fillRule="nonzero">
              <g id="Group-8" transform="translate(250.000000, 253.000000)">
                  <g id="Group-24" transform="translate(290.000000, 286.000000)">
                      <g id="Group-23" transform="translate(0.000000, 53.000000)">
                          <g id="Icons/-Global-icon" transform="translate(1.000000, 0.000000)">
                              <path d="M55.229442,27.1650661 C55.6486941,27.1520025 55.999186,27.4802969 56.0122877,27.8983321 C56.053728,29.2205747 56.0045774,30.494782 55.8570949,31.6691405 C55.5897921,34.3724856 54.8700483,37.095641 53.7498089,39.7285409 C53.5859902,40.1135644 53.140157,40.2932716 52.7540128,40.1299284 C52.3678685,39.9665851 52.1876381,39.522046 52.3514569,39.1370225 C53.413172,36.6416723 54.0936312,34.0671501 54.3476371,31.5009083 C54.4869838,30.38937 54.5331336,29.1929575 54.4940415,27.9456396 C54.4809398,27.5276043 54.8101899,27.1781297 55.229442,27.1650661 Z" id="Path_272" fill="#9A999A"></path>
                              <path d="M11.2371328,6.60683219 C23.5299725,-2.69304414 41.0191924,-0.323819342 50.3517964,11.9018383 C52.8135276,15.1356578 54.5295715,18.834719 55.3876625,22.7627023 C55.476936,23.1713592 55.2170602,23.5748016 54.8072136,23.663816 C54.397367,23.7528304 53.9927502,23.4937089 53.9034767,23.0850519 C53.0925793,19.3731011 51.4704126,15.8763993 49.1424892,12.8183535 C40.3182665,1.25867337 23.7808038,-0.981619044 12.1552723,7.81341998 C11.8211123,8.0662213 11.3446897,8.00105338 11.0911524,7.66786336 C10.8376152,7.33467334 10.9029729,6.8596335 11.2371328,6.60683219 Z" id="Path_274" fill="#9A999A"></path>
                              <path d="M7.87838611,9.64340382 C8.16649336,9.33943101 8.6471864,9.32589153 8.95204406,9.61316253 C9.25690173,9.90043353 9.27048063,10.3797313 8.98237338,10.6837041 C2.63700298,17.3785029 0.270071308,26.9099102 2.74440047,35.7842759 C2.85675198,36.187233 2.62021872,36.6047088 2.21608864,36.7167342 C1.81195856,36.8287596 1.39326751,36.5929129 1.28091599,36.1899558 C-1.33598786,26.8042354 1.16719136,16.7241722 7.87838611,9.64340382 Z" id="Path_275" fill="#9A999A"></path>
                              <path d="M3.81473998,40.8119851 C4.18334293,40.6123766 4.64444034,40.7485064 4.84462987,41.1160395 C7.19371829,45.4287907 10.6065854,48.9825756 14.7647554,51.4194767 L15.6584678,51.9163693 C16.006595,52.1068217 16.2643975,52.2418447 16.5548458,52.3866473 C18.9085731,53.5600951 21.5091138,54.3565142 24.2666016,54.7765743 C24.6812471,54.8397391 24.9660295,55.2261052 24.9026809,55.6395472 C24.8393323,56.0529892 24.4518415,56.3369451 24.0371959,56.2737803 C21.1277675,55.8305745 18.3755338,54.9876992 15.875534,53.7413274 L15.6668252,53.6361511 C15.232296,53.4146611 14.8148467,53.1852977 14.0075775,52.7323921 C9.5967142,50.147567 5.98947647,46.3913859 3.50980051,41.8388857 C3.30961098,41.4713526 3.44613703,41.0115936 3.81473998,40.8119851 Z" id="Path_276" fill="#9A999A"></path>
                              <g id="Group_710" transform="translate(23.140253, 29.595595)" fill="#0066B3">
                                  <g id="Group_708">
                                      <path d="M8.12951473,12.8601011 C8.4937052,12.6525963 8.95764545,12.7787582 9.16575429,13.1418916 C9.37386312,13.505025 9.24733396,13.9676187 8.8831435,14.1751235 C7.61128528,14.8997901 6.50559715,15.7961143 5.5865993,16.8534186 C5.31188425,17.1694775 4.83222153,17.2036397 4.51524263,16.929722 C4.19826373,16.6558043 4.1640021,16.1775338 4.43871715,15.861475 C5.4717574,14.6729652 6.71119524,13.668217 8.12951473,12.8601011 Z" id="Path_279"></path>
                                      <path d="M2.09069052,19.4308992 C2.27588389,19.0556309 2.73111344,18.901109 3.10747417,19.0857648 C3.48383489,19.2704207 3.63880665,19.7243289 3.45361328,20.0995972 C2.49949514,22.0329834 1.94698444,24.185633 1.81552142,26.409381 L1.80075949,26.6944444 L9.4556962,26.6944444 C9.85608676,26.6944444 10.1841133,27.0033722 10.2131067,27.3952163 L10.2151899,27.4517336 C10.2151899,27.8509621 9.90536286,28.1780365 9.51237811,28.2069457 L9.4556962,28.2090229 L0.265822785,28.2090229 L0.265822785,27.4517336 C0.265822785,24.6331635 0.88338814,21.8773277 2.09069052,19.4308992 Z" id="Path_280"></path>
                                      <path d="M12.0143754,0.247681619 C15.3513511,-0.542549753 18.8270469,0.822329902 20.7556583,3.66504547 C20.9906838,4.01146612 20.8995628,4.4822684 20.5521337,4.71661179 C20.2047046,4.95095517 19.7325318,4.86009859 19.4975063,4.51367794 C17.9215335,2.19074115 15.0855998,1.07709128 12.3575891,1.7230702 C8.7369829,2.5404507 6.45722113,6.18805491 7.30883287,9.82238093 C8.16628774,13.4474371 11.7971972,15.7101686 15.434797,14.862729 C15.8432531,14.7675724 16.2517365,15.0205907 16.3471702,15.4278612 C16.4426038,15.8351318 16.188849,16.2424295 15.7803929,16.3375862 C11.3245801,17.3756421 6.87956551,14.6055717 5.83004503,10.1685139 C4.79702141,5.76000592 7.52258863,1.33850244 11.8817808,0.278762467 L12.0143754,0.247681619 Z" id="Path_284"></path>
                                  </g>
                                  <g id="Group_709" transform="translate(8.543165, 2.926165)">
                                      <path d="M7.87361214,13.0438691 C8.24087913,12.8418271 8.70287198,12.9749044 8.90550204,13.3411053 C9.1081321,13.7073063 8.9746675,14.1679582 8.60740051,14.3700001 C4.58510897,16.5827541 1.84183677,21.3020167 1.5456256,26.6729696 L1.53493671,26.8837667 L16.443038,26.8837667 C16.8434285,26.8837667 17.1714551,27.1926945 17.2004485,27.5845386 L17.2025316,27.6410559 C17.2025316,28.0402844 16.8927046,28.3673588 16.4997199,28.396268 L16.443038,28.3983452 L0,28.3983452 L0,27.6410559 C0,21.3407495 3.10892451,15.665032 7.87361214,13.0438691 Z" id="Path_285"></path>
                                      <path d="M18.3982954,13.3411053 C18.6009255,12.9749044 19.0629183,12.8418271 19.4301853,13.0438691 C24.1441848,15.6371473 27.2374628,21.2203353 27.3027437,27.4401973 L27.3037975,27.6410559 L27.3037975,28.3983452 L22.7848101,28.3983452 C22.3653534,28.3983452 22.0253165,28.0592952 22.0253165,27.6410559 C22.0253165,27.2418275 22.3351435,26.9147531 22.7281282,26.8858439 L22.7848101,26.8837667 L25.768481,26.8837667 L25.7678106,26.8673809 C25.5307007,21.4802305 22.8317593,16.7226015 18.8395247,14.4501011 L18.696397,14.3700001 C18.32913,14.1679582 18.1956654,13.7073063 18.3982954,13.3411053 Z" id="Path_287"></path>
                                      <path d="M13.6329114,0.227186761 L13.4959313,0.228331888 C8.98431519,0.305625548 5.35443038,3.99630224 5.35443038,8.51950355 C5.35443038,13.0714104 9.0806924,16.7739559 13.6708861,16.7739559 C18.2360435,16.7739559 21.9493671,13.0585095 21.9493671,8.48163909 C21.9493671,3.92973228 18.2231051,0.227186761 13.6329114,0.227186761 Z M13.5155065,1.74277053 L13.6329114,1.74176517 C17.385372,1.74176517 20.4303797,4.7673924 20.4303797,8.48163909 C20.4303797,12.223208 17.3959449,15.2593775 13.6708861,15.2593775 C9.91842549,15.2593775 6.87341772,12.2337502 6.87341772,8.51950355 C6.87341772,4.82184992 9.83982177,1.8057657 13.5155065,1.74277053 Z" id="Path_288"></path>
                                  </g>
                              </g>
                              <path d="M9.05827173,16.9609898 L10.2664828,16.5520302 C12.8880423,15.6922421 14.4604749,15.500125 16.1709441,16.1160036 C18.5194534,16.9675282 19.8102076,18.8803099 21.2191445,22.5394988 L21.2934146,22.733704 L23.5607357,28.7640482 C23.9038587,29.5765999 24.1393768,30.3934157 24.2619257,31.2531699 C24.4786985,33.3785823 23.6631533,35.052308 21.6095313,37.8020242 L20.8768384,38.7792715 C19.1027717,41.1298731 17.918031,42.5688708 16.5349422,43.9875729 L16.4253245,44.0995498 C14.303806,46.257862 12.1458769,47.8290337 9.75162531,48.8390461 C9.36531936,49.0020089 8.91966454,48.8218624 8.7562274,48.4366777 C8.59279027,48.051493 8.77346115,47.6071318 9.1597671,47.444169 L9.45154344,47.3181507 C13.2151755,45.6540137 15.8542368,42.9617325 20.2220039,37.1240162 L20.3908533,36.8979332 C22.2242086,34.4431449 22.9157115,33.0239886 22.7544687,31.4368841 C22.6647757,30.810855 22.4941111,30.1874663 22.23812,29.5404171 L22.1496234,29.3238216 L19.8720308,23.2678583 C18.5856007,19.8815405 17.4792988,18.2019958 15.6534346,17.5399703 C14.3556033,17.0726693 13.047551,17.2339417 10.7288494,17.9948324 L9.3459974,18.4623667 C6.2128603,19.5050127 4.71970033,19.6858579 2.88510653,18.8844639 C2.50090216,18.7166343 2.32589176,18.2700265 2.49420986,17.8869373 C2.66252797,17.5038481 3.11043594,17.3293456 3.49464031,17.4971752 C4.89521221,18.1089781 6.15770642,17.9374204 9.05827173,16.9609898 Z" id="Path_289" fill="#9A999A"></path>
                              <path d="M46.856605,8.61227707 C47.275416,8.58907733 47.6337912,8.90879813 47.6570585,9.32639347 C47.7515504,11.0223103 46.2881253,12.5003155 44.5212646,12.7916494 L44.420938,12.8068661 L44.3795282,12.8114503 C42.9699795,12.9285718 41.5392413,12.6818737 40.1972689,12.1071624 L40.0148332,12.026763 C38.9418287,11.5404493 37.7664547,11.2925051 36.5735402,11.2959716 L36.4146835,11.2983519 L36.312497,11.3169318 C35.2377833,11.5253711 34.2528848,12.0314781 33.4169614,12.7947829 L33.2985636,12.9055718 C32.9962053,13.1954571 32.5154129,13.1860571 32.2246837,12.8845764 C31.9339546,12.5830957 31.9433819,12.1036989 32.2457402,11.8138136 C33.3195506,10.7843005 34.6182758,10.0979151 36.0450863,9.82586567 L36.2684523,9.78664802 L36.3206832,9.78520137 C37.7437337,9.74578693 39.1542625,10.0097843 40.4499203,10.5628202 L40.6433946,10.6479415 C41.7481271,11.1486351 42.9205303,11.3790742 44.0717934,11.3147185 L44.2340506,11.3032742 L44.3020313,11.2924803 C45.3171871,11.1127044 46.1391751,10.3013342 46.1422772,9.48264734 L46.1404099,9.41040716 C46.1171426,8.99281182 46.4377941,8.63547681 46.856605,8.61227707 Z" id="Path_290" fill="#9A999A"></path>
                              <path d="M13.751068,5.05396197 C13.9386547,4.67987738 14.3948621,4.52824965 14.7700356,4.71529194 C15.1452092,4.90233423 15.2972783,5.35721744 15.1096915,5.73130202 C15.0034086,5.94325092 15.0892556,6.22477753 15.2965901,6.35694996 L15.3246835,6.37294287 L15.3833119,6.39621791 C15.73121,6.52147959 16.1546704,6.50941392 16.5432794,6.36314338 L16.6700328,6.3117318 C16.7791044,6.27094868 16.8927523,6.22216272 17.0266829,6.1590564 L17.4402004,5.95428893 L17.7005199,5.82350088 C17.9623065,5.69388647 18.1544413,5.60644874 18.3542322,5.52970954 L18.4818987,5.48237076 L18.5671064,5.44748305 C19.3187096,5.15110005 20.1613786,5.12846059 20.8919088,5.41138998 L21.0006219,5.45612514 L21.046597,5.47804149 C22.1791813,6.06527588 22.571017,7.2482237 22.6327985,8.97670094 C22.6750401,11.4195983 23.9264828,14.3173223 25.5347341,15.0681671 L25.5949367,15.0946426 L25.6818923,15.1210558 C26.6765901,15.4058498 27.7452542,15.2660858 28.6078548,14.7441425 L28.7103598,14.6796776 C29.0612191,14.4504715 29.5319959,14.5482653 29.7618692,14.8981062 C29.9917425,15.2479471 29.893664,15.7173574 29.5428048,15.9465636 C28.2841412,16.7688116 26.7010981,16.9967414 25.236661,16.5692913 L25.0953264,16.5258772 L25.037001,16.5043947 C22.7040579,15.5351566 21.1662961,12.0250437 21.114408,9.01678452 C21.072731,7.85321761 20.8435799,7.12435056 20.3963942,6.85037018 L20.3681013,6.83413199 L20.3128906,6.81237153 C19.9649925,6.68710985 19.5415321,6.69917552 19.1529231,6.84544606 L19.063913,6.88158172 L19.0122684,6.90190206 L18.8974004,6.94429531 C18.7252656,7.01088879 18.5471963,7.09383996 18.2729212,7.23150892 L17.8735896,7.43264962 C17.6602113,7.53827466 17.4824369,7.61990076 17.3079448,7.68947897 L17.2207595,7.72281087 L17.1290961,7.76110639 C16.377493,8.05748939 15.5348239,8.08012885 14.8042937,7.79719946 L14.6955806,7.7524643 L14.6603441,7.7360083 C13.6775224,7.24602378 13.2596544,6.03393632 13.751068,5.05396197 Z" id="Path_291" fill="#9A999A"></path>
                              <path d="M50.9768726,14.758184 C51.2842478,14.4735926 51.7648031,14.4913393 52.0502229,14.7978223 C52.3206206,15.0881746 52.318878,15.5335062 52.0565937,15.8215583 L52.0104692,15.8680572 L47.7587227,19.8046447 C47.1191436,20.3998526 46.8628043,20.7860854 46.8937283,21.0953083 C46.9246412,21.3624423 47.106082,21.5730192 47.6813122,21.9959727 L48.0367086,22.2547866 C48.2508946,22.413086 48.3964396,22.5292532 48.5344458,22.6555694 L48.5859641,22.7035158 C49.3068731,23.3549432 49.6470173,24.0068794 50.1199435,25.3893799 L50.339436,26.0432669 C50.5329154,26.6120992 50.6630469,26.9374967 50.8323736,27.258386 C51.7419536,28.9327366 53.0952094,29.7719527 54.7135963,29.5926538 C55.1304876,29.546467 55.5059955,29.8460014 55.5523168,30.2616827 C55.598638,30.6773639 55.2982317,31.0517819 54.8813404,31.0979687 C52.5993553,31.3507866 50.6821407,30.1618337 49.4924672,27.9717749 C49.2604091,27.5321443 49.1018677,27.1281237 48.8625137,26.4161259 L48.6881405,25.8955961 C48.3098091,24.7885477 48.0583851,24.290846 47.6066433,23.8634472 L47.5536117,23.8144133 C47.4277271,23.6955005 47.2925496,23.5882538 47.0469957,23.4090415 L46.8696394,23.2804988 C45.8964592,22.5758096 45.4831485,22.1192036 45.3835161,21.257465 C45.2907696,20.3326922 45.7195722,19.6498972 46.6248885,18.7890247 L46.723708,18.6960878 L50.9768726,14.758184 Z" id="Path_292" fill="#9A999A"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
);
