import React from 'react';
import '../../../../styles/judgingRubric/FLLFeedbackComments.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import JudgingFeedbackComment from './CommentRow';
import { useTranslation } from 'react-i18next';

const titleClassName = 'paragraph--large paragraph--charcoal paragraph--bold'

const FeedbackCommentsContainer = (props) => {
  const { t } = useTranslation();
 
  const { commentSection, rubricComments, comments, labelKey } = props;

  const commentsData = comments.length > 0 ? comments : rubricComments;

  const sectionFeedbackComments = commentsData.filter(c => c.commentSection === commentSection);
  const positiveComments = sectionFeedbackComments.filter(c => c.commentType === 'positive');
  const negativeComments = sectionFeedbackComments.filter(c => c.commentType === 'negative');

  return (
    <div>
      <p className='h-2 heading--charcoal m-b-8'>{t(labelKey)}</p>
      <div className='c-fll-feedback'>
        <div className='c-fll-feedback__section c-fll-feedback__section--left'>
          <div className='c-fll-feedback__section-header'>
            <span className={titleClassName}>{t('greatJobLabel')}:</span>
          </div>
         
          <ul className='c-fll-feedback__comments'>
            {positiveComments.map(c => (
              <li key={c.commentId} className='c-fll-feedback__comment-row-readonly'>
                <JudgingFeedbackComment
                  {...c}
                />
              </li>
              ))}
          </ul>

        </div>      
        <div className='c-fll-feedback__section'>
          <div className='c-fll-feedback__section-header'>
            <span className={titleClassName}>
              {t('thinkAboutLabel')}:
            </span>
          </div>
          <ul className='c-fll-feedback__comments'>
            {negativeComments.map(c => (
              <li key={c.commentId} className='c-fll-feedback__comment-row-readonly'>
                <JudgingFeedbackComment
                  {...c}
                />
              </li>
              ))}
          </ul>
        </div>

      </div>
    </div>
  );
};

FeedbackCommentsContainer.propTypes = {
  commentSection: PropTypes.string.isRequired,
  rubricComments: PropTypes.array
}
FeedbackCommentsContainer.defaultProps = {
  rubricComments: []
}
const mapStateToProps = (state) => {
  const {judgingRubric: {judgingRubricComments}} = state;

  return {
    rubricComments: judgingRubricComments
  }
}

export default connect(mapStateToProps)(FeedbackCommentsContainer);