import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import truncateString, {addEllipsisMiddle} from 'utils/truncateString';
import CloseCircle from 'shared/components/Icon/icons/CloseCircle';

import './UserInviteeTile.scss'

const UserInviteeTile = ({fullName, email, id, removeUser, hasDeleteBtn}) => {
  return (
    <div className='add-volunteers-manually__tile'>
      <span className='add-volunteers-manually__tile-inner'>
        <Avatar size={48} icon={<UserOutlined />} className='add-volunteers-manually__tile-avatar' />
        <span className='user-invitee-tile__text-container'>
          <p className='add-volunteers-manually__tile-name'>{truncateString(fullName, 32)}</p>
          <a className='link--email' href={`mailto:${email}`}>
            {addEllipsisMiddle(email, 15, 13, 8)}
          </a>
        </span>
      </span>
      <div>
        {hasDeleteBtn && (
          <Button type='link' className='add-volunteers-manually__tile-delete' onClick={() => removeUser(id || email)}>
            <CloseCircle />
            {' '}
          </Button>
        )}
      </div>
    </div>
  );
};

UserInviteeTile.propTypes = {
  fullName: PropTypes.string,
  email: PropTypes.string,
  removeUser: PropTypes.func,
  id: PropTypes.string,
  hasDeleteBtn: PropTypes.bool
};

UserInviteeTile.defaultProps = {
  removeUser: () => {},
  hasDeleteBtn: true,
  fullName: '',
  email: '',
  id: ''
};

export default UserInviteeTile;
