import React, {useState, useEffect} from 'react';
import ScheduleContent from './ScheduleContent';
import ScheduleHeader from './ScheduleHeader';
import ScheduleInstructions from './ScheduleInstructions';
import '../../styles/ScheduleContainer/ScheduleContainer.scss';
import Success from 'shared/components/Icon/icons/Success';
import Close from 'shared/components/Icon/icons/Close';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { resetGenerateScheduleCompleteStatus } from 'features/EventContainer/redux/actions/scheduleActions/scheduleSettings';


const ScheduleContainer = (props) => {
  const { isGeneratingScheduleSessionsComplete} = props;

  const [scheduleSuccessInfoVisible, setSceduleSuccessInfoVisible] = useState(false);

  useEffect(()=>{
    isGeneratingScheduleSessionsComplete && setSceduleSuccessInfoVisible(true);
    return ()=>props.resetScheduleCompleteStatus()
  }, [isGeneratingScheduleSessionsComplete]);

  const ScheduleSuccessInfoBlock = ()=>{
    if(!scheduleSuccessInfoVisible) return null;
    return (
      <div className="success-info-block">
        <div className="checkmark-with-text">
          <Success fill="#52C41A"/>
          <span className="title">Sessions successfully generated</span>
        </div>
        <Button type="link" onClick={()=>setSceduleSuccessInfoVisible(false)}><Close fill="#4B4B4B"/></Button>
      </div>
    )
  }
  return (
    <div className='event-schedule-container'>
      <ScheduleSuccessInfoBlock />
      <ScheduleHeader />
      <ScheduleInstructions />
      <ScheduleContent {...props} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { event: { eventSchedule: { isGeneratingScheduleSessionsComplete }}} = state;
  return {
    isGeneratingScheduleSessionsComplete
  };
};

const mapDispatchToProps = {
  resetScheduleCompleteStatus: resetGenerateScheduleCompleteStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleContainer);
