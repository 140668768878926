/* eslint-disable import/prefer-default-export */
import * as sessionService from 'utils/services/sessions';
import * as matchService from 'utils/services/matches';
import {formatScoreRes} from 'utils/services/score/model';
import ScheduleSession from 'models/ScheduleSession';
import * as scoreService from 'utils/services/score';
import {scoredStatus} from 'shared/constants/matchStatuses';
import * as types from '../../types/coachScoringTypes';
import { formatSessionResData } from 'utils/services/sessions/model';
import _ from 'lodash';

const getMatchesBegin = () => {
  return {
    type: types.GET_COACH_MATCHES_BEGIN
  }
}

const getMatchesSuccess = (coachMatches) => {
  return {
    type: types.GET_COACH_MATCHES_SUCCESS,
    payload: {
      coachMatches
    }
  }
}

const getMatchesError = () => {
  return {
    type: types.GET_COACH_MATCHES_ERROR
  }
}


export const getMatches = (teamId) => {

  return async (dispatch, getState) => {
    await dispatch(getMatchesBegin())
    const {eventOverview: {eventScheduleId, eventData: { attributes: {season}}}} = getState();
    if (!teamId) {
      dispatch(getMatchesError())
    } else {
      try {
        const eventScheduleData = await sessionService.getScoringSessions(eventScheduleId);

        const formattedSessionsList = ((eventScheduleData||{}).data||[]).map(s => formatSessionResData(new ScheduleSession(s, (eventScheduleData||{}).included)));
     
        const teamSessions = formattedSessionsList.map(s => {
          return {
            sessionId: s.sessionId,
            teamName: s.teamName,
            teamId: s.teamId
          }
        })

        let matchesIds = [];
        ((eventScheduleData||{}).data||[]).forEach(session=>{
          const {matches, team} = ((session||{}).relationships||{});
          const match_data = ((matches||{}).data||[])
          const team_id = ((team||{}).data||{}).id;
          if(team_id === teamId){
            matchesIds = [...matchesIds, ...match_data]
          }
        })
        const uniqMatchIds = _.uniqBy(matchesIds, "id");
        const {matches} = await matchService.getMatchesByIdsAndPage(uniqMatchIds, {size:500, number:1});
          
        const scoredMatches = matches.filter(m => m.matchStatusId === scoredStatus.statusId);
        let scoreData = [];

        if (scoredMatches.length) {
          const scoresIds = scoredMatches.map(m => m.scoreId);

          scoreData = await scoreService.getAllScoresByScoreIds(scoresIds, season)
        }
        const matchScoresList = scoreData;
        const formattedMatches = matches.map(match => {
          const matchTeam = teamSessions.find(s => s.sessionId === match.sessionId) || {};
          const matchScore = matchScoresList.find(s => s.matchId === match.matchId) || {};
          return {
            ...match,
            scoreId: matchScore.scoreId,
            scoreInputId: matchScore.scoreInputId,
            totalScore: matchScore.totalScore,
            matchTeamId: matchTeam.teamId,
          }
        })
        const filteredMatches = formattedMatches.filter(m=>m.matchTeamId === teamId)
        await dispatch(getMatchesSuccess(filteredMatches))

      } catch(err) {
        dispatch(getMatchesError())
        throw err;
      }

    }
  }

}