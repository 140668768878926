import * as types from '../types/coachJudgingTypes';

const DEFAULT_UPLOADED_JUDGING_RUBRIC = {
  contentType: null,
  eventFileId: null,
  fileName: '',
  sessionDocumentId: null
}

const DEFAULT_JUDGING_SESSION_DETAILS = {
  teamId: null,
  sessionId: null,
  startIsoString: null,
  sessionStatusId: null,
  sessionStatusName: null,
  sessionTypeName: null,
};

const DEFAULT_FLL_CHALLENGE_RUBRIC  = {
  "discovery_ip_cv1": undefined,
  "teamwork_ip_cv2": undefined,
  "innovation_ip_cv3": undefined,
  "impact_ip_cv4": undefined,
  "fun_ip_cv5": undefined,
  "discovery_rd_cv6": undefined,
  "inclusion_rd_cv7": undefined,
  "innovation_rd_cv8": undefined,
  "impact_rd_cv9": undefined,
  "fun_rd_cv10": undefined,
  "innovation_project_1_identify_problem": undefined,
  "innovation_project_1_identify_research": undefined,
  "innovation_project_2_design_planning": undefined,
  "innovation_project_2_design_process": undefined,
  "innovation_project_3_create_innovation": undefined,
  "innovation_project_3_create_model_drawing": undefined,
  "innovation_project_4_iterate_share": undefined,
  "innovation_project_4_iterate_improvements": undefined,
  "innovation_project_5_communicate_solution": undefined,
  "innovation_project_5_communicate_presentation": undefined,
  "innovation_project_1_identify_problem_justification": undefined,
  "innovation_project_1_identify_research_justification": undefined,
  "innovation_project_2_design_planning_justification": undefined,
  "innovation_project_2_design_process_justification": undefined,
  "innovation_project_3_create_innovation_justification": undefined,
  "innovation_project_3_create_model_drawing_justification": undefined,
  "innovation_project_4_iterate_share_justification": undefined,
  "innovation_project_4_iterate_improvements_justification": undefined,
  "innovation_project_5_communicate_solution_justification": undefined,
  "innovation_project_5_communicate_presentation_justification": undefined,
  "robot_design_1_identify_mission": undefined,
  "robot_design_1_identify_building": undefined,
  "robot_design_2_design_contribution": undefined,
  "robot_design_2_design_skills": undefined,
  "robot_design_3_create_purpose": undefined,
  "robot_design_3_create_code_sensor": undefined,
  "robot_design_4_iterate_testing": undefined,
  "robot_design_4_iterate_improvements": undefined,
  "robot_design_5_communicate_explanation": undefined,
  "robot_design_5_communicate_enthusiasm": undefined,
  "robot_design_1_identify_mission_justification": undefined,
  "robot_design_1_identify_building_justification": undefined,
  "robot_design_2_design_contribution_justification": undefined,
  "robot_design_2_design_skills_justification": undefined,
  "robot_design_3_create_purpose_justification": undefined,
  "robot_design_3_create_code_sensor_justification": undefined,
  "robot_design_4_iterate_testing_justification": undefined,
  "robot_design_4_iterate_improvements_justification": undefined,
  "robot_design_5_communicate_explanation_justification": undefined,
  "robot_design_5_communicate_enthusiasm_justification": undefined,
  "is_published": undefined,
  "is_publishable": undefined
}
const DEFAULT_STATE = {
  judgingTimestamps: [],
  isFetchingJudgingTimestamps: false,
  documentTypes: [],
  isFetchingDocumentTypes: false,
  sessionDocuments: [],
  isUploadingFile: false,
  uploadRetry: 0,
  isRemovingFile: false,
  isCoachJudgingSessionRubricPublished: false,
  isCoachJudgingObjectPublished: false,
  isFetchingCoachJudgingObject: false,
  isFetchingCoachJudgingSessionRubric: false,
  uploadedJudgingSessionRubric: DEFAULT_UPLOADED_JUDGING_RUBRIC,
  judgingObject: DEFAULT_FLL_CHALLENGE_RUBRIC,
  selectedTeamJudgingSession: DEFAULT_JUDGING_SESSION_DETAILS
}

export default (state = DEFAULT_STATE, action) => {
  const {type} = action;
  switch(type) {
    case types.GET_COACH_JUDGING_TIMESTAMPS_BEGIN:
      return {
        ...state,
        isFetchingJudgingTimestamps: true,
      }
    
    case types.GET_COACH_JUDGING_TIMESTAMPS_ERROR:
      return {
        ...state,
        isFetchingJudgingTimestamps: false,
        judgingTimestamps: []
      }
    
    case types.GET_COACH_JUDGING_TIMESTAMPS_SUCCESS:
      return {
        ...state,
        isFetchingJudgingTimestamps: false,
        judgingTimestamps: action.payload.judgingTimestamps
      }
    case types.GET_JUDGING_DOCUMENT_TYPES_BEGIN:
      return {
        ...state,
        isFetchingDocumentTypes: true
      }
    case types.GET_JUDGING_DOCUMENT_TYPES_ERROR:
      return {
        ...state,
        isFetchingDocumentTypes: false,
        documentTypes: [],
        sessionDocuments: [],
        selectedTeamJudgingSession: DEFAULT_JUDGING_SESSION_DETAILS,
      }
    case types.GET_JUDGING_DOCUMENT_TYPES_SUCCESS:
      return {
        ...state,
        isFetchingDocumentTypes: false,
        documentTypes: action.payload.documentTypes,
        sessionDocuments: action.payload.sessionDocuments
      }
    case types.UPLOAD_JUDGING_SESSION_DOCUMENT_BEGIN:
      return {
        ...state,
        isUploadingFile: true,
        sessionDocuments: action.payload.sessionDocuments,
        uploadRetry: 0,
      }
    case types.UPLOAD_JUDGING_SESSION_DOCUMENT_ERROR:
      return {
        ...state,
        isUploadingFile: false,
        sessionDocuments: action.payload.sessionDocuments,
        uploadRetry: 0,
      }
    case types.UPLOAD_JUDGING_SESSION_DOCUMENT_SUCCESS:
      return {
        ...state,
        isUploadingFile: false,
        sessionDocuments: action.payload.sessionDocuments,
        uploadRetry: 0,
      }
    case types.UPLOAD_JUDGING_SESSION_DOCUMENT_RETRY:
      return {
        ...state,
        uploadRetry: action.payload.uploadRetry
      }
    case types.REMOVE_JUDGING_SESSION_DOCUMENT_BEGIN:
      return {
        ...state,
        isRemovingFile: true
      }
    case types.REMOVE_JUDGING_SESSION_DOCUMENT_ERROR:
      return {
        ...state,
        isRemovingFile: false
      }
    case types.REMOVE_JUDGING_SESSION_DOCUMENT_SUCCESS:
      return {
        ...state,
        isRemovingFile: false,
        sessionDocuments: action.payload.sessionDocuments
      }
    case types.SET_IS_COACH_JUDGING_SESSION_RUBRIC_PUBLISHED:
      return {
        ...state,
        isCoachJudgingSessionRubricPublished: action.payload.isSessionRubricPublished
      }
    case types.SET_IS_COACH_JUDGING_OBJECT_PUBLISHED:
      return {
        ...state,
        isCoachJudgingObjectPublished: action.payload.isJudgingObjectPublished
      }
    case types.GET_COACH_JUDGING_SESSION_RUBRIC_BEGIN:
      return {
        ...state,
        isFetchingCoachJudgingSessionRubric: true
      }
    case types.GET_COACH_JUDGING_SESSION_RUBRIC_ERROR:
      return {
        ...state,
        isFetchingCoachJudgingSessionRubric: false,
        uploadedJudgingSessionRubric: DEFAULT_UPLOADED_JUDGING_RUBRIC,
        isCoachJudgingSessionRubricPublished: false
      }
    case types.GET_COACH_JUDGING_SESSION_RUBRIC_SUCCESS:
      return {
        ...state,
        isFetchingCoachJudgingSessionRubric: false,
        uploadedJudgingSessionRubric: {
          ...DEFAULT_UPLOADED_JUDGING_RUBRIC,
          ...action.payload.sessionRubric
        }
      }
    case types.GET_COACH_JUDGING_OBJECT_BEGIN:
      return {
        ...state,
        isFetchingCoachJudgingObject: true
      }
    case types.GET_COACH_JUDGING_OBJECT_ERROR:
      return {
        ...state,
        isFetchingCoachJudgingObject: false,
        judgingObject: DEFAULT_FLL_CHALLENGE_RUBRIC
      }
    case types.GET_COACH_JUDGING_OBJECT_SUCCESS:
      return {
        ...state,
        isFetchingCoachJudgingObject: false,
        judgingObject: {
          ...DEFAULT_FLL_CHALLENGE_RUBRIC,
          ...action.payload.judgingObject
        }
      }
    case types.SET_COACH_SELECTED_TEAM_JUDGING_SESSION:
      return {
        ...state,
        selectedTeamJudgingSession: {
          ...DEFAULT_JUDGING_SESSION_DETAILS,
          ...action.payload.judgingSession
        }
      }
    default:
      return state;
  }
}