import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  FLL_CHALLENGE_ID
} from 'shared/constants/programIds';
import Pagination, { getPageFilter, initialPage } from 'shared/components/Pagination';
import VolunteersList from 'shared/components/CardsList/Volunteers';
import TitleSort from 'features/AdminDashboard/components/TitleAndSort';
import EventNav from 'features/EventContainer/components/EventsNav';
import { handleEventVolunteers } from 'features/EventContainer/redux/actions/volunteers/getEventVolunteers';
import handleReinviteVolunteerUser from 'features/EventContainer/redux/actions/volunteers/resendUserInvitation';
import handleReferees from 'features/EventContainer/redux/actions/scoringActions/getReferees';
import handleJudges from 'features/EventContainer/redux/actions/judgingActions/getJudges';
import VolunteerEventRoles from 'features/EventContainer/models/VolunteerEventRoles';
import getJudgingPanels from 'features/EventContainer/redux/actions/judgingActions/getJudgingPanels';
import { handleGetScoringPanelsNew as getScoringPanels } from 'features/EventContainer/redux/actions/scoringActions/getScoringPanels';
import VolunteersModal from './VolunteersModal';
import EditVolunteerModal from './EditVolunteerModal';

import '../../styles/VolunteersContainer/EventVolunteers.scss';

const EventVolunteers = (props) => {
  const [current, setCurrentPage] = useState(initialPage);
  const [visible, setVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedVolunteer, setSelectedVolunteer] = useState()
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation();

  const {
    eventId,
    canAddVolunteer,
    eventVolunteers,
    fetchEventVolunteers,
    reinviteVolunteerUser,
    eventProgramId,
    canViewJudgingTab,
    canViewScoringTab,
  } = props;

  const {
    count,
    volunteers,
    isFetchingEventVolunteers,
    fetchEventVolunteersError,
    volunteersIncludedResponse,
    newVolunteerInvited
  } = eventVolunteers;

  const fetchPageVolunteers = useCallback((number, nosearch) => {
    const page = getPageFilter(number);
    fetchEventVolunteers(eventId, page, !nosearch && searchText);
  }, [eventId, searchText]);

  const handleSearch = (nosearch) => {
    setCurrentPage(pre => {
      if (pre === initialPage && searchText) {
        fetchPageVolunteers(initialPage, nosearch);
      }
      return initialPage;
    });
  };

  useEffect(() => {
    fetchPageVolunteers(current);
  }, [current]);

  useEffect(() => {
    if (newVolunteerInvited) {
      setCurrentPage(pre => {
        if (pre === initialPage) {
          fetchPageVolunteers(initialPage);
        }
        return initialPage;
      });
      // fetchReferees(eventId);
      // fetchJudges(eventId);
      if ((eventProgramId === FLL_CHALLENGE_ID) && canViewScoringTab) {
        props.getScoringPanels();
      }
      if (canViewJudgingTab) {
        props.getJudgingPanels();
      }
    }
  }, [eventId, newVolunteerInvited]);

  const handleAddClick = () => setVisible(!visible);
  const handleModalClose = () => setVisible(false);

  const handleCloseEditVolunteerModal = () => {
    setEditModalVisible(false);
    setSelectedVolunteer(null)
  }


  if (fetchEventVolunteersError) {
    return <p>Error</p>;
  }

  let formattedVolunteersList = [];
  if (volunteers.length) {
    formattedVolunteersList = volunteers.map(volunteer => {
      const volunteerInfo = new VolunteerEventRoles(volunteer, volunteersIncludedResponse);
      const volunteerRolesList = volunteerInfo.getUserEventRolesList();
      const volunteerUser = volunteerInfo.formatUserData()
      const volunteerObj = {
        ...volunteerUser,
        roles: volunteerRolesList,
        eventRoleAssignments: volunteer.eventRoleAssignments,
        handleReinviteClick: () => { reinviteVolunteerUser(volunteerUser.id) }
      };
      return volunteerObj;
    })
  } else {
    formattedVolunteersList = [];
  }

  const handleOpenEditVolunteerModal = (userId) => {
    const selectedUser = formattedVolunteersList.find(v => v.id === userId);
    setSelectedVolunteer(selectedUser)
    setEditModalVisible(true);
  }

  return (
    <div>
      <VolunteersModal visible={visible} onCancel={handleModalClose} />
      <EditVolunteerModal selectedVolunteer={selectedVolunteer} selectedUserId visible={editModalVisible} onCancel={handleCloseEditVolunteerModal} />
      <EventNav
        isLinkVisible={canAddVolunteer}
        addClickTeamOnly={() => { }}
        addClick={handleAddClick}
        customText={t('addVolunteersLabel')}
        placeholder='Volunteer'
        searchText={searchText}
        setSearchText={setSearchText}
        handleSearch={handleSearch}
      />
      <div className='event-volunteers'>
        <TitleSort title={t('volunteersLabel')} />
        <VolunteersList
          loading={isFetchingEventVolunteers}
          volunteers={formattedVolunteersList}
          isEditButtonVisible={canAddVolunteer}
          onEditClick={(userId) => {
            handleOpenEditVolunteerModal(userId)
          }}
          showInvitationLink={true}
        />
        <Pagination
          total={count}
          current={current}
          onChange={setCurrentPage}
        />
      </div>
    </div>
  );
};

EventVolunteers.propTypes = {
  fetchEventVolunteers: PropTypes.func.isRequired,
  reinviteVolunteerUser: PropTypes.func.isRequired,
  eventVolunteers: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  fetchEventVolunteers: handleEventVolunteers,
  fetchReferees: handleReferees,
  fetchJudges: handleJudges,
  reinviteVolunteerUser: handleReinviteVolunteerUser,
  getJudgingPanels,
  getScoringPanels
};

const mapStateToProps = (state) => {
  const { event: { eventVolunteers, eventDetails: { eventProgramId } } } = state;
  return {
    eventVolunteers,
    eventProgramId
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventVolunteers);
