import * as meetingService from 'utils/services/meetings';
import { notification } from 'antd';
import * as types from '../../types/eventScheduleTypes';

const createSessionUrlBegin = () => {
  return {
    type: types.CREATE_NEW_MEETING_LINK_BEGIN
  }
}

const createSessionUrlSuccess = () => {
  return {
    type: types.CREATE_NEW_MEETING_LINK_SUCCESS
  }
}

const createSessionUrlError = () => {
  return {
    type: types.CREATE_NEW_MEETING_LINK_ERROR
  }
}


const handleCreateSessionUrl = (meetingId, sessionUrl, cb = () => {}) => {
  return async dispatch => {
    await dispatch(createSessionUrlBegin())
    try {
      await meetingService.createExternalLink(meetingId, sessionUrl);
      await dispatch(createSessionUrlSuccess());
      cb(sessionUrl);
      notification.success({
        message: 'Success',
        description: 'New Meeting URL Created',
      });
    } catch(err) {
      notification.error({
        message: 'Error',
        description: 'Error creating new Meeting URL'
      });
      await dispatch(createSessionUrlError())
      throw err;
    }
  }

}

export default handleCreateSessionUrl;