import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';
import SpreadSheetIcon from './RubricSpreadsheetIcon';
import DownloadOutlined from '@ant-design/icons';
import './styles/DownloadFeedbackFile.scss';
import RubricPDFIcon from 'features/EventOverviewContainer/components/JudgingRubric/RubricUploadForm/icons/RubricsPdfIcon';

const boldClassName = 'paragraph--charcoal paragraph--medium paragraph--bold';


const DownloadFeedbackFile = props => {
  const { text, downloadClick, rubricUrl, extraText, programTypeExplorer } = props;
  if (!rubricUrl) {
    return null
  }
  return (
    <a download={text} title={text} href={rubricUrl} className='c-download-feedback-file'>
      <div className='c-rubric-upload__download-icons'>
        <Icon component={programTypeExplorer ? RubricPDFIcon : SpreadSheetIcon} style={{ color: '#52C41A' }} className='p-r-8' />
        <DownloadOutlined />
      </div>
      <div className='c-rubric-upload__download-text'>
        <p className={boldClassName}>{text}</p>
        {extraText}
      </div>
    </a>
  );
};

DownloadFeedbackFile.propTypes = {
  text: PropTypes.string,
  downloadClick: PropTypes.func,
  rubricUrl: PropTypes.string,
  programTypeExplorer: PropTypes.bool
};

DownloadFeedbackFile.defaultProps = {
  text: 'Download',
  downloadClick: () => { },
  rubricUrl: '',
  programTypeExplorer: false
}

export default DownloadFeedbackFile;