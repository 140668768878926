import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReadOnlyFeedbackComments from 'features/EventOverviewContainer/components/CoachJudging/JudgingResults/ReadOnlyFeedbackComments';
import { CollapsableHeader } from './CollapsableHeader';
import Container from 'shared/components/Container'; 

const ReadonlyFeedbackSection = props => {
  const { rubric, active} = props;
  const { t } = useTranslation();

  return (
    <Container>
      <h3 className='h-1 h--400 heading--charcoal m-b-36'>
        {t('judgingFeedbackLabel')}
      </h3>
      <CollapsableHeader
        leftTitle={t('feedbackCommentsLabel')}
        status={active}
      >
        <ReadOnlyFeedbackComments commentSection='ip' labelKey='innovationProjectLabel' comments={rubric?.comments ? rubric.comments : []}/>
        <ReadOnlyFeedbackComments commentSection='rd' labelKey='robotDesignLabel' comments={rubric?.comments ? rubric.comments : []}/>
        <ReadOnlyFeedbackComments commentSection='cv' labelKey='coreValueLabel' comments={rubric?.comments ? rubric.comments : []}/>
      </CollapsableHeader>

    </Container>
  );
};

ReadonlyFeedbackSection.propTypes = {
  rubric: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {

};

export default connect(mapStateToProps)(ReadonlyFeedbackSection);