import React from 'react';
import BtnPrimary from 'shared/components/Button/BtnPrimary';

const InformationContainer = ({
  title, btnText, handleSubmit, disabled, children
}) => {
  const headingClassName = 'h-2--normal heading--charcoal m-b-24';

  let className = handleSubmit ? 'toc-container__content block' : 'toc-container__content full';

  return (
    <div className='toc-container'>
      <div className={className}>
        {children}
      </div>
      {Boolean(handleSubmit) && (
        <div className='toc-container__action'>
          <BtnPrimary disabled={disabled} onClick={handleSubmit}>
            {btnText}
          </BtnPrimary>
        </div>
      )}
    </div>
  );
};

export default InformationContainer;
