import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {getJudgingObject} from 'features/EventOverviewContainer/redux/actions/coachJudging/feedback';
import {connect} from 'react-redux';
import { ClipLoader } from 'react-spinners';
import RubricModal from './RubricModal';


const normalText = 'paragraph--default paragraph--charcoal paragraph--normal';
const DEFAULT_STATE = {
  isInnovationProjectForm: false,
  isRobotDesignForm: false,
  isFeedbackForm: false
}
const FLLChallenge = props => {
  const {getRubric, isRubricPublished, loadingFeedback, digitalRubric, is_FLLC_event} = props;
  const [state, setState] = useState(DEFAULT_STATE);
  const [visible, setVisible] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    if (digitalRubric) {
      getRubric(digitalRubric);
    }
  }, [digitalRubric])
  
  if (loadingFeedback) {
    return (
      <div className='judging-results-feedback-inner'>
        <ClipLoader size={36} color='#0066B3' />
      </div>
    )
  }

  if (!isRubricPublished) {
    return (
      <div className='judging-results-feedback-inner'>
        <p className={normalText}>
          {is_FLLC_event ? t('emptyFeedbackLabel') : t('nonChallengeEmptyFeedbackLabel')}
        </p>
      </div>
    )
  }

  const openModal = () => {
    setVisible(true);
  }

  const openFeedbackForm = () => {
    setState(prevState => ({
      ...prevState,
      isFeedbackForm: true
    }));
    openModal();
  }

  const openInnovation = () => {
    setState(prevState => ({
      ...prevState,
      isInnovationProjectForm: true
    }));
    openModal();
  }
  const openRobotDesign = () => {
    setState(prevState => ({
      ...prevState,
      isRobotDesignForm: true
    }));
    openModal();
  }

  const closeModal = () => {
    setVisible(false);
    setState(DEFAULT_STATE)
  }

  const rubricOptions = [
    {
      title: 'innovationProjectLabel',
      onClick: openInnovation,
      id: 'innovationProject'
    },
    {
      title: 'robotDesignLabel',
      onClick: openRobotDesign,
      id: 'robotDesign'
    },
    {
      title: 'judgingFeedbackLabel',
      onClick: openFeedbackForm,
      id: 'feedback'
    }
  ]

  return (
    <div>
      <RubricModal
        onCancel={closeModal}
        visible={visible}
        {...state}
      />
      <div className='judging-results-feedback__fll'>
        {rubricOptions.map(opt => (
          <div key={opt.id} className='judging-results-feedback__rubric-form'>
            <button
              type='button'
              className='judging-results-feedback__rubric-modal-btn'
              onClick={
                opt.onClick
              }
            >
              <img
                src='/match-scoresheet.png'
                alt='judging rubric'
                height={153}
              />
            </button>
            <p className='paragraph--medium paragraph--charcoal p-t-8'>{t(opt.title)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

FLLChallenge.propTypes = {
  getRubric: PropTypes.func.isRequired,
  isRubricPublished: PropTypes.bool.isRequired,
  loadingFeedback: PropTypes.bool.isRequired,
  digitalRubric: PropTypes.string.isRequired
};

const mapStateToProps = ({coachJudging}) => {
  const {
    isCoachJudgingObjectPublished,
    isFetchingCoachJudgingObject,
  } = coachJudging;

  return {
    isRubricPublished: isCoachJudgingObjectPublished,
    loadingFeedback: isFetchingCoachJudgingObject,
    
  }
}

export default connect(mapStateToProps, {getRubric: getJudgingObject})(FLLChallenge);