import { API } from 'utils/api';
import Region from 'models/Region';
import { queryStringify } from 'utils/query';

//const { http } = API;
const baseUrl = '/api/regions';


export const getRegionsByPrograms = async (programIds, input) => {
  if (!programIds.length || !input) return [];
  const filter = {
    'program.id': `in:${programIds.join(',')}`,
    'name': `like:${input}`
  };
  const queryStr = queryStringify({ filter });
  const url = `/api/regions${queryStr}`;
  const { data } = await API.GetInstance().http.get(url);
  return data.map((r) => new Region(r));
};

export const getRegionsByProgram = async (programId, input) => {
  if (!programId || !input) return [];
  return await getRegionsByPrograms([programId], input);
};

export const getRegionsByIds = async (regionIds) => {
  if (!regionIds.length) return [];
  const url = `/api/regions?filter[id]=in:${regionIds.join(',')}`;
  const { data } = await API.GetInstance().http.get(url);
  return data.map((r) => new Region(r));
}

export const getRegionsBy = async (filter) => {
  const queryStr = queryStringify({ filter });
  const url = `${baseUrl}${queryStr}`;
  const { data } = await API.GetInstance().http.get(url);
  return data.map((r) => new Region(r));
}
