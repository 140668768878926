const shortLangaugeKeys = (lang)=>{
  let shortLang = lang;
  if (shortLang && shortLang.indexOf('-') !== -1) {
    shortLang = shortLang.split('-')[0];
  }
  
  if (shortLang && shortLang.indexOf('_') !== -1) {
    shortLang = shortLang.split('_')[0];
  }
  return shortLang
}

export default shortLangaugeKeys;