import React from 'react';
import PropTypes from 'prop-types';
import './TitleCard.scss';

const TitleCard = props => {
  const {
    teamNumber,
    teamName,
    title,
    location
  } = props;
  const boldText = 'paragraph--default paragraph--charcoal paragraph--bold'
  return (
    <div>
      <h2 className='h-1 heading--charcoal m-b-14'>{title}</h2>
      <div className='c-title-card__team'>
        <p className={boldText}>{teamNumber}</p>
        <p className={boldText}>{teamName}</p>
        <p className={boldText}>{location}</p>
      </div>
    </div>
  );
};

TitleCard.defaultProps = {
  teamNumber: '',
  teamName: '',
  title: '',
  location: ''
};

TitleCard.propTypes = {
  teamNumber: PropTypes.string,
  teamName: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.string
}

export default TitleCard;