import React from 'react';
import ConfirmModal from 'shared/components/ConfirmModal';
import { useTranslation } from 'react-i18next';


const AwardConfirmModal = (props) => {
  const { isPublished, tabName, isFTC, is_FLLC_event } = props;
  const { t } = useTranslation()
  const action = isPublished ? 'Unpublish' : 'Publish';
  const awardText = !isFTC ? `the list of award winner will be ${isPublished ? 'unpublished' : 'published'} to all event participants` : '';
  const teamText = isPublished ? 'unable access' : 'receive';
  const awardLabel = is_FLLC_event ? 'awardFtcConfirmLabel' : 'nonChallengeAwardFtcConfirmLabel';
  const awardConfirmLabe = is_FLLC_event ? 'awardConfirmLabel' : 'nonChallengeAwardConfirmLabel';
  return (
    <ConfirmModal
      title={`${action} ${tabName}?`}
      description={isFTC ? t(awardLabel, { '0': action, '1': teamText}) : t(awardConfirmLabe, { '0': action, '1': awardText, '2': teamText})}
      {...props}
    />
  );
};

export default AwardConfirmModal;
