import React from 'react';

const style = {
  marginRight: '11px'
}

export default ()=>(
  <svg width="81px" height="27px" viewBox="0 0 81 27" style={style}>
    <title>6F81D720-5F4E-4B9B-9819-8FD0BB23B8B7</title>
    <g id="Judging-and-Awards" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Header---Presented-by-Qualcomm" transform="translate(-1024.000000, -79.000000)" fill="#FFFFFF">
            <g id="Group-18" transform="translate(0.000000, 60.000000)">
                <g id="Group-29" transform="translate(1024.000000, 19.000000)">
                    <g id="Group-43" transform="translate(0.000000, 10.240964)">
                        <path d="M77.0520616,4.55238807 C76.9946146,4.34981014 76.8068536,4.21072678 76.5952067,4.21707624 L75.331675,4.21707624 C75.1197258,4.21072678 74.9322671,4.34981014 74.8751225,4.55238807 L72.8221479,10.1550284 L70.7056794,4.55238807 C70.64793,4.34981014 70.4604714,4.21072678 70.2491268,4.21707624 L69.0034339,4.21707624 C68.791787,4.21072678 68.6043284,4.34981014 68.546579,4.55238807 L66.4727422,10.1550284 L64.4013241,4.55238807 C64.3441795,4.34981014 64.1567208,4.21072678 63.9447716,4.21707624 L62.6990787,4.21707624 C62.4874318,4.21072678 62.3002755,4.34981014 62.239805,4.55238807 L60.1505481,10.1550284 L58.0975736,4.55238807 C58.0398242,4.34981014 57.852668,4.21072678 57.6410211,4.21707624 L56.3738611,4.21707624 C56.1622143,4.21072678 55.975058,4.34981014 55.917611,4.55238807 L52.9575785,12.226161 C52.8910609,12.3806645 52.9602996,12.5587516 53.1144995,12.6252698 C53.1541077,12.6434111 53.1964371,12.6524817 53.2384641,12.6494582 L54.3635183,12.6494582 C54.5781887,12.6645759 54.7716944,12.5285161 54.8321649,12.3229146 L56.9936841,6.526767 L59.1527844,12.3229146 C59.2132549,12.522469 59.4004112,12.6555053 59.6093369,12.6403875 L60.6461041,12.6403875 C60.8550298,12.6555053 61.0424885,12.522469 61.102959,12.3229146 L63.3158781,6.526767 L65.4595584,12.3229146 C65.5263784,12.5164219 65.7105111,12.646737 65.9164133,12.6403875 L67.0048828,12.6403875 C67.2135062,12.6494582 67.4039884,12.5197478 67.4705059,12.3229146 L69.6290015,6.526767 L71.8153136,12.3229146 C71.8757841,12.522469 72.0632428,12.6524817 72.2715638,12.6403875 L73.3086334,12.6403875 C73.5175591,12.6555053 73.7050177,12.522469 73.7654882,12.3229146 L75.9363803,6.57816737 L78.0770371,12.3229146 C78.1405312,12.5197478 78.3279898,12.6494582 78.5332873,12.6403875 L79.6943215,12.6403875 C79.8609178,12.6434111 80,12.5103748 80,12.3410559 C80,12.2987262 79.9909294,12.2563965 79.9758118,12.2173927 L77.0520616,4.55238807 Z" id="Fill-1"></path>
                        <path d="M43.2817689,10.6009143 C43.0741375,10.3251238 42.863497,10.411123 42.6799388,10.5149151 C42.204493,10.8203605 41.6538185,10.9923589 41.0880982,11.0131173 C39.5835229,11.0131173 38.5543935,9.82692171 38.5543935,8.29079843 C38.5543935,6.75467515 39.5684772,5.55365213 41.0880982,5.55365213 C41.6688642,5.56581063 42.2315754,5.73751244 42.7160486,6.05185427 C42.8785427,6.20012872 43.1313114,6.18826676 43.2817689,6.02813036 C43.2938055,6.01330291 43.3058421,5.99847547 43.3178787,5.98364802 L43.8083702,5.31048203 C43.9467912,5.1592421 43.9347546,4.92793396 43.784297,4.79152147 C43.763233,4.77372853 43.7421689,4.75890109 43.7180957,4.74407364 C42.9357166,4.18656172 41.9908433,3.8959438 41.0279152,3.91670223 C38.4219909,3.91670223 36.6917293,5.81164967 36.6917293,8.29079843 C36.6917293,10.7699472 38.4219909,12.6500672 41.0279152,12.6500672 C41.9938525,12.6648946 42.9387258,12.3713112 43.7180957,11.8108338 C43.8956356,11.7159382 43.9618369,11.4967885 43.8655441,11.3185626 C43.8504984,11.2918732 43.8324434,11.2651838 43.8083702,11.2444254 L43.2817689,10.6009143 Z" id="Fill-3"></path>
                        <path d="M27.5171495,11.1445783 C26.019469,11.1445783 24.962406,9.93379458 24.962406,8.42478826 C24.962406,6.91578194 26.0227639,5.72289157 27.5171495,5.72289157 C29.0118348,5.72289157 30.075188,6.90087081 30.075188,8.42478826 C30.075188,9.94870571 28.996858,11.1445783 27.5171495,11.1445783 L27.5171495,11.1445783 Z M31.4974593,4.1570942 L30.4736321,4.1570942 C30.2527481,4.16005929 30.0766379,4.33499904 30.0766379,4.55441501 L30.0766379,5.24231372 C29.4438352,4.38244033 28.4259779,3.88727187 27.354392,3.91692267 C25.0980919,3.91692267 23.1578947,5.69597105 23.1578947,8.2755912 C23.1578947,10.8552113 25.0980919,12.6490851 27.336781,12.6490851 C28.4140382,12.681701 29.4381638,12.1868291 30.0766379,11.3236941 L30.0766379,12.0471738 C30.0858912,12.2576945 30.2590165,12.4267041 30.4736321,12.4267041 L31.4974593,12.4267041 C31.7064036,12.423739 31.8768424,12.2547294 31.8795289,12.0471738 L31.8795289,4.55441501 C31.8857972,4.34122571 31.7183433,4.16302437 31.5037276,4.1570942 L31.4974593,4.1570942 Z" id="Fill-5"></path>
                        <path d="M9.45071132,10.1790592 L8.63455041,8.08010462 C8.55477529,7.8639123 8.33385955,7.72927935 8.09760245,7.74996905 L7.02984307,7.74996905 C6.8365418,7.71728533 6.65551363,7.8432226 6.62176261,8.03212851 C6.60642124,8.1160867 6.62483089,8.19974503 6.670855,8.27200904 L7.75088748,11.0123442 C7.37962631,11.0993009 6.99916033,11.1445783 6.61562607,11.1445783 C3.97691035,11.1445783 2.10526316,9.12328505 2.10526316,6.47560376 C2.10526316,3.82792248 3.97691035,1.80722892 6.61562607,1.80722892 C9.25434178,1.80722892 11.125989,3.82792248 11.125989,6.47560376 C11.1658765,7.89419721 10.5522217,9.25551919 9.45071132,10.1790592 L9.45071132,10.1790592 Z M6.48422588,0 C2.90451957,0 0,2.88433758 0,6.43886836 C0,9.99400319 2.90451957,12.8783408 6.48422588,12.8783408 C7.0803367,12.8783408 7.67036476,12.7998143 8.24518591,12.6424593 L8.94166233,14.4546086 C9.00857273,14.654247 9.20626254,14.7780772 9.41611788,14.7566335 L10.5292636,14.7566335 C10.7969052,14.7566335 10.9885122,14.5814591 10.866857,14.2465135 L9.95444245,11.8967599 C12.9745345,9.9577602 13.8410242,5.95593058 11.8887612,2.95682355 C10.699581,1.13259329 8.67097936,0.0208397165 6.4811845,0 L6.48422588,0 Z" id="Fill-7"></path>
                        <path d="M21.5494773,4.21686747 L20.5117488,4.21686747 C20.302993,4.22570295 20.1335679,4.39662382 20.127517,4.60654267 L20.127517,9.01209652 C20.127517,10.3773307 19.2834172,11.348929 17.8584315,11.348929 C16.4334458,11.348929 15.6619567,10.41054 15.6619567,8.99686293 L15.6619567,4.60654267 C15.6589312,4.3935771 15.4864807,4.21991419 15.2746994,4.21686747 L14.236971,4.21686747 C14.0221642,4.21686747 13.8436628,4.39022571 13.8345865,4.60654267 L13.8345865,9.29574594 C13.8345865,11.4738445 15.1869614,12.9518072 17.3831337,12.9518072 C18.7025313,12.9518072 19.5980637,12.4216783 20.127517,11.6417186 L20.127517,12.3516038 C20.1365933,12.5587806 20.3060184,12.7233034 20.5117488,12.7233034 L21.5494773,12.7233034 C21.7673095,12.7233034 21.9458109,12.5523825 21.9548872,12.3333235 L21.9548872,4.62512765 C21.9518618,4.39967053 21.7733604,4.21991419 21.5494773,4.21686747" id="Fill-9"></path>
                        <path d="M35.1062783,0.301204819 L34.0845405,0.301204819 C33.8664503,0.310212403 33.6931731,0.481356498 33.6842105,0.700541041 L33.6842105,12.2482637 C33.6931731,12.4674482 33.8664503,12.6415948 34.0845405,12.6506024 L35.1062783,12.6506024 C35.321381,12.6475999 35.4916706,12.4704507 35.4886831,12.254569 L35.4886831,12.2482637 L35.4886831,0.685528401 C35.4856956,0.475351442 35.3154059,0.304207347 35.1062783,0.301204819" id="Fill-11"></path>
                        <path d="M48.5697811,11.1445783 C47.0721005,11.1445783 46.0150376,9.94839812 46.0150376,8.43223971 C46.0150376,6.91578227 47.0753954,5.72289157 48.5697811,5.72289157 C50.0644663,5.72289157 51.1278195,6.90382047 51.1278195,8.43223971 C51.1278195,9.96035992 50.0674617,11.1445783 48.5697811,11.1445783 L48.5697811,11.1445783 Z M48.5889262,3.91566265 C46.1802297,3.90678 44.2197525,5.85281592 44.2105263,8.26530625 C44.2016576,10.6777966 46.1449299,12.6413617 48.5533297,12.6506024 C50.9620261,12.6594851 52.9227999,10.7134491 52.9319957,8.30095881 L52.9319957,8.28016148 C52.9613628,5.90035268 51.0569501,3.94540362 48.6811804,3.91566265 L48.5889262,3.91566265 Z" id="Fill-13"></path>
                    </g>
                    <g id="Group-44">
                        <path d="M2.10170942,1.5060241 C2.13630905,1.20763428 1.91429477,0.940209436 1.60866472,0.906429456 C1.57983169,0.903614458 1.55099867,0.903614458 1.52504895,0.903614458 L0.902255639,0.903614458 L0.902255639,2.10843373 L1.52504895,2.10843373 C1.84221221,2.10843373 2.10170942,1.85789889 2.10170942,1.54543407 C2.10459272,1.53417408 2.10170942,1.52009909 2.10170942,1.5060241 L2.10170942,1.5060241 Z M2.45751623e-14,4.51506225 L2.45751623e-14,3.31729289e-14 L1.61450137,3.31729289e-14 C2.38386664,3.31729289e-14 3.0075188,0.644148881 3.0075188,1.43879984 C3.0075188,2.2337518 2.38386664,2.87489064 1.61450137,2.87759967 L0.880107245,2.87759967 L0.880107245,4.51807229 L2.45751623e-14,4.51506225 Z" id="Fill-15"></path>
                        <path d="M6.01148386,1.50583114 C6.04608348,1.20753689 5.8240692,0.940197714 5.51843915,0.906428554 C5.48960613,0.903614458 5.46077311,0.903614458 5.43482338,0.903614458 L4.81203008,0.903614458 L4.81203008,2.10804782 L5.43482338,2.10804782 C5.74333674,2.1193042 6.00283395,1.88292009 6.01436716,1.58209316 C6.01436716,1.55648488 6.01436716,1.53115801 6.01148386,1.50583114 L6.01148386,1.50583114 Z M5.93613754,4.50605855 L5.26068317,2.87519399 L4.78320681,2.87519399 L4.78320681,4.50605855 L3.90977444,4.50605855 L3.90977444,0.000907822303 L5.54018153,0.000907822303 C6.25639607,-0.0261230821 6.85906441,0.553839989 6.88526738,1.29268471 C6.88817882,1.33743587 6.88526738,1.37978429 6.88235594,1.42453545 C6.88235594,2.20242481 6.47766561,2.59587464 6.11082401,2.716012 L6.91729323,4.51807229 L5.93613754,4.50605855 Z" id="Fill-17"></path>
                        <polygon id="Fill-19" points="7.81954887 4.51476146 7.81954887 6.36706217e-14 10.5263158 6.36706217e-14 10.5263158 0.797607859 8.76785932 0.797607859 8.76785932 1.82125477 10.4792143 1.82125477 10.4792143 2.61284295 8.76785932 2.61284295 8.76785932 3.71745459 10.5263158 3.71745459 10.5263158 4.51807229"></polygon>
                        <path d="M11.1278195,4.20905767 L11.5566259,3.48335333 C11.8297697,3.80247716 12.2203947,3.98576402 12.6286419,3.98576402 C13.031015,3.98576402 13.2366071,3.75626778 13.2366071,3.46753669 C13.2366071,2.64600216 11.2599859,2.93721429 11.2599859,1.36175357 C11.2599859,0.627055458 11.8003994,0.000592734183 12.7138158,0.000592734183 C13.2072368,-0.0118124683 13.6859727,0.170854138 14.0560385,0.515408636 L13.6389803,1.22870778 C13.3863957,0.971299827 13.0457002,0.828639998 12.6932566,0.831431169 C12.437735,0.794215562 12.2027726,0.983705029 12.1675282,1.25630935 C12.1645912,1.27181586 12.1645912,1.29073379 12.1645912,1.30624029 C12.1645912,2.03814724 14.1353383,1.82694867 14.1353383,3.39000418 C14.1353383,4.20254494 13.612547,4.81691259 12.634516,4.81691259 C12.0735432,4.8448243 11.5272556,4.62463195 11.1278195,4.20905767" id="Fill-21"></path>
                        <polygon id="Fill-23" points="15.037594 4.51476146 15.037594 6.36706217e-14 17.7443609 6.36706217e-14 17.7443609 0.797607859 15.9796242 0.797607859 15.9796242 1.82125477 17.6878391 1.82125477 17.6878391 2.61284295 15.9796242 2.61284295 15.9796242 3.71745459 17.7380807 3.71745459 17.7380807 4.51807229"></polygon>
                        <polygon id="Fill-25" points="21.0874383 4.51174699 19.5439775 1.75873494 19.5439775 4.51807229 18.6466165 4.51807229 18.6466165 0 19.5619247 0 21.0575263 2.65662651 21.0575263 0 21.9548872 0 21.9548872 4.51807229"></polygon>
                        <polygon id="Fill-27" points="23.8936848 4.51807229 23.8936848 0.797962001 22.8571429 0.797962001 22.8571429 3.31729289e-14 25.8646617 3.31729289e-14 25.8646617 0.797962001 24.8250255 0.797962001 24.8250255 4.51807229"></polygon>
                        <polygon id="Fill-29" points="26.4661654 4.51476146 26.4661654 6.36706217e-14 29.1729323 6.36706217e-14 29.1729323 0.797607859 27.4081957 0.797607859 27.4081957 1.82125477 29.1164105 1.82125477 29.1164105 2.61284295 27.4081957 2.61284295 27.4081957 3.71745459 29.1666521 3.71745459 29.1666521 4.51807229"></polygon>
                        <path d="M32.481203,2.41243324 C32.481203,1.48087194 32.1442023,0.903614458 31.4985203,0.903614458 L30.9774436,0.903614458 L30.9774436,3.91566265 L31.4928564,3.91566265 C32.1300426,3.91566265 32.481203,3.30673208 32.481203,2.41243324 L32.481203,2.41243324 Z M30.075188,4.51476146 L30.075188,6.36706217e-14 L31.4748409,6.36706217e-14 C32.6923077,6.36706217e-14 33.3834586,0.948099907 33.3834586,2.26069156 C33.3834586,3.57268124 32.6923077,4.51807229 31.4806246,4.51807229 L30.075188,4.51476146 Z" id="Fill-31"></path>
                        <path d="M38.4913788,3.3174184 C38.5223193,3.01265623 38.2885463,2.7439412 37.9688273,2.71444809 C37.9413246,2.71084337 37.9172597,2.71084337 37.889757,2.71084337 L36.9924812,2.71084337 L36.9924812,3.91350698 L37.889757,3.91350698 C38.1957247,3.93972308 38.4673139,3.72376795 38.4948166,3.43211384 C38.4982544,3.39246198 38.4948166,3.35674255 38.4913788,3.3174184 L38.4913788,3.3174184 Z M38.1913735,1.35206307 C38.2204728,1.13155372 38.0458768,0.930149879 37.8014425,0.906268 C37.7723432,0.903614458 37.7432438,0.903614458 37.7170544,0.903614458 L36.9924812,0.903614458 L36.9924812,1.80581876 L37.7170544,1.80581876 C37.9614888,1.82439356 38.1739139,1.6572204 38.1942834,1.43697641 C38.1971933,1.40778745 38.1942834,1.38125203 38.1913735,1.35206307 L38.1913735,1.35206307 Z M36.0902256,4.51281397 L36.0902256,0.00542158052 L38.0602307,0.00542158052 C38.6907578,-0.0543764918 39.2491351,0.387347962 39.3118741,0.991338542 C39.318148,1.04512676 39.318148,1.09951596 39.315011,1.15360467 C39.3526544,1.62838333 39.0326854,2.061093 38.552732,2.18729999 C39.0734658,2.31350698 39.4248043,2.77626593 39.3934348,3.29010866 C39.4530369,3.90912388 38.9793573,4.45602082 38.3362824,4.51281397 C38.2704064,4.51912432 38.2045304,4.51912432 38.1386545,4.51611939 L36.0902256,4.51281397 Z" id="Fill-33"></path>
                        <polygon id="Fill-35" points="40.7679393 4.5027221 40.7679393 2.65467974 39.3984962 6.36706217e-14 40.37083 6.36706217e-14 41.2059274 1.80590459 42.0351851 6.36706217e-14 43.0075188 6.36706217e-14 41.6468355 2.65467974 41.6468355 4.51807229"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)