import React from 'react';
import PropTypes from 'prop-types';
import CardTile from 'shared/components/CardsList/CardTile';
import './UserTile.scss'

const UserTile = (props) => {
  return (
    <CardTile
      {...props}
      rowClassName='user-tile__last-row'
      renderInfo={() => (
        <img src='/FIRST_Horz_RGB.png' alt='FIRST logo' className='user-tile__logo--small' />
      )}
    />
  );
};

UserTile.propTypes = {
  email: PropTypes.string,
  fullName: PropTypes.string,
};

UserTile.defaultProps = {
  email: '',
  fullName: '',
};

export default UserTile;
