const m05Section = {
  id: 'm05',
  name: '2025ScoresheetM05Sub1Label',
  fields: [
    {
      scoring: "2025ScoresheetM05Sub2Label",
      id: 'm05_scoring_1',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        }
      ]
    },
  ]
}

export default m05Section;