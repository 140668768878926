/* eslint-disable import/prefer-default-export */
import { API } from 'utils/api';
import * as types from 'features/EventContainer/redux/types/eventDetailsTypes';
import * as eventRoleService from 'utils/services/eventRoles';
import { formatUserEventRoleTypesList } from 'utils/services/eventRoles/model';
import * as userTypes from 'shared/constants/userTypes';
import { adminEventRoles } from 'shared/constants/eventRoleTypes';
import { setScheculeSettingsData } from './scheduleActions/scheduleDetails';
import { editEvent } from "utils/services/events";

const getEventDetailsBegin = () => {
  return {
    type: types.GET_EVENT_DETAILS_BEGIN
  }
}

const getEventDetailsError = () => {
  return {
    type: types.GET_EVENT_DETAILS_ERROR
  }
}

export const getEventDetailsSuccess = (eventDetails, eventIncluded) => {
  return {
    type: types.GET_EVENT_DETAILS_SUCCESS,
    payload: {
      eventDetails,
      eventIncluded
    }
  }
}

export const setEventProgramId = (eventProgramId) => {
  return {
    type: types.SET_EVENT_PROGRAM_ID,
    payload: {
      eventProgramId
    }
  }
}

export const setEventRegionId = (eventRegionId) => {
  return {
    type: types.SET_EVENT_REGION_ID,
    payload: {
      eventRegionId
    }
  }
}

const setEventScheduleId = (id) => {
  return {
    type: types.SET_EVENT_SCHEDULE_ID,
    payload: {
      eventScheduleId: id
    }
  }
}

const setAdminEventTimezone = (timezone) => {
  return ({
    type: types.SET_ADMIN_EVENT_TIME_ZONE,
    payload: timezone
  })
}

export const handleEventDetails = (eventId) => {
  return async dispatch => {
    await dispatch(getEventDetailsBegin());
    try {
      const response = await API.GetInstance().http.get(`/api/events/${eventId}`);
      const { data, included } = response;

      const eventTimeZone = (((data||{}).attributes||{}).timezone);
      await dispatch(setAdminEventTimezone(eventTimeZone));

      const eventProgramData = included.find(({ type }) => type === 'program') || {};
      const eventProgramId = eventProgramData.id;

      const eventRegionData = included.find(({ type }) => type === 'region') || {};
      const eventRegionId = eventRegionData.id;

      const scheduleId = ((((data||{}).relationships||{}).schedule||{}).data||{}).id;

      dispatch(setEventScheduleId(scheduleId));
      dispatch(setEventProgramId(eventProgramId));
      dispatch(setEventRegionId(eventRegionId));
      dispatch(getEventDetailsSuccess(data, included));
      dispatch(setScheculeSettingsData({time_zone: ((data||{}).attributes||{}).timezone}))
    } catch (err) {
      dispatch(getEventDetailsError())
      throw err;
    }
  };
};

const getUserEventAssignmentsBegin = () => {
  return {
    type: types.GET_USER_EVENT_ASSIGNMENTS_BEGIN
  }
}

const getUserEventAssignmentsError = () => {
  return {
    type: types.GET_USER_EVENT_ASSIGNMENTS_ERROR
  }
}

const getUserEventAssignmentsSuccess = (userEventRoleIds = []) => {
  return {
    type: types.GET_USER_EVENT_ASSIGNMENTS_SUCCESS,
    payload: {
      userEventRoleIds
    }
  }
}


export const getUserEventAssignments = (eventId) => {
  return async (dispatch, getState) => {
    const { auth: { userId, userType } } = getState();
    await dispatch(getUserEventAssignmentsBegin())
    try {
      if (userType === userTypes.USER) {
        const filter = {
          'event.id': `eq:${eventId}`,
          'user.id': `eq:${userId}`
        }

        const { included } = await eventRoleService.getUserEventRolesBy(filter);

        let userEventRoles = formatUserEventRoleTypesList(included);
        userEventRoles = userEventRoles.filter(e => adminEventRoles.includes(e));
        await dispatch(getUserEventAssignmentsSuccess(userEventRoles))
      } else {
        await dispatch(getUserEventAssignmentsSuccess([]))
      }

    } catch (err) {
      await dispatch(getUserEventAssignmentsError())

      throw err;
    }

  }


}

export const startChangingTimeZone = ()=>{
  return {type: types.START_CHANGING_SCHEDULE_TIME_ZONE}
}

export const finishChangingTimeZone = (time_zone)=>{
  return {type: types.FINISH_CHANGING_SCHEDULE_TIME_ZONE, payload: time_zone}
}

export const handleEventTimeZoneUpdate = (timezone)=>{
  return async (dispatch, getState)=>{
    const {event: {eventDetails: {id}}} = getState();
    try {
      await dispatch(startChangingTimeZone());
      const payload = {
        data: {
          type: 'event',
          id: id,
          attributes: {timezone: timezone}
        }
      };
      await editEvent(id ,payload);
      await dispatch(finishChangingTimeZone(timezone));
    }catch(e){
      dispatch(finishChangingTimeZone());
    }
  }
}