import * as programsTypes from '../types/programsTypes';

const defaultProgramsState = {
  list: [],
  countries: [],
  regions: [],
  isFetchingProgramTypes: false,
  isFetchingRegions: false,
  isFetchingCountries: false,
};

export default (state = defaultProgramsState, action) => {
  switch (action.type) {
    case programsTypes.FETCH_PROGRAMS_BEGIN: {
      return {
        ...state,
        isFetchingProgramTypes: true,
      };
    }
    case programsTypes.FETCH_PROGRAMS_ERROR: {
      return {
        ...state,
        list: [],
        isFetchingProgramTypes: false,
      };
    }
    case programsTypes.FETCH_PROGRAMS_SUCCESS: {
      return {
        ...state,
        list: action.payload,
        isFetchingProgramTypes: false,
      };
    }
    case programsTypes.FETCH_PROGRAM_REGIONS_BEGIN: {
      return {
        ...state,
        isFetchingRegions: true
      }
    }
    case programsTypes.FETCH_PROGRAM_REGIONS_SUCCESS: {
      const { programId, regions } = action.payload;
      const programs = state.list;
      return {
        ...state,
        regions,
        isFetchingRegions: false,
        list: programs.map((p) => 
          p.id === programId ? { ...p, regions } : p
        )
      };
    }
    case programsTypes.FETCH_PROGRAM_REGIONS_ERROR: {
      return {
        ...state,
        isFetchingRegions: false
      };
    }
    case programsTypes.GET_COUNTRIES_BEGIN: {
      return {
        ...state,
        isFetchingCountries: true
      };
    }
    case programsTypes.GET_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: action.payload,
        isFetchingCountries: false
      };
    }
    case programsTypes.GET_COUNTRIES_ERROR: {
      return {
        ...state,
        isFetchingCountries: false
      };
    }
    default:
      return state;
  }
};
