const rd4 = {
  id: 'RD4',
  label: 'rd4Label',
  title: 'iterateLabel',
  description: 'iterate1DescriptionLabel',
  fields: [
    {
      legendTitle: 'ChallengeRubricsTestingLabel',
      id: 'robot_design_4_iterate_testing',
      isCoreValue: false,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricsTesting1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricsTesting2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricsTesting3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricsTesting4Label',
      }]
    },
    {
      legendTitle: 'improvementsLabel',
      id: 'robot_design_4_iterate_improvements',
      isCoreValue: true,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricsImprovements1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricsImprovements2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricsImprovements3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricsImprovements4Label',
      }]
    },
  ]
}

export default rd4;