import React from 'react';
import PropTypes from 'prop-types';

const SignUpStepperHeader = props => {
  const {currentStep, totalSteps, handleBack} = props;
  return (
    <section className='registration__stepper-header'>
      { currentStep !== 1 && currentStep !== 3 && currentStep !== 4
        ? <p className='paragraph--white paragraph--bold paragraph--small registration__stepper-header__back' onClick={handleBack}>{'< Back'}</p>
        : <div/> }
      <p className='paragraph--white paragraph--bold paragraph--small'>
        Step
        {' '}
        {currentStep}
        {' '}
        of
        {' '}
        {totalSteps}
      </p>
    </section>
  );
};

SignUpStepperHeader.propTypes = {
  
};

export default SignUpStepperHeader;