import * as eventFileService from 'utils/services/eventFiles';
import {formatMatchFile} from 'utils/services/eventFiles/model';
import * as types from '../../types/coachScoringTypes';
import get from 'lodash/get';
import { find } from 'lodash';
import { updateGlobalUploadModalProps } from 'features/GlobalUploadModal/redux/actions';

const uploadFileBegin = (coachMatches) => {
  return {
    type: types.UPLOAD_COACH_MATCH_FILE_BEGIN,
    payload: {
      coachMatches
    }
  }
}

const uploadFileSuccess = (coachMatches) => {
  return {
    type: types.UPLOAD_COACH_MATCH_FILE_SUCCESS,
    payload: {
      coachMatches
    }
  }
}

const uploadFileError = (coachMatches) => {
  return {
    type: types.UPLOAD_COACH_MATCH_FILE_ERROR,
    payload: {
      coachMatches
    }
  }
}

const uploadFileRetry = (uploadRetry) => {
  return {
    type: types.UPLOAD_COACH_MATCH_FILE_RETRY,
    payload: {
      uploadRetry,
    }
  }
}

const handleDocumentUpload = (matchId, teamId, file, onProgress, onErrorFallback) => {
  return async (dispatch, getState) => {
    const {coachScoring: { coachMatches, uploadRetry }} = getState();
    const coachMatchesCopy = [...coachMatches];
    const originalCopy = [...coachMatches]; 
    let fileId
    // console.log('getstate', coachMatches)
    
    try {
      const matchIndex = coachMatchesCopy.findIndex(i => i.matchId === matchId);
      const selectedMatch = coachMatchesCopy[matchIndex];
      const uploadingMatchFile = {
        ...selectedMatch,
        loading: true
      }
      coachMatchesCopy[matchIndex] = uploadingMatchFile;

      await dispatch(uploadFileBegin(coachMatchesCopy));
      dispatch(updateGlobalUploadModalProps({modalClosable: false}))

      // Initiate file upload and receive S3 presigned url + fileId
      const initFileUploadResponse = await eventFileService.initiateMatchEventFileUpload(matchId, teamId, file)
      const presignedUrlObject = initFileUploadResponse.included && find(initFileUploadResponse.included, response => response.type === 'pre-signed_url')
      const presignedUrl = get(presignedUrlObject, ['attributes', 'url'], undefined)
      fileId = get(initFileUploadResponse, ['data', 'id'], undefined)
      if (!presignedUrl || !fileId) throw 'missingUrl'

      // Upload file with S3 presigned url, attach caculateProgress callback for progress bar modal
      await eventFileService.uploadFileToS3(presignedUrl, file, onProgress)

      // Edit match event file to have 'Uploaded' status
      const editMatchEventFileResponse = await eventFileService.editMatchEventFile(matchId, fileId, { status: 'Uploaded' })

      const newMatchFile = formatMatchFile(editMatchEventFileResponse);
      const updatedSessionDocument = {
        ...selectedMatch,
        files: [...selectedMatch.files, newMatchFile],
        loading: false
      }
      coachMatchesCopy[matchIndex] = updatedSessionDocument;
      await dispatch(uploadFileSuccess(coachMatchesCopy))
      dispatch(updateGlobalUploadModalProps({modalClosable: true}))

    } catch(error) {
      // Retry up to 3 times if upload request stalls or times out
      if (get(error, ['config', 'onUploadProgress'], false) && uploadRetry < 3) {
        dispatch(uploadFileError(originalCopy))
        dispatch(uploadFileRetry(uploadRetry + 1))
        if (fileId) await eventFileService.editMatchEventFile(matchId, fileId, { status: 'UploadFailed' })
        await dispatch(handleDocumentUpload(matchId, teamId, file, onProgress, onErrorFallback))
      } else {
        if (error === 'missingUrl') console.warn('Missing presigned url')
        dispatch(uploadFileError(originalCopy))
        if (fileId) await eventFileService.editMatchEventFile(matchId, fileId, { status: 'UploadFailed' })
        onErrorFallback(error)
      }
    }
  }
}

export default handleDocumentUpload;