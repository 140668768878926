import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'shared/components/Carousel';
import '../../../styles/eventScoringMatch/EventScoringEvidence.scss';
import Container from 'shared/components/Container';


const EvidenceCarousel = ({files, matchId}) => {
  return (
    <div className='event-scoring-evidence'>
      <Container>
        <Carousel carouselType='scoring' files={files} matchId={matchId} />
      </Container>
    </div>
  );
};

EvidenceCarousel.propTypes = {
  files: PropTypes.array,
  matchId: PropTypes.string.isRequired
};

EvidenceCarousel.defaultProps = {
  files: []
};

export default EvidenceCarousel;