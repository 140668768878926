import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../../styles/ScheduleContainer/EventScheduleCard.scss';
import Icon from '@ant-design/icons';
import AlertIcon from '../../../../../shared/components/Icon/icons/AlertIcon.js'
import TimestampModal from '../TimestampModal';
import { sessionTypesTranslations } from 'shared/constants/sessionTypesTranslations';

const IncompleteTimestampCard = (props) => {
  const { t } = useTranslation()
  const {
    data,
    cb,
    canEditSchedule,
    time_zone,
    is_FLLC_event
  } = props;

  const [visible, setVisible] = useState(false);

  const openModal = () => {
    setVisible(true)
  }

  const closeModal = () => {
    setVisible(false);
    cb();
  }

  const translatedCardTitle = sessionTypesTranslations(is_FLLC_event)[data.timestampTypeId];
  const cardTitle = translatedCardTitle ? t(translatedCardTitle) : data.getTimestampDescription();
  const timestampId = data.id;

  return (
    <>
      <TimestampModal timestampId={timestampId} title={cardTitle} visible={visible} onCancel={closeModal} time_zone={time_zone}/>
      <button disabled={!canEditSchedule} type='button' className='event-schedule-card-btn' onClick={openModal}>
        <div className='event-schedule-card'>
          <div className='event-schedule-card__rectangle event-schedule-card__rectangle--incomplete'>
            <Icon component={AlertIcon} className='event-schedule-card__alert-icon' />
          </div>
          <div className='event-schedule-card__data event-schedule-card__data--incomplete'>
            <p className='paragraph--default paragraph--bold paragraph--charcoal'>{cardTitle}</p>
            <div className='event-schedule-card__data__date-time'>
              
              <p className='paragraph--medium paragraph--red'>{t('setDateLabel')}</p>
          
             
              <p className='paragraph--medium paragraph--red'>{t('setTimeLabel')}</p>

       
            </div>
          </div>
        </div>
      </button>
    </>
  );
};

IncompleteTimestampCard.propTypes = {
  data: PropTypes.object,
  cb: PropTypes.func,
  canEditSchedule: PropTypes.bool
}

IncompleteTimestampCard.defaultProps = {
  data: {},
  cb: () => {},
  canEditSchedule: false
}

export default IncompleteTimestampCard;