/* eslint-disable import/prefer-default-export */
import { API } from 'utils/api';
import Panel from 'models/Panel';
import { parallelRequest, queryStringify } from 'utils/query';
import {
  formatCreateJudgingPanelPayload,
  formatCreateScoringPanelPayload
} from './model';

//const { http } = API;
const baseUrl = '/api/panels'

export const formatPanelData = (p) => {
  const panelObj = {};
  panelObj.name = p.name;
  panelObj.id = p.id;
  panelObj.panelTypeId = p.panelType;
  panelObj.panelTypeName = p.getPanelTypeName();
  panelObj.judgesIds = p.getPanelJudgesIds();
  panelObj.refereesIds = p.getPanelRefereesIds();
  panelObj.sessionsIds = p.getPanelSessionsIds();
  return panelObj;
}

export const createNewPanel = async (payload) => {
  const url = baseUrl;
  const { data, included } = await API.GetInstance().http.post(url, payload);
  return {
    data,
    included: included || []
  };
}


export const fetchPanelById = async (panelId) => {
  const url = `${baseUrl}/${panelId}`;
  const { data, included } = await API.GetInstance().http.get(url);

  return formatPanelData(new Panel(data, included));
}

export const getPanelById = async (panelId) => {
  const url = `${baseUrl}/${panelId}`;
  const { data, included } = await API.GetInstance().http.get(url);

  return new Panel(data, included);
}

export const getAllPanelsByIds = async (ids) => {
  let result = [];
  const valid_ids = ids.filter(id => id);
  await parallelRequest(valid_ids.map(id => API.GetInstance().http.get(`${baseUrl}/${id}`))).then(values => {
    values.forEach(value => {
      const { data, included } = value;
      result.push(new Panel(data, included))
    })
  })
  return result;
}


export const updatePanel = async (panelId, payload) => {
  const url = `${baseUrl}/${panelId}`;
  const response = await API.GetInstance().http.patch(url, payload);
  return response;
}

export const getPanelsByPanelsIdsAndJudgeId = async (panelsIds) => {
  if (panelsIds) {
    let data = [];
    let included = [];
    const result = await getAllPanelsByIds(panelsIds);
    result.forEach(r => {
      data.push(r)
      included = [...included, ...r.included];
    })
    return {
      data,
      included: included || []
    }
  }
  return {
    data: [],
    included: []
  }
}

export const deleteJudgesFromPanel = async (panelId, data) => {

  const url = `${baseUrl}/${panelId}/relationships/judges`;
  await API.GetInstance().http.delete(url, { data });
}



export const deleteRefereesFromPanel = async (panelId, data) => {

  const url = `${baseUrl}/${panelId}/relationships/referees`;
  await API.GetInstance().http.delete(url, { data });

}


export const deleteSessionsFromPanel = async (panelId, data) => {

  const url = `${baseUrl}/${panelId}/relationships/sessions`;
  await API.GetInstance().http.delete(url, { data });
}


export const deletePanelByID = async (panelId) => {

  const url = `${baseUrl}/${panelId}`;
  return await API.GetInstance().http.delete(url);
}


export const getPanelsBy = async (filter) => {
  const queryStr = queryStringify({ filter });
  const url = `${baseUrl}${queryStr}`;
  const { data, included } = await API.GetInstance().http.get(url);
  return { data, included: included || [] };
}

export const createJudgingPanel = async (panelName, sessionIds = [], judgesIds = [], scheduleId) => {
  const payload = formatCreateJudgingPanelPayload(panelName, sessionIds, judgesIds, scheduleId);
  const { data, included } = await API.GetInstance().http.post(baseUrl, payload);
  return {
    data,
    included: included || []
  };
}

export const createScoringPanel = async (panelName, sessionIds = [], refereeIds = [], scheduleId) => {
  const payload = formatCreateScoringPanelPayload(panelName, sessionIds, refereeIds, scheduleId);
  const { data, included } = await API.GetInstance().http.post(baseUrl, payload);
  return {
    data,
    included: included || []
  };
}

export const batchCreatePanel = async (payload) => {
  const res = await API.GetInstance().http.post(`${baseUrl}/bulk_panel_creation_request`, payload);
  return res
}

export const autoAssignPanels = async (scheduleID, panel_type_id) => {
  const url = `${baseUrl}/schedule/${scheduleID}/panel_type/${panel_type_id}/auto_assign`;
  const response = await API.GetInstance().http.post(url, {});
  return response
  // return { data, included: included || [] };
}
