import { notification } from 'antd';
import { parseDateString, parseTime ,todayMomentObject, momentize} from 'utils/dateTimeUtils';
import * as eventService from 'utils/services/events';
import * as meetingService from 'utils/services/meetings';
import * as scheduleService from 'utils/services/schedules';
import { formatTeamResData } from 'utils/services/teams/model';
import * as types from '../../types/eventScheduleTypes';


const formatPatchMeetingPayload = (values, meetingId = null) => {
  const meetingPayload = {
    data: {
      type: 'meeting',
      id: meetingId,
      attributes: {
        end_time: values.endTime,
        start_time: values.startTime,
        meeting_url: values.meetingUrl,
        notes: values.notes,
        title: values.title
      },
      relationships: {
        attendees: {
          data: values.attendees.map(a => ({ type: 'user', id: a }))
        },
        teams: {
          data: values.teams.map(t => ({ type: 'team', id: t }))
        },
      }
    }
  }

  return meetingPayload;
}

const formatNewMeetingPayload = (values, scheduleId) => {
  const meetingPayload = {
    data: {
      type: 'meeting',
      attributes: {
        end_time: values.endTime,
        start_time: values.startTime,
        meeting_url: values.meetingUrl,
        notes: values.notes,
        title: values.title
      },
      relationships: {
        schedule: {
          data: {
            type: 'schedule',
            id: scheduleId
          },
        },
        attendees: {
          data: values.attendees.map(a => ({ type: 'user', id: a }))
        },
        teams: {
          data: values.teams.map(t => ({ type: 'team', id: t }))
        },
      }
    }
  }

  return meetingPayload;
}



const getMeetingSessionsBegin = () => {
  return {
    type: types.GET_SCHEDULE_MEETING_SESSIONS_BEGIN
  }
}

const getMeetingSessionsError = () => {
  return {
    type: types.GET_SCHEDULE_MEETING_SESSIONS_ERROR
  }
}

export const getMeetingSessionsSuccess = (meetingSessions = []) => {
  return {
    type: types.GET_SCHEDULE_MEETING_SESSIONS_SUCCESS,
    payload: {
      meetingSessions
    }
  }

}

export const getScheduleMeetingSessions = (scheduleId) => {
  return async (dispatch, getState) => {
    await dispatch(getMeetingSessionsBegin())
    try {
      const { event: { eventDetails: { eventScheduleId } } } = getState();
      const { data, included } = await scheduleService.getScheduleMeetings(eventScheduleId || scheduleId);


      const meetingsList = data.map(d => {
        const meetingLinkId = (d.relationships.external_link && d.relationships.external_link.data) ? d.relationships.external_link.data.id : undefined;
        let externalLink;
        const externalLinkDetails = included.find(i => i.id === meetingLinkId);
        if (externalLinkDetails) {
          externalLink = externalLinkDetails.attributes ? externalLinkDetails.attributes.url : '';
        }
        const { attributes, id } = d;
        const twentyTwoHoursBefore = momentize(attributes.start_time) ? momentize(attributes.start_time).subtract(22, 'hours') : false
        const isJoinLinkDisabled = twentyTwoHoursBefore ? todayMomentObject().isBefore(twentyTwoHoursBefore) : false;
      
        return {
          startTime: attributes.start_time,
          startDate: attributes.start_time,
          endTime: attributes.end_time,
          meetingUrl: externalLink,
          title: attributes.title,
          isJoinLinkDisabled,
          id
        }
      })

      await dispatch(getMeetingSessionsSuccess(meetingsList))

    } catch (err) {
      await dispatch(getMeetingSessionsError())
      throw err;
    }
  }
}


const getTeamsBegin = () => {
  return {
    type: types.GET_SCHEDULE_MEETING_TEAMS_BEGIN
  }
}

const getTeamsError = () => {
  return {
    type: types.GET_SCHEDULE_MEETING_TEAMS_ERROR
  }
}

const getTeamsSuccess = (teamOptions) => {
  return {
    type: types.GET_SCHEDULE_MEETING_TEAMS_SUCCESS,
    payload: { teamOptions }
  }
}



export const getEventTeams = (eventId) => {
  return async (dispatch) => {
    await dispatch(getTeamsBegin())
    try {
      const { data } = await eventService.getEventTeams(eventId);
      const teamOptions = data.map(d => formatTeamResData(d));
      await dispatch(getTeamsSuccess(teamOptions))

    } catch (err) {
      dispatch(getTeamsError())
      throw err;
    }

  }

}

const createNewMeetingBegin = () => {
  return {
    type: types.CREATE_NEW_SCHEDULE_MEETING_BEGIN
  }
}

const createNewMeetingError = () => {
  return {
    type: types.CREATE_NEW_SCHEDULE_MEETING_ERROR
  }
}

const createNewMeetingSuccess = () => {
  return {
    type: types.CREATE_NEW_SCHEDULE_MEETING_SUCCESS
  }
}

export const createMeeting = (sessionPayload, cb = () => { }) => {
  return async (dispatch, getState) => {
    await dispatch(createNewMeetingBegin())
    try {
      const { event: { eventDetails: { eventScheduleId } } } = getState();

      const formattedMeetingPayload = formatNewMeetingPayload(sessionPayload, eventScheduleId);

      await meetingService.createNewMeeting(formattedMeetingPayload)



      await dispatch(createNewMeetingSuccess())
      notification.success({
        message: 'Success',
        description: 'New meeting created',
      });
      cb();

    } catch (err) {
      console.log({ err })
      notification.error({
        message: 'Error',
        description: err.data.error,
      });
      await dispatch(createNewMeetingError())
      throw err;
    }
  }
}


// EDIT AN EXISTING MEETING

const updateMeetingBegin = () => {
  return {
    type: types.SAVE_SCHEDULE_MEETING_BEGIN
  }
}

const updateMeetingError = () => {
  return {
    type: types.SAVE_SCHEDULE_MEETING_ERROR
  }
}

const updateMeetingSuccess = () => {
  return {
    type: types.SAVE_SCHEDULE_MEETING_SUCCESS
  }
}

export const handleUpdateMeeting = (values, meetingId, cb = () => { }) => {
  return async (dispatch) => {
    await dispatch(updateMeetingBegin())
    try {
      const { patchPayload, removedAttendees, removedTeams } = values;
      const formattedPatchPayload = formatPatchMeetingPayload(patchPayload, meetingId);
      await meetingService.updateMeeting(meetingId, formattedPatchPayload)

      if (removedAttendees.length) {
        const formattedRemovedAttendees = removedAttendees.map(i => ({ type: 'meeting_attendee', id: i }))
        await meetingService.deleteAttendeesFromMeeting(meetingId, { data: formattedRemovedAttendees })
      }

      if (removedTeams.length) {
        const formattedRemovedTeams = removedTeams.map(i => ({ type: 'meeting_team', id: i }));
        await meetingService.deleteTeamsFromMeeting(meetingId, { data: formattedRemovedTeams })
      }
      await dispatch(updateMeetingSuccess())
      notification.success({
        message: 'Success',
        description: 'Meeting successfully saved.'
      });
      cb()
    } catch (err) {
      notification.error({
        message: 'Error',
        description: err.data.error,
      });
      dispatch(updateMeetingError())
      throw err;
    }
  }
}

