import React, { useState, useEffect } from 'react';
import Icon from '@ant-design/icons';
import { getSponsorFile } from 'utils/services/eventFiles';
import ImageIcon from 'shared/components/Icon/icons/Image';
import Paragraph from 'shared/components/Paragraph';
import get from 'lodash/get'
import find from 'lodash/find'

export default ({ id, name, setLen, hideErrorImage }) => {
  const [src, setUrl] = useState('');
  const [error, setError] = useState();

  useEffect(() => {
    const getFile = async () => {
      try{
        const response = await getSponsorFile(id);
        const presignedUrlObject = response.included && find(response.included, res => res.type === 'pre-signed_url')
        const presignedUrl = get(presignedUrlObject, ['attributes', 'url'], '')
        setUrl(presignedUrl);
      }catch(e){
        // console.log(e)
        if (e?.status == '404') {
          hideErrorImage && setError('not found')
        }
      }
    };
    getFile();
  }, [id]);

  useEffect(()=>{
    if(error){
      setLen(prevLen => prevLen - 1);
    }
  },[error])

  if(error) return null;

  return (
    <div className='c-event-sponsors-list__sponsor-logo'>
      {src 
        ? <><img src={src} width={218} alt={name} /> 
          <Paragraph size='medium'>{name}</Paragraph></>
        : <>
          <Icon component={ImageIcon} className='c-event-sponsors-list__no-logo' />
          <Paragraph size='medium'>{name}</Paragraph>
        </>
      }
    </div>
  );
};
