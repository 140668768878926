import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './CommentTile.scss';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import { connect } from 'react-redux';
import EditIcon from 'shared/components/Icon/icons/EditIcon';
import CloseCircle from 'shared/components/Icon/icons/CloseCircle';
import * as userTypes from 'shared/constants/userTypes';
import * as eventRoleTypes from 'shared/constants/eventRoleTypes';
import ConfirmationModal from 'shared/components/ConfirmModal';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const metaTextClassName = 'paragraph--medium paragraph--charcoal paragraph--normal'
const paragraphClassName = 'paragraph--xlarge paragraph--charcoal paragraph--normal'

const CommentTile = props => {
  const {
    commentId,
    fullName,
    dateTime,
    comments,
    commentType,
    deleteClick,
    userId,
    signedInUserId,
    onEdit,
    userEventAssignments,
    disableEdit,
    isEditable,
    rubricEventId,
    userType,
    eventName,
    use_24_hour_format
  } = props;

  const { t } = useTranslation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const isAllowedToEditOrDelete = (userID) => {

    let isUserIsEventAdmin = isUserTypeMatch(eventRoleTypes.EVENT_ADMIN_ID)
    let isUserIsJudgeAdvisor = isUserTypeMatch(eventRoleTypes.JUDGE_ADVISOR_ID)

    if (userID == signedInUserId
      || userType === userTypes.PARTNER_ADMIN
      || userType === userTypes.FIRST_ADMIN
      || isUserIsEventAdmin
      || isUserIsJudgeAdvisor) {
      return true
    }

    return false
  }

  const handleDelete = () => {
    setShowDeleteModal(true)
  }

  const isUserTypeMatch = (roleID) => {
    let match = false
    userEventAssignments.forEach(data => {
      if (data.roleId == roleID && data.eventId == rubricEventId) {
        match = true
      }
    });

    return match
  }

  const formatDateTime = (date, is24Hour) => {
    if (!moment(date, 'h:mma M/D/YYYY').isValid()) return '';
    return moment(date, 'h:mma M/D/YYYY').format(is24Hour ? 'HH:mm M/D/YYYY' : 'h:mma M/D/YYYY')
  }


  return (
    <div className={`c-comment-tile c-comment-tile--${commentType}`}>
      <div className='c-comment-tile__meta with-right-margin'>
        <p className={metaTextClassName}>{fullName}</p>
        <p className={metaTextClassName}>{formatDateTime(dateTime, use_24_hour_format)}</p>
      </div>
      <div className='c-comment-tile__comments'>
        <p className={paragraphClassName}>{comments}</p>
      </div>
      {/* {(userId === signedInUserId) ? ( */}
      {onEdit && !disableEdit && isAllowedToEditOrDelete(userId) ? (
        <Button className='c-comment-tile__delete' shape='circle' type='link' onClick={() => handleDelete()}>
          <Icon component={CloseCircle} />
        </Button>
      ) : (null)}
      {/* {onEdit && (userId === signedInUserId) && !disableEdit */}
      {isEditable && onEdit && !disableEdit && isAllowedToEditOrDelete(userId)
        ? <Icon
          className='c-comment-tile__edit'
          component={EditIcon}
          onClick={e => onEdit(commentId)}
        />
        : null}

      <ConfirmationModal
        visible={showDeleteModal}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onSubmit={() => {
          // deletePanel(id)
          deleteClick(commentId)
        }}
        title={(<div>
          <div className='team-name m-b-20'>{eventName}</div>
          <div className='title'>{t('deleteCommentLabel')}</div>
        </div>)}
        // title={`${t('DeleteTeamDeleteLabel')} ${panel.panelName}?`}
        description='This cannot be undone.'
      />
    </div>
  );
};

CommentTile.propTypes = {
  fullName: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  comments: PropTypes.string.isRequired,
  commentType: PropTypes.oneOf(['scoring', 'judging']),
  deleteClick: PropTypes.func,
  signedInUserId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string
};

CommentTile.defaultProps = {
  dateTime: '',
  commentType: 'scoring',
  deleteClick: () => { },
  userType: ''
}

const mapStateToProps = (state) => {
  const {
    judgingRubric: {
      eventDetails: {
        eventId,
        eventName,
      }
    },
    auth: {
      userEventAssignments,
      userType,
      userInfo: {
        id,
        use_24_hour_format
      }
    } } = state;
  return {
    userType,
    signedInUserId: id,
    rubricEventId: eventId,
    userEventAssignments,
    eventName,
    use_24_hour_format
  };
};

export default connect(mapStateToProps)(CommentTile);