import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './styles/SessionCard.scss';
import Icon from '@ant-design/icons';
import LawIcon from 'shared/components/Icon/icons/Law';
import {parseDateString, parseTime} from 'utils/dateTimeUtils';
import Score from 'shared/components/Icon/icons/Score';
import Ballot from 'shared/components/Icon/icons/Ballot';
import { useTranslation } from 'react-i18next';



const EventScheduleCard = (props) => {


  const {
    startTimeString,
    endTimeString,
    dateString,
    title,
    iconType,
    sessionType
  } = props;
  const {t} = useTranslation();
 



  const renderIcon = () => {
    switch(iconType) {
      case 'ballot':
        return Ballot;
      case 'score':
        return Score;
      default:
        return LawIcon
    }

  }

  const renderDateTime = () => {
    if (startTimeString && endTimeString) return `${startTimeString} - ${endTimeString}`;
    if (startTimeString) return startTimeString;
    if (endTimeString ) return endTimeString;
    return t('notSetLabel');
  }


  return (
  
    <div className='c-session-card c-session-card--complete'>
      <div className={`c-session-card__rectangle c-session-card__rectangle--${sessionType}`}>
        <Icon component={renderIcon()} className='c-session-card__event-icon' />
      </div>
      <div className='c-session-card__data c-session-card__data--complete'>
        <p className='paragraph--default paragraph--bold paragraph--charcoal c-session-card__data__title'>{title}</p>
        <div className='c-session-card__data__date-time'>
          <p className='paragraph--medium paragraph--charcoal'>{dateString ? dateString : t('notSetLabel')}</p>
          <p className='paragraph--medium paragraph--charcoal'>
            {renderDateTime()}
          </p>
        </div>
      </div>
    </div>


  
  );
};

EventScheduleCard.propTypes = {
  startTimeString: PropTypes.string,
  endTimeString: PropTypes.string,
  dateString: PropTypes.string,
  title: PropTypes.string,
  sessionType: PropTypes.string,
  iconType: PropTypes.string,

}

EventScheduleCard.defaultProps = {
  sessionType: 'judging',
  iconType: 'law',
  startTimeString: '',
  endTimeString: '',
  dateString: '',
  title: '',
}


export default EventScheduleCard;