import React from 'react';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button } from 'antd';
import './manual-view.scss'
import truncateString from 'utils/truncateString';
import CloseCircle from 'shared/components/Icon/icons/CloseCircle';
import { connect } from 'react-redux';
import {handleManualVolunteerDelete} from 'features/EventContainer/redux/actions/volunteersActions'


const InviteTile = ({fullName, email, id, removeVolunteer}) => {
  return (
    <div className='add-volunteers-manually__tile'>
      <span className='add-volunteers-manually__tile-inner'>
        <Avatar size={48} icon={<UserOutlined />} className='add-volunteers-manually__tile-avatar' />
        <span>
          <p className='add-volunteers-manually__tile-name'>{truncateString(fullName, 32)}</p>
          <a className='link--email' href={`mailto:${email}`}>{email}</a>
        </span>
      </span>
      <div> 
        <Button type='link' className='add-volunteers-manually__tile-delete' onClick={() => {removeVolunteer(id)}}>
          <CloseCircle />
          {' '}
        </Button>
         
      </div>
      
    </div>
  );
};

InviteTile.propTypes = {
  fullName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  removeVolunteer: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default connect(null,{removeVolunteer: handleManualVolunteerDelete})(InviteTile);