import * as registrationTypes from 'features/RegistrationContainer/redux/registrationTypes';
import * as types from './authTypes'

const DEFAULT_AUTH_DETAILS = {
  authorized: false,
  loading: false,
  loadingUser: false,
  idToken: null,
  accessToken: null,
  refreshToken: null,
  expiresAt: null,
  state: '', // Unique value sent by Auth0 to prevent cross-origin attacks
  userInfo: {},
  userId: null,
  userType: null,
  canAccessAdminDashboard: false,
  canAccessVolunteerDashboard: false,
  userEventRoles: [],
  partnerAdminRegionsPrograms: [],
  error: false,
  auth0Authorized: false,
}
const AUTH_INITIAL_STATE = {
  authorized: false,
  loading: false,
  loadingUser: false,
  idToken: null,
  accessToken: null,
  refreshToken: null,
  expiresAt: null,
  state: '', // Unique value sent by Auth0 to prevent cross-origin attacks
  userInfo: {},
  userId: null,
  userType: null,
  canAccessAdminDashboard: false,
  canAccessVolunteerDashboard: false,
  lastVerify: {},
  lastSignUp: {},
  userEventRoles: [],
  partnerAdminRegionsPrograms: [],
  error: false,
  eventIdsAssociatedWithUser: [], // may remove, not used
  userEventAssignments: [], // should be removed, not used,
  initialRegistrationDetails: {},
  auth0Authorized: false,
}

export default (state = AUTH_INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET_LOADING_USER: {
      return {
        ...state,
        loadingUser: false
      }
    }
    case types.SET_AUTH0_AUTHENTICATED_STATUS:{
      return {
        ...state,
        auth0Authorized: action.payload
      }
    }
    case types.LOGIN_REQUEST_BEGIN: {
      return {
        ...state,
        authorized: false,
        loading: true,
        loadingUser: true
      }
    }
    case types.LOGIN_REQUEST_SUCCESS:
    case types.REFRESH_TOKEN_SUCCESS: {
      const { expires_at } = action.payload
      const expiresAt = expires_at
      return {
        ...state,
        ...action.payload,
        expiresAt,
        authorized: true,
        loading: false,
        error: false
      }
    }
    case types.LOGIN_REQUEST_ERROR: {
      return {
        ...state,
        authorized: false,
        loading: false,
        loadingUser: false,
        error: action.payload
      }
    }
    case types.CLEAR_LOGIN_ERROR: {
      return {
        ...state,
        error: false
      }
    }
    case types.LOGOUT: {
      return {
        ...AUTH_INITIAL_STATE,
        authorized: false,
        loading: false
      }
    }
    case types.LOGOUT_REQUEST_BEGIN: {
      return AUTH_INITIAL_STATE; 
    }
    case types.LOGOUT_REQUEST_SUCCESS: {
      return AUTH_INITIAL_STATE;
    }
    case types.LOGOUT_REQUEST_ERROR: {
      return {
        ...state,
        authorized: false,
        loading: false
      }
    }
    case types.RESET_AUTH: {
      return AUTH_INITIAL_STATE;
    }
    case registrationTypes.SET_VERIFY_USER_DATA: {
      return {
        ...state,
        lastVerify: action.payload
      }
    }
    case registrationTypes.VERIFY_USER_AUTH_EMAIL_SUCCESS: {
      return {
        ...state,
        lastVerify: {}
      };
    }
    case registrationTypes.SET_SIGNUP_USER_DATA: {
      return {
        ...state,
        lastSignUp: action.payload
      }
    }
    case registrationTypes.VERIFY_USER_SIGNUP_SUCCESS: {
      return {
        ...state,
        lastSignUp: {}
      };
    }
    case registrationTypes.SET_VERIFY_USER_ASSIGN_ROLE: {
      return {
        ...state,
        lastSignUp: {
          ...state.lastSignUp,
          assignRole: true
        }
      };
    }
    case registrationTypes.ACCEPT_TERMS_CONDITIONS_SUCCESS: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          hasAcceptedTerms: true
        }
      };
    }
    case types.SET_AUTH_USER_DATA: {
      return {
        ...state,
        ...action.payload
      };
    }
    case types.SET_USER_INFO:
      return {
        ...state,
        userInfo: {
          ...action.payload
        }
      }
    case types.SET_USER_ID:
      return {
        ...state,
        userId: action.payload.userId
      }
    case types.SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload.userType
      }
    case types.SET_CAN_ACCESS_ADMIN_DASHBOARD: 
      return {
        ...state,
        canAccessAdminDashboard: action.payload.canAccessAdminDashboard
      }
    case types.SET_CAN_ACCESS_VOLUNTEER_DASHBOARD: 
      return {
        ...state,
        canAccessVolunteerDashboard: action.payload.canAccessVolunteerDashboard
      }
    case types.SET_USER_EVENT_ROLES:
      return {
        ...state,
        userEventRoles: action.payload.userEventRoles
      }
    case types.SET_PARTNER_ADMIN_REGIONS_AND_PROGRAMS: 
      return {
        ...state,
        partnerAdminRegionsPrograms: action.payload.partnerAdminRegionsPrograms
      }
    case types.SET_EVENT_IDS_ASSOCIATED_WITH_USER:
      return {
        ...state,
        eventIdsAssociatedWithUser: action.payload.eventIdsAssociatedWithUser
      }
    case types.SET_USER_EVENT_ASSIGNMENTS:
      return {
        ...state,
        userEventAssignments: action.payload.userEventAssignments
      }
    case types.UPDATE_USER_INFO:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.payload.userData
        }
      }
    case registrationTypes.SET_REGISTRATION_AUTH_QUERY: 
      return {
        ...state,
        ...DEFAULT_AUTH_DETAILS,
        initialRegistrationDetails: {
          ...action.payload
        }
      }
    default:
      return state
  }
}