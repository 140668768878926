import React from 'react';
import PropTypes from 'prop-types';

import AddModal from 'shared/components/Modal';
import Tooltip from 'shared/components/Tooltip';
import TeamAutocomplete from './TeamAutocomplete';
import AddTeamForm from './CreateNewTeamForm';
import '../../../styles/AddTeamModal.scss';
import { useTranslation } from 'react-i18next';

const AddTeamModal = (props) => {
  const {onCancel, visible} = props;
  const {t} = useTranslation();

  const infoText =
    'If a team has already been added to the Event Hub their name will appear in the drop down list when typed. Otherwise, please choose "Create New Team".';

  return (
    <AddModal
      noTitle
      visible={visible}
      onCancel={onCancel}
      maxWidth={940}
      iconColor='#9A989A'
      className='add-team-modal'
    >
      <div className='add-team-modal__wrapper'>
        <div className='add-team-modal__header'>
          <h2 className='heading--charcoal h-2'>{t('addTeamLabel')}</h2>
          <Tooltip text={infoText} />
        </div>
        <div className='add-team-modal__selection'>
          <div className='add-team-modal__select-wrapper'>
            <TeamAutocomplete onCancel={onCancel} />
          </div>
        </div>
        <div className='add-team-modal__or-wrapper'>
          <span className='add-team-modal__or-text'>
            <p className='paragraph--default paragraph--400'>{t('orLabel')}</p>
          </span>
        </div>
        <div>
          <AddTeamForm onCancel={onCancel} />
        </div>
      </div>
    </AddModal>
  );
};

AddTeamModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default AddTeamModal;
