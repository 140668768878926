/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../../../../../features/PanelManagement/styles/JudgingPanelCard.scss';
import UserRow from 'shared/components/UserRow';
import MissingJudge from './MissingJudge';
import { Button, Input } from 'antd';
import { Col, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from 'shared/components/Icon'
import { connect } from 'react-redux';
import { handleUpdateScoringPanel, handleDeleteRefereePanel } from "features/EventContainer/redux/actions/scoringActions/editScoringPanel";
import CloseCircle from 'shared/components/Icon/icons/CloseCircle';
import { handleExpands } from 'features/EventContainer/redux/actions/scoringActions/getScoringPanels';

const { Option } = Select;


const JudgesList = props => {
  const ref = useRef();
  const { panelJudges, panelTitle, hasError, refereeOptions, panelId, numberOfTeams, expendables, handleUpdateScoringPanelData, handleDeleteRefereePanelData, manageExpands } = props;
  const [isEditing, setIsEditing] = useState(false)
  const [editText, setEditText] = useState('')
  const { t } = useTranslation();

  const subTitle = numberOfTeams === 0 ? t('2022EmptyPanelTeamAssignedLabel') : numberOfTeams === 1 ? t('2022EmptyPanelTeamAssigned3Label', { '0': 1 }) : t('2022EmptyPanelTeamAssigned2Label', { '0': numberOfTeams });

  return (
    <div>
      <div className='judging-panel-card__judges'>
        <div className='panel-card-title-container'>
          <div ref={ref} className='panel-card-title'>
            
            <h4 className='h-4 heading--charcoal m-r-20 m-l-10'>
              {panelTitle}
            </h4>
          </div>
          <div className=''>
            {subTitle}
          </div>
        </div>
      </div>
    </div>
  );
};

JudgesList.propTypes = {
  panelJudges: PropTypes.array,
  panelTitle: PropTypes.string,
  numberOfTeams: PropTypes.string,
  panelId: PropTypes.number,
  hasError: PropTypes.bool
};

JudgesList.defaultProps = {
  panelJudges: [],
  numberOfTeams: 0,
  panelTitle: '',
  panelId: 0,
  hasError: false
}

const mapDispatchToProps = {
  // getScoringPanels: fetchScoringPanels
  handleUpdateScoringPanelData: handleUpdateScoringPanel,
  handleDeleteRefereePanelData: handleDeleteRefereePanel,
  manageExpands: handleExpands
}

const mapStateToProps = (state) => {
  const { event: { eventScoring: { refereeOptions, expendables } } } = state;

  return {
    refereeOptions,
    expendables
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(JudgesList);
// export default JudgesList;