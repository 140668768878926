const ip1Section = {
    id: 'IP1',
    // label: 'ipv1Label',
    title: 'exploreReviewingSheetChallengeSolutionLabel',
    // description: 'identifyDescriptionLabel',
    fields: [
        {
            id: 'challenge_solution_1',
            value: 'exploreReviewingSheetLearnQuestionLabel',
            answear: 'exploreReviewingSheetLearnAnswerLabel' 
        },
        {
            id: 'challenge_solution_2',
            value: 'exploreReviewingSheetImpactQuestionLabel',
            answear: 'exploreReviewingSheetImpactAnswerLabel' 
        },
    ]
}

export default ip1Section;