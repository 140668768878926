import * as types from '../types/teams';

const DEFAULT_TEAMS_STATE = {
  addTeamsToPanelList: []
}

export default (state = DEFAULT_TEAMS_STATE, action) => {
  const {type} = action;
  switch(type) {
    case types.ADD_NEW_TEAM_TO_PANEL_LIST:
      return {
        ...state,
        addTeamsToPanelList: action.payload.addTeamsToPanelList
      }
    case types.DELETE_TEAM_FROM_ADD_TO_PANEL_LIST:
      return {
        ...state,
        addTeamsToPanelList: action.payload.addTeamsToPanelList
      }
    case types.RESET_TEAMS_ADD_TO_PANEL_LIST:
      return {
        ...state,
        addTeamsToPanelList: []
      }
    default:
      return state;
  }
}