import React, { useState } from 'react';
import { connect } from 'react-redux';
import FeedbackComments from './FeedbackComments';
import { useTranslation } from 'react-i18next';
import Icon from 'shared/components/Icon';
import RubricSection from './RubricSection'
import PropTypes from 'prop-types';

const FeedbackSection = props => {
  const { rubric } = props;
  const [showFeedback, setShowFeedback] = useState(false);
  const { t } = useTranslation();

  const toggleFeedbackIcon = () => {
    setShowFeedback(show => !show);
  };

  const FeedbackToggleButton = (
    <div className="rubric-toggle-button" onClick={toggleFeedbackIcon}>
      {showFeedback ? <Icon type="downArrowBlue" /> : <Icon type="upArrowBlue" />}
    </div>
  );

  return (
    <div>
      <h3 className='h-1 h--400 heading--charcoal m-b-36 mobile-compatitable-rubric-title'>
        {t('judgingFeedbackLabel')}
      </h3>
      <RubricSection
        name={t('feedbackCommentsLabel')}
        button={FeedbackToggleButton}
      >
        {!showFeedback && <div>
          <FeedbackComments rubric={rubric} subtitleKey='coreValueSubtitle' labelKey='coreValueLabel' commentSection='cv' extraClass="core-value-comments" />
          <FeedbackComments rubric={rubric} subtitleKey='innovationProjectSubtitle' labelKey='innovationProjectLabel' commentSection='ip' extraClass="innovation-project-comments" />
          <FeedbackComments rubric={rubric} subtitleKey='robotDesignSubtitle' labelKey='robotDesignLabel' commentSection='rd' extraClass="robot-design-comments" />
        </div>}
      </RubricSection>

    </div>
  );
};

FeedbackSection.propTypes = {
  rubric: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {

};

export default connect(mapStateToProps)(FeedbackSection);