export const defaultConfig = {
    REACT_APP_AUTH_DOMAIN:"staging-firstinspires.eu.auth0.com",
    REACT_APP_AUTH_CLIENT_ID:"adrGOMqklwM7659Jf75iMKLH0mJE30Sg",
    REACT_APP_AUTH_REDIRECT_URI:"http://localhost:3000/login",
    REACT_APP_AUTH_AUDIENCE:"https://staging-remotehub.firstinspires.org",
    REACT_APP_GOOGLE_AUTH_REDIRECT_URI:"http://localhost:3000/signup/verification",
    REACT_APP_API_BASE_URL:"https://32pyt4gj82.execute-api.eu-central-1.amazonaws.com",
    REACT_APP_IS_STAGING_ENV:"false",
    REACT_APP_UPLOAD_API_BASE_URL:"https://dev-eventhub.firstinspires.org",
    REACT_APP_ENVIRONMENT:"development",
  };
  
  export const dynamicConfigUrl = "config.json";