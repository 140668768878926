export const SET_AUTH0_AUTHENTICATED_STATUS = 'SET_AUTH0_AUTHENTICATED_STATUS'
export const RESET_LOADING_USER = 'RESET_LOADING_USER'
export const LOGIN_REQUEST_BEGIN = 'LOGIN_REQUEST_BEGIN'
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS'
export const LOGIN_REQUEST_ERROR= 'LOGIN_REQUEST_ERROR'
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS'
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR'

export const LOGOUT = 'LOGOUT';


export const LOGOUT_REQUEST_BEGIN = 'LOGOUT_REQUEST_BEGIN'
export const LOGOUT_REQUEST_SUCCESS = 'LOGOUT_REQUEST_SUCCESS'
export const LOGOUT_REQUEST_ERROR= 'LOGOUT_REQUEST_ERROR'

export const SET_AUTH_USER_DATA = 'SET_AUTH_USER_DATA'

export const RESET_AUTH = 'RESET_AUTH';

export const SET_USER_INFO = 'SET_USER_INFO'

export const SET_USER_ID = 'SET_USER_ID';

export const SET_USER_TYPE = 'SET_USER_TYPE';

export const SET_CAN_ACCESS_VOLUNTEER_DASHBOARD = 'SET_CAN_ACCESS_VOLUNTEER_DASHBOARD';

export const SET_CAN_ACCESS_ADMIN_DASHBOARD = 'SET_CAN_ACCESS_ADMIN_DASHBOARD';

export const SET_USER_EVENT_ROLES = 'SET_USER_EVENT_ROLES';

export const SET_PARTNER_ADMIN_REGIONS_AND_PROGRAMS = 'SET_PARTNER_ADMIN_REGIONS_AND_PROGRAMS';

export const SET_EVENT_IDS_ASSOCIATED_WITH_USER = 'SET_EVENT_IDS_ASSOCIATED_WITH_USER';

export const SET_USER_EVENT_ASSIGNMENTS = 'SET_USER_EVENT_ASSIGNMENTS';

export const SET_PARTNER_ADMIN_EVENTS = 'SET_PARTNER_ADMIN_EVENTS';

export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'
