import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Skeleton, Collapse} from 'antd';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import '../../styles/EventOverviewCollapse.scss';
import getEventSchedule from 'features/EventOverviewContainer/redux/actions/getEventSchedule';
import EventOverviewSessions from './EventOverviewSessions';
import { UpOutlined, DownOutlined } from '@ant-design/icons';


const {Panel} = Collapse;


const EventOverviewCollapse = props => {
  const {eventId} = useParams();
  const {
    eventScheduleId,
    userEventRoleIds,
    teamId,
    isLoadingSchedule,
    eventScheduleList,
    handleTabClick
  } = props;

  useEffect(() => {
    if (eventScheduleId) {
      const args = {
        userEventRoleIds,
        scheduleId: eventScheduleId,
        teamId,
        eventId
      }
      props.getEventSchedule(args);
    }
  }, [eventScheduleId, teamId])


    if (isLoadingSchedule) {
      return <Skeleton title={false} paragraph={{rows: 12}} />
    }


  const hasScheduleData = (Array.isArray(eventScheduleList) && eventScheduleList.length);


  if (!hasScheduleData) {
    return <p>No Schedule Data</p>
  }

  const scheduleKeys = eventScheduleList.map(s => s.date);
 
  const handleExpandIcon = (panelProps) => {
    const {isActive} = panelProps;
    if (isActive) {
      return <UpOutlined className='event-schedule-accordion__icon' />
    }

    return <DownOutlined className='event-schedule-accordion__icon'  />
  }

  const renderPanelHeader = (dateObject) => {
    if (!dateObject) return null;
    return (
      <div className='event-overview-collapse__panel-header'>
        <p className='event-overview-collapse__panel-header__date'>{dateObject.date}</p>
        <p className='paragraph--bold paragraph--large event-overview-collapse__panel-header__day'>{dateObject.day}</p>
        <p className='paragraph--small paragraph--charcoal'>
          {dateObject.month}
          ,
          {' '}
          {' '} 
          {' '}
          {dateObject.year}
        </p>
      </div>
    )
  }

  return (
    <Collapse
      bordered={false}
      expandIconPosition='right'
      expandIcon={handleExpandIcon}
      className='event-overview-collapse'
      defaultActiveKey={scheduleKeys}
    >
      {eventScheduleList.map(s => (
        <Panel header={renderPanelHeader(s.dateInfo)} key={s.date}>
          <EventOverviewSessions sessions={s.sessions} handleTabClick={handleTabClick}/>
        </Panel>
      ))}

    </Collapse>
  );
};

EventOverviewCollapse.propTypes = {
  eventScheduleList: PropTypes.array,
  isLoadingSchedule: PropTypes.bool,
  eventScheduleId: PropTypes.string,
  userEventRoleIds: PropTypes.array,
  getEventSchedule: PropTypes.func.isRequired,
  teamId: PropTypes.string
};

EventOverviewCollapse.defaultProps = {
  eventScheduleList: [],
  isLoadingSchedule: false,
  eventScheduleId: '',
  teamId: '',
  userEventRoleIds: []
}

const mapStateToProps = (state) => {
  const {eventOverview: {
    eventScheduleId,
    userEventRoleIds,
    isLoadingSchedule,
    eventScheduleList,
    selectedTeam: {id}
  }} = state;
  return {
    eventScheduleId,
    userEventRoleIds,
    teamId: id,
    isLoadingSchedule,
    eventScheduleList,
  }
}

export default connect(mapStateToProps, {getEventSchedule})(EventOverviewCollapse);