import React from 'react';
import PropTypes from 'prop-types';
import Paragraph from 'shared/components/Paragraph';
import './manual-view.scss';
import text from 'shared/translations/en/volunteersModal';
import { useTranslation } from 'react-i18next';


import BtnPrimary from 'shared/components/Button/BtnPrimary';
import {handleInviteAllVolunteers, handleResetManualVolunteers} from 'features/EventContainer/redux/actions/volunteersActions';
import { connect } from 'react-redux';
import {useParams} from 'react-router-dom';

import InviteTile from './InviteTile';

const InviteList = (props) => {
  const { t } = useTranslation()
  const {handleInviteAll, invitees, resetManualList, isInvitingVolunteer} = props;

  const {id: eventId}= useParams();

  const noInvitees = invitees.length < 1;

  const successCallback = () => {
    resetManualList();
  };
  
  const inviteAll = () => {
    handleInviteAll(invitees, eventId, successCallback);
  };

  return (
    <div className='invite-list__wrapper'>
      <Paragraph color={noInvitees ? 'silver' : 'darkGray'}>
        {t('inviteTheseUsersLabel')}
        {' '}
        :
      </Paragraph>
      <div className={noInvitees ? 'invite-list' : 'invite-list--invitees'}>
        {invitees.map(i => (
          <InviteTile key={`manual-invite-${i.id}`} id={i.id} fullName={`${i.firstName} ${i.lastName}`} email={i.email} />
        ))}
      </div>
      <div className='invite-list__btn-wrapper--outer'>
        <div className='invite-list__btn-wrapper--inner'>
          <BtnPrimary loading={isInvitingVolunteer} size='large' styleType='primary' additionalClassNames='m-t-24' disabled={noInvitees} onClick={inviteAll}>
            {t('inviteAllLabel')}
          </BtnPrimary>
        </div>
      </div>
    </div>
  );
};

InviteList.propTypes = {
  invitees: PropTypes.array,
  handleInviteAll: PropTypes.func,
  resetManualList: PropTypes.func,
  isInvitingVolunteer: PropTypes.bool.isRequired
};


InviteList.defaultProps = {
  invitees: [],
  handleInviteAll: () => {},
  resetManualList: () => {}
};

function mapStateToProps(state) {
  const {event: {eventVolunteers}} = state;
  const {addVolunteersManuallyList, isInvitingVolunteer} = eventVolunteers;
  return {
    invitees: addVolunteersManuallyList,
    isInvitingVolunteer
  };
}

const mapDispatchToProps = {
  handleInviteAll: handleInviteAllVolunteers,
  resetManualList: handleResetManualVolunteers
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteList);
