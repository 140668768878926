import {
  formatDataResult
} from 'utils/query';

export const formatChannelResponse = (data) => {
  const { relationships } = data;
  const channel = formatDataResult(data);
  const assignmentsNode = relationships.roles;
  const roleIds = ((assignmentsNode && assignmentsNode.data) || []);
  const roleTypes = roleIds.map(({ id }) => id);
  return { ...channel, roles: roleTypes };
};

export const formatRolesPayload = (roles) => {
  return {
    data: roles.map(roleId => ({
      type: 'user_event_role_type',
      id: roleId
    }))
  };
};

export const formatNewChannelPayload = (channel) => {
  const { eventId, roles, ...attributes } = channel;
  return {
    data: {
      type: 'messaging_channel',
      attributes,
      relationships: {
        event: {
          data: {
            type: 'event',
            id: eventId
          }
        }
      }
    }
  };
};

export const formatEditChannelPayload = (id, attributes) => {
  return {
    data: {
      type: 'messaging_channel',
      id,
      attributes
    }
  };
};
