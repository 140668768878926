import { API } from 'utils/api';
import { parallelRequest, queryStringify } from 'utils/query';
import Match from 'models/Match';
import {formatMatchResponse} from './model';
import _ from 'lodash';
import { CURRENT_EVENT_SEASON } from 'shared/constants/eventConstants';

//const { http } = API;
const baseUrl = '/api/matches';
const endPoint = '/api';

export const getMatchesBy = async (scheduleId) => {
  const page = { size: 100, number: 1 };
  const queryStr = queryStringify({page });
  const url = `/api/schedules/${scheduleId}/matches${queryStr}`;
  const { data, included } = await API.GetInstance().http.get(url);
  const matchesList = data.map(m => formatMatchResponse(new Match(m, included)))
  return matchesList;
};

export const getMatchById = async (matchId) => {
  const url = `${baseUrl}/${matchId}`;
  const {data, included} = await API.GetInstance().http.get(url);
  return {
    data,
    included: included || []
  }
}

export const getMatchByIdRequest = (matchId)=>API.GetInstance().http.get(`${baseUrl}/${matchId}`)
export const getMatchByEventId = (eventId) => API.GetInstance().http.get(`${endPoint}/event_matches/${eventId}`)
export const getMatchScoresByEventId = async (eventId, season = '2022') => {
  const config = {headers: {'api-version': '2.0'}};
  let data;
  if (season == CURRENT_EVENT_SEASON) {
    data = await API.GetInstance().http.get(`${endPoint}/event_scores/${eventId}`, config);
  } else {
    data = await API.GetInstance().http.get(`${endPoint}/event_scores/${eventId}`);
  }
  const formattedData = data.map(d=>{
    if(d.score_id) {
      d.id = d.score_id;
      d.overallScore = d.overall_score;
      return d
    }
    return d;
  })
  return formattedData;
}

export const getMatchesByPage = async (scheduleId, page) => {
  const queryStr = queryStringify({page });
  const url = `/api/schedules/${scheduleId}/matches${queryStr}`;
  const { data, included, meta } = await API.GetInstance().http.get(url);
  const matchesList = data.map(m => formatMatchResponse(new Match(m, included)))
  return {
    matches: matchesList,
    count: meta && meta.count
  }
};

export const getMatchesByIdsAndPage = async (allMatches, page) => {
  let matches = [];
  let count = (allMatches||[]).length || 0;
  let {size=12, number=1} = page;
  let startMatchNumber = (number - 1) * size;
  let endMatchNumber = number * size;
  let validMatches = (allMatches||[]).slice(startMatchNumber, endMatchNumber)
  await parallelRequest(validMatches.map(m=>getMatchByIdRequest(m.id))).then(
    (values)=>{
      matches = values.map(v=>{
        const {data, included} = v;
        const formatMatch = formatMatchResponse(new Match(data, included))
        return formatMatch;
      })
    }, 
    (errors)=>{});
  return {matches, count}
}