import {
  EVENT_ADMIN_ID,
  JUDGE_ADVISOR_ID,
  HEAD_REFEREE_ID,
  JUDGE_ID,
  REFEREE_ID,
  COACH_ID,
} from 'shared/constants/eventRoleTypes';

const allRoles = [
  EVENT_ADMIN_ID,
  JUDGE_ADVISOR_ID,
  HEAD_REFEREE_ID,
  JUDGE_ID,
  REFEREE_ID,
  COACH_ID]

export default [
  {
    description: 'challengeUpdatesLabel',
    default: 'https://firstinspires.blob.core.windows.net/fll/challenge/2024-25/fll-challenge-updates-and-clarifications.pdf',
    "es-MX": '',
    "tr": '',
    "nl": '',
    "he": '',
    roles: allRoles
  },
  {
    description: 'Robot Game Rulebook',
    default: "https://firstinspires.blob.core.windows.net/fll/challenge/2024-25/fll-challenge-submerged-rgr-eng.pdf",
    "es-MX": "",
    "tr": "",
    "nl": "",
    "he": "",
    roles: allRoles
  }
]