import { API } from 'utils/api';
import { queryStringify, formatDataResult } from 'utils/query';
import {
  formatNewSponsorPayload,
  formatEditSponsorPayload
} from './model';

//const { http } = API;
const baseUrl = '/api/sponsors';

export const getAllSponsors = async (scheduleId) => {
  const url = `/api/schedules/${scheduleId}/sponsors`;
  const { data } = await API.GetInstance().http.get(url);
  const sponsors = data.map(formatDataResult);
  return sponsors;
};

export const getAllSponsorsForPublicLeaderBoard = async (scheduleId) => {
  const url = `/api/schedules/${scheduleId}/public_sponsors`;
  const { data } = await API.GetInstance().http.get(url);
  const sponsors = data.map(formatDataResult);
  return sponsors;
}

export const updateSponsor = async (sponsor) => {
  const { id } = sponsor;
  const payload = formatEditSponsorPayload(sponsor);
  const { data } = await API.GetInstance().http.patch(`${baseUrl}/${id}`, payload);
  return formatDataResult(data);
};

export const createSponsor = async (sponsor) => {
  const payload = formatNewSponsorPayload(sponsor);
  const { data } = await API.GetInstance().http.post(baseUrl, payload);
  return formatDataResult(data);
};

export const deleteSponsor = async (sponsorId) => {
  const { data } = await API.GetInstance().http.delete(`${baseUrl}/${sponsorId}`);
  return data;
};
