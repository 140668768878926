import React from 'react';
import PropTypes from 'prop-types';

const CloseCircle = (props) => {
  const {fill_one="#C6C4C6", fill_two="#FFFFFF"} = props;
  return (
    <svg width="18px" height="18px" viewBox="0 0 18 18" {...props}>
      <g id="Judging-and-Awards" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Judging-Assignments---All-Programs---Edit-or-Add-Judges---Added-to-Panel---Admin" transform="translate(-614.000000, -317.000000)">
          <g id="Group-36" transform="translate(140.000000, 10.000000)">
            <g id="Group-32" transform="translate(110.000000, 96.000000)">
              <g id="Group-27" transform="translate(79.000000, 86.000000)">
                <g id="Group-22" transform="translate(0.000000, 72.000000)">
                  <g id="Group-35" transform="translate(0.000000, 26.000000)">
                    <g id="Group-17" transform="translate(285.000000, 12.000000)">
                      <g id="Message/_Icon/Error" transform="translate(0.000000, 15.000000)">
                        <path d="M9,0 C4.02890625,0 0,4.02890625 0,9 C0,13.9710937 4.02890625,18 9,18 C13.9710937,18 18,13.9710937 18,9 C18,4.02890625 13.9710937,0 9,0 L9,0 Z M13.2363281,12.3626953 C13.4578125,12.5806641 13.4613281,12.9357422 13.2451172,13.1589844 L13.2451172,13.1589844 C13.0271484,13.3804687 12.6720703,13.3839844 12.4488281,13.1677734 L9,9.7875 L5.55117187,13.1660156 C5.3296875,13.3839844 4.97285156,13.3804687 4.75488281,13.1572266 L4.75488281,13.1572266 C4.53691406,12.9357422 4.54042969,12.5789062 4.76367188,12.3609375 L8.19667969,9 L4.76367188,5.63730469 C4.5421875,5.41933594 4.53867187,5.06425781 4.75488281,4.84101562 L4.75488281,4.84101562 C4.97285156,4.61953125 5.32792969,4.61601562 5.55117187,4.83222656 L9,8.2125 L12.4488281,4.83398437 C12.6703125,4.61601563 13.0271484,4.61953125 13.2451172,4.84277344 L13.2451172,4.84277344 C13.4630859,5.06425781 13.4595703,5.42109375 13.2363281,5.6390625 L9.80332031,9 L13.2363281,12.3626953 L13.2363281,12.3626953 Z" id="Shape" fill={fill_one} />
                        <path d="M13.2363281,12.3626953 C13.4578125,12.5806641 13.4613281,12.9357422 13.2451172,13.1589844 L13.2451172,13.1589844 C13.0271484,13.3804688 12.6720703,13.3839844 12.4488281,13.1677734 L9,9.7875 L5.55117188,13.1660156 C5.3296875,13.3839844 4.97285156,13.3804688 4.75488281,13.1572266 L4.75488281,13.1572266 C4.53691406,12.9357422 4.54042969,12.5789063 4.76367188,12.3609375 L8.19667969,9 L4.76367188,5.63730469 C4.5421875,5.41933594 4.53867188,5.06425781 4.75488281,4.84101563 L4.75488281,4.84101563 C4.97285156,4.61953125 5.32792969,4.61601563 5.55117188,4.83222656 L9,8.2125 L12.4488281,4.83398438 C12.6703125,4.61601563 13.0271484,4.61953125 13.2451172,4.84277344 L13.2451172,4.84277344 C13.4630859,5.06425781 13.4595703,5.42109375 13.2363281,5.6390625 L9.80332031,9 L13.2363281,12.3626953 L13.2363281,12.3626953 Z" id="Path" fill={fill_two} />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

CloseCircle.propTypes = {
  fill: PropTypes.string
};

CloseCircle.defaultProps = {
  fill: 'currentColor'
};

export default CloseCircle;
