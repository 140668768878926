const m04Section = {
  id: 'm04',
  name: '2022ScoresheetM04Sub1Label',
  fields: [
    {
      scoring: '2022ScoresheetM04Sub2Label',
      id: 'm04_scoring_1',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        }
      ]
    },
    {
      scoring: '2022ScoresheetM04Sub3Label',
      id: 'm04_scoring_2',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        }
      ]
    }
  ]
}

export default m04Section;