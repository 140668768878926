import React from 'react';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';

import BtnPrimary from 'shared/components/Button/BtnPrimary';
import BtnSecondary from 'shared/components/Button/BtnSecondary';
import {Modal} from 'antd';
import 'shared/components/ConfirmationModal/ConfirmationModal.scss';




const ConfirmationModal = (props) => {
  const { t } = useTranslation()
  const {onOk, onCancel, visible} = props;
  return (
    (<Modal
      footer={null}
      onOk={onOk}
      onCancel={onCancel}
      open={visible}
      closable={false}
      className='confirmation-modal'
    >
      <div>
        <div className='confirmation-modal__heading-wrapper'>
          <h1>{t('eventCreatedLabel')}</h1>
        </div>
        <div className='confirmation-modal__btn-group'>
          <BtnPrimary onClick={onOk}>{t('createAnotherLabel')}</BtnPrimary>
          <BtnSecondary onClick={onCancel}>{t('finishLabel')}</BtnSecondary>
        </div>
      </div>
    </Modal>)
  );
};

ConfirmationModal.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
}



export default ConfirmationModal;