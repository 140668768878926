import React from 'react';
import PropTypes from 'prop-types';
import Paragraph from 'shared/components/Paragraph';

import BtnPrimary from 'shared/components/Button/BtnPrimary';
import { connect } from 'react-redux';
import { handleInviteAllTeamsWithoutCoaches } from 'features/EventContainer/redux/actions/teams';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Team from 'models/Team';

const InviteTeamList = (props) => {
    const { eventTeams: { addedTeamList } , inviteAllTeams, onTeamAdded, isInvitingCoach} = props
    const noTeams = addedTeamList.length < 1;
    const { t } = useTranslation();

    const handleInviteAll = () => {
      inviteAllTeams(onTeamAdded);
    };

    return (
        <div className='invite-team-list'>
            <Paragraph color={noTeams ? 'silver' : 'darkGray'}>
                Invite these teams :
            </Paragraph>
            <div className={noTeams ? 'teams-coaches-modal__manual-invite-list' : 'teams-coaches-modal__manual-invite-list--invitees'}>
                {addedTeamList.map(team => {
                  const teamObject = new Team(team.data, team.included);
                  return (
                    <div className='m-b-10'>
                        <div className='paragraph--charcoal paragraph--medium paragraph--bold'>{teamObject?.teamNumber} {" - "} {teamObject.teamName}</div>
                        <div className=''>
                            <p className='paragraph--charcoal paragraph--medium paragraph--400'>{teamObject?.getCountryName()}, {teamObject?.getRegionName()}</p>
                        </div>
                    </div>
                )})}
            </div>
            <div className='teams-coaches-modal__manual-invite-list__btn-wrapper--outer'>
                <div className='teams-coaches-modal__manual-invite-list__btn-wrapper--inner'>
                    <BtnPrimary loading={isInvitingCoach} disabled={noTeams} onClick={handleInviteAll}>{t('Add All')}</BtnPrimary>
                </div>
            </div>
        </div>
    );
};

InviteTeamList.propTypes = {
    coachesList: PropTypes.array.isRequired,
    inviteAll: PropTypes.object.isRequired,
    isInvitingCoach: PropTypes.bool.isRequired,
    eventTeams: PropTypes.array.isRequired
};

function mapStateToProps(state) {
    const { event: { eventTeams } } = state;
    const { isInvitingCoach } = eventTeams;
    return {
        isInvitingCoach,
        eventTeams
    };
}

export default connect(mapStateToProps, { inviteAllTeams: handleInviteAllTeamsWithoutCoaches })(InviteTeamList);
