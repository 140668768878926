import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber as AntInputNumber } from 'antd';
import './InputNumber.scss';

const InputNumber = props => {
  return (
    <AntInputNumber className='c-input-number' {...props} />
  );
};

InputNumber.propTypes = {
  
};

export default InputNumber;