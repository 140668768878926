const ip4Section = {
  id: 'IP4',
  label: 'ipv4Label',
  title: 'iterateLabel',
  description: 'iterateDescriptionLabel',
  fields: [
    {
      legendTitle: 'ChallengeRubricsShareLabel',
      id: 'innovation_project_4_iterate_share',
      isCoreValue: false,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricsShare1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricsShare2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricsShare3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricsShare4Label',
      }]
    },
    {
      legendTitle: 'improvementsLabel',
      id: 'innovation_project_4_iterate_improvements',
      isCoreValue: false,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricImprovements1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricImprovements2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricImprovements3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricImprovements4Label',
      }]
    },
  ]
}

export default ip4Section;