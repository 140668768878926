import { notification } from 'antd';
import * as userService from 'utils/services/users';
import { FIRST_ADMIN } from 'shared/constants/userTypes';
import {seventyTwoHoursFromNow} from 'utils/dateTimeUtils';
import {formatUserResData} from 'utils/services/users/model';
import * as types from '../types/usersTypes';

export const getFirstAdminsBegin = () => ({
  type: types.FETCH_FIRST_ADMINS_BEGIN
});

export const getFirstAdminsSuccess = (payload) => ({
  type: types.FETCH_FIRST_ADMINS_SUCCESS,
  payload
});

export const getFirstAdminsError = () => ({
  type: types.FETCH_FIRST_ADMINS_ERROR
});

export const fetchFirstAdmins = (page, search) => async (dispatch) => {
  try {
    dispatch(getFirstAdminsBegin());
    const filter = { 
      user_type: `eq:${FIRST_ADMIN}`,
      ...search && { full_name: `like:${search}` }
    };
    const firstUsersAll = await userService.getUsersByFilter(filter);
    const firstUsers = await userService.getUsersByFilter(filter, page);
    dispatch(getFirstAdminsSuccess({...firstUsers, total: firstUsersAll.count}));
  } catch (e) {
    dispatch(getFirstAdminsError());
  }
};

export const updateSelectedFirstUser = (fieldName, value) => {
  return {
    type: types.UPDATE_SELECTED_FIRST_USER,
    payload: {
      fieldName, value
    }
  };
};

export const resetManualForm = () => {
  return {
    type: types.RESET_FIRST_USER_MANUAL_FORM
  };
};

export const resetInviteesList = () => {
  return {
    type: types.RESET_FIRST_USERS_MANUAL_LIST
  };
};

export const setIsAutocompleteUserSelected = (payload) => {
  return {
    type: types.SET_IS_AUTOCOMPLETE_FIRST_USER_SELECTED,
    payload
  };
};

export const setSelectedFirstUser = (payload) => {
  return {
    type: types.SET_SELECTED_FIRST_USER,
    payload
  };
};

export const addFirstUserToList = (newUser) => {
  return {
    type: types.ADD_FIRST_USER_TO_MANUAL_LIST,
    payload: newUser
  };
};

export const deleteFirstUserFromManualList = (id) => {
  return {
    type: types.DELETE_FIRST_USER_FROM_MANUAL_LIST,
    payload: id
  };
};

const inviteFirstUserBegin = () => {
  return {
    type: types.FIRST_USER_INVITE_BEGIN
  }
}

const inviteFirstUserError = () => {
  return {
    type: types.FIRST_USER_INVITE_ERROR
  }
}

const inviteFirstUserSuccess = () => {
  return {
    type: types.FIRST_USER_INVITE_SUCCESS
  }
}


const changeExistingUserTypeToFirstAdmin = async (userId) => {
  const existingUser = {id: userId, user_type : FIRST_ADMIN};

  await userService.updateUser(userId, existingUser);
  notification.success({
    message: 'Success',
    description: 'First Admin Invitation successfully sent.',
  });

}
// Service/Help method: Invite one First Admin user
const inviteFirstUser = async (firstUser) => {
  try {
    const {email} = firstUser;
    const existingUserId = email ? await userService.verifyUserExist(email) : false;
    if (existingUserId) {
      await changeExistingUserTypeToFirstAdmin(existingUserId);
    } else {
      const user = await userService.createFirstAdmin(firstUser);
      if (user && user.id) {
        notification.success({
          message: 'Success',
          description: 'First Admin Invitation successfully sent.',
        });
      }
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: 'Error sending the invitation.',
    });
  }
}

export const handleInviteAllFirstUsers = () => async (dispatch, getState) => {
  await dispatch(inviteFirstUserBegin());
  try {
    const { adminBoard: { users: { manualFirstUsersList } } } = getState();
    for (let i = 0; i < manualFirstUsersList.length; i++) {
      const user = manualFirstUsersList[i];
      await inviteFirstUser(user);
    }
    await dispatch(resetInviteesList());
    await dispatch(inviteFirstUserSuccess());
  } catch (err) {
    await dispatch(inviteFirstUserError());
    throw err;
  }
};

export const handleInviteFirstUserNow = (user) => async (dispatch, getState) => {
  await dispatch(inviteFirstUserBegin());
  try {
    const { adminBoard: { users: { selectedFirstUser } } } = getState();
    await inviteFirstUser({ ...selectedFirstUser, ...user });
    await dispatch(resetManualForm());
    await dispatch(inviteFirstUserSuccess());
  } catch (err) {
    await dispatch(inviteFirstUserError());
    throw err;
  }
};

const resendInvitationBegin = () => {
  return {
    type: types.REINVITE_DASHBOARD_FIRST_USER_BEGIN
  }
}
const resendInvitationError = () => {
  return {
    type: types.REINVITE_DASHBOARD_FIRST_USER_ERROR
  }
}
const resendInvitationSuccess = (updatedFirstUsersList = []) => {
  return {
    type: types.REINVITE_DASHBOARD_FIRST_USER_SUCCESS,
    payload: {
      updatedFirstUsersList
    }
  }
}

export const resendUserInvitation = (userId) => {
  return async (dispatch, getState) => {
    const {adminBoard: {users: {firstUsers}}} = getState();
    const notificationKey = 'reinvite-volunteer-user'

    const copyUsersList = [...firstUsers];
    try {
      await dispatch(resendInvitationBegin())
      await userService.reinviteUser(userId);
      const newTimestamp = seventyTwoHoursFromNow();
 
     
      const selectedVolunteerUser = copyUsersList.find(i => i.id === userId);
      
      if (selectedVolunteerUser) {
        const selectedVolunteerIndex = copyUsersList.findIndex(i => i.id === userId);
        const updatedVolunteerUser = {
          ...selectedVolunteerUser,
          userVerificationCodeTimeout: newTimestamp
        }
        copyUsersList[selectedVolunteerIndex] = updatedVolunteerUser;
      }

      await dispatch(resendInvitationSuccess(copyUsersList));
      
     
      notification.destroy(notificationKey);
      notification.success({
        message: 'Success',
        description: 'User successfully reinvited.',
      })
      
    } catch(err) {
      notification.error({
        message: 'Error',
        description: 'Error reinviting user',
      });
      await dispatch(resendInvitationError())
      throw err;
    }
  }
}

const setSelectedEditFirstUser = (data) => {
  return {
    type: types.SET_SELECTED_EDIT_FIRST_USER,
    payload: {
      data
    }
  }
}

export const handleSelectEditFirstUser = (data) => {
  return async dispatch => {
    await dispatch(setSelectedEditFirstUser(data));
  }
}

const resetSelectedEditFirstUser = () => {
  return {
    type: types.RESET_SELECTED_EDIT_FIRST_USER
  }
}

export const handleReselectEditFirstUser = () => {
  return async dispatch => {
    await dispatch(resetSelectedEditFirstUser());
  }
}

const deleteFirstUserBegin = () => {
  return {
    type: types.DELETE_SELECTED_FIRST_USER_BEGIN
  }
}
const deleteFirstUserError = () => {
  return {
    type: types.DELETE_SELECTED_FIRST_USER_ERROR
  }
}
const deleteFirstUserSuccess = (updatedFirstUsersList) => {
  return {
    type: types.DELETE_SELECTED_FIRST_USER_SUCCESS,
    payload: {
      updatedFirstUsersList
    }
  }
}

export const handleDeleteFirstUser = (userId, cb = () => {}) => {
  return async (dispatch, getState) => {
    const {adminBoard: {users: {firstUsers}}} = getState();

    const copyFirstUsers = [...firstUsers];
    try {

      await dispatch(deleteFirstUserBegin());
      const updatedList = copyFirstUsers.filter(f => f.id !== userId);
      await userService.deleteUser(userId);
      await dispatch(deleteFirstUserSuccess(updatedList));
      notification.success({
        message: 'Success',
        description: 'User successfully removed.',
      });
      cb();
    } catch(err) {
      await dispatch(deleteFirstUserError());
      notification.error({
        message: 'Error',
        description: 'Error removing user',
      });
      throw err;
    }
  }
}


const updateFirstUserBegin = () => {
  return {
    type: types.EDIT_FIRST_USER_BEGIN
  }
}

const updateFirstUserError = () => {
  return {
    type: types.EDIT_FIRST_USER_ERROR
  }
}

const updateFirstUserSuccess = (updatedFirstUsersList) => {
  return {
    type: types.EDIT_FIRST_USER_SUCCESS,
    payload: {updatedFirstUsersList}
  }
}

export const handleUpdateFirstUser = (userId, values, cb = () => {}) => {
  return async (dispatch, getState) => {
    const {adminBoard: {users: {firstUsers}}} = getState();
    const copyFirstUsers = [...firstUsers];

    try {
      await dispatch(updateFirstUserBegin())
      const res = await userService.updateUser(userId, values);
      const indexSelectedUser = copyFirstUsers.findIndex(f => f.id === userId);
      const updatedUserPayload = formatUserResData(res);
      copyFirstUsers[indexSelectedUser] = updatedUserPayload;
      await dispatch(updateFirstUserSuccess(copyFirstUsers));
      cb();
      notification.success({
        message: 'Success',
        description: 'User successfully saved.',
      });
    } catch(err) {
      await dispatch(updateFirstUserError())
      notification.error({
        message: 'Error',
        description: 'Error saving user',
      });
      throw err;
    }
  }
}