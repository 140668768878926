import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/judgingRubric/FLLRubricSection.scss';

const titleClassName = `paragraph--large paragraph--white paragraph--bold`;
const countClassName = `paragraph--white paragraph--medium`

const RubricSection = props => {
  const {
    children,
    name,
    questionText,
    button
  } = props
  return (
    <div className='c-fll-rubric-section'>
      <div className='c-fll-rubric-section__header-wrapper'>
        <div className='c-fll-rubric-section__header'>
          <p className={titleClassName}>{name}</p>
          <p className={countClassName}>{questionText}</p>
        </div>
        {button}
      </div>
      {children}
    </div>
  );
};

RubricSection.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
  questionText: PropTypes.string
};

RubricSection.defaultProps = {
  name: '',
  questionText: ''
}

export default RubricSection;