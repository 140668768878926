import * as types from 'features/AdminDashboard/redux/types/organizationsTypes';
import * as organizationService from 'utils/services/organizations';
import * as regionService from 'utils/services/regions';
import {formatOrganizationResponse} from 'utils/services/organizations/model';
import { notification } from 'antd';


const fetchOrganizationsBegin = () => {
  return {
    type: types.FETCH_ORGANIZATIONS_BEGIN
  }
}
const fetchOrganizationsError = () => {
  return {
    type: types.FETCH_ORGANIZATIONS_ERROR
  }
}
const fetchOrganizationsSuccess = (organizationsList, count) => {
  return {
    type: types.FETCH_ORGANIZATIONS_SUCCESS,
    payload: {
      organizationsList,
      count
    }
  }
}

export const handleGetOrganizations = (pageNumber, searchText) => {

  return async dispatch => {
    try {
      await dispatch(fetchOrganizationsBegin());
      const searchFilter = searchText && { name: `like:${searchText}` };

      const {organizations, count} = await organizationService.filterOrganizations(searchFilter, pageNumber);
 
      const formattedOrganizations = organizations.map(r => formatOrganizationResponse(r))
      await dispatch(fetchOrganizationsSuccess(formattedOrganizations, count));

    } catch(err) {
      await dispatch(fetchOrganizationsError())
      throw err
    }

  }
}

const editOrganizationBegin = () => {
  return {
    type: types.EDIT_ORGANIZATION_BEGIN
  }
}
const editOrganizationError = () => {
  return {
    type: types.EDIT_ORGANIZATION_ERROR
  }
}
const editOrganizationSuccess = () => {
  return {
    type: types.EDIT_ORGANIZATION_SUCCESS
  }
}

export const handleEditOrganization = (organizationId, payload, cb = () => {}, successCb = ()=>{}) => {
  return async dispatch => {
    try {
      await dispatch(editOrganizationBegin());
      const {addedRegions, removedRegions, name} = payload;
      if (addedRegions.length || name) {
        await organizationService.editOrganization(organizationId, {name, regions: addedRegions});
      }
      if (removedRegions.length) {
        await organizationService.removeRegionsFromOrganization(organizationId, removedRegions);
      }
      // todo find and replace the updated organization
      await dispatch(editOrganizationSuccess());
      notification.success({
        message: 'Success',
        description: 'Organization successfully saved.',
      })
      cb()
    } catch(err) {
      notification.error({
        message: 'Error',
        description: 'Error editing organization.',
      });
      await dispatch(editOrganizationError())
      cb()
      throw err;
    }
  }
}

const updateSelectedEditOrganization = (field, value) => {
  return {
    type: types.UPDATE_SELECTED_EDIT_ORGANIZATION,
    payload: {
      field, value
    }
  }
}
export const handleUpdateSelectedOrganizationPrograms = (field, value) => {
  return async dispatch => {
    dispatch(updateSelectedEditOrganization(field, value))
  }
}

const updateOrganizationPrograms = (programs) => {
  return {
    type: types.UPDATE_EDIT_ORGANIZATION_PROGRAMS,
    payload: {programs}
  }
}

export const handleSetOrganizationPrograms = (programIds) => {
  return async (dispatch, getState) => {
    const {adminBoard: {organizations: {selectedOrganization}}} = getState();
    const organizationProgramsCopy = [...selectedOrganization.programs];
    const isProgramAdded = organizationProgramsCopy.length < programIds.length;
    const newProgramsList = isProgramAdded 
    ? [...organizationProgramsCopy, {derivedRegions: [], initialRegions: [],programId: programIds.find(id => !organizationProgramsCopy.find(({ programId }) => id === programId))}] 
    : organizationProgramsCopy.filter(({ programId }) => programIds.includes(programId));
    
    dispatch(updateOrganizationPrograms(newProgramsList))
  }
}

const setSelectedOrganization = (selectedOrganization) => {
  return {
    type: types.SET_SELECTED_EDIT_ORGANIZATION,
    payload: {
      selectedOrganization
    }
  }
}

export const handleSetSelectedOrganization = (org) => {
  return async dispatch => {
    dispatch(setSelectedOrganization(org))
  }
}

const resetEditOrganizationForm = () => {
  return {
    type: types.RESET_EDIT_ORGANIZATION_FORM
  }
}

export const handleResetOrganizationForm = () => {
  return async dispatch => {
    dispatch(resetEditOrganizationForm())
  }
}

const deleteOrganizationBegin = () => {
  return {
    type: types.DELETE_ORGANIZATION_BEGIN
  }
}
const deleteOrganizationError = () => {
  return {
    type: types.DELETE_ORGANIZATION_ERROR
  }
}
const deleteOrganizationSuccess = (organizationsList) => {
  return {
    type: types.DELETE_ORGANIZATION_SUCCESS,
    payload: {
      organizationsList
    }
  }
}

export const handleDeleteOrganization = (organizationId, cb = () => {}) => {
  return async (dispatch, getState) => {
    try {
      const {adminBoard: {organizations: {organizationsList}}} = getState();
      await dispatch(deleteOrganizationBegin())
      const updatedOrganizationsList = organizationsList.filter(i => i.organizationId !== organizationId);
      await organizationService.deleteOrganization(organizationId);

      await dispatch(deleteOrganizationSuccess(updatedOrganizationsList))
      cb();
      notification.success({
        message: 'Success',
        description: 'Organization successfully removed.',
      })

    } catch(err) {
      await dispatch(deleteOrganizationError())
      notification.error({
        message: 'Error',
        description: 'Error removing organization',
      });
      throw err;
    }
  }
}

const getRegionsBegin = () => {
  return {
    type: types.FETCH_ORGANIZATION_REGIONS_BY_PROGRAM_BEGIN
  }
}
const getRegionsError = () => {
  return {
    type: types.FETCH_ORGANIZATION_REGIONS_BY_PROGRAM_ERROR
  }
}
const getRegionsSuccess = (updatedOrganizationPrograms) => {
  return {
    type: types.FETCH_ORGANIZATION_REGIONS_BY_PROGRAM_SUCCESS,
    payload: {
      updatedOrganizationPrograms
    }
  }
}

export const handleGetRegionsByProgramId = (input, programId) => {
  return async(dispatch, getState) => {
    try {
      const {adminBoard: {organizations: {selectedOrganization}}} = getState();
      const organizationProgramsCopy = [...selectedOrganization.programs];
    
      await dispatch(getRegionsBegin())
      const regions = await regionService.getRegionsByProgram(programId, input);
      const formattedRegions = regions.map(r => {
        return {
          regionId: r.id,
          regionName: r.name,
          programId: r.programId
        }
      });
      const indexSelectedProgram = organizationProgramsCopy.findIndex(i => i.programId === programId);
      let selectedProgramElement = organizationProgramsCopy.find(i => i.programId === programId);
      selectedProgramElement = {
        ...selectedProgramElement,
        derivedRegions: [...formattedRegions] 
      }
      organizationProgramsCopy[indexSelectedProgram] = selectedProgramElement;
      await dispatch(getRegionsSuccess(organizationProgramsCopy))
    } catch(err) {

      await dispatch(getRegionsError())
      throw err;
    }
  }
}