const KeysOfRubricForJustificationValidation = [
  "innovation_project_1_identify_problem",
  "innovation_project_1_identify_research",
  "innovation_project_2_design_planning",
  "innovation_project_2_design_process",
  "innovation_project_3_create_innovation",
  "innovation_project_3_create_model_drawing",
  "innovation_project_4_iterate_share",
  "innovation_project_4_iterate_improvements",
  "innovation_project_5_communicate_solution",
  "innovation_project_5_communicate_presentation",
  "robot_design_1_identify_mission",
  "robot_design_1_identify_building",
  "robot_design_2_design_contribution",
  "robot_design_2_design_skills",
  "robot_design_3_create_purpose",
  "robot_design_3_create_code_sensor",
  "robot_design_4_iterate_testing",
  "robot_design_4_iterate_improvements",
  "robot_design_5_communicate_explanation",
  "robot_design_5_communicate_enthusiasm"
]

export default KeysOfRubricForJustificationValidation;