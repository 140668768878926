import ip1 from './ip1';
import ip2 from './ip2';
import ip3 from './ip3';
import ip4 from './ip4';
import ip5 from './ip5';

export default [
  ip1,
  ip2,
  ip3,
  ip4,
  ip5
]