import * as scheduleService from 'utils/services/schedules';
import * as sessionService from 'utils/services/sessions';
import ScheduleSession from 'models/ScheduleSession';
import {momentize, todayMomentObject} from 'utils/dateTimeUtils';
import * as types from '../../types/eventScheduleTypes';

export const formatScheduleSessionsRes = (scheduleId, data) => {
  const formattedSessions = data.map(s => {
    const sessionObj = {};
    const session = new ScheduleSession(s, [], [], scheduleId, 2); 
    const twentyTwoHoursBefore = momentize(session.sessionStartTime) ? momentize(session.sessionStartTime).subtract(22, 'hours') : false
    const isJoinLinkDisabled = twentyTwoHoursBefore ? todayMomentObject().isBefore(twentyTwoHoursBefore) : false;
  
    sessionObj.id = session.sessionId;
    sessionObj.startTime = session.sessionStartTime;
    sessionObj.endTime = session.sessionEndTime;
    sessionObj.title = session.getSessionTypeName();
    sessionObj.sessionUrl = session.getExternalLink();
    sessionObj.sessionType = session.getSessionTypeName();
    sessionObj.teamDetails = session.getTeamName();
    sessionObj.isJoinLinkDisabled = isJoinLinkDisabled;
    sessionObj.teamId = session.teamId;
    sessionObj.matchId = session?.matchId;
    sessionObj.panelName = session.getPanelName();
    sessionObj.sessionTypeId = session.sessionTypeId;
    return sessionObj

  })
  return formattedSessions;
}

export const formatScheduleSessionResV2 = (response) => {
  const formattedSessions = response.map(session => {
    const sessionObj = {};
    const twentyTwoHoursBefore = momentize(session.startTime) ? momentize(session.startTime).subtract(22, 'hours') : false
    const isJoinLinkDisabled = twentyTwoHoursBefore ? todayMomentObject().isBefore(twentyTwoHoursBefore) : false;
  
    sessionObj.type = 'session';
    sessionObj.id = session.id;
    sessionObj.startTime = session.startTime;
    sessionObj.endTime = session.endTime;
    sessionObj.title = session.sessionTypeDesc;
    sessionObj.sessionUrl = session.externalLinkUrl;
    sessionObj.sessionType = session.sessionTypeDesc;
    sessionObj.teamDetails = session?.team?.name;
    sessionObj.isJoinLinkDisabled = isJoinLinkDisabled;
    sessionObj.teamId = session?.team?.id;
    sessionObj.matchId = session.matches;
    sessionObj.panelId = session.panelId;
    sessionObj.sessionTypeId = session.sessionTypeId;
    sessionObj.sessionStatus = session.sessionStatus;
    sessionObj.sessionStatusId = session.sessionStatusId;
    return sessionObj

  })
  return formattedSessions;
}

const getJudgingSessionsBegin = () => {
  return {
    type: types.GET_SCHEDULE_JUDGING_SESSIONS_BEGIN
  }
}

const getJudgingSessionsError = () => {
  return {
    type: types.GET_SCHEDULE_JUDGING_SESSIONS_ERROR
  }
}

export const getJudgingSessionsSuccess = (judgingSessionsData) => {
  return {
    type: types.GET_SCHEDULE_JUDGING_SESSIONS_SUCCESS,
    payload: {
      judgingSessionsData
    }
  }
}


export const saveJudgingSession = () => {

}

export const fetchJudgingSessions = (scheduleId, page={size:500, number:1}) => {
  return async dispatch => {
    try {
      await dispatch(getJudgingSessionsBegin())
      // const { data, included } = await sessionService.getJudgingSessions(scheduleId, page)
      const {judging_sessions: response} = await sessionService.getJudgingSessions(scheduleId, page)

      const formattedSessions = formatScheduleSessionsRes(scheduleId, response)
      await dispatch(getJudgingSessionsSuccess(formattedSessions))

    } catch(err) {
      await dispatch(getJudgingSessionsError());
      throw err;
    }
  }
}