import Region from './Region';

export default class ProgramType {
  constructor(responseData, region) {
    const regionData = region && new Region(region);
    this.id = responseData.id;
    this.type = responseData.type;
    this.name = responseData.attributes.name;
    this.shortName = responseData.attributes.short_name;
    this.region = regionData;
  }
}
