import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InnovationProject from './InnovationProject';
import RobotDesign from './RobotDesign';
import ReadonlyFeedbackSection from './ReadonlyFeedbackSection';

const RubricModalContent = props => {
  const {
    judgingObject,
    isInnovationProjectForm,
    isRobotDesignForm,
    isFeedbackForm,
    rubric,
  } = props;

  const judgingData = rubric?.id ? rubric : judgingObject;

  return (
    <div>
        <InnovationProject rubric={judgingData} active={isInnovationProjectForm}/>
        <RobotDesign rubric={judgingData} active={isRobotDesignForm}/>
        <ReadonlyFeedbackSection rubric={judgingData} active={isFeedbackForm}/>
    </div>
  );
};

RubricModalContent.propTypes = {
  isInnovationProjectForm: PropTypes.bool.isRequired,
  isRobotDesignForm: PropTypes.bool.isRequired,
  isFeedbackForm: PropTypes.bool.isRequired,
  judgingObject: PropTypes.object.isRequired
  //
};

const mapStateToProps = ({coachJudging}) => {
  const {
    judgingObject
  } = coachJudging;
  return {
    judgingObject 
  }
}

export default connect(mapStateToProps)(RubricModalContent);