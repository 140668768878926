import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Heading from 'shared/components/Heading';
import AddLink from 'shared/components/TopBar/AddLink';
import ListLoading from 'shared/components/Spinner/ListLoading';
import { fetchMessageChannels, saveMessageChannel } from 'features/MessagingContainer/redux/eventMessageActions';

import Instructions from './components/Instructions';
import AddMessageChannel from './components/AddMessageChannel';
import MessagingChannel from './components/MessagingChannel';
import './EventMessaging.scss';

const MessagingContainer = (props) => {
  const { t } = useTranslation();
  const { canEdit, eventId, channels, isFetchingChannels, isSavingChannel, scheduleId, isFLLC} = props;
  const [visible, setVisible] = useState(false);
  const [selChannel, setSelChannel] = useState({});

  useEffect(() => {
    props.fetchMessageChannels(eventId, scheduleId);
  }, [eventId, scheduleId]);

  const handleEdit = (id) => {
    const channel = channels.find((c) => c.id === id);
    setSelChannel(channel || {});
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
    setSelChannel({});
  };

  const handleSave = (channel) => props.saveMessageChannel(eventId, channel);

  const editable = canEdit && !isFetchingChannels;

  return (
    <section className='c-event-messaging'>
      <AddMessageChannel
        visible={visible}
        onCancel={closeModal}
        channel={selChannel}
        handleSave={handleSave}
        loading={isSavingChannel}
        isFLLC={isFLLC}
      />
      <Heading additionalClassNames='m-t-0'>
        {t('messagingCenterLabel')}
      </Heading>
      <Instructions />
      <div className='c-event-messaging__actions'>
        <AddLink
          addClick={() => setVisible(true)}
          color='primaryBlue'
          customText={t('addMessagingChannelLabel')}
          disabled={!editable}
        />
      </div>
      <ListLoading
        loading={isFetchingChannels}
      />
      <div className='c-event-messaging__channels'>
        {channels.map((channel) => (
          <MessagingChannel
            key={channel.id}
            handleEdit={canEdit && handleEdit}
            {...channel}
          />
        ))}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  const { eventMessaging, event: {eventDetails: {relationships}} } = state;
  const { channels, isFetchingChannels, isSavingChannel } = eventMessaging;
  const scheduleId = (((relationships||{}).schedule||{}).data||{}).id
  return {
    channels,
    isFetchingChannels,
    isSavingChannel,
    scheduleId
  };
};

export default connect(mapStateToProps, { 
  fetchMessageChannels, saveMessageChannel
})(MessagingContainer);
