import * as actionTypes from '../types';

const DEFAULT_UPLOAD_MODAL_VALUE = {
  wrapClassName: 'coach-judging-upload-modal',
  visible: false,
  onCancel: undefined,
  uploadProgress: 0,
  disableESC: true,
  withAlert: true,
  onUploadStatusChanged: undefined,
  modalClosable: false,
}

export default (state = DEFAULT_UPLOAD_MODAL_VALUE, action)=>{
  const {type} = action;
  switch (type) {
    case actionTypes.UPDATE_GLOBAL_UPLOAD_MODAL_PROPS:
      return {
        ...state,
        ...action.payload
      }
  
    default:
      return state;
  }
}