import React from 'react';
import { FloatButton } from 'antd';
import BtnPrimary from 'shared/components/Button/BtnPrimary';
import '../../../styles/tocSteps.scss';

const TermsAndConditionsWrapper = ({
  title, btnText, handleSubmit, disabled, children
}) => {
  const headingClassName = 'paragraph--normal paragraph--charcoal paragraph--large m-t-18 m-b-30';
  
  return (
    <div className='c-toc-steps'>
      <h2 className={`${headingClassName} c-toc-steps__description`}>{title}</h2>
      <div className='c-toc-steps__content'>
        {children}
      </div>
      {Boolean(handleSubmit) && (
        <div className='c-toc-steps__action'>
         
          <BtnPrimary disabled={disabled} onClick={handleSubmit}>
            {btnText}
          </BtnPrimary>
         
        </div>
      )}
    </div>
  );
};

export default TermsAndConditionsWrapper;
