import React from 'react';
import PropTypes from 'prop-types';

const DownArrow = ({fill}) => {
  return (
    <svg width="14px" height="8px" viewBox="0 0 14 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      {/* <title>196FE0F4-21C1-40A5-8B79-62CC18F60BED</title> */}
      <g id="Dashboards" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dashboard---PDP-Admin" transform="translate(-1107.000000, -268.000000)" fill={fill}>
          <g id="Group-18" transform="translate(310.000000, 249.000000)">
            <g id="Group-5" transform="translate(642.000000, 5.000000)">
              <g id="Icons/FIRST/Drop-Arrow" transform="translate(155.000000, 14.000000)">
                <polygon id="Path" points="1.645 0 7 4.94466937 12.355 0 14 1.52226721 7 8 0 1.52226721" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

DownArrow.propTypes = {
  fill: PropTypes.string
};

DownArrow.defaultProps = {
  fill: "currentColor"
}

export default DownArrow;