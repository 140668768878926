import React from 'react';
import { ForgotPassContainer } from 'features/RegistrationContainer';

const ForgotPassword = (props) => {
  return (
    <div>
      <ForgotPassContainer {...props} />
    </div>
  );
};

export default ForgotPassword;
