import React from 'react';
import Modal from 'shared/components/EventModal';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ModalHeader from '../../ModalHeader';
import JudgingCommentForm from './JudgingCommentForm'

const JudgingCommentModal = (props) => {
  const {
    visible,
    onCancel,
    programId,
    commentId,
  } = props;

  return (
    <Modal
      onCancel={onCancel}
      visible={visible}
      programId={programId}
      renderHeader={() => <ModalHeader />}
    >
      <JudgingCommentForm onCancel={onCancel} commentId={commentId} />
    </Modal>
  );
};

JudgingCommentModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  commentId: PropTypes.string,
}

const mapStateToProps = (state) => {
  const {judgingRubric: {eventDetails}} = state;
  const {
    programId
  } = eventDetails
  return {
    programId
  }
}
export default connect(mapStateToProps)(JudgingCommentModal);