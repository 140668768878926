/* eslint-disable import/prefer-default-export */
import { API } from 'utils/api';
import { formatDataResult } from 'utils/query';
import axios from 'axios';

import { UploadAPI } from 'utils/uploadAPI';
import {EXTENSION_CONTENT_TYPE_MAPPING} from 'shared/constants/fileExtensions';
//const { http } = API;
const baseUrl = '/api/event_files';
const scheduleBaseUrl = "/api/schedules"
const uploadBaseUrl = `/event${baseUrl}`;

// POST file info for BE to generate S3 presigned url for secured FE file upload
export const initiateMatchEventFileUpload = async (matchId, teamId, file) => {
  const url = `${baseUrl}/matches/${matchId}/team/${teamId}`
  const response = await API.GetInstance().http.request({ 
    method: 'POST',
    url,
    data: { // LOL Brad the data wrap master
      data: {
        type: 'event_file',
        attributes: {
          file_name: file.name,
          content_type: file.type,
        },
      },
    },
  })
  return response
}

const checkContentTypeFromExtension = (file)=>{
  let {type, name}  = (file || {});
  if(type) return type;
  else{
    let extension = (name||'').split('.').pop();
    let checkedType = EXTENSION_CONTENT_TYPE_MAPPING[extension]||'';
    return checkedType;
  }
}

export const uploadFileToS3 = async (presignedUrl, file, progressCallback) => {
  const contentType = checkContentTypeFromExtension(file)
  const { data } = await axios.request({
    method: 'PUT',
    url: presignedUrl,
    data: file,
    headers: {
      'Content-Type': contentType,
    },
    onUploadProgress: e => progressCallback(e)
  })
  return data
};

export const editMatchEventFile = async (matchId, fileId, attributes) => {
  const url = `${baseUrl}/matches/${matchId}/files/${fileId}`
  const { data } = await API.GetInstance().http.request({
    method: 'PATCH',
    url,
    data: {
      data: {
        id: fileId,
        type: 'event_file',
        attributes,
      }
    }
  })
  return data
}

// POST logo info for BE to generate S3 presigned url for secured FE file upload
export const initiateSponsorLogoUpload = async (sponsorId, file) => {
  const url = `${baseUrl}/sponsor/${sponsorId}`
  const response = await API.GetInstance().http.request({ 
    method: 'POST',
    url,
    data: { // LOL Brad the data wrap master
      data: {
        type: 'event_file',
        attributes: {
          file_name: file.name,
          content_type: file.type,
        },
      },
    },
  })
  return response
}

export const editSponsorEventFile = async (sponsorId, fileId, attributes) => {
  const url = `${baseUrl}/sponsor/${sponsorId}`
  const { data } = await API.GetInstance().http.request({
    method: 'PATCH',
    url,
    data: {
      data: {
        id: fileId,
        type: 'event_file',
        attributes,
      }
    }
  })
  return data
}

export const getSponsorFile = async(sponsorId) => {
  const url = `${uploadBaseUrl}/sponsor/${sponsorId}`;
  const response = await UploadAPI.GetInstance().http.request({
    method: 'GET',
    url,
  });
  return response
};

export const getSponsorStatus = async(scheduleId) => {
  const url = `${scheduleBaseUrl}/${scheduleId}/sponsor_file_status`;
  const response = await API.GetInstance().http.request({
    method: 'GET',
    url,
  });
  return response
};

export const getPublicSponsorFile = async(sponsorId) => {
  const url = `${uploadBaseUrl}/public_sponsor/${sponsorId}`;
  const response = await UploadAPI.GetInstance().http.request({
    method: 'GET',
    url,
  });
  return response
};

export const removeEventFile = async (fileId) => {
  const url =`${baseUrl}/${fileId}`;
  const {data, included} = await API.GetInstance().http.delete(url);
  
  return {
    data,
    included: included || []
  }
}

export const getMatchFile = async(matchId, fileId) => {
  const url = `${baseUrl}/matches/${matchId}/files/${fileId}`;
  const response = await API.GetInstance().http.request({
    method: 'GET',
    url,
  });

  return response
}

export const getMatchVideoFile = async(matchId, fileId) => {
  const url = `${baseUrl}/matches/${matchId}/files/${fileId}`;
  const response = await API.GetInstance().http.request({
    method: 'GET',
    url,
  });

  return response
}
