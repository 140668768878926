import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, notification } from 'antd';
import Icon from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons';
import ChooseFile from 'shared/components/ChooseFile';
import { connect } from 'react-redux';
import {
  uploadSessionRubricFile,
  deleteRubricFile

} from 'features/EventOverviewContainer/redux/actions/judgingRubric'
import CloseCircle from 'shared/components/Icon/icons/CloseCircle';
import { FILE_UPLOAD_INTERRUPTED_MESSAGE, UPLOAD_FILE_SIZE_LIMIT } from 'shared/constants/fileUpload'
import SpreadSheetIcon from './icons/RubricSpreadsheetIcon';
import RubricPDFIconGrey from './icons/RubricPdfIconGrey';
import RubricPDFIcon from './icons/RubricsPdfIcon';
import UploadIcon from './icons/RubricUploadIcon';
import {
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FTC_ID
} from 'shared/constants/programIds';

const DEFAULT_STATE = {
  formattedFile: {},
  isLoadingFile: false,
  isError: false
}

const UploadRubricForm = props => {
  const {
    sessionDocumentId,
    uploadRubricFile,
    uploadedFile,
    isFileUploaded,
    isDeletingUploadedRubric,
    isUploadingRubricFile,
    setUploadModalVisibility,
    setUploadProgress,
    programId,
    isUploadedRubricPublishable,
  } = props;
  const [state, setState] = useState(DEFAULT_STATE);

  let acceptedFormates = ".xlsx, .xls, .csv";

  const __handleUploadProgress = event => {
    const progress = ~~(event.loaded / event.total * 100)
    setUploadProgress(progress)
  }

  const __handleUploadError = error => {
    if (error && 'data' in error && error.data.error) {
      notification.error({
        message: error.data.error,
        duration: 0,
        onClose: () => setUploadModalVisibility(false)
      });
    } else {
      notification.error({
        message: FILE_UPLOAD_INTERRUPTED_MESSAGE,
        description: 'Please try again later',
        duration: 0,
        onClose: () => setUploadModalVisibility(false)
      });
    }
    console.warn(error)
  }

  const isPdf = (name) => {
    if (name.split('.').pop() == "pdf") {
      return true
    }

    return false
  }

  const handleUpload = async ({ file }) => {

    if (file.size > UPLOAD_FILE_SIZE_LIMIT) {
      notification.error({
        message: 'File size exceeds upload limit',
        description: 'Cannot upload a file larger than 5GB',
        duration: 5,
      });
    } else if (!isPdf(file.name) && programId === FLL_EXPLORE_ID) {
      notification.error({
        message: 'File type not match',
        description: 'Only pdf files are allowed to upload.',
        duration: 5,
      });
    } else {
      setUploadModalVisibility(true)
      await uploadRubricFile(sessionDocumentId, file, __handleUploadProgress, __handleUploadError)
    }
  }
  const boldClassName = 'paragraph--charcoal paragraph--medium paragraph--bold';


  if (programId === FLL_EXPLORE_ID) {
    acceptedFormates = ".pdf"
  }

  useEffect(() => {
    let isMounted = true;
    const getSessionRubric = async () => {
      setState(prevState => ({ ...prevState, isLoading: true, isError: false }));
      try {
        // const response = await getSessionFile(sessionDocumentId, uploadedFile.eventFileId);
        // const url = window.URL.createObjectURL(response);
        // console.log({response, url})


      } catch (err) {
        if (isMounted) {
          setState(prevState => {
            return { ...prevState, isLoading: false, isError: true }
          })
        }
        throw err;
      }
    }
    if (isFileUploaded) {
      getSessionRubric()

    }
    return () => { isMounted = false }

  }, [isFileUploaded])

  const onDelete = (e) => {
    e.stopPropagation();
    if (uploadedFile.eventFileId) {
      props.deleteRubricFile(uploadedFile.eventFileId)
    }
  }

  if (isFileUploaded) {
    return (
      <div className='c-rubric-upload__download'>
        {!isUploadedRubricPublishable && (<Button disabled={isDeletingUploadedRubric} type='link' className='c-rubric-upload__delete-btn' onClick={onDelete}>
          <CloseCircle />
          {' '}
        </Button>)}
        <div className='c-rubric-upload__download-icons'>
          {programId === FLL_EXPLORE_ID ? (
            <Icon component={RubricPDFIcon} className='p-r-4' />
          ) : (
            <Icon component={SpreadSheetIcon} style={{ color: '#52C41A' }} className='p-r-8' />
          )}

        </div>
        <div className='c-rubric-upload__download-text'>
          <p className={boldClassName}>{uploadedFile.fileName}</p>
        </div>
      </div>
    );
  }

  return (
    <div className='c-rubric-upload__upload'>
      <div className='c-rubric-upload__download-icons'>
        {programId === FLL_EXPLORE_ID ? (
          <Icon component={RubricPDFIconGrey} className='p-r-4' />
        ) : (
          <Icon component={SpreadSheetIcon} style={{ color: '#DDDCDD' }} className='p-r-8' />
        )}
        <UploadOutlined />
      </div>
      <ChooseFile
        accept={acceptedFormates}
        onChange={handleUpload}
        loading={isUploadingRubricFile}
      />
    </div>
  );
};

UploadRubricForm.propTypes = {
  sessionDocumentId: PropTypes.string.isRequired,
  uploadRubricFile: PropTypes.func.isRequired,
  deleteRubricFile: PropTypes.func.isRequired,
  isFileUploaded: PropTypes.bool.isRequired,
  uploadedFile: PropTypes.object.isRequired,
  isUploadingRubricFile: PropTypes.bool.isRequired,
  isDeletingUploadedRubric: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  uploadRubricFile: uploadSessionRubricFile,
  deleteRubricFile
}

const mapStateToProps = (state) => {

  const { judgingRubric: {
    judgingRubricTemplate,
    isLoadingSessionRubric,
    isUploadingRubricFile,
    isDeletingUploadedRubric,
    eventDetails
  } } = state;

  const {
    programId
  } = eventDetails

  return {
    sessionRubricTemplate: judgingRubricTemplate,
    isLoadingSessionRubric,
    isUploadingRubricFile,
    isDeletingUploadedRubric,
    programId
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadRubricForm);