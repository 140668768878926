import React, { useRef, useEffect } from 'react';

const VideoPlayer = (props)=>{
  const {f} = props;
  const videoRef = useRef(null);
  let manualPaused = true;
  const videoId = `video-${f.eventFileId}`

  const threshold = 0.85

  useEffect(()=>{
    const video = document.querySelector(`#${videoId}`);
    const observer = new IntersectionObserver(
      entries => {
        const entry = (entries||[])[0]||{};
        const {paused, ended, currentTime} = ((videoRef||{}).current||{});
        const isPlaying = currentTime > 0 && !ended && !paused;
        if (entry.intersectionRatio <= threshold && isPlaying) {
          videoRef.current.pause();
          manualPaused = false;
        } if(entry.intersectionRatio <= threshold && !isPlaying){
          manualPaused = true;
        }
        else if (entry.intersectionRatio >= threshold && !isPlaying && !manualPaused) {
          videoRef.current.play();
        }
      },
      { 
        threshold: threshold,
        rootMargin: "-20px -20px -20px -20px"
      }
    );
    observer.observe(video);
    return ()=>observer.unobserve(video);
  },[videoId, manualPaused])

  return (
    <video 
      className='c-carousel__video' 
      src={f.src} 
      controls 
      id={videoId}
      type={f.contentType} 
      controlsList="nodownload" 
      onContextMenu={(e)=>e.preventDefault()} 
      ref={videoRef}
      />
  )
}

export default VideoPlayer;