import * as panelService from 'utils/services/panels';
import ScheduleSession from 'models/ScheduleSession';
import UserData from 'models/User';
import * as types from '../../types/scoringTypes';

const fetchPanelBegin = () => {
  return {
    type: types.FETCH_SELECTED_SCORING_PANEL_BEGIN
  }
}
const fetchPanelError = () => {
  return {
    type: types.FETCH_SELECTED_SCORING_PANEL_ERROR
  }
}
const fetchPanelSuccess = (selectedPanel) => {
  return {
    type: types.FETCH_SELECTED_SCORING_PANEL_SUCCESS,
    payload: {
      selectedPanel
    }
  }
}

const fetchSelectedPanel = (panelId) => {
  return async (dispatch, getState) => {
    const {event: {eventScoring}} = getState();
    const {eventRefereesIncluded, scoringSessionTeams} = eventScoring;
    await dispatch(fetchPanelBegin());
    try {
      const response = await panelService.fetchPanelById(panelId);
      const {refereesIds, sessionsIds} = response;
      const panelRefereesList = [];
      if (Array.isArray(refereesIds) && refereesIds.length) {
        refereesIds.forEach(j => {
          const currentJudge = eventRefereesIncluded.find(i => i.id === j);
          if (currentJudge) {
            const judgeModel = new UserData(currentJudge);
            const formattedJudge = {
              userId: judgeModel.userId,
              fullName: judgeModel.userFullNameString(),
              email: judgeModel.email,
            }
            panelRefereesList.push(formattedJudge);
          }
        } )
      }
      const panelSessionsList = [];


      scoringSessionTeams.forEach(opt => {
        if (opt.scoringSessionIds.some(i => sessionsIds.includes(i))) {
          panelSessionsList.push({
            ...opt,
          })
        } 
      })
      const selectedPanel = {
        ...response,
        panelRefereesList,
        panelSessionsList
      }
      
      await dispatch(fetchPanelSuccess(selectedPanel))
    } catch(err) {
      await dispatch(fetchPanelError())
      throw err;
    }
  }
}

export default fetchSelectedPanel;