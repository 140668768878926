export const SET_PARTNER_USER_INFO = 'SET_PARTNER_USER_INFO';
export const UPDATE_PARTNER_USER_INFO = 'UPDATE_PARTNER_USER_INFO';
export const SET_PARTNER_ORGANIZATION_NAME = 'SET_PARTNER_ORGANIZATION_NAME';
export const SET_PARTNER_ORGANIZATION_DATA = 'SET_PARTNER_ORGANIZATION_DATA';
export const SET_PARTNER_PROGRAMS = 'SET_PARTNER_PROGRAMS';
export const SET_PARTNER_PROGRAM_REGION = 'SET_PARTNER_PROGRAM_REGION';
export const RESET_PARTNER_FORM = 'RESET_PARTNER_FORM';
export const RESET_PARTNER_INVITEES = 'RESET_PARTNER_INVITEES';
export const CLEAR_SELECTED_PARTNER = 'CLEAR_SELECTED_PARTNER';
export const ADD_PARTNER_INVITEE = 'ADD_PARTNER_INVITEE';
export const DELETE_PARTNER_INVITEE = 'DELETE_PARTNER_INVITEE';
export const SET_PARTNER_USER_SELECTED_STATUS = 'SET_PARTNER_USER_SELECTED_STATUS';
export const PARTNER_INVITE_BEGIN = 'PARTNER_INVITE_BEGIN';
export const PARTNER_INVITE_ERROR = 'PARTNER_INVITE_ERROR';
export const PARTNER_INVITE_SUCCESS = 'PARTNER_INVITE_SUCCESS';
export const FETCH_PARTNER_ADMINS_BEGIN = 'FETCH_PARTNER_ADMINS_BEGIN';
export const FETCH_PARTNER_ADMINS_SUCCESS = 'FETCH_PARTNER_ADMINS_SUCCESS';
export const FETCH_PARTNER_ADMINS_ERROR = 'FETCH_PARTNER_ADMINS_ERROR';


export const REINVITE_DASHBOARD_PARTNER_ADMIN_BEGIN = 'REINVITE_DASHBOARD_PARTNER_ADMIN_BEGIN';
export const REINVITE_DASHBOARD_PARTNER_ADMIN_ERROR = 'REINVITE_DASHBOARD_PARTNER_ADMIN_ERROR';
export const REINVITE_DASHBOARD_PARTNER_ADMIN_SUCCESS = 'REINVITE_DASHBOARD_PARTNER_ADMIN_SUCCESS';

export const SET_SELECTED_EDIT_PARTNER_ADMIN = 'SET_SELECTED_EDIT_PARTNER_ADMIN';
export const RESET_SELECTED_EDIT_PARTNER_ADMIN = 'RESET_SELECTED_EDIT_PARTNER_ADMIN';

export const EDIT_PARTNER_ADMIN_BEGIN = 'EDIT_PARTNER_ADMIN_BEGIN';
export const EDIT_PARTNER_ADMIN_ERROR = 'EDIT_PARTNER_ADMIN_ERROR';
export const EDIT_PARTNER_ADMIN_SUCCESS = 'EDIT_PARTNER_ADMIN_SUCCESS';

export const DELETE_SELECTED_PARTNER_ADMIN_BEGIN = 'DELETE_SELECTED_PARTNER_ADMIN_BEGIN';
export const DELETE_SELECTED_PARTNER_ADMIN_ERROR = 'DELETE_SELECTED_PARTNER_ADMIN_ERROR';
export const DELETE_SELECTED_PARTNER_ADMIN_SUCCESS = 'DELETE_SELECTED_PARTNER_ADMIN_SUCCESS';

export const SET_DERIVED_SELECTED_PARTNER_ORGANIZATIONS_LIST = 'SET_DERIVED_SELECTED_PARTNER_ORGANIZATIONS_LIST';

export const SEARCH_PARTNER_ORGANIZATIONS_BEGIN = 'SEARCH_PARTNER_ORGANIZATIONS_BEGIN';
export const SEARCH_PARTNER_ORGANIZATIONS_ERROR = 'SEARCH_PARTNER_ORGANIZATIONS_ERROR';
export const SEARCH_PARTNER_ORGANIZATIONS_SUCCESS = 'SEARCH_PARTNER_ORGANIZATIONS_SUCCESS';