import {formatDocumentTypeRes} from 'utils/services/documentTypes/model';
import {formatSessionFiles} from 'utils/services/sessionDocuments/model';
import DocumentType from './DocumentType';


export default class SessionDocument {
  constructor(responseData, responseIncluded = []) {
    const {id, relationships} = responseData;
    this.sessionDocumentId = id;
    this.documentTypeId = relationships.document_type.data.id;
    this.files = relationships.files.data || [];
    this.included = responseIncluded;
  }

  getDocumentTypeDetails = () => {
    if (!this.documentTypeId) return {};
    const documentType = this.included.find(i => i.id === this.documentTypeId);

    if (!documentType) return {}
    return formatDocumentTypeRes(new DocumentType(documentType));
  }

  getFiles = () => {
    const sessionFiles = formatSessionFiles(this.files, this.included); 
    
    return sessionFiles;
  }
}