import * as scheduleService from 'utils/services/schedules';
import * as types from '../../types/eventScheduleTypes';
import {formatScheduleSessionResV2} from './scheduleJudgingSessions';

const getScoringSessionsBegin = () => {
  return {
    type: types.GET_SCHEDULE_SCORING_SESSIONS_BEGIN
  }
}

const getScoringSessionsError = () => {
  return {
    type: types.GET_SCHEDULE_SCORING_SESSIONS_ERROR
  }
}

export const getScoringSessionsSuccess = (scoringSessionsData) => {
  return {
    type: types.GET_SCHEDULE_SCORING_SESSIONS_SUCCESS,
    payload: {
      scoringSessionsData
    }
  }
}


export const saveScoringSession = () => {

}

export const fetchScoringSessions = (scheduleId) => {
  return async dispatch => {
    try {
      await dispatch(getScoringSessionsBegin())
      const response  = await scheduleService.getScheduleScoringSessions(scheduleId)
      const formattedSessions = formatScheduleSessionResV2(response)
      await dispatch(getScoringSessionsSuccess(formattedSessions))

    } catch(err) {
      await dispatch(getScoringSessionsError());
      console.log({err})
      throw err;
    }
  }
}