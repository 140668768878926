import React from 'react';
import PropTypes from 'prop-types';
import { CloseOutlined, SearchOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Input from 'shared/components/Input';
import {Button} from 'antd';
import '../../../shared/components/TopBar/AddLink/add-link.scss';

const SearchBar = props => {
  const { hideSearchOnType, addClick, addClickTeamOnly, customText, isLinkVisible, placeholder, searchText, setSearchText, handleSearch, showTeamsOnly } = props;
  if(customText === 'Add'){
    console.log(addClick);
  }
  const onChange = (e) => {
    const v = e.target.value;
    setSearchText(v);
    if (!v) {
      handleSearch(true);
    }
  };

  const onSearch = () => handleSearch();

  const onKeyPress = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      handleSearch();
    }
  };

  return (
    <div className='admin-dashboard__search-bar'>
      <div className='admin-dashboard__search-bar-left'>
        <Input
          allowClear={!hideSearchOnType}
          size='default'
          placeholder={placeholder}
          value={searchText}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
        {hideSearchOnType && searchText ? <CloseOutlined fill="rgba(0, 0, 0, 0.65)" onClick={() => setSearchText('')} /> : <SearchOutlined onClick={onSearch} />}
      </div>
      <div>
        <span>
          {/* <AddLink addClick={addClick} color='primaryBlue' customText={customText} /> */}
          {isLinkVisible && (
            <Button
              type='link'
              className='add-link add-link--primaryBlue'
              onClick={addClick}
            >
              <div className='add-link-inner'>
                <PlusCircleOutlined className='add-link__icon' style={{fontSize:'20px'}} />
                {customText || 'Add'}
              </div>
            </Button>
          )}
        </span>
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  customText: PropTypes.string,
  searchText: PropTypes.string,
  addClick: PropTypes.func,
  addClickTeamOnly: PropTypes.func,
  setSearchText: PropTypes.func,
  handleSearch: PropTypes.func,
  isLinkVisible: PropTypes.bool,
  showTeamsOnly: PropTypes.bool
};

SearchBar.defaultProps = {
  customText: 'Add',
  placeholder: 'Name',
  addClick: () => { },
  addClickTeamOnly: () => { },
  setSearchText: () => { },
  handleSearch: () => { },
  isLinkVisible: false,
  showTeamsOnly: false
};

export default SearchBar;
