import React, {useState} from 'react';
import PropTypes from 'prop-types';
import '../../../styles/ScheduleContainer/EventScheduleCard.scss';
import Icon from '@ant-design/icons';
import JoinBtn from '../../../../../shared/components/Button/JoinBtn';
import LawIcon from '../../../../../shared/components/Icon/icons/Law';
import Ballot from '../../../../../shared/components/Icon/icons/Ballot';
import SessionModal from 'features/EventContainer/components/ScheduleContainer/SessionModal'
import Trophy from '../../../../../shared/components/Icon/icons/Trophy';
import GrandOpening from '../../../../../shared/components/Icon/icons/GrandOpening';
import * as userTypes from 'shared/constants/userTypes';
import * as eventRoleTypes from 'shared/constants/eventRoleTypes';
import {connect} from 'react-redux';
import {todayMomentObject, momentize} from 'utils/dateTimeUtils';
import OverlayLoading from 'shared/components/Spinner/OverlayLoading';
import {sanitizeUrl as sanitize} from '@braintree/sanitize-url'

const EventScheduleCard = (props) => {
  const [modalVisible, setModalVisible] = useState(false);

  const {
    endTime,
    sessionId,
    title,
    startTime,
    cardType,
    iconType,
    date,
    cb,
    userEventRoleIds,
    userType,
    url,
    sessionStartTime,
    time_zone,
    loading
  } = props;

  const canEditSchedule = userType === userTypes.FIRST_ADMIN ||
  userType === userTypes.PARTNER_ADMIN ||
  userEventRoleIds.includes(eventRoleTypes.EVENT_ADMIN_ID);
  const twentyTwoHoursBefore = momentize(sessionStartTime) ? momentize(sessionStartTime).subtract(22, 'hours') : false
  const isJoinLinkDisabled = twentyTwoHoursBefore ? todayMomentObject().isBefore(twentyTwoHoursBefore) : false;

  const closeModal = () => {
    setModalVisible(false);
    cb();
  }

  const openModal = () => {
    setModalVisible(true);
  }

  const renderIcon = () => {
    switch(iconType) {
      case 'ballot':
        return Ballot;
      case 'trophy':
        return Trophy;
      case 'opening':
        return GrandOpening;
      default:
        return LawIcon
    }

  }

  return (
    <>
      <SessionModal
        showPanelInfo={false}
        sessionId={sessionId}
        visible={modalVisible}
        onCancel={closeModal}
        iconType={iconType}
        time_zone={time_zone}
      />

      <button disabled={!canEditSchedule} onClick={openModal} type="button" className="event-schedule-card-btn">
        <div className='event-session-card event-session-card--complete'>
          <div
            className={`event-schedule-card__rectangle event-schedule-card__rectangle--complete event-schedule-card__rectangle--${cardType}`}
          >
            <Icon component={renderIcon()} className="event-schedule-card__event-icon" />
          </div>
          <div className="event-schedule-card__data event-schedule-card__data--complete">
            <p className="paragraph--default paragraph--bold paragraph--charcoal event-schedule-card__data__title">
              {title}
            </p>
            <div className='event-session-card__data__date-time'>
              <span>
                <p className="paragraph--medium paragraph--charcoal">{date}</p>
                <p className="paragraph--medium paragraph--charcoal">
                  {startTime}
                  {' '}
                  -
                  {' '}
                  {endTime}
                </p>

              </span>
              {url ? (
                <div className='event-session-card__join-btn'>
                  <JoinBtn url={sanitize(url)} isDisabled={isJoinLinkDisabled} />
                </div>

              ): null}
            </div>
         
          </div>
          <OverlayLoading loading={loading}/>
        </div>
      </button>
    </>
  );
};

EventScheduleCard.defaultProps = {
  title: 'Judging Materials Submission Due',
  startTime: undefined,
  endTime: undefined,
  cardType: 'judging',
  iconType: 'ballot',
  sessionId: undefined,
  date: undefined,
  cb: () => {},
}

const mapStateToProps = (state) => {
  const { event: { eventDetails }, auth: { userType } } = state;
  const {
    userEventRoleIds,
    time_zone
  } = eventDetails
  return { userEventRoleIds, userType, time_zone  };
};

export default connect(mapStateToProps)(EventScheduleCard);