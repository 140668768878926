import React from 'react';
import Heading from 'shared/components/Heading';
import Paragraph from 'shared/components/Paragraph';
import NominatedList from './NominatedList';

const AwardNominateCard = (props) => {
  const { id, editable, awardName, awardDescription, assignments } = props;
  return (
    <div className='c-event-overview-awards__card'>
      <div className='c-event-overview-awards__card-header'>
        <div className='c-event-overview-awards__card-title'>
          <Heading>{awardName}</Heading>
        </div>
        {/* <Paragraph size='large' weight='bold'>
          {isRequired ? '* Required' : 'Optional'}
        </Paragraph> */}
      </div>
      <Paragraph size='medium'>{awardDescription}</Paragraph>
      <NominatedList editable={editable} awardId={id} assignments={assignments} />
    </div>
  );
};

export default AwardNominateCard;
