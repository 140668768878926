export const GET_EVENT_AWARDS_BEGIN = 'GET_EVENT_AWARDS_BEGIN';
export const GET_EVENT_AWARDS_SUCCESS = 'GET_EVENT_AWARDS_SUCCESS';
export const GET_EVENT_AWARDS_FAIL = 'GET_EVENT_AWARDS_FAIL';

export const PUBLISH_EVENT_AWARDS_BEGIN = 'PUBLISH_EVENT_AWARDS_BEGIN';
export const PUBLISH_EVENT_AWARDS_SUCCESS = 'PUBLISH_EVENT_AWARDS_SUCCESS';
export const PUBLISH_EVENT_AWARDS_FAIL = 'PUBLISH_EVENT_AWARDS_FAIL';

// Event Teams
export const GET_EVENT_AWARD_TEAMS_BEGIN = 'GET_EVENT_AWARD_TEAMS_BEGIN';
export const GET_EVENT_AWARD_TEAMS_SUCCESS = 'GET_EVENT_AWARD_TEAMS_SUCCESS'; 
export const GET_EVENT_AWARD_TEAMS_FAIL = 'GET_EVENT_AWARD_TEAMS_FAIL';

// Judging feedback
export const GET_EVENT_JUDGING_FEEDBACKS_BEGIN = 'GET_EVENT_JUDGING_FEEDBACKS_BEGIN';
export const GET_EVENT_JUDGING_FEEDBACKS_SUCCESS = 'GET_EVENT_JUDGING_FEEDBACKS_SUCCESS'; 
export const GET_EVENT_JUDGING_FEEDBACKS_FAIL = 'GET_EVENT_JUDGING_FEEDBACKS_FAIL';

export const PUBLISH_EVENT_JUDGING_FEEDBACKS_BEGIN = 'PUBLISH_EVENT_JUDGING_FEEDBACKS_BEGIN';
export const PUBLISH_EVENT_JUDGING_FEEDBACKS_SUCCESS = 'PUBLISH_EVENT_JUDGING_FEEDBACKS_SUCCESS'; 
export const PUBLISH_EVENT_JUDGING_FEEDBACKS_FAIL = 'PUBLISH_EVENT_JUDGING_FEEDBACKS_FAIL';

export const SET_EVENT_FEEDBACK_SESSIONS = 'SET_EVENT_FEEDBACK_SESSIONS';
export const RESET_EVENT_AWARDS = 'RESET_EVENT_AWARDS';
