import { Row, Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import TeamTile from './TeamTile';
import ListLoading from 'shared/components/Spinner/ListLoading';

const TeamsList = props => {
  const { teams, loading, included, isButton, onTileClick, showCoaches } = props;

  return (
    <div>
      <ListLoading loading={loading} text='Loading Teams' />
      {!loading && (
      <Row type='flex'>
        {teams.map(e => (
          <TeamTile showCoaches={showCoaches} isButton={isButton} included={included} team={e} key={e.id} id={e.id} onClick={onTileClick} />
        ))}
      </Row>
      )}
    </div>
  );
};

TeamsList.propTypes = {
  teams: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  included: PropTypes.array.isRequired,
  isButton: PropTypes.bool,
  onTileClick: PropTypes.func,
  showCoaches: PropTypes.bool
};

TeamsList.defaultProps = {
  loading: false,
  isButton: false,
  onTileClick: () => {},
  showCoaches: true
};

export default TeamsList;
