import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'shared/components/Icon';
import { Trans, useTranslation } from 'react-i18next'


const RegistrationForm = props => {
  const { title, error, removeError, email, children } = props;
  const { t } = useTranslation();
  return (
    <>
      {!!error && (
        <section role="alert" className='registration-page-layout__error'>
          <Icon type='warningIcon' />
          <span className='paragraph--bold paragraph--large paragraph--charcoal m-l-12 m-r-12'>
            {error}
          </span>
          <Icon type='close' onClick={removeError} />
        </section>
      )}
      <main className='registration-page-layout__form'>
        <h1 className='h-2 heading--charcoal'>{title}</h1>
        <p className='paragraph--small paragraph--charcoal'>{email}</p>
        {children}
      </main>
    </>
  );
};

RegistrationForm.propTypes = {
  children: PropTypes.node.isRequired
};

export default RegistrationForm;


/*
  <div className='paragraph--large paragraph--charcoal'>
  <b>Need Help? </b>Email <a href={`mailto:support@xyzpartner.com`} target='_blank'>
    support@xyzpartner.com
  </a>
</div> 
*/

