/* eslint-disable no-unused-vars */
import axios from 'axios';
import log from 'loglevel';
import React, { Suspense, useState, useEffect } from 'react';
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import store from 'features/store';

import Routes from 'Routes';
import GlobalUploadModal from 'features/GlobalUploadModal';
import { defaultConfig, dynamicConfigUrl } from './configuration/config';
import { useConfig } from './configuration/useConfig';
import { API } from 'utils/api';
import { UploadAPI } from 'utils/uploadAPI';
import Auth from 'Auth';
const persistor = persistStore(store);

const configLoadingErrorMessage =
  'Error while fetching global config, the App wil not be rendered. (This is NOT a React error.)';

//try to do a git dev build

function App() {
  const { i18n: i18nObject } = useTranslation();
  const { setConfig, config } = useConfig();
  const [configLoadingState, setConfigLoadingState] = useState('loading');
  //  "loading" | "ready" | "error"

  document.body.dir = i18nObject.dir();
  document.body.className = i18nObject.dir();

  useEffect(() => {
    log.debug('App.js, fetching global config from', dynamicConfigUrl);
    let configUrl = window.location.protocol + '//' + window.location.host + '/' + dynamicConfigUrl;
    axios
      .get(configUrl)
      .then((response) => {
        setConfig(response.data);
        log.debug('Global config fetched: ', response.data);
        setConfigLoadingState('ready');
      })
      .catch((e) => {
        // In Codesandbox.io: deleting `config.json` will not trigger this branch, because the request response code will still be 200, not 404.
        // To test this case in codesanbox.io, add "throw {};" to line 22.

        // In development, treat this case as a warning, render the app and use default config values.
        // In production (and test) on the other hand, show error instead of rendering the app.

        // In Codesandbox.io: You cannot change the value of NODE_ENV. To test this if, change "development"
        if (process.env.NODE_ENV === 'development') {
          log.warn(
            `Failed to load global configuration from '${dynamicConfigUrl}', using the default configuration instead:`,
            defaultConfig
          );
          setConfigLoadingState('ready');
        } else {
          log.error(
            configLoadingErrorMessage,
            `Have you provided the config file '${dynamicConfigUrl}'?`,
            e
          );
          setConfigLoadingState('error');
        }
      });
  }, [setConfig]);

  useEffect(() => {
    if (configLoadingState === 'ready') {
      // create API singleton instance
      API.CreateInstance(config);
      // create UploadAPI singleton instance
      UploadAPI.CreateInstance(config);
      // create Auth singleton instance
      Auth.CreateInstance(config);
    }
  }, [config, configLoadingState]);

  if (configLoadingState === 'loading') {
    return 'Loading the app...'; // change to some visual CircularProgress in real app
  }
  if (configLoadingState === 'error') {
    return <p style={{ color: 'red', textAlign: 'center' }}>{configLoadingErrorMessage}</p>;
  }

  return (
    <Provider store={store}>
      <Suspense fallback={null}>
        <PersistGate persistor={persistor} loading={null}>
          <Router>
            <GlobalUploadModal />
            <Routes />
          </Router>
        </PersistGate>
      </Suspense>
    </Provider>
  );
}

export default App;
