import validateEmail from 'utils/validateEmail';
import validatePhoneNumber from 'utils/validatePhoneNumber';

export const checkRequiredFields = (volunteer, line) => {
  const errorsArray = [];

  if(!volunteer.Email) {
    errorsArray.push({message: `Email address missing, line ${line}`})
  }
  if(!volunteer.FirstName) {
    errorsArray.push({message: `First name missing, line ${line}`})
  }
  if(!volunteer.LastName) {
    errorsArray.push({message: `Last name missing, line ${line}`})
  }
  if(!volunteer.EventRole) {
    errorsArray.push({message: `Role not specified, line ${line}`})
  }
  
  return errorsArray;
  
}

const checkEmailValidity = (volunteerEmail, line) => {

  if (!volunteerEmail) return [];
  const isEmailValid = validateEmail(volunteerEmail);

  if (!isEmailValid) return [{message: `Invalid email address, , line ${line}`}]

  return []

}

const checkPhoneNumberValidity = (phoneNumber, line) => {

  if (!phoneNumber) return [];
  const isPhoneValid = validatePhoneNumber(phoneNumber);

  if (!isPhoneValid) return [{message: `Invalid phone number, line ${line}`}]

  return []
}

const checkRoleValidity = (role, line, is_FLLC_event) => {
  const roleErrorMessage = [{message: `Role does not match available option, line ${line}`}];

  if (!role) return [];
  const roleInputType = typeof role;
  let AVAILABLE_ROLES = ['coach', 'referee', 'head referee', 'event admin', 'volunteer coordinator'];

  if (is_FLLC_event){
    AVAILABLE_ROLES.push('judge advisor', 'judge')
  }else{
    AVAILABLE_ROLES.push('reviewer advisor', 'reviewer')
  }
  
  if (roleInputType !== 'string') return roleErrorMessage;
  const lowerCaseRole = role.toLowerCase();
  console.log(lowerCaseRole);
  const isRoleValid = AVAILABLE_ROLES.includes(lowerCaseRole);
  
  if (!isRoleValid) return roleErrorMessage;

  return []
  


}

export const validateImportedVolunteer = (volunteer, line = 1, is_FLLC_event) => {
  const missingValuesErrors = checkRequiredFields(volunteer, line);
  const emailValidityErrors = checkEmailValidity(volunteer.Email, line);
  const phoneNumberErrors = checkPhoneNumberValidity(volunteer.Phone, line);
  const eventRoleErrors = checkRoleValidity(volunteer.EventRole, line, is_FLLC_event);

  const totalErrors = [...missingValuesErrors, ...emailValidityErrors, ...phoneNumberErrors, ...eventRoleErrors];

  return totalErrors
}