import React, {useEffect, useState} from 'react';
import { notification, Button, Modal, Form } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Icon from 'shared/components/Icon'
import InputPassword from 'shared/components/InputPassword'
import validatePassword from 'utils/validatePassword';
import * as userService from 'utils/services/users';
import { connect } from 'react-redux';
import { processAuthResult } from 'utils/authorize';
import {
  COACH_ID,
  HEAD_REFEREE_ID,
  EVENT_ADMIN_ID,
  JUDGE_ADVISOR_ID,
  REFEREE_ID,
  JUDGE_ID
} from 'shared/constants/eventRoleTypes';
import {
  setCanAccessAdminDashboard,
  setCanAccessVolunteerDashboard,
} from 'features/Authentication/redux/authActions';
import auth from '../../../../Auth';
import { 
  verifySignUpSuccess,
} from '../../redux/registrationActions';

// event admin, head referee, judge advisor
const ADMIN_EVENT_ROLE_TYPE_IDS = [
  HEAD_REFEREE_ID,
  EVENT_ADMIN_ID,
  JUDGE_ADVISOR_ID,
];

// coach, judge, referee, event admin, head referee, judge advisor
const VOLUNTEER_EVENT_ROLE_TYPE_IDS = [
  COACH_ID,
  JUDGE_ID,
  REFEREE_ID,
  HEAD_REFEREE_ID,
  EVENT_ADMIN_ID,
  JUDGE_ADVISOR_ID,
];


const FormItem = Form.Item;

export const FormLabel = ({ text }) => (
  <span className='paragraph--large paragraph--bold paragraph--charcoal'>
    {text}
  </span>
);

export const BackToOptions = ({ goBack, text }) => (
  <p className='paragraph--medium paragraph--blue registration__goback' onClick={goBack}>
    <Icon type='leftArrow' /> 
    {' '}
    {text || 'Back to options'}
  </p>
);

const CreateAccount = (props) => {
  const {
    handleCreateNew,
    email,
    setNextStep,
    initialQuery,
    setAdminDashboardAccess,
    setVolunteerDashboardAccess,
  } = props;
  const [confirmDirty, setConfirmDirty] = useState(false);
  const { t } = useTranslation()
  const [form] = Form.useForm();

  const handlePostAuth = async ()=>{
    const authResult = await auth.GetInstance().handleAuthentication();
    const roleData = await processAuthResult(authResult);
    if (roleData) {
      const canAccessAdminDashboard = ADMIN_EVENT_ROLE_TYPE_IDS.some(i => roleData.userEventRoles.includes(i)) || roleData.userType !== 'User';
      const canAccessVolunteerDashboard = VOLUNTEER_EVENT_ROLE_TYPE_IDS.some(i => roleData.userEventRoles.includes(i));
      setAdminDashboardAccess(canAccessAdminDashboard);
      setVolunteerDashboardAccess(canAccessVolunteerDashboard);
    }
    await setNextStep();
  }

  function success(payload) {
    Modal.success({
      title: <h1 className='paragraph--charcoal paragraph--large paragraph--bold'>Success!</h1>,
      content: (<p className='paragraph--charcoal paragraph--medium'>
        Please click
        {' '}
        <b>Next</b>
        {' '}
        for a few last things.
                </p>),
      okText: 'Next',
      onOk: () => {
        auth.GetInstance().accountAuthentication(handlePostAuth);
      }
    });
  }


  const handleSubmit = () => {
    const password = form.getFieldValue('password');
    const errMsg = validatePassword(password);

    if (errMsg || password === undefined || password === null) {
      notification.error({
        message: 'Validation Error',
        description: errMsg
      });
      return;
    }

    handleCreateNew(password, async () => {
      await userService.verifyInvitation(initialQuery);
      await userService.assignUserRole(initialQuery)
      await props.verifySignUpSuccess();
      success({email, password})
    });
  };

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0]);
  };

  const confirmPassword = (rule, value, callback) => {
    const password = form.getFieldValue('password');
    if (value && value !== password) {
      callback('Passwords don\'t match');
    } else {
      callback();
    }
  };

 const passwordErrorMessage = 'Password must have at least 8 characters including a lowercase letter, an uppercase letter, and a number.'
  const passwordPattern = {
    pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8})'),
    message: passwordErrorMessage
  }
  const validateToNextPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };
  const handleConfirmBlur = e => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value)
  };

  function hasErrors(fieldsError) {
    const formHasErrors = Object.keys(fieldsError).some(field => fieldsError[field]);
    return formHasErrors;
  }

  const passwordError = form.isFieldTouched('password') && form.getFieldError('password');
  return (
    <div className='registration__new-account'>
      <section className='registration__new-account-form'>
        <section className='registration__new-account-email'>
          <p className='paragraph--small paragraph--charcoal m-b-20'>{email}</p>
        </section>
        <Form hideRequiredMark onFinishFailed={onFinishFailed} form={form} name='NewAccount'>
          <div> 
            <FormItem name='password' label={<FormLabel text='Create password' />} hasFeedback rules={[{ required: true, message: 'Please Input Password. Password must have at least 8 characters including a lowercase letter, an uppercase letter, and a number              ' }, {validator: validateToNextPassword}, passwordPattern]}>              
              <InputPassword size='large' />
            </FormItem>

            <FormItem name='confirmPassword' label={<FormLabel text='Confirm password' />} hasFeedback rules={[{ required: true, message: 'Please Confirm Password' },{ validator: confirmPassword }]}>
              <InputPassword onBlur={handleConfirmBlur} size='large' />
            </FormItem>
          </div>
          <FormItem className='registration__new-account-btn-wrapper'>
            <Button className='registration__new-account-btn' type="primary" htmlType='submit' onClick={handleSubmit}>Next</Button>
          </FormItem>
        </Form>
      </section>
    </div>
  );
};

CreateAccount.propTypes = {
  initialQuery: PropTypes.object.isRequired

};

const mapStateToProps = (state) => {
  const {
    auth: { initialRegistrationDetails}
  } = state;
  return {
    initialQuery: initialRegistrationDetails
  };
};

const mapDispatchToProps = {
  verifySignUpSuccess,
  setAdminDashboardAccess: setCanAccessAdminDashboard,
  setVolunteerDashboardAccess: setCanAccessVolunteerDashboard,
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
