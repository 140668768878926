import React from 'react';
import PropTypes from 'prop-types';

const WarningIcon = ({fill = "#FAAD14"}) => {
  return (
    <svg viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      <g id="Volunteers" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="My-Volunteers---Add-Volunteers---Invite-Now---Errors---PDP-Admin" transform="translate(-664.000000, -534.000000)" fill={fill}>
          <g id="Group-25" transform="translate(139.000000, 10.000000)">
            <g id="Group-3" transform="translate(191.000000, 332.000000)">
              <g id="Group-24" transform="translate(0.000000, 28.000000)">
                <g id="Group-20" transform="translate(12.000000, 25.000000)">
                  <g id="Group-17-Copy-2" transform="translate(0.000000, 109.000000)">
                    <g id="Group-6" transform="translate(322.000000, 28.000000)">
                      <g id="Message/_Icon/Success-2" transform="translate(0.000000, 2.000000)">
                        <path d="M8,14.875 C8.928125,14.875 9.828125,14.69375 10.675,14.3359375 C11.49375,13.9890625 12.228125,13.49375 12.8609375,12.8625 C13.4921875,12.23125 13.9890625,11.4953125 14.334375,10.6765625 C14.69375,9.828125 14.875,8.928125 14.875,8 C14.875,7.071875 14.69375,6.171875 14.3359375,5.325 C13.9890625,4.50625 13.49375,3.771875 12.8625,3.1390625 C12.23125,2.5078125 11.4953125,2.0109375 10.6765625,1.665625 C9.828125,1.30625 8.928125,1.125 8,1.125 C7.071875,1.125 6.171875,1.30625 5.325,1.6640625 C4.50625,2.0109375 3.771875,2.50625 3.1390625,3.1375 C2.5078125,3.76875 2.0109375,4.5046875 1.665625,5.3234375 C1.30625,6.171875 1.125,7.071875 1.125,8 C1.125,8.928125 1.30625,9.828125 1.6640625,10.675 C2.0109375,11.49375 2.50625,12.228125 3.1375,12.8609375 C3.76875,13.4921875 4.5046875,13.9890625 5.3234375,14.334375 C6.171875,14.69375 7.071875,14.875 8,14.875 L8,14.875 Z M8,16 C3.58125,16 0,12.41875 0,8 C0,3.58125 3.58125,0 8,0 C12.41875,0 16,3.58125 16,8 C16,12.41875 12.41875,16 8,16 L8,16 L8,16 Z M8,6 C7.6546875,6 7.375,6.2796875 7.375,6.625 L7.375,12.390625 C7.375,12.7359375 7.6546875,13.015625 8,13.015625 C8.3453125,13.015625 8.625,12.7359375 8.625,12.390625 L8.625,6.625 C8.625,6.2796875 8.3453125,6 8,6 L8,6 Z M7.296875,4.296875 C7.296875,4.68520021 7.61167479,5 8,5 C8.38832521,5 8.703125,4.68520021 8.703125,4.296875 C8.703125,3.90854979 8.38832521,3.59375 8,3.59375 C7.61167479,3.59375 7.296875,3.90854979 7.296875,4.296875 L7.296875,4.296875 Z" id="Shape" transform="translate(8.000000, 8.000000) scale(1, -1) translate(-8.000000, -8.000000) " />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

WarningIcon.propTypes = {
  fill: PropTypes.string
}

export default WarningIcon;
