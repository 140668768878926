import { Row, Col, Form, Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Input from 'shared/components/Input';
import TextBox from 'shared/components/TextBox';
import { createEventAward } from 'features/EventOverviewContainer/redux/actions/assignedAwards';
import { useTranslation } from 'react-i18next';

const FormItem = Form.Item;

const rowProps = {
  type: 'flex',
  gutter: 20,
  className: 'm-t-20'
};

const colProps = {
  xs: 24
};

const AddAwardForm = (props) => {
  const { onCancel, isCreatingAward } = props;
  const { eventId } = useParams();
  const {t} = useTranslation();
  const [form] = Form.useForm();

  const handleSubmit = async (e) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }
      await props.createEventAward(values, eventId);
      onCancel();
  };

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  return (
    <Form hideRequiredMark onFinish={handleSubmit} onFinishFailed={onFinishFailed} name='CreateNewAward' form={form}>
      <Row {...rowProps}>
        <Col {...colProps}>
          <FormItem name='awardName' label={<span>{t('awardNameLabel')}</span>} rules={[{ required: true, message: 'Enter Award Name.' }]}>
              <Input size='large' placeholder={t('awardNameLabel')} />
          </FormItem>
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col {...colProps}>
          <FormItem name='awardDescription' label={<span>{t('awardDescriptionLabel')}</span>}>
              <TextBox
                size='large' 
                placeholder={t('awardDescriptionLabel')}
                rows={4}
              />
          </FormItem>
        </Col>
      </Row>
      <Row {...rowProps} justify="end">
        <Button
          htmlType='submit'
          additionalClassNames='m-r-10'
          loading={isCreatingAward}
        >
          {isCreatingAward ? 'Saving' : t('addLabel')}
        </Button>
      </Row>
    </Form>
  );
};

AddAwardForm.propTypes = {
  form: PropTypes.object.isRequired
};

const mapStateToProps = ({
  assignedAwards
}) => {
  const { meta: { isCreatingAward } } = assignedAwards;
  return {
    isCreatingAward,
  };
};

const mapDispatchToProps = {
  createEventAward
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAwardForm);
