import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Input from 'shared/components/Input';
import Dropdown from 'shared/components/FormDropdown';
import {
  fetchRegionsBy,
  fetchCountries
} from 'features/AdminDashboard/redux/actions/programsActions';
import '../../../styles/EditTeamForm.scss';
import { Button, Form } from 'antd';
import { handleUpdateTeam } from 'features/EventContainer/redux/actions/teams/updateTeam';

const { Option } = Dropdown;
const FormItem = Form.Item;

const EditTeamForm = (props) => {
  const {
    eventProgramId,
    regions,
    countries,
    updateTeam,
    refreshTeamData,
    onCancel,
    isFetchingCountries,
    isFetchingRegions,
    isUpdatingTeam,
    data,
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { teamId, teamName, teamNumber, countryId, regionId } = data;
  const countryName = data.getCountryName();
  const regionName = data.getRegionName();
  const regionProgram = data.getRegionProgram();

  const handleSearchRegions = (input) => {
    if (!input) return;
    props.fetchRegionsBy(eventProgramId || regionProgram, input);
  };

  const saveTeamInfo = (e) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }
    form.validateFields().then(async (values, err) => {
      const { country, region } = values;
      const payload = {
        ...values,
        teamId,
        country: country === countryName ? countryId : country,
        region: region === regionName ? regionId : region
      };
      updateTeam(payload, () => {
        refreshTeamData && refreshTeamData();
        onCancel();
      }, onCancel);
    });
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  }

  return (
    <div className="edit-team-container">
      <div className={`edit-team`}>
        <h3 className="edit-team__heading">{t('EditTeamLabel')}</h3>
        <Form hideRequiredMark onFinish={saveTeamInfo} onFinishFailed={onFinishFailed} name='EditTeamForm' form={form} initialValues={{teamName: teamName, teamNumber: teamNumber, country: countryName, region: regionName}}>
          <FormItem name='teamName' label={<span className={'form__label--visible'}>{t('teamNameLabel')}</span>} rules={[{ required: true, message: 'Enter Team Name.' }]}>
            <Input size='large' placeholder={t('teamNameLabel')} />
          </FormItem>
          <FormItem name='teamNumber' label={<span className={'form__label--visible'}>{t('EditTeamNumberLabel')}</span>} rules={[{ required: true, message: 'Enter Team Number.' }]}>
            <Input size='large' placeholder={t('EditTeamNumberLabel')} />
          </FormItem>
          <FormItem name='country' label={<span className={'form__label--visible'}>{t('countryLabel')}</span>} rules={[{ required: true, message: 'Select Country.' }]}>
              <Dropdown
                allowClear
                showSearch
                filterOption={false}
                placeholder={t('countryLabel')}
                onSearch={props.fetchCountries}
                popUpContainerElementId='country-autocomplete'
                loading={isFetchingCountries}
              >
                {countries.map(c => (
                  <Option key={c.id} value={c.id} text={c.name}>
                    {c.name}
                  </Option>
                ))}
              </Dropdown>
          </FormItem>
          <FormItem name='region' label={<span className={'form__label--visible'}>{t('regionLabel')}</span>} rules={[{ required: true, message: t('selectRegionLabel') }]}>
              <Dropdown
                allowClear
                showSearch
                filterOption={false}
                placeholder='Region'
                popUpContainerElementId='region-autocomplete'
                onSearch={handleSearchRegions}
                loading={isFetchingRegions}
              >
                {regions.map(({ id, name }) => (
                  <Option key={id} value={id} text={name}>{name}</Option>
                ))}
              </Dropdown>
          </FormItem>
        </Form>
      </div>
      <div className="edit-save-button">
        <Button size='large' type="primary" loading={isUpdatingTeam} onClick={saveTeamInfo}>
          {t('saveLabel')}
        </Button>
      </div>
    </div>
  );
};

EditTeamForm.propTypes = {
  // form: PropTypes.object.isRequired,
  // saveNewTeam: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  eventProgramId: PropTypes.string,
  fetchRegionsBy: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func.isRequired,
};

EditTeamForm.defaultProps = {
  onCancel: () => { }
};

const mapDispatchToProps = {
  updateTeam: handleUpdateTeam,
  fetchRegionsBy,
  fetchCountries
};

const mapStateToProps = (state) => {
  const {
    event: {
      eventDetails: {
        eventProgramId
      },
      eventTeams: {
        isUpdatingTeam
      }
    },
    adminBoard: {
      programs: {
        countries, regions, isFetchingRegions, isFetchingCountries
      }
    }
  } = state;
  return {
    eventProgramId,
    countries,
    regions,
    isFetchingRegions,
    isFetchingCountries,
    isUpdatingTeam
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTeamForm);
