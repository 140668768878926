import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import '../../styles/AdminDashboard.scss';
import { connect } from 'react-redux';
import {
  handleGetOrganizations,
  handleResetOrganizationForm,
  handleSetSelectedOrganization
} from 'features/AdminDashboard/redux/actions/organizationsActions';
import Pagination, { getPageFilter, initialPage } from 'shared/components/Pagination';
import SearchBar from 'features/AdminDashboard/components/SearchBar';
import TitleSortRow from 'shared/components/TitleSortRow';
import OrganizationsCardsList from 'shared/components/CardsList/Organizations';
import EditOrganizationModal from './EditOrganizationModal';

const OrganizationsContainer = props => {
  const [current, setCurrentPage] = useState(initialPage);
  const [searchText, setSearchText] = useState('');
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(null)

  const {
    getOrganizations,
    organizationsList,
    isLoadingOrganizations,
    organizationEdited,
    organizationsError,
    count,
    selectOrganization,
    resetEditForm
  } = props;

  const fetchPageOrganizations = useCallback((number, noSearch) => {
    const page = getPageFilter(number)
    getOrganizations(page, !noSearch && searchText)
  })

  const handleSearch = (noSearch) => {
    setCurrentPage(pre => {
      if (pre === initialPage && searchText) {
        fetchPageOrganizations(initialPage, noSearch)
      }
    return initialPage;
  })
}

  useEffect(() => {
    fetchPageOrganizations(current)
  }, [current])
  useEffect(() => {
    if (organizationEdited) {
      setCurrentPage(pre => {
        if (pre === initialPage) {
          fetchPageOrganizations(initialPage)
        }
        return initialPage
      })
    }
  }, [organizationEdited])

  const closeModal = () => {
    setEditModalVisible(false);
    setSelectedOrganizationId(null)
    resetEditForm();
  }

  const openModal = (id) => {
    setEditModalVisible(true);
    setSelectedOrganizationId(id);
    const selectedOrganization = organizationsList.find(i => i.organizationId === id);
    const programRegionsList = [];

    if (selectedOrganization) {
      const selectedRegions = [...selectedOrganization.programs];
      // console.log('here', selectedRegions)
      selectedRegions.forEach(r => {
        const isProgramAdded = programRegionsList.find(p => p.programId === r.programId)
        if (!isProgramAdded) {
          programRegionsList.push({
            ...r,
            initialRegions: [r.regionId],
            derivedRegions: [r],

          })
        } else {
          const programIndex = programRegionsList.findIndex(p => p.programId === r.programId);
          const selectedProgramRegion = {...programRegionsList[programIndex]};
          const orgInitialProgramRegions = [...selectedProgramRegion.initialRegions, r.regionId]
          const orgDerivedRegions = [...selectedProgramRegion.derivedRegions, { regionId: r.regionId,
            regionName: r.regionName, programId: r.programId}]
          programRegionsList[programIndex] = {
            ...selectedProgramRegion,
            initialRegions: orgInitialProgramRegions,
            derivedRegions: orgDerivedRegions
          }
        }
      })

      const updatedOrganization = {
        ...selectedOrganization,
        programs: programRegionsList,
        organizationInitialRegions: selectedOrganization.programs.map(ip => ip.regionId)
      }
      selectOrganization(updatedOrganization)

    }
  }
  return (
    <div className='admin-dashboard'>
      <EditOrganizationModal organizationId={selectedOrganizationId} visible={editModalVisible} onCancel={closeModal}  />
      <SearchBar
       
        searchText={searchText}
        setSearchText={setSearchText}
        handleSearch={handleSearch}
      />
      <div className='admin-dashboard__list'>

        <TitleSortRow title='Organizations' />

        <OrganizationsCardsList
          organizations={organizationsList}
          loading={isLoadingOrganizations}
          onEditClick={(orgId) => openModal(orgId)}
        />
        <Pagination
          total={count}
          current={current}
          onChange={setCurrentPage}
          loading={isLoadingOrganizations}
        />
      </div>
    </div>
  );
};

OrganizationsContainer.propTypes = {
  getOrganizations: PropTypes.func.isRequired,
  organizationsList: PropTypes.array.isRequired
};

const mapStateToProps = ({adminBoard}) => {
  const {
    organizations: {
      organizationsList,
      isLoadingOrganizations,
      organizationEdited,
      organizationsError,
      count
    }
  } = adminBoard;

  return {
    organizationsList,
    isLoadingOrganizations,
    organizationEdited,
    organizationsError,
    count
  }
}

const mapDispatchToProps = {
  resetEditForm: handleResetOrganizationForm,
  getOrganizations: handleGetOrganizations,
  selectOrganization: handleSetSelectedOrganization
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsContainer);