import React from 'react';
import { Popover, Menu} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';
import DownArrow from 'shared/components/Icon/icons/DownArrow';
import { connect } from 'react-redux';
import {handleTeamSelect} from 'features/EventOverviewContainer/redux/actions/getEventOverview'
import '../styles/EventOverviewHeader.scss';
import { useTranslation } from 'react-i18next';

const EventPageTeamSelection = props => {
  const {
    eventCoachTeamsList,
    selectedTeam,
  } = props;
  const { t } = useTranslation();

  const onSelect = (e) => {
    const {key} = e;
    props.handleTeamSelect(key)
  }
  const menu = (
    <Menu className='event-overview-header-team-selection__menu' onSelect={onSelect} selectedKeys={[selectedTeam.id]}>
      {eventCoachTeamsList.map(r => (
        <Menu.Item key={r.id} className='event-overview-header-team-selection__menu-item' disabled={r.disabled}>
          <p className='event-overview-header-team-selection__item'>{r.teamNumberName}</p>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <div className='event-overview-header-team-selection__title'>
      <h2 className='h-4 h--300 heading--white'>{`${t('teamLabel')}: ${selectedTeam.teamNumberName}`}</h2>
      <Popover placement="bottom" content={menu}><DownOutlined className='event-overview-header-team-selection__icon' /></Popover> 
    </div>
  );
};

EventPageTeamSelection.propTypes = {
  eventCoachTeamsList: PropTypes.array.isRequired,
  selectedTeam: PropTypes.object.isRequired,
  handleTeamSelect: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  const { eventOverview: { eventCoachTeamsList, selectedTeam } } = state;
  return { eventCoachTeamsList, selectedTeam };
};

export default connect(mapStateToProps, {handleTeamSelect})(EventPageTeamSelection);