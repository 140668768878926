import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchMessageChannels } from 'features/MessagingContainer/redux/eventMessageActions';
import MessagingChannel from 'features/MessagingContainer/components/MessagingChannel';
import Heading from 'shared/components/Heading';
import { useTranslation } from 'react-i18next';

const MessagingCard = (props) => {
  const { eventId } = useParams();
  const { channels, scheduleId, isAdminRole, userEventRoleIds } = props;
  const {t} = useTranslation();

  useEffect(() => {
    props.fetchMessageChannels(eventId, scheduleId);
  }, [eventId, scheduleId]);

  const filtered_channels = channels.filter(({roles})=>{
    const role = roles.find((rId) => userEventRoleIds.includes(rId))
    return Boolean(role) || isAdminRole
  })

  return (
    <div>
      <Heading tag={2} additionalClassNames='m-b-24 m-t-24'>{t('messagingLabel')}</Heading>
      <div className={`c-event-messaging__channels c-event-messaging__channels--modal`}>
        {filtered_channels.map((channel) => (
          <MessagingChannel
            key={channel.id}
            {...channel}
          />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { eventOverview: { userEventRoleIds, isAdminRole }, eventMessaging: { channels } } = state;
  return {
    isAdminRole, 
    userEventRoleIds,
    channels,
  };
};

const mapDispatchToProps = {
  fetchMessageChannels
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagingCard);
