import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
  getScoringTimestamps,
  getMatches
} from 'features/EventOverviewContainer/redux/actions/coachScoring';
import {changeFileUploadingStatus} from 'features/EventOverviewContainer/redux/actions/uploadingFileStatus';
import ListLoading from 'shared/components/Spinner/ListLoading';
import Evidence from './Evidence';
import {updateGlobalUploadModalProps} from 'features/GlobalUploadModal/redux/actions'
import _ from 'lodash';

const MatchesAndScoring = (props) => {
  const { t } = useTranslation()
  const {
    coachScoring,
    teamId,
    teamName, teamNumber, teamLocation,
    changeFileUploadingStatus,
    updateGlobalUploadModalProps
  } = props;

  useEffect(() => {
    props.getScoringTimestamps();
    props.getMatches(teamId)
  }, [teamId]);


  const setUploadModalVisibility = (value)=>{
    updateGlobalUploadModalProps({visible: value})
  }

  const setUploadProgress = (value)=>{
    updateGlobalUploadModalProps({uploadProgress: value})
  }

  const onModalCancel = ()=>{
    updateGlobalUploadModalProps({
      visible:false,
      uploadProgress:0,
    })
  }

  useEffect(()=>{
    updateGlobalUploadModalProps({
      onCancel:onModalCancel,
      onUploadStatusChanged:changeFileUploadingStatus
    })
  }, [onModalCancel, changeFileUploadingStatus])
  
  const {
    coachMatches,
    isFetchingCoachMatches
  } = coachScoring;

  const sortedCoachMatches = _.orderBy(coachMatches, ['matchTypeName', 'matchNumber'], ['desc', 'asc']);;

  return (
    <div>
      <div className="coach-matches-scoring-title">{t('matchesAndScoringLabel')}</div>
      <div className="coach-matches-scoring-upload-instruction">{t('scoringUploadIntructionText')}</div>
      {isFetchingCoachMatches ? (
        <ListLoading loading text={t('loadingMatchesAndScoringLabel')} />
      ) : (
        <Evidence
          matches={sortedCoachMatches}
          setUploadModalVisibility={setUploadModalVisibility}
          setUploadProgress={setUploadProgress}
        />
      )}
    </div>
  );
};

MatchesAndScoring.propTypes = {
  coachScoring: PropTypes.object.isRequired,
  getScoringTimestamps: PropTypes.func.isRequired,
  getMatches: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
  teamLocation: PropTypes.string,
  teamName: PropTypes.string,
  teamNumber: PropTypes.string
}

MatchesAndScoring.defaultProps = {
  teamLocation: '',
  teamName: '',
  teamNumber: ''
}
const mapStateToProps = (state) => {
  const {coachScoring, eventOverview: {selectedTeam}} = state;
  const {id, teamName, teamNumber, teamLocation} = selectedTeam;

  return {coachScoring, teamId: id, teamName, teamNumber, teamLocation};
}

const mapDispatchToProps = {
  getScoringTimestamps,
  getMatches,
  changeFileUploadingStatus,
  updateGlobalUploadModalProps
}
export default connect(mapStateToProps, mapDispatchToProps)(MatchesAndScoring);