import * as types from '../types/assignedJudgingSessionsTypes';

const DEFAULT_STATE = {
  assignedSessions: [],
  assignedPanels: [],
  isFetchingAssignedSessions: false,
  documentTypes: [],
  isFetchingDocumentTypes: false,
  assignedSessionsCount: 0,
  count: 60
}


export default (state = DEFAULT_STATE, action) => {

  const {type} = action;
  switch(type) {
    case types.GET_ASSIGNED_JUDGING_SESSIONS_BEGIN:
      return {
        ...state,
        assignedSessionsCount: 0, 
        isFetchingAssignedSessions: true
      }
    case types.GET_ASSIGNED_JUDGING_SESSIONS_ERROR:
      return {
        ...state,
        assignedSessionsCount: 0, 
        isFetchingAssignedSessions: false
      }
    case types.GET_ASSIGNED_JUDGING_SESSIONS_SUCCESS:
      return {
        ...state,
        isFetchingAssignedSessions: action.payload.loading,
        assignedSessions: action.payload.assignedSessions,
        assignedPanels: action.payload.assignedPanels
      }
    case types.SET_ASSIGNED_JUDGES_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload.currentPage
      }
    case types.UPDATE_ASSIGNED_JUDGING_SESSIONS_COUNT:
      return {
        ...state,
        assignedSessionsCount: action.payload
      }
    default:
      return state;
  }

}