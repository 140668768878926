

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Row, Col, Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'shared/components/FormDropdown';
import getRefereePanels from 'features/EventContainer/redux/actions/scoringActions/getScoringPanels';
import EventModal from 'features/EventContainer/components/EventModal';
import handleCreateScoringPanel from 'features/EventContainer/redux/actions/scoringActions/createNewScoringPanel';
import PanelTitle from './PanelTitle';

import '../../../../../features/PanelManagement/styles/JudgingPanelModal.scss';
import { IN_PERSON_OFFICIAL, IN_PERSON_TEST } from 'shared/constants/eventTypes';
import { officialRefereeReviewSessionType, practiceRefereeReviewSessionType } from 'shared/constants/timestampTypes';
import _ from 'lodash';
import PanelAssignmentTable from './PanelAssignmentTable';
import SessionPanelUsers from 'shared/components/SessionPanelUsers';
import Input from 'shared/components/Input';



const FormItem = Form.Item;
const SelectOption = Select.Option;

const rowProps = {
  type: 'flex', gutter: 20,
  className: 'm-t-20'
};

const colProps = {
  xs: 24,
  md: 16
}

const PanelModal = (props) => {
  const {
    roleType,
    visible,
    onCancel,
    isEdit,
    panelTitle,
    panelSubtitle,
    toolTipInfo,
    eventProgramId,
    newPanelTitle,
    isCreatingNewRefereePanel,
    refereeOptions,
    sessionOptions,
    formattedPanels,
    event_type
  } = props;
  const isInPersonEvent = event_type === IN_PERSON_OFFICIAL || event_type === IN_PERSON_TEST;
  const { t } = useTranslation()
  const [form] = Form.useForm();

  const [tableName, setTableName] = useState("");

  const validateSelectedJudgesCount = (rule, value, callback) => {

    if (!Array.isArray(value) || value.length <= 1) {
      callback('At least two referees must be assigned to the panel.')
    } else {
      callback()
    }
  }

  const findUniquePanelNames = (rule, value, callback) => {
    if (value === undefined) callback()

    let match = false;
    formattedPanels.map((panel) => {
      let panelName = panel.panelName.trim().toLowerCase();
      if (panelName == value.trim().toLowerCase()) {
        match = true;
      }
    })
    if (match) {
      callback('Table name already exists')
    } else {
      callback();
    }
  }


  const handleCloseModal = () => {
    onCancel();
    setTableName("")
    form.resetFields();
  }

  const successCb = () => {
    handleCloseModal()
  }

  const handleSave = async (e) => {
    e.preventDefault()
    form.validateFields().then(async (values, err) => {
      const payload = {
        panelName: values.tableName,
        refereeIds: values.referees || [],
        sessionIds: values.sessions || []
      }
      await props.handleCreateScoringPanel(payload, successCb);
    });
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  }

  const text1 = t('selectRefereeLabel');
  const text2 = t('selectTeamsLabel');

  const getMatchNumberText = (teamOpt) => {
    const { sessionTypeId, matchNumber, teamName } = teamOpt;
    let labelText = '';
    if (sessionTypeId === practiceRefereeReviewSessionType) {
      labelText = `${teamName} (${t('practiceReviewLabel')})`;
    } else if (sessionTypeId === officialRefereeReviewSessionType) {
      labelText = `${teamName} (${t('officialReviewLabel')})`;
    }

    if (isInPersonEvent && matchNumber && sessionTypeId !== practiceRefereeReviewSessionType) {
      labelText = labelText.replace(')', ` ${matchNumber})`)
    }
    return labelText
  }

  const generatePanelsCommaSeparated = (panels) => {
    const panelsArray = panels.map((panel) => {
      return panel.panelName
    })

    let renderPanels = panelsArray.join(", ")
    return renderPanels
  }

  const sortedSessionOptions = _.orderBy(sessionOptions, ['teamName', 'sessionTypeName', 'matchNumber'], ['asc', 'desc', 'asc'])
  const selectedRefereeIds = form.getFieldValue('referees') || [];
  const selectedReferees = refereeOptions.filter(r => selectedRefereeIds.includes(r.userId))
  return (
    <EventModal
      visible={visible}
      onCancel={handleCloseModal}
      closable={!isCreatingNewRefereePanel}
      extraClass='in-person-scoring-modal'
    >
      <PanelTitle instructions={toolTipInfo} panelSubtitle={panelSubtitle} title={`${isEdit ? t('editLabel') : t('addLabel')} Table`} isEdit={isEdit} />
      {/* <PanelTitle instructions={toolTipInfo} panelSubtitle={panelSubtitle} title={`${isEdit ? t('editLabel') : t('addLabel')} ${newPanelTitle}`} isEdit={isEdit} /> */}

      <Form hideRequiredMark onFinish={handleSave} form={form} name='AddJudge'>

        <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem name='tableName' label={<span>{t('Table name')}</span>} rules={[{ required: true, message: t('Enter table name') }, { validator: findUniquePanelNames }]}>
                <Input onChange={(e) => { setTableName(e.target.value); }} className="judging-modal-judges-tab__add-select" size='large' placeholder={'Name'} />
            </FormItem>
          </Col>
        </Row>

        <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem name='referees' label={t('refereesLabel')}>
                <Select
                  showArrow
                  showSearch
                  filterOption={false}
                  mode='multiple'
                  optionLabelProp="label"
                  optionFilterProp='label'
                  className='judging-modal-judges-tab__add-select'
                  popUpContainerElementId='add-judge'
                  placeholder={text1}
                >
                  {refereeOptions.map(j => (
                    <SelectOption value={j.userId} label={`${j.fullName}`}>
                      <span aria-label={`${j.fullName}`} className='judging-modal-judges-tab__add-option'>
                        <div>
                          <p className='paragraph--medium paragraph--bold paragraph--charcoal'>{`${j.fullName}`}</p>
                          {j?.panels?.length > 0 ? (
                            <p className='paragraph--small paragraph--blue'>{generatePanelsCommaSeparated(j.panels)}</p>
                          ) : (
                            <p className='paragraph--small paragraph--red'>{t('notAssignedLabel')}</p>
                          )}
                        </div>
                      </span>
                    </SelectOption>
                  ))}
                </Select>
            </FormItem>

          </Col>
        </Row>
        {/* <Row className="referee-assignment-panel-user-row">
          <SessionPanelUsers panelName={newPanelTitle} users={selectedReferees} hideAvatar={true} />
        </Row> */}
        {!isInPersonEvent ? <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem name='sessions' label={t('teamsLabel')}>
                <Select
                  showArrow={false}
                  showSearch
                  mode='multiple'
                  size="large"
                  className="judging-panel-modal__add-select"
                  placeholder={text2}
                  allowClear
                  optionLabelProp="label"
                  optionFilterProp='label'
                >
                  {sortedSessionOptions.map((teamOpt) => (
                    <SelectOption
                      value={teamOpt.sessionId}
                      label={getMatchNumberText(teamOpt)}
                      disabled={teamOpt.panelId}
                    >
                      <span
                        aria-label={teamOpt.teamName}
                        className="judging-modal-judges-tab__add-option"
                      >
                        <div>
                          <p className="paragraph--medium paragraph--bold paragraph--charcoal">

                            {getMatchNumberText(teamOpt)}

                          </p>
                          {teamOpt.panelId ? (
                            <p className="paragraph--small paragraph--blue">{teamOpt.panelName}</p>
                          ) : (
                            <p className="paragraph--small paragraph--red">{t('notAssignedLabel')}</p>
                          )}
                        </div>
                      </span>
                    </SelectOption>
                  ))}
                </Select>
            </FormItem>

          </Col>
        </Row>
          :
          <Row>
            <FormItem name='sessions' label={t('teamsLabel')}>
                <PanelAssignmentTable data={sortedSessionOptions} panelName={tableName} form={form} isEdit={false}/>
            </FormItem>
          </Row>}
        <Row {...rowProps}>
          <Col {...colProps}>
            <Button loading={isCreatingNewRefereePanel} additionalClassNames='add-event-modal__btn' htmlType='submit'>{t('addLabel')}</Button>
          </Col>
        </Row>
      </Form>
    </EventModal>
  );
};

PanelModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  resetJudgesList: PropTypes.func,
  resetTeamsList: PropTypes.func,
  isEdit: PropTypes.bool,
  refereeOptions: PropTypes.array,
  sessionOptions: PropTypes.array,
  panelTitle: PropTypes.string,
  panelSubtitle: PropTypes.string,
  assignedJudges: PropTypes.array,
  addPanel: PropTypes.func,
  assignedSessions: PropTypes.array,
  handleCreateScoringPanel: PropTypes.func,
  isCreatingNewRefereePanel: PropTypes.bool,
  getRefereePanels: PropTypes.func
};

PanelModal.defaultProps = {
  visible: false,
  onCancel: () => { },
  resetJudgesList: () => { },
  resetTeamsList: () => { },
  isEdit: false,
  refereeOptions: [],
  sessionOptions: [],
  panelTitle: 'Add Panel',
  panelSubtitle: 'Must assign at least two volunteers',
  assignedJudges: [],
  assignedSessions: [],
  addPanel: () => { },
  handleCreateScoringPanel: () => { },
  isCreatingNewRefereePanel: false,
  getRefereePanels: () => { }
};

const mapStateToProps = (state) => {
  const { event: { eventDetails, eventScoring: { isCreatingNewRefereePanel, refereeOptions, sessionOptions, formattedPanels } } } = state;
  const { eventProgramId, attributes: { event_type } } = eventDetails;
  return {
    eventProgramId,
    isCreatingNewRefereePanel, refereeOptions, sessionOptions, event_type, formattedPanels
  }
}

const mapDispatchToProps = {
  handleCreateScoringPanel,
  getRefereePanels
};


export default connect(mapStateToProps, mapDispatchToProps)(PanelModal);