import * as eventRolesService from 'utils/services/eventRoles';
import {formatUserEventRoleTypesList} from 'utils/services/eventRoles/model';
import * as eventRoleTypes from 'shared/constants/eventRoleTypes';
import * as types from '../types/userEventOverviewRoleTypes';

const getEventRolesBegin = () => {
  return {
    type: types.GET_USER_EVENT_OVERVIEW_ROLES_BEGIN
  }
}

const getEventRolesError = () => {
  return {
    type: types.GET_USER_EVENT_OVERVIEW_ROLES_ERROR
  }
}

const getEventRolesSuccess = (userEventRoleIds) => {
  return {
    type: types.GET_USER_EVENT_OVERVIEW_ROLES_SUCCESS,
    payload: {
      userEventRoleIds
    }
  }
}

// const setCoachDetails = (data) => {
//   return {
//     type: types.SET_EVENT_OVERVIEW_COACH_DETAILS
//   }
// }

const handleFetchUserEventRoles = (eventId) => {
  return async (dispatch, getState) => {
    // await dispatch(getEventRolesBegin());
    // try {
    //   const {auth: {userId}} = getState();
    //   const {included} = await eventRolesService.getUserEventRolesByUserIdAndEventId(userId, eventId);
    //   const eventRolesList = formatUserEventRoleTypesList(included);
    //   console.log(eventRolesList)
    //   const isCoach = eventRolesList.includes(eventRoleTypes.COACH_ID);
    //   console.log("isCoach", isCoach)
    //   await dispatch(getEventRolesSuccess(eventRolesList));
    //   if (isCoach) {
        

    //   }
    // } catch(err) {
    //   await dispatch(getEventRolesError())
    //   throw err;
    // }
  }
}


export default handleFetchUserEventRoles;