import * as types from 'features/EventContainer/redux/types/eventDetailsTypes';
import * as adminEventTypes from 'features/AdminDashboard/redux/types/eventsTypes';
import { FLL_CHALLENGE_ID } from 'shared/constants/programIds';

const DEFAULT_EVENT_STATE = {
  isFetchingEventDetails: true,
  type: "event",
  id: null,
  error: false,
  attributes: {
    title: '',
    description: '',
    start_date: '',
    end_date: '',
  },
  included: [],
  eventScheduleId: undefined,
  eventProgramId: undefined,
  eventRegionId: undefined,
  userEventRoleIds: [],
  isFetchingUserEventRoles: false,
  scheduleError: false,
  isChangingTimeZone: false,
  time_zone: null,
  is_FLLC_event: null,
}

export default (state = DEFAULT_EVENT_STATE, action) => {
  const {type} = action;
  switch(type) {
    case adminEventTypes.FETCH_ADMIN_DASHBOARD_EVENTS_BEGIN: {
      return DEFAULT_EVENT_STATE;
    }
    case types.GET_EVENT_DETAILS_BEGIN:
      return {
        ...state,
        eventScheduleId: null,
        eventProgramId: null,
        is_FLLC_event: null,
        isFetchingEventDetails: true
      }
    case types.GET_EVENT_DETAILS_ERROR:
      return {
        ...state,
        isFetchingEventDetails: false,
        error: true
      }
    case types.GET_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload.eventDetails,
        included: action.payload.eventIncluded,
        isFetchingEventDetails: false,
        time_zone: ((((action||{}).payload||{}).eventDetails||{}).attributes||{}).timezone,
      }
    case types.SET_EVENT_SCHEDULE_ID:
      return {
        ...state,
        eventScheduleId: action.payload.eventScheduleId
      }
    case types.SET_EVENT_PROGRAM_ID:
      return {
        ...state,
        eventProgramId: action.payload.eventProgramId,
        is_FLLC_event: action.payload.eventProgramId === FLL_CHALLENGE_ID,
      }
    case types.GET_USER_EVENT_ASSIGNMENTS_BEGIN:
      return {
        ...state,
        isFetchingUserEventRoles: true
      }
    case types.GET_USER_EVENT_ASSIGNMENTS_ERROR:
      return {
        ...state,
        isFetchingUserEventRoles: false,
        userEventRoleIds: []
      }
    case types.GET_USER_EVENT_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        isFetchingUserEventRoles: false,
        userEventRoleIds: action.payload.userEventRoleIds
      }
    case types.SET_EVENT_REGION_ID:
      return {
        ...state,
        eventRegionId: action.payload.eventRegionId
      }
    case types.SET_ADMIN_EVENT_SCHEDULE_ERROR:
      return {
        ...state,
        scheduleError: true
      }
    case types.SET_ADMIN_EVENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        scheduleError: false
      }
    case types.SET_ADMIN_EVENT_TIME_ZONE: 
      return {
        ...state,
        time_zone: action.payload
      }
    case types.START_CHANGING_SCHEDULE_TIME_ZONE:
      return {
        ...state,
        isChangingTimeZone: true,
      }
    case types.FINISH_CHANGING_SCHEDULE_TIME_ZONE:
      return {
        ...state,
        isChangingTimeZone: false,
        time_zone: action.payload
      }
    default:
      return state
  }
}