import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import RegistrationFooter from './Registration/Footer';
import './styles/RegistrationPageLayout.scss';

const RegistrationLayout = props => {
  const { children, isSigningUp } = props;
  const wrapperRef = useRef(null);
  const [size, setSize] = useState(0);

  useEffect(()=>{
    if(wrapperRef && wrapperRef.current && wrapperRef.current.clientWidth){
      setSize(wrapperRef.current.clientWidth)
    }
  }, [wrapperRef]);
  
  useEffect(() => {
    const resizeListener = () => {
      if(wrapperRef && wrapperRef.current && wrapperRef.current.clientWidth){
        setSize(wrapperRef.current.clientWidth)
      }
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  const generateTriangleStyle = (size = 1440) => {
    const multiplier = 1
    const ratio = size/1440 * multiplier;
    const width = ratio * 304;
    const height = ratio * 197;
    const right = ratio * (-28);
    const bottom = ratio * (-79);
    return {height: `${height}px`, width: `${width}px`, right: `${right}px`, bottom: `${bottom}px`}
  }

  const triangleStyle = generateTriangleStyle(size);

  return (
    <div className='registration registration-page-layout'>
      { isSigningUp
        ? <p className='paragraph--white paragraph--bold paragraph--large registration-page-layout__invited '>
          You've been invited!
        </p>
        : null }
      <header className='registration-page-layout__logo'>
        <div className="registration-page-layout__logo_wrapper">
          <img
            src='/First-Event-Hub-logo.png'
            alt='logo'
            className='registration-page-layout__header_logo'
          />
        </div>
      </header>
      <div className='registration-page-layout__container' ref={wrapperRef}>
        {children}
        <div className="registration-page-layout__triangle" style={triangleStyle}/>
      </div>
      <RegistrationFooter />
    </div>
  );
};

RegistrationLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default RegistrationLayout;
