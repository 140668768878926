import {
  JUDGE_ADVISOR_ID,
  JUDGE_ID,
  COACH_ID,
} from 'shared/constants/eventRoleTypes';


  export default [
    {
      description: 'Engineering Notebook Guidelines',
      link: 'https://www.firstinspires.org/sites/default/files/uploads/resource_library/ftc/engineering-notebook-guidelines.pdf',
      roles: [JUDGE_ID, JUDGE_ADVISOR_ID, COACH_ID]
    },
    {
      description: 'Engineering Notebook Self Assessment',
      link: 'https://www.firstinspires.org/sites/default/files/uploads/resource_library/ftc/eng-notebook-self-assessment.pdf',
      roles: [COACH_ID]
    },
    {
      description: 'Judging Self Reflections Sheet',
      link: 'https://www.firstinspires.org/sites/default/files/uploads/resource_library/ftc/team-judging-self-reflection.pdf',
      roles: [COACH_ID]
    },
    {
      description: 'Judge Feedback',
      link: 'https://drive.google.com/file/d/1R3Dwb7dnTO64CxzQhoTHtNOKFu1_SY6G/view?usp=sharing',
      roles: [JUDGE_ID, JUDGE_ADVISOR_ID, COACH_ID]
    },
    {
      description: 'Control Award Instructions',
      link: 'https://www.firstinspires.org/sites/default/files/uploads/resource_library/ftc/control-award-instructions.pdf',
      roles: [JUDGE_ID, JUDGE_ADVISOR_ID, COACH_ID]
    },
    {
      description: 'Control Award Submission Form',
      link: 'https://www.firstinspires.org/sites/default/files/uploads/resource_library/ftc/control-award-submission-form.pdf',
      roles: [COACH_ID]
    },
    {
      description: 'Awards List',
      link: 'https://drive.google.com/file/d/19XwNwREWY5-vAlxtEYIY6zkNOZlU6nuF/view?usp=sharing',
      roles: [JUDGE_ID, JUDGE_ADVISOR_ID, COACH_ID]
    },
  ]