import { Row, Col, Form, Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextBox from 'shared/components/TextBox';
import { useParams } from 'react-router-dom';
import find from 'lodash/find'

import {
  createJudgingComment,
  editJudgingComment,
} from 'features/EventOverviewContainer/redux/actions/judgingRubric'

const FormItem = Form.Item;

const rowProps = {
  type: 'flex',
  gutter: 20,
  className: 'm-t-20'
};

const colProps = {
  xs: 24, md: 16
};

const paragraphClassName = 'paragraph--xlarge paragraph--charcoal paragraph--normal'

const JudgingCommentForm = (props) => {
  const { sessionId } = useParams();

  const {
    firstName,
    lastName,
    isCreatingJudgingComment,
    judgingRubricComments,
    commentId,
    id,
    is_FLLC_event
  } = props;

  const existingComment = find(judgingRubricComments, c => c.commentId === commentId)
  const initialValue = existingComment ? existingComment.comment : ''

  const submitCallBack = () => form.resetFields()

  const handleSubmit = async(e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields().then(async (values, err) => {
      if (err) {
        throw err;
      }
      const {comment} = values;
      const fullName = `${firstName} ${lastName}`
      await props.createJudgingComment(comment, sessionId, fullName, submitCallBack, is_FLLC_event)
    });
  };

  const handleEdit = async(e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields().then(async (values, err) => {
      if (err) {
        throw err;
      }
      const { comment } = values;
      // const fullName = commentId ? existingComment.fullName : `${firstName} ${lastName}`
      const fullName = `${firstName} ${lastName}`
      const payload = { comment, referee_id: id }
      await props.editJudgingComment(payload, fullName, commentId, submitCallBack, is_FLLC_event)
    });
  };

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  return (
    <Form hideRequiredMark onFinish={commentId ? handleEdit : handleSubmit} onFinishFailed={onFinishFailed} name='CreateNewRefereeNote' initialValues={{comment: initialValue}}>
      <Row {...rowProps}>
        <Col {...colProps}>
          <p className={paragraphClassName}>
            {commentId ? existingComment.fullName : firstName + ' ' + lastName}
            {/* {firstName} 
            {' '}
            {lastName} */}
          </p>
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col {...colProps}>
          <FormItem name='comment' label={<span>Comment</span>}>
              <TextBox 
                size='large' 
                placeholder='Comment'
                rows={4}
              />
          </FormItem>
        </Col>
      </Row>
      <Row {...rowProps} justify="end">
        <Button
          loading={isCreatingJudgingComment}
          htmlType='submit'
        >
          { commentId
            ? 'Edit'
            : 'Add' }
        </Button>
      </Row>
    </Form>
  );
};

JudgingCommentForm.propTypes = {
  form: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  createJudgingComment: PropTypes.func,
  editJudgingComment: PropTypes.func,
  isCreatingJudgingComment: PropTypes.bool,
  judgingRubricComments: PropTypes.array,
  commentId: PropTypes.string,
};

JudgingCommentForm.defaultProps = {
  createJudgingComment: () => {},
  editJudgingComment: () => {},
  isCreatingJudgingComment: false
}
const mapStateToProps = (state) => {
  const { auth: {
    userInfo: {
      firstName,
      lastName,
      id
    }},
    eventOverview: { is_FLLC_event },
    judgingRubric: {
      isCreatingJudgingComment,
      judgingRubricComments,
    } } = state;
  return {
    firstName,
    lastName,
    isCreatingJudgingComment,
    judgingRubricComments,
    id,
    is_FLLC_event
  };
};

export default connect(
  mapStateToProps,
  {
    createJudgingComment,
    editJudgingComment,
  }
)(JudgingCommentForm);
