import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BackLink from 'shared/components/BackLink';
import { useHistory, useParams } from 'react-router-dom';
import BtnPrimary from 'shared/components/Button/BtnPrimary';
import BtnSecondary from 'shared/components/Button/BtnSecondary';
import '../../styles/judgingRubric/RubricHeader.scss';
import sessionStatuses from 'shared/constants/judgingSessionStatuses';
import SessionStatus from 'shared/components/SessionStatus';
import Container from 'shared/components/Container'
import {
  saveExistingJudgingRubric,
  editUploadedRubricPublishStatus,
  submitFLLERubric,
  getJudgingRubricFLLE
} from 'features/EventOverviewContainer/redux/actions/judgingRubric';
import * as programTypes from 'shared/constants/programIds';
import { useTranslation } from 'react-i18next';
import ConfirmModal from 'shared/components/ConfirmModal';
import PublishStatusBar from 'shared/components/PublishStatusBar';
import { Tooltip } from 'antd';
import Paragraph from 'shared/components/Paragraph';
import Success from 'shared/components/Icon/icons/Success';
import { getTransLabel } from 'utils/translation';

const titleClassName = 'heading--charcoal h-2 h--normal m-b-12';

const RubricHeader = props => {
  const { eventId, sessionId } = useParams();
  const {
    is_FLLC_event,
    teamDetails,
    sessionDetails,
    programId,
    editRubric,
    editFLLERubric,
    rubric,
    editUploadedRubric,
    sessionDocumentId,
    isUpdatingUploadedRubric,
    isUpdatingFllChallengeRubric,
    isUploadedRubricPublishable,
    isUploadedRubricPublished,
    isRubricFileUploaded,
    fllEChallengeRubric,
    getFLLERubric
  } = props;

  const {
    teamName,
    teamNumber
  } = teamDetails

  const {
    sessionStatusId,
    sessionStatusName,
    sessionTypeName,
    digital_rubric
  } = sessionDetails;

  const [confirmVisible, setConfirmVisible] = useState(false);

  const sessionStatus = sessionStatuses.find(s => s.statusId === sessionStatusId) || {};
  const { badgeType, statusName } = sessionStatus;

  const teamNameNumber = teamNumber ? `${teamNumber} - ${teamName}` : `${teamName}`;

  const { t } = useTranslation();

  const browserHistory = useHistory();
  const backClick = () => {
    browserHistory.push(`/event-overview/${eventId}#judging-sessions`)
  }

  // Commenting out creat rubric path since POST should be called when fetching a first-time rubric
  const handleSaveAndExit = () => {
    if (programId === programTypes.FLL_CHALLENGE_ID) {
      editRubric(sessionId, undefined, backClick, eventId)
    }
    if ((programId === programTypes.FLL_EXPLORE_ID)) {
      editFLLERubric(sessionId, undefined, backClick, eventId)  
    }
  };

  const handleSaveAndSubmit = () => {
    if ((programId === programTypes.FLL_CHALLENGE_ID)) {
      editRubric(sessionId, !rubric.is_publishable, () => { setConfirmVisible(false) }, eventId)
    }
  };

  const submitBtnText = rubric.is_publishable ? t('unpublishFeedbackLabel') : t('submitFeedbackLabel')

  const uploadedRubricSubmitText = isUploadedRubricPublishable ? `${t('unpublishLabel')} ${t('rubricLabel')}` : t('submitRubricLabel');

  const submitFLLERubricData = () => {
    editFLLERubric(sessionId, !fllEChallengeRubric.is_publishable, () => { }, eventId)
    // getFLLERubric(digital_rubric)
  }


  const updatedUploadedRubricFile = () => {
    if (!sessionDocumentId) return;

    if (isUploadedRubricPublishable) {
      const unpublishRubricPayload = {
        data: {
          type: 'session_document',
          id: sessionDocumentId,
          attributes: {
            is_publishable: false,
          }
        }
      }
      editUploadedRubric(sessionDocumentId, unpublishRubricPayload, sessionId)
    } else {
      const publishRubricPayload = {
        data: {
          type: 'session_document',
          id: sessionDocumentId,
          attributes: {
            is_publishable: true,
          }
        }
      }
      editUploadedRubric(sessionDocumentId, publishRubricPayload, sessionId)
    }
  }

  const submitBtnDisabled = (programId === programTypes.FLL_DISCOVER_ID && !isRubricFileUploaded) || isUploadedRubricPublished

  const uploadedRubricButtons = () => {

    if (programId === programTypes.FLL_EXPLORE_ID){
      if (!fllEChallengeRubric.is_publishable) {
        return (
          <div className='c-judging-rubric-header__btn-wrapper'>
            <span className='c-judging-rubric-header__exit-btn'>
              <BtnSecondary
                disabled={isUpdatingUploadedRubric || fllEChallengeRubric.is_publishable || fllEChallengeRubric.is_published}
                tabIndex={0}
                onClick={handleSaveAndExit}
              >
                {t('saveAndExitLabel')}
              </BtnSecondary>
            </span>
            <Tooltip overlayClassName="c-judging-rubric-header-tooltip" placement="top" title={tooltipText(t('submitRubricTipLabel'))}>
              <div>
                <BtnPrimary loading={isUpdatingUploadedRubric} onClick={submitFLLERubricData} disabled={isUpdatingUploadedRubric}>
                {t('submitFeedbackLabel')}
                </BtnPrimary>
              </div>
            </Tooltip>
          </div>)
      } else {
        return (
          <div className='c-judging-rubric-header__btn-wrapper'>
            <span className='c-judging-rubric-header__exit-btn'>
              <Tooltip overlayClassName="c-judging-rubric-header-tooltip" placement="left" title={tooltipText("Use this to change the rubric after it has been submitted. It will need to be re-submitted after changes have been made.")}>
                <div>
                  <BtnSecondary loading={isUpdatingUploadedRubric} onClick={submitFLLERubricData} disabled={isUploadedRubricPublished || fllEChallengeRubric.is_published}>
                  {t('unpublishFeedbackLabel')}
                  </BtnSecondary>
                </div>
              </Tooltip>
            </span>
            <div className="c-judging-rubric-header__sumitted">
              <Success />
              <span>{t('submittedLabel')}</span>
            </div>
          </div>
        )
      }
    }
    else{
      if (!isUploadedRubricPublishable) {
        return (<Tooltip overlayClassName="c-judging-rubric-header-tooltip" placement="top" title={tooltipText(t('submitRubricTipLabel'))}>
          <div>
            <BtnPrimary loading={isUpdatingUploadedRubric} onClick={updatedUploadedRubricFile} disabled={submitBtnDisabled}>
              {t('submitRubricLabel')}
            </BtnPrimary>
          </div>
        </Tooltip>)
      } else {
        return (
          <div className='c-judging-rubric-header__btn-wrapper'>
            <span className='c-judging-rubric-header__exit-btn'>
              <Tooltip overlayClassName="c-judging-rubric-header-tooltip" placement="left" title={tooltipText("Use this to change the rubric after it has been submitted. It will need to be re-submitted after changes have been made.")}>
                <div>
                  <BtnSecondary loading={isUpdatingUploadedRubric} onClick={updatedUploadedRubricFile} disabled={isUploadedRubricPublished}>
                    {`${t('unpublishLabel')} ${t('rubricLabel')}`}
                  </BtnSecondary>
                </div>
              </Tooltip>
            </span>
            <div className="c-judging-rubric-header__sumitted">
              <Success />
              <span>{t('submittedLabel')}</span>
            </div>
          </div>
        )
      }
    }
    
  }

  const tooltipText = (text) => (
    <div className='c-judging-rubric-header__button-tooltip'>
      <span>{text}</span>
    </div>
  )

  return (
    <Container type='default'>
      <ConfirmModal
        visible={confirmVisible}
        onCancel={() => { setConfirmVisible(false) }}
        onSubmit={handleSaveAndSubmit}
        title={t('submitFeedbackLabel')}
        description={t('confirmationtTextLabel')}
      />
      <div className='c-judging-rubric-header'>
        <div className='c-judging-rubric-header__nav'>
          <BackLink tabIndex={0} backClick={backClick} text={is_FLLC_event ? t('backJudgingAssignmentsLabel') : t('nonChallengeBackJudgingAssignmentsLabel')} />
          {(programId === programTypes.FLL_CHALLENGE_ID) ? (
            <div className='c-judging-rubric-header__btn-wrapper'>
              <span className='c-judging-rubric-header__exit-btn'>
                {
                  !rubric.is_publishable ?
                    <BtnSecondary
                      disabled={isUpdatingFllChallengeRubric || rubric.is_published || rubric.is_publishable}
                      tabIndex={0}
                      onClick={handleSaveAndExit}
                    >
                      {t('saveAndExitLabel')}
                    </BtnSecondary>
                    :
                    <Tooltip overlayClassName="c-judging-rubric-header-tooltip" placement="left" title={tooltipText("Use this to change the rubric after it has been submitted. It will need to be re-submitted after changes have been made.")}>
                      <div>
                        <BtnSecondary
                          disabled={isUpdatingFllChallengeRubric || rubric.is_published}
                          tabIndex={0}
                          onClick={handleSaveAndSubmit}
                        >
                          {t('unpublishFeedbackLabel')}
                        </BtnSecondary>
                      </div>
                    </Tooltip>
                }
              </span>
              {
                !rubric.is_publishable ?
                  <Tooltip overlayClassName="c-judging-rubric-header-tooltip" placement="top" title={tooltipText(t('submitRubricTipLabel'))}>
                    <div>
                      <BtnPrimary
                        loading={isUpdatingFllChallengeRubric}
                        disabled={rubric.is_published}
                        onClick={() => setConfirmVisible(true)}
                      >
                        {t('submitFeedbackLabel')}
                      </BtnPrimary>
                    </div>
                  </Tooltip>
                  :
                  <div className="c-judging-rubric-header__sumitted">
                    <Success />
                    <span>{t('submittedLabel')}</span>
                  </div>
              }
            </div>
          ) : uploadedRubricButtons()}
        </div>
        <div className='c-judging-rubric-header__title'>
          <h2 className={titleClassName}>
            {getTransLabel(t, sessionTypeName, is_FLLC_event)}
            :
            {' '}
            {teamNameNumber}
          </h2>
          <div>
            <SessionStatus badgeType={badgeType} statusText={t(statusName)} />
          </div>
        </div>
      </div>
    </Container>
  );
};

RubricHeader.propTypes = {
  sessionDetails: PropTypes.object.isRequired,
  teamDetails: PropTypes.object.isRequired,
  programId: PropTypes.string.isRequired,
  editRubric: PropTypes.func.isRequired,
  rubric: PropTypes.object.isRequired,
  isUploadedRubricPublishable: PropTypes.bool.isRequired,
  editUploadedRubric: PropTypes.func.isRequired,
  sessionDocumentId: PropTypes.string,
  isUpdatingUploadedRubric: PropTypes.bool.isRequired,
  isUpdatingFllChallengeRubric: PropTypes.bool.isRequired,
};

RubricHeader.defaultProps = {
  sessionDocumentId: ''
}

const mapStateToProps = (state) => {
  const { judgingRubric, eventOverview: {is_FLLC_event}} = state;
  const {
    teamDetails,
    sessionDetails,
    eventDetails,
    fllChallengeRubric,
    fllEChallengeRubric,
    isUploadedRubricPublished,
    isUploadedRubricPublishable,
    judgingRubricTemplate,
    isUpdatingUploadedRubric,
    isUpdatingFllChallengeRubric,
    isRubricFileUploaded
  } = judgingRubric;
  const { documentTypeId } = judgingRubricTemplate
  const { programId } = eventDetails;
  return {
    is_FLLC_event,
    isRubricFileUploaded,
    teamDetails,
    sessionDetails,
    programId,
    rubric: fllChallengeRubric,
    isUploadedRubricPublished,
    isUploadedRubricPublishable,
    sessionDocumentId: documentTypeId,
    isUpdatingUploadedRubric,
    isUpdatingFllChallengeRubric,
    fllEChallengeRubric
  }
}

const mapDispatchToProps = {
  editRubric: saveExistingJudgingRubric,
  editFLLERubric: submitFLLERubric,
  editUploadedRubric: editUploadedRubricPublishStatus,
  getFLLERubric: getJudgingRubricFLLE,
}

export default connect(mapStateToProps, mapDispatchToProps)(RubricHeader);