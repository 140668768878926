export const GET_COACH_MATCHES_BEGIN = 'GET_COACH_MATCHES_BEGIN';
export const GET_COACH_MATCHES_ERROR = 'GET_COACH_MATCHES_ERROR';
export const GET_COACH_MATCHES_SUCCESS = 'GET_COACH_MATCHES_SUCCESS';


export const GET_COACH_SCORING_TIMESTAMPS_BEGIN = 'GET_COACH_SCORING_TIMESTAMPS_BEGIN';
export const GET_COACH_SCORING_TIMESTAMPS_ERROR = 'GET_COACH_SCORING_TIMESTAMPS_ERROR';
export const GET_COACH_SCORING_TIMESTAMPS_SUCCESS = 'GET_COACH_SCORING_TIMESTAMPS_SUCCESS';

export const UPLOAD_COACH_MATCH_FILE_BEGIN = 'UPLOAD_COACH_MATCH_FILE_BEGIN';
export const UPLOAD_COACH_MATCH_FILE_ERROR = 'UPLOAD_COACH_MATCH_FILE_ERROR';
export const UPLOAD_COACH_MATCH_FILE_SUCCESS = 'UPLOAD_COACH_MATCH_FILE_SUCCESS';
export const UPLOAD_COACH_MATCH_FILE_RETRY = 'UPLOAD_COACH_MATCH_FILE_RETRY';

export const REMOVE_COACH_MATCH_FILE_BEGIN = 'REMOVE_COACH_MATCH_FILE_BEGIN';
export const REMOVE_COACH_MATCH_FILE_ERROR = 'REMOVE_COACH_MATCH_FILE_ERROR';
export const REMOVE_COACH_MATCH_FILE_SUCCESS = 'REMOVE_COACH_MATCH_FILE_SUCCESS';

export const GET_SELECTED_MATCH_SCORE_INPUT_BEGIN = 'GET_SELECTED_MATCH_SCORE_INPUT_BEGIN';
export const GET_SELECTED_MATCH_SCORE_INPUT_ERROR = 'GET_SELECTED_MATCH_SCORE_INPUT_ERROR';
export const GET_SELECTED_MATCH_SCORE_INPUT_SUCCESS = 'GET_SELECTED_MATCH_SCORE_INPUT_SUCCESS';

export const RESET_SELECTED_MATCH_SCORE_INPUT = 'RESET_SELECTED_MATCH_SCORE_INPUT';