import { API } from 'utils/api';
import Event from 'models/Event';
import { queryStringify } from 'utils/query';

//const { http } = API;
const baseUrl = '/api/events';

// model method
const formatEventData = (r) => {
  const eventObj = {};
  eventObj.dateInfo = r.getEventDateObject();
  eventObj.startDate = r.startDate;
  eventObj.name = r.name;
  eventObj.id = r.eventId;
  eventObj.programId = r.programId;
  eventObj.location = r.getEventLocation();
  eventObj.url = r.url;
  eventObj.description = r.description;
  eventObj.teamsNumbers = r.getTeamsNumbers();
  eventObj.time_zone = r.time_zone;
  eventObj.event_type = r.event_type;
  return eventObj;
};

export const getEventsBy = async (filter, page, sort) => {
  const sortQuery = sort ? `&sort=${encodeURIComponent(sort)}` : '';
  const queryStr = queryStringify({ filter, page });
  const url = `${baseUrl}${queryStr}${sortQuery}`;
  const { data, included, meta } = await API.GetInstance().http.get(url);
  const includedArr = included || []
  const eventsList = data.map(d => formatEventData(new Event(d, includedArr)));
  return { eventsList, count: meta && meta.count };
};

export const getEvents = async (page) => {
  return await getEventsBy(null, page);
};

export const getEventsByIds = async (eventsIds) => {
  if (!eventsIds || !eventsIds.length) {
    return { eventsList: [] };
  }
  const filter = {
    id: `in:${eventsIds.join(',')}`
  };
  const page = eventsIds.length > 25 && { size: eventsIds.length, number: 1 };
  return await getEventsBy(filter, page);
};

export const getEventsByUserId = async (userId, page) => {
  if (!userId) {
    return { eventsList: [] };
  }
  const filter = { 'event_assignments.user.id': `eq:${userId}` };
  if (page) {
    return await getEventsBy(filter, page);
  }
  const initialPageSize = 50;
  const res = await getEventsBy(filter, { size: initialPageSize, number: 1 });
  const { count } = res;
  if (count && count > initialPageSize) {
    return await getEventsBy(filter, { size: count, number: 1 });
  }
  return res;
};

export const getEventsByPartnerRegions = async (partnerRegions, page, searchFilter, sort="") => {
  if (!partnerRegions || !partnerRegions.length) {
    return { eventsList: [] };
  }
  const partnerRegionIds = partnerRegions.map(p => p.regionId);
  const filter = {
    'region.id': `in:${partnerRegionIds.join(',')}`,
    ...searchFilter
  };
  return await getEventsBy(filter, page, sort);
};

export const getEventById = async (eventId) => {
  const url = `${baseUrl}/${eventId}`
  const response = await API.GetInstance().http.get(url);
  const { data, included } = response;
  return { data, included: included || [] };
};

export const createNewEvent = async (payload) => {
  const response = await API.GetInstance().http.post(baseUrl, payload);
  const { data, included } = response;
  return formatEventData(new Event(data, included));
};

export const getEventTeams = async (eventId) => {
  const url = `${baseUrl}/${eventId}/teams`;
  const response = await API.GetInstance().http.get(url);
  const { data, included } = response;
  return { data, included: included || [] };
}


export const editEvent = async (eventId, payload) => {
  const url = `${baseUrl}/${eventId}`;
  const { data, included } = await API.GetInstance().http.patch(url, payload);
  return { 
    data, 
    included: included || []
  };
}

export const deleteEvent = async (eventId) => {
  const url = `${baseUrl}/${eventId}`;
  return await API.GetInstance().http.delete(url);
}
