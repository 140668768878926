export const GET_RUBRIC_EVENT_DETAILS_BEGIN = 'GET_RUBRIC_EVENT_DETAILS_BEGIN';
export const GET_RUBRIC_EVENT_DETAILS_ERROR = 'GET_RUBRIC_EVENT_DETAILS_ERROR';
export const GET_RUBRIC_EVENT_DETAILS_SUCCESS = 'GET_RUBRIC_EVENT_DETAILS_SUCCESS';

export const GET_RUBRIC_JUDGING_SESSION_BEGIN = 'GET_RUBRIC_JUDGING_SESSION_BEGIN';
export const GET_RUBRIC_JUDGING_SESSION_ERROR = 'GET_RUBRIC_JUDGING_SESSION_ERROR';
export const GET_RUBRIC_JUDGING_SESSION_SUCCESS = 'GET_RUBRIC_JUDGING_SESSION_SUCCESS';

export const SET_RUBRIC_TEAM_DETAILS = 'SET_RUBRIC_TEAM_DETAILS';

export const SET_RUBRIC_FILES = 'SET_RUBRIC_FILES';

export const GET_FLL_CHALLENGE_JUDGING_RUBRIC_BEGIN = 'GET_FLL_CHALLENGE_JUDGING_RUBRIC_BEGIN';
export const GET_FLL_CHALLENGE_JUDGING_RUBRIC_ERROR = 'GET_FLL_CHALLENGE_JUDGING_RUBRIC_ERROR';
export const GET_FLL_CHALLENGE_JUDGING_RUBRIC_SUCCESS = 'GET_FLL_CHALLENGE_JUDGING_RUBRIC_SUCCESS';
export const GET_FLL_E_CHALLENGE_JUDGING_RUBRIC_SUCCESS = 'GET_FLL_E_CHALLENGE_JUDGING_RUBRIC_SUCCESS';

export const UPDATE_FLL_CHALLENGE_JUDGING_RUBRIC = 'UPDATE_FLL_CHALLENGE_JUDGING_RUBRIC';
export const UPDATE_FLL_E_CHALLENGE_JUDGING_RUBRIC = 'UPDATE_FLL_E_CHALLENGE_JUDGING_RUBRIC';

export const SAVE_EXISTING_FLL_CHALLENGE_JUDGING_RUBRIC_BEGIN = 'SAVE_EXISTING_FLL_CHALLENGE_JUDGING_RUBRIC_BEGIN';
export const SAVE_EXISTING_FLL_CHALLENGE_JUDGING_RUBRIC_ERROR = 'SAVE_EXISTING_FLL_CHALLENGE_JUDGING_RUBRIC_ERROR';
export const SAVE_EXISTING_FLL_CHALLENGE_JUDGING_RUBRIC_SUCCESS = 'SAVE_EXISTING_FLL_CHALLENGE_JUDGING_RUBRIC_SUCCESS';

export const CREATE_FLL_CHALLENGE_JUDGING_RUBRIC_BEGIN = 'CREATE_FLL_CHALLENGE_JUDGING_RUBRIC_BEGIN';
export const CREATE_FLL_CHALLENGE_JUDGING_RUBRIC_ERROR = 'CREATE_FLL_CHALLENGE_JUDGING_RUBRIC_ERROR';
export const CREATE_FLL_CHALLENGE_JUDGING_RUBRIC_SUCCESS = 'CREATE_FLL_CHALLENGE_JUDGING_RUBRIC_SUCCESS';

export const SET_IS_FLL_CHALLENGE_RUBRIC_NEW = 'SET_IS_FLL_CHALLENGE_RUBRIC_NEW';

export const SET_FLL_CHALLENGE_RUBRIC_JUDGING_ID = 'SET_FLL_CHALLENGE_RUBRIC_JUDGING_ID'; 

export const GET_JUDGING_RUBRIC_COMMENTS_BEGIN = 'GET_JUDGING_RUBRIC_COMMENTS_BEGIN';
export const GET_JUDGING_RUBRIC_COMMENTS_ERROR = 'GET_JUDGING_RUBRIC_COMMENTS_ERROR';
export const GET_JUDGING_RUBRIC_COMMENTS_SUCCESS = 'GET_JUDGING_RUBRIC_COMMENTS_SUCCESS';

export const CREATE_JUDGING_RUBRIC_COMMENT_BEGIN = 'CREATE_JUDGING_RUBRIC_COMMENT_BEGIN';
export const CREATE_JUDGING_RUBRIC_COMMENT_ERROR = 'CREATE_JUDGING_RUBRIC_COMMENT_ERROR';
export const CREATE_JUDGING_RUBRIC_COMMENT_SUCCESS = 'CREATE_JUDGING_RUBRIC_COMMENT_SUCCESS';

export const EDIT_JUDGING_RUBRIC_COMMENT_BEGIN = 'EDIT_JUDGING_RUBRIC_COMMENT_BEGIN';
export const EDIT_JUDGING_RUBRIC_COMMENT_ERROR = 'EDIT_JUDGING_RUBRIC_COMMENT_ERROR';
export const EDIT_JUDGING_RUBRIC_COMMENT_SUCCESS = 'EDIT_JUDGING_RUBRIC_COMMENT_SUCCESS';

export const DELETE_JUDGING_RUBRIC_COMMENT_BEGIN = 'DELETE_JUDGING_RUBRIC_COMMENT_BEGIN';
export const DELETE_JUDGING_RUBRIC_COMMENT_ERROR = 'DELETE_JUDGING_RUBRIC_COMMENT_ERROR';
export const DELETE_JUDGING_RUBRIC_COMMENT_SUCCESS = 'DELETE_JUDGING_RUBRIC_COMMENT_SUCCESS';

export const GET_JUDGING_SESSION_RUBRIC_TEMPLATE_BEGIN = 'GET_JUDGING_SESSION_RUBRIC_TEMPLATE_BEGIN';
export const GET_JUDGING_SESSION_RUBRIC_TEMPLATE_ERROR = 'GET_JUDGING_SESSION_RUBRIC_TEMPLATE_ERROR';
export const GET_JUDGING_SESSION_RUBRIC_TEMPLATE_SUCCESS = 'GET_JUDGING_SESSION_RUBRIC_TEMPLATE_SUCCESS';


export const UPLOAD_JUDGING_SESSION_RUBRIC_BEGIN = 'UPLOAD_JUDGING_SESSION_RUBRIC_BEGIN'
export const UPLOAD_JUDGING_SESSION_RUBRIC_ERROR = 'UPLOAD_JUDGING_SESSION_RUBRIC_ERROR'
export const UPLOAD_JUDGING_SESSION_RUBRIC_SUCCESS = 'UPLOAD_JUDGING_SESSION_RUBRIC_SUCCESS'
export const UPLOAD_JUDGING_SESSION_RUBRIC_RETRY = 'UPLOAD_JUDGING_SESSION_RUBRIC_RETRY'

export const SET_UPLOADED_RUBRIC_FILE = 'SET_UPLOADED_RUBRIC_FILE';

export const DELETE_UPLOADED_RUBRIC_FILE_BEGIN = 'DELETE_UPLOADED_RUBRIC_FILE_BEGIN';
export const DELETE_UPLOADED_RUBRIC_FILE_ERROR = 'DELETE_UPLOADED_RUBRIC_FILE_ERROR';
export const DELETE_UPLOADED_RUBRIC_FILE_SUCCESS = 'DELETE_UPLOADED_RUBRIC_FILE_SUCCESS';

export const UPDATE_UPLOADED_JUDGING_SESSION_RUBRIC_BEGIN = 'UPDATE_UPLOADED_JUDGING_SESSION_RUBRIC_BEGIN';
export const UPDATE_UPLOADED_JUDGING_SESSION_RUBRIC_ERROR = 'UPDATE_UPLOADED_JUDGING_SESSION_RUBRIC_ERROR';
export const UPDATE_UPLOADED_JUDGING_SESSION_RUBRIC_SUCCESS = 'UPDATE_UPLOADED_JUDGING_SESSION_RUBRIC_SUCCESS';

export const SET_IS_UPLOADED_JUDGING_RUBRIC_PUBLISHABLE = 'SET_IS_UPLOADED_JUDGING_RUBRIC_PUBLISHABLE';
export const SET_IS_UPLOADED_JUDGING_RUBRIC_PUBLISHED = 'SET_IS_UPLOADED_JUDGING_RUBRIC_PUBLISHED';

export const GET_RUBRIC_JUDGING_SESSION_DOCUMENTS_BEGIN = 'GET_RUBRIC_JUDGING_SESSION_DOCUMENTS_BEGIN';
export const GET_RUBRIC_JUDGING_SESSION_DOCUMENTS_ERROR = 'GET_RUBRIC_JUDGING_SESSION_DOCUMENTS_ERROR';
export const GET_RUBRIC_JUDGING_SESSION_DOCUMENTS_SUCCESS = 'GET_RUBRIC_JUDGING_SESSION_DOCUMENTS_SUCCESS';