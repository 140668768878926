import React from 'react';
import Paragraph from 'shared/components/Paragraph';
import { useTranslation } from 'react-i18next';

const Instructions = ({ readOnly }) => {
  const { t } = useTranslation()

  return (
    <div className='c-event-leaderboard__instructions'>
      {!readOnly && <Paragraph size='medium' weight='bold'>{t('instructionsLabel')}</Paragraph>}
      <Paragraph size='medium'>
        {t('leaderboardInstructionText')}
      </Paragraph>
    </div>
  );
};

export default Instructions;
