import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import { connect } from 'react-redux';
import BtnPrimary from 'shared/components/Button/BtnPrimary';
import PublishStatusBar from 'shared/components/PublishStatusBar';
import {
  fetchEventLeaderboard,
  publishEventLeaderboard
} from './redux/leaderboardActions';
import LeaderboardTable from './components/LeaderboardTable';
import LeaderboardConfirmModal from './components/LeaderboardConfirmModal';
import Instructions from './components/Instructions';
import './Leaderboard.scss';
import { useTranslation } from 'react-i18next';

const LeaderboardContainer = (props) => {
  const [visible, setVisible] = useState(false);
  const [canUnpublish, setUnpublish] = useState(false);
  const { readOnly, canEdit, eventId, isFTC, teams, isFetchingLeaderboard, isPublishingBoard, leaderboardId, isPublished } = props;
  const { t } = useTranslation();
  
  useEffect(() => {
    if (leaderboardId) {
      props.fetchEventLeaderboard(leaderboardId);
    }
  }, [leaderboardId]);

  const handlePublish = async () => {
    if (leaderboardId) {
      await props.publishEventLeaderboard(eventId, leaderboardId, canUnpublish);
      setUnpublish(false);
    } else {
      notification.error({
        message: t('warningLabel'),
        description: t('noLeaderboardDataLabel'),
      });
    }
    setVisible(false);
  };

  const unPubProp = isPublished && canEdit && { onClick: () => setUnpublish(true) };

  return (
    <div className='c-event-leaderboard'>
      <LeaderboardConfirmModal
        visible={visible}
        onSubmit={handlePublish}
        onCancel={() => setVisible(false)}
        isPublished={isPublished}
      />
      {!readOnly && (
        <div className='c-event-leaderboard__actions'>
          <PublishStatusBar
            publishText={t('leaderboardPublishedLabel')}
            published={isPublished && !canUnpublish}
            publishable
            {...unPubProp}
          />
          {(!isPublished || canUnpublish) && (
            <BtnPrimary
              disabled={isFetchingLeaderboard || isPublishingBoard}
              loading={isPublishingBoard}
              onClick={() => setVisible(true)}
            >
              {canUnpublish ? t('unpublishLabel') : t('publishLabel')} {t('leaderboardLabel')}
            </BtnPrimary>
          )}
        </div>
      )}
      <Instructions readOnly={readOnly} />
      <LeaderboardTable eventId={eventId} teams={teams} isFTC={isFTC} loading={isFetchingLeaderboard} showPublicButton={isPublished} leaderboardId={leaderboardId} />
    </div>
  );
};

const mapStateToProps = ({ eventLeaderboard }) => {
  const {
    teams,
    leaderboardId,
    leaderboard: { isPublished, id },
    isFetchingLeaderboard,
    isPublishingBoard
  } = eventLeaderboard;

  return {
    teams,
    isPublished,
    leaderboardId: leaderboardId || id,
    isFetchingLeaderboard,
    isPublishingBoard
  };
};

const mapDispatchToProps = {
  fetchEventLeaderboard,
  publishEventLeaderboard
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardContainer);
