import React from 'react';
import { Upload} from 'antd';
import Button from 'shared/components/Button/BtnSecondary';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';



const ChooseFile = (props) => {
  const {loading, accept, onChange, text, disabled, ..._props } = props;
  // disabled={_props.disabled}
  const { t } = useTranslation()
  const DEFAULT_TEXT = t('chooseFileLabel')

  return (
    <Upload
      accept={accept}
      customRequest={onChange}
      showUploadList={false}
      {..._props}
    >
      <Button
        loading={loading}
        type='default'
        styleType='secondary'
        size='small'
        additionalClassNames='p-r-24 p-l-24 p-t-8 p-b-8'
        onChange={onChange}
        disabled={disabled}
      > 
        {text || DEFAULT_TEXT}
      </Button>
    </Upload>
  );
};

ChooseFile.propTypes = {
  onChange: PropTypes.func,
  text: PropTypes.string,
  accept: PropTypes.string,
  loading: PropTypes.bool
};

ChooseFile.defaultProps = {
  onChange: () => {},
  text: null,
  accept: '.txt, .csv',
  loading: false
}

export default ChooseFile;