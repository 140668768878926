import React from 'react';
import { LoginContainer } from 'features/RegistrationContainer';

const Login = (props) => {
  return (
    <div>
      <LoginContainer {...props} />
    </div>
  );
};

export default Login;
