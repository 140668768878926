import { officialRefereeReviewSessionType, practiceRefereeReviewSessionType } from "./timestampTypes";

const openCeremonyTypeId = '06f87ea7-666a-4807-ace6-e07bd68787c8';
const closeCeremonyTypeId = '69397715-05a1-497f-b74f-86b53371de33';
const judgingMaterialDueTimestampTypeId = '60d3520a-fe88-42f9-b118-ed0fc85567b5';
const officialMatchSubmissionDueTimestampTypeId = '398b1b4e-cfac-4912-a56a-9f94dacfc33f';
const officialJudgingSessionTypeId = '362bcfc5-894d-4bfc-a31f-136857fcfe8e';
const practiceMatchSubmissionDueTimestampTypeId = '78372cc4-e807-4727-a477-081cd4405f38';
const officialRefereeReviewSession = officialRefereeReviewSessionType
const practiceRefereeReviewSession = practiceRefereeReviewSessionType

export const sessionTypesTranslations = (is_FLLC_event=true) => ({
  [openCeremonyTypeId] : 'openingCeremonyLabel',
  [closeCeremonyTypeId] : 'closingCeremonyLabel',
  [judgingMaterialDueTimestampTypeId] : is_FLLC_event ? 'judgingMaterialSubmissionDueLabel' : 'nonChallengeJudgingMaterialSubmissionDueLabel',
  [officialMatchSubmissionDueTimestampTypeId] : 'officialMatchSubmissionDue',
  [practiceMatchSubmissionDueTimestampTypeId] : 'practiceMatchDueDateLabel',
  [officialJudgingSessionTypeId]: is_FLLC_event ?'judgingSessionLabel' : 'nonChallengeJudgingSessionLabel',
  [officialRefereeReviewSession]: 'officialReviewLabel',
  [practiceRefereeReviewSession]: 'practiceReviewLabel',
})