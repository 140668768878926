import op1 from './fields/op1';
import op2 from './fields/op2';
import op3 from './fields/op3';
import op4 from './fields/op4';
import op5 from './fields/op5';

export default [
    op1,
    op2,
    op3,
    op4,
    op5
]