export const openStatus = {
  statusName: 'openLabel',
  statusId: '0acd2c0f-e34e-4640-8f7d-618e84d273ea',
  badgeType: 'solid-green'
}

export const reviewInProgressStatus = {
  statusName: 'reviewInProgressLabel',
  statusId: '0ccd2c0f-e34e-4640-8f7d-618e84d273ea',
  badgeType: 'hollow-green'
}

export const scoredStatus = {
  statusName: 'scoredLabel',
  statusId: '0bcd2c0f-e34e-4640-8f7d-618e84d273ea',
  badgeType: 'solid-blue'
}

export const readyForReviewStatus = {
  statusName: 'Ready for review',
  statusId: '8b2c90c3-3f5b-4110-b9c3-4817b9eb1531',
  badgeType: 'solid-blue'
}

export const NotSetStatus = {
  statusName: 'notSetLabel',
  statusId: '',
  badgeType: 'solid-red'
}


const matchStatuses = [
  openStatus,
  reviewInProgressStatus,
  scoredStatus,
  readyForReviewStatus
]

export default matchStatuses;