import { Row, Col, Form, Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import find from 'lodash/find'

import TextBox from 'shared/components/TextBox';
import {
  createFeedbackComment,
  editJudgingComment,
} from 'features/EventOverviewContainer/redux/actions/judgingRubric';

const FormItem = Form.Item;

const rowProps = {
  type: 'flex',
  gutter: 20,
  className: 'm-t-20'
};

const colProps = {
  xs: 24, md: 16
};

const paragraphClassName = 'paragraph--xlarge paragraph--charcoal paragraph--normal'

const JudgeComment = (props) => {
  const {
    firstName,
    lastName,
    commentSection,
    commentType,
    formTitle,
    categoryLabel,
    helperText,
    createComment,
    isCreatingComment,
    judgingRubricComments,
    commentId,
    id,
    callback
  } = props;
  const [form] = Form.useForm();

  const existingComment = find(judgingRubricComments, c => c.commentId === commentId)
  const initialValue = existingComment ? existingComment.comment : ''

  const submitCallBack = () => {
    form.resetFields()
    callback && callback()
  }

  const { sessionId } = useParams();

  const handleSubmit = async(e) => {
    if (e && 'preventDefault' in e) e.preventDefault()
    form.validateFields().then(async (values, err) => {
      if (err) {
        throw err;
      }

      const {comment} = values;
      const fullName = `${firstName} ${lastName}`;

      const payload = {
        fullName,
        comment,
        commentSection,
        commentType,
        sessionId
      }
      createComment(payload, submitCallBack)
    })
  };

  const handleEdit = async(e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields().then(async (values, err) => {
      const { comment } = values;
      // const fullName = commentId ? existingComment.fullName : `${firstName} ${lastName}`
      const fullName = `${firstName} ${lastName}`
      const payload = { comment, referee_id: id }
      props.editJudgingComment(payload, fullName, commentId, submitCallBack)
    });
  };

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  return (
    <div>
      <h2 className='h-2 heading--charcoal m-b-8'>{categoryLabel}</h2>
      <h3 className='h-3 heading--charcoal m-b-8'>{formTitle}</h3>
      <p dangerouslySetInnerHTML={{ __html: helperText }}></p>
      <Form hideRequiredMark onFinish={commentId ? handleEdit : handleSubmit} onFinishFailed={onFinishFailed} name='CreateNewRefereeNote' form={form} >
        <Row {...rowProps}>
          <Col {...colProps}>
            <p className={paragraphClassName}>
              {commentId ? existingComment.fullName : firstName + ' ' + lastName}
            </p>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem initialValue={initialValue} name='comment' label={<span>Comment</span>}>
                <TextBox 
                  size='large' 
                  placeholder='Comment'
                  rows={4}
                />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps} justify="end">
          <Button
            loading={isCreatingComment}
            htmlType='submit'
            style={{ marginRight:25, marginBottom:10 }}
          >
            { commentId
              ? 'Edit'
              : 'Add' }
          </Button>
        </Row>
      </Form>
    </div>
  );
};

JudgeComment.propTypes = {
  form: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  createJudgingComment: PropTypes.func,
  commentSection: PropTypes.string.isRequired,
  commentType: PropTypes.string.isRequired,
  formTitle: PropTypes.string.isRequired,
  createComment: PropTypes.func.isRequired,
  isCreatingComment: PropTypes.bool.isRequired,
  judgingRubricComments: PropTypes.array,
  commentId: PropTypes.string,
};

JudgeComment.defaultProps = {
  createJudgingComment: () => {},
  editJudgingComment: () => {},
}
const mapStateToProps = (state) => {
  const { 
    judgingRubric: {
      isCreatingJudgingComment,
      judgingRubricComments,
    },
    auth: {
    userInfo: {
      firstName,
      lastName,
      id
    }
  } } = state;
  return {
    firstName,
    lastName,
    id,
    isCreatingComment: isCreatingJudgingComment,
    judgingRubricComments,
  };
};

const mapDispatchToProps = {
  createComment: createFeedbackComment,
  editJudgingComment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JudgeComment);
