import {
  JUDGE_ADVISOR_ID,
  JUDGE_ID,
  COACH_ID,
  EVENT_ADMIN_ID
} from 'shared/constants/eventRoleTypes';

export default [
  {
    description: 'judgingTeamsSessionFlowChartLabel',
    default: 'https://firstinspires.blob.core.windows.net/fll/challenge/2024-25/fll-challenge-submerged-judging-session-flowchart.pdf',
    "es-MX": "",
    "tr": "",
    "nl": "",
    "he": "",
    roles: [COACH_ID, JUDGE_ID, JUDGE_ADVISOR_ID, EVENT_ADMIN_ID]
  },
  {
    description: 'SUBMERGED Judging Rubrics',
    default: 'https://firstinspires.blob.core.windows.net/fll/challenge/2024-25/fll-challenge-submerged-rubrics-color.pdf',
    "es-MX": "",
    "tr": "",
    "nl": "",
    "he": "",
    roles: [JUDGE_ID, JUDGE_ADVISOR_ID, COACH_ID, EVENT_ADMIN_ID]
  },
  {
    description: 'SUBMERGED Awards List',
    default: 'https://firstinspires.blob.core.windows.net/fll/challenge/2024-25/fll-challenge-submerged-awards.pdf',
    "es-MX": "",
    "tr": "",
    "nl": "",
    "he": "",
    roles: [JUDGE_ID, JUDGE_ADVISOR_ID, EVENT_ADMIN_ID]
  },
  {
    description: 'judgingSessionScriptLabel',
    default: "https://firstinspires.blob.core.windows.net/fll/challenge/2024-25/fll-challenge-submerged-judging-session-script-questions.pdf",
    "es-MX": "",
    "tr": "",
    "nl": "",
    "he": "",
    roles: [JUDGE_ID, JUDGE_ADVISOR_ID, EVENT_ADMIN_ID]
  },
  {
    description: 'awardAllocationLabel',
    default: "https://firstinspires.blob.core.windows.net/fll/challenge/2024-25/fll-challenge-submerged-awards.pdf",
    "es-MX": "",
    "tr": "",
    "nl": "",
    "he": "",
    roles: [JUDGE_ID, JUDGE_ADVISOR_ID, EVENT_ADMIN_ID]
  },
  {
    description: 'coachMentoNominationSheetLabel',
    default: 'https://firstinspires.blob.core.windows.net/fll/challenge/2024-25/fll-challenge-submerged-coach-mentor-nomination-print.pdf',
    "es-MX": "",
    "tr": "",
    "nl": "",
    "he": "",
    roles: [COACH_ID, EVENT_ADMIN_ID]
  },
  {
    description: 'SUBMERGED Official Judging Spreadsheet',
    default: 'https://firstinspires.blob.core.windows.net/fll/challenge/2024-25/fll-challenge-submerged-official-judging-spreadsheet.xlsm',
    "es-MX": "",
    "tr": "",
    "nl": "",
    "he": "",
    roles: [JUDGE_ADVISOR_ID, EVENT_ADMIN_ID]
  },
]
