export default class EventSchedule {
  constructor(responseData, responseIncluded) {
    this.scheduleId = responseData.id;
    this.startTime = responseData.attributes.start_time;
    this.endTime = responseData.attributes.end_time;
    this.startTime = responseData.attributes.start_time;
    this.closingSessionId = responseData.relationships.closing_session.data.id;
    this.openingSessionId = responseData.relationships.opening_session.data.id;
    this.included = responseIncluded;
    this.judgingSessionsData = responseData.relationships.judging_sessions.data;
    this.scoringSessionsData = responseData.relationships.scoring_sessions ? responseData.relationships.scoring_sessions.data : [];
    this.meetingSessionsData = responseData.relationships.meetings ? responseData.relationships.meetings.data : [];
    this.leaderboardId = ((((responseData||{}).relationships||{}).leaderboard||{}).data||{}).id;
    this.scheduleSettings = {
      default_judging_session_buffer: ((responseData||{}).attributes||{}).default_judging_session_buffer,
      default_judging_session_duration: ((responseData||{}).attributes||{}).default_judging_session_duration,
      default_scoring_session_buffer: ((responseData||{}).attributes||{}).default_scoring_session_buffer,
      default_scoring_session_duration: ((responseData||{}).attributes||{}).default_scoring_session_duration,
      default_session_end_time: ((responseData||{}).attributes||{}).default_session_end_time,
      default_session_start_time: ((responseData||{}).attributes||{}).default_session_start_time,
      default_judging_session_start_time: ((responseData||{}).attributes||{}).default_judging_session_start_time,
      default_official_scoring_session_start_time: ((responseData||{}).attributes||{}).default_official_scoring_session_start_time,
      default_practice_scoring_session_start_time: ((responseData||{}).attributes||{}).default_practice_scoring_session_start_time,
      include_judging_sessions_when_scheduling: ((responseData||{}).attributes||{}).include_judging_sessions_when_scheduling, 
      include_official_referee_sessions_when_scheduling: ((responseData||{}).attributes||{}).include_official_referee_sessions_when_scheduling, 
      include_practice_referee_sessions_when_scheduling: ((responseData||{}).attributes||{}).include_practice_referee_sessions_when_scheduling, 
      default_judging_session_team_travel_time_when_scheduling: ((responseData||{}).attributes||{}).default_judging_session_team_travel_time_when_scheduling, 
      default_referee_session_team_travel_time_when_scheduling: ((responseData||{}).attributes||{}).default_referee_session_team_travel_time_when_scheduling, 

      days: ((responseData||{}).relationships||{}).days,
      breaks: ((responseData||{}).relationships||{}).breaks,
      time_zone: ((responseData||{}).attributes||{}).time_zone,
    }
  }

  getSessionData = (sessionId) => {
    const selectedSession = this.included.find(i => i.id === sessionId);
    return selectedSession;
  }

  formatSessionData = (sessionId) => {
    const selectedSession = this.getSessionData(sessionId);
    if (!selectedSession) return {};
    const sessionObj = {
      startTime: selectedSession.attributes.start_time,
      endTime: selectedSession.attributes.end_time,
      notes: selectedSession.attributes.notes,
      sessionUrl: selectedSession.attributes.session_url,
      id: sessionId
    }
    return sessionObj;
  }
}