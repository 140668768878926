import {notification} from 'antd';
import * as userService from 'utils/services/users';
import * as eventRoleService from 'utils/services/eventRoles';
import * as types from '../types/volunteersTypes';

const inviteVolunteerBegin = () => ({
  type: types.VOLUNTEER_INVITE_BEGIN,
  payload: {
    isInvitingVolunteer: true
  }
})
const inviteVolunteerError = () => ({
  type: types.VOLUNTEER_INVITE_ERROR,
  payload: {
    isInvitingVolunteer: false
  }
})
const inviteVolunteerSuccess = () => ({
  type: types.VOLUNTEER_INVITE_SUCCESS,
  payload: {
    isInvitingVolunteer: false
  }
})

// action: Invite one Volunteer
const inviteVolunteer = (userId, eventId, roles = [], cb = () => {}) => async dispatch => {
  const assignmentIds = [];
  for (let i = 0; i < roles.length; i++) {
    const role = roles[i];
    try {
      const data = await eventRoleService.createUserEventRole({ userId, eventId, roleTypeId: role });
      assignmentIds.push(data.id);
      cb();
    } catch (err) {
      if (err && 'data' in err && err.data.statusCode === 451) {
        notification.error({
          message: 'Error',
          description: 'Volunteer has not been screened and cannot serve as coach or volunteer.',
        });
      }
      else if (err && 'data' in err && err.data.statusCode === 409) {
        notification.error({
          message: 'Error',
          description: 'The user has already been assigned that role of this event.',
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Error sending invitation',
        });
      }
      throw err;
    }
  }
  if (assignmentIds.length) {
    await eventRoleService.sendInvitations(assignmentIds);
    notification.success({
      message: 'Success',
      description: 'Invitation successfully sent.',
    });
  }
}

export const handleInviteAllVolunteers = (volunteers, eventId, cb = () => {}) => async dispatch => {
  await dispatch(inviteVolunteerBegin())
  try {
    for (let i = 0; i < volunteers.length; i++) {
      const volunteer = volunteers[i];
      const userId = await userService.getOrCreateUserId(volunteer);
      if (userId) {
        await dispatch(inviteVolunteer(userId, eventId, volunteer.eventRoles));
      }
    }
    await dispatch(inviteVolunteerSuccess());
    cb();
  } catch (err) {
    await dispatch(inviteVolunteerError());
    throw err;
  }
};

export const handleInviteVolunteerNow = (volunteer, eventId, cb = () => {}) => async dispatch => {
  await dispatch(inviteVolunteerBegin())
  try {
    const userId = await userService.getOrCreateUserId(volunteer);
    if (userId) {
      await dispatch(inviteVolunteer(userId, eventId, volunteer.eventRoles, cb));
      await dispatch(inviteVolunteerSuccess());
    }
  } catch(err) {
    await dispatch(inviteVolunteerError());
    throw err;
  }
};

export const addVolunteerToListBegin = () => {
  return {
    type: types.VOLUNTEER_ADD_TO_LIST_BEGIN
  }
} 
export const addVolunteerToListError = () => {
 return {
  type: types.VOLUNTEER_ADD_TO_LIST_ERROR,
  payload: {
    isAddingVolunteerToManualList: false
  }
 }
}
export const addVolunteerToListSuccess = (volunteer) => {
  return {
    type: types.VOLUNTEER_ADD_TO_LIST_SUCCESS,
    payload: {
      volunteer,
      isAddingVolunteerToManualList: false
    }
  }
}

export const handleAddVolunteerToList = (volunteer, cb = () => {}) => {
  
  return async dispatch => {
    try {
      await dispatch(addVolunteerToListBegin());
      await dispatch(addVolunteerToListSuccess(volunteer))
      cb();
    } catch(err) {
      await dispatch(addVolunteerToListError());
      throw err;
    }
  }
}


// REMOVE VOLUNTEER FROM MANUAL LIST
const deleteManualVolunteerBegin = () => {
  return {
    type: types.DELETE_MANUAL_VOLUNTEER_BEGIN
  }
}
const deleteManualVolunteerError = () => {
  return {
    type: types.DELETE_MANUAL_VOLUNTEER_ERROR
  }
}
const deleteManualVolunteerSuccess = (volunteers) => {
  return {
    type: types.DELETE_MANUAL_VOLUNTEER_SUCCESS,
    payload: {
      volunteers
    }
  }
}

export const handleManualVolunteerDelete = (id) => {
  return async (dispatch, getState) => {
    const {event: {eventVolunteers}} = getState();
    const {addVolunteersManuallyList} = eventVolunteers;

    const copyManualList = [...addVolunteersManuallyList];
    try {
      await dispatch(deleteManualVolunteerBegin())
      const updatedList = copyManualList.filter(volunteer => volunteer.id !== id);
      await dispatch(deleteManualVolunteerSuccess(updatedList))
    } catch(err) {
      await dispatch(deleteManualVolunteerError())
      throw err;
    }
  }
}

// REMOVE VOLUNTEER FROM CSV IMPORT LIST

const deleteImportVolunteerBegin = () => {
  return {
    type: types.DELETE_IMPORT_VOLUNTEER_BEGIN
  }
}
const deleteImportVolunteerError = (volunteers) => {
  return {
    type: types.DELETE_IMPORT_VOLUNTEER_ERROR,
    payload: {
      volunteers
    }
  }
}
const deleteImportVolunteerSuccess = (volunteers) => {
  return {
    type: types.DELETE_IMPORT_VOLUNTEER_SUCCESS,
    payload: {
      volunteers
    }
  }
}

export const handleImportVolunteerDelete = (id, cb = () => {}, onDelete) => {
  return async (dispatch, getState) => {
    const {event: {eventVolunteers: {addVolunteersImportList}}} = getState();

    // const copyOriginalList = Array.isArray(addVolunteersImportList) ? [...addVolunteersImportList] : [];
    const copyOriginalList = [...addVolunteersImportList];
    try {
      const updatedList = copyOriginalList.filter(volunteer => volunteer.id !== id);
      if(onDelete && typeof(onDelete) == 'function'){
        let errorCount = 0;
        updatedList.forEach(i => {
          errorCount += i?.errors?.length ?? 0;
        })
        onDelete(errorCount);
      }
      if (updatedList.length < 1) {
        // console.log('no volunteers')
        cb()
      }
      await dispatch(deleteImportVolunteerBegin());
      await dispatch(deleteImportVolunteerSuccess(updatedList));
    } catch(err) {
      await dispatch(deleteImportVolunteerError(copyOriginalList));
      throw err;
    }
  }
}

// ADD VOLUNTEERS FROM CSV IMPORT LIST

const importVolunteersBegin = () => {
  return {
    type: types.IMPORT_VOLUNTEERS_BEGIN
  }
}

const importVolunteersError = () => {
  return {
    type: types.IMPORT_VOLUNTEERS_ERROR
  }
}

const importVolunteersSuccess = (volunteers) => {
  return {
    type: types.IMPORT_VOLUNTEERS_SUCCESS,
    payload: {
      addVolunteersImportList: volunteers
    }
  }
}

export const handleImportVolunteers = (volunteers) => {
  return async dispatch => {
    try {
      await dispatch(importVolunteersBegin())
      await dispatch(importVolunteersSuccess(volunteers))
    } catch (err) {
      await dispatch(importVolunteersError())
      throw err
    }
  }
}

const setSelectedAutocompleteVolunteer = (selectedVolunteer) => {
  return {
    type: types.SET_SELECTED_AUTOCOMPLETE_VOLUNTEER,
    payload: {
      selectedVolunteer
    }
  }

}

export const handleSetSelectedAutocompleteVolunteer = (volunteer) => {
  return async dispatch => {
    try {
      await dispatch(setSelectedAutocompleteVolunteer(volunteer))

    } catch(err) {
      throw err;
    }
  }
}

const resetSelectedAutocompleteVolunteer = () => {
  return {
    type: types.RESET_SELECTED_AUTOCOMPLETE_VOLUNTEER
  
  }
}

export const handleResetSelectedAutocompleteVolunteer = () => {
  return async dispatch => {
    try {
      await dispatch(resetSelectedAutocompleteVolunteer())

    } catch(err) {
      throw err;
    }
  }
}

const updateSelectedVolunteer = (fieldName, value) => {
  return {
    type: types.UPDATE_SELECTED_VOLUNTEER,
    payload: {
      fieldName, value
    }
  }
}

export const handleUpdateSelectedVolunteer = (fieldName, value) => {
  return async dispatch => {
    await dispatch(updateSelectedVolunteer(fieldName, value))
  }
}

const resetManualVolunteersList = () => {
  return {
    type: types.RESET_MANUAL_VOLUNTEERS_LIST
  }
}

export const handleResetManualVolunteers = () => {
  return async dispatch => {
    dispatch(resetManualVolunteersList())
  }
}

const setInvitedImportVolunteers = (successfullyInvited, notInvited) => {
  return {
    type: types.SET_EVENT_IMPORT_VOLUNTEERS_INVITED,
    payload: {
      successfullyInvited, notInvited
    }
  }
}

export const handleInviteImportList = (volunteers, eventId, cb = () => {}) => async dispatch => {
  const volunteersSuccessfullyInvited = [];
  const inviteErrors = [];
  const volunteersNotInvited = [];
  try {
    await dispatch(inviteVolunteerBegin())
    for (let i = 0; i < volunteers.length; i++) {
      const volunteer = volunteers[i];
      const inviteImportedVolunteer = async (userId, roles = []) => {
        const assignmentIds = [];
        for (let j = 0; j < roles.length; j++) {
          const role = roles[j];
          try {
            const data = await eventRoleService.createUserEventRole({ userId, eventId, roleTypeId: role });
            assignmentIds.push(data.id);
            volunteersSuccessfullyInvited.push(volunteer)
          } catch (err) {
            volunteersNotInvited.push(volunteer)
            if (err && 'data' in err && err.data.statusCode === 451) {
              notification.error({
                message: 'Error',
                description: 'Volunteer has not been screened and cannot serve as coach or volunteer.',
              });
            }
            else if (err && 'data' in err && err.data.statusCode === 409) {
              notification.error({
                message: 'Error',
                description: 'The user has already been assigned that role of this event.',
              });
            } else {
              notification.error({
                message: 'Error',
                description: 'Error sending invitation',
              });
            }
            // throw err;
          }
        }
        if (assignmentIds.length) {
          await eventRoleService.sendInvitations(assignmentIds);
          notification.success({
            message: 'Success',
            description: 'Invitation successfully sent.',
          });
        }
      }

      
      const userId = await userService.getOrCreateUserId(volunteer);
      if (userId) {
        await inviteImportedVolunteer(userId, volunteer.eventRoles);
      } else {
        volunteersNotInvited.push(volunteer)
      }

    }
    
    console.log({volunteersSuccessfullyInvited, volunteersNotInvited})

    await dispatch(setInvitedImportVolunteers(volunteersSuccessfullyInvited, volunteersNotInvited))
    await dispatch(inviteVolunteerSuccess());
    cb();
  } catch (err) {
    await dispatch(inviteVolunteerError());
  }
}


const resetAddVolunteerModal = () => {
  return {
    type: types.RESET_ADD_VOLUNTEER_MODAL
  }
}

export const handleResetVolunteerModal = () => {
  return async dispatch => {
    await dispatch(resetAddVolunteerModal())
  }
}
