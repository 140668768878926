import * as types from '../types/assignedMatches';

const DEFAULT_STATE = {
  assignedMatches: [],
  assignedPanels: [],
  isFetchingAssignedMatches: false,
  count: 0,
  currentPage: 1,
  teamCount: 0,
}

export default (state = DEFAULT_STATE, action) => {
  const {type} = action;
  switch (type) {
    case types.GET_ASSIGNED_MATCHES_BEGIN:
      return {
        ...state,
        isFetchingAssignedMatches: true
      }
    case types.GET_ASSIGNED_MATCHES_ERROR:
      return {
        ...state,
        isFetchingAssignedMatches: false,
        assignedMatches: [],
        assignedPanels: []
      }
    case types.GET_ASSIGNED_MATCHES_SUCCESS:
      return {
        ...state,
        isFetchingAssignedMatches: action.payload.loading,
        assignedMatches: action.payload.assignedMatches,
        assignedPanels: action.payload.assignedPanels,
        count: action.payload.count
      }
    case types.SET_ASSIGNED_MATCHES_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload.currentPage
      }
    case types.SET_ASSIGNED_TEAMS_COUNT:
      return {
        ...state,
        teamCount: action.payload
      }
    case types.UPDATE_ASSIGNED_MATCHES_ON_SUBMIT:
      return {
        ...state,
        assignedMatches: action.payload
      }
    default:
      return state;
  }
}