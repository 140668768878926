export const GET_EVENT_SCHEDULE_DETAILS_BEGIN = 'GET_EVENT_SCHEDULE_DETAILS_BEGIN';
export const GET_EVENT_SCHEDULE_DETAILS_SUCCESS = 'GET_EVENT_SCHEDULE_DETAILS_SUCCESS';
export const GET_EVENT_SCHEDULE_DETAILS_ERROR = 'GET_EVENT_SCHEDULE_DETAILS_ERROR';
export const GET_EVENT_SCHEDULE_TAB_DETAILS_BEGIN = 'GET_EVENT_SCHEDULE_TAB_DETAILS_BEGIN';
export const GET_EVENT_SCHEDULE_TAB_DETAILS_SUCCESS = 'GET_EVENT_SCHEDULE_TAB_DETAILS_SUCCESS';
export const GET_EVENT_SCHEDULE_TAB_DETAILS_ERROR = 'GET_EVENT_SCHEDULE_TAB_DETAILS_ERROR';
export const GET_EVENT_SCHEDULE_DETAILS_COMPLETE = 'GET_EVENT_SCHEDULE_DETAILS_COMPLETE';

export const SET_SCHEDULE_SCORING_TIMESTAMPS = 'SET_SCHEDULE_SCORING_TIMESTAMPS';
export const SET_SCHEDULE_JUDGING_TIMESTAMPS = 'SET_SCHEDULE_JUDGING_TIMESTAMPS';


export const GET_SCHEDULE_JUDGING_TIMESTAMPS_BEGIN = 'GET_SCHEDULE_JUDGING_TIMESTAMPS_BEGIN';
export const GET_SCHEDULE_JUDGING_TIMESTAMPS_ERROR = 'GET_SCHEDULE_JUDGING_TIMESTAMPS_ERROR';
export const GET_SCHEDULE_JUDGING_TIMESTAMPS_SUCCESS = 'SET_SCHEDULE_JUDGING_TIMESTAMPS_SUCCESS';

export const GET_SCHEDULE_SCORING_TIMESTAMPS_BEGIN = 'GET_SCHEDULE_SCORING_TIMESTAMPS_BEGIN';
export const GET_SCHEDULE_SCORING_TIMESTAMPS_ERROR = 'GET_SCHEDULE_SCORING_TIMESTAMPS_ERROR';
export const GET_SCHEDULE_SCORING_TIMESTAMPS_SUCCESS = 'SET_SCHEDULE_SCORING_TIMESTAMPS_SUCCESS';


export const GET_SCHEDULE_OPENING_SESSION_BEGIN = 'GET_SCHEDULE_OPENING_SESSION_BEGIN';
export const GET_SCHEDULE_OPENING_SESSION_ERROR = 'GET_SCHEDULE_OPENING_SESSION_ERROR';
export const GET_SCHEDULE_OPENING_SESSION_SUCCESS = 'GET_SCHEDULE_OPENING_SESSION_SUCCESS';

export const GET_SCHEDULE_CLOSING_SESSION_BEGIN = 'GET_SCHEDULE_CLOSING_SESSION_BEGIN';
export const GET_SCHEDULE_CLOSING_SESSION_ERROR = 'GET_SCHEDULE_CLOSING_SESSION_ERROR';
export const GET_SCHEDULE_CLOSING_SESSION_SUCCESS = 'GET_SCHEDULE_CLOSING_SESSION_SUCCESS';

export const GET_SCHEDULE_JUDGING_SESSIONS_BEGIN = 'GET_SCHEDULE_JUDGING_SESSIONS_BEGIN';
export const GET_SCHEDULE_JUDGING_SESSIONS_ERROR = 'GET_SCHEDULE_JUDGING_SESSIONS_ERROR';
export const GET_SCHEDULE_JUDGING_SESSIONS_SUCCESS = 'GET_SCHEDULE_JUDGING_SESSIONS_SUCCESS';

export const GET_SCHEDULE_SCORING_SESSIONS_BEGIN = 'GET_SCHEDULE_SCORING_SESSIONS_BEGIN';
export const GET_SCHEDULE_SCORING_SESSIONS_ERROR = 'GET_SCHEDULE_SCORING_SESSIONS_ERROR';
export const GET_SCHEDULE_SCORING_SESSIONS_SUCCESS = 'GET_SCHEDULE_SCORING_SESSIONS_SUCCESS';

export const SET_SCHEDULE_MEETING_SECTION_LOADING = 'SET_SCHEDULE_MEETING_SECTION_LOADING';
export const GET_SCHEDULE_MEETING_SESSIONS_BEGIN = 'GET_SCHEDULE_MEETING_SESSIONS_BEGIN';
export const GET_SCHEDULE_MEETING_SESSIONS_ERROR = 'GET_SCHEDULE_MEETING_SESSIONS_ERROR';
export const GET_SCHEDULE_MEETING_SESSIONS_SUCCESS = 'GET_SCHEDULE_MEETING_SESSIONS_SUCCESS';

export const GET_SCHEDULE_MEETING_BEGIN = 'GET_SCHEDULE_MEETING_BEGIN';
export const GET_SCHEDULE_MEETING_ERROR = 'GET_SCHEDULE_MEETING_ERROR';
export const GET_SCHEDULE_MEETING_SUCCESS = 'GET_SCHEDULE_MEETING_SUCCESS';

export const SAVE_SCHEDULE_MEETING_BEGIN = 'SAVE_SCHEDULE_MEETING_BEGIN'
export const SAVE_SCHEDULE_MEETING_ERROR = 'SAVE_SCHEDULE_MEETING_ERROR'
export const SAVE_SCHEDULE_MEETING_SUCCESS = 'SAVE_SCHEDULE_MEETING_SUCCESS'


export const CREATE_NEW_SCHEDULE_MEETING_BEGIN = 'CREATE_NEW_SCHEDULE_MEETING_BEGIN'
export const CREATE_NEW_SCHEDULE_MEETING_ERROR = 'CREATE_NEW_SCHEDULE_MEETING_ERROR'
export const CREATE_NEW_SCHEDULE_MEETING_SUCCESS = 'CREATE_NEW_SCHEDULE_MEETING_SUCCESS'

export const GET_SCHEDULE_MEETING_TEAMS_BEGIN = 'GET_SCHEDULE_MEETING_TEAMS_BEGIN';
export const GET_SCHEDULE_MEETING_TEAMS_ERROR = 'GET_SCHEDULE_MEETING_TEAMS_ERROR';
export const GET_SCHEDULE_MEETING_TEAMS_SUCCESS = 'GET_SCHEDULE_MEETING_TEAMS_SUCCESS';

export const EDIT_EVENT_GENERAL_INFO_BEGIN = 'EDIT_EVENT_GENERAL_INFO_BEGIN';
export const EDIT_EVENT_GENERAL_INFO_ERROR = 'EDIT_EVENT_GENERAL_INFO_ERROR';
export const EDIT_EVENT_GENERAL_INFO_SUCCESS = 'EDIT_EVENT_GENERAL_INFO_SUCCESS';

export const SET_REFETCH_EVENT_SCHEDULE = 'SET_REFETCH_EVENT_SCHEDULE';


export const SET_SCHEDULE_MEETING_SESSIONS = 'SET_SCHEDULE_MEETING_SESSIONS';
export const SET_SCHEDULE_JUDGING_SESSIONS = 'SET_SCHEDULE_JUDGING_SESSIONS';
export const SET_SCHEDULE_SCORING_SESSIONS = 'SET_SCHEDULE_SCORING_SESSIONS';


export const CREATE_NEW_SESSION_LINK_BEGIN = 'CREATE_NEW_SESSION_LINK_BEGIN';
export const CREATE_NEW_SESSION_LINK_ERROR = 'CREATE_NEW_SESSION_LINK_ERROR';
export const CREATE_NEW_SESSION_LINK_SUCCESS = 'CREATE_NEW_SESSION_LINK_SUCCESS';

export const REGENERATE_MICROSOFT_SESSION_LINK_BEGIN = 'REGENERATE_MICROSOFT_SESSION_LINK_BEGIN';
export const REGENERATE_MICROSOFT_SESSION_LINK_ERROR = 'REGENERATE_MICROSOFT_SESSION_LINK_ERROR';
export const REGENERATE_MICROSOFT_SESSION_LINK_SUCCESS = 'REGENERATE_MICROSOFT_SESSION_LINK_SUCCESS';

export const CREATE_NEW_MEETING_LINK_BEGIN = 'CREATE_NEW_MEETING_LINK_BEGIN';
export const CREATE_NEW_MEETING_LINK_ERROR = 'CREATE_NEW_MEETING_LINK_ERROR';
export const CREATE_NEW_MEETING_LINK_SUCCESS = 'CREATE_NEW_MEETING_LINK_SUCCESS';

export const REGENERATE_MICROSOFT_MEETING_LINK_BEGIN = 'REGENERATE_MICROSOFT_MEETING_LINK_BEGIN';
export const REGENERATE_MICROSOFT_MEETING_LINK_ERROR = 'REGENERATE_MICROSOFT_MEETING_LINK_ERROR';
export const REGENERATE_MICROSOFT_MEETING_LINK_SUCCESS = 'REGENERATE_MICROSOFT_MEETING_LINK_SUCCESS';

export const UPDATE_JUDGING_AND_SCORING_SESSIONS = 'UPDATE_JUDGING_AND_SCORING_SESSIONS';
export const SET_SCHEDULE_SETTINGS_DETAIL = 'SET_SCHEDULE_SETTINGS_DETAIL';

export const GENERATE_SCHEDULE_SESSIONS_BEGIN = 'GENERATE_SCHEDULE_SESSIONS_BEGIN';
export const GENERATE_SCHEDULE_SESSIONS_SUCCESS = 'GENERATE_SCHEDULE_SESSIONS_SUCCESS';
export const GENERATE_SCHEDULE_SESSIONS_ERROR = 'GENERATE_SCHEDULE_SESSIONS_ERROR';
export const DELETE_BREAK_BY_ID_BEGIN = 'DELETE_BREAK_BY_ID_BEGIN';
export const DELETE_BREAK_BY_ID_SUCCESS = 'DELETE_BREAK_BY_ID_SUCCESS';
export const SAVING_SCHEDULES_SETTINGS_BEGIN = 'SAVING_SCHEDULES_SETTINGS_BEGIN';
export const SAVING_SCHEDULES_SETTINGS_END = 'SAVING_SCHEDULES_SETTINGS_END';
export const RESET_GENERATE_SCHEDULE_COMPLETE = 'RESET_GENERATE_SCHEDULE_COMPLETE';