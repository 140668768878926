import React from 'react';
import PropTypes from 'prop-types';
import PartnerAdminDashboard from 'features/AdminDashboard';
import VolunteerDashboard from 'features/VolunteerDashboard';

const DashboardView = props => {
  const {role} = props;

  switch(role) {
    case 'AdminDashboard':
      return <PartnerAdminDashboard />
    case 'VolunteerDashboard':
      return <VolunteerDashboard />
    default:
      return <PartnerAdminDashboard />
  }

};

DashboardView.propTypes = {
  role: PropTypes.string.isRequired
};

export default DashboardView;