import React from 'react';
import PropTypes from 'prop-types';

import '../../../../../shared/components/Scoresheet/styles/MatchScoresheetForm.scss';
import {connect} from 'react-redux';
import InputNumber from 'shared/components/InputNumber'
import RadioBtn from 'shared/components/RadioButton';
import '../../../../../shared/components/Scoresheet/styles/MatchScoresheet.scss';
import '../../../../../shared/components/Scoresheet/styles/ModuleSection.scss';
import { useTranslation } from 'react-i18next';
const moduleNameClassName = `h-2 heading--white`;
const moduleTitleClassName = `h-2 heading--charcoal`;
const paragraphClassName = 'paragraph--large paragraph--charcoal paragraph--normal'


const ModuleSection = props => {

  const {moduleName, moduleTitle, fields, scoreInput, scoreInputData} = props;
  const {t} = useTranslation();
  const scoreData = scoreInputData?.id ? scoreInputData : scoreInput;
 
  return (
    <div className="scoresheet-module">
      <div className="scoresheet-module__name">
        <h3 className={moduleNameClassName}>{moduleName}</h3>
      </div>

      <div className="m-l-30 m-t-8 scoresheet-module__content">
        <h2 className={moduleTitleClassName} dangerouslySetInnerHTML={{__html: t(moduleTitle)}} />
        <div className="scoresheet-module__fields-wrapper">
          {fields.map((f) => (
            <div key={f.id} className="scoresheet-module__field">
              {Array.isArray(f.scoring)?
              <div>
                <div className="scoresheet-module__field-description">{t(f.description)}</div>
                <ul className="scoresheet-module__field-list">
                {f.scoring.map((s)=>(
                  <li dangerouslySetInnerHTML={{__html: t(s)}} />
                ))}
                </ul>
              </div>
              :<span className={`${paragraphClassName} scoresheet-module__field-label`} dangerouslySetInnerHTML={{__html: t(f.scoring)}} />}
              {f.type !== 'integer' ? (
                <span>
                  <RadioBtn value={scoreData[f.id]} options={f.options} disabled />
                </span>
              ) : (
                <span>
                  <InputNumber value={scoreData[f.id]} disabled size='large' min={f.min} max={f.max} />
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

ModuleSection.propTypes = {
  moduleName: PropTypes.string,
  moduleTitle: PropTypes.string,
  fields: PropTypes.array,
  form: PropTypes.object.isRequired,
  scoreInput: PropTypes.object.isRequired
};

ModuleSection.defaultProps = {
  moduleName: '',
  moduleTitle: '',
  fields: []
};

const mapStateToProps = (state) => {
  const {coachScoring: {
    selectedScoreInput,  
  }} = state;
  return {
    scoreInput: selectedScoreInput,
  }
}
export default connect(mapStateToProps)(ModuleSection);