/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../../../styles/ScheduleContainer/SessionModal.scss';


const SessionDetails = props => {
  const {teamName, teamNumber, managePanelsText, managePanelsLink} = props;
  const { t } = useTranslation()
 
  let title;

  if (teamName && teamNumber) {
    title = `${teamNumber} - ${teamName}`
  } else {
    title = `${teamName}`
  }
  

  return (
    <div className='schedule-session-modal__details'>
      <h4 className='h-4 schedule-session-modal__details-team'>{title}</h4>
    </div>
  );
};

SessionDetails.propTypes = {
  teamName: PropTypes.string,
  teamNumber: PropTypes.string,
  managePanelsText: PropTypes.string,
  managePanelsLink: PropTypes.string

};

SessionDetails.defaultProps = {
  managePanelsText: 'Manage Panels',
  teamName: '',
  teamNumber: '',
  managePanelsLink: ''

}

export default SessionDetails;