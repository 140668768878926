import {
  FLL_DISCOVER_ID,
  FLL_CHALLENGE_ID,
  FLL_EXPLORE_ID,
  FTC_ID
} from './programIds';
import LegoChallenge from 'shared/components/Icon/logos/LegoChallenge';
import LegoDiscover from 'shared/components/Icon/logos/LegoDiscover';
import LegoExplore from 'shared/components/Icon/logos/LegoExplore';
import TechChallenge from 'shared/components/Icon/logos/TechChallenge';
import Replay from 'shared/components/Icon/logos/Replay';
import PlayMarkers from 'shared/components/Icon/logos/PlayMarkers';
import UltimateGoal from 'shared/components/Icon/logos/UltimateGoal';
import PresentedByQualcomm from 'shared/components/Icon/logos/PresentedByQualcomm';

import WhiteReplay from 'shared/components/Icon/logos/WhiteReplay';
import WhitePlayMarkers from 'shared/components/Icon/logos/WhitePlayMarkers';
import WhiteUltimateGoal from 'shared/components/Icon/logos/WhiteUltimateGoal';

export default {
  [FLL_CHALLENGE_ID]: {
    ProgramLogo: LegoChallenge, PresentedBy: null, PlayMarker: Replay, WhitePlayMarker: WhiteReplay, color: 'red'
  },
  [FLL_DISCOVER_ID]: {
    ProgramLogo: LegoDiscover, PresentedBy: null, PlayMarker: PlayMarkers, WhitePlayMarker: WhitePlayMarkers, color: 'purple'
  },
  [FLL_EXPLORE_ID]: {
    ProgramLogo: LegoExplore, PresentedBy: null, PlayMarker: PlayMarkers, WhitePlayMarker: WhitePlayMarkers, color: 'green'
  },
  [FTC_ID]: {
    ProgramLogo: TechChallenge, PresentedBy: PresentedByQualcomm, PlayMarker: UltimateGoal, WhitePlayMarker: WhiteUltimateGoal, color: 'orange'
  },
};
