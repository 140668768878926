import React from 'react';
import Paragraph from 'shared/components/Paragraph';
import { useTranslation } from 'react-i18next';

const Instructions = () => {
  const { t } = useTranslation()
  return (
    <div className='c-event-sponsors__instructions'>
      <Paragraph size='medium' weight='bold'>
        {t('instructionsLabel')}
      </Paragraph>
      <Paragraph size='medium'>
        {t('sponsorIntructionLabel')}.
      </Paragraph>
    </div>
  );
};

export default Instructions;
