import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'shared/components/Tooltip';
import Tabs from 'shared/components/Tabs/ModalTabs';
import { connect } from 'react-redux';
import UserEventRole from 'models/UserEventRole';
import { useTranslation } from 'react-i18next';
import '../../../../../../features/PanelManagement/styles/JudgingPanelModal.scss';

import { Avatar, Row, Col, Form, Button } from 'antd';
import Panel from 'models/Panel';
import handleEditScoringPanel from 'features/EventContainer/redux/actions/scoringActions/editScoringPanel';
import fetchSelectedPanel from 'features/EventContainer/redux/actions/scoringActions/selectedPanel';
import ListLoading from 'shared/components/Spinner/ListLoading';
import Select from 'shared/components/FormDropdown';
import PanelTitle from './PanelTitle';
import { officialRefereeReviewSessionType, practiceRefereeReviewSessionType } from 'shared/constants/timestampTypes';
import { IN_PERSON_OFFICIAL, IN_PERSON_TEST } from 'shared/constants/eventTypes';
import _ from 'lodash';
import Input from 'shared/components/Input';

const FormItem = Form.Item;
const SelectOption = Select.Option;

const rowProps = {
  type: 'flex', gutter: 20,
  className: 'm-t-20'
};

const colProps = {
  xs: 24,
  md: 16
}
const PanelModalContent = props => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const {
    panelId,
    getPanel,
    eventScoring,
    closeModal,
    event_type
  } = props;

  const {
    isFetchingSelectedPanel,
    selectedPanel,
    isEditingRefereePanel,
    refereeOptions, sessionOptions, formattedPanels
  } = eventScoring;

  const isInPersonEvent = event_type === IN_PERSON_OFFICIAL || event_type === IN_PERSON_TEST;

  const infoText = "Referees and teams added to this panel will be automatically assigned to the corresponding Referee Review Session on the activity schedule."


  useEffect(() => {
    if (panelId) {
      getPanel(panelId)
    }
  }, [panelId])

  if (isFetchingSelectedPanel) {
    return (
      <ListLoading loading text='Loading Panel' />
    );
  }

  const findUniquePanelNames = (rule, value, callback) => {
    if (value === undefined || value.trim().toLowerCase() == selectedPanel.name.trim().toLowerCase()) callback()
    let match = false;
    formattedPanels.map((panel) => {
      let panelName = panel.panelName.trim().toLowerCase();
      if (panelName == value.trim().toLowerCase()) {
        match = true;
      }
    })
    if (match) {
      callback('Table name already exists')
    } else {
      callback();
    }
  }

  const validateSelectedJudgesCount = (rule, value, callback) => {

    if (!Array.isArray(value) || value.length <= 1) {
      callback('At least two referees must be assigned to the panel.')
    } else {
      callback()
    }
  }

  const handleCloseModal = () => {
    closeModal();
    form.resetFields();
  }

  const successCb = () => {
    handleCloseModal()
  }

  const handleSave = (e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields().then(async (values, err) => { 
      const formReferees = values.referees || [];
      const formSessions = values.sessions || [];

      const removedSessions = [];
      const addedSessions = [];

      const removedReferees = [];
      const addedReferees = [];

      const initialReferees = [...selectedPanel.refereesIds];
      const initialSessions = [...selectedPanel.sessionsIds];

      formSessions.forEach(selectedSession => {
        if (!initialSessions.includes(selectedSession)) {
          addedSessions.push(selectedSession)
        }
      })

      initialSessions.forEach(initialSession => {
        if (!formSessions.includes(initialSession)) {
          removedSessions.push(initialSession)
        }
      })

      formReferees.forEach(selectedJudge => {
        if (!initialReferees.includes(selectedJudge)) {
          addedReferees.push(selectedJudge)
        }
      })

      initialReferees.forEach(initialJudge => {
        if (!formReferees.includes(initialJudge)) {
          removedReferees.push(initialJudge)
        }
      })
      const payload = {
        removedReferees,
        removedSessions,
        addedReferees,
        addedSessions,
        formReferees,
        formSessions,
        panelName: values.tableName,
      }

      props.handleEditScoringPanel(panelId, payload, successCb)
    });
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  }

  const getMatchNumberText = (teamOpt) => {
    const { sessionTypeId, matchNumber, teamName } = teamOpt;
    let labelText = '';
    if (sessionTypeId === practiceRefereeReviewSessionType) {
      labelText = `${teamName} (${t('practiceReviewLabel')})`;
    } else if (sessionTypeId === officialRefereeReviewSessionType) {
      labelText = `${teamName} (${t('officialReviewLabel')})`;
    }

    if (isInPersonEvent && matchNumber && sessionTypeId !== practiceRefereeReviewSessionType) {
      labelText = labelText.replace(')', ` ${matchNumber})`)
    }
    return labelText
  }

  const generatePanelsCommaSeparated = (panels) => {
    const panelsArray = panels.map((panel) => {
      return panel.panelName
    })

    let renderPanels = panelsArray.join(", ")
    return renderPanels
  }

  const sortedSessionOptions = _.orderBy(sessionOptions, ['teamName', 'sessionTypeName', 'matchNumber'], ['asc', 'desc', 'asc'])
  const sortedPanelSessionIds = sortedSessionOptions.filter(session => selectedPanel.sessionsIds.includes(session.sessionId)).map(session => session.sessionId)
  return (
    <div>
      {/* <PanelTitle instructions={infoText} title={`${t('editLabel')} ${selectedPanel.name}`} /> */}
      <PanelTitle instructions={infoText} title={`Editing Table`} />
      <Form hideRequiredMark onFinish={handleSave} onFinishFailed={onFinishFailed} form={form} name='Edit-Scoring-Panel'initialValues={{tableName: selectedPanel.name, referees: selectedPanel.refereesIds,sessions: sortedPanelSessionIds,}}>
        <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem name='tableName' label={<span>{t('Table name')}</span>} rules={[{ required: true, message: t('Enter table name') }, { validator: findUniquePanelNames }]}>
              <Input className="judging-modal-judges-tab__add-select" size='large' placeholder={'Name'} />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem name='referees' label='Referees'>
                <Select
                  showSearch
                  filterOption={false}
                  style={{ width: "100%" }}
                  mode='multiple'
                  optionLabelProp="label"
                  optionFilterProp='label'
                  className='judging-modal-judges-tab__add-select'
                  popUpContainerElementId='add-referee'
                  placeholder='Select Referees'
                >
                  {refereeOptions.map(j => (
                    <SelectOption value={j.userId} label={`${j.fullName}`}>
                      <span aria-label={`${j.fullName}`} className='judging-modal-judges-tab__add-option'>
                        <div>
                          <p className='paragraph--medium paragraph--bold paragraph--charcoal'>{`${j.fullName}`}</p>
                          {j?.panels?.length > 0 ? (
                            <p className='paragraph--small paragraph--blue'>{generatePanelsCommaSeparated(j.panels)}</p>
                          ) : (
                            <p className='paragraph--small paragraph--red'>{t('notAssignedLabel')}</p>
                          )}
                        </div>
                      </span>
                    </SelectOption>
                  ))}
                </Select>
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem name='sessions' label={t('teamsLabel')}>
                <Select
                  showArrow={false}
                  showSearch
                  mode='multiple'
                  size="large"
                  className="judging-panel-modal__add-select"
                  placeholder={t('selectTeamsLabel')}
                  allowClear
                  optionLabelProp="label"
                  optionFilterProp='label'
                >
                  {sortedSessionOptions.map((teamOpt) => (
                    <SelectOption
                      value={teamOpt.sessionId}
                      label={getMatchNumberText(teamOpt)}
                      disabled={teamOpt.panelId && (teamOpt.panelId !== panelId)}
                    >
                      <span
                        aria-label={teamOpt.teamName}
                        className="judging-modal-judges-tab__add-option"
                      >
                        <div>
                          <p className="paragraph--medium paragraph--bold paragraph--charcoal">

                            {getMatchNumberText(teamOpt)}

                          </p>
                          {teamOpt.panelId ? (
                            <p className="paragraph--small paragraph--blue">{teamOpt.panelName}</p>
                          ) : (
                            <p className="paragraph--small paragraph--red">{t('notAssignedLabel')}</p>
                          )}
                        </div>
                      </span>
                    </SelectOption>
                  ))}
                </Select>
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <Button loading={isEditingRefereePanel} additionalClassNames='add-event-modal__btn' htmlType='submit'>{t('saveLabel')}</Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

PanelModalContent.propTypes = {
  panelId: PropTypes.string.isRequired,
  getPanel: PropTypes.func.isRequired,
  eventScoring: PropTypes.object.isRequired,
  closeModal: PropTypes.func,
  handleEditScoringPanel: PropTypes.func
};

PanelModalContent.defaultProps = {
  closeModal: () => { },
  handleEditScoringPanel: () => { }
}

const mapDispatchToProps = {
  getPanel: fetchSelectedPanel,
  handleEditScoringPanel
}

const mapStateToProps = (state) => {
  const { event: { eventScoring, eventDetails: { attributes: { event_type } } } } = state;

  return {
    event_type,
    eventScoring,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelModalContent);