import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './styles/DetailedMatchScoreCard.scss';

const DetailedMatchScoreCard = props => {
  const {
    score,
    teamNumber,
    teamName,
    teamLocation
  } = props;
  const { t } = useTranslation()

  const scoreClassName = 'h-1 heading--charcoal h--normal'
  const boldClassName = 'paragraph--large paragraph--bold paragraph--charcoal'
  return (
    <div className='detailed-match-score-card'>
      <div className='detailed-match-score-card__team'>
        <p className={boldClassName}>{teamNumber}</p>
        <p className={boldClassName}>{teamName}</p>
        <p className={boldClassName}>{teamLocation}</p>
      </div>
      <div className='detailed-match-score-card__score'>
        <p className={boldClassName}>{`${t('scoreLabel')}:`}</p>
        <p className={scoreClassName}>{score}</p>
      </div>
    </div>
  );
};

DetailedMatchScoreCard.propTypes = {
  teamName: PropTypes.string,
  teamNumber: PropTypes.string,
  teamLocation: PropTypes.string,
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

DetailedMatchScoreCard.defaultProps = {
  score: '0',
  teamName: '',
  teamNumber: '',
  teamLocation: ''
}

export default DetailedMatchScoreCard;