import { API } from 'utils/api';
import * as types from '../../types/eventScheduleTypes';

const getClosingCeremonyBegin = () => {
  return {
    type: types.GET_SCHEDULE_CLOSING_SESSION_BEGIN
  }
}
const getClosingCeremonyError = () => {
  return {
    type: types.GET_SCHEDULE_CLOSING_SESSION_ERROR
  }

}
const getClosingCeremonySuccess = (closingSessionData, closingSessionIncluded) => {
  return {
    type: types.GET_SCHEDULE_CLOSING_SESSION_SUCCESS,
    payload: {
      closingSessionData, closingSessionIncluded
    }
  }
}

const handleClosingSession = (scheduleId) => {
  return async dispatch => {
    await dispatch(getClosingCeremonyBegin())
    try {
      const response = await API.GetInstance().http.get(`/api/schedules/${scheduleId}/closing_session`);
      const {data, included} = response;
      const includedArr = included || [];
      await dispatch(getClosingCeremonySuccess(data, includedArr))
    } catch(err) {
      await dispatch(getClosingCeremonyError())
    }
  }

}

export default handleClosingSession;