const m00Section = {
  id: 'EI',
  name: '2025ScoresheetM00Sub1Label',
  description: 'm00sub1Label',
  fields: [
    {
      scoring: '2025ScoresheetM00Sub2Label',
      id: 'm00_scoring_1',
          options: [
              {
                  description: 'noLabel',
                  value: false
              },
              {
                  description: 'yesLabel',
                  value: true
              }
          ]
    },    
  ]
}

export default m00Section;