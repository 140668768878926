import { API } from 'utils/api';
import { formatDataResult } from 'utils/query';
import { formatPublishPayload } from './model';

//const { http } = API;
const baseUrl = '/api/leaderboard';

export const getLeaderboardById = async (eventId) => {
  const {data} = await API.GetInstance().http.get(`${baseUrl}/${eventId}`);
  return formatDataResult(data);
}

export const getLeaderboardDataById = async (eventId) => {
  const { data } = await API.GetInstance().http.get(`${baseUrl}/${eventId}/rankings`);
  return {
    teams: data.map(formatDataResult),
  };
};

export const toggleLeaderboardPublishStatus = async (eventId, leaderboardId, unpublish) => {
  const payload = formatPublishPayload(eventId, !unpublish);
  const { data } = await API.GetInstance().http.patch(`${baseUrl}/${leaderboardId}`, payload);
  return formatDataResult(data);
};
