import React from 'react';
import PropTypes from 'prop-types';

const Search = ({ fill, onClick }) => {
  return (
    <svg onClick={onClick} width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      {/* <title>A496A930-88B2-4CE4-8B6B-4C2E8429A4A9</title> */}
      <g id="Volunteers-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="My-Volunteers---Add-Volunteers---Import-CSV-Successful---PDP-Admin" transform="translate(-250.000000, -262.000000)" fill={fill}>
          <g id="Group-13" transform="translate(0.000000, 242.000000)">
            <g id="Group-11" transform="translate(250.000000, 12.000000)">
              <g id="Icons/FIRST_Search-Icon" transform="translate(0.000000, 8.000000)">
                <path d="M7.99,0 C3.57724485,0 0,3.57724485 0,7.99 C0,12.4027552 3.57724485,15.98 7.99,15.98 C12.4027552,15.98 15.98,12.4027552 15.98,7.99 C15.98,3.57724485 12.4027552,0 7.99,0 Z M7.99,4.16666667 C10.1015687,4.16666667 11.8133333,5.87843131 11.8133333,7.99 C11.8133333,10.1015687 10.1015687,11.8133333 7.99,11.8133333 C5.87843131,11.8133333 4.16666667,10.1015687 4.16666667,7.99 C4.16666667,5.87843131 5.87843131,4.16666667 7.99,4.16666667 Z" id="Ellipse_2388" />
                <path d="M10.7368609,10.7368609 C11.5150796,9.95864218 12.7557986,9.92480658 13.5742348,10.6353541 L13.6831391,10.7368609 L19.3081391,16.3618609 C20.1217314,17.1754531 20.1217314,18.4945469 19.3081391,19.3081391 C18.5299204,20.0863578 17.2892014,20.1201934 16.4707652,19.4096459 L16.3618609,19.3081391 L10.7368609,13.6831391 C9.9232686,12.8695469 9.9232686,11.5504531 10.7368609,10.7368609 Z" id="Line_16" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Search.propTypes = {
  fill: PropTypes.string,
  onClick: PropTypes.func
};

Search.defaultProps = {
  fill: "#C6C4C6",
  onClick: () => {}
};

export default Search;
