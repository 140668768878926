const rd2Section = {
  id: 'RD2',
  label: 'rd2Label',
  title: 'designLabel',
  description: 'design2DescriptionLabel',
  fields: [
    {
      legendTitle: 'ChallengeRubricsWorkplanLabel',
      id: 'robot_design_2_design_contribution',
      isCoreValue: true,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricsWorkplan1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricsWorkplan2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricsWorkplan3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricsWorkplan4Label',
      }]
    },
    {
      legendTitle: 'featuresLabel',
      id: 'robot_design_2_design_skills',
      isCoreValue: false,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricsMissionInnovation1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricsMissionInnovation2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricsMissionInnovation3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricsMissionInnovation4Label',
      }]
    },
  ]
}

export default rd2Section;