import fllChallenge from './fllChallenge';
import ftc from './ftc';
import fllDiscover from './fllDiscover';
import fllExplore from './fllExplore';
import * as programIds from '../programIds';



export default {
  [programIds.FLL_CHALLENGE_ID]: fllChallenge,
  [programIds.FTC_ID]: ftc,
  [programIds.FLL_EXPLORE_ID]: fllExplore,
  [programIds.FLL_DISCOVER_ID]: fllDiscover,
}

