import * as types from '../types/volunteersTypes';

const DEFAULT_VOLUNTEERS_STATE = {
  volunteers: [],
  count: 0,
  included: [],
  isFetchingVolunteers: false,
  fetchingVolunteersError: false,
  isReinvitingVolunteerUser: false,
  isDeletingVolunteer: false,
  isUpdatingVolunteer: false,
  needRefreshVolunteer: false
};

export default (state = DEFAULT_VOLUNTEERS_STATE, action) => {
  const {type} = action;
  switch (type) {
    case types.DASHBOARD_REQUEST_VOLUNTEERS_BEGIN:
      return {
        ...state,
        isFetchingVolunteers: true,
        needRefreshVolunteer: false
      }
    case types.DASHBOARD_REQUEST_VOLUNTEERS_ERROR:
      return {
        ...state,
        isFetchingVolunteers: false,
        fetchingVolunteersError: true,
        volunteers: [],
        included: []
      };
    case types.DASHBOARD_REQUEST_VOLUNTEERS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetchingVolunteers: false,
        fetchingVolunteersError: false
      };
    case types.REINVITE_USER_DASHBOARD_VOLUNTEERS_TAB_BEGIN:
      return {
        ...state,
        isReinvitingVolunteerUser: true
      }
    case types.REINVITE_USER_DASHBOARD_VOLUNTEERS_TAB_ERROR:
      return {
        ...state,
        isReinvitingVolunteerUser: false
      }
    case types.REINVITE_USER_DASHBOARD_VOLUNTEERS_TAB_SUCCESS:
      return {
        ...state,
        isReinvitingVolunteerUser: false,
        included: action.payload.updatedIncludedList
      }
    case types.EDIT_ADMINBOARD_VOLUNTEER_BEGIN:
      return {
        ...state,
        isUpdatingVolunteer: true
      }
    case types.EDIT_ADMINBOARD_VOLUNTEER_ERROR:
      return {
        ...state,
        isUpdatingVolunteer: false
      }
    case types.EDIT_ADMINBOARD_VOLUNTEER_SUCCESS:
      return {
        ...state,
        isUpdatingVolunteer: false,
        needRefreshVolunteer: true
      }
    case types.DELETE_ADMINBOARD_VOLUNTEER_BEGIN:
      return {
        ...state,
        isDeletingVolunteer: true
      }
    case types.DELETE_ADMINBOARD_VOLUNTEER_ERROR:
      return {
        ...state,
        isDeletingVolunteer: false
      }
    case types.DELETE_ADMINBOARD_VOLUNTEER_SUCCESS:
      return {
        ...state,
        isDeletingVolunteer: false,
        needRefreshVolunteer: true
      }
    default:
      return state;
  }
};
