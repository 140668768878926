import * as types from '../types/judges';

const addNewJudgeToList = (addJudgesToPanelList) => {
  return {
    type: types.ADD_NEW_JUDGE_TO_LIST,
    payload: {
      addJudgesToPanelList
    }
  }
}

const deleteJudgeFromAddToPanelList = (addJudgesToPanelList) => {
  return {
    type: types.DELETE_JUDGE_FROM_ADD_TO_PANEL_LIST,
    payload: {
      addJudgesToPanelList
    }
  }
}

export const resetAddToPanelList = () => {
  return {
    type: types.RESET_JUDGES_ADD_TO_PANEL_LIST,
  }
}


export const handleAddJudgeToPanelList = (newJudge) => {
  return (dispatch, getState) => {
    const {panelManagement: {judgesReferees: {addJudgesToPanelList}}} = getState();

    const updatedList = [...addJudgesToPanelList, newJudge];
    dispatch(addNewJudgeToList(updatedList));

  }
}

export const handleDeleteJudgeFromAddList = (emailAddress) => {
  return (dispatch, getState) => {
    const {panelManagement: {judgesReferees: {addJudgesToPanelList}}} = getState();

    const updatedList = addJudgesToPanelList.filter(j => j.email !== emailAddress)
    dispatch(deleteJudgeFromAddToPanelList(updatedList))
  }
}

export const handleResetAddToPanelList = () => {
  return dispatch => {
    dispatch(resetAddToPanelList())
  }
}