export const VERIFY_USER_AUTH_EMAIL_BEGIN = 'VERIFY_USER_AUTH_FROM_EMAIL_BEGIN';
export const VERIFY_USER_AUTH_EMAIL_SUCCESS = 'VERIFY_USER_AUTH_FROM_EMAIL_SUCCESS';
export const VERIFY_USER_AUTH_EMAIL_ERROR = 'VERIFY_USER_AUTH_FROM_EMAIL_ERROR';

export const SET_VERIFY_USER_DATA = 'SET_VERIFY_USER_DATA';
export const SET_VERIFY_USER_ASSIGN_ROLE = 'SET_VERIFY_USER_ASSIGN_ROLE';

export const VERIFY_USER_SIGNUP_BEGIN = 'VERIFY_USER_SIGNUP_BEGIN';
export const VERIFY_USER_SIGNUP_SUCCESS = 'VERIFY_USER_SIGNUP_SUCCESS';
export const VERIFY_USER_SIGNUP_ERROR = 'VERIFY_USER_SIGNUP_ERROR';

export const SET_SIGNUP_USER_DATA = 'SET_SIGNUP_USER_DATA';
export const ACCEPT_TERMS_CONDITIONS_SUCCESS = 'ACCEPT_TERMS_CONDITIONS_SUCCESS';

export const SET_REGISTRATION_AUTH_QUERY = 'SET_REGISTRATION_AUTH_QUERY';