const m07Section = {
  id: 'm07',
  name: '2025ScoresheetM07Sub1Label',
  fields: [
    {
      scoring: '2025ScoresheetM07Sub2Label',
      id: 'm07_scoring_1',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        }
      ]
    }
  ]
}

export default m07Section;