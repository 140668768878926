import React from 'react';
import PropTypes from 'prop-types';

const Close = (props) => {
  const { fill = 'white', ..._props } = props;
  return (
    <svg {..._props} className='icon-close' width="16px" height="16px" viewBox="0 0 16 16">
      <defs>
        <path d="M9.30546875,7.99765625 L15.2773438,2.03046875 C15.6382813,1.66953125 15.6382813,1.0859375 15.2773438,0.72734375 C14.9164063,0.36640625 14.3328125,0.36640625 13.9742188,0.72734375 L8,6.6921875 L2.02578125,0.725 C1.66484375,0.3640625 1.08125,0.3640625 0.72265625,0.725 C0.36171875,1.0859375 0.36171875,1.66953125 0.72265625,2.028125 L6.69453125,7.9953125 L0.72265625,13.9648438 C0.36171875,14.3257813 0.36171875,14.909375 0.72265625,15.2679688 C0.903125,15.4484375 1.13984375,15.5375 1.37421875,15.5375 C1.6109375,15.5375 1.8453125,15.4484375 2.02578125,15.2679688 L8,9.30078125 L13.9742188,15.2703125 C14.1546875,15.4507813 14.3890625,15.5398437 14.6257813,15.5398437 C14.8625,15.5398437 15.096875,15.4507813 15.2773438,15.2703125 C15.6382813,14.909375 15.6382813,14.3257813 15.2773438,13.9671875 L9.30546875,7.99765625 Z" id="path-12" />
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1240.000000, -32.000000)">
          <g transform="translate(139.000000, 10.000000)">
            <g transform="translate(1101.000000, 22.000000)">
              <mask fill={fill}>
                <use xlinkHref="#path-12" />
              </mask>
              <use fill={fill} xlinkHref="#path-12" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Close.propTypes = {
  fill: PropTypes.string
};

Close.defaultProps = {
  fill: 'white'
};

export default Close;
