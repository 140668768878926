import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import Container from 'shared/components/Container';
import {Button, Form} from 'antd';
import { handleUpdateProfile } from 'features/Header/redux/headerActions';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import Input from 'shared/components/Input';
import { Switch } from 'antd';

const FormItem = Form.Item;

const rowProps = {
  type: 'flex',
  className: 'm-t-2 m-b-2'
};

const EditProfileForm = props => {
  const {
    firstName,
    lastName,
    email,
    phone,
    isUpdatingUser,
    use_24_hour_format
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (values && 'preventDefault' in values) {
      values.preventDefault()
    }
    props?.handleUpdateProfile({...values});
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };
  
  return (
    <Container type='small'>
      <Form hideRequiredMark onFinishFailed={onFinishFailed} onFinish={onFinish} name='profile' layout="vertical" form={form} initialValues={{ email: email, first_name: firstName, last_name: lastName, mobile_phone: phone, use_24_hour_format: use_24_hour_format }}>
        <Row {...rowProps}>
          <Col xs={24} md={16}>
            <FormItem name='email' label={t('emailLabel')} >
              <Input disabled size='large' placeholder={t('emailLabel')} />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col xs={24} md={16}>
            <FormItem name='first_name' label={t('firstNameLabel')} rules={[{ required: true, message: t('enterFirstNameLabel') }]}>
              <Input size='large' placeholder={t('firstNameLabel')} />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col xs={24} md={16}>
            <FormItem name='last_name' label={t('lastNameLabel')} rules={[{ required: true, message: t('enterLastNameLabel') }]}>
              <Input size='large' placeholder={t('lastNameLabel')} />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col xs={24} md={16}>
            <FormItem name='mobile_phone' label={t('phoneLabel')}>
              <Input size='large' placeholder={t('phoneLabel')} />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col xs={24} md={16}>
            <div className='d-flex hours-switch-container'>
              <div className='m-b-5 m-r-10'>12 hour clock</div>
              <FormItem name='use_24_hour_format'>
                <Switch className='hour-btn custom-switch' defaultChecked={use_24_hour_format} />
              </FormItem>
              <div className='m-b-5 m-l-10 enable-switch'>24 hour clock</div>
            </div>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col xs={24} md={16} className='m-t-24'>
            <Button type="primary" htmlType="submit" loading={isUpdatingUser}>
              {t('saveLabel')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

EditProfileForm.propTypes = {
  form: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  handleUpdateProfile: PropTypes.func.isRequired,
  isUpdatingUser: PropTypes.bool.isRequired
};

EditProfileForm.defaultProps = {
  firstName: '',
  lastName: '',
  email: '',
  phone: undefined
}

const mapStateToProps = ({ auth, header }) => {
  const { userInfo = {} } = auth;
  const { isUpdatingUser } = header;
  const { firstName, lastName, email, phone, use_24_hour_format } = userInfo;
  return {
    firstName,
    lastName,
    email,
    phone,
    isUpdatingUser,
    use_24_hour_format
  };
};

export default connect(mapStateToProps, { handleUpdateProfile })(EditProfileForm);