import React from 'react';

export default ()=>{
  return (
    <svg width="6px" height="10px" viewBox="0 0 6 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
    <title>C81F0055-1904-441B-993F-F385F88281B1</title>
    <g id="Events" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Event-Management---Single-Event---PDP-Admin---V2" transform="translate(-274.000000, -1688.000000)" fill="#0066B3">
            <g id="Group-55" transform="translate(250.000000, 1031.000000)">
                <g id="Group-50" transform="translate(0.000000, 42.000000)">
                    <g id="Group-49" transform="translate(10.000000, 76.000000)">
                        <g id="Group-47" transform="translate(0.000000, 32.000000)">
                            <g id="Group-14" transform="translate(2.000000, 26.000000)">
                                <g id="Group-6-Copy-13" transform="translate(0.000000, 465.000000)">
                                    <g id="Group-3" transform="translate(12.000000, 8.000000)">
                                        <g id="Group-8" transform="translate(0.000000, 2.000000)">
                                            <g id="Icons/FIRST/Drop-Arrow-Copy-5" transform="translate(3.000000, 11.000000) rotate(-90.000000) translate(-3.000000, -11.000000) translate(-2.000000, 8.000000)">
                                                <polygon id="Path" points="1.175 0 5 3.70850202 8.825 0 10 1.1417004 5 6 0 1.1417004"></polygon>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
  )
}