/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import '../../../../../../features/PanelManagement/styles/JudgingPanelCard.scss';
import UserRow from 'shared/components/UserRow';
import MissingJudge from './MissingJudge';

const JudgesList = props => {
  const {panelJudges, panelTitle, hasError} = props;

 

  return (
    <div className='judging-panel-card__judges'>
      <h4 className='h-4 heading--charcoal'>{panelTitle}</h4>
      <div className='judging-panel-card__judges-list'>
        {
          panelJudges.map(j => (
            <div className='judging-panel-card__judges-user' key={j.userId}>
              <UserRow fullName={j.fullName} email={j.email} />
            </div>
          ))
        }

        {hasError && (
          <MissingJudge />
        )}
      </div>
    </div>
  );
};

JudgesList.propTypes = {
  panelJudges: PropTypes.array,
  panelTitle: PropTypes.string,
  hasError: PropTypes.bool
};

JudgesList.defaultProps = {
  panelJudges: [],
  panelTitle: '',
  hasError: false
}

export default JudgesList;