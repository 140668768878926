import React from 'react';
import PropTypes from 'prop-types';
import EventModal from 'features/EventOverviewContainer/components/EventModal';
import 'features/EventOverviewContainer/styles/matchesAndScoring/ScoresheetModal.scss';
import Scoresheet from './ScoresheetContent';

const ScoresheetModal = props => {
  const { extraClass, visible, onCancel, scoreInputId, title, scoredScore} = props;

  const wrappClassName = `teams-coaches-modal ${extraClass}`;

  return (
    <EventModal
      visible={visible}
      onCancel={onCancel}
      wrapClassName={wrappClassName}
    >
      <Scoresheet title={title} scoreInputId={scoreInputId} scoredScore={scoredScore}/>
    </EventModal>
  );
};

ScoresheetModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  scoreInputId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default ScoresheetModal;
