import {
  JUDGE_ADVISOR_ID,
  JUDGE_ID,
  COACH_ID,
  EVENT_ADMIN_ID,
} from 'shared/constants/eventRoleTypes';

const ALL_ROLES = [JUDGE_ADVISOR_ID,
  EVENT_ADMIN_ID,
  JUDGE_ID,
  COACH_ID,]

export default [
  {
    description: 'reviewingQuestionsLabel',
    default: "https://firstinspires.blob.core.windows.net/fll/explore/2024-25/fll-explore-submerged-reviewing-documents.pdf",
    "es-MX": "",
    "tr": "",
    "nl": "",
    "he": "",
    roles: ALL_ROLES
  },
  {
    description: 'reviewingSheetLabel',
    default: "https://firstinspires.blob.core.windows.net/fll/explore/2024-25/fll-explore-submerged-reviewing-documents.pdf",
    "es-MX": "",
    "tr": "",
    "nl": "",
    "he": "",
    roles: ALL_ROLES
  },
  {
    description: 'awardsListLabel',
    default: "https://firstinspires.blob.core.windows.net/fll/explore/2024-25/fll-explore-submerged-reviewing-documents.pdf",
    "es-MX": "",
    "tr": "",
    "nl": "",
    "he": "",
    roles: ALL_ROLES
  },
]