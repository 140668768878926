import * as types from './eventMessageTypes';
import * as adminEventTypes from 'features/AdminDashboard/redux/types/eventsTypes';
import * as volunteerEventTypes from 'features/VolunteerDashboard/redux/types/volunteerDashboardTypes';

const DEFAULT_STATE = {
  eventId: null,
  channels: [],
  count: 0,
  isFetchingChannels: false,
  isSavingChannel: false
};

export default (state = DEFAULT_STATE, action) => {
  const {type} = action;
  switch(type) {
    case volunteerEventTypes.FETCH_VOLUNTEER_DASHBOARD_EVENTS_BEGIN:
    case adminEventTypes.FETCH_ADMIN_DASHBOARD_EVENTS_BEGIN: {
      return DEFAULT_STATE;
    }
    case types.GET_EVENT_MESSAGING_CHANNELS_BEGIN: {
      return {
        ...state,
        eventId: action.payload,
        channels: [],
        isFetchingChannels: true
      };
    }
    case types.GET_EVENT_MESSAGING_CHANNELS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        eventId: null,
        isFetchingChannels: false
      };
    }
    case types.GET_EVENT_MESSAGING_CHANNELS_FAIL: {
      return {
        ...state,
        eventId: null,
        channels: [],
        count: 0,
        isFetchingChannels: false
      };
    }
    case types.SAVE_EVENT_MESSAGING_CHANNEL_BEGIN: {
      return {
        ...state,
        isSavingChannel: true
      };
    }
    case types.SAVE_EVENT_MESSAGING_CHANNEL_FAIL: {
      return {
        ...state,
        isSavingChannel: false
      };
    }
    case types.SAVE_EVENT_MESSAGING_CHANNEL_SUCCESS: {
      const channel = action.payload;
      const channels = state.channels;
      const idx = channels.findIndex(({ id }) => id === channel.id);
      const updatedChannels = idx > -1
        ? channels.map((c, i) => i === idx ? { ...c, ...channel } : c)
        : [...channels, channel];
      return {
        ...state,
        channels: updatedChannels,
        isSavingChannel: false
      };
    }
    case types.DELETE_EVENT_MESSAGING_CHANNEL_SUCCESS: {
      const channelId = action.payload;
      return {
        ...state,
        channels: state.channels.filter((s) => s.id !== channelId)
      };
    }
    default:
      return state;
  }
};
