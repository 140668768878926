import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import missingTime from 'features/EventContainer/utils/isCardMissingTime';
import IncompleteEventScheduleCard from 'features/EventContainer/components/ScheduleContainer/ceremonyCards/EventScheduleCardIncomplete';
import '../../styles/ScheduleContainer/EventScheduleCeremonies.scss';
import CompletedScheduleCard from 'features/EventContainer/components/ScheduleContainer/ceremonyCards/EventScheduleCard'
import handleOpeningSession from 'features/EventContainer/redux/actions/scheduleActions/scheduleOpeningSession';
import handleClosingSession from 'features/EventContainer/redux/actions/scheduleActions/scheduleClosingSession';
import { useTranslation } from 'react-i18next';
import { sessionTypesTranslations } from 'shared/constants/sessionTypesTranslations';

const OpeningClosingCeremonies = (props) => {
  const { t } = useTranslation();

  const { use_24_hour_format, openingSession, closingSession, fetchOpeningSession, fetchClosingSession, scheduleId, time_zone, isFetchingOpeningSession, isFetchingClosingSession, is_FLLC_event } = props;
  const isMissingTimeDataOpening = missingTime(openingSession.sessionStartTime, openingSession.sessionEndTime);
  const isMissingTimeDataClosing = missingTime(closingSession.sessionStartTime, closingSession.sessionEndTime);

  const openingStartTime = openingSession.parseStartTime(time_zone, use_24_hour_format);
  const openingStartDate = openingSession.parseStartDate(time_zone);
  const openingEndDate = openingSession.parseEndDate(time_zone);
  const openingEndTime = openingSession.parseEndTime(time_zone, use_24_hour_format);

  const closingStartTime = closingSession.parseStartTime(time_zone, use_24_hour_format);
  const closingStartDate = closingSession.parseStartDate(time_zone);
  const closingEndDate = closingSession.parseEndDate(time_zone);
  const closingEndTime = closingSession.parseEndTime(time_zone, use_24_hour_format);

  const translatedOpeningTitle = sessionTypesTranslations(is_FLLC_event)[openingSession.sessionTypeId];
  const translatedClosingTitle = sessionTypesTranslations(is_FLLC_event)[closingSession.sessionTypeId];

  const openingTitle = translatedOpeningTitle ? t(translatedOpeningTitle) : openingSession.getSessionTypeName();
  const closingTitle = translatedClosingTitle ? t(translatedClosingTitle) : closingSession.getSessionTypeName();

  const openingSessionModalCallback = () => {
    fetchOpeningSession(scheduleId)
  }

  const closingSessionModalCallback = () => {
    fetchClosingSession(scheduleId)
  }

  const renderOpeningCeremonyCard = () => {
    if (isMissingTimeDataOpening) {
      return (
        <IncompleteEventScheduleCard
          sessionId={openingSession.sessionId}
          title={openingTitle}
          iconType='opening'
          endTime={openingEndTime}
          startTime={openingStartTime}
          date={openingStartDate || openingEndDate}
          cb={openingSessionModalCallback}
          loading={isFetchingOpeningSession}
        />
      )
    }

    return (
      <CompletedScheduleCard
        date={openingStartDate || openingEndDate}
        iconType="opening"
        sessionId={openingSession.sessionId}
        title={openingTitle}
        endTime={openingEndTime}
        startTime={openingStartTime}
        cardType="ceremony"
        cb={openingSessionModalCallback}
        url={openingSession.getExternalLink()}
        sessionStartTime={openingSession.sessionStartTime}
        loading={isFetchingOpeningSession}
      />
    );
  }

  const renderClosingCeremonyCard = () => {
    if (isMissingTimeDataClosing) {
      return (
        <IncompleteEventScheduleCard
          iconType="trophy"
          sessionId={closingSession.sessionId}
          title={closingTitle}
          endTime={closingEndTime}
          startTime={closingStartTime}
          date={closingStartDate || closingEndDate}
          cb={closingSessionModalCallback}
          loading={isFetchingClosingSession}
        />
      );
    }

    return (
      <CompletedScheduleCard
        iconType="trophy"
        cardType="ceremony"
        sessionId={closingSession.sessionId}
        title={closingTitle}
        endTime={closingEndTime}
        startTime={closingStartTime}
        date={closingStartDate || closingEndDate}
        cb={closingSessionModalCallback}
        url={closingSession.getExternalLink()}
        sessionStartTime={closingSession.sessionStartTime}
        loading={isFetchingClosingSession}
      />
    );
  }

  return (
    <div className='event-schedule-ceremonies'>
      <h3 className='event-schedule-ceremonies__title h-3'>{t('openAndClosingCeremoniesLabel')}</h3>

      <div className='event-schedule-ceremonies__cards'>

        {renderOpeningCeremonyCard()}
        {renderClosingCeremonyCard()}
      </div>
    </div>
  );
};

OpeningClosingCeremonies.propTypes = {
  openingSession: PropTypes.object.isRequired,
  closingSession: PropTypes.object.isRequired,
  fetchClosingSession: PropTypes.func.isRequired,
  fetchOpeningSession: PropTypes.func.isRequired,
  scheduleId: PropTypes.string.isRequired
}

OpeningClosingCeremonies.defaultProps = {

}

const mapStateToProps = state => {
  const { auth: { userInfo: { use_24_hour_format } }, event: { eventDetails: {is_FLLC_event}, eventSchedule: { isFetchingOpeningSession, isFetchingClosingSession } } } = state;
  return { isFetchingOpeningSession, isFetchingClosingSession, use_24_hour_format, is_FLLC_event }
}

const mapDispatchToProps = {
  fetchOpeningSession: handleOpeningSession,
  fetchClosingSession: handleClosingSession
}

export default connect(mapStateToProps, mapDispatchToProps)(OpeningClosingCeremonies);