/* eslint-disable import/prefer-default-export */
import { notification } from 'antd';
import * as userService from 'utils/services/users';
import * as eventRoleService from 'utils/services/eventRoles';
import * as types from 'features/EventContainer/redux/types/volunteersTypes';

const editEventVolunteerBegin = () => {
  return {
    type: types.EDIT_EVENT_VOLUNTEER_BEGIN
  }
}

const editEventVolunteerError = () => {
  return {
    type: types.EDIT_EVENT_VOLUNTEER_ERROR
  }
}

const editEventVolunteerSuccess = () => {
  return {
    type: types.EDIT_EVENT_VOLUNTEER_SUCCESS
  }
}

export const handleEditEventAssignments = (args) => {
  return async dispatch => {
    await dispatch(editEventVolunteerBegin())
    const { eventId, userId, removedRoles, addedRoles } = args;
    const assignmentIds = [];
    const removedAssignmentIds = [];

    for (let i = 0; i < addedRoles.length; i++) {
      const role = addedRoles[i];
      try {
        const data = await eventRoleService.createUserEventRole({ userId, eventId, roleTypeId: role });
        assignmentIds.push(data.id);
      } catch (err) {
        if (err && 'data' in err && err.data.statusCode === 451) {
          notification.error({
            message: 'Error',
            description: 'Volunteer has not been screened and cannot serve as coach or volunteer.',
          });
        } else if (err && 'data' in err && err.data.statusCode === 409) {
          notification.error({
            message: 'Error',
            description: 'The user has already been assigned that role of this event.',
          });
        } else if (err && 'status' in err && err.status === 403) {
          notification.error({
            message: 'You do not have permission to edit the volunteer.',
          });
        } else {
          notification.error({
            message: 'Error',
            description: 'Error sending invitation',
          });
        }
      }
    }

    for (let i = 0; i < removedRoles.length; i++) {
      const deletedRole = removedRoles[i];
      try {
        await eventRoleService.removeUserEventRole(deletedRole);
        removedAssignmentIds.push(i);
        notification.success({
          message: 'Success',
          description: 'User event role successfully removed.',
        });
      } catch (err) {
        notification.error({
          message: 'Error',
          description: 'Error removing user event role.',
        });
      }
    }

    if (assignmentIds.length) {
      await eventRoleService.sendInvitations(assignmentIds);
      notification.success({
        message: 'Success',
        description: 'Invitation successfully sent.',
      });
      await dispatch(editEventVolunteerSuccess())
    } else if (removedAssignmentIds.length) {
      await dispatch(editEventVolunteerSuccess())
    } else {
      await dispatch(editEventVolunteerError())
    }
  }
}

const editVolunteerInfoBegin = () => {
  return {
    type: types.EDIT_VOLUNTEER_INFO_BEGIN
  }
}

const editVolunteerInfoError = () => {
  return {
    type: types.EDIT_VOLUNTEER_INFO_ERROR
  }
}

const editVolunteerInfoSuccess = () => {
  return {
    type: types.EDIT_VOLUNTEER_INFO_SUCCESS
  }
}

export const editVolunteerInfo = (userId, values) => {
  return async (dispatch) => {
    try {
      await dispatch(editVolunteerInfoBegin())
      await userService.updateUser(userId, values);
      await dispatch(editVolunteerInfoSuccess());
      notification.success({
        message: 'Success',
        description: 'Volunteer information successfully saved.',
      });
    } catch(err) {
      await dispatch(editVolunteerInfoError())
      notification.error({
        message: 'Error',
        description: 'Error saving volunteer information.',
      });
    }
  }
}

const deleteVolunteerBegin = () => {
  return {
    type: types.DELETE_EVENT_VOLUNTEER_BEGIN
  }
}

const deleteVolunteerError = () => {
  return {
    type: types.DELETE_EVENT_VOLUNTEER_ERROR
  }
}

const deleteVolunteerSuccess = () => {
  return {
    type: types.DELETE_EVENT_VOLUNTEER_SUCCESS
  }
}

export const deleteEventVolunteer = ({ assignmentIds }) => {
  return async (dispatch) => {
    try {
      await dispatch(deleteVolunteerBegin());
      await eventRoleService.removeMultiUserEventRoles(assignmentIds);
      await dispatch(deleteVolunteerSuccess());
    } catch(err) {
      await dispatch(deleteVolunteerError());
      notification.error({
        message: 'Error',
        description: 'Error deleting event volunteer.',
      });
    }
  }
}
