import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../styles/ScheduleContainer/EditEventInfoModal.scss';
import {connect} from 'react-redux';
import Container from 'shared/components/Container';
import editEventGeneralInfo from 'features/EventContainer/redux/actions/scheduleActions/editEventInfo';
import EventTimeBlock from 'features/EventTimeSection/components/EventTimeBlock';
import EventTimeBlockerBlock from 'features/EventTimeSection/components/EventTimeBlockerBlock';
import { getScheduleBreaksByScheduleId, getScheduleDaysByScheduleId, getScheduleJudgingSessions, getScheduleScoringSessions } from 'utils/services/schedules';
import EventSessionDurationBlock from 'features/EventTimeSection/components/SessionDurationBlock';
import { handleScheduleBreakCreateAndUpdate, handleScheduleDaysUpdate, handleScheduleSessionDurationUpdate, handleScheduleGenerating, handleDeleteBreakById, extractUpdatedBreaks } from 'features/EventContainer/redux/actions/scheduleActions/scheduleSettings';
import SchedulingModal from './SchedulingModal';
import { notification, Tooltip, Button, Form } from 'antd';
import {
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID
} from 'shared/constants/programIds';
import Icon from 'shared/components/Icon';

const FormItem = Form.Item;

const EditMeetingModalContent = props => {

  const {
    eventDetails,
    closeCb,
    saveBreaks,
    saveDays,
    saveSessionsSetting,
    generateSchedule,
    isGeneratingScheduleSessions,
    isLoadingMessage,
    deleteBreakById
  } = props;
  const { t, i18n} = useTranslation()
  const [form] = Form.useForm();

  const [days, setDays] = useState({});
  const [breaks, setBreaks] = useState({});

  const [isLoadingDays, setIsLoadingDays] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [generateDisabled, setGenerateDisabled] = useState(true);

  const {time_zone, eventScheduleId, eventProgramId} = eventDetails;
  const is_FLLD_OR_FLLE = eventProgramId === FLL_DISCOVER_ID || eventProgramId === FLL_EXPLORE_ID;

  const checkPanelExistingBeforeGeneratingSchedule = async (scheduleId)=>{
    const judging_sessions = await getScheduleJudgingSessions(scheduleId);
    const scoring_sessions = await getScheduleScoringSessions(scheduleId);
    const has_no_judging_panel = [...((judging_sessions||{}).included||[])].filter(i=>(i||{}).type === 'panel').length === 0;
    const has_no_scoring_panel = [...(scoring_sessions||{})].filter(i=>(i||{}).panelId).length === 0;
    const disableGenerateSchedule = is_FLLD_OR_FLLE ? has_no_judging_panel : has_no_judging_panel || has_no_scoring_panel
    setGenerateDisabled(disableGenerateSchedule);

  }

  const fetchDaysDetail = async (eventScheduleId)=>{
    const days = await getScheduleDaysByScheduleId(eventScheduleId);
    const breaks = await getScheduleBreaksByScheduleId(eventScheduleId);
    setDays(days);
    setBreaks(breaks)
    setIsLoadingDays(false);
  }

  useEffect(()=>{
    eventScheduleId && fetchDaysDetail(eventScheduleId);
    eventScheduleId && checkPanelExistingBeforeGeneratingSchedule(eventScheduleId);
  },[eventScheduleId]);

  const saveSettings = async (callback)=>{
    try{
      setIsSaving(true);
      const formValues = form.getFieldsValue();
      const updated_breaks = formValues.breaks;
      const updated_days = formValues.days;
      let updated_duration = formValues.durations;
      if(updated_breaks){
        const needs_update = extractUpdatedBreaks(days, breaks, updated_breaks);
        if(needs_update.length > 0) await saveBreaks(needs_update, days)
      }
      if(updated_days){
        if('start' in updated_days || 'end' in updated_days){
          const {start, end} = updated_days;
          const current_schedule = (((days||{}).included||[]).filter(i=>(i||{}).type === 'schedule')[0]||{}).attributes;
          const {default_session_start_time, default_session_end_time} = current_schedule;
  
          if(start !==default_session_start_time || end !==default_session_end_time){
            const duration = {
              default_session_start_time: start,
              default_session_end_time: end,
            }
            updated_duration = updated_duration ? {...updated_duration, ...duration} : duration;
          }
        }else{
          await saveDays(updated_days, days);
          const temp_duration = {default_session_start_time: null, default_session_end_time: null};
          updated_duration = updated_duration ? {...updated_duration, ...temp_duration} : temp_duration;
        }
      }
      if(updated_duration){
        await saveSessionsSetting(updated_duration)
      }
      if(updated_breaks, updated_days, updated_duration) fetchDaysDetail(eventScheduleId);
      setIsSaving(false);
      callback && callback();
    }catch(e){
      setIsSaving(false);
      throw(e);
    }
  }

  const onSaveAndClose = async ()=>{
    try {
      await saveSettings(closeCb);
    }catch(e){
      let description = ((e||{}).data||{}).error || ((e||{}).data||{}).message ||'';
      if(i18n.exists(description)){
        description = t(description)
      }
      notification.error({
        message: 'Error',
        description: description
      })
    }
  }

  const onGenerateSchedule =async ()=>{
    try{await generateSchedule(saveSettings, closeCb);}catch(e){
      let description = ((e||{}).data||{}).error || ((e||{}).data||{}).message ||'';
      if(i18n.exists(description)){
        description = t(description)
      }
      notification.error({
        message: "Error",
        description: description,
      })
    }
  }

  const overlayStyle = generateDisabled ? {} : {display: "none"};

  return (
    <div>
      <Container type="default">
        <div className="c-edit-event-info__title-schedule">
          <h1 className="heading--charcoal h--bold h-2">{t('ScheduleSettingsLabel')}</h1>
        </div>
      </Container>

      <SchedulingModal visible={isGeneratingScheduleSessions} message={isLoadingMessage}/>

      <Container type="medium">
        <Form name='EditScheduleSetting' form={form} rules={[{ required: true}]}>
          <FormItem name='days'>
            <EventTimeBlock days={days} isLoadingDays={isLoadingDays} form={form} time_zone={time_zone}/>
          </FormItem>
          <FormItem name='breaks' rules={[{ required: true}]}>
            <EventTimeBlockerBlock breaks={breaks} days={days} isLoadingDays={isLoadingDays} form={form} onBreakDelete={deleteBreakById} time_zone={time_zone}/>
          </FormItem>
          <FormItem name='durations' rules={[{ required: true}]}>
              <>
                <EventSessionDurationBlock isLoadingDays={isLoadingDays} days={days} form={form} type="judging"  time_zone={time_zone}/>
                {is_FLLD_OR_FLLE ? null : <EventSessionDurationBlock isLoadingDays={isLoadingDays} days={days} form={form} type="scoring"  time_zone={time_zone}/>}
              </>
          </FormItem>
        </Form>
        <div className="submit-group-wrapper">
          <div className="submit-message">
                <Icon type="orangeWaring"/>
                <span>
                  <p>{t('AutoSchedulerWarningLabel')}</p>
                  <p>{is_FLLD_OR_FLLE ? t('nonChallengeAutoSchedulerWarningLabel3') : t('AutoSchedulerWarningLabel2')}</p>
                </span>
          </div>
          <div className="submit-button-group">
              <Button disabled={isSaving} additionalClassNames="save-button" onClick={onSaveAndClose}>Save and Close</Button>
              <Tooltip defaultVisible={false} overlayStyle={overlayStyle} placement="bottom" title={t('ScheduleSettingsWarningLabel')} className="generate-tooltip-wrapper">
                <div>
                  <Button disabled={isSaving || generateDisabled} additionalClassNames="generate-button" onClick={onGenerateSchedule}>Generate Schedule</Button>
                </div>
              </Tooltip>
          </div>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { event: { 
    eventDetails,
    eventSchedule,
   } } = state;
   const {
    isEditingEventGeneralInfo,
    isGeneratingScheduleSessions,
    isLoadingMessage,
   } = eventSchedule;
  return { 
    eventDetails,
    savingEvent: isEditingEventGeneralInfo,
    isGeneratingScheduleSessions,
    isLoadingMessage,
  };
};

export default connect(mapStateToProps, 
  {editEventInfo: editEventGeneralInfo,
  saveBreaks: handleScheduleBreakCreateAndUpdate,
  saveDays: handleScheduleDaysUpdate,
  saveSessionsSetting: handleScheduleSessionDurationUpdate,
  generateSchedule: handleScheduleGenerating,
  deleteBreakById: handleDeleteBreakById,
}
  )(EditMeetingModalContent);