import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'antd';
import './UploadInstructions.scss';
import { useTranslation } from 'react-i18next';

const UploadInstructions = props => {
  const {instructions, relatedLinks} = props
  const {t} = useTranslation();
  return (
    <Row type='flex' gutter={10} justify='space-between' className='upload-instructions'>
      <Col xs={24}>
        <p className='paragraph--default paragraph--charcoal paragraph--normal'>
          {instructions ? instructions : t('judgingInstructionsText')}
        </p>
        {relatedLinks.length ? (
          <Row className='m-t-30' gutter={20}>
            {relatedLinks.map(i => (
              <Col xs={24} sm={12} md={8} key={i.title}><a href={i.url} className='link'>{i.title}</a></Col>
            ))}
          </Row>
        ) : (null)}
      </Col>
    </Row>
  );
};

UploadInstructions.propTypes = {
  instructions: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  relatedLinks: PropTypes.array
};
UploadInstructions.defaultProps = {
  instructions: '',
  relatedLinks: []
};

export default UploadInstructions;