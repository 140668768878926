import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Row, TimePicker, Col, Skeleton, Form, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { addEllipsisMiddle } from 'utils/truncateString';
import Input from 'shared/components/Input';
import TextBox from 'shared/components/TextBox';
import Dropdown from 'shared/components/FormDropdown';
import { connect } from 'react-redux';
import { formatRangePickerToTimezone, convertToDayjs, morningMomentObject } from 'utils/dateTimeUtils';
import { handleUpdateMeeting, getScheduleMeetingSessions, getEventTeams } from 'features/EventContainer/redux/actions/scheduleActions/scheduleMeeting';
import { useParams } from 'react-router-dom';
import { handleEventVolunteers } from 'features/EventContainer/redux/actions/volunteers/getEventVolunteers';
import { getMeetingById } from 'utils/services/meetings';
import { urlTransform, urlValidation } from 'utils/formHelpers';
import {
  generateMeetingMicrosoftTeamsLink,
  createNewMeetingLink
} from 'features/EventContainer/redux/actions/scheduleActions'
import SessionUrlMessage from '../../SessionUrlActions/SessionUrlMessage';
import SessionUrlActions from '../../SessionUrlActions';
import EditLinkModal from '../../SessionUrlActions/EditLinkModal';
import GenerateLinkModal from '../../SessionUrlActions/GenerateLinkModal';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(tz);
dayjs.extend(utc);


const INITIAL_STATE = {
  isLoading: true,
  isError: false,
  data: {
    startTime: null,
    endTime: null,
    meetingUrl: null,
    notes: undefined,
    title: null,
    attendees: [],
    teams: []
  }
}

const MEETING_URL_FIELD_ID = 'meetingUrl';

const MAX_SIZE = 500;

const { Option } = Dropdown;

const FormItem = Form.Item;

const { RangePicker } = DatePicker;


const rowProps = {
  type: 'flex', gutter: [20, 20],
}

const SessionForm = props => {
  const { t } = useTranslation()
  const [state, setState] = useState(INITIAL_STATE);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [generateLinkModalVisible, setGenerateLinkModalVisible] = useState(false);
  const [form] = Form.useForm();

  const {
    fetchEventVolunteers,
    volunteers,
    getTeamOptions,
    eventScheduleId,
    teamOptions,
    meetingId,
    updateMeeting,
    isSavingMeeting,
    isCreatingNewMeetingLink,
    isGeneratingLink,
    generateLink,
    createLink,
    time_zone,
    use_24_hour_format
  } = props;

  const { id: eventId } = useParams();

  useEffect(() => {
    fetchEventVolunteers(eventId, { number: 1, size: MAX_SIZE });
    getTeamOptions(eventId)
  }, [eventId])

  useEffect(() => {
    let isMounted = true;
    const fetchMeeting = async () => {
      setState(prevState => ({ ...prevState, isLoading: true, isError: false }))
      try {
        const { data, included } = await getMeetingById(meetingId);
        const { attributes } = data;
        const meetingLinkId = (data.relationships.external_link && data.relationships.external_link.data) ? data.relationships.external_link.data.id : undefined;
        let externalLink;
        const externalLinkDetails = included.find(i => i.id === meetingLinkId);
        if (externalLinkDetails) {
          externalLink = externalLinkDetails.attributes ? externalLinkDetails.attributes.url : '';
        }
        const meetingDetails = {
          startTime: attributes.start_time,
          startDate: attributes.start_time,
          endTime: attributes.end_time,
          meetingUrl: externalLink,
          title: attributes.title,
          teams: included.filter(i => i.type === 'team').map(meetingTeam => meetingTeam.id),
          attendees: included.filter(i => i.type === 'user').map(meetingUser => meetingUser.id),
          notes: attributes.notes
        }
        if (isMounted) {
          setState(prevState => {
            return {
              ...prevState,
              data: {
                ...prevState.data,
                ...meetingDetails,
              },
              isLoading: false,

            }
          })
        }
      } catch (err) {
        if (isMounted) {
          setState(prevState => {
            return { ...prevState, isLoading: false, isError: true }
          })
        }
        throw err;
      }

    }

    if (meetingId) {
      fetchMeeting();
    }
    return () => { isMounted = false }

  }, [meetingId])


  if (state.isLoading) {

    return (
      <div style={{ width: '100%', marginTop: '60px' }}>
        <Skeleton title={false} paragraph={{ rows: 6 }} />
      </div>
    );

  }
  if (state.isError) {
    return <p>{t('errorLabel')}</p>
  }
  const updateMeetingCallback = () => {
    props.closeModal()
    // props.getScheduleMeetingSessions();
  }
  const onSave = async (e) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }
    form.validateFields().then(async (values) => {
      const { date } = values;

      const startDate = Array.isArray(date) ? date[0] : undefined;
      const endDate = Array.isArray(date) ? date[1] : undefined;

      const formattedZuluStart = formatRangePickerToTimezone(startDate, time_zone);
      const formattedZuluEnd = formatRangePickerToTimezone(endDate, time_zone);


      const patchedTeams = [];
      const removedTeams = [];
      const initialTeams = state.data.teams || [];
      const formTeams = values.teams || [];
      formTeams.forEach(ft => {
        if (!initialTeams.includes(ft)) {
          patchedTeams.push(ft);
        }
      })
      initialTeams.forEach(a => {
        if (!formTeams.includes(a)) {
          removedTeams.push(a);
        }
      })


      const patchedAttendees = [];
      const removedAttendees = [];
      const initialAttendees = state.data.attendees || [];
      const formAttendees = values.attendees || [];
      formAttendees.forEach(fa => {
        if (!initialAttendees.includes(fa)) {
          patchedAttendees.push(fa);
        }
      });
      initialAttendees.forEach(a => {
        if (!formAttendees.includes(a)) {
          removedAttendees.push(a);
        }
      })

      const trimmedTitle = values.meetingTitle && values.meetingTitle.trim();

      const patchPayload = {
        startTime: formattedZuluStart,
        endTime: formattedZuluEnd,
        meetingUrl: urlTransform(values.meetingUrl),
        notes: values.notes,
        scheduleId: eventScheduleId,
        title: trimmedTitle,
        attendees: patchedAttendees || [],
        teams: patchedTeams || [],
      }

      const args = {
        removedAttendees,
        removedTeams,
        patchPayload
      }
      await updateMeeting(args, meetingId, updateMeetingCallback)
    });
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };
  

  const refetchMeeting = async () => {
    const { data, included } = await getMeetingById(meetingId);
    let externalLink;
    const meetingLinkId = (data.relationships.external_link && data.relationships.external_link.data) ? data.relationships.external_link.data.id : undefined;
    const externalLinkDetails = included.find(i => i.id === meetingLinkId);
    if (externalLinkDetails) {
      externalLink = externalLinkDetails.attributes ? externalLinkDetails.attributes.url : '';
    }
    setState(prevState => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          meetingUrl: externalLink
        }
      }
    })
  }

  const closeGenerateLinkModal = () => {
    setGenerateLinkModalVisible(false)
  }

  const generateLinkCb = async () => {
    await refetchMeeting();
    closeGenerateLinkModal()
  }
  const handleRegenerateLink = () => {
    generateLink(meetingId, generateLinkCb)
  }

  const closeEditModal = () => {
    setEditModalVisible(false)
  }


  const updateLinkValue = (linkValue) => {
    setState(prevState => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          meetingUrl: linkValue
        }
      }
    })
  }

  const saveNewLinkCb = async (linkValue) => {
    updateLinkValue(linkValue)
    closeEditModal()
  }

  const handleSaveNewLink = (meetingLink) => {
    createLink(meetingId, meetingLink, saveNewLinkCb)
  }

  return (
    <div className="schedule-session-modal__form">
      <EditLinkModal
        onCancel={closeEditModal}
        visible={editModalVisible}
        title='Edit Link'
        initialUrlValue={state.data.meetingUrl}
        loading={isCreatingNewMeetingLink}
        onSubmit={handleSaveNewLink}
      />
      <GenerateLinkModal
        visible={generateLinkModalVisible}
        onCancel={closeGenerateLinkModal}
        onSubmit={handleRegenerateLink}
        loading={isGeneratingLink}
      />
      <Form hideRequiredMark onFinish={onSave} onFinishFailed={onFinishFailed} form={form} initialValues={{meetingTitle: state.data.title, attendees: state.data.attendees,teams: state.data.teams,meetingUrl: state.data.meetingUrl, notes: state.data.notes}}>
        <Row {...rowProps}>
          <Col xs={24}>
            <FormItem name='meetingTitle' label={<span>{t('meetingTitleLabel')}</span>} rules={[{ required: true, message: t('enterMeetingTitleLabel') }]}>
              <Input size="large" placeholder={t('meetingTitleLabel')} />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col xs={24} id='edit-meeting-range-picker' style={{ position: 'relative' }}>
            <FormItem name='date' label={<span>{t('dateLabel')}</span>} rules={[{ required: true, message: 'Select Date and Time' }]} initialValue={[convertToDayjs(state.data.startTime, time_zone),
                  convertToDayjs(state.data.endTime, time_zone)]}>
              <RangePicker
                placeholder={[t('startDateLabel'), t('endDateLabel')]}
                showTime={{
                  format: use_24_hour_format ? 'HH:mm' : 'h:mm a', use12Hours: !use_24_hour_format,
                }}
                defaultValue={[morningMomentObject(), morningMomentObject()] }
                className="schedule-session-modal__range-picker"
                size="large"
                format={`MM-DD-YYYY ${use_24_hour_format ? 'HH:mm' : 'h:mm a'}`}
                getCalendarContainer={() => document.getElementById('edit-meeting-range-picker')}
              />
            </FormItem>
          </Col>
        </Row>

        <Row {...rowProps}>
          <Col xs={24} md={12}>
            <FormItem name='attendees' label={<span>{t('volunteersLabel')}</span>}>
                <Dropdown
                  placeholder={t('volunteersLabel')}
                  {...form}
                  mode="multiple"
                  removeIcon={<CloseCircleOutlined />}
                >
                  {volunteers
                    .sort((a, b) => a.lastName.localeCompare(b.lastName))
                    .map((v) => (
                      <Option key={v.id} value={v.id}>
                        {v.fullName}
                      </Option>
                    ))}
                </Dropdown>
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem name='teams' label={<span>{t('teamsLabel')}</span>}>
                <Dropdown
                  placeholder={t('selectTeamsLabel')}
                  {...form}
                  mode="multiple"
                  removeIcon={<CloseCircleOutlined />}
                >
                  {teamOptions
                    .sort((a, b) => a.teamNumberName.localeCompare(b.teamNumberName))
                    .map((v) => (
                      <Option key={v.id} value={v.id}>
                        {v.teamNumberName}
                      </Option>
                    ))}
                </Dropdown>
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps} align='middle'>
          <Col xs={24} md={18}>
            <FormItem name='meetingUrl' label={<span>{t('meetingUrlLabel')}</span>} rules={[urlValidation]}>
              <abbr title={state.data.meetingUrl} className='schedule-session-modal__external-link paragraph--large paragraph--charcoal'>{addEllipsisMiddle(state.data.meetingUrl, 50, 30, 10)}</abbr>
            </FormItem>
          </Col>
          <Col xs={24} md={6}>
            <SessionUrlActions
              copyElementId='meeting-url-id'
              onRegenerateClick={() => { setGenerateLinkModalVisible(true) }}
              onEditClick={() => { setEditModalVisible(true) }}
            />
          </Col>
        </Row>
        <p style={{ display: 'none' }} id='meeting-url-id'>{state.data.meetingUrl}</p>
        <SessionUrlMessage boldMessage='If you want to use your own meeting link, click edit and replace the one above.' />
        <Row {...rowProps}>
          <Col xs={24}>
            <FormItem name='notes' label={<span>{t('notesLabel')}</span>}>
                <TextBox
                  size="large"
                  placeholder={t('enterAnyNotesLabel')}
                  style={{ height: '254px' }}
                />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Col>
            <FormItem>
              <Button disabled={isSavingMeeting} htmlType="submit">
                {t('saveAndPublishLabel')}
              </Button>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

SessionForm.propTypes = {
  form: PropTypes.object.isRequired,
  volunteers: PropTypes.array,
  eventScheduleId: PropTypes.string.isRequired,
  updateMeeting: PropTypes.func.isRequired,
  getScheduleMeetingSessions: PropTypes.func.isRequired,
  teamOptions: PropTypes.array,
  meetingId: PropTypes.string.isRequired,
  isSavingMeeting: PropTypes.bool
};

SessionForm.defaultProps = {
  volunteers: [],
  teamOptions: [],
  isSavingMeeting: false
}

const mapDispatchToProps = {
  updateMeeting: handleUpdateMeeting,
  getScheduleMeetingSessions,
  fetchEventVolunteers: handleEventVolunteers,
  getTeamOptions: getEventTeams,
  generateLink: generateMeetingMicrosoftTeamsLink,
  createLink: createNewMeetingLink
}

const mapStateToProps = (state) => {
  const { auth: { userInfo: { use_24_hour_format } }, event: {
    eventDetails: {
      eventScheduleId,
      time_zone,
    },
    eventSchedule: {
      isCreatingNewMeetingLink,
      isGeneratingMicrosoftMeetingLink
    },
  } } = state;
  return {
    time_zone,
    eventScheduleId,
    isCreatingNewMeetingLink,
    isGeneratingLink: isGeneratingMicrosoftMeetingLink,
    use_24_hour_format
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionForm)