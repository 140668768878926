import RightOutlined from '@ant-design/icons';
import React, { useState, useCallback } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {Form, Button} from 'antd';
import AutoComplete from 'shared/components/FormAutoComplete';
import Input from 'shared/components/Input';
import text from 'shared/translations/en/volunteersModal';
import UserOption from 'shared/components/UserAutocomplete/UserAutocompleteOption';
import * as userService from 'utils/services/users';
import validateEmail from 'utils/validateEmail';

import './UserForm.scss';

const FormItem = Form.Item;

const { Option } = AutoComplete;

const UserForm = props => {
  const [disabledForm, setDisabledForm] = useState(true);
  const [users, setUsers] = useState([]);
  const {
    disableInvite,
    selectedUser,
    updateUser,
    handleUserSelect,
    isAutocompleteSelected,
    setIsAutocompleteUserSelected,
    addUserToList,
    resetManualForm,
    handleInviteUserNow
  } = props;
  const { t } = useTranslation()

  const [form] = Form.useForm();
  const isEmailTouched = form.isFieldTouched('email');
  const isFirstNameTouched = Boolean(form.getFieldValue('firstName'));
  const isLastNameTouched = Boolean(form.getFieldValue('lastName'));
  const isPhoneTouched = Boolean(form.getFieldValue('phone'));

  const handleUpdateUser = useCallback(debounce(updateUser, 200), []);

  const handleUserSearch = async (input) => {
    const formattedUsers = await userService.getUsersLikeEmail(input);
    setUsers(formattedUsers);
  };

  const clearForm = () => {
    form.resetFields();
    setDisabledForm(true);
    resetManualForm();
  }

  const inviteNow = async (e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields().then(async (values, err) => {
      await handleInviteUserNow(values);
    });
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const addToList = async (e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields().then(async (values, err) => {
      addUserToList(values);
      clearForm();
    });
  }

  const handleEmailChange = (value) => {
    handleUpdateUser('email', value);
    const isValidEmail = validateEmail(value);
    setDisabledForm(!isValidEmail);
    if (isAutocompleteSelected) {
      clearForm();
    }
  }

  const handleChange = (fieldName, e) => {
    const { value } = e.target;
    handleUpdateUser(fieldName, value);
  }

  const onSelect = (email) => {
    const selectedUser = users.find(u => u.email === email);
    handleUserSelect(selectedUser)
    setDisabledForm(false)
    setIsAutocompleteUserSelected(true)
  }

  return (
    <Form hideRequiredMark className='add-user-form' form={form} initialValues={{email: selectedUser.email, firstName: selectedUser.firstName, lastName: selectedUser.lastName, phone: selectedUser.phone}}>
      <Form.Item name='email' label={t('emailLabel')} rules={[{ required: true, message: t('enterEmailLabel') },{ type: 'email', message: t('enterValidEmailLabel') },]}>
          <AutoComplete
            placeholder={t('emailLabel')}
            onChange={handleEmailChange}
            onSearch={handleUserSearch}
            onSelect={onSelect}
            dataSource={users.map(u => (
              <Option key={u.id} value={u.email} text={u.email}>
                <UserOption name={`${u.firstName} ${u.lastName}`} email={u.email} id={u.id} />
              </Option>
            ))}
          />
      </Form.Item>


      <Form.Item name='firstName' label={t('firstNameLabel')} rules={[{ required: true, message: t('enterFirstNameLabel') }]}>
        <Input size='large' placeholder={text.firstName} disabled={disabledForm || isAutocompleteSelected} onChange={(e) => { handleChange('firstName', e) }} />
      </Form.Item>
      <Form.Item name='lastName' label={t('lastNameLabel')} rules={[{ required: true, message: t('enterLastNameLabel')}]}>
        <Input size='large' placeholder={text.lastName} disabled={disabledForm || isAutocompleteSelected} onChange={(e) => { handleChange('lastName', e) }} />
      </Form.Item>
      <Form.Item name='phone' label={t('phoneOptionalLabel')}>
        <Input size='large' placeholder={text.phoneOptional} disabled={disabledForm || isAutocompleteSelected} onChange={(e) => { handleChange('phone', e) }} />
      </Form.Item>
      <div className='add-user-form__buttons'>
        <Form.Item>
          <Button htmlType='submit' disabled={disabledForm || disableInvite} onClick={inviteNow}>{t('inviteNowLabel')}</Button>
        </Form.Item>
        <p className={disabledForm ? 'paragraph--silver paragraph--small' : 'paragraph--charcoal paragraph--small'}>{` ${t('orLabel')} `}</p>
        <Form.Item>
          <Button type='default' styleType='secondary' size='large' additionalClassNames='p-r-14 p-l-14' onClick={addToList} disabled={disabledForm || disableInvite}>
            {`${t('addToListLabel')}`}
            {' '}
            <RightOutlined />
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

UserForm.propTypes = {
  form: PropTypes.object.isRequired,
  selectedUser: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  handleUserSelect: PropTypes.func.isRequired,
  isAutocompleteSelected: PropTypes.bool.isRequired,
  setIsAutocompleteUserSelected: PropTypes.func.isRequired,
  addUserToList: PropTypes.func.isRequired,
  resetManualForm: PropTypes.func.isRequired,
  handleInviteUserNow: PropTypes.func
};

UserForm.defaultProps = {
  handleInviteUserNow: () => { }
};

export default UserForm;
