import React from 'react';
import Paragraph from 'shared/components/Paragraph';
import Lock from 'shared/components/Icon/icons/Lock';
import { useTranslation } from 'react-i18next';
import validateEmail from 'utils/validateEmail';
import CustomLink from 'shared/components/Link';
import { ROLE_TYPES } from '../UserRolesWithAccess';
import {sanitizeUrl as sanitize} from '@braintree/sanitize-url'


const cls = 'c-event-messaging-channel';

const MessagingChannel = (props) => {
  const { t } = useTranslation();
  const { id, title, description, roles, link, handleEdit } = props;
  return (
    <section className={cls}>
      {Boolean(handleEdit) && <CustomLink
        className={`${cls}__edit`}
        onClick={() => handleEdit(id)}
        customText={t('editLabel')}
      />}
      <div className={`${cls}__title`}>
        <Lock />
        <Paragraph size='large' weight='bold'>
          {title}
        </Paragraph>
      </div>
      <Paragraph size='medium' className={`${cls}__description`}>
        {description}
      </Paragraph>
      <Paragraph className={`${cls}__roles`}>
        {roles.map((id) => t(ROLE_TYPES[id])).join(', ')}
      </Paragraph>
      {validateEmail(link) 
        ? <a href={`mailto:${link}`} target='_blank'>
          <Paragraph size='medium' color='blue'>{link}</Paragraph>
        </a>
        : <CustomLink 
          className={`${cls}__link`} 
          href={sanitize(link && link.includes('http') ? link : `https://${link}`)}
          target='_blank'
          customText={t('visitLabel')}
        />
      }
    </section>
  );
};

export default MessagingChannel;
