import { notification } from 'antd';
import jwtDecode from 'jwt-decode';
import { assignUserRole, getUserIdTypeDetails } from 'utils/services/users';
import * as userEventRoleService from 'utils/services/eventRoles';
import * as userTypes from 'shared/constants/userTypes';
import { formatUserEventRoleTypesList, formatEventAssignments } from 'utils/services/eventRoles/model';
import store from 'features/store';
import {
  loginRequestSuccess,
  loginRequestError,
  resetAuth,
  setAuthUserData
} from 'features/Authentication/redux/authActions';
import { verifySignUpSuccess } from 'features/RegistrationContainer/redux/registrationActions';

const getSignUpUrl = (signUpData, error) => {
  const { email, userId, code } = signUpData;
  return `/signup?verify_user=true&email=${email}&id=${userId}&code=${code}&error=${error}`;
};

export const updateAuthToken = (authResult, successAction) => {
  const idTokenPayload = authResult.idTokenPayload || jwtDecode(authResult.idToken);
  store.dispatch(successAction({ ...authResult, idTokenPayload, expires_at: idTokenPayload?.exp }));
  return idTokenPayload;
};

export const handleAuthError = (error) => {
  store.dispatch(resetAuth());
  store.dispatch(loginRequestError(error));
  window.location.replace('/login');
};

export const processAuthResult = async (authResult) => {
  try {
    const { lastSignUp } = store.getState().auth;
    const { idToken } = authResult;
    if (!idToken) {
      const description = 'Authentication failed';
      notification.error({
        message: 'Error',
        description,
      });
      store.dispatch(loginRequestError(description));
      return;
    }
    if (lastSignUp.email && lastSignUp.assignRole) {
      notification.success({
        message: 'Success',
        description: 'Your new account setup successful. Please login.',
      });
      await assignUserRole(lastSignUp);
      store.dispatch(resetAuth());
      return;
    }
    const { email } = updateAuthToken(authResult, loginRequestSuccess);
    const userResponse = await getUserIdTypeDetails(email);
    const { userId, userType, partnerRegionsPrograms, ...userInfo } = userResponse;

    if (!userId) {
      const error = 'Your email is not registered. Please try with a different account.';
      store.dispatch(resetAuth());
      const returnTo = lastSignUp.email ? getSignUpUrl(lastSignUp, error) : `/login?error=${error}`;
      window.location.replace(returnTo);
      return;
    }
    if (lastSignUp.email && lastSignUp.email.toLowerCase() !== email.toLowerCase()) {
      const error = `Selected user account does not match signup email: ${lastSignUp.email}.`;
      store.dispatch(resetAuth());
      const returnTo = getSignUpUrl(lastSignUp, error);
      window.location.replace(returnTo);
      return;
    }
    if (lastSignUp.email) {
      notification.success({
        message: 'Success',
        description: 'Your new account setup successful.',
      });
      store.dispatch(verifySignUpSuccess());
    }

    const authUserData = {
      userInfo,
      userId,
      userType,
      loadingUser: false
    };

    if (userType === userTypes.PARTNER_ADMIN && partnerRegionsPrograms &&  Array.isArray(partnerRegionsPrograms)) {
      Object.assign(authUserData, { partnerAdminRegionsPrograms: partnerRegionsPrograms });
    }

    // Todo: clean not used
    const { data, included } = await userEventRoleService.getUserEventRolesByUserId(userId);
    const assignedEvents = included.filter(i => i.type === 'event');
    const userEventAssignments = formatEventAssignments(data);
    const eventIds = assignedEvents.map(e => e.id);
    const signedInUserEventRoles = formatUserEventRoleTypesList(included);

    Object.assign(authUserData, {
      userEventRoles: signedInUserEventRoles,
      eventIdsAssociatedWithUser: eventIds,
      userEventAssignments
    });

    await store.dispatch(setAuthUserData(authUserData));
    return {
      userType,
      userEventRoles: signedInUserEventRoles,
      hasAcceptedTerms: userInfo.hasAcceptedTerms
    };
  } catch (err) {
    handleAuthError(err.description);
  }
};
