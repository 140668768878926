import { Collapse, Button, Row, Col, Form} from 'antd';
import Icon from '@ant-design/icons';
import React, {useCallback, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import UpArrowBlue from 'shared/components/Icon/icons/UpArrowBlue';
import DownArrowBlue from 'shared/components/Icon/icons/DownArrowBlue';
import '../styles/index.scss';
import Select from 'shared/components/Select';
import Input from 'shared/components/Input';
import CloseCircleOutlined from '@ant-design/icons';
import {eventTimes} from 'shared/constants/eventTimes';
import BlockLoading from 'shared/components/Spinner/BlockLoading';
import { format24HoursToReadableString, formatDateToReadableStrTwo, formatReadableStringTo24Hours, generateEndTimeList, generateStartTimeList } from 'features/EventContainer/utils/formatTimeString';
import { validateBreakFields } from 'features/EventContainer/redux/actions/scheduleActions/scheduleSettings';
import _, { debounce } from 'lodash';
import { connect } from 'react-redux';


const {Option} = Select;

const EventTimeBlockerBlock = (props)=>{
  const { form, days, isLoadingDays, breaks, onBreakDelete, time_zone, use_24_hour_format} = props;
  const times_list = eventTimes(0, ['am', 'pm',], 15, use_24_hour_format);
  const days_list = (days||{}).data||[]

  const {t} = useTranslation();
  const [blocker, setBlocker] = useState([]);
  const [blockerId, setBlockerId] = useState(-1);

  useEffect(()=>{
    const days_break_set = {};
    ((days||{}).data||[]).map(d=>{
      const d_id = d.id;
      const d_attributes = d.attributes;
      const breaks = ((((d||{}).relationships||{}).breaks||{}).data||[]);
      breaks.forEach(b => {
        const b_id = b.id;
        const day_object = {id: d_id, ...d_attributes};
        if(b_id in days_break_set){
          days_break_set[b_id].push(day_object)
        }else{
          days_break_set[b_id] = [day_object];
        }
      });
    })
    const breaksData = _.cloneDeep([...((breaks||{}).data||[])]).map(b=>{
      const {id} = b;
      const breaksDays = days_break_set[id] || []
      b.attributes.break_days = breaksDays
      return b;
    })

    breaksData && breaksData.length && breaksData.length > 0 &&  setBlocker(breaksData);
  }, [breaks, days])

  const handleExpandIcon = (panelProps) => {
    const {isActive} = panelProps;
    if (isActive) {
      return <Icon component={UpArrowBlue}/>
    }

    return <Icon component={DownArrowBlue} />
  }

  const Header = (
    <div className="time-blocker-header">
    <Row type="flex" align="middle">
      <Col xs={24} sm={24} md={4}>
      <span className="title">{t('BreakPeriodHeader1Label')}</span>
      </Col>
      <Col xs={24} sm={24} md={8}>
      <span className="description">({t('BreakPeriodHeader2Label')})</span>
      </Col>
      </Row>
    </div>
  )

  const addNewBlocker = ()=>{
    const start_time = formatReadableStringTo24Hours('12:00 pm', time_zone);
    const end_time = formatReadableStringTo24Hours('1:00 pm', time_zone);
    const newBlock = _.cloneDeep([...blocker, {id: blockerId,attributes: {name: '', start_time, end_time}}]);
    setBlocker(newBlock);
    setBlockerId(blockerId=>blockerId-1);
    form.setFieldsValue({'breaks': newBlock})
  }

  return (
    <Collapse defaultActiveKey={['1']} expandIcon={handleExpandIcon} expandIconPosition={"right"} className="event-time-blocker" bordered={false}>
      <Collapse.Panel key="1" header={Header}>
        {isLoadingDays ? <BlockLoading loading={isLoadingDays} text="loading" /> : blocker.map(b => <BlockerItem times_list={times_list} use_24_hour_format={use_24_hour_format}  BlockerItem data={b} key={b.id} form={form} setBlocker={setBlocker} blocker={blocker} days_list={days_list} onBreakDelete={onBreakDelete} time_zone={time_zone}/>)}
      {isLoadingDays ? null : <Button type="link" onClick={addNewBlocker} className="add-another-blocker-link">+ {t('BreakPeriodHeaderLabel2')}</Button>}
      </Collapse.Panel>
    </Collapse>
  )
}

const BlockerItem = (props) =>{
  const {form, data, setBlocker, blocker, days_list, onBreakDelete, time_zone, times_list, use_24_hour_format} = props;
  const {id, attributes, error} = data||{};
  const {name, start_time, end_time, break_days} = attributes||{};
  const initial_block_days = (break_days||[]).map(b=>b.date);
  const [blockName, setBlockName] = useState(name||'')
  const [blockDays, setBlockDays] = useState(initial_block_days);
  const [blockStartTime, setBlockStartTime] = useState(start_time);
  const [blockEndTime, setBlockEndTime] = useState(end_time);
  const {t} = useTranslation();


  const deleteBlocker = async (id)=>{
    const currentBlcokers = _.cloneDeep(blocker).filter(b=>b.id !== id);
    if(id > 0 || id > '0') await onBreakDelete(id)
    setBlocker(currentBlcokers);
    form.setFieldsValue({'breaks': currentBlcokers})
  }

  const setBlockerName = (value)=>{
    setBlockName(value);
    debouncedUpdateBlocker('name', value);
  }

  const setBlockerDays = (value)=>{
    setBlockDays(value);
    const break_days = days_list.filter(d=>value.includes(((d||{}).attributes||{}).date)).map(d=>({...d.attributes, id: d.id}));
    debouncedUpdateBlocker('break_days', break_days);
  }

  const setBlockerTimeRange = (key, value)=>{
    const time = formatReadableStringTo24Hours(value, time_zone);
    if(key === 'start'){
      setBlockStartTime(time);
    }else if(key === 'end'){
      setBlockEndTime(time);
    }
    const key_field = key === 'start' ? 'start_time' : key === 'end' ? 'end_time' : '';
    debouncedUpdateBlocker(key_field, time)
  }
  
  const debouncedUpdateBlocker = useCallback(debounce((key, value)=>{
    const newBlocker = _.cloneDeep(blocker).map(b=>{
      if(b.id===id){
       b.attributes[key] = value;
      }
      const errorMessage = validateBreakFields(b);
      b.error = t(errorMessage);
      return b;
    })
    setBlocker(newBlocker)
    form.setFieldsValue({'breaks': newBlocker})
  }, 500),[id, blocker])

  const display_start = format24HoursToReadableString(blockStartTime, time_zone, use_24_hour_format);
  const display_end = format24HoursToReadableString(blockEndTime, time_zone, use_24_hour_format);
  const start_list = generateStartTimeList(display_end, times_list, use_24_hour_format);
  const end_list = generateEndTimeList(display_start, times_list, use_24_hour_format);

  return (
    <div key={id} >
    {error ? <div className="error-message-block">{error}</div> : null}
    <div className="timer-blocker-item-wrapper">
      <Button type='link' onClick={()=>deleteBlocker(id)} className="timer-blocker-delete">
        <CloseCircleOutlined style={{fontSize: 18}}/>
      </Button>
      <div className="blocker-name-wrapper">
        <label className="blocker-label">{t('BreakPeriodNameLabel')}</label>
        <Input placeholder={t('BreakPeriodNameHelptextLabel')} className="blocker-name-input" value={blockName} onChange={({target: {value}})=>setBlockerName(value)}/>
      </div>
      <div className="blocker-days-wrapper">
        <label className="blocker-label">{t('BreakPeriodDaysLabel')}</label>
        <Select showArrow placeholder={t('BreakPeriodDaysHelptextLabel')} mode="multiple" className="blocker-days-select" value={blockDays} onChange={(value)=>setBlockerDays(value)}>
          {days_list.map(d=>{
            const {date} = (d||{}).attributes||{};
            const displayedDate = formatDateToReadableStrTwo(date, time_zone);
            return (<Option key={date} value={date}>{displayedDate}</Option>)
          })}
        </Select>
      </div>
      <div className="blocker-time-range-wrapper">
        <label className="blocker-label">{t('BreakPeriodTimeRangeLabel')}</label>
        <div className="blocker-timer-select">
          <div className="select-start-list">
            <Select value={display_start?display_start:t('notSetLabel')} onChange={value => setBlockerTimeRange('start', value)}>
              {start_list.map(st=>{
                return (
                  <Option value={st} key={st}>{st}</Option>
                )
              })}
            </Select>
          </div>
          <div className="select-to">to</div>
          <div className="select-end-list">
            <Select value={display_end?display_end:t('notSetLabel')} onChange={value => setBlockerTimeRange('end', value)}>
              {end_list.map(et=>{
                return (
                  <Option value={et} key={et}>{et}</Option>
                )
              })}
            </Select>
          </div>
        </div>
      </div>
      <div className="block-separator" />
    </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { auth: { userInfo: { use_24_hour_format } } } = state;

  return {
    use_24_hour_format
  };
};

export default connect(mapStateToProps)(EventTimeBlockerBlock);