import { notification } from 'antd';
import * as types from '../../types/teamsTypes';
import * as eventRoleService from 'utils/services/eventRoles';
import { COACH_ID } from 'shared/constants/eventRoleTypes';
import { getAwardAssignmentByIdRequest, getAwardsOnlyByEventIdRequest, removeTeamFromAwardRequest } from 'utils/services/awards';
import { parallelRequest } from 'utils/query';

const deleteEventTeamBegin = () => {
  return {
    type: types.DELETE_EVENT_TEAM_BEGIN
  };
};

const deleteEventTeamError = () => {
  return {
    type: types.DELETE_EVENT_TEAM_ERROR
  };
};

const deleteEventTeamSuccess = () => {
  return {
    type: types.DELETE_EVENT_TEAM_SUCCESS
  };
};

const deleteAwardAssignmentAssociatedToTeam = (teamId, eventId) => {
  return async (dispatch) => {
    const award = await getAwardsOnlyByEventIdRequest(eventId);
    const assignment = ((award || {}).included || []).filter(i => i.type === 'award_assignment');
    const assignmentsId = assignment.map(a => a.id).filter(id => !!id);
    const getAssignmentRequests = assignmentsId.map(id => getAwardAssignmentByIdRequest(id));
    const assignmentDetails = await parallelRequest(getAssignmentRequests);

    const relatedAssignments = assignmentDetails.filter(assignmentData => {
      const assignmentTeam = ((((assignmentData || {}).data || {}).relationships || {}).team || {}).data || {};
      return assignmentTeam.id === teamId;
    });

    const deleteAssignmentIds = relatedAssignments.map(r => ((r || {}).data || {}).id).filter(id => !!id);
    const deleteAssignmentRequests = deleteAssignmentIds.map(id => removeTeamFromAwardRequest(id));
    await parallelRequest(deleteAssignmentRequests);
  }
}

const deleteEventTeam = ({ eventId, teamId, coachesId }) => {
  return async (dispatch) => {
    try {
      await dispatch(deleteEventTeamBegin());
      const filter = {
        'role.id': `eq:${COACH_ID}`,
        'user.id': `in:${coachesId.join(',')}`,
        'event.id': `eq:${eventId}`,
        'event.teams.id': `eq:${teamId}`
      }
      //const { data } = await eventRoleService.getAllUserEventRolesBy(filter);
      // if (data && data.length) {
      //   const assignmentIds = data.map(({ id }) => id);
      //   const teamPayload = { data: [{ type: 'team', id: teamId }] };
      // for (let i = 0; i < assignmentIds.length; i++) { 
      // await eventRoleService.removeTeamFromCoach(assignmentIds[i], teamPayload);
      await eventRoleService.removeTeam(eventId, teamId);
      // }
      await dispatch(deleteAwardAssignmentAssociatedToTeam(teamId, eventId));
      notification.success({
        message: 'Success',
        description: 'Team successfully removed from event.',
      });
      await dispatch(deleteEventTeamSuccess());
      // }
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error removing the team from event',
      });
      await dispatch(deleteEventTeamError());
      throw err;
    }
  };
};

export default deleteEventTeam;
