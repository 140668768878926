import * as types from './types/volunteerDashboardTypes';

const DEFAULT_EVENTS_STATE = {
  eventsList: [],
  eventsCount: 0,
  isFetchingEvents: false,
  isFetchingTeams: false,
  eventsError: false,
  teamsCount: 0,
  teamsData: [],
  teamsIncluded: [],
  teamsError: false
};

export default (state = DEFAULT_EVENTS_STATE, action) => {
  switch(action.type) {
    case types.FETCH_VOLUNTEER_DASHBOARD_EVENTS_BEGIN:
      return {
        ...state,
        eventsError: false,
        isFetchingEvents: true
      };
    case types.FETCH_VOLUNTEER_DASHBOARD_EVENTS_ERROR:
      return {
        ...state,
        isFetchingEvents: false,
        eventsError: true
      };
    case types.FETCH_VOLUNTEER_DASHBOARD_EVENTS_SUCCESS:
      return {
        ...state,
        isFetchingEvents: false,
        eventsError: false,
        eventsList: action.payload.eventsList,
        eventsCount: action.payload.count
      };
    case types.FETCH_VOLUNTEER_DASHBOARD_TEAMS_BEGIN:
      return {
        ...state,
        teamsError: false,
        isFetchingTeams: true
      };
    case types.FETCH_VOLUNTEER_DASHBOARD_TEAMS_ERROR:
      return {
        ...state,
        isFetchingTeams: false,
        teamsError: true
      };
    case types.FETCH_VOLUNTEER_DASHBOARD_TEAMS_SUCCESS:
      return {
        ...state,
        isFetchingTeams: false,
        teamsError: false,
        teamsData: action.payload.data,
        teamsIncluded: action.payload.included,
        teamsCount: action.payload.count
      };
    default:
      return state;
  }
};
