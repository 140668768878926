import React from 'react';
import PropTypes from 'prop-types';
import EventModal from 'features/EventContainer/components/EventModal';
import ModalContent from './TimestampModalContent';

const TimestampModal = props => {
  const {
    visible,
    onCancel,
    title,
    timestampId,
    time_zone,
  } = props;

  return (
    <EventModal
      visible={visible}
      onCancel={onCancel}
    >
      <ModalContent timestampId={timestampId} title={title} closeModal={onCancel} time_zone={time_zone}/> 
    </EventModal>
  );
};

TimestampModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  timestampId: PropTypes.string
};

TimestampModal.defaultProps = {
  visible: false,
  onCancel: () => {},
  title: '',
  timestampId: ''
};

export default TimestampModal;
