import * as types from '../types/volunteersTypes';

const DEFAULT_SELECTED_VOLUNTEER = {
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  phone: undefined,
  eventRoleTypes: []
}


const DEFAULT_VOLUNTEERS_STATE = {
  volunteers: [],
  count: 0,
  isFetchingEventVolunteers: true,
  volunteersIncludedResponse: [],
  fetchVolunteersError: false,
  addVolunteersManuallyList: [],
  isInvitingVolunteer: false,
  isAddingVolunteerToManualList: false,
  addVolunteersImportList: [],
  isAddingVolunteersToImportList: false,
  isDeletingVolunteerFromImportList: false,
  isDeletingVolunteerFromManualList: false,
  isAutocompleteVolunteerSelected: false,
  selectedVolunteer: DEFAULT_SELECTED_VOLUNTEER,
  newVolunteerInvited: false,
  isReinvitingEventVolunteerUser: false,
  csvImport: {
    successfullyInvited: [],
    notInvited: [],
  },
  isEditingVolunteerEventAssignments: false,
  isDeletingEventVolunteer: false
}
export default (state = DEFAULT_VOLUNTEERS_STATE, action) => {
  const {type} = action;
  switch(type) {
    case types.VOLUNTEER_ADD_TO_LIST_BEGIN:
      return {
        ...state,
        ...action.payload
      }
    case types.VOLUNTEER_ADD_TO_LIST_ERROR:
      return {
        ...state,
        ...action.payload
      }
    case types.VOLUNTEER_ADD_TO_LIST_SUCCESS:
      return {
        ...state,
        isAddingVolunteerToManualList: action.payload.isAddingVolunteerToManualList,
        addVolunteersManuallyList: [...state.addVolunteersManuallyList, action.payload.volunteer]
      }
    case types.DELETE_MANUAL_VOLUNTEER_BEGIN:
      return {
        ...state,
        isDeletingVolunteerFromManualList: true,
      }
    case types.DELETE_MANUAL_VOLUNTEER_ERROR:
      return {
        ...state,
        isDeletingVolunteerFromManualList: false,
      }
    case types.DELETE_MANUAL_VOLUNTEER_SUCCESS:
      return {
        ...state,
        isDeletingVolunteerFromManualList: false,
        addVolunteersManuallyList: action.payload.volunteers,
      }
    case types.IMPORT_VOLUNTEERS_BEGIN:
      return {
        ...state,
        isAddingVolunteersToImportList: true,
      }
    case types.IMPORT_VOLUNTEERS_SUCCESS:
      return {
        ...state,
        isAddingVolunteersToImportList: false,
        addVolunteersImportList: action.payload.addVolunteersImportList
      }
    case types.IMPORT_VOLUNTEERS_ERROR:
      return {
        ...state,
        isAddingVolunteersToImportList: false,
      }
    case types.DELETE_IMPORT_VOLUNTEER_BEGIN:
      return {
        ...state,
        isDeletingVolunteerFromImportList: true
      }
    case types.DELETE_IMPORT_VOLUNTEER_ERROR:
      return {
        ...state,
        isDeletingVolunteerFromImportList: false,
        addVolunteersImportList: action.payload.volunteers
      }
    case types.DELETE_IMPORT_VOLUNTEER_SUCCESS:
      return {
        ...state,
        isDeletingVolunteerFromImportList: false,
        addVolunteersImportList: action.payload.volunteers
      }
    case types.SET_SELECTED_AUTOCOMPLETE_VOLUNTEER:
      return {
        ...state,
        isAutocompleteVolunteerSelected: true,
        selectedVolunteer: {
          ...action.payload.selectedVolunteer,
          eventRoleTypes: [],
        }
      }
    case types.RESET_SELECTED_AUTOCOMPLETE_VOLUNTEER:
      return {
        ...state,
        isAutocompleteVolunteerSelected: false,
        selectedVolunteer: DEFAULT_SELECTED_VOLUNTEER
      }
    case types.UPDATE_SELECTED_VOLUNTEER:
      return {
        ...state,
        selectedVolunteer: {
          ...state.selectedVolunteer,
          [action.payload.fieldName]: action.payload.value
        }
      }
    case types.RESET_MANUAL_VOLUNTEERS_LIST:
      return {
        ...state,
        addVolunteersManuallyList: [],
      }
    case types.GET_EVENT_VOLUNTEERS_BEGIN:
      return {
        ...state,
        isFetchingEventVolunteers: true,
        fetchVolunteersError: false
      }
    case types.GET_EVENT_VOLUNTEERS_ERROR:
      return {
        ...state,
        isFetchingEventVolunteers: false,
        fetchVolunteersError: true,
        volunteersIncludedResponse: []
      }
    case types.GET_EVENT_VOLUNTEERS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetchingEventVolunteers: false,
        fetchVolunteersError: false,
        newVolunteerInvited: false
      }
    case types.VOLUNTEER_INVITE_BEGIN:
      return {
        ...state,
        isInvitingVolunteer: true
      }
    case types.VOLUNTEER_INVITE_ERROR:
      return {
        ...state,
        isInvitingVolunteer: false
      }
    case types.VOLUNTEER_INVITE_SUCCESS:
      return {
        ...state,
        isInvitingVolunteer: false,
        newVolunteerInvited: true
      }
    case types.RESET_ADD_VOLUNTEER_MODAL:
      return {
        ...state,
        addVolunteersManuallyList: [],
        isInvitingVolunteer: false,
        isAddingVolunteerToManualList: false,
        addVolunteersImportList: [],
        isAddingVolunteersToImportList: false,
        isDeletingVolunteerFromImportList: false,
        isDeletingVolunteerFromManualList: false,
        isAutocompleteVolunteerSelected: false,
        selectedVolunteer: DEFAULT_SELECTED_VOLUNTEER,
      }
    case types.REINVITE_EVENT_VOLUNTEER_USER_BEGIN:
      return {
        ...state,
        isReinvitingEventVolunteerUser: true
      }
    case types.REINVITE_EVENT_VOLUNTEER_USER_ERROR:
      return {
        ...state,
        isReinvitingEventVolunteerUser: false
      }
    case types.REINVITE_EVENT_VOLUNTEER_USER_SUCCESS:
      return {
        ...state,
        isReinvitingEventVolunteerUser: false,
        volunteersIncludedResponse: action.payload.updatedIncludedList
      }
    case types.SET_EVENT_IMPORT_VOLUNTEERS_INVITED:
      return {
        ...state,
        csvImport: {
          ...state.csvImport,
          successfullyInvited: action.payload.successfullyInvited,
          notInvited: action.payload.notInvited
        }
      }
    case types.EDIT_EVENT_VOLUNTEER_BEGIN:
      return {
        ...state,
        isEditingVolunteerEventAssignments: true
      }
    case types.EDIT_EVENT_VOLUNTEER_ERROR:
      return {
        ...state,
        isEditingVolunteerEventAssignments: false
      }
    case types.EDIT_VOLUNTEER_INFO_SUCCESS:
      return {
        ...state,
        newVolunteerInvited: true
      }
    case types.EDIT_EVENT_VOLUNTEER_SUCCESS:
      return {
        ...state,
        isEditingVolunteerEventAssignments: false,
        newVolunteerInvited: true
      }
    case types.DELETE_EVENT_VOLUNTEER_BEGIN:
      return {
        ...state,
        isDeletingEventVolunteer: true
      }
    case types.DELETE_EVENT_VOLUNTEER_ERROR:
      return {
        ...state,
        isDeletingEventVolunteer: false
      }
    case types.DELETE_EVENT_VOLUNTEER_SUCCESS:
      return {
        ...state,
        newVolunteerInvited: true,
        isDeletingEventVolunteer: false
      }
    default:
      return state
  }
}
