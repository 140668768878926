import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as teamService from 'utils/services/teams';

import Input from 'shared/components/Input';
import Dropdown from 'shared/components/FormDropdown';
import { getPageFilter, initialPage } from 'shared/components/Pagination';
import AutoComplete from 'shared/components/FormAutoComplete';
import {
    handleResetSelectedTeamDetails,
    handleAddNewTeamToEvent,
    handleSelectedTeam,
    handleGetEventAllTeams
} from 'features/EventContainer/redux/actions/teams';
import {
    fetchRegionsBy,
    fetchCountries,
    updateCountries,
    updateRegions
} from 'features/AdminDashboard/redux/actions/programsActions';
import '../../../styles/CreateNewTeamForm.scss';
import { handleAddTeamToCoach, setAddedTeamList, handleAddTeam } from 'features/EventContainer/redux/actions/teams';
import TeamOption from './TeamOption';
import { notification, Form, Button } from 'antd';

const { Option } = Dropdown;
const FormItem = Form.Item;

const CreateNewOnlyTeamForm = (props) => {
    const {
        eventProgramId,
        id,
        regions,
        countries,
        saveNewTeam,
        onCancel,
        isFetchingCountries,
        isFetchingRegions,
        selectedTeamDetails,
        isInvitingCoach,
        handleAddTeam,
        teams,
        onTeamAdded,
        eventTeamsListAll, 
        isFetchingEventAllTeams
    } = props;
    const { t } = useTranslation();

    const isTeamNameTouched = Boolean(form.getFieldValue('teamName'));
    const isTeamNumberTouched = Boolean(form.getFieldValue('teamNumber'));
    const isCountryTouched = Boolean(form.getFieldValue('country'));
    const isRegionTouched = Boolean(form.getFieldValue('region'));
    const [teamName, setTeamName] = useState("");
    const [teamResults, setTeamResults] = useState([]);
    const [addingToList, setAddingToList] = useState(false);
    const [allEvents, setAllEvents] = useState([])
    const [form] = Form.useForm();

    const [teamManualSelected, setTeamManualSelected] = useState(false);

    const isFormFilled = teamName != "" && isTeamNumberTouched && isRegionTouched;

    const handleCustomerSearch = async (input) => {
        const pageSize = 5;
        const formattedTeams = await teamService.getTeamsLikeName(input, pageSize);
        setTeamResults(formattedTeams);

        if (props.onSearch !== undefined && typeof props.onSearch == 'function') {
            props.onSearch(input);
        }
    };


    const handleSearchRegions = (input) => {
        if (!input) return;
        props.fetchRegionsBy(eventProgramId, input);
    };

    const createNewTeam = (e) => {
        if (e && 'preventDefault' in e) {
            e.preventDefault();
        }
        
        form.validateFields().then(async (values, err) => {
            if (err) {
                return;
            }
            saveNewTeam(values, !teamManualSelected, onTeamAdded, t('Team already added for this event'));
        });
    }

    const isTeamAlreadyAssigned = (team) => {
        return team.id == selectedTeamDetails.teamId
    }

    const addToList = async () => {

        if (!teamManualSelected) {
            let cid = form.getFieldValue('country');
            let rid = form.getFieldValue('region');
            const teamPayload = {
                country: cid,
                id: "",
                region: rid,
                shortName: teamName,
                teamName: teamName,
                teamNumber: form.getFieldValue('teamNumber')
            }

            handleAddTeam(teamPayload)
            form.setFieldsValue({
                teamName: "",
                teamNumber: "",
                country: "",
                region: "",
            })
        } else {
            // console.log(eventTeamsListAll);
            let teamDuplicate = eventTeamsListAll?.some(isTeamAlreadyAssigned)
            

            if (teamDuplicate) {
                notification.error({
                    message: 'Error',
                    description: 'Team already added for this event',
                });
            } else {
                props.setAddedTeamList();
                form.setFieldsValue({
                    teamName: "",
                    teamNumber: "",
                    country: "",
                    region: "",
                })
            }
        }
    }

    const loadAllTeams = async () => {
        setAddingToList(true)
        let events = await handleGetEventAllTeams(id, getPageFilter(1, 500), '');
        setAllEvents(events)
        setAddingToList(false)
    }

    useEffect(() => {
        // loadAllTeams();
    }, [])

    useEffect(() => {
        if (selectedTeamDetails !== undefined) {
            let countryOfSelectedTeam = selectedTeamDetails?.getCountry()
            props.updateCountries([countryOfSelectedTeam]);

            let regionOfSelectedTeam = selectedTeamDetails?.getRegion()
            props.updateRegions([regionOfSelectedTeam], eventProgramId);
        }

    }, [selectedTeamDetails])

    const onSelectTeam = (v) => {
        props.selectTeam(v, (e) => {
            setTeamName(e.teamName);
            setTeamManualSelected(true);
            form.setFieldsValue({
                teamName: e.teamName,
                teamNumber: e.teamNumber,
                country: e.countryId,
                region: e.regionId,
            })
        });
    }
    const handleTeamSearch = (e) => {
        setTeamName(e)
        form.setFieldsValue({ "teamName": e });
        setTeamManualSelected(false)
        handleCustomerSearch(e);
    }

    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    }

    return (
        <div className="additional-left-space create-team-container-col custom-disable-form">
            <div className={`create-team ${isFormFilled ? 'create-team--complete' : 'create-team--incomplete'}`}>
                <h3 className="create-team__heading">{t('Add Team')}:</h3>
                <Form hideRequiredMark onFinish={createNewTeam} onFinishFailed={onFinishFailed} form={form} name='CreateNewOnlyTeamForm' initialValues={{teamName: selectedTeamDetails?.teamName, teamNumber: selectedTeamDetails?.teamNumber, country: selectedTeamDetails?.countryId, region: selectedTeamDetails?.regionId}}>
                    <FormItem name='teamName' label={<span className={isTeamNameTouched ? 'form__label--visible' : 'form__label--invisible'}>{t('teamNameLabel')}</span>} rules={[{ required: true, message: 'Enter Team Name.' }]}>
                            {/* <Input size='large' placeholder={t('teamNameLabel')} /> */}
                            <AutoComplete
                                onSearch={handleTeamSearch}
                                onSelect={onSelectTeam}
                                onCancel={onCancel}
                                placeholder={t('teamNameLabel')}
                                dataSource={teamResults.map((tr) => (
                                    <AutoComplete.Option key={tr.id} value={tr.id} text={tr.teamName}>
                                        <TeamOption
                                            teamName={tr.teamName}
                                            teamNumber={tr.teamNumber}
                                            region={tr.regionName}
                                            country={tr.countryName}
                                        />
                                    </AutoComplete.Option>
                                ))}
                            >
                            </AutoComplete>
                    </FormItem>

                    <FormItem name='teamNumber' label={<span className={isTeamNumberTouched ? 'form__label--visible' : 'form__label--invisible'}>Team Number</span>} rules={[{ required: true, message: 'Enter Team Number.' }]}>
                        <Input disabled={teamManualSelected} size='large' placeholder={t('teamNumberLabel')} />
                    </FormItem>
                    <FormItem name='country' label={<span className={isCountryTouched ? 'form__label--visible' : 'form__label--invisible'}>Country</span>} rules={[{ required: true, message: 'Select Country.' }]}>
                            <Dropdown
                                allowClear
                                showSearch
                                filterOption={false}
                                placeholder='Country'
                                onSearch={props.fetchCountries}
                                popUpContainerElementId='country-autocomplete'
                                loading={isFetchingCountries}
                                disabled={teamManualSelected}
                            >
                                {countries.map(c => (
                                    <Option key={c.id} value={c.id} text={c.name}>
                                        {c.name}
                                    </Option>
                                ))}
                            </Dropdown>
                    </FormItem>
                    <FormItem name='region' label={<span className={isRegionTouched ? 'form__label--visible' : 'form__label--invisible'}>Region</span>} rules={[{ required: true, message: t('selectRegionLabel') }]}>
                            <Dropdown
                                allowClear
                                showSearch
                                filterOption={false}
                                placeholder='Region'
                                popUpContainerElementId='region-autocomplete'
                                onSearch={handleSearchRegions}
                                loading={isFetchingRegions}
                                disabled={teamManualSelected}
                            >
                                {regions.map(({ id, name }) => (
                                    <Option key={id} value={id} text={name}>{name}</Option>
                                ))}
                            </Dropdown>
                    </FormItem>
                </Form>

            </div>
            <div className='button-group-team m-t-20'>
                <div>
                    <Button
                        loading={isInvitingCoach || isFetchingEventAllTeams}
                        disabled={!isFormFilled || isInvitingCoach || isFetchingEventAllTeams}
                        size='large' additionalClassNames='btn--100' onClick={createNewTeam}>{t('Add Now')}
                    </Button>
                </div>
                <div className='m-l-10 m-r-10'>
                    or
                </div>
                <div>
                    <Button
                        loading={isInvitingCoach || isInvitingCoach || isFetchingEventAllTeams}
                        disabled={!isFormFilled || isFetchingEventAllTeams}
                        type='default' styleType='secondary' size='large' additionalClassNames='btn--100' onClick={addToList}>{t('Add to List')}
                    </Button>
                </div>

            </div>
        </div>
    );
};

CreateNewOnlyTeamForm.propTypes = {
    form: PropTypes.object.isRequired,
    saveNewTeam: PropTypes.func.isRequired,
    eventProgramId: PropTypes.string,
    fetchRegionsBy: PropTypes.func.isRequired,
    fetchCountries: PropTypes.func.isRequired,
    updateCountries: PropTypes.func.isRequired,
    updateRegions: PropTypes.func.isRequired,
    addTeamToCoach: PropTypes.func.isRequired,
    handleAddTeam: PropTypes.func.isRequired
};

CreateNewOnlyTeamForm.defaultProps = {
    updateCountries: () => { },
    updateRegions: () => { },
    handleAddTeam: () => { }
};

const mapDispatchToProps = {
    getEventTeams: handleGetEventAllTeams,
    saveNewTeam: handleAddNewTeamToEvent,
    fetchRegionsBy,
    fetchCountries,
    updateCountries,
    updateRegions,
    addTeamToCoach: handleAddTeamToCoach,
    handleAddTeam,
    setAddedTeamList,
    handleResetSelectedTeamDetails,
    selectTeam: handleSelectedTeam,
};

const mapStateToProps = (state) => {
    const {
        event: {
            eventDetails: {
                eventProgramId,
                id
            },
            // eventTeams: { selectedCoach: { teams } }
            eventTeams: { eventTeamsListAll, isFetchingEventAllTeams, selectedTeamDetails, selectedCoach: { teams }, isInvitingCoach },
        },
        adminBoard: {
            programs: {
                countries, regions, isFetchingRegions, isFetchingCountries
            }
        }
    } = state;
    return {
        eventProgramId,
        id,
        countries,
        regions,
        isFetchingRegions,
        isFetchingCountries,
        selectedTeamDetails,
        teams,
        isInvitingCoach,
        eventTeamsListAll,
        isFetchingEventAllTeams
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateNewOnlyTeamForm);
