import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import ScreenLoading from 'shared/components/Spinner/ScreenLoading';
import { PrivacyPDFs } from '../../../../../shared/externalFiles/index';
import './pdf.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const PrivacyPolicySections = () => {
  const [numPages, setNumPages] = useState(null);
  const [padUrl, setPdfUrl] = useState('');

  const onDocumentLoaded = ({numPages:page})=>{
    setNumPages(page);
  }

  const selectedLanguage = localStorage.getItem('i18nextLng');

  useEffect(()=>{
    const Root = 'https://first-prod-s3.s3.eu-central-1.amazonaws.com/miscellaneous/Legal/Privacy+Policy/';
    let pdfUrl = PrivacyPDFs[selectedLanguage];
    if(!pdfUrl) pdfUrl = PrivacyPDFs.en;
    setPdfUrl(Root + pdfUrl);
    setNumPages(1);
  },[selectedLanguage])

  return (
    <div className='Example__container__document'>
      <Document
        file={padUrl}
        loading={<ScreenLoading loading title="Loading PDF" subTitle=" " />}
        onLoadSuccess={onDocumentLoaded}
        externalLinkTarget='_blank'
      >
        {Array.from(
          new Array(numPages),
          (el, index) => (
            <Page
              style={{fontSize: '20px;'}}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ),
        )}
      </Document>
    </div>
  );
};

export default PrivacyPolicySections;
