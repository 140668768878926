import React from 'react';
import PropTypes from 'prop-types';
import { convertTimeToTimeAndDateFormat } from 'utils/dateTimeUtils';
import moment from 'moment';
import { connect } from 'react-redux';

const metaTextClassName = 'paragraph--medium paragraph--charcoal paragraph--normal'

const paragraphClassName = 'paragraph--xlarge paragraph--charcoal paragraph--normal'


const JudgingFeedbackComment = props => {
  const {
    fullName,
    createdAt,
    comment,
    use_24_hour_format
  } = props;

  const formatDateTime = (date, is24Hour) => {
    return moment(date).format(is24Hour ? 'HH:mm M/D/YYYY' : 'h:mma M/D/YYYY')
  }

  return (
    <div className='c-fll-feedback__comment-content'>
      <div className='m-b-12 judging-comments-meta-wrapper'>
        <p className={metaTextClassName}>{formatDateTime(createdAt, use_24_hour_format)}</p>
      </div>
      <div>
        <p className={paragraphClassName}>{comment}</p>
      </div>
    </div>
  );
};

JudgingFeedbackComment.propTypes = {
  fullName: PropTypes.string.isRequired,
  comment: PropTypes.string,
  dateTime: PropTypes.string,
};

JudgingFeedbackComment.defaultProps = {
  comment: '',
  dateTime: '',
}


const mapStateToProps = (state) => {
  const {
    auth: { userInfo: { use_24_hour_format } } } = state;
  return {
    use_24_hour_format
  };
};

export default connect(mapStateToProps)(JudgingFeedbackComment);