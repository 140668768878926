import React from 'react';
import '../../../../../../features/PanelManagement/styles/MissingJudge.scss';
import {AlertOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';

const MissingJudge = (props) => {
  const { is_FLLC_event } = props
  return (
    <div className='missing-panel-judge'>
      <AlertOutlined className='missing-panel-judge__icon' />
      <p className='paragraph--red paragraph--medium missing-panel-judge__text'>
        {is_FLLC_event ? 'At least one judge per panel must be assigned' : 'At least one reviewer per panel must be assigned'} 
      </p>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { event: { eventDetails: { is_FLLC_event } } } = state;

  return {
    is_FLLC_event
  }
}

export default connect(mapStateToProps)(MissingJudge);