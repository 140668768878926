import { Col, Row } from 'antd';
import React from 'react';
import Checkbox from 'shared/components/Checkbox';
import '../../../styles/judgingRubric/FLLCOptionalAwards.scss';
import { connect } from 'react-redux';
import { updateJudgingRubric } from 'features/EventOverviewContainer/redux/actions/judgingRubric';

const OptionalAwardsNomination = (props)=>{
  const {breakthrough_award, rising_all_star_award, motivate_award, updateRubric,  is_published, is_publishable} = props;

  const handleChange = (field, e) =>{
    const {target: {checked}} = e;
    updateRubric(field, checked)
  }

  return (
    <div className="optional-awards">
      <Row>
        <Col md={6} sm={24}><span className="label">Award Eligibility:</span></Col>
        <Col md={6} sm={24}>
          <Checkbox disabled={ is_published || is_publishable } checked={breakthrough_award} onChange={e => handleChange('breakthrough_award',e)}><span className="awards-label">Breakthrough Award</span></Checkbox>
        </Col>
        <Col md={6} sm={24}>
          <Checkbox disabled={ is_published || is_publishable } checked={rising_all_star_award} onChange={e => handleChange('rising_all_star_award',e)}><span className="awards-label">Rising All-Star Award</span></Checkbox>
        </Col>
        <Col md={6} sm={24}>
          <Checkbox disabled={ is_published || is_publishable } checked={motivate_award} onChange={e => handleChange('motivate_award',e)}><span className="awards-label">Motivate Award</span></Checkbox>
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = state =>{
  const { judgingRubric: {fllChallengeRubric: { breakthrough_award, rising_all_star_award, motivate_award, is_published, is_publishable}}} = state;
  return {breakthrough_award, rising_all_star_award, motivate_award, is_published, is_publishable}
}

const mapDispatchToProps = {
  updateRubric: updateJudgingRubric
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionalAwardsNomination);