import * as types from '../types/judgingRubric';

const DEFAULT_FLL_CHALLENGE_RUBRIC = {
  "discovery_ip_cv1": undefined,
  "teamwork_ip_cv2": undefined,
  "innovation_ip_cv3": undefined,
  "impact_ip_cv4": undefined,
  "fun_ip_cv5": undefined,
  "discovery_rd_cv6": undefined,
  "inclusion_rd_cv7": undefined,
  "innovation_rd_cv8": undefined,
  "impact_rd_cv9": undefined,
  "fun_rd_cv10": undefined,
  "innovation_project_1_identify_problem": undefined,
  "innovation_project_1_identify_research": undefined,
  "innovation_project_2_design_planning": undefined,
  "innovation_project_2_design_process": undefined,
  "innovation_project_3_create_innovation": undefined,
  "innovation_project_3_create_model_drawing": undefined,
  "innovation_project_4_iterate_share": undefined,
  "innovation_project_4_iterate_improvements": undefined,
  "innovation_project_5_communicate_solution": undefined,
  "innovation_project_5_communicate_presentation": undefined,
  "innovation_project_1_identify_problem_justification": undefined,
  "innovation_project_1_identify_research_justification": undefined,
  "innovation_project_2_design_planning_justification": undefined,
  "innovation_project_2_design_process_justification": undefined,
  "innovation_project_3_create_innovation_justification": undefined,
  "innovation_project_3_create_model_drawing_justification": undefined,
  "innovation_project_4_iterate_share_justification": undefined,
  "innovation_project_4_iterate_improvements_justification": undefined,
  "innovation_project_5_communicate_solution_justification": undefined,
  "innovation_project_5_communicate_presentation_justification": undefined,
  "robot_design_1_identify_mission": undefined,
  "robot_design_1_identify_building": undefined,
  "robot_design_2_design_contribution": undefined,
  "robot_design_2_design_skills": undefined,
  "robot_design_3_create_purpose": undefined,
  "robot_design_3_create_code_sensor": undefined,
  "robot_design_4_iterate_testing": undefined,
  "robot_design_4_iterate_improvements": undefined,
  "robot_design_5_communicate_explanation": undefined,
  "robot_design_5_communicate_enthusiasm": undefined,
  "robot_design_1_identify_mission_justification": undefined,
  "robot_design_1_identify_building_justification": undefined,
  "robot_design_2_design_contribution_justification": undefined,
  "robot_design_2_design_skills_justification": undefined,
  "robot_design_3_create_purpose_justification": undefined,
  "robot_design_3_create_code_sensor_justification": undefined,
  "robot_design_4_iterate_testing_justification": undefined,
  "robot_design_4_iterate_improvements_justification": undefined,
  "robot_design_5_communicate_explanation_justification": undefined,
  "robot_design_5_communicate_enthusiasm_justification": undefined,
  "is_published": undefined,
  "is_publishable": undefined
}

const DEFAULT_FLL_E_CHALLENGE_RUBRIC = {
  challenge_solution_1: undefined,
  challenge_solution_2: undefined,
  coding_1: undefined,
  coding_2: undefined,
  core_value_1: undefined,
  core_value_2: undefined,
  team_model_1: undefined,
  team_model_2: undefined,
  team_poster_1: undefined,
  team_poster_2: undefined,
  is_publishable: undefined,
  is_published: undefined,
}


const DEFAULT_EVENT_STATE = {
  eventLocation: null,
  eventId: null,
  eventDate: null,
  eventName: null,
  programId: null
}

const DEFAULT_TEAM_STATE = {
  id: null,
  regionName: '',
  countryName: '',
  teamNumber: '',
  teamName: ''
}

const DEFAULT_SESSION_STATE = {
  sessionTypeName: '',
  sessionStatusId: null,
  sessionStatusName: ''
}

const DEFAULT_JUDGING_RUBRIC_TEMPLATE = {
  name: '',
  documentTypeId: null,
  notes: '',
  url: ''
}

const DEFAULT_UPLOADED_JUDGING_RUBRIC = {
  contentType: null,
  eventFileId: null,
  fileName: '',
}

const DEFAULT_STATE = {
  eventDetails: DEFAULT_EVENT_STATE,
  isFetchingEvent: true,
  teamDetails: DEFAULT_TEAM_STATE,
  sessionFiles: [],
  sessionDetails: DEFAULT_SESSION_STATE,
  isFetchingSession: false,
  fllChallengeRubric: DEFAULT_FLL_CHALLENGE_RUBRIC,
  fllEChallengeRubric: DEFAULT_FLL_E_CHALLENGE_RUBRIC,
  isLoadingRubric: false,
  isFLLChallengeRubricNew: true,
  judgingId: null,
  judgingRubricTemplate: DEFAULT_JUDGING_RUBRIC_TEMPLATE,
  isCreatingJudgingComment: false,
  isLoadingSessionRubric: false,
  uploadedRubricFile: DEFAULT_UPLOADED_JUDGING_RUBRIC,
  isRubricFileUploaded: false,
  isDeletingUploadedRubric: false,
  isUploadingRubricFile: false,
  judgingRubricComments: [],
  isFetchingRubricComments: false,
  judgingRubricCommentCreators: [],
  isDeletingJudgingRubricComment: false,
  isUploadedRubricPublished: false,
  isUploadedRubricPublishable: false,
  isUpdatingUploadedRubric: false,
  isFetchingRubricEvidenceDocuments: false,
  judgingRubricEvidenceDocuments: [],
  isUpdatingFllChallengeRubric: false,
  uploadRetry: 0,
}

export default (state = DEFAULT_STATE, action) => {
  const { type } = action;
  switch (type) {
    case types.GET_RUBRIC_EVENT_DETAILS_BEGIN:
      return {
        ...state,
        isFetchingEvent: true
      }
    case types.GET_RUBRIC_EVENT_DETAILS_ERROR:
      return {
        ...state,
        isFetchingEvent: false
      }
    case types.GET_RUBRIC_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingEvent: false,
        eventDetails: {
          ...DEFAULT_EVENT_STATE,
          ...action.payload.eventDetails
        }
      }
    case types.SET_RUBRIC_TEAM_DETAILS:
      return {
        ...state,
        teamDetails: {
          ...DEFAULT_TEAM_STATE,
          ...action.payload.teamDetails
        }
      }
    case types.GET_RUBRIC_JUDGING_SESSION_BEGIN:
      return {
        ...state,
        isFetchingSession: true,
      }
    case types.GET_RUBRIC_JUDGING_SESSION_ERROR:
      return {
        ...state,
        isFetchingSession: true,
        sessionDetails: DEFAULT_SESSION_STATE
      }
    case types.GET_RUBRIC_JUDGING_SESSION_SUCCESS:
      return {
        ...state,
        isFetchingSession: false,
        sessionDetails: {
          ...DEFAULT_SESSION_STATE,
          ...action.payload.sessionDetails
        }
      }
    case types.UPDATE_FLL_CHALLENGE_JUDGING_RUBRIC:
      return {
        ...state,
        fllChallengeRubric: {
          ...state.fllChallengeRubric,
          [action.payload.field]: action.payload.value
        }
      }
    case types.UPDATE_FLL_E_CHALLENGE_JUDGING_RUBRIC:
      return {
        ...state,
        fllEChallengeRubric: {
          ...state.fllEChallengeRubric,
          [action.payload.field]: action.payload.value
        }
      }
    case types.GET_FLL_CHALLENGE_JUDGING_RUBRIC_BEGIN:
      return {
        ...state,
        isLoadingRubric: true
      }
    case types.GET_FLL_CHALLENGE_JUDGING_RUBRIC_ERROR:
      return {
        ...state,
        isLoadingRubric: false,
        fllChallengeRubric: DEFAULT_FLL_CHALLENGE_RUBRIC
      }
    case types.GET_FLL_CHALLENGE_JUDGING_RUBRIC_SUCCESS:
      return {
        ...state,
        isLoadingRubric: false,
        fllChallengeRubric: {
          ...DEFAULT_FLL_CHALLENGE_RUBRIC,
          ...action.payload.fllChallengeRubric
        }
      }
    case types.GET_FLL_E_CHALLENGE_JUDGING_RUBRIC_SUCCESS:
      return {
        ...state,
        isLoadingRubric: false,
        fllEChallengeRubric: {
          ...DEFAULT_FLL_E_CHALLENGE_RUBRIC,
          ...action.payload.fllEChallengeRubric
        }
      }
    case types.SET_IS_FLL_CHALLENGE_RUBRIC_NEW:
      return {
        ...state,
        isFLLChallengeRubricNew: action.payload.isNew
      }
    case types.SET_FLL_CHALLENGE_RUBRIC_JUDGING_ID:
      return {
        ...state,
        judgingId: action.payload.judgingId
      }
    case types.GET_JUDGING_SESSION_RUBRIC_TEMPLATE_BEGIN:
      return {
        ...state,
        isLoadingSessionRubric: true,
      }
    case types.GET_JUDGING_SESSION_RUBRIC_TEMPLATE_ERROR:
      return {
        ...state,
        isLoadingSessionRubric: false,
        judgingRubricTemplate: DEFAULT_JUDGING_RUBRIC_TEMPLATE
      }
    case types.GET_JUDGING_SESSION_RUBRIC_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoadingSessionRubric: false,
        judgingRubricTemplate: {
          ...DEFAULT_JUDGING_RUBRIC_TEMPLATE,
          ...action.payload.judgingRubricTemplate
        }
      }
    case types.CREATE_JUDGING_RUBRIC_COMMENT_BEGIN:
      return {
        ...state,
        isCreatingJudgingComment: true
      }
    case types.CREATE_JUDGING_RUBRIC_COMMENT_ERROR:
      return {
        ...state,
        isCreatingJudgingComment: false
      }
    case types.CREATE_JUDGING_RUBRIC_COMMENT_SUCCESS:
      return {
        ...state,
        isCreatingJudgingComment: false,
        judgingRubricComments: action.payload.judgingRubricComments
      }
    case types.EDIT_JUDGING_RUBRIC_COMMENT_BEGIN:
      return {
        ...state,
        isCreatingJudgingComment: true
      }
    case types.EDIT_JUDGING_RUBRIC_COMMENT_ERROR:
      return {
        ...state,
        isCreatingJudgingComment: false,
        judgingRubricComments: action.payload.judgingRubricComments
      }
    case types.EDIT_JUDGING_RUBRIC_COMMENT_SUCCESS:
      return {
        ...state,
        isCreatingJudgingComment: false,
        judgingRubricComments: action.payload.judgingRubricComments
      }
    case types.SET_UPLOADED_RUBRIC_FILE:
      return {
        ...state,
        uploadedRubricFile: {
          ...DEFAULT_UPLOADED_JUDGING_RUBRIC,
          ...action.payload.uploadedRubricFile
        },
        isRubricFileUploaded: action.payload.isFileUploaded
      }
    case types.DELETE_UPLOADED_RUBRIC_FILE_BEGIN:
      return {
        ...state,
        isDeletingUploadedRubric: true
      }
    case types.DELETE_UPLOADED_RUBRIC_FILE_ERROR:
      return {
        ...state,
        isDeletingUploadedRubric: false
      }
    case types.DELETE_UPLOADED_RUBRIC_FILE_SUCCESS:
      return {
        ...state,
        uploadedRubricFile: DEFAULT_UPLOADED_JUDGING_RUBRIC,
        isRubricFileUploaded: false,
        isDeletingUploadedRubric: false
      }
    case types.UPLOAD_JUDGING_SESSION_RUBRIC_BEGIN:
      return {
        ...state,
        isUploadingRubricFile: true,
        uploadRetry: 0,
      }
    case types.UPLOAD_JUDGING_SESSION_RUBRIC_ERROR:
      return {
        ...state,
        isUploadingRubricFile: false,
        uploadRetry: 0,
      }
    case types.UPLOAD_JUDGING_SESSION_RUBRIC_SUCCESS:
      return {
        ...state,
        isUploadingRubricFile: false,
        uploadedRubricFile: {
          ...DEFAULT_UPLOADED_JUDGING_RUBRIC,
          ...action.payload.uploadedRubricFile
        },
        isRubricFileUploaded: action.payload.isFileUploaded,
        uploadRetry: 0,
      }
    case types.UPLOAD_JUDGING_SESSION_RUBRIC_RETRY:
      return {
        ...state,
        uploadRetry: action.payload.uploadRetry
      }
    case types.GET_JUDGING_RUBRIC_COMMENTS_BEGIN:
      return {
        ...state,
        isFetchingRubricComments: true
      }
    case types.GET_JUDGING_RUBRIC_COMMENTS_ERROR:
      return {
        ...state,
        isFetchingRubricComments: false
      }
    case types.GET_JUDGING_RUBRIC_COMMENTS_SUCCESS:
      return {
        ...state,
        isFetchingRubricComments: false,
        judgingRubricComments: action.payload.judgeComments
      }
    case types.DELETE_JUDGING_RUBRIC_COMMENT_BEGIN:
      return {
        ...state,
        isDeletingJudgingRubricComment: true
      }
    case types.DELETE_JUDGING_RUBRIC_COMMENT_ERROR:
      return {
        ...state,
        isDeletingJudgingRubricComment: false
      }
    case types.DELETE_JUDGING_RUBRIC_COMMENT_SUCCESS:
      return {
        ...state,
        isDeletingJudgingRubricComment: false,
        judgingRubricComments: action.payload.judgeComments
      }
    case types.SET_IS_UPLOADED_JUDGING_RUBRIC_PUBLISHABLE:
      return {
        ...state,
        isUploadedRubricPublishable: action.payload.isPublishable
      }
    case types.SET_IS_UPLOADED_JUDGING_RUBRIC_PUBLISHED:
      return {
        ...state,
        isUploadedRubricPublished: action.payload.isPublished
      }
    case types.UPDATE_UPLOADED_JUDGING_SESSION_RUBRIC_BEGIN:
      return {
        ...state,
        isUpdatingUploadedRubric: true
      }
    case types.UPDATE_UPLOADED_JUDGING_SESSION_RUBRIC_ERROR:
      return {
        ...state,
        isUpdatingUploadedRubric: false
      }
    case types.UPDATE_UPLOADED_JUDGING_SESSION_RUBRIC_SUCCESS:
      return {
        ...state,
        isUpdatingUploadedRubric: false
      }
    case types.GET_RUBRIC_JUDGING_SESSION_DOCUMENTS_BEGIN:
      return {
        ...state,
        isFetchingRubricEvidenceDocuments: true
      }
    case types.GET_RUBRIC_JUDGING_SESSION_DOCUMENTS_ERROR:
      return {
        ...state,
        isFetchingRubricEvidenceDocuments: false,
        judgingRubricEvidenceDocuments: []
      }
    case types.GET_RUBRIC_JUDGING_SESSION_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isFetchingRubricEvidenceDocuments: false,
        judgingRubricEvidenceDocuments: action.payload.evidenceFiles
      }
    case types.SAVE_EXISTING_FLL_CHALLENGE_JUDGING_RUBRIC_BEGIN:
      return {
        ...state,
        isUpdatingFllChallengeRubric: true
      }
    case types.SAVE_EXISTING_FLL_CHALLENGE_JUDGING_RUBRIC_ERROR:
      return {
        ...state,
        isUpdatingFllChallengeRubric: false
      }
    case types.SAVE_EXISTING_FLL_CHALLENGE_JUDGING_RUBRIC_SUCCESS:
      return {
        ...state,
        isUpdatingFllChallengeRubric: false
      }
    default:
      return state;
  }
}