const m11Section = {
  id: 'm11',
  name: '2025ScoresheetM11Sub1Label',
  fields: [
    {
      scoring: '2025ScoresheetM11Sub2Label',
      id: 'm11_scoring_1',
      options: [
          {
              description: '0',
              value: 0
          },
          {
              description: '1',
              value: 1
          },
          {
              description: '2',
              value: 2
          }, 
      ]
    },
  ]
}

export default m11Section;