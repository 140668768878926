import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../../styles/judgingRubric/RubricUpload.scss';
import Container from 'shared/components/Container'
import { Col, Row } from 'antd';
import { connect } from 'react-redux';
import { getSessionRubric } from 'features/EventOverviewContainer/redux/actions/judgingRubric';
import { rowProps } from 'utils/rowHelpers';

import { colMdProps } from 'utils/colHelpers';
import { ClipLoader } from 'react-spinners';
import UploadRubricForm from './UploadRubricForm';
import DownloadRubricForm from './DownloadRubricForm';
import JudgingComments from './JudgingComments';
import UploadModal from 'shared/components/UploadModal'

const uploadInstructions = `
Download the feedback form for this team and event. Complete the form and upload the completed form. If you have additional comments, please use the Comments form below.
`

const instructionsClassName = 'paragraph--charcoal paragraph--large paragraph--normal'

const boldClassName = 'h-4 h--bold p-r-4 heading--charcoal';
const charcoalClassName = 'h-4 h--400 p-r-4 heading--charcoal';

const whiteTextClassName = 'paragraph--large paragraph--white paragraph--400'
const RubricUpload = props => {

  const {
    sessionRubricTemplate,
    isLoadingSessionRubric,
    uploadedRubricFile,
    isRubricFileUploaded,
    isUploadedRubricPublishable,
    isUploadedRubricPublished
  } = props;

  const {
    documentTypeId
  } = sessionRubricTemplate;

  const [uploadModalVisibility, setUploadModalVisibility] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  if (isLoadingSessionRubric) {
    return (
      <div className='c-rubric-upload__loading'>
        <ClipLoader size={36} color='#0066B3' />
      </div>
    )
  }

  // Upload modal, disallows user interaction while upload request is on-going
  const renderUploadModal = () => (
    <UploadModal
      wrapClassName='judging-rubric-upload-modal'
      visible={uploadModalVisibility}
      onCancel={() => setUploadModalVisibility(false)}
      uploadProgress={uploadProgress}
    />
  )

  return (
    <Container>
      <div className='c-rubric-upload'>
        <div className='c-rubric-upload__header'>
          <p className={whiteTextClassName}>Instructions</p>
        </div>
        <div className='c-rubric-upload__content'>
          <p className={instructionsClassName}>
            {uploadInstructions}
          </p>
          <div className='m-t-24'>
            <Row {...rowProps} justify='space-between'>
              <Col {...colMdProps}>
                <span className={boldClassName}>Step 1:</span>
                <span className={charcoalClassName}>Download Feedback Form</span>
              </Col>
              <Col {...colMdProps}>
                <DownloadRubricForm
                  template={sessionRubricTemplate}
                />
              </Col>
            </Row>
            <div className='m-t-16'>
              <Row {...rowProps} justify='space-between'>
                <Col {...colMdProps}>
                  <span className={boldClassName}>Step 2:</span>
                  <span className={charcoalClassName}>Upload Completed Feedback Form</span>
                </Col>
                <Col {...colMdProps}>
                  <UploadRubricForm
                    isUploadedRubricPublishable={isUploadedRubricPublishable}
                    sessionDocumentId={documentTypeId}
                    uploadedFile={uploadedRubricFile}
                    isFileUploaded={isRubricFileUploaded}
                    setUploadModalVisibility={setUploadModalVisibility}
                    setUploadProgress={setUploadProgress}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <JudgingComments
        isUploadedRubricPublishable={isUploadedRubricPublishable}
        isUploadedRubricPublished={isUploadedRubricPublished}
      />
      {renderUploadModal()}
    </Container>
  );
};

RubricUpload.propTypes = {
  getSessionRubricDetails: PropTypes.func.isRequired,
  sessionRubricTemplate: PropTypes.object.isRequired,
  isLoadingSessionRubric: PropTypes.bool.isRequired,
  isRubricFileUploaded: PropTypes.bool.isRequired,
  uploadedRubricFile: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  getSessionRubricDetails: getSessionRubric
}

const mapStateToProps = (state) => {
  const { judgingRubric: {
    judgingRubricTemplate,
    isLoadingSessionRubric,
    uploadedRubricFile,
    isRubricFileUploaded,
    isUploadedRubricPublishable,
    isUploadedRubricPublished
  } } = state;
  return {
    sessionRubricTemplate: judgingRubricTemplate,
    isLoadingSessionRubric,
    uploadedRubricFile,
    isRubricFileUploaded,
    isUploadedRubricPublishable,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RubricUpload);