import React from 'react';
import { ASC, DESC } from 'shared/constants/sortOrder';
import DownArrow from './DownArrow';
import UpArrow from './UpArrow';
import ArrowIcon from './ArrowIcon';

export default (props) => {
  const { order } = props;
  if (order === ASC) {
    return <UpArrow />
  }
  if (order === DESC) {
    return <DownArrow />
  }
  return <ArrowIcon />;
};
