import React from 'react';
import PropTypes from 'prop-types';
import EventModal from 'shared/components/EventModal';
import Container from 'shared/components/Container';
import FirstText from 'shared/components/FirstText';
import ModalContent from './EditUserContent';

const EditUserModal = props => {
  const { onCancel, visible, organizationId } = props;

  return (
    <EventModal
      visible={visible}
      onCancel={onCancel}
      renderHeader={() => (
        <span>
          {' '}
          Edit
          {' '}
          <FirstText />
          {' '}
          User
        </span>
)}
    >
      <Container>
        <h1>
          {' '}
          Edit
          {' '}
          <FirstText />
          {' '}
          User

        </h1>

      </Container>
      <ModalContent 
        organizationId={organizationId}
        closeModal={onCancel}
      />
    </EventModal>
  );
};

EditUserModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  organizationId: PropTypes.string
};

EditUserModal.defaultProps = {
  organizationId: null
}

export default EditUserModal;