import { DatePicker, Row, Col, Select, Form, Button } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import timezoneOptions from 'shared/constants/timezones';
import Input from 'shared/components/Input';
import FirstText from 'shared/components/FirstText';
import Dropdown from 'shared/components/FormDropdown';
import TextBox from 'shared/components/TextBox';
import formatEventPayload from 'features/AddEventModal/utils/formatEventPayload';
import { handleCreateNewEvent, fetchPartnerDetails, fetchProgramRegions } from 'features/AddEventModal/redux/actions';
import { fetchPrograms, fetchRegionsBy, fetchCountries } from 'features/AdminDashboard/redux/actions/programsActions';
import ConfirmationModal from 'features/AddEventModal/components/ConfirmationModal';
import '../styles/AddEventModal.scss';
import {urlValidation} from 'utils/formHelpers';
import moment from 'moment';
import { CURRENT_EVENT_SEASON } from 'shared/constants/eventConstants';
import { OFFICIAL } from 'shared/constants/eventOfficialTypes';
import { IN_PERSON_LOCATION, REMOTE_LOCATION } from 'shared/constants/eventLocationTypes';
import { IN_PERSON_OFFICIAL, IN_PERSON_TEST, REMOTE_OFFICIAL, REMOTE_TEST } from 'shared/constants/eventTypes';


const { Option } = Dropdown;

const rowProps = {
  type: 'flex', gutter: 20,
  className: 'm-t-20'
};

const AddEventForm = (props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState('');
  const [filteredRegions, setFilteredRegions] = useState([]);
  const [form] = Form.useForm();
  useEffect(() => {
    props.fetchPrograms();
    props.fetchPartnerDetails();
  }, []);

  const initialTimezone = moment.tz.guess();
  const initialTimezoneValid = timezoneOptions.findIndex(t=>t.id===initialTimezone) > -1;

  const FormItem = Form.Item;

  const {
    closeModal,
    createNewEvent,
    programs,
    countries,
    isFetchingRegions,
    newEvent,
    eventLocationType,
    eventOfficialType
  } = props;

  useEffect(()=>{
    !initialTimezoneValid && form.setFields({timezone: {value: undefined, errors: [new Error("")]}})
  },[initialTimezoneValid])

  const {
    partnerAdminDetails,
    isCreatingNewEvent
  } = newEvent;

  const partnerProgramIds = partnerAdminDetails.map(p => p.programId);
  const filteredProgramOptions = programs.filter(el => partnerProgramIds.includes(el.id)&&(el.shortName!=='FTC'));

  const resetForm = () => {
    form.resetFields();
  };

  const onCancel = () => {
    setVisible(false);
    closeModal();
  };

  const showConfirmationModal = () => {
    setVisible(true);
  };

  const successCallback = () => {
    resetForm();
    showConfirmationModal();
  };

  const handleProgramChange = (value) => {
    form.resetFields(['region']);
    const filteredDetails = partnerAdminDetails.filter(i => i.programId === value);
    setFilteredRegions(filteredDetails);
  };

  const addEvent = async (e) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }
    form.validateFields().then(async (values, err) => {
      const payload = formatEventPayload(values);
      payload.data.attributes.event_type = mapEventType(eventLocationType, eventOfficialType);
      payload.data.attributes.season = CURRENT_EVENT_SEASON;
      createNewEvent(payload, successCallback);
     });
  };

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const mapEventType = (locationType, officialType) => {
    switch (locationType) {
      case REMOTE_LOCATION: return officialType === OFFICIAL ? REMOTE_OFFICIAL : REMOTE_TEST;
      case IN_PERSON_LOCATION: return officialType === OFFICIAL ? IN_PERSON_OFFICIAL : IN_PERSON_TEST;
      default: return null;
    }
  };

  const closeConfirmationModal = () => {
    setVisible(false);
  };

  const validateCountry = (rule, value, callback) => {
    if (value && !countries.find(({ id }) => id === value)) {
      callback('Select Country from List.');
    } else {
      callback();
    }
  };

  const filterOptionIgnoreCase = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div>
      <ConfirmationModal visible={visible} onCancel={onCancel} onOk={closeConfirmationModal} />
      <Form hideRequiredMark onFinishFailed={onFinishFailed} name='CreateNewEvent' form={form} initialValues={{timezone: (initialTimezoneValid ? initialTimezone : undefined) }}>
        <Row {...rowProps}>
          <Col xs={24} md={14}>
            <FormItem name='title' label={<span>{t('eventTitleLabel')}</span>} rules={[{ required: true, message: t('enterEventTitleLabel') }]}>
              <Input size='large' placeholder={t('eventTitleLabel')} />
            </FormItem>
          </Col>
          <Col xs={24} md={10}>
            <FormItem name='program' label={<span>{t('programLabel')}</span>} rules={[{ required: true, message: t('selectProgramLabel') }]}>
                <Dropdown placeholder={t('programLabel')} {...form} onChange={handleProgramChange}>
                  {filteredProgramOptions.map(p => (
                    <Option key={p.id} value={p.id}>
                      <FirstText />
                      {' '}
                      {p.name}
                    </Option>
                  ))}
                </Dropdown>
            </FormItem>
          </Col>
        </Row>

        <Row {...rowProps}>
          <Col id='add-event-range-picker' style={{ position: 'relative' }} xs={24} md={12}>
            <FormItem name='dates' label={<span>{t('datesLabel')}</span>} rules={[{ required: true, message: t('selectStartAndEndDatesLabel') }]}>
                <DatePicker.RangePicker
                  getCalendarContainer={() => document.getElementById('add-event-range-picker')}
                  className='range-picker'
                  format='MM-DD-YYYY'
                  size='large'
                  style={{ position: 'relative' }}
                  placeholder={[t('startDateLabel'), t('endDateLabel')]}
                />              
            </FormItem>
          </Col>
          <Col xs={0} md={2}></Col>
          <Col xs={24} md={10}>
            <FormItem name='timezone' label={initialTimezoneValid ?<span>Time zone</span> : <span>Time zone not detected</span>} className={initialTimezoneValid ? '' : 'timezone-error'} rules={[{required: true, message: ''}]}>
                <Dropdown
                  showSearch
                  placeholder="Select Time Zone"
                >
                  {timezoneOptions.map(tz => (
                    <Option value={tz.id} key={tz.id}>{tz.description}</Option>
                  ))}
                </Dropdown>
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col xs={24} md={12}>
            <FormItem name='region' label={<span>{t('regionLabel')}</span>} rules={[{ required: true, message: t('selectRegionLabel') }]}>
                <Dropdown
                  placeholder={t('regionLabel')}
                  {...form}
                  disabled={isFetchingRegions}
                  optionFilterProp='children'
                  showSearch
                >
                  {filteredRegions.map(r => (
                    <Option key={r.regionId} value={r.regionId}>{r.regionName}</Option>
                  ))}
                </Dropdown>
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col xs={24} md={12}>
            <FormItem name='country' label={<span>{t('countryLabel')}</span>} rules={[{ required: true, message: 'Select Country.' },{ validator: validateCountry }]}>
                <Select
                  allowClear
                  showSearch
                  placeholder={t('countryLabel')}
                  onSearch={props.fetchCountries}
                  filterOption={filterOptionIgnoreCase}
                  options={countries.map(c => ( {value: c.id, label: c.name}))}
                />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col xs={24} md={16}>
            <FormItem name='url' label={<span>{t('eventUrlLabel')}</span>} rules={[urlValidation]}> 
                <Input
                  size='large'
                  placeholder={t('eventUrlLabel')}
                />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col xs={24} md={16}>
            <FormItem name='description' label={<span>{t('descriptionLabel')}</span>}>
              <TextBox size='large' placeholder={t('descriptionLabel')} />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps} justify="end">
          <Button loading={isCreatingNewEvent} additionalClassNames='add-event-modal__btn' htmlType='submit' onClick={addEvent}>{t('addLabel')}</Button>
        </Row>
      </Form>
    </div>
  );
};

AddEventForm.propTypes = {
  form: PropTypes.object.isRequired,
  createNewEvent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  fetchPrograms: PropTypes.func.isRequired,
  fetchRegions: PropTypes.func.isRequired,
  newEvent: PropTypes.object.isRequired,
  fetchCountries: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  createNewEvent: handleCreateNewEvent,
  fetchRegions: fetchRegionsBy,
  fetchPrograms,
  fetchCountries,
  fetchPartnerDetails,
  fetchProgramRegions
};

const mapStateToProps = (state) => {
  const { addEvent, adminBoard: {
    programs: { list, countries, isFetchingRegions }
  } } = state;
  return {
    newEvent: addEvent,
    programs: list,
    countries,
    isFetchingRegions
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEventForm);
