import {
  EVENT_ADMIN_ID,
  JUDGE_ADVISOR_ID,
  HEAD_REFEREE_ID,
  JUDGE_ID,
  REFEREE_ID,
  COACH_ID,
} from 'shared/constants/eventRoleTypes';

const allRoles = [
  EVENT_ADMIN_ID,
  JUDGE_ADVISOR_ID,
  HEAD_REFEREE_ID,
  JUDGE_ID,
  REFEREE_ID,
  COACH_ID]


export default [
  {
    description: 'Game Manual part 1 (remote events)',
    link: 'https://www.firstinspires.org/sites/default/files/uploads/resource_library/ftc/game-manual-part-1-remote.pdf',
    roles: allRoles
  },
  {
    description: 'Game Manual part 2 (remote events)',
    link: 'https://www.firstinspires.org/resource-library/ftc/game-and-season-info',
    roles: allRoles
  },
  {
    description: 'Legal & Illegal Parts List',
    link: 'https://www.firstinspires.org/sites/default/files/uploads/resource_library/ftc/legal-illegal-parts-list.pdf',
    roles: allRoles
  },
  {
    description: 'Programing Resources',
    link: 'https://www.firstinspires.org/resource-library/ftc/technology-information-and-resources',
    roles: [COACH_ID]
  },
  {
    description: 'Robot Building Resources',
    link: 'https://www.firstinspires.org/resource-library/ftc/robot-building-resources',
    roles: [COACH_ID]
  },
  {
    description: 'Robot Reliability Checklist',
    link: 'https://www.firstinspires.org/sites/default/files/uploads/resource_library/ftc/robot-reliability-checklist.pdf',
    roles: [COACH_ID]
  },
]