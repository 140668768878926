import { API } from 'utils/api';
import * as scheduleService from 'utils/services/schedules';
import EventScheduleClass from 'features/EventContainer/models/EventSchedule';
import * as types from '../../types/eventScheduleTypes';
import * as detailTypes from '../../types/eventDetailsTypes';
import { fetchJudgingSessions, getJudgingSessionsSuccess } from './scheduleJudgingSessions';
import { fetchScoringSessions, getScoringSessionsSuccess } from './scheduleScoringSessions';
import { fetchEventLeaderboard } from 'features/LeaderboardContainer/redux/leaderboardActions';
import { getScheduleMeetingSessions, getMeetingSessionsSuccess } from './scheduleMeeting';
import _ from 'lodash';
import handleOpeningSession from './scheduleOpeningSession';
import handleClosingSession from './scheduleClosingSession';
import { matchTypes } from 'shared/constants/matchTypes';
import { getPanelsData } from 'utils/services/sessions';
const getScheduleDetailsBegin = () => {
  return {
    type: types.GET_EVENT_SCHEDULE_DETAILS_BEGIN
  }
}

const getScheduleDetailsError = () => {
  return {
    type: types.GET_EVENT_SCHEDULE_DETAILS_ERROR
  }
}

const getScheduleDetailsSuccess = () => {
  return {
    type: types.GET_EVENT_SCHEDULE_DETAILS_SUCCESS,

  }
}
const getScheduleTabDetailsBegin = () => {
  return {
    type: types.GET_EVENT_SCHEDULE_TAB_DETAILS_BEGIN
  }
}

const getScheduleTabDetailsError = () => {
  return {
    type: types.GET_EVENT_SCHEDULE_TAB_DETAILS_ERROR
  }
}

const getScheduleTabDetailsSuccess = () => {
  return {
    type: types.GET_EVENT_SCHEDULE_TAB_DETAILS_SUCCESS,

  }
}

const getScheduleDetailsComplete = () => {
  return {
    type: types.GET_EVENT_SCHEDULE_DETAILS_COMPLETE
  }
}

const setJudgingTimestamps = (judgingTimestampsData, judgingTimestampsIncluded) => {
  return {
    type: types.SET_SCHEDULE_JUDGING_TIMESTAMPS,
    payload: {
      judgingTimestampsData, judgingTimestampsIncluded
    }
  }
}

const setScoringTimestamps = (scoringTimestampsData, scoringTimestampsIncluded) => {
  return {
    type: types.SET_SCHEDULE_SCORING_TIMESTAMPS,
    payload: {
      scoringTimestampsData, scoringTimestampsIncluded
    }
  }
}

const getScoringTimestampsBegin = () => {
  return {
    type: types.GET_SCHEDULE_SCORING_TIMESTAMPS_BEGIN
  }
}
const getScoringTimestampsError = () => {
  return {
    type: types.GET_SCHEDULE_SCORING_TIMESTAMPS_ERROR
  }
}
const getScoringTimestampsSuccess = (scoringTimestampsData, scoringTimestampsIncluded) => {
  return {
    type: types.GET_SCHEDULE_SCORING_TIMESTAMPS_SUCCESS,
    payload: {
      scoringTimestampsData, scoringTimestampsIncluded
    }
  }
}

const getJudgingTimestampsBegin = () => {
  return {
    type: types.GET_SCHEDULE_JUDGING_TIMESTAMPS_BEGIN
  }
}
const getJudgingTimestampsError = () => {
  return {
    type: types.GET_SCHEDULE_JUDGING_TIMESTAMPS_ERROR
  }
}
const getJudgingTimestampsSuccess = (judgingTimestampsData, judgingTimestampsIncluded) => {
  return {
    type: types.GET_SCHEDULE_JUDGING_TIMESTAMPS_SUCCESS,
    payload: {
      judgingTimestampsData, judgingTimestampsIncluded
    }
  }
}

const setOpeningSession = (openingSessionData, openingSessionIncluded) => {
  return {
    type: types.GET_SCHEDULE_OPENING_SESSION_SUCCESS,
    payload: {
      openingSessionData, openingSessionIncluded
    }
  }
}

const setClosingSession = (closingSessionData, closingSessionIncluded) => {
  return {
    type: types.GET_SCHEDULE_CLOSING_SESSION_SUCCESS,
    payload: {
      closingSessionData, closingSessionIncluded
    }
  }
}

const setEventScheduleId = (id) => {
  return {
    type: detailTypes.SET_EVENT_SCHEDULE_ID,
    payload: {
      eventScheduleId: id
    }
  }
}

const getEventScheduleError = () => {
  return {
    type: detailTypes.SET_ADMIN_EVENT_SCHEDULE_ERROR
  }
}

const getEventScheduleSuccess = () => {
  return {
    type: detailTypes.SET_ADMIN_EVENT_SCHEDULE_SUCCESS
  }
}

const refetchEventSchedule = () => {
  return {
    type: types.SET_REFETCH_EVENT_SCHEDULE
  }
}

const setScheduleMeetingSessions = (meetingSessions) => {
  return {
    type: types.SET_SCHEDULE_MEETING_SESSIONS,
    payload: {
      meetingSessions
    }
  }
}

const setScheduleJudgingSessions = (judgingSessions) => {
  return {
    type: types.SET_SCHEDULE_JUDGING_SESSIONS,
    payload: {
      judgingSessions
    }
  }
}

const setScheduleScoringSessions = (scoringSessions) => {
  return {
    type: types.SET_SCHEDULE_SCORING_SESSIONS,
    payload: {
      scoringSessions
    }
  }
}

export const cleanUpScoringSessionsData = ()=>{
  return async (dispatch)=>{
    await dispatch(setScheduleScoringSessions([]))
  }
}

export const setScheculeSettingsData = (data) =>{
  return {
    type: types.SET_SCHEDULE_SETTINGS_DETAIL,
    payload: data
  }
}


export const handleRefetchEventScheduleBegin = () => {
  return async (dispatch) => {
    await dispatch(refetchEventSchedule())
  }
}

const delay = ms => new Promise(res => setTimeout(res, ms));


export const fetchEventScheduleById = () => {
  return async (dispatch, getState) => {
    const {event: {
      eventDetails: {eventScheduleId},
      eventSchedule: { sessions: {judgingSessions}}
    
    }} = getState();
    await dispatch(getScheduleDetailsBegin());

    try {
      const MAX_CALL_COUNT = 5;
      let totalCount = 0
      const recursiveScheduleCall = async (scheduleId, prevIncludedCount) => {
        await delay(10000);
        const response = await scheduleService.getScheduleById(scheduleId);
       
        const { data, included } = response;
        const scheduleData = new EventScheduleClass(data, included);
        
        dispatch(setScheculeSettingsData(scheduleData.scheduleSettings))

        const {leaderboardId} = (scheduleData||{})

        if(leaderboardId){
          await dispatch(fetchEventLeaderboard(leaderboardId));
        }
        
        const currentJudgingSessionsList = scheduleData.judgingSessionsData.map(session => {
          const judging = scheduleData.formatSessionData(session.id)
          return judging;
        });
        
        if (currentJudgingSessionsList.length <= prevIncludedCount && totalCount < MAX_CALL_COUNT) {
          totalCount += 1;
          return recursiveScheduleCall(scheduleId, prevIncludedCount)
        } 
        const scoringSessions = scheduleData.scoringSessionsData.map(session => {
          const scoring = scheduleData.formatSessionData(session.id);
          return scoring;
        })
      
        await dispatch(setScheduleJudgingSessions(currentJudgingSessionsList))
        await dispatch(setScheduleScoringSessions(scoringSessions))
        await dispatch(fetchJudgingSessions(eventScheduleId));
        await dispatch(fetchScoringSessions(eventScheduleId));
        await dispatch(getScheduleDetailsSuccess());

          await dispatch(getEventScheduleSuccess());
      }

      recursiveScheduleCall(eventScheduleId, judgingSessions.length);

    } catch(err) {
      await dispatch(getEventScheduleError());

      throw err;
    }
  }

}

const setMeetingSectionData =(value)=>{
  return {
    type: types.SET_SCHEDULE_MEETING_SECTION_LOADING,
    payload: value
  }
}

export const handleEventSchedules = (scheduleId) => {
  return async dispatch => {
    await dispatch(getScheduleDetailsBegin());
    try {
      const response = await scheduleService.getScheduleById(scheduleId);
      const { data, included } = response;
      if (data.id) {
        const scheduleData = new EventScheduleClass(data, included);

        dispatch(setScheculeSettingsData(scheduleData.scheduleSettings))

        const {leaderboardId, judgingSessionsData, scoringSessionsData, meetingSessionsData} = (scheduleData||{})

        await dispatch(setMeetingSectionData(scheduleData.meetingSessionsData))

        if(meetingSessionsData.length){
          dispatch(getScheduleMeetingSessions(scheduleId));
        }else{
          dispatch(getMeetingSessionsSuccess([]))
        }
        
        if(leaderboardId){
          dispatch(fetchEventLeaderboard(leaderboardId));
        }

        if(judgingSessionsData.length){
          dispatch(fetchJudgingSessions(scheduleId))
        }else{
          dispatch(getJudgingSessionsSuccess([]))
        }

        if(scoringSessionsData.length){
          dispatch(fetchScoringSessions(scheduleId))
        }else{
          dispatch(getScoringSessionsSuccess([]))
        }
      }
      await dispatch(getScheduleDetailsSuccess());
      if (data.id) {
        const scheduleId = data.id;
        await dispatch(setEventScheduleId(scheduleId));
        await dispatch(getEventScheduleSuccess());
      } else {
        await dispatch(getEventScheduleError());
      }
    } catch (err) {
      await dispatch(getScheduleDetailsError());
      throw err;
    }
  }
}

export const handleInPersonMatches = (page, setDataCallback = undefined, setDataCount = undefined, setCurrentMatchesCount=undefined)=>{
  return async (dispatch, getState) =>{
    try {
      setDataCallback&&setDataCallback([])
      const {event:{eventSchedule:{sessions:{scoringSessions}}, eventDetails: {eventScheduleId}}} = getState()

      const sortedScoringSession = _.orderBy(scoringSessions, ['teamDetails'], ['asc']);
      const groupedScoringSession = _.groupBy(sortedScoringSession, 'teamId');
      const teamKeys = Object.keys(groupedScoringSession);

      const teamsLength = teamKeys.length;
      setDataCount && setDataCount(teamsLength);
      
      let {size = 6, number = 1 } = page;
      let startMatchNumber = (number - 1) * size;
      let endMatchNumber = number * size;
      let inRangeTeamKeys = (teamKeys||[]).slice(startMatchNumber, endMatchNumber)

      const panelData = await getPanelsData(eventScheduleId, 'score');
      const panels = (panelData||[])
      const groupedPanels = _.groupBy(panels, 'id');

      let matches = [];
      inRangeTeamKeys.forEach(key=>{
        const sessionData = groupedScoringSession[key];
        sessionData.forEach(session=>{
          const matchNumber = session?.matchId[0]?.matchNumber;
          const matchTypeId= session?.matchId[0]?.matchTypeId;
          const panel = groupedPanels[session.panelId];
          const panelName = ((panel||[])[0]||{}).name;
          const formatMatch = {
            matchTeamId: session.teamId,
            teamDetail: session.teamDetails,
            startTime: session.startTime,
            endTime: session.endTime,
            panelName: panelName,
            sessionTypeId: session.sessionTypeId,
            matchNumber: matchNumber,
            matchTypeId: matchTypeId,
            matchTypeName: matchTypes[matchTypeId]?.name,
            sessionId: session.id,
          }
          matches = [...matches, formatMatch]
        })
      })

      setCurrentMatchesCount&&setCurrentMatchesCount(matches.length);
      setDataCallback && setDataCallback(matches)

    }catch (err){
      throw err;
    }
  }
}

const loadJudgingTimestamps = (scheduleId)=>{
  return async dispatch=>{
    try{
      await dispatch(getJudgingTimestampsBegin())
      const judgingTimestampsResponse = await API.GetInstance().http.get(`/api/schedules/${scheduleId}/judging_time_stamps`);
      const judgingIncluded = judgingTimestampsResponse.included;
      const judgingData = judgingTimestampsResponse.data;
      await dispatch(getJudgingTimestampsSuccess(judgingData, judgingIncluded ))
    }catch(e){
      await dispatch(getJudgingTimestampsError())
    }
  }
}

const loadScoringTimestamps = (scheduleId)=>{
  return async dispatch=>{
    try{
      await dispatch(getScoringTimestampsBegin())
      const scoringTimestampsResponse = await API.GetInstance().http.get(`/api/schedules/${scheduleId}/scoring_time_stamps`);
      const scoringIncluded = scoringTimestampsResponse.included;
      const scoringData = scoringTimestampsResponse.data;
      await dispatch(getScoringTimestampsSuccess(scoringData, scoringIncluded ))
    }catch(e){
      await dispatch(getScoringTimestampsError())
    }
  }
}

export const handleScheduleDetails = (scheduleId) => {
  return async dispatch => {
    try {
      if (!scheduleId) return;
      dispatch(handleOpeningSession(scheduleId))
      dispatch(handleClosingSession(scheduleId));
      dispatch(loadJudgingTimestamps(scheduleId));
      dispatch(loadScoringTimestamps(scheduleId))
    } catch (err) {
      await dispatch(getScheduleTabDetailsError());
      throw err;
    }
  }
}

export const handleJudgingTimestamps = (scheduleId) => {
  return async dispatch => {
    await dispatch(getJudgingTimestampsBegin())
    try {
      const response = await API.GetInstance().http.get(`/api/schedules/${scheduleId}/judging_time_stamps`);
      const { data, included } = response;

      const includedArray = included || [];
      await dispatch(getJudgingTimestampsSuccess(data, includedArray))
    } catch (err) {
      await dispatch(getJudgingTimestampsError())
      throw err;
    }
  }
}

export const handleScoringTimestamps = (scheduleId) => {
  return async dispatch => {
    await dispatch(getScoringTimestampsBegin())
    try {
      const response = await API.GetInstance().http.get(`/api/schedules/${scheduleId}/scoring_time_stamps`);
      const { data, included } = response;
      const includedArray = included || [];
      await dispatch(getScoringTimestampsSuccess(data, includedArray));
    } catch (err) {
      await dispatch(getScoringTimestampsError())
      throw err;
    }
  }
}
