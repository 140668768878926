import { JUDGE_ID } from 'shared/constants/eventRoleTypes';
import * as userEventRoleService from 'utils/services/eventRoles';
import * as sessionsService from 'utils/services/sessions';
import * as panelsService from 'utils/services/panels';
import UserEventRole from 'models/UserEventRole';
import ScheduleSession from 'models/ScheduleSession';
import * as panelService from 'utils/services/panels';
import * as types from '../../types/judgingTypes';
import { JUDGING_REVIEW_PANEL_TYPE_ID } from 'shared/constants/panelTypes';
import Panel from 'models/Panel';
import PanelModel from 'models/PanelClassModel';
import _ from 'lodash';

export const sortPanelSessions = (a, b) => {
  const sessionA = a.panelName
  const sessionB = b.panelName
  let comparison = 0;
  if (!sessionA) {
    return -1
  }
  if (!sessionB) {
    return 1;
  }
  if (sessionA === sessionB) {
    return b.teamName.localeCompare(a.teamName)
  }
  if (sessionA > sessionB) {
    comparison = 1;
  } else if (sessionA < sessionB) {
    comparison = -1;
  }
  return comparison;
}
export const sortPanelUsers = (a, b) => {
  const userA = a.panelName
  const userB = b.panelName

  let comparison = 0;

  if (!userA) {
    return -1
  }
  if (!userB) {
    return 1;
  }
  if ((userA === userB && b.fullName && a.fullName)) {
    return b.fullName.localeCompare(a.fullName)

  }
  if (userA > userB) {
    comparison = 1;
  } else if (userA < userB) {
    comparison = -1;
  }
  return comparison;
}
const getJudgingPanelsBegin = () => {
  return {
    type: types.GET_EVENT_JUDGING_PANELS_BEGIN
  }
}

const getJudgingPanelsError = () => {
  return {
    type: types.GET_EVENT_JUDGING_PANELS_ERROR
  }
}

export const getJudgingPanelsSuccess = (judgingPanels, judgeOptions, sessionOptions) => {
  return {
    type: types.GET_EVENT_JUDGING_PANELS_SUCCESS,
    payload: {
      judgingPanels, judgeOptions, sessionOptions
    }
  }
}

const setExpands = (panels) => {
  return {
    type: types.SET_JUDGING_PANELS_EXPANDS,
    payload: {
      panels
    }
  }
}


export const getJudgingTabData = async (eventId, eventScheduleId) => {
  let panelsData = [];
  const { judging_sessions: data } = await sessionsService.getJudgingSessions(eventScheduleId);


  // let panels = testData;
  let panels = await sessionsService.getPanelsData(eventScheduleId, 'judge');
  // let panelsIncluded = panels.data;

  // const {included, data} = sessionsResponse;
  // let data = sessionsResponse;

  // const sessionsPanels = included.filter(i => i.type === 'panel');
  let sessionsPanels = [];

  panels.forEach(panel => {
    if (panel?.panelType?.id == JUDGING_REVIEW_PANEL_TYPE_ID) {
      sessionsPanels.push(panel)
    }
  });

  if (sessionsPanels.length) {
    panelsData = sessionsPanels.map(panel => new PanelModel(panel, 'judge'))
  }

  const filter = {
    'role.id': `eq:${JUDGE_ID}`,
    'event.id': `eq:${eventId}`
  };
  const judgesRes = await userEventRoleService.getAllUserEventRolesBy(filter);
  const eventJudges = judgesRes.data.map(j => {
    const judge = new UserEventRole(j, judgesRes.included);
    return {
      fullName: judge.userFullNameString(),
      email: judge.userEmailString(),
      userId: judge.userId
    };
  })

  const eventJudgingSessions = data.map(d => {
    // const judgingSession = new ScheduleSession(d, included);
    const judgingSession = new ScheduleSession(d, [], [], eventScheduleId, 2);

    return {
      panelId: judgingSession.panelId,
      sessionTypeId: judgingSession.sessionTypeId,
      sessionId: judgingSession.sessionId,
      teamName: judgingSession.getTeamName(),
      teamId: judgingSession.teamId,
      sessionTypeName: judgingSession.getSessionTypeName()
    }
  })


  const panelsJudgesList = [];
  const panelsSessionsList = [];

  const judgingPanels = panelsData.map(panelClass => {
    const panelJudges = panelClass.judges;
    const panelSessions = panelClass.sessions;
    panelJudges.forEach(j => {
      const userAlreadyAddedIndex = panelsJudgesList.findIndex(i => i.id === j.id);
      const isUserAlreadyAdded = userAlreadyAddedIndex > -1;

      const panelObj = {
        panelId: panelClass.id,
        panelName: panelClass.name
      }
      let selectedJudge;

      if (isUserAlreadyAdded) {
        selectedJudge = panelsJudgesList[userAlreadyAddedIndex];
        selectedJudge = {
          ...selectedJudge,
          panels: [...selectedJudge.panels, panelObj]
        }
        panelsJudgesList[userAlreadyAddedIndex] = selectedJudge;
      } else {
        selectedJudge = {
          ...j,
          panelId: panelClass.id,
          panelName: panelClass.name,
          panels: [panelObj]
        }
        panelsJudgesList.push(selectedJudge)
      }
    })

    panelSessions.forEach(s => {
      const selectedSession = {
        ...s,
        panelId: panelClass.id,
        panelName: panelClass.name
      }
      panelsSessionsList.push(selectedSession);
    })
    return panelClass;
  })

  const judgeOptions = eventJudges.map(eventJudge => {
    const judgeOnPanel = panelsJudgesList.find(p => p.id === eventJudge.userId) || {};
    return {
      ...eventJudge,
      ...judgeOnPanel,
      userId: eventJudge.userId,
      fullName: eventJudge.fullName
    }
  })
  const sessionOptions = eventJudgingSessions.map(eventSession => {
    const sessionOnPanel = panelsSessionsList.find(p => p.id === eventSession.sessionId) || {};
    return {
      ...eventSession,
      ...sessionOnPanel
    }
  })

  const formattedPanels = judgingPanels.map(judgingPanel => {
    const panelJudges = [];
    judgingPanel.judges.forEach(j => {
      const selectedJudge = eventJudges.find(ej => ej.userId === j.id);
      if (selectedJudge) {
        panelJudges.push(selectedJudge)
      }
    })

    const panelSessions = []
    judgingPanel.sessions.forEach(j => {
      const selectedSession = eventJudgingSessions.find(es => es.sessionId === j.id);
      if (selectedSession) {
        panelSessions.push(selectedSession)
      }
    })

    return {
      panelName: judgingPanel.name,
      panelId: judgingPanel.id,
      panelJudges,
      panelSessions,
    }
  })

  const sortedPanels = formattedPanels.sort((a, b) => {
    const aValue = a.panelName ?? '';
    const bValue = b.panelName ?? '';
    return aValue.localeCompare(bValue, undefined, { numeric: true, sensitivity: 'base'});
  })
  return {
    formattedPanels: sortedPanels,
    judgeOptions: judgeOptions.sort(sortPanelUsers),
    sessionOptions: sessionOptions.sort(sortPanelSessions)
  }
}

const autoAssignPanels = async (scheduleId, panelTypeId) => {
  let response = await panelService.autoAssignPanels(scheduleId, JUDGING_REVIEW_PANEL_TYPE_ID)
  return response
}

const handleGetJudgingPanels = (scheduleId) => {
  return async (dispatch, getState) => {
    const { event: { eventDetails: { id, eventScheduleId } } } = getState();
    await dispatch(getJudgingPanelsBegin());

    try {
      const { formattedPanels, judgeOptions, sessionOptions } = await getJudgingTabData(id, eventScheduleId)
      await dispatch(getJudgingPanelsSuccess(formattedPanels, judgeOptions, sessionOptions));

    } catch (err) {
      await dispatch(getJudgingPanelsError())
      throw err;
    }
  }
}

const handleExpands = (panels) => {
  return async (dispatch, getState) => {
    // const { event: { eventDetails: { id, eventScheduleId } } } = getState();

    await dispatch(setExpands(panels))
  }
}


export { autoAssignPanels, handleExpands }
export default handleGetJudgingPanels;