const ip3Section = {
    id: 'IP3',
    // label: 'ipv1Label',
    title: 'exploreReviewingSheetTeamModelLabel',
    // description: 'identifyDescriptionLabel',
    fields: [
        {
            id: 'team_model_1',
            value: 'exploreReviewingSheetTeamModelDescribeLabel',
            answear: 'exploreReviewingSheetTeamModelDescribeAnswerLabel'
        },
        {
            id: 'team_model_2',
            value: 'exploreReviewingSheetTeamModelHowQuestionLabel',
            answear: 'exploreReviewingSheetTeamModelHowAnswerLabel'
        },
    ]
}

export default ip3Section;