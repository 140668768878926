import React from 'react';
import PropTypes from 'prop-types';

const EditIcon = ({ fill }) => {
  return (
    <svg width="14px" height="14px" viewBox="0 0 14 14">
      <g id="Event" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Event-Management---Single-Event---Sponsors---Preview---PDP-Admin" transform="translate(-1068.000000, -458.000000)" fill={fill}>
              <g id="Group-20" transform="translate(0.000000, 422.000000)">
                  <g id="Group-4" transform="translate(1068.000000, 33.000000)">
                      <g id="noun_edit_689096" transform="translate(0.000000, 3.000000)">
                          <path d="M10.4837963,0.172839506 C10.3680968,0.186735802 10.2576406,0.24099479 10.1759259,0.324074074 L1.1882716,9.3117284 C1.1195921,9.37934114 1.07034338,9.46647333 1.04783951,9.56018519 L0.183641975,13.3626543 C0.144704277,13.5328883 0.198990123,13.7214833 0.322472543,13.8449657 C0.445954963,13.968448 0.63455,14.0227339 0.804783951,13.9837963 L4.60725309,13.1195988 C4.7028641,13.0980391 4.7920422,13.0487066 4.86111111,12.9791667 L13.8487654,3.99151235 C14.038272,3.79982763 14.038272,3.4540304 13.8487654,3.26234568 L10.9104938,0.324074074 C10.8008396,0.212892469 10.6389922,0.155528765 10.4837963,0.172839506 Z M10.5432099,1.42052469 L12.7469136,3.62962963 L11.7530864,4.62345679 L9.54938272,2.41975309 L10.5432099,1.42052469 Z M8.81481481,3.14891975 L11.0185185,5.35802469 L4.66666667,11.7098765 L2.46296296,9.50617284 L8.81481481,3.14891975 Z M1.91743827,10.4243827 L3.74845679,12.2554012 L1.38271605,12.7901235 L1.91743827,10.4243827 Z" id="Shape"></path>
                      </g>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  );
};

EditIcon.propTypes = {
  fill: PropTypes.string
};

EditIcon.defaultProps = {
  fill: '#0066B3'
}

export default EditIcon;
