import React from 'react';
import PropTypes from 'prop-types';
import DownloadOutlined from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import Paragraph from 'shared/components/Paragraph';
import './downloadLink.scss';

const DownloadLink = (props) => {
  const { text, href, onClick, filename, csvData } = props;
 //const linkProps = onClick ? { onClick } : { href, download: true };
  const renderContent = () => (
    <>
      <DownloadOutlined />
      <Paragraph size='medium'>
        {text}
      </Paragraph>
    </>
  );
  if (csvData) {
    const disabled = !csvData || !csvData.length;
    return (
      <CSVLink 
        disabled={disabled} 
        className={`c-download-link ${disabled ? 'c-download-link--disabled' : ''}`}
        data={csvData} 
        filename={filename || `${text}.csv`}
      >
        {renderContent()}
      </CSVLink>
    );
  }

  const disabledLink = !href
  return (
    <a className={`c-download-link ${disabledLink ? 'c-download-link--disabled' : ''}`} download={filename || text || true} href={href}>
      {renderContent()}
    </a>
  );
};

DownloadLink.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  filename: PropTypes.string,
  csvData: PropTypes.array
}

DownloadLink.propTypes = {
  text: '',
  href: '',
  onClick: () => {},
  filename: '',
  csvData: false
}

export default DownloadLink