import React from 'react';
import PropTypes from 'prop-types';
import EventModal from 'shared/components/Modal';
import EditTeamModalContent from './EditTeamModalContent';



const EditTeamModal = props => {
  const {onCancel, visible, selectedTeamId,onCoachDeletedCallback} = props;

  return (
    <EventModal
      visible={visible}
      onCancel={onCancel}
      noTitle
      iconColor='#9A989A'
      className='add-team-modal'
      maxWidth={1100}
    >
      {selectedTeamId ? (
        <EditTeamModalContent teamId={selectedTeamId} onCoachDeletedCallback={onCoachDeletedCallback} onCancel={onCancel} showDeleteOption/>
      ) : (null)}
    </EventModal>
  );
};

EditTeamModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedTeamId: PropTypes.string
};

EditTeamModal.defaultProps = {
  selectedTeamId: null
}

export default EditTeamModal;