/* eslint-disable react/jsx-one-expression-per-line */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'

import EventProgramLogos from 'shared/components/EventProgramLogos';
import Heading from 'shared/components/Heading';
import '../styles/EventHeader.scss';

import { setDashboardRole } from 'features/DashboardContainer/redux/roleActions';
import { Switch } from 'antd';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as eventRoleTypes from 'shared/constants/eventRoleTypes';
import * as userTypes from 'shared/constants/userTypes';

const EventHeader = (props) => {
  const {
    eventLocation,
    eventTitle,
    eventDateRange,
    eventProgramId,
    dashboard,
    selectRole,
    canAccessVolunteerDashboard,
    canAccessAdminDashboard,
    userType,
    eventDetails
  } = props;

  const {
    userEventRoleIds,
  } = eventDetails;

  const { t, i18n } = useTranslation();
  const rtl_direction = i18n.dir() === 'rtl';
  const wrapperRef = useRef(null);
  const [size, setSize] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname
  useEffect(() => {
    if (wrapperRef && wrapperRef.current && wrapperRef.current.clientWidth) {
      setSize(wrapperRef.current.clientWidth)
    }
  }, [wrapperRef]);

  useEffect(() => {
    const resizeListener = () => {
      if (wrapperRef && wrapperRef.current && wrapperRef.current.clientWidth) {
        setSize(wrapperRef.current.clientWidth)
      }
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  useEffect(() => {
    if (path.includes('/events')) {
      selectRole(t('adminDashboardLabel'), 'AdminDashboard', 'admin')
    } else if (path === '/event-overview') {
      selectRole('Volunteer Dashboard', 'VolunteerDashboard', 'volunteer')
    }
  }, [])

  const haveAccessToViewAdmin = () => {
    if (userEventRoleIds.includes(eventRoleTypes.EVENT_ADMIN_ID)
      || userEventRoleIds.includes(eventRoleTypes.HEAD_REFEREE_ID)
      || userEventRoleIds.includes(eventRoleTypes.JUDGE_ADVISOR_ID)
      || userType === userTypes.FIRST_ADMIN
      || userType === userTypes.PARTNER_ADMIN) {
      return true
    }
    return false
  }

  const getHashAdmin = (hash) => {

    if (hash == "" || hash == "#schedule") {
      return "#1"
    }

    if (hash == "#scoring-sessions") {
      return "#6"
    }

    if (hash == "#judging-sessions") {
      return "#5"
    }

    if (hash == "#message") {
      return "#4"
    }

    if (hash == "#awards") {
      return "#8"
    }

    return ""
  }

  const getHashVolunteer = (hash) => {

    if (hash == "#1" || hash == "") {
      return ""
    }

    if (hash == "#6") {
      return "#scoring-sessions"
    }

    if (hash == "#5") {
      return "#judging-sessions"
    }

    if (hash == "#4") {
      return "#message"
    }

    if (hash == "#8") {
      return "#awards"
    }

    return ""
  }

  const onSwitchBoard = (data) => {
    const { id } = data;

    const role = roles.find(r => r.id === id)
    selectRole(role.name, id, role.path)

    let eventPath;
    let hash;

    if (role.id == "AdminDashboard") {
      hash = getHashAdmin(window.location.hash)
      eventPath = window.location.pathname.replace('event-overview', 'events')
      history.push(eventPath + hash)
    }

    if (role.id == "VolunteerDashboard") {
      hash = getHashVolunteer(window.location.hash)
      eventPath = window.location.pathname.replace('events', 'event-overview')
      history.push(eventPath + hash)
    }
  }

  const roles = [
    {
      name: t('adminDashboardLabel'),
      id: 'AdminDashboard',
      path: 'admin',
      disabled: !canAccessAdminDashboard,
    },
    {
      name: t('volunteerDashboardLabel'),
      id: 'VolunteerDashboard',
      path: 'volunteer',
      disabled: !canAccessVolunteerDashboard
    }
  ]

  const generateStyles = (size = 1440) => {
    const basePosition = size && size < 1300 ? size - 1300 : 0;
    const l_width = 180 + basePosition;
    const r_width = 104 + basePosition;
    const multiplier = 1
    const ratio = size / 1440 * multiplier;
    const width = ratio * (l_width > 0 ? l_width : 0);
    const height = ratio * 183;
    const left = ratio * (0);

    const rightWidth = ratio * (r_width > 0 ? r_width : 0);
    const rightHeight = ratio * 182;
    const rightTop = ratio * (-37);
    const rightRight = ratio * (0);
    const leftStyle = { height: `${height}px`, width: `${width}px`, top: `${0}px`, left: `${left}px` }
    const rightStyle = { height: `${rightHeight}px`, width: `${rightWidth}px`, top: `${rightTop}px`, right: `${rightRight}px` };

    return { left: leftStyle, right: rightStyle }
  }

  const { left, right } = generateStyles(size);

  return (
    <div className='event-header' ref={wrapperRef}>
      <div className='event-header__content'>
        <div className='event-header__logo-wrapper'>
          <Link to='/admin' href='/' className='event-header__back'>
            {rtl_direction ? ` ${t('backToAllEventsLabel')}` : null}
            {' '} <LeftOutlined className='event-header__arrow' />
            {!rtl_direction ? ` ${t('backToAllEventsLabel')}` : null}
          </Link>
          <EventProgramLogos programId={eventProgramId} />

        </div>

        <div className='event-header'>
          <Heading tag='2' textColor='white'>{eventTitle}</Heading>
        </div>

        <div className='event-header__title'>


          {/* <div className='switch-with-time-container'>
            <div className='switch-with-time'> */}
          {(canAccessAdminDashboard && haveAccessToViewAdmin()) && (
            <>
              <div className='sort-section-admin m-r-40'>
                <div className='m-r-10 white'>View as: <span><strong>Admin</strong></span> </div>
                <div className=''><Switch className='custom-switch' checked={dashboard.selectedRoleId == "AdminDashboard" ? false : true} onChange={(e) => {
                  onSwitchBoard(dashboard.selectedRoleId == "AdminDashboard" ? roles[1] : roles[0])
                }} /></div>
                <div className='m-l-10'><strong className='white'>Volunteer</strong></div>
              </div>
            </>
          )}
          <span className='event-header__info m-t-10'>
            <p>
              {eventDateRange}
            </p>
            <span className='event-header__point'>&#8226;</span>
            <p>
              {eventLocation}
            </p>
          </span>
          {/* </div>
          </div> */}
        </div>
      </div>
      <div className="left-shape" style={left} />
      <div className="right-shape" style={right} />
    </div>
  );
};

EventHeader.propTypes = {
  eventTitle: PropTypes.string,
  eventLocation: PropTypes.string,
  eventDateRange: PropTypes.string,
  eventProgramId: PropTypes.string,
  selectRole: PropTypes.func.isRequired,
  dashboard: PropTypes.object.isRequired,
  canAccessVolunteerDashboard: PropTypes.bool.isRequired,
  canAccessAdminDashboard: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired
};

EventHeader.defaultProps = {
  eventTitle: '',
  eventDateRange: '',
  eventLocation: '',
  eventProgramId: ''
};

const mapDispatchToProps = {
  selectRole: setDashboardRole
}

const mapStateToProps = (state) => {
  const {
    dashboard,
    auth,
    event: { eventDetails, eventAwards }
  } = state;

  const {
    canAccessVolunteerDashboard,
    canAccessAdminDashboard,
    userType
  } = auth;
  return {
    eventDetails,
    dashboard,
    canAccessVolunteerDashboard,
    canAccessAdminDashboard,
    userType
  }
}


// export default EventHeader;
export default connect(mapStateToProps, mapDispatchToProps)(EventHeader);
