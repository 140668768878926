import React from 'react';
import Paragraph from 'shared/components/Paragraph';
import Divider from 'shared/components/Divider';

const AwardTeam = ({ teamNumberName, assignments }) => {
  return (
    <div>
      <Paragraph size='medium'>
        {teamNumberName}
      </Paragraph>
      {assignments.map(({ awardName, place }) => (
        <div key={awardName} className='m-b-6'>
          <Paragraph size='medium' weight='bold'>
            {awardName}
          </Paragraph>
          <Paragraph size='xsmall'>
            {place || '-NA-'}
          </Paragraph>
        </div>
      ))}
      <Divider margin={12} />
    </div>
  );
};

export default AwardTeam;
