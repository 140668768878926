import React from 'react';
import PropTypes from 'prop-types';
import '../../../../../features/PanelManagement/styles/JudgesList.scss';
import JudgesListHeader from './JudgesListHeader';
import JudgeTile from './JudgeTile';

const JudgesList = (props) => {

  const { judges, judgesListTitle} = props;
  const totalJudges = judges.length;
  
  const assignedJudgesList = [];
  const unassignedJudgesList = []
  judges.forEach(i => {
    if (i.panelId) {
      assignedJudgesList.push(i)
    } else {
      unassignedJudgesList.push(i)
    }
  
  })

  const assignedJudgesCount = assignedJudgesList.length;

  const unassignedJudgesCount = totalJudges - assignedJudgesCount;



  return (
    <div>
      <JudgesListHeader totalCount={totalJudges} unAssignedCount={unassignedJudgesCount} title={judgesListTitle} />
      <div className='judges-list__unassigned'>
        {unassignedJudgesList.map(j => (
          <JudgeTile key={j.userId} data={j} />
        ))}
        

      </div>
      <div className='judges-list__assigned'>
        {assignedJudgesList.map(j => (
          <JudgeTile isAssigned key={j.userId} data={j} />
        ))}
      </div>
    </div>
  );
};

JudgesList.propTypes = {
  judgesListTitle: PropTypes.string,
  judges: PropTypes.array,
  assignedJudges: PropTypes.array
};

JudgesList.defaultProps = {
  judgesListTitle: 'Judges',
  judges: [],
  assignedJudges: []
}

export default JudgesList;