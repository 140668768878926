import React, { useState } from 'react';
import Icon from 'shared/components/Icon';
import '../../../../styles/judgingRubric/JudgeResultCollapseHeader.scss';

export const CollapsableHeader = (props)=>{
  const {leftTitle, rightTitle, status} = props;
  const [collapsed, setCollapsed] = useState(!status)

  const toggleCollapsed = ()=>{
    setCollapsed(!collapsed)
  }

  return (
    <>
      <div onClick={toggleCollapsed} className="collapse-header-judging">
        <div className="collapse-header-judging_title-section">
          <span className="collapse-header-judging_title-section-left">{leftTitle}</span>
          <span>{rightTitle}</span>
        </div>
        <div className="collapse-header-judging_title-section-icon">
        {collapsed ? <Icon type="downArrowBlue"/> : <Icon type="upArrowBlue"/>}
        </div>
      </div>
      {collapsed ? null : props.children}
    </>
  )
}