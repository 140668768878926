import { CreateClient } from 'utils/apiClient';
import { getAuth0Token } from  'features/store'

export class UploadAPI extends CreateClient {
  constructor (runtimeConfig) {
    super({
      getToken: getAuth0Token,
      showLog: process.env.NODE_ENV === 'development'
    })
    this.http.interceptors.request.use(config => {
      return config
    })
    this.http.interceptors.response.use(
      response => {
        return response.data},
      error => Promise.reject(error)
    )
  }
  static apiInstance = null;
  static CreateInstance(runtimeConfig) {
    this.apiInstance = new UploadAPI(runtimeConfig);
  }

  static GetInstance(){
    return this.apiInstance;
  }
}

//export default UploadAPI
