import React from 'react';
import PropTypes from 'prop-types';

const Clock = ({fill}) => {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      <defs>
        <path d="M8,0 C3.58125,0 0,3.58125 0,8 C0,12.41875 3.58125,16 8,16 C12.41875,16 16,12.41875 16,8 C16,3.58125 12.41875,0 8,0 Z M12.8609375,12.8609375 C12.2296875,13.4921875 11.49375,13.9890625 10.675,14.334375 C9.828125,14.69375 8.928125,14.875 8,14.875 C7.071875,14.875 6.171875,14.69375 5.325,14.3359375 C4.50625,13.9890625 3.771875,13.49375 3.1390625,12.8625 C2.5078125,12.23125 2.0109375,11.4953125 1.665625,10.6765625 C1.30625,9.828125 1.125,8.928125 1.125,8 C1.125,7.071875 1.30625,6.171875 1.6640625,5.325 C2.0109375,4.50625 2.50625,3.771875 3.1375,3.1390625 C3.76875,2.5078125 4.5046875,2.0109375 5.3234375,1.665625 C6.171875,1.30625 7.071875,1.125 8,1.125 C8.928125,1.125 9.828125,1.30625 10.675,1.6640625 C11.49375,2.0109375 12.228125,2.50625 12.8609375,3.1375 C13.4921875,3.76875 13.9890625,4.5046875 14.334375,5.3234375 C14.69375,6.171875 14.875,7.071875 14.875,8 C14.875,8.928125 14.69375,9.828125 14.3359375,10.675 C13.9890625,11.49375 13.49375,12.2296875 12.8609375,12.8609375 Z M11.453125,7.875 L8.5625,7.875 L8.5625,4.125 C8.5625,3.8140625 8.3109375,3.5625 8,3.5625 L8,3.5625 C7.6890625,3.5625 7.4375,3.8140625 7.4375,4.125 L7.4375,7.875 L7.4375,7.875 C7.4375,8.496875 7.940625,9 8.5625,9 L8.5625,9 L11.453125,9 C11.7640625,9 12.015625,8.7484375 12.015625,8.4375 L12.015625,8.4375 C12.015625,8.1265625 11.7640625,7.875 11.453125,7.875 Z" id="path-clock-time" />
      </defs>
      <g id="Event" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Event-Management---Single-Event---Edit-Referee-Session---PDP-Admin" transform="translate(-722.000000, -470.000000)">
          <g id="Group-25" transform="translate(139.000000, 10.000000)">
            <g id="Group-55" transform="translate(111.000000, 158.000000)">
              <g id="Group-51" transform="translate(201.000000, 166.000000)">
                <g id="Group-53" transform="translate(79.000000, 0.000000)">
                  <g id="Group-36" transform="translate(0.000000, 96.000000)">
                    <g id="Group-3" transform="translate(0.000000, 24.000000)">
                      <g id="Icon/Time" transform="translate(192.000000, 16.000000)">
                        <mask id="mask-2" fill="white">
                          <use xlinkHref="#path-clock-time" />
                        </mask>
                        <use id="Mask" fill={fill} xlinkHref="#path-clock-time" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Clock.propTypes = {
  fill: PropTypes.string
};

Clock.defaultProps = {
  fill: 'currentColor'
}

export default Clock;