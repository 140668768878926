import { API } from 'utils/api';
import Country from 'models/Country';
import { queryStringify } from 'utils/query';

//const { http } = API;

const baseUrl = '/api/countries';

export const getCountries = async (input) => {
  if (!input) return [];
  const url = `/api/countries?filter[name]=like:${input}`;
  const { data } = await API.GetInstance().http.get(url);
  return data.map((c) => new Country(c));
};

export const getCountriesBy = async (filter) => {
  const queryStr = queryStringify({ filter });
  const url = `${baseUrl}${queryStr}`;
  const { data } = await API.GetInstance().http.get(url);
  return data.map((c) => new Country(c));
}