import React from 'react';
import PropTypes from 'prop-types';


const RubricSpreadsheetIcon = ({fill}) => {
  return (
    <svg width="60px" height="48px" viewBox="0 0 60 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      <g id="Judging-and-Awards" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Judging-Session---FTC---Upload-Form---Judge" transform="translate(-761.000000, -1305.000000)" fill={fill}>
          <g id="Group-20" transform="translate(250.000000, 980.000000)">
            <g id="Group-19" transform="translate(40.000000, 85.000000)">
              <g id="Group-21" transform="translate(0.000000, 68.000000)">
                <g id="Group-15" transform="translate(0.000000, 136.000000)">
                  <g id="Group-10-Copy" transform="translate(446.000000, 0.000000)">
                    <g id="Group-12" transform="translate(25.000000, 36.000000)">
                      <polygon id="Path" points="48 0 48 12 60 12" />
                      <polygon id="Path" points="45 3 3 3 3 45 57 45 57 15 60 15 60 48 0 48 0 0 45 0" />
                      <path d="M9,15 L9,36 L51,36 L51,15 L9,15 Z M36,18 L36,24 L24,24 L24,18 L36,18 Z M12,18 L21,18 L21,24 L12,24 L12,18 Z M12,33 L12,27 L21,27 L21,33 L12,33 Z M24,33 L24,27 L36,27 L36,33 L24,33 Z M48,33 L39,33 L39,27 L48,27 L48,33 Z M48,24 L39,24 L39,18 L48,18 L48,24 Z" id="Shape" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

RubricSpreadsheetIcon.propTypes = {
  fill: PropTypes.string
}


RubricSpreadsheetIcon.defaultProps = {
  fill: 'currentColor'
}

export default RubricSpreadsheetIcon;