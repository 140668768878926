import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Heading from 'shared/components/Heading';
import { API } from 'utils/api';
import { useTranslation } from 'react-i18next';
import Tooltip from 'shared/components/Tooltip';
import Score from 'shared/components/Icon/icons/Score';
import GrandOpening from 'shared/components/Icon/icons/GrandOpening';
import Trophy from 'shared/components/Icon/icons/Trophy';
import LawIcon from '../../../../../shared/components/Icon/icons/Law';
import ScheduleTimestamp from 'models/ScheduleTimestamp';
import Icon from '@ant-design/icons';
import ListLoading from 'shared/components/Spinner/ListLoading';
import TimestampForm from './TimestampForm';
import '../../../styles/ScheduleContainer/EventTimestampModal.scss';

const infoText = `
Add date, time, and session URL to save and publish this activity. Once Published the activity will be visible to invited participants.
`
const INITIAL_STATE = {
  isLoading: true,
  isError: false,
  responseData: {},
  responseIncluded: [],
  teamName: '',
  teamNumber: ''
}

const TimestampModalContent = props => {
  const {
    title,
    iconType,
    timestampId,
    closeModal,
    time_zone
  } = props;
  const [state, setState] = useState(INITIAL_STATE);
  const { t } = useTranslation()

  useEffect(() => {
    const isMounted = true;
    const fetchTimestamp = async(id) => {
      setState(prevState => ({...prevState, isLoading: true}))
      try {
        const response = await API.GetInstance().http.get(`/api/time_stamps/${id}`)
        const {data, included} = response;
        
        if (isMounted) {
          setState(prevState => ({
            ...prevState,
            isLoading: false,
            responseData: data,
            responseIncluded: included,
            isError: false
          }))
        }
      } catch(err) {
        setState(prevState => ({...prevState, isLoading: false, isError: true}))

        throw err;
      }
    }

    if(timestampId) {
      fetchTimestamp(timestampId)
    }
  }, [timestampId])
  const renderIcon = () => {
    switch(iconType) {
      case 'opening':
        return GrandOpening;
      case 'trophy':
        return Trophy;
      case 'judging':
        return LawIcon;
    default:
      return Score
    }
  }

  if (state.isLoading) {
    return (
      <ListLoading loading text={t('loadingSessionLabel')} />
    );
  }
  if (state.isError) {
    return <p className='m-t-36'>{t('errorLabel')}</p>
  }


  const timestampData = new ScheduleTimestamp(state.responseData, state.responseIncluded);

  const initialDateValue = timestampData.getMomentDate(time_zone);
  const initialTimeValue = timestampData.getMomentTime(time_zone);

  return (
    <div className='m-t-36 event-timestamp-modal'>
      <div className='schedule-session-modal__title m-b-24'>
        <Heading tag={2}>{title}</Heading>
        <Tooltip text={infoText} placement='left' />
      </div>
      <div className='schedule-session-modal__content'>
        <div className={`event-timestamp-modal__rectangle event-timestamp-modal__rectangle--${iconType}`}>          
          {' '}
          <Icon component={renderIcon()} className='schedule-session-modal__icon' />
        </div>
        <TimestampForm
          timestampId={timestampId}
          dateValue={initialDateValue}
          timeValue={initialTimeValue}
          closeModal={closeModal}
          time_zone={time_zone}
        />

      </div>
    </div>
  );
};

TimestampModalContent.propTypes = {
  title: PropTypes.string,
  iconType: PropTypes.string,
  timestampId: PropTypes.string,
};

TimestampModalContent.defaultProps = {
  title: 'Timestamp',
  iconType: 'judging',
  timestampId: ''
}

export default TimestampModalContent;