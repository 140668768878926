export const eventTimes = (start_time=0, ap=['am','pm'], time_interval=15, is24Hour = false) =>{
  let times = [];
  for (var i=0; start_time< 24 * 60; i++) {
     // getting hours of day in 0-24 format
    var hh = Math.floor(start_time / 60);

     // getting minutes of the hour in 0-55 format
    var mm = (start_time % 60);

    //format time;
    
    let formated_hour = !is24Hour ? ("0" + (hh % 12)).slice(-2) : ("0" + (hh)).slice(-2);
    let formated_minutes = ("0" + mm).slice(-2); 
    if (formated_hour === "00") formated_hour = is24Hour ? "00" : "12"
    let time = formated_hour + ":" + formated_minutes

    // apend am/pm 
    if(!is24Hour){
      time = time + " " + ap[Math.floor(hh / 12)]
    }

    // pushing data in array in [00:00 - 12:00 AM/PM format]
    times[i] = time

    start_time = start_time + time_interval;
  }

  return times;
}