const ip4Section = {
    id: 'IP4',
    // label: 'ipv1Label',
    title: 'exploreReviewingSheetCodingLabel',
    // description: 'identifyDescriptionLabel',
    fields: [
        {
            id: 'coding_1',
            value: 'exploreReviewingSheetCodingMotorizedQuestionLabel',
            answear: 'exploreReviewingSheetCodingMotorizedAnswerLabel'
        },
        {
            id: 'coding_2',
            value: 'exploreReviewingSheetCodingMotorizedHowQuestionLabel',
            answear: 'exploreReviewingSheetCodingMotorizedHowAnswerLabel'
        },
    ]
}

export default ip4Section;