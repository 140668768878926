import * as manualTypes from './types/manualFirstUserTypes';

const DEFAULT_SELECTED_FIRST_USER = {
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  phone: undefined,
}

const DEFAULT_ADD_FIRST_USER_STATE = {
  manualFirstUsersList: [],
  selectedFirstUser: DEFAULT_SELECTED_FIRST_USER,
  isInvitingFirstUser: false,
  isAutocompleteFirstUserSelected: false,
}

export default (state = DEFAULT_ADD_FIRST_USER_STATE, action) => {
  const {type} = action;
  switch(type) {
    case manualTypes.ADD_FIRST_USER_TO_MANUAL_LIST:
      return {
        ...state,
        manualFirstUsersList: action.payload.manualFirstUsersList
      }
    case manualTypes.DELETE_FIRST_USER_FROM_MANUAL_LIST:
      return {
        ...state,
        manualFirstUsersList: action.payload.manualFirstUsersList
      }
    case manualTypes.UPDATE_SELECTED_FIRST_USER:
      return {
        ...state,
        selectedFirstUser: {
          ...state.selectedFirstUser,
          [action.payload.fieldName]: action.payload.value
        }
      }
    case manualTypes.RESET_FIRST_USERS_MANUAL_LIST:
      return {
        ...state,
        manualFirstUsersList: []
      }
    case manualTypes.RESET_FIRST_USER_MANUAL_FORM:
      return {
        ...state,
        selectedFirstUser: DEFAULT_SELECTED_FIRST_USER,
        isAutocompleteFirstUserSelected: false
      }
    case manualTypes.SET_IS_AUTOCOMPLETE_FIRST_USER_SELECTED:
      return {
        ...state,
        isAutocompleteFirstUserSelected: action.payload.isAutocompleteFirstUserSelected
      }
    case manualTypes.SET_SELECTED_FIRST_USER:
      return {
        ...state,
        selectedFirstUser: action.payload.user
      }
    default:
      return state;
  }


}