import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AutoComplete from 'shared/components/FormAutoComplete';
import Input from 'shared/components/Input';
import text from 'shared/translations/en/volunteersModal';
import { Col, Row, notification, Form, Button } from 'antd';
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import UserOption from 'shared/components/UserAutocomplete/UserAutocompleteOption';
import { connect } from 'react-redux';
import {
  handleAddVolunteerToList,
  handleUpdateSelectedVolunteer,
  handleSetSelectedAutocompleteVolunteer,
  handleResetSelectedAutocompleteVolunteer,
  handleInviteVolunteerNow,
} from 'features/EventContainer/redux/actions/volunteersActions';
import { handleGetEventRoles } from 'features/EventContainer/redux/actions/eventRoles';
import Checkbox from 'shared/components/Checkbox';
import validateEmail from 'utils/validateEmail';
import { useParams } from 'react-router-dom';
import * as userService from 'utils/services/users';
import {
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FTC_ID
} from 'shared/constants/programIds';
import { phoneRegexPattern } from 'shared/constants/regexPatterns';
import {
  COACH_ID,
  REFEREE_ID,
  HEAD_REFEREE_ID,
  JUDGE_ID,
  JUDGE_ADVISOR_ID,
  roleTypeTranslation
} from 'shared/constants/eventRoleTypes';

import '../../../../../../shared/components/UserAutocomplete/styles/UserAutocomplete.scss';

const LIMITED_EVENT_ROLES_PROGRAM_TYPES = [
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FTC_ID
]

/*
Head Referee and Referee Roles removed if the program type is:
FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FTC_ID
*/

const RESTRICTED_ROLES = [
  REFEREE_ID,
  HEAD_REFEREE_ID
]

const FormItem = Form.Item;

const { Option } = AutoComplete;

const ManualForm = props => {
  const { t, i18n } = useTranslation()
  const rtl_direction = i18n.dir() === 'rtl';

  const [disabledForm, setDisabledForm] = useState(true);
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false)
  const {
    addVolunteer,
    getEventRoles,
    eventRoleTypes,
    volunteer,
    handleVolunteerDataChange,
    handleUserSelect,
    isAutocompleteVolunteerSelected,
    handleResetSelectedAutocompleteUser,
    handleInviteNow,
    invitees,
    eventProgramId,
    isInvitingVolunteer,
    emailLanguage,
    is_FLLC_event
  } = props;

  const [form] = Form.useForm();

  // Remove COACH from the event roles list
  const volunteerEventRoleTypes = eventRoleTypes.filter(role => role.id !== COACH_ID);
  let filteredVolunteerEventRoles = [];
  const { id: eventId } = useParams();
  if (LIMITED_EVENT_ROLES_PROGRAM_TYPES.includes(eventProgramId)) {
    filteredVolunteerEventRoles = volunteerEventRoleTypes.filter(val => !RESTRICTED_ROLES.includes(val.id))
  } else {
    filteredVolunteerEventRoles = [...volunteerEventRoleTypes];
  }

  const isEmailTouched = form.isFieldTouched('email');
  const isFirstNameTouched = Boolean(form.getFieldValue('firstName'));
  const isLastNameTouched = Boolean(form.getFieldValue('lastName'));
  const isPhoneTouched = Boolean(form.getFieldValue('phone'));

  const clearForm = () => {
    form.resetFields();
    setDisabledForm(true);
    handleResetSelectedAutocompleteUser()
  }

  const inviteNow = (e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields().then(async (values, err) => {
      const volunteerPayload = {
        ...volunteer,
        eventRoles: values.roles,
        culture: emailLanguage
      }

      handleInviteNow(volunteerPayload, eventId, clearForm)
    });
  }

  useEffect(() => {
    getEventRoles()
  }, [])

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const addToList =  (e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields().then(async (values, err) => {
      if (err) {
        throw err;
      }
      const volunteerPayload = {
        ...volunteer,
        eventRoles: values.roles,
        culture: emailLanguage
      }

      if (invitees.length) {
        if (invitees.filter(i => i.email === volunteer.email).length > 0) {

          notification.warn({
            message: 'Warning',
            description: 'User has already been added to the list.',
          })
        } else {
            addVolunteer(volunteerPayload, clearForm);
            clearForm();
        }
      } else {
          addVolunteer(volunteerPayload, clearForm);
          clearForm();
      }
    });
  }

  const handleEmailChange = (value) => {
    handleVolunteerDataChange('email', value);

    const isValidEmail = validateEmail(value);
    if (isValidEmail) {
      setDisabledForm(false)
    }

    if (!isValidEmail) {
      setDisabledForm(true)
    }

    if (isAutocompleteVolunteerSelected) {
      clearForm()
    }

  }


  const handleChange = (fieldName, e) => {
    const { value } = e.target;
    handleVolunteerDataChange(fieldName, value);
  }

    const onSelect = (email) => {
        const selectedUser = users.find(u => u.email === email);
        setDisabledForm(false);
        handleUserSelect(selectedUser);
        form.setFieldValue('firstName', selectedUser.firstName);
        form.setFieldValue('lastName', selectedUser.lastName);
        form.setFieldValue('phone', selectedUser.phone);
    }

  const handleUserSearch = async (input) => {
    try {
      setLoadingUsers(true);
      const formattedUsers = await userService.getUsersLikeEmail(input);
      setUsers(formattedUsers);
      setLoadingUsers(false);
    } catch (err) {
      setLoadingUsers(false);
      throw err;
    }
  };

  const getRoleName = (role) => {
    const roleId = role.id;
    const keyList = roleTypeTranslation[roleId];
    if(Array.isArray(keyList) && keyList.length >= 2) {
      const key = is_FLLC_event ? keyList[0] : keyList[1];
      if(key) return t(key);
    }
    return role.attribute.name;
  }


  return (
    <div className='manual__form'>
      <Form hideRequiredMark name='AddVolunteerForm' form={form} initialValues={{email: volunteer.email, firstName: volunteer.firstName, lastName: volunteer.lastName, phone: volunteer.phone}}>
        <FormItem name='email' rules={[{ required: true, message: t('enterEmailLabel') },{ type: 'email', message: t('enterValidEmailLabel') },]}>
            <AutoComplete
              placeholder={t('emailLabel')}
              onChange={handleEmailChange}
              onSearch={handleUserSearch}
              onSelect={onSelect}
              dataSource={users.map(u => (
                <Option key={u.id} value={u.email} text={u.email}>
                  <UserOption name={`${u.firstName} ${u.lastName}`} email={u.email} id={u.id} />
                </Option>
              ))}
            />
        </FormItem>
        <FormItem name='firstName' rules={[{ required: true, message: t("enterFirstNameLabel") }]}>
          <Input size='large' placeholder={t('firstNameLabel')} disabled={disabledForm || isAutocompleteVolunteerSelected} onChange={(e) => { handleChange('firstName', e) }} />
        </FormItem>
        <FormItem name='lastName' rule={[{ required: true, message: t('enterLastNameLabel') }]}>
            <Input size='large' placeholder={t('lastNameLabel')} disabled={disabledForm || isAutocompleteVolunteerSelected} onChange={(e) => { handleChange('lastName', e) }} />
        </FormItem>
              {/*
        <FormItem name='phone' rules={[{ pattern: phoneRegexPattern, message: 'Enter valid phone number.' }]}>
            */}
        <FormItem name='phone'>
          <Input size='large' placeholder={t('phoneOptionalLabel')} disabled={disabledForm || isAutocompleteVolunteerSelected} onChange={(e) => { handleChange('phone', e) }} />
        </FormItem>
        <FormItem name='roles' label={t('rolesLabel')} rules={[{ required: true, message: 'Select Role(s)' }]}>
            <Checkbox.Group style={{ width: '100%' }} disabled={disabledForm} className='manual__form__checkbox'>
              <Row>
                {filteredVolunteerEventRoles.map(role => (
                  <Col span={12}>
                    <Checkbox value={role.id} key={role.id}><span className={disabledForm ? 'checkbox-label--disabled' : 'checkbox-label'}>{getRoleName(role)}</span></Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
        </FormItem>
        <div className='manual--btn-wrapper'>
          <FormItem>
            <Button disabled={disabledForm} onClick={inviteNow} loading={isInvitingVolunteer}>{t('inviteNowLabel')}</Button>
          </FormItem>
          <p className={disabledForm ? 'paragraph--silver paragraph--small' : 'paragraph--charcoal paragraph--small'}>{` ${t('orLabel')} `}</p>
          <FormItem>
            <Button
              disabled={isInvitingVolunteer || disabledForm}
              type='default'
              styleType='secondary'
              size='large'
              additionalClassNames='p-r-14 p-l-14'
              onClick={addToList}
            >
              {`${t('addToListLabel')}`}
              {' '}
              <Icon type={rtl_direction ? "left" : "right"} />
            </Button>
          </FormItem>
        </div>
      </Form>

    </div>
  );
};

ManualForm.propTypes = {
  form: PropTypes.object.isRequired,
  addVolunteer: PropTypes.func.isRequired,
  getEventRoles: PropTypes.func.isRequired,
  eventRoleTypes: PropTypes.array.isRequired,
  volunteer: PropTypes.object.isRequired,
  handleVolunteerDataChange: PropTypes.object.isRequired,
  handleUserSelect: PropTypes.func.isRequired,
  isAutocompleteVolunteerSelected: PropTypes.bool.isRequired,
  handleResetSelectedAutocompleteUser: PropTypes.bool.isRequired,
  handleInviteNow: PropTypes.func.isRequired,
  invitees: PropTypes.array.isRequired,
  eventProgramId: PropTypes.string.isRequired,
  isInvitingVolunteer: PropTypes.bool.isRequired
};

const mapDispatchToProps = {
  addVolunteer: handleAddVolunteerToList,
  getEventRoles: handleGetEventRoles,
  handleVolunteerDataChange: handleUpdateSelectedVolunteer,
  handleUserSelect: handleSetSelectedAutocompleteVolunteer,
  handleResetSelectedAutocompleteUser: handleResetSelectedAutocompleteVolunteer,
  handleInviteNow: handleInviteVolunteerNow,
}

const mapStateToProps = (state) => {
  const { event: { eventRoles, eventVolunteers, eventDetails } } = state;
  const { eventRoleTypes, isFetchingEventRoleTypes } = eventRoles;
  const { isAutocompleteVolunteerSelected, selectedVolunteer, addVolunteersManuallyList, isInvitingVolunteer } = eventVolunteers;
  const { eventProgramId, is_FLLC_event } = eventDetails;
  return {
    eventRoleTypes,
    isFetchingEventRoleTypes,
    volunteer: selectedVolunteer,
    isAutocompleteVolunteerSelected,
    invitees: addVolunteersManuallyList,
    eventProgramId,
    isInvitingVolunteer,
    is_FLLC_event
  }


}

export default connect(mapStateToProps, mapDispatchToProps)(ManualForm);