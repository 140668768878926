import { Row, Col } from 'antd';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import BtnPrimary from 'shared/components/Button/BtnPrimary';
import AddLink from 'shared/components/TopBar/AddLink';
import PublishStatusBar from 'shared/components/PublishStatusBar';
import ListLoading from 'shared/components/Spinner/ListLoading';
import { PENDING, PUBLISHABLE, PUBLISHED } from 'shared/constants/publishStatus';
import {
  fetchEventAwardTeams,
  fetchEventAwards,
  submitEventAwards
} from 'features/EventOverviewContainer/redux/actions/assignedAwards';
import {
  JUDGE_ID, JUDGE_ADVISOR_ID
} from 'shared/constants/eventRoleTypes';
import {
  FLL_CHALLENGE_ID
} from 'shared/constants/programIds';
import { useTranslation } from 'react-i18next';
import TeamsList from './TeamsList';
import DownloadInstructions from './DownloadInstructions'
import AwardNominateCard from './AwardNominateCard';
import AddAwardModal from './AddAwardModal';
import AwardConfirmModal from './AwardConfirmModal';
import BackToBoard from '../BackToBoard';

import '../../styles/EventOverviewAwards.scss';

const leftColProps = {
  xs: 24, md: 8
};

const rightColProps = {
  xs: 24, md: 16
};

const NonFLLCAssignedAwards = (props) => {
  const { canEdit, canSubmit, status, awards, isFetchingAwards, isSubmittingAwards, userEventRoleIds, eventProgramId, is_FLLC_event } = props;
  const [visible, setVisible] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(false);
  const [canUnsubmit, setUnsubmit] = useState(false);
  const { eventId } = useParams();
  const { t } = useTranslation()

  const instructionsRoles = [JUDGE_ID, JUDGE_ADVISOR_ID];
  const canViewInstructions = instructionsRoles.some(i => userEventRoleIds.includes(i)) && (eventProgramId === FLL_CHALLENGE_ID);

  const pending = status === PENDING;
  const publishable = status === PUBLISHABLE;
  const published = status === PUBLISHED;

  const editable = canEdit && pending;

  const unSubProp = publishable && canEdit && { onClick: () => setUnsubmit(true) };

  useEffect(() => {
    props.fetchEventAwardTeams(eventId);
    props.fetchEventAwards(eventId);
  }, [eventId]);

  const handleSubmitAward = async () => {
    await props.submitEventAwards(canUnsubmit);
    setUnsubmit(false);
    setSubmitVisible(false);
  };

  return (
    <div className='c-event-overview-awards'>
      <AddAwardModal
        visible={visible}
        onCancel={() => setVisible(false)}
      />
      <AwardConfirmModal
        is_FLLC_event={is_FLLC_event}
        isSubmitted={publishable}
        visible={submitVisible}
        onSubmit={handleSubmitAward}
        onCancel={() => setSubmitVisible(false)}
        loading={isSubmittingAwards}
      />
      <div className='c-event-overview-awards__actions'>
        <div className="awards-title">Awards</div>
        <PublishStatusBar
          published={(publishable || published) && !canUnsubmit}
          publishText={publishable ? t('awardsSubmittedLabel') : "Awards Published"}
          publishable={isFetchingAwards || canSubmit}
          {...unSubProp}
        />
        {(editable || canUnsubmit) && (
          <BtnPrimary disabled={isFetchingAwards || !canSubmit} onClick={() => setSubmitVisible(true)}>
            {canUnsubmit ? t('withdrawAwardsWarningLabel') : t('submitAwardsLabel')}
          </BtnPrimary>
        )}
      </div>
      <Row gutter={60}>
        {!published && (
          <Col {...leftColProps}>
            <TeamsList />
          </Col>
        )}
        <Col {...(!published && rightColProps)}>
          {canViewInstructions ? (
            <DownloadInstructions eventId={eventId}  is_FLLC_event={is_FLLC_event}/>
          ) : null}
          {editable && (
            <div className='c-event-overview-awards__add-award'>
              <AddLink
                addClick={() => setVisible(true)}
                color='primaryBlue'
                customText={t('addAwardLabel')}
              />
            </div>
          )}
          <ListLoading loading={isFetchingAwards} text='Loading Awards' />
          {awards.map((award, i) => (
            <AwardNominateCard editable={editable} key={i} {...award} />
          ))}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ eventOverview, assignedAwards }) => {
  const { eventProgramId, userEventRoleIds,  is_FLLC_event } = eventOverview;
  const { awards, status, meta: { isFetchingAwards, isSubmittingAwards } } = assignedAwards;
  const allAssignments = [];
  awards.forEach(({ assignments }) => {
    allAssignments.push(...assignments);
  });
  const canSubmit = allAssignments.length > 0 && !allAssignments.find(({ place }) => !place);

  return {
    eventProgramId,
    awards,
    status,
    canSubmit,
    isFetchingAwards,
    isSubmittingAwards,
    userEventRoleIds,
    is_FLLC_event
  };
};

const mapDispatchToProps = {
  fetchEventAwardTeams,
  fetchEventAwards,
  submitEventAwards
};

export default connect(mapStateToProps, mapDispatchToProps)(NonFLLCAssignedAwards);
