import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'shared/components/Checkbox';
import { useTranslation } from 'react-i18next';
import Input from 'shared/components/Input';
import { connect } from 'react-redux';
import {
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FTC_ID
} from 'shared/constants/programIds';
import {
  COACH_ID,
  REFEREE_ID,
  HEAD_REFEREE_ID,
  JUDGE_ADVISOR_ID,
  roleTypeTranslation
} from 'shared/constants/eventRoleTypes';
import { handleGetEventRoles } from 'features/EventContainer/redux/actions/eventRoles';
import {
  handleEditEventAssignments,
  editVolunteerInfo,
  deleteEventVolunteer
} from 'features/EventContainer/redux/actions/volunteers/editVolunteer';
import ConfirmationModal from 'shared/components/ConfirmModal';
import { useParams } from 'react-router-dom';
import { Col, Row, Form, Button } from 'antd';
import Container from 'shared/components/Container';

const FormItem = Form.Item;

const LIMITED_EVENT_ROLES_PROGRAM_TYPES = [
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FTC_ID
]

/*
  Head Referee and Referee Roles removed if the program type is:
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FTC_ID
*/
const RESTRICTED_ROLES = [
  REFEREE_ID,
  HEAD_REFEREE_ID
]

const rowProps = {
  type: 'flex', gutter: 20,
  className: 'm-t-20'
};

const EditVolunteerModalContent = props => {
  const { t } = useTranslation();
  const { id: eventId } = useParams();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [form] = Form.useForm();

  const {
    is_FLLC_event,
    isFetchingEventRoleTypes,
    eventRoleTypes,
    eventProgramId,
    getEventRoles,
    selectedVolunteer,
    isSavingVolunteer,
    isDeletingEventVolunteer,
    handleSaveAssignments
  } = props;
  const volunteerEventRoleTypes = eventRoleTypes.filter(role => role.id !== COACH_ID);
  let filteredVolunteerEventRoles = [];

  useEffect(() => {
    if (!eventRoleTypes.length)
      getEventRoles()
  }, [eventRoleTypes])

  if (!selectedVolunteer || isFetchingEventRoleTypes) {
    return null;
  }
  const { email, firstName, lastName, roles, mobilePhone, id, eventRoleAssignments } = selectedVolunteer;

  const volunteerAssignedRoles = roles.map(r => r.id);


  if (LIMITED_EVENT_ROLES_PROGRAM_TYPES.includes(eventProgramId)) {
    filteredVolunteerEventRoles = volunteerEventRoleTypes.filter(val => !RESTRICTED_ROLES.includes(val.id))
  } else {
    filteredVolunteerEventRoles = [...volunteerEventRoleTypes];
  }

  const getRoleName = (role) => {
    const roleId = role.id;
    const keyList = roleTypeTranslation[roleId];
    if(Array.isArray(keyList) && keyList.length >= 2) {
      const key = is_FLLC_event ? keyList[0] : keyList[1];
      if(key) return t(key);
    }
    return role.attribute.name;
  }

  const onSaveVolunteer = async (e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields().then(async (values, err) => {
      const removedRoles = [];
      const newEventAssignments = [];
      const initialRoles = [...volunteerAssignedRoles];
      const updatedRolesList = [...values.roles]
      updatedRolesList.forEach(r => {
        if (!initialRoles.includes(r)) {
          newEventAssignments.push(r)
        }
      })
      initialRoles.forEach(ir => {
        if (!updatedRolesList.includes(ir)) {
          removedRoles.push(ir)
        }
      })
      const removedEventRoleAssignmentIds = removedRoles.map(r => {
        const selectedAssignment = eventRoleAssignments.find(eventRole => eventRole.roleTypeId === r);
        return selectedAssignment.userEventRoleAssignmentId;
      });

      const userPayload = {
        'first_name': values.firstName,
        'last_name': values.lastName,
        'mobile_phone': values.phone
      };
      await props.editVolunteerInfo(id, userPayload);

      const args = {
        eventId,
        userId: id,
        addedRoles: newEventAssignments,
        removedRoles: removedEventRoleAssignmentIds
      };
      await handleSaveAssignments(args);
      props.closeEditModal();
    });
  }
  
  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const cancelDelete = () => {
    setDeleteModalVisible(false);
  };

  const onDeleteClick = () => {
    setDeleteModalVisible(true);
  };

  const deleteVolunteer = async () => {
    const assignmentIds = eventRoleAssignments.map(
      ({ userEventRoleAssignmentId }) => userEventRoleAssignmentId
    );
    await props.deleteEventVolunteer({ assignmentIds });
    setDeleteModalVisible(false);
    props.closeEditModal();
  };

  return (
    <>
      <Container type='default'>
        <h1 className='heading--charcoal h--bold h-2'>Edit User</h1>
      </Container>
      <Container type='medium'>
        <ConfirmationModal
          visible={deleteModalVisible}
          onCancel={cancelDelete}
          onSubmit={deleteVolunteer}
          title='Volunteer'
          description='Are you sure you want to remove this Volunteer?'
          loading={isDeletingEventVolunteer}
        />
        <Form hideRequiredMark onFinish={onSaveVolunteer} onFinishFailed={onFinishFailed} form={form} name='volunteer' initialValues={{email: email, firstName: firstName, lastName: lastName, phone: mobilePhone, roles: volunteerAssignedRoles}}>
          <Row {...rowProps}>
            <Col xs={24} md={16}>
              <FormItem name='email' label={t('emailLabel')}>
                <Input size='large' disabled />
              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col xs={24} md={16}>
              <FormItem name='firstName' label={t('firstNameLabel')} rules={[{ required: true, message: t('enterFirstNameLabel') }]}>
                <Input size='large' />
              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col xs={24} md={16}>
              <FormItem name='lastName' label={t('lastNameLabel')} rules={[{ required: true, message: t('enterLastNameLabel') }]}>
                <Input size='large' />
              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col xs={24} md={16}>
              <FormItem name='phone' label={t('phoneOptionalLabel')}>
                <Input size='large' />
              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col xs={24} md={16}>
              <FormItem name='roles' label={t('rolesLabel')}>
                  <Checkbox.Group style={{ width: '100%' }}>
                    {filteredVolunteerEventRoles.map(role => (
                      <Col xs={24} md={12}>
                        <Checkbox value={role.id} key={role.id}>
                          <span className='paragraph--charcoal paragraph--medium'>
                            {getRoleName(role)}
                          </span>
                        </Checkbox>
                      </Col>
                    ))}
                  </Checkbox.Group>
              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Button
              loading={isSavingVolunteer}
              htmlType='submit'
              additionalClassNames='m-r-24'
            >
              {t('saveLabel')}
            </Button>
            <Button 
              disabled={isSavingVolunteer} 
              onClick={onDeleteClick}
              loading={isDeletingEventVolunteer}
            >
              Delete
            </Button>
          </Row>
        </Form>
      </Container>
    </>
  );
};

EditVolunteerModalContent.propTypes = {
  form: PropTypes.object.isRequired,
  eventRoleTypes: PropTypes.array,
  eventProgramId: PropTypes.string.isRequired,
  getEventRoles: PropTypes.func.isRequired,
  selectedVolunteer: PropTypes.object.isRequired,
  isFetchingEventRoleTypes: PropTypes.bool.isRequired,
  isSavingVolunteer: PropTypes.bool.isRequired,
  handleSaveAssignments: PropTypes.func.isRequired,
  closeEditModal: PropTypes.func
};

EditVolunteerModalContent.defaultProps = {
  eventRoleTypes: [],
  closeEditModal: () => { }
}

const mapStateToProps = ({ event }) => {
  const { eventRoles, eventDetails, eventVolunteers } = event;
  const { eventRoleTypes, isFetchingEventRoleTypes } = eventRoles;
  const { eventProgramId, is_FLLC_event } = eventDetails;
  const { isEditingVolunteerEventAssignments, isDeletingEventVolunteer } = eventVolunteers;

  return {
    is_FLLC_event,
    eventRoleTypes,
    eventProgramId,
    isFetchingEventRoleTypes,
    isSavingVolunteer: isEditingVolunteerEventAssignments,
    isDeletingEventVolunteer,
  }
}

const mapDispatchToProps = {
  getEventRoles: handleGetEventRoles,
  handleSaveAssignments: handleEditEventAssignments,
  editVolunteerInfo,
  deleteEventVolunteer
}
export default connect(mapStateToProps, mapDispatchToProps)(EditVolunteerModalContent);