export default class Team {
  constructor(responseData, responseIncluded = []) {
    this.teamId = responseData.id;
    this.teamNumber = responseData.attributes.team_number;
    this.teamName = responseData.attributes.name;
    this.shortName = responseData.attributes.short_name;
    this.countryId = (responseData.relationships.country && responseData.relationships.country.data) ? responseData.relationships.country.data.id : undefined;
    this.regionId = (responseData.relationships.region && responseData.relationships.region.data) ? responseData.relationships.region.data.id : undefined;
    this.included = responseIncluded;
    this.data = responseData;
    this.coaches = (responseData.relationships.coaches && responseData.relationships.coaches.data && Array.isArray(responseData.relationships.coaches.data)) ? responseData.relationships.coaches.data : [];
    this.events = (responseData.relationships.events && responseData.relationships.events.data && Array.isArray(responseData.relationships.events.data)) ? responseData.relationships.events.data : [];
  }

  getTeamCoachesList = () => {
    const coachesList = this.coaches.map(coach => {
      const selectedCoach = this.included.find(i => i.id === coach.id);
      return selectedCoach;
    })
    return coachesList;
  }

  getTeamEventsId = () => {
    return this.events.map(event => event.id);
  }

  getCountry = () => {
    const teamHasCountry = this.countryId;
    if (!teamHasCountry) {
      return undefined;
    }

    return this.included.find(c => c.id === this.countryId);
  }

  getCountryName = () => {
    const teamHasCountry = this.countryId;
    if (!teamHasCountry) {
      return undefined;
    }

    const selectedCountry = this.included.find(r => r.id === this.countryId);
    if (!selectedCountry) {
      return undefined;
    }

    const countryName = selectedCountry.attributes.name;
    return countryName;
  }

  getRegion = () => {
    const teamHasRegion = this.regionId;
    if (!teamHasRegion) {
      return undefined;
    }

    return this.included.find(r => r.id === this.regionId);
  }

  getRegionName = () => {
    const selectedRegion = this.getRegion();
    if (!selectedRegion) {
      return undefined;
    }
    const regionName = selectedRegion.attributes.name;
    return regionName;
  }

  getRegionProgram = () => {
    const selectedRegion = this.getRegion();
    if (!selectedRegion) {
      return undefined;
    }
    const programId = selectedRegion.attributes.program_id;
    return programId;
  }

  getTeamLocation = () => {
    const teamRegion = this.getRegionName();
    const teamCountry = this.getCountryName();

    if (teamCountry && teamRegion) {
      return `${teamRegion}, ${teamCountry}`
    }

    return teamRegion || teamCountry;
  }
}