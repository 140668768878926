import React from 'react';
// import PropTypes from 'prop-types';


const RubricPDFIconGrey = ({ fill }) => {
    return (
        <svg width="60px" height="48px" viewBox="0 0 60 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
            <g id="Judging-and-Awards" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Judging-Session---FLL-Challenge---Judge" transform="translate(-1250.000000, -1179.000000)">
                    <g id="Group" transform="translate(1250.000000, 1179.000000)">
                        <path d="M37.4926136,12.0321429 L25.8767045,0.503571429 C25.5528409,0.182142857 25.115625,0 24.6568182,0 L1.72727273,0 C0.771875,0 0,0.766071429 0,1.71428571 L0,46.2857143 C0,47.2339286 0.771875,48 1.72727273,48 L36.2727273,48 C37.228125,48 38,47.2339286 38,46.2857143 L38,13.2482143 C38,12.7928571 37.8164773,12.3535714 37.4926136,12.0321429 Z" id="Path" fill="#FFFFFF"></path>
                        <path d="M37.4926136,12.0321429 L25.8767045,0.503571429 C25.5528409,0.182142857 25.115625,0 24.6568182,0 L1.72727273,0 C0.771875,0 0,0.766071429 0,1.71428571 L0,46.2857143 C0,47.2339286 0.771875,48 1.72727273,48 L36.2727273,48 C37.228125,48 38,47.2339286 38,46.2857143 L38,13.2482143 C38,12.7928571 37.8164773,12.3535714 37.4926136,12.0321429 Z M34.0164773,14.0357143 L23.8579545,14.0357143 L23.8579545,3.95357143 L34.0164773,14.0357143 Z M34.1136364,44.1428571 L3.88636364,44.1428571 L3.88636364,3.85714286 L20.1875,3.85714286 L20.1875,15.4285714 C20.1875,16.6712121 21.2024908,17.6785714 22.4545455,17.6785714 L34.1136364,17.6785714 L34.1136364,44.1428571 Z" id="Shape" fill="#DDDCDD"></path>
                        <g id="PDF" transform="translate(8.000000, 25.000000)" fill="#DDDCDD">
                            <path d="M1.8649635,5.73626374 L1.8649635,9 L0,9 L0,0 L3.55748175,0 C4.62621655,0 5.46715328,0.263736264 6.08029197,0.791208791 C6.69343066,1.31868132 7,2.01304945 7,2.87431319 C7,3.73557692 6.69343066,4.42788462 6.08029197,4.95123626 C5.46715328,5.47458791 4.62621655,5.73626374 3.55748175,5.73626374 L1.8649635,5.73626374 Z M1.8649635,4.34546703 L3.55748175,4.34546703 C4.07694647,4.34546703 4.47080292,4.2084478 4.73905109,3.93440934 C5.00729927,3.66037088 5.14142336,3.31112637 5.14142336,2.88667582 C5.14142336,2.45398352 5.00836375,2.09649725 4.74224453,1.81421703 C4.4761253,1.53193681 4.08120438,1.3907967 3.55748175,1.3907967 L1.8649635,1.3907967 L1.8649635,4.34546703 Z" id="Shape"></path>
                            <path d="M8,9 L8,0 L11.1721677,0 C12.2836753,0 13.1995242,0.351304945 13.9197145,1.05391484 C14.6399048,1.75652473 15,2.65796703 15,3.75824176 L15,5.24793956 C15,6.35233516 14.6399048,7.25377747 13.9197145,7.95226648 C13.1995242,8.65075549 12.2836753,9 11.1721677,9 L8,9 Z M9.82337199,1.3907967 L9.82337199,7.61538462 L11.1721677,7.61538462 C11.7716325,7.61538462 12.2545346,7.39388736 12.6208742,6.95089286 C12.9872138,6.50789835 13.1703836,5.94024725 13.1703836,5.24793956 L13.1703836,3.74587912 C13.1703836,3.05769231 12.9872138,2.49313187 12.6208742,2.0521978 C12.2545346,1.61126374 11.7716325,1.3907967 11.1721677,1.3907967 L9.82337199,1.3907967 Z" id="Shape"></path>
                            <polygon id="Path" points="21.3578174 5.2603022 17.838405 5.2603022 17.838405 9 16 9 16 0 22 0 22 1.3907967 17.838405 1.3907967 17.838405 3.86950549 21.3578174 3.86950549"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

// RubricPDFIconGrey.propTypes = {
//     fill: PropTypes.string
// }


// RubricPDFIconGrey.defaultProps = {
//     fill: 'currentColor'
// }

export default RubricPDFIconGrey;