import React from 'react';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button} from 'antd';
import Icon from '@ant-design/icons';
import CloseCircle from 'shared/components/Icon/icons/CloseCircle';
import '../../../../styles/TeamsCoachesInviteTile.scss';
import ErrorIcon from 'features/EventContainer/components/InviteErrorIcon';


const ImportInviteTile = props => {
  const {
    coach,
    handleDelete,
    isInvited,
    withoutCoach,
  } = props;
  const {
    CoachEmail,
    CoachFirstName,
    CoachLastName,
    TeamName,
    TeamNumber,
    TeamRegion,
    TeamCountry,
    id,
    errors,
    existing,
  } = coach;

  const isInvalid = Boolean(errors.length);

  const renderErrorMessages = (errorsArray) => {
    if (existing) return (
      <div className='teams-coaches-invite-tile__error-message-row'>
        <p className='teams-coaches-invite-tile__error-message'>Team Already added to this event.</p>
      </div>
    )
    if (!isInvalid) return null;
    const errorsRows = errorsArray.map(e => (
      <div className='teams-coaches-invite-tile__error-message-row'>
        <Icon component={ErrorIcon} className='teams-coaches-invite-tile__error-icon' />
        <p className='teams-coaches-invite-tile__error-message'>{e.message}</p>
      </div>
    ))

    return errorsRows;

  }

  if(withoutCoach) return (
    <div className={isInvalid ? 'teams-coaches-invite-tile teams-coaches-invite-tile--error' : 'teams-coaches-invite-tile'}>
      <div className='teams-coaches-invite-tile__col'>
        <span>
        <p className='teams-coaches-invite-tile__team'>{TeamNumber ? `${TeamNumber} - ${TeamName}` : `${TeamName}`}</p>
        </span>
      </div>
      <div className='teams-coaches-invite-tile__team-col'>
        <div>
          <p className='teams-coaches-invite-tile__team'>{TeamRegion ? `${TeamRegion} - ${TeamCountry}` : `${TeamCountry}`}</p>
        </div>
        <div>
          {renderErrorMessages(errors)}
        </div>
      </div>


      {!isInvited && (
      <span className='teams-coaches-invite-tile__btn-delete'>
        <Button type='link' onClick={() => {handleDelete(id)}}>
          <CloseCircle />

        </Button>
        <div style={{height: '20px'}} />
      </span>

      )}
    
    
    </div>
  )

  return (
    <div className={isInvalid ? 'teams-coaches-invite-tile teams-coaches-invite-tile--error' : 'teams-coaches-invite-tile'}>
      <div className='teams-coaches-invite-tile__col'>
        <Avatar size={48} icon={<UserOutlined />} className='teams-coaches-invite-tile__avatar' />
        <span>
          <p className='teams-coaches-invite-tile__name'>{`${CoachFirstName} ${CoachLastName}`}</p>
          <a className='link--email' href={`mailto:${CoachEmail}`}>{CoachEmail}</a>

        
        </span>
      
      </div>
      <div className='teams-coaches-invite-tile__team-col'>
        <div>
          <p className='teams-coaches-invite-tile__team'>{TeamNumber ? `${TeamNumber} - ${TeamName}` : `${TeamName}`}</p>

        </div>
        <div>
          {renderErrorMessages(errors)}
        </div>
      </div>


      {!isInvited && (
      <span className='teams-coaches-invite-tile__btn-delete'>
        <Button type='link' onClick={() => {handleDelete(id)}}>
          <CloseCircle />

        </Button>
        <div style={{height: '20px'}} />
      </span>

      )}
    
    
    </div>
  );
};

ImportInviteTile.propTypes = {
  coach: PropTypes.shape({
    CoachEmail: PropTypes.string.isRequired,
    CoachFirstName: PropTypes.string.isRequired,
    CoachLastName: PropTypes.string.isRequired,
    TeamName: PropTypes.string.isRequired,
    TeamNumber: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    errors: PropTypes.array
  }).isRequired,
  handleDelete: PropTypes.func,
  isInvited: PropTypes.bool
  
};


ImportInviteTile.defaultProps = {
  handleDelete: () => {},
  isInvited: false
}

export default ImportInviteTile;