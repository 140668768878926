import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Col, Row} from 'antd';
import MeetingCard from './MeetingCard';
import ListLoading from 'shared/components/Spinner/ListLoading';

const MeetingSessions = props => {

  const {
    isLoadingMeetings,
    meetingSessions,
    scheduleId,
    fetchMeetingSessions,
  } = props;

  useEffect(() => {
    if (scheduleId) {
      fetchMeetingSessions(scheduleId)
    }
  }, [scheduleId]);

  if (isLoadingMeetings) {
    return <ListLoading loading text="Loading Meetings"/>
  }
  
  return (
    <Row type='flex' gutter={[10, 10]}>
      {meetingSessions.map(s => (
        <Col xs={24} sm={12} md={8} key={s.id}>
          <MeetingCard isJoinLinkDisabled={s.isJoinLinkDisabled} url={s.meetingUrl} startTime={s.startTime} endTime={s.endTime} date={s.startDate} title={s.title} meetingId={s.id} />
        </Col>

      ))}
    </Row>
  );
};

MeetingSessions.propTypes = {
  meetingSessions: PropTypes.array.isRequired,
  isLoadingMeetings: PropTypes.bool.isRequired
};


const mapStateToProps = (state) => {
  const {event: {eventSchedule: {meetings: {
    meetingsList,
    isLoadingMeetings

  }}}} = state;
  return {
    meetingSessions: meetingsList,
    isLoadingMeetings: isLoadingMeetings

  }
}

export default connect(mapStateToProps)(MeetingSessions);