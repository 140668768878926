import React from 'react';
import PropTypes from 'prop-types';
import './styles/RegistrationPageLayout.scss';

const ScreenLayout = props => {
  const { children } = props;
  return (
    <div className='registration registration-page-layout'>
      <div className='registration-page-layout__container logged-in'>
        {children}
      </div>
    </div>
  );
};

ScreenLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default ScreenLayout;
