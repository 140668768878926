import React from 'react';
import PropTypes from 'prop-types';

const RubricUploadIcon = ({fill}) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      <g id="Judging-and-Awards" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Judging-Session---FTC---Upload-Form---Judge" transform="translate(-829.000000, -1317.000000)" fill="#DDDCDD">
          <g id="Group-20" transform="translate(250.000000, 980.000000)">
            <g id="Group-19" transform="translate(40.000000, 85.000000)">
              <g id="Group-21" transform="translate(0.000000, 68.000000)">
                <g id="Group-15" transform="translate(0.000000, 136.000000)">
                  <g id="Group-10-Copy" transform="translate(446.000000, 0.000000)">
                    <g id="Group-7" transform="translate(25.000000, 36.000000)">
                      <g id="Icons/icon-ADK-arrow-1" transform="translate(80.000000, 24.000000) scale(1, -1) translate(-80.000000, -24.000000) translate(68.000000, 12.000000)">
                        <polygon id="Path" transform="translate(12.000000, 12.000000) rotate(-315.000000) translate(-12.000000, -12.000000) " points="20.2758621 7.30064114 17.0736441 7.30064114 17.1152313 14.86952 5.96984925 3.72413793 3.72413793 5.96984925 14.86952 17.1152313 7.30064114 17.1152313 7.30064114 20.2758621 20.2758621 20.2758621" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

RubricUploadIcon.propTypes = {
  fill: PropTypes.string
};

RubricUploadIcon.defaultProps = {
  fill: 'currentColor'
}

export default RubricUploadIcon;