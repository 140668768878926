const m08Section = {
  id: 'm08',
  name: '2025ScoresheetM08Sub1Label',
  fields: [
    {
      scoring: '2025ScoresheetM08Sub2Label',
      id: 'm08_scoring_1',
      options: [
        {
          description: '0',
          value: 0
        },
        {
          description: '1',
          value: 1
          },
          {
              description: '2',
              value: 2
          },
          {
              description: '3',
              value: 3
          },
          {
              description: '4',
              value: 4
          }
      ]
    },
  ]
}

export default m08Section;