import * as types from '../types/eventScheduleTypes';

const INITIAL_SESSION_VALUE = {
  type: 'session',
  id: null,
  attributes:{
    start_time:null,
    end_time:null,
    session_url: null,
    notes: null
 },
 relationships: {
   schedule: {
     data: {}
   },
   session_type: {
     data: {}
   },
   panel: {
     data: null
   }
 }
}

const DEFAULT_EVENT_SCHEDULE_STATE = {
  isFetchingEventSchedule: false,
  errorFetchingEventSchedule: false,
  eventScheduleData: [],
  eventScheduleIncluded: [],
  scoringTimestampsData: [],
  scoringTimestampsIncluded: [],
  judgingTimestampsData: [],
  judgingTimestampsIncluded: [],
  isFetchingJudgingTimestamps: false,
  isFetchingScoringTimestamps: false,
  errorFetchingScoringTimestamps: false,
  errorFetchingJudgingTimestamps: false,
  openingSessionData: INITIAL_SESSION_VALUE,
  openingSessionIncluded: [],
  closingSessionData: INITIAL_SESSION_VALUE,
  closingSessionIncluded: [],
  isFetchingOpeningSession: false,
  isFetchingClosingSession: false,
  errorFetchingOpeningSession: false,
  errorFetchingClosingSession: false,
  isEditingEventGeneralInfo: false,
  isCreatingNewSessionUrl: false,
  isGeneratingMicrosoftSessionLink: false,
  isCreatingNewMeetingLink: false,
  isGeneratingMicrosoftMeetingLink: false,
  isChangingTimeZone: false,
  scheduleSettings: {},
  isGeneratingScheduleSessions: false,
  isGeneratingScheduleSessionsComplete: false,
  isLoadingMessage: '',
  isFetchingScheduleTabScheduleDetails: false,
  isFetchingMeetingSection: false,
  isLoadingJudgingSection: false,
  isLoadingScoringSection: false,
  meetingSectionData: [],
  meetings: {
    meetingsList: [],
    isLoadingMeetings: false,
    isLoadingTeamOptions: false,
    volunteerOptions: [],
    teamOptions: [],
    meetingsCount: 0,
    isUpdatingMeeting: false,
    isCreatingMeeting: false
  },
  sessions: {
    meetingSessions: [],
    scoringSessions: [],
    judgingSessions: [],
    isLoadingMeetingSessions: false,
    judgingSessionsData: [],
    judgingSessionsIncluded: [],
    judgingSessionsTeams: [],
    scoringSessionsData: [],
    scoringSessionsIncluded: [],
    scoringSessionsTeams: [],
    isLoadingScoringSessions: false,
    isLoadingJudgingSessions: false,
    errorScoringSessions: false,
    errorJudgingSessions: false,
  }
}

export default (state = DEFAULT_EVENT_SCHEDULE_STATE, action) => {
  const {type} = action;
  switch(type) {
    case types.GET_EVENT_SCHEDULE_DETAILS_BEGIN:
      return {
        ...state,
        isFetchingEventSchedule: true,
        isFetchingMeetingSection: true,
        isLoadingJudgingSection: true,
        isLoadingScoringSection: true,
      }
    case types.SET_SCHEDULE_MEETING_SECTION_LOADING:
      return {
        ...state,
        isFetchingMeetingSection: false,
        meetingSectionData: action.payload,
      }
    case types.GET_EVENT_SCHEDULE_DETAILS_ERROR:
      return {
        ...state,
        isFetchingEventSchedule: false,
        errorFetchingEventSchedule: true
      }
    case types.GET_EVENT_SCHEDULE_DETAILS_SUCCESS:
      return {
        ...state,
        errorFetchingEventSchedule: false,
        isFetchingEventSchedule: false,
      }
    case types.GET_EVENT_SCHEDULE_DETAILS_COMPLETE:
      return {
        ...state,
        isFetchingEventSchedule: false,
        errorFetchingEventSchedule: false
      }
    case types.GET_SCHEDULE_JUDGING_TIMESTAMPS_BEGIN:
      return {
        ...state,
        isFetchingJudgingTimestamps: true
      }
    case types.GET_SCHEDULE_JUDGING_TIMESTAMPS_ERROR:
      return {
        ...state,
        isFetchingJudgingTimestamps: false,
        errorFetchingJudgingTimestamps: true
      }
    case types.GET_SCHEDULE_JUDGING_TIMESTAMPS_SUCCESS:
      return {
        ...state,
        isFetchingJudgingTimestamps: false,
        errorFetchingJudgingTimestamps: false,
        judgingTimestampsData: action.payload.judgingTimestampsData,
        judgingTimestampsIncluded: action.payload.judgingTimestampsIncluded,
      }
    case types.GET_SCHEDULE_SCORING_TIMESTAMPS_BEGIN:
      return {
        ...state,
        isFetchingScoringTimestamps: true
      }
    case types.GET_SCHEDULE_SCORING_TIMESTAMPS_ERROR:
      return {
        ...state,
        isFetchingScoringTimestamps: false,
        errorFetchingScoringTimestamps: true
      }
    case types.GET_SCHEDULE_SCORING_TIMESTAMPS_SUCCESS:
      return {
        ...state,
        isFetchingScoringTimestamps: false,
        errorFetchingScoringTimestamps: false,
        scoringTimestampsData: action.payload.scoringTimestampsData,
        scoringTimestampsIncluded: action.payload.scoringTimestampsIncluded,
      }
    case types.SET_SCHEDULE_SCORING_TIMESTAMPS:
      return {
        ...state,
        scoringTimestampsData: action.payload.scoringTimestampsData,
        scoringTimestampsIncluded: action.payload.scoringTimestampsIncluded,
      }
    case types.SET_SCHEDULE_JUDGING_TIMESTAMPS:
      return {
        ...state,
        judgingTimestampsData: action.payload.judgingTimestampsData,
        judgingTimestampsIncluded: action.payload.judgingTimestampsIncluded,
      }
    case types.GET_SCHEDULE_JUDGING_SESSIONS_BEGIN:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          isLoadingJudgingSessions: true,
        }
      }
    case types.GET_SCHEDULE_JUDGING_SESSIONS_ERROR:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          isLoadingJudgingSessions: false,
          errorJudgingSessions: true
        },
        isLoadingJudgingSection: false,
      }
    case types.GET_SCHEDULE_JUDGING_SESSIONS_SUCCESS:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          isLoadingJudgingSessions: false,
          errorJudgingSessions: false,
          judgingSessions: action.payload.judgingSessionsData,
        },
        isLoadingJudgingSection: false,
      }
    case types.GET_SCHEDULE_SCORING_SESSIONS_BEGIN:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          isLoadingScoringSessions: true,
        }
      }
    case types.GET_SCHEDULE_SCORING_SESSIONS_ERROR:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          isLoadingScoringSessions: false,
          errorScoringSessions: true
        },
        isLoadingScoringSection: false,
      }
    case types.GET_SCHEDULE_SCORING_SESSIONS_SUCCESS:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          isLoadingScoringSessions: false,
          errorScoringSessions: false,
          scoringSessions: action.payload.scoringSessionsData,
        },
        isLoadingScoringSection: false,
      }
    case types.GET_SCHEDULE_CLOSING_SESSION_BEGIN:
      return {
        ...state,
        isFetchingClosingSession: true
      }
    case types.GET_SCHEDULE_CLOSING_SESSION_ERROR:
      return {
        ...state,
        isFetchingClosingSession: false,
        errorFetchingClosingSession: true
      }
    case types.GET_SCHEDULE_CLOSING_SESSION_SUCCESS:
      return {
        ...state,
        closingSessionData: action.payload.closingSessionData,
        closingSessionIncluded: action.payload.closingSessionIncluded,
        isFetchingClosingSession: false,
        errorFetchingClosingSession: false
      }
    case types.GET_SCHEDULE_OPENING_SESSION_BEGIN:
      return {
        ...state,
        isFetchingOpeningSession: true
      }
    case types.GET_SCHEDULE_OPENING_SESSION_ERROR:
      return {
        ...state,
        isFetchingOpeningSession: false,
        errorFetchingOpeningSession: true
      }
    case types.GET_SCHEDULE_OPENING_SESSION_SUCCESS:
      return {
        ...state,
        openingSessionData: action.payload.openingSessionData,
        openingSessionIncluded: action.payload.openingSessionIncluded,
        isFetchingOpeningSession: false,
        errorFetchingOpeningSession: false
      }
    case types.GET_SCHEDULE_MEETING_SESSIONS_BEGIN:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          isLoadingMeetings: true
        }
      }
    case types.GET_SCHEDULE_MEETING_SESSIONS_ERROR:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          isLoadingMeetings: false,
          meetingsList: [],
          meetingsCount: 0,
        }
      }
    case types.GET_SCHEDULE_MEETING_SESSIONS_SUCCESS:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          isLoadingMeetings: false,
          meetingsList: action.payload.meetingSessions,
          meetingsCount: action.payload.meetingSessions.length
        }
      }
    case types.GET_SCHEDULE_MEETING_TEAMS_BEGIN:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          isLoadingTeamOptions: true
        }
      }
    case types.GET_SCHEDULE_MEETING_TEAMS_ERROR:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          isLoadingTeamOptions: false,
          teamOptions: []
        }
      }
    case types.GET_SCHEDULE_MEETING_TEAMS_SUCCESS:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          isLoadingTeamOptions: false,
          teamOptions: action.payload.teamOptions
        }
      }
    case types.CREATE_NEW_SCHEDULE_MEETING_BEGIN:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          isCreatingMeeting: true
        }
      }
    case types.CREATE_NEW_SCHEDULE_MEETING_ERROR:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          isCreatingMeeting: false
        }
      }
    case types.CREATE_NEW_SCHEDULE_MEETING_SUCCESS:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          isCreatingMeeting: false
        }
      }
    case types.SAVE_SCHEDULE_MEETING_BEGIN:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          isUpdatingMeeting: true
        }
      }
    case types.SAVE_SCHEDULE_MEETING_ERROR:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          isUpdatingMeeting: false
        }
      }
    case types.SAVE_SCHEDULE_MEETING_SUCCESS:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          isUpdatingMeeting: false
        }
      }
    case types.EDIT_EVENT_GENERAL_INFO_BEGIN:
      return {
        ...state,
        isEditingEventGeneralInfo: true
      }
    case types.EDIT_EVENT_GENERAL_INFO_ERROR:
      return {
        ...state,
        isEditingEventGeneralInfo: false
      }
    case types.EDIT_EVENT_GENERAL_INFO_SUCCESS:
      return {
        ...state,
        isEditingEventGeneralInfo: false
      }
    case types.SET_REFETCH_EVENT_SCHEDULE:
      return {
        ...state,
        isFetchingEventSchedule: true,
      }
    case types.SET_SCHEDULE_JUDGING_SESSIONS: 
      return {
        ...state,
        sessions: {
          ...state.sessions,
          judgingSessions: action.payload.judgingSessions
        }
      }
    case types.SET_SCHEDULE_SCORING_SESSIONS: 
      return {
        ...state,
        sessions: {
          ...state.sessions,
          scoringSessions: action.payload.scoringSessions
        }
      }
    case types.SET_SCHEDULE_MEETING_SESSIONS: 
      return {
        ...state,
        sessions: {
          ...state.sessions,
          meetingSessions: action.payload.meetingSessions
        }
      }
    case types.UPDATE_JUDGING_AND_SCORING_SESSIONS:
      return {
        ...state,
        sessions: action.payload
      }
    case types.CREATE_NEW_SESSION_LINK_BEGIN:
      return {
        ...state,
        isCreatingNewSessionUrl: true
      }
    case types.CREATE_NEW_SESSION_LINK_ERROR:
      return {
        ...state,
        isCreatingNewSessionUrl: false
      }
    case types.CREATE_NEW_SESSION_LINK_SUCCESS:
      return {
        ...state,
        isCreatingNewSessionUrl: false
      }
    case types.REGENERATE_MICROSOFT_SESSION_LINK_BEGIN:
      return {
        ...state,
        isGeneratingMicrosoftSessionLink: true
      }
    case types.REGENERATE_MICROSOFT_SESSION_LINK_ERROR:
      return {
        ...state,
        isGeneratingMicrosoftSessionLink: false
      }
    case types.REGENERATE_MICROSOFT_SESSION_LINK_SUCCESS:
      return {
        ...state,
        isGeneratingMicrosoftSessionLink: false
      }
      case types.CREATE_NEW_MEETING_LINK_BEGIN:
        return {
          ...state,
          isCreatingNewMeetingLink: true
        }
      case types.CREATE_NEW_MEETING_LINK_ERROR:
        return {
          ...state,
          isCreatingNewMeetingLink: false
        }
      case types.CREATE_NEW_MEETING_LINK_SUCCESS:
        return {
          ...state,
          isCreatingNewMeetingLink: false
        }
    case types.REGENERATE_MICROSOFT_MEETING_LINK_BEGIN:
      return {
        ...state,
        isGeneratingMicrosoftMeetingLink: true
      }
    case types.REGENERATE_MICROSOFT_MEETING_LINK_ERROR:
      return {
        ...state,
        isGeneratingMicrosoftMeetingLink: false
      }
    case types.REGENERATE_MICROSOFT_MEETING_LINK_SUCCESS:
      return {
        ...state,
        isGeneratingMicrosoftMeetingLink: false
      }
    case types.SET_SCHEDULE_SETTINGS_DETAIL:
      return {
        ...state,
        scheduleSettings: action.payload
      }
    case types.GENERATE_SCHEDULE_SESSIONS_BEGIN:
      return {
        ...state,
        isGeneratingScheduleSessions: true,
        isGeneratingScheduleSessionsComplete: false,
        isLoadingMessage: action.payload,
      }
    case types.RESET_GENERATE_SCHEDULE_COMPLETE:
      return {
        ...state,
        isGeneratingScheduleSessionsComplete: false,
      }
    case types.GENERATE_SCHEDULE_SESSIONS_SUCCESS:
      return {
        ...state,
        isGeneratingScheduleSessions: false,
        isGeneratingScheduleSessionsComplete: true,
        isLoadingMessage: '',
      }
    case types.DELETE_BREAK_BY_ID_BEGIN:
      return {
        ...state,
        isGeneratingScheduleSessions: true,
        isLoadingMessage: action.payload,
      }
    case types.DELETE_BREAK_BY_ID_SUCCESS:
      return {
        ...state,
        isGeneratingScheduleSessions: false,
        isLoadingMessage: '',
      }
    case types.SAVING_SCHEDULES_SETTINGS_BEGIN:
      return {
        ...state,
        isGeneratingScheduleSessions: true,
        isLoadingMessage: action.payload,
      }
    case types.SAVING_SCHEDULES_SETTINGS_END:
      return {
        ...state,
        isGeneratingScheduleSessions: false,
        isLoadingMessage: '',
      }
    case types.GENERATE_SCHEDULE_SESSIONS_ERROR:
      return {
        ...state,
        isGeneratingScheduleSessions: false,
        isGeneratingScheduleSessionsComplete: false
      }
      case types.GET_EVENT_SCHEDULE_TAB_DETAILS_BEGIN:
        return {
          ...state,
          isFetchingScheduleTabScheduleDetails: true,
        }
      case types.GET_EVENT_SCHEDULE_TAB_DETAILS_ERROR:
        return {
          ...state,
          isFetchingScheduleTabScheduleDetails: false,
        }
      case types.GET_EVENT_SCHEDULE_TAB_DETAILS_SUCCESS:
        return {
          ...state,
          isFetchingScheduleTabScheduleDetails: false,
        }
    default:
      return state;
  }
}