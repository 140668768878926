import { notification } from 'antd';
import { PARTNER_ADMIN } from 'shared/constants/userTypes'; 
import {seventyTwoHoursFromNow} from 'utils/dateTimeUtils';
import * as userService from 'utils/services/users';
import {formatUserResData} from 'utils/services/users/model';
import * as organizationService from 'utils/services/organizations';
import {formatOrganizationResponse} from 'utils/services/organizations/model';
import {updateUserProfile, resetAuth} from 'features/Authentication/redux/authActions';
import store from 'features/store';
import { fetchRegionsBy } from './programsActions';
import {
  SET_PARTNER_USER_INFO,
  SET_PARTNER_PROGRAMS,
  SET_PARTNER_PROGRAM_REGION,
  SET_PARTNER_ORGANIZATION_NAME,
  SET_PARTNER_ORGANIZATION_DATA,
  RESET_PARTNER_FORM,
  ADD_PARTNER_INVITEE,
  DELETE_PARTNER_INVITEE,
  UPDATE_PARTNER_USER_INFO,
  SET_PARTNER_USER_SELECTED_STATUS,
  CLEAR_SELECTED_PARTNER,
  PARTNER_INVITE_SUCCESS,
  PARTNER_INVITE_BEGIN,
  PARTNER_INVITE_ERROR,
  FETCH_PARTNER_ADMINS_BEGIN,
  FETCH_PARTNER_ADMINS_SUCCESS,
  FETCH_PARTNER_ADMINS_ERROR,
  RESET_PARTNER_INVITEES,
  REINVITE_DASHBOARD_PARTNER_ADMIN_BEGIN,
  REINVITE_DASHBOARD_PARTNER_ADMIN_ERROR,
  REINVITE_DASHBOARD_PARTNER_ADMIN_SUCCESS,
  SET_SELECTED_EDIT_PARTNER_ADMIN,
  EDIT_PARTNER_ADMIN_BEGIN,
  EDIT_PARTNER_ADMIN_ERROR,
  EDIT_PARTNER_ADMIN_SUCCESS,
  SEARCH_PARTNER_ORGANIZATIONS_BEGIN,
  SEARCH_PARTNER_ORGANIZATIONS_ERROR,
  SEARCH_PARTNER_ORGANIZATIONS_SUCCESS,
  SET_DERIVED_SELECTED_PARTNER_ORGANIZATIONS_LIST,
  DELETE_SELECTED_PARTNER_ADMIN_BEGIN,
  DELETE_SELECTED_PARTNER_ADMIN_ERROR,
  DELETE_SELECTED_PARTNER_ADMIN_SUCCESS
} from '../types/partnersTypes';

export const getPartnerAdminsBegin = () => ({
  type: FETCH_PARTNER_ADMINS_BEGIN
});

export const getPartnerAdminsSuccess = (payload) => ({
  type: FETCH_PARTNER_ADMINS_SUCCESS,
  payload
});

export const getPartnerAdminsError = () => ({
  type: FETCH_PARTNER_ADMINS_ERROR
});

export const fetchPartnerAdmins = (page, search, sort) => async (dispatch) => {
    try {
        dispatch(getPartnerAdminsBegin());
        const orgDetail = true;
        const filter = {
            user_type: `eq:${PARTNER_ADMIN}`,
            ...search && { full_name: `like:${search}` }
        };
        const AllPartners = await userService.getUsersByFilter(filter);
        const partners = await userService.getUsersByFilter(filter, page, orgDetail, sort);
        dispatch(getPartnerAdminsSuccess({ ...partners, count: AllPartners.count }));
    } catch (e) {
        dispatch(getPartnerAdminsError());
    }
};

export const setPartnerUser = (payload) => ({
  type: SET_PARTNER_USER_INFO,
  payload
});

export const setPartnerPrograms = (programs) => ({
  type: SET_PARTNER_PROGRAMS,
  payload: programs
});

export const setPartnerProgRegion = (payload) => ({
  type: SET_PARTNER_PROGRAM_REGION,
  payload
});

export const setPartnerOrgName = (name) => ({
  type: SET_PARTNER_ORGANIZATION_NAME,
  payload: name
});

const setPartnerOrgData = (payload) => ({
  type: SET_PARTNER_ORGANIZATION_DATA,
  payload
});

export const setPartnerOrganization = (payload) => async (dispatch) => {
  dispatch(setPartnerOrgData(payload));
  const { programs } = payload;
  
  if (programs && programs.length) {
    programs.forEach((p) => {
      dispatch(fetchRegionsBy(p.programId, p.name));
    });
  }
};

export const updatePartnerUser = (fieldName, value) => ({
  type: UPDATE_PARTNER_USER_INFO,
  payload: { fieldName, value }
});

export const setIsPartnerSelected = (status) => ({
  type: SET_PARTNER_USER_SELECTED_STATUS,
  payload: status
});

export const resetPartnerForm = () => ({
  type: RESET_PARTNER_FORM
});

export const clearSelectedPartner = () => ({
  type: CLEAR_SELECTED_PARTNER
});

export const resetPartnerInvitees = () => ({
  type: RESET_PARTNER_INVITEES
});

export const addPartnerToInvitees = (partner) => ({
  type: ADD_PARTNER_INVITEE,
  payload: partner
});

export const removePartnerFromInvitees = (userId) => ({
  type: DELETE_PARTNER_INVITEE,
  payload: userId
});

const invitePartnerBegin = () => {
  return {
    type: PARTNER_INVITE_BEGIN
  }
}

const invitePartnerError = () => {
  return {
    type: PARTNER_INVITE_ERROR
  }
}

const invitePartnerSuccess = () => {
  return {
    type: PARTNER_INVITE_SUCCESS
  }
}

const changeExistingPartnerTypeToPartnerAdmin = async (userId, organizationId) =>{
  const existingUser = {id: userId, user_type : PARTNER_ADMIN};
  const partnerOrganizationsList = organizationId ? [organizationId] : []
  const res = await userService.updatePartnerUser(userId, existingUser, partnerOrganizationsList);

  const userRes = formatUserResData(res);
  
  notification.success({
    message: 'Success',
    description: 'Partner Admin Invitation successfully sent.',
  });
  return userRes;
}

// Service/Help method: Invite one Partner
const invitePartner = async (partner, signedInUserId) => {
  try {
    let {organizationId} = partner;
    const { organizationName, programs } = partner;
    if (!organizationId) {
      const organizationRegions = [];
      programs.forEach(p => {
        if (p.regions && Array.isArray(p.regions)) {
          p.regions.forEach(reg => {
            organizationRegions.push(reg)
          })
        }
      })
      const organization = await organizationService.createOrganization({
        name: organizationName, regions: organizationRegions
      });
      organizationId = organization.id;
    }
    const {email} = partner;
    const existingUserId = email ? await userService.verifyUserExist(email) : false;
    if (existingUserId) {
      await changeExistingPartnerTypeToPartnerAdmin(existingUserId, organizationId)
      if (existingUserId === signedInUserId) {
        store.dispatch(resetAuth());
        notification.info({
          message: 'User Role Changed',
          description: 'Please sign back in to view your updated dashboard.',
        });
        
      }
    } else {
      const user = await userService.createPartnerAdmin(partner, organizationId);
      if (user && user.id) {
        notification.success({
          message: 'Success',
          description: 'Partner Admin Invitation successfully sent.',
        });
      }
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: 'Error sending the invitation.',
    });
    // throw err;
  }
}

export const handleInviteAllPartners = () => async (dispatch, getState) => {
  await dispatch(invitePartnerBegin());
  try {
    const { auth: {userId}, adminBoard: { partners: { partnerInvitees } } } = getState();
    for (let i = 0; i < partnerInvitees.length; i++) {
      const partner = partnerInvitees[i];
      await invitePartner(partner, userId);
    }
    await dispatch(resetPartnerInvitees());
    await dispatch(invitePartnerSuccess());
  } catch (err) {
    await dispatch(invitePartnerError());
    throw err;
  }
};

export const handleInvitePartnerNow = (user) => async (dispatch, getState) => {
  await dispatch(invitePartnerBegin());
  try {
    const { auth: {userId}, adminBoard: { partners: { selectedPartner } } } = getState();
    await invitePartner({ ...selectedPartner, ...user }, userId);
    await dispatch(clearSelectedPartner());
    await dispatch(invitePartnerSuccess());
  } catch (err) {
    await dispatch(invitePartnerError());
    throw err;
  }
};

const resendInvitationBegin = () => {
  return {
    type: REINVITE_DASHBOARD_PARTNER_ADMIN_BEGIN
  }
}
const resendInvitationError = () => {
  return {
    type: REINVITE_DASHBOARD_PARTNER_ADMIN_ERROR
  }
}
const resendInvitationSuccess = (updatedPartnerAdminsList = []) => {
  return {
    type: REINVITE_DASHBOARD_PARTNER_ADMIN_SUCCESS,
    payload: {
      updatedPartnerAdminsList
    }
  }
}

export const resendUserInvitation = (userId) => {
  return async (dispatch, getState) => {
    const {adminBoard: {partners: {partnerUsers}}} = getState();

    const copyUsersList = [...partnerUsers];
    try {
      await dispatch(resendInvitationBegin())
      await userService.reinviteUser(userId);
      const newTimestamp = seventyTwoHoursFromNow();

      const selectedPartnerAdminUser = copyUsersList.find(i => i.id === userId);
      
      if (selectedPartnerAdminUser) {
        const selectedVolunteerIndex = copyUsersList.findIndex(i => i.id === userId);
        const updatedPartnerAdminUser = {
          ...selectedPartnerAdminUser,
          userVerificationCodeTimeout: newTimestamp
        }
        copyUsersList[selectedVolunteerIndex] = updatedPartnerAdminUser;
      }

      await dispatch(resendInvitationSuccess(copyUsersList));
      
      notification.success({
        message: 'Success',
        description: 'User successfully reinvited.',
      })
      
    } catch(err) {
      notification.error({
        message: 'Error',
        description: 'Error reinviting user',
      });
      await dispatch(resendInvitationError())
      throw err;
    }
  }
}

const setSelectedEditPartner = (data) => {
  return {
    type: SET_SELECTED_EDIT_PARTNER_ADMIN,
    payload: {
      data
    }
  }
}

const setDerivedOrganizations = (data) => {
  return {
    type: SET_DERIVED_SELECTED_PARTNER_ORGANIZATIONS_LIST,
    payload: {
      data
    }
  }
}

export const handleSetSelectedEditPartner = (data) => {
  return async dispatch => {
    dispatch(setDerivedOrganizations(data.partnerOrganizationsList))
    dispatch(setSelectedEditPartner(data))
  }
}

const editPartnerAdminBegin = () => {
  return {
    type: EDIT_PARTNER_ADMIN_BEGIN
  }
}
const editPartnerAdminError = () => {
  return {
    type: EDIT_PARTNER_ADMIN_ERROR
  }
}
const editPartnerAdminSuccess = (updatedPartnersList) => {
  return {
    type: EDIT_PARTNER_ADMIN_SUCCESS,
    payload: {
      updatedPartnersList
    }
  }
}

export const handleUpdateSelectedPartnerAdmin = (id, payload, cb = () => {}) => {
  return async (dispatch, getState) => {
    const {adminBoard: {partners: {partnerUsers}}} = getState();
    const {user, userId, addedOrganizations, removedOrganizations} = payload;
    try {
      await dispatch(editPartnerAdminBegin())
      await userService.updatePartnerUser(id, user, addedOrganizations);
      if (removedOrganizations.length) {
        await userService.removeOrganizationsFromUser(userId, removedOrganizations)
      }
      await dispatch(editPartnerAdminSuccess(partnerUsers));
      notification.success({
        message: 'Success',
        description: 'Partner Admin successfully updated.',
      });
      cb()
    } catch(err) {
      dispatch(editPartnerAdminError())
      notification.error({
        message: 'Error',
        description: 'Partner Admin unsuccessfully updated.',
      });
      cb()
      throw err;
    }
  }
}

const searchOrganizationsBegin = () => {
  return {
    type: SEARCH_PARTNER_ORGANIZATIONS_BEGIN
  }
}

const searchOrganizationsError = () => {
  return {
    type: SEARCH_PARTNER_ORGANIZATIONS_ERROR
  }
}

const searchOrganizationsSuccess = (organizationsList) => {
  return {
    type: SEARCH_PARTNER_ORGANIZATIONS_SUCCESS,
    payload: {
      organizationsList
    }
  }
}

export const handleSearchOrganizations = (input) => {
  return async dispatch => {

    try {
      await dispatch(searchOrganizationsBegin())
      const res = await organizationService.getOrganizationsLikeName(input);
      const organizationsList = res.map(r => formatOrganizationResponse(r));

      dispatch(setDerivedOrganizations(organizationsList))

      await dispatch(searchOrganizationsSuccess(organizationsList))
    } catch(err) {
      await dispatch(searchOrganizationsError())


      throw err;
    }
  }

}

const deletePartnerBegin = () => {
  return {
    type: DELETE_SELECTED_PARTNER_ADMIN_BEGIN
  }
}

const deletePartnerError = () => {
  return {
    type: DELETE_SELECTED_PARTNER_ADMIN_ERROR
  }
}

const deletePartnerSuccess = (updatedList) => {
  return {
    type: DELETE_SELECTED_PARTNER_ADMIN_SUCCESS,
    payload: {
      updatedList
    }
  }
}

export const handleDeleteSelectedPartner = (userId, cb = () => {}) => {
  return async (dispatch, getState) => {
    const {adminBoard: {partners: {partnerUsers}} } = getState();
    const copyPartnerUsers = [...partnerUsers]
    try {
      await dispatch(deletePartnerBegin())
      await userService.deleteUser(userId);
      const updatedList = copyPartnerUsers.filter(f => f.id !== userId);

      await dispatch(deletePartnerSuccess(updatedList));
      notification.success({
        message: 'Success',
        description: 'User successfully removed.',
      });
      cb();
    } catch(err) {
      dispatch(deletePartnerError())
      notification.error({
        message: 'Error',
        description: 'Error removing user',
      });
      throw err;
    }
  }
}