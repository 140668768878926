import { API } from 'utils/api';

//const { http } = API;

const baseUrl = '/api/score_export/fll';

export const exportScore = async (eventId) => {
  if (!eventId) return;
  const url = `${baseUrl}/${eventId}`;

  const res = await API.GetInstance().http.request({
    method: 'get',
    url,
    responseType: 'blob',
  })


  const blob = new Blob([res], { type: 'text/csv', encoding: 'UTF-8' });

  const newUrl = window.URL.createObjectURL(blob);
  return newUrl;
};
