import React from 'react';
import EventModal from 'features/EventOverviewContainer/components/EventModal';
import AddAwardForm from '../AddAwardForm';
import { useTranslation } from 'react-i18next';

const AddAwardModal = (props) => {
  const {t} = useTranslation();
  return (
    <EventModal {...props} maxWidth={850}>
      <div className='c-event-overview-awards__add-modal'>
        <div className='c-event-overview-awards__modal-heading'>
          <h1>{t('newAwardLabel')}</h1>
        </div>
        <div className='c-event-overview-awards__modal-content'>
          <AddAwardForm {...props} />
        </div>
      </div>
    </EventModal>
  );
};

export default AddAwardModal;
