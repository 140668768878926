import React from 'react';
import PropTypes from 'prop-types';
import Paragraph from 'shared/components/Paragraph';

import BtnPrimary from 'shared/components/Button/BtnPrimary';
import { connect } from 'react-redux';
import {handleInviteAllCoaches} from 'features/EventContainer/redux/actions/teams';
import {useParams} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CoachInviteTile from './TeamCoachTile';

const InviteList = (props) => {
  const {coachesList, inviteAll, isInvitingCoach} = props;
  const noCoaches = coachesList.length < 1;
  const { t } = useTranslation();
  
  const {id: eventId}= useParams();

  const handleInviteAll = () => {
    inviteAll(coachesList, eventId);
  };

  return (
    <div className='teams-coaches-modal__manual-invite-list-container'>
      <Paragraph color={noCoaches ? 'silver' : 'darkGray'}>
        Invite these Coaches/Teams :
      </Paragraph>
      <div className={noCoaches ? 'teams-coaches-modal__manual-invite-list' : 'teams-coaches-modal__manual-invite-list--invitees'}>
        {coachesList.map(c => (
          <CoachInviteTile coach={c} />
        ))}
      </div>
      <div className='teams-coaches-modal__manual-invite-list__btn-wrapper--outer'>
        <div className='teams-coaches-modal__manual-invite-list__btn-wrapper--inner'>
          <BtnPrimary loading={isInvitingCoach} disabled={noCoaches} onClick={handleInviteAll}>{t('inviteAllLabel')}</BtnPrimary>
        </div>
      </div>
    </div>
  );
};

InviteList.propTypes = {
  coachesList: PropTypes.array.isRequired,
  inviteAll: PropTypes.object.isRequired,
  isInvitingCoach: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  const {event: {eventTeams}} = state;
  const {addCoachesManuallyList, isInvitingCoach} = eventTeams;
  return {
    coachesList: addCoachesManuallyList,
    isInvitingCoach
  };
}

export default connect(mapStateToProps, {inviteAll: handleInviteAllCoaches})(InviteList);
