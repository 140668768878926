import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from 'shared/components/Modal';
import EditProfileForm from './EditProfileForm';

const EditProfileModal = props => {
  const {onCancel, visible} = props;
  const { firstName, lastName } = props;

  const { t } = useTranslation();

  const modalHeader = () => {
    return (
      <p>
        {firstName} 
        {' '}
        {lastName}
      </p>
    )
  }
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      renderHeader={modalHeader}
    >
      <h2 className='heading--charcoal h-bold h-2'>{t('myProfileLabel')}</h2>
      <EditProfileForm />
    </Modal>
  );
};

const mapStateToProps = ({ auth }) => {
  const { userInfo = {} } = auth;
  
  const { firstName, lastName } = userInfo;
  return {
    firstName,
    lastName
  };
};

EditProfileModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string
};

EditProfileModal.defaultProps = {
  firstName: '',
  lastName: ''
}
export default connect(mapStateToProps)(EditProfileModal);