export const officialMatchType = {
  matchTypeId: "62259249-902c-4dd7-a46e-b769dcb0cde7",
  name: 'Official Match',
  label: 'offificalMatchLabel',
}

export const practiceMatchType = {
  matchTypeId: "61259249-902c-4dd7-a46e-b769dcb0cde7",
  name: 'Practice Match',
  label: 'practiceMatchLabel'
}

export const matchTypes = {
  "61259249-902c-4dd7-a46e-b769dcb0cde7": {
    name: 'Practice Match',
    label: 'practiceMatchLabel'
  },
  "62259249-902c-4dd7-a46e-b769dcb0cde7": {
    name: 'Official Match',
    label: 'offificalMatchLabel',
  }
}
