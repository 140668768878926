export const DASHBOARD_REQUEST_TEAMS_BEGIN = 'DASHBOARD_REQUEST_TEAMS_BEGIN';
export const DASHBOARD_REQUEST_TEAMS_SUCCESS = 'DASHBOARD_REQUEST_TEAMS_SUCCESS';
export const DASHBOARD_REQUEST_TEAMS_ERROR = 'DASHBOARD_REQUEST_TEAMS_ERROR';

export const DASHBOARD_REINVITE_COACH_BEGIN = 'DASHBOARD_REINVITE_COACH_BEGIN';
export const DASHBOARD_REINVITE_COACH_ERROR = 'DASHBOARD_REINVITE_COACH_ERROR';
export const DASHBOARD_REINVITE_COACH_SUCCESS = 'DASHBOARD_REINVITE_COACH_SUCCESS';

export const DASHBOARD_DELETE_COACH_BEGIN = 'DASHBOARD_DELETE_COACH_BEGIN';
export const DASHBOARD_DELETE_COACH_ERROR = 'DASHBOARD_DELETE_COACH_ERROR';
export const DASHBOARD_DELETE_COACH_SUCCESS = 'DASHBOARD_DELETE_COACH_SUCCESS';

export const DASHBOARD_DELETE_TEAM_BEGIN = 'DASHBOARD_DELETE_TEAM_BEGIN';
export const DASHBOARD_DELETE_TEAM_ERROR = 'DASHBOARD_DELETE_TEAM_ERROR';
export const DASHBOARD_DELETE_TEAM_SUCCESS = 'DASHBOARD_DELETE_TEAM_SUCCESS';

export const DASHBOARD_EDIT_COACH_BEGIN = 'DASHBOARD_EDIT_COACH_BEGIN';
export const DASHBOARD_EDIT_COACH_ERROR = 'DASHBOARD_EDIT_COACH_ERROR';
export const DASHBOARD_EDIT_COACH_SUCCESS = 'DASHBOARD_EDIT_COACH_SUCCESS';
