const sessionsStatus = [
  {
    statusName: 'completedLabel',
    statusId: 'a74f9902-2af0-4009-8cf2-ae71e8f09778',
    badgeType: 'solid-green'
  },
  {
    statusName: 'reviewInProgressLabel',
    statusId: 'a64f9902-2af0-4009-8cf2-ae71e8f09778',
    badgeType: 'hollow-green'
  },
  {
    statusName: 'readyToReviewLabel',
    statusId: 'a54f9902-2af0-4009-8cf2-ae71e8f09778',
    badgeType: 'solid-blue'
  },
  {
    statusName: 'waitingForEvidenceLabel',
    statusId: 'a44f9902-2af0-4009-8cf2-ae71e8f09778',
    badgeType: 'solid-orange'
  },
  {
    statusName: 'notSetLabel',
    statusId: 'a44f9902-2af0-4009-8cf2-ae71e8f09777',// statusId need to be matching BE status id.
    badgeType: 'solid-red'
  },

]

export default sessionsStatus;