import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Col, Row, Form, Button } from 'antd';
import * as organizationService from 'utils/services/organizations';
import ConfirmationModal from 'shared/components/ConfirmModal';
import Input from 'shared/components/Input';
import {connect} from 'react-redux';
import Container from 'shared/components/Container';
import Select from 'shared/components/FormDropdown';
import { useTranslation } from 'react-i18next';
import {
  handleSearchOrganizations,
  handleDeleteSelectedPartner,
  handleUpdateSelectedPartnerAdmin
} from 'features/AdminDashboard/redux/actions/partnersActions'

const rowProps = {
  type: 'flex', gutter: 20,
  className: 'm-t-20'
};

const colProps = {
  xs: 24,
  md: 16
}
const FormItem = Form.Item;
const SelectOption = Select.Option;


const EditPartnerModalContent = props => {

  const [deleteConfirmationModalVisible, setDeleteConfirmationModalVisible] = useState(false)
  const [editConfirmationModalVisible, setEditConfirmationModalVisible] = useState(false)
  const [orgs, setOrgs] = useState([]);


  const {
    selectedPartner,
    closeModal,
    handleUpdate,
    isDeletingPartner,
    isSavingPartner,
    handleDelete,
    isFetchingOrganizations,
    handleSearch,
    derivedPartnerOrganizations,
  } = props;

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    lastName,
    firstName,
    id,
    phone,
    email,
    organizationIds
  } = selectedPartner;

  const onSaveClick = (e) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }
    setEditConfirmationModalVisible(true)

  }

  const deleteCallBack = () => {
    closeModal()
  }

  const onDeleteClick = () => {
    setDeleteConfirmationModalVisible(true)
  }
  
  const deleteUser = () => {
    handleDelete(id, deleteCallBack)
  }

  const saveCallback = () => {
    setEditConfirmationModalVisible(false)
  }
    const saveUser = (e) => {
        if (e && 'preventDefault' in e) e.preventDefault();
        form.validateFields().then((values, err) => {
            const formOrganizations = values.organizations || [];
            const addedOrganizations = [];
            const removedOrganizations = [];

            const initialOrganizations = [...organizationIds];

            formOrganizations.forEach(selectedOrg => {
                if (!initialOrganizations.includes(selectedOrg)) {
                    addedOrganizations.push(selectedOrg)
                }
            })

            initialOrganizations.forEach(initialOrg => {
                if (!formOrganizations.includes(initialOrg)) {
                    removedOrganizations.push(initialOrg);
                }
            })
            const payload = {
                user: {
                    'first_name': values.firstName,
                    'last_name': values.lastName,
                    'mobile_phone': values.phone,
                },
                userId: id,
                addedOrganizations,
                removedOrganizations,
            }

            handleUpdate(id, payload, saveCallback);

        });

    };
        
        
    
  

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  return (
    <Container type='medium'>
      <ConfirmationModal
        visible={deleteConfirmationModalVisible}
        onCancel={() => {
          setDeleteConfirmationModalVisible(false);
         
        }}
        onSubmit={deleteUser}
        title='Partner Admin'
        description='Are you sure you want to remove this Partner Admin?'
        loading={isDeletingPartner}
      />
      <ConfirmationModal
        visible={editConfirmationModalVisible}
        onCancel={() => {
          setEditConfirmationModalVisible(false);
         
        }}
        onSubmit={saveUser}
        title='Partner Admin'
        description='Are you sure you want to edit this Partner Admin?'
        loading={isSavingPartner}
      />
      <Form hideRequiredMark onFinish={onSaveClick} onFinishFailed={onFinishFailed} name='EditPartner' form={form} initialValues={{email: email, firstName: firstName, lastName: lastName, phone: phone, organizations: organizationIds}}>
        <Row {...rowProps}>
          <Col xs={24} md={16}>
            <FormItem name='email' label={t('emailLabel')} rules={[{ required: true, message: t('enterEmailLabel') },]}>
              <Input required disabled />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem name='firstName' label={t('firstNameLabel')} rules={[{ required: true, message: t('enterFirstNameLabel') }]}>
              <Input size='large' required  />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem name='lastName' label={t('lastNameLabel')} rules={[{ required: true, message: t('enterLastNameLabel') }]}>
              <Input size='large' required  />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem name='phone' label={t('phoneOptionalLabel')}>
              <Input size='large'  />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem name='organizations' label={t('partnerOrganizationLabel')} rules={[{required: true, message: 'Select Organization(s).'}]}>
                  <Select
                    showSearch
                    onSearch={text => handleSearch(text)}
                    loading={isFetchingOrganizations}
                    filterOption={false}
                    mode='multiple'
                    popUpContainerElementId='edit-partner-organization-search'
                  >
                    {derivedPartnerOrganizations.map(r => (
                      <SelectOption value={r.organizationId} key={r.organizationId}>
                        {r.name}
                      </SelectOption>
                    ))}
                  </Select>
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps} justify='space-between'>
          <Col {...colProps} className='admin-dashboard__edit-modal-buttons'>
            <Button disabled={isDeletingPartner || isSavingPartner} htmlType='submit'>{t('saveLabel')}</Button>
            <Button disabled={isDeletingPartner || isSavingPartner} onClick={onDeleteClick}>Delete</Button>
          </Col>
        </Row>

      </Form>
    </Container>
  );
};

EditPartnerModalContent.propTypes = {
  selectedPartner: PropTypes.object,
  closeModal: PropTypes.func,
  handleUpdate: PropTypes.func,
  form: PropTypes.object.isRequired,
  isDeletingPartner: PropTypes.bool,
  isSavingPartner: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleOrganizationSearch: PropTypes.func,
  isFetchingOrganizations: PropTypes.bool,
  handleSearch: PropTypes.func,
  derivedPartnerOrganizations: PropTypes.array,
};

EditPartnerModalContent.defaultProps = {
  selectedPartner: {},
  closeModal: () => {},
  handleUpdate: () => {},
  handleDelete: () => {},
  handleSearch: () => {},
  handleOrganizationSearch: () => {},
  isDeletingPartner: false,
  isSavingPartner: false,
  isFetchingOrganizations: false,
  derivedPartnerOrganizations: []
}

const mapStateToProps = ({adminBoard}) => {

  const {partners: {selectedEditPartnerAdmin, derivedPartnerOrganizations, isDeletingPartner, isUpdatingPartnerUser}} = adminBoard;
  return {
    selectedPartner: selectedEditPartnerAdmin,
    derivedPartnerOrganizations,
    isDeletingPartner,
    isSavingPartner: isUpdatingPartnerUser
  }
}

const mapDispatchToProps = {
  handleSearch: handleSearchOrganizations,
  handleDelete: handleDeleteSelectedPartner,
  handleUpdate: handleUpdateSelectedPartnerAdmin
}
export default connect(mapStateToProps, mapDispatchToProps)(EditPartnerModalContent);