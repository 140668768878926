const m02Section = {
  id: 'm02',
  name: '2022ScoresheetM02Label',
  description: '2022ScoresheetM02Label',
  fields: [
    {
      scoring: '2022ScoresheetM02Sub1Label',
      id: 'm02_scoring_1',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        },
      ]
    },
    {
      scoring: '2022ScoresheetM02Sub2Label',
      id: 'm02_scoring_2',
      options: [
        {
          description: '0',
          value: 0
        },
        {
          description: '1',
          value: 1
        },
        {
          description: '2',
          value: 2
        },
        {
          description: '3',
          value: 3
        },
        {
          description: '4',
          value: 4
        },
        {
          description: '5',
          value: 5
        },
        {
          description: '6',
          value: 6
        },
      ]
    }
  ]
}

export default m02Section;