import * as judgeCommentService from 'utils/services/judgeComments';
import { formatJudgeCommentRes, formatJudgeCommentResFLLE } from 'utils/services/judgeComments/model';
import {getUsersBy} from 'utils/services/users';
import {formatUserResData} from 'utils/services/users/model'
import { notification } from 'antd';
import * as types from '../../types/judgingRubric';
import findIndex from 'lodash/findIndex'
import { FLL_CHALLENGE_ID } from 'shared/constants/programIds';

const getJudgeCommentsBegin = () => {
  return {
    type: types.GET_JUDGING_RUBRIC_COMMENTS_BEGIN
  }
}
const getJudgeCommentsError = () => {
  return {
    type: types.GET_JUDGING_RUBRIC_COMMENTS_ERROR
  }
}
const getJudgeCommentsSuccess = (judgeComments) => {
  return {
    type: types.GET_JUDGING_RUBRIC_COMMENTS_SUCCESS,
    payload: {
      judgeComments
    }
  }
}

export const getJudgeComments = (digital_rubric) => {
  return async dispatch => {
    await dispatch(getJudgeCommentsBegin())
    try {
      const {data} = await judgeCommentService.getJudgeCommentsBy(digital_rubric);
      const judgeComments = data.map(d => formatJudgeCommentRes(d));
      const detailedCommentList = [];

      const judgeCommenterIds = [];

      judgeComments.forEach(c => {
        if (!judgeCommenterIds.includes(c.userId)) {
          judgeCommenterIds.push(c.userId)
        }
      })
      if (judgeCommenterIds.length) {
        
        let usersFilter = {
          'id': `in:${judgeCommenterIds.join(',')}`
        }
        
        const userRes = await getUsersBy(usersFilter);
        const formattedRubricCommenters = userRes.data.map(user => { return formatUserResData(user)});
        judgeComments.forEach(comment => {
          const commentCreator = formattedRubricCommenters.find(i => i.id === comment.userId);
          let fullName = '';
          if (commentCreator) {
            let {firstName, lastName} = (commentCreator||{});
            fullName = (firstName && lastName) ? `${firstName} ${lastName}` : (firstName || lastName)
          }
          detailedCommentList.push({
            ...comment,
            fullName
          })
          
           
        })
        
      }
      await dispatch(getJudgeCommentsSuccess(detailedCommentList))

    } catch(err) {
      await dispatch(getJudgeCommentsSuccess([]))
      await dispatch(getJudgeCommentsError())

      throw err;
    }

  }


}

export const getJudgeCommentsForFLLE = (digital_rubric) => {
  return async dispatch => {
    await dispatch(getJudgeCommentsBegin())
    try {
      const res = await judgeCommentService.getJudgeFLLECommentsBy(digital_rubric);
      const judgeComments = res.map(d => formatJudgeCommentResFLLE(d));
      const detailedCommentList = [];

      const judgeCommenterIds = [];

      judgeComments.forEach(c => {
          judgeCommenterIds.push(c.userId)
  
      })

      if (judgeCommenterIds.length) {

        let usersFilter = {
          'id': `in:${judgeCommenterIds.join(',')}`
        }

        const userRes = await getUsersBy(usersFilter);
        const formattedRubricCommenters = userRes.data.map(user => { return formatUserResData(user) });
        judgeComments.forEach(comment => {
          const commentCreator = formattedRubricCommenters.find(i => i.id === comment.userId);
          let fullName = '';
          if (commentCreator) {
            let { firstName, lastName } = (commentCreator || {});
            fullName = (firstName && lastName) ? `${firstName} ${lastName}` : (firstName || lastName)
          }
          detailedCommentList.push({
            ...comment,
            fullName
          })


        })

      }
      await dispatch(getJudgeCommentsSuccess(detailedCommentList))

    } catch (err) {
      await dispatch(getJudgeCommentsSuccess([]))
      await dispatch(getJudgeCommentsError())

      throw err;
    }

  }


}

const createJudgingCommentBegin = () => {
  return {
    type: types.CREATE_JUDGING_RUBRIC_COMMENT_BEGIN
  }
}

const createJudgingCommentError = () => {
  return {
    type: types.CREATE_JUDGING_RUBRIC_COMMENT_ERROR
  }
}

const createJudgingCommentSuccess = (judgingRubricComments) => {
  return {
    type: types.CREATE_JUDGING_RUBRIC_COMMENT_SUCCESS,
    payload: {
      judgingRubricComments
    }
  }
}

export const createFeedbackComment = (commentPayload, cb = () => {} ) => {
  return async (dispatch, getState) => {
    const {
      judgingRubric: {
        judgingRubricComments,
        sessionDetails: {
          digital_rubric
        },
        eventDetails: {
          programId
        }
      },
      auth: {
        userId
      }} = getState();
      const is_FLLC_event = programId == FLL_CHALLENGE_ID;
      const commentsCopy = [...judgingRubricComments];
      await dispatch(createJudgingCommentBegin())
      try {

        let payload;

        if (is_FLLC_event){
          payload = {
            data: {
              type: 'judge_comment',
              attributes: {
                comment: commentPayload.comment,
                referee_id: userId,
                comment_type: commentPayload.commentType,
                comment_section: commentPayload.commentSection,
                judge_object_id: digital_rubric
              },
              relationships: {
                session: {
                    data: {
                        type:"session",
                        id: commentPayload.sessionId,
                    }
                }
              }
            }
          }
        } else {
          payload = {
              data: {
                type: 'judge_rubric_comment',
                attributes: {
                  comment: commentPayload.comment,
                  comment_owner_id: userId,
                  judge_rubric_id: digital_rubric,
                  comment_type: commentPayload.commentType,
                  comment_section: commentPayload.commentSection,
                  session_id: commentPayload.sessionId,
                }
            }
          }
        }

        let newJudgeComment

        if(is_FLLC_event){
          const res = await judgeCommentService.createJudgeComment(payload)
          newJudgeComment = formatJudgeCommentRes(res.data)
        }else{
          const data = await judgeCommentService.createJudgeCommentForFLLE(payload)
          newJudgeComment = formatJudgeCommentResFLLE(data)
        }

        const detailedComment = {
          ...newJudgeComment,
          fullName: commentPayload.fullName
        }
        const updatedList = [...commentsCopy, detailedComment]
        
        await dispatch(createJudgingCommentSuccess(updatedList))
        cb();
        notification.success({
          message: 'Success',
          description: 'Comment successfully added.',
        });

      } catch(err) {
        dispatch(createJudgingCommentError())
        notification.error({
          message: 'Error',
          description: err.data.error,
        });
        throw err;
      }
  }
}

export const createJudgingComment = (comment, sessionId, fullName, cb = () => { }) => {
  return async (dispatch, getState) => {
    const {
      judgingRubric: {
        judgingRubricComments,
        sessionDetails: {
          digital_rubric
        },
        eventDetails: {
          programId
        }
      },
      auth: {
        userId
      }} = getState();
      const isFLLC = programId == FLL_CHALLENGE_ID;
      const commentsCopy = [...judgingRubricComments];
      await dispatch(createJudgingCommentBegin())
      try {
        let payload;
        if (isFLLC){
          payload = {
            data: {
              type: 'judge_comment',
              attributes: {
                comment,
                referee_id: userId,
                judge_object_id: digital_rubric
              },
              relationships: {
                session: {
                  data: {
                    type: "session",
                    id: sessionId,
                  }
                }
              }
            }
          }
        }else{
          payload = {
            data: {
              type: 'judge_rubric_comment',
              attributes: {
                comment,
                comment_owner_id: userId,
                judge_rubric_id: digital_rubric,
                comment_type: 'positive',
                session_id: sessionId,
              }
            }
          }
        }
        let data;
        let newJudgeComment
        if(isFLLC){
          const res = await judgeCommentService.createJudgeComment(payload)
          data = res.data
          newJudgeComment = formatJudgeCommentRes(data)
        }else{
          data = await judgeCommentService.createJudgeCommentForFLLE(payload)
          newJudgeComment = formatJudgeCommentResFLLE(data)
        }
        
        const detailedComment = {
          ...newJudgeComment,
          fullName
        }
        const updatedList = [...commentsCopy, detailedComment]
        
        await dispatch(createJudgingCommentSuccess(updatedList))
        cb();
        notification.success({
          message: 'Success',
          description: 'Comment successfully added.',
        });

      } catch(err) {
        dispatch(createJudgingCommentError())
        notification.error({
          message: 'Error',
          description: err.data.error,
        });
        throw err;
      }
  }
}

const editJudgingCommentBegin = () => {
  return {
    type: types.EDIT_JUDGING_RUBRIC_COMMENT_BEGIN
  }
}

const editJudgingCommentSuccess = (judgingRubricComments) => {
  return {
    type: types.EDIT_JUDGING_RUBRIC_COMMENT_SUCCESS,
    payload: {
      judgingRubricComments
    }
  }
}

const editJudgingCommentError = (judgingRubricComments) => {
  return {
    type: types.EDIT_JUDGING_RUBRIC_COMMENT_ERROR,
    payload: {
      judgingRubricComments
    }
  }
}

export const editJudgingComment = (payload, fullName, commentId, cb = () => {}) => {
  return async (dispatch, getState) => {
    const {
      judgingRubric: {
        judgingRubricComments,
        eventDetails: {
          programId
        }
      }} = getState();
      const commentsCopy = [...judgingRubricComments];
      const isFLLC = programId == FLL_CHALLENGE_ID;
      dispatch(editJudgingCommentBegin())
      try {
        let data;
        if (isFLLC){
          let res = await judgeCommentService.editJudgeComment({
            data: {
              type: 'judge_comment',
              attributes: payload,
            }
          }, commentId)
          data = res.data
        }else{
          data = await judgeCommentService.editJudgeCommentFLLE({
            data: {
              type: 'judge_rubric_comment',
              attributes: { comment: payload.comment },
            }
          }, commentId)
          console.log("===============", data);
        }
        
        let editedJudgeComment;
        if (isFLLC){
          editedJudgeComment = formatJudgeCommentRes(data)
        }else{
          editedJudgeComment = formatJudgeCommentResFLLE(data)
        }
        
        
        const detailedComment = {
          ...editedJudgeComment,
          fullName
        }
        
        const editedCommentIndex = findIndex(commentsCopy, c => c.commentId === commentId)
        let updatedCommentList = commentsCopy
        updatedCommentList[editedCommentIndex] = detailedComment
        
        dispatch(editJudgingCommentSuccess(updatedCommentList))
        cb(commentId);
        notification.success({
          message: 'Success',
          description: 'Comment successfully edited.',
        });

      } catch(err) {
        dispatch(editJudgingCommentError(commentsCopy))
        notification.error({
          message: 'Cannot edit comment',
          description: 'Please try again later',
        });
      }
  }
}

const deleteCommentBegin = () => {
  return {
    type: types.DELETE_JUDGING_RUBRIC_COMMENT_BEGIN
  }
}

const deleteCommentError = () => {
  return {
    type: types.DELETE_JUDGING_RUBRIC_COMMENT_ERROR
  }
}

const deleteCommentSuccess = (judgeComments) => {
  return {
    type: types.DELETE_JUDGING_RUBRIC_COMMENT_SUCCESS,
    payload: {
      judgeComments
    }
  }
}

export const deleteJudgingRubricComment = (commentId) => {
  return async (dispatch, getState) => {
    await dispatch(deleteCommentBegin());
    const {
      judgingRubric: {
        judgingRubricComments,
        eventDetails: {
          programId
        }
      }} = getState();
      const isFLLC = programId == FLL_CHALLENGE_ID
      const commentsCopy = [...judgingRubricComments];
    try {
      if(isFLLC) await judgeCommentService.deleteJudgeComment(commentId);
      else await judgeCommentService.deleteJudgeCommentFLLE(commentId);

      const updatedList = commentsCopy.filter(c => c.commentId !== commentId);
      await dispatch(deleteCommentSuccess(updatedList))
      notification.success({
        message: 'Success',
        description: 'Comment successfully deleted.',
      });
    } catch(err) {
      dispatch(deleteCommentError())
      throw err;
    }
  }
}

export const deleteJudgingRubricCommentFLLE = (commentId) => {
  return async (dispatch, getState) => {
    await dispatch(deleteCommentBegin());
    const {
      judgingRubric: {
        judgingRubricComments
      } } = getState();
    const commentsCopy = [...judgingRubricComments];
    try {
      await judgeCommentService.deleteJudgeCommentFLLE(commentId);

      const updatedList = commentsCopy.filter(c => c.commentId !== commentId);
      await dispatch(deleteCommentSuccess(updatedList))
      notification.success({
        message: 'Success',
        description: 'Comment successfully deleted.',
      });
    } catch (err) {
      dispatch(deleteCommentError())
      throw err;
    }
  }
}