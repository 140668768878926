import SessionUrlActions from 'features/EventContainer/components/ScheduleContainer/SessionUrlActions';
import * as types from '../types/matchScoresheet';

const DEFAULT_EVENT_STATE = {
  eventLocation: null,
  eventId: null,
  eventDate: null,
  eventName: null,
  programId: null,
  season: null
}

const DEFAULT_SCORE_INPUT_STATE = {
  event_id: undefined,
  is_published: undefined,
  m00_scoring_1: undefined,
  m01_scoring_1: undefined,
  m02_scoring_1: undefined,
  m02_scoring_2: undefined,
  m03_scoring_1: undefined,
  m04_scoring_1: undefined,
  m04_scoring_2: undefined,
  m05_scoring_1: undefined,
  m06_scoring_1: undefined,
  m06_scoring_2: undefined,
  m07_scoring_1: undefined,
  m08_scoring_1: undefined,
  m09_scoring_1: undefined,
  m09_scoring_2: undefined,
  m10_scoring_1: undefined,
  m11_scoring_1: undefined,
  m12_scoring_1: undefined,
  m12_scoring_2: undefined,
  m13_scoring_1: undefined,
  m13_scoring_2: undefined,
  m14_scoring_1: undefined,
  m14_scoring_2: undefined,
  m15_scoring_1: undefined,
  m16_scoring_1: undefined,
  // m00_scoring_1: false,
  // m01_scoring_1: false,
  // m02_scoring_1: 0,
  // m02_scoring_2: false,
  // m03_scoring_1: false,
  // m04_scoring_1: false,
  // m04_scoring_2: false,
  // m05_scoring_1: false,
  // m06_scoring_1: false,
  // m06_scoring_2: false,
  // m07_scoring_1: false,
  // m08_scoring_1: 0,
  // m09_scoring_1: false,
  // m09_scoring_2: false,
  // m10_scoring_1: 0,
  // m11_scoring_1: 0,
  // m12_scoring_1: false,
  // m12_scoring_2: false,
  // m13_scoring_1: false,
  // m13_scoring_2: false,
  // m14_scoring_1: 0,
  // m14_scoring_2: 0,
  // m15_scoring_1: 0,
  // m16_scoring_1: 0,
  match_id: undefined,
  precision_tokens: 0,
  score_id: undefined,
}

const DEFAULT_SCORE_STATE = {
  m00_scoring_1: null,
  m01_scoring_1: null,
  m01_scoring_2: null,
  m01_scoring_3: null,
  m02_scoring_1: null,
  m02_scoring_2: null,
  m03_scoring_1: null,
  m03_scoring_2: null,
  m04_scoring_1: null,
  m04_scoring_2: null,
  m05_scoring_1: null,
  m06_scoring_1: null,
  m07_scoring_1: null,
  m08_scoring_1: null,
  m09_scoring_1: null,
  m09_scoring_2: null,
  m10_scoring_1: null,
  m10_scoring_2: null,
  m11_scoring_1: null,
  m12_scoring_1: null,
  m13_scoring_1: null,
  m14_scoring_1: null,
  m14_scoring_2: null,
  m14_scoring_3: null,
  m14_scoring_4: null,
  m15_scoring_1: null,
  m15_scoring_2: null,
  gracious_professionalism: 3,
  precision_tokens: null
}

const DEFAULT_MATCH_STATE = {
  matchId: null,
  matchStatusId: null,
  matchTypeId: null,
  sessionId: null,
  sessionName: null,
  matchStatusName: null,
  matchTypeName: null,
  files: []
}

const DEFAULT_TEAM_STATE = {
  id: null,
  regionName: null,
  countryName: null,
  teamNumber: null,
  teamName: null
}
const DEFAULT_STATE = {
  eventDetails: DEFAULT_EVENT_STATE,
  matchId: null,
  isFetchingEvent: false,
  isFetchingMatch: false,
  matchDetails: DEFAULT_MATCH_STATE,
  scoreInput: DEFAULT_SCORE_INPUT_STATE,
  scoreInputId: null,
  isNewScoreInput: false,
  isFetchingMatchScoreInput: false,
  matchScoreComments: [],
  teamDetails: DEFAULT_TEAM_STATE,
  overallScore: 0,
  scoreId: null,
  isUpdatingMatchScoresheet: false,
  isMatchScoresheetPublished: false,
  isCreatingScoreInputComment: false,
  isCreatingNewScoreInput: false,
  isRecalculatingScore: false,
  isScoresheetEdited: false,
  isRecalculateMatchScoreError: false
}

export default (state = DEFAULT_STATE, action) => {
  const { type } = action;
  switch (type) {
    case types.CREATE_NEW_SCORE_INPUT_BEGIN:
      return {
        ...state,
        isCreatingNewScoreInput: true
      }
    case types.CREATE_NEW_SCORE_INPUT_ERROR:
      return {
        ...state,
        isCreatingNewScoreInput: false
      }
    case types.CREATE_NEW_SCORE_INPUT_SUCCESS:
      return {
        ...state,
        isCreatingNewScoreInput: false
      }
    case types.GET_MATCH_EVENT_DETAILS_BEGIN:
      return {
        ...state,
        isFetchingEvent: true
      }
    case types.GET_MATCH_EVENT_DETAILS_ERROR:
      return {
        ...state,
        isFetchingEvent: false,
        eventDetails: DEFAULT_EVENT_STATE
      }
    case types.GET_MATCH_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingEvent: false,
        eventDetails: {
          ...state.eventDetails,
          ...action.payload.eventDetails
        }
      }
    case types.GET_MATCH_DETAILS_BEGIN:
      return {
        ...state,
        isFetchingMatch: true
      }
    case types.GET_MATCH_DETAILS_ERROR:
      return {
        ...state,
        isFetchingMatch: false,
        matchDetails: DEFAULT_MATCH_STATE
      }
    case types.GET_MATCH_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingMatch: false,
        matchDetails: {
          ...DEFAULT_MATCH_STATE,
          ...action.payload.matchDetails
        }
      }
    case types.RESET_MATCH_DETAILS:
      return {
        ...state,
        isFetchingMatch: false,
        matchDetails: {
          ...DEFAULT_MATCH_STATE
        }
      }
    case types.GET_MATCH_SCORE_INPUT_BEGIN:
      return {
        ...state,
        isFetchingMatchScoreInput: true
      }
    case types.GET_MATCH_SCORE_INPUT_ERROR:
      return {
        ...state,
        isFetchingMatchScoreInput: false,
        scoreInput: DEFAULT_SCORE_INPUT_STATE
      }
    case types.GET_MATCH_SCORE_INPUT_SUCCESS:
      return {
        ...state,
        isFetchingMatchScoreInput: false,
        scoreInput: {
          ...DEFAULT_SCORE_INPUT_STATE,
          ...action.payload.data
        }
      }
    case types.SET_IS_MATCH_SCORE_INPUT_NEW:
      return {
        ...state,
        isNewScoreInput: action.payload.isNewScoreInput,
        scoreInputId: action.payload.scoreInputId,
      }
    case types.RESET_SCORE_INPUT:
      return {
        ...state,
        isScoresheetEdited: true,
        scoreInput: {
          ...state.scoreInput,
          ...DEFAULT_SCORE_STATE
        }
      }
    case types.SET_MATCH_SCORE_COMMENTS:
      return {
        ...state,
        matchScoreComments: action.payload.data
      }
    case types.SET_MATCH_TEAM_DATA:
      return {
        ...state,
        teamDetails: {
          ...state.teamDetails,
          ...action.payload.teamDetails
        }
      }
    case types.SET_MATCH_SCORE_DATA:
      return {
        ...state,
        overallScore: action.payload.overallScore,
        scoreId: action.payload.scoreId
      }
    case types.UPDATE_SCORE_INPUT_BEGIN:
      return {
        ...state,
        isUpdatingMatchScoresheet: true
      }
    case types.UPDATE_SCORE_INPUT_ERROR:
      return {
        ...state,
        isUpdatingMatchScoresheet: false
      }
    case types.UPDATE_SCORE_INPUT_SUCCESS:
      return {
        ...state,
        isUpdatingMatchScoresheet: false
      }
    case types.SET_IS_MATCH_SCORE_INPUT_PUBLISHED:
      return {
        ...state,
        isMatchScoresheetPublished: action.payload.isPublished
      }
    case types.CREATE_NEW_SCORE_COMMENT_BEGIN:
      return {
        ...state,
        isCreatingScoreInputComment: true
      }
    case types.CREATE_NEW_SCORE_COMMENT_ERROR:
      return {
        ...state,
        isCreatingScoreInputComment: false
      }
    case types.CREATE_NEW_SCORE_COMMENT_SUCCESS:
      return {
        ...state,
        isCreatingScoreInputComment: false
      }
    case types.RECALCULATE_MATCH_SCORE_BEGIN:
      return {
        ...state,
        isRecalculatingScore: true
      }
    case types.RECALCULATE_MATCH_SCORE_ERROR:
      return {
        ...state,
        isRecalculatingScore: false,
        isScoresheetEdited: false,
        isRecalculateMatchScoreError: true
      }
    case types.RECALCULATE_MATCH_SCORE_SUCCESS:
      return {
        ...state,
        isRecalculatingScore: false,
        overallScore: action.payload.updatedScore,
        isScoresheetEdited: false,
        isRecalculateMatchScoreError: false
      }
    case types.SET_IS_MATCH_SCORESHEET_EDITED:
      return {
        ...state,
        isScoresheetEdited: action.payload.isScoresheetEdited
      }
    default:
      return state;
  }

}