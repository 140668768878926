import React from 'react';
import PropTypes from 'prop-types';

const ButtonWarningIcon = props => {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 0c6.628 0 12 5.372 12 12s-5.372 12-12 12S0 18.628 0 12 5.372 0 12 0zm.714 17.143h-1.428a1 1 0 00-1 1v1.428a1 1 0 001 1h1.428a1 1 0 001-1v-1.428a1 1 0 00-1-1zm0-13.714h-1.428a1 1 0 00-1 1v10a1 1 0 001 1h1.428a1 1 0 001-1v-10a1 1 0 00-1-1z"
        fill="#FAAD14"
        fillRule="nonzero"
      />
    </svg>
  );
};

ButtonWarningIcon.propTypes = {
  
};

export default ButtonWarningIcon;