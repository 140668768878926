import * as regionService from 'utils/services/regions';
import * as types from '../types';

const getRegionsBegin = () => {
  return {
    type: types.GET_NEW_EVENT_REGIONS_BEGIN
  }
}

const getRegionsError = () => {
  return {
    type: types.GET_NEW_EVENT_REGIONS_ERROR
  }
}

const getRegionsSuccess = (regions) => {
  return {
    type: types.GET_NEW_EVENT_REGIONS_SUCCESS,
    payload: {
      regions
    }
  }
}

const fetchRegionsByProgram = (regionIds) => {
  return async dispatch => {
    await dispatch(getRegionsBegin())
    try {
      const response = await regionService.getRegionsByIds(regionIds);
      console.log(response)
    
      // await dispatch(getRegionsSuccess(data))
    } catch(err) {
      dispatch(getRegionsError())
      throw err;
    }
  }

}

export default fetchRegionsByProgram;