import { Modal ,Row, Col, Form, Button} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Paragraph from 'shared/components/Paragraph';
import Heading from 'shared/components/Heading';
import Input from 'shared/components/Input';
import {urlValidation, urlTransform} from 'utils/formHelpers';
import '../../../../../shared/components/ConfirmModal/Modal.scss';

const rowProps = {
  type: 'flex', gutter: [20, 20],
}
const ConfirmModal = (props) => {
  const {
    onCancel,
    onSubmit,
    visible,
    iconColor,
    title,
    description,
    width,
    loading,
    initialUrlValue
  } = props;
  const { t } = useTranslation()
  const [form] = Form.useForm();

  const submitLinkForm = (e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields().then(async (values, err) => {   
      if (values.sessionLink) {
        onSubmit(urlTransform(values.sessionLink))
      }
    });
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  }

  return (
    (<Modal
      title={null}
      footer={null}
      open={visible}
      maskStyle={{
        background: 'rgba(0,0,0,0.65)'
      }}
      width={`${width || 500}px`}
      wrapClassName='confirm-modal'
      onCancel={onCancel}
      destroyOnClose
      closeIcon={<CloseOutlined style={{ color: iconColor }} />}
    >
      <div className='confirm-modal__body'>
        <h1 className='heading--primary h--400 m-b-16'>{title}</h1>
        <Form className='m-b-36' overrides={{style: {width: '100%'}}} hideRequiredMark onFinish={submitLinkForm} onFinishFailed={onFinishFailed} form={form} initialValues={{sessionLink: initialUrlValue}}>
          <Row {...rowProps} align='middle'>
            <Col xs={24}>
              <Form.Item name='sessionLink' label={<span className='u-sr-only'>Session Link</span>} rules={[{required: true, message: 'Enter Link'}, urlValidation]}>
                <Input size='large' allowClear />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className='confirm-modal__footer'>
          <Button key="back" onClick={onCancel} additionalClassNames='m-r-36'>
            {t('cancelLabel')}
          </Button>
          
          <Button key="submit"loading={loading}>
            {t('saveLabel')}
          </Button>
        </div>
      </div>
    </Modal>)
  );
};

ConfirmModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

ConfirmModal.defaultProps = {
  iconColor: 'white'
};

export default (ConfirmModal);
