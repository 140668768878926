import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {Row, Col} from 'antd';

import Dropdown from 'shared/components/Dropdown';
import './TitleSortRow.scss'

const {Option} = Dropdown;

const TitleSortRow = props => {
  const { t } = useTranslation()
  const {hasLogo, title, logoComponent, options, onSelect} = props;
  return (
    <Row type='flex' gutter={20} justify='space-between' className='title-sort-row'>
      <Col className='title-sort-row__title'>
        {hasLogo && logoComponent}
        <h2 className='h-1 heading--charcoal'>{title}</h2>
      </Col>
      <Col>
        <Dropdown placeholder={t('sortByLabel')} additionalClassNames='dropdown--180' onChange={onSelect}>
          {options.map(option=>{
            return <Option value={option.value} key={option.value}>{option.description}</Option>
          })}
        </Dropdown>
      </Col>
      
    </Row>
  );
};

TitleSortRow.propTypes = {
  title: PropTypes.string,
  hasLogo: PropTypes.bool,
  logoComponent: PropTypes.node,
  onSelect: PropTypes.func,
  options: PropTypes.array,
};

TitleSortRow.defaultProps = {
  title: 'FIRST',
  hasLogo: false,
  logoComponent: <div />,
  onSelect: null,
  options: [],
}

export default TitleSortRow;