import React from 'react';
import PropTypes from 'prop-types';
import './Container.scss';

const CONTAINER_TYPES = [
  'default',
  'small',
  'large',
  'medium'
]

const Container = props => {
  const {
    type,
    children,
    additionalclassname
  } = props
  const className = `c-container--${type} ${additionalclassname}`
  return (
    <div className={className}>
      {children}
    </div>
  );
};

Container.propTypes = {
  type: PropTypes.oneOf(CONTAINER_TYPES),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  additionalclassname: PropTypes.string
};

Container.defaultProps = {
  type: 'default',
  additionalclassname: ''
}

export default Container;