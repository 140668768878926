/* eslint-disable import/prefer-default-export */
import { CURRENT_EVENT_SEASON } from 'shared/constants/eventConstants';
import { API } from 'utils/api';
import { parallelRequest, queryStringify } from 'utils/query';
import {formatScoreRes} from 'utils/services/score/model';


//const { http } = API;

const baseUrl = '/api/score_2022';
const baseUrl2023 = '/api/score';

export const getScoreById = async (scoreInputId) => {
  const url = `${baseUrl2023}/${scoreInputId}`;
  const { data, included } = await API.GetInstance().http.get(url);
  return { data, included: included || [] }; 
}

export const getScoreById2023 = async (scoreInputId) => {
  const url = `${baseUrl2023}/${scoreInputId}`;
  const { data, included } = await API.GetInstance().http.get(url, {headers: {'api-version': 2.0}});
  return { data, included: included || [] };
}

export const getScoresBy = async (filter, page) => {
  const queryStr = queryStringify({ filter, page });
  const url = `${baseUrl}${queryStr}`;
  const { data, included } = await API.GetInstance().http.get(url);
  return { data, included: included || [] }; 
}

export const getAllScoresByScoreIds = async (scoresIds, season) => {
  if (!scoresIds || !scoresIds.length) {
    return []
  }
  let scoreData = [];
  const valid_scoresIds = scoresIds.filter(id=>id)
  await parallelRequest(valid_scoresIds.map(id=>{
    if(season == CURRENT_EVENT_SEASON) return API.GetInstance().http.get(`${baseUrl2023}/${id}`, {headers: {'api-version': 2.0}});
    return API.GetInstance().http.get(`${baseUrl}/${id}`);
  })).then(
    values => {
      scoreData = values.map(v=>v.data)
    },
    errors => {}
  )

  const matchScoresList = scoreData.map(s => formatScoreRes(s));
  return matchScoresList;
}