import { convertTimeToTimeAndDateFormat } from "utils/dateTimeUtils";

export const formatJudgeCommentRes = (data) => {
  const {attributes, id, type, relationships} = data;
  let time = attributes.updated_at || attributes.created_at;
  if(time){
    time = convertTimeToTimeAndDateFormat(time);
  }
  
  return {
    commentId: id,
    comment: attributes.comment,
    commentSection: attributes.comment_section,
    commentType: attributes.comment_type,
    judgeObjectId: attributes.judge_object_id,
    userId: attributes.referee_id,
    sessionId: (((relationships||{}).session||{}).data||{}).session_id,
    dateTime: time,
    created_at: attributes.created_at,
    type
  }

}

export const formatJudgeCommentResFLLE = (data) => {
  let time = data.updated_at || data.created_at;
  if (time) {
    time = convertTimeToTimeAndDateFormat(time);
  }

  return {
    commentId: data.id,
    comment: data.comment,
    commentSection: 'flle',
    commentType: data.comment_type,
    judgeObjectId: data.judge_rubric_id,
    userId: data.comment_owner_id,
    sessionId: data.session_id,
    dateTime: time,
    created_at: data.created_at,
    type: 'judge_comment'
  }

}


export const formatJudgeCommentPayload = () => {}