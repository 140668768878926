import React from 'react';
import PropTypes from 'prop-types';
import '../../../../../../features/PanelManagement/styles/JudgingPanelCard.scss';
import { useTranslation } from 'react-i18next';
import { officialRefereeReviewSessionType, practiceRefereeReviewSessionType } from 'shared/constants/timestampTypes';
import { Col, Row } from 'antd';
import _ from 'lodash';

const TeamsList = props => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const {panelSessions, isInPerson} = props;
  const panelByTeams = _.groupBy(panelSessions, 'teamId');
  const numberOfTeams = Object.keys(panelByTeams).length;
  const subTitle = numberOfTeams === 0 ? t('2022EmptyPanelTeamAssignedLabel') : numberOfTeams === 1? t('2022EmptyPanelTeamAssigned3Label', { '0': 1 }) : t('2022EmptyPanelTeamAssigned2Label', { '0': numberOfTeams });
  const className = numberOfTeams === 0 ? "waring-text" : "sub-title";

  const sortedPanelSessions = _.sortBy(panelSessions, ['teamName', 'sessionTypeId', 'matchNumber'])
  return (
    <div className='judging-panel-card__teams'>
      <p className={`${className}`}>
        {subTitle}
      </p>
      <div>
        <div>
          {sortedPanelSessions.map(s => {
            const sessionTypeName = s.sessionTypeId === practiceRefereeReviewSessionType ? 
            t('practiceReviewLabel') : s.sessionTypeId === officialRefereeReviewSessionType ?
            t('officialReviewLabel') + (isInPerson ? ` ${s.matchNumber}`: '') : s.sessionTypeName;
            return (
              <Row type='flex'>
                <Col span={8}><p className='judging-panel-card__teams-item'>{s.teamName}</p></Col>
                <Col span={8}><p className='judging-panel-card__matches-item'>{sessionTypeName}</p></Col>
              </Row>
          )})}
        </div>
      </div>
    </div>
  );
};

TeamsList.propTypes = {
  panelSessions: PropTypes.array
};

TeamsList.defaultProps = {
  panelSessions: [],
}

export default TeamsList;