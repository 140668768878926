import React from 'react';
import { connect } from 'react-redux';
import InviteesForm from 'shared/components/InviteesForm';
import {
  removePartnerFromInvitees,
  handleInviteAllPartners
} from 'features/AdminDashboard/redux/actions/partnersActions';

const InviteesList = (props) => (
  <InviteesForm
    {...props}
    userType='Partner Users'
  />
);

const mapStateToProps = ({ adminBoard }) => {
  const { partners: { partnerInvitees } } = adminBoard;
  return {
    users: partnerInvitees
  }
};

const mapDispatchToProps = {
  deleteUser: removePartnerFromInvitees,
  handleInviteAllUsers: handleInviteAllPartners
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteesList);
