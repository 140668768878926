import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import '../../../styles/TeamAdded.scss';
import { useTranslation } from 'react-i18next';


const AddTeamForm = (props) => {
  const { t } = useTranslation()
  const { team: { teamName, teamNumber, countryName, regionName } } = props;
  return (
    <div>
      <div className='team-added__row-wrapper'>
        <p className='team-added__label'>{t('teamNameLabel')}</p>
        <div className='team-added__name-wrapper'>
          <div className='team-added__row--name'>
            <p className='team-added__name'>{teamName}</p>
          </div>
        </div>
      </div>
      <div className='team-added__row-wrapper'>
        <p className='team-added__label'>{t('teamNumberLabel')}</p>
        <div className='team-added__row'>
          <p className='team-added__data'>{teamNumber}</p>
        </div>
      </div>
      <div className='team-added__row-wrapper'>
        <p className='team-added__label'>{t('countryLabel')}</p>
        <div className='team-added__row'>
          <p className='team-added__data'>{countryName}</p>
        </div>
      </div>
      <div className='team-added__row-wrapper'>
        <p className='team-added__label'>{t('regionLabel')}</p>
        <div className='team-added__row'>
          <p className='team-added__data'>{regionName}</p>
        </div>
      </div>
    </div>
  );
};

AddTeamForm.propTypes = {
  team: PropTypes.object.isRequired
};

export default AddTeamForm;
