import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';
import DocumentIcon from 'shared/components/Icon/icons/Document';
import PlayButtonIcon from 'shared/components/Icon/icons/PlayButton';
import ImageIcon from 'shared/components/Icon/icons/Image';
import ChooseFile from 'shared/components/ChooseFile';
import './ChooseFileCard.scss';
import * as fileExtensions from 'shared/constants/fileExtensions';

const ACCEPTED_EXTENSIONS = [...fileExtensions.IMAGE_EXTENSIONS, ...fileExtensions.VIDEO_EXTENSIONS, ...fileExtensions.DOCUMENT_EXTENSIONS].join(', ')

const VIDEO_ACCEPT = '.mp4';
const IMAGE_ACCEPT = '.jpg, .png';
const PDF_ACCEPT = '.pdf'

const FILE_TYPES = ['image', 'pdf', 'video']

const ChooseFileCard = props => {
  const {fileType, onUpload, accept, disabled, loading} = props;

  
  const renderIcon = () => {
    switch (fileType) {
      case 'video':
        return PlayButtonIcon;
      case 'image':
        return ImageIcon;
      case 'document':
        return DocumentIcon;
      default:
        return DocumentIcon;
    }
  }



  return (
    <div className='choose-file-card'>
      <Icon component={renderIcon()} className={`choose-file-card-icon choose-file-card-icon--${fileType}`} />
      <div className='choose-file-card-btn'>

        <ChooseFile loading={loading} disabled={disabled} onChange={onUpload} accept={accept} />
      </div>
    </div>
  );
};

ChooseFileCard.propTypes = {
  fileType: PropTypes.oneOf(FILE_TYPES ),
  onUpload: PropTypes.func,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

ChooseFileCard.defaultProps = {
  fileType: 'document',
  onUpload: () => {},
  accept: ACCEPTED_EXTENSIONS,
  disabled: false,
  loading: false
};

export default ChooseFileCard;