export const officialMatchSubmissionDueType = {
  timestampTypeId: '398b1b4e-cfac-4912-a56a-9f94dacfc33f',
  description: 'Official Match Submission Due'
}

export const practiceMatchSubmissionDueType = {
  timestampTypeId: '78372cc4-e807-4727-a477-081cd4405f38',
  description: 'Practice Match Submission Due'
}

export const officialRefereeReviewSessionType = 'e8b9b8aa-b906-45e9-91cb-e0695b6065c2';
export const practiceRefereeReviewSessionType = 'de1d9c81-f8c8-4cdb-8ee9-4829554a7ece';

export const officialAndPracticeMatchSubmissionDueIds = ['398b1b4e-cfac-4912-a56a-9f94dacfc33f','78372cc4-e807-4727-a477-081cd4405f38']

export const officialAndPracticeRefereeSessionIds = ['e8b9b8aa-b906-45e9-91cb-e0695b6065c2', 'de1d9c81-f8c8-4cdb-8ee9-4829554a7ece'];