export default {
  'manually': 'Manually',
  'csvImport': 'Import CSV',
  'addVolunteers': 'Add Volunteers',
  'invitePeople': 'Invite these people',
  'email': 'Email',
  'firstName': 'First Name',
  'lastName': 'Last Name',
  'phone': 'Phone',
  'optional': 'optional',
  'phoneOptional': 'Phone (optional)',
  'role': 'Role',
  'inviteNow': 'Invite Now',
  'inviteAll': 'Invite All',
  'addToList': 'Add to List'
}