import * as teamService from 'utils/services/teams';
import * as eventService from 'utils/services/events';
import * as types from '../types/volunteerDashboardTypes';
import { getUserEventRolesBy } from 'utils/services/eventRoles';

const getEventsBegin = () => {
  return {
    type: types.FETCH_VOLUNTEER_DASHBOARD_EVENTS_BEGIN
  };
};

const getEventsError = () => {
  return {
    type: types.FETCH_VOLUNTEER_DASHBOARD_EVENTS_ERROR
  };
};

const getEventsSuccess = (payload) => {
  return {
    type: types.FETCH_VOLUNTEER_DASHBOARD_EVENTS_SUCCESS,
    payload
  };
};

export const fetchEvents = (page) => {
  return async (dispatch, getState) => {
    await dispatch(getEventsBegin());
    const { auth: { userId } } = getState();
    try {
      const data = await eventService.getEventsByUserId(userId, page);
      await dispatch(getEventsSuccess(data));
    } catch (err) {
      await dispatch(getEventsError());
      throw err;
    }
  };
};

export const fetchVolunteerEvents = (search, page, sort=null)=>{
  return async (dispatch, getState) => {
    await dispatch(getEventsBegin());
    try {
      const {auth: {userId}} = getState();
      const searchFilter = search && { title: `like:${search}` };
      const events = await eventService.getEventsBy(searchFilter, page, sort);
      const {eventsList} = events;
      // get user event roles for each event;
      const eventIds = eventsList.map(e=>e.id).join(',');
      const userEventRolesFilter = {
        'event.id': `in:${eventIds}`,
        'user.id': `eq:${userId}`
      }
      const {data, included} = (eventsList.length) ? await getUserEventRolesBy(userEventRolesFilter, {number:1, size: 100}) : {data:[], included:[]};
      const userEventRolesAssignment = {};
      data.forEach(d=>{
        const {relationships} = (d||{});
        const roleId = (((relationships||{}).role||{}).data||{}).id;
        const eventId = (((relationships||{}).event||{}).data||{}).id;
        if(eventId && roleId){
          const userRoleType = included.find(u=>u.id===roleId);
          const {name} = (userRoleType||{}).attributes||{};
          if(name){
            if(eventId in userEventRolesAssignment){
              userEventRolesAssignment[eventId].push(name);
            }else{
              userEventRolesAssignment[eventId] = [name];
            }
          }
        }
      })

      const eventsListWithRoles = eventsList.map(e=>{
        const {id} = e;
        const roles = userEventRolesAssignment[id] || [];
        e.userRoles = roles
        return e;
      })

      events.eventsList = eventsListWithRoles;

      // end of getting user event roles for each event
      await dispatch(getEventsSuccess(events));
    } catch (err) {
      await dispatch(getEventsError());
      throw err;
    }
  };
}

const getTeamsBegin = () => {
  return {
    type: types.FETCH_VOLUNTEER_DASHBOARD_TEAMS_BEGIN
  };
};

const getTeamsError = () => {
  return {
    type: types.FETCH_VOLUNTEER_DASHBOARD_TEAMS_ERROR
  };
};

const getTeamsSuccess = (payload) => {
  return {
    type: types.FETCH_VOLUNTEER_DASHBOARD_TEAMS_SUCCESS,
    payload
  };
};

export const fetchTeams = (search, page, sort) => {
  return async (dispatch, getState) => {
    await dispatch(getTeamsBegin());
    const { auth: { userId } } = getState();
    try {
      const teamsFilter = {
        'coaches.id': `eq:${userId}`
      };
      if(search){
        teamsFilter['title'] = `like:${search}`
      }
      const teamsRes = await teamService.getTeamsBy(teamsFilter, page, sort);
      await dispatch(getTeamsSuccess(teamsRes));
    } catch (err) {
      await dispatch(getTeamsError());
      throw err;
    }
  };
};
