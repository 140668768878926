import React from 'react';
import Paragraph from 'shared/components/Paragraph';
import { useTranslation } from 'react-i18next';

const UnassignedList = ({ teams }) => {
  const { t } = useTranslation()

  if (!teams.length) {
    return null;
  }
  return (
    <div className='m-t-5'>
      <Paragraph>
        {t('unassignedLabel')}
        :
      </Paragraph>
      {teams.map(({ id, teamNumberName }) => (
        <Paragraph key={id} size='medium' className='p-t-5 p-b-5'>
          {teamNumberName}
        </Paragraph>
      ))}
    </div>
  );
};

export default UnassignedList;
