import React from 'react';
import PropTypes from 'prop-types';
import EventPageHeader from './EventPageHeader';
import '../styles/EventOverviewHeader.scss';

const EventOverviewHeader = props => {
  const { eventDetails, activeTab, coachDetails } = props;
  
  const eventTitle = eventDetails.name;

  const eventLocation = eventDetails.getEventLocation();

  const dateString = eventDetails.parseStartEndTimeShortenedString();
  const { isCoach } = coachDetails;

  const invalidTab = (activeTab !== '#matches' && activeTab !== '#judging' && activeTab !== '#schedule');

  const getTeamName = () => {
    if (!isCoach) return undefined;
    if(invalidTab) return undefined;
    const teamFullName = (coachDetails.teamName && coachDetails.teamNumber)
      ? `Team: ${coachDetails.teamNumber} - ${coachDetails.teamName}`
      : `Team: ${coachDetails.teamName}`;
    return teamFullName;
  };

  const teamName = getTeamName();

  return (
    <EventPageHeader
      title={eventTitle}
      hasSubheading={isCoach && !invalidTab}
      subHeading={teamName}
      eventLocation={eventLocation}
      dateString={dateString}
      programId={eventDetails.programId}
      showBgColor={true}
    />
  );
};

EventOverviewHeader.propTypes = {
  eventDetails: PropTypes.object,
  activeTab: PropTypes.string,
  coachDetails: PropTypes.object
};

EventOverviewHeader.defaultProps = {
  eventDetails: {},
  activeTab: '',
  coachDetails: {}
};

export default EventOverviewHeader;
