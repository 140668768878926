/* eslint-disable import/prefer-default-export */
import{ API } from 'utils/api';
import * as eventRoles from 'shared/constants/eventRoleTypes';
import * as types from '../types/eventRolesTypes';

const fetchEventRoleTypesBegin = () => {
  return {
    type: types.FETCH_EVENT_ROLE_TYPES_BEGIN,
    payload: {
      isFetchingEventRoleTypes: true
    }
  }
}

const fetchEventRoleTypesError = () => {
  return {
    type: types.FETCH_EVENT_ROLE_TYPES_ERROR,
    payload: {
      isFetchingEventRoleTypes: false,
      eventRoleTypes: []
    }
  }
}

const fetchEventRoleTypesSuccess = (eventRoleTypes) => {
  return {
    type: types.FETCH_EVENT_ROLE_TYPES_SUCCESS,
    payload: {
      isFetchingEventRoleTypes: false,
      eventRoleTypes
    }
  }
}



export const handleGetEventRoles = () => {
  return async dispatch => {
    try {
      await dispatch(fetchEventRoleTypesBegin())

      const response = await API.GetInstance().http.get('/api/user_event_role_types');
      const {data} = response;
      const filteredList = data.filter(r => r.id !== eventRoles.VOLUNTEER_COORDINATOR_ID)
      await dispatch(fetchEventRoleTypesSuccess(filteredList))
      
    } catch(err) {
      await dispatch(fetchEventRoleTypesError())
      throw err
    }
  }
}