import React from 'react';
import Input from 'shared/components/Input';
import './TextBox.scss';

import PropTypes from 'prop-types';

const { TextArea } = Input;

const TextBox = props => {
  const { disabled } = props;
  const className = disabled ? 'text-box--disabled' : 'text-box'
  return (
    <TextArea allowClear {...props} className={className} />
  );
};

TextBox.propTypes = {
  disabled: PropTypes.bool
};

TextBox.defaultProps = {
  disabled: false
};

export default TextBox;
