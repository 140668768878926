const m12Section = {
  id: 'm12',
  name: '2022ScoresheetM12Sub1Label',
  fields: [
    {
      scoring: '2022ScoresheetM12Sub2Label',
      id: 'm12_scoring_1',
      options: [
        {
          description: 'noLabel',
          value: 'no'
        },
        {
          description: '2022ScoresheetM12Sub3Label',
          value: "The Mat"
        },
        {
          description: '2022ScoresheetM12Sub4Label',
          value: "Nothing Else"
        }
      ]
    },
    {
      scoring: '2022ScoresheetM12Sub5Label',
      id: 'm12_scoring_2',
      options: [
        {
          description: 'noLabel',
          value: 'no'
        },
        {
          description: '2022ScoresheetPartlyLabel',
          value: "Partly"
        },
        {
          description: '2022ScoresheetCompletelyLabel',
          value: "Completely"
        }
      ]
    },
  ]
}

export default m12Section;