import * as types from '../types/eventAwardsTypes';
import * as awardService from 'utils/services/awards';
import * as teamService from 'utils/services/teams';
import * as sessionService from 'utils/services/sessions';
import * as sessionDocumentService from 'utils/services/sessionDocuments';
import * as judgingService from 'utils/services/judging';
import { formatDataResult, parallelRequest } from 'utils/query';

const getJudgingFeedbacksBegin = (payload) => {
  return {
    type: types.GET_EVENT_JUDGING_FEEDBACKS_BEGIN,
    payload
  };
};

const getJudgingFeedbacksError = () => {
  return {
    type: types.GET_EVENT_JUDGING_FEEDBACKS_FAIL
  };
};

const getJudgingFeedbacksSuccess = (payload) => {
  return {
    type: types.GET_EVENT_JUDGING_FEEDBACKS_SUCCESS,
    payload
  };
};

const setFeedbackSessions = (payload) => {
  return {
    type: types.SET_EVENT_FEEDBACK_SESSIONS,
    payload
  };
};

export const resetEventAwards = (eventId) => async (dispatch, getState) => {
  const { event: { eventAwards } } = getState();
  if (eventAwards.eventId && eventAwards.eventId === eventId) {
    return;
  }
  dispatch({
    type: types.RESET_EVENT_AWARDS,
    payload: eventId
  });
};

export const fetchJudgingFeedbacks = (scheduleId, isFLLC) => async (dispatch, getState) => {
  try {
    const { event: { eventAwards } } = getState();
    if (eventAwards.scheduleId && eventAwards.scheduleId === scheduleId) {
      return;
    }
    await dispatch(getJudgingFeedbacksBegin({ scheduleId, isFLLC }));
    const { data } = await sessionService.getJudgingSessions(scheduleId);
    const sessionIds = data && data.map(({ id }) => id);
    let digital_rubrics = [];
    data.forEach(item => {
      const digital_rubric = ((((item||{}).relationships||{}).digital_rubric||{}).data||{}).id;
      if(digital_rubric) digital_rubrics.push(digital_rubric);
    });
    await dispatch(setFeedbackSessions(sessionIds));
    if (sessionIds && sessionIds.length) {
      let results;
      if (isFLLC) {
        if(digital_rubrics.length){
          let judgings = [];
          const judgingRequests = digital_rubrics.map(digital_rubric=>judgingService.getJudgingObjectByRequest(digital_rubric));
          const values = await parallelRequest(judgingRequests);
          judgings = values.map(res=>{
            const {data} = res;
            return formatDataResult(data);
          })
          results = judgings.map(({ id, sessionId, isPublishable, isPublished }) => ({ id, sessionId, isPublishable, isPublished }));
        }
      } else {
        results = await sessionService.getAllSessionsRubrics(sessionIds);
      }
      const { event: { eventAwards: { scheduleId: fetchedScheduleId, sessions } } } = getState();
      if (scheduleId === fetchedScheduleId && sessions.length && 
        !results.find(({ sessionId }) => !sessions.includes(sessionId))
      ) {
        dispatch(getJudgingFeedbacksSuccess(results));
      }
    }else{
      dispatch(getJudgingFeedbacksSuccess([]))
    }
  } catch (e) {
    dispatch(getJudgingFeedbacksError());
  }
};

const publishJudgingFeedbacksBegin = (payload) => {
  return {
    type: types.PUBLISH_EVENT_JUDGING_FEEDBACKS_BEGIN,
    payload
  };
};

const publishJudgingFeedbacksError = () => {
  return {
    type: types.PUBLISH_EVENT_JUDGING_FEEDBACKS_FAIL
  };
};

const publishJudgingFeedbacksSuccess = (payload) => {
  return {
    type: types.PUBLISH_EVENT_JUDGING_FEEDBACKS_SUCCESS,
    payload
  };
};

export const publishJudgingFeedbacks = (unpublish) => async (dispatch, getState) => {
  try {
    dispatch(publishJudgingFeedbacksBegin());
    const { event: { eventAwards: { judgings, isFLLC } } } = getState();
    let results = [];
    if (isFLLC) {
      results = await judgingService.publishJudgingObjects(judgings, unpublish);
    } else {
      results = await sessionDocumentService.publishSessionDocuments(judgings, unpublish);
    }
    dispatch(publishJudgingFeedbacksSuccess({ results, isPublished: !unpublish }));
  } catch (e) {
    dispatch(publishJudgingFeedbacksError());
  }
};

// GET Event Award Teams
const getEventAwardTeamsBegin = () => {
  return {
    type: types.GET_EVENT_AWARD_TEAMS_BEGIN
  };
};

const getEventAwardTeamsError = () => {
  return {
    type: types.GET_EVENT_AWARD_TEAMS_FAIL
  };
};

const getEventAwardTeamsSuccess = (payload) => {
  return {
    type: types.GET_EVENT_AWARD_TEAMS_SUCCESS,
    payload
  };
};

export const fetchEventAwardTeams = (eventId) => async (dispatch) => {
  try {
    dispatch(getEventAwardTeamsBegin());
    const teams = await teamService.getAllTeamsByEventIdWithoutInclude(eventId);
    dispatch(getEventAwardTeamsSuccess(teams));
  } catch (e) {
    dispatch(getEventAwardTeamsError());
  }
};

const getEventAwardsBegin = (payload) => {
  return {
    type: types.GET_EVENT_AWARDS_BEGIN,
    payload
  };
};

const getEventAwardsError = () => {
  return {
    type: types.GET_EVENT_AWARDS_FAIL
  };
};

const getEventAwardsSuccess = (payload) => {
  return {
    type: types.GET_EVENT_AWARDS_SUCCESS,
    payload
  };
};

export const fetchEventAwards = (eventId) => async (dispatch, getState) => {
  try {
    const { event: { eventAwards } } = getState();
    if (eventAwards.eventId && eventAwards.eventId === eventId) {
      return;
    }
    dispatch(getEventAwardsBegin(eventId));
    const data = await awardService.getAwardsByEventId(eventId);
    dispatch(getEventAwardsSuccess(data));
  } catch (e) {
    dispatch(getEventAwardsError());
  }
};

const publishEventAwardsBegin = () => {
  return {
    type: types.PUBLISH_EVENT_AWARDS_BEGIN
  };
};

const publishEventAwardsError = () => {
  return {
    type: types.PUBLISH_EVENT_AWARDS_FAIL
  };
};

const publishEventAwardsSuccess = (payload) => {
  return {
    type: types.PUBLISH_EVENT_AWARDS_SUCCESS,
    payload
  };
};

export const publishEventAwards = (eventId, unpublish) => async (dispatch, getState) => {
  try {
    const { event: { eventAwards } } = getState();
    if (eventId !== eventAwards.eventId) {
      return;
    }
    dispatch(publishEventAwardsBegin());
    await awardService.publishEventAwardsRequest(eventId, unpublish);
    dispatch(publishEventAwardsSuccess(unpublish));
  } catch (e) {
    dispatch(publishEventAwardsError());
  }
};
