import { Modal, Row, Col, Form } from 'antd';
import {WarningOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Paragraph from 'shared/components/Paragraph';
import BtnPrimary from 'shared/components/Button/BtnPrimary';
import BtnSecondary from 'shared/components/Button/BtnSecondary';
import {CloseOutlined} from '@ant-design/icons';

import '../../../../../shared/components/ConfirmModal/Modal.scss';
import './SessionUrlActions.scss';


const REGENERATE_LINK_TEXT = 'Regenerating this link will remove any previously configured meeting link, and will generate a new one. Do you wish to proceed?';

const ConfirmModal = (props) => {
  const {
    onCancel,
    onSubmit,
    visible,
    iconColor,
    title,
    description,
    width,
    loading,
  } = props;
  const { t } = useTranslation()

  return (
    (<Modal
      title={null}
      footer={null}
      open={visible}
      maskStyle={{
        background: 'rgba(0,0,0,0.65)'
      }}
      width={`${width || 500}px`}
      wrapClassName='confirm-modal'
      onCancel={onCancel}
      destroyOnClose
      maskClosable={false}
      closeIcon={<CloseOutlined style={{ color: iconColor }} />}
    >
      <div className='confirm-modal__body'>
        <span className='m-b-16 c-session-url__regenerate-header'>
          <WarningOutlined className='m-r-8' />
          <h1 className='heading--primary h--400'> 
       
            Regenerate Link?
          </h1>
        </span>
        <Paragraph size='large' className='m-b-36'>{REGENERATE_LINK_TEXT}</Paragraph>

        <div className='confirm-modal__footer'>
          <BtnSecondary key="back" onClick={onCancel} additionalClassNames='m-r-36'>
            {t('cancelLabel')}
          </BtnSecondary>
          
          <BtnPrimary key="submit" onClick={onSubmit} loading={loading}>
            {t('yesLabel')}
          </BtnPrimary>
        </div>
      </div>
    </Modal>)
  );
};

ConfirmModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

ConfirmModal.defaultProps = {
  iconColor: 'white'
};

export default ConfirmModal;
