import React from 'react';
import Paragraph from 'shared/components/Paragraph';
import { useTranslation } from 'react-i18next';


const Instructions = ({ is_FLLC_event = true}) => {
  const { t } = useTranslation()
  return (
    <div className='c-event-awards__instructions'>
      <Paragraph size='medium' weight='bold'>{t('instructionsLabel')}</Paragraph>
      <Paragraph size='medium'>{is_FLLC_event ? t('publishAwardInstructionText') : t('nonChallengePublishAwardInstructionText')}</Paragraph>
    </div>
  );
};

export default Instructions;
