import shortLangaugeKeys from 'utils/shortLangaugeKeys';
import Languages from './languages.json';
const codes = Object.keys(Languages);

const languageResources = {};
codes.forEach(key => {
  if(!(key in languageResources)) {
    const shortLang = shortLangaugeKeys(key);
    languageResources[shortLang] = {"common": Languages[key]}
  }
})


export default languageResources;