import React from 'react';

export default ()=>{
  return (
    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      <g id="Scoring-and-Refereeing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Teams---Single-Event---Scoring---Coach" transform="translate(-601.000000, -1055.000000)" fill="#FAAD14" fillRule="nonzero">
              <g id="Group-2" transform="translate(249.000000, 390.000000)">
                  <g id="Group-21" transform="translate(0.000000, 596.000000)">
                      <g id="Group-19" transform="translate(1.000000, 0.000000)">
                          <g id="Message/_Icon/Warning" transform="translate(351.000000, 69.000000)">
                              <path d="M9,0 C13.9710937,0 18,4.02890625 18,9 C18,13.9710937 13.9710937,18 9,18 C4.02890625,18 0,13.9710937 0,9 C0,4.02890625 4.02890625,0 9,0 Z M9.53571429,12.8571429 L8.46428571,12.8571429 C8.05007215,12.8571429 7.71428571,13.1929293 7.71428571,13.6071429 L7.71428571,13.6071429 L7.71428571,14.6785714 C7.71428571,15.092785 8.05007215,15.4285714 8.46428571,15.4285714 L8.46428571,15.4285714 L9.53571429,15.4285714 C9.94992785,15.4285714 10.2857143,15.092785 10.2857143,14.6785714 L10.2857143,14.6785714 L10.2857143,13.6071429 C10.2857143,13.1929293 9.94992785,12.8571429 9.53571429,12.8571429 L9.53571429,12.8571429 Z M9.53571429,2.57142857 L8.46428571,2.57142857 C8.05007215,2.57142857 7.71428571,2.90721501 7.71428571,3.32142857 L7.71428571,3.32142857 L7.71428571,10.8214286 C7.71428571,11.2356421 8.05007215,11.5714286 8.46428571,11.5714286 L8.46428571,11.5714286 L9.53571429,11.5714286 C9.94992785,11.5714286 10.2857143,11.2356421 10.2857143,10.8214286 L10.2857143,10.8214286 L10.2857143,3.32142857 C10.2857143,2.90721501 9.94992785,2.57142857 9.53571429,2.57142857 L9.53571429,2.57142857 Z" id="Combined-Shape"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
  )
}