import * as teamService from 'utils/services/teams';
import * as userService from 'utils/services/users';
import * as eventRoleService from 'utils/services/eventRoles';
import { notification } from 'antd';
import { seventyTwoHoursFromNow } from 'utils/dateTimeUtils';
import { COACH_ID } from 'shared/constants/eventRoleTypes';
import * as types from '../types/teamsTypes';
import { getAwardAssignmentByIdRequest, getAwardsOnlyByEventIdRequest, removeTeamFromAwardRequest} from 'utils/services/awards';
import { parallelRequest } from 'utils/query';

export const requestTeamsBegin = () => {
  return {
    type: types.DASHBOARD_REQUEST_TEAMS_BEGIN,
    payload: {
      isFetchingTeams: true
    }
  };
};

export const requestTeamsSuccess = (teams, included, count) => {
  return {
    type: types.DASHBOARD_REQUEST_TEAMS_SUCCESS,
    payload: {
      teams,
      count,
      included,
      isFetchingTeams: false,
    }
  };
};

export const requestTeamsError = () => {
  return {
    type: types.DASHBOARD_REQUEST_TEAMS_ERROR,
    payload: {
      teams: [],
      isFetchingTeams: false
    }
  };
};

export const requestTeams = (pageFilter, searchText, sort) => {
  return async (dispatch) => {
    try {
      await dispatch(requestTeamsBegin());
      const filter = searchText && { name: `like:${searchText}` };
      const { data, included, count } = await teamService.getTeamsBy(filter, pageFilter, sort);
      if (Array.isArray(data)) {
        await dispatch(requestTeamsSuccess(data, included, count));
      }
    } catch (error) {
      await dispatch(requestTeamsError());
      throw error;
    }
  };
};


const reinviteUserBegin = () => {
  return {
    type: types.DASHBOARD_REINVITE_COACH_BEGIN
  }
}

const reinviteUserError = () => {
  return {
    type: types.DASHBOARD_REINVITE_COACH_ERROR
  }
}

const reinviteUserSuccess = (updatedIncludedList = []) => {
  return {
    type: types.DASHBOARD_REINVITE_COACH_SUCCESS,
    payload: {
      updatedIncludedList
    }
  }
}

export const handleReinviteCoachUser = (userId) => {
  return async (dispatch, getState) => {
    const { adminBoard: { teams: { included } } } = getState();
    const copyIncluded = [...included];

    try {
      await dispatch(reinviteUserBegin());
      await userService.reinviteUser(userId);
      const newTimestamp = seventyTwoHoursFromNow();

      const selectedVolunteerUser = copyIncluded.find(i => i.id === userId);

      if (selectedVolunteerUser) {
        const selectedVolunteerIndex = copyIncluded.findIndex(i => i.id === userId);
        const updatedVolunteerUser = {
          ...selectedVolunteerUser,
          attributes: {
            ...selectedVolunteerUser.attributes,
            verification_code_timeout: newTimestamp
          }
        }
        copyIncluded[selectedVolunteerIndex] = updatedVolunteerUser;

      }
      await dispatch(reinviteUserSuccess(copyIncluded));

      notification.success({
        message: 'Success',
        description: 'User successfully reinvited.',
      })
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error reinviting user',
      });
      await dispatch(reinviteUserError());
      throw err;
    }
  }
}

const deleteHubTeamBegin = () => {
  return {
    type: types.DASHBOARD_DELETE_TEAM_BEGIN
  };
};

const deleteHubTeamError = () => {
  return {
    type: types.DASHBOARD_DELETE_TEAM_ERROR
  };
};

const deleteHubTeamSuccess = () => {
  return {
    type: types.DASHBOARD_DELETE_TEAM_SUCCESS
  };
};

const deleteAllRelatedAwardAssignment = (teamId)=>{
  return async (dispatch, getState)=>{
    const award_assignments = [];
    try{
      const {adminBoard: {teams: {teams}}} = getState();
      const team = (teams||[]).find(team=>team.id===teamId);
      const eventIds = ((((team||{}).relationships||{}).events||{}).data||[]).map(e=>e.id).filter(id=>!!id);
      const awardRequests = eventIds.map(id=>getAwardsOnlyByEventIdRequest(id));
      const awards = await parallelRequest(awardRequests);
      awards.forEach(award=>{
        const awardIncluded = (award||{}).included||[];
        const assignment = awardIncluded.filter(i=>i.type==="award_assignment");
        award_assignments.push(...assignment)
      });

      const assignmentsId = award_assignments.map(a=>a.id).filter(id=>!!id);
      const getAssignmentRequests = assignmentsId.map(id=>getAwardAssignmentByIdRequest(id));
      const assignmentDetails = await parallelRequest(getAssignmentRequests);

      const relatedAssignments = assignmentDetails.filter(assignmentData=>{
        const assignmentTeam = ((((assignmentData||{}).data||{}).relationships||{}).team||{}).data||{};
        return assignmentTeam.id === teamId;
      });

      const deleteAssignmentIds = relatedAssignments.map(r=>((r||{}).data||{}).id).filter(id=>!!id);
      const deleteAssignmentRequests = deleteAssignmentIds.map(id=>removeTeamFromAwardRequest(id));
      await parallelRequest(deleteAssignmentRequests);

    }catch(e){
      throw(e);
    }
  }
}

export const deleteRehTeam = ({ teamId, coachesId }, successCb, errorCb) => {
  return async (dispatch) => {
    try {
      await dispatch(deleteHubTeamBegin());
      // if (coachesId.length) {
      //   const filter = {
      //     'role.id': `eq:${COACH_ID}`,
      //     'user.id': `in:${coachesId.join(',')}`,
      //     'event.teams.id': `eq:${teamId}`
      //   };
      //   const { data } = await eventRoleService.getAllUserEventRolesBy(filter);
      //   if (data && data.length) {
      //     const assignmentIds = data.map(({ id }) => id);
      //     const teamPayload = { data: [{ type: 'team', id: teamId }] };
      //     for (let i = 0; i < assignmentIds.length; i++) {
      //       await eventRoleService.removeTeamFromCoach(assignmentIds[i], teamPayload);
      //     }
      //   }
      // }
      await teamService.deleteTeam(teamId);
      await dispatch(deleteAllRelatedAwardAssignment(teamId));
      notification.success({
        message: 'Success',
        description: `Team successfully deleted ${coachesId.length ? 'and removed from events' : ''}.`
      });
      await dispatch(deleteHubTeamSuccess(teamId));
      successCb();
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error deleting the team',
      });
      await dispatch(deleteHubTeamError());
      errorCb();
      throw err;
    }
  };
};

const deleteTeamCoachBegin = () => {
  return {
    type: types.DASHBOARD_DELETE_COACH_BEGIN
  };
};

const deleteTeamCoachError = () => {
  return {
    type: types.DASHBOARD_DELETE_COACH_BEGIN
  };
};

const deleteTeamCoachSuccess = (payload) => {
  return {
    type: types.DASHBOARD_DELETE_COACH_SUCCESS,
    payload
  };
};

export const deleteTeamCoach = ({ teamId, userId }, successCb, errorCb) => {
  return async (dispatch) => {
    try {
      await dispatch(deleteTeamCoachBegin());
      const filter = {
        'role.id': `eq:${COACH_ID}`,
        'user.id': `eq:${userId}`,
        'event.teams.id': `eq:${teamId}`
      }
      const { data } = await eventRoleService.getAllUserEventRolesBy(filter);
      if (data && data.length) {
        const assignmentIds = data.map(({ id }) => id);
        const teamPayload = { data: [{ type: 'team', id: teamId }] };
        for (let i = 0; i < assignmentIds.length; i++) {
          await eventRoleService.removeTeamFromCoach(assignmentIds[i], teamPayload);
        }
        notification.success({
          message: 'Success',
          description: 'Coach successfully removed from team.',
        });
        await dispatch(deleteTeamCoachSuccess({ teamId, userId }));
        successCb();
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error removing the coach from team',
      });
      await dispatch(deleteTeamCoachError());
      errorCb();
      throw err;
    }
  };
};
