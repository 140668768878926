const LABELS = {
  'Judging Session': 'judgingSessionLabel',
  'Judge session': 'judgeSessionLabel',
  'Session': 'sessionLabel'
}
const NON_FLLC_LABELS = {
  'Judging Session': 'nonChallengeJudgingSessionLabel',
  'Judge session': 'nonChallengeJudgeSessionLabel',
  'Session': 'sessionLabel'
}

export const getTransLabel = (t, en, is_FLLC_event) => {
  const label = is_FLLC_event ? LABELS[en] : NON_FLLC_LABELS[en];
  if (label) {
    return t(label)
  }
  return en
}
