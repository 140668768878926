import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmModal from 'shared/components/ConfirmModal';

const AwardConfirmModal = (props) => {
  const { isSubmitted, is_FLLC_event } = props;
  const {t} = useTranslation();
  
  const title = isSubmitted 
    ? t('withdrawAwardsWarningLabel')
    : is_FLLC_event ? t('submitAwards&feedbackLabel') : t('nonChallengeSubmitAwards&feedbackLabel');
  const description = isSubmitted
    ? 'When you withdraw submission the list of award winner will be pending to Event Admins.'
    : t('submitNoticeLabel')
  return (
    <ConfirmModal
      title={title}
      description={description}
      {...props}
    />
  );
};

export default AwardConfirmModal;
