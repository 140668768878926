import * as types from '../types/teamsTypes';

const DEFAULT_SELECTED_COACH = {
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  phone: undefined,
  teams: [],
  teamsIncluded: [],
}

// const DEFAULT_TEAM = {
//   teamName: '',
//   teamNumber: '',
//   country: '',
//   region: ''
// }

const DEFAULT_TEAMS_STATE = {
  eventTeamsList: [],
  eventTeamsListAll: [],
  count: 0,
  eventTeamsIncludedResponse: [],
  isFetchingEventTeams: false,
  errorFetchingTeams: false,
  isFetchingEventAllTeams: false,
  errorFetchingAllTeams: false,
  addCoachesManuallyList: [],
  coachSelectedInAutocomplete: false,
  addCoachesImportList: [],
  selectedCoach: DEFAULT_SELECTED_COACH,
  isSettingSelectedCoach: false,
  isAddingTeamToCoach: false,
  isAddTeamDisabled: true,
  isTeamSelected: false,
  selectedTeam: undefined,
  selectedTeamDetails: undefined,
  addedTeamList: [],
  isAddingCoachToList: false,
  isCreatingNewTeam: false,
  eventCountries: [],
  eventRegions: [],
  isFetchingCountries: false,
  isFetchingRegions: false,
  isInvitingCoach: false,
  newTeamCreated: false,
  newCoachInvited: false,
  importedCoachesSuccessfullyInvited: [],
  isInvitingImportedCoaches: false,
  importErrorsList: [],
  importedCoachesNotInvited: [],
  isRemovingCoach: false,
  isUpdatingCoach: false,
  isDeletingTeam: false,
  isUpdatingTeam: false
}

export default (state = DEFAULT_TEAMS_STATE, action) => {
  const {type} = action;
  switch(type) {
    case types.SET_SELECTED_COACH_BEGIN:
      return {
        ...state,
        isSettingSelectedCoach: true
      }
    case types.SET_SELECTED_COACH_ERROR:
      return {
        ...state,
        isSettingSelectedCoach: false,
        selectedCoach: DEFAULT_SELECTED_COACH
      }
    case types.SET_SELECTED_COACH_SUCCESS:
      return {
        ...state,
        isSettingSelectedCoach: false,
        selectedCoach: {
          ...state.selectedCoach,
          [action.payload.fieldName] : action.payload.value
        }
      }
    case types.UPDATE_SELECTED_COACH:
      return {
        ...state,
        selectedCoach: {
          ...state.selectedCoach,
          [action.payload.fieldName] : action.payload.value
        }
      }
    case types.SET_IS_ADD_TEAM_DISABLED:
      return {
        ...state,
        isAddTeamDisabled: action.payload.isAddTeamDisabled
      }
    case types.SET_SELECTED_TEAM_FOR_COACH_SUCCESS:
      return {
        ...state,
        selectedTeam: action.payload.selectedTeam
      }
    case types.SET_SELECTED_TEAM_DETAILS:
      return {
        ...state,
        selectedTeamDetails: action.payload.selectedTeamDetails
      }
    case types.COACH_ADD_TO_LIST_BEGIN:
      return {
        ...state,
        isAddingCoachToList: action.payload.isAddingCoachToList,
      }
    case types.COACH_ADD_TO_LIST_ERROR:
      return {
        ...state,
        isAddingCoachToList: action.payload.isAddingCoachToList,
      }
    case types.COACH_ADD_TO_LIST_SUCCESS:
      return {
        ...state,
        isAddingCoachToList: action.payload.isAddingCoachToList,
        addCoachesManuallyList: action.payload.addCoachesManuallyList
      }
    case types.CREATE_NEW_TEAM_BEGIN:
      return {
        ...state,
        isCreatingNewTeam: action.payload.isCreatingNewTeam
      }
    case types.CREATE_NEW_TEAM_ERROR:
      return {
        ...state,
        isCreatingNewTeam: action.payload.isCreatingNewTeam
      }
    case types.CREATE_NEW_TEAM_SUCCESS:
      return {
        ...state,
        isCreatingNewTeam: action.payload.isCreatingNewTeam,
        newTeamCreated: true
      }

    case types.RESET_SELECTED_COACH:
      return {
        ...state,
        selectedCoach: DEFAULT_SELECTED_COACH
      }
    case types.RESET_SELECTED_TEAM_FOR_COACH:
      return {
        ...state,
        selectedTeam: undefined
      }
    case types.RESET_MANUALLY_ADDED_COACHES_LIST:
      return {
        ...state,
        addCoachesManuallyList: []
      }
    case types.SET_SELECTED_TEAM_DETAILS_RESET:
      return {
        ...state,
        selectedTeamDetails: undefined
      }
    case types.SET_SELECTED_COACH_IN_AUTOCOMPLETE: 
      return {
        ...state,
        coachSelectedInAutocomplete: true,
        isAddTeamDisabled: false,
        selectedCoach: {
          ...action.payload.selectedCoach,
          teams: []
        }
      }
    case types.RESET_SELECTED_COACH_IN_AUTOCOMPLETE: 
      return {
        ...state,
        coachSelectedInAutocomplete: false,
        selectedCoach: DEFAULT_SELECTED_COACH,
        isAddTeamDisabled: true,
      }
    case types.GET_EVENT_TEAMS_BEGIN:
      return {
        ...state,
        isFetchingEventTeams: true,
        newTeamCreated: false,
        newCoachInvited: false,
        errorFetchingTeams: false
      }
    case types.GET_EVENT_TEAMS_ERROR:
      return {
        ...state,
        isFetchingEventTeams: false,
        errorFetchingTeams: true
      }
    case types.GET_EVENT_TEAMS_SUCCESS:
      return {
        ...state,
        isFetchingEventTeams: false,
        errorFetchingTeams: false,
        eventTeamsList: action.payload.data,
        eventTeamsIncludedResponse: action.payload.included,
        count: action.payload.count
      }
    case types.GET_EVENT_ALL_TEAMS_BEGIN:
      return {
        ...state,
        isFetchingEventAllTeams: true,
        // newTeamCreated: false,
        // newCoachInvited: false,
        // errorFetchingTeams: false
      }
    case types.GET_EVENT_ALL_TEAMS_ERROR:
      return {
        ...state,
        isFetchingEventAllTeams: false,
        errorFetchingAllTeams: true
      }
    case types.GET_EVENT_ALL_TEAMS_SUCCESS:
      return {
        ...state,
        isFetchingEventAllTeams: false,
        errorFetchingTeams: false,
        eventTeamsListAll: action.payload,
        // eventTeamsIncludedResponse: action.payload.included,
        // count: action.payload.count
      }
    case types.UPDATE_EVENT_TEAMS_ON_REMOVING_COACH:
      return {
        ...state,
        eventTeamsList: action.payload.data,
        count: action.payload.count,
      }
      case types.GET_EVENT_TEAMS_REGIONS_BEGIN:
        return {
          ...state,
          isFetchingRegions: true
        }
      case types.GET_EVENT_TEAMS_REGIONS_ERROR:
        return {
          ...state,
          isFetchingRegions: false,
          eventRegions: []
        }
      case types.GET_EVENT_TEAMS_REGIONS_SUCCESS:
        return {
          ...state,
          isFetchingRegions: false,
          eventRegions: action.payload.regions
        }
      case types.GET_EVENT_TEAMS_COUNTRIES_BEGIN:
        return {
          ...state,
          isFetchingCountries: false,
        }
      case types.GET_EVENT_TEAMS_COUNTRIES_ERROR:
        return {
          ...state,
          isFetchingCountries: false,
          eventCountries: []
        }
      case types.GET_EVENT_TEAMS_COUNTRIES_SUCCESS:
        return {
          ...state,
          isFetchingCountries: false,
          eventCountries: action.payload.countries
        }
      case types.RESET_ADD_COACH_ONLY_MODAL:
        return {
          ...state,
          addCoachesManuallyList: [],
          coachSelectedInAutocomplete: false,
          addCoachesImportList: [],
          selectedCoach: DEFAULT_SELECTED_COACH,
          isSettingSelectedCoach: false,
          isAddingCoachToList: false,
          isInvitingCoach: false,
          importedCoachesSuccessfullyInvited: [],
          isInvitingImportedCoaches: false,
          importErrorsList: [],
          importedCoachesNotInvited: []
        }
      case types.RESET_ADD_COACH_TEAM_MODAL:
        return {
          ...state,
          addCoachesManuallyList: [],
          coachSelectedInAutocomplete: false,
          addCoachesImportList: [],
          selectedCoach: DEFAULT_SELECTED_COACH,
          isSettingSelectedCoach: false,
          isAddingTeamToCoach: false,
          isAddTeamDisabled: true,
          isTeamSelected: false,
          selectedTeam: undefined,
          isAddingCoachToList: false,
          isCreatingNewTeam: false,
          isInvitingCoach: false,
          importedCoachesSuccessfullyInvited: [],
          isInvitingImportedCoaches: false,
          importErrorsList: [],
          importedCoachesNotInvited: []
        }
      case types.COACH_INVITE_BEGIN:
        return {
          ...state,
          isInvitingCoach: true
        }
      case types.COACH_INVITE_ERROR:
        return {
          ...state,
          isInvitingCoach: false
        }
      case types.COACH_INVITE_SUCCESS:
        return {
          ...state,
          isInvitingCoach: false,
          newCoachInvited: true
        }
      case types.TEAM_INVITE_BEGIN:
        return {
          ...state,
          isInvitingCoach: true
        }
      case types.TEAM_INVITE_ERROR:
        return {
          ...state,
          isInvitingCoach: false
        }
      case types.TEAM_INVITE_SUCCESS:
        return {
          ...state,
          isInvitingCoach: false,
        }
      case types.SET_COACHES_SUCCESSFULLY_INVITED:
        return {
          ...state,
          importedCoachesSuccessfullyInvited: action.payload.coaches,
          importErrorsList: action.payload.errors,
          importedCoachesNotInvited: action.payload.coachesNotInvited
        }
      case types.REINVITE_EVENT_COACH_USER_SUCCESS:
        return {
          ...state,
          eventTeamsIncludedResponse: action.payload.updatedIncludedList
        }
      case types.DELETE_COACH_EVENT_ASSIGNMENT_BEGIN:
        return {
          ...state,
          isRemovingCoach: true
        }
      case types.DELETE_COACH_EVENT_ASSIGNMENT_ERROR:
        return {
          ...state,
          isRemovingCoach: false
        }
      case types.DELETE_COACH_EVENT_ASSIGNMENT_SUCCESS:
        return {
          ...state,
          isRemovingCoach: false,
          eventTeamsIncludedResponse: action.payload.updatedIncludedList
        }
      case types.EDIT_TEAM_COACH_INFO_BEGIN:
        return {
          ...state,
          isUpdatingCoach: true
        }
      case types.EDIT_TEAM_COACH_INFO_ERROR:
      case types.EDIT_TEAM_COACH_INFO_SUCCESS:
        return {
          ...state,
          isUpdatingCoach: false
        }
      case types.UPDATE_TEAM_BEGIN:
        return {
          ...state,
          isUpdatingTeam: true
        }
      case types.UPDATE_TEAM_ERROR:
      case types.UPDATE_TEAM_SUCCESS:
        return {
          ...state,
          isUpdatingTeam: false
        }
      case types.DELETE_EVENT_TEAM_BEGIN:
        return {
          ...state,
          isDeletingTeam: true
        }
      case types.DELETE_EVENT_TEAM_ERROR:
        return {
          ...state,
          isDeletingTeam: false
        }
      case types.DELETE_EVENT_TEAM_SUCCESS:
        return {
          ...state,
          isDeletingTeam: false,
          newTeamCreated: true
        }
      case types.SET_ADDED_TEAM_LIST:
        return {
          ...state,
          addedTeamList: action.payload,
        }
      case types.SET_ADDED_TEAM_LIST_RESET:
        return {
          ...state,
          addedTeamList: [],
        }
    default:
      return state
  }
}
