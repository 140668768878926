import { COACH_ID } from 'shared/constants/eventRoleTypes';

export const formatCreateEventRoleBody = ({
  eventId, userId, roleTypeId = COACH_ID, teams
}) => {
  const relationships = {
    user: {
      data: {
        type: 'user',
        id: userId
      }
    },
    event: {
      data: {
        type: 'event',
        id: eventId
      }
    },
    role: {
      data: {
        type: 'user_event_role_type',
        id: roleTypeId
      }
    }
  };
  if (teams) {
    relationships.teams = {
      data: teams.map(t => ({ type: 'team', id: t.id }))
    };
  }
  return {
    data: {
      type: 'user_event_roles',
      relationships
    }
  };
};

export const formatSendInvitationsBody = (assignmentIds) => {
  return {
    data: {
      type: 'send_invitations',
      relationships: {
        user_event_role_assignments: {
          data: assignmentIds.map(id => ({
            type: 'user_event_role_assignment',
            id
          }))
        }
      }
    }
  };
};

export const formatVerifyInvitationBody = (verificationCode, email) => {
  return {
    data: {
      type: 'verify_invitation',
      attributes: {
        verification_code: verificationCode,
        email
      }
    }
  };
};

export const formatAssignRoleBody = (userId, password) => {
  return {
    data: {
      type: 'assign_role',
      attributes: {
        user_id: userId,
        password
      }
    }
  };
};

export const formatUserEventRoleTypesList = (included = []) => {
  const userEventRolesTypes = included.filter(i => i.type === 'user_event_role_type');
  const formattedList = userEventRolesTypes.map(r => r.id);
  return formattedList;
};

export const formatEventAssignments = (dataResponse = []) => {
  const eventAssignments = dataResponse.map(assignment => {
    return {
      eventRoleAssignmentId: assignment.id,
      roleId: assignment.relationships.role.data.id,
      eventId: assignment.relationships.event.data.id
    };
  });
  return eventAssignments;
};

export const formatVolunteers = (dataResponse = []) => {
  const volunteersList = [];
  dataResponse.forEach(volunteer => {
    let selectedVolunteer;

    const roleObj = {
      ...volunteer.relationships.role,
      ...volunteer.attributes
    };
    const eventRoleAssignmentObj = {
      userEventRoleAssignmentId: volunteer.id,
      roleTypeId: volunteer.relationships.role.data.id
    }

    const userAlreadyAddedIndex = volunteersList.findIndex(i => 
      i.relationships.user.data.id === volunteer.relationships.user.data.id);
    const isUserAlreadyAdded = userAlreadyAddedIndex > -1;

    if (isUserAlreadyAdded) {
      selectedVolunteer = volunteersList[userAlreadyAddedIndex];
      selectedVolunteer = {
        ...selectedVolunteer,
        roles: [...selectedVolunteer.roles, roleObj],
        eventRoleAssignments: [...selectedVolunteer.eventRoleAssignments, eventRoleAssignmentObj] 
      }
      volunteersList[userAlreadyAddedIndex] = selectedVolunteer;
    } else {
      selectedVolunteer = {
        ...volunteer,
        roles: [roleObj],
        eventRoleAssignments: [eventRoleAssignmentObj] 
      };
      volunteersList.push(selectedVolunteer);
    }
  })
  return volunteersList;
};

export const formatVolunteersNew = (dataResponse = [], included = []) => {
  const volunteersList = [];
  dataResponse.forEach(volunteer => {

    const roles = volunteer.relationships.roles.data;

    const eventRoleAssignments = roles.map((role, index)=>{
      let id = role.id;
      let roleId;
      included.forEach(i=>{
        if(i.id === id){
          roleId = i.attributes.role_id
        }
      });
      roles[index].id = roleId;
      return ({
        userEventRoleAssignmentId: id,
        roleTypeId: roleId,
      })
    })

    volunteersList.push({
      ...volunteer,
      roles,
      eventRoleAssignments,
    })
  })
  return volunteersList;
};
