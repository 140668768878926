import {
  DASHBOARD_REQUEST_TEAMS_BEGIN,
  DASHBOARD_REQUEST_TEAMS_SUCCESS,
  DASHBOARD_REQUEST_TEAMS_ERROR,
  DASHBOARD_REINVITE_COACH_BEGIN,
  DASHBOARD_REINVITE_COACH_ERROR,
  DASHBOARD_REINVITE_COACH_SUCCESS,
  DASHBOARD_DELETE_COACH_SUCCESS
} from '../types/teamsTypes';
import {
  EDIT_TEAM_COACH_INFO_SUCCESS
} from 'features/EventContainer/redux/types/teamsTypes';

const DEFAULT_TEAMS_STATE = {
  teams: [],
  count: 0,
  included: [],
  isFetchingTeams: false,
  isReinvitingCoach: false
};

export default (state = DEFAULT_TEAMS_STATE, action) => {
  const { type } = action;
  switch (type) {
    case DASHBOARD_REQUEST_TEAMS_BEGIN:
      return {
        ...state,
        ...action.payload
      }
    case DASHBOARD_REQUEST_TEAMS_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case DASHBOARD_REQUEST_TEAMS_ERROR:
      return {
        ...state,
        ...action.payload
      }
    case DASHBOARD_REINVITE_COACH_BEGIN:
      return {
        ...state,
        isReinvitingCoach: true,
      }
    case DASHBOARD_REINVITE_COACH_ERROR:
      return {
        ...state,
        isReinvitingCoach: false,
      }
    case DASHBOARD_REINVITE_COACH_SUCCESS:
      return {
        ...state,
        isReinvitingCoach: false,
        included: action.payload.updatedIncludedList
      }
    case DASHBOARD_DELETE_COACH_SUCCESS: {
      const { teamId, userId } = action.payload;
      return {
        ...state,
        teams: state.teams.map((team) => {
          if (team.id === teamId) {
            const { relationships: { coaches: { data } } } = team
            return {
              ...team,
              relationships: {
                ...team.relationships,
                coaches: {
                  data: data.filter((c) => c.id !== userId)
                }
              }
            }
          }
          return team;
        })
      }
    }
    case EDIT_TEAM_COACH_INFO_SUCCESS: {
      const { userId, values } = action.payload;
      return {
        ...state,
        included: state.included.map((item) => {
          if (item.type === 'user' && item.id === userId) {
            return {
              ...item,
              attributes: {
                ...item.attributes,
                ...values
              }
            };
          }
          return item;
        })
      }
    }
    default:
      return state;
  }
};
