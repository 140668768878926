import { notification } from 'antd';
import * as eventService from 'utils/services/events';
import * as userTypes from 'shared/constants/userTypes';
import * as userEventRoles from 'shared/constants/eventRoleTypes';
import * as types from '../types/eventsTypes';

const getEventsBegin = () => {
  return {
    type: types.FETCH_ADMIN_DASHBOARD_EVENTS_BEGIN
  }
};

const getEventsError = () => {
  return {
    type: types.FETCH_ADMIN_DASHBOARD_EVENTS_ERROR
  };
};

export const getEventsSuccess = ({ eventsList, count }) => {
  return {
    type: types.FETCH_ADMIN_DASHBOARD_EVENTS_SUCCESS,
    payload: {
      eventsList,
      count
    }
  };
};

export const fetchEvents = (page, searchText, sortBy = "") => {
  return async (dispatch, getState) => {
    const { auth: {
      userId,
      userType,
      partnerAdminRegionsPrograms
    } } = getState();
    await dispatch(getEventsBegin());
    try {
      const searchFilter = searchText && { title: `like:${searchText}` };
      if (userType === userTypes.PARTNER_ADMIN) {
        const partnerAdminEvents = await eventService.getEventsByPartnerRegions(partnerAdminRegionsPrograms, page, searchFilter, sortBy);
        await dispatch(getEventsSuccess(partnerAdminEvents));
      } else if (userType === userTypes.FIRST_ADMIN) {
        const dashboardEvents = await eventService.getEventsBy(searchFilter, page, sortBy);
        await dispatch(getEventsSuccess(dashboardEvents));
      } else {
        const adminDashboardUserRoles = userEventRoles.adminEventRoles.join(',');
        const userSearchFilter = searchText ? {
          'event_assignments.role.id': `in:${adminDashboardUserRoles}`,
          'event_assignments.user.id': `eq:${userId}`,
          title: `like:${searchText}`
        } : { 'event_assignments.role.id': `in:${adminDashboardUserRoles}`, 'event_assignments.user.id': `eq:${userId}` }
        const dashboardEvents = await eventService.getEventsBy(userSearchFilter, page, sortBy);
        await dispatch(getEventsSuccess(dashboardEvents));
      }
    } catch (err) {
      await dispatch(getEventsError([]));
      throw err;
    }
  };
};

const deleteEventBegin = () => {
  return {
    type: types.DELETE_EVENT_BEGIN
  };
};

const deleteEventError = () => {
  return {
    type: types.DELETE_EVENT_ERROR
  };
};

const deleteEventSuccess = () => {
  return {
    type: types.DELETE_EVENT_SUCCESS,
  };
};

export const deleteEvent = (eventId) => async (dispatch) => {
  try {
    dispatch(deleteEventBegin());
    await eventService.deleteEvent(eventId);
    dispatch(deleteEventSuccess());
  } catch (err) {
    dispatch(deleteEventError());
    notification.error({
      message: 'Error',
      description: 'Failed to delete event.',
    });
  }
};

export const clearEventDeleteStatus = () => {
  return {
    type: types.CLEAR_EVENT_DELETE_STATUS
  };
};
