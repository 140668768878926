import * as types from '../types/assignedAwards';
import * as teamService from 'utils/services/teams';
import * as awardService from 'utils/services/awards';
import * as rankingService from 'utils/services/rankings';
import { getJudgingObjectBy, updateJudgingObject, getJudgingObjectByEventId } from 'utils/services/judging';
import { PENDING, PUBLISHABLE, PUBLISHED } from 'shared/constants/publishStatus';
import _ from 'lodash';

// GET Event Award Teams
const getEventAwardTeamsBegin = () => {
  return {
    type: types.GET_EVENT_AWARD_TEAMS_BEGIN
  };
};

const getEventAwardTeamsError = () => {
  return {
    type: types.GET_EVENT_AWARD_TEAMS_FAIL
  };
};

const getEventAwardTeamsSuccess = (payload) => {
  return {
    type: types.GET_EVENT_AWARD_TEAMS_SUCCESS,
    payload
  };
};

export const fetchEventAwardTeams = (eventId) => async (dispatch) => {
  try {
    dispatch(getEventAwardTeamsBegin());
    const teams = await teamService.getAllTeamsByEventIdWithoutInclude(eventId);
    dispatch(getEventAwardTeamsSuccess(teams));
  } catch (e) {
    dispatch(getEventAwardTeamsError());
  }
};

// GET Event Awards List
const getEventAwardsBegin = (payload) => {
  return {
    type: types.GET_EVENT_AWARDS_BEGIN,
    payload
  };
};

const getEventAwardsError = () => {
  return {
    type: types.GET_EVENT_AWARDS_FAIL
  };
};

const getEventAwardsSuccess = (payload, loading) => {
  return {
    type: types.GET_EVENT_AWARDS_SUCCESS,
    payload,
    loading
  };
};
// GET Event Ranking List
const getEventRankingBegin = (payload) => {
  return {
    type: types.GET_EVENT_RANKING_BEGIN,
    payload
  };
};

const getEventRankingError = () => {
  return {
    type: types.GET_EVENT_RANKING_FAIL
  };
};

const getChampionRankComplete = (payload)=>{
  return {
    type: types.GET_CHAMPIONSHIP_RANKING_LOADING,
    payload
  }
}
const getGameScoreRankComplete = (payload)=>{
  return {
    type: types.GET_GAME_SCORE_RANKING_LOADING,
    payload
  }
}
const getJudgingRankComplete = (payload)=>{
  return {
    type: types.GET_JUDGING_RANKING_LOADING,
    payload
  }
}

const getEventRankingSuccess = (payload) => {
  return {
    type: types.GET_EVENT_RANKING_SUCCESS,
    payload
  };
};

const getEventRankingComplete = ()=>{
  return {
    type: types.GET_EVENT_RANKING_COMPLETE
  };
}
// GET Team Optional Nomination List
const getEventOptionalAwardBegin = (payload) => {
  return {
    type: types.GET_EVENT_OPTIONAL_AWARDS_BEGIN,
    payload
  };
};

const getEventOptionalAwardError = () => {
  return {
    type: types.GET_EVENT_OPTIONAL_AWARDS_FAIL
  };
};

const getEventOptionalAwardSuccess = (data, loading) => {
  return {
    type: types.GET_EVENT_OPTIONAL_AWARDS_SUCCESS,
    data: data,
    loading: loading
  };
};

const getEventOptionalAwardComplete = ()=>{
  return {
    type: types.GET_EVENT_OPTIONAL_AWARDS_COMPLETE
  };
}

const updateOptionalAwardData = (data, count)=>{
  // console.log("================================", data);
  return async (dispatch, getState)=>{
    const {assignedAwards: {rubricObjects}} = getState();
    const newObject = {...rubricObjects, ...data};
    const loading = Object.keys(newObject).length !== count
    dispatch(getEventOptionalAwardSuccess(newObject, loading))
  }
}

const updateOptionalAwardDataWithIndex = (data, index, count) => {
  // console.log("================================", data);
  return async (dispatch, getState) => {
    const { assignedAwards: { rubricObjects } } = getState();
    const newObject = { ...rubricObjects, ...data };
    const loading = (count - 1) !== index
    dispatch(getEventOptionalAwardSuccess(newObject, loading))
  }
}

export const getOptionalAwardsNomination = (judgingSessions) => async (dispatch, getState  )=>{
  const { eventOverview: { eventData: {id} } } = getState();
  try {
    dispatch(getEventOptionalAwardBegin());
    // const digital_rubrics = judgingSessions.map(j=>j.digital_rubric).filter(d=>!!d);
    // if(digital_rubrics.length===0) dispatch(updateOptionalAwardData([], 0))

    let rubrics = await getJudgingObjectByEventId(id)
    // const rubrics = _.groupBy(res, 'id');

    rubrics.forEach((rubric, index) => {
      const payload = { [rubric.id]: { ...rubric } };
      dispatch(updateOptionalAwardDataWithIndex(payload, index, rubrics.length))
    })  

    // digital_rubrics.forEach(digital_rubric => {
    //   getJudgingObjectBy(digital_rubric).then(response => {
    //     const {data} = response;
    //     const {id, attributes} = data||{};
    //     const payload = {[id]: {...attributes}};
    //     console.log(payload);
    //     dispatch(updateOptionalAwardData(payload, digital_rubrics.length))
    //   })
    // })
  }catch(e){
    dispatch(getEventOptionalAwardError());
  }
}

const updateRankingDataLoadingStatus = (index)=>{
  return (dispatch, getState) =>{
    const {assignedAwards: {isFetchingChampionShipRank, isFetchingGameScoreRank, isFetchingJudingRank}} = getState();
    if(index === 1){
      dispatch(getChampionRankComplete(false))
      if(!isFetchingGameScoreRank && !isFetchingJudingRank){
        dispatch(getEventRankingComplete())
      }
    }else if(index === 2){
      dispatch(getGameScoreRankComplete(false))
      if(!isFetchingChampionShipRank && !isFetchingJudingRank){
        dispatch(getEventRankingComplete())
      }
    }else if(index === 3){
      dispatch(getJudgingRankComplete(false))
      if(!isFetchingChampionShipRank && !isFetchingGameScoreRank){
        dispatch(getEventRankingComplete())
      }
    }
  }
}

const updateEventRankingData = (data, index)=>{
  return async (dispatch, getState)=>{
    const {assignedAwards: {ranking}} = getState();
    data.forEach(d=>{
      const {attributes, type, relationships} = d;
      const teamId = relationships.team.data.id;
      if(!(teamId in ranking)){
        ranking[teamId] = {}
      }
      ranking[teamId][type] = {...attributes};
    })
    dispatch(getEventRankingSuccess(ranking));
    dispatch(updateRankingDataLoadingStatus(index))
  }
}

export const fetchAllRanking = (eventId) => async (dispatch)=>{
  try {
    dispatch(getEventRankingBegin())
    rankingService.getChampionRank(eventId).then(data=>{
      dispatch(updateEventRankingData(data, 1));
    }).catch(e=>{
      dispatch(updateRankingDataLoadingStatus(1));
    })
    rankingService.getJudgingRank(eventId).then(data=>{
      dispatch(updateEventRankingData(data, 2));
    }).catch(e=>{
      dispatch(updateRankingDataLoadingStatus(2));
    })
    rankingService.getGameScoreRank(eventId).then(data=>{
      dispatch(updateEventRankingData(data, 3));
    }).catch(e=>{
      dispatch(updateRankingDataLoadingStatus(3));
    })
  }catch(e){
    dispatch(getEventRankingError())
  }
}

const updateAssignedAwardData = (awardsData, awardsCount) => {
  return async (dispatch, getState) =>{
    const {assignedAwards: {awards}} = getState();
    const awardId = ((awardsData||[])[0]||{}).id
    const filteredAward = awards.filter(a=>a.id != awardId);
    const newAwards = [...filteredAward, ...awardsData];
    const allAssignments = [];
    newAwards.forEach(({ assignments }) => {
      allAssignments.push(...assignments);
    });
    const isPublishable = allAssignments.length > 0 && !allAssignments.find((assign) => !assign.isPublishable);
    const isPublished = allAssignments.length > 0 && !allAssignments.find((assign) => !assign.isPublished);
    const status = isPublished ? PUBLISHED : isPublishable ? PUBLISHABLE : PENDING;
    const loading = newAwards.length !== awardsCount
    const status_loading = loading ? undefined : status;
    const payload = {awards: newAwards, status: status_loading}
    dispatch(getEventAwardsSuccess(payload, loading))
  }
}

export const fetchEventAwards = (eventId) => async (dispatch, getState) => {
  try {
    const { assignedAwards } = getState();
    if (assignedAwards.eventId && assignedAwards.eventId === eventId) {
      return;
    }
    dispatch(getEventAwardsSuccess({awards:[], loading:true}))
    dispatch(getEventAwardsBegin(eventId));
    const awards = await awardService.getAwardsOnlyByEventId(eventId);

    let awardAssignments = await awardService.getAwardAssignmentsByEventId(eventId)
    const { data, included } = awardAssignments

    if(awards.length > 0){
      for(const award of awards){
        const {assignments} = award;

        for (const assignment of assignments){
          const { id } = assignment;
          
          data.map((assignmentOfAward) => {
            if (assignmentOfAward.id == id){
              assignment.teamId = assignmentOfAward.relationships.team.data.id
            }
          })
        }
        dispatch(updateAssignedAwardData([award], awards.length))
     
        // for(const assignment of assignments){
        //   const {id} = assignment;
        //   const res = await awardService.getAwardAssignmentById(id);
        //   console.log("&&&&&&&&&&&", res);
        //   const {data} = res;
        //   const teamId = data.teamId
        //   assignment.teamId = teamId;
        //   newAssignments.push(assignment)
        // }
        // award.assignments = newAssignments
        // console.log("--------------", award);
        // dispatch(updateAssignedAwardData([award], awards.length))
      }
    }else{
      dispatch(updateAssignedAwardData([], 0))
    }

  } catch (e) {
    dispatch(getEventAwardsError());
  }
};

// Create New Event Award
const createEventAwardBegin = () => ({
  type: types.CREATE_EVENT_AWARD_BEGIN
});

const createEventAwardError = () => ({
  type: types.CREATE_EVENT_AWARD_FAIL
});

const createEventAwardSuccess = (payload) => ({
  type: types.CREATE_EVENT_AWARD_SUCCESS,
  payload
});

export const createEventAward = (award, eventId) => async (dispatch) => {
  try {
    dispatch(createEventAwardBegin());
    const data = await awardService.createAwardToEvent(award, eventId);
    dispatch(createEventAwardSuccess(data));
  } catch (e) {
    dispatch(createEventAwardError());
  }
};

export const addTeamToNewlyCreatedAward = ({award, eventId, teamId, userId}) => async (dispatch)=>{
  try {
    dispatch(createEventAwardBegin());
    const data = await awardService.createAwardToEvent(award, eventId);
    const {id} = data;
    const awardData = await awardService.addTeamToAward({eventId, userId, awardId: id, teamId})
    dispatch(createEventAwardSuccess(data));
    dispatch(addTeamToAwardSuccess(awardData));
  } catch (e) {
    dispatch(createEventAwardError());
  }
}

export const updateTeamAdvanceInAwardsTable = (digital_rubric, value)=> async (dispatch, getState)=>{
  try{
    dispatch(getEventOptionalAwardBegin());
    const {assignedAwards} = getState();
    const {rubricObjects} = assignedAwards;
    const newRubricObjects = _.cloneDeep(rubricObjects);
    const rubric = newRubricObjects[digital_rubric];
    if('advance' in rubric){
      rubric.advance = value;
    }
    if(digital_rubric) {
      const payload = {
        data: {
          type: 'judge',
          id: digital_rubric,
          attributes: {
            advance: value
          }
        }
      }
      await updateJudgingObject(payload, digital_rubric);
    }
    dispatch(getEventOptionalAwardSuccess(newRubricObjects, false))
  }catch(e){
    dispatch(getEventOptionalAwardError());
  }
}

// Nominate Team to selected Event Award
const addTeamToAwardBegin = () => ({
  type: types.ADD_TEAM_TO_AWARD_BEGIN
});

const addTeamToAwardError = () => ({
  type: types.ADD_TEAM_TO_AWARD_FAIL
});

const addTeamToAwardSuccess = (payload) => ({
  type: types.ADD_TEAM_TO_AWARD_SUCCESS,
  payload
});

export const addTeamToAward = (body) => async (dispatch) => {
  try {
    dispatch(addTeamToAwardBegin());
    const data = await awardService.addTeamToAward(body);
    dispatch(addTeamToAwardSuccess(data));
  } catch (e) {
    dispatch(addTeamToAwardError());
  }
};

// Remove Team from award nominated list
const removeTeamFromAwardBegin = () => ({
  type: types.REMOVE_TEAM_FROM_AWARD_BEGIN
});

const removeTeamFromAwardError = () => ({
  type: types.REMOVE_TEAM_FROM_AWARD_FAIL
});

const removeTeamFromAwardSuccess = (payload) => ({
  type: types.REMOVE_TEAM_FROM_AWARD_SUCCESS,
  payload
});

export const removeTeamFromAward = (assignmentId, awardId) => async (dispatch) => {
  try {
    dispatch(removeTeamFromAwardBegin());
    const id = await awardService.removeTeamFromAward(assignmentId, awardId);
    dispatch(removeTeamFromAwardSuccess({ id, awardId }));
  } catch (e) {
    dispatch(removeTeamFromAwardError());
  }
};

// Select Award Place for team
const updateTeamAwardPlaceBegin = () => ({
  type: types.UPDATE_TEAM_AWARD_PLACE_BEGIN
});

const updateTeamAwardPlaceError = () => ({
  type: types.UPDATE_TEAM_AWARD_PLACE_FAIL
});

const updateTeamAwardPlaceSuccess = (payload) => ({
  type: types.UPDATE_TEAM_AWARD_PLACE_SUCCESS,
  payload
});

export const updateTeamAwardPlace = (payload) => async (dispatch) => {
  try {
    dispatch(updateTeamAwardPlaceBegin());
    const res = await awardService.updateAwardAssignment(payload);
    dispatch(updateTeamAwardPlaceSuccess(res));
  } catch (e) {
    dispatch(updateTeamAwardPlaceError());
  }
};

// Submit Event Awards to Event Admin to review
const submitEventAwardsBegin = () => ({
  type: types.SUBMIT_EVENT_AWARDS_BEGIN
});

const submitEventAwardsError = () => ({
  type: types.SUBMIT_EVENT_AWARDS_FAIL
});

const submitEventAwardsSuccess = (payload) => ({
  type: types.SUBMIT_EVENT_AWARDS_SUCCESS,
  payload
});

export const submitEventAwards = (unsubmit) => async (dispatch, getState) => {
  try {
    const { assignedAwards: { eventId } } = getState();
    dispatch(submitEventAwardsBegin());
    await awardService.submitEventAwardsRequest(eventId, unsubmit);
    dispatch(submitEventAwardsSuccess(unsubmit));
  } catch (e) {
    dispatch(submitEventAwardsError());
  }
};