import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, useHistory, withRouter } from "react-router-dom";
import auth from '../../../Auth';
import {
  loginRequestBegin,
  setCanAccessAdminDashboard,
  setCanAccessVolunteerDashboard
} from 'features/Authentication/redux/authActions';
import { processAuthResult, handleAuthError } from 'utils/authorize';
import { handleRoleAccess } from 'utils/access';
import ScreenLoading from '../Spinner/ScreenLoading';

const RequireAuthentication = (WrappedComponent) => {

  const AuthenticatedPage = (props) => {
    const { authorized, loading, location, hasAcceptedTerms, expiresAt } = props;
    const { hash } = location;
    const history = useHistory();
    const [isAuthenticatingUser, setIsAuthenticatingUser] = useState(false);

    useEffect(() => {
      if (hash && /access_token|id_token|error/.test(hash)) {
        setIsAuthenticatingUser(true);
        const handleAuth = async () => {
          try {
            await props.loginRequestBegin();
            const authResult = await auth.GetInstance().handleAuthentication(hash);
            const roleData = await processAuthResult(authResult);
            if (!roleData) return;

            setIsAuthenticatingUser(false);
            handleRoleAccess({
              ...props,
              ...roleData
            });
          } catch (err) {
            handleAuthError(err.message || err.description);
          }
        }
        handleAuth();
      }
    }, []);

    useEffect(()=>{
      if(expiresAt*1000 < Date.now()){
        history.replace('/login');
      }
    },[expiresAt])

    if (loading || isAuthenticatingUser) {
      return <ScreenLoading loading subTitle='Loading User' />;
    }

    if (authorized) {
      return !hasAcceptedTerms 
       ? <Redirect {...props} to={{ pathname: '/terms-and-conditions' }} />
       : <WrappedComponent />;
    }
    return (
      <Redirect {...props} to={{ pathname: '/login' }} />
    );
  };

  AuthenticatedPage.propTypes = {
    loading: PropTypes.bool.isRequired,
    authorized: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    setVolunteerDashboardAccess: PropTypes.func.isRequired,
    setAdminDashboardAccess: PropTypes.func.isRequired
  };

  const mapStateToProps = (state) => {
    const { auth: { authorized, loading, userId, userInfo: { hasAcceptedTerms }, expiresAt } } = state;
    return {
      authorized,
      loading,
      userId,
      hasAcceptedTerms,
      expiresAt
    };
  };

  const mapDispatchToProps = {
    loginRequestBegin,
    setAdminDashboardAccess: setCanAccessAdminDashboard,
    setVolunteerDashboardAccess: setCanAccessVolunteerDashboard
  };

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticatedPage));
};

export default RequireAuthentication;
