import {
  COACH_ID,
  HEAD_REFEREE_ID,
  EVENT_ADMIN_ID,
  JUDGE_ADVISOR_ID,
  REFEREE_ID,
  JUDGE_ID
} from 'shared/constants/eventRoleTypes';

// event admin, head referee, judge advisor
const ADMIN_EVENT_ROLE_TYPE_IDS = [
  HEAD_REFEREE_ID,
  EVENT_ADMIN_ID,
  JUDGE_ADVISOR_ID,
];

// coach, judge, referee, event admin, head referee, judge advisor
const VOLUNTEER_EVENT_ROLE_TYPE_IDS = [
  COACH_ID,
  JUDGE_ID,
  REFEREE_ID,
  HEAD_REFEREE_ID,
  EVENT_ADMIN_ID,
  JUDGE_ADVISOR_ID,
];

export const handleRoleAccess = ({
  history,
  userType,
  hasAcceptedTerms,
  userEventRoles,
  setAdminDashboardAccess,
  setVolunteerDashboardAccess
}) => {
  const canAccessAdminDashboard = ADMIN_EVENT_ROLE_TYPE_IDS.some(i => userEventRoles.includes(i)) || userType !== 'User';
  const canAccessVolunteerDashboard = true;
  setAdminDashboardAccess(canAccessAdminDashboard);
  setVolunteerDashboardAccess(canAccessVolunteerDashboard);
  if (!hasAcceptedTerms) {
    history.replace('/terms-and-conditions');
  } else if (canAccessAdminDashboard && canAccessVolunteerDashboard) {
    history.replace('/volunteer');
  } else if (canAccessAdminDashboard) {
    history.replace('/admin');
  } else if (canAccessVolunteerDashboard) {
    history.replace('/volunteer');
  } else {
    history.replace('/');
  }
};
