const ip2Section = {
    id: 'IP2',
    // label: 'ipv1Label',
    title: 'ChallengeRubricsHeaderCoreValuesLabel',
    // description: 'identifyDescriptionLabel',
    fields: [
        {
            id: 'core_value_1',
            value: 'exploreReviewingSheetCoreValuesHowQuestionLabel',
            answear: 'exploreReviewingSheetCoreValuesHowAnswerLabel'
        },
        {
            id: 'core_value_2',
            value: 'exploreReviewingSheetCoreValuesWhatQuestionLabel',
            answear: 'exploreReviewingSheetCoreValuesWhatAnswerLabel'
        },
    ]
}

export default ip2Section;