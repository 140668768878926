import * as types from './roleTypes';

const DEFAULT_DASHBOARD_STATE = {
  selectedRoleName: 'Volunteer Dashboard',
  selectedRoleId: 'VolunteerDashboard',
  selectedRolePath: 'volunteer'
}

export default (state = DEFAULT_DASHBOARD_STATE, action) => {
  const {type} = action;
  switch (type) {
    case types.SET_SELECTED_DASHBOARD_ROLE:
      return {
        ...state,
        selectedRoleName: action.payload.selectedRoleName,
        selectedRoleId: action.payload.selectedRoleId,
        selectedRolePath: action.payload.selectedRolePath
      }
    case types.RESET_SELECTED_DASHBOARD_ROLE:
      return DEFAULT_DASHBOARD_STATE;
    default:
      return state;
  }



}