import * as types from '../types/publicScoring';
import * as scoreInputService from 'utils/services/scoreInput';

export const resetPublicScoringData = ()=>{
  return {
    type: types.RESET_PUBLIC_SCORING
  }
}

export const clearPublicScoringReset = ()=>{
  return {
    type: types.CLEAR_PUBLIC_SCORING_RESET
  }
}

const startCalculation = ()=>{
  return {
    type: types.SET_PUBLIC_SCORING,
    payload: {isScoreCalculating: true}
  }
}

const endCalculation = ()=>{
  return {
    type: types.SET_PUBLIC_SCORING,
    payload: {isScoreCalculating: false,  isScoreEdited: false}
  }
}

const calculatingScoreSuccess = (data)=>{
  return {
    type: types.SET_PUBLIC_SCORING,
    payload: {...data, isScoreCalculating: false, isScoreEdited: false}
  }
}

export const calculatePublicScoring = (payload) =>{
  return async dispatch=>{
    await dispatch(startCalculation());
    try{
      const formated_data = {
        "data": {
          "type": "generate_public_score",
          "attributes": {...payload}
        }
      }
      const data = await scoreInputService.getPublicScoringResult2023(formated_data);
      const overallScore = ((data||{}).attributes||{}).overall_score||0;
      await dispatch(calculatingScoreSuccess({totalScore: overallScore}))
    }catch(e){
      console.log('recalculating score eeee',e)
      await dispatch(endCalculation());
    }
  }
}

const setIsPublicScoresheetEdited = (isScoresheetEdited) => {
  return {
    type: types.SET_IS_PUBLIC_SCORESHEET_EDITED,
    payload: {
      isScoresheetEdited
    }
  }
}

export const handleSetIsPublicScoresheetEdited = (isEdited) => {
  return async dispatch => {
    await dispatch(setIsPublicScoresheetEdited(isEdited))
  }
}