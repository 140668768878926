import React, { useState } from 'react';
import {
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FLL_CHALLENGE_ID,
  FTC_ID
} from 'shared/constants/programIds';
import Select from 'shared/components/FormDropdown';

// const { Option } = AutoComplete;
const SelectOption = Select.Option;


const REGION_LABELS = {
  [FLL_CHALLENGE_ID]: 'FLL Challenge',
  [FLL_EXPLORE_ID]: 'FLL Explore',
  [FLL_DISCOVER_ID]: 'FLL Discover',
  [FTC_ID]: 'FTC',
};

const RegionAutoComplete = ({
  initialValue,
  regions = [], programId, regionId, disabled, setPartnerProgRegion, fetchRegionsBy
}) => {
  const [regionInput, setRegionInput] = useState('');

  const handleSearchRegions = (input) => {
    setRegionInput(input);
    fetchRegionsBy(programId, input);
  };



  if (disabled && !regions.length) {
    return null;
  }

  const handleMultipleChange = (selectedRegions) => {
  
    setPartnerProgRegion({ programId, regions: selectedRegions });
  }


  return (
    <Select
      showSearch
      onSearch={handleSearchRegions}
        // loading={isFetchingOrganizations}
      allowClear
      disabled={disabled}
      onChange={handleMultipleChange}
      filterOption={false}
      defaultValue={initialValue}
      mode='multiple'
    >
      {regions.map(({ id, name }) => (
        <SelectOption key={id} value={id}>{name}</SelectOption>
        ))}
    </Select>
  );
};

export default RegionAutoComplete;
