import React from 'react';
import PropTypes from 'prop-types';
import EventTile from 'shared/components/EventTile';

const EventsList = props => {
  const { events, loading, tileType } = props;

  return (
    <div>
      {!loading && (
        <div>
          {events.map(e => (
            <EventTile tileType={tileType} event={e} key={e.id} id={e.id} />
          ))}
        </div>
      )}
    </div>
  );
};

EventsList.propTypes = {
  events: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  tileType: PropTypes.string
};

EventsList.defaultProps = {
  loading: false,
  tileType: 'admin'
}

export default EventsList;
