import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { API } from 'utils/api';
import { DatePicker, Row, Col, notification, Form, Button } from 'antd';
import TextBox from 'shared/components/TextBox';
import formatSessionPayload from 'features/EventContainer/utils/formatSessionPayload';
import { morningMomentObject, formatRangePickerToTimezone, convertToDayjs } from 'utils/dateTimeUtils';
import moment from 'moment';
import { addEllipsisMiddle } from 'utils/truncateString';
import { createNewSessionUrl, generateMicrosoftTeamsLink } from 'features/EventContainer/redux/actions/scheduleActions'
import { connect } from 'react-redux';
import SessionUrlActions from '../SessionUrlActions';
import SessionUrlMessage from '../SessionUrlActions/SessionUrlMessage';
import EditLinkModal from '../SessionUrlActions/EditLinkModal';
import GenerateLinkModal from '../SessionUrlActions/GenerateLinkModal';

const { RangePicker } = DatePicker;

const FormItem = Form.Item;

const rowProps = {
  type: 'flex', gutter: [20, 20],
}

const sessionUrlFieldId = 'sessionUrl';

const SessionForm = props => {
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [generateLinkModalVisible, setGenerateLinkModalVisible] = useState(false);
    const { t } = useTranslation()
    const [form] = Form.useForm();

  const {
    createNewUrl,
    isCreatingNewSessionUrl,
    data,
    sessionId,
    closeCallback,
    updateSessionLink,
    isGeneratingLink,
    generateLink,
    refetchSession,
    time_zone,
    use_24_hour_format
  } = props;


  const {
    sessionDate,
    startTime,
    endTime,
    sessionNotes,
    sessionUrl
  } = data;

  const dayStartTime = convertToDayjs(startTime, time_zone);
  const dayEndTime = convertToDayjs(endTime, time_zone);
   
  useEffect(() => {
    moment.tz.setDefault(time_zone);
  }, [time_zone])

  const saveSession = async (payload) => {
    try {
      const { data } = await API.GetInstance().http.patch(`/api/sessions/${sessionId}`, payload);
      notification.success({
        message: 'Success',
        description: 'Session was successfully saved!',
      });
      closeCallback(data);
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error saving the session.',
      });
      throw err;
    }

  }

    const onSave = async (e) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }
    form.validateFields().then(async (values, err) => {
      const { date } = values;

      const startDate = Array.isArray(date) ? date[0] : undefined;
      const endDate = Array.isArray(date) ? date[1] : undefined;

      const formattedZuluStart = formatRangePickerToTimezone(startDate, time_zone);
      const formattedZuluEnd = formatRangePickerToTimezone(endDate, time_zone);

      const formValues = {
        startTime: formattedZuluStart,
        endTime: formattedZuluEnd,        
        // sessionUrl: values.sessionUrl,
        sessionNotes: values.sessionNotes
      }

      const formattedPayload = formatSessionPayload(sessionId, formValues);

      saveSession(formattedPayload);
    });
  }

  const closeEditModal = () => {
    setEditModalVisible(false)
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  }

  const saveUrlCb = (linkValue) => {
    updateSessionLink(linkValue);
    closeEditModal()
  }
  const handleSaveNewLink = async (linkValue) => {

    await createNewUrl(sessionId, linkValue, saveUrlCb);

  }

  const closeGenerateLinkModal = () => {
    setGenerateLinkModalVisible(false)
  }

  const regenerateCb = async () => {
    await refetchSession();
    closeGenerateLinkModal();
  }

  const handleRegenerateLink = () => {

    generateLink(sessionId, regenerateCb)
  }

  return (
    <div className="schedule-session-modal__form">
      <EditLinkModal
        onCancel={closeEditModal}
        visible={editModalVisible}
        title='Edit Link'
        initialUrlValue={sessionUrl}
        loading={isCreatingNewSessionUrl}
        onSubmit={handleSaveNewLink}
      />
      <GenerateLinkModal
        visible={generateLinkModalVisible}
        onCancel={closeGenerateLinkModal}
        onSubmit={handleRegenerateLink}
        loading={isGeneratingLink}
          />
          <Form hideRequiredMark onFinish={onSave} onFinishFailed={onFinishFailed} form={form}
              initialValues={{
                 // date: [startTime, endTime],
                  sessionNotes: sessionNotes
              }} >
        <Row {...rowProps}>
          <Col xs={24} id="edit-session-range-picker" style={{ position: 'relative' }}>
            <FormItem 
                name='date'
                label={<span>{t('dateLabel')}</span>}
                initialValue={[dayStartTime, dayEndTime]}>
                <RangePicker                    
                  placeholder={[t('startDateLabel'), t('endDateLabel')]}
                  showTime={{
                    format: use_24_hour_format ? 'HH:mm' : 'h:mm a', use12Hours: !use_24_hour_format                      
                  }}
                  defaultValue={ [morningMomentObject(), morningMomentObject()] }
                  className="schedule-session-modal__range-picker"
                  size="large"
                  format={`MM-DD-YYYY ${use_24_hour_format ? 'HH:mm' : 'h:mm a'}`}
                  getCalendarContainer={() => document.getElementById('edit-meeting-range-picker')}
                />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps} align='middle'>
          <Col xs={24} md={18}>
            <FormItem name={sessionUrlFieldId} label={<span>{t('sessionUrlLabel')}</span>}>
              <abbr title={sessionUrl} className='schedule-session-modal__external-link paragraph--large paragraph--charcoal'>{addEllipsisMiddle(sessionUrl, 50, 30, 10)}</abbr>
            </FormItem>
            <p style={{ display: 'none' }} id='session-url-id'>{sessionUrl}</p>
          </Col>
          <Col xs={24} md={6}>
            <SessionUrlActions onRegenerateClick={() => { setGenerateLinkModalVisible(true) }} onEditClick={() => { setEditModalVisible(true) }} copyElementId="session-url-id" />
          </Col>
        </Row>
        <SessionUrlMessage />
        <Row {...rowProps}>
          <Col xs={24}>
                <FormItem 
                    name='sessionNotes' 
                    label={<span>{t('notesLabel')}</span>}
                >
                <TextBox
                  size="large"
                  placeholder={t('enterAnyNotesLabel')}
                  style={{ height: '254px' }}
                />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Col>
            <FormItem>
              <Button htmlType="submit">{t('saveLabel')}</Button>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

SessionForm.propTypes = {
  form: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  sessionId: PropTypes.string.isRequired,

};

SessionForm.defaultProps = {

}

const mapDispatchToProps = dispatch => ({
  createNewUrl: (...arg) => dispatch(createNewSessionUrl(...arg)),
  generateLink: (...arg) => dispatch(generateMicrosoftTeamsLink(...arg))
})

const mapStateToProps = (state) => {
  const { auth: { userInfo: { use_24_hour_format } }, event: {
    eventDetails: { time_zone },
    eventSchedule,
  } } = state;
  const {
    isCreatingNewSessionUrl,
    isGeneratingMicrosoftSessionLink,
  } = eventSchedule;
  return {
    isGeneratingLink: isGeneratingMicrosoftSessionLink,
    isCreatingNewSessionUrl,
    time_zone,
      use_24_hour_format
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionForm);