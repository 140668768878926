export const CREATE_NEW_EVENT_BEGIN = 'CREATE_NEW_EVENT_BEGIN'
export const CREATE_NEW_EVENT_ERROR = 'CREATE_NEW_EVENT_ERROR'
export const CREATE_NEW_EVENT_SUCCESS = 'CREATE_NEW_EVENT_SUCCESS'


export const GET_NEW_EVENT_PROGRAM_TYPES_BEGIN = 'GET_NEW_EVENT_PROGRAM_TYPES_BEGIN';
export const GET_NEW_EVENT_PROGRAM_TYPES_ERROR = 'GET_NEW_EVENT_PROGRAM_TYPES_ERROR';
export const GET_NEW_EVENT_PROGRAM_TYPES_SUCCESS = 'GET_NEW_EVENT_PROGRAM_TYPES_SUCCESS';

export const GET_NEW_EVENT_COUNTRIES_BEGIN = 'GET_NEW_EVENT_COUNTRIES_BEGIN';
export const GET_NEW_EVENT_COUNTRIES_ERROR = 'GET_NEW_EVENT_COUNTRIES_ERROR';
export const GET_NEW_EVENT_COUNTRIES_SUCCESS = 'GET_NEW_EVENT_COUNTRIES_SUCCESS';

export const GET_NEW_EVENT_REGIONS_BEGIN = 'GET_NEW_EVENT_REGIONS_BEGIN';
export const GET_NEW_EVENT_REGIONS_ERROR = 'GET_NEW_EVENT_REGIONS_ERROR';
export const GET_NEW_EVENT_REGIONS_SUCCESS = 'GET_NEW_EVENT_REGIONS_SUCCESS';

export const RESET_ADD_EVENT_FORM = 'RESET_ADD_EVENT_FORM';


export const GET_PARTNER_ADMIN_DETAILS_BEGIN = 'GET_PARTNER_ADMIN_DETAILS_BEGIN';
export const GET_PARTNER_ADMIN_DETAILS_ERROR = 'GET_PARTNER_ADMIN_DETAILS_ERROR';
export const GET_PARTNER_ADMIN_DETAILS_SUCCESS = 'GET_PARTNER_ADMIN_DETAILS_SUCCESS';