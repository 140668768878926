import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { acceptTermsAndConditions } from 'features/RegistrationContainer/redux/registrationActions';
import OtherConditions from './TermsStepTwo';
import TermsSections from '../../TermsAndConditions/TermsSections';
import TermsWrapper from './TermsWrapper';

const TermsAndConditions = (props) => {
  const { userId, /*hasAcceptedTerms,*/ history, goToFinalStep } = props;
  const [acceptStep1, setAcceptStep] = useState(false);
  //const { t } = useTranslation();
  console.log('TermsAndConditionsSteps/Index.js rendered');
  // console.log({userId, hasAcceptedTerms})
  if (acceptStep1) {
    return (
      <OtherConditions 
        handleSubmit={() => {
          props.acceptTermsAndConditions(userId, history)
        }} 
      />
    );
  }

  const handleAcceptContinue =  () => {
    setAcceptStep(true)
    goToFinalStep()
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
   

  return (
    <TermsWrapper
      title={<div>Please read Terms & Conditions below and click <b>Accept and Continue</b> at the bottom of this page</div>}
      btnText='Accept and Continue'
      handleSubmit={handleAcceptContinue}
    >
      <TermsSections />
    </TermsWrapper>
  );
};

const mapStateToProps = (state) => {
  let userId = state?.auth?.initialRegistrationDetails?.userId;
  if (userId == null) userId = state?.auth?.userId;
  return {
    userId,
  };
};

const mapDispatchToProps = {
  acceptTermsAndConditions
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
