import React from 'react';
import { useTranslation } from 'react-i18next';
import InformationContainer from '../InformationContainer';
import Sections from './Sections';

const PrivacyPolicy = () => {

  const { t } = useTranslation();

  return (
    <InformationContainer
      title={t('privacyPolicyLabel')}
      btnText={t('acceptAndContinue')}
    >
      <Sections />
    </InformationContainer>
  );
};

export default PrivacyPolicy;
