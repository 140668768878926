import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import PlusCircleOutlined from '@ant-design/icons';
import './add-link.scss';

const LINK_COLORS = ['white', 'primaryBlue'];

const AddLink = (props) => {
  const { color, addClick, customText, ..._props } = props;
  const className = `add-link add-link--${color}`
  return (
    <Button
      type='link'
      className={className}
      onClick={addClick}
      {..._props}
    >
      <div className='add-link-inner'>
        <PlusCircleOutlined className='add-link__icon' />
        {customText || 'Add'}
      </div>
    </Button>
  );
};

AddLink.propTypes = {
  addClick: PropTypes.func,
  color: PropTypes.oneOf(LINK_COLORS),
  customText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
};

AddLink.defaultProps = {
  color: 'white',
  customText: false,
  addClick: () => { }
};

export default AddLink;
