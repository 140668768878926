export const FILE_EXTENSIONS_MAP = {
  'image': ['jpg', 'jpeg', 'png', 'svg', 'bmp', 'gif', 'tiff', 'ico'],
  'video': ['webm', 'mp4', 'avi', 'mp3', 'mpg', 'ogg', 'mov'],
  'document': ['pdf', 'doc', 'docx'],
}


export const getFileExtension = (fileName) => {
  const position = fileName.lastIndexOf('.');
  if (fileName === '' || position < 1) {
    return '';
  }
  return fileName.slice(position + 1).toLowerCase();
}


export const trimFileName = (fileName) => {
  if (!fileName || !fileName.includes('.')) {
    return fileName;
  }
  const name = fileName.split('.').slice(0, -1).join('.');
  return name;
}


export const getFileIconType = (extension) => {
  const ext = extension.indexOf('.') === 0 ? extension.slice(1) : extension;

  let iconName = 'document';
  Object.entries(FILE_EXTENSIONS_MAP).forEach(([key, value]) => {
    if (value.includes(ext)) iconName = key;
  });

  return iconName;
}