import React from 'react';
import PropTypes from 'prop-types';
import {Input as AntInput} from 'antd';
import './input-password.scss';




const INPUT_SIZES = ['default', 'large', 'small']

const InputPassword = props => {
  const {size} = props;
  const className = `input-password input-password--${size}`
  return (
    <AntInput.Password className={className} {...props} />
  );
};

InputPassword.propTypes = {
  size: PropTypes.oneOf(INPUT_SIZES),
  placeholder: PropTypes.string
};

InputPassword.defaultProps = {
  size: 'large',
  placeholder: undefined,
}

export default InputPassword;