import React from 'react';
import PropTypes from 'prop-types';

const MinusCircle = ({ fill }) => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <defs>
        <path d="M10,0 C4.4765625,0 0,4.4765625 0,10 C0,15.5234375 4.4765625,20 10,20 C15.5234375,20 20,15.5234375 20,10 C20,4.4765625 15.5234375,0 10,0 Z M16.0761719,16.0761719 C15.2871094,16.8652344 14.3671875,17.4863281 13.34375,17.9179688 C12.2851562,18.3671875 11.1601562,18.59375 10,18.59375 C8.83984375,18.59375 7.71484375,18.3671875 6.65625,17.9199219 C5.6328125,17.4863281 4.71484375,16.8671875 3.92382812,16.078125 C3.13476562,15.2890625 2.51367188,14.3691406 2.08203125,13.3457031 C1.6328125,12.2851562 1.40625,11.1601562 1.40625,10 C1.40625,8.83984375 1.6328125,7.71484375 2.08007812,6.65625 C2.51367188,5.6328125 3.1328125,4.71484375 3.921875,3.92382812 C4.7109375,3.13476562 5.63085938,2.51367188 6.65429688,2.08203125 C7.71484375,1.6328125 8.83984375,1.40625 10,1.40625 C11.1601562,1.40625 12.2851562,1.6328125 13.34375,2.08007812 C14.3671875,2.51367188 15.2851562,3.1328125 16.0761719,3.921875 C16.8652344,4.7109375 17.4863281,5.63085938 17.9179688,6.65429688 C18.3671875,7.71484375 18.59375,8.83984375 18.59375,10 C18.59375,11.1601562 18.3671875,12.2851562 17.9199219,13.34375 C17.4863281,14.3671875 16.8671875,15.2871094 16.0761719,16.0761719 Z M15.5566406,10.703125 L4.44335938,10.703125 C4.0546875,10.703125 3.74023438,10.3886719 3.74023438,10 L3.74023438,10 C3.74023438,9.61132812 4.0546875,9.296875 4.44335938,9.296875 L15.5566406,9.296875 C15.9453125,9.296875 16.2597656,9.61132812 16.2597656,10 L16.2597656,10 C16.2597656,10.3886719 15.9453125,10.703125 15.5566406,10.703125 Z" id="path-1"></path>
      </defs>
      <g id="Event" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Dashboard---Edit-Event---Event-Management---Single-Event---Sponsors---Edit---PDP-Admin" transform="translate(-624.000000, -272.000000)">
              <g id="Group-25" transform="translate(139.000000, 10.000000)">
                  <g id="Group-4" transform="translate(485.000000, 261.000000)">
                      <g id="Icon/Plus-circle" transform="translate(0.000000, 1.000000)">
                        <mask id="mask-2" fill="white">
                          <use xlinkHref="#path-1"></use>
                        </mask>
                        <use id="Mask" fill={fill} xlinkHref="#path-1"></use>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
  );
};

MinusCircle.propTypes = {
  fill: PropTypes.string
};

MinusCircle.defaultProps = {
  fill: '#0066B3'
}

export default MinusCircle;
