import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import UserInviteeTile from 'shared/components/UserInviteeTile';
import BtnPrimary from 'shared/components/Button/BtnPrimary';

import './InviteesForm.scss';

const InviteesForm = props => {
  const { t } = useTranslation()
  const { deleteUser, handleInviteAllUsers, users, userType } = props;
  const hasUsers = users.length;
  const labelClassName = `paragraph--small paragraph--400 paragraph--${hasUsers ? 'darkGray' : 'silver'}`;
  const wrapperClassName = `add-users-list__data add-users-list__data--${hasUsers ? 'has' : 'no'}-data`;

  return (
    <div className='add-users-list'>
      <p className={labelClassName}>{t('inviteTheseUserTypesLabel', { '0': userType })}</p>
      <div className={wrapperClassName}>
        {users.map(user => (
          <UserInviteeTile
            hasDeleteBtn
            removeUser={deleteUser}
            id={user.id}
            key={`${user.id}-${user.email}`}
            fullName={`${user.firstName} ${user.lastName}`}
            email={user.email}
          />
        ))}
      </div>
      <div className='add-users-list__btn'>
        <BtnPrimary
          disabled={!hasUsers} 
          onClick={handleInviteAllUsers}
        >
          {t('inviteAllLabel')}
        </BtnPrimary>
      </div>
    </div>
  );
};

InviteesForm.propTypes = {
  users: PropTypes.array,
  deleteUser: PropTypes.func,
  userType: PropTypes.string,
  handleInviteAllUsers: PropTypes.func
};

InviteesForm.defaultProps = {
  users: [],
  deleteUser: () => { },
  handleInviteAllUsers: () => { },
  userType: 'people'
}

export default InviteesForm;
