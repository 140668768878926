import * as volunteerDashboardTypes from 'features/VolunteerDashboard/redux/types/volunteerDashboardTypes';
import { FLL_CHALLENGE_ID } from 'shared/constants/programIds';
import * as types from '../types/eventOverviewTypes';
import * as eventRoleTypes from '../types/userEventOverviewRoleTypes';

/*
  mock coach teams list
  [  
        {
    "id": "testing1",
    "shortName": "",
    "teamNumberName": "HEY - Commerce Cougars Team #7779",
    "teamNumber": "HEY",
    "teamName": "Commerce Cougars Team #7779",
    "teamLocation": "Rhode Island, USA"
  },
  {
    "id": "testing2",
    "shortName": "",
    "teamNumberName": "foo bar - Commerce Cougars Team #7779",
    "teamNumber": "foo",
    "teamName": "Commerce Cougars Team #7779",
    "teamLocation": "Massachusetts, USA"
  },
  {
    "id": "c172590e-ff05-42ef-9a91-bbee47571267",
    "shortName": "",
    "teamNumberName": "7779 - Commerce Cougars Team #7779",
    "teamNumber": "7779",
    "teamName": "Commerce Cougars Team #7779",
    "teamLocation": "Michigan, USA"
  }


  ]


*/
const DEFAULT_COACH_DETAILS = {
  isCoach: false,
  teamName: null,
  teamNumber: null,
  teamId: null,
  sessionId: null,
  startIsoString: null,
  sessionStatusId: null,
  sessionStatusName: null,
  sessionTypeName: null,
  teamLocation: null
};

const DEFAULT_SELECTED_TEAM = {
  id: null,
  teamNumberName: '',
  teamNumber: '',
  teamName: '',
  teamLocation: ''
}

const DEFAULT_EVENT_OVERVIEW_STATE = {
  eventScheduleData: [],
  eventScheduleIncluded: [],
  eventData: {},
  eventIncluded: [],
  isFetchingEventSchedule: true,
  errorFetchingEventSchedule: false,
  eventScheduleId: null,
  userEventRoleIds: [],
  isFetchingUserEventRoles: false,
  eventProgramId: null,
  is_FLLC_event: null,
  coachDetails: DEFAULT_COACH_DETAILS,
  eventScheduleList: [],
  isLoadingSchedule: false,
  scheduleError: false,
  eventCoachTeamsList: [],
  selectedTeam: DEFAULT_SELECTED_TEAM,
  isLoadingEventDetails: true,
  sessionTeams: [],
  isFileUploading: false,
  isAdminRole: false,
  time_zone: null,
};

export default (state = DEFAULT_EVENT_OVERVIEW_STATE, action) => {
  const { type } = action;
  switch (type) {
    case volunteerDashboardTypes.FETCH_VOLUNTEER_DASHBOARD_EVENTS_BEGIN: {
      return DEFAULT_EVENT_OVERVIEW_STATE;
    }
    case types.GET_EVENT_OVERVIEW_SCHEDULE_BEGIN:
      return {
        ...state,
        isFetchingEventSchedule: true,
      }
    case types.GET_EVENT_OVERVIEW_SCHEDULE_ERROR:
      return {
        ...state,
        isFetchingEventSchedule: false,
        errorFetchingEventSchedule: true
      }
    case types.GET_EVENT_OVERVIEW_SCHEDULE_SUCCESS:
      return {
        ...state,
        isFetchingEventSchedule: false,
        errorFetchingEventSchedule: false,
        eventScheduleData: action.payload.eventScheduleData,
        eventScheduleIncluded: action.payload.eventScheduleIncluded,
      }
    case types.SET_EVENT_OVERVIEW_DETAILS:
      return {
        ...state,
        eventData: action.payload.eventData,
        eventIncluded: action.payload.eventIncluded,
      }
    case types.SET_EVENT_OVERVIEW_SCHEDULE_ID:
      return {
        ...state,
        eventScheduleId: action.payload.eventScheduleId
      }
    case eventRoleTypes.GET_USER_EVENT_OVERVIEW_ROLES_BEGIN:
      return {
        ...state,
        isFetchingUserEventRoles: true
      }
    case eventRoleTypes.GET_USER_EVENT_OVERVIEW_ROLES_ERROR:
      return {
        ...state,
        isFetchingUserEventRoles: false,
        userEventRoleIds: []
      }
    case eventRoleTypes.GET_USER_EVENT_OVERVIEW_ROLES_SUCCESS:
      return {
        ...state,
        isFetchingUserEventRoles: false,
        userEventRoleIds: action.payload.userEventRoleIds
      }
    case types.SET_EVENT_OVERVIEW_PROGRAM_ID:
      return {
        ...state,
        eventProgramId: action.payload.eventProgramId,
        is_FLLC_event: action.payload.eventProgramId == FLL_CHALLENGE_ID,
      }
    case types.SET_EVENT_OVERVIEW_COACH_DETAILS:
      return {
        ...state,
        coachDetails: {
          ...state.coachDetails,
          ...action.payload
        }
      }
    case types.SET_EVENT_OVERVIEW_SCHEDULE_DETAILS_BEGIN:
      return {
        ...state,
        isLoadingSchedule: true
      }
    case types.SET_EVENT_OVERVIEW_SCHEDULE_DETAILS_ERROR:
      return {
        ...state,
        isLoadingSchedule: false,
        eventScheduleList: []
      }
    case types.SET_EVENT_OVERVIEW_SCHEDULE_DETAILS_SUCCESS:
      return {
        ...state,
        isLoadingSchedule: false,
        eventScheduleList: action.payload.data,
        sessionTeams: action.payload.sessionTeams
      }
    case types.SET_VOLUNTEER_EVENT_SCHEDULE_ERROR:
      return {
        ...state,
        scheduleError: true,
        isFetchingEventSchedule: false,
      }
    case types.SET_VOLUNTEER_EVENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        scheduleError: false
      }
    case types.SET_EVENT_OVERVIEW_COACH_TEAMS_LIST:
      return {
        ...state,
        eventCoachTeamsList: action.payload.coachTeams
      }
    case types.SET_EVENT_OVERVIEW_SELECTED_TEAM:
      return {
        ...state,
        selectedTeam: {
          ...DEFAULT_SELECTED_TEAM,
          ...action.payload.selectedTeam
        }
      }
    case types.GET_EVENT_OVERVIEW_DETAILS_BEGIN:
      return {
        ...state,
        isLoadingEventDetails: true
      }
    case types.GET_EVENT_OVERVIEW_DETAILS_ERROR:
      return {
        ...state,
        isLoadingEventDetails: false
      }
    case types.GET_EVENT_OVERVIEW_DETAILS_SUCCESS:
      return {
        ...state,
        isLoadingEventDetails: false
      }
    case types.CHANGE_STATUS_OF_UPLOADING:
      return {
        ...state,
        isFileUploading: action.payload
      }
    case types.SET_IS_EVENT_ADMIN_ROLE:
      return {
        ...state,
        isAdminRole: action.payload
      }
    case types.SET_EVENT_OVERVIEW_TIME_ZONE:
      return {
        ...state,
        time_zone: action.payload
      }
    default:
      return state;
  }
};
