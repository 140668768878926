import {momentize, parseDateString, parseTime} from 'utils/dateTimeUtils';

export default class ScheduleTimestamp {
  constructor(responseData, responseIncluded = []) {
    this.id = responseData.id;
    this.targetDateTime = responseData.attributes.target_date_time;
    this.timestampTypeId = responseData.relationships.time_stamp_type.data.id;
    this.included = responseIncluded;
  }


  getTimestampDescription = () => {
    const timestampType = this.included.find(i => i.id === this.timestampTypeId);
    if (!timestampType) {
      return undefined;
    }
    const {description} = timestampType.attributes;
    return description;
  }

  parseDate = (time_zone) => {
    if (!this.targetDateTime) {
      return undefined;
    }
    const targetTimeString = parseDateString(this.targetDateTime,time_zone);
    return targetTimeString;
  }

  parseTime = (time_zone) => {
    if (!this.targetDateTime) {
      return undefined;
    }
    const targetTimeString = parseTime(this.targetDateTime,time_zone);
    return targetTimeString;
  }

  getMomentDate = (time_zone) => {
    if (!this.targetDateTime) {
      return undefined;
    }
    const momentDate = momentize(this.targetDateTime, time_zone);
    return momentDate
  }

  getMomentTime = (time_zone) => {
    if (!this.targetDateTime) {
      return undefined;
    }
    const momentDate = momentize(this.targetDateTime, time_zone);
    return momentDate
  }
}