import * as matchService from 'utils/services/matches';
import Match from 'models/Match';
import { getSessionById } from 'utils/services/sessions';
import { getTeamDetails } from 'utils/services/teams';
import { formatTeamModelRes } from 'utils/services/teams/model';
import { formatMatchResponse } from 'utils/services/matches/model';
import Team from 'models/Team';
import * as types from '../../types/matchScoresheet';
import { getMatchScoreInput } from './scoreInput';
import { UPDATE_ASSIGNED_MATCHES_ON_SUBMIT } from '../../types/assignedMatches';

const getMatchDetailsBegin = () => {
  return {
    type: types.GET_MATCH_DETAILS_BEGIN
  }
}

const getMatchDetailsError = () => {
  return {
    type: types.GET_MATCH_DETAILS_ERROR
  }
}

const getMatchDetailsSuccess = (matchDetails) => {
  return {
    type: types.GET_MATCH_DETAILS_SUCCESS,
    payload: {
      matchDetails
    }
  }
}

const setMatchTeamData = (teamDetails) => {
  return {
    type: types.SET_MATCH_TEAM_DATA,
    payload: {
      teamDetails
    }
  }
}

const resetMatchData = () => {
  return {
    type: types.RESET_MATCH_DETAILS,
    payload: {}
  }
}

const getMatchDetails = (matchId) => {
  return async dispatch => {
    await dispatch(getMatchDetailsBegin())
    try {
      const { data, included } = await matchService.getMatchById(matchId);
      const matchDetails = formatMatchResponse(new Match(data, included));

      const { sessionId, scoreId } = matchDetails;
      if (sessionId) {
        const res = await getSessionById(sessionId);
        const includedList = res.included;
        const sessionTeam = includedList.find(i => i.type === 'team');
        const teamId = sessionTeam ? sessionTeam.id : undefined;

        const teamRes = await getTeamDetails(teamId);

        const teamData = formatTeamModelRes(new Team(teamRes.data, teamRes.included));
        await dispatch(setMatchTeamData(teamData))
      }
      if (!sessionId) {
        const defaultTeam = {
          id: null,
          regionName: null,
          countryName: null,
          teamNumber: null,
          teamName: null
        }
        await dispatch(setMatchTeamData(defaultTeam))
      }

      if (scoreId) {
        await dispatch(getMatchScoreInput(scoreId))
      }

      await dispatch(getMatchDetailsSuccess(matchDetails))
    } catch (err) {
      dispatch(getMatchDetailsError())
      throw err;
    }
  }
}

const updateAssignedMatches = data => {
  return ({
    type: UPDATE_ASSIGNED_MATCHES_ON_SUBMIT,
    payload: data
  })
}

export const upDateMatchDetails = (matchId) => {
  return async (dispatch) => {
    try {
      const { data, included } = await matchService.getMatchById(matchId);
      const matchDetails = formatMatchResponse(new Match(data, included));
      await dispatch(getMatchDetailsSuccess(matchDetails));
    } catch (err) {
      dispatch(getMatchDetailsError())
      throw err;
    }
  }
}

export const resetMatchDetails = () => {
  return async dispatch => {
    try {
      await dispatch(resetMatchData())
    } catch (err) {
      console.log("Match detail reset error", err);
    }
  }
}

export default getMatchDetails