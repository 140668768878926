import React, { useState } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import PanelModal from '../PanelModal';
import JudgesList from './PanelJudgesList';
import TeamsList from './PanelTeamsList';
import '../../../../../../features/PanelManagement/styles/JudgingPanelCard.scss';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'shared/components/ConfirmModal';
import { connect } from 'react-redux';
import * as panelService from 'utils/services/panels';
import { notification } from 'antd';
import fetchJudgingPanels from 'features/EventContainer/redux/actions/judgingActions/getJudgingPanels';


const PanelCard = (props) => {

  const { hasError, id, panel, eventSessions, eventJudges } = props;
  const [visible, setVisible] = useState(false);
  const [deleteConfirmModalVisible, setDeleteConfirmModalVisiable] = useState(false);
  const { attributes, getJudgingPanels } = props
  const { title } = attributes

  const { t } = useTranslation();

  let className = '';
  if (hasError) {
    className = 'judging-panel-card--incomplete'
  }
  if (!hasError) {
    className = 'judging-panel-card--complete'
  }

  const openModal = () => {
    setVisible(true);
  }

  const closeModal = () => {
    setVisible(false);
  }

  const deletePanel = async (panelId) => {
    await panelService.deletePanelByID(panelId)
    setDeleteConfirmModalVisiable(false)
    notification.success({
      message: 'Success',
      description: 'The room was successfully deleted.',
    });
    getJudgingPanels();
  }


  return (
    <>
      <div className='judging-panel-card-container'>
        <PanelModal panelId={panel.panelId} isEdit visible={visible} onCancel={closeModal} />
        <div className='judging-panel-card__btn-container'>
          <Button type='link' className='judging-panel-card__btn link' onClick={openModal}>{t('editLabel')}</Button>
        </div>
        <div className={`judging-panel-card ${className}`}>
          <JudgesList panelTitle={panel.panelName} panelJudges={panel.panelJudges} hasError={panel.panelJudges.length < 1} />
          <TeamsList panelSessions={panel.panelSessions} />
          <div onClick={() => { setDeleteConfirmModalVisiable(true) }} className='m-t-10 danger cursor'>{t('DeleteTeamDeleteLabel')}</div>
        </div>
      </div>
      <ConfirmationModal
        visible={deleteConfirmModalVisible}
        onCancel={() => {
          setDeleteConfirmModalVisiable(false);
        }}
        onSubmit={() => {
          deletePanel(id)
        }}
        title={(<div>
          <div className='team-name m-b-20'>{title}</div>
          <div className='title'>{`${t('DeleteTeamDeleteLabel')} ${panel.panelName}?`}</div>
        </div>)}
        // title={`${t('DeleteTeamDeleteLabel')} ${panel.panelName}?`}
        description='This can not be undone.'
      />
    </>
  );
};

PanelCard.propTypes = {
  hasError: PropTypes.bool,
  id: PropTypes.string,
  panel: PropTypes.object.isRequired,
  eventSessions: PropTypes.array,
  eventJudges: PropTypes.array,
  event: PropTypes.object,
  getJudgingPanels: PropTypes.func.isRequired,
}

PanelCard.defaultProps = {
  hasError: false,
  id: '',
  eventSessions: [],
  eventJudges: [],
  event: null
}


const mapDispatchToProps = {
  getJudgingPanels: fetchJudgingPanels
}

const mapStateToProps = (state) => {
  const { event: { eventDetails: { attributes } } } = state;

  return {
    attributes,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelCard);
// export default PanelCard;