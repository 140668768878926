export const GET_EVENT_SPONSORS_BEGIN = 'GET_EVENT_SPONSORS_BEGIN';
export const GET_EVENT_SPONSORS_SUCCESS = 'GET_EVENT_SPONSORS_SUCCESS';
export const GET_EVENT_SPONSORS_FAIL = 'GET_EVENT_SPONSORS_FAIL';

export const SAVE_EVENT_SPONSOR_BEGIN = 'SAVE_EVENT_SPONSOR_BEGIN';
export const SAVE_EVENT_SPONSOR_SUCCESS = 'SAVE_EVENT_SPONSOR_SUCCESS';
export const SAVE_EVENT_SPONSOR_FAIL = 'SAVE_EVENT_SPONSOR_FAIL';

export const UPLOAD_SPONSOR_LOGO_BEGIN = 'UPLOAD_SPONSOR_LOGO_BEGIN';
export const UPLOAD_SPONSOR_LOGO_SUCCESS = 'UPLOAD_SPONSOR_LOGO_SUCCESS';
export const UPLOAD_SPONSOR_LOGO_FAIL = 'UPLOAD_SPONSOR_LOGO_FAIL';
export const UPLOAD_SPONSOR_LOGO_RETRY = 'UPLOAD_SPONSOR_LOGO_RETRY';

export const DELETE_EVENT_SPONSOR_BEGIN = 'DELETE_EVENT_SPONSOR_BEGIN';
export const DELETE_EVENT_SPONSOR_SUCCESS = 'DELETE_EVENT_SPONSOR_SUCCESS';
export const DELETE_EVENT_SPONSOR_FAIL = 'DELETE_EVENT_SPONSOR_FAIL';

export const REMOVE_SPONSOR_LOGO_BEGIN = 'REMOVE_SPONSOR_LOGO_BEGIN';
export const REMOVE_SPONSOR_LOGO_SUCCESS = 'REMOVE_SPONSOR_LOGO_SUCCESS';
export const REMOVE_SPONSOR_LOGO_FAIL = 'REMOVE_SPONSOR_LOGO_FAIL';

export const RESET_SPONSOR_CREATED_STATE = 'RESET_SPONSOR_CREATED_STATE';
