import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'shared/components/Tooltip';
import EventModal from 'shared/components/EventModal';
import AddEventForm from 'features/AddEventModal/components/AddEventForm';
import {CheckCircleOutlined} from '@ant-design/icons';

import './styles/AddEventModal.scss';
import { IN_PERSON_LOCATION, REMOTE_LOCATION } from 'shared/constants/eventLocationTypes';
import { OFFICIAL, UNOFFICIAL } from 'shared/constants/eventOfficialTypes';

const getSelectedClass = (eventType, comparisonValue) => !eventType ? '' : eventType === comparisonValue ? 'selected' : 'not-selected';
const isSelected = className => className === 'selected';

const AddEventModal = (props) => {
  const { t } = useTranslation()
  const { onCancel, visible } = props;
  const [eventLocationType, setEventLocationType] = useState(null);
  const [eventOfficialType, setEventOfficialType] = useState(null);

  const info = (
     <Tooltip text={t('addEventTooltip')} />
  );

  const remoteClass = getSelectedClass(eventLocationType, REMOTE_LOCATION);
  const inPersonClass = getSelectedClass(eventLocationType, IN_PERSON_LOCATION);
  const officialClass = getSelectedClass(eventOfficialType, OFFICIAL);
  const unofficialClass = getSelectedClass(eventOfficialType, UNOFFICIAL);
  const remoteSelected = isSelected(remoteClass);
  const inPersonSelected = isSelected(inPersonClass);
  const officialSelected = isSelected(officialClass);
  const unofficialSelected = isSelected(unofficialClass);

  return (
    <EventModal
      visible={visible}
      onCancel={onCancel}
      renderHeader={() => (<span>{t('newEventLabel')}</span>)}
    >
      <div className='add-event-modal'>
        <div className='add-event-modal__heading'>
          <h1>{t('addEventLabel')}</h1>
          {info}
        </div>
        <div className="event-type-wrapper">
          <div className="event-type-title-wrapper">
            <div className='event-type-title-header'>{t('locationTypeHeader')}</div>
            <div className='event-type-title-hint'>{t('EventTypeWarningLabel')}</div>
          </div>
          <div className="event-type-button-wrapper">
            <div>
              <div className='event-option-container'>
                <a className={`event-type-in-person-button ${inPersonClass}`} onClick={() => setEventLocationType(IN_PERSON_LOCATION)}>
                  <div className="button-title">
                    {inPersonSelected ? <div className="title-icon"><CheckCircleOutlined style={{fontSize:'22px'}}/></div> : null}
                    {t('InPersonLabel')}
                  </div>
                </a>
                <a className={`event-type-remote-button ${remoteClass}`} onClick={() => setEventLocationType(REMOTE_LOCATION)}>
                  <div className="button-title">
                    {remoteSelected ? <div className="title-icon"><CheckCircleOutlined style={{fontSize:'22px'}}/></div> : null}
                    {t('RemoteLabel')}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="event-type-wrapper">
          <div className="event-type-title-wrapper">
            <div className='event-type-title-header'>{t('EventTypeLabel')}</div>
            <div className='event-type-title-hint'>{t('EventTypeWarningLabel')}</div>
          </div>
          <div className="event-type-button-wrapper">
            <div>
              <div className='event-option-container'>
                <a className={`event-type-official-button ${officialClass}`} onClick={() => setEventOfficialType(OFFICIAL)}>
                  <div className="button-title">
                    {officialSelected ? <div className="title-icon"><CheckCircleOutlined style={{fontSize:'22px'}}/></div> : null}
                    {t('OfficialLabel')}
                  </div>
                </a>
                <a className={`event-type-unofficial-button ${unofficialClass}`} onClick={() => setEventOfficialType(UNOFFICIAL)}>
                  <div className="button-title">
                    {unofficialSelected ? <div className="title-icon"><CheckCircleOutlined style={{fontSize:'22px'}}/></div> : null}
                    {t('UnofficialLabel')}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='add-event-modal__content'>
          <AddEventForm closeModal={onCancel} eventLocationType={eventLocationType} eventOfficialType={eventOfficialType} />
          {((remoteSelected || inPersonSelected) && (officialSelected || unofficialSelected)) ? null : <div className="add-event-modal-overlay" />}
        </div>
      </div>
    </EventModal>
  );
};

AddEventModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default AddEventModal;
