/* eslint-disable import/prefer-default-export */
import { API } from 'utils/api';
import { formatPatchSessionDocument } from './model';
import axios from 'axios';

//const { http } = API;
const baseUrl = '/api/session_documents';

// POST file info for BE to generate S3 presigned url for secured FE file upload
export const initiateSessionFileUpload = async (sessionDocumentId, file) => {
  const url = `${baseUrl}/${sessionDocumentId}/files`
  const response = await API.GetInstance().http.request({ 
    method: 'POST',
    url,
    data: { // LOL Brad the data wrap master
      data: {
        type: 'event_file',
        attributes: {
          file_name: file.name,
          content_type: file.type,
        },
      },
    },
  })
  return response
}

export const uploadSessionFile = async (presignedUrl, file, progressCallback) => {
  const { data } = await axios.request({
    method: 'PUT',
    url: presignedUrl,
    data: file,
    headers: {
      'Content-Type': file.type,
    },
    onUploadProgress: e => progressCallback(e)
  })
  return data
}

export const getSessionFile = async(sessionDocumentId, fileId) => {
  const url = `${baseUrl}/${sessionDocumentId}/files/${fileId}`;
  const response = await API.GetInstance().http.request({
    method: 'GET',
    url,
  });

  return response
}

export const editSessionFile = async (sessionDocumentId, fileId, attributes) => {
  const url = `${baseUrl}/${sessionDocumentId}/files/${fileId}`;
  const { data } = await API.GetInstance().http.request({
    method: 'PATCH',
    url,
    data: {
      data: {
        id: fileId,
        type: 'event_file',
        attributes,
      }
    }
  })
  return data
}

export const editSessionDocument = async(sessionDocumentId, payload) => {
  const url = `${baseUrl}/${sessionDocumentId}`;
  const {data, included} = await API.GetInstance().http.patch(url, payload);
  return {
    data,
    included: included || []
  }
}

export const publishSessionDocuments = async (documents, unpublish) => {
  if (!documents.length) return;
  const results = [];
  const attr = { isPublished: !unpublish };
  for (let i = 0; i < documents.length; i++) {
    const { id, isPublishable, isPublished } = documents[i];
    if ((unpublish && isPublished) || (!unpublish && isPublishable && !isPublished)) {
      const url = `${baseUrl}/${id}`;
      const payload = formatPatchSessionDocument(attr, id);
      await API.GetInstance().http.patch(url, payload);
      results.push(id);
    }
  }
  return results;
};
