import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Form, Button } from 'antd';
import Input from 'shared/components/Input'
import { FormLabel, BackToOptions } from './CreateAccount';

const FormItem = Form.Item;

const ForgotPassword = (props) => {
  const { handleSendEmail, goBack } = props;
  const { t } = useTranslation()
  const [form] = Form.useForm();

  const handleSubmit = (e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields().then(async (values, err) => {
      handleSendEmail(values);
    });
  };

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  return (
    <div className='registration__forgot-pass'>
      <BackToOptions
        goBack={goBack}
        text={t('backLabel')}
      />
      <Form hideRequiredMark onFinish={handleSubmit} onFinishFailed={onFinishFailed} form={form} name='ForgotPassword'>
        <div className='registration__forgot-pass-form'>
          <FormItem name='email' label={<FormLabel text={t('emailLabel')} />} rules={[{ required: true, message: t('enterEmailLabel') },{ type: 'email', message: t('enterValidEmailLabel') },]}>
            <Input size='large' />
          </FormItem>
        </div>
        <div className='registration__submit'>
          <Button htmlType='submit'>Email Me Reset Instructions</Button>
        </div>
      </Form>
    </div>
  );
};

ForgotPassword.propTypes = {
  form: PropTypes.object.isRequired
};

export default (ForgotPassword);
