import { API } from 'utils/api';
import { queryStringify, formatDataResult } from 'utils/query';
import axios from 'axios';
import { JUDGING_REVIEW_PANEL_TYPE_ID, REFEREE_REVIEW_PANEL_TYPE_ID } from 'shared/constants/panelTypes';

//const { http } = API;

const scheduleBaseUrl = '/api/schedules';

const baseUrl = '/api/sessions'

export const getSessionDocuments = async (sessionId) => {
  const url = `${baseUrl}/${sessionId}/documents`;
  const { data, included } = await API.GetInstance().http.get(url);
  return { data, included: included || [] };
}

export const getSessionsBy = async (filter) => {
  const queryStr = queryStringify({ filter });
  const url = `${baseUrl}${queryStr}`;
  const { data, included } = await API.GetInstance().http.get(url);
  return { data, included: included || [] };
};

export const getJudgingSessions = async (scheduleId, page={number:1, size: 500}) => {
  const pageUrl = queryStringify({page});
  const config = { headers: { 'api-version': '2.0' } };
  const url = `${scheduleBaseUrl}/${scheduleId}/judging_sessions${pageUrl}`;
  const response = await API.GetInstance().http.get(url, config );
  // const {data, included, meta} = response;
  return response
  // return {
  //   data,
  //   included: included || [], 
  //   meta
  // }
}

export const getScoringSessionsrequest = (scheduleId, filter={}, page={size: 500, number: 1}) => {
  const queryStr = queryStringify({ filter, page });
  const url = `${scheduleBaseUrl}/${scheduleId}/scoring_sessions${queryStr}`;
  return API.GetInstance().http.get(url);
}

export const getScoringSessions = async (scheduleId, filter={}, page={size: 500, number: 1}) => {
  const queryStr = queryStringify({ filter, page });
  const url = `${scheduleBaseUrl}/${scheduleId}/scoring_sessions${queryStr}`;
  const response = await API.GetInstance().http.get(url);
  const {data, included} = response;
  return {
    data,
    included: included || []
  }
}

export const getScoringSessionsV2 = async (scheduleId, filter = {}, page = { size: 10, number: 1 }) => {
  const queryStr = queryStringify({ page });
  const url = `${scheduleBaseUrl}/${scheduleId}/scoring_sessions`;
  const response = await API.GetInstance().http.get(url, {headers: {'api-version': 2.0}});
  return response
  // const { data, included } = response;
  // return {
  //   data,
  //   included: included || []
  // }
}


// export const getPanelsData = async (scheduleId, type) => {
//   // const queryStr = queryStringify({ filter, page });
//   // const url = `${scheduleBaseUrl}/${scheduleId}/panels`;
//   const url = `${scheduleBaseUrl}/${scheduleId}/panels?filter[panel_type.Id]=eq:${type == 'score' ? REFEREE_REVIEW_PANEL_TYPE_ID : JUDGING_REVIEW_PANEL_TYPE_ID}`;
//   const response = await API.GetInstance().http.get(url);
//   const { data, included } = response;
//   return {
//     data,
//     included: included || []
//   }
// }

export const getPanelsData = async (scheduleId, type) => {
  // const queryStr = queryStringify({ filter, page });
  // const url = `${scheduleBaseUrl}/${scheduleId}/panels`;
  const url = `${scheduleBaseUrl}/${scheduleId}/panels?filter[panel_type.Id]=eq:${type == 'score' ? REFEREE_REVIEW_PANEL_TYPE_ID : JUDGING_REVIEW_PANEL_TYPE_ID}`;
  const response = await API.GetInstance().http.get(url, { headers: { 'api-version': 2.0 } });
  
  return response
}

export const createNewSession = async (payload) => {
  const {data, included} = await API.GetInstance().http.post(baseUrl, payload);
  return {
    data,
    included: included || []
  }
}

export const getSessionById = async (sessionId) => {
  const url = `${baseUrl}/${sessionId}`;
  const {data, included} = await API.GetInstance().http.get(url);
  return {
    data,
    included: included || []
  }
}


export const getSessionRubrics = async (sessionId) => {
  const url = `${baseUrl}/${sessionId}/rubrics`;
  const { data, included } = await API.GetInstance().http.get(url);
  return { data, included: included || [] };
}

export const getAllSessionsRubrics = async (sessionIds) => {
  const results = [];
  for (let i = 0; i < sessionIds.length; i++) {
    const sessionId = sessionIds[i];
    const url = `${baseUrl}/${sessionId}/rubrics`;
    const { data } = await API.GetInstance().http.get(url);
    const rubrics = data
      .filter(({ id }) => !results.find(r => r.id === id))
      .map((d) => ({ ...formatDataResult(d), sessionId }));
    results.push(...rubrics);
  }
  return results;
}

export const regenerateSessionUrl = async (sessionId) => {
  const url = `${baseUrl}/commands`;
  
  const payload = {
    data: {
      type: "generate_external_meeting_link", 
      relationships: {
          sessions: {
            data: [{type: 'session', id: sessionId}]
          }
      }
  }
  }
  await API.GetInstance().http.post(url, payload);
}

export const createExternalLink = async (sessionId, sessionMeetingLink) => {
  const url = `${baseUrl}/${sessionId}/external_link`;
  
  const payload = {
    data: {
      type: "external_link", 
      attributes: {
        url: sessionMeetingLink
      }
  }
  }
  const {data} = await API.GetInstance().http.post(url, payload);
  return data;
}