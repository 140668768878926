import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Collapse} from 'antd';
import {ArrowUpOutlined, ArrowDownOutlined} from '@ant-design/icons';

import AddTeamModal from 'features/EventContainer/components/TeamsContainer/AddTeamModal';
import { useTranslation } from 'react-i18next';
import PanelHeader from './PanelHeader';
import AddTeamForm from './TeamAdded';
import DefaultTeamForm from './DefaultTeamForm';
import '../../../styles/AddTeamAccordion.scss';

const { Panel } = Collapse;

const TeamAccordion = (props) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const { selectedCoach: { teams } } = props;
  const noTeams = teams.length < 1;

  const handleClose = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const inactivePanelStyle = {
    background: "#F4F4F4",
    borderRadius: 0,
    marginBottom: 24,
    border: 0,
    overflow: "hidden"
  };

  const activePanelStyle = {
    background: "#EDF9E8",
    borderRadius: 0,
    marginBottom: 24,
    border: 0,
    overflow: "hidden"
  };

  const handleExpandIcon = (panelProps) => {
    const { isActive } = panelProps;

    if (isActive) {
      return <ArrowUpOutlined />;
    }

    return <ArrowDownOutlined />;
  };

  const defaultPanel = (panelKey = 'default') => {
    return (
      <Panel
        header={<PanelHeader teamKey='1' />}
        key={panelKey}
        style={inactivePanelStyle}
        hasData={false}
      >
        <DefaultTeamForm />
      </Panel>
    );
  };

  const teamsPanels = (teamsArr) => {
    const teamsPanelsComponents = teamsArr.map((t, i) => (
      // <Panel
      //   header={<PanelHeader teamKey={i + 1} />}
      //   key={t.id}
      //   style={activePanelStyle}
      //   forceRender={false}
      // >
      //   <AddTeamForm team={t} hasData />
      // </Panel>
      <div className='add-team-accordion__panel' key={t.id}>
        <AddTeamForm team={t} hasData />

      </div>


    ))
    return teamsPanelsComponents;
  };

  const defaultActiveKey = noTeams ? null : [teams[teams.length - 1].id];

  // const testingKeys = noTeams ? ['1'] : teams.map(t => t.id);
  // console.log(testingKeys)
  // console.log({defaultActiveKey, formattedTeams, teams})

  // const handleCollapseChange = (openPanelKey) => {
  //   console.log({openPanelKey})
  // }

  return (
    <div className='add-team-accordion'>
      <AddTeamModal visible={visible} onCancel={handleClose} />
      {noTeams ? (
        <Collapse
          bordered={false}
          accordion={false}
          expandIconPosition='right'
          defaultActiveKey="1"
          expandIcon={handleExpandIcon}
        >
          {defaultPanel('1')}
        </Collapse>
      ) : (
        <Collapse
          bordered={false}
          accordion={false}
          expandIconPosition='right'
          defaultActiveKey={defaultActiveKey}
          expandIcon={handleExpandIcon}
        >
          {teamsPanels(teams)}
        </Collapse>
      )}
      <div className='add-team-accordion__link-container'>
        <Button disabled={noTeams} type='link' className='add-team-accordion__link link--large' icon={<PlusOutlined />} onClick={handleOpenModal}>
       
          {t('addAnotherTeamButtonLabel')}
        </Button>
      </div>
    </div>
  );
};


TeamAccordion.propTypes = {
  selectedCoach: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const { event: { eventTeams: { selectedCoach } } } = state;
  return {
    selectedCoach
  };
}

export default connect(mapStateToProps)(TeamAccordion);
