import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'antd';
import Input from 'shared/components/Input';
import Container from 'shared/components/Container';
import { fetchPrograms } from 'features/AdminDashboard/redux/actions/programsActions';
import {
  handleSetOrganizationPrograms,
  handleDeleteOrganization,
  handleGetRegionsByProgramId,
  handleEditOrganization
} from 'features/AdminDashboard/redux/actions/organizationsActions';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import FirstText from 'shared/components/FirstText';
import Checkbox from 'shared/components/Checkbox';
import { useTranslation } from 'react-i18next';
import Select from 'shared/components/FormDropdown';
import ConfirmationModal from 'shared/components/ConfirmModal';
import ScreenLoading from 'shared/components/Spinner/ScreenLoading';
import './EditOrganizationContent.scss';

import {
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FLL_CHALLENGE_ID
} from 'shared/constants/programIds';

const FormItem = Form.Item;
const SelectOption = Select.Option;

const rowProps = {
  type: 'flex', gutter: 20,
  className: 'm-t-20'
};

const EditOrganizationContent = props => {
  const {
    isFetchingRegions,
    closeModal,
    isFetchingProgramTypes,
    programOptions,
    selectedOrganization,
    handleDelete,
    isDeletingOrganization,
    setOrganizationPrograms,
    handleGetRegions,
    isSavingOrganization
  } = props;
  const [deleteConfirmationModalVisible, setDeleteConfirmationModalVisible] = useState(false)
  const [editConfirmationModalVisible, setEditConfirmationModalVisible] = useState(false)

  const { t } = useTranslation()

  const [form] = Form.useForm();

  useEffect(() => {
    props.fetchPrograms();
  }, []);

  const REGION_LABELS = {
    [FLL_CHALLENGE_ID]: 'FLL Challenge',
    [FLL_EXPLORE_ID]: 'FLL Explore',
    [FLL_DISCOVER_ID]: 'FLL Discover',
  };

  if (!selectedOrganization) {
    return null;
  }

  if (isFetchingProgramTypes) {
    return <ScreenLoading loading subTitle='Loading' />
  }

  const {
    organizationId
  } = selectedOrganization;

  const selectedOrganizationProgramIds = selectedOrganization.programs.map(p => p.programId);
  const selectedRegions = selectedOrganization.programs;
  const updateProgramOptions = (programIds) => {
    const removedPrograms = selectedOrganizationProgramIds.filter(x => !programIds.includes(x));
    if (removedPrograms.length > 0) {
      let resetValues = Object.fromEntries(removedPrograms.map(s => ([`region_${s}`, []])));
      form.setFieldsValue(resetValues);
    }
    setOrganizationPrograms(programIds)
  }

  const deleteCb = () => {
    setDeleteConfirmationModalVisible(false);
    closeModal();
  }

  const onDelete = () => {
    handleDelete(organizationId, deleteCb);
  }

  const handleRegionSearch = (input, programId) => {
    if (!input) return;
    handleGetRegions(input, programId)
  }

  const saveCallback = () => {
    setEditConfirmationModalVisible(false);
    closeModal();
  }

  const handleSaveOrganization = async (e) => {
    if (e && 'preventDefault' in e) { e.preventDefault(); }
    form.validateFields().then((values, err) => {

      const formKeys = Object.keys(values);
      const regionKeys = formKeys.filter(k => k.includes('region'));
      const addedRegions = [];
      const removedRegions = [];

      const initialRegions = [...selectedOrganization.organizationInitialRegions];

      let formRegions = [];
      regionKeys.forEach(r => {
        const programRegionsList = Array.isArray(values[r]) ? values[r] : [];
        formRegions = formRegions.concat(programRegionsList)
      })

      formRegions.forEach(ri => {
        if (!initialRegions.includes(ri)) {
          addedRegions.push(ri)
        }
      })

      initialRegions.forEach(ir => {
        if (!formRegions.includes(ir)) {
          removedRegions.push(ir)
        }
      })
      const payload = {
        name: values.name,
        removedRegions,
        addedRegions,
        organizationId
      }

      props.handleEditOrganization(organizationId, payload, saveCallback, closeModal)
    });
  }

  const onSubmitClick = (e) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }

    setEditConfirmationModalVisible(true)
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  return (
    <Container type='medium'>
      <ConfirmationModal
        visible={deleteConfirmationModalVisible}
        onCancel={() => {
          setDeleteConfirmationModalVisible(false);

        }}
        onSubmit={onDelete}
        title='Organization'
        description='Are you sure you want to remove this organization?'
        loading={isDeletingOrganization}
      />
      <ConfirmationModal
        visible={editConfirmationModalVisible}
        onCancel={() => {
          setEditConfirmationModalVisible(false);
        }}
        onSubmit={handleSaveOrganization}
        title='Organization'
        description='Are you sure you want to edit this organization?'
        loading={isSavingOrganization}
      />
      <Form hideRequiredMark onFinish={onSubmitClick} onFinishFailed={onFinishFailed} name='EditOrganization' form={form} >
        <Row {...rowProps}>
          <Col xs={24} md={16}>
            <FormItem name='name' label='Organization Name' rules={[{ required: true, message: 'Enter Organization Name.' }]} initialValue={selectedOrganization.name}>
              <Input required />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col xs={24} md={16}>
            <FormItem name='program' label={t('programAllLabel')} initialValue={selectedOrganizationProgramIds}>
              <Checkbox.Group
                className='add-partner-organization__checkboxs'
                onChange={updateProgramOptions}
              >
                {programOptions.map(({ name, id }) => (
                  <Checkbox key={id} value={id}>
                    <p className='paragraph--large paragraph--charcoal'>
                      <FirstText />
                      {' '}
                      {name}
                    </p>
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </FormItem>
          </Col>
        </Row>
        {selectedRegions.map((s, i) => (
          <Row key={s.programId}>
            <Col xs={24} md={16}>
              <FormItem
                name={`region_${s.programId}`}
                label={`${REGION_LABELS[s.programId]} Region`}
                rules={[{ required: true, message: 'Select Region.' }]}
                initialValue={(s.initialRegions && Array.isArray(s.initialRegions)) ? s.initialRegions : []}
              >
                <Select
                  showSearch
                  onSearch={text => handleRegionSearch(text, s.programId)}
                  loading={isFetchingRegions}
                  filterOption={false}
                  mode='multiple'
                >
                  {s.derivedRegions.map(r => (
                    <SelectOption value={r.regionId} key={r.regionId}>
                      {r.regionName}
                    </SelectOption>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>
        ))}
        <Row {...rowProps} justify='space-between'>
          <Col xs={24} md={16} className='admin-dashboard__edit-modal-buttons'>
            <Button disabled={isDeletingOrganization || isSavingOrganization} additionalClassNames='add-event-modal__btn' htmlType='submit' onSubmit={onSubmitClick}>Save</Button>
            <Button disabled={isDeletingOrganization || isSavingOrganization} onClick={() => setDeleteConfirmationModalVisible(true)} additionalClassNames='add-event-modal__btn'>Delete</Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

EditOrganizationContent.propTypes = {
  form: PropTypes.object.isRequired,
  programOptions: PropTypes.array.isRequired,
  organizationsList: PropTypes.array.isRequired,
  isFetchingProgramTypes: PropTypes.bool.isRequired,
  selectedOrganization: PropTypes.object.isRequired,
  closeModal: PropTypes.func,
  isFetchingRegions: PropTypes.bool.isRequired,
  handleGetRegions: PropTypes.func.isRequired,
  fetchPrograms: PropTypes.func.isRequired,
  handleEditOrganization: PropTypes.func.isRequired,
  isSavingOrganization: PropTypes.bool.isRequired
};

EditOrganizationContent.defaultProps = {
  closeModal: () => { }
}

const mapStateToProps = ({ adminBoard }) => {
  const { organizations: { isSavingOrganization, isFetchingRegions, isDeletingOrganization, organizationsList, selectedOrganization }, programs: { list, isFetchingProgramTypes } } = adminBoard;
  const filteredList = (list || []).filter(p => p.shortName !== 'FTC')
  return {
    programOptions: filteredList,
    organizationsList,
    isFetchingProgramTypes,
    selectedOrganization,
    isDeletingOrganization,
    isFetchingRegions,
    isSavingOrganization
  };
}

const mapDispatchToProps = {
  handleDelete: handleDeleteOrganization,
  fetchPrograms,
  setOrganizationPrograms: handleSetOrganizationPrograms,
  handleGetRegions: handleGetRegionsByProgramId,
  handleEditOrganization
}
export default connect(
  mapStateToProps,
  mapDispatchToProps)(EditOrganizationContent);