import React from 'react';
import { Upload} from 'antd';
import { useTranslation } from 'react-i18next';

import Button from 'shared/components/Button/BtnSecondary';
import PropTypes from 'prop-types';
import './choose-file.scss';

const ChooseFile = (props) => {
  const {onChange, text} = props;
  const { t } = useTranslation()
  const displayText = text.length ? text : t('chooseCsvFileLabel')

  return (
    <div className='choose-file'>
      <Upload
        accept=".txt, .csv"
        onChange={onChange}
        showUploadList={false}
      >
        <Button type='default' styleType='secondary' size='large' additionalClassNames='p-r-14 p-l-14 btn--180' onChange={onChange}> 
          {displayText}
          {' '}

        </Button>
      </Upload>
    </div>
  );
};

ChooseFile.propTypes = {
  onChange: PropTypes.func,
  text: PropTypes.string
};

ChooseFile.defaultProps = {
  onChange: () => {},
  text: ''
}

export default ChooseFile;