import React from 'react';
import PropTypes from 'prop-types';
import Container from 'shared/components/Container';
import '../../../styles/judgingRubric/JudgingRubricEvidence.scss';
import {connect} from 'react-redux';
import EvidenceCarousel from './EvidenceCarousel';

const JudgingRubricEvidence = props => {
  const {
    evidenceDocuments,
  } = props;

  return (
    <div className='c-judging-rubric-evidence'>
      <Container>
        <EvidenceCarousel files={evidenceDocuments} />
      </Container>
    </div>
  );
};

JudgingRubricEvidence.propTypes = {
  getDocuments: PropTypes.func.isRequired,
  evidenceDocuments: PropTypes.array,
};

JudgingRubricEvidence.defaultProps = {
  evidenceDocuments: [],
}

const mapStateToProps = (state) => {
  const {
    judgingRubric: {
      judgingRubricEvidenceDocuments
    }
  } = state;
  
  return {
    evidenceDocuments: judgingRubricEvidenceDocuments,
  }
}

export default connect(mapStateToProps )(JudgingRubricEvidence);