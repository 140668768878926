import React from 'react';
import PropTypes from 'prop-types';
import './paragraph.scss';

const types = ['default'];

// xsmall: 12/16, small: 12/20, medium: 14/20, large: 16/22, xlarge: 16/24
const sizes = ['xsmall', 'small', 'medium', 'large', 'xlarge'];
const weight = [300, 400, 'normal', 'bold'];
const colors = ['blue', 'black', 'white', 'darkGray', 'charcoal', 'silver', 'red', 'dark-red'];

const Paragraph = props => {
  const { children, type, color, weight, size, className, ..._props } = props;
  const cls = `
    paragraph--${type}
    paragraph--${color}
    paragraph--${weight}
    paragraph--${size}
    ${className}
  `;
  return (
    <p className={cls} {..._props}>
      {children}
    </p>
  );
};

Paragraph.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  type: PropTypes.oneOf(types),
  size: PropTypes.oneOf(sizes),
  weight: PropTypes.oneOf(weight),
  color: PropTypes.oneOf(colors)
};

Paragraph.defaultProps = {
  type: 'default',
  color: 'charcoal',
  weight: 400,
  size: 'small',
  className: ''
};

export default Paragraph;
