import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import programLogos from 'shared/constants/programLogos';
import truncateString from 'utils/truncateString';
import { parseDateTime } from 'utils/dateTimeUtils';
import ErrorIcon from '../Icon/icons/ErrorIcon';
import './EventTile.scss';
import { IN_PERSON_TEST, REMOTE_TEST } from 'shared/constants/eventTypes';

const EventTile = props => {
  const { event, id, tileType, canDelete, selectDeleteEvent } = props;
  const { name, programId, startDate, userRoles = [], teamsNumbers = [] } = event;
  const { ProgramLogo, PlayMarker, color } = programLogos[programId];
  const selectedLanguage = localStorage.getItem('i18nextLng');

  const handleDeleteEvent = (e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    e.stopPropagation();
    selectDeleteEvent({ id, name });
  };

  const dateInfo = useMemo(() =>
    startDate ? parseDateTime(startDate) : {},
    [selectedLanguage]
  );

  let eventLink = '';
  switch (tileType) {
    case 'admin':
      eventLink = `/events/${id}`;
      break;
    case 'volunteer':
      eventLink = `/event-overview/${id}`
      break;
    default:
      eventLink = `/events/${id}`;
      break;
  }

  const teamsLength = teamsNumbers.length;
  const slicedTeams = teamsNumbers.slice(0, 2);
  const firstPart = teamsLength >= 1 ? `Teams: ${slicedTeams.join(', ')}` : '';
  const optionalMore = teamsLength > 2 ? ` + ${teamsLength - 2} more` : '';
  const displayedTeamsNumber = `${firstPart}${optionalMore}`;
  const isTest = event.event_type === REMOTE_TEST || event.event_type === IN_PERSON_TEST;

  return (
    <Link to={eventLink} href={eventLink} className='event-tile__link'>
      <div className={`event-tile event-tile--${color}`}>
        {isTest && <div className='test-event-ribbon-wrapper'><div className={`ribbon ribbon--${color}`}>Test</div></div> }
        <div className='event-tile-inner'>
          <span className='event-tile__col-left'>
            <div className='event-tile__date'>
              <p className='event-tile__date__num'>{dateInfo.date}</p>
              <span className='event-tile__month-year'>
                <p className='event-tile__date__day'>{dateInfo.day}</p>
                <p className='event-tile__date__month_year'>{`${dateInfo.month} ${dateInfo.year}`}</p>
              </span>
            </div>
            <div className='event-tile__location'>
              <p className='event-tile__event-name'>
                {truncateString(name, 90)}
              </p>
              <div className='event-tile__location-separator' />
              <p className='event-tile__coach-head-referee'>{userRoles.join(', ')}</p>
              <p className='event-tile__region-name'>{displayedTeamsNumber}</p>
            </div>
          </span>
          <div className='event-tile__col'>
            <ProgramLogo />
          </div>
        </div>
        <ErrorIcon
          visible={canDelete}
          onClick={handleDeleteEvent}
          className='event-tile__delete-icon'
        />
      </div>
    </Link>
  );
};

EventTile.propTypes = {
  event: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  tileType: PropTypes.string,
  canDelete: PropTypes.bool,
  selectDeleteEvent: PropTypes.func
};

EventTile.defaultProps = {
  tileType: 'admin',
  canDelete: false,
  selectDeleteEvent: () => {}
};

export default EventTile;
