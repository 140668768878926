import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EventModal from 'shared/components/EventModal';
import Container from 'shared/components/Container';
import ConfirmationModal from 'shared/components/ConfirmModal';
import {
  deleteVolunteer, updateVolunteerInfo
} from 'features/AdminDashboard/redux/actions/volunteersActions';
import { Col, Row, Form } from 'antd';
import Input from 'shared/components/Input';
import BtnPrimary from 'shared/components/Button/BtnPrimary';
import BtnSecondary from 'shared/components/Button/BtnSecondary';
import { useTranslation } from 'react-i18next';

const rowProps = {
  type: 'flex', gutter: 20,
  className: 'm-t-20'
};

const colProps = {
  xs: 24,
  md: 16
}
const FormItem = Form.Item;

const EditVolunteerModal = props => {
  const {
    onCancel,
    visible,
    selectedVolunteer: {
      id, email, firstName, lastName, mobilePhone
    },
    isDeletingVolunteer,
    isUpdatingVolunteer,
  } = props;

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const cancelDelete = () => {
    setDeleteModalVisible(false);
  };

  const onDeleteClick = () => {
    setDeleteModalVisible(true);
  };

  const deleteVolunteer = async () => {
    await props.handleDelete(id);
    setDeleteModalVisible(false);
    onCancel();
  };

  const onSaveClick = (e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields().then(async (values, err) => {
            
     await props.handleUpdate(id, values);
    });
  };

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  return (
    <EventModal
      onCancel={onCancel}
      visible={visible}
      renderHeader={() => (<span>Edit User</span>)}
    >
      <Container>
        <h1>Edit User</h1>
      </Container>
      <Container type='medium'>
        <ConfirmationModal
          visible={deleteModalVisible}
          onCancel={cancelDelete}
          onSubmit={deleteVolunteer}
          title='Volunteer'
          description='Are you sure you want to remove this volunteer from all event role assignments?'
          loading={isDeletingVolunteer}
        />
        <Form form={form} initialValues={{ email: email, firstName: firstName, lastName: lastName, phone: mobilePhone }} hideRequiredMark onFinish={onSaveClick} name='EditVolunteer'>
          <Row {...rowProps}>
            <Col xs={24} md={16}>
              <FormItem name="email" label={t('emailLabel')}>
                <Input required disabled />
              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col {...colProps}>
              <FormItem name="firstName" label={t('firstNameLabel')} rules={[{ required: true, message: t('enterFirstNameLabel') }]}>
                <Input size='large' required />
              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col {...colProps}>
              <FormItem name="lastName" label={t('lastNameLabel')} rules={[{ required: true, message: t('enterLastNameLabel') }]}>
                <Input size='large' required />
              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col {...colProps}>
              <FormItem name="phone" label={t('phoneOptionalLabel')}>
                <Input size='large' />
              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps} justify='space-between'>
            <Col xs={24} md={16} className='admin-dashboard__edit-modal-buttons'>
              <BtnPrimary
                disabled={isDeletingVolunteer || isUpdatingVolunteer}
                loading={isUpdatingVolunteer}
                htmlType='submit'
              >
                {t('saveLabel')}
              </BtnPrimary>
              <BtnSecondary
                disabled={isDeletingVolunteer || isUpdatingVolunteer}
                loading={isDeletingVolunteer}
                onClick={onDeleteClick}
              >
                Delete
              </BtnSecondary>
            </Col>
          </Row>
        </Form>
      </Container>
    </EventModal>
  );
};

EditVolunteerModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
};

const mapStateToProps = ({ adminBoard }) => {
  const { volunteers: {
    isDeletingVolunteer,
    isUpdatingVolunteer
  } } = adminBoard;
  return {
    isDeletingVolunteer,
    isUpdatingVolunteer
  };
};

const mapDispatchToProps = {
  handleDelete: deleteVolunteer,
  handleUpdate: updateVolunteerInfo
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditVolunteerModal);
