import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { AutoComplete } from 'antd';
import { debounce } from 'lodash';

const FormAutocomplete = (props) => {
  const { onSearch, noDebounce, popUpContainerElementId } = props;
  const handleSearch = useCallback(debounce(onSearch, 300), []);
  return (
    <div id={popUpContainerElementId} style={{ position: 'relative' }}>
      <AutoComplete
        size='large'
        optionLabelProp='text'
        className='autocomplete'
        getPopupContainer={() => document.getElementById(popUpContainerElementId)}
        {...props}
        onSearch={noDebounce ? onSearch : handleSearch}
      />
    </div>
  );
};

FormAutocomplete.propTypes = {
  onSearch: PropTypes.func.isRequired,
  popUpContainerElementId: PropTypes.string
};

FormAutocomplete.defaultProps = {
  popUpContainerElementId: 'autocompleteField'
}

FormAutocomplete.Option = AutoComplete.Option;

export default FormAutocomplete;
