import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {Form} from 'antd';
import {getScoreInputDetails} from 'features/EventOverviewContainer/redux/actions/coachScoring';
import {connect} from 'react-redux';
import DetailedMatchScoreCard from 'shared/components/MatchScoreCard/DetailedMatchScoreCard';
import ListLoading from 'shared/components/Spinner/ListLoading';
import ScoresheetSection from './ScoresheetSection';
import '../../../styles/matchesAndScoring/ScoresheetModal.scss';
import ReadonlyRefereeNotes from '../../ScoringMatch/ScoresheetForm/ReadonlyRefereeNotes';
import { newFllScoringOptions } from 'shared/constants/2025Scoresheet';
import { newFllScoringOptions as OldFllScoringOptions } from 'shared/constants/newScoresheetOptions';
import { CURRENT_EVENT_SEASON } from 'shared/constants/eventConstants';



const titleClassName = 'heading--charcoal h-2 h--normal';

const ScoresheetContent = props => {
  const {
    scoreInputId,
    getDetails,
    totalScore,
    coachDetails,
    title,
    loading, 
    scoredScore,
    teamDetail,
    season,
    scoreInput,
  } = props;

  let seasonData = season == CURRENT_EVENT_SEASON ? newFllScoringOptions : OldFllScoringOptions;

  const {t} = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if ( !scoreInput?.id && scoreInputId) {
      getDetails(scoreInputId)
    }
  }, [scoreInputId])
  const {
    teamName,
    teamNumber,
    teamLocation
  } = coachDetails;
  if (loading) {
    return (
      <ListLoading loading text='Loading Scoresheet' />
    );
  }
  return (
    <div className='match-scoresheet-modal'>
      <div className='match-scoresheet-modal__header'>
        <h2 className={titleClassName}>
          {title}
          {' '}
          {t('scoresheetLabel')}
        </h2>
        <DetailedMatchScoreCard
          score={scoredScore?scoredScore:totalScore}
          teamName={teamDetail ? teamDetail : teamName}
          teamNumber={teamNumber}
          teamLocation={teamLocation}
        />
      </div>
      <Form form={form} name='Scoresheet'>
        <ul className='match-scoresheet-modal__sections-wrapper'>
          {seasonData.map(i => (
            <li key={i.id}>
              <ScoresheetSection form={form} moduleName={i.id} moduleTitle={t(i.name)} fields={i.fields} scoreInputData={scoreInput}/>
            </li>
          ))}
        </ul>

      </Form>
      <ReadonlyRefereeNotes comments={scoreInput?.id ? scoreInput?.comments : []} useComments={scoreInput?.id}/>
    </div>
  );
};

ScoresheetContent.propTypes = {
  form: PropTypes.object.isRequired,
  scoreInputId: PropTypes.string.isRequired,
  getDetails: PropTypes.func.isRequired,
  totalScore: PropTypes.number.isRequired,
  coachDetails: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  const {
    eventOverview,
    matchScoresheet,
    coachScoring: {
      selectedScoreInput,
      isFetchingSelectedScoreInput

    }} = state;
  const {
    totalScore
  } = selectedScoreInput;
  const {
    coachDetails
  } = eventOverview;

  const { eventDetails: { season } } = matchScoresheet
  return {
    coachDetails,
    totalScore,
    loading: isFetchingSelectedScoreInput,
    season
  }
}

export default connect(mapStateToProps, {getDetails: getScoreInputDetails})(ScoresheetContent);