import React from 'react';
import { SignUpContainer } from 'features/RegistrationContainer';

const SignUp = (props) => {
  return (
    <div>
      <SignUpContainer {...props} />
    </div>
  );
};

export default SignUp;
