import React from 'react';
import {Select as AntSelect} from 'antd';
import LoadingOutlined from '@ant-design/icons';

import PropTypes from 'prop-types';
import './FormDropdown.scss';
import './FormDropdownSmall.scss';
import DropdownIcon from 'shared/components/Icon/icons/DownArrow';
import Icon from '@ant-design/icons';

const Dropdown = (props) => {
  const { loading, children, placeholder, disabled, popUpContainerElementId, usePopupContainer} = props;

  return (
    <div id={popUpContainerElementId} style={{ position: 'relative' }}>
      <AntSelect
        {...props}
        getPopupContainer={usePopupContainer ? () => document.getElementById(popUpContainerElementId) : null}
        disabled={disabled}
        className={'form-dropdown ' + props.className}
        placeholder={placeholder}
      >
        {children}
      </AntSelect>

    </div>
  );
};

Dropdown.Option = AntSelect.Option;

Dropdown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  popUpContainerElementId: PropTypes.string,
  usePopupContainer: PropTypes.bool
}

Dropdown.defaultProps = {
  placeholder: null,
  disabled: false,
  loading: false,
  usePopupContainer: true,
  popUpContainerElementId: 'selectFormDropdown'
}

export default Dropdown;