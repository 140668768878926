import { API } from 'utils/api';
import { parallelRequest } from 'utils/query';

//const { http } = API;
const baseUrl = '/api/schedules';

const daysBaseUrl = '/api/schedule_days';
const breakBaseUrl = '/api/schedule_breaks';


export const getScheduleJudgingSessions = async (scheduleId, page={number: 1, size:500}) => {
  const {number, size} = page;
  const url = `${baseUrl}/${scheduleId}/judging_sessions?page[size]=${size}&page[number]=${number}`;
  const response = await API.GetInstance().http.get(url);
  const {data, included} = response;
  return {
    data,
    included: included || []
  }
}

export const getScheduleJudgingSessionsWithPage = async (scheduleId, page={size:500, number:1}) => {
  const {number, size} = page;
  const url = `${baseUrl}/${scheduleId}/judging_sessions?page[size]=${size}&page[number]=${number}`;
  const response = await API.GetInstance().http.get(url);
  const {data, included} = response;
  return {
    data,
    included: included || []
  }
}

export const updateScheduleSessionSetting = async (scheduleId, payload) => {
  const url = `${baseUrl}/${scheduleId}`;
  const response = await API.GetInstance().http.patch(url, payload);
  return response
}

export const generateScheduleSessions = async (payload) =>{
  const url = `${baseUrl}/commands`;
  const response = await API.GetInstance().http.post(url, payload);
  return response;
}

export const getScheduleScoringSessions = async (scheduleId, page={number: 1, size:2000}) => {
  const {number, size} = page;
  const url = `${baseUrl}/${scheduleId}/scoring_sessions?page[size]=${size}&page[number]=${number}`;
  const response = await API.GetInstance().http.get(url, {headers: {'api-version': 2.0}});
  return response
}


export const getScheduleMeetings = async (scheduleId) => {
  const url = `${baseUrl}/${scheduleId}/meetings`;
  const response = await API.GetInstance().http.get(url);
  const {data, included} = response;
  return {
    data,
    included: included || []
  }

}



export const getScheduleOpeningSession = async (scheduleId) => {
  const url = `${baseUrl}/${scheduleId}/opening_session`;
  const response = await API.GetInstance().http.get(url);
  const {data, included} = response;
  return {
    data,
    included: included || []
  }
}


export const getScheduleClosingSession = async (scheduleId) => {
  const url = `${baseUrl}/${scheduleId}/closing_session`;
  const response = await API.GetInstance().http.get(url);
  const {data, included} = response;
  return {
    data,
    included: included || []
  }
}

export const getScheduleScoringTimestamps = async (scheduleId) => {
  const url = `${baseUrl}/${scheduleId}/scoring_time_stamps`;
  const response = await API.GetInstance().http.get(url);
  const {data, included} = response;
  return {
    data,
    included: included || []
  }
}
export const getScheduleJudgingTimestamps = async (scheduleId) => {
  const url = `${baseUrl}/${scheduleId}/judging_time_stamps`;
  const response = await API.GetInstance().http.get(url);
  const {data, included} = response;
  return {
    data,
    included: included || []
  }
}

export const getScheduleById = async (scheduleId) => {
  const url = `${baseUrl}/${scheduleId}`;
  const response = await API.GetInstance().http.get(url);
  const {data, included} = response;
  return {
    data,
    included: included || []
  }
}


export const getScheduleDayById = async (id) =>{
  const url = `${daysBaseUrl}/${id}`;
  const response = await API.GetInstance().http.get(url);
  const {attributes} = (response||{}).data||{};
  return {...attributes}
}

export const getScheduleDaysByScheduleId = async (id) => {
  const url = `${baseUrl}/${id}/days`
  const response = await API.GetInstance().http.get(url);
  return response;
}

export const getScheduleBreaksByScheduleId = async (id) =>{
  const url = `${baseUrl}/${id}/breaks`
  const response = await API.GetInstance().http.get(url);
  return response;
}

export const createScheduleBreak = async (payload) =>{
  const url = `${breakBaseUrl}`;
  const response = await API.GetInstance().http.post(url, payload)
  return response
}

export const updateScheduleBreak = async (id, payload) =>{
  const url = `${breakBaseUrl}/${id}`;
  const response = await API.GetInstance().http.patch(url, payload)
  return response
}

export const addAssociateDaysToBreak = async (id, payload) =>{
  const url = `${breakBaseUrl}/${id}/relationships/days`;
  const response = await API.GetInstance().http.post(url, payload);
  return response;
}

export const deleteAssociateDaysToBreak = async (id, payload) =>{
  const url = `${breakBaseUrl}/${id}/relationships/days`;
  const response = await API.GetInstance().http.delete(url, {data: payload});
  return response;
}

export const addAllAssociatedDaysToBreaks = async (payloads)=>{
  const valid_payloads = (payloads||[]).filter(p=>(p||{}).break_id);
  await parallelRequest(valid_payloads.map(p=>{
    const {break_id, data} = p;
    const url = `${breakBaseUrl}/${break_id}/relationships/days`;
    const payload = {data};
    return API.GetInstance().http.post(url, payload);
  }))
}

export const deleteAllAssociatedDaysToBreaks = async (payloads)=>{
  const valid_payloads = (payloads||[]).filter(p=>(p||{}).break_id);
  await parallelRequest(valid_payloads.map(p=>{
    const {break_id, data} = p;
    const url = `${breakBaseUrl}/${break_id}/relationships/days`;
    const payload = {data};
    return API.GetInstance().http.delete(url, {data: payload});
  }))
}

export const updateDayInfoById = async (id, payload) =>{
  const url = `${daysBaseUrl}/${id}`;
  const response = await API.GetInstance().http.patch(url, payload);
  return response
}

export const updateAllDayInfoByIds = async (payloads)=>{
  const valid_payloads = (payloads||[]).filter(p=>((p||{}).data||{}).id);
  const result = await parallelRequest(valid_payloads.map(p=>{
    const id = p.data.id;
    const url = `${daysBaseUrl}/${id}`;
    return API.GetInstance().http.patch(url, p);
  }))
  return result
}

export const deleteBreakById = async (id) =>{
  const url = `${breakBaseUrl}/${id}`
  const response = await API.GetInstance().http.delete(url);
  return response;
}

export const createOrUpdateAlScheduleBreaks = async (payloads)=>{
  const valid_payloads = (payloads||[]).filter(p=>((p||{}).data||{}).id);
  const result = await parallelRequest(payloads.map(p=>{
    const id = p.data.id;
    if(id){
      if(id < 0){
        delete p.data.id
        return API.GetInstance().http.post(breakBaseUrl, p).then(res=>({...res, prevId: id}));
      }else{
        return API.GetInstance().http.patch(`${breakBaseUrl}/${id}`, p);
      }
    }
  }))
  return result;
}

export const downloadScheduleCsv = async (scheduleId) => {
  
  const url = `${baseUrl}/${scheduleId}/export`;
  const response = await API.GetInstance().http.get(url);
  return response;
}