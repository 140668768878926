import * as types from '../types/judgingTypes';

const DEFAULT_SELECTED_PANEL = {
  name: '',
  panelJudgesList: [],
  panelSessionsList: [],
  sessions: [],
  id: null,
  judgesIds: [],
  refereesIds: [],
  sessionsIds: [],
  panelTypeId: '',
  panelTypeName: ''
}

const DEFAULT_JUDGING_STATE = {
  eventJudgesData: [],
  eventJudgesIncluded: [],
  eventJudgesError: false,
  isLoadingEventJudges: true,
  eventJudgingSessionsData: [],
  eventJudgingSessionsIncluded: [],
  isLoadingEventJudgingSessions: false,
  eventJudgingPanelsData: [],
  eventJudgingPanelsIncluded: [],
  isFetchingJudgingPanels: true,
  errorJudgingPanels: false,
  selectedPanel: DEFAULT_SELECTED_PANEL,
  isFetchingSelectedPanel: false,
  isRemovingJudgeFromPanel: false,
  isCreatingNewJudgingPanel: false,
  judgingPanels: [],
  judgeOptions: [],
  sessionOptions: [],
  isUpdatingJudgingPanel: false,
  expendables: []
}

export default (state = DEFAULT_JUDGING_STATE, action) => {
  const {type} = action;
  switch(type) {
    case types.GET_EVENT_JUDGES_BEGIN:
      return {
        ...state,
        isLoadingEventJudges: true
      }
    case types.GET_EVENT_JUDGES_ERROR:
      return {
        ...state,
        isLoadingEventJudges: false,
        eventJudgesError: true
      }
    case types.GET_EVENT_JUDGES_SUCCESS:
      return {
        ...state,
        eventJudgesError: false,
        isLoadingEventJudges: false,
        eventJudgesData: action.payload.eventJudgesData,
        eventJudgesIncluded: action.payload.eventJudgesIncluded
      }
    case types.GET_EVENT_JUDGING_PANELS_BEGIN:
      return {
        ...state,
        eventJudgesError: false,
        isFetchingJudgingPanels: true,
      }
    case types.GET_EVENT_JUDGING_PANELS_ERROR:
      return {
        ...state,
        errorJudgingPanels: true,
        isFetchingJudgingPanels: false,
      }
    case types.GET_EVENT_JUDGING_PANELS_SUCCESS:
      return {
        ...state,
        errorJudgingPanels: false,
        isFetchingJudgingPanels: false,
        judgingPanels: action.payload.judgingPanels,
        judgeOptions: action.payload.judgeOptions,
        sessionOptions: action.payload.sessionOptions
      }
    case types.FETCH_SELECTED_JUDGING_PANEL_BEGIN:
      return {
        ...state,
        isFetchingSelectedPanel: true,
      }
    case types.FETCH_SELECTED_JUDGING_PANEL_ERROR:
      return {
        ...state,
        isFetchingSelectedPanel: false,
      }
    case types.FETCH_SELECTED_JUDGING_PANEL_SUCCESS:
      return {
        ...state,
        isFetchingSelectedPanel: false,
        selectedPanel: {
          ...state.selectedPanel,
          ...action.payload.selectedPanel
        },
      }
    case types.RESET_SELECTED_JUDGING_PANEL:
      return {
        ...state,
        selectedPanel: DEFAULT_SELECTED_PANEL 
      }
    case types.UPDATE_SELECTED_JUDGING_PANEL:
      return {
        ...state,
        selectedPanel: {
          ...state.selectedPanel,
          [action.payload.field]: action.payload.value
        }
      }
      case types.REMOVE_JUDGE_FROM_PANEL_BEGIN:
        return {
          ...state,
          isRemovingJudgeFromPanel: true
        }
      case types.REMOVE_JUDGE_FROM_PANEL_ERROR:
        return {
          ...state,
          isRemovingJudgeFromPanel: false
        }
      case types.REMOVE_JUDGE_FROM_PANEL_SUCCESS:
        return {
          ...state,
          isRemovingJudgeFromPanel: false
        }
      case types.CREATE_NEW_JUDGING_PANEL_BEGIN:
        return {
          ...state,
          isCreatingNewJudgingPanel: true
        }
      case types.CREATE_NEW_JUDGING_PANEL_ERROR:
        return {
          ...state,
          isCreatingNewJudgingPanel: false
        }
      case types.CREATE_NEW_JUDGING_PANEL_SUCCESS:
        return {
          ...state,
          isCreatingNewJudgingPanel: false,
          judgingPanels: action.payload.judgingPanels,
          judgeOptions: action.payload.judgeOptions,
          sessionOptions: action.payload.sessionOptions
        }
      case types.EDIT_JUDGING_PANEL_BEGIN:
        return {
          ...state,
          isUpdatingJudgingPanel: true
        }
      case types.EDIT_JUDGING_PANEL_ERROR:
        return {
          ...state,
          isUpdatingJudgingPanel: false
        }
      case types.EDIT_JUDGING_PANEL_SUCCESS:
        return {
          ...state,
          isUpdatingJudgingPanel: false,
          judgingPanels: action.payload.judgingPanels,
          judgeOptions: action.payload.judgeOptions,
          sessionOptions: action.payload.sessionOptions
        }
      case types.SET_SELECTED_JUDGING_PANEL_DATA:
        return {
          ...state,
          selectedPanel: {
            ...state.selectedPanel,
            sessionsIds: action.payload.sessionIds,
            judgesIds: action.payload.judgeIds
          }
        }
    case types.SET_JUDGING_PANELS_EXPANDS:
      return {
        ...state,
        expendables: action.payload.panels
      }
    default:
      return state;
  }

}