import rd1 from './rd1';
import rd2 from './rd2';
import rd3 from './rd3';
import rd4 from './rd4';
import rd5 from './rd5';

export default [
  rd1,
  rd2,
  rd3,
  rd4,
  rd5
]