import PropTypes from 'prop-types';
import { notification } from 'antd';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import RegistrationLayout from 'shared/components/Layouts/RegistrationPageLayout';
import RegistrationForm from 'shared/components/Layouts/Registration/FormContainer';
import * as userService from 'utils/services/users';
import ResetPassword from '../Forms/ResetPassword';

const ResetPassContainer = (props) => {
  const { history, location: { search } } = props;
  const [query, setQuery] = useState({});
  const { email } = query;

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const email = urlParams.get('email');
    const userId = urlParams.get('id');
    const code = urlParams.get('code');
    if (email && userId && code) {
      setQuery({ email, userId, code });
    }
  }, [search]);

  const handleReset = async (password) => {
    if (email && password) {
      try {
        await userService.changePassword({ ...query, password });
        notification.success({
          message: 'Success',
          description: 'Your password changed successful.',
        });
        history.push('/login');
      } catch (e) {
        notification.error({
          message: 'Error',
          description: 'Your password change failed.',
        });
      }
    }
  };

  return (
    <RegistrationLayout>
      <RegistrationForm title='Reset Password' email={email}>
        <ResetPassword
          goBack={() => history.replace('/login')}
          handleReset={handleReset}
        />
      </RegistrationForm>
    </RegistrationLayout>
  );
};

ResetPassContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  authorized: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  const {
    auth: { authorized, loading, expiresAt }
  } = state;
  return {
    authorized,
    expiresAt,
    loading
  };
};

const mapDispatchToProps = {

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassContainer);
