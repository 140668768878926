import React from 'react';

export default ()=>{
  return (
    <svg width="14px" height="8px" viewBox="0 0 14 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
    <title>C32C287A-D3A9-4B9B-80B0-09074D266CDD</title>
    <g id="Events" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Event-Management---Single-Event---PDP-Admin---V2" transform="translate(-1149.000000, -928.000000)" fill="#FFFFFF">
            <g id="Group-34" transform="translate(250.000000, 705.000000)">
                <g id="Group-33" transform="translate(0.000000, 42.000000)">
                    <g id="Group-32" transform="translate(10.000000, 11.000000)">
                        <g id="Group-13" transform="translate(0.000000, 150.000000)">
                            <g id="Group" transform="translate(872.000000, 0.000000)">
                                <g transform="translate(17.000000, 20.000000)" id="Icons/FIRST/Drop-Arrow">
                                    <polygon id="Path" points="1.645 0 7 4.94466937 12.355 0 14 1.52226721 7 8 0 1.52226721"></polygon>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
  )
}