import { API } from 'utils/api';
import * as types from '../../types/eventScheduleTypes';


const getOpeningCeremonyBegin = () => {
  return {
    type: types.GET_SCHEDULE_OPENING_SESSION_BEGIN
  }
}
const getOpeningCeremonyError = () => {
  return {
    type: types.GET_SCHEDULE_OPENING_SESSION_ERROR
  }
}
const getOpeningCeremonySuccess = (openingSessionData, openingSessionIncluded) => {
  return {
    type: types.GET_SCHEDULE_OPENING_SESSION_SUCCESS,
    payload: {
      openingSessionData, openingSessionIncluded
    }
  }
}

const handleOpeningSession = (scheduleId) => {
  return async dispatch => {
    await dispatch(getOpeningCeremonyBegin())
    try {
      const response = await API.GetInstance().http.get(`/api/schedules/${scheduleId}/opening_session`);
      const {data, included} = response;
      const includedArr = included || [];
      await dispatch(getOpeningCeremonySuccess(data, includedArr))
    } catch(err) {
      await dispatch(getOpeningCeremonyError())
    }
  }

}

export default handleOpeningSession;