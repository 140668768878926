import React from 'react';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import '../../../../../features/PanelManagement/styles/JudgesList.scss';


const JudgeTile = props => {
  const { data, isAssigned} = props;
  const { t } = useTranslation()


  return (
    <div className='judges-list__tile'>
      <Avatar size={30} icon={<UserOutlined />}  />
      <div className='judges-list__tile-name'>
        <p className='paragraph--medium paragraph--charcoal'>{data.fullName}</p>
        {isAssigned ? (
          <p className='paragraph--small paragraph--blue'>{data.panels.map(p => p.panelName).join(', ')}</p>
        ) : (
          <p className='paragraph--red paragraph--small'>{t('unassignedLabel')}</p>
        )}
      </div>
    </div>
  );
};

JudgeTile.propTypes = {
  data: PropTypes.object,
  isAssigned: PropTypes.bool

};

JudgeTile.defaultProps ={
  data: {},
  isAssigned: false
}

export default JudgeTile;