import * as types from './leaderboardTypes';
import * as leaderboardService from 'utils/services/leaderboard';

const getEventLeaderboardBegin = (payload) => {
  return {
    type: types.GET_EVENT_LEADERBOARD_BEGIN,
    payload
  };
};

const getEventLeaderboardError = () => {
  return {
    type: types.GET_EVENT_LEADERBOARD_FAIL
  };
};

const getEventLeaderboardSuccess = (payload) => {
  return {
    type: types.GET_EVENT_LEADERBOARD_SUCCESS,
    payload
  };
};

export const fetchEventLeaderboard = (leaderboardId, getPublishStatus = true) => async (dispatch, getState) => {
  try {
    const { eventLeaderboard } = getState();

    if (eventLeaderboard.leaderboardId && eventLeaderboard.leaderboardId === leaderboardId) {
      return;
    }
    dispatch(getEventLeaderboardBegin(leaderboardId));
    let data
    if(getPublishStatus) data = await leaderboardService.getLeaderboardById(leaderboardId);
    const rankings = await leaderboardService.getLeaderboardDataById(leaderboardId);
    
    if(getPublishStatus) dispatch(publishEventLeaderboardSuccess(data));
    dispatch(getEventLeaderboardSuccess(rankings));
  } catch (e) {
    dispatch(getEventLeaderboardError());
  }
};

const publishEventLeaderboardBegin = () => {
  return {
    type: types.PUBLISH_EVENT_LEADERBOARD_BEGIN
  };
};

const publishEventLeaderboardError = () => {
  return {
    type: types.PUBLISH_EVENT_LEADERBOARD_FAIL
  };
};

const publishEventLeaderboardSuccess = (payload) => {
  return {
    type: types.PUBLISH_EVENT_LEADERBOARD_SUCCESS,
    payload
  };
};

export const publishEventLeaderboard = (eventId, leaderboardId, unpublish) => async (dispatch) => {
  try {
    dispatch(publishEventLeaderboardBegin());
    const leaderboard = await leaderboardService.toggleLeaderboardPublishStatus(eventId, leaderboardId, unpublish);
    dispatch(publishEventLeaderboardSuccess(leaderboard));
  } catch (e) {
    dispatch(publishEventLeaderboardError());
  }
};
