import { Row, Col, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Container from 'shared/components/Container';
import CardTile from 'shared/components/CardsList/CardTile';
import ConfirmationModal from 'shared/components/ConfirmModal';
import Checkbox from 'shared/components/Checkbox';
import EditTeamInfoForm from './EditTeamInfoForm';
import EditCoachModal from '../EditCoachModal';
import AddModal from 'shared/components/Modal';
import programLogos from 'shared/constants/programLogos';
import FirstText from 'shared/components/FirstText';
import { COACH_ID } from 'shared/constants/eventRoleTypes';
import {
  FLL_DISCOVER_ID,
  FLL_CHALLENGE_ID,
  FLL_EXPLORE_ID,
  FTC_ID
} from 'shared/constants/programIds';
import Team from 'models/Team';
import { getUsersBy } from 'utils/services/users';
import { formatUserResData } from 'utils/services/users/model';
import {
  handleRemoveCoachEventAssignment,
  handleReinviteCoachUser,
  handleDeleteEventTeam,
  handleUpdateCoachInfo
} from 'features/EventContainer/redux/actions/teams';
import '../../../styles/TeamsContainer/EditEventTeamModal.scss';
import AddLink from 'shared/components/TopBar/AddLink';
import CoachAddForm from '../TeamsCoachesModal/CoachAddForm';
import AddList from '../TeamsCoachesModal/ManualView/AddList';
import { handleGetEventTeams, handleResetAddCoachTeamModal } from 'features/EventContainer/redux/actions/teams';

const INITIAL_COACHES_STATE = {
  error: false,
  loading: false,
  eventTeamCoaches: []
};

const EditTeamModalContent = props => {
  const {
    programId,
    teamId,
    teamsData,
    teamsIncluded,
    handleReinvite,
    handleDeleteCoach,
    isRemovingCoach,
    isUpdatingCoach,
    isDeletingTeam,
    onCancel,
    showDeleteOption,
    onCoachDeletedCallback,
    resetAddCoachOnly
  } = props;
  const [coachesState, setCoachesState] = useState(INITIAL_COACHES_STATE);
  const [selectedCoachId, setSelectedCoachId] = useState(null)
  const [teamCoachesList, setTeamCoachesList] = useState([]);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [deleteChecked, setDeleteChecked] = useState(false);
  const [deleteConfirmModalVisible, setDeleteConfirmModalVisiable] = useState(false);
  const [editTeamModalVisible, setEditTeamModalVisible] = useState(false);
  const [editCoachModalVisible, setEditCoachModalVisible] = useState(false);
  const [selectedCoachUser, setSelectedCoachUser] = useState({});
  const [addCoachModalView, setAddCoachModalView] = useState(false);
  const { t } = useTranslation()
  const { id: eventId } = useParams();

  let teamLocation = '';
  let teamTitle = '';

  const selectedTeam = teamsData.find(team => team.id === teamId);
  const selectedTeamDetails = selectedTeam ? new Team(selectedTeam, teamsIncluded) : null;

  const fetchEventAssignments = async () => {
    setCoachesState(prevState => ({ ...prevState, loading: true, error: false }))
    try {
      const filter = {
        'event_assignments.role.id': `eq:${COACH_ID}`,
        'event_assignments.event.teams.id': `eq:${teamId}`,
        'event_assignments.event.id': `eq:${eventId}`,
      }
      const { data } = await getUsersBy(filter);

      const coachesAssignedForEvent = data.map(d => {
        const coachUser = formatUserResData(d)
        return {
          ...coachUser,
          handleReinviteClick: () => { handleReinvite(coachUser.id) }
        }
      });

      setTeamCoachesList(coachesAssignedForEvent)
      setCoachesState(prevState => {
        return {
          ...prevState,
          loading: false,
          error: false
        }
      })
    } catch (err) {
      throw err;
    }
  }

  useEffect(() => {
    if (teamId) {
      fetchEventAssignments()
    }
  }, [teamId])

  if (selectedTeamDetails) {
    teamLocation = selectedTeamDetails.getTeamLocation();
    const { teamName, teamNumber } = selectedTeamDetails;
    teamTitle = (teamName && teamNumber) ? `${teamNumber} - ${teamName}` : teamName
  }

  const { color } = programLogos[programId];
  const programNameMap = {
    [FLL_CHALLENGE_ID]: 'Lego League Challenge',
    [FLL_DISCOVER_ID]: 'Lego League Discover',
    [FLL_EXPLORE_ID]: 'Lego League Explore',
    [FTC_ID]: 'Tech Challenge'
  }

  const handleDeleteClick = () => {
    setConfirmationModalVisible(true)
  }

  const successCallback = () => {
    const updatedCoachesList = teamCoachesList.filter(c => c.id !== selectedCoachId);
    if (updatedCoachesList.length === 0) {
      onCoachDeletedCallback && onCoachDeletedCallback()
    }
    setTeamCoachesList(updatedCoachesList);
    setConfirmationModalVisible(false);
    setSelectedCoachId(null)
  }

  const successCallbackOnCoachAdded = ()=>{
    fetchEventAssignments();
    setAddCoachModalView(false);
    resetAddCoachOnly(false);
  }

  const errorCallBack = () => {
    setConfirmationModalVisible(false);
    setSelectedCoachId(null)
  }

  const removeCoachCallback = () => {
    const updatedCoachesList = teamCoachesList.filter(c => c.id !== selectedCoachId);
    setTeamCoachesList(updatedCoachesList)
    setConfirmationModalVisible(false);
    setSelectedCoachId(null)
  }

  const removeCoachFromEvent = async () => {
    const args = {
      eventId,
      userId: selectedCoachId,
      teamId
    }
    await handleDeleteCoach(args, successCallback, errorCallBack)
  }

  const onCheckedChanged = (e) => {
    setDeleteChecked(e.target.checked)
  }

  const closeEditModal = () => {
    setEditTeamModalVisible(false);
  };

  const handleDeleteTeam = async () => {
    // if (teamCoachesList.length) {
      await props.handleDeleteEventTeam({
        eventId,
        coachesId: teamCoachesList.map(({ id }) => id),
        teamId
      });
    // }
    setDeleteConfirmModalVisiable(false);
    onCoachDeletedCallback && onCoachDeletedCallback();
    onCancel && onCancel();
  };

  const closeEditCoach = () => {
    setSelectedCoachUser({});
    setEditCoachModalVisible(false);
  };

  const onEditCoach = (c) => {
    setSelectedCoachUser(c);
    setEditCoachModalVisible(true);
  };

  const updateCoach = async (id, values) => {
    const updatedCoaches = teamCoachesList.map((coach) => {
      return coach.id === id ? { ...coach, ...values } : coach;
    });
    const userPayload = {
      'first_name': values.firstName,
      'last_name': values.lastName,
      'mobile_phone': values.phone
    };
    await props.handleUpdateCoachInfo(id, userPayload);
    setTeamCoachesList(updatedCoaches);
    closeEditCoach();
  };

  if (coachesState.error) {
    return <p>Error</p>
  }

  const defaultTeamForAddingCoach = [{type: 'team', id: teamId}];

  return (
    <Container type='small'>
      <ConfirmationModal
        visible={confirmationModalVisible}
        onCancel={() => {
          setConfirmationModalVisible(false);
          setSelectedCoachId(null)
        }}
        onSubmit={removeCoachFromEvent}
        title='Remove Coach?'
        description='Are you sure you want to remove this coach from this team for this event?'
        loading={isRemovingCoach}
      />
      <ConfirmationModal
        loading={isDeletingTeam}
        visible={deleteConfirmModalVisible}
        onCancel={() => {
          setDeleteConfirmModalVisiable(false);
        }}
        onSubmit={handleDeleteTeam}
        title='Remove Team?'
        description='This can not be undone.'
      />
      <AddModal
        noTitle
        visible={editTeamModalVisible}
        onCancel={closeEditModal}
        maxWidth={940}
        iconColor='#9A989A'
        wrapClassName='edit-team-modal'>
        <EditTeamInfoForm
          data={selectedTeamDetails}
          onCancel={closeEditModal}
          refreshTeamData={onCoachDeletedCallback}
        />
      </AddModal>
      <AddModal
        noTitle
        visible={addCoachModalView}
        onCancel={() => { setAddCoachModalView(false); props.handleResetAddCoachTeamModal(); }}
        maxWidth={940}
        iconColor='#9A989A'
        className="max-width-940"
        wrapClassName='edit-team-modal'>
        <Row type='flex' gutter={20}>
          <Col span={12}>
            <CoachAddForm 
              defaultTeams={defaultTeamForAddingCoach}
              onAddedSucceed={successCallbackOnCoachAdded}
            ></CoachAddForm>
          </Col>
          <Col span={12}>
            <AddList defaultTeams={defaultTeamForAddingCoach} onAddedSucceed={successCallbackOnCoachAdded}/>
          </Col>
        </Row>
      </AddModal>
      <EditCoachModal
        visible={editCoachModalVisible}
        onCancel={closeEditCoach}
        updateCoach={updateCoach}
        selectedCoach={selectedCoachUser}
        isUpdatingCoach={isUpdatingCoach}
      />
      <div className={`edit-event-team-content edit-event-team-content--${color}`}>
        <div className="edit-event-team-content__header">
          <div className="edit-event-team-content__header_inner">
            <div className='m-b-8'>
              <span className='paragraph--default paragraph--bold paragraph--charcoal'>
                <FirstText />
                <span className='p-l-4'>{programNameMap[programId]}</span>
              </span>
            </div>
            <p className='heading--primary h--bold m-b-8'>{teamTitle}</p>
            <p className='paragraph--default paragraph--bold paragraph--charcoal'>{teamLocation}</p>
          </div>
          <Button type="link" className="team-info-edit-button" onClick={() => setEditTeamModalVisible(true)}>{t('editLabel')}</Button>
        </div>
        <div className='m-t-36'>
          <div className='add-coach-content'>
            <p className='paragraph--default paragraph--normal paragraph--charcoal m-r-20'>{t('coachesLabel')}</p>
            <AddLink addClick={() => { setAddCoachModalView(true) }} color='primaryBlue' customText={'Add'} />
          </div>
          <Row type='flex' gutter={20} className='m-t-12'>
            {
              teamCoachesList.map(c => (
                <Col xs={24} sm={12} key={c.email}>
                  <CardTile
                    {...c}
                    showInvitationLink={true}
                    closePosition="top-right"
                    showDeleteBtn
                    handleDelete={
                      () => {
                        handleDeleteClick(c.userId);
                        setSelectedCoachId(c.userId)
                      }
                    }
                    renderInfo={() => (
                      <div className='volunteer-tile__roles'>
                        <p className='paragraph--xsmall paragraph--darkGray'>{t('coachLabel')}</p>
                        {c.phone ? (
                          <p className='paragraph--xsmall paragraph--darkGray'>{c.phone}</p>
                        ) : null}
                        <Button type='link' onClick={() => onEditCoach(c)}>{t('editLabel')}</Button>
                      </div>
                    )}
                  />
                </Col>
              ))
            }
          </Row>
        </div>
      </div>
      {showDeleteOption ? <div className="edit-event-delete-option-section">
        <div className="title">Remove Team:</div>
        <div className="delete-checkbox-wrapper">
          <Checkbox
            checked={deleteChecked}
            onChange={onCheckedChanged}
          >
            <div className="checkbox-text">Check to confirm remove.</div>
          </Checkbox>
        </div>
        <div className="button-wrapper">
          <Button
            type="primary"
            ghost
            loading={isDeletingTeam}
            disabled={!deleteChecked}
            onClick={() => setDeleteConfirmModalVisiable(true)}
          >
            Remove
          </Button>
        </div>
      </div> : null}
    </Container>
  );
};

EditTeamModalContent.propTypes = {
  programId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  teamsData: PropTypes.array,
  teamsIncluded: PropTypes.array,
  handleReinvite: PropTypes.func.isRequired,
  handleDeleteCoach: PropTypes.func.isRequired,
  isRemovingCoach: PropTypes.bool.isRequired,
  showDeleteOption: PropTypes.bool
};

EditTeamModalContent.defaultProps = {
  teamsData: [],
  teamsIncluded: [],
  showDeleteOption: false
}

const mapStateToProps = ({ event }) => {
  const {
    eventDetails: { eventProgramId },
    eventTeams: { eventTeamsList, eventTeamsIncludedResponse, isRemovingCoach, isUpdatingCoach, isDeletingTeam },
  } = event;
  return {
    programId: eventProgramId,
    teamsData: eventTeamsList,
    teamsIncluded: eventTeamsIncludedResponse,
    isRemovingCoach,
    isUpdatingCoach,
    isDeletingTeam
  }
}

export default connect(
  mapStateToProps,
  {
    handleReinvite: handleReinviteCoachUser,
    handleDeleteCoach: handleRemoveCoachEventAssignment,
    handleDeleteEventTeam,
    handleUpdateCoachInfo,
    handleResetAddCoachTeamModal,
    resetAddCoachOnly: handleResetAddCoachTeamModal,
  }
)(EditTeamModalContent);
