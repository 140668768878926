import {
  JUDGE_ADVISOR_ID,
  JUDGE_ID,
  COACH_ID,
} from 'shared/constants/eventRoleTypes';


  export default [
    {
      description: 'engineeringNotebookLabel',
      default: 'https://drive.google.com/file/d/1ubS6kl2Cy1hkK7ZLx7ha4yXNmmhEYD05/view?usp=sharing',
      "tr": "",
      "ja": "https://drive.google.com/drive/u/1/folders/1o9_ccqXyE32SX4CrmfZRrRHWfgoZkY01",
      "he": "",
      "de": "https://firstlegoleague.nl/images/2021/discover/FLL21_Discover_Techneutenschrift_def.pdf",
      roles: [JUDGE_ID, JUDGE_ADVISOR_ID, COACH_ID]
    },
    {
      description: 'Team Meeting Guide',
      default: 'https://drive.google.com/file/d/16H30yup-RjQG27WLt0_AIWnU1F95th1Z/view?usp=sharing',
      "tr": "",
      "ja": "https://drive.google.com/drive/u/1/folders/1Xz43U8nJHmfNd4e0VlX5HWgMBJ-D1I5c",
      "he": "",
      "de": "https://firstlegoleague.nl/images/2021/discover/FLL_Discover_Teambijeenkomstengids-def.pdf",
      roles: [JUDGE_ID, JUDGE_ADVISOR_ID, COACH_ID]
    },

  ]