import { Collapse } from 'antd';
import React, { useEffect, useState } from 'react';
import MatchesTable from "./MatchesTable"
import WhiteArrowDown from 'shared/components/Icon/icons/WhiteArrowDown';
import WhiteArrowUp from 'shared/components/Icon/icons/WhiteArrowUp';
import EventModal from '../../EventModal';
import { useTranslation } from 'react-i18next';
import { handleInPersonMatches } from 'features/EventContainer/redux/actions/scheduleActions/scheduleDetails';
import { connect } from 'react-redux';
import _ from 'lodash';
import BlockLoading from 'shared/components/Spinner/BlockLoading';
import { debug } from 'loglevel';
const DEFAULT_IN_PERSON_TEAMS_SIZE = 6;
const MAX_IN_PERSON_TEAMS_SIZE = 500;
const Matches = (props)=>{
  const [extraClass, setExtraClass] = useState('');
  const {t} = useTranslation();
  const {scoringSessions, getInPersonMatches, eventScheduleId, time_zone, isLoadingScoringSection} = props;
  const [matchesData ,setMatchesData] = useState([]);
  const [teamsCount, setTeamsCount] = useState(0);
  const [currentMatchesCount, setCurrentMatchesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevPage, setPrevPage] = useState(1);
  const [currentSize, setCurrentSize] = useState(DEFAULT_IN_PERSON_TEAMS_SIZE);
  useEffect(()=>{
    const scoringSessionLength = (scoringSessions||[]).length;
    if(scoringSessionLength > 0 && eventScheduleId){
      const page = {size: currentSize, number: currentPage}
      getInPersonMatches(page, setMatchesData, setTeamsCount, setCurrentMatchesCount);
    }
  }, [scoringSessions, eventScheduleId, currentSize, currentPage])

  const updateMatchTime = (res)=>{
    const tempMatches = _.cloneDeep(matchesData);
    const updatedMatches = tempMatches.map(match=>{
      const {id, attributes} = res||{};
      const {start_time, end_time} = attributes||{};
      if(match.sessionId === id){
        if(start_time) match.startTime = start_time;
        if(end_time) match.endTime = end_time;
      }
      return match;
    })
    setMatchesData(updatedMatches);
  }

  useEffect(()=>{
    const resetClass = ()=>{
      var full_screen_element = document.fullscreenElement;
      if(!full_screen_element) setExtraClass('');
    }

    document.addEventListener("fullscreenchange",resetClass);
    return ()=>document.removeEventListener("fullscreenchange",resetClass)
  })

  const handleExpandIcon = (panelProps) => {
    const {isActive} = panelProps;
    if (isActive) {
      return <div className="arrow-wrapper"><WhiteArrowUp/></div>
    }

    return <div className="arrow-wrapper"><WhiteArrowDown /></div>
  }

  const Header = (
    <div className="matches-wrapper-header">
      <span className="title">{`${teamsCount} ${t('teamsLabel')}`}</span>
    </div>
  )

  const enterFullScreen = ()=>{
    setExtraClass(extraClass ? '' : 'fullscreen');
  }

  const exitFullScreen = ()=>{
    setExtraClass('');
  }
  
  const toggleCurrentPageSize = ()=>{
    const showingAll = currentSize > DEFAULT_IN_PERSON_TEAMS_SIZE;
    const size = showingAll ? DEFAULT_IN_PERSON_TEAMS_SIZE : MAX_IN_PERSON_TEAMS_SIZE;
    if(showingAll){
      setCurrentPage(prevPage);
    }else{
      setPrevPage(currentPage);
      setCurrentPage(1)
    }
    setCurrentSize(size);
  }

  const ShowTotalBlock = (total, range)=>{
    const hasMore = total > DEFAULT_IN_PERSON_TEAMS_SIZE;
    const showMoreTitle = currentSize > DEFAULT_IN_PERSON_TEAMS_SIZE ? "Show Less" : "Show All";
    return (
      <div className="showing-total-wrapper">
        <div className="text">{`Showing ${range[0]} - ${range[1]} of ${total}`}</div>
        <div className="divider">|</div>
        {hasMore ? <a onClick={toggleCurrentPageSize}>{showMoreTitle}</a> : null}
      </div>
    )
  }

  let groupd_data = [];
  const groupedMatches = _.groupBy(matchesData, 'teamDetail');
  const teamKeys = Object.keys(groupedMatches);
  teamKeys.forEach(key=>{
    const matches = groupedMatches[key];
    const sortedMatches = _.orderBy(matches, ["matchTypeName", "matchNumber"], ["desc", "asc"]);
    const match = sortedMatches[0];
    const groupedMatch = {
      key: match.matchTeamId,
      teamKey: match.teamDetail,
      data: sortedMatches
    };
    groupd_data.push(groupedMatch)
  })

   const sortedGroupedMatches = _.orderBy(groupd_data, ["teamKey"]);

   const loading = currentMatchesCount !== matchesData.length;

  if(extraClass){
    return (
      <EventModal width={"95%"} visible={!!extraClass} onCancel={exitFullScreen} extraClass={`matches-wrapper ${extraClass}`}>
        {Header}
        <MatchesTable 
          enterFullScreen={enterFullScreen} 
          fullscreen={extraClass}
          matchesData={sortedGroupedMatches} 
          time_zone={time_zone} 
          updateMatchTime={updateMatchTime}
          loading={loading}
          pagination={{
            pageSize:currentSize,
            current:currentPage,
            onChange:(page)=>setCurrentPage(page),
            total:teamsCount,
            showTotal: ShowTotalBlock
          }}/>
      </EventModal>
    )
  }
  return (
    <div className="matches-outter-wrapper">
      <h3 className="h-3 h--400">{t('matchesLabel')}</h3>
      <div className={`matches-wrapper ${extraClass}`}>
        {isLoadingScoringSection ?
          <BlockLoading loading={true} text={"Loading In-Person matches"}/>
          :
          <Collapse expandIcon={handleExpandIcon} expandIconPosition={"right"} bordered={false} defaultActiveKey={['1']}>
          <Collapse.Panel header={Header} key="1">
            <MatchesTable 
              enterFullScreen={enterFullScreen} 
              fullscreen={extraClass} 
              matchesData={sortedGroupedMatches} 
              time_zone={time_zone} 
              updateMatchTime={updateMatchTime}
              pagination={{
                pageSize:currentSize,
                current:currentPage,
                onChange:(page)=>setCurrentPage(page),
                total:teamsCount,
                showTotal: ShowTotalBlock
              }}
              loading={loading}
              />
          </Collapse.Panel>
        </Collapse>}
      </div>
    </div>
  )
}

const mapStateToProps = (state)=>{
  const {event: {eventDetails: {eventScheduleId, time_zone},eventSchedule: {sessions: {scoringSessions}, isLoadingScoringSection}}}= state;
  return {scoringSessions, eventScheduleId, time_zone, isLoadingScoringSection}
}

const mapDispatchToProps = {
  getInPersonMatches: handleInPersonMatches
}

export default connect(mapStateToProps, mapDispatchToProps)(Matches);