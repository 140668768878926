import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import i18n from 'utils/i18n'
import { UserOutlined } from '@ant-design/icons';
import { Alert, Avatar, Badge, Dropdown, Menu, Modal } from 'antd';
import Icon from '@ant-design/icons';
import PageLoading from 'shared/components/Spinner/PageLoading';
import { resetAuth } from 'features/Authentication/redux/authActions';
import './styles/header.scss';
import Select from 'shared/components/Select';
import { useTranslation } from 'react-i18next';
import Auth from '../../Auth';
import {Form} from 'antd';
import detectLanguage from 'utils/detectLanguage';

import localizationLanguages from 'shared/constants/localizationLanguages';
import EditProfileModal from 'features/Header/components/EditProfileModal';
import { PublicLeaderBoardType, PublicScoresheetType } from 'shared/constants/routerTypes';
import { ReportConcernLink } from 'shared/constants/statisticLinkString';
import EventProgramLogos from 'shared/components/EventProgramLogos';
import { useConfig } from '../../configuration/useConfig';
import { FLL_CHALLENGE_ID } from 'shared/constants/programIds';

const { Option } = Select
const FormItem = Form.Item;

const STAGING_MESSAGE = `CAUTION: Due to the progress of the event hub, any email invitation you send out is now being sent to those users in the staging environment. Please note coach and volunteer email addresses you are adding into the remote hub will be sent to those email addresses.`

const Header = (props) => {
  const { config } = useConfig();
  const [modalVisible, setModalVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const { t } = useTranslation();
  const { firstName, lastName, picture, appLoading, selectedRolePath, authorized, eventProgramId } = props;
  const [form] = Form.useForm();

  const handleSignOut = (e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    props.resetAuth();
    Auth.GetInstance().signOut();
  };

  const handleSelect = (value) => {
    i18n.changeLanguage(value)
  }

  const currentLocation = useLocation();
  const {pathname} = (currentLocation||{})
  const isPublisScoresheet = pathname === PublicScoresheetType;
  const isPublicLeaderboard = matchPath(pathname, {path: PublicLeaderBoardType, exact: true, strict: true})

  const LogoLinkDisabled = isPublisScoresheet;

  const selectedLanguage = localStorage.getItem('i18nextLng');

  const xlang = detectLanguage(selectedLanguage);

  const menu = (
    <Menu>
      <Menu.Item className='header__menu-item'>
        <button type='button' className='header__profile-btn' onClick={() => setModalVisible(true)}>
          <Icon type="user" className='p-r-4' />
          {t('myProfileLabel')}
        </button>
      </Menu.Item>
      <Menu.Item>
        <a href="/" onClick={handleSignOut} className='header__menu-link'>
          <Icon type="logout" className='p-r-4' />
          Sign out
          {' '}
          {firstName}
          {' '}
          {lastName}
        </a>
      </Menu.Item>
    </Menu>
  );

  const sortList = (a, b) => {
    const textA = a.text.toUpperCase();
    const textB = b.text.toUpperCase();
    let comparison = 0;
    if (textA > textB) {
      comparison = 1;
    } else if (textA < textB) {
      comparison = -1;
    }
    return comparison;

  }

  const toggleMenu = () => {
    setMenuVisible(visible => !visible)
  }

  const MobileOverlay = (
    <div className={`mobile-only-menu-content ${menuVisible ? 'visible' : 'invisible'}`}>
      <Icon type="close" style={{ fontSize: '24px' }} onClick={toggleMenu} className="icon-close" />
      <a className='header__link' href='/volunteer' rel="noreferrer">Dashboard</a>
      <a className='header__link' href={ReportConcernLink} target='_blank' rel="noreferrer">{t('reportConcernLabel')}</a>
      {authorized ? <span className='header__link' onClick={() => setModalVisible(true)}>
        <Badge count={0} offset={[-4, 8]}>
          <Avatar alt='Profile Avatar' src={picture} size={48} icon={<UserOutlined />} />
        </Badge>
        {t('myProfileLabel')}
      </span> : null}
      {authorized ? <a className='header__link last' href="/" onClick={handleSignOut}>
        Sign out
      </a> : null}
    </div>
  )

  const MobileMenu = () => {
    if(isPublicLeaderboard) return null;
    return (
      <div className="mobile-only-menu-button">
        <Icon type="menu" style={{ fontSize: "20px" }} onClick={toggleMenu} />
        {MobileOverlay}
      </div>
    )
  }

  const unauthorizedClass = authorized ? '' : 'unauthorized-class';
  const wrapperName = isPublicLeaderboard ? 'public-leaderboard' : '';

  const LeaderboardTitle = isPublicLeaderboard ? <div className="leaderboard-title">Leaderboard</div> : null
  return (
    <div className={`header ${wrapperName}`}>
      <EditProfileModal
        onCancel={() => setModalVisible(false)}
        visible={modalVisible}
      />
      <div className='header__wrapper'>
        {MobileMenu()}
        <Link to={`/${selectedRolePath}`} href={`/${selectedRolePath}`} className='header__logo-link' disabled={LogoLinkDisabled}>
          <img src='/First-Event-Hub-logo.png' alt='FIRST logo' className='header__logo' />
        </Link>
        {LeaderboardTitle}
        {!isPublicLeaderboard ? <div className={`header__content ${unauthorizedClass}`}>
          <a className='header__link' href={ReportConcernLink} target='_blank' rel="noreferrer">{t('reportConcernLabel')}</a>
          <Form hideRequiredMark form={form}>
            <FormItem>
              <Select listItemHeight={5} listHeight={150} virtual={false} tabIndex={0} theme='gray' onChange={handleSelect} additionalClassNames='header__local' allowsearch defaultValue={xlang} value={xlang}>
                {localizationLanguages.sort(sortList).map(l => (
                  <Option key={l.value} value={l.value}>{l.text}</Option>
                ))}
              </Select>
            </FormItem>
          </Form>
          {authorized && !isPublicLeaderboard ? <Dropdown tabIndex={0} overlay={menu}>
            <span className='header__avatar'>
              <Badge count={0} offset={[-4, 8]}>
                <Avatar alt='Profile Avatar' src={picture} size={48} icon={<UserOutlined />} />
              </Badge>
            </span>
          </Dropdown> : null}
        </div> : <EventProgramLogos programId={eventProgramId}/>}
      </div>
          {/* {config.REACT_APP_IS_STAGING_ENV && (<Alert style={{ fontSize: '16px', marginTop: '12px' }} message={STAGING_MESSAGE} type="warning" />)} */}
      <PageLoading loading={appLoading} />
    </div>
  );
};

const mapStateToProps = ({ auth, dashboard, event: {eventDetails: {eventProgramId}} }) => {
  const { idTokenPayload = {}, userInfo = {}, authorized } = auth;
  const { picture, given_name, family_name } = idTokenPayload;
  const { firstName, lastName } = userInfo;
  const { selectedRolePath } = dashboard;
  return {
    picture,
    firstName: firstName || given_name,
    lastName: lastName || family_name,
    selectedRolePath,
    authorized,
    eventProgramId
  };
};

const mapStateToDispatch = {
  resetAuth
};

Header.propTypes = {
  selectedRolePath: PropTypes.string
}

Header.defaultProps = {
  selectedRolePath: ''
}
export default connect(mapStateToProps, mapStateToDispatch)(Header);
