import React from 'react';
import PropTypes from 'prop-types';
import {Select as AntSelect} from 'antd';
import './Dropdown.scss';
import Icon from 'shared/components/Icon'

const Select = props => {
  // eslint-disable-next-line no-unused-vars
  const {additionalClassNames, children, size, placeholder,loading, ..._props} = props;
  const className = `dropdown ${additionalClassNames}`;
  const suffixIcon = loading ? undefined : <Icon type='downArrowBlue' />
  return (
    <AntSelect
      className={className}
      size={size}
      optionFilterProp='children'
      placeholder={placeholder}
      loading={loading}
      suffixIcon={suffixIcon}
      {..._props}
    >
      {children}
    </AntSelect>
  );
};

Select.Option = AntSelect.Option;

Select.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  _props: PropTypes.object,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  additionalClassNames: PropTypes.string
};

Select.defaultProps = {
  _props:{},
  size: 'large',
  placeholder: undefined,
  additionalClassNames: ''
}


export default Select;