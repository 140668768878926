const ip3Section = {
  id: 'IP3',
  label: 'ipv3Label',
  title: 'createLabel',
  description: 'ChallengeRubricsIdeasCreateIntroLabel',
  fields: [
    {
      legendTitle: 'innovationLabel',
      id: 'innovation_project_3_create_innovation',
      isCoreValue: true,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricsInnovation1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricsInnovation2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricsInnovation3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricsInnovation4Label',
      }]
    },
    {
      legendTitle: 'ChallengeRubricsModelDrawingLabel',
      id: 'innovation_project_3_create_model_drawing',
      isCoreValue: false,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricsModelDrawing1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricsModelDrawing2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricsModelDrawing3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricsModelDrawing4Label',
      }]
    },
  ]
}

export default ip3Section;