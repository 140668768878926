import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

import { Form, Button} from 'antd';
import Input from 'shared/components/Input';
import Dropdown from 'shared/components/FormDropdown';
import AutoComplete from 'shared/components/FormAutoComplete';
import {
  handleCreateNewTeam
} from 'features/EventContainer/redux/actions/teams';
import {
  fetchRegionsBy,
  fetchCountries
} from 'features/AdminDashboard/redux/actions/programsActions';
import '../../../styles/CreateNewTeamForm.scss';

const { Option } = Dropdown;
const FormItem = Form.Item;

const CreateNewTeamForm = (props) => {
  const {
    eventProgramId,
    regions,
    countries,
    saveNewTeam,
    onCancel,
    isFetchingCountries,
    isFetchingRegions
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const isTeamNameTouched = form.isFieldTouched('teamName');
  const isTeamNumberTouched = Boolean(form.getFieldValue('teamNumber'));
  const isCountryTouched = Boolean(form.getFieldValue('country'));
  const isRegionTouched = Boolean(form.getFieldValue('region'));
  const isFormFilled = isTeamNameTouched && isTeamNumberTouched && isRegionTouched;

  const handleSearchRegions = (input) => {
    if (!input) return;
    props.fetchRegionsBy(eventProgramId, input);
  };

  const createNewTeam = (e) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }

    form.validateFields().then(async (values, err) => {
      if (err) {
        return err;
      }
      saveNewTeam(values, onCancel)
    });
  }
  
  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  }

  return (
    <div className="create-team-container">
      <div className={`create-team ${isFormFilled ? 'create-team--complete' : 'create-team--incomplete'}`}>
        <h3 className="create-team__heading">{t('newTeamLabel')}:</h3>
        <Form hideRequiredMark onFinish={createNewTeam} onFinishFailed={onFinishFailed} name='CreateNewTeamForm' form={form} >
          <FormItem name='teamName' label={<span className={isTeamNameTouched ? 'form__label--visible' : 'form__label--invisible'}>{t('teamNameLabel')}</span>} rules={[{ required: true, message: 'Enter Team Name.' }]}>
            <Input size='large' placeholder={t('teamNameLabel')} />
          </FormItem>
          <FormItem name='teamNumber' label={<span className={isTeamNumberTouched ? 'form__label--visible' : 'form__label--invisible'}>Team Number</span>} rules={[{ required: true, message: 'Enter Team Number.' }]}>
            <Input size='large' placeholder={t('teamNumberLabel')} />
          </FormItem>
          <FormItem name='country' label={<span className={isCountryTouched ? 'form__label--visible' : 'form__label--invisible'}>Country</span>} rules={[{ required: true, message: 'Select Country.' }]}>
              <Dropdown
                allowClear
                showSearch
                filterOption={false}
                placeholder='Country'
                onSearch={props.fetchCountries}
                popUpContainerElementId='country-autocomplete'
                loading={isFetchingCountries}
              >
                {countries.map(c => (
                  <Option key={c.id} value={c.id} text={c.name}>
                    {c.name}
                  </Option>
                ))}
              </Dropdown>
          </FormItem>
          <FormItem name='region' label={<span className={isRegionTouched ? 'form__label--visible' : 'form__label--invisible'}>Region</span>} rules={[{ required: true, message: t('selectRegionLabel') }]}>
              <Dropdown
                allowClear
                showSearch
                filterOption={false}
                placeholder='Region'
                popUpContainerElementId='region-autocomplete'
                onSearch={handleSearchRegions}
                loading={isFetchingRegions}

               
              >
                {regions.map(({ id, name }) => (
                  <Option key={id} value={id} text={name}>{name}</Option>
                ))}
              </Dropdown>
          </FormItem>
        </Form>
      </div>
      <Button disabled={!isFormFilled} type='default' styleType='secondary' size='large' additionalClassNames='btn--180'>{t('CreateNewTeamLabel')}</Button>
    </div>
  );
};

CreateNewTeamForm.propTypes = {
  form: PropTypes.object.isRequired,
  saveNewTeam: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  eventProgramId: PropTypes.string,
  fetchRegionsBy: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func.isRequired,
};

CreateNewTeamForm.defaultProps = {
  onCancel: () => { }
};

const mapDispatchToProps = {
  saveNewTeam: handleCreateNewTeam,
  fetchRegionsBy,
  fetchCountries
};

const mapStateToProps = (state) => {
  const {
    event: {
      eventDetails: {
        eventProgramId
      }
    },
    adminBoard: {
      programs: {
        countries, regions, isFetchingRegions, isFetchingCountries
      }
    }
  } = state;
  return {
    eventProgramId,
    countries,
    regions,
    isFetchingRegions,
    isFetchingCountries
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewTeamForm);
