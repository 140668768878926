import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AutoComplete from 'shared/components/FormAutoComplete';
import BtnPrimary from 'shared/components/Button/BtnPrimary';
import * as teamService from 'utils/services/teams';
import { handleAddTeamToCoach, handleSelectedTeam } from 'features/EventContainer/redux/actions/teams';
import TeamOption from './TeamOption';
//import { handleGetEventTeams, handleResetAddCoachTeamModal } from 'features/EventContainer/redux/actions/teams';
import '../../../styles/TeamAutocomplete.scss';

const { Option } = AutoComplete;

const TeamAutocomplete = (props) => {
  const { t } = useTranslation()
  const [teamResults, setTeamResults] = useState([]);
  const { addTeamToCoach, selectTeam, selectedTeam, onCancel, needToSelect, placeHolder } = props;

  const handleTeamSelect = (e) => {
    let callback;
    if (props.onSelect !== undefined && typeof props.onSelect == 'function' ){
      callback = props.onSelect;
    }
    selectTeam(e, callback);
  };

  const handleSelect = () => {
    addTeamToCoach(needToSelect ? onCancel : () => { });
  };

  const handleCustomerSearch = async (input) => {
    const pageSize = 5;
    const formattedTeams = await teamService.getTeamsLikeName(input, pageSize);
    setTeamResults(formattedTeams);
    
    if (props.onSearch !== undefined && typeof props.onSearch == 'function') {
      props.onSearch(input);
    }
  };

  return (
    <div className="add-team-modal__selection">
      <div className="add-team-modal__select-wrapper">
        <AutoComplete
          placeholder={placeHolder != null ? placeHolder : t('startSearchLabel')}
          style={{ width: '100%' }}
          onSearch={handleCustomerSearch}
          onSelect={(e) => {
            handleTeamSelect(e);
          }}
          filterOption={false}
          popUpContainerElementId='search-teams-autocomplete'
          dataSource={teamResults.map((tr) => (
            <Option key={tr.id} value={tr.id} text={tr.teamName}>
              <TeamOption
                teamName={tr.teamName}
                teamNumber={tr.teamNumber}
                region={tr.regionName}
                country={tr.countryName}
              />
            </Option>
          ))}
        />
      </div>
      {needToSelect && (
        <span className="add-team-modal__btn-wrapper">
          <BtnPrimary additionalClassNames="btn--180" disabled={!selectedTeam} onClick={handleSelect}>
            {t('selectLabel')}
          </BtnPrimary>
        </span>
      )}
    </div>
  );
};

TeamAutocomplete.propTypes = {
  selectTeam: PropTypes.func.isRequired,
  addTeamToCoach: PropTypes.func.isRequired,
  selectedTeam: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  onCancel: PropTypes.func,
  onSelect: PropTypes.func,
  onSearch: PropTypes.func,
  needToSelect: PropTypes.bool,
  placeHolder: PropTypes.string
};

TeamAutocomplete.defaultProps = {
  selectedTeam: undefined,
  onCancel: () => { },
  needToSelect: true,
  placeHolder: null
};

const mapDispatchToProps = {
  selectTeam: handleSelectedTeam,
  addTeamToCoach: handleAddTeamToCoach
  //handleResetAddCoachTeamModal
};

function mapStateToProps(state) {
  const { event: { eventTeams: { selectedTeam } } } = state;
  return {
    selectedTeam
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamAutocomplete);
