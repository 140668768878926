import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../ConfirmModal';

const DeleteEventModal = (props) => {
  const { eventName } = props;
  const { t } = useTranslation();
  const title = t('ScheduleDeleteConfirmSub1Label');
  const description = t('ScheduleDeleteConfirmSub2Label');
  return (
    <ConfirmModal
      title={title}
      subTitle={eventName}
      description={description}
      {...props}
    />
  );
};

export default DeleteEventModal;
