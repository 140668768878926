import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Pagination, { getPageFilter, initialPage } from 'shared/components/Pagination';
import EventTile from 'shared/components/EventTile';
import ListLoading from 'shared/components/Spinner/ListLoading';
import { PARTNER_ADMIN, FIRST_ADMIN } from 'shared/constants/userTypes';
import SearchBar from 'features/AdminDashboard/components/SearchBar';
import TitleAndSort from 'features/AdminDashboard/components/TitleAndSort';
import { fetchEvents, deleteEvent } from 'features/AdminDashboard/redux/actions/eventsActions';
import AddEventModal from 'features/AddEventModal'
import { eventsSortBy } from 'shared/constants/sortOptions';
import DeleteEventModal from 'shared/components/DeleteEventModal';

const EVENTS_PAGE_SIZE = 10;

const EventsContainer = (props) => {
  const {
    userType,
    eventsList,
    count,
    isFetchingEvents,
    isDeletingEvent,
    newEventCreated,
    hasEventDeleted
  } = props;
  const [visible, setVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [current, setCurrentPage] = useState(initialPage);
  const [sortBy, setSortBy] = useState('');
  const [deleteEventInfo, setDeleteEvent] = useState(null);
  const { t } = useTranslation();

  const canAddEvent = userType === PARTNER_ADMIN;
  const canDelete = canAddEvent || userType === FIRST_ADMIN;

  const fetchPageEvents = useCallback((number, nosearch) => {
    const page = getPageFilter(number, EVENTS_PAGE_SIZE);
    props.fetchEvents(page, !nosearch && searchText, sortBy);
    window.scrollTo(0, 0);
  }, [searchText, sortBy]);

  const handleSearch = (nosearch) => {
    setCurrentPage(pre => {
      if (pre === initialPage && searchText) {
        fetchPageEvents(initialPage, nosearch);
      }
      return initialPage;
    });
  };

  useEffect(() => {
    fetchPageEvents(current);
  }, [current, sortBy]);

  useEffect(() => {
    if (newEventCreated || hasEventDeleted) {
      setCurrentPage(pre => {
        if (pre === initialPage) {
          fetchPageEvents(initialPage);
        }
        return initialPage;
      });
    }
  }, [newEventCreated, hasEventDeleted]);

  const addEvent = () => {
    if (canAddEvent) {
      setVisible(true);
    }
  };

  const closeAddEventModal = () => {
    setVisible(false);
  };

  const cancelEventDelete = () => {
    setDeleteEvent(null);
  };

  const handleDeleteEvent = async () => {
    if (deleteEventInfo) {
      const { id } = deleteEventInfo;
      await props.deleteEvent(id);
      setDeleteEvent(null);
    }
  };

  const onSortBySelected = (value) => {
    setSortBy(value);
  };

  return (
    <div className='admin-dashboard'>
      <AddEventModal
        visible={visible}
        onCancel={closeAddEventModal}
      />
      <DeleteEventModal
        visible={canDelete && Boolean(deleteEventInfo)}
        onCancel={cancelEventDelete}
        onSubmit={handleDeleteEvent}
        loading={isDeletingEvent}
        eventName={deleteEventInfo && deleteEventInfo.name}
      />
      <SearchBar
        placeholder='Event Name'
        customText={t('addEventLabel')}
        addClick={addEvent}
        isLinkVisible={canAddEvent}
        searchText={searchText}
        setSearchText={setSearchText}
        handleSearch={handleSearch}
      />
      <div className='admin-dashboard__list'>
        <TitleAndSort title={t('eventsLabel')} fieldOptions={eventsSortBy} onSelect={onSortBySelected} />
        <ListLoading
          loading={isFetchingEvents}
          text={t('loadingEventsLabel')}
        />
        {!isFetchingEvents && (
          <div className='m-b-12'>
            {eventsList.map(e => (
              <EventTile
                event={e}
                key={e.id}
                id={e.id}
                canDelete={canDelete}
                selectDeleteEvent={setDeleteEvent}
              />
            ))}
          </div>
        )}
        <Pagination
          total={count}
          current={current}
          onChange={setCurrentPage}
          pageSize={EVENTS_PAGE_SIZE}
          loading={isFetchingEvents}
        />
      </div>
    </div>
  );
};

EventsContainer.propTypes = {
  isFetchingEvents: PropTypes.bool.isRequired,
  eventsList: PropTypes.array.isRequired,
  fetchEvents: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const {
    auth: { userType },
    adminBoard: { events }
  } = state;
  const {
    count,
    eventsList,
    isLoadingEvents,
    newEventCreated,
    isDeletingEvent,
    hasEventDeleted
  } = events;
  return {
    count,
    eventsList,
    isFetchingEvents: isLoadingEvents,
    isDeletingEvent,
    newEventCreated,
    hasEventDeleted,
    userType
  };
}

export default connect(
  mapStateToProps,
  { fetchEvents, deleteEvent }
)(EventsContainer);
