import React from 'react';

export default ({fill = "#52C41A", size = '24px'})=>{
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
        <title>1A4B3A06-A462-402A-87CC-A384E86059D2</title>
        <g id="Events" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Dashboard---Add-Event---PDP-Admin---V2---Selected" transform="translate(-800.000000, -208.000000)" fill={fill}>
                <g id="Group-19" transform="translate(250.000000, 170.000000)">
                    <g id="Group-15" transform="translate(516.000000, 26.000000)">
                        <g id="Message/_Icon/Success-2" transform="translate(34.000000, 12.000000)">
                            <path d="M16.9054687,16.8375 L9.2296875,9.13828125 L7.228125,12.6421875 C6.99609375,13.0476563 6.4828125,13.1882813 6.07734375,12.95625 C5.671875,12.7242188 5.53125,12.2109375 5.76328125,11.8054688 L8.31328125,7.340625 C8.46796875,7.06875 8.75390625,6.9140625 9.046875,6.9140625 C9.18984375,6.9140625 9.3328125,6.94921875 9.4640625,7.02421875 C9.5390625,7.06875 9.60703125,7.1203125 9.66328125,7.18125 C9.665625,7.18359375 9.6703125,7.18828125 9.67265625,7.190625 L18.0984375,15.6421875 C18.4265625,15.9726563 18.4265625,16.5070313 18.0960937,16.8351563 C17.7679687,17.1679688 17.2359375,17.1679688 16.9054687,16.8375 L16.9054687,16.8375 Z M12,24 C5.371875,24 0,18.628125 0,12 C0,5.371875 5.371875,0 12,0 C18.628125,0 24,5.371875 24,12 C24,18.628125 18.628125,24 12,24 L12,24 Z M19.2914063,4.70859375 C18.3445312,3.76171875 17.240625,3.01640625 16.0125,2.4984375 C14.7421875,1.959375 13.3921875,1.6875 12,1.6875 C10.6078125,1.6875 9.2578125,1.959375 7.9875,2.49609375 C6.759375,3.01640625 5.6578125,3.759375 4.70859375,4.70625 C3.76171875,5.653125 3.01640625,6.75703125 2.4984375,7.98515625 C1.959375,9.2578125 1.6875,10.6078125 1.6875,12 C1.6875,13.3921875 1.959375,14.7421875 2.49609375,16.0125 C3.01640625,17.240625 3.759375,18.3421875 4.70625,19.2914063 C5.653125,20.2382812 6.75703125,20.9835938 7.98515625,21.5015625 C9.2578125,22.040625 10.6078125,22.3125 12,22.3125 C13.3921875,22.3125 14.7421875,22.040625 16.0125,21.5039062 C17.240625,20.9835938 18.3421875,20.240625 19.2914063,19.29375 C20.2382812,18.346875 20.9835938,17.2429688 21.5015625,16.0148438 C22.040625,14.7421875 22.3125,13.3921875 22.3125,12 C22.3125,10.6078125 22.040625,9.2578125 21.5039062,7.9875 C20.9835938,6.759375 20.240625,5.65546875 19.2914063,4.70859375 L19.2914063,4.70859375 Z" id="Shape-Copy-3" transform="translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000) "></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  )
}