import { urlTransform } from 'utils/formHelpers'

const formatPayload = (sessionId, formValues) => {
  const {
    startTime,
    endTime,
    sessionNotes,
    sessionUrl
  } = formValues;

  const payload = {
    data: {
      type: 'session',
      id: sessionId,
      attributes: {
        session_url: urlTransform(sessionUrl) || null,
        start_time: startTime || null,
        end_time: endTime || null,
        notes: sessionNotes || null,
      }
    }
  }

  return payload;

}

export default formatPayload