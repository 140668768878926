export const GET_EVENT_OVERVIEW_SCHEDULE_BEGIN = 'GET_EVENT_OVERVIEW_SCHEDULE_BEGIN';
export const GET_EVENT_OVERVIEW_SCHEDULE_ERROR = 'GET_EVENT_OVERVIEW_SCHEDULE_ERROR';
export const GET_EVENT_OVERVIEW_SCHEDULE_SUCCESS = 'GET_EVENT_OVERVIEW_SCHEDULE_SUCCESS';


export const SET_EVENT_OVERVIEW_DETAILS = 'SET_EVENT_OVERVIEW_DETAILS';

export const SET_EVENT_OVERVIEW_SCHEDULE_ID = 'SET_EVENT_OVERVIEW_SCHEDULE_ID';


export const SET_EVENT_OVERVIEW_PROGRAM_ID = 'SET_EVENT_OVERVIEW_PROGRAM_ID';

export const SET_EVENT_OVERVIEW_COACH_DETAILS = 'SET_EVENT_OVERVIEW_COACH_DETAILS';


export const GET_EVENT_OVERVIEW_DETAILS_BEGIN = 'GET_EVENT_OVERVIEW_DETAILS_BEGIN';
export const GET_EVENT_OVERVIEW_DETAILS_ERROR = 'GET_EVENT_OVERVIEW_DETAILS_ERROR';
export const GET_EVENT_OVERVIEW_DETAILS_SUCCESS = 'GET_EVENT_OVERVIEW_DETAILS_SUCCESS';




export const SET_EVENT_OVERVIEW_SCHEDULE_DETAILS_BEGIN = 'SET_EVENT_OVERVIEW_SCHEDULE_DETAILS_BEGIN'
export const SET_EVENT_OVERVIEW_SCHEDULE_DETAILS_ERROR = 'SET_EVENT_OVERVIEW_SCHEDULE_DETAILS_ERROR'
export const SET_EVENT_OVERVIEW_SCHEDULE_DETAILS_SUCCESS = 'SET_EVENT_OVERVIEW_SCHEDULE_DETAILS_SUCCESS'

export const SET_VOLUNTEER_EVENT_SCHEDULE_ERROR = 'SET_VOLUNTEER_EVENT_SCHEDULE_ERROR';
export const SET_VOLUNTEER_EVENT_SCHEDULE_SUCCESS = 'SET_VOLUNTEER_EVENT_SCHEDULE_SUCCESS';

export const SET_EVENT_OVERVIEW_COACH_TEAMS_LIST = 'SET_EVENT_OVERVIEW_COACH_TEAMS_LIST';

export const SET_EVENT_OVERVIEW_SELECTED_TEAM = 'SET_EVENT_OVERVIEW_SELECTED_TEAM';

export const CHANGE_STATUS_OF_UPLOADING = "CHANGE_STATUS_OF_UPLOADING";

export const SET_IS_EVENT_ADMIN_ROLE = 'SET_IS_EVENT_ADMIN_ROLE';

export const SET_EVENT_OVERVIEW_TIME_ZONE = 'SET_EVENT_OVERVIEW_TIME_ZONE';