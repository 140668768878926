export const formatCoachToUser = (coach) => {
  return {
    firstName: coach.CoachFirstName,
    lastName: coach.CoachLastName,
    phone: coach.CoachPhone,
    email: coach.CoachEmail
  };
};

export const formatNewTeamPayload = (team) => {
  const { number, name, teamNumber, teamName, country, region } = team;
  const relationships = {};

  if (country) {
    relationships.country = {
      data: {
        type: 'country',
        id: country
      }
    };
  }

  if (region) {
    relationships.region = {
      data: {
        type: 'region',
        id: region
      }
    };
  }
  
  return {
    data: {
      type: 'team',
      id: null,
      attributes: {
        team_number: number || teamNumber,
        name: name || teamName,
        short_name: name || teamName
      },
      relationships
    }
  };
};

export const formatTeamInfo = (data) =>{
  const { teamNumber, teamName, country, region, teamId } = data;
  const relationships = {};

  if (country) {
    relationships.country = {
      data: {
        type: 'country',
        id: country
      }
    };
  }

  if (region) {
    relationships.region = {
      data: {
        type: 'region',
        id: region
      }
    };
  }

  return {
    data: {
      type: 'team',
      id: teamId,
      attributes: {
        team_number: teamNumber,
        name: teamName,
        short_name: teamName,
      },
      relationships
    }
  };
}

export const formatTeamResData = (data) => {
  const teamNumber = data.attributes.team_number;
  const teamName = data.attributes.name;
  const shortName = data.attributes.short_name;
  const teamNumberName = teamNumber ? `${teamNumber} - ${teamName}` : `${teamName}`;

  return {
    id: data.id,
    shortName,
    teamNumberName,
    teamNumber,
    teamName
  };
};

export const formatTeamModelRes = (data) => {
  return {
    id: data.teamId,
    teamNumber: data.teamNumber,
    teamName: data.teamName,
    shortName: data.shortName,
    regionName: data.getRegionName(),
    countryName: data.getCountryName()
  };
};


export const formatTeamDetailsRes = (data, includedRes = []) => {
  const teamNumber = data.attributes.team_number;
  const teamName = data.attributes.name;
  const shortName = data.attributes.short_name;
  const teamNumberName = teamNumber ? `${teamNumber} - ${teamName}` : `${teamName}`;
  const regionId = (data.relationships.region && data.relationships.region.data) ? data.relationships.region.data.id : undefined;
  const countryId = (data.relationships.country && data.relationships.country.data) ? data.relationships.country.data.id : undefined;
  let teamLocation = '';
  const selectedRegion = regionId ? includedRes.find(r => r.id === regionId) : undefined;
  const selectedCountry = countryId ? includedRes.find(r => r.id === countryId) : undefined;

  let countryName = '';
  let regionName = '';

  if (selectedCountry) {
    countryName = selectedCountry.attributes.name;
  }
  if (selectedRegion) {
    regionName = selectedRegion.attributes.name;

  }
  if (selectedRegion && selectedCountry) {

    teamLocation = `${regionName}, ${countryName}`
  } else if (regionName || countryName) {
    teamLocation = regionName || countryName;
  }

  return {
    id: data.id,
    shortName,
    teamNumberName,
    teamNumber,
    teamName,
    teamLocation
  };
};