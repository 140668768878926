import React from 'react';
import PropTypes from 'prop-types';
import InviteTile from './ImportInviteTile';
import '../../../../styles/TeamsCoachesImport.scss';

const ImportInviteList = props => {
  const {handleDelete, coaches, hasErrors, withoutCoach} = props;
  
  return (
    <div className={hasErrors ? 'teams-coaches-import__list teams-coaches-import__list--errors' : 'teams-coaches-import__list'}>
      {coaches.map(c => (
        <InviteTile key={c.id} coach={c} handleDelete={handleDelete} withoutCoach={withoutCoach}/>
      ))}
    </div>
  );
};

ImportInviteList.propTypes = {
  coaches: PropTypes.array,
  handleDelete: PropTypes.func,
  hasErrors: PropTypes.bool
};

ImportInviteList.defaultProps = {
  coaches: [],
  handleDelete: () => {},
  hasErrors: false
};

export default ImportInviteList;