const rd1Section = {
  id: 'RD1',
  label: 'rd1Label',
  title: 'identifyLabel',
  description: 'identify2descriptionLabel',
  fields: [
    {
      legendTitle: 'ChallengeRubricsMissionLabel',
      id: 'robot_design_1_identify_mission',
      isCoreValue: false,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricsMission1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricsMission2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricsMission3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricsMission4Label',
      }]
    },
    {
      legendTitle: 'ChallengeRubricsBuildingLabel',
      id: 'robot_design_1_identify_building',
      isCoreValue: true,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricsBuilding1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricsBuilding2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricsBuilding3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricsBuilding4Label',
      }]
    },
  ]
}

export default rd1Section;