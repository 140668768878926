import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from 'screens/Home';
import Header from 'features/Header';
import Event from 'screens/Event';
import Login from 'screens/Login';
import SignUp from 'screens/SignUp';
import ForgotPassword from 'screens/ForgotPassword';
import ResetPassword from 'screens/ResetPassword';
import AuthenticatedPage from 'shared/components/AuthenticatedPage';
import EventOverview from 'screens/EventOverview';
import TermsAndConditions from 'screens/TermsAndConditions';
import PrivacyPolicy from 'screens/PrivacyPolicy';
import EventMatchScoring from 'features/EventOverviewContainer/components/ScoringMatch';
import JudgingRubric from 'screens/JudgingRubric';
import VerifyAccount from 'features/RegistrationContainer/components/SignUpContainer/VerifyAccount';
import RegistrationFooter from 'shared/components/Layouts/Registration/Footer';
import PublicScoresheet from 'features/PublicScoresheet';
import { PublicLeaderBoardType, PublicScoresheetType } from 'shared/constants/routerTypes';
import PublicLeaderBoard from 'features/PublicLeaderBoard';
import { getAuth0Token } from 'features/store';

const Routes = () => {
  useEffect(() => {
    getAuth0Token();
  }, []);

  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/signup/verification" component={VerifyAccount} />
      <Route path="/signup" component={SignUp} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/resetpassword" component={ResetPassword} />
      <Route path="/terms-and-conditions" component={TermsAndConditions} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <>
        <Header />
        <Route path="/admin/:tabId" exact component={AuthenticatedPage(Home)} />
        <Route path="/admin" exact component={AuthenticatedPage(Home)} />
        <Route path="/" exact component={AuthenticatedPage(Home)} />
        <Route path="/volunteer" exact component={AuthenticatedPage(Home)} />
        <Route path="/events/:id/:tabId" component={AuthenticatedPage(Event)} />
        <Route path="/events/:id" component={AuthenticatedPage(Event)} />
        <Route
          exact
          path="/event-overview/:eventId/scoresheet/:matchId"
          component={AuthenticatedPage(EventMatchScoring)}
        />
        <Route
          exact
          path="/event-overview/:eventId/rubric/:sessionId"
          component={AuthenticatedPage(JudgingRubric)}
        />
        <Route exact path="/event-overview/:eventId" component={AuthenticatedPage(EventOverview)} />
        <Route path={PublicScoresheetType} component={PublicScoresheet} />
        <Route path={PublicLeaderBoardType} component={PublicLeaderBoard} />
        <RegistrationFooter />
      </>
    </Switch>
  );
};

export default Routes;
