import { notification } from 'antd';
import * as eventRoleService from 'utils/services/eventRoles';
import * as userService from 'utils/services/users';
import * as types from './registrationTypes';

export const setRegistrationAuthQueryDetails = (payload) => ({
  type: types.SET_REGISTRATION_AUTH_QUERY,
  payload
})
export const setVerifyUserData = (payload) => ({
  type: types.SET_VERIFY_USER_DATA,
  payload
});

export const setAssignRole = () => ({
  type: types.SET_VERIFY_USER_ASSIGN_ROLE
});

export const setSignupUserData = (payload) => ({
  type: types.SET_SIGNUP_USER_DATA,
  payload
});

export const verifySignUpSuccess = () => ({
  type: types.VERIFY_USER_SIGNUP_SUCCESS
});

const verifyUserAuthBegin = () => ({
  type: types.VERIFY_USER_AUTH_EMAIL_BEGIN
});

const verifyUserAuthSuccess = () => ({
  type: types.VERIFY_USER_AUTH_EMAIL_SUCCESS
});

const verifyUserAuthError = () => ({
  type: types.VERIFY_USER_AUTH_EMAIL_ERROR
});

export const verifyUserAuth = ({ code, email }, history) => async (dispatch) => {
  try {
    dispatch(verifyUserAuthBegin());
    await eventRoleService.verifyInvitation(code, email);
    dispatch(verifyUserAuthSuccess());
    if (history) {
      history.push('/');
    }
    notification.success({
      message: 'Success',
      description: 'Your role invitation verified successful.',
    });
  } catch (err) {
    notification.error({
      message: 'Error',
      description: 'Your role invitation verify failed.',
    });
    dispatch(verifyUserAuthError());
  }
};

const acceptTermsSuccess = () => ({
  type: types.ACCEPT_TERMS_CONDITIONS_SUCCESS
});

export const acceptTermsAndConditions = (id, history) => async (dispatch, getState) => {
  try {
    await userService.acceptUserTerms(id);
    const { auth: {
      canAccessAdminDashboard,
      canAccessVolunteerDashboard
    } } = getState();
    dispatch(acceptTermsSuccess());
    if (history) {
      const homePath = canAccessAdminDashboard 
        ? '/admin' 
        : canAccessVolunteerDashboard 
          ? '/volunteer' 
          : '/'
      history.replace(homePath);
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: 'System Error.'
    });
  }
};
