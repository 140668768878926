import { Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ScreenLoading from 'shared/components/Spinner/ScreenLoading';
import volunteerResources from 'shared/constants/volunteerResources';
import '../../../../styles/VolunteerResourcesModal.scss'
import { FIRST_ADMIN, PARTNER_ADMIN } from 'shared/constants/userTypes';
//import log from 'loglevel';
//import { useConfig } from '../../../../../../configuration/useConfig';

const ResourcesModal = props => {
  //const { config } = useConfig();
  const { t } = useTranslation();

  const headingTitle = 'h--bold h-2 heading--charcoal m-b-36 m-t-36';
  const sectionTitle = 'paragraph--bold paragraph--charcoal paragraph--default p-b-24'
  const {
    eventOverview,
    eventProgramId,
    userEventRoleIds,
    userType
  } = props;

  const {
    is_FLLC_event,
    isFetchingUserEventRoles,
    isFetchingEventSchedule,
  } = eventOverview;

  const isPartnerAdminOrFirstAdmin = (userType === FIRST_ADMIN || userType === PARTNER_ADMIN)

  const [matchesList, setMatchesList] = useState([]);
  const [judgingResourcesList, setJudgingResourcesList] = useState([]);
  const [challengeResourcesList, setChallengeResourcesList] = useState([]);
  const selectedLanguage = localStorage.getItem('i18nextLng');

  useEffect(() => {
    //log.debug("Global config urls availaable");
    if (!isPartnerAdminOrFirstAdmin && (!volunteerResources[eventProgramId] || userEventRoleIds.length === 0)) {
      setMatchesList([]);
      setJudgingResourcesList([]);
      setChallengeResourcesList([]);
    } else {
      const matchesResources = [];
      const judgingResources = [];
      const challengeResources = [];
      volunteerResources[eventProgramId].matches.forEach(f => {
        if (isPartnerAdminOrFirstAdmin || f.roles.some(r => userEventRoleIds.includes(r))) {
          f.link = f[selectedLanguage] ? f[selectedLanguage] : f.default;
          if(f.link) matchesResources.push(f)
        }
      });

      volunteerResources[eventProgramId].judging.forEach(f => {
        if (isPartnerAdminOrFirstAdmin || f.roles.some(r => userEventRoleIds.includes(r))) {
          f.link = f[selectedLanguage] ? f[selectedLanguage] : f.default;
          if(f.link) judgingResources.push(f)
        }
      })

      volunteerResources[eventProgramId].challenge.forEach(f => {
        if (isPartnerAdminOrFirstAdmin || f.roles.some(r => userEventRoleIds.includes(r))) {
          f.link = f[selectedLanguage] ? f[selectedLanguage] : f.default;
          if(f.link) challengeResources.push(f)
        }
      })
      setMatchesList(matchesResources);
      setJudgingResourcesList(judgingResources);
      setChallengeResourcesList(challengeResources);
    }

  }, [selectedLanguage, eventProgramId, userEventRoleIds, isPartnerAdminOrFirstAdmin]);

  if (isFetchingUserEventRoles ||
    isFetchingEventSchedule) {
    return <ScreenLoading loading subTitle={t('loadingResourcesLabel')} />
  }

  const linkClass = (link) => `link ${link?"":"disabled"}`;

  const renderResourceLink = (m)=>{
    if(Array.isArray(m.link)){
      return (m.link.map((link, index)=>{
        return <li key={link} className='volunteer-resources-modal__col-item'><a target='_blank' rel="noreferrer" className={linkClass(link)} href={link}>{`${t(m.description)} (${index+1})`}</a></li>
      }))
    }
    return (<li key={m.link} className='volunteer-resources-modal__col-item'><a target='_blank' rel="noreferrer" className={linkClass(m.link)} href={m.link}>{t(m.description)}</a></li>)
  }

  console.log(challengeResourcesList, matchesList, judgingResourcesList, volunteerResources)

  return (
    <>
      <h1 className={headingTitle}>{t('resourcesLabel')}</h1>
      <Row type='flex' gutter={40} style={{marginBottom: '20px'}}>
        {challengeResourcesList.length ? (
          <Col sm={24} md={8} className='volunteer-resources-modal__col'>
            <div className='volunteer-resources-modal__section'>
              <p className={sectionTitle}>{t('challengeAndBuildLabel')}</p>
              <ul>
                {challengeResourcesList.map(m => (renderResourceLink(m)))}
              </ul>
            </div>
          </Col>
        ) : (null)}
        {matchesList.length ? (
          <Col sm={24} md={8} className='volunteer-resources-modal__col'>
            <div className='volunteer-resources-modal__section'>
              <p className={sectionTitle}>{t('matchesLabel')}</p>
              <ul>
                {matchesList.map(m => (renderResourceLink(m)))}
              </ul>
            </div>
          </Col>
        ) : (null)}
        {judgingResourcesList.length ? (
          <Col sm={24} md={8} className='volunteer-resources-modal__col'>
            <div className='volunteer-resources-modal__section'>
              <p className={sectionTitle}>{is_FLLC_event ? t('judgingLabel') : t('nonChallengeJudgingLabel')}</p>
              <ul>
                {judgingResourcesList.map(m => (renderResourceLink(m)))}
              </ul>
            </div>
          </Col>
        ) : (null)}
      </Row>
    </>
  );
};

ResourcesModal.propTypes = {
  eventProgramId: PropTypes.string.isRequired,
  userEventRoleIds: PropTypes.array.isRequired,
  getEventDetails: PropTypes.func,
  eventOverview: PropTypes.object.isRequired
};

ResourcesModal.defaultProps = {
  getEventDetails: () => { },
}

const mapStateToProps = (state) => {
  const { eventOverview, auth: {userType} } = state;
  const { eventProgramId, userEventRoleIds } = eventOverview
  return { eventProgramId, userEventRoleIds, eventOverview, userType }
};

export default connect(mapStateToProps)(ResourcesModal);
