import React from 'react';
import PropTypes from 'prop-types';

const Trophy = props => {
  const {fill} = props;
  return (
    <svg viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      <g id="Scoring-and-Refereeing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dashboard---Event---FLL-Challenge---Coach" transform="translate(-340.000000, -2873.000000)" fill={fill}>
          <g id="Group-14" transform="translate(310.000000, 2601.000000)">
            <g id="Group-40-Copy-8" transform="translate(20.000000, 220.000000)">
              <g id="Icons/-noun_Trophy_1971244" transform="translate(10.000000, 52.000000)">
                <path d="M6.75875591,15.4021357 C7.42015522,20.9254362 11.9308164,25.2738286 17.5280621,25.6887437 C17.466441,26.0974966 17.4150902,26.5226819 17.3678473,26.9684075 C17.057688,29.8830834 16.704394,33.1859719 11.848655,34.5601214 C11.497415,34.6607691 11.2735251,35.003793 11.322822,35.3673572 C11.3721188,35.7309214 11.6822781,36 12.0499504,36 L24.7500496,36 C25.1156679,36 25.4258272,35.7309214 25.477178,35.3673572 C25.5264749,35.005847 25.302585,34.6607691 24.951345,34.5601214 C20.095606,33.1859719 19.744366,29.8830834 19.4321527,26.9684075 C19.3849098,26.5226819 19.333559,26.0974966 19.2719379,25.6887437 C24.8671296,25.2738286 29.3777907,20.9274902 30.0412441,15.4021357 C33.3749431,15.0734901 36,12.2861644 36,8.86619595 L36,2.99987162 C36,2.5952267 35.6713544,2.26658108 35.2667095,2.26658108 L30.1336757,2.26658108 L30.1336757,1.53329054 C30.1336757,1.12864562 29.8050301,0.8 29.4003851,0.8 L7.39961487,0.8 C6.99496995,0.8 6.66632433,1.12864562 6.66632433,1.53329054 L6.66632433,2.26658108 L1.53329054,2.26658108 C1.12864562,2.26658108 0.8,2.5952267 0.8,2.99987162 L0.8,8.86619595 C0.8,12.2861644 3.42300286,15.0734901 6.75875591,15.4021357 Z M21.4019723,34.5313649 L15.3959736,34.5313649 C17.2055786,33.2558091 17.9984361,31.5694462 18.398973,29.9015697 C18.7995098,31.5694462 19.5944214,33.2558091 21.4019723,34.5313649 Z M34.5313649,3.73316216 L34.5313649,8.86619595 C34.5313649,11.4460641 32.6170041,13.5904767 30.1316216,13.9478789 L30.1316216,3.73316216 L34.5313649,3.73316216 Z M8.13290541,2.26658108 L28.6650406,2.26658108 L28.6650406,2.99987162 L28.6650406,13.9992297 C28.6650406,19.6601506 24.0598938,24.2652973 18.398973,24.2652973 C12.7380522,24.2652973 8.13290541,19.6601506 8.13290541,13.9992297 L8.13290541,2.99987162 L8.13290541,2.26658108 Z M2.26658108,3.73316216 L6.66632433,3.73316216 L6.66632433,13.9478789 C4.18094182,13.5904767 2.26658108,11.4481181 2.26658108,8.86619595 L2.26658108,3.73316216 L2.26658108,3.73316216 Z" id="Shape" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Trophy.propTypes = {
  fill: PropTypes.string
};

Trophy.defaultProps = {
  fill: 'currentColor'
}

export default Trophy;