import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {useLocation, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'shared/components/Layouts/DashboardLayout';
import { verifyUserAuth } from 'features/RegistrationContainer/redux/registrationActions';
import {setDashboardRole} from 'features/DashboardContainer/redux/roleActions';
import DashboardView from './DashboardView';

const DashboardContainer = (props) => {
  const { dashboard: { selectedRoleId }, lastVerify, selectRole } = props;
  const location = useLocation();
  const path = location.pathname
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const { email, code } = lastVerify;
    if (email && code) {
      props.verifyUserAuth({ email, code });
    }
  }, []);
  // console.log(selectedRoleId)
  useEffect(() => {
    if (path.includes('/admin')) {
      selectRole(t('adminDashboardLabel'), 'AdminDashboard', 'admin')
    } else if (path === '/volunteer') {
      selectRole('Volunteer Dashboard', 'VolunteerDashboard', 'volunteer')
    } 
    else if ( selectedRoleId === 'AdminDashboard') {
      history.replace('/admin')
    } else {
      history.replace('/volunteer')
    }

  }, [path, selectedRoleId])

  return (
    <DashboardLayout>
     
      <DashboardView role={selectedRoleId} />
    </DashboardLayout>
  );
};

DashboardContainer.propTypes = {
  dashboard: PropTypes.object.isRequired,
  selectRole: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { dashboard, auth: { lastVerify } } = state;
  return {
    dashboard,
    lastVerify
  };
};

const mapDispatchToProps = {
  verifyUserAuth,
  selectRole: setDashboardRole
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
