import * as sessionDocumentService from 'utils/services/sessionDocuments';
import {formatSessionDocumentsRes} from 'utils/services/sessions/model';
import {formatSessionDocumentFile} from 'utils/services/sessionDocuments/model';
import * as types from '../../types/coachJudgingTypes';
import get from 'lodash/get';
import { updateGlobalUploadModalProps } from 'features/GlobalUploadModal/redux/actions';

const uploadDocumentBegin = (sessionDocuments) => {
  return {
    type: types.UPLOAD_JUDGING_SESSION_DOCUMENT_BEGIN,
    payload: {
      sessionDocuments
    }
  }
}

const uploadDocumentSuccess = (sessionDocuments) => {
  return {
    type: types.UPLOAD_JUDGING_SESSION_DOCUMENT_SUCCESS,
    payload: {
      sessionDocuments
    }
  }
}

const uploadDocumentError = (sessionDocuments) => {
  return {
    type: types.UPLOAD_JUDGING_SESSION_DOCUMENT_ERROR,
    payload: {
      sessionDocuments
    }
  }
}

const uploadDocumentRetry = (uploadRetry) => {
  return {
    type: types.UPLOAD_JUDGING_SESSION_DOCUMENT_RETRY,
    payload: {
      uploadRetry,
    }
  }
}

var handleDocumentUpload = (sessionDocumentId, file, onProgress, onErrorFallback) => {
  return async (dispatch, getState) => {
    const {coachJudging: {sessionDocuments, uploadRetry}} = getState();
    const sessionDocumentsCopy = [...sessionDocuments];
    const originalCopy = [...sessionDocuments];
    let fileId
    
    try {
      const sessionDocumentIndex = sessionDocumentsCopy.findIndex(i => i.sessionDocumentId === sessionDocumentId);
      const selectedSessionDocument = sessionDocumentsCopy[sessionDocumentIndex];
      const uploadingDocument = {
        ...selectedSessionDocument,
        loading: true
      }
      sessionDocumentsCopy[sessionDocumentIndex] = uploadingDocument;

      await dispatch(uploadDocumentBegin(sessionDocumentsCopy));
      dispatch(updateGlobalUploadModalProps({modalClosable: false}))

      // Initiate file upload and receive S3 presigned url + fileId
      const initFileUploadResponse = await sessionDocumentService.initiateSessionFileUpload(sessionDocumentId, file)
      const presignedUrl = initFileUploadResponse.included && initFileUploadResponse.included[0]
        ? get(initFileUploadResponse.included[0], ['attributes', 'url'], undefined)
        : undefined
      fileId = get(initFileUploadResponse, ['data', 'id'], undefined)
      if (!presignedUrl || !fileId) throw 'missingUrl'

      // Upload file with S3 presigned url, attach caculateProgress callback for progress bar modal
      await sessionDocumentService.uploadSessionFile(presignedUrl, file, onProgress)

      // Edit session file to have 'Uploaded' status
      const editSessionFileResponse = await sessionDocumentService.editSessionFile(sessionDocumentId, fileId, { status: 'Uploaded' })

      const newSessionFile = formatSessionDocumentFile(editSessionFileResponse);
      const updatedSessionDocument = {
        ...selectedSessionDocument,
        files: [...selectedSessionDocument.files, newSessionFile],
        loading: false
      }
      sessionDocumentsCopy[sessionDocumentIndex] = updatedSessionDocument;
      await dispatch(uploadDocumentSuccess(sessionDocumentsCopy))
      dispatch(updateGlobalUploadModalProps({modalClosable: true}))
    } catch(error) {
      // Retry up to 3 times if upload request stalls or times out
      if (get(error, ['config', 'onUploadProgress'], false) && uploadRetry < 3) {
        dispatch(uploadDocumentError(originalCopy))
        dispatch(uploadDocumentRetry(uploadRetry + 1))
        if (fileId) await sessionDocumentService.editSessionFile(sessionDocumentId, fileId, { status: 'UploadFailed' })
        await dispatch(handleDocumentUpload(sessionDocumentId, file, onProgress, onErrorFallback))
      } else {
        if (error === 'missingUrl') console.warn('Missing presigned url')
        dispatch(uploadDocumentError(originalCopy))
        if (fileId) await sessionDocumentService.editSessionFile(sessionDocumentId, fileId, { status: 'UploadFailed' })
        onErrorFallback(error)
      }
    }
  }
}

export default handleDocumentUpload;