export const GET_EVENT_JUDGES_BEGIN = 'GET_EVENT_JUDGES_BEGIN';
export const GET_EVENT_JUDGES_ERROR = 'GET_EVENT_JUDGES_ERROR';
export const GET_EVENT_JUDGES_SUCCESS = 'GET_EVENT_JUDGES_SUCCESS';

export const GET_EVENT_JUDGING_SESSIONS_BEGIN = 'GET_EVENT_JUDGING_SESSIONS_BEGIN';
export const GET_EVENT_JUDGING_SESSIONS_ERROR = 'GET_EVENT_JUDGING_SESSIONS_ERROR';
export const GET_EVENT_JUDGING_SESSIONS_SUCCESS = 'GET_EVENT_JUDGING_SESSIONS_SUCCESS';

export const GET_EVENT_JUDGING_PANELS_BEGIN = 'GET_EVENT_JUDGING_PANELS_BEGIN';
export const GET_EVENT_JUDGING_PANELS_ERROR = 'GET_EVENT_JUDGING_PANELS_ERROR';
export const GET_EVENT_JUDGING_PANELS_SUCCESS = 'GET_EVENT_JUDGING_PANELS_SUCCESS';



export const CREATE_NEW_JUDGING_PANEL_BEGIN = 'CREATE_NEW_JUDGING_PANEL_BEGIN';
export const CREATE_NEW_JUDGING_PANEL_ERROR = 'CREATE_NEW_JUDGING_PANEL_ERROR';
export const CREATE_NEW_JUDGING_PANEL_SUCCESS = 'CREATE_NEW_JUDGING_PANEL_SUCCESS';


export const FETCH_SELECTED_JUDGING_PANEL_BEGIN = 'FETCH_SELECTED_JUDGING_PANEL_BEGIN';
export const FETCH_SELECTED_JUDGING_PANEL_ERROR = 'FETCH_SELECTED_JUDGING_PANEL_ERROR';
export const FETCH_SELECTED_JUDGING_PANEL_SUCCESS = 'FETCH_SELECTED_JUDGING_PANEL_SUCCESS';

export const RESET_SELECTED_JUDGING_PANEL = 'RESET_SELECTED_JUDGING_PANEL';

export const REMOVE_JUDGE_FROM_PANEL_BEGIN = 'REMOVE_JUDGE_FROM_PANEL_BEGIN';
export const REMOVE_JUDGE_FROM_PANEL_ERROR = 'REMOVE_JUDGE_FROM_PANEL_ERROR';
export const REMOVE_JUDGE_FROM_PANEL_SUCCESS = 'REMOVE_JUDGE_FROM_PANEL_SUCCESS';

export const REMOVE_SESSION_FROM_JUDGING_PANEL_BEGIN = 'REMOVE_SESSION_FROM_JUDGING_PANEL_BEGIN';
export const REMOVE_SESSION_FROM_JUDGING_PANEL_ERROR = 'REMOVE_SESSION_FROM_JUDGING_PANEL_ERROR';
export const REMOVE_SESSION_FROM_JUDGING_PANEL_SUCCESS = 'REMOVE_SESSION_FROM_JUDGING_PANEL_SUCCESS';

export const ADD_JUDGES_TO_JUDGING_PANEL_BEGIN = 'ADD_JUDGES_TO_JUDGING_PANEL_BEGIN';
export const ADD_JUDGES_TO_JUDGING_PANEL_ERROR = 'ADD_JUDGES_TO_JUDGING_PANEL_ERROR';
export const ADD_JUDGES_TO_JUDGING_PANEL_SUCCESS = 'ADD_JUDGES_TO_JUDGING_PANEL_SUCCESS';

export const ADD_SESSIONS_TO_JUDGING_PANEL_BEGIN = 'ADD_SESSIONS_TO_JUDGING_PANEL_BEGIN';
export const ADD_SESSIONS_TO_JUDGING_PANEL_ERROR = 'ADD_SESSIONS_TO_JUDGING_PANEL_ERROR';
export const ADD_SESSIONS_TO_JUDGING_PANEL_SUCCESS = 'ADD_SESSIONS_TO_JUDGING_PANEL_SUCCESS';

export const UPDATE_SELECTED_JUDGING_PANEL = 'UPDATE_SELECTED_JUDGING_PANEL';


export const EDIT_JUDGING_PANEL_BEGIN = 'EDIT_JUDGING_PANEL_BEGIN';
export const EDIT_JUDGING_PANEL_ERROR = 'EDIT_JUDGING_PANEL_ERROR';
export const EDIT_JUDGING_PANEL_SUCCESS = 'EDIT_JUDGING_PANEL_SUCCESS';

export const SET_SELECTED_JUDGING_PANEL_DATA = 'SET_SELECTED_JUDGING_PANEL_DATA';
export const SET_JUDGING_PANELS_EXPANDS = 'SET_JUDGING_PANELS_EXPANDS';