import * as panelService from 'utils/services/panels';
import { notification } from 'antd';
import { formatRemoveUsersFromPanel, formatRemoveSessionsFromPanel } from 'utils/services/panels/model';
import * as types from '../../types/scoringTypes';
import { getScoringTabData, getScoringData } from './getScoringPanels';

const editScoringPanel = () => {
  return {
    type: types.EDIT_SCORING_PANEL_BEGIN
  }
}
const editScoringPanelError = () => {
  return {
    type: types.EDIT_SCORING_PANEL_ERROR
  }
}
const editScoringPanelSuccess = (formattedPanels, refereeOptions, sessionOptions) => {
  return {
    type: types.EDIT_SCORING_PANEL_SUCCESS,
    payload: {
      formattedPanels, refereeOptions, sessionOptions
    }
  }
}

const setScoringPanelData = (sessionIds, refereeIds) => {
  return {
    type: types.SET_SELECTED_SCORING_PANEL_DATA,
    payload: {
      sessionIds, refereeIds
    }
  }
}

const handleEditScoringPanel = (panelId, payload, cb = () => { }) => {
  return async (dispatch, getState) => {
    const { event: { eventDetails: { id, eventScheduleId } } } = getState();
    try {
      await dispatch(editScoringPanel())
      const {
        removedReferees,
        removedSessions,
        addedReferees,
        addedSessions,
        formReferees,
        formSessions,
        panelName
      } = payload;

      if (removedSessions.length) {
        const removeSessionsPayload = formatRemoveSessionsFromPanel(removedSessions);

        await panelService.deleteSessionsFromPanel(panelId, removeSessionsPayload)
      }
      if (removedReferees.length) {
        const removedRefereesPayload = formatRemoveUsersFromPanel(removedReferees);
        await panelService.deleteRefereesFromPanel(panelId, removedRefereesPayload)
      }
      // if (addedReferees.length || addedSessions.length) {
      const updatePayload = {
        data: {
          type: 'panel',
          attributes: {
            name: panelName,
          },
          id: panelId,
          relationships: {
            sessions: {
              data: addedSessions.map(s => { return { type: 'session', id: s } })
            },
            referees: { data: addedReferees.map(s => { return { type: 'user', id: s } }) }
          }
        }
      }
      await panelService.updatePanel(panelId, updatePayload)
      // }

      notification.success({
        message: 'Success',
        description: 'Table successfully saved',
      })

      await dispatch(setScoringPanelData(formSessions, formReferees))

      try {
        // const { formattedPanels, refereeOptions, sessionOptions } = await getScoringTabData(id, eventScheduleId)
        const { formattedPanels, refereeOptions, sessionOptions } = await getScoringData(id, eventScheduleId, 1)
        await dispatch(editScoringPanelSuccess(formattedPanels, refereeOptions, sessionOptions))
      } catch (error) {
        console.log(error);
      }

      cb && cb();
    } catch (err) {
      await dispatch(editScoringPanelError())
      notification.error({
        message: 'Error',
        description: 'Error saving panel.',
      });
      throw err;
    }
  }

}


const handleDeletScoringPanel = (panelId, payload, cb = () => { }) => {
  return async (dispatch, getState) => {
    const { event: { eventDetails: { id, eventScheduleId } } } = getState();
    await panelService.deleteSessionsFromPanel(panelId, payload)

    const { formattedPanels, refereeOptions, sessionOptions } = await getScoringData(id, eventScheduleId, 1)
    await dispatch(editScoringPanelSuccess(formattedPanels, refereeOptions, sessionOptions))
  }
}

const handleDeleteRefereePanel = (panelId, payload, cb = () => { }) => {
  return async (dispatch, getState) => {
    const { event: { eventDetails: { id, eventScheduleId } } } = getState();
    await panelService.deleteRefereesFromPanel(panelId, payload)

    const { formattedPanels, refereeOptions, sessionOptions } = await getScoringData(id, eventScheduleId, 1)
    await dispatch(editScoringPanelSuccess(formattedPanels, refereeOptions, sessionOptions))
  }
}

const handleUpdateScoringPanel = (panelId, payload, cb = () => { }) => {
  return async (dispatch, getState) => {
    await dispatch(editScoringPanel())
    const { event: { eventDetails: { id, eventScheduleId } } } = getState();
    await panelService.updatePanel(panelId, payload)

    const { formattedPanels, refereeOptions, sessionOptions } = await getScoringData(id, eventScheduleId, 1)
    await dispatch(editScoringPanelSuccess(formattedPanels, refereeOptions, sessionOptions))
  }
}

export { handleDeletScoringPanel, handleUpdateScoringPanel, handleDeleteRefereePanel }
export default handleEditScoringPanel