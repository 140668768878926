import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import CloseCircle from 'shared/components/Icon/icons/CloseCircle';
import BtnSecondary from 'shared/components/Button/BtnSecondary';
import Paragraph from 'shared/components/Paragraph';
import Select from 'shared/components/Select';
import Divider from 'shared/components/Divider';
import { AWARD_PLACES } from 'shared/constants/awards';
import { 
  addTeamToAward,
  removeTeamFromAward, 
  updateTeamAwardPlace
} from 'features/EventOverviewContainer/redux/actions/assignedAwards';
import DeleteConfirmModal from '../DeleteConfirm';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const NominatedList = (props) => {
  const { userId, assignments, awardId, editable, teams } = props;
  const [selTeam, setSelTeam] = useState(undefined);
  const [delTeam, setDelVisible] = useState(null);
  const { eventId } = useParams();
  const {t} = useTranslation();

  const formattedTeams = teams.map((team) => {
    const selected = assignments.find(({ teamId }) => teamId === team.id);
    return { ...team, disabled: Boolean(selected) };
  });

  const formattedAssignments = assignments.map(assign => {
    const selected = teams.find(({ id }) => id === assign.teamId);
    return { ...assign, teamNumberName: selected && selected.teamNumberName };
  });

  const handleRemoveTeam = async () => {
    const assignmentId = delTeam && delTeam.id;
    await props.removeTeamFromAward(assignmentId, awardId);
    setDelVisible(null);
  };

  const handleUpdateAwardPlace = (assignmentId, place) => {
    props.updateTeamAwardPlace({ awardId, assignmentId, place });
  };

  const handleAddTeam = async () => {
    await props.addTeamToAward({ eventId, awardId, teamId: selTeam, userId });
    setSelTeam(undefined);
  };

  return (
    <>
      <DeleteConfirmModal
        delTeam={delTeam}
        onSubmit={handleRemoveTeam}
        onCancel={() => setDelVisible(null)}
      />
      {editable && (
        <>
          <Paragraph size='medium' weight='bold' className='m-t-24'>
            {t('nominateTeamLabel')}
          </Paragraph>
          <div className='c-event-overview-awards__nominate'>
            <Select 
              theme='blue' 
              placeholder={t('searchTeamLabel')}
              value={selTeam}
              onChange={setSelTeam}
            >
              {formattedTeams.map(({ id, teamNumberName, disabled }) => (
                <Option key={id} value={id} disabled={disabled}>{teamNumberName}</Option>
              ))}
            </Select>
            <BtnSecondary disabled={!selTeam} size='medium' onClick={handleAddTeam}>
              {t('nominateLabel')}
            </BtnSecondary>
          </div>
        </>
      )}
      {assignments.length > 0 && <Divider width={4} margin={24} />}
      {formattedAssignments.map(({ id, teamNumberName, place, isPublishable, isPublished }, i) => (
        <React.Fragment key={i}>
          <div className='c-event-overview-awards__nominated-team'>
            <div>
              {!isPublished ? (
                <Select 
                  theme='blue' 
                  placeholder='Choose Place' 
                  disabled={!editable || isPublishable}
                  value={place || undefined}
                  onChange={(val) => handleUpdateAwardPlace(id, val)}
                >
                  {AWARD_PLACES.map(({ place }) => (
                    <Option key={place} value={place}>{place}</Option>
                  ))}
                </Select>
              ) : (
                <Paragraph size='medium' weight='bold' className='c-event-overview-awards__place'>
                  {place}
                </Paragraph>
              )}
              <Paragraph size='medium' weight='bold' className='m-l-24'>
                {teamNumberName}
              </Paragraph>
            </div>
            <div className='c-event-overview-awards__nominated-actions'>
              {/* <Paragraph size='medium' color='blue'>Judging Materials</Paragraph>
              <Paragraph size='medium' color='blue'>Rubric</Paragraph>
              <Paragraph size='medium' color='blue'>Scoresheet</Paragraph> */}
              {editable && !isPublished && (
                <div className='c-event-overview-awards__assign-del'>
                  <CloseCircle onClick={() => setDelVisible({ id, name: teamNumberName })} />
                </div>
              )}
            </div>
          </div>
          {(i + 1) < assignments.length && <Divider margin={12} />}
        </React.Fragment>
      ))}
    </>
  );
};

const mapStateToProps = ({ auth, assignedAwards }) => {
  const { teams } = assignedAwards;
  const { userId } = auth;
  return {
    userId,
    teams
  };
};

const mapDispatchToProps = {
  addTeamToAward,
  removeTeamFromAward, 
  updateTeamAwardPlace
};

export default connect(mapStateToProps, mapDispatchToProps)(NominatedList);
