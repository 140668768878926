import React, {useState} from 'react';
import '../../../../styles/judgingRubric/FLLFeedbackComments.scss';
import {Button} from 'antd';
import Icon from '@ant-design/icons';
import PlusCircle from 'shared/components/Icon/icons/PlusCircle';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import JudgingFeedbackComment from './CommentRow';
import get from 'lodash/get'

import JudgingFeedbackModal from './JudgingFeedbackModal';
import { useTranslation } from 'react-i18next';

const titleClassName = 'paragraph--large paragraph--charcoal paragraph--bold'

const FeedbackCommentsContainer = (props) => {
  const { t } = useTranslation();

  const [greatJobModalVisible, setGreatJobModalVisible] = useState(false);
  const [thinkAboutModalVisible, setThinkAboutModalVisible] = useState(false)
  const [commentId, setCommentId] = useState(undefined);
 
  const { commentSection, rubricComments, rubric, extraClass, commentsData, labelKey, subtitleKey } = props;

  const data = commentsData && commentsData.length > 0 ? commentsData : rubricComments

  const isPublished = get(rubric, ['is_published'], false)
  const isPublishable = get(rubric, ['is_publishable'], false)

  const openGreatJobModal = (id) => {
    setCommentId(id)
    setGreatJobModalVisible(true)
  }
  const openThinkAboutModal = (id) => {
    setCommentId(id)
    setThinkAboutModalVisible(true)
  }
  const closeModal = (id) => {
    setCommentId(undefined)
    setGreatJobModalVisible(false)
    setThinkAboutModalVisible(false)
  }

  const sectionFeedbackComments = data.filter(c => c.commentSection === commentSection);
  const positiveComments = sectionFeedbackComments.filter(c => c.commentType === 'positive');
  const negativeComments = sectionFeedbackComments.filter(c => c.commentType === 'negative');

  return (
    <div className={extraClass ? extraClass + '-wrapper' : ''}>
      <JudgingFeedbackModal
        categoryLabel={t(labelKey)}
        helperText={t(subtitleKey)}
        commentSection={commentSection}
        onCancel={closeModal}
        visible={greatJobModalVisible || thinkAboutModalVisible}
        isGreatJobComment={greatJobModalVisible}
        isThinkAboutComment={thinkAboutModalVisible}
        commentId={commentId}
        extraClass={extraClass}
      />
      <p className='h-2 heading--charcoal m-b-8'>{t(labelKey)}</p>
      <p className='h-4 heading--charcoal m-b-8' dangerouslySetInnerHTML={{ __html: t(subtitleKey) }}></p>
      <div className='c-fll-feedback'>
        <div className='c-fll-feedback__section c-fll-feedback__section--left'>
          <div className='c-fll-feedback__section-header'>
            <span className={titleClassName}>{t('greatJobLabel')}</span>
            <Button
              onClick={e => openGreatJobModal(commentId)}
              type='link'
              className='c-rubric-upload__judging-comments__add'
              disabled={isPublished || isPublishable}
            > 
              {' '}
              <Icon className='m-r-8' component={PlusCircle} />
              {' '}
              {t('addCommentLabel')}
            </Button>
          </div>
         
          <ul className='c-fll-feedback__comments'>
            {positiveComments.map(c => (
              <li key={c.commentId} className='c-fll-feedback__comment-row'>
                <JudgingFeedbackComment
                  {...c}
                  onEdit={openGreatJobModal}
                  isPublished={isPublished || isPublishable}
                />
              </li>
              ))}
          </ul>

        </div>      
        <div className='c-fll-feedback__section'>
          <div className='c-fll-feedback__section-header'>
            <span className={titleClassName}>
              {t('thinkAboutLabel')}
            </span>
            <Button
              onClick={e => openThinkAboutModal(commentId)}
              type='link'
              className='c-rubric-upload__judging-comments__add'
              disabled={isPublished || isPublishable}
            > 
              {' '}
              <Icon className='m-r-8' component={PlusCircle} />
              {' '}
              {t('addCommentLabel')}
            </Button>

          </div>
          <ul className='c-fll-feedback__comments'>
            {negativeComments.map(c => (
              <li key={c.commentId} className='c-fll-feedback__comment-row'>
                <JudgingFeedbackComment
                  {...c}
                  onEdit={openThinkAboutModal}
                  isPublished={isPublished || isPublishable}
                />
              </li>
              ))}
          </ul>
        </div>

      </div>
    </div>
  );
};
FeedbackCommentsContainer.propTypes = {
  commentSection: PropTypes.string.isRequired,
  rubricComments: PropTypes.array
}
FeedbackCommentsContainer.defaultProps = {
  rubricComments: []
}
const mapStateToProps = (state) => {
  const {judgingRubric: {judgingRubricComments}} = state;

  return {
    rubricComments: judgingRubricComments
  }
}

export default connect(mapStateToProps)(FeedbackCommentsContainer);