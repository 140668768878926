const m01Section = {
  id: 'm01',
  name: '2025ScoresheetM01Sub1Label',
  description: '2025ScoresheetM01Sub2Label',
  fields: [
    {
      id: 'm01_scoring_1',
      description: null,
      scoring: '2025ScoresheetM01Sub2Label', 
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        }
      ]
    },
    {
        scoring: '2025ScoresheetM01Sub3Label',
        id: 'm01_scoring_2',
        options: [
            {
                description: 'noLabel',
                value: false
            },
            {
                description: 'yesLabel',
                value: true
            },
        ]
    },
    {
        scoring: '2025ScoresheetM01Sub4Label',
        id: 'm01_scoring_3',
        options: [
            {
                description: 'noLabel',
                value: false
            },
            {
                description: 'yesLabel',
                value: true
            },
        ]
    },
  ]
}

export default m01Section;