import { Table, Button, Switch } from 'antd';
import { handleEventDetails } from 'features/EventContainer/redux/actions/eventActions';
import { fetchEventLeaderboard } from 'features/LeaderboardContainer/redux/leaderboardActions';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import './style.scss';
import SingleEvent from 'models/Event';
import ScreenLoading from 'shared/components/Spinner/ScreenLoading';
import EventProgramLogos from 'shared/components/EventProgramLogos';
import FirstLogo from '../../shared/components/Icon/pngs/first-season-logo-current-dark-background.png';
import { getAllSponsorsForPublicLeaderBoard } from 'utils/services/sponsors';
import { FLL_CHALLENGE_ID, FLL_DISCOVER_ID, FLL_EXPLORE_ID } from 'shared/constants/programIds';
import SponsorLogo from './SponsorLogo';
import { getPublicSponsorFile } from 'utils/services/eventFiles';
import { find, get } from 'lodash';

const TIMES_IMAGE_REPEATED = 10;

const AnimationWrapper = (props) => {
  useEffect(() => {
    const width = -1 * props.width/TIMES_IMAGE_REPEATED;
    addKeyFrames(width)
  }, [props.width])

  const nameWidth = Math.ceil(props.width);

  const keyFramesName = props?.left ? `left-slider-show-${nameWidth}` : `right-slider-show-${nameWidth}`;
  const duration = props.width/TIMES_IMAGE_REPEATED/1000 * 20;

  const addKeyFrames = (width) => {
    const FIXED_STYLE_ID = props.left ? 'left-style-for-animation' : 'right-style-for-animation';
    let style = document.getElementById(FIXED_STYLE_ID);
    if(style){
      style.remove();
    } 
    style = document.createElement('style');
    style.setAttribute('id', FIXED_STYLE_ID);
    style.setAttribute('type', 'text/css');
    const startPoint = '0px';
    const text = props.left ? 
    `.left-wrapper-animation {-webkit-animation: ${duration}s ${keyFramesName} linear infinite normal;animation: ${duration}s ${keyFramesName} linear infinite normal;} @-webkit-keyframes ${keyFramesName} {0% {-webkit-transform: translate3d(${startPoint}, 0, 0);transform: translate3d(${startPoint}, 0, 0);} 100% {-webkit-transform: translate3d(${width}px, 0, 0);transform: translate3d(${width}px, 0, 0);}} @keyframes ${keyFramesName} {0% {-webkit-transform: translate3d(${startPoint}, 0, 0); transform: translate3d(${startPoint}, 0, 0);} 100% {-webkit-transform: translate3d(${width}px, 0, 0); transform: translate3d(${width}px, 0, 0);}}`
    :
    `.right-wrapper-animation {-webkit-animation: ${duration}s ${keyFramesName} linear infinite normal;animation: ${duration}s ${keyFramesName} linear infinite normal;} @-webkit-keyframes ${keyFramesName} {0% {-webkit-transform: translate3d(${startPoint}, 0, 0);transform: translate3d(${startPoint}, 0, 0);} 100% {-webkit-transform: translate3d(${width}px, 0, 0);transform: translate3d(${width}px, 0, 0);}} @keyframes ${keyFramesName} {0% {-webkit-transform: translate3d(${startPoint}, 0, 0); transform: translate3d(${startPoint}, 0, 0);} 100% {-webkit-transform: translate3d(${width}px, 0, 0); transform: translate3d(${width}px, 0, 0);}}`;
    style.innerHTML = text;
    document.getElementsByTagName('head')[0].appendChild(style);
  }

  const className = props?.left ? 'left-wrapper-animation' : 'right-wrapper-animation'

  const wrapperClass = props?.fullSize ? 'sponsors-logos fullsize' : 'sponsors-logos';

  // here is the scrolling logic which generate the 4 as for 4 time of the children
  return (
    <div className={wrapperClass}>
      <div className='inner-wrapper'>
        <div className={className}>
          {Array(TIMES_IMAGE_REPEATED).fill(props.children)}
        </div>
      </div>
    </div>
  )
}

const GlobalSponsors = ({ eventProgramId, len }) => {
  const [width, setWidth] = useState(0);
  const fullSize = len == 0;
  const isFLLC = eventProgramId == FLL_CHALLENGE_ID;
  const isFLLD_FLLE = eventProgramId == FLL_DISCOVER_ID || eventProgramId == FLL_EXPLORE_ID;
  const onImageLoad = (event) => {
    const {clientWidth} = event.target
    setWidth(clientWidth+width)
  }

  if(isFLLC) {
    return (
      <AnimationWrapper left={true} fullSize={fullSize} width={width}>
        <img
          onLoad={onImageLoad}
          src='/logos/lego_education.png'
          alt='education'
          className='m-r-40'
        />
        <img
          onLoad={onImageLoad}
          src='/logos/lego_foundation.png'
          alt='lego_foundation'
          className='m-r-20'
        />
        <img
          onLoad={onImageLoad}
          src='/logos/rockwell_automation.png'
          alt='rockwell_automation'
          className='m-r-40'
        />
      </AnimationWrapper>
    ) 
  } else if (isFLLD_FLLE){
    return (
      <AnimationWrapper left={true} fullSize={fullSize} width={width}>
        <img
          onLoad={onImageLoad}
          src='/logos/lego_education.png'
          alt='education'
          className='m-r-40'
        />
        <img
          onLoad={onImageLoad}
          src='/logos/lego_foundation.png'
          alt='lego_foundation'
        />
      </AnimationWrapper>
    )
  }
  return null
}

const EventsLogo = ({sponsors, len, setLen}) => {

  // this is the default icon placeholder width 
  const [width, setWidth] = useState(0);
  const [urls, setUrls] = useState([]);

  useEffect(() => {
    const getFiles = async () => {
      for (let index = 0; index < sponsors.length; index++){
        const sponsor = sponsors[index];
        const {id, name} = sponsor;
        if(id){
          try{
            const response = await getPublicSponsorFile(id);
            const presignedUrlObject = response.included && find(response.included, res => res.type === 'pre-signed_url')
            const presignedUrl = get(presignedUrlObject, ['attributes', 'url'], '')
            setUrls(urls => [...urls,{url: presignedUrl, id, name}])
            setLen(len => len + 1);
          }catch(e){}
        }
      }
    };
    getFiles();
  }, [sponsors.length]);

  if(len == 0) return null;
  const onImageLoad = (event) => {
    const {clientWidth} = event.target
    const newWidth = clientWidth;
    setWidth(prev => prev + newWidth);
  }

  return (
    <AnimationWrapper width={width}>
      {urls.map(({id, name, url}) => (<img src={url} id={id} alt={name} onLoad={onImageLoad} />))}
    </AnimationWrapper>
  );
}

const DEFAULT_LIST_LENGTH = 10;
const DEFAULT_REFRESH_INTERVAL = 10 * 1000;
const DEFAULT_FETCH_DATA_INTERVAL = 60 * 1000;
const PublicLeaderBoard = (props)=>{
  const {teams, eventDetails, isFetchingLeaderboard} = props;
  
  const {included, isFetchingEventDetails, eventProgramId, eventScheduleId} = eventDetails;

  const teamsData = teams.map(t=>({key: (t||{}).id, ...t}))
  const {leaderboardId, eventId} = useParams();

  useEffect(()=>{
    props.getEventDetails(eventId)
  },[eventId])

  const [currentIndex, setCurrentIndex] = useState(0);
  const [fullscreen, setFullscreen] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const [sponsors, setSponsors] = useState([]);
  const [len, setLen] = useState(0);

  const updateDataListPeriodically = useCallback(()=>{
    if (!autoScroll) return;
    const futureStartIndex = currentIndex + DEFAULT_LIST_LENGTH;
    const futureEndIndex = futureStartIndex + DEFAULT_LIST_LENGTH;
    if (futureStartIndex === teamsData.length){
      setCurrentIndex(0);
    } else if(futureEndIndex >= teamsData.length){
      setCurrentIndex(Math.max(0, teamsData.length - DEFAULT_LIST_LENGTH));
    } else if(teamsData.length > futureEndIndex){
      setCurrentIndex(futureStartIndex);
    }
  }, [currentIndex, teamsData, autoScroll])

  const fetchPublicLeaderboardData = useCallback(()=>{
    props.getLeaderboardData(leaderboardId, false)
  },[leaderboardId]);

  useEffect(()=>{
    fetchPublicLeaderboardData()
  },[leaderboardId])

  useEffect(()=>{
    const updateInterval = setInterval(() => {
      updateDataListPeriodically()
    }, DEFAULT_REFRESH_INTERVAL);
    const updateDataInterval = setInterval(() => {
      fetchPublicLeaderboardData();
    }, DEFAULT_FETCH_DATA_INTERVAL);
    return ()=>{
      clearInterval(updateInterval);
      clearInterval(updateDataInterval);
    }
  },[updateDataListPeriodically])

  useEffect(() => {
    if (eventScheduleId) {
      const fetchSponsors = async () => {
        const sponsorsData = await getAllSponsorsForPublicLeaderBoard(eventScheduleId);
        setSponsors(sponsorsData);
      }
      fetchSponsors();
    }
  }, [eventScheduleId])

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
    },
    {
      width: 410,
      title: 'Team Number and Name',
      dataIndex: 'numberName',
      key: 'numberName',
    },
    {
      title: 'High Score',
      dataIndex: 'highScore',
      key: 'highScore',
    },
    {
      title: 'Match 1',
      dataIndex: 'match1Score',
      key: 'match1Score',
    },
    {
      title: 'Match 2',
      dataIndex: 'match2Score',
      key: 'match2Score',
    },
    {
      title: 'Match 3',
      dataIndex: 'match3Score',
      key: 'match3Score',
    },
  ];

  const indexedData = teamsData.slice(currentIndex, currentIndex + DEFAULT_LIST_LENGTH);

  const toggleAutoScroll = (checked)=>{
    setAutoScroll(checked)
  }

  const toggleFullScreen = ()=>{
    const bodyElement = document.querySelector('#root');
    console.log(document.webkitExitFullscreen, bodyElement.webkitRequestFullscreen)
    if(fullscreen){
      setFullscreen(false);
      bodyElement.classList.remove('fullscreen')
      if (document.webkitExitFullscreen) document.webkitExitFullscreen();
      else document.exitFullscreen();
    }else{
      setFullscreen(true);
      bodyElement.classList.add('fullscreen')
      if (bodyElement.webkitRequestFullscreen) bodyElement.webkitRequestFullscreen();
      else bodyElement.requestFullscreen();
    }
  }

  const isLoading = isFetchingEventDetails || isFetchingLeaderboard;

  if(isLoading){
    return <ScreenLoading loading={true}/>
  }
  

  const eventPage = new SingleEvent(eventDetails, included);
  const eventDateRange = eventPage.parseStartEndTimeShortenedString();
  const eventLocation = eventPage.getEventLocation();
  
  return (
    <div className="public-leaderboard-wrapper">
      <div className="public-leaderboard-wrapper-header">
        <EventProgramLogos programId={eventProgramId} />
        <div className="title-wrapper">
          <div className="title">{eventPage.name}</div>
          <div className="subtitle">{eventDateRange}   •   {eventLocation}</div>
        </div>
        <img src={FirstLogo} alt="FIRST Season Logo" className="dashboard-layout__first-logo" />
      </div>
      <div className="fullscreen-link">
        <div className="auto-scorll-toggler">
          <div className={`switch-title ${!autoScroll ? 'unchecked' : ''}`}>Auto Scrolling</div>
          <Switch checked={autoScroll} onChange={toggleAutoScroll}/>
        </div>
        <Button type="link" onClick={toggleFullScreen}>{fullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}</Button>
      </div>
      <div className="table-wrapper">
        <Table columns={columns} dataSource={indexedData} className="public-leaderboard-table" bordered={false} pagination={false}/>
      </div>
      <div className='sponsor-logos-wrapper'>
        <GlobalSponsors len={len} eventProgramId={eventProgramId}/>
        <EventsLogo len={len} setLen={setLen} sponsors={sponsors} />
      </div>
    </div>
  )
}

const mapStateToProps = state =>{
  const {eventLeaderboard: {teams, isFetchingLeaderboard}, event: {eventDetails}} = state;
  return {teams, eventDetails, isFetchingLeaderboard}
}

const mapDispatchToProps = {
  getLeaderboardData: fetchEventLeaderboard,
  getEventDetails: handleEventDetails
}
export default connect(mapStateToProps, mapDispatchToProps)(PublicLeaderBoard)