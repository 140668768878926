import React from 'react';
import PropTypes from 'prop-types';

const ButtonRefreshIcon = props => {
  return (
    <svg width="30" height="32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.166 21.331a8.068 8.068 0 01-4.891 3.78 7.903 7.903 0 01-6.088-.777 7.903 7.903 0 01-3.718-4.883 8.078 8.078 0 01.829-6.127 8.073 8.073 0 014.891-3.78 7.912 7.912 0 016.088.778c.007.004-2.646 1.132-2.646 1.132a1.012 1.012 0 00-.546 1.315.993.993 0 001.304.537l4.783-2.012c.512-.216.757-.805.545-1.315l-1.989-4.866a.99.99 0 00-1.303-.537 1.012 1.012 0 00-.546 1.316l1.008 2.465a9.78 9.78 0 00-3.369-1.04 9.974 9.974 0 00-3.834.286 10.06 10.06 0 00-3.461 1.71 10.113 10.113 0 00-2.649 3.013 10.113 10.113 0 00-1.285 3.8 10.089 10.089 0 001.92 7.316 9.832 9.832 0 002.974 2.636 9.832 9.832 0 003.77 1.258 9.89 9.89 0 003.83-.282 10.06 10.06 0 003.46-1.71 10.113 10.113 0 002.649-3.013c.281-.482.119-1.1-.357-1.374a1.003 1.003 0 00-1.37.374z"
        fill="#0066B3"
        fillRule="evenodd"
      />
    </svg>
  );
};

ButtonRefreshIcon.propTypes = {
  
};

export default ButtonRefreshIcon;