import React from 'react';
import EventContainer from 'features/EventContainer';

const Event = (props) => {

  return (
    <div>
      <EventContainer {...props} />
    </div>
  );
};

export default Event;