import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import DocumentIcon from 'shared/components/Icon/icons/Document';
import PlayButtonIcon from 'shared/components/Icon/icons/PlayButton';
import ImageIcon from 'shared/components/Icon/icons/Image';
import CloseCircle from 'shared/components/Icon/icons/CloseCircle';
import './FileCard.scss';
import { getFileExtension, trimFileName, getFileIconType } from 'utils/fileUpload';
import { useTranslation } from 'react-i18next';

const FileCard = props => {
  const {t} = useTranslation()
  const {
    wide,
    fileType,
    fileName,
    fileId,
    imageUrl,
    fileExtension,
    hasDeleteBtn,
    onDelete,
    error,
    status,
    modal
  } = props;

  const extension = fileName ? getFileExtension(fileName) : undefined;
  const iconType = getFileIconType(extension);

  const renderIcon = () => {
    switch (iconType) {
      case 'video':
        return PlayButtonIcon;
      case 'image':
        return ImageIcon;
      case 'document':
        return DocumentIcon;
      default:
        return DocumentIcon;
    }
  }

  const renderImgCard = () => {

    if(status.fileStatus == 2){
      return <p className='file-card__errortext'>There was  an error uploading the file, please try again.</p>
    }

    if(status.fileStatus == 4 || status.fileStatus == 1 || status.fileStatus == 0){
      return <p className='file-card__errortext'>{t('sponsorUploadInProgressLabel')}</p>
    }

    if(status.fileStatus == 5 || status.fileStatus == 3){
      return <p className='file-card__errortext'>{t('sponsorUploadFailedLabel')}</p>
    }

  //  if(status.fileStatus == 3){
    //  return <p className='file-card__errortext'>Virus scan failed. Please upload another logo.</p>
   // }

    if(imageUrl != '' && status.fileStatus == 6){
      return <img width="100%" height="auto" src={imageUrl}></img>
    }

    if(imageUrl == ''){
      return <Icon component={renderIcon()} className={`file-card__icon file-card__icon--${iconType}`} />
    }
    
  }

  //       <a download href="https://sk2ga9a3kg.execute-api.eu-central-1.amazonaws.com/api/session_documents/9020badc-4187-4909-bf1d-bf5e57a15daa/files/69b71e0b-9fd8-415a-9fbb-206b1db19dc4">test</a>

  const fileNameClassName = 'paragraph--normal paragraph--charcoal paragraph--medium';
  const extensionClassName = 'paragraph--normal paragraph--charcoal paragraph--small';

  if(status && status.fileStatus == 3 && modal == false){
    return (
      <div className={`file-card ${status.fileStatus == 3 && 'file-card--bggrey'} ${wide ? 'file-card--wide' : ''}`}></div>
    )
  }

  return (
    <div className={`file-card ${wide ? 'file-card--wide' : ''}`}>

      {status && renderImgCard()}
      
      <div className='file-card__last-row'>
        <p className={extensionClassName}>{wide ? trimFileName(fileName) : fileName}.{extension}</p>
        {hasDeleteBtn && (
          <Button type='link' className='file-card__btn' onClick={onDelete}>
            <CloseCircle />
            {' '}
          </Button>
        )}
      </div>
    </div>
  );
};

FileCard.propTypes = {
  fileName: PropTypes.string,
  fileType: PropTypes.string,
  fileExtension: PropTypes.string,
  imageUrl: PropTypes.string,
  hasDeleteBtn: PropTypes.bool,
  error: PropTypes.bool,
  onDelete: PropTypes.func,
  status: PropTypes.object,
  modal: PropTypes.bool
};

FileCard.defaultProps = {
  fileName: 'Sharks: Main video of practice match',
  fileType: 'video',
  fileExtension: '.mpg',
  imageUrl: '',
  hasDeleteBtn: false,
  error: false,
  onDelete: () => { },
  status: null,
  modal: false
};

export default FileCard;