import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../styles/ScheduleContainer/ScheduleInstructions.scss';

const ScheduleInstructions = () => {
  const { t } = useTranslation()
  return (
    <div className='event-schedule-instructions'>
      <p className='paragraph--bold paragraph--charcoal paragraph--medium'>{t('instructionsLabel')}</p>
      <p className='paragraph--400 paragraph--charcoal paragraph--medium'>{t('scheduleInstructionText')}</p>
    </div>
  );
};

export default ScheduleInstructions;
