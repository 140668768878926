import React from 'react';
import ScreenLayout from 'shared/components/Layouts/ScreenLayout';
import Heading from 'shared/components/Heading';
import ListLoading from './ListLoading';
import './styles/ScreenLoading.scss';

export default ({ loading, title, subTitle }) => {
  return loading ? (
    <ScreenLayout>
      <div className='c-screenloading'>
        <Heading tag={2} weight='normal'><span>{title}</span></Heading>
        <ListLoading loading text={subTitle} />
      </div>
    </ScreenLayout>
  ) : null;
};
