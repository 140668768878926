import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './SessionPanelSelect.scss';
import UserRow from 'shared/components/UserRow';
import PanelRow from '../PanelRow';
import { Col, Row, Select } from 'antd';
import CloseCircle from '../Icon/icons/CloseCircle';
// import Dropdown from 'shared/components/Dropdown';
import Icon from 'shared/components/Icon'
import { Tooltip } from 'antd';
const { Option } = Select;


const SessionPaneSelect = props => {
    const { panelList, panelType } = props;
    const { t } = useTranslation()
    // const displayText = noPanelsText && noPanelsText.length ? noPanelsText : t('noRefereePanelChosenLabel')

    let panels = panelList
    const [selectedPanels, setSelectedPanels] = useState([]);

    const onSelectPanel = (selectedPanle) => {
        let currentSelected = [...selectedPanels];
        panels.forEach(panel => {
            if (panel.id == selectedPanle) {
                currentSelected.push(panel)
            }
        });
        setSelectedPanels(currentSelected);
        props.onChange(currentSelected);
    }

    const isDisabled = (id) => {
        let disabled = false;
        selectedPanels.forEach(panel => {
            if (id == panel.id) {
                disabled = true
            }
        });

        return disabled;
    }

    const removeSelection = (selectedPanle) => {
        let currentSelected = [...selectedPanels];
        currentSelected.forEach((panel, index) => {
            if (panel.id == selectedPanle.id) {
                currentSelected.splice(index, 1)
            }
        });
        setSelectedPanels(currentSelected);
    }

    const arrayToNameOnlyText = (referees) => {
        let newArray = referees.slice(1)
        let refNames = newArray.map((referee) => referee.fullName.trim())
        return refNames.join(', ')
    }

    useEffect(() => {
        props.fullData(selectedPanels)
    }, [selectedPanels])


    // if (!users.length) {
    //     return (
    //         <div className='session-panel-users'>
    //             <p className='session-panel-users__title'>{panelName}</p>
    //             <p className='session-panel-users__subtitle'>{displayText}</p>
    //         </div>
    //     )
    // }
    return (
        <>
            <div className='selected-panel-wrapper'>
                <div className='m-r-10'>
                    <Select defaultValue="" className='panel-select-dropdown' onSelect={(e) => { onSelectPanel(e) }} suffixIcon={<Icon type='downArrowBlue' />}>
                        <Option value="">{t('addPanelLabel')}</Option>
                        {panels.map(panel => (
                            <Option value={panel.id} disabled={isDisabled(panel.id)}>{panel.name}</Option>
                        ))}
                    </Select>
                    {/* <Dropdown placeholder={t('Add Panel')} additionalClassNames='panel-select-dropdown mobile-compatible m-l-4' onChange={(e) => { onSelectPanel(e) }}>
                        {panels.map(panel => (
                            <Option value={panel.id} disabled={isDisabled(panel.id)}>{panel.panelName}</Option>
                        ))}
                    </Dropdown> */}
                </div>
                <div style={{ width: '100%' }}>
                    {selectedPanels.map(panel => (
                        <div className='panel'>
                            <div className='remove' onClick={() => { removeSelection(panel) }}><CloseCircle></CloseCircle></div>
                            <div className='title'>{panel.name}</div>
                            <div className='subtitle'>
                                {panel[panelType][0]?.fullName !== undefined ? panel[panelType][0]?.fullName : panelType == "referees" ? 'Empty table' : 'Empty room'} {panel[panelType]?.length > 1 &&
                                    <Tooltip placement="bottom" title={arrayToNameOnlyText(panel[panelType])}>
                                        <span className='addition'>+{panel[panelType]?.length - 1}</span>
                                    </Tooltip>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* <div className='panels'>
                <div className='panel'>
                    <div className='panel-title'></div>
                    <div className='panel-desc'></div>
                </div>
            </div> */}

            {/* {a.map(d => (
                <div style={{ display: 'flex' }}>
                    <div>asd</div>
                    <div>bsd</div>
                </div>
                <div className='session-panel-users'>
                    <div className='session-panel-users__content'>
                        <div className='session-panel-users__user' key={d.id}>
                            <PanelRow hideEmail={hideEmail} hideAvatar={hideAvatar} fullName={'Panel 1'} email={'firstroboticstest6@gmail.com'} />
                        </div>
                    </div>
                </div>
            ))} */}
        </>
    );
};

SessionPaneSelect.propTypes = {
    panelList: PropTypes.array,
    onChange: PropTypes.func,
    fullData: PropTypes.func,
    panelType: PropTypes.string
};

SessionPaneSelect.defaultProps = {
    panelList: [],
    panelType: 'referees',
    onChange: () => { },
    fullData: () => { },
}

export default SessionPaneSelect;
