import {parseDate, parseDateString, parseFullDate, parseTime,todayMomentObject, momentize} from 'utils/dateTimeUtils';
import * as eventRolesService from 'utils/services/eventRoles';
import {formatUserEventRoleTypesList} from 'utils/services/eventRoles/model';
import * as eventRoleIds from 'shared/constants/eventRoleTypes';
import * as panelService from 'utils/services/panels';
import * as teamService from 'utils/services/teams';
import { notification } from 'antd';


import * as sessionService from 'utils/services/sessions';
import Session from 'models/ScheduleSession';
import ScheduleTimestamp from 'models/ScheduleTimestamp';
import * as meetingService from 'utils/services/meetings';
import * as scheduleService from 'utils/services/schedules';
import * as types from '../types/eventOverviewTypes';
import _ from 'lodash';

const setScheduleBegin = () => {
  return {
    type: types.SET_EVENT_OVERVIEW_SCHEDULE_DETAILS_BEGIN
  }
}

const setScheduleError = () => {
  return {
    type: types.SET_EVENT_OVERVIEW_SCHEDULE_DETAILS_ERROR
  }
}

const setScheduleSuccess = (data, sessionTeams) => {
  return {
    type: types.SET_EVENT_OVERVIEW_SCHEDULE_DETAILS_SUCCESS,
    payload: {
      data,
      sessionTeams
    }
  }
}

const getEventOverviewSchedule = (args) => {

  const {
    userEventRoleIds,
    scheduleId,
    teamId,
    eventId
  } = args;

  // console.log({   userEventRoleIds,
  //   scheduleId,
  //   teamId,
  //   eventId})
  return async (dispatch, getState) => {

    await dispatch(setScheduleBegin())
    
    const {auth: {userId}, eventOverview: {eventScheduleData}} = getState();

    const time_zone = ((eventScheduleData||{}).attributes||{}).time_zone

    /*

    if a user is a head ref ,they can see all scoring sessions
    if a user is a judge advisor ,they can see all judging sessions
    if a user is a coach, they can see all sessions that relate to their team
    If I am referee or judge, then I can only see the sessions that are on my panels.
    If I am a coach, I can only see the sessions that relate to my team
    all users can see meetings related to them, opening and closing ceremony, submission deadlines for scoring/judging

    schedule Obj

    {
      id: 123,
      title: ,
      subtitle: ,
      date:
      startTime,
      endTime,
      type

    }

    
    */



    const scoringSessions = [];
    const judgingSessions = [];
    const attendeeMeetings = [];
    const teamMeetings = [];
    const timestampSessions = [];
    const ceremonySessions = []

    try {
   
      
      const isCoach = userEventRoleIds.includes(eventRoleIds.COACH_ID);
      const isJudge = userEventRoleIds.includes(eventRoleIds.JUDGE_ID);
      const isJudgeAdvisor = userEventRoleIds.includes(eventRoleIds.JUDGE_ADVISOR_ID);
      const isReferee = userEventRoleIds.includes(eventRoleIds.REFEREE_ID);
      const isHeadReferee = userEventRoleIds.includes(eventRoleIds.HEAD_REFEREE_ID);
 
      // ENDPOINT CALLS FOR ALL VOLUNTEERS 
      const openingRes = await scheduleService.getScheduleOpeningSession(scheduleId);
      if (openingRes.data) {
        const {attributes, id, type} = openingRes.data;
        const details = new Session(openingRes.data, openingRes.included)
        ceremonySessions.push({
          isoString: attributes.start_time,
          startTime: parseTime(attributes.start_time, time_zone),
          startDate: parseDateString(attributes.start_time, time_zone),
          endTime: parseTime(attributes.end_time, time_zone),
          url: details.getExternalLink(),
          title: details.getSessionTypeName(),
          id,
          type,
          typeId: isCoach?details.sessionTypeId:undefined,
          sessionTypeId: details.sessionTypeId,
          sessionTypeName: details.getSessionTypeName(),
          sessionStatus: details.getSessionStatusName(),
          sessionStatusId: details.sessionStatusId,
          iconType: 'opening'
        })

      }
      const closingRes = await scheduleService.getScheduleClosingSession(scheduleId);
      if (closingRes.data) {
        const {attributes, id, type} = closingRes.data;
        const details = new Session(closingRes.data, closingRes.included)
        ceremonySessions.push({
          isoString: attributes.start_time,
          startTime: parseTime(attributes.start_time, time_zone),
          startDate: parseDateString(attributes.start_time, time_zone),
          endTime: parseTime(attributes.end_time, time_zone),
          url: details.getExternalLink(),
          title: details.getSessionTypeName(),
          id,
          type,
          typeId: isCoach? details.sessionTypeId : undefined,
          sessionTypeId: details.sessionTypeId,
          sessionTypeName: details.getSessionTypeName(),
          sessionStatus: details.getSessionStatusName(),
          sessionStatusId: details.sessionStatusId,
          iconType: 'closing',
        })
      }

      // TIMESTAMPS

      const scoringTimeStampsRes = await scheduleService.getScheduleScoringTimestamps(scheduleId);
      if (scoringTimeStampsRes.data) {
        scoringTimeStampsRes.data.forEach(d => {
          const {attributes, id, type} = d;
          const details = new ScheduleTimestamp(d, scoringTimeStampsRes.included)
          timestampSessions.push({
            isoString: attributes.target_date_time,
            startTime: parseTime(attributes.target_date_time, time_zone),
            startDate: parseDateString(attributes.target_date_time, time_zone),
            endTime: undefined,
            url: undefined,
            title: details.getTimestampDescription(),
            id,
            type,
            typeId: isCoach ? details.timestampTypeId : null,
            sessionTypeId: details.sessionTypeId,
            sessionTypeName: undefined,
            sessionStatus: undefined,
            sessionStatusId: undefined,
            iconType: 'scoring',
            isTimestamp: true
          })

        })
      }
      const judgingTimestampsRes = await scheduleService.getScheduleJudgingTimestamps(scheduleId);
      if (judgingTimestampsRes.data) {
        judgingTimestampsRes.data.forEach(d => {
          const {attributes, id, type} = d;
          const details = new ScheduleTimestamp(d, judgingTimestampsRes.included)
          timestampSessions.push({
            isoString: attributes.target_date_time,
            startTime: parseTime(attributes.target_date_time, time_zone),
            startDate: parseDateString(attributes.target_date_time, time_zone),
            endTime: undefined,
            url: undefined,
            title: details.getTimestampDescription(),
            id,
            type,
            typeId: isCoach ? details.timestampTypeId : undefined,
            sessionTypeId: details.timestampTypeId,
            sessionTypeName: undefined,
            sessionStatus: undefined,
            sessionStatusId: undefined,
            iconType: 'judging',
            isTimestamp: true
          })

        })
      }

    
    
      // GET THE SCORING SESSIONS ASSOCIATED WITH THE USER
      if (isHeadReferee) {
        const scoringSessionsRes = await scheduleService.getScheduleScoringSessions(scheduleId);
        scoringSessionsRes.forEach(session => {
          scoringSessions.push({
            isoString: session.startTime,
            startTime: parseTime(session.startTime, time_zone),
            startDate: parseDateString(session.startTime, time_zone),
            endTime: parseTime(session.endTime, time_zone),
            url: session.externalLinkUrl,
            title: session.title,
            id: session.id,
            type: 'session',
            sessionTypeName: session.sessionType,
            sessionStatus: session.sessionStatus,
            sessionStatusId: session.sessionStatusId,
            sessionTypeId: session.sessionTypeId,
            iconType: 'scoring',
            teamId: session?.team?.id,
            sessionTeamName: session?.team?.name,
          })
        })
      }  
      if (isReferee && !isHeadReferee) {
        // const scoringSessionsFilter = {
        //   'schedule.id': `eq:${scheduleId}`,
        //   'panel.type.role_assignments.user.id': `eq:${userId}`
        // }
        const scoringSessionsRes = await scheduleService.getScheduleScoringSessions(scheduleId);
        const panelsList = scoringSessionsRes.filter(i => i.panelId);
        const panelsIds = _.uniq(panelsList.map(p => p.panelId))
        const panelsResponse = await panelService.getPanelsByPanelsIdsAndJudgeId(panelsIds);

        const panelsIncluded = panelsResponse.included;

        const panelsSessions = panelsIncluded.filter(i => i.type === 'session');
        const panelsSessionsIds = panelsSessions.map(i => i.id);
      
        scoringSessionsRes.forEach(session => {
          const assignedSession = panelsSessionsIds.find(i => session.id === i);
          if (assignedSession) {
            scoringSessions.push({
              isoString: session.startTime,
              startTime: parseTime(session.startTime, time_zone),
              startDate: parseDateString(session.startTime, time_zone),
              endTime: parseTime(session.endTime, time_zone),
              url: session.externalLinkUrl,
              title: session.title,
              id: session.id,
              type: 'session',
              sessionTypeName: session.sessionType,
              sessionStatus: session.sessionStatus,
              sessionStatusId: session.sessionStatusId,
              sessionTypeId: session.sessionTypeId,
              teamId: session?.team?.id,
              iconType: 'scoring',
              sessionTeamName: session?.team?.name,
            })
          }
        })

      }

        // GET THE JUDGING SESSIONS ASSOCIATED WITH THE USER
        if (isJudgeAdvisor) {
          const judgingSessionsRes = await scheduleService.getScheduleJudgingSessions(scheduleId);
          judgingSessionsRes.data.forEach(d => {
            const judgingSessionDetails = new Session(d, judgingSessionsRes.included);
            const {attributes, id, type} = d;
            judgingSessions.push({
              isoString: attributes.start_time,
              startTime: parseTime(attributes.start_time, time_zone),
              startDate: parseDateString(attributes.start_time, time_zone),
              endTime: parseTime(attributes.end_time, time_zone),
              url: judgingSessionDetails.getExternalLink(),
              title: judgingSessionDetails.getSessionTypeName(),
              notes: attributes.notes,
              id,
              type,
              sessionTypeId: judgingSessionDetails.sessionTypeId,
              sessionTypeName: judgingSessionDetails.getSessionTypeName(),
              sessionStatus: judgingSessionDetails.getSessionStatusName(),
              sessionStatusId: judgingSessionDetails.sessionStatusId,
              iconType: 'judging',
              teamId: judgingSessionDetails.teamId,
              sessionTeamName: judgingSessionDetails.getTeamName()
            })
          })
        }
        if (!isJudgeAdvisor && isJudge)  {
          const judgingSessionsRes = await scheduleService.getScheduleJudgingSessions(scheduleId);

          const panelsList = judgingSessionsRes.included.filter(i => i.type === 'panel');
          const panelsIds = panelsList.map(p => p.id)
          const panelsResponse = await panelService.getPanelsByPanelsIdsAndJudgeId(panelsIds);

          const panelsIncluded = panelsResponse.included;

          const panelsSessions = panelsIncluded.filter(i => i.type === 'session');
          const panelsSessionsIds = panelsSessions.map(i => i.id);
        
          judgingSessionsRes.data.forEach(j => {
            const assignedSession = panelsSessionsIds.find(i => j.id === i);
            if (assignedSession) {
              const judgingSessionDetails = new Session(j, judgingSessionsRes.included);
              const {attributes, id, type} = j;
              judgingSessions.push({
                isoString: attributes.start_time,
                startTime: parseTime(attributes.start_time, time_zone),
                startDate: parseDateString(attributes.start_time, time_zone),
                endTime: parseTime(attributes.end_time, time_zone),
                url: judgingSessionDetails.getExternalLink(),
                title: judgingSessionDetails.getSessionTypeName(),
                notes: attributes.notes,
                id,
                type,
                sessionTypeId: judgingSessionDetails.sessionTypeId,
                sessionTypeName: judgingSessionDetails.getSessionTypeName(),
                sessionStatus: judgingSessionDetails.getSessionStatusName(),
                sessionStatusId: judgingSessionDetails.sessionStatusId,
                iconType: 'judging',
                teamId: judgingSessionDetails.teamId,
                sessionTeamName: judgingSessionDetails.getTeamName(),       
              })
            }
          })
        }

        if (isCoach && !isHeadReferee && !isJudgeAdvisor && teamId ) {
          //coach judge sessions
          // const coachSessionsRes = await sessionService.getJudgingSessions(scheduleId);
          const {judging_sessions: coachSessionsRes} = await sessionService.getJudgingSessions(scheduleId);
          // const filteredDataByTeamId = ((coachSessionsRes || {})?.data || []).filter(item => ((((item || {}).relationships || {}).team || {}).data || {}).id === teamId)
          const filteredDataByTeamId = (coachSessionsRes || []).filter(item => item.teamId == teamId)
          filteredDataByTeamId.forEach(session => {
            judgingSessions.push({
              isoString: session.startTime,
              startTime: parseTime(session.startTime, time_zone),
              startDate: parseDateString(session.startTime, time_zone),
              endTime: parseTime(session.endTime, time_zone),
              url: session.externalLinkUrl,
              title: session.title,
              id: session.id,
              type: 'session',
              sessionTypeName: session.sessionType,
              sessionStatus: session.sessionStatus,
              sessionStatusId: session.sessionStatusId,
              sessionTypeId: session.sessionTypeId,
              teamId: session?.team?.id,
              iconType: 'scoring',
              sessionTeamName: session?.team?.name,    
            })
          })
          
          //coach scoring sessions
          const coachScoreSessionsRes = await sessionService.getScoringSessions(scheduleId);
          const filteredScoreDataByTeamId = ((coachScoreSessionsRes||{}).data||[]).filter(item=>((((item||{}).relationships||{}).team||{}).data||{}).id === teamId)
          filteredScoreDataByTeamId.forEach(r => {
            const coachScoreSessionDetails = new Session(r, coachScoreSessionsRes.included);
            const {attributes, id, type} = r;

            const sessionType = coachScoreSessionDetails.getSessionTypeName()
            let sessionIconType = '';
            if (sessionType) {
              const isScoringSession = sessionType.toLowerCase().includes('referee');
              sessionIconType = isScoringSession ? 'scoring' : 'judging'
            }
            scoringSessions.push({
              isoString: attributes.start_time,
              startTime: parseTime(attributes.start_time, time_zone),
              startDate: parseDateString(attributes.start_time, time_zone),
              endTime: parseTime(attributes.end_time, time_zone),
              url: coachScoreSessionDetails.getExternalLink(),
              title: coachScoreSessionDetails.getSessionTypeName(),
              notes: attributes.notes,
              id,
              type,
              typeId: coachScoreSessionDetails.sessionTypeId,
              sessionTypeId: coachScoreSessionDetails.sessionTypeId,
              sessionTypeName: coachScoreSessionDetails.getSessionTypeName(),
              sessionStatus: coachScoreSessionDetails.getSessionStatusName(),
              sessionStatusId: coachScoreSessionDetails.sessionStatusId,
              iconType: sessionIconType,
              teamId: coachScoreSessionDetails.teamId,
              sessionTeamName: coachScoreSessionDetails.getTeamName(),       
            })
          })
        }


        // if a user is a head ref ,they can see all scoring sessions
        // IF A USER IS NOT A COACH GET ALL THE MEETINGS THEY ARE ATTENDEES FOR
        
          const attendeeMeetingsRes = await meetingService.getMeetingsBy(scheduleId);
          attendeeMeetingsRes.data.forEach(d => {
            const {attributes, id, type} = d;
            const meetingLinkId = (d.relationships.external_link && d.relationships.external_link.data) ? d.relationships.external_link.data.id : undefined;
            let externalLink;
            const externalLinkDetails = attendeeMeetingsRes.included.find(i => i.id === meetingLinkId);
            if (externalLinkDetails) {
              externalLink = externalLinkDetails.attributes ? externalLinkDetails.attributes.url : '';
            }
            attendeeMeetings.push({
              isoString: attributes.start_time,
              startTime: parseTime(attributes.start_time, time_zone),
              startDate: parseDateString(attributes.start_time, time_zone),
              endTime: parseTime(attributes.end_time, time_zone),
              url: externalLink,
              title: attributes.title,
              notes: attributes.notes,
              id,
              type,
              iconType: 'meeting'
            })
          })
        

        // if (isCoach && teamId) {

        //   const teamMeetingsRes = await meetingService.getMeetingsBy(scheduleId);
        //   teamMeetingsRes.data.forEach(d => {
        //     const {attributes, type} = d;
        //     const meetingLinkId = (d.relationships.external_link && d.relationships.external_link.data) ? d.relationships.external_link.data.id : undefined;
        //     let externalLink;
        //     const externalLinkDetails = teamMeetingsRes.included.find(i => i.id === meetingLinkId);
        //     if (externalLinkDetails) {
        //       externalLink = externalLinkDetails.attributes ? externalLinkDetails.attributes.url : '';
        //     }
        //     teamMeetings.push({
        //       isoString: attributes.start_time,
        //       startTime: parseTime(attributes.start_time, time_zone),
        //       startDate: parseDateString(attributes.start_time, time_zone),
        //       endTime: parseTime(attributes.end_time, time_zone),
        //       url: externalLink,
        //       title: attributes.title,
        //       notes: attributes.notes,
        //       id: d.id,
        //       type,
        //       iconType: 'meeting'
        //     })
        //   })

        // } else if (isCoach && !teamId) {
        //   notification.error({
        //     message: 'Error',
        //     description: 'You are not a coach of any team for this event.',
        //   });
        // }
  
      
      // const successData = {
      //   judgingSessions, scoringSessions, attendeeMeetings, teamMeetings
      // }
      const scheduleTeamIds = [];

    
    const formattedEventSchedule = [];

    const completeScheduleList = [...judgingSessions, ...scoringSessions, ...attendeeMeetings, ...teamMeetings, ...ceremonySessions, ...timestampSessions];

    completeScheduleList.forEach(session => {
      if (session.teamId && !scheduleTeamIds.includes(session.teamId)) {
        scheduleTeamIds.push(session.teamId)
      }
    })
    // console.log({scheduleTeamIds})
    completeScheduleList.forEach(session => {
      let currentScheduleDay;

      if (session.isoString) {
        const sessionDateObj = parseFullDate(session.isoString, time_zone);
        const temp_twentyTwoHoursBefore = time_zone ? momentize(session.isoString, time_zone) : momentize(session.isoString);
        const twentyTwoHoursBefore = temp_twentyTwoHoursBefore ? temp_twentyTwoHoursBefore.subtract(22, 'hours') : false
        const isJoinLinkDisabled = twentyTwoHoursBefore ? todayMomentObject().isBefore(twentyTwoHoursBefore) : false;

        const dayAlreadyAddedIndex = formattedEventSchedule.findIndex(i => i.startDate === session.startDate);
        const isDayAlreadyAdded = dayAlreadyAddedIndex > -1;

        if (isDayAlreadyAdded) {
          currentScheduleDay = formattedEventSchedule[dayAlreadyAddedIndex];
          const sessionAlreadyAddedIndex = currentScheduleDay.sessions.findIndex(i => i.id === session.id);
          const isSessionAlreadyAdded = sessionAlreadyAddedIndex > -1;
          if (!isSessionAlreadyAdded) {
            currentScheduleDay = {
              ...currentScheduleDay,
              sessions: [...currentScheduleDay.sessions, {...session, isJoinLinkDisabled}].sort((a, b) => a.isoString.localeCompare(b.isoString))
            }
            formattedEventSchedule[dayAlreadyAddedIndex] = currentScheduleDay;
          }
        } else {
          currentScheduleDay = {
            startDate: session.startDate,
            date: session.isoString,
            dateInfo: sessionDateObj,
            sessions: [
              {
              ...session,
              isJoinLinkDisabled
            }]
          }
          formattedEventSchedule.push(currentScheduleDay);
        }
      }
    })

    await dispatch(setScheduleSuccess(formattedEventSchedule.sort((a, b) => a.date.localeCompare(b.date)), []))
  
    } catch(err) {
      await dispatch(setScheduleError())
      throw err;
    }

    
  
  }
  
}

export default getEventOverviewSchedule;