import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Row, Col, Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'shared/components/FormDropdown';
import getJudgingPanels from 'features/EventContainer/redux/actions/judgingActions/getJudgingPanels';
import EventModal from 'features/EventContainer/components/EventModal';
import { handleResetAddToPanelList } from 'features/PanelManagement/redux/actions/judges';
import {
  handleResetTeamsAddToPanelList,
} from 'features/PanelManagement/redux/actions/teams';
import handleCreateNewJudgingPanel from 'features/EventContainer/redux/actions/judgingActions/createNewJudgingPanel';
import PanelTitle from './PanelTitle';
import Input from 'shared/components/Input';

import '../../../../../features/PanelManagement/styles/JudgingPanelModal.scss';

import {
  FTC_ID
} from 'shared/constants/programIds';

const FormItem = Form.Item;
const SelectOption = Select.Option;

const rowProps = {
  type: 'flex', gutter: 20,
  className: 'm-t-20'
};

const colProps = {
  xs: 24,
  md: 16
}

const PanelModal = (props) => {
  const {
    visible,
    onCancel,
    isEdit,
    resetJudgesList,
    resetTeamsList,
    judgeOptions,
    sessionOptions,
    panelTitle,
    panelSubtitle,
    toolTipInfo,
    eventProgramId,
    newPanelTitle,
    isCreatingNewJudgingPanel,
    judgingPanels,
    is_FLLC_event
  } = props;
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const findUniquePanelNames = (rule, value, callback) => {

    if (value === undefined) callback()
    
    let match = false;

    judgingPanels.map((panel) => {
      let panelName = panel.panelName.trim().toLowerCase();
      if (panelName == value.trim().toLowerCase()) {
        match = true;
      }
    })

    if (match) {
      callback('Room name already exists')
    } else {
      callback();
    }
  }

  const validateSelectedJudgesCount = (rule, value, callback) => {
    if (!Array.isArray(value) || value.length <= 1) {
      callback('At least two judges must be assigned to the panel.')
    } else {
      callback()
    }
  }

  const handleCloseModal = () => {
    onCancel();
    form.resetFields();
    resetJudgesList();
    resetTeamsList();
  }

  const successCb = () => {
    handleCloseModal()
  }

  const handleSave = async (e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields().then(async (values, err) => {
      const payload = {
        panelName: values.roomName,
        judgeIds: values.judges || [],
        sessionIds: values.sessions || []
      }

      await props.handleCreateNewJudgingPanel(payload, successCb)
    });
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  }

  const generatePanelsCommaSeparated = (panels) => {
    const panelsArray = panels.map((panel) => {
      return panel.panelName
    })

    let renderPanels = panelsArray.join(", ")
    return renderPanels
  }

  return (
    <EventModal
      visible={visible}
      onCancel={handleCloseModal}
      closable={!isCreatingNewJudgingPanel}
    >
      <PanelTitle instructions={toolTipInfo} panelSubtitle={panelSubtitle} title={panelTitle} isEdit={isEdit} />

      <Form hideRequiredMark onFinish={handleSave} onFinishFailed={onFinishFailed} name='AddJudge' form={form}>

        <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem name='roomName' label={<span>{t('Room name')}</span>} rules={[{ required: true, message: t('Enter room name') }, { validator: findUniquePanelNames }]}>
              <Input size='large' placeholder={'Name'} optionLabelProp="label" optionFilterProp='label' />
            </FormItem>
          </Col>
        </Row>

        <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem name='judges' label={is_FLLC_event ? t('judgesLabel') : t('nonChallengeJudgesLabel')}>
                <Select
                  showSearch
                  filterOption={false}
                  mode='multiple'
                  optionLabelProp="label"
                  optionFilterProp='label'
                  className='judging-modal-judges-tab__add-select'
                  popUpContainerElementId='add-judge'
                  placeholder={ is_FLLC_event ? t('selectJudgesLabel') : t('nonChallengeSelectJudgesLabel')}
                >
                  {judgeOptions.map(j => (
                    <SelectOption value={j.userId} label={`${j.fullName}`}>
                      <span aria-label={`${j.fullName}`} className='judging-modal-judges-tab__add-option'>
                        <div>
                          <p className='paragraph--medium paragraph--bold paragraph--charcoal'>{`${j.fullName}`}</p>
                          {j?.panels?.length > 0 ? (
                            <p className='paragraph--small paragraph--blue'>{generatePanelsCommaSeparated(j.panels)}</p>
                          ) : (
                            <p className='paragraph--small paragraph--red'>{t('notAssignedLabel')}</p>
                          )}
                        </div>
                      </span>
                    </SelectOption>
                  ))}
                </Select>
            </FormItem>

          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem name='sessions' label={t('teamsLabel')}>
                <Select
                  showArrow={false}
                  showSearch
                  mode='multiple'
                  size="large"
                  className="judging-panel-modal__add-select"
                  placeholder={t('selectTeamsLabel')}
                  allowClear
                  optionLabelProp="label"
                  optionFilterProp='label'
                >
                  {sessionOptions.map((teamOpt) => (
                    <SelectOption
                      value={teamOpt.sessionId}
                      label={(eventProgramId === FTC_ID) ? (
                        `${teamOpt.teamName} (${teamOpt.sessionTypeName})`
                      ) : (
                        teamOpt.teamName
                      )}
                      disabled={(eventProgramId !== FTC_ID) && teamOpt.panelId}
                    >
                      <span
                        aria-label={teamOpt.teamName}
                        className="judging-modal-judges-tab__add-option"
                      >
                        <div>
                          <p className="paragraph--medium paragraph--bold paragraph--charcoal">
                            {(eventProgramId === FTC_ID) ? (
                              `${teamOpt.teamName} (${teamOpt.sessionTypeName})`
                            ) : (
                              teamOpt.teamName
                            )}
                          </p>
                          {teamOpt.panelId ? (
                            <p className="paragraph--small paragraph--blue">{teamOpt.panelName}</p>
                          ) : (
                            <p className="paragraph--small paragraph--red">{t('notAssignedLabel')}</p>
                          )}
                        </div>
                      </span>
                    </SelectOption>
                  ))}
                </Select>
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <Button loading={isCreatingNewJudgingPanel} additionalClassNames='add-event-modal__btn' htmlType='submit'>{t('addLabel')}</Button>
          </Col>
        </Row>
      </Form>
    </EventModal>
  );
};

PanelModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  resetJudgesList: PropTypes.func,
  resetTeamsList: PropTypes.func,
  isEdit: PropTypes.bool,
  judgeOptions: PropTypes.array,
  sessionOptions: PropTypes.array,
  panelTitle: PropTypes.string,
  panelSubtitle: PropTypes.string,
  assignedJudges: PropTypes.array,
  addPanel: PropTypes.func,
  assignedSessions: PropTypes.array,
  handleCreateNewJudgingPanel: PropTypes.func,
  isCreatingNewJudgingPanel: PropTypes.bool,
  getJudgingPanels: PropTypes.func
};

PanelModal.defaultProps = {
  visible: false,
  onCancel: () => { },
  resetJudgesList: () => { },
  resetTeamsList: () => { },
  isEdit: false,
  judgeOptions: [],
  sessionOptions: [],
  panelTitle: 'Add Panel',
  panelSubtitle: 'Must assign at least two volunteers',
  assignedJudges: [],
  assignedSessions: [],
  addPanel: () => { },
  handleCreateNewJudgingPanel: () => { },
  isCreatingNewJudgingPanel: false,
  getJudgingPanels: () => { }
};

const mapStateToProps = (state) => {
  const { event: { eventDetails, eventJudging: { isCreatingNewJudgingPanel, judgingPanels } } } = state;
  const { eventProgramId, is_FLLC_event } = eventDetails;
  return {
    eventProgramId,
    isCreatingNewJudgingPanel,
    judgingPanels,
    is_FLLC_event
  }
}

const mapDispatchToProps = {
  resetTeamsList: handleResetTeamsAddToPanelList,
  resetJudgesList: handleResetAddToPanelList,
  handleCreateNewJudgingPanel,
  getJudgingPanels
};


export default connect(mapStateToProps, mapDispatchToProps)(PanelModal);
