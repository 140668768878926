import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InviteTile from './InviteTile';
import '../../../../styles/EventInvitedList.scss';


const InvitedList = props => {
  const { t } = useTranslation()
  const {volunteers, volunteersNotInvited} = props;

  return (
    
    <div className='event-invited-list-container'>
      {volunteers.length ? (
        <>
          <p className='paragraph--darkGray paragraph--small'>{t('peopleHaveBeenInvitedLabel')}</p>
          <div className='event-invited-list'>
            {volunteers.map (v => (
              <InviteTile errors={v.errors} name={`${v.firstName} ${v.lastName}`} email={v.email} role={v.EventRole} key={`${v.email}`} id={v.email} isInvited />
          ))}
          </div>
        </>
      ) : (
        <p className='paragraph--default paragraph--charcoal paragraph--bold'>No invitations were successfully sent.</p>
      )}
      {volunteersNotInvited.length ? (
        <div className='m-t-36'>
          <p className='paragraph--darkGray paragraph--small'>These people were not invited:</p>
          <div className='event-invited-list'>
            {volunteersNotInvited.map (v => (
              <InviteTile errors={v.errors} name={`${v.firstName} ${v.lastName}`} email={v.email} role={v.EventRole} key={`${v.email}`} id={v.email} isInvited />

                ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

InvitedList.propTypes = {
  volunteers: PropTypes.array,
  volunteersNotInvited: PropTypes.array
};

InvitedList.defaultProps = {
  volunteers: [],
  volunteersNotInvited: []
}

export default InvitedList;