import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import '../../../../../shared/components/Scoresheet/styles/MatchScoresheetForm.scss';
import {Radio, Form} from 'antd';
import {connect} from 'react-redux';
import InputNumber from 'shared/components/InputNumber'
import M03Validation from './scoresheetValidation/M03Validation';
import M06M07Validation from './scoresheetValidation/M06M07Validation';
import M08Validation from './scoresheetValidation/M08Validation';
import M12Validation from './scoresheetValidation/M12Validation';
import M14Validation from './scoresheetValidation/M14Validation';
import m05Section from 'shared/constants/2023Scoreheet/m05';
import m12Section from 'shared/constants/2023Scoreheet/m12';
import m07Section from 'shared/constants/newScoresheetOptions/m07';
import m08Section from 'shared/constants/newScoresheetOptions/m08';
import m16Section from 'shared/constants/newScoresheetOptions/m16';
import m14Section from 'shared/constants/newScoresheetOptions/m14';
import m10Section from 'shared/constants/newScoresheetOptions/m10';
import m03Section from 'shared/constants/newScoresheetOptions/m03';
import m15Section from 'shared/constants/newScoresheetOptions/m15';
import { CURRENT_EVENT_SEASON } from 'shared/constants/eventConstants';
import store from 'features/store';
import { handleSetIsPublicScoresheetEdited } from '../../../../PublicScoresheet/redux/actions/publicScoring';
import NoEquipmentContraintSymbol from 'shared/components/Icon/pngs/NoEquipmentConstraintSymbol.png';


const moduleNameClassName = `h-2 heading--white`;
const moduleTitleClassName = `h-2 heading--charcoal`;
const paragraphClassName = 'paragraph--large paragraph--charcoal paragraph--normal'
const errorClassName = 'paragraph--large paragraph--dark-red paragraph--normal'


const ModuleSection = props => {

  const { form, moduleName, moduleTitle, fields, scoreInput, showError, setShowError, publicScoreInput, isScoreReseted, clearPublicScoringReset, season
        } = props;
  const initialFormValues = publicScoreInput ? publicScoreInput : scoreInput;
  const [scoreInputData, setScoreInputData] = useState(initialFormValues);
  const {t} = useTranslation();
  const [showModuleError, setShowModuleError] = useState(false) // control error for module 08 or other module except m15 and m16
  const [moduleError, setModuleError] = useState('');


  useEffect(() => {
    Object.entries(initialFormValues).map(([key, value]) => form.setFieldValue(key, value));
    setScoreInputData(initialFormValues);
  }, [scoreInput]);
  
  useEffect(() => {
    if (isScoreReseted) {
      Object.entries(initialFormValues).map(([key, value]) => form.setFieldValue(key, value));
      setScoreInputData(initialFormValues);
    }

    if (isScoreReseted && (moduleName === 'm08' || moduleName === 'm05' || moduleName === 'm12')) {
      clearPublicScoringReset();
      setShowModuleError(false);
      setModuleError(false);
    }
  }, [isScoreReseted, moduleName]);

  const m05QuestionOne = m05Section.fields[0];
  const m05QuestionTwo = m05Section.fields[1];
  
  const m07QuestionOne = m07Section.fields[0];
  const m07QuestionTwo = m07Section.fields[1];
  
  const m08QuestionOne = m08Section.fields[0];
  const m08QuestionTwo = m08Section.fields[1];
  const m08QuestionThree = m08Section.fields[2];

  const m10Question = m10Section.fields[0];

  const m12QuestionOne = m12Section.fields[0];
  const m12QuestionTwo = m12Section.fields[1];

  const m15QuestionOne = m15Section.fields[0];
  const m15QuestionTwo = m15Section.fields[1];
  const m15QuestionThree = m15Section.fields[2];

  const m16QuestionOne = m16Section.fields[0];
  const m16QuestionTwo = m16Section.fields[1];
  const m16QuestionThree = m16Section.fields[2];
  const m16QuestionFour = m16Section.fields[3];
  const m16QuestionFive = m16Section.fields[4];

    const pullRight = {
        float: 'right'
    };

  const validator = (rule, value, callback) => {

    if (moduleName === 'm05' && (season == CURRENT_EVENT_SEASON || season == null)){
      const m8ErrorMsg = t('2023M05ValidationLabel')
      const valueOne = form.getFieldValue(m05QuestionOne.id);
      const valueTwo = form.getFieldValue(m05QuestionTwo.id);

      if (!valueOne && valueTwo){
        setModuleError(m8ErrorMsg)
        setShowModuleError(true)
        callback(m8ErrorMsg)
        return
      }else{
        setModuleError('')
        setShowModuleError(false)
      }
    }

    if (moduleName === 'm12' && (season == CURRENT_EVENT_SEASON || season == null)) {
      const m12ErrorMsg = t('2023M12ValidationLabel')
      const valueOne = form.getFieldValue(m12QuestionOne.id);
      const valueTwo = form.getFieldValue(m12QuestionTwo.id);

      if ((valueOne == 2 && valueTwo == 2) || (valueOne == 3 && valueTwo == 1) || (valueOne == 3 && valueTwo == 2)) {
        setModuleError(m12ErrorMsg)
        setShowModuleError(true)
        callback(m12ErrorMsg)
        return
      } else {
        setModuleError('')
        setShowModuleError(false)
      }
    }

    if(moduleName === 'm07') {
      const valueOne = form.getFieldValue(m07QuestionOne.id);
      const valueTwo = form.getFieldValue(m07QuestionTwo.id);

      if(!value && !valueOne && valueTwo){
        form.setFieldsValue({[m07QuestionTwo.id]: false})
      }else if(value && !valueOne && valueTwo){
        form.setFieldsValue({[m07QuestionOne.id]: true})
      }
    }

    if (moduleName === 'm08' && season == '2022') {
      const valueOne = form.getFieldValue(m08QuestionOne.id);
      const valueTwo = form.getFieldValue(m08QuestionTwo.id);
      const valueThree = form.getFieldValue(m08QuestionThree.id);
      const m8ErrorMsg = t('ScoresheetM16logic1')

      if (valueOne && valueTwo && !valueThree) {
        setModuleError(m8ErrorMsg)
        setShowModuleError(true)
        callback(m8ErrorMsg)
        return
      } else {
        setModuleError('')
        setShowModuleError(false)
        if (!valueTwo && !valueOne && valueThree) {
          form.setFieldsValue({ [m08QuestionThree.id]: false })
        } else if (valueTwo && !valueOne && valueThree) {
          form.setFieldsValue({ [m08QuestionOne.id]: true })
        }
      }
    }

    if(moduleName === 'm16' || moduleName === 'm15') {
      let error = false;
      const m10Value = form.getFieldValue(m10Question.id);

      const m15ValueOne = form.getFieldValue(m15QuestionOne.id);
      const m15ValueTwo = form.getFieldValue(m15QuestionTwo.id);
      const m15ValueThree = form.getFieldValue(m15QuestionThree.id);

      const m16ValueTwo = form.getFieldValue(m16QuestionTwo.id)
      const m16ValueThree = form.getFieldValue(m16QuestionThree.id)
      const m16ValueFour = form.getFieldValue(m16QuestionFour.id)
      const m16ValueFive = form.getFieldValue(m16QuestionFive.id)

      let valueConstraint = 8;
    
      if(m10Value){
        valueConstraint -= 1;
      }

      if(m15ValueOne === 1) valueConstraint -= 1;
      if(m15ValueOne === 2) valueConstraint -= 2;
      if(m15ValueTwo === 1) valueConstraint -= 1;
      if(m15ValueTwo === 2) valueConstraint -= 2;
      if(m15ValueThree === 1) valueConstraint -= 1;
      if(m15ValueThree === 2) valueConstraint -= 2;

      
      const Msg2 =t('ScoresheetMessage4');
      const Msg3 =t('ScoresheetM16logic3');

      if(moduleName === 'm16'){

        if((m16ValueThree && !m16ValueFour) || (!m16ValueThree && m16ValueFour)){
          if(m16ValueTwo === 0 || m16ValueFive === 0) {
            error = true;
            setModuleError(Msg2);
            callback(Msg2)
          }
        }else if(m16ValueThree && m16ValueFour){
          if(m16ValueTwo <= 1 || m16ValueFive <= 1) {
            error = true;
            setModuleError(Msg2)
            callback(Msg2)
          }
        }else{
          setShowError(false);
        }
        
        if(m16ValueTwo < m16ValueFive){
          error = true
          setModuleError(Msg3);
          callback(Msg3)
        }else{
          if(m16ValueTwo >= 1 && m16ValueFive === 0){
            error = true
            setModuleError(Msg2);
            callback(Msg2)
          }
          if(m16ValueTwo === 0 && m16ValueFive >= 1){
            error = true
            setModuleError(Msg2);
            callback(Msg2)
          }
        }
      }
      setShowError(error);
    }
    callback();
  }

  const onFormItemClick = (e, f) =>{
    form.setFieldValue(f.id, e.target.value)
    store.dispatch(handleSetIsPublicScoresheetEdited(true))
  }

  return (
    <div className="scoresheet-module">
      <div className="scoresheet-module__name">
        <h3 className={moduleNameClassName}>{moduleName}</h3>
      </div>
      <div className="m-l-30 m-t-8 scoresheet-module__content">
        {/*EH-361 we will need to remove this image for next years scoresheet*/}
        {moduleName === 'm01' || moduleName === 'm03' || moduleName === 'm06' || moduleName === 'm07' || moduleName === 'm08' || moduleName === 'm10' || moduleName === 'm12' || moduleName === 'm15'
            ? <img
                style={pullRight}
                src={NoEquipmentContraintSymbol}
                alt='NoEquipmentContraintSymbol'
              /> : null}
        <h2 
          className={moduleTitleClassName} 
          dangerouslySetInnerHTML={{
            __html: moduleName === 'GP' ? t(moduleTitle) : t(moduleTitle).toUpperCase()
          }}
        />
        {showError || showModuleError ? <p className={errorClassName}>{moduleError}</p> : null}
        <div className="scoresheet-module__fields-wrapper">
          {fields.map((f) => (
            <Form.Item name={f.id} onClick={(e) => onFormItemClick(e, f)} key={f.id} className="scoresheet-module__field">
              {Array.isArray(f.scoring)
                ? (
                  <div>
                    <div className="scoresheet-module__field-description">
                      {t(f.description)}
                    </div>
                    <ul className="scoresheet-module__field-list">
                      {f.scoring.map((s)=>(
                        <li dangerouslySetInnerHTML={{__html: t(s)}}>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <span className={`${paragraphClassName} scoresheet-module__field-label`} dangerouslySetInnerHTML={{__html: t(f.scoring)}}>
                  </span>
                )}
              {f.type !== 'integer' ? (
                <span>
                    <Radio.Group
                      buttonStyle="solid"
                      className="match-scoresheet-form__radio-wrapper"
                      optionType="button"
                      disabled={scoreInputData.is_published}
                      // name={f.id}
                      value={scoreInputData[f.id]}
                      onChange={(e) => setScoreInputData({...scoreInputData, [f.id]: e.target.value})}
                    >
                      {f.options.map((o) => (
                        <Radio.Button
                          buttonStyle="solid"
                          className="match-scoresheet-form__radio-btn"
                          key={`${f.id}-${o.value}`}
                          value={o.value}
                        >
                          {t(o.description)}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  {/* )} */}
                </span>
              ) : (
                <span>
                  {/* {form.getFieldDecorator( f.id, {
                    initialValue: scoreInputData[f.id]
                }
                )( */}
                  <InputNumber size='large' min={f.min} max={f.max} disabled={scoreInputData.is_published}/>
                {/* )} */}
                </span>
              )}
            </Form.Item>
          ))}
        </div>
      </div>
    </div>
  );
};

ModuleSection.propTypes = {
  moduleName: PropTypes.string,
  moduleTitle: PropTypes.string,
  fields: PropTypes.array,
  form: PropTypes.object.isRequired,
  scoreInput: PropTypes.object.isRequired,
  clearPublicScoringReset: PropTypes.func
};

ModuleSection.defaultProps = {
  moduleName: '',
  moduleTitle: '',
  fields: [],
  clearPublicScoringReset: () => {}
};

const mapStateToProps = (state) => {
  const {matchScoresheet: {scoreInput, eventDetails: {season}}, publicScoring: {isScoreReseted}} = state;
  return {
    scoreInput,
    isScoreReseted,
    season
  }
}
export default connect(mapStateToProps)(ModuleSection);