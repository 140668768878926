import React from 'react';
import Heading from 'shared/components/Heading';
import Paragraph from 'shared/components/Paragraph';
import Divider from 'shared/components/Divider';
import NominatedList from './NominatedList';

const AwardNominateCard = (props) => {
  const { awardName, awardDescription, assignments, teams } = props;

  const formattedAssignments = assignments.map(assign => {
    const selected = teams.find(({ id }) => id === assign.teamId);
    return { ...assign, teamNumberName: selected && selected.teamNumberName };
  });

  return (
    <div className='c-event-awards__card'>
      <div className='c-event-awards__card-header'>
        <Heading>{awardName}</Heading>
      </div>
      <Paragraph size='medium'>{awardDescription}</Paragraph>
      <NominatedList assignments={formattedAssignments} />
      <Divider margin={48} width={4} />
    </div>
  );
};

export default AwardNominateCard;
