const m10Section = {
  id: 'm10',
  name: '2025ScoresheetM10Sub1Label',
  fields: [
    {
      scoring: '2025ScoresheetM10Sub2Label',
      id: 'm10_scoring_1',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        },
      ]
      },
      {
      scoring: '2025ScoresheetM10Sub3Label',
      id: 'm10_scoring_2',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        },
      ]
    }
  ]
}

export default m10Section;