import React from 'react';
import RegistrationLayout from 'shared/components/Layouts/RegistrationPageLayout';
import { PrivacyPolicyContainer } from 'features/RegistrationContainer';

const PrivacyPolicy = () => {
  return (
    <RegistrationLayout>
      <PrivacyPolicyContainer />
    </RegistrationLayout>
  );
};

export default PrivacyPolicy;