import * as types from '../types/organizationsTypes';

const DEFAULT_ORGANIZATION = {
  organizationId: '',
  name: '',
  programs: [],
  organizationInitialRegions: []
}
const DEFAULT_STATE = {
  organizationsList: [],
  count: 0,
  isLoadingOrganizations: false,
  organizationEdited: false,
  organizationsError: false,
  selectedOrganization: DEFAULT_ORGANIZATION,
  isDeletingOrganization: false,
  isFetchingRegions: false,
  isSavingOrganization: false
}

export default (state = DEFAULT_STATE, action) => {
  switch(action.type) {
    case types.FETCH_ORGANIZATIONS_BEGIN:
      return {
        ...state,
        isLoadingOrganizations: true,
        organizationEdited: false
      }
    case types.FETCH_ORGANIZATIONS_ERROR:
      return {
        ...state,
        isLoadingOrganizations: false,
        organizationsError: true
      }
    case types.FETCH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        isLoadingOrganizations: false,
        organizationsError: false,
        count: action.payload.count,
        organizationsList: action.payload.organizationsList
      }
    case types.SET_SELECTED_EDIT_ORGANIZATION: 
      return {
        ...state,
        selectedOrganization: action.payload.selectedOrganization
      }
    case types.UPDATE_SELECTED_EDIT_ORGANIZATION:
      return {
        ...state,
        selectedOrganization: {
          ...state.selectedOrganization,
          [action.payload.field]: action.payload.value
        }
      }
    case types.RESET_EDIT_ORGANIZATION_FORM:
      return {
        ...state,
        selectedOrganization: DEFAULT_ORGANIZATION,
      }
    case types.DELETE_ORGANIZATION_BEGIN:
      return {
        ...state,
        isDeletingOrganization: true
      }
    case types.DELETE_ORGANIZATION_ERROR:
      return {
        ...state,
        isDeletingOrganization: false
      }
    case types.DELETE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isDeletingOrganization: false,
        organizationsList: action.payload.organizationsList
      }
    case types.UPDATE_EDIT_ORGANIZATION_PROGRAMS:
      return {
        ...state,
        selectedOrganization: {
          ...state.selectedOrganization,
          programs: action.payload.programs
        }
      }
    case types.FETCH_ORGANIZATION_REGIONS_BY_PROGRAM_BEGIN:
      return {
        ...state,
        isFetchingRegions: true
      }
    case types.FETCH_ORGANIZATION_REGIONS_BY_PROGRAM_ERROR:
      return {
        ...state,
        isFetchingRegions: false
      }
    case types.FETCH_ORGANIZATION_REGIONS_BY_PROGRAM_SUCCESS:
      return {
        ...state,
        isFetchingRegions: false,
        selectedOrganization: {
          ...state.selectedOrganization,
          programs: action.payload.updatedOrganizationPrograms
        }
      }
    case types.EDIT_ORGANIZATION_BEGIN:
      return {
        ...state,
        isSavingOrganization: true
      }
    case types.EDIT_ORGANIZATION_ERROR:
      return {
        ...state,
        isSavingOrganization: false
      }
    case types.EDIT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isSavingOrganization: false,
        organizationEdited: true
      }
    default:
      return state;
  }
}