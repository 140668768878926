import React from 'react';
import PropTypes from 'prop-types';
import CardsList from 'shared/components/CardsList';
import { useTranslation } from 'react-i18next';
import UserTile from './UserTile';


const UsersList = props => {
  const { users, loading, isEditButtonVisible, onEditClick } = props;
  const { t } = useTranslation()

  return (
    <CardsList
      list={users}
      loading={loading}
      renderTile={(user) => (
        <UserTile
          {...user}
          isEditButtonVisible={isEditButtonVisible}
          onEditClick={() => onEditClick(user.userId)}
        />
)}
      tabName={t('usersLabel')}
    />
  );
};

UsersList.propTypes = {
  list: PropTypes.array,
  users: PropTypes.array,
  loading: PropTypes.bool,
  isEditButtonVisible: PropTypes.bool,
  onEditClick: PropTypes.func
};

UsersList.defaultProps = {
  loading: false,
  list: [],
  users: [],
  isEditButtonVisible: false,
  onEditClick: () => {}
};

export default UsersList;
