import React from 'react';
import PropTypes from 'prop-types';
import CardsList from 'shared/components/CardsList';
import Organization from './OrganizationTile';

const OrganizationsList = props => {
  const { organizations, loading, onEditClick } = props;

  return (
    <CardsList
      list={organizations}
      loading={loading}
      renderTile={(organization) => <Organization key={organization.organizationId} {...organization} onEditClick={() => onEditClick(organization.organizationId)} />}
      tabName='Organizations'
    />
  );
};

OrganizationsList.propTypes = {
  organizations: PropTypes.array,
  loading: PropTypes.bool,
  onEditClick: PropTypes.func
};

OrganizationsList.defaultProps = {
  loading: false,
  organizations: [],
  onEditClick: () => {}
};

export default OrganizationsList;
