const m11Section = {
  id: 'm11',
  name: '2022ScoresheetM11Sub1Label',
  fields: [
    {
      scoring: '2022ScoresheetM11Sub2Label',
      id: 'm11_scoring_1',
      options: [
        {
          description: 'noLabel',
          value: 'no'
        },
        {
          description: '2022ScoresheetPartlyLabel',
          value: 'partly'
        },
        {
          description: '2022ScoresheetCompletelyLabel',
          value: 'completely'
        }
      ]
    },
  ]
}

export default m11Section;