export const formatSessionResData =  (data) => {
  return {
    teamId: data.teamId,
    sessionId: data.sessionId,
    startTime: data.parseSessionStartDayDateTime(),
    startIsoString: data.sessionStartTime,
    sessionStatusId: data.sessionStatusId,
    sessionStatusName: data.getSessionStatusName(),
    sessionTypeName: data.getSessionTypeName(),
    endIsoString: data.sessionEndTime,
    teamName: data.getTeamName(),
    digital_rubric: data.digital_rubric,
    sessionTypeId: data.sessionTypeId,
    panelName: data.getPanelName(),
    panelId: data.panelId
  }

}

export const formatSessionDocumentsRes =  (data) => {
  return {
    sessionDocumentId: data.sessionDocumentId,
    documentTypeId: data.documentTypeId,
    documentType: data.getDocumentTypeDetails(),
    files: data.getFiles(),
  }
}