import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import '../../styles/ScheduleContainer/ScheduleHeader.scss';
import Dropdown from 'shared/components/Dropdown';
import BtnSecondary from 'shared/components/Button/BtnSecondary';
import timezoneOptions from 'shared/constants/timezones';
import { Form } from '@ant-design/compatible';
import { Col, Row, notification, Popover } from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';
import * as userTypes from 'shared/constants/userTypes';
import * as eventRoleTypes from 'shared/constants/eventRoleTypes';
import { rowProps } from 'utils/rowHelpers';
import EditEventDetailsModal from './EditEventDetailsModal';
import EditEventScheduleSettingsModal from './EditEventScheduleSettingsModal';
import Button from 'shared/components/Button/BtnPrimary';
import { handleEventTimeZoneUpdate } from 'features/EventContainer/redux/actions/eventActions';
import { downloadScheduleCsv } from 'utils/services/schedules';
// import Tooltip from 'shared/components/Tooltip';

const { Option } = Dropdown;

const ScheduleHeader = (props) => {
  const {
    userType,
    userEventRoleIds,
    time_zone,
    handleTimezoneUpdate,
    isChangingTimeZone,
    eventScheduleId
  } = props;
  const { t } = useTranslation()

  const [visible, setVisible] = useState(false);
  const [scheduleVisible, setScheduleVisible] = useState(false);
  const [downloadingSchedule, setDownloadingSchedule] = useState(false)

  const downloadCsv = async () => {
    if (downloadingSchedule){
      return
    }

    setDownloadingSchedule(true)
    try {
      let data = await downloadScheduleCsv(eventScheduleId)
      const url = window.URL.createObjectURL(new Blob([data], {
        type: "text/csv"
      }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'schedule.csv')
      document.body.appendChild(link)
      link.click()
      setDownloadingSchedule(false)
    } catch (err) {
      notification.error({
        message: 'Error',
        description: err.description || err.message,
      });
      setDownloadingSchedule(false)
    }    
  }

  const openScheduleSettings = () => {
    setScheduleVisible(true);
  }

  const closeScheduleSettings = () => {
    setScheduleVisible(false);
  }

  const openModal = () => {
    setVisible(true)
  }

  const closeModal = () => {
    setVisible(false)
  }

  const isAdminUser = userType === userTypes.PARTNER_ADMIN || userType === userTypes.FIRST_ADMIN;

  const canEditEvent = isAdminUser || userEventRoleIds.includes(eventRoleTypes.EVENT_ADMIN_ID)

  const onTimeZoneChanged = (value) => {
    handleTimezoneUpdate(value);
  }

  return (

    <div className='event-schedule-header'>
      <EditEventDetailsModal visible={visible} onCancel={closeModal} />
      <EditEventScheduleSettingsModal visible={scheduleVisible} onCancel={closeScheduleSettings} />
      <h1 className='heading--charcoal h-1'>{t('scheduleLabel')}</h1>
      <div className='event-schedule-header__right'>
        <Form>
          <Row {...rowProps} justify={canEditEvent ? "space-between" : 'end'}>
            <Col xs={24} md={8}>
              <Form.Item>
                <Dropdown
                  disabled={true}
                  showArrow={false}
                  showSearch
                  value={time_zone ? time_zone : undefined}
                  onChange={onTimeZoneChanged}
                  loading={isChangingTimeZone}
                  placeholder="Select Timezone"
                >
                  {timezoneOptions.map(tz => (
                    <Option value={tz.id} key={tz.id}>{tz.description}</Option>
                  ))}
                </Dropdown>

              </Form.Item>
            </Col>
            {canEditEvent ? (
              <Col xs={24} md={7}>
                <Form.Item>
                  <Button size='medium' onClick={openScheduleSettings}>
                    {t('ScheduleSettingsLabel')}
                  </Button>

                </Form.Item>
              </Col>
            ) : null}
            {canEditEvent ? (
              <Col xs={24} md={7}>
                <Form.Item>
                  <BtnSecondary size='medium' onClick={openModal}>
                    {t('editGeneralInfoLabel')}
                  </BtnSecondary>

                </Form.Item>
              </Col>
            ) : null}

            {(isAdminUser || canEditEvent) && (
              <Col xs={24} md={2}>
                <Popover title={null} content={"Download"}>
                  <span onClick={() => downloadCsv()} style={{ fontSize: 25, color: '#0066B3' }}><DownloadOutlined type={downloadingSchedule ? 'loading' : 'download'}  /></span>
                </Popover>
              </Col>
            )}
          </Row>

        </Form>
      </div>
    </div>

  );
};
ScheduleHeader.propTypes = {
  userType: PropTypes.string.isRequired,
  userEventRoleIds: PropTypes.array.isRequired
}

const mapStateToProps = (state) => {
  const {

    event: { eventDetails },
    auth: { userType }
  } = state;

  const { userEventRoleIds, time_zone, isChangingTimeZone, eventScheduleId } = eventDetails;

  return {
    userType,
    userEventRoleIds,
    time_zone,
    isChangingTimeZone,
    eventScheduleId
  };
};

const mapDispatchToProps = {
  handleTimezoneUpdate: handleEventTimeZoneUpdate,
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleHeader);
