import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EventProgramLogos from 'shared/components/EventProgramLogos';
import '../../../shared/components/EventPageHeader/EventPageHeader.scss';
import EventPageTeamSelection from './EventPageTeamSelection';
import { setDashboardRole } from 'features/DashboardContainer/redux/roleActions';
import { useTranslation } from 'react-i18next';
import { Col, Row, Switch } from 'antd';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom';
import * as eventRoleTypes from 'shared/constants/eventRoleTypes';
import * as userTypes from 'shared/constants/userTypes';

const EventHeader = props => {
  const {
    title,
    subHeading,
    hasSubheading,
    eventLocation,
    dateString,
    programId,
    showBgColor,
    loading,
    dashboard,
    selectRole,
    canAccessVolunteerDashboard,
    canAccessAdminDashboard,
    userType,
    eventOverview
  } = props;

  const {
    userEventRoleIds,
  } = eventOverview;

  const history = useHistory();
  const location = useLocation();
  const path = location.pathname
  const wrapperRef = useRef(null);
  const [size, setSize] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    if (wrapperRef && wrapperRef.current && wrapperRef.current.clientWidth) {
      setSize(wrapperRef.current.clientWidth)
    }
  }, [wrapperRef]);

  useEffect(() => {
    const resizeListener = () => {
      if (wrapperRef && wrapperRef.current && wrapperRef.current.clientWidth) {
        setSize(wrapperRef.current.clientWidth)
      }
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  useEffect(() => {
    if (path.includes('/events')) {
      selectRole(t('adminDashboardLabel'), 'AdminDashboard', 'admin')
    } else if (path === '/event-overview') {
      selectRole('Volunteer Dashboard', 'VolunteerDashboard', 'volunteer')
    }
  }, [])

  const haveAccessToViewAdmin = () => {
    if (userEventRoleIds.includes(eventRoleTypes.EVENT_ADMIN_ID)
      || userEventRoleIds.includes(eventRoleTypes.HEAD_REFEREE_ID)
      || userEventRoleIds.includes(eventRoleTypes.JUDGE_ADVISOR_ID)
      || userType === userTypes.FIRST_ADMIN
      || userType === userTypes.PARTNER_ADMIN) {
      return true
    }
    return false
  }

  const roles = [
    {
      name: t('adminDashboardLabel'),
      id: 'AdminDashboard',
      path: 'admin',
      disabled: !canAccessAdminDashboard,
    },
    {
      name: t('volunteerDashboardLabel'),
      id: 'VolunteerDashboard',
      path: 'volunteer',
      disabled: !canAccessVolunteerDashboard
    }
  ]

  const generateStyles = (size = 1440) => {
    const basePosition = size && size < 1300 ? size - 1300 : 0;
    const l_width = 180 + basePosition;
    const r_width = 104 + basePosition;
    const multiplier = 1
    const ratio = size / 1440 * multiplier;
    const width = ratio * (l_width > 0 ? l_width : 0);
    const height = ratio * 183;
    const left = ratio * (0);

    const rightWidth = ratio * (r_width > 0 ? r_width : 0);
    const rightHeight = ratio * 182;
    const rightTop = ratio * (-37);
    const rightRight = ratio * (0);
    const leftStyle = { height: `${height}px`, width: `${width}px`, top: `${0}px`, left: `${left}px` }
    const rightStyle = { height: `${rightHeight}px`, width: `${rightWidth}px`, top: `${rightTop}px`, right: `${rightRight}px` };

    return { left: leftStyle, right: rightStyle }
  }

  const getHashAdmin = (hash) => {

    if (hash == "" || hash == "#schedule") {
      return "#1"
    }

    if (hash == "#scoring-sessions") {
      return "#6"
    }

    if (hash == "#judging-sessions") {
      return "#5"
    }

    if (hash == "#message") {
      return "#4"
    }

    if (hash == "#awards") {
      return "#8"
    }

    return ""
  }

  const getHashVolunteer = (hash) => {

    if (hash == "#1" || hash == "") {
      return ""
    }

    if (hash == "#6") {
      return "#scoring-sessions"
    }

    if (hash == "#5") {
      return "#judging-sessions"
    }

    if (hash == "#4") {
      return "#message"
    }

    if (hash == "#8") {
      return "#awards"
    }

    return ""
  }

  const onSwitchBoard = (data) => {
    const { id } = data;

    const role = roles.find(r => r.id === id)
    selectRole(role.name, id, role.path)

    let eventPath;
    let hash;

    if (role.id == "AdminDashboard") {
      hash = getHashAdmin(window.location.hash)
      eventPath = window.location.pathname.replace('event-overview', 'events')
      history.push(eventPath + hash)
    }

    if (role.id == "VolunteerDashboard") {
      hash = getHashVolunteer(window.location.hash)
      eventPath = window.location.pathname.replace('events', 'event-overview')
      history.push(eventPath + hash)
    }
  }

  const { left, right } = generateStyles(size);

  return (
    <div className={`event-page-header`} ref={wrapperRef}>
      {loading ? (null) : (
        <>
          <div className='event-page-header__title'>
            {/* <h1 className='h-2 h--bold heading--white event-page-header__title-text'>{title}</h1> */}
            <EventProgramLogos programId={programId} />
          </div>

          <div className='event-page-header__title'>
            <h1 className='h-2 h--bold heading--white event-page-header__title-text'>{title}</h1>
          </div>

          <div className='event-page-header__subtitle'>
            <Row className='width-100'>
              {hasSubheading ? (
              <Col span={24} md={8} style={{ width: !hasSubheading && 0 }}>
                <EventPageTeamSelection />
              </Col>
              ) : ''}
              <Col span={24} md={6} style={{ width: !(canAccessAdminDashboard && haveAccessToViewAdmin()) && 0 }}>
                {(canAccessAdminDashboard && haveAccessToViewAdmin()) && (
                  <>
                    <div className='sort-section-overview m-r-50'>
                      <div className='m-r-10 white'>View as: <span><strong>Admin</strong></span> </div>
                      <div className=''><Switch className='custom-switch' checked={dashboard.selectedRoleId == "AdminDashboard" ? false : true} onChange={(e) => {
                        onSwitchBoard(dashboard.selectedRoleId == "AdminDashboard" ? roles[1] : roles[0])
                      }} /></div>
                      <div className='m-l-10'><strong className='white'>Volunteer</strong></div>
                    </div>
                  </>
                )}
              </Col>
              <Col span={24} md={10} className="header-flex-end">
                <span className='event-page-header__subtitle-info'>
                  <h3 className='paragraph--white paragraph--large paragraph--300'>{dateString}</h3>
                  <span className='event-header__point'>&#8226;</span>
                  <h3 className='paragraph--white paragraph--large paragraph--300'>{eventLocation}</h3>
                </span>
              </Col>
            </Row>
          </div>

          {/* <div className={`event-page-header__subtitle`}>

            <div>
              {hasSubheading ? (
                <EventPageTeamSelection />
              ) : ''}
            </div>

            {(canAccessAdminDashboard && haveAccessToViewAdmin()) && (
              <>
                <div className='sort-section m-r-50'>
                  <div className='m-r-10 white'>View as: <span><strong>Admin</strong></span> </div>
                  <div className=''><Switch className='custom-switch' checked={dashboard.selectedRoleId == "AdminDashboard" ? false : true} onChange={(e) => {
                    onSwitchBoard(dashboard.selectedRoleId == "AdminDashboard" ? roles[1] : roles[0])
                  }} /></div>
                  <div className='m-l-10'><strong className='white'>Volunteer</strong></div>
                </div>
              </>
            )}

            <span className='event-page-header__subtitle-info'>
              <h3 className='paragraph--white paragraph--large paragraph--300'>{dateString}</h3>
              <span className='event-header__point'>&#8226;</span>
              <h3 className='paragraph--white paragraph--large paragraph--300'>{eventLocation}</h3>
            </span>
          </div> */}
        </>
      )}
      <div className="left-shape" style={left} />
      <div className="right-shape" style={right} />
    </div>
  );
};

EventHeader.propTypes = {
  title: PropTypes.string,
  eventLocation: PropTypes.string,
  dateString: PropTypes.string,
  subHeading: PropTypes.string,
  hasSubheading: PropTypes.bool,
  showBgColor: PropTypes.bool,
  loading: PropTypes.bool,
  selectRole: PropTypes.func.isRequired,
  dashboard: PropTypes.object.isRequired,
  canAccessVolunteerDashboard: PropTypes.bool.isRequired,
  canAccessAdminDashboard: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired
};

EventHeader.defaultProps = {
  title: '',
  eventLocation: '',
  dateString: '',
  subHeading: '',
  hasSubheading: false,
  showBgColor: true,
  loading: false
};

const mapDispatchToProps = {
  selectRole: setDashboardRole
}

const mapStateToProps = (state) => {
  const {
    dashboard,
    auth,
    eventOverview
  } = state;

  const {
    canAccessVolunteerDashboard,
    canAccessAdminDashboard,
    userType
  } = auth;
  return {
    eventOverview,
    dashboard,
    canAccessVolunteerDashboard,
    canAccessAdminDashboard,
    userType
  }
}


// export default EventHeader;

export default connect(mapStateToProps, mapDispatchToProps)(EventHeader);