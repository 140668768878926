import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';

import Tooltip from 'shared/components/Tooltip';
import Meeting from 'shared/components/Icon/icons/Meeting';
import Heading from 'shared/components/Heading';
import VolunteerEventRoles from 'features/EventContainer/models/VolunteerEventRoles';
import EventModal from 'features/EventContainer/components/EventModal';
import {getScheduleMeetingSessions} from 'features/EventContainer/redux/actions/scheduleActions/scheduleMeeting';

import NewMeeting from './NewMeeting';
import EditMeeting from './EditMeeting';
import '../../../../styles/ScheduleContainer/SessionModal.scss';

const SessionModal = (props) => {
  const {
    visible,
    onCancel,
    title,
    formType,
    isFetchingEventVolunteers,
    fetchEventVolunteersError,
    volunteersIncludedResponse,
    volunteers,
    meetings,
    meetingId
  } = props;
  const { t } = useTranslation()

  
  const TOOLTIP_TEXT = 'Add title, date, time, audience, and session URL to save and publish this activity. Once Published the activity will be visible to invited participants.';


  if (fetchEventVolunteersError) {
    return <p>Error</p>
  }

  let formattedVolunteersList = [];
  if (volunteers.length && !isFetchingEventVolunteers) {
    formattedVolunteersList = volunteers.map(volunteer => {
      const volunteerInfo = new VolunteerEventRoles(volunteer, volunteersIncludedResponse);
      
      const volunteerRolesList = volunteerInfo.getUserEventRolesList();
      const volunteerUser = volunteerInfo.formatUserData()
      const volunteerObj = {
        ...volunteerUser,
        roles: volunteerRolesList,
      };
      return volunteerObj;
    })
  } else {
    formattedVolunteersList = [];
  }

  const {
    teamOptions,
    isLoadingTeamOptions,
    isUpdatingMeeting,
    isCreatingMeeting
  } = meetings


  const closeModal = () => {
    if (formType === 'edit') {
      props.getScheduleMeetingSessions();
      onCancel()
    } else {
      onCancel()
    }

  }

  return (
    <EventModal
      visible={visible}
      onCancel={closeModal}
    >
      <div className='schedule-session-modal'>
        <div className='schedule-session-modal__title'>
          <Heading tag={2}>{title}</Heading>
          <Tooltip text={t('createMeetingTooltip')} placement='left' />
        </div>
        <div className='schedule-session-modal__meeting-content'>
          <div className='schedule-session-modal__rectangle-meeting'>          
            {' '}
            <Icon component={Meeting} className='schedule-session-modal__icon' />
          </div>
          {formType === 'new' ? (
            <NewMeeting
              closeModal={onCancel}
              volunteers={formattedVolunteersList}
              teamOptions={teamOptions}
              isLoadingTeamOptions={isLoadingTeamOptions}
              isCreatingMeeting={isCreatingMeeting}
            />
          ) : (
            <EditMeeting
              closeModal={closeModal}
              volunteers={formattedVolunteersList}
              teamOptions={teamOptions}
              isLoadingTeamOptions={isLoadingTeamOptions}
              meetingId={meetingId}
              isSavingMeeting={isUpdatingMeeting}
            />
          )}
        </div>
      </div>
    </EventModal>
  );
};

SessionModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  formType: PropTypes.oneOf(['new', 'edit']),
  meetings: PropTypes.object.isRequired,
  volunteers: PropTypes.array,
  meetingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  getScheduleMeetingSessions: PropTypes.func.isRequired,
};

SessionModal.defaultProps = {
  visible: false,
  onCancel: () => {},
  title: 'New Meeting',
  formType: 'new',
  volunteers: [],
  meetingId: null,
};


const mapStateToProps = (state) => {
  const {event: {
    eventDetails: {
      eventScheduleId
    },
    eventSchedule: {
      meetings
    },
    eventVolunteers: {
    volunteers, isFetchingEventVolunteers, fetchEventVolunteersError, volunteersIncludedResponse
  }}} = state;
  return {
    eventScheduleId,
    meetings,
    volunteers, isFetchingEventVolunteers, fetchEventVolunteersError, volunteersIncludedResponse
  }
}

const mapDispatchToProps = {
  getScheduleMeetingSessions
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionModal);
