import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import ListLoading from '../Spinner/ListLoading';

const CardsList = props => {
  const { list, loading, tabName, renderTile } = props;

  return (
    <div>
      <ListLoading loading={loading} text={`Loading ${tabName}`} />
      {!loading && <Row type='flex' gutter={20}>
        {list.map(item => (
          <Col xs={24} sm={12} md={8} key={item.id}>
            {renderTile(item)}
          </Col>
        ))}
      </Row>}
    </div>
  );
};

CardsList.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  renderTile: PropTypes.func
};

CardsList.defaultProps = {
  list: [],
  loading: false,
  renderTile: () => { },
  tabName: ''
};

export default CardsList;
