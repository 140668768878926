import React from 'react';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
// import CloseCircle from 'shared/components/Icon/icons/CloseCircle';
import '../../../../styles/TeamCoachTile.scss';


const CoachTeamTile = props => {
  const {coach} = props;

  return (
    <div className='team-coach-tile'>
      <div>
        <Avatar size={48} icon={<UserOutlined />} className='team-coach-tile__avatar' />
      </div>
      <div className='team-coach-tile__data'>
        <p className='paragraph--charcoal paragraph--medium paragraph--bold'>{`${coach.firstName} ${coach.lastName}`}</p>
        <a className='link--email' href={`mailto:${coach.email}`}>{coach.email}</a>
        <div>
          {coach.teams.map(t => (
            <p className='paragraph--charcoal paragraph--medium paragraph--400'>{t.teamNumber ? `${t.teamNumber}-${t.teamName}` : `${t.teamName}`}</p>
        ))}
        </div>
      </div>
      
    </div>
  );
};

CoachTeamTile.propTypes = {
  coach: PropTypes.object.isRequired
};

export default CoachTeamTile;