import {formatEventStartDate, formatEventEndDate} from 'utils/dateTimeUtils';
import { urlTransform } from 'utils/formHelpers'


const formatPayload = (formValues) => {

  const eventStartDate = formatEventStartDate(formValues.dates[0])
  const eventEndDate = formatEventEndDate(formValues.dates[1])

  const payload = {
    data: {
      type: 'event',
      id: null,
      attributes: {
        title: formValues.title,
        url: urlTransform(formValues.url),
        description: formValues.description,
        start_date: eventStartDate,
        end_date: eventEndDate,
        sub_division: null,
        timezone: formValues.timezone
      },
      relationships: {
        ...formValues.country && {
          country: {
            data: {
              type: 'country',
              id: formValues.country
            }
          },
        },
        program: {
          data: {
            type: 'program',
            id: formValues.program
          }
        },
        region: {
          data: {
            type: 'region',
            id: formValues.region
          }
        }
      }
    }
  }

  return payload;
}


export default formatPayload;