import {formatDocumentTypeRes} from 'utils/services/documentTypes/model';
import * as sessionService from 'utils/services/sessions';
import {formatSessionDocumentsRes,formatSessionResData} from 'utils/services/sessions/model';
import DocumentType from 'models/DocumentType';
import SessionDocument from 'models/SessionDocument';
import Session from 'models/ScheduleSession';

import {officialJudgingSessionType} from 'shared/constants/sessionTypes';
import * as types from '../../types/coachJudgingTypes';


const getDocumentTypesBegin = () => {
  return {
    type: types.GET_JUDGING_DOCUMENT_TYPES_BEGIN
  }
}
const getDocumentTypesError = () => {
  return {
    type: types.GET_JUDGING_DOCUMENT_TYPES_ERROR
  }
}
const getDocumentTypesSuccess = (documentTypes, sessionDocuments) => {
  return {
    type: types.GET_JUDGING_DOCUMENT_TYPES_SUCCESS,
    payload: {
      documentTypes,
      sessionDocuments
    }
  }
}

const setSelectedJudgingSession = (judgingSession) => {
  return {
    type: types.SET_COACH_SELECTED_TEAM_JUDGING_SESSION,
    payload: {
      judgingSession
    }
  }
}

const getDocumentTypes = (teamId) => {
  return async(dispatch, getState) => {
    const {eventOverview: {coachDetails, eventScheduleId}} = getState();

    try {
      await dispatch(getDocumentTypesBegin())
      const sessionsRes = await sessionService.getJudgingSessions(eventScheduleId);
      const sessionData = ((sessionsRes||{}).judging_sessions||[]).filter(item=> item.teamId === teamId)
      
      if (sessionData.length && coachDetails.isCoach) {
        const selectedTeamJudgingSession = sessionData[0];
        const judgingSessionId = selectedTeamJudgingSession.id;
        const sessionDetails = formatSessionResData(new Session(selectedTeamJudgingSession, [], [], eventScheduleId, 2))
        await dispatch(setSelectedJudgingSession(sessionDetails))
        const {included, data} = await sessionService.getSessionDocuments(judgingSessionId);
        const sessionDocuments = data.map(d => {
          const sessionDocumentDetails = formatSessionDocumentsRes(new SessionDocument(d, included));
          return {
            ...sessionDocumentDetails,
            loading: false
          }
        })
        const documentTypes = included.filter(i => i.type === 'document_type');
        const formattedDocumentTypes =  documentTypes.map(d => formatDocumentTypeRes(new DocumentType(d)));
  
        await dispatch(getDocumentTypesSuccess(formattedDocumentTypes, sessionDocuments))
      } else {
        await dispatch(getDocumentTypesSuccess([], []));
        await dispatch(setSelectedJudgingSession({}))
      }

    } catch(err) {
      await dispatch(getDocumentTypesError())
      throw err;
    }
  }
}

export default getDocumentTypes;


