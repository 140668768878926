import React from 'react';
import PropTypes from 'prop-types';
import { Tabs as AntTabs } from 'antd';
import './styles/dashboard-tabs.scss';

const Tabs = props => {
  const {children } = props;
  return (
    <AntTabs className='tabs-dashboard' gutter={48} animated={false} {...props}>
      {children}
    </AntTabs>
  );
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
};

Tabs.defaultProps = {

}

Tabs.TabPane = AntTabs.TabPane;

export default Tabs;