import * as partnersTypes from '../types/partnersTypes';

const defaultPartner = {
  organizationId: '',
  organizationName: '',
  programs: [],
};

const DEFAULT_SELECTED_EDIT_PARTNER_ADMIN = {
  id: "",
  userId: "",
  email: "",
  firstName: "",
  lastName: "",
  phone: null,
  organizationId: undefined,
  organization: '',
  organizationIds: [],
  partnerOrganizationsList: []
}

const defaultPartnerState = {
  partnerUsers: [],
  count: 0,
  isFetchingUsers: false,
  newPartnerInvited: false,
  partnerInvitees: [],
  selectedPartner: defaultPartner,
  isInviting: false,
  isPartnerUserSelected: false,
  isReinvitingPartnerAdmin: false,
  selectedEditPartnerAdmin: DEFAULT_SELECTED_EDIT_PARTNER_ADMIN,
  isUpdatingPartnerUser: false,
  derivedPartnerOrganizations: [],
  isDeletingPartner: false,
};

export default (state = defaultPartnerState, action) => {
  switch (action.type) {
    case partnersTypes.FETCH_PARTNER_ADMINS_BEGIN: {
      return {
        ...state,
        isFetchingUsers: true,
        newPartnerInvited: false
      };
    }
    case partnersTypes.FETCH_PARTNER_ADMINS_SUCCESS: {
      const { users, count } = action.payload;
      return {
        ...state,
        count,
        partnerUsers: users,
        isFetchingUsers: false
      };
    }
    case partnersTypes.FETCH_PARTNER_ADMINS_ERROR: {
      return {
        ...state,
        isFetchingUsers: false
      };
    }
    case partnersTypes.PARTNER_INVITE_SUCCESS: {
      return {
        ...state,
        newPartnerInvited: true
      };
    }
    case partnersTypes.SET_PARTNER_USER_INFO: {
      return {
        ...state,
        selectedPartner: {
          ...state.selectedPartner,
          ...action.payload
        }
      };
    }
    case partnersTypes.UPDATE_PARTNER_USER_INFO: {
      const { fieldName, value } = action.payload;
      return {
        ...state,
        selectedPartner: {
          ...state.selectedPartner,
          [fieldName]: value
        }
      }
    }
    case partnersTypes.SET_PARTNER_ORGANIZATION_NAME: {
      return {
        ...state,
        selectedPartner: {
          ...state.selectedPartner,
          organizationName: action.payload
        }
      };
    }
    case partnersTypes.SET_PARTNER_ORGANIZATION_DATA: {
      const { id, name, programs = [] } = action.payload;
      const linkedProgramsList = [];
      // { id, programId, name }
      programs.forEach(prog => {
        const isProgramIdAdded =  linkedProgramsList.find(p => p.programId === prog.programId);
        if (!isProgramIdAdded) {
          linkedProgramsList.push({
            programId: prog.programId,
            regions: [{
              id: prog.id,
              name: prog.name
            }]
          })
        } else {
          const programIndex = linkedProgramsList.findIndex(p => p.programId === prog.programId);
          const selectedProgram = {...linkedProgramsList[programIndex]};
          linkedProgramsList[programIndex] = {
            ...selectedProgram,
            regions: [...selectedProgram.regions, { id: prog.id,
              name: prog.name}]
          }
        }
      });
      // console.log({linkedProgramsList})
      return {
        ...state,
        selectedPartner: {
          ...state.selectedPartner,
          organizationName: name,
          organizationId: id,
          programs: linkedProgramsList
        }
      };
    }
    case partnersTypes.SET_PARTNER_PROGRAMS: {
      const programIds = action.payload;
      const { programs } = state.selectedPartner;
      const newPrograms = programs.length < programIds.length
        ? [...programs, {
          programId: programIds.find(id =>
            !programs.find(({ programId }) => id === programId))
        }]
        : programs.filter(({ programId }) => programIds.includes(programId));
      return {
        ...state,
        selectedPartner: {
          ...state.selectedPartner,
          programs: newPrograms
        }
      };
    }
    case partnersTypes.SET_PARTNER_PROGRAM_REGION: {
      const { programId, regions } = action.payload;
      const { programs } = state.selectedPartner;
      return {
        ...state,
        selectedPartner: {
          ...state.selectedPartner,
          programs: programs.map((program) =>
            program.programId === programId
              ? { programId, regions: [...regions] }
              : program
          )
        }
      };
    }
    case partnersTypes.SET_PARTNER_USER_SELECTED_STATUS: {
      return {
        ...state,
        isPartnerUserSelected: action.payload
      };
    }
    case partnersTypes.ADD_PARTNER_INVITEE: {
      const { partnerInvitees, selectedPartner } = state;
      const partner = { ...selectedPartner, ...action.payload };
      const invitees = [...partnerInvitees, partner];
      return {
        ...state,
        partnerInvitees: invitees,
        selectedPartner: defaultPartner
      };
    }
    case partnersTypes.DELETE_PARTNER_INVITEE: {
      const { partnerInvitees } = state;
      const userId = action.payload;
      return {
        ...state,
        partnerInvitees: partnerInvitees.filter((invitee) =>
          invitee.id !== userId && invitee.email !== userId
        )
      };
    }
    case partnersTypes.RESET_PARTNER_INVITEES: {
      return {
        ...state,
        partnerInvitees: []
      };
    }
    case partnersTypes.CLEAR_SELECTED_PARTNER: {
      return {
        ...state,
        selectedPartner: defaultPartner,
        isPartnerUserSelected: false,
      };
    }
    case partnersTypes.RESET_PARTNER_FORM: {
      const { partnerUsers } = state;
      return {
        ...defaultPartnerState,
        partnerUsers
      };
    }
    case partnersTypes.REINVITE_DASHBOARD_PARTNER_ADMIN_BEGIN:
      return {
        ...state,
        isReinvitingPartnerAdmin: true
      }
    case partnersTypes.REINVITE_DASHBOARD_PARTNER_ADMIN_ERROR:
      return {
        ...state,
        isReinvitingPartnerAdmin: false
      }
    case partnersTypes.REINVITE_DASHBOARD_PARTNER_ADMIN_SUCCESS:
      return {
        ...state,
        isReinvitingPartnerAdmin: false,
        partnerUsers: action.payload.updatedPartnerAdminsList
      }
    case partnersTypes.SET_SELECTED_EDIT_PARTNER_ADMIN:
      return {
        ...state,
        selectedEditPartnerAdmin: {
          ...DEFAULT_SELECTED_EDIT_PARTNER_ADMIN,
          ...action.payload.data
        }
      }
    case partnersTypes.EDIT_PARTNER_ADMIN_BEGIN:
      return {
        ...state,
        isUpdatingPartnerUser: true
      }
    case partnersTypes.EDIT_PARTNER_ADMIN_ERROR:
      return {
        ...state,
        isUpdatingPartnerUser: false
      }
    case partnersTypes.EDIT_PARTNER_ADMIN_SUCCESS:
      return {
        ...state,
        newPartnerInvited: true,
        isUpdatingPartnerUser: false,
        partnerUsers: action.payload.updatedPartnersList
      }
    case partnersTypes.SET_DERIVED_SELECTED_PARTNER_ORGANIZATIONS_LIST:
      return {
        ...state,
        derivedPartnerOrganizations: action.payload.data
      }
    case partnersTypes.DELETE_SELECTED_PARTNER_ADMIN_BEGIN:
      return {
        ...state,
        isDeletingPartner: true
      }
    case partnersTypes.DELETE_SELECTED_PARTNER_ADMIN_ERROR:
      return {
        ...state,
        isDeletingPartner: false
      }
    case partnersTypes.DELETE_SELECTED_PARTNER_ADMIN_SUCCESS:
      return {
        ...state,
        isDeletingPartner: false,
        partnerUsers: action.payload.updatedList,
      }
    default:
      return state;
  }
};
