import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Col, Row, Form, Button} from 'antd';
import ConfirmationModal from 'shared/components/ConfirmModal';
import Input from 'shared/components/Input';
import {connect} from 'react-redux';
import Container from 'shared/components/Container';
import { useTranslation } from 'react-i18next';
import {
  handleDeleteFirstUser,
  handleUpdateFirstUser
} from 'features/AdminDashboard/redux/actions/usersAction';

const rowProps = {
  type: 'flex', gutter: 20,
  className: 'm-t-20'
};

const colProps = {
  xs: 24,
  md: 16
}
const FormItem = Form.Item;

const EditUserContent = props => {

  const [deleteConfirmationModalVisible, setDeleteConfirmationModalVisible] = useState(false)
  const [editConfirmationModalVisible, setEditConfirmationModalVisible] = useState(false)


  const {
    selectedEditUser,
    handleDelete,
    isDeletingFirstUser,
    closeModal,
    handleUpdate,
    isUpdatingFirstUser
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    lastName,
    firstName,
    id,
    phone,
    email
  } = selectedEditUser;

  const onSaveClick = (e) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }
    setEditConfirmationModalVisible(true)

  }

  const deleteCallBack = () => {
    closeModal()
  }

  const onDeleteClick = () => {
    setDeleteConfirmationModalVisible(true)
  }
  
  const deleteUser = () => {
    handleDelete(id, deleteCallBack)
  }

  const saveCallback = () => {
    setEditConfirmationModalVisible(false)
  }
  //const saveUser = () => {
  //    const payload = {
  //      'first_name': values.firstName,
  //      'last_name': values.lastName,
  //      'mobile_phone': values.phone,
  //    }

  //    handleUpdate(id, payload, (saveCallback))
  //}
    const saveUser = (e) => {
        if (e && 'preventDefault' in e) e.preventDefault();
        form.validateFields().then((values, err) => {
            const payload = {
                'first_name': values.firstName,
                'last_name': values.lastName,
                'mobile_phone': values.phone,
            };

            handleUpdate(id, payload, (saveCallback));
        });
    };

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  return (
    <Container type='medium'>
      <ConfirmationModal
        visible={deleteConfirmationModalVisible}
        onCancel={() => {
          setDeleteConfirmationModalVisible(false);
         
        }}
        onSubmit={deleteUser}
        title='First User'
        description='Are you sure you want to remove this First User?'
        loading={isDeletingFirstUser}
      />
      <ConfirmationModal
        visible={editConfirmationModalVisible}
        onCancel={() => {
          setEditConfirmationModalVisible(false);
         
        }}
        onSubmit={saveUser}
        title='First User'
        description='Are you sure you want to edit this First User?'
        loading={isUpdatingFirstUser}
      />
      <Form hideRequiredMark onFinish={onSaveClick} onFinishFailed={onFinishFailed} form={form} name='EditUser' initialValues={{email: email, firstName: firstName, lastName: lastName, phone: phone}}>
        <Row {...rowProps}>
          <Col xs={24} md={16}>
            <FormItem name='email' label={t('emailLabel')} rules={[{ required: true, message: t('enterEmailLabel') },]}>
              <Input required disabled />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem name='firstName' label={t('firstNameLabel')} rules={[{ required: true, message: t('enterFirstNameLabel') }]}>
              <Input size='large' required  />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem name='lastName' label={t('lastNameLabel')} rules={[{ required: true, message: t('enterLastNameLabel') }]}>
              <Input size='large' required  />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <FormItem name='phone' label={t('phoneOptionalLabel')}>
              <Input size='large'  />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps} justify='space-between'>
          <Col xs={24} md={16} className='admin-dashboard__edit-modal-buttons'>
            <Button disabled={isDeletingFirstUser || isUpdatingFirstUser} htmlType='submit'>{t('saveLabel')}</Button>
            <Button disabled={isDeletingFirstUser || isUpdatingFirstUser} onClick={onDeleteClick}>Delete</Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

EditUserContent.propTypes = {
  form: PropTypes.object.isRequired,
  selectedEditUser: PropTypes.object.isRequired,
  isDeletingFirstUser: PropTypes.bool.isRequired,
  isUpdatingFirstUser: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = ({ adminBoard}) => {
  const {users: {selectedEditUser, isDeletingFirstUser, isUpdatingFirstUser}} = adminBoard;
  return {
    selectedEditUser,
    isDeletingFirstUser,
    isUpdatingFirstUser
  }
}

const mapDispatchToProps = {
  handleDelete: handleDeleteFirstUser,
  handleUpdate: handleUpdateFirstUser
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserContent);