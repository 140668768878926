import React from 'react';
import PropTypes from 'prop-types';

const PlayButton = ({fill}) => {
  return (
    <svg viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      <g id="Scoring-and-Refereeing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Teams---Single-Event---Scoring---Coach" transform="translate(-520.000000, -2097.000000)" fill={fill}>
          <g id="Group-22" transform="translate(250.000000, 465.000000)">
            <g id="Group-18" transform="translate(0.000000, 1543.000000)">
              <g id="Group-17" transform="translate(0.000000, 48.000000)">
                <g id="Group-15" transform="translate(220.000000, 16.000000)">
                  <g id="Icons/-Play-button-red" transform="translate(50.000000, 25.000000)">
                    <path d="M30.0983607,59.6065574 C13.8688525,59.6065574 0.590163934,46.3278689 0.590163934,30.0983607 C0.590163934,13.8688525 13.8688525,0.590163934 30.0983607,0.590163934 C46.3278689,0.590163934 59.6065574,13.8688525 59.6065574,30.0983607 C59.6065574,46.4262295 46.4262295,59.6065574 30.0983607,59.6065574 Z M30.0983607,3.3442623 C15.3442623,3.3442623 3.3442623,15.3442623 3.3442623,30.0983607 C3.3442623,44.852459 15.3442623,56.852459 30.0983607,56.852459 C44.852459,56.852459 56.852459,44.852459 56.852459,30.0983607 C56.852459,15.3442623 44.852459,3.3442623 30.0983607,3.3442623 Z" id="Shape" />
                    <polygon id="Path_11" points="44.1639344 29.704918 33.147541 36.0983607 22.1311475 42.4918033 22.1311475 17.0163934 33.147541 23.4098361" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

PlayButton.propTypes = {
  fill: PropTypes.string
};

PlayButton.defaultProps = {
  fill: 'currentColor'
}

export default PlayButton;