import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'shared/components/Container';
import RubricSection from '../FLLChallengeRubric/RubricSection';
import RubricFLLEInstructions from './instructions';
import ReviewingCriteria from './ReviewingCriteria';
// import coreValueOptions from './options';
import innovationOptions from './options';
import { connect } from 'react-redux';
import FormFieldLegend from '../FLLChallengeRubric/FormFieldLegend';
import RadioBtn from 'shared/components/RadioButton';
import { Col, Row, Form} from 'antd';
import JudgingComments from '../RubricUploadForm/JudgingComments';
import { updateJudgingRubricFLLE } from 'features/EventOverviewContainer/redux/actions/judgingRubric';
import FeedbackComments from '../FLLChallengeRubric/FeedbackComments';

const FormItem = Form.Item;
const moduleNameClassName = `h-2 heading--white`;
const moduleTitleClassName = `h-2 heading--charcoal p-l-40`;
// const paragraphClassName = 'paragraph--large paragraph--charcoal paragraph--normal c-fll-rubric-form__sub-title p-r-40'
const paragraphClassName = 'paragraph--large paragraph--charcoal paragraph--normal'

const radioOptions = [
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' }
]

const RubricForm = (props) => {
    const { t } = useTranslation()
    const { rubric, judgingRubricComments, isUploadedRubricPublished, updateRubric } = props;
    const [ form ] = Form.useForm();
    const initialFormValues = {};

    innovationOptions.map((v) => {
        v.fields.map((f) => {
            initialFormValues[f.id] = rubric[f.id];
        });
    });

    const handleChange = (field, e, isRating = false) => {
        const { target: { value } } = e;
        updateRubric(field, value);
    }

    return (
        <Container>
            <div className='c-rubric-upload'>
                <RubricFLLEInstructions
                    instructionsText={t('exploreReviewingSheetInstructionsLabel')} 
                    // button={InstructionToggleButton}
                    collapsed={false}
                >
                    <ReviewingCriteria />
                </RubricFLLEInstructions>
            </div>

            {/* <RubricSection
                name={t('innovationProjectLabel')}
                questionText={t('5questionsLabel')}
                button={<></>}
            > */}
            {<Form hideRequiredMark className='m-t-40' name='CoreValuesRubric' initialValues={{initialFormValues}}>
                {innovationOptions.map(v => (
                    <div tabIndex={0} className='c-fll-rubric-form__section' key={v.id}>
                        <div>
                            <div className=''>
                                <p className={`${moduleTitleClassName} fll_e_header`}>{t(v.title)}</p>
                            </div>
                            <div className='c-fll-rubric-form__section-content-wrapper'>
                                {v.fields.map(f => {
                                    return (
                                        <fieldset className='c-fll-rubric-form' key={f.id}>
                                            <div className='c-fll-rubric-form__section-content-legend-wrapper'>
                                                <Row>
                                                    <Col md={8} xs={24} className="p-l-40">
                                                        <div className='m-t-20'>
                                                            <p key={v.id} style={{ fontWeight: 600 }} className={paragraphClassName}>{t(f.value)}</p>
                                                        </div>
                                                    </Col>

                                                    <Col md={8} xs={24} className="p-l-20">
                                                        <div className='m-t-20'>
                                                            <p key={v.id} className={paragraphClassName}>{t(f.answear)}</p>
                                                        </div>
                                                    </Col>

                                                    <Col md={8} xs={24}>
                                                        <FormItem name={f.id} label={<span className='u-sr-only'>{t(v.description)}</span>}>
                                                            {/* {form.getFieldDecorator(f.id, {
                                                                
                                                                // initialValue: v.id == "IP1" && f.id == "item_1_option_1" ? 1 : null
                                                                initialValue: rubric[f.id]
                                                            })( */}
                                                                <RadioBtn
                                                                    options={radioOptions}
                                                                    name={t(f.legendTitle)}
                                                                    onChange={(e) => handleChange(f.id, e)}
                                                                    disabled={rubric.is_publishable || rubric.is_published}
                                                                />
                                                            {/* )} */}

                                                        </FormItem>
                                                    </Col>
                                                </Row>
                                                {/* <div className='m-t-20'>
                                                    <p key={v.id} className={paragraphClassName}>{t(f.value)}</p>
                                                </div>
                                                <div></div>
                                                <FormItem label={<span className='u-sr-only'>{t(v.description)}</span>}>
                                                    {form.getFieldDecorator(f.id, {
                                                        initialValue: 1
                                                    })(
                                                        <RadioBtn
                                                            options={radioOptions}
                                                            name={t(f.legendTitle)}
                                                            onChange={(e) => handleChange(f.id, e)}
                                                            disabled={rubric.is_published || rubric.is_publishable}
                                                        />
                                                    )}

                                                </FormItem> */}
                                            </div>
                                        </fieldset>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                ))}

            </Form>}
            {/* </RubricSection> */}
            <FeedbackComments commentSection="flle" rubric={rubric} commentsData={judgingRubricComments} />
        </Container>
    )
}

const mapDispatchToProps = {
    updateRubric: updateJudgingRubricFLLE
}

const mapStateToProps = (state) => {
    const { judgingRubric: {
        judgingRubricTemplate,
        isLoadingSessionRubric,
        uploadedRubricFile,
        isRubricFileUploaded,
        isUploadedRubricPublishable,
        isUploadedRubricPublished,
        fllEChallengeRubric,
        judgingRubricComments,
    } } = state;
    return {
        sessionRubricTemplate: judgingRubricTemplate,
        isLoadingSessionRubric,
        uploadedRubricFile,
        isRubricFileUploaded,
        isUploadedRubricPublishable,
        rubric: fllEChallengeRubric,
        judgingRubricComments
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RubricForm);
// export default RubricForm