import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  fetchEventSponsors
} from 'features/EventContainer/redux/actions/sponsorsActions';
import SponsorsManagement from './SponsorsManagement';
import SponsorsPreview from './SponsorsPreview';

import '../../styles/EventSponsors.scss';

const SponsorsContainer = (props) => {
  const { scheduleId } = props;
  const [preview, setPreview] = useState(false);
  
  useEffect(() => {
    if (scheduleId) {
      props.fetchEventSponsors(scheduleId);
    }
  }, [scheduleId]);

  return (
    <div className='c-event-sponsors'>
      <SponsorsManagement 
        previewSponsors={() => setPreview(true)}
        hide={preview}
        {...props}
      />
      <SponsorsPreview 
        editSponsors={() => setPreview(false)}
        hide={!preview}
        {...props}
      />
    </div>
  );
};

const mapStateToProps = ({ event }) => {
  const {
    eventSponsors: { sponsors, newSponsorCreated, isFetchingSponsors },
    eventDetails: { eventScheduleId, eventProgramId, scheduleError },
    eventSchedule: { errorFetchingEventSchedule }
  } = event;
  return {
    sponsors,
    newSponsorCreated,
    isFetchingSponsors,
    scheduleId: eventScheduleId,
    programId: eventProgramId,
    scheduleError: scheduleError || errorFetchingEventSchedule
  };
};

const mapDispatchToProps = {
  fetchEventSponsors
};

export default connect(mapStateToProps, mapDispatchToProps)(SponsorsContainer);
