import { notification } from 'antd';
import * as eventFileService from 'utils/services/eventFiles';
import * as types from '../../types/coachJudgingTypes';


const removeDocumentBegin = () => {
  return {
    type: types.REMOVE_JUDGING_SESSION_DOCUMENT_BEGIN
  }
}

const removeDocumentSuccess = (sessionDocuments) => {
  return {
    type: types.REMOVE_JUDGING_SESSION_DOCUMENT_SUCCESS,
    payload: {
      sessionDocuments
    }
  }
}

const removeDocumentError = () => {
  return {
    type: types.REMOVE_JUDGING_SESSION_DOCUMENT_ERROR
  }
}



const handleRemoveDocument = (sessionDocumentId, fileId) => {
  return async (dispatch, getState) => {
    const {coachJudging: {sessionDocuments}} = getState();
    const sessionDocumentsCopy = [...sessionDocuments]
    await dispatch(removeDocumentBegin())

    try {

      await eventFileService.removeEventFile(fileId)
      const sessionDocumentIndex = sessionDocumentsCopy.findIndex(i => i.sessionDocumentId === sessionDocumentId);

      const selectedSessionDocument = sessionDocumentsCopy[sessionDocumentIndex];
      const sessionDocumentFiles = selectedSessionDocument.files.filter(f => f.eventFileId !== fileId);

      const updatedSessionDocument = {
        ...selectedSessionDocument,
        files: sessionDocumentFiles
      }
      sessionDocumentsCopy[sessionDocumentIndex] = updatedSessionDocument;
      await dispatch(removeDocumentSuccess(sessionDocumentsCopy))

    } catch(err) {
      await dispatch(removeDocumentError())
      notification.error({
        message: 'Error',
        description: 'File not removed',
      });
      throw err;
    }
  }
}

export default handleRemoveDocument;