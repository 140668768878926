import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import Paragraph from 'shared/components/Paragraph';
import DownloadLink from 'shared/components/DownloadLink';
import { exportScore } from 'utils/services/scoreExport';
import { useTranslation } from 'react-i18next';
import {exportJudging, exportFllChallengeJudging} from 'utils/services/judgingRubricExport';
import {
  CORE_VALUES_TYPE,
  INNOVATION_TYPE,
  ROBOT_DESIGN_TYPE,
} from 'shared/constants/judgingExportResultTypes';

const DownloadInstructions = ({ eventId, is_FLLC_event }) => {
  const [scoreData, setScoreData] = useState('');
  const [innovationData, setInnovationData] = useState('');
  const [robotDesignData, setRobotDesignData] = useState('');
  const [coreValuesData, setCoreValuesData] = useState('');

  const { t } = useTranslation()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await exportScore(eventId);
        setScoreData(results);
      } catch (e) {
        notification.error({
          message: 'Error',
          description: 'Failed to get match scores.'
        });
      }
    };
    fetchData();
  }, [eventId]);

  useEffect(() => {
    const fetchJudgingData = async () => {
      try {
        const innovationResults = await exportFllChallengeJudging(eventId, INNOVATION_TYPE);
        setInnovationData(innovationResults)
        const coreValuesResults = await exportFllChallengeJudging(eventId, CORE_VALUES_TYPE);
        setCoreValuesData(coreValuesResults)
        const robotDesignResults = await exportFllChallengeJudging(eventId, ROBOT_DESIGN_TYPE);
        setRobotDesignData(robotDesignResults)
        
      } catch(err) {
        notification.error({
          message: 'Error',
          description: is_FLLC_event ? 'Failed to get judge results.' : 'Failed to get reviewer results.'
        });
      }
    }
    fetchJudgingData()

  }, [eventId])

  return (
    <div className='c-event-overview-awards__instructions'>
      <Paragraph size='medium' weight='bold'>{t('instructionsLabel')}</Paragraph>
      <Paragraph size='medium'>
        {is_FLLC_event ? t('awardAssignmentInstructionText') : t('nonChallengeAwardAssignmentInstructionText')}
      </Paragraph>
      <div className='c-event-overview-awards__downloads'>
       
        <DownloadLink
          href={scoreData}
          download='Match_Results.csv'
          csvData={false}
          text="Match Results (csv)"
          filename='Match_Results.csv'
       
        />   
        <DownloadLink
          text={`${t('innovationJudgingResultsLabel')}`}
          download='Innovation_Judging_Results.csv'
          href={innovationData}
          filename='Innovation_Judging_Results.csv'
        />
        <DownloadLink
          text={`${t('CoreJudgingResultsLabel')}`}
          download='Core_Values_Judging_Results.csv'
          href={coreValuesData}
          filename='Core_Values_Judging_Results.csv'
        />
        <DownloadLink
          text='Robot Design Judging Results (csv)'
          download='Robot_Design_Judging_Results.csv'
          href={robotDesignData}
          filename='Robot_Design_Judging_Results.csv'
        />
      </div>
    </div>
  );
};

export default DownloadInstructions;
