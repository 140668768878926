import Search from './icons/Search';
import DownArrow from './icons/DownArrow';
import Info from './icons/Info';
import RightArrow from './icons/RightArrow';
import Close from './icons/Close';
import DownArrowBlue, { SmallDownArrowBlue } from './icons/DownArrowBlue';
import Spreadsheet from './icons/Spreadsheet';
import UpArrow from './icons/UpArrow';
import LeftArrow from './icons/LeftArrow';
import WarningIcon from './icons/WarningIcon';
import Global from './icons/Global';
import ResourceLib from './icons/ResourceLib';
import TeamworkSkills from './icons/TeamworkSkills';
import UpArrowBlue from './icons/UpArrowBlue';
import WarningIconOrange from './icons/WarningIconOrange';
import GreenCheckIcon from './icons/GreenCheckIcon';
import WarningIconRed from './icons/WarningIconRed';
import EditIcon from './icons/EditIcon';
import WhiteArrowUp from './icons/WhiteArrowUp';
import WhiteArrowDown from './icons/WhiteArrowDown';
import RightArrowBlue from './icons/RightArrowBlue';
import whiteCheckIcon from './icons/whiteCheckIcon';
import CloseCircle from './icons/CloseCircle';

const iconMap =  {
  search: Search,
  downArrow: DownArrow,
  info: Info,
  rightArrow: RightArrow,
  close: Close,
  closeCircle: CloseCircle,
  downArrowBlue: DownArrowBlue,
  upArrowBlue: UpArrowBlue,
  smallDownBlue: SmallDownArrowBlue,
  smallRightArrowBlue: RightArrowBlue,
  spreadsheet: Spreadsheet,
  upArrow: UpArrow,
  leftArrow: LeftArrow,
  warningIcon: WarningIcon,
  global: Global,
  resourceLib: ResourceLib,
  teamworkSkills: TeamworkSkills,
  orangeWaring: WarningIconOrange,
  greenCheck: GreenCheckIcon,
  whiteCheck: whiteCheckIcon,
  redWarning: WarningIconRed,
  edit: EditIcon,
  whiteArrowUp: WhiteArrowUp,
  whiteArrowDown: WhiteArrowDown
};

export default iconMap;
