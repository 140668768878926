import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button } from 'antd';
import Checkbox from 'shared/components/Checkbox';
import AutoComplete from 'shared/components/FormAutoComplete';
import FirstText from 'shared/components/FirstText';
import * as organizationService from 'utils/services/organizations';
import {
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FLL_CHALLENGE_ID,
  FTC_ID
} from 'shared/constants/programIds';
import RegionAutoComplete from './RegionAutoComplete';
import './PartnerOrganization.scss';

const FormItem = Form.Item;
const { Option } = AutoComplete;

const REGION_LABELS = {
  [FLL_CHALLENGE_ID]: 'FLL Challenge',
  [FLL_EXPLORE_ID]: 'FLL Explore',
  [FLL_DISCOVER_ID]: 'FLL Discover',
  [FTC_ID]: 'FTC',
};

const PartnerOrganization = (props) => {
  const { t } = useTranslation()
  const [orgs, setOrgs] = useState([]);
  const {
    partner,
    programOptions,
    setPartnerProgRegion,
    handleChangePrograms,
    setPartnerOrgName,
    setPartnerOrganization,
    fetchRegionsBy
  } = props;
  const { programs, organizationName, organizationId, email } = partner;
  const programIds = programs.map(({ programId }) => programId);
  const [form] = Form.useForm();

  


  useEffect(() => {
    if (!organizationName) {
      form.resetFields();
    }
  }, [organizationName])

  const handleSearchOrgs = async (input) => {
    await setPartnerOrganization({ name: input });
    const res = await organizationService.getOrganizationsLikeName(input);
   
    setOrgs(res);
    if (res.length === 1 && res[0].name === input) {
      setPartnerOrganization(res[0]);
    }
  };

  const handleSelectOrg = (name) => {
   
    const org = orgs.find((o) => o.name === name);
   
    if (org) setPartnerOrganization(org);
  };

  return (
    <Form hideRequiredMark className='add-partner-organization' name='PartnerOrganizationForm'>
      <FormItem name='organizationName' label={(
        <span className='paragraph--large paragraph--bold paragraph--charcoal'>
          {t('partnerOrganizationLabel')}
        </span>
      )}
      rules={[{ required: true, message: 'Enter Organization Name.' }]}>
          <AutoComplete
            allowClear
            disabled={!email}
            placeholder={t('organizationLabel')}
            onChange={setPartnerOrgName}
            onSelect={handleSelectOrg}
            onSearch={handleSearchOrgs}
            dataSource={orgs.map(u => (
              <Option key={u.id} value={u.name} text={u.name}>{u.name}</Option>
            ))}
          />
      </FormItem>
      <div className='m-t-24 m-b-8'>
        <span className='paragraph--large paragraph--charcoal'>Programs</span>
        {' '}
        <span className='paragraph--small paragraph--charcoal'>{t('programAllLabel')}</span>
      </div>
      <Checkbox.Group
        disabled={!organizationName || organizationId}
        className='add-partner-organization__checkboxs'
        onChange={handleChangePrograms}
        value={programIds}
      >
        {programOptions.map(({ name, id }) => (
          <Checkbox key={id} value={id}>
            <p className='paragraph--large paragraph--charcoal'>
              <FirstText /> 
              {' '}
              {name}
            </p>
          </Checkbox>
        ))}
      </Checkbox.Group>
      
      {programs.map(({ programId, regionId, regions }) => {
        const program = programOptions.find(({ id }) => id === programId);
        const { shortName } = program||{};
        // const isExistingOrgWithRegions = organizationId && Array.isArray(regions);
        const initialRegions = (organizationId && Array.isArray(regions)) ? regions.map(r=> r.id) : []
       
        return (
          <FormItem
            key={programId}
            label={`${REGION_LABELS[programId]} Region`}
            className='m-t-8'
            name={`${REGION_LABELS[programId]}_region`}
            rules={[{ required: true, message: 'Enter Organization Name.' }]}
          >
            <RegionAutoComplete
              key={programId}
              disabled={organizationId}
              shortName={shortName}
              regions={organizationId ? (regions || []) : program.regions}
              regionId={regionId}
              programId={programId}
              setPartnerProgRegion={setPartnerProgRegion}
              fetchRegionsBy={fetchRegionsBy}
              initialValue={initialRegions}
              {...props}
            />
          </FormItem>
        );
      })}
    </Form>
  )
};

export default (PartnerOrganization);
