import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Dropdown from 'shared/components/Dropdown';

const { Option } = Dropdown;

const TitleAndSort = props => {
  const { t } = useTranslation();
  
  const { title, fieldOptions, onSelect } = props;
  return (
    <div className='admin-dashboard__title-sort'>
      <h2 className='heading--charcoal title-sort__title'>{title}</h2>
      <Dropdown showSearch optionFilterProp='children' placeholder={t('sortByLabel')} additionalClassNames='dropdown--180 sort-dropdown' onChange={onSelect}>
        {fieldOptions.map(f => (
          <Option value={f.value} key={f.value}>{f.description}</Option>
        ))}
      </Dropdown>
    </div>
  );
};

TitleAndSort.propTypes = {
  title: PropTypes.string,
  fieldOptions: PropTypes.array,
  onSelect: PropTypes.func,
};

TitleAndSort.defaultProps = {
  title: '',
  fieldOptions: [],
  onSelect: null,
};

export default TitleAndSort;
