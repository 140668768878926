import { Row, Col } from 'antd';
import React from 'react';
import { useMediaQuery } from 'react-responsive'

import Divider from 'shared/components/Divider';
import { rowProps } from 'utils/rowHelpers';
import { colSmProps } from 'utils/colHelpers';
import ManualForm from './ManualForm';
import InviteList from './InviteList';
import './manual-view.scss';

const ManualView = (props) => {
  const isMedium = useMediaQuery({ query: '(min-width: 786px)' })
  const {emailLanguage} = props;

  return (
    <Row {...rowProps} className='manual'>
      <Col {...colSmProps}>
        <ManualForm emailLanguage={emailLanguage}/>
      </Col>
      {isMedium && (<div>
        <Divider type='vertical' />
      </div>)}
      <Col {...colSmProps}>
        <InviteList emailLanguage={emailLanguage}/>
      </Col>
    </Row>
  );
};

export default ManualView;
