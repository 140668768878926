import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { rowProps } from 'utils/rowHelpers'
import { colXsProps } from 'utils/colHelpers';
import { useTranslation } from 'react-i18next';

const Criterion = (props) => {
    const numberTextClassName = 'paragraph--white paragraph--bold paragraph--large m-t-18'
    const {
        number,
        label,
        description
    } = props;

    return (
        <div className='c-fll-rubric-instructions__reviewing-criterion'>
            <div className={`${numberTextClassName} c-fll-rubric-instructions__reviewing-criterion-numberFLLE`}>{number}</div>
            <div className="mobile-content-wrapper">
                <p className='paragraph--uppercase paragraph--medium paragraph--charcoal m-t-18'>{number}. {label}</p>
                <p className='paragraph--medium paragraph--charcoal m-t-8'>{description}</p>
            </div>
        </div>
    )
}

const reviewOptions = [
    {
        number: 1,
        label: 'exploreReviewingBeginningLabel',
        description: 'exploreReviewingBeginningInstructionLabel'
    },
    {
        number: 2,
        label: 'exploreReviewingAcomplishedLabel',
        description: 'exploreReviewingAcomplishedInstructionLabel'
    },
    {
        number: 3,
        label: 'exploreReviewingExceedsLabel',
        description: 'exploreReviewingExceedsInstructionLabel'
    },
]

const ReviewingCriteria = props => {

    const titleClassName = 'paragraph--large paragraph--charcoal paragraph--bold';

    const { t } = useTranslation();

    return (
        <div className='m-t-36'>
            <p className={titleClassName}>{t('reviewingCriteriaLabel')}</p>
            <Row {...rowProps}>
                {reviewOptions.map(i => (
                    <Col xs={24} md={8}  key={i.label}>
                        <Criterion number={i.number} label={t(i.label)} description={t(i.description)} />
                    </Col>
                ))}
            </Row>
        </div>
    );
};

ReviewingCriteria.propTypes = {

};

export default ReviewingCriteria;