import { REFEREE_ID } from 'shared/constants/eventRoleTypes';
import UserEventRole from 'models/UserEventRole';
import * as panelsService from 'utils/services/panels';
import ScheduleSession from 'models/ScheduleSession';
import * as sessionService from 'utils/services/sessions';
import * as panelService from 'utils/services/panels';
import * as userEventRoleService from 'utils/services/eventRoles';
import { sortPanelUsers } from '../judgingActions/getJudgingPanels';
import { REFEREE_REVIEW_PANEL_TYPE_ID } from 'shared/constants/panelTypes';

import * as types from '../../types/scoringTypes';
import _ from 'lodash';
import Panel from 'models/Panel';
import PanelModel from 'models/PanelClassModel';

export const sortScoringPanels = (a, b) => {
  const sessionA = a.panelName
  const sessionB = b.panelName

  const teamNameA = a.teamName
  const teamNameB = b.teamName

  const comparison = 0;

  if (teamNameA > teamNameB) {
    return -1
  } if (teamNameB < teamNameA) {
    return 1
  }
  if (teamNameB === teamNameA) {
    return b.sessionTypeName.localeCompare(a.sessionTypeName)

  }
  return comparison;
  // if (!sessionA || !sessionB) {
  //   if (teamNameA > teamNameB) {
  //     return -1
  //   } if (teamNameB < teamNameA) {
  //     return 1
  //   } 
  //   if (teamNameB === teamNameA) {
  //     return b.sessionTypeName.localeCompare(a.sessionTypeName)

  //   }


  //   // if (!sessionA) {
  //   //   return -1
  //   // }
  //   // if (!sessionB) {
  //   //   return 1;
  //   // }

  // }



  // if (sessionA > sessionB) {
  //   if (teamNameA > teamNameB) {
  //     return -1
  //   } if (teamNameB < teamNameA) {
  //     return 1
  //   } 
  //   if (teamNameB === teamNameA) {
  //     return b.sessionTypeName.localeCompare(a.sessionTypeName)

  //   }
  // } else if (sessionA < sessionB) {
  //   if (teamNameA > teamNameB) {
  //     return -1
  //   } if (teamNameB < teamNameA) {
  //     return 1
  //   } 
  //   if (teamNameB === teamNameA) {
  //     return b.sessionTypeName.localeCompare(a.sessionTypeName)

  //   }
  // }
  // return comparison;
}
const getScoringPanelsBegin = () => {
  return {
    type: types.GET_EVENT_SCORING_PANELS_BEGIN
  }
}

const getScoringPanelsError = () => {
  return {
    type: types.GET_EVENT_SCORING_PANELS_ERROR
  }
}

export const getScoringPanelsSuccess = (formattedPanels, refereeOptions, sessionOptions) => {
  return {
    type: types.GET_EVENT_SCORING_PANELS_SUCCESS,
    payload: {
      formattedPanels, refereeOptions, sessionOptions
    }
  }
}

const setScoringSessionTeams = (scoringSessionTeams) => {
  return {
    type: types.SET_SCORING_SESSION_TEAMS,
    payload: {
      scoringSessionTeams
    }
  }
}

const setExpands = (panels) => {
  return {
    type: types.SET_SCORING_PANELS_EXPANDS,
    payload: {
      panels
    }
  }
}

export const getScoringTabData = async (eventId, eventScheduleId) => {
  let panelsData = [];
  const sessionsResponse = await sessionService.getScoringSessions(eventScheduleId);

  let panels = await sessionService.getPanelsData(eventScheduleId, 'score');
  // let panels = testData;
  let panelsIncluded = panels;

  const { included, data } = sessionsResponse;

  // const sessionsPanels = panelsIncluded.filter(i => i.type === 'panel');
  let sessionsPanels = [];

  panelsIncluded.forEach(panel => {    
    if (panel?.panelType?.id == REFEREE_REVIEW_PANEL_TYPE_ID) { 
      sessionsPanels.push(panel)
    }
  });

  let panelIdsArray = [];

  if (sessionsPanels.length) {
    panelIdsArray = sessionsPanels.map(p => p.id);
    panelsData = await panelsService.getAllPanelsByIds(panelIdsArray);
  }

  const filter = {
    'role.id': `eq:${REFEREE_ID}`,
    'event.id': `eq:${eventId}`
  };
  const refereesRes = await userEventRoleService.getAllUserEventRolesBy(filter);
  const eventReferees = refereesRes.data.map(j => {
    const referee = new UserEventRole(j, refereesRes.included);
    return {
      fullName: referee.userFullNameString(),
      email: referee.userEmailString(),
      userId: referee.userId
    };
  })
  const eventScoringSessions = data.map(d => {
    const scoringSession = new ScheduleSession(d, included);

    return {
      panelId: scoringSession.panelId,
      sessionTypeId: scoringSession.sessionTypeId,
      sessionId: scoringSession.sessionId,
      teamName: scoringSession.getTeamName(),
      teamId: scoringSession.teamId,
      sessionTypeName: scoringSession.getSessionTypeName(),
      matchNumber: scoringSession.getMatchNumber()
    }
  })


  const panelsRefereesList = [];
  const panelsSessionsList = [];

  const scoringPanels = panelsData.map(panelClass => {
    const panelReferees = panelClass.referees;
    const panelSessions = panelClass.sessions;
    panelReferees.forEach(j => {
      const userAlreadyAddedIndex = panelsRefereesList.findIndex(i => i.id === j.id);
      const isUserAlreadyAdded = userAlreadyAddedIndex > -1;

      const panelObj = {
        panelId: panelClass.id,
        panelName: panelClass.name
      }
      let selectedReferee;

      if (isUserAlreadyAdded) {
        selectedReferee = panelsRefereesList[userAlreadyAddedIndex];
        selectedReferee = {
          ...selectedReferee,
          panels: [...selectedReferee.panels, panelObj]
        }
        panelsRefereesList[userAlreadyAddedIndex] = selectedReferee;
      } else {
        selectedReferee = {
          ...j,
          panelId: panelClass.id,
          panelName: panelClass.name,
          panels: [panelObj]
        }
        panelsRefereesList.push(selectedReferee)
      }
    })

    panelSessions.forEach(s => {
      const selectedSession = {
        ...s,
        panelId: panelClass.id,
        panelName: panelClass.name
      }
      panelsSessionsList.push(selectedSession);
    })
    return panelClass;
  })

  const refereeOptions = eventReferees.map(eventReferee => {
    const refereeOnPanel = panelsRefereesList.find(p => p.id === eventReferee.userId) || {};
    return {
      ...eventReferee,
      ...refereeOnPanel,
      userId: eventReferee.userId,
      fullName: eventReferee.fullName
    }
  })
  const sessionOptions = eventScoringSessions.map(eventSession => {
    const sessionOnPanel = panelsSessionsList.find(p => p.id === eventSession.sessionId) || {};
    return {
      ...eventSession,
      ...sessionOnPanel
    }
  })

  const formattedPanels = scoringPanels.map(scoringPanel => {
    const panelReferees = [];
    scoringPanel.referees.forEach(j => {
      const selectedReferee = eventReferees.find(ej => ej.userId === j.id);
      if (selectedReferee) {
        panelReferees.push(selectedReferee)
      }
    })

    const panelSessions = []
    scoringPanel.sessions.forEach(j => {
      const selectedSession = eventScoringSessions.find(es => es.sessionId === j.id);
      if (selectedSession) {
        panelSessions.push(selectedSession)
      }
    })
    const sortedPanelReferees = _.orderBy(panelReferees, ['fullName', 'email'])
    const sortedPanelSessions = _.orderBy(panelSessions, ['teamName', 'sessionTypeId', 'matchNumber'])
    return {
      panelName: scoringPanel.name,
      panelId: scoringPanel.id,
      panelReferees: sortedPanelReferees,
      panelSessions: sortedPanelSessions,
    }
  })

  return {
    formattedPanels,
    refereeOptions: refereeOptions.sort(sortPanelUsers),
    sessionOptions: sessionOptions.sort(sortScoringPanels)
  }
}

export const getScoringData = async (eventId, eventScheduleId, pageNumber) => {
  let panelsData = [];

  const sessionsResponse = await sessionService.getScoringSessionsV2(eventScheduleId, {}, { size: 10, number: pageNumber});

  let panels = [];
  panels = await sessionService.getPanelsData(eventScheduleId, 'score');
  const data = sessionsResponse
  // const sessionsPanels = panelsIncluded.filter(i => i.type === 'panel');
  let sessionsPanels = [];

  panels.forEach(item => {
    if (item?.panelType?.id == REFEREE_REVIEW_PANEL_TYPE_ID) {
      sessionsPanels.push(item)
    }
  });

  if (sessionsPanels.length) {
    panelsData = sessionsPanels.map(panel => new PanelModel(panel, 'score'))
  }

  const filter = {
    'role.id': `eq:${REFEREE_ID}`,
    'event.id': `eq:${eventId}`
  };
  const refereesRes = await userEventRoleService.getAllUserEventRolesBy(filter);
  const eventReferees = refereesRes.data.map(j => {
    const referee = new UserEventRole(j, refereesRes.included);
    return {
      fullName: referee.userFullNameString(),
      email: referee.userEmailString(),
      userId: referee.userId
    };
  })


  const eventScoringSessions = data.map(d => {
    // const scoringSession = new ScheduleSession(d);
    return {
      panelId: d.panelId,
      sessionTypeId: d.sessionTypeId,
      sessionId: d.id,
      // teamName: scoringSession.getTeamName(),
      teamName: d?.team ? (d.team?.teamNumber && d.team?.name) ? `${d.team.teamNumber} - ${d.team.name}` : d.team.name : '',
      teamId: d.teamId,
      // sessionTypeName: scoringSession.getSessionTypeName(),
      sessionTypeName: d.sessionTypeDesc ? d.sessionTypeDesc : undefined,
      // matchNumber: scoringSession.getMatchNumber()
      matchNumber: d.matches && d.matches[0] !== undefined ? d.matches[0].matchNumber : undefined
    }
  })


  const panelsRefereesList = [];
  const panelsSessionsList = [];

  const scoringPanels = panelsData.map(panelClass => {
    const panelReferees = panelClass.referees;
    const panelSessions = panelClass.sessions;
    panelReferees.forEach(j => {
      const userAlreadyAddedIndex = panelsRefereesList.findIndex(i => i.id === j.id);
      const isUserAlreadyAdded = userAlreadyAddedIndex > -1;

      const panelObj = {
        panelId: panelClass.id,
        panelName: panelClass.name
      }
      let selectedReferee;

      if (isUserAlreadyAdded) {
        selectedReferee = panelsRefereesList[userAlreadyAddedIndex];
        selectedReferee = {
          ...selectedReferee,
          panels: [...selectedReferee.panels, panelObj]
        }
        panelsRefereesList[userAlreadyAddedIndex] = selectedReferee;
      } else {
        selectedReferee = {
          ...j,
          panelId: panelClass.id,
          panelName: panelClass.name,
          panels: [panelObj]
        }
        panelsRefereesList.push(selectedReferee)
      }
    })

    panelSessions.forEach(s => {
      const selectedSession = {
        ...s,
        panelId: panelClass.id,
        panelName: panelClass.name
      }
      panelsSessionsList.push(selectedSession);
    })
    return panelClass;
  })

  const refereeOptions = eventReferees.map(eventReferee => {
    const refereeOnPanel = panelsRefereesList.find(p => p.id === eventReferee.userId) || {};
    return {
      ...eventReferee,
      ...refereeOnPanel,
      userId: eventReferee.userId,
      fullName: eventReferee.fullName
    }
  })
  const sessionOptions = eventScoringSessions.map(eventSession => {
    const sessionOnPanel = panelsSessionsList.find(p => p.id === eventSession.sessionId) || {};
    return {
      ...eventSession,
      ...sessionOnPanel
    }
  })

  const formattedPanels = scoringPanels.map(scoringPanel => {
    const panelReferees = [];
    scoringPanel.referees.forEach(j => {
      const selectedReferee = eventReferees.find(ej => ej.userId === j.id);
      if (selectedReferee) {
        panelReferees.push(selectedReferee)
      }
    })

    const panelSessions = []
    scoringPanel.sessions.forEach(j => {
      const selectedSession = eventScoringSessions.find(es => es.sessionId === j.id);
      if (selectedSession) {
        panelSessions.push(selectedSession)
      }
    })
    const sortedPanelReferees = _.orderBy(panelReferees, ['fullName', 'email'])
    const sortedPanelSessions = _.orderBy(panelSessions, ['teamName', 'sessionTypeId', 'matchNumber'])
    return {
      panelName: scoringPanel.name,
      panelId: scoringPanel.id,
      panelReferees: sortedPanelReferees,
      panelSessions: sortedPanelSessions,
    }
  })
  const sortedPanels = formattedPanels.sort((a, b) => {
    const aValue = a.panelName ?? '';
    const bValue = b.panelName ?? '';
    return aValue.localeCompare(bValue, undefined, { numeric: true, sensitivity: 'base'});
  });
  return {
    formattedPanels: sortedPanels,
    refereeOptions: refereeOptions.sort(sortPanelUsers),
    sessionOptions: sessionOptions.sort(sortScoringPanels)
  }
}

const autoAssignPanels = async (scheduleId, panelTypeId) => {
  let response = await panelService.autoAssignPanels(scheduleId, REFEREE_REVIEW_PANEL_TYPE_ID)
  return response
  
  // const { formattedPanels, refereeOptions, sessionOptions } = await getScoringTabData(id, eventScheduleId);
  // await dispatch(getScoringPanelsSuccess(formattedPanels, refereeOptions, sessionOptions));

}


const handleGetScoringPanels = () => {
  return async (dispatch, getState) => {
    const { event: { eventDetails: { id, eventScheduleId } } } = getState();

    await dispatch(getScoringPanelsBegin());
    try {
      const { formattedPanels, refereeOptions, sessionOptions } = await getScoringTabData(id, eventScheduleId);

      await dispatch(getScoringPanelsSuccess(formattedPanels, refereeOptions, sessionOptions));

    } catch (err) {
      await dispatch(getScoringPanelsError())
      throw err;
    }
  }
}

const handleGetScoringPanelsNew = (eid, pageNumber) => {
  return async (dispatch, getState) => {
    const { event: { eventDetails: { id, eventScheduleId } } } = getState();

    await dispatch(getScoringPanelsBegin());
    try {
      // const { formattedPanels, refereeOptions, sessionOptions } = await getScoringTabData(id, eventScheduleId);
      const { formattedPanels, refereeOptions, sessionOptions } = await getScoringData(id, eventScheduleId, 1);
      await dispatch(getScoringPanelsSuccess(formattedPanels, refereeOptions, sessionOptions));

    } catch (err) {
      await dispatch(getScoringPanelsError())
      throw err;
    }
  }
}

const handleExpands = (panels) => {
  return async (dispatch, getState) => {
    // const { event: { eventDetails: { id, eventScheduleId } } } = getState();

    await dispatch(setExpands(panels))
  }
}


export { handleGetScoringPanelsNew, autoAssignPanels, handleExpands }
export default handleGetScoringPanels;