export default class DocumentType {
  constructor(responseData, responseIncluded = []) {
    const {attributes, id} = responseData;
    this.documentTypeId  = id;
    this.programId = null;
    this.name = attributes.name;
    this.notes = attributes.notes;
    this.isRequired = attributes.is_required;
    this.link = attributes.link;
    this.included = responseIncluded;

  }
}