import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { notification, DatePicker, Row, TimePicker, Col, Form, Button } from 'antd';
import Clock from 'shared/components/Icon/icons/Clock';
import { formatZuluTimeStamp, morningMomentObject, convertToDayjs, formatRangePickerToTimezone } from 'utils/dateTimeUtils';
import {API} from 'utils/api';
import { connect } from 'react-redux';


const FormItem = Form.Item;

const TimestampForm = props => {

  const [saving, setSaving] = useState(false);
  const { timestampId, dateValue, time_zone, closeModal, timeValue, use_24_hour_format } = props;
  const { t } = useTranslation()
  const [form] = Form.useForm();
  
  const rowProps = {
    type: 'flex', gutter: [20, 20],
  }

  const saveTimestamp = async (timestampPayload) => {
    try {
      if (timestampId) {
        await API.GetInstance().http.patch(`/api/time_stamps/${timestampId}`, timestampPayload);
        notification.success({
          message: 'Success',
          description: 'Session was successfully saved.',
        });
        closeModal()
        setSaving(false)
      }

    } catch (error) {
      setSaving(false)
      notification.error({
        message: 'Error',
        description: 'Error saving the session.',
      });
      throw error;
    }
  }

  const onSave = async (e) => {

    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }
    form.validateFields().then(async (values) => {
      setSaving(true);   
        const { date } = values;

        const formattedDateTime = formatRangePickerToTimezone(date, time_zone);
        const timestampPayload = {
          data: {
            id: timestampId,
            type: 'time_stamp',
            attributes: {
              target_date_time: formattedDateTime
            }
          }

        }
        await saveTimestamp(timestampPayload);
    }).catch(err => console.log(err));
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  }

  return (
    <div className='event-timestamp-modal__form'>
      <Form hideRequiredMark onFinish={onSave} onFinishFailed={onFinishFailed} form={form} name='TimestampForm'>
        <Row {...rowProps}>
          <Col xs={24} md={12} id='edit-timestamp-date-picker' style={{ position: 'relative' }}>
            <FormItem name='date' label={<span>{t('dateLabel')}</span>} rules={[{required: true, message: t('selectDateLabel')}]} initialValue={convertToDayjs(dateValue, time_zone)}>
                <DatePicker
                  defaultValue={timeValue ? morningMomentObject : undefined}
                  showTime={{
                    format: use_24_hour_format ? 'HH:mm' : 'h:mm a', use12Hours: !use_24_hour_format,
                  }}
                  className='range-picker'
                  size='large'
                  // use12Hours
                  placeholder='MM-DD-YYYY'
                  format={`MM-DD-YYYY ${use_24_hour_format ? 'HH:mm' : 'h:mm a'}`}
                  getCalendarContainer={() => document.getElementById('edit-timestamp-date-picker')}
                />
            </FormItem>
          </Col>
        </Row>
        <Row type='flex' justify='end' className='m-t-36'>
          <Col xs={24} md={12}>
            <FormItem>
              <Button disabled={saving} htmlType='submit'>{t('saveLabel')}</Button>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

TimestampForm.propTypes = {
  form: PropTypes.object.isRequired,
  timestampId: PropTypes.string,
  dateValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  timeValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
};

TimestampForm.defaultProps = {
  timestampId: '',
  dateValue: undefined,
  timeValue: undefined
}

const mapStateToProps = (state) => {
  const { auth: { userInfo: { use_24_hour_format } } } = state;
  return {
    use_24_hour_format
  };
};

export default connect(mapStateToProps)(TimestampForm);
