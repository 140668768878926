import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../../styles/coachJudging/JudgingMaterialsRow.scss';
import ChooseFileCard from 'shared/components/ChooseFileCard';
import FileCard from 'shared/components/FileCard';
import handleDocumentUpload from 'features/EventOverviewContainer/redux/actions/coachJudging/uploadDocument';
import handleRemoveDocument from 'features/EventOverviewContainer/redux/actions/coachJudging/removeDocument';
import documentTypesValidation from 'shared/constants/judgingFilesValidation';
import { notification } from 'antd'
import { UPLOAD_FILE_SIZE_LIMIT, FILE_UPLOAD_INTERRUPTED_MESSAGE } from 'shared/constants/fileUpload'
import { useTranslation } from 'react-i18next';
import docTypes from "./docTypes";

const titleClassName = `paragraph--large paragraph--white paragraph--bold`;
const optionalTextClassName = `paragraph--large paragraph--white paragraph--300`;
const boldClassName = `paragraph--large paragraph--charcoal paragraph--bold m-b-24`
const JudgingMaterialsRow = props => {
  const {t} = useTranslation()
  const {
    documentType,
    files,
    id,
    docTypeID,
    loading,
    setUploadModalVisibility,
    setUploadProgress,
  } = props;
  
  const {
    link,
    name,
    isRequired,
    notes,
  } = documentType;

  const getName = () => {
    const docType = docTypes.find(type => type.id == docTypeID);
    if (docType.keyName) return t(docType.keyName);
    else return docType.name;
  }

  const getNote = () => {
    const docType = docTypes.find(type => type.id == docTypeID);
    if (docType.keyNote) return t(docType.keyNote);
    else return docType.notes;
  }

  const __handleUploadProgress = event => {
    const progress = ~~(event.loaded / event.total * 100)
    setUploadProgress(progress)
  }

  const __handleUploadError = error => {
    if (error && 'data' in error && error.data.error) {
      setUploadModalVisibility(false)
      notification.error({
        message: error.data.error,
        duration: 0,
        onClose: () => setUploadModalVisibility(false)
      });
    } else {
      setUploadModalVisibility(false)
      notification.error({
        message: FILE_UPLOAD_INTERRUPTED_MESSAGE,
        description: 'Please try again later',
        duration: 0,
        onClose: () => setUploadModalVisibility(false)
      });
    }
    console.warn(error)
  }

  const handleUpload = async ({ file }) => {
    // File size validation
    if (file.size > UPLOAD_FILE_SIZE_LIMIT) {
      notification.error({
        message: 'File size exceeds upload limit',
        description: 'Cannot upload a file larger than 5GB',
        duration: 5,
      });
    } else {
      setUploadModalVisibility(true)
      await props.handleDocumentUpload(id, file, __handleUploadProgress, __handleUploadError)
    }
  }

  const removeFile = (fileId) => {
    if (!fileId) return;
    props.handleRemoveDocument(id, fileId)
  }

  const documentTypeId = documentType.id;

  const currentDocumentType = documentTypesValidation.find(d => d.documentTypeId === documentTypeId);
  const acceptedDocumentTypes = currentDocumentType ? currentDocumentType.accepted : undefined;
  const maxFiles = currentDocumentType ? currentDocumentType.maximumFileCount : 1;
  // const showChooseFile = files.filter(f => f.status === 'Uploaded' || f.status === 'Available').length < maxFiles;
  const showChooseFile = files.length < maxFiles;

  return (
    <div className='judging-materials-row'>
      <div className='judging-materials-row__header'>
        <p className={titleClassName}>{getName()}</p>
        {isRequired ? (
          null

        ) : (<p className={optionalTextClassName}>{t('optionalLabel')}</p>)}
      </div>
      <div className='judging-materials-row__content'>
        <div className="text-wrapper">
          {notes ? (
            <p className={boldClassName}>{getNote()}</p>
          ) : null}
          {link ? (
            <div className='m-b-24'>
              <a className='link--large' href={link}>{`Download ${name} Template`}</a>
            </div>
          ) : null}
        </div>
        <div className='judging-materials-row__item'>
          {files.map(f => (
            <FileCard fileId={f.eventFileId} fileName={f.fileName} hasDeleteBtn onDelete={() => removeFile(f.eventFileId)} />
            ))}
          {showChooseFile
            ? (
              <ChooseFileCard
                onUpload={handleUpload}
                loading={loading}
                disabled={loading}
                accept={acceptedDocumentTypes}
              />
)
            : (null)}
        </div>
      </div>
    </div>
  );
};

JudgingMaterialsRow.propTypes = {
  documentType: PropTypes.object.isRequired,
  files: PropTypes.array,
  id: PropTypes.string.isRequired,
  handleDocumentUpload: PropTypes.func.isRequired,
  handleRemoveDocument: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  setUploadModalVisibility: PropTypes.func,
  setUploadProgress: PropTypes.func,
};

JudgingMaterialsRow.defaultProps = {
  files: [],
  loading: false
};

const mapDispatchToProps = {
  handleDocumentUpload,
  handleRemoveDocument
}

export default connect(null, mapDispatchToProps)(JudgingMaterialsRow);