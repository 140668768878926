import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {Collapse} from 'antd';
import AlertOutlined from '@ant-design/icons';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import '../../styles/ScheduleContainer/EventScheduleAccordion.scss';
import { ClipLoader } from 'react-spinners';


const {Panel} = Collapse;

const ScheduleAccordion = (props) => {
  const { t } = useTranslation()
  // eslint-disable-next-line no-unused-vars
  const {missingDetails, incompleteSessionsCount, panelSubtitle, panelTitle, data, children, sessionsCount, disabled} = props;
  const handleExpandIcon = (panelProps) => {
    if(disabled) return <div className="clipper-loader-wrapper"><ClipLoader size={16} color='#0066B3' /></div>
    const {isActive} = panelProps;
    if (isActive) {
      return <UpOutlined className='event-schedule-accordion__icon' />
    }

    return <DownOutlined className='event-schedule-accordion__icon'  />
  }

  const panelHeaderError = (
    <div className='event-schedule-accordion__header'>
      <p className='paragraph--default paragraph--bold paragraph--charcoal'>{panelTitle}</p>


      <span className='event-schedule-accordion__error'>
        <AlertOutlined className='event-schedule-accordion__error-icon' />
        <p className='paragraph--medium paragraph--400 paragraph--red'>
          {t('SchedulerSessionsLabel', {'0': sessionsCount, '1': incompleteSessionsCount})}
        </p>
      </span>
     
    </div>
  )
  const panelHeader = (
    <div className='event-schedule-accordion__header'>
      <p className='paragraph--default paragraph--bold paragraph--charcoal'>{panelTitle}</p>


      <p className='paragraph--medium paragraph--400 paragraph--charcoal'>
        {data.length || t('noLabel')} 
        {' '}
        {' '}
        {data.length === 1 ? t('sessionLabel') : t('sessionsLabel') }
        {' '}
      </p>


    </div>
  )

  return (
    <div>
      <Collapse
        bordered={false}
        expandIconPosition='right'
        expandIcon={handleExpandIcon}
        className='event-schedule-accordion'
        destroyInactivePanel
      >

        <Panel header={missingDetails ? panelHeaderError : panelHeader} className='event-schedule-accordion__panel' disabled={!sessionsCount || disabled}>
          <div className='event-schedule-accordion__content'>
            {children}
          </div>
        </Panel>

      </Collapse>
      
    </div>
  );
};

ScheduleAccordion.propTypes = {
  missingDetails: PropTypes.bool,
  panelTitle: PropTypes.string,
  panelSubtitle: PropTypes.string,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  sessionsCount: PropTypes.number,
  incompleteSessionsCount: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

ScheduleAccordion.defaultProps = {
  missingDetails: false,
  panelTitle: '',
  panelSubtitle: '',
  data: [],
  sessionsCount: 0,
  children: null,
  disabled: false,
  incompleteSessionsCount: 0
}


export default ScheduleAccordion;