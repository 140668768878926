const m14Section = {
  id: 'm14',
  name: '2022ScoresheetM14Sub1Label',
  fields: [
    {
      scoring: '2022ScoresheetM14Sub2Label',
      id: 'm14_scoring_1',
      options: [
        {
          description: '0',
          value: 0
        },
        {
          description: '1',
          value: 1
        },
        {
          description: '2',
          value: 2
        },
      ]
    }
  ]
}

export default m14Section;

