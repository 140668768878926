import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button } from 'antd';
import CloseCircleOutlined from '@ant-design/icons';

import './PanelRow.scss';

const PanelRow = props => {

    const { hasDeleteBtn, fullName, email, deleteClick, id, hideEmail, hideAvatar } = props;
    if (hasDeleteBtn) {
        return (
            <div className='user-row'>
                {/* <Button className='user-row__btn' shape='circle' type='link' onClick={() => { deleteClick(id) }}>
                    <CloseCircleOutlined />
                </Button>
                {hideAvatar ? null : <div className='user-row__avatar'>
                    <Avatar size={48} icon="user" />
                </div>}
                <div>
                    <p className='paragraph--charcoal paragraph--medium paragraph--bold'>{fullName}</p>
                    <a href={`mailto:${email}`} className='link--email'>{email}</a>
                </div> */}

            </div>
        )
    }
    return (
        <div className='user-row'>
            {/* {hideAvatar ? null : <div className='user-row__avatar'>
                <Avatar size={48} icon="user" />
            </div>} */}
            <div>
                <p className='paragraph--charcoal paragraph--medium paragraph--bold'>{fullName}</p>
                {hideEmail ? (null) : (
                    <a href={`mailto:${email}`} className='link--email'>{email}</a>
                )}
            </div>

        </div>
    );
};

PanelRow.propTypes = {
    fullName: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.string,
    hasDeleteBtn: PropTypes.bool,
    deleteClick: PropTypes.func,
    hideEmail: PropTypes.bool,
}

PanelRow.defaultProps = {
    fullName: '',
    email: '',
    id: '',
    hasDeleteBtn: false,
    deleteClick: () => { },
    hideEmail: false
};


export default PanelRow;