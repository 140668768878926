import * as types from '../types/judges';

const DEFAULT_JUDGES_STATE = {
  addJudgesToPanelList: [],
}

export default (state = DEFAULT_JUDGES_STATE, action) => {
  const {type} = action;
  switch(type) {
    case types.ADD_NEW_JUDGE_TO_LIST:
      return {
        ...state,
        addJudgesToPanelList: action.payload.addJudgesToPanelList
      }
    case types.DELETE_JUDGE_FROM_ADD_TO_PANEL_LIST:
      return {
        ...state,
        addJudgesToPanelList: action.payload.addJudgesToPanelList
      }
    case types.RESET_JUDGES_ADD_TO_PANEL_LIST:
      return {
        ...state,
        addJudgesToPanelList: []
      }
    default:
      return state;
  }
}