import React from 'react';
import PropTypes from 'prop-types';
import CardTile from 'shared/components/CardsList/CardTile';
import './VolunteerTile.scss'
import _ from 'lodash';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const VolunteerTile = (props) => {
  const {t} = useTranslation()
  const { roles, is_FLLC_event } = props;
  const uniq_roles = _.uniqBy(roles, "id")

  const getRole = (roleValue) => {

    if (roleValue == "Judge Adviser") {
      return is_FLLC_event ? t('judgeAdvisor') : t('nonChallengeJudgeAdvisor')
    }

    if (roleValue == "Judge") {
      return is_FLLC_event ? t('judgeLabel') : t('nonChallengeJudgeLabel')
    }

    return roleValue
  }

  return (
    <CardTile
      {...props}
      renderInfo={() => (
        <div className='volunteer-tile__roles'>
          {uniq_roles.map(role => (
            <p className='paragraph--xsmall paragraph--darkGray' key={role.id}>{getRole(role.name)}</p>
          ))}
        </div>
      )}
    />
  );
};

VolunteerTile.propTypes = {
  email: PropTypes.string,
  fullName: PropTypes.string,
  roles: PropTypes.array
};

VolunteerTile.defaultProps = {
  email: '',
  fullName: '',
  roles: []
};

const mapStateToProps = (state) => {
  const { event: { eventDetails: { is_FLLC_event } } } = state

  return {
    is_FLLC_event
  }
}

export default connect(mapStateToProps)(VolunteerTile)
