const ip5Section = {
    id: 'IP5',
    // label: 'ipv1Label',
    title: 'exploreReviewingSheetTeamPosterLabel',
    // description: 'identifyDescriptionLabel',
    fields: [
        {
            id: 'team_poster_1',
            value: 'exploreReviewingSheetTeamPosterIncludeQuestionLabel',
            answear: 'exploreReviewingSheetTeamPosterIncludeAnswerLabel'
        },
        {
            id: 'team_poster_2',
            value: 'exploreReviewingSheetTeamPosterHowQuestionLabel',
            answear: 'exploreReviewingSheetTeamPosterHowAnswerLabel'
        },
    ]
}

export default ip5Section;