import axios from 'axios'

/**
 * @param {Object} options Project specifications for client intialization
 * @param {String} options.baseUrl Base url for api calls
 * @param {Function} options.getToken Async function for retrieving a token in a request interceptor
 * @param {Boolean} options.showLog Condition for showing console log for api requests. For example, to hide in production: process.env.NODE_ENV === 'development'
 * @param {Object} options.extend Additional properties to add to axios create method
 * @returns Api Client
 */
//export function CreateClient (options) {
//  return class {
export class CreateClient {
    constructor (options) {
      this.options = options
      this.http = axios.create({
        baseURL: options.baseUrl,
        headers: {
          'cache-control': 'no-cache'
        },
        ...options.extend
      })

      // Request Interceptor
      this.http.interceptors.request.use(async config => {
        // Log api call
        this.log(`[Req][${config.method.toUpperCase()}][${config.url}]`, config.params)

        // Retrieve token with each request
        const token = await options.getToken()
        if (token) config.headers.Authorization = `Bearer ${token}`

        return config
      }, error => {
        // Log request errors
        this.log(`[Req][${error.config.method.toUpperCase()}][${error.config.url}]`, undefined, true)
        return Promise.reject(error.request || new Error(error))
      })

      // Response Interceptor
      this.http.interceptors.response.use(response => response, error => {
        // Log response errors
        this.log(`[Err][${error.config.method.toUpperCase()}][${error.config.url}]`, undefined, true)
        return Promise.reject(error.response || new Error(error))
      })
    }

    /**
     * Network activity log
     * @private
     * @param {String} message
     * @param {Object} params
     * @param {Boolean} error | flag to change log color
     */
    log (message = '', params = '', error = false) {
      if (this.options.showLog) {
        console.log(
          '%cAPI',
          `font-size: 9px;background-color: ${
            error ? 'red' : 'green'
          };color: white;padding: 0.35em 0.5em 0.2em;border-radius: 0.25em;`,
          message,
          params
        )
      }
    }
  }
//}