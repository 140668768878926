import React from 'react';
import { useTranslation } from 'react-i18next';
import './Instructions.scss';

const Instructions = ({ text }) => {
  const { t } = useTranslation()
  return (
    <div className='instructions-container'>
      <p className='paragraph--bold paragraph--charcoal paragraph--medium'>{t('instructionsLabel')}</p>
      <p className='paragraph--400 paragraph--charcoal paragraph--medium'>
        {text}
      </p>
    </div>
  );
};

export default Instructions;
