import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../../styles/ScheduleContainer/EventSessionCard.scss';
import Icon from '@ant-design/icons';
import LawIcon from 'shared/components/Icon/icons/Law';
import { parseDateString, parseTime } from 'utils/dateTimeUtils';
import SessionModal from 'features/EventContainer/components/ScheduleContainer/SessionModal'
import Score from 'shared/components/Icon/icons/Score';
import Ballot from 'shared/components/Icon/icons/Ballot';
import JoinBtn from 'shared/components/Button/JoinBtn';
import { connect } from 'react-redux';
import * as userTypes from 'shared/constants/userTypes';
import * as eventRoleTypes from 'shared/constants/eventRoleTypes';
import IncompleteSession from './EventSessionCardIncomplete';
import { sanitizeUrl as sanitize } from '@braintree/sanitize-url'


const EventScheduleCard = (props) => {
  const [modalVisible, setModalVisible] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const {
    cb,
    iconType,
    sessionType,
    startTime,
    sessionId,
    endTime,
    team,
    url,
    sessionTypeName,
    hasSubtitle,
    userEventRoleIds,
    userType,
    isJoinLinkDisabled,
    time_zone,
    use_24_hour_format
  } = props;

  const canEditSchedule = userType === userTypes.FIRST_ADMIN ||
    userType === userTypes.PARTNER_ADMIN ||
    userEventRoleIds.includes(eventRoleTypes.EVENT_ADMIN_ID);


  const closeModal = () => {
    setModalVisible(false);
    cb();
  }

  const openModal = () => {
    setModalVisible(true);
  }

  const dateString = startTime ? parseDateString(startTime, time_zone) : undefined;
  const startTimeString = startTime ? parseTime(startTime, time_zone, use_24_hour_format) : undefined;
  const endTimeString = endTime ? parseTime(endTime, time_zone, use_24_hour_format) : undefined;

  const title = `Team ${team}`;
  if (!startTime || !endTime) {
    return (
      <>
        <SessionModal iconType={iconType} sessionId={sessionId} visible={modalVisible} onCancel={closeModal} />
        <button disabled={!canEditSchedule} type='button' className='event-schedule-card-btn' onClick={openModal}>
          <IncompleteSession time_zone={time_zone} hasSubtitle={hasSubtitle} startTime={startTime} endTime={endTime} title={title} sessionTypeName={sessionTypeName} />
        </button>
      </>
    )
  }

  const renderIcon = () => {
    switch (iconType) {
      case 'ballot':
        return Ballot;
      case 'score':
        return Score;
      default:
        return LawIcon
    }

  }



  return (
    <>
      <SessionModal cb={cb} iconType={iconType} sessionId={sessionId} visible={modalVisible} onCancel={closeModal} time_zone={time_zone} />
      <button disabled={!canEditSchedule} type='button' className='event-schedule-card-btn' onClick={openModal}>
        <div className='event-session-card event-session-card--complete'>
          <div className={`event-session-card__rectangle event-session-card__rectangle--${sessionType}`}>
            <Icon component={renderIcon()} className='event-session-card__event-icon' />
          </div>
          <div className='event-session-card__data event-session-card__data--complete'>
            <p className='paragraph--default paragraph--bold paragraph--charcoal event-session-card__data__title'>{title}</p>
            {hasSubtitle ? (
              <p className='paragraph--medium paragraph--charcoal'>{sessionTypeName}</p>
            ) : null}
            <div className='event-session-card__data__date-time'>
              <span>
                <p className='paragraph--medium paragraph--charcoal'>{dateString}</p>
                <p className='paragraph--medium paragraph--charcoal'>
                  {startTimeString}
                  {' '}
                  -
                  {' '}
                  {endTimeString}
                </p>
              </span>
              {url ? (
                <div className='event-session-card__join-btn'>
                  <JoinBtn url={sanitize(url)} isDisabled={isJoinLinkDisabled} />
                </div>

              ) : null}

            </div>
          </div>
        </div>

      </button>

    </>
  );
};

EventScheduleCard.propTypes = {
  team: PropTypes.object,
  sessionId: PropTypes.string,
  sessionType: PropTypes.string,
  iconType: PropTypes.string,
  cb: PropTypes.func,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasSubtitle: PropTypes.bool,
  sessionTypeName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userEventRoleIds: PropTypes.array.isRequired,
  userType: PropTypes.string.isRequired
}

EventScheduleCard.defaultProps = {
  sessionId: undefined,
  sessionType: 'judging',
  iconType: 'law',
  team: '',
  cb: () => { },
  url: null,
  hasSubtitle: false,
  sessionTypeName: null
}

const mapStateToProps = (state) => {
  const { event: { eventDetails }, auth: { userType, userInfo: { use_24_hour_format } } } = state;
  const {
    userEventRoleIds,
    time_zone
  } = eventDetails
  return { userEventRoleIds, userType, time_zone, use_24_hour_format };
};


export default connect(mapStateToProps)(EventScheduleCard);