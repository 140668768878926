// FIRST® LEGO® League Discover
export const FLL_DISCOVER_ID = 'b28005f6-bb66-4f81-bb69-a09b251ba47b';

// FIRST® LEGO® League Challenge
export const FLL_CHALLENGE_ID = '6ded20ec-5f26-4d70-a24d-16ad46fccc1a'

// FIRST® LEGO® League Explore
export const FLL_EXPLORE_ID = '396c79f6-b45a-4a6d-a522-3c90bcbdfc3f';

// FIRST® Tech Challenge
export const FTC_ID = '2b6e99e5-aec9-438a-8f04-287a561512c2';
