export const JUDGE_ID = 'c0a07a56-2806-491f-91eb-2f4175b983e0';

export const EVENT_ADMIN_ID = 'c213c11b-01a9-417c-976f-cbc61bd5a44c';

export const JUDGE_ADVISOR_ID = 'cdf68916-7a02-4e40-89f9-11b6461cebc3';

export const HEAD_REFEREE_ID = '8b6b55a9-fb02-414a-8b64-ff7764baa130';

export const REFEREE_ID = '36b87599-25ae-4ba0-b738-2be4f1eb1880';

export const COACH_ID = '0ade6fca-9c63-4e0e-8e49-30800d391ca8';

export const VOLUNTEER_COORDINATOR_ID = 'd552b4c0-5636-4a35-bfda-96acdea318cd';

export const adminEventRoles = [
  EVENT_ADMIN_ID,
  JUDGE_ADVISOR_ID,
  HEAD_REFEREE_ID
]

export const VolunteerRoleIdList = [
  JUDGE_ID,
  EVENT_ADMIN_ID,
  JUDGE_ADVISOR_ID,
  HEAD_REFEREE_ID,
  REFEREE_ID,
  VOLUNTEER_COORDINATOR_ID
]

export const roleTypeMap = {
  'coach': COACH_ID,
  'event admin': EVENT_ADMIN_ID,
  'head referee': HEAD_REFEREE_ID,
  'judge': JUDGE_ID,
  'judge adviser': JUDGE_ADVISOR_ID,
  'judge advisor': JUDGE_ADVISOR_ID,
  'referee': REFEREE_ID,
  'reviewer': JUDGE_ID,
  'reviewer advisor': JUDGE_ADVISOR_ID,
  'reviewer adviser': JUDGE_ADVISOR_ID,
}

export const roleTypeTranslation = {
  [JUDGE_ID]: ['judgeLabel', 'nonChallengeJudgeLabel'],
  [EVENT_ADMIN_ID]: ['eventAdminLabel', 'eventAdminLabel'],
  [JUDGE_ADVISOR_ID]: ['judgeAdvisor', 'nonChallengeJudgeAdvisor'],
  [HEAD_REFEREE_ID]: ['headRefereeLabel', 'headRefereeLabel'],
  [REFEREE_ID]: ['refereeLabel', 'refereeLabel'],
  [COACH_ID]: ['coachLabel', 'coachLabel'],
}