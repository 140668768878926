const m01Section = {
  id: 'm01',
  name: '2022ScoresheetM01Label',
  description: '2022ScoresheetM01Sub1Label',
  fields: [
    {
      id: 'm01_scoring_1',
      description: '2022ScoresheetM01Sub1Label',
      scoring: [
        '2022ScoresheetM01Sub2Label',
        '2022ScoresheetM01Sub3Label',
        '2022ScoresheetM01Sub4Label'
      ],
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        }
      ]
    },
  ]
}

export default m01Section;