import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/ScheduleContainer/EventSessionCard.scss';
import AlertOutlined from '@ant-design/icons';
import {parseDateString, parseTime} from 'utils/dateTimeUtils';
import { useTranslation } from 'react-i18next';

const EventScheduleCardIncomplete = (props) => {

  const {
    endTime,
    startTime,
    title,
    sessionTypeName, hasSubtitle, time_zone
  } = props;
  const {t} = useTranslation();

  const dateString = startTime ? parseDateString(startTime, time_zone) : undefined;

  const startTimeString = startTime ? parseTime(startTime, time_zone) : undefined;
  const endTimeString = endTime ? parseTime(endTime, time_zone) : undefined;

  return (
    <div className='event-session-card'>
      <div className='event-session-card__rectangle event-session-card__rectangle--incomplete'>
        <AlertOutlined className='event-session-card__alert-icon' />
      </div>
      <div className='event-session-card__data event-session-card__data--incomplete'>
        <p className='paragraph--default paragraph--bold paragraph--charcoal event-session-card__data__title'>{title}</p>
        {hasSubtitle ? (
          <p className='paragraph--medium paragraph--charcoal'>{sessionTypeName}</p>
            ) : null}
        <div className='event-session-card__data__date-time'>
          <span>
            {dateString ? (
              <p className='paragraph--medium paragraph--charcoal'>{dateString}</p>
            ) : (
              <p className='paragraph--medium paragraph--red'>{t('setDateLabel')}</p>
            ) }
            {(!startTimeString || !endTimeString) ? (
              <p className='paragraph--medium paragraph--red'>{t('setTimeLabel')}</p>            
            ) : (
              <p className='paragraph--medium paragraph--charcoal'>
                {startTimeString}
                {' '}
                -
                {' '}
                {endTimeString}
              </p>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

EventScheduleCardIncomplete.propTypes = {
  title: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  hasSubtitle: PropTypes.bool,
  sessionTypeName: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

EventScheduleCardIncomplete.defaultProps = {
  title: undefined,
  startTime: undefined,
  endTime: undefined,
  hasSubtitle: false,
  sessionTypeName: null
}

export default EventScheduleCardIncomplete;