import React from 'react';
import PropTypes from 'prop-types'
import {Modal} from 'antd';
import { ClipLoader } from 'react-spinners';




const SchedulingModal = (props) => {
  const {visible, message} = props;
  return (
    (<Modal
      footer={null}
      open={visible}
      closable={false}
      className="schedule-generating-loading"
      bodyStyle={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '54px 138.5px',
      }}
    >
      <ClipLoader size={80} color='#1890FF' css="border-width: 5px"/>
      <div className="title">{message}</div>
    </Modal>)
  );
};

SchedulingModal.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
}



export default SchedulingModal;