export const urlValidation = {
  message: 'Enter a valid URL (e.g. https://www.example.com)',
  pattern: /^\S+$/,
}

export const urlTransform = (value) => {
  if (!value || value === '') return value
  const HTTPS_PREFIX = 'https://'
  const HTTP_PREFIX = 'http://'

  let formattedUrl = value;

  const transformedValue = value.toLowerCase()
  
  if (
    
    transformedValue.substring(0, HTTPS_PREFIX.length) !== HTTPS_PREFIX &&
    transformedValue.substring(0, HTTP_PREFIX.length) !== HTTP_PREFIX
  ) {
  
    formattedUrl = HTTPS_PREFIX + value
  }
 
  return formattedUrl
}