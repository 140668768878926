import Icon from '@ant-design/icons';
import Tooltip from 'shared/components/Tooltip';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Heading from 'shared/components/Heading';
import Tabs from 'shared/components/Tabs/ModalTabs';
import Spreadsheet from 'shared/components/Icon/icons/Spreadsheet';
import EventModal from 'features/EventContainer/components/EventModal';
import { handleResetVolunteerModal } from 'features/EventContainer/redux/actions/volunteersActions';
import ManualView from './ManualView';
import ImportView from './ImportView';
import Select from 'shared/components/Select';
import {Form} from 'antd';
import localizationLanguages from 'shared/constants/localizationLanguages';
import detectLanguage from 'utils/detectLanguage';

const { Option } = Select
const FormItem = Form.Item;

const VolunteersModal = (props) => {
  const { t } = useTranslation();
  const selectedLanguage = localStorage.getItem('i18nextLng');
  const [form] = Form.useForm();
  const lang = detectLanguage(selectedLanguage);
  const defaultLang = (localizationLanguages.find(i => i.value === lang) || {}).code
  const { onCancel, visible, is_FLLC_event } = props;
  const [excelVisible, setExcelVisible] = useState(false);
  const [emailLanguage, setEmailLanguage] = useState(defaultLang);

  const sortList = (a, b) => {
    const textA = a.text.toUpperCase();
    const textB = b.text.toUpperCase();
    let comparison = 0;
    if (textA > textB) {
      comparison = 1;
    } else if (textA < textB) {
      comparison = -1;
    }
    return comparison;

  }

  const info = (
    <Tooltip text={t('importCsvTooltip')} />
  );

  const tabChange = (activeKey) => {
    if (activeKey === 'Import') {
      setExcelVisible(true);
    } else {
      setExcelVisible(false);
    }
  };

  const closeModal = () => {
    onCancel()
    props.handleResetVolunteerModal()
  }

  return (
    <EventModal
      visible={visible}
      onCancel={closeModal}
    >
      <div>
        <div className='modal__title'>
          <Heading tag={2} type='primary' weight='bold'>{t('addVolunteersLabel')}</Heading>
          {excelVisible ? (
            is_FLLC_event ? (
              <a href='/volunteer.csv' download className='link'>
                <span className='modal__link'>
                  <span className='modal__link__icon'><Icon component={Spreadsheet} /></span>
                  {t('downloadCsvLinkLabel')}
                </span>
              </a>
            ) : (
                <a href='/volunteer-exp-discover.csv' download="volunteer.csv" className='link'>
                <span className='modal__link'>
                  <span className='modal__link__icon'><Icon component={Spreadsheet} /></span>
                  {t('downloadCsvLinkLabel')}
                </span>
              </a>
            )
          ) :
            <div>
              Send email invitation in
              <Select tabIndex={0} theme='gray' onChange={setEmailLanguage} additionalClassNames='email-language-selection' allowSearch value={emailLanguage}>
                {localizationLanguages.sort(sortList).map(l => (<Option key={l.code} value={l.code}>{l.text}</Option>))}
              </Select>
            </div>
          }
        </div>
        <Tabs
          tabBarGutter={48}
          def
          overrides={{
            tabBarExtraContent: info,
            onChange: tabChange,
          }}
        >
          <Tabs.TabPane tab={t('manuallyLabel')} key='Manual'>
            <ManualView emailLanguage={emailLanguage} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('importCsvLabel')} key='Import'>
            <ImportView />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </EventModal>
  );
};

VolunteersModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleResetVolunteerModal: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { event: { eventDetails: { is_FLLC_event } } } = state

  return { is_FLLC_event }
}

const mapDispatchToProps = {
  handleResetVolunteerModal
}
export default connect(mapStateToProps, mapDispatchToProps)(VolunteersModal);
