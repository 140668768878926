import { API } from 'utils/api';
import { queryStringify, formatDataResult, parallelRequest } from 'utils/query';
import {
  formatNewAwardPayload,
  formatNewAwardAssignment,
  formatPatchAwardAssignment,
  formatAwardResPayload,
  formatAssignmentResPayload
} from './model';
import { PENDING, PUBLISHABLE, PUBLISHED } from 'shared/constants/publishStatus';

//const { http } = API;
const baseUrl = '/api/awards';
const assignmentBaseUrl = '/api/award_assignments';

// Award Assignment GET
export const getAwardAssignmentByIdRequest = async (assignmentId) => {
  return API.GetInstance().http.get(`${assignmentBaseUrl}/${assignmentId}`);
};
// Award Assignment GET
export const getAwardAssignmentById = async (assignmentId) => {
  const { data, included } = await API.GetInstance().http.get(`${assignmentBaseUrl}/${assignmentId}`);
  return { 
    data: formatAssignmentResPayload(data),
    included
  };
};

export const getAwardAssignmentsByEventId = async (eventId) => {
  const filter = { 'event.id': `eq:${eventId}` };
  const queryStr = queryStringify({ filter });
  const { data, included } = await API.GetInstance().http.get(`${assignmentBaseUrl}/${queryStr}`);
  return {
    data,
    included
  };
};

export const getAwardAssignmentsBy = async (assignmentIds) => {
  if (!assignmentIds) {
    return [];
  }
  let requests = [];
  for (const id of assignmentIds){
    const request = API.GetInstance().http.get(`${assignmentBaseUrl}/${id}`);
    requests.push(request)
  }
  const values = await parallelRequest(requests);
  return values.map(res=>{
    const {data} = res;
    return formatAssignmentResPayload(data);
  });
};

// Award Assignment POST
export const addTeamToAward = async (body) => {
  //body = { eventId, awardId, teamId: selTeam, userId }
  const payload = formatNewAwardAssignment(body);
  const { data } = await API.GetInstance().http.post(assignmentBaseUrl, payload);
  const assignment = formatDataResult(data);
  return { ...body, ...assignment };
};

// Award Assignment DELETE
export const removeTeamFromAwardRequest = async (assignmentId) => {
  return API.GetInstance().http.delete(`${assignmentBaseUrl}/${assignmentId}`);
};

// Award Assignment DELETE
export const removeTeamFromAward = async (assignmentId) => {
  if (!assignmentId) return;
  await API.GetInstance().http.delete(`${assignmentBaseUrl}/${assignmentId}`);
  return assignmentId;
};

// Award Assignment PATCH place/isPublishable/isPublished
export const updateAwardAssignment = async (body) => {
  const { awardId, assignmentId, ...attr } = body;
  const payload = formatPatchAwardAssignment(attr);
  const { data } = await API.GetInstance().http.patch(`${assignmentBaseUrl}/${assignmentId}`, payload);
  const assignment = formatDataResult(data);
  return { ...body, ...assignment };
};
// Award Assignment PATCH place/isPublishable/isPublished
export const updateAwardAssignmentForParallelRequest = async (body) => {
  const { awardId, assignmentId, ...attr } = body;
  const payload = formatPatchAwardAssignment(attr);
  return API.GetInstance().http.patch(`${assignmentBaseUrl}/${assignmentId}`, payload);
};

// Award GET
export const getAwardById = async (id) => {
  const { data, included } = await API.GetInstance().http.get(`${baseUrl}/${id}`);
  const award = formatAwardResPayload(data, included);
  const { assignments } = award;
  for (let i = 0; i < assignments.length; i++) {
    const assign = assignments[i];
    const assignRes = await getAwardAssignmentById(assign.id);
    Object.assign(assign, assignRes.data);
  }
  return { data: { ...award, assignments } };
};

// Award POST
export const createAwardToEvent = async (award, eventId) => {
  const payload = formatNewAwardPayload(award, eventId);
  const { data } = await API.GetInstance().http.post(baseUrl, payload);
  return formatDataResult(data);
};

//GET EVENT AWARDS 
export const getAwardsOnlyByEventIdRequest = async (eventId) =>{
  const filter = { 'event.id': `eq:${eventId}` };
  const queryStr = queryStringify({ filter });
  const url = `${baseUrl}${queryStr}`;
  return API.GetInstance().http.get(url);
}

//GET EVENT AWARDS 
export const getAwardsOnlyByEventId = async (eventId) =>{
  const filter = { 'event.id': `eq:${eventId}` };
  const queryStr = queryStringify({ filter });
  const url = `${baseUrl}${queryStr}`;
  const { data, included } = await API.GetInstance().http.get(url);
  const awards = data.map(d => formatAwardResPayload(d, included));
  return awards
}

// Event Awards GET
export const getAwardsByEventId = async (eventId) => {
  const filter = { 'event.id': `eq:${eventId}` };
  const queryStr = queryStringify({ filter });
  const url = `${baseUrl}${queryStr}`;
  const { data, included } = await API.GetInstance().http.get(url);
  const awards = data.map(d => formatAwardResPayload(d, included));
  
  const allAssignments = [];
  awards.forEach(({ assignments }) => {
    allAssignments.push(...assignments);
  });
  const eventAssignmentsId = allAssignments.map(({ id }) => id);

  const eventAssignments = await getAwardAssignmentsBy(eventAssignmentsId);
  awards.forEach((award) => {
    const { assignments } = award;
    award.assignments = assignments.map((assign) => {
      const team = eventAssignments.find(({ id }) => id === assign.id);
      return { ...assign, teamId: team && team.teamId }
    });
  });

  const isPublishable = allAssignments.length > 0 &&
    !allAssignments.find((assign) => !assign.isPublishable);
  const isPublished = allAssignments.length > 0 &&
    !allAssignments.find((assign) => !assign.isPublished);
  const status = isPublished
    ? PUBLISHED
    : isPublishable 
      ? PUBLISHABLE 
      : PENDING;

  return { awards, status };
};

// Event Awards PATCH Submit/Publish
export const patchEventAwards = async (awards, field, revert = false) => {
  const allAssignments = [];
  awards.forEach(({ id, assignments }) => {
    const arr = assignments.map((assign) => ({ ...assign, awardId: id }));
    allAssignments.push(...arr);
  });
  const patchRequests = [];
  for (let i = 0; i < allAssignments.length; i++) {
    const assign = allAssignments[i];
    const { awardId, id } = assign;
    if (!assign[field] || revert) {
      const request = updateAwardAssignmentForParallelRequest({
        awardId, assignmentId: id, [field]: !revert
      });
      patchRequests.push(request);
    }
  }
  return await parallelRequest(patchRequests)
};

const changePublishableStatusOfAwards = async (eventId, status)=>{
  const payload = {
    data: {
      type: "change_publishable_status_of_all_awards",
      attributes:{
        is_publishable: status
      },
      relationships: {
        event:{
          data: {
            type: "event",
            id: eventId
          }
        }
      }
    }
  }
  await API.GetInstance().http.post(`${assignmentBaseUrl}/commands`, payload);
}


const changePublishedStatusOfAwards = async (eventId, status)=>{
  const payload = {
    data: {
      type: "change_publish_status_of_all_awards",
      attributes:{
        is_published: status
      },
      relationships: {
        event:{
          data: {
            type: "event",
            id: eventId
          }
        }
      }
    }
  }
  await API.GetInstance().http.post(`${assignmentBaseUrl}/commands`, payload);
}


// Submit Event Awards
export const submitEventAwardsRequest = async (eventId, unsubmit) => {
  return await changePublishableStatusOfAwards(eventId, !unsubmit);
};

// Publish Event Awards
export const publishEventAwardsRequest = async (eventId, unpublish) => {
  return await changePublishedStatusOfAwards(eventId, !unpublish);
};
