const m17ection = {
  id: 'm17',
  name: '2022ScoresheetM17PrecisionTokensLabel',
  fields: [
    {
      scoring: '2022ScoresheetM17TokensSub1Label',
      id: 'm17_scoring_1',
      options: [
        {
          description: '0',
          value: 0
        },
        {
          description: '1',
          value: 1
        },
        {
          description: '2',
          value: 2
        },
        {
          description: '3',
          value: 3
        },
        {
          description: '4',
          value: 4
        },
        {
          description: '5',
          value: 5
        },
        {
          description: '6',
          value: 6
        }
      ]
    },
  ]
}

export default m17ection;