import React from 'react';
import PropTypes from 'prop-types';
import { notification, Button } from 'antd';
import Icon from '@ant-design/icons';
import '../../styles/EventOverviewSessionCard.scss';
import LawIcon from 'shared/components/Icon/icons/Law';
import Ballot from 'shared/components/Icon/icons/Ballot';
import MeetingIcon from 'shared/components/Icon/icons/Meeting';
import ScoringIcon from 'shared/components/Icon/icons/Score';
import Trophy from 'shared/components/Icon/icons/Trophy';
import GrandOpening from 'shared/components/Icon/icons/GrandOpening';

import JoinBtn from 'shared/components/Button/JoinBtn';
import { judgingMaterialsSubmissionDueIds, officialJudgingSessionType } from 'shared/constants/sessionTypes';
import { officialAndPracticeMatchSubmissionDueIds, officialAndPracticeRefereeSessionIds } from 'shared/constants/timestampTypes';
import sessionsStatus from 'shared/constants/judgingSessionStatuses';
import StatusBadge from 'shared/components/StatusBadge';
import { sessionTypesTranslations } from 'shared/constants/sessionTypesTranslations';
import { useTranslation } from 'react-i18next';
import { sanitizeUrl as sanitize } from '@braintree/sanitize-url'
import moment from 'moment';
import { connect } from 'react-redux';


const activeTextClassName = 'paragraph--medium paragraph--charcoal'
const SessionCard = props => {

  const {
    isDisabled,
    type,
    id,
    data,
    handleTabClick,
    use_24_hour_format,
    is_FLLC_event
  } = props;
  const { t } = useTranslation();

  function copyToClipboard() {
    if (!data.url) return;
    const copyText = data.url
    const tempElement = document.createElement('input');
    document.body.appendChild(tempElement);
    tempElement.value = copyText;

    if (copyText) {
      tempElement.select();
      const isCopySuccessful = document.execCommand('copy');
      document.body.removeChild(tempElement);
      if (isCopySuccessful) {
        notification.success({
          message: 'URL Copied to Clipboard',

        });
      }

    }
  }


  const renderIcon = () => {
    let iconBackground = 'event-overview-session-card__rectangle--gray';
    let SessionIcon = LawIcon;

    if (data.iconType === 'judging') {
      iconBackground = 'event-overview-session-card__rectangle--navy-blue';
    } else if (data.iconType === 'meeting') {
      iconBackground = 'event-overview-session-card__rectangle--dark-blue';
      SessionIcon = MeetingIcon;
    } else if (data.iconType === 'scoring') {
      iconBackground = 'event-overview-session-card__rectangle--turquoise';
      SessionIcon = ScoringIcon;
    } else if (data.iconType === 'opening') {
      iconBackground = 'event-overview-session-card__rectangle--blue';
      SessionIcon = GrandOpening;
    } else if (data.iconType === 'closing') {
      iconBackground = 'event-overview-session-card__rectangle--blue';
      SessionIcon = Trophy;
    }
    if (data.isTimestamp) {
      SessionIcon = Ballot;
    }
    if (isDisabled) {
      iconBackground = 'event-overview-session-card__rectangle--gray';
    }



    return (
      <div className={`event-overview-session-card__rectangle ${iconBackground}`}>
        <Icon component={SessionIcon} className='event-overview-session-card__icon' />
      </div>
    )
  }



  let dataTextClassName;

  if (isDisabled) {
    dataTextClassName = 'paragraph--medium paragraph--silver'
  }

  if (!isDisabled) {
    dataTextClassName = activeTextClassName

  }

  const isJudgingSession = officialJudgingSessionType === data.sessionTypeId;
  const isJudgingMaterialsSubmissionDue = judgingMaterialsSubmissionDueIds.includes(data.sessionTypeId);
  const isMatchSubmissionDue = officialAndPracticeMatchSubmissionDueIds.includes(data.sessionTypeId);
  const isRefereeSession = officialAndPracticeRefereeSessionIds.includes(data.sessionTypeId);

  const ViewMatchesButton = <Button className="schedule_matches_judging_button" onClick={() => handleTabClick('#matches')}>{t('viewMatchesLabel')}</Button>;

  const ViewMaterialButton = <Button className="schedule_matches_judging_button" onClick={() => handleTabClick('#judging')}>View Materials</Button>;

  const translatedSessionTitle = sessionTypesTranslations(is_FLLC_event)[data.sessionTypeId];
  const sessionTitle = translatedSessionTitle ? t(translatedSessionTitle) : data.title;

  const sessionStatus = sessionsStatus.find(s => s.statusId === data.sessionStatusId) || {};
  const { statusName } = sessionStatus;
  const statusLabel = statusName ? t(statusName) : data.sessionStatus;

  if (type === 'session' || type === 'meeting') {
    const { badgeType } = sessionStatus;
    return (
      <div className='event-overview-session-card'>
        {renderIcon()}
        <div className='event-overview-session-card__content'>
          <div>
            <div className='event-overview-session-card__content-title'>
              <div>
                <h4 className='h-4 heading--charcoal'>{sessionTitle}</h4>
                {data.sessionTeamName ? (
                  <p className='paragraph--large paragraph--charcoal'>{data.sessionTeamName}</p>
                ) : null}
              </div>

              <div className="status-with-extra-buttons">
                {data.sessionStatus && <StatusBadge statusText={statusLabel} badgeType={badgeType} />}
                {isRefereeSession ? ViewMatchesButton : null}
                {isJudgingSession ? ViewMaterialButton : null}
              </div>

            </div>
            <p className='paragraph--large paragraph--charcoal'>{data.subtitle}</p>
          </div>
          <div className='event-overview-session-card__content-info'>
            <div>
              <p className={dataTextClassName}>{data.startDate}</p>
              <p className={dataTextClassName}>
                {`${moment(data.startTime, 'hh:mm a').format(use_24_hour_format ? 'HH:mm' : 'h:mm a')} - ${moment(data.endTime, 'hh:mm a').format(use_24_hour_format ? 'HH:mm' : 'h:mm a')}`}
              </p>
              {/* <p className={dataTextClassName}>{`${data.startTime} - ${data.endTime}`}</p> */}
              {data.notes && (
                <p className={`${activeTextClassName} m-t-12`}>{data.notes}</p>
              )}
            </div>
            {data.url ? (
              <div className="c-session-url-action-wrapper">
                <JoinBtn size='large' url={sanitize(data.url)} isDisabled={data.isJoinLinkDisabled} />
                {data.isJoinLinkDisabled ? null : <button aria-label='Copy Session Link' onClick={copyToClipboard} type='button' className='c-session-url-action'>
                  <Icon type="copy" />
                </button>}
              </div>
            ) : (null)}
          </div>
        </div>
      </div>
    );

  }

  return (
    <div className='event-overview-session-card'>
      {renderIcon()}
      <div className='event-overview-session-card__content'>
        <div>
          <div className='event-overview-session-card__content-title'>
            <h4 className='h-4 heading--charcoal'>{sessionTitle}</h4>

            <p>{statusLabel}</p>

          </div>
          <p className='paragraph--large paragraph--charcoal'>{data.subtitle}</p>
        </div>
        <div className='event-overview-session-card__content-info'>
          <div>
            <p className={dataTextClassName}>{data.startDate}</p>
            {`${moment(data.startTime, 'hh:mm a').format(use_24_hour_format ? 'HH:mm' : 'h:mm a')}`}
            {/* <p className={dataTextClassName}>{data.startTime}</p> */}
          </div>
          {isJudgingMaterialsSubmissionDue ? ViewMaterialButton : null}
          {isMatchSubmissionDue ? ViewMatchesButton : null}
        </div>
      </div>
    </div>
  );
};

SessionCard.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

SessionCard.defaultProps = {
  isDisabled: false,

}

const mapStateToProps = (state) => {
  const { auth: { userInfo: { use_24_hour_format } }, eventOverview: {is_FLLC_event} } = state;
  return {
    is_FLLC_event,
    use_24_hour_format
  }
}

export default connect(mapStateToProps)(SessionCard)
// export default SessionCard;