import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import { useTranslation } from 'react-i18next';
import AutoComplete from 'shared/components/FormAutoComplete';
import Input from 'shared/components/Input';
import text from 'shared/translations/en/volunteersModal';
import { Button, Form } from 'antd';
import { connect } from 'react-redux';
import {
  handleAddCoachToList,
  handleUpdateSelectedCoach,
  handleIsAddTeamDisabled,
  handleResetSelectedTeam,
  handleResetSelectedCoach,
  handleSetCoachInAutocomplete,
  handleInviteCoachNow,
  handleResetCoachInAutocomplete
} from 'features/EventContainer/redux/actions/teams';
import '../../../styles/TeamsCoachesModal.scss';
import { useParams } from 'react-router-dom';
import validateEmail from 'utils/validateEmail';
import BtnSecondary from 'shared/components/Button/BtnSecondary';
import BtnPrimary from 'shared/components/Button/BtnPrimary';
import UserOption from 'shared/components/UserAutocomplete/UserAutocompleteOption';
import * as userService from 'utils/services/users';
import {phoneRegexPattern} from 'shared/constants/regexPatterns';

const FormItem = Form.Item;

const { Option } = AutoComplete;

const ManualForm = props => {
  const [disabledForm, setDisabledForm] = useState(true);
  const [users, setUsers] = useState([])
  // eslint-disable-next-line no-unused-vars
  const {
    count,
    setCount,
    addSelectedCoachToList,
    onFieldChange,
    coach,
    handleAddBtn,
    resetSelectedCoach,
    resetSelectedTeam,
    setSelectedAutocompleteCoach,
    coachSelectedInAutocomplete,
    handleInviteNow,
    resetAutocompleteCoach,
    isInvitingCoach,
    emailLanguage
  } = props;

  const { t, i18n } = useTranslation();
  const rtl_direction = i18n.dir() === 'rtl';
  const { teams } = coach;
  const [form] = Form.useForm();

  const btnDisabled = teams.length < 1;

  const isEmailTouched = form.isFieldTouched('email');
  const isFirstNameTouched = Boolean(form.getFieldValue('firstName'));
  const isLastNameTouched = Boolean(form.getFieldValue('lastName'));
  const isPhoneTouched = Boolean(form.getFieldValue('phone'));

  const clearForm = () => {
    form.resetFields();
    setDisabledForm(true)
  }

  const isFilled = (v) => Boolean(v) === true;

  const checkCoachProperties = () => {
    if (coachSelectedInAutocomplete) {
      handleAddBtn(false)
      return;
    }
    const currentKeys = ['email', 'firstName', 'lastName']
    const valuesObj = form.getFieldsValue(currentKeys);
    const valuesArr = currentKeys.map(k => valuesObj[k]);
    const isFormFilled = valuesArr.every(isFilled);
    handleAddBtn(!isFormFilled)
  }

  const { id: eventId } = useParams();

  const validateForm = async () => {
    let formValues = {}; 
    formValues = values;
    return formValues
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  }

  const successCallback = () => {
    clearForm();
    resetAutocompleteCoach();
    resetSelectedTeam();
  }

  const inviteNow = async (e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
      form.validateFields().then(async () => {
        coach.culture = emailLanguage;
        handleInviteNow(coach, eventId, successCallback)
      });
    }

  const addToList = async (e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields().then(async (values) => {
      const volunteerData = {
        ...values,
        id: uid(values),
        culture: emailLanguage
      }
      await addSelectedCoachToList(volunteerData)
      clearForm();
      await resetSelectedCoach();
      await resetSelectedTeam();
      await resetAutocompleteCoach();
    });
  }

  const handleEmailChange = (value) => {
    onFieldChange('email', value)
    const isValidEmail = validateEmail(value);
    if (isValidEmail) {
      setDisabledForm(false)
    }

    if (!isValidEmail) {
      setDisabledForm(true)
    }

    if (coachSelectedInAutocomplete) {
      resetAutocompleteCoach();
    }
  }

  const handleFieldChange = (fieldName, e) => {
    const { value } = e.target;
    onFieldChange(fieldName, value);
    // checkCoachProperties()
  }

  checkCoachProperties();

  const coachData = form.getFieldsValue();
  const {firstName, lastName, email, phone} = coachData || {}
  const coachFormInvalid = !firstName || !lastName || !email;

  const handleAddCoach = () => {
    form.validateFields().then((values) => {
      const coach = values;
      coach.id = count;
      setCount(count-1);
      const newCoaches = [...props.selectedCoaches, coach];
      props.onSubmit(newCoaches);
      form.resetFields();
    }).catch((err) => {
      props.onSubmit(null);
        throw err;
    });
  }

  return (
    <div className='create-team'>
      <Form hideRequiredMark name='AddCoachesTeams' onFinishFailed={onFinishFailed} form={form}>
        <FormItem name='firstName' rules={[{ required: true, message: t('enterFirstNameLabel') }]}>
          <Input size='large' placeholder={t('firstNameLabel')} onChange={(e) => handleFieldChange('firstName', e)} />
        </FormItem>
        <FormItem name='lastName' rules={[{ required: true, message: t('enterLastNameLabel') }]}>
          <Input size='large' placeholder={t('lastNameLabel')} onChange={(e) => handleFieldChange('lastName', e)} />
        </FormItem>
        <FormItem name='email' rules={[{ required: true, message: t('enterEmailLabel') },{ type: 'email', message: t('enterValidEmailLabel') },]}>
          <Input size='large' placeholder={t('EditUserEmailLabel')} onChange={(e) => handleFieldChange('email', e)} />
        </FormItem>
        <FormItem name='phone' rules={[{ pattern: phoneRegexPattern, message: 'Enter valid phone number.'}]}>
          <Input size='large' placeholder={t('phoneOptionalLabel')} onChange={(e) => handleFieldChange('phone', e)} />
        </FormItem>
        <div className='add-button'>
          <Button onClick={handleAddCoach} disabled={coachFormInvalid} type='primary'>Add Coach</Button>
        </div>
      </Form>
    </div>
  );
};

ManualForm.propTypes = {
  form: PropTypes.object.isRequired,
  addSelectedCoachToList: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  coach: PropTypes.object.isRequired,
  handleAddBtn: PropTypes.object.isRequired,
  resetSelectedCoach: PropTypes.func.isRequired,
  resetSelectedTeam: PropTypes.func.isRequired,
  setSelectedAutocompleteCoach: PropTypes.func.isRequired,
  coachSelectedInAutocomplete: PropTypes.bool.isRequired,
  handleInviteNow: PropTypes.func.isRequired,
  resetAutocompleteCoach: PropTypes.func.isRequired,
  isInvitingCoach: PropTypes.bool.isRequired
};

const mapDispatchToProps = {
  addSelectedCoachToList: handleAddCoachToList,
  onFieldChange: handleUpdateSelectedCoach,
  handleAddBtn: handleIsAddTeamDisabled,
  resetSelectedTeam: handleResetSelectedTeam,
  resetSelectedCoach: handleResetSelectedCoach,
  setSelectedAutocompleteCoach: handleSetCoachInAutocomplete,
  handleInviteNow: handleInviteCoachNow,
  resetAutocompleteCoach: handleResetCoachInAutocomplete

}

function mapStateToProps(state) {
  const { event: { eventTeams } } = state;
  const { selectedCoach, coachSelectedInAutocomplete, isInvitingCoach } = eventTeams;
  return {
    coach: selectedCoach,
    coachSelectedInAutocomplete,
    isInvitingCoach,

  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ManualForm);