import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Pagination, { getPageFilter } from 'shared/components/Pagination';
import ListLoading from 'shared/components/Spinner/ListLoading';
import TitleAndSort from 'features/AdminDashboard/components/TitleAndSort';
import EventsList from 'shared/components/EventsList';
import '../styles/VolunteerDashboard.scss';
import SearchBar from 'features/AdminDashboard/components/SearchBar';

const EVENTS_PAGE_SIZE = 6;

const MyEvents = (props) => {
  const { events, count, loading, fetchEvents, sortBy } = props;
  const { t } = useTranslation();
  const [current, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');

  const fetchPageEvents = useCallback((number) => {
    const page = getPageFilter(number, EVENTS_PAGE_SIZE);
    fetchEvents(search, page, sortBy);
  }, [search, sortBy]);

  useEffect(()=>{
    setCurrentPage(1);
  },[search])

  useEffect(()=>{
    let timeout = setTimeout(() => {
        fetchPageEvents(current)
      }, 500);
    return ()=>clearTimeout(timeout);
  },[search, sortBy, current])

  return (
    <>
      <div className="volunteer-dashboard__search-bar-left">
          <SearchBar
            placeholder={t('eventNameLabel')}
            isLinkVisible={false}
            searchText={search}
            setSearchText={setSearch}
          />
        </div>
      <section>
        <ListLoading loading={loading} text={t('loadingEventsLabel')} />
        <div>
          <EventsList loading={loading} events={events} tileType='volunteer' />
          <Pagination
            total={count}
            showSizeChanger={false}
            current={current}
            onChange={setCurrentPage}
            pageSize={EVENTS_PAGE_SIZE}
            loading={loading}
          />
        </div>
      </section>
    </>
  );
};

MyEvents.propTypes = {
  loading: PropTypes.bool,
  events: PropTypes.array,
};

MyEvents.defaultProps = {
  loading: false,
  events: []
};

export default MyEvents;
