import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Row, Col, Form, Button } from 'antd';
import {CalendarOutlined, CloseCircleOutlined} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Input from 'shared/components/Input';
import TextBox from 'shared/components/TextBox';
import Dropdown from 'shared/components/FormDropdown';
import { connect } from 'react-redux';
import { morningMomentObject, formatRangePickerToTimezone } from 'utils/dateTimeUtils';
import { createMeeting, getScheduleMeetingSessions, getEventTeams } from 'features/EventContainer/redux/actions/scheduleActions/scheduleMeeting';
import { useParams } from 'react-router-dom';
import { handleEventVolunteers } from 'features/EventContainer/redux/actions/volunteers/getEventVolunteers';
import { urlTransform } from 'utils/formHelpers';


const { Option } = Dropdown;

const FormItem = Form.Item;

const { RangePicker } = DatePicker;

const MAX_SIZE = 500;

const rowProps = {
  type: 'flex', gutter: [20, 20],
}

const SessionForm = props => {
  const {
    fetchEventVolunteers,
    volunteers,
    getTeamOptions,
    eventScheduleId,
    teamOptions,
    time_zone,
    use_24_hour_format
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { id: eventId } = useParams();
  useEffect(() => {
    fetchEventVolunteers(eventId, { number: 1, size: MAX_SIZE });
    getTeamOptions(eventId)
  }, [eventId])


  const createMeetingCb = () => {
    form.resetFields();
    props.getScheduleMeetingSessions();
    props.closeModal()
  }
  const onSave = async (e) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }
    form.validateFields().then(async (values, err) => {
      const { date } = values;
      const startDate = Array.isArray(date) ? date[0] : undefined;
      const endDate = Array.isArray(date) ? date[1] : undefined;
      const formattedZuluStart = formatRangePickerToTimezone(startDate, time_zone);
      const formattedZuluEnd = formatRangePickerToTimezone(endDate, time_zone);
      const trimmedTitle = values.meetingTitle && values.meetingTitle.trim();

      const sessionFormValues = {
        startTime: formattedZuluStart,
        endTime: formattedZuluEnd,
        meetingUrl: urlTransform(values.meetingUrl),
        notes: values.notes,
        scheduleId: eventScheduleId,
        title: trimmedTitle,
        attendees: values.attendees || [],
        teams: values.teams || [],
      }
      await props.createMeeting(sessionFormValues, createMeetingCb)
    });
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  }

  return (
    <div className="schedule-session-modal__form">
      <Form hideRequiredMark onFinish={onSave} name='MeetingSession' form={form}>
        <Row {...rowProps}>
          <Col xs={24}>
            <FormItem name='meetingTitle' label={<span>{t('meetingTitleLabel')}</span>} rules={[{ required: true, message: t('enterMeetingTitleLabel') }]}>
              <Input size="large" placeholder={t('meetingTitleLabel')} />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col xs={24} id='new-meeting-range-picker' style={{ position: 'relative' }}>
            <FormItem name='date' label={<span>{t('dateLabel')}</span>} rules={[{ required: true, message: t('selectDateAndTimeLabel') }]}>
                <RangePicker
                  placeholder={[t('startDateLabel'), t('endDateLabel')]}
                  showTime={{
                    format: use_24_hour_format ? 'HH:mm' : 'h:mm a', use12Hours: !use_24_hour_format,
                    defaultValue: [morningMomentObject(time_zone), morningMomentObject(time_zone)]
                  }}
                  className="schedule-session-modal__range-picker"
                  size="large"
                  format={`MM-DD-YYYY ${use_24_hour_format ? 'HH:mm' : 'h:mm a'}`}
                  suffixIcon={<CalendarOutlined className="time-picker__icon" />}
                  getCalendarContainer={() => document.getElementById('new-meeting-range-picker')}
                />
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col xs={24} md={12}>
            <FormItem name='attendees' label={<span>{t('volunteersLabel')}</span>}>
              
                <Dropdown
                  placeholder={t('volunteersLabel')}
                  {...form}
                  mode="multiple"
                  removeIcon={<CloseCircleOutlined />}
                >
                  {volunteers
                    .sort((a, b) => a.lastName.localeCompare(b.lastName))
                    .map((v) => (
                      <Option key={v.id} value={v.id}>
                        {v.fullName}
                      </Option>
                    ))}
                </Dropdown>
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem name='teams' label={<span>{t('teamsLabel')}</span>}>
                <Dropdown
                  placeholder={t('selectTeamsLabel')}
                  {...form}
                  mode="multiple"
                  removeIcon={<CloseCircleOutlined />}
                >
                  {teamOptions
                    .sort((a, b) => a.teamNumberName.localeCompare(b.teamNumberName))
                    .map((v) => (
                      <Option key={v.id} value={v.id}>
                        {v.teamNumberName}
                      </Option>
                    ))}
                </Dropdown>
            </FormItem>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col xs={24}>
            <p className='paragraph--charcoal paragraph--large'>*Meeting URL can be added after the meeting has been saved.*</p>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col xs={24}>
            <FormItem name='notes' label={<span>{t('notesLabel')}</span>}>
                <TextBox
                  size="large"
                  placeholder={t('enterAnyNotesLabel')}
                  style={{ height: '254px' }}
                />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Col>
            <FormItem>
              <Button htmlType="submit">{t('saveAndPublishLabel')}</Button>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

SessionForm.propTypes = {
  form: PropTypes.object.isRequired,
  volunteers: PropTypes.array,
  eventScheduleId: PropTypes.string.isRequired,
  createMeeting: PropTypes.func.isRequired,
  getScheduleMeetingSessions: PropTypes.func.isRequired,
  teamOptions: PropTypes.array,
};

SessionForm.defaultProps = {
  volunteers: [],
  teamOptions: []
}

const mapDispatchToProps = {
  createMeeting,
  getScheduleMeetingSessions,
  fetchEventVolunteers: handleEventVolunteers,
  getTeamOptions: getEventTeams
}

const mapStateToProps = (state) => {
  const { auth: { userInfo: { use_24_hour_format } }, event: {
    eventDetails: {
      eventScheduleId,
      time_zone
    }
  } } = state;
  return {
    time_zone,
    eventScheduleId,
    use_24_hour_format
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionForm);