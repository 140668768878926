import React from 'react';
import { connect } from 'react-redux';
import {
  FLL_CHALLENGE_ID
} from 'shared/constants/programIds';
import FLL_C_Award from './FLL_C_Award';
import Non_FLL_C_Award from './Non_FLL_C_Award';

const AssignedAwards = (props) => {
  const {eventProgramId} = props;
  return eventProgramId === FLL_CHALLENGE_ID ? <FLL_C_Award {...props}/> : <Non_FLL_C_Award {...props}/>
};

const mapStateToProps = ({ eventOverview}) => {
  const { eventProgramId } = eventOverview;
  return {
    eventProgramId,
  }
};

export default connect(mapStateToProps)(AssignedAwards);
