import React, {useEffect} from 'react';
import {Col, Row} from 'antd';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ScheduleSession from 'models/ScheduleSession';
import {fetchJudgingSessions} from 'features/EventContainer/redux/actions/scheduleActions/scheduleJudgingSessions';
import * as programTypes from 'shared/constants/programIds';
import sortSessions from 'features/EventContainer/utils/sortSessions';
import ListLoading from 'shared/components/Spinner/ListLoading';
import EventSessionCard from '../sessionCards/EventSessionCard';


const JudgingSessions = (props) => {
  const { scheduleId, getJudgingSessions, sessions, eventProgramId, is_FLLC_event } = props;

  const {
    judgingSessions,
    isLoadingJudgingSessions,
    errorJudgingSessions
  } = sessions;

  const formattedSessions = [];

  useEffect(() => {
    getJudgingSessions(scheduleId);
  }, [scheduleId])

  if (isLoadingJudgingSessions) {
    return (
      <ListLoading loading text={is_FLLC_event ? 'Loading Judging Sessions' : 'Loading Reviewing Sessions'} />

    )
  }
  if (errorJudgingSessions) {
    return <p>Error</p>
  }




  const closeModalCallback = () => {
    getJudgingSessions(scheduleId)
  }

  return (
    <Row type='flex' gutter={[10, 10]}>
      {judgingSessions.sort(sortSessions).map(session => (
        <Col xs={24} sm={12} md={8} key={session.id}>
          <EventSessionCard
            sessionTypeName={session.sessionType}
            isJoinLinkDisabled={session.isJoinLinkDisabled}
            hasSubtitle={Boolean(eventProgramId === programTypes.FTC_ID)}
            url={session.sessionUrl}
            cb={closeModalCallback}
            team={session.teamDetails}
            title={session.title}
            iconType='judging'
            sessionId={session.id}
            startTime={session.startTime}
            endTime={session.endTime}
          />
        </Col>
    ))}
    </Row>
  );
};

JudgingSessions.propTypes = {
  scheduleId: PropTypes.string.isRequired,
  getJudgingSessions: PropTypes.func.isRequired,
  sessions: PropTypes.object.isRequired,
  eventProgramId: PropTypes.string.isRequired
}

JudgingSessions.defaultProps = {

}

const mapDispatchToProps = {
  getJudgingSessions: fetchJudgingSessions
}

const mapStateToProps = (state) => {
  const { event: { eventDetails: { eventProgramId, is_FLLC_event }, eventSchedule: {sessions}}} = state;

  return {
    sessions,
    eventProgramId,
    is_FLLC_event
  }

}

export default  connect(mapStateToProps, mapDispatchToProps)(JudgingSessions);