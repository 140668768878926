import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {Form} from 'antd';
import store from 'features/store';

// NOTE: Ensure 2023 scoresheet folder is imported until after 1 August launch
//import { newFllScoringOptions } from 'shared/constants/2023Scoreheet';
import { newFllScoringOptions } from 'shared/constants/2025Scoresheet';

import Container from 'shared/components/Container';
import ModuleSection from 'features/EventOverviewContainer/components/ScoringMatch/ScoresheetForm/FormModuleSectionPublic';
import '../../shared/components/Scoresheet/styles/MatchScoresheet.scss';
import LegoChallenge from 'shared/components/Icon/logos/LegoChallenge';
import firstLegoLeagueSeasonCurrent from '../../shared/components/Icon/pngs/first-lego-league-season-logo-current-light-background.png';
import './style.scss';
import MatchScoreButton from 'features/EventOverviewContainer/components/ScoringMatch/MatchScoreButton';
import Button from 'shared/components/Button/BtnSecondary';
import { handleSetIsPublicScoresheetEdited, resetPublicScoringData, calculatePublicScoring, clearPublicScoringReset } from './redux/actions/publicScoring';
import NoEquipmentContraintSymbol from 'shared/components/Icon/pngs/NoEquipmentConstraintSymbol.png';
const subTitleClassName = 'heading--charcoal h-4 h--normal m-b-36';

const PublicScoring = props => {
  const { publicScoreInput, loadingScoreInput, totalScore, isScoresheetEdited, resetScoreData, calculatePublicScore, clearScoringReset } = props;
  const [headerClassName, setHeaderClassName] = useState('public-scoresheet-header');
  const [form] = Form.useForm();

  const listenScrollEvent = event => {
    if (window.scrollY < 115) {
      return setHeaderClassName("public-scoresheet-header");
    } if (window.scrollY > 115) {
      return setHeaderClassName("public-scoresheet-header2");
    }
  };

const symbolDivStyle = {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '50px'
};
const imgStyle = {
    objectFit: 'contain',
    marginBottom: 'auto'
};

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  const { t } = useTranslation();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    resetScoreData();
  }, [])

  const formatValues = (v) => {
    const newValues = {...v}
        if( newValues.m00_scoring_1 === null ) {newValues.m00_scoring_1 = false}
        if( newValues.m00_scoring_1 === "false" ) {newValues.m00_scoring_1 = false}
        if( newValues.m00_scoring_1 === "true" ) {newValues.m00_scoring_1 = true}
        if( newValues.m01_scoring_1 === null ) {newValues.m01_scoring_1 = false}
        if( newValues.m01_scoring_2 === null ) {newValues.m01_scoring_2 = false}
        if( newValues.m01_scoring_3 === null ) {newValues.m01_scoring_3 = false}
        if( newValues.m02_scoring_1 === null ) {newValues.m02_scoring_1 = false}
        if( newValues.m02_scoring_2 === null ) {newValues.m02_scoring_2 = false}
        if( newValues.m03_scoring_1 === null ) {newValues.m03_scoring_1 = false}
        if( newValues.m03_scoring_2 === null ) {newValues.m03_scoring_2 = 0}
        if( newValues.m04_scoring_1 === null ) {newValues.m04_scoring_1 = false}
        if( newValues.m04_scoring_2 === null ) {newValues.m04_scoring_2 = false}
        if( newValues.m05_scoring_1 === null ) {newValues.m05_scoring_1 = false}
        if( newValues.m06_scoring_1 === null ) {newValues.m06_scoring_1 = false}
        if( newValues.m07_scoring_1 === null ) {newValues.m07_scoring_1 = false}
        if( newValues.m08_scoring_1 === null ) {newValues.m08_scoring_1 = 0}
        if( newValues.m09_scoring_1 === null ) {newValues.m09_scoring_1 = false}
        if( newValues.m09_scoring_2 === null ) {newValues.m09_scoring_2 = false}
        if( newValues.m10_scoring_1 === null ) {newValues.m10_scoring_1 = false}
        if( newValues.m10_scoring_2 === null ) {newValues.m10_scoring_2 = false}
        if( newValues.m11_scoring_1 === null) {newValues.m11_scoring_1 = 0}
        if( newValues.m12_scoring_1 === null ) {newValues.m12_scoring_1 = 0}
        if( newValues.m13_scoring_1 === null ) {newValues.m13_scoring_1 = false}
        if( newValues.m14_scoring_1 === null ) {newValues.m14_scoring_1 = false}
        if( newValues.m14_scoring_2 === null ) {newValues.m14_scoring_2 = false}
        if( newValues.m14_scoring_3 === null ) {newValues.m14_scoring_3 = false}
        if( newValues.m14_scoring_4 === null ) {newValues.m14_scoring_4 = 0}
        if( newValues.m15_scoring_1 === null ) {newValues.m15_scoring_1 = 0}
        if( newValues.m15_scoring_2 === null ) {newValues.m15_scoring_2 = false}
        if( newValues.precision_tokens === null ) {newValues.precision_tokens = 0}
        if( newValues.precision_tokens === null ) {newValues.precision_tokens = 0}
    
    
        return newValues;
      }

  const handleCalculatePublicScoring = async (e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields().then(async (values, err) => {
      if (err) {
        throw err;
      }
      calculatePublicScore(formatValues(values));
    });
  }

  const resetForm = () => {
    form.resetFields();
    resetScoreData();
    setShowError(false);
  }

  return (
    <div>
      <div className={headerClassName}>
        <div className="public-scoresheet-header-wrapper">
          <div className="public-scoresheet-header-logo-wrapper">
            <LegoChallenge />
            <img src={firstLegoLeagueSeasonCurrent} className="public-scoresheet-header-logo-two" alt="FIRST LEGO League Current Season Logo" height="175" />
          </div>
          <div className="public-scoresheet-header-title-buttons-wrapper">
            <div className="public-scoresheet-header-title">Official Scoring Calculator</div>
            <div className="public-scoresheet-header-buttons-wrapper">
              <Button _props={{ ghost: true }} additionalClassNames="public-scoresheet-header-start-over" onClick={resetForm}>Start Over</Button>
              <MatchScoreButton
                score={totalScore}
                disabled={!isScoresheetEdited}
                loading={loadingScoreInput}
                onRecalculateClick={handleCalculatePublicScoring}
              />
            </div>
          </div>
        </div>        
      </div>
      <Container className='match-scoresheet__form'>
              <div style={symbolDivStyle}><img
                  src={NoEquipmentContraintSymbol}
                  alt='NoEquipmentContraintSymbol'
                  className='m-r-10 m-t-10'
                  style={imgStyle}
              /><h1 className={subTitleClassName}>{t('noEquipmentContraintHeaderLabel')}</h1></div>
        <Form form={form} className="match-scoresheet-referee">
          {newFllScoringOptions.map(i => (
            <li key={i.id}>
                <ModuleSection
                  form={form}
                  moduleName={i.id}
                  moduleTitle={t(i.name)}
                  fields={i.fields}
                  showError={showError}
                  setShowError={setShowError}
                  publicScoreInput={publicScoreInput}
                  clearPublicScoringReset={clearScoringReset}
                />
            </li>
          ))}
        </Form>
      </Container>
    </div>
  );
};

const mapDispatchToProps = {
  resetScoreData: resetPublicScoringData,
  calculatePublicScore: calculatePublicScoring,
  clearScoringReset: clearPublicScoringReset
}

const mapStateToProps = (state) => {
  const { publicScoring } = state;
  const { isScoreEdited, totalScore, isScoreCalculating } = publicScoring;
  return {
    publicScoreInput: publicScoring,
    loadingScoreInput: isScoreCalculating,
    totalScore,
    isScoresheetEdited: isScoreEdited
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicScoring);