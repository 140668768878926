import * as actionTypes from '../types';

const updateGlobalUploadModalPropsAction = (payload) =>{
  return {
    type: actionTypes.UPDATE_GLOBAL_UPLOAD_MODAL_PROPS,
    payload
  }
}

export const updateGlobalUploadModalProps = (data)=>async(dispatch)=>{
  await dispatch(updateGlobalUploadModalPropsAction(data))
}