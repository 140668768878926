/* eslint-disable import/prefer-default-export */
import {updateUserProfile} from 'features/Authentication/redux/authActions';
import {updateUser} from 'utils/services/users';
import {formatUserResData} from 'utils/services/users/model';
import { notification } from 'antd';
import * as types from './headerTypes';


const updateProfileBegin = () => {
  return {
    type: types.UPDATE_HEADER_USER_PROFILE_BEGIN
  }
}

const updateProfileError = () => {
  return {
    type: types.UPDATE_HEADER_USER_PROFILE_ERROR
  }
}

const updateProfileSuccess = () => {
  return {
    type: types.UPDATE_HEADER_USER_PROFILE_SUCCESS
  }
}

export const handleUpdateProfile = (values) => {
  return async (dispatch, getState) => {
    const {auth: {userId}} = getState();
    try {
      await dispatch(updateProfileBegin());
      const res = await updateUser(userId, values);
      await dispatch(updateProfileSuccess());

      const updatedUserPayload = formatUserResData(res);
    
      await dispatch(updateUserProfile(updatedUserPayload));
      notification.success({
        message: 'Success',
        description: 'Your profile was successfully saved',
      });
    } catch(err) {
      dispatch(updateProfileError());
      notification.error({
        message: 'Error',
        description: 'Error saving your profile',
      });
      throw err;
    }
  }
}