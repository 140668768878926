import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/TeamTile.scss';
import Team from 'models/Team';
import {Tooltip} from 'antd';
import truncateString from 'utils/truncateString';

const TeamTile = (props) => {
  const { team, included } = props;

  const teamData = new Team(team, included);

  const country = teamData.getCountryName();
  const region = teamData.getRegionName();

  const prompt = (
    <div>
      <p>{teamData.teamNumber}</p>
      <p>{teamData.teamName}</p>
    </div>
  );

  const truncatedName = truncateString(teamData.teamName, 40);
  const showHelper = teamData.teamName.length > 40;

  const teamTileContent = (
    <div className='team-tile__inner'>
      <Tooltip title={showHelper && prompt}>
        <div className="team-tile__inner-row">
          <div className="team-tile__left-wrapper">
            <div className="team-tile__left-wrapper first-row">{teamData.teamNumber}</div>
            <div className="team-tile__left-wrapper second-row">{truncatedName}</div>
          </div>
          <div className="team-tile-separator"/>
          <div className="team-tile__right-wrapper">
            <div className="team-tile__right-wrapper first-row"/>
            <div className="team-tile__right-wrapper second-row">
              {country}{country&&region ? ', ':''}{region}
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  )

  return (
    <div className='team-tile'>
      {teamTileContent}
    </div>
  );
};

TeamTile.propTypes = {
  team: PropTypes.object.isRequired,
  included: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  isButton: PropTypes.bool,
  showCoaches: PropTypes.bool,
};

TeamTile.defaultProps = {
  onClick: () => { },
  isButton: false,
  showCoaches: true
};

export default TeamTile;
