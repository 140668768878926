import React from 'react';
import PropTypes from 'prop-types';
import JudgingMaterialsRow from './JudgingMaterialsRow';
import { useTranslation } from 'react-i18next';

const DocumentsAndMaterials = props => {
  const headingClassName = 'h-2--normal heading--charcoal m-b-24';
  const {
    documentTypes,
    sessionDocuments,
    setUploadModalVisibility,
    setUploadProgress,
  } = props;
  const {t} = useTranslation();
  return (
    <div className='m-t-60 m-b-18'>
      <h2 className={headingClassName}>{t('documentAndMaterialsLabel')}</h2>
      {sessionDocuments.map(d => (
        <JudgingMaterialsRow
          key={d.sessionDocumentId}
          id={d.sessionDocumentId}
          docTypeID={d.documentTypeId}
          documentType={d.documentType}
          files={d.files}
          loading={d.loading}
          setUploadModalVisibility={setUploadModalVisibility}
          setUploadProgress={setUploadProgress}
        />
      ))}
    </div>
  );
};

DocumentsAndMaterials.propTypes = {
  documentTypes: PropTypes.array,
  sessionDocuments: PropTypes.array,
  setUploadModalVisibility: PropTypes.func,
  setUploadProgress: PropTypes.func,
};
DocumentsAndMaterials.defaultProps = {
  documentTypes: [],
  sessionDocuments: []
};

export default DocumentsAndMaterials;