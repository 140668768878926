/* eslint-disable import/prefer-default-export */
import * as types from 'features/EventContainer/redux/types/teamsTypes';
import * as teamService from 'utils/services/teams';

const getEventTeamsBegin = () => {
  return {
    type: types.GET_EVENT_TEAMS_BEGIN
  };
};

const getEventTeamsError = () => {
  return {
    type: types.GET_EVENT_TEAMS_ERROR
  };
};

const getEventTeamsSuccess = (payload) => {
  return {
    type: types.GET_EVENT_TEAMS_SUCCESS,
    payload
  };
};


//get all teams
const getEventAllTeamsBegin = () => {
  return {
    type: types.GET_EVENT_ALL_TEAMS_BEGIN
  };
};

const getEventAllTeamsError = () => {
  return {
    type: types.GET_EVENT_ALL_TEAMS_ERROR
  };
};

const getEventAllTeamsSuccess = (payload) => {
  return {
    type: types.GET_EVENT_ALL_TEAMS_SUCCESS,
    payload
  };
};

export const handleGetEventTeams = (eventId, pageFilter, searchText) => {
  return async dispatch => {
    await dispatch(getEventTeamsBegin());
    try {
      const searchFilter = searchText && { name: `like:${searchText}` };
      const response = await teamService.getTeamsByEventId(eventId, pageFilter, searchFilter);
      await dispatch(getEventTeamsSuccess(response))
    } catch (err) {
      await dispatch(getEventTeamsError());
      throw err;
    }
  };
};

export const handleGetEventAllTeams = (eventId, pageFilter, searchText) => {
  return async dispatch => {
    await dispatch(getEventAllTeamsBegin());

    try {
      const searchFilter = searchText && { name: `like:${searchText}` };
      const response = await teamService.getAllTeamsByEventIdWithoutInclude(eventId, pageFilter, searchFilter);
      await dispatch(getEventAllTeamsSuccess(response))
      return response
    } catch (error) {
      await dispatch(getEventAllTeamsError());
      console.log(error);
      throw error;
    }
  }
};