import * as types from 'features/EventContainer/redux/types/volunteersTypes';
import * as userService from 'utils/services/users';
import { notification } from 'antd';

const reinviteUserBegin = () => {
    return {
        type: types.REINVITE_EVENT_VOLUNTEER_USER_BEGIN
    }
}

const reinviteUserError = () => {
    return {
        type: types.REINVITE_EVENT_VOLUNTEER_USER_ERROR
    }
}

const reinviteUserSuccess = (updatedIncludedList = []) => {
    return {
        type: types.REINVITE_EVENT_VOLUNTEER_USER_SUCCESS,
        payload: {
            updatedIncludedList
        }
    }
}

const generateInvitationLink = (userId) => {
    return async (dispatch, getState) => {
        const { event: { eventDetails: { id } } } = getState();

        try {
            const link = await userService.getInvitationLink(userId, id)
            notification.success({
                message: 'Success',
                description: 'Invitation link copied to clipboard .',
            })

            return link
        } catch (err) {
            notification.error({
                message: 'Error',
                description: 'Unable to generate invitation link',
            });
            // await dispatch(reinviteUserError());
            throw err;
        }
    }
}

export default generateInvitationLink;