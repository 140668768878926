import React from 'react';
import PropTypes from 'prop-types';
import { Popover} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import './Tooltip.scss';

const Tooltip = props => {
  const {placement, text} = props;
  const content = (
    <div className='tooltip'>
      <p className='paragraph--charcoal paragraph--medium'>{text}</p>
    </div>
  );

  return (
    <Popover title={null} content={content} placement={placement}>
      <span><InfoCircleOutlined style={{color: '#0066B3', fontSize: '25px'}}/></span>
    </Popover>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string,
  placement: PropTypes.string
};

Tooltip.defaultProps = {
  text: '',
  placement: 'top'
}

export default Tooltip;