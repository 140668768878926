import * as panelService from 'utils/services/panels';
import {notification} from 'antd';
import {resetAddToPanelList} from 'features/PanelManagement/redux/actions/judges';
import {resetAddToPanelList as resetTeamsAddToPanelList} from 'features/PanelManagement/redux/actions/teams';
import * as types from '../../types/judgingTypes';
import {getJudgingTabData} from './getJudgingPanels'

const createNewJudgingPanelBegin = () => {
  return {
    type: types.CREATE_NEW_JUDGING_PANEL_BEGIN
  }
  
}
const createNewJudgingPanelError = () => {
  return {
    type: types.CREATE_NEW_JUDGING_PANEL_ERROR
  }
}
const createNewJudgingPanelSuccess = (judgingPanels, judgeOptions, sessionOptions) => {
  return {
    type: types.CREATE_NEW_JUDGING_PANEL_SUCCESS,
    payload: {
      judgingPanels, judgeOptions, sessionOptions
    }
  }

}

const createNewJudgingPanel = (payload, cb = () => {}) => {
  return async (dispatch, getState) => {
    const {event: {eventDetails: {id, eventScheduleId}}} = getState();

    const {panelName, sessionIds, judgeIds} = payload;
    try {
      await dispatch(createNewJudgingPanelBegin())
      await panelService.createJudgingPanel(panelName, sessionIds, judgeIds, eventScheduleId);
      const {formattedPanels, judgeOptions, sessionOptions} = await getJudgingTabData(id, eventScheduleId)
      notification.success({
        message: 'Success',
        description: 'The room was successfully created.',
      });
      cb();
      await dispatch(createNewJudgingPanelSuccess(formattedPanels, judgeOptions, sessionOptions))

    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error creating the panel',
      });
      await dispatch(createNewJudgingPanelError())
      throw err;
    }
  }

}

const createNew = (payload, cb = () => { }) => {
  return async (dispatch, getState) => {
    const { panel_type_id, desired_number_of_panels } = payload;
    const { event: { eventDetails: { id, eventScheduleId } } } = getState();

    const { panelName, sessionIds, judgeIds } = payload;
    try {
      await dispatch(createNewJudgingPanelBegin())
      let data = {
        panel_type_id,
        desired_number_of_panels,
        schedule_id: eventScheduleId
      }
      const response = await panelService.batchCreatePanel(data);
      notification.success({
        message: 'Success',
        description: desired_number_of_panels > 1 ? 'Rooms were successfully created.' : 'Room was successfully created.',
      }); 
      // await panelService.createJudgingPanel(panelName, sessionIds, judgeIds, eventScheduleId);
      // const { formattedPanels, judgeOptions, sessionOptions } = await getJudgingTabData(id, eventScheduleId)
      
      cb();
      // await dispatch(createNewJudgingPanelSuccess(formattedPanels, judgeOptions, sessionOptions))

    } catch (err) {
      if(err?.data?.statusCode == 400 && err?.data?.error == 'Failed to add new panels as current request would result in a panel count higher than allowable threshold of 64.'){
        notification.error({
          message: 'Error',
          description: 'A maximum of 64 rooms/tables can be created',
        });
      }else{
        notification.error({
          message: 'Error',
          description: 'Error creating room',
        });
      }
      await dispatch(createNewJudgingPanelError())
      throw err;
    }
  }

}
export { createNew }
export default createNewJudgingPanel;