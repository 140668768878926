import React from 'react';

export default ({fill = "#52C41A", size = '18px'})=>{
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
        <title>E988F47F-A96E-4B11-A989-D9371F0590FE</title>
        <g id="Scoring-and-Refereeing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Teams---Single-Event---Scoring---Coach" transform="translate(-601.000000, -756.000000)" fill={fill}>
                <g id="Group-2" transform="translate(249.000000, 390.000000)">
                    <g id="Group-21" transform="translate(0.000000, 298.000000)">
                        <g id="Group-19" transform="translate(1.000000, 0.000000)">
                            <g id="Group-3" transform="translate(26.000000, 66.000000)">
                                <g id="Icon/Question" transform="translate(325.000000, 2.000000)">
                                    <path d="M9,18 C4.02932551,18 0,13.9706745 0,9 C0,4.02932551 4.02932551,0 9,0 C13.9706745,0 18,4.02932551 18,9 C18,13.9706745 13.9706745,18 9,18 L9,18 Z M14.4404692,11.3859238 L7.1313783,4.27214076 C6.91143695,4.05219941 6.5542522,4.05219941 6.33431085,4.27214076 L3.54193548,6.94134897 C3.32199413,7.16129032 3.32199413,7.51847507 3.54193548,7.73841642 L3.54193548,7.73841642 C3.76187683,7.95835777 4.11906158,7.95835777 4.33900293,7.73841642 L6.73372434,5.46686217 L13.6434018,12.1829912 C13.8633431,12.4029326 14.2205279,12.4029326 14.4404692,12.1829912 L14.4404692,12.1829912 C14.6604106,11.9630499 14.6604106,11.6058651 14.4404692,11.3859238 L14.4404692,11.3859238 Z" id="Shape" transform="translate(9.000000, 9.000000) scale(1, -1) translate(-9.000000, -9.000000) "></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  )
}