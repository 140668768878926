const rd5Section = {
  id: 'RD5',
  label: 'rd5Label',
  title: 'communicateLabel',
  description: 'communicate1DescriptionLabel',
  fields: [
    {
      legendTitle: 'ChallengeRubricExplanationLabel',
      id: 'robot_design_5_communicate_explanation',
      isCoreValue: true,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricExplanation1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricExplanation2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricExplanation3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricExplanation4Label',
      }]
    },
    {
      legendTitle: 'ChallengeRubricEvidenceLabel',
      id: 'robot_design_5_communicate_enthusiasm',
      isCoreValue: true,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricEvidence1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricEvidence2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricEvidence3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricEvidence4Label',
      }]
    },
  ]
}

export default rd5Section;