import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Heading from 'shared/components/Heading';
import EventSponsors from 'shared/components/EventSponsors';
import { getAllSponsors } from 'utils/services/sponsors';
import '../styles/EventOverviewLayout.scss';
import { useTranslation } from 'react-i18next';

const EventOverviewLayout = (props) => {
  const { children, /*currentTab,*/ scheduleId, programId } = props;
  const [sponsors, setSponsors] = useState([]);
  const {t} = useTranslation();
  useEffect(() => {
    if (scheduleId) {
      const fetchSponsors = async () => {
        const sponsorsData = await getAllSponsors(scheduleId);
        setSponsors(sponsorsData);
      }
      fetchSponsors();
    }
  }, [scheduleId]);

  const renderSponsors = () => (
    <EventSponsors
      renderHeader={() => (
        <Heading weight='normal' tag={2} additionalClassNames='event-overview-layout__sponsor-header'>
          {t('eventSponsorsLabel')}
        </Heading>
      )}
      sponsors={sponsors}
      programId={programId}
    />
  );

  // if (currentTab === '#schedule') {
  //   return (
  //     <div className='event-overview-layout'>
  //       <div className='event-overview-layout__hero'>
  //         <div className='event-overview-layout__hero-image' />
  //       </div>
  //       <div className='event-overview-layout__content'>
  //         {children}
  //       </div>
  //       {renderSponsors()}
  //     </div>
  //   );
  // }

  return (
    <div className='event-overview-layout--child'>
      {children}
      {renderSponsors()}
    </div>
  );
};

EventOverviewLayout.propTypes = {
  children: PropTypes.node.isRequired,
  currentTab: PropTypes.string
};

EventOverviewLayout.defaultProps = {
  currentTab: '#schedule'
};

export default EventOverviewLayout;
