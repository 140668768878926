import RegistrationLayout from 'shared/components/Layouts/RegistrationPageLayout';
import RegistrationForm from 'shared/components/Layouts/Registration/FormContainer';
import { Row, Col, Modal, notification } from 'antd';
import BtnPrimary from 'shared/components/Button/BtnPrimary'
import * as userService from 'utils/services/users';
import auth from '../../../../Auth';
import {
  COACH_ID,
  HEAD_REFEREE_ID,
  EVENT_ADMIN_ID,
  JUDGE_ADVISOR_ID,
  REFEREE_ID,
  JUDGE_ID
} from 'shared/constants/eventRoleTypes';
import jwtDecode from 'jwt-decode';
import { processAuthResult, handleAuthError } from 'utils/authorize';
import React, {useEffect, useState} from 'react';
import { Redirect, withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  setCanAccessAdminDashboard,
  setCanAccessVolunteerDashboard,
} from 'features/Authentication/redux/authActions';
import SignUpStepperHeader from './SignUpStepperHeader';
import TermsAndConditions from './TermsAndConditionsSteps';
import RegistrationOptions from './SignupOptions';
import { 
  setAssignRole, 
  verifySignUpSuccess,
} from '../../redux/registrationActions';


const TOTAL_STEPS_COUNT = 4;
// event admin, head referee, judge advisor
const ADMIN_EVENT_ROLE_TYPE_IDS = [
  HEAD_REFEREE_ID,
  EVENT_ADMIN_ID,
  JUDGE_ADVISOR_ID,
];

// coach, judge, referee, event admin, head referee, judge advisor
const VOLUNTEER_EVENT_ROLE_TYPE_IDS = [
  COACH_ID,
  JUDGE_ID,
  REFEREE_ID,
  HEAD_REFEREE_ID,
  EVENT_ADMIN_ID,
  JUDGE_ADVISOR_ID,
];

const VerifyAccount = props => {
  const {
    initialQuery,
    location,
    history,
    setNextStep,
    setAdminDashboardAccess,
    setVolunteerDashboardAccess,
  } = props;
  const [disableVerifyBtn, setDisableVerifyBtn] = useState(true);
  const [currentStep, setCurrentStep] = useState(2);
  const [errorMessage, setErrorMessage] = useState(null);
  
  const { hash } = location;

  const options = [
    {
      text:'Complete with your email address',
      handleClick: () => {},
      isBtnDisabled: true
    },
    { 
      text:  'Complete with Google', 
      handleClick:  () => {},
      isBtnDisabled: true
    }
  ];

  const googleAuthError = () => {
    Modal.error({
      title: <h1 className='paragraph--charcoal paragraph--large paragraph--bold'>Email does not match the email that was invited to the FIRST Remote Hub.</h1>,
      // content: (
      //   <div>
      //     <p className='paragraph--charcoal paragraph--medium'>Please go to your original email invitation and click on the invitation link.</p>
      //   </div>
      // ),
      onOk: () => {history.go(-2)}
    });
  }

  const handlePostGoogleAuth = async ()=>{
    const updatedAuthResult = await auth.GetInstance().handleAuthentication();
    const roleData = await processAuthResult(updatedAuthResult);
    if (roleData) {
      const canAccessAdminDashboard = ADMIN_EVENT_ROLE_TYPE_IDS.some(i => roleData.userEventRoles.includes(i)) || roleData.userType !== 'User';
      const canAccessVolunteerDashboard = VOLUNTEER_EVENT_ROLE_TYPE_IDS.some(i => roleData.userEventRoles.includes(i));
      setAdminDashboardAccess(canAccessAdminDashboard);
      setVolunteerDashboardAccess(canAccessVolunteerDashboard);
    }

    setCurrentStep(3)
  }

  const googleAuthSuccess = (authResultValue) => {
    Modal.success({
      title: <h1 className='paragraph--charcoal paragraph--large paragraph--bold'>Success!</h1>,
      content: (<p className='paragraph--charcoal paragraph--medium'>
        Please click
        {' '}
        <b>Next</b>
        {' '}
        for a few last things.
                </p>),
      okText: 'Next',
      onOk: async () => {
        await userService.verifyInvitation(initialQuery);
        await userService.assignUserRole(initialQuery)
        await props.verifySignUpSuccess();
        await props.setAssignRole();
        auth.GetInstance().googleAuthentication(handlePostGoogleAuth)
      }
    });
  }

  useEffect(() => {
    const handleAuth = async () => {
      try {
        const authResult = await auth.GetInstance().handleAuthentication(hash);
        
        const idTokenPayload = authResult.idTokenPayload || jwtDecode(authResult.idToken) || {};
        
        if ((initialQuery.email && idTokenPayload.email) && initialQuery.email.toLowerCase() === idTokenPayload.email.toLowerCase()) {
          setDisableVerifyBtn(false);
          setErrorMessage(null)
          googleAuthSuccess(authResult)
        } else {
          googleAuthError()
          // setDisableVerifyBtn(true)
          setErrorMessage('Email does not match the email that was invited to the FIRST Remote Hub')
        }
        // console.log(idTokenPayload)
      } catch (err) {
        googleAuthError()
        setDisableVerifyBtn(true)     

        throw err
      }
    }
    if (hash && /access_token|id_token|error/.test(hash)) {

      handleAuth();
    } else {

      // history.replace('/signup')
    }
  }, [hash])



  const renderContent = () => {
    if (currentStep === 3 || currentStep === 4) {
      return (
        <TermsAndConditions
          history={history}
          goToFinalStep={() => {setCurrentStep(4)}}
        />
)
    }
      return (
        <div className='registration__signup-form'>
          <RegistrationOptions
            options={options}
            email={initialQuery.email}
          />
        </div>
      )
    

  }
  return (
    <div>
      <RegistrationLayout>
        <SignUpStepperHeader currentStep={currentStep} totalSteps={TOTAL_STEPS_COUNT}  />
        <RegistrationForm title="Complete Account Setup" error={errorMessage}>
          {renderContent()}
        </RegistrationForm>
      </RegistrationLayout>
    </div>
  );
};


VerifyAccount.propTypes = {
  initialQuery: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  const {
    auth: { initialRegistrationDetails}
  } = state;
  return {
    initialQuery: initialRegistrationDetails
  };
};
const mapDispatchToProps = {
  verifySignUpSuccess,
  setAssignRole,
  setAdminDashboardAccess: setCanAccessAdminDashboard,
  setVolunteerDashboardAccess: setCanAccessVolunteerDashboard,
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyAccount));