import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from 'swiper';
import 'swiper/swiper-bundle.css';
import PropTypes from 'prop-types';
import {getSessionFile} from 'utils/services/sessionDocuments';
import { ClipLoader } from 'react-spinners';
import get from 'lodash/get'
import find from 'lodash/find'
import {fileStatuses} from 'shared/constants/fileStatuses';
import PDFModal from './PDFModal';
import Button from 'shared/components/Button/BtnSecondary';
import VideoPlayer from 'shared/components/Carousel/videoPlayer';

const whiteTextClassName = 'paragraph--large paragraph--bold paragraph--white';

const textClassName = 'paragraph--xlarge paragraph--charcoal paragraph--400';

const DEFAULT_STATE = {
  formattedFiles: [],
  isLoadingFiles: false,
  isError: false
}
SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);

const Carousel  = (props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const [formattedFiles, setFormattedFiles] = useState([]);
  const {files} = props;
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [state, setState] = useState(DEFAULT_STATE);
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);

  const swiperRef = useRef(null);


  useEffect(() => {
    let isMounted = true;

    const getSessionFiles = async () => {
      setState(prevState => ({...prevState, isLoading: true, isError: false}))
      const matchFilesList = [];
      try {
        for (let i = 0; i < files.length; i+=1) {
          const { eventFileId, sessionDocumentId, status } = files[i];
          if (eventFileId && status === 'Available') {
            try {
              const response =  await getSessionFile(sessionDocumentId, eventFileId);
              const presignedUrlObject = response.included && find(response.included, res => res.type === 'pre-signed_url')
              const presignedUrl = get(presignedUrlObject, ['attributes', 'url'], '')
              matchFilesList.push({...files[i], src: presignedUrl, isError: false})
            } catch(err) {
              matchFilesList.push({...files[i], isError: true,})
            }
          } else {
            matchFilesList.push({...files[i]})
          }
        }

        if (isMounted) {
          setState(prevState => {
            return {
              ...prevState,
              formattedFiles: matchFilesList,
              isLoading: false,
              isError: false
            }
          })
        }
         setFormattedFiles(matchFilesList);

      } catch(err) {
        if (isMounted) {
          setState(prevState => {
            return {...prevState, isLoading: false, isError: true}
          })
        }
        throw err;
      }
    }
    if (files.length) {
      getSessionFiles();
    }

    return () => {isMounted = false}

  }, [files])


  if (!files.length) return null;

  if (state.isLoading) {
    return (
      <div className='c-carousel__loading'>
        <ClipLoader size={36} color='#0066B3' />
      </div>
    )

  }

  const slides = formattedFiles.map((f, i) => {
    if (f.status !== 'Available') {
      const sliderStatus = fileStatuses.find(fileStatus => fileStatus.status === f.status) || {};
      
      return (
        <SwiperSlide key={f.eventFileId} tag="li">
          <div className='c-carousel__slide-no-content'>
            <p className={whiteTextClassName}>{sliderStatus.description || 'Error rendering the file.'}</p>
          </div>
        </SwiperSlide>
      )
    }
    
    if (f.isError) {
      return (
        <SwiperSlide key={f.eventFileId} tag="li">
          <div className='c-carousel__slide-content'>
            <p className={whiteTextClassName}>Error</p>
          </div>
      
        </SwiperSlide>
      )
    }

    if (f.contentType.includes('video')) {
     
      return (
        <SwiperSlide className='foobar' key={f.eventFileId} tag="li">
          <div className='c-carousel__slide-content'>
            <VideoPlayer f={f}/>
          </div>
        </SwiperSlide>
      )
    } if (f.contentType.includes('image')) {
      return (
        <SwiperSlide key={f.eventFileId} tag="li">
          <div className='c-carousel__slide-content'>
            <img className='c-carousel__image' src={f.src} alt={f.contentType} />
          </div>
        </SwiperSlide>
      )
    } if(f.contentType.includes('pdf')) {
      return (
          <SwiperSlide key={f.eventFileId} tag="li">
            <div className='c-carousel__slide-content'>
              <div className='c-carousel__pdf'>
                <Button onClick={()=>{
                  setPdfModalVisible(true);
                  setPdfFile(f)
                }}>{f.fileName}</Button>
              </div>
            </div>
          </SwiperSlide>
      )
    }
    return (
      <SwiperSlide key={f.eventFileId} tag="li">
        <div className='c-carousel__slide-content'>
          <object 
            data={f.src}
            type={f.contentType}
            aria-label={f.contentType}
            width="100%"
            height="100%"
            name={f.fileName}
          />
        </div>
      </SwiperSlide>
    )

  })


  return (
    <div className='c-carousel'>
      <PDFModal file={pdfFile} visible={pdfModalVisible} onCancel={()=>{setPdfModalVisible(false);setPdfFile(null)}}/>
      {formattedFiles.length ? (
        <div className='c-carousel__meta'>
          <p className={textClassName}>{formattedFiles[currentFileIndex].fileName}</p>
          <p className={textClassName}>{`${currentFileIndex + 1} of ${formattedFiles.length}`}</p>
        </div>
        ): null}
      
      <Swiper
        id="main"
        thumbs={{ swiper: thumbsSwiper }}
        controller={{ control: controlledSwiper }}
        tag="section"
        wrapperTag="ul"
        navigation
        ref={swiperRef}
        spaceBetween={0}
        slidesPerView={1}
        className='c-carousel__slider'
        onSlideChange={(swiper) => {
          const videoElement = swiperRef.current.querySelector('.swiper-slide-active video')
          if (videoElement) {
            videoElement.pause()
          }
          setCurrentFileIndex(swiper.activeIndex);
        }}
      >
        {slides}
      </Swiper>
    </div>
  );
}
Carousel.propTypes = {
  files: PropTypes.array,
}

Carousel.defaultProps = {
  files: [],
}

export default Carousel;