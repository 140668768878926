import validatePhoneNumber from 'utils/validatePhoneNumber';

const checkPhoneNumberValidity = (phoneNumber, line) => {

  if (!phoneNumber) return [];
  const isPhoneValid = validatePhoneNumber(phoneNumber);

  if (!isPhoneValid) return [{message: `Invalid phone number, line ${line}`}]

  return []
}

export default checkPhoneNumberValidity;