import { matchTypes } from "shared/constants/matchTypes"
import { parseDayDateTime } from "utils/dateTimeUtils";

export const formatMatchResponse = (match) => {
  return {
    matchId: match.matchId,
    matchStatusId: match.matchStatusId,
    matchTypeId: match.matchTypeId,
    sessionId: match.sessionId,
    sessionName: null,
    matchStatusName: match.getMatchStatusName(),
    matchTypeName: match.getMatchTypeName(),
    matchNumber: match.matchNumber,
    files: match.getFiles(),
    scoreId: match.scoreId
  }
}

export const formatMatchData = (match, scoresSet) => {
  const matchTypeName = (matchTypes[match.matchTypeId]||{}).name;
  const matchTypeLabel = (matchTypes[match.matchTypeId]||{}).label;
  const score = scoresSet[match.scoreId];
  const totalScore = score?.overall_score ? score.overall_score : score?.overallScore;
  
  return {
    matchId: match.id,
    matchStatusId: match.statusId,
    matchTypeId: match.matchTypeId,
    sessionId: match.sessionId,
    sessionName: null,
    matchStatusName: match.matchStatus,
    matchTypeName: matchTypeName,
    matchTypeLabel: matchTypeLabel,
    matchNumber: match.matchNumber,
    files: match.files,
    scoreId: match.scoreId,
    matchTeam: match.teamName,
    teamNumber: match.teamNumber,
    matchTeamId: match.teamId,
    startTime: match.startTime,
    endTime: match.endTime,
    startIsoString: parseDayDateTime(match.startTime),
    panelName: match.panelName,
    panelId: match.panelId,
    matchTotalScore: totalScore,
  }
}