import React from 'react';

const ErrorIcon = (props) => {
  const { visible, size='24' , ..._props} = props;

  if (!visible) {
    return null
  }

  return (
    <svg {..._props} width={size+'px'} height={size+'px'} viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1128.000000, -320.000000)">
          <g transform="translate(298.000000, 320.000000)">
            <g transform="translate(830.000000, 0.000000)">
              <path d="M12,0 C5.371875,0 0,5.371875 0,12 C0,18.628125 5.371875,24 12,24 C18.628125,24 24,18.628125 24,12 C24,5.371875 18.628125,0 12,0 L12,0 Z M17.6484375,16.4835937 C17.94375,16.7742187 17.9484375,17.2476562 17.6601563,17.5453125 L17.6601563,17.5453125 C17.3695313,17.840625 16.8960938,17.8453125 16.5984375,17.5570312 L12,13.05 L7.4015625,17.5546875 C7.10625,17.8453125 6.63046875,17.840625 6.33984375,17.5429687 L6.33984375,17.5429687 C6.04921875,17.2476562 6.05390625,16.771875 6.3515625,16.48125 L10.9289063,12 L6.3515625,7.51640625 C6.05625,7.22578125 6.0515625,6.75234375 6.33984375,6.4546875 L6.33984375,6.4546875 C6.63046875,6.159375 7.10390625,6.1546875 7.4015625,6.44296875 L12,10.95 L16.5984375,6.4453125 C16.89375,6.1546875 17.3695313,6.159375 17.6601563,6.45703125 L17.6601563,6.45703125 C17.9507813,6.75234375 17.9460938,7.228125 17.6484375,7.51875 L13.0710938,12 L17.6484375,16.4835937 L17.6484375,16.4835937 Z" fill="#AEACAE" fillRule="nonzero"></path>
              <path d="M17.6484375,16.4835937 C17.94375,16.7742187 17.9484375,17.2476562 17.6601563,17.5453125 L17.6601563,17.5453125 C17.3695313,17.840625 16.8960938,17.8453125 16.5984375,17.5570312 L12,13.05 L7.4015625,17.5546875 C7.10625,17.8453125 6.63046875,17.840625 6.33984375,17.5429688 L6.33984375,17.5429688 C6.04921875,17.2476562 6.05390625,16.771875 6.3515625,16.48125 L10.9289063,12 L6.3515625,7.51640625 C6.05625,7.22578125 6.0515625,6.75234375 6.33984375,6.4546875 L6.33984375,6.4546875 C6.63046875,6.159375 7.10390625,6.1546875 7.4015625,6.44296875 L12,10.95 L16.5984375,6.4453125 C16.89375,6.1546875 17.3695313,6.159375 17.6601563,6.45703125 L17.6601563,6.45703125 C17.9507813,6.75234375 17.9460938,7.228125 17.6484375,7.51875 L13.0710938,12 L17.6484375,16.4835937 L17.6484375,16.4835937 Z" id="Path" fill="#FFFFFF"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ErrorIcon;
