import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';


const RegistrationOptions = props => {
  const { t } = useTranslation()
  const { email, options } = props;
  return (
    <div className='registration-page-layout__options'>
      <p className='paragraph--small paragraph--charcoal m-b-16'>{email}</p>
      {options.map(({ text, isBtnDisabled, handleClick = () => {} }, idx) => (
        <React.Fragment key={text}>
          <button disabled={isBtnDisabled} className='registration__setup-btn' type='button' onClick={handleClick}>
            <p className='paragraph--large paragraph--bold'>{text}</p>
          </button>
          {idx < options.length - 1 && 
            <p className='paragraph--medium paragraph--charcoal paragraph--bold m-b-12 m-t-12'>{t('orLabel')}</p>}
        </React.Fragment>
      ))}
    </div>
  );
};

RegistrationOptions.propTypes = {
  options: PropTypes.array.isRequired,
  email: PropTypes.string
};

RegistrationOptions.defaultProps = {
  email: ''
}

export default RegistrationOptions;
