import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IN_PERSON_OFFICIAL, IN_PERSON_TEST } from 'shared/constants/eventTypes';
import InPersonContainer from './InPersonContainer';
import RemoteContainer from './RemoteContainer';
import { handleGetEventAllTeams } from 'features/EventContainer/redux/actions/teams';
import Pagination, { getPageFilter, initialPage } from 'shared/components/Pagination';

const ScoringContainer = (props) => {
  const {
    event_type,
    getEventAllTeams,
    eventId
  } = props;

  const fetchAllTeams = async () => {
    getEventAllTeams(eventId, getPageFilter(1, 1), '');
  }


  useEffect(() => {
    fetchAllTeams()
  }, [])

  const isInPerson = event_type === IN_PERSON_OFFICIAL || event_type === IN_PERSON_TEST;

  return isInPerson ? <InPersonContainer {...props} /> : <RemoteContainer {...props} />;
};


const mapDispatchToProps = {
  getEventAllTeams: handleGetEventAllTeams,
}


const mapStateToProps = (state) => {
  const { event: { eventDetails } } = state;

  const { attributes, id } = eventDetails;
  const { event_type } = attributes;
  return {
    event_type,
    eventId: id
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ScoringContainer);