import React from 'react';
import PropTypes from 'prop-types';
import './StatusBadge.scss';

const StatusBadge = props => {
  const {
    statusText,
    badgeType
  } = props;
  const className = `status-badge status-badge--${badgeType}`
  return (
    <div className='status-badge-container table-item-wrapper'>
      <span className={className} />
      <p className='m-l-8 paragraph--large paragraph--charcoal paragraph--normal'>{statusText}</p>
    </div>
  );
};

StatusBadge.propTypes = {
  statusText: PropTypes.string,
  badgeType: PropTypes.string
};

StatusBadge.defaultProps = {
  statusText: '',
  badgeType: ''
};

export default StatusBadge;