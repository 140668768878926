import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import BtnPrimary from 'shared/components/Button/BtnPrimary';
import PublishStatusBar from 'shared/components/PublishStatusBar';
import ListLoading from 'shared/components/Spinner/ListLoading';
import { PENDING, PUBLISHABLE, PUBLISHED } from 'shared/constants/publishStatus';
import {
  fetchEventAwardTeams,
  fetchEventAwards,
  submitEventAwards,
  fetchAllRanking,
  getOptionalAwardsNomination
} from 'features/EventOverviewContainer/redux/actions/assignedAwards';
import {fetchAssignedScoringSessionsOnAwardsPage} from 'features/EventOverviewContainer/redux/actions/getAssignedMatches';
import {
  JUDGE_ID, JUDGE_ADVISOR_ID
} from 'shared/constants/eventRoleTypes';
import {
  FLL_CHALLENGE_ID
} from 'shared/constants/programIds';
import { useTranslation } from 'react-i18next';
import AddAwardModal from './AddAwardModal';
import AwardConfirmModal from './AwardConfirmModal';

import '../../styles/EventOverviewAwards.scss';
import AwardTableContainer from './AwardTable';
import _ from 'lodash';
import {getAssignedJudgingSessionsForAwards} from 'features/EventOverviewContainer/redux/actions/getAssignedJudgingSessions';
import DownloadInstructions from './DownloadInstructions';
import AwardNominateCard from './AwardNominateCard';

const FLLCAssignedAwards = (props) => {
  const { canViewAwardTable, canEdit, canSubmit, status, awards,teams, isFetchingAwards, isSubmittingAwards, userEventRoleIds, eventProgramId, userId, eventScheduleId,  isFetchingRankData, isFetchingRubricObjects, isFetchingAssignedSessions, isFetchingAssignedMatches, eventTeams, is_FLLC_event} = props;
  const [visible, setVisible] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(false);
  const [canUnsubmit, setUnsubmit] = useState(false);
  const { eventId } = useParams();
  const { t } = useTranslation()

  const instructionsRoles = [JUDGE_ID, JUDGE_ADVISOR_ID];
  const canViewInstructions = (canEdit || instructionsRoles.some(i => userEventRoleIds.includes(i))) && (eventProgramId === FLL_CHALLENGE_ID);

  const pending = status === PENDING;
  const publishable = status === PUBLISHABLE;
  const published = status === PUBLISHED;

  const editable = canEdit && pending;

  const unSubProp = publishable && canEdit && { onClick: () => setUnsubmit(true) };
  const eventTeamsLength = eventTeams.length;

  useEffect(() => {
    if(canViewAwardTable){
      if(eventTeamsLength > 0){
        props.fetchEventAwardTeams(eventId);
        props.fetchEventAwards(eventId);
        props.fetchAllRanking(eventId)
      }
    }else{
      props.fetchEventAwardTeams(eventId);
      props.fetchEventAwards(eventId);
    }
  }, [eventId, eventTeamsLength, canViewAwardTable]);

  useEffect(() => {
    if (canViewAwardTable && eventScheduleId && userId && eventTeamsLength > 0) {
      props.getAssignedJudgingSessionsForAwards();
    }
  }, [eventScheduleId, userId, eventTeamsLength, canViewAwardTable]);

   useEffect(()=>{
     canViewAwardTable && eventTeamsLength > 0 && props.fetchAssignedMatches({}, {size: 500, number: 1});
   }, [eventScheduleId, eventTeamsLength, canViewAwardTable])

  const handleSubmitAward = async () => {
    await props.submitEventAwards(canUnsubmit);
    setUnsubmit(false);
    setSubmitVisible(false);
  };

  const clonedAwards = _.cloneDeep(awards);
  const clonedTeams = _.cloneDeep(teams);

  const isLoadingData = isFetchingAwards ||  isFetchingRankData || isFetchingRubricObjects || isFetchingAssignedSessions || isFetchingAssignedMatches;
  return (
    <div className='c-event-overview-awards'>
      <AddAwardModal
        visible={visible}
        onCancel={() => setVisible(false)}
      />
      <AwardConfirmModal
        is_FLLC_event={is_FLLC_event}
        isSubmitted={publishable}
        visible={submitVisible}
        onSubmit={handleSubmitAward}
        onCancel={() => setSubmitVisible(false)}
        loading={isSubmittingAwards}
      />
      <div className='c-event-overview-awards__actions'>
        <div className="awards-title">Awards</div>
        <PublishStatusBar
          published={(publishable || published) && !canUnsubmit}
          publishText={publishable ? t('awardsSubmittedLabel') : "Awards Published"}
          publishable={isFetchingAwards || canSubmit}
          {...unSubProp}
        />
        {(editable || canUnsubmit) && (
          <BtnPrimary disabled={isFetchingAwards || !canSubmit} onClick={() => setSubmitVisible(true)}>
            {canUnsubmit ? t('withdrawAwardsWarningLabel') : t('submitAwardsLabel')}
          </BtnPrimary>
        )}
      </div>
      {canViewInstructions ? (
        <DownloadInstructions eventId={eventId} is_FLLC_event={is_FLLC_event}/>
      ) : null}
      {canViewAwardTable ? <AwardTableContainer loading={isLoadingData} eventProgramId={eventProgramId} editable={editable} awards={clonedAwards} teams={clonedTeams}/> : 
        awards.map((award, i) => (<AwardNominateCard editable={editable} key={i} {...award}/>))
      }
    </div>
  );
};

const mapStateToProps = ({ eventOverview, assignedAwards, auth}) => {
  const {userId} = auth;
  const { is_FLLC_event, eventProgramId, userEventRoleIds, eventScheduleId, eventData: {relationships} } = eventOverview;
  const { awards,teams, status, meta: { isFetchingAwards, isSubmittingAwards }, isFetchingRankData, isFetchingRubricObjects, isFetchingJudgingSessions, assignedJudgingSessions, isFetchScoringSessions } = assignedAwards;
  const allAssignments = [];
  awards.forEach(({ assignments }) => {
    allAssignments.push(...assignments);
  });
  const canSubmit = allAssignments.length > 0 && !allAssignments.find(({ place }) => !place);

  return {
    is_FLLC_event,
    userId,
    eventScheduleId,
    eventProgramId,
    awards,
    status,
    canSubmit,
    isFetchingAwards,
    isSubmittingAwards,
    userEventRoleIds,
    teams,
    assignedSessions: assignedJudgingSessions,
    isFetchingRankData, 
    isFetchingRubricObjects,
    isFetchingAssignedSessions: isFetchingJudgingSessions,
    isFetchingAssignedMatches: isFetchScoringSessions,
    eventTeams: ((relationships||{}).teams||{}).data||[]
  };
};

const mapDispatchToProps = {
  fetchEventAwardTeams,
  fetchEventAwards,
  submitEventAwards,
  getAssignedJudgingSessionsForAwards,
  fetchAllRanking,
  getOptionalAwardsNomination,
  fetchAssignedMatches: fetchAssignedScoringSessionsOnAwardsPage
};

export default connect(mapStateToProps, mapDispatchToProps)(FLLCAssignedAwards);
