export const COACH_ADD_TO_LIST_BEGIN = 'COACH_ADD_TO_LIST_BEGIN';
export const COACH_ADD_TO_LIST_ERROR = 'COACH_ADD_TO_LIST_ERROR';
export const COACH_ADD_TO_LIST_SUCCESS = 'COACH_ADD_TO_LIST_SUCCESS';

export const COACH_INVITE_BEGIN = 'COACH_INVITE_BEGIN';
export const COACH_INVITE_ERROR = 'COACH_INVITE_ERROR';
export const COACH_INVITE_SUCCESS = 'COACH_INVITE_SUCCESS';

export const TEAM_INVITE_BEGIN = 'TEAM_INVITE_BEGIN';
export const TEAM_INVITE_ERROR = 'TEAM_INVITE_ERROR';
export const TEAM_INVITE_SUCCESS = 'TEAM_INVITE_SUCCESS';

export const DELETE_MANUAL_COACH_BEGIN = 'DELETE_MANUAL_COACH_BEGIN';
export const DELETE_MANUAL_COACH_SUCCESS = 'DELETE_MANUAL_COACH_SUCCESS';
export const DELETE_MANUAL_COACH_ERROR = 'DELETE_MANUAL_COACH_ERROR';

export const SET_SELECTED_COACH_BEGIN = 'SET_SELECTED_COACH_BEGIN';
export const SET_SELECTED_COACH_ERROR = 'SET_SELECTED_COACH_ERROR';
export const SET_SELECTED_COACH_SUCCESS = 'SET_SELECTED_COACH_SUCCESS';

export const SET_SELECTED_TEAM_FOR_COACH_BEGIN = 'SET_SELECTED_TEAM_FOR_COACH_BEGIN';
export const SET_SELECTED_TEAM_FOR_COACH_ERROR = 'SET_SELECTED_TEAM_FOR_COACH_ERROR';
export const SET_SELECTED_TEAM_FOR_COACH_SUCCESS = 'SET_SELECTED_TEAM_FOR_COACH_SUCCESS';

export const UPDATE_SELECTED_COACH = 'UPDATE_SELECTED_COACH';

export const SET_IS_ADD_TEAM_DISABLED = 'SET_IS_ADD_TEAM_DISABLED';


export const CREATE_NEW_TEAM_BEGIN = 'CREATE_NEW_TEAM_BEGIN';
export const CREATE_NEW_TEAM_SUCCESS = 'CREATE_NEW_TEAM_SUCCESS';
export const CREATE_NEW_TEAM_ERROR = 'CREATE_NEW_TEAM_ERROR';


export const UPDATE_TEAM_BEGIN = 'UPDATE_TEAM_BEGIN';
export const UPDATE_TEAM_ERROR = 'UPDATE_TEAM_ERROR';
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS';


export const RESET_SELECTED_COACH = 'RESET_SELECTED_COACH';
export const RESET_SELECTED_TEAM_FOR_COACH = 'RESET_SELECTED_TEAM_FOR_COACH';

export const RESET_MANUALLY_ADDED_COACHES_LIST = 'RESET_MANUALLY_ADDED_COACHES_LIST';



export const SET_SELECTED_COACH_IN_AUTOCOMPLETE = 'SET_SELECTED_COACH_IN_AUTOCOMPLETE';
export const RESET_SELECTED_COACH_IN_AUTOCOMPLETE = 'RESET_SELECTED_COACH_IN_AUTOCOMPLETE';

export const INVITE_COACHES_IMPORT_LIST_BEGIN = 'INVITE_COACHES_IMPORT_LIST_BEGIN';
export const INVITE_COACHES_IMPORT_LIST_ERROR = 'INVITE_COACHES_IMPORT_LIST_ERROR';
export const INVITE_COACHES_IMPORT_LIST_SUCCESS = 'INVITE_COACHES_IMPORT_LIST_SUCCESS';


export const GET_EVENT_TEAMS_BEGIN = 'GET_EVENT_TEAMS_BEGIN';
export const GET_EVENT_TEAMS_ERROR = 'GET_EVENT_TEAMS_ERROR';
export const GET_EVENT_TEAMS_SUCCESS = 'GET_EVENT_TEAMS_SUCCESS';
export const UPDATE_EVENT_TEAMS_ON_REMOVING_COACH = 'UPDATE_EVENT_TEAMS_ON_REMOVING_COACH';

export const GET_EVENT_ALL_TEAMS_BEGIN = 'GET_EVENT_ALL_TEAMS_BEGIN';
export const GET_EVENT_ALL_TEAMS_ERROR = 'GET_EVENT_ALL_TEAMS_ERROR';
export const GET_EVENT_ALL_TEAMS_SUCCESS = 'GET_EVENT_ALL_TEAMS_SUCCESS';
// export const UPDATE_EVENT_TEAMS_ON_REMOVING_COACH = 'UPDATE_EVENT_TEAMS_ON_REMOVING_COACH';


export const GET_EVENT_TEAMS_COUNTRIES_BEGIN = 'GET_EVENT_TEAMS_COUNTRIES_BEGIN'
export const GET_EVENT_TEAMS_COUNTRIES_ERROR = 'GET_EVENT_TEAMS_COUNTRIES_ERROR'
export const GET_EVENT_TEAMS_COUNTRIES_SUCCESS = 'GET_EVENT_TEAMS_COUNTRIES_SUCCESS'


export const GET_EVENT_TEAMS_REGIONS_BEGIN = 'GET_EVENT_TEAMS_REGIONS_BEGIN'
export const GET_EVENT_TEAMS_REGIONS_ERROR = 'GET_EVENT_TEAMS_REGIONS_ERROR'
export const GET_EVENT_TEAMS_REGIONS_SUCCESS = 'GET_EVENT_TEAMS_REGIONS_SUCCESS'

export const RESET_ADD_COACH_TEAM_MODAL = 'RESET_ADD_COACH_TEAM_MODAL';
export const RESET_ADD_COACH_ONLY_MODAL = 'RESET_ADD_COACH_ONLY_MODAL';
 
export const SET_COACHES_SUCCESSFULLY_INVITED = 'SET_COACHES_SUCCESSFULLY_INVITED';

export const RESET_COACHES_SUCCESSFULLY_INVITED_LIST = 'RESET_COACHES_SUCCESSFULLY_INVITED_LIST';


export const REINVITE_EVENT_COACH_USER_BEGIN = 'REINVITE_EVENT_COACH_USER_BEGIN';
export const REINVITE_EVENT_COACH_USER_ERROR = 'REINVITE_EVENT_COACH_USER_ERROR';
export const REINVITE_EVENT_COACH_USER_SUCCESS = 'REINVITE_EVENT_COACH_USER_SUCCESS';

export const DELETE_COACH_EVENT_ASSIGNMENT_BEGIN = 'DELETE_COACH_EVENT_ASSIGNMENT_BEGIN';
export const DELETE_COACH_EVENT_ASSIGNMENT_ERROR = 'DELETE_COACH_EVENT_ASSIGNMENT_ERROR';
export const DELETE_COACH_EVENT_ASSIGNMENT_SUCCESS = 'DELETE_COACH_EVENT_ASSIGNMENT_SUCCESS';

export const DELETE_EVENT_TEAM_BEGIN = 'DELETE_EVENT_TEAM_BEGIN';
export const DELETE_EVENT_TEAM_ERROR = 'DELETE_EVENT_TEAM_ERROR';
export const DELETE_EVENT_TEAM_SUCCESS = 'DELETE_EVENT_TEAM_SUCCESS';

export const EDIT_TEAM_COACH_INFO_BEGIN = 'EDIT_TEAM_COACH_INFO_BEGIN';
export const EDIT_TEAM_COACH_INFO_ERROR = 'EDIT_TEAM_COACH_INFO_ERROR';
export const EDIT_TEAM_COACH_INFO_SUCCESS = 'EDIT_TEAM_COACH_INFO_SUCCESS';

export const SET_SELECTED_TEAM_DETAILS = 'SET_SELECTED_TEAM_DETAILS';
export const SET_SELECTED_TEAM_DETAILS_RESET = 'SET_SELECTED_TEAM_DETAILS_RESET';

export const SET_ADDED_TEAM_LIST = 'SET_ADDED_TEAM_LIST';
export const SET_ADDED_TEAM_LIST_RESET = 'SET_ADDED_TEAM_LIST_RESET';
