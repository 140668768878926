import {
  HEAD_REFEREE_ID,
  REFEREE_ID,
  COACH_ID,
} from 'shared/constants/eventRoleTypes';

const COACHES_REFEREES = [
  HEAD_REFEREE_ID,
  REFEREE_ID,
  COACH_ID]

  export default [
    {
      description: 'Pre-Match Checklist',
      link: 'https://www.firstinspires.org/sites/default/files/uploads/resource_library/ftc/prematch-checklist.pdf',
      roles: [COACH_ID]
    },
    {
      description: 'Robot Self-Inspection Checklist',
      link: 'https://www.firstinspires.org/sites/default/files/uploads/resource_library/ftc/robot-inspection-checklist.pdf',
      roles: [COACH_ID]
    },
    {
      description: 'Field Reset Guide',
      link: 'https://www.firstinspires.org/resource-library/ftc/game-and-season-info',
      roles: COACHES_REFEREES
    },
    {
      description: 'Field Self-Inspection Checklist',
      link: 'https://www.firstinspires.org/sites/default/files/uploads/resource_library/ftc/field-inspection-checklist.pdf',
      roles: COACHES_REFEREES
    },
    {
      description: 'Navigation Targets US',
      link: 'https://www.firstinspires.org/resource-library/ftc/game-and-season-info',
      roles: COACHES_REFEREES
    },
    {
      description: 'Navigation Targets International',
      link: 'https://www.firstinspires.org/resource-library/ftc/game-and-season-info',
      roles: COACHES_REFEREES
    },
  ]