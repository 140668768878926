import { Button } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import CreateNewCoachForm from './CreateNewCoachForm';
import CreateNewTeamForm from './CreateNewTeamForm';
import * as teamService from 'utils/services/teams';
import * as userService from 'utils/services/users';
import TeamOption from './TeamOption';
import { debounce } from 'lodash';
import CoachOption from './CoachOption';
import FormAutocomplete from 'shared/components/FormAutoComplete';
import ErrorIcon from 'shared/components/Icon/icons/ErrorIcon';
import { handleInviteTeamAndCoaches } from 'features/EventContainer/redux/actions/teams/manually';
import { connect } from 'react-redux';
const { Option } = FormAutocomplete;

const DEFAULT_RESULT_SIZE = 10;
const DisableOverlay = (disabled) => (disabled ? <div className="disabled-overlay" /> : null)

const SearchOrCreateTeam = (props) => {
  const {onSelect, selectedTeam, count, setCount, withCoach, addedTeams } = props;
  const [teamResults, setTeamResults] = useState([]);
  const [value, setValue] = useState('');
  // 0 for initial state, 1 for create new team, -1 for search for team
  const [state, setState] = useState(0);
  const selectedIds = [...selectedTeam, ...addedTeams].map(t=>t.id);
  const disabledIds = selectedIds

  const handleCustomerSearch = useCallback(debounce(async (input) => {
    const formattedTeams = await teamService.getTeamsLikeName(input, DEFAULT_RESULT_SIZE);
    setTeamResults(formattedTeams);
  }, 300))

  const handleSelect = (teamId) => {
    const isSelected = disabledIds.includes(teamId);
    if(isSelected) return;
    const team = teamResults.find(t=>t.id == teamId);
    if(withCoach){
      team && onSelect && onSelect([team]);
    }else{
      team && onSelect && onSelect([...selectedTeam, team]);
    }
    setValue('');
  };

  const handleValueChange = (value) => {
    setValue(value);
  }


  if(state === 0) {
    return (
      <div className='add-teams-coaches-container__switcher'>
        <Button className={`outlined-btn`} onClick={()=>setState(-1)}>Search for team</Button>
        <div className='or-text'>Or</div>
        <Button className={`primary-btn`} onClick={()=>setState(1)}>Create new team</Button>
      </div>
    )
  }else if (state === -1){
    return (
      <div className='add-teams-coaches-container__search'>
        <FormAutocomplete
          value={value}
          onChange={handleValueChange}
          popUpContainerElementId='search-teams-autocomplete'
          allowClear
          optionLabelProp='text'
          placeholder={"Search for team"}
          onSearch={handleCustomerSearch}
          onSelect={(e)=>handleSelect(e)}
          dataSource={teamResults.map((tr) => (
            <Option key={tr.id} value={tr.id} text={tr.teamName} disabled={disabledIds.includes(tr.id)}>
              <TeamOption
                selected={selectedIds.includes(tr.id)}
                teamName={tr.teamName}
                teamNumber={tr.teamNumber}
                region={tr.regionName}
                country={tr.countryName}
              />
            </Option>))
          }
        />
        <Button type='link' onClick={()=>{setState(0);setValue('')}}>Cancel</Button>
      </div>
    )
  }else{
    return (
      <div className='add-teams-coaches-container__create'>
        <div className='create__title'>
          <div className='title'>Create new team:</div>
          <Button type='link' onClick={()=>setState(0)}>Cancel</Button>
        </div>
        <CreateNewTeamForm onSubmit={onSelect} selectedTeam={selectedTeam} count={count} setCount={setCount} withCoach={withCoach}/>
      </div>
    )
  }
}

const SearchOrCreateCoach = (props) => {
  const {disabled, onSelect, selectedCoaches, count, setCount} = props;
  const [coachResults, setCoachResults] = useState([]);
  const [value, setValue] = useState('');
  // 0 for initial state, 1 for create new coach, -1 for search for coach
  const [state, setState] = useState(0);

  useEffect(() => {
    if(disabled) {
      setState(0);
      setCoachResults([]);
      onSelect([]);
    }
  }, [disabled])

  const handleCoachSearch = useCallback(debounce(async (input) => {
    const formattedUsers = await userService.getUsersLikeEmail(input, DEFAULT_RESULT_SIZE);
    setCoachResults(formattedUsers)
  }, 300));

  const handleSelect = (coachId) => {
    const coach = coachResults.find(c=>c.id == coachId);
    coach && onSelect && onSelect([...selectedCoaches, coach]);
    setValue('')
  };

  const handleValueChange = (value) => {
    setValue(value);
  }

  const selectedIds = (props?.selectedCoaches || []).map(c=>c.id);
  const filteredCoaches = coachResults.filter(coach => !selectedIds.includes(coach.id));

  if(state === 0) {
    return (
      <div className='add-teams-coaches-container__switcher'>
        <Button className='outlined-btn' onClick={()=>setState(-1)}>Search for coach</Button>
        <div className='or-text'>Or</div>
        <Button className='primary-btn' type="primary" onClick={()=>setState(1)}>Create new coach</Button>
        {DisableOverlay(disabled)}
      </div>
    )
  }else if (state === -1){
    return (
      <div className='add-teams-coaches-container__search'>
        <FormAutocomplete
          value={value}
          onChange={handleValueChange}
          popUpContainerElementId='search-teams-autocomplete'
          allowClear
          optionLabelProp='text'
          placeholder={"Search for coach"}
          onSearch={handleCoachSearch}
          onSelect={(e)=>handleSelect(e)}
          dataSource={filteredCoaches.map((coach) => (
            <Option key={coach.id} value={coach.id} text={coach.email}>
              <CoachOption
                selected={coach.id === props?.selectedCoaches?.id}
                coach={coach}
              />
            </Option>))
          }
        />
        <Button type='link' onClick={()=>{setState(0);setValue('')}}>Cancel</Button>
      </div>
    )
  }else{
    return (
      <div className='add-teams-coaches-container__create'>
        <div className='create__title'>
          <div className='title'>Create new coach:</div>
          <Button type='link' onClick={()=>setState(0)}>Cancel</Button>
        </div>
        <CreateNewCoachForm onSubmit={onSelect} selectedCoaches={selectedCoaches} count={count} setCount={setCount}/>
      </div>
    )
  }
}

const ListItem = (props) => {
  const { text, onClick } = props;
  return (
    <div className='list-item'>
      <div className='text'>{text}</div>
      <ErrorIcon onClick={onClick} visible={true} size={18}/>
    </div>
  )
}

const ManualForm = (props) => {
  const { withCoach, emailLanguage, eventId, existingTeamIds } = props;
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectedCoaches, setSelectedCoaches] = useState([]);
  const [count, setCount] = useState(-1);

  useEffect(()=> {
    !withCoach && setSelectedCoaches([])
    !withCoach && setSelectedTeam([])
  }, [withCoach])

  const selectedId = selectedTeam[0]?.id;

  useEffect(()=>{
    setSelectedCoaches([]);
  }, [selectedId])

  const btnDisabled = selectedTeam.length == 0;

  const addDisabled = (withCoach && selectedCoaches.length == 0) || btnDisabled;


  const addTeamAndCoachesToEvent = () => {
    const callback = () => {
      setSelectedTeam([]);
      setSelectedCoaches([]);
    };
    const coachesWithLanguage = selectedCoaches.map(c=>{
      c.culture = emailLanguage;
      return c;
    })
    props.addTeamAndCoaches(selectedTeam, coachesWithLanguage, callback);
  }


  const onTeamRemove = (id) => {
    const newTeams = selectedTeam.filter(team=>team.id!=id);
    setSelectedTeam(newTeams);
  }

  return (
    <div className='manual-form'>
      <div className='manual-form-top-section'>
        <div className='colum-wrapper'>
          <SearchOrCreateTeam
            selectedTeam={selectedTeam}
            onSelect={setSelectedTeam}
            count={count}
            setCount={setCount}
            withCoach={withCoach}
            addedTeams={existingTeamIds}
          />
          <div className='manual-form-selection'>
            <div className='team-list'>
              {selectedTeam.map((team) => {
                const {countryName, regionName, teamName, teamNumber} = team || {};
                const displayedTeam = `${teamNumber?teamNumber:'-'} ${teamName?teamName:'-'}, ${regionName?regionName:'-'}, ${countryName?countryName:'-'}`;
                return (
                  <ListItem text={displayedTeam} onClick={()=>onTeamRemove(team.id)} count={count} key={team.id}/>
                )
              })}
            </div>
          </div>
        </div>
        <div className='colum-wrapper'>
          {withCoach && <SearchOrCreateCoach selectedCoaches={selectedCoaches} onSelect={setSelectedCoaches} disabled={btnDisabled} count={count} setCount={setCount}/>}
          <div className='manual-form-selection'>
            <div className='coach-list'>
              {(selectedCoaches.length > 0 && selectedCoaches.map(coach=>{
                const {firstName, lastName, email, phone} = coach || {};
                const displayedCoach = `${firstName ? firstName : '-'} ${lastName ? lastName : '-'}, ${email ? email : '-'}, ${phone ? phone : '-'}`;
                const onClick = () => {
                  const filteredCoaches = selectedCoaches.filter(c => c.id != coach.id);
                  setSelectedCoaches(filteredCoaches)
                }
                return <ListItem text={displayedCoach} onClick={onClick} />
              }) )}
            </div>
          </div>
        </div>
      </div>
      <div className='bottom-btn-wrapper'>
        <Button className={`add-primary-btn ${addDisabled?'disabled':''}`} disabled={addDisabled} onClick={addTeamAndCoachesToEvent}>Add</Button>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const {event: {eventTeams: {eventTeamsListAll, isFetchingEventAllTeams}, eventDetails: {id}}} = state;
  const existingTeamIds = eventTeamsListAll.map(t=>({id: t?.id}));
  return {eventId: id, existingTeamIds: existingTeamIds}
}

const mapDispatchToProps = {
  addTeamAndCoaches: handleInviteTeamAndCoaches
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualForm);