import React, {useEffect} from 'react';
import {Col, Row} from 'antd';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ListLoading from 'shared/components/Spinner/ListLoading';
import sortSessions from 'features/EventContainer/utils/sortSessions';
import {fetchScoringSessions} from 'features/EventContainer/redux/actions/scheduleActions/scheduleScoringSessions';
import EventSessionCard from '../sessionCards/EventSessionCard';
import { officialRefereeReviewSessionType, practiceRefereeReviewSessionType } from 'shared/constants/timestampTypes';
import { useTranslation } from 'react-i18next';

const ScoringSessions = (props) => {
  const {scheduleId, getScoringSessions, sessions} = props;
  const {t} = useTranslation();

  const {
    scoringSessions,
    errorScoringSessions,
    isLoadingScoringSessions
  } = sessions;
  

  useEffect(() => {
    getScoringSessions(scheduleId)
  }, [scheduleId])

  if (isLoadingScoringSessions) {
    return (
      <ListLoading loading text='Loading Scoring Sessions' />

    )
  }
  if (errorScoringSessions) {
    return <p>Error</p>
  }

  const closeModalCallback = () => {
    getScoringSessions(scheduleId)
  }

  return (
    <Row type='flex' gutter={[10, 10]}>
      {scoringSessions.sort(sortSessions).map(session => {
        const sessionTypeName = session.sessionTypeId === practiceRefereeReviewSessionType ? 
        t('practiceReviewLabel') : session.sessionTypeId === officialRefereeReviewSessionType ?
        t('officialReviewLabel') : session.sessionTypeName;
        return (
        <Col xs={24} sm={12} md={8} key={session.id}>
          <EventSessionCard
            hasSubtitle
            url={session.sessionUrl}
            sessionTypeName={sessionTypeName}
            cb={closeModalCallback}
            team={session.teamDetails}
            title={session.title}
            iconType='score'
            sessionType='scoring'
            sessionId={session.id}
            startTime={session.startTime}
            endTime={session.endTime}
            isJoinLinkDisabled={session.isJoinLinkDisabled}
          />
        </Col>
    )})}
    </Row>
  );
};

ScoringSessions.propTypes = {
  scheduleId: PropTypes.string.isRequired,
  getScoringSessions: PropTypes.func.isRequired,
  sessions: PropTypes.object.isRequired
}

ScoringSessions.defaultProps = {

}

const mapStateToProps = (state) => {
  const {event: {eventSchedule: {sessions}}} = state;
  return {
    sessions
  }
}

const mapDispatchToProps = {
  getScoringSessions: fetchScoringSessions
}
export default connect(mapStateToProps, mapDispatchToProps)(ScoringSessions);