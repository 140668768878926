import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from 'swiper';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getMatchFile } from 'utils/services/eventFiles';
import get from 'lodash/get'
import find from 'lodash/find'
import 'swiper/swiper-bundle.css';
import './styles/Carousel.scss';
import {fileStatuses} from 'shared/constants/fileStatuses';
import VideoPlayer from './videoPlayer';

const whiteTextClassName = 'paragraph--large paragraph--bold paragraph--white';


const textClassName = 'paragraph--xlarge paragraph--charcoal paragraph--400';

const DEFAULT_STATE = {
  formattedFiles: [],
  isLoadingFiles: false,
  isError: false
}
SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);

const Carousel = (props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const [formattedFiles, setFormattedFiles] = useState([]);
  const { files, matchId, idToken } = props;
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [state, setState] = useState(DEFAULT_STATE);

  useEffect(() => {
    let isMounted = true;

    const getMatchFiles = async () => {
      setState(prevState => ({ ...prevState, isLoading: true, isError: false }))
      const matchFilesList = [];
      try {
        for (let i = 0; i < files.length; i += 1) {
          const { eventFileId, status } = files[i];
          if (eventFileId && status === 'Available') {
            const response = await getMatchFile(matchId, eventFileId);
            const presignedUrlObject = response.included && find(response.included, res => res.type === 'pre-signed_url')
            const presignedUrl = get(presignedUrlObject, ['attributes', 'url'], '')
            matchFilesList.push({ ...files[i], src: presignedUrl, isError: !presignedUrl })
          } else {
            matchFilesList.push({...files[i]})
          }
        }

        if (isMounted) {
          setState(prevState => {
            return {
              ...prevState,
              formattedFiles: matchFilesList,
              isLoading: false,
              isError: false
            }
          })
        }
        setFormattedFiles(matchFilesList);

      } catch (err) {
        if (isMounted) {
          setState(prevState => {
            return { ...prevState, isLoading: false, isError: true }
          })
        }
        throw err;
      }
    }
    if (files.length) {
      getMatchFiles();
    }

    return () => { isMounted = false }

  }, [files])


  if (!files.length) return null;

  if (state.isLoading) {
    return (
      <div className='c-carousel__loading'>
        <ClipLoader size={36} color='#0066B3' />
      </div>
    )

  }
  const slides = formattedFiles.map((f, i) => {

    if (f.status !== 'Available') {
      const sliderStatus = fileStatuses.find(fileStatus => fileStatus.status === f.status) || {};
      
      return (
        <SwiperSlide key={f.eventFileId} tag="li">
          <div className='c-carousel__slide-no-content'>
            <p className={whiteTextClassName}>{sliderStatus.description || 'Error rendering the file.'}</p>
          </div>
        </SwiperSlide>
      )
    }

    if (f.isError) {
      return (
        <SwiperSlide key={f.eventFileId} tag="li">
          <div className='c-carousel__slide-no-content'>
            <p className={whiteTextClassName}>Error rendering file.</p>
          </div>
      
        </SwiperSlide>
      )
    }
    if (f.contentType.includes('video')) {

      return (
        <SwiperSlide key={f.eventFileId} tag="li">
          <div className='c-carousel__slide-content'>
            <VideoPlayer f={f}/>
          </div>

        </SwiperSlide>
      )
    } if (f.contentType.includes('image')) {
      return (
        <SwiperSlide key={f.eventFileId} tag="li">
          <div className='c-carousel__slide-content'>
            <img className='c-carousel__image' src={f.src} alt={f.contentType} />

          </div>

        </SwiperSlide>
      )
    } return (
      <SwiperSlide key={f.eventFileId} tag="li">
        <div className='c-carousel__slide-content'>
          <object
            data={f.src}
            type={f.contentType}
            aria-label={f.contentType}
            width="100%"
            height="100%"
            name={f.fileName}
          />
        </div>
      </SwiperSlide>
    )

  })

  return (
    <div className='c-carousel'>

      {formattedFiles.length ? (
        <div className='c-carousel__meta'>
          <p className={textClassName}>{formattedFiles[currentFileIndex].fileName}</p>
          <p className={textClassName}>{`${currentFileIndex + 1} of ${formattedFiles.length}`}</p>
        </div>
      ) : null}

      <Swiper
        id="main"
        thumbs={{ swiper: thumbsSwiper }}
        controller={{ control: controlledSwiper }}
        tag="section"
        wrapperTag="ul"
        navigation
        spaceBetween={0}
        slidesPerView={1}
        className='c-carousel__slider'
        onSlideChange={(swiper) => {
          setCurrentFileIndex(swiper.activeIndex);
        }}
      >
        {slides}
      </Swiper>
    </div>
  );
}
Carousel.propTypes = {
  files: PropTypes.array
}

Carousel.defaultProps = {
  files: []
}

const mapStateToProps = (state) => {
  const { auth: {
    idToken
  } } = state;

  return {
    idToken
  }
}
export default connect(mapStateToProps)(Carousel);