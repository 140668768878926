import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Skeleton, Tooltip, message, Form } from 'antd';
import BackLink from 'shared/components/BackLink';
import { useHistory } from 'react-router-dom';
import BtnPrimary from 'shared/components/Button/BtnPrimary';
import BtnSecondary from 'shared/components/Button/BtnSecondary';
import DetailedMatchScoreCard from 'shared/components/MatchScoreCard/DetailedMatchScoreCard';
import '../../styles/eventScoringMatch/EventScoringMatchHeader.scss';
import { connect } from 'react-redux';
import { handleNewScoreInput, updateScoreInput, handleRecalculateScore, handleCalculateScoresheetScore, resetScoreInput, handleSetIsScoresheetEdited } from 'features/EventOverviewContainer/redux/actions/matchScoresheet/scoreInput';
import matchStatuses from 'shared/constants/matchStatuses';
import SessionStatus from 'shared/components/SessionStatus';
import Container from 'shared/components/Container';
import MatchScoreButton from './MatchScoreButton';
import { useTranslation } from 'react-i18next';
import ConfirmModal from 'shared/components/ConfirmModal';
import Success from 'shared/components/Icon/icons/Success';
import { CURRENT_SCORE_INPUT_TYPE, SCORE_INPUT_TYPE_2025 } from 'shared/constants/scoreConstants';
import { notification} from 'antd';
import { CURRENT_EVENT_SEASON } from 'shared/constants/eventConstants';


const titleClassName = 'heading--charcoal h-4 h--bold m-b-12';

const boldClassName = 'paragraph--large paragraph--bold paragraph--charcoal'


const ScoringMatchHeader = props => {
  const [headerClassName, setHeaderClassName] = useState('event-scoring-match-header');
  const [uploadModalVisibility, setUploadModalVisibility] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isReset, setIsReset] = useState(false);

  const {
    form,
    withoutBacklink,
    eventId,
    isNewScoreInput,
    scoreInputId,
    matchTypeName,
    matchNumber,
    isFetchingMatch,
    teamDetails,
    overallScore,
    matchStatusId,
    matchStatusName,
    isPublished,
    isUpdatingScoresheet,
    isCreatingNewScoreInput,
    isRecalculatingScore,
    isScoresheetEdited,
    backAction,
    season,
    isRecalculateMatchScoreError,
    resetScoreInput
  } = props;

  const {
    teamName,
    teamNumber,
    regionName,
    countryName
  } = teamDetails

  const listenScrollEvent = event => {
    if (window.scrollY < 190) {
      return setHeaderClassName("event-scoring-match-header");
    } if (window.scrollY > 195) {
      return setHeaderClassName("event-scoring-match-header2");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);


  const teamNumberName = teamNumber ? `${teamNumber} - ${teamName}` : `${teamName}`;

  const sessionStatus = matchStatuses.find(s => s.statusId === matchStatusId) || {};
  const { badgeType, statusName } = sessionStatus;

  const { t } = useTranslation();
  const browserHistory = useHistory();

  if (isFetchingMatch) {
    return (
      <div className='event-scoring-match-header'>
        <Skeleton paragraph={{ rows: 3 }} />
      </div>
    );
  }
  const backClick = () => {
    if (withoutBacklink) {
      backAction && backAction();
    } else {
      browserHistory.push(`/event-overview/${eventId}#scoring-sessions`)
    }
  }

  const handleSaveAndExit = () => {
    form.validateFields()
        .then(async (values) => {
          if (Object.keys(values).length === 0) {
            console.log('Values Undefined');
            notification.error({
              message: <span>{t('errorLabel')}</span>,
              description: <span>Values Undefined</span>,
            });

            return
          }
        const payload = {
          data: {
            type: SCORE_INPUT_TYPE_2025,
            id: scoreInputId,
            attributes: values
          }
        }

        if (!validateFields(payload.data.attributes)) {
          notification.error({
            message: <span>{t('errorLabel')}</span>,
            description: <span>{t('scoresheetSaveAndExitErrorLabel')}</span>,
          });
          return
        }
        
        setIsReset(false);

        if (isNewScoreInput) {
          await props.handleNewScoreInput(payload, backClick)
        }

        if (!scoreInputId) {
          notification.error({
            message: 'Error',
            description: 'Match was not created properly, please contact your event administrator',
          });
          return
        }
        if (!isNewScoreInput) {
          const successMessage = 'Scoresheet successfully saved.'
          await props.updateScoreInput(payload, scoreInputId, successMessage, backClick)
        }
      }).catch((errorInfo) => {console.log(errorInfo)})
  }

  const handleSaveAndSubmit = () => {
    console.log('handleSaveAndSubmit hit')
    form.validateFields().then(async (values) => {
      const payload = {
        data: {
          type: SCORE_INPUT_TYPE_2025,
          id: scoreInputId,
          attributes: {
            ...values,
            is_published: true,
          }
        }
      }

      if (!validateFields(payload.data.attributes)) {
        notification.error({
          message: <span>{t('errorLabel')}</span>,
          description: <span>{t('scoresheetSaveAndSubmitErrorLabel')}</span>,
        });
        return
      }

      setIsReset(false);

      let createdScoreInputId = scoreInputId;
      if (!scoreInputId) {
        notification.error({
          message: 'Error',
          description: 'Match was not created properly, please contact your event administrator',
        });
        createdScoreInputId = await props.handleNewScoreInput(payload);
        return
      }
      const successMessage = 'Scoresheet successfully published.'
      await props.updateScoreInput(payload, createdScoreInputId, successMessage);
      setConfirmVisible(false)
    }).catch((errorInfo) => {console.log(errorInfo)})

  }

  const unPublishScoresheet = async () => {
    const payload = {
      data: {
        type: SCORE_INPUT_TYPE_2025,
        id: scoreInputId,
        attributes: {
          is_published: false,
        }
      }
    }
    const successMessage = 'Scoresheet successfully unpublished.'
    await props.updateScoreInput(payload, scoreInputId, successMessage);
  }

  const teamLocation = (regionName && countryName) ? `${regionName}, ${countryName}` : (regionName || countryName);



  //const recalculateInfo = () => {
  //  form.validateFieldsAndScroll({ scroll: { offsetTop: 500 } }, async (err, values) => {
  //    if (err) {
  //      return;
  //    }
  //    setUploadModalVisibility(true)
  //    const payload = {
  //      data: {
  //        // type: CURRENT_SCORE_INPUT_TYPE,
  //        type: season == CURRENT_EVENT_SEASON ? SCORE_INPUT_TYPE_2024 : CURRENT_SCORE_INPUT_TYPE,
  //        id: scoreInputId,
  //        attributes: values
  //      }
  //    }

  //    await props.handleRecalculateScore(payload, scoreInputId)
  //  })
  //}

  const formatValues = (v) => {
      const newValues = { ...v }
      if (newValues.m00_scoring_1 === null) { newValues.m00_scoring_1 = false }
      //if (newValues.m00_scoring_1 === "false") { newValues.m00_scoring_1 = false }
      //if (newValues.m00_scoring_1 === "true") { newValues.m00_scoring_1 = true }
      if (newValues.m01_scoring_1 === null) { newValues.m01_scoring_1 = false }
      if (newValues.m01_scoring_2 === null) { newValues.m01_scoring_2 = false }
      if (newValues.m01_scoring_3 === null) { newValues.m01_scoring_3 = false }
      if (newValues.m02_scoring_1 === null) { newValues.m02_scoring_1 = false }
      if (newValues.m02_scoring_2 === null) { newValues.m02_scoring_2 = false }
      if (newValues.m03_scoring_1 === null) { newValues.m03_scoring_1 = false }
      if (newValues.m03_scoring_2 === null) { newValues.m03_scoring_2 = 0 }
      if (newValues.m04_scoring_1 === null) { newValues.m04_scoring_1 = false }
      if (newValues.m04_scoring_2 === null) { newValues.m04_scoring_2 = false }
      if (newValues.m05_scoring_1 === null) { newValues.m05_scoring_1 = false }
      if (newValues.m06_scoring_1 === null) { newValues.m06_scoring_1 = false }
      if (newValues.m07_scoring_1 === null) { newValues.m07_scoring_1 = false }
      if (newValues.m08_scoring_1 === null) { newValues.m08_scoring_1 = 0 }
      if (newValues.m09_scoring_1 === null) { newValues.m09_scoring_1 = false }
      if (newValues.m09_scoring_2 === null) { newValues.m09_scoring_2 = false }
      if (newValues.m10_scoring_1 === null) { newValues.m10_scoring_1 = false }
      if (newValues.m10_scoring_2 === null) { newValues.m10_scoring_2 = false }
      if (newValues.m11_scoring_1 === null) { newValues.m11_scoring_1 = 0 }
      if (newValues.m12_scoring_1 === null) { newValues.m12_scoring_1 = 0 }
      if (newValues.m13_scoring_1 === null) { newValues.m13_scoring_1 = false }
      if (newValues.m14_scoring_1 === null) { newValues.m14_scoring_1 = false }
      if (newValues.m14_scoring_2 === null) { newValues.m14_scoring_2 = false }
      if (newValues.m14_scoring_3 === null) { newValues.m14_scoring_3 = false }
      if (newValues.m14_scoring_4 === null) { newValues.m14_scoring_4 = 0 }
      if (newValues.m15_scoring_1 === null) { newValues.m15_scoring_1 = 0 }
      if (newValues.m15_scoring_2 === null) { newValues.m15_scoring_2 = false }
      if (newValues.precision_tokens === null) { newValues.precision_tokens = 0 }
      if (newValues.precision_tokens === null) { newValues.precision_tokens = 0 }


    return newValues;
  }


    const recalculateInfo = () => {
      form.validateFields().then(async (values) => {
      values = formatValues(values);
      if (!validateFields(values)) {
        notification.error({
          message: <span>{t('errorLabel')}</span>,
          description: <span>{t('scoresheetCalculateScoreErrorLabel')}</span>,
        });
        return;
      }

      setUploadModalVisibility(true)
      await props.handleCalculateScoresheetScore(values);

      if (!isRecalculateMatchScoreError) {
        setIsReset(false);
      }
    }).catch((errorInfo) => {console.log(errorInfo)})
  }

  const publishButton = (isPublished ? (
    <BtnPrimary loading={isUpdatingScoresheet || isCreatingNewScoreInput} onClick={unPublishScoresheet}>{t('unpublishScoresheetLabel')}</BtnPrimary>
  ) : (
    <BtnPrimary loading={isUpdatingScoresheet || isCreatingNewScoreInput} onClick={handleSaveAndSubmit}>{t('publishScoresheetLabel')}</BtnPrimary>
  ))

  const tooltipText = (text) => (
    <div className='event-scoring-match-header__button-tooltip'>
      <span>{text}</span>
    </div>
  )


  const resetForm = () => {
    form.resetFields();
    setIsReset(true);
    props.resetScoreInput();
  }

  const validateFields = (values) => {
    console.log(values);
    if (values.m00_scoring_1 === undefined ||
      values.m01_scoring_1 === undefined ||
      values.m01_scoring_2 === undefined ||
      values.m01_scoring_3 === undefined ||
      values.m02_scoring_1 === undefined ||
      values.m02_scoring_2 === undefined ||
      values.m03_scoring_1 === undefined ||
      values.m03_scoring_2 === undefined ||
      values.m04_scoring_1 === undefined ||
      values.m04_scoring_2 === undefined ||
      values.m05_scoring_1 === undefined ||
      values.m06_scoring_1 === undefined ||
      values.m07_scoring_1 === undefined ||
      values.m08_scoring_1 === undefined ||
      values.m09_scoring_1 === undefined ||
      values.m09_scoring_2 === undefined ||
      values.m10_scoring_1 === undefined ||
      values.m10_scoring_2 === undefined ||
      values.m11_scoring_1 === undefined ||
      values.m12_scoring_1 === undefined ||
      values.m13_scoring_1 === undefined ||
      values.m14_scoring_1 === undefined ||
      values.m14_scoring_2 === undefined ||
      values.m14_scoring_3 === undefined ||
      values.m14_scoring_4 === undefined ||
      values.m15_scoring_1 === undefined ||
      values.m15_scoring_2 === undefined ||
      values.precision_tokens === undefined 
      ){
      return false;
    }
    if (values.m00_scoring_1 === null ||
      values.m01_scoring_1 === null ||
      values.m01_scoring_2 === null ||
      values.m01_scoring_3 === null ||
      values.m02_scoring_1 === null ||
      values.m02_scoring_2 === null ||
      values.m03_scoring_1 === null ||
      values.m03_scoring_2 === null ||
      values.m04_scoring_1 === null ||
      values.m04_scoring_2 === null ||
      values.m05_scoring_1 === null ||
      values.m06_scoring_1 === null ||
      values.m07_scoring_1 === null ||
      values.m08_scoring_1 === null ||
      values.m09_scoring_1 === null ||
      values.m09_scoring_2 === null ||
      values.m10_scoring_1 === null ||
      values.m10_scoring_2 === null ||
      values.m11_scoring_1 === null ||
      values.m12_scoring_1 === null ||
      values.m13_scoring_1 === null ||
      values.m14_scoring_1 === null ||
      values.m14_scoring_2 === null ||
      values.m14_scoring_3 === null ||
      values.m14_scoring_4 === null ||
      values.m15_scoring_1 === null ||
      values.m15_scoring_2 === null ||
      values.precision_tokens === null
        ){
      return false;
    }
    return true;
  }

  return (
    (<div className={`${headerClassName}__wrapper`}>
      <ConfirmModal
        visible={confirmVisible}
        onCancel={() => { setConfirmVisible(false) }}
        onSubmit={handleSaveAndSubmit}
        title={t('publishScoresheetLabel')}
        description={t('confirmationtTextLabel')}
      />
      <Modal
        wrapClassName='coach-judging-upload-modal'
        open={uploadModalVisibility}
        onCancel={() => setUploadModalVisibility(false)}
        closable={!isRecalculatingScore}
        destroyOnClose
        maskClosable={false}
        footer={null}
        bodyStyle={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '54px',
        }}
      >
        <div>
          {isRecalculatingScore && (
            <p className={boldClassName}>{t('Recalculating score...')}</p>
          )}
          {(!isRecalculatingScore && isRecalculateMatchScoreError) && (
            <p className={boldClassName}>{t('scoresheetCalculateScoreErrorLabel')}</p>
          )}
          {(!isRecalculatingScore && !isRecalculateMatchScoreError) && (
            <p className={boldClassName}>{t('Score recalculated.')}</p>
          )}
        </div>
      </Modal>
      <Container additionalclassname={headerClassName}>
        <div className={`event-scoring-match-header__nav ${withoutBacklink ? 'end' : ''}`}>
          {withoutBacklink ? null : <BackLink backClick={backClick} text='Assigned Matches for this Event' />}
          <div className='event-scoring-match-header__btn-wrapper'>
            <span className='event-scoring-match-header__exit-btn'>
              {
                isPublished ?
                  <Tooltip overlayClassName="event-scoring-match-header-tooltip" placement="top" title={tooltipText("Use this to unpublish the scoresheet. It will need to be re-published after changes have been made")}>
                    <div>
                      <BtnSecondary loading={isUpdatingScoresheet || isCreatingNewScoreInput} onClick={unPublishScoresheet}>{t('unpublishScoresheetLabel')}</BtnSecondary>
                    </div>
                  </Tooltip>
                  :
                  <BtnSecondary disabled={isPublished} loading={isUpdatingScoresheet || isCreatingNewScoreInput} onClick={handleSaveAndExit}>{t('saveAndExitLabel')}</BtnSecondary>
              }
            </span>
            {
              isPublished ?
                <div className="event-scoring-match-header__sumitted">
                  <Success />
                  <span>Submitted</span>
                </div>
                :
                <Tooltip overlayClassName="event-scoring-match-header-tooltip" placement="top" title={tooltipText("Publishing Scoresheet means that teams will be able to see their totals.")}>
                  <div>
                    <BtnPrimary loading={isUpdatingScoresheet || isCreatingNewScoreInput} onClick={() => setConfirmVisible(true)}>{t('publishScoresheetLabel')}</BtnPrimary>
                  </div>
                </Tooltip>
            }
          </div>
        </div>
        <div className='event-scoring-match-header__title'>
          <div className='m-b-12'>
            <h1 className={titleClassName}>
              {`${matchTypeName} ${matchNumber}`}
              :
              {' '}
              {teamNumberName}
            </h1>
            <div>
              <SessionStatus badgeType={badgeType} statusText={t(statusName)} />
            </div>
          </div>
          <div className='start-over-button'>
            <BtnSecondary disabled={isPublished} _props={{ ghost: true }} onClick={resetForm}>Start Over</BtnSecondary>
          </div>
          <MatchScoreButton
            score={overallScore}
            disabled={!isReset && (!isScoresheetEdited || isPublished)}
            onRecalculateClick={recalculateInfo}
          />
        </div>
      </Container>
    </div>)
  );
};

ScoringMatchHeader.propTypes = {
  form: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
  handleNewScoreInput: PropTypes.func.isRequired,
  isNewScoreInput: PropTypes.bool.isRequired,
  scoreInputId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateScoreInput: PropTypes.func.isRequired,
  matchTypeName: PropTypes.string.isRequired,
  matchNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  overallScore: PropTypes.number,
  teamDetails: PropTypes.object.isRequired,
  isUpdatingScoresheet: PropTypes.bool.isRequired,
  isCreatingNewScoreInput: PropTypes.bool.isRequired,
  //handleRecalculateScore: PropTypes.func.isRequired,
};

ScoringMatchHeader.defaultProps = {
  scoreInputId: null,
  overallScore: 0
}

const mapDispatchToProps = {
  handleNewScoreInput,
  updateScoreInput,
  handleRecalculateScore,
  handleCalculateScoresheetScore,
  resetScoreInput
}

const mapStateToProps = (state) => {
  const {
    matchScoresheet: { eventDetails: { season }, isScoresheetEdited, isCreatingNewScoreInput, isUpdatingMatchScoresheet, isMatchScoresheetPublished, isNewScoreInput, scoreInputId, isFetchingMatch, matchDetails, teamDetails, overallScore, isRecalculatingScore, isRecalculateMatchScoreError }
  } = state;
  const {
    matchStatusName,
    matchTypeName,
    matchNumber,
    matchTypeId,
    matchStatusId,
    matchId,
  } = matchDetails;
  return {
    isNewScoreInput,
    scoreInputId,
    matchTypeName,
    matchNumber,
    isFetchingMatch,
    teamDetails,
    overallScore,
    matchStatusId,
    matchStatusName,
    isCreatingNewScoreInput,
    isPublished: isMatchScoresheetPublished,
    isUpdatingScoresheet: isUpdatingMatchScoresheet,
    isRecalculatingScore,
    isScoresheetEdited,
    matchId,
    season,
    isRecalculateMatchScoreError
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScoringMatchHeader);