import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Pagination, { getPageFilter, initialPage } from 'shared/components/Pagination';
import TitleSortRow from 'shared/components/TitleSortRow';
import UsersList from 'shared/components/CardsList/Users';
import AddModal from 'shared/components/Modal';
import FirstText from 'shared/components/FirstText';
import { fetchFirstAdmins, resendUserInvitation, handleSelectEditFirstUser, handleReselectEditFirstUser } from 'features/AdminDashboard/redux/actions/usersAction';
import SearchBar from 'features/AdminDashboard/components/SearchBar';
import AddFirstUser from './AddFirstUser';
import EditFirstUser from './EditUserModal';

import '../../styles/AdminDashboard.scss';
import { firstUsersSortBy } from 'shared/constants/sortOptions';

const UsersContainer = props => {
  const { firstUsers, total, isFetchingUsers, newUserInvited, reinviteFirstUser, selectUser, resetSelectedUser } = props;
  const { t } = useTranslation();
  const [current, setCurrentPage] = useState(initialPage);
  const [searchText, setSearchText] = useState('');
  const [visible, setVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [sortBy, setSortBy] = useState('');

  const fetchPageUsers = useCallback((number, nosearch) => {
    const page = getPageFilter(number);
    props.fetchFirstAdmins(page, !nosearch && searchText, sortBy);
  }, [searchText, sortBy]);

  const handleSearch = (nosearch) => {
    setCurrentPage(pre => {
      if (pre === initialPage && searchText) {
        fetchPageUsers(initialPage, nosearch);
      }
      return initialPage;
    });
  };

  useEffect(() => {
    fetchPageUsers(current);
  }, [current, sortBy]);

  useEffect(() => {
    if (newUserInvited) {
      setCurrentPage(pre => {
        if (pre === initialPage) {
          fetchPageUsers(initialPage);
        }
        return initialPage;
      });
    }
  }, [newUserInvited]);

  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  let firstUsersList = [];
  firstUsersList = firstUsers.map(u => {
    return {
      ...u,
      handleReinviteClick: () => { reinviteFirstUser(u.id)}
    }
  })

  const closeEditModal = () => {
    resetSelectedUser();
    setEditModalVisible(false)
  }

  const openEditModal = (id) => {
    const selectedUser = firstUsersList.find(u => u.id === id);
    if (selectedUser) {
      selectUser(selectedUser)
    }
    setEditModalVisible(true)
  }

  const onSortBySelected=(value)=>{
    setSortBy(value);
  }

  return (
    <div className='admin-dashboard'>
      <AddModal
        fullTabs
        visible={visible}
        onCancel={closeModal}
        renderHeader={() => (
          <span>
            Add
            {' '}
            <FirstText />
            {' '}
            User  
          </span>
        )}
      >
        <AddFirstUser />
      </AddModal>

      <EditFirstUser
        visible={editModalVisible}
        onCancel={closeEditModal}
      />

      <SearchBar
        isLinkVisible
        placeholder='User Name'
        customText={t('addUserLabel')}
        addClick={openModal}
        searchText={searchText}
        setSearchText={setSearchText}
        handleSearch={handleSearch}
      />
      <div className='admin-dashboard__list'>
        <TitleSortRow
          title={t('usersLabel')}
          hasLogo
          options={firstUsersSortBy}
          onSelect={onSortBySelected}
          logoComponent={
            <img className='admin-dashboard__logo' src='/FIRST_Horz_RGB.png' alt='FIRST logo' />
          }
        />
        <UsersList isEditButtonVisible users={firstUsersList} loading={isFetchingUsers} onEditClick={openEditModal} />
        <Pagination
          total={total}
          current={current}
          onChange={setCurrentPage}
          loading={isFetchingUsers}
        />
      </div>
    </div>
  );
};

UsersContainer.propTypes = {
  firstUsers: PropTypes.array.isRequired,
  reinviteFirstUser: PropTypes.func.isRequired,
  selectUser: PropTypes.func.isRequired,
  resetSelectedUser: PropTypes.func.isRequired
};

const mapStateToProps = ({ adminBoard }) => {
  const { users: { firstUsers, total, isFetchingUsers, newUserInvited } } = adminBoard;

  return {
    firstUsers,
    total,
    isFetchingUsers,
    newUserInvited
  };
};

const mapDispatchToProps = {
  fetchFirstAdmins,
  reinviteFirstUser: resendUserInvitation,
  selectUser: handleSelectEditFirstUser,
  resetSelectedUser: handleReselectEditFirstUser
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);
