export const formatNewUserPayload = (user, organizationId) => {
  const data = {
    type: 'user',
    id: null,
    attributes: {
      first_name: user.firstName,
      last_name: user.lastName,
      mobile_phone: user.phone,
      email: user.email,
      user_type: user.user_type || 'User',
      culture: user.culture
    }
  };
  if (organizationId) {
    data.relationships = {
      organizations: {
        data: [{
          type: 'organization',
          id: organizationId
        }]
      }
    };
  }
  return {
    data
  };
};

export const formatPatchUserPayload = (id, attributes) => {
  return {
    data: {
      id,
      type: 'user',
      attributes
    }
  };
};

export const formatPatchPartnerAdminPayload = (id, attributes, organizations) => {
  const data = {
    id,
    type: 'user',
    attributes
  }
  if (organizations.length) {
    const relationships = {
      organizations: {
        data: organizations.map(organizationId => ({
          type: 'organization',
          id: organizationId
        }))
      }
    };
    data.relationships = relationships;
  }
  return {
    data
  }
}

export const formatNewAccountPayload = ({ userId, password, code }) => {
  return {
    data: {
      type: 'create_user',
      attributes: {
        user_id: userId,
        password,
        verification_code: code
      }
    }
  };
};

export const formatAssignRolePayload = ({ userId, code }) => {
  return {
    data: {
      type: 'assign_role',
      attributes: {
        user_id: userId,
        verification_code: code
      }
    }
  };
};

export const formatVerifyInvitePayload = ({ email, code }) => {
  return {
    data: {
      type: 'verify_invitation',
      attributes: {
        email,
        verification_code: code
      }
    }
  };
};

export const formatSendResetPayload = ({ email }) => {
  return {
    data: {
      type: 'send_reset_password_email',
      attributes: {
        email
      }
    }
  };
};

export const formatChangePassPayload = ({ userId, password, code }) => {
  return {
    data: {
      type: 'change_password',
      attributes: {
        user_id: userId,
        password,
        verification_code: code
      }
    }
  };
};

export const formatUserResData = (data) => {
  const { id, attributes, relationships } = data;
  const { organizations } = relationships;
  const userHasOrganizations = (organizations && organizations.data && Array.isArray(organizations.data));
  const organizationIds = userHasOrganizations ? organizations.data.map(o => o.id) : [];
  return {
    id,
    userId: id,
    email: attributes.email,
    firstName: attributes.first_name,
    lastName: attributes.last_name,
    phone: attributes.mobile_phone,
    userType: attributes.user_type,
    hasAcceptedTerms: attributes.has_accepted_terms,
    organizationIds,
    userIsActive: attributes.is_active,
    userStatus: attributes.status,
    userVerificationCodeTimeout: attributes.verification_code_timeout,
    use_24_hour_format: attributes.use_24_hour_format
  };
};

export const formatResendUserInvitation = (userId) => {
  const payload = {
    data: {
      type: 'resend_expired_emails',
      relationships: {
        users: {
          data: [{
            type: 'user',
            id: userId
          }]
        }
      }
    }
  }
  return payload;
}