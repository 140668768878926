import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputNumber, Row } from 'antd';
import { Button, Select, Tooltip } from 'antd';
import AddLink from 'shared/components/TopBar/AddLink';
import BtnPrimary from 'shared/components/Button/BtnPrimary';
import JudgesList from 'features/EventContainer/components/JudgingContainer/JudgesListContainer';
import Instructions from 'shared/components/Instructions';
import handleReferees from 'features/EventContainer/redux/actions/scoringActions/getReferees';
import { connect } from 'react-redux';
import ListLoading from 'shared/components/Spinner/ListLoading';
import { handleGetScoringPanelsNew as fetchScoringPanels, autoAssignPanels, handleExpands } from 'features/EventContainer/redux/actions/scoringActions/getScoringPanels';
import handleCreateNewPanel from 'features/EventContainer/redux/actions/scoringActions/createNewScoringPanel';
import ConfirmationModal from 'shared/components/ConfirmModal';
import PanelModal from './AddScoringPanelModal';
import { notification } from 'antd';
import Icon from 'shared/components/Icon';
import PanelsContainer from './PanelsContainer';
import '../../styles/JudgingContainer.scss';
import { useTranslation } from 'react-i18next';
import * as panelService from 'utils/services/panels';
import BtnSecondary from 'shared/components/Button/BtnSecondary';
import LinkButton from 'shared/components/Button/BtnSecondary';
import { createNew } from 'features/EventContainer/redux/actions/scoringActions/createNewScoringPanel';
// import Tooltip from 'shared/components/Tooltip';

import { FLL_CHALLENGE_ID } from 'shared/constants/programIds';
import { REFEREE_REVIEW_PANEL_TYPE_ID } from 'shared/constants/panelTypes';
const { Option } = Select;

const MAX_NUMBER_OF_PANELS = 64;
const ScoringContainer = (props) => {
  const {
    panelType,
    eventId,
    eventScoring,
    eventScheduleId,
    getScoringPanels,
    event_name,
    included,
    addNew,
    createNewScoringPanel,
    manageExpands,
    eventProgramId
  } = props;

  const {
    isFetchingScoringPanels,
    scoringSessionTeams,
    formattedPanels, refereeOptions, sessionOptions, isCreatingNewRefereePanel
  } = eventScoring;

  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [autoAssigning, setAutoAssigning] = useState(false);
  const [showAutoAssignConfirmModal, setShowAutoAssignConfirmModal] = useState(false);
  const [panelNumber, setPanelNumber] = useState(0)
  const [selectedPanels, setSelectedPanels] = useState([]);

  const expandAllRecord = () => {
    manageExpands([])

  }

  const collapseAllRecord = () => {
    let ids = formattedPanels.map(panel => panel.panelId)
    manageExpands(ids)
  }

  const createTables = async () => {
    await createNewScoringPanel(
      {
        panel_type_id: REFEREE_REVIEW_PANEL_TYPE_ID,
        desired_number_of_panels: panelNumber
      }, successCb)
  }

  const successCb = async () => {
    getScoringPanels();
  }

  const deleteAllPanels = async () => {
    setPanelNumber(0)
    formattedPanels.forEach(async (panel) => {
      panelService.deletePanelByID(panel.panelId)
    })

    getScoringPanels()
  }

  const handlePanelSelect = (e) => {
    setSelectedPanels(e)
  }

  let filterData = () => {
    // return []
    if (selectedPanels.length == 0) {
      return formattedPanels;
    }

    let filter = []
    let data = [...formattedPanels]
    data.forEach((item) => {
      selectedPanels.forEach((panel) => {
        if (item.panelId == panel) {
          filter.push(item);
        }
      })
    });

    return filter
  }

  let filteredData = filterData();

  const openModal = () => {
    setVisible(true);
  }

  const closeModal = () => {
    setVisible(false);
  }

  useEffect(() => {

    if (eventScheduleId) {
      getScoringPanels(eventScheduleId);
    }
  }, [eventId, eventScheduleId])

  if (isFetchingScoringPanels) {
    return (
      <ListLoading loading text='Loading Scoring' />
    );
  }

  const createPanelCb = () => {
    getScoringPanels(eventScheduleId);
    closeModal();
  }

  const generateNewPanelName = (panels) => {

    if (panels.length == 0) {
      return 1
    }

    let panelNumbres = [];
    panels.forEach(panel => {
      if (panel && panel.panelName) {
        panelNumbres.push(parseInt(panel.panelName.replace(/\D/g, "")))
      }
    });

    return Math.max(...panelNumbres) + 1;

  }

  const currentPanelCount = formattedPanels.length;

  const isSessionAvailable = (data) => {
    return data.panelSessions.length > 0
  }


  const handleAutoAssign = () => {
    let isTeamsAssigned = formattedPanels.some(isSessionAvailable)

    if (isTeamsAssigned) {
      setShowAutoAssignConfirmModal(true);
    } else {
      autoAssign();
    }
  }

  const totalTeams = () => {
    // let teams = included.filter((data) => data.type == 'team')
    return included.length
  }

  const totalPanels = () => {
    return formattedPanels.length
  }

  const autoAssign = async () => {
    try {
      setAutoAssigning(true)
      let assignments = await autoAssignPanels(eventScheduleId)
      getScoringPanels(eventScheduleId);
      setAutoAssigning(false)
      // notification.success({
      //   message: 'Success',
      //   description: assignments,
      // });
      notification.success({
        message: 'Success',
        description: 'Teams were assigned successfully',
      });
    } catch (error) {
      console.log(error);
      setAutoAssigning(false)

      notification.error({
        message: 'Error',
        description: 'Teams weren’t assigned successfully',
      });
    }
  }

  const newPanelTitle = `Table ${generateNewPanelName(formattedPanels)}`;
  // const newPanelTitle = `Panel ${currentPanelCount + 1}`;

  const titleText = t('addRefereePanelLabel');
  const title1 = t('refereesLabel')
  const currentPanelNumber = formattedPanels?.length ? formattedPanels?.length : 0;
  const maxPanelsNumber = MAX_NUMBER_OF_PANELS - currentPanelNumber ;


  return (

    <div className="event-judging-container">
      <PanelModal
        isEdit={false}
        visible={visible}
        newPanelTitle={newPanelTitle}
        onCancel={closeModal}
        panelTitle={titleText}
        roleType={title1}
        panelSubtitle={t('')}
        sessionOptions={scoringSessionTeams}
        toolTipInfo={t('refereePanelInstructionLabel')}
      />

      <div className="panel-management-container__add">
        <div className="title">{t('scoringLabel')}</div>

        {/* {eventProgramId == FLL_CHALLENGE_ID && ( */}
          <div className='add-table'>
            <div className='m-r-10'>{t('addRefereePanelLabel')}</div>
            <div>
              <InputNumber
                className="m-r-20 panel-add-button-size"
                style={{ width: 60 }}
                min={0}
                max={maxPanelsNumber}
                defaultValue={0}
                value={panelNumber}
                onChange={setPanelNumber}>
              </InputNumber>
            </div>
            <div>
              <BtnSecondary
                type='default'
                styleType='secondary'
                size='large'
                // additionalClassNames='p-r-14 p-l-14'
                onClick={() => { createTables() }}
                // disabled={btnDisabled || isInvitingCoach}
                disabled={(panelNumber > 0 && panelNumber <= maxPanelsNumber) ? false : true}
              >
                {`${t('Add')}`}
              </BtnSecondary>
            </div>
          </div>
        {/* )} */}

        <div className='d-flex'>
          {/* <AddLink disabled={isCreatingNewRefereePanel} className="m-r-10" addClick={() => { createTables() }} color="primaryBlue" customText={'Add Table'} /> */}
          {/* {eventProgramId == FLL_CHALLENGE_ID ? (
            formattedPanels.length > 0 && (
              <BtnSecondary
                type='default'
                styleType='secondary'
                // className="m-r-10"
                size='large'
                additionalClassNames='m-r-10'
                onClick={() => { deleteAllPanels() }}
              // disabled={btnDisabled || isInvitingCoach}
              >
                {`${t('2022DeletePanelLabel')}`}
              </BtnSecondary>
            )
          ) : (
            <AddLink className="m-r-10" addClick={openModal} color="primaryBlue" customText={'Add Table'} />
          )} */}

          {/* <Tooltip title={t('asdasd')}> */}
            <BtnPrimary
              onClick={() => { handleAutoAssign() }}
              disabled={(formattedPanels && formattedPanels.length > 0 && totalTeams() > 0) ? false : true}
              loading={autoAssigning}
              additionalClassNames='add-event-modal__btn'>{t('AutoAssignLabel')}</BtnPrimary>
          {/* </Tooltip> */}
        </div>
      </div>
      <div className="scoring-sessions-wrapper">
        <div className='judge-list-wrapper'>
          <JudgesList
            judges={refereeOptions}
            panelType={panelType}
            judgesListTitle={title1}
          />
        </div>
        <Row type="flex" className='panels'>
          <Instructions text={t('refereePanelInstructionText')}
          />

          {eventProgramId == FLL_CHALLENGE_ID && (
            formattedPanels.length > 0 ? (
              <div className='filter-area m-t-30'>
                <LinkButton className="first-button" type="link" onClick={collapseAllRecord}>{t('CollapseAllLabel')}</LinkButton>
                <LinkButton className="first-button m-r-20" type="link" onClick={expandAllRecord}>{t('ExpandAllLabel')}</LinkButton>
                <div>
                  <Select
                    style={{ width: 200 }}
                    // defaultValue={0}
                    placeholder="Show All"
                    mode="multiple"
                    showArrow
                    className='m-r-20 judging-modal-judges-tab__add-select'
                    onChange={(e) => { handlePanelSelect(e) }} suffixIcon={<Icon type='downArrowBlue' />}>

                    {/* {formattedPanels.map(option => { */}
                    {formattedPanels.map(option => {
                      return (
                        <Option
                          value={option.panelId}
                          key={option.panelId}
                        >
                          {option.panelName}
                        </Option>)
                    })}
                  </Select>
                </div>
              </div>
            ) : (
              <div className='empty-panels m-t-20'>
                <div className='empty-panel-text'>{t('EmptyScoringPageLabel')}</div>
              </div>
            )
          )}


          <PanelsContainer
            panels={filteredData}
            eventSessions={sessionOptions}
            eventJudges={refereeOptions}
            panelType='scoring'
            panelTypeId='33462a72-a260-483b-98f9-06380d9c72cc'
          />
        </Row>
      </div>

      <ConfirmationModal
        visible={showAutoAssignConfirmModal}
        onCancel={() => {
          setShowAutoAssignConfirmModal(false);
        }}
        onSubmit={() => {
          // deletePanel(id)
          autoAssign()
          setShowAutoAssignConfirmModal(false);
        }}
        title={(<div>
          <div className='team-name m-b-20'>{event_name}</div>
          <div className='title'>{`${t('randomizeConfirmLable')}`}</div>
        </div>)}
        // title={`${t('DeleteTeamDeleteLabel')} ${panel.panelName}?`}
        description='Proceeding with this auto assignment will overwritten any existing assignments. Do you wish to continue?'
      />
    </div>
  );
};


ScoringContainer.propTypes = {
  addText: PropTypes.string,
  panelType: PropTypes.string,
  eventId: PropTypes.string.isRequired,
  getReferees: PropTypes.func.isRequired,
  eventScoring: PropTypes.object.isRequired,
  eventScheduleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getScoringPanels: PropTypes.func.isRequired,
  createNewScoringPanel: PropTypes.func,

}
ScoringContainer.defaultProps = {
  addText: 'Add Referee Panel',
  panelType: 'refereePanel',
  eventScheduleId: undefined,
  createNewScoringPanel: () => { },
  createNewScoringPanel: () => { },
  addNew: () => { },
  getScoringPanels: () => {},
}

const mapDispatchToProps = {
  getReferees: handleReferees,
  getScoringPanels: fetchScoringPanels,
  createNewScoringPanel: handleCreateNewPanel,
  addNew: createNew,
  manageExpands: handleExpands
}

const mapStateToProps = (state) => {
  const { event: { eventScoring, eventDetails, eventTeams: { eventTeamsListAll } } } = state;

  const { eventScheduleId, attributes, included, eventProgramId } = eventDetails;
  const { title } = attributes;

  return {
    eventScoring,
    eventScheduleId,
    event_name: title,
    included: eventTeamsListAll,
    eventProgramId
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ScoringContainer);