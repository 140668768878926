import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import './RadioButton.scss';
import { useTranslation } from 'react-i18next';

const RadioButton = (props) => {
  const { options, ..._props } = props;
  const {t} = useTranslation();

  return (
    <Radio.Group
      buttonStyle='solid'
      optionType='button'
      className='c-radio-button-wrapper'
      {..._props}
    >
      {options.map(({ value, text, description }) => (
        <Radio.Button
          className='c-radio-button'
          buttonStyle='solid'
          key={value}
          value={value}
        >
          {text || t(description)}
        </Radio.Button>
 
      ))}
    </Radio.Group>
  );
};

RadioButton.propTypes = {
  options: PropTypes.array,
  _props: PropTypes.object,
}

RadioButton.defaultProps = {
  options: [],
  _props: {},
}
export default RadioButton;
