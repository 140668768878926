import validateEmail from 'utils/validateEmail';

const checkEmailValidity = (volunteerEmail, line) => {

  if (!volunteerEmail) return [];
  const isEmailValid = validateEmail(volunteerEmail);

  if (!isEmailValid) return [{message: `Invalid email address, line ${line}`}]

  return []

}

export default checkEmailValidity;