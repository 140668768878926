import React from 'react';
import './file.scss';
import PropTypes from 'prop-types';


const FileChosen = ({text}) => {
  return (
    <div className='file-chosen'>
      <p>{text}</p>
    </div>
  );
};

FileChosen.propTypes = {
  text: PropTypes.string
};

FileChosen.defaultProps = {
  text: 'File uploaded'
}

export default FileChosen;