import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../../styles/matchesAndScoring/MatchEvidenceRow.scss';
import { connect } from 'react-redux';
import { handleDocumentUpload, removeMatchFile } from 'features/EventOverviewContainer/redux/actions/coachScoring';
import matchStatuses, {
  openStatus,
  scoredStatus,
} from 'shared/constants/matchStatuses';
import {
  officialMatchType,
  practiceMatchType
} from 'shared/constants/matchTypes';
import {
  officialMatchSubmissionDueType,
  practiceMatchSubmissionDueType
} from 'shared/constants/timestampTypes';
import { Button, notification, Upload } from 'antd'
import { FILE_UPLOAD_INTERRUPTED_MESSAGE, UPLOAD_FILE_SIZE_LIMIT } from 'shared/constants/fileUpload'
import ScoresheetModal from '../ScoresheetModal';
import { VIDEO_EXTENSIONS } from 'shared/constants/fileExtensions';
import { updateGlobalUploadModalProps } from 'features/GlobalUploadModal/redux/actions'
import moment from 'moment';
import Icon from 'shared/components/Icon'
import { formatTimeInMatchEvidenceView } from 'utils/dateTimeUtils';
import { useTranslation } from 'react-i18next';


const MatchEvidenceRow = props => {
  const {
    eventData,
    title,
    matchStatus,
    matchId,
    teamId,
    files,
    fileCount,
    time_zone,
    matchStatusId,
    totalScore,
    scoreInputId,
    matchTypeId,
    loading,
    setUploadModalVisibility,
    setUploadProgress,
    scoringTimestamps,
    isFileUploading,
    globalUploadModal,
    use_24_hour_format
  } = props;

  const { t } = useTranslation()

  const isGlobalUploading = isFileUploading && (globalUploadModal.matchId === matchId && globalUploadModal.teamId === teamId);

  const isLoading = loading || isGlobalUploading;

  const [modalVisible, setModalVisible] = useState(false);

  const __handleUploadProgress = event => {
    const progress = ~~(event.loaded / event.total * 100)
    setUploadProgress(progress)
  }

  const __handleUploadError = error => {
    if (error && 'data' in error && error.data.error) {
      setUploadModalVisibility(false)
      notification.error({
        message: error.data.error,
        duration: 0,
        onClose: () => setUploadModalVisibility(false)
      });
    } else {
      setUploadModalVisibility(false)
      notification.error({
        message: FILE_UPLOAD_INTERRUPTED_MESSAGE,
        description: 'Please try again later',
        duration: 0,
        onClose: () => setUploadModalVisibility(false)
      });
    }
    console.warn(error)
  }

  const handleUpload = async ({ file }) => {
    // File size validation
    if (file.size > UPLOAD_FILE_SIZE_LIMIT) {
      notification.error({
        message: 'File size exceeds upload limit',
        description: 'Cannot upload a file larger than 5GB',
        duration: 5,
      });
    } else {
      setUploadModalVisibility(true)
      await props.updateGlobalUploadModalProps({ matchId, teamId })
      await props.handleDocumentUpload(matchId, teamId, file, __handleUploadProgress, __handleUploadError)
    }
  }

  const removeFile = () => {
    let file_data = (files || [])[0];
    const { eventFileId } = (file_data || {});
    eventFileId && props.removeMatchFile(matchId, eventFileId)
  }

  const replaceFile = (data) => {
    let file_data = (files || [])[0];
    const { eventFileId } = (file_data || {});
    if (eventFileId) {
      props.removeMatchFile(matchId, eventFileId, () => handleUpload(data))
    } else {
      handleUpload(data)
    }
  }

  let file_content = null;
  let buttons = null;
  let file_data = (files || [])[0];
  const { fileName, updatedAt, status } = (file_data || {});
  const ValidStatus = ['Uploaded', "Available", "Processing"];
  const fileError = status && !ValidStatus.includes(status);
  const fileErrorClass = fileError ? 'match-file-error' : ''
  if (fileName && !isLoading) {
    file_content = (
      <>
        "
        {<div className="inner_wrapper">{fileName}</div>}
        "
      </>
    )

    if (fileError) {
      file_content = <div className="inner_wrapper_error">File error</div>;
    }
    buttons = (
      <div className="inner_wrapper">
        <Button loading={isLoading} disabled={isLoading} type="link" onClick={removeFile}>{t('deleteVideoLabel')}</Button>
        <Upload accept={VIDEO_EXTENSIONS.join(', ')} customRequest={replaceFile} showUploadList={false} disabled={isLoading}>
          <Button loading={isLoading} disabled={isLoading} type="primary" ghost>{t('replaceVideoLabel')}</Button>
        </Upload>
      </div>
    );
  } else {
    file_content = <div className="inner_wrapper">{t('noFileUploadedLabel')}</div>;
    buttons = (
      <div className="inner_wrapper">
        <div />
        <Upload accept={VIDEO_EXTENSIONS.join(', ')} customRequest={handleUpload} showUploadList={false} disabled={isLoading}>
          <Button loading={isLoading} disabled={isLoading} type="primary">{t('uploadVideoLabel')}</Button>
        </Upload>
      </div>
    )
  }

  let button_header = null;
  let mobile_button_header = null
  let mobile_view_scoresheet = null;
  if (matchStatusId === scoredStatus.statusId) {
    buttons = null;
    button_header = <div className="button-header">
      <Button type="link" onClick={() => setModalVisible(true)}>{t('viewScoresheetLabel')}</Button>
      {t('scoreLabel')}: <span className="score-value">{`${totalScore}`}</span>
    </div>
    mobile_button_header = <div className="mobile-button-header">
      {t('scoreLabel')}: <span className="score-value">{`${totalScore}`}</span>
    </div>
    mobile_view_scoresheet = (
      <div className="mobile-button-header">
        <a onClick={() => setModalVisible(true)}>{t('viewScoresheetLabel')}</a>
      </div>
    )
  }

  let dateString;
  let match_end_time;
  const practiceMatchTimestamp = scoringTimestamps.find(s => s.timestampTypeId === practiceMatchSubmissionDueType.timestampTypeId) || {};
  const officialMatchTimestamp = scoringTimestamps.find(s => s.timestampTypeId === officialMatchSubmissionDueType.timestampTypeId) || {};
  if (matchTypeId === officialMatchType.matchTypeId) {
    dateString = formatTimeInMatchEvidenceView(officialMatchTimestamp.targetDateTime, time_zone, use_24_hour_format)
    match_end_time = officialMatchTimestamp.targetDateTime;
  } else if (matchTypeId === practiceMatchType.matchTypeId) {
    dateString = formatTimeInMatchEvidenceView(practiceMatchTimestamp.targetDateTime, time_zone, use_24_hour_format)
    match_end_time = practiceMatchTimestamp.targetDateTime;
  }

  /*
  status for indicator and background which are not used right now
  const content_class = Math.floor(Math.random()*10) > 5 ? 'passed-deadline' : 'match-file-error'
  */

  const match_due_time = dateString ? dateString : "Not Set";

  const event_end_time = ((eventData || {}).attributes || {}).end_date
  let is_passed_deadline = false;
  if (event_end_time && moment(event_end_time).isBefore()) {
    is_passed_deadline = true;
  } else if (match_end_time && moment(match_end_time).isBefore()) {
    is_passed_deadline = true;
  }

  const is_practice_match = matchTypeId === practiceMatchType.matchTypeId;
  const match_type = is_practice_match ? 'practice-match' : 'official-match';

  const file_upload_passed_deadline = is_passed_deadline && matchStatusId === openStatus.statusId && !file_data;
  const file_status_closed = is_passed_deadline && matchStatusId === openStatus.statusId;
  const status_class = file_upload_passed_deadline ? 'passed-deadline' : ''
  const status_only_class = file_status_closed ? 'passed-deadline' : '';

  const updateMatchStatus = () => {
    let statusKey = '';
    matchStatuses.forEach(status => {
      if(status.statusId == matchStatusId){
        statusKey = status.statusName
      }
    });

    return t(statusKey);
  }

  const displayed_status = file_status_closed ? "Closed" : updateMatchStatus()

  const scored_class = matchStatusId === scoredStatus.statusId ? 'scored' : status_class;
  if (is_passed_deadline) buttons = null;

  let upload_time = null;
  const displayed_time = updatedAt ? formatTimeInMatchEvidenceView(file_data.updatedAt, time_zone, use_24_hour_format) : '';
  if (file_upload_passed_deadline) {
    upload_time = <div className="upload_time_component"><Icon type="orangeWaring" /><span className="upload_time_text">{t('deadlinePassedLabel')}</span></div>
  } else if (file_data && displayed_time) {
    upload_time = <div className="upload_time_component"><Icon type="greenCheck" /><span className="upload_time_text">{displayed_time}</span></div>
  } else if (fileError) {
    upload_time = <div className="upload_time_component"><Icon type="redWarning" /><span className="upload_time_text">{displayed_time}</span></div>
  }

  return (
    <>
      <ScoresheetModal extraClass="scoresheet-modal" visible={modalVisible} onCancel={() => setModalVisible(false)} matchId={matchId} scoreInputId={scoreInputId} title={title} scoredScore={totalScore} />
      <div className={`match-evidence-row ${match_type}`}>
        <div className={`title-row ${scored_class} ${fileErrorClass}`}>
          <div className={`match-type`}>
            {title}
            {mobile_button_header}
          </div>
          <div className="saperator" />
          <div className={`due`}>
            <p>
              <span className="due-title">{t('MatchesDueLabel')}</span>
              <span>{match_due_time}</span>
            </p>
          </div>
          <div className="saperator" />
          <div className={`status`}>
            <p>
              <span className="status-title">{t('statusLabel')}: </span>
              <span className={status_only_class}>{displayed_status}</span>
            </p>
          </div>
          <div className="saperator" />
          <div className={`button-title`}>
            {button_header}
            {mobile_view_scoresheet}
          </div>
        </div>
        <div className={`content-row ${scored_class} ${fileErrorClass}`}>
          <div className="content-file-name">
            {file_content}
          </div>
          <div className="content-update-time">{upload_time}</div>
          <div className="status-content" />
          <div className="content-buttons">
            {buttons}
          </div>
        </div>
      </div>
    </>
  );
};

MatchEvidenceRow.propTypes = {
  title: PropTypes.string,
  matchStatus: PropTypes.string,
  matchId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  files: PropTypes.array,
  handleDocumentUpload: PropTypes.func.isRequired,
  removeMatchFile: PropTypes.func.isRequired,
  matchStatusId: PropTypes.string.isRequired,
  totalScore: PropTypes.number,
  scoreInputId: PropTypes.string,
  fileCount: PropTypes.number,
  matchTypeId: PropTypes.string.isRequired,
  setUploadModalVisibility: PropTypes.func,
  setUploadProgress: PropTypes.func,
};

MatchEvidenceRow.defaultProps = {
  title: '',
  matchStatus: '',
  files: [],
  totalScore: 0,
  fileCount: 0,
  scoreInputId: '',
};

const mapStateToProps = (state) => {
  const { auth: { userInfo: { use_24_hour_format } }, eventOverview: { time_zone, selectedTeam: { id }, isFileUploading, eventData }, coachScoring: { scoringTimestamps }, globalUploadModal } = state;
  return { teamId: id, scoringTimestamps, isFileUploading, globalUploadModal, eventData, time_zone, use_24_hour_format };
}

const mapDispatchToProps = {
  handleDocumentUpload,
  removeMatchFile,
  updateGlobalUploadModalProps
}


export default connect(mapStateToProps, mapDispatchToProps)(MatchEvidenceRow);