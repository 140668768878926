/* eslint-disable import/prefer-default-export */
import * as timestampService from 'utils/services/timestamps';
import {formatTimestampRes} from 'utils/services/timestamps/model';
import Timestamp from 'models/ScheduleTimestamp';
import * as types from '../../types/coachScoringTypes';

const getScoringTimestampsBegin = () => {
  return {
    type: types.GET_COACH_SCORING_TIMESTAMPS_BEGIN
  }
  
}

const getScoringTimestampsError = () => {
  return {
    type: types.GET_COACH_SCORING_TIMESTAMPS_ERROR
  }
}

const getScoringTimestampsSuccess = (scoringTimestamps) => {
  return {
    type: types.GET_COACH_SCORING_TIMESTAMPS_SUCCESS,
    payload: {
      scoringTimestamps
    }
  }
}


export const getScoringTimestamps = () => {
  return async (dispatch, getState) => {
    const { eventOverview: {eventScheduleId}} = getState();
    try {
      dispatch(getScoringTimestampsBegin())
      const {data, included} = await timestampService.getScoringTimestamps(eventScheduleId);
      const scoringTimestamps = data.map(d => formatTimestampRes(new Timestamp(d, included)));
      await dispatch(getScoringTimestampsSuccess(scoringTimestamps))
    } catch(err) {
      await dispatch(getScoringTimestampsError())
      throw err
    }


  }
}