import moment from "moment"

export const format24HoursToReadableString = (str, time_zone, is24Hour = false)=>{
  if(!str) return null;
  // if(time_zone){
  //   return moment.utc(str, 'HH:mm:ss').tz(time_zone).format('hh:mm a');
  // }
  return moment.utc(str, 'HH:mm:ss').format(is24Hour ? 'HH:mm' : 'hh:mm a');
}

export const formatReadableStringTo24Hours = (str, time_zone)=>{
  if(!str) return null;
  // if(time_zone){
  //   return moment.tz(str, 'hh:mm a', time_zone).utc().format('HH:mm:ss');
  // }
  return moment.utc(str, 'hh:mm a').format('HH:mm:ss');
}

export const formatDateToReadableStr = (str, time_zone) =>{
  if(!str) return null;
  // if(time_zone){
  //   return moment.tz(str, time_zone).format('dddd, MMM D, YYYY');
  // }
  return moment.utc(str).format('dddd, MMM D, YYYY');
}

export const formatDateToReadableStrTwo = (str, time_zone) => {
  if(!str) return null;
  // if(time_zone){
  //   return moment.tz(str, time_zone).format('dddd, MMM D')
  // }
  return moment.utc(str).format('dddd, MMM D')
}

export const formatReadableStrTwoToDate = (str)=>{
  if(!str) return null;
  return moment.utc(str,'dddd, MMM D').format('YYYY-MM-D')
}

export const formatDateToReadableStrThree = (str, time_zone, is24Hour = false) => {
  if(!str) return null;
  // if(time_zone){
  //   return moment.tz(str, time_zone).format('h:mm a')
  // }
  return moment.utc(str).format(is24Hour ? 'HH:mm' : 'hh:mm a')
}

export const generateStartTimeList = (end, time_list, is24Hour = false) => {
  return time_list.filter(t => moment(t, is24Hour ? 'HH:mm' : 'hh:mm a').isBefore(moment(end, is24Hour ? 'HH:mm' : 'hh:mm a')));
}

export const generateEndTimeList = (start, time_list, is24Hour = false) => {
  return time_list.filter(t => moment(t, is24Hour ? 'HH:mm' : 'hh:mm a').isAfter(moment(start, is24Hour ? 'HH:mm' : 'hh:mm a')));
}

export const combineDateAndTime = (date, time) =>{
  const new_date = `${date}T${time}`
  const result = moment.utc(new_date, 'dddd, MMM D, YYYYTh:mm a').format('YYYY-MM-DTHH:mm:ss')+"Z";
  return result;
}