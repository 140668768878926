import React from 'react';
import PropTypes from 'prop-types';
import Paragraph from 'shared/components/Paragraph';

import BtnPrimary from 'shared/components/Button/BtnPrimary';
import { connect } from 'react-redux';
import { handleInviteAllCoaches } from 'features/EventContainer/redux/actions/teams';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CoachInviteTile from './TeamCoachTile';

const AddList = (props) => {
    const { coachesList, inviteAll, isInvitingCoach, defaultTeams, onAddedSucceed } = props;
    const noCoaches = coachesList.length < 1;
    const { t } = useTranslation();

    const { id: eventId } = useParams();

    const handleInviteAll = () => {
      if(defaultTeams){
        const newCoachInvited = coachesList.map( coach => {
          coach.teams = defaultTeams;
          return coach;
        });
        console.log(newCoachInvited, eventId)
        inviteAll(newCoachInvited, eventId, onAddedSucceed);
      }else{
        inviteAll(coachesList, eventId);
      }
    };

    return (
        <div className='teams-add-coaches-modal__manual-invite-list-container width-300 m-l-30'>
            <Paragraph color={noCoaches ? 'silver' : 'darkGray'}>
                Invite these people :
            </Paragraph>
            <div className={noCoaches ? 'teams-coaches-modal__manual-invite-list' : 'teams-coaches-modal__manual-invite-list--invitees'}>
                {coachesList.map(c => (
                    <CoachInviteTile coach={c} />
                ))}
            </div>
            <div className='teams-coaches-modal__manual-invite-list__btn-wrapper--outer'>
                <div className='teams-coaches-modal__manual-invite-list__btn-wrapper--inner'>
                    <BtnPrimary loading={isInvitingCoach} disabled={noCoaches} onClick={handleInviteAll}>{t('Add All')}</BtnPrimary>
                </div>
            </div>
        </div>
    );
};

AddList.propTypes = {
    coachesList: PropTypes.array.isRequired,
    inviteAll: PropTypes.object.isRequired,
    isInvitingCoach: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    const { event: { eventTeams } } = state;
    const { addCoachesManuallyList, isInvitingCoach } = eventTeams;
    return {
        coachesList: addCoachesManuallyList,
        isInvitingCoach
    };
}

export default connect(mapStateToProps, { inviteAll: handleInviteAllCoaches })(AddList);
