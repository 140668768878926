import React from 'react';
import { Button, Upload} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './ChangeFile.scss';

const ChooseFile = (props) => {
  const { t } = useTranslation()
  const {onChange} = props;

  return (
    <div className='change-file'>
      <Upload
        accept=".txt, .csv"
        onChange={onChange}
        showUploadList={false}
        className='change-file__upload'
        // beforeUpload={beforeUpload}
      >
        <Button type='link' className='change-file__btn'> 
          {t('changeFileLinkLabel')}
        </Button>
      </Upload>
    </div>
  );
};

ChooseFile.propTypes = {
  onChange: PropTypes.func,
};

ChooseFile.defaultProps = {
  onChange: () => {},
}

export default ChooseFile;