import { notification } from 'antd';
import * as eventFileService from 'utils/services/eventFiles';
import * as types from '../../types/coachScoringTypes';


const removeMatchFileBegin = () => {
  return {
    type: types.REMOVE_COACH_MATCH_FILE_BEGIN
  }
}

const removeMatchFileSuccess = (coachMatches) => {
  return {
    type: types.REMOVE_COACH_MATCH_FILE_SUCCESS,
    payload: {
      coachMatches
    }
  }
}

const removeDocumentError = () => {
  return {
    type: types.REMOVE_COACH_MATCH_FILE_ERROR
  }
}



const handleRemoveMatchFile = (matchId, fileId, callback = null) => {
  return async (dispatch, getState) => {
    const {coachScoring: {coachMatches}} = getState();
    const coachMatchesCopy = [...coachMatches]
    await dispatch(removeMatchFileBegin())

    try {

      await eventFileService.removeEventFile(fileId)
      const matchIndex = coachMatchesCopy.findIndex(i => i.matchId === matchId);

      const selectedMatch = coachMatchesCopy[matchIndex];
      const matchFiles = selectedMatch.files.filter(f => f.eventFileId !== fileId);

      const updatedMatch = {
        ...selectedMatch,
        files: matchFiles
      }
      coachMatchesCopy[matchIndex] = updatedMatch;
      await dispatch(removeMatchFileSuccess(coachMatchesCopy))
      callback && callback();
    } catch(err) {
      await dispatch(removeDocumentError())
      notification.error({
        message: 'Error',
        description: 'File not removed',
      });
      throw err;
    }
  }
}

export default handleRemoveMatchFile;