import React from 'react';
import PropTypes from 'prop-types';
import EventModal from 'features/EventContainer/components/EventModal';
import ModalContent from './EditEventModalContent';

const EditMeetingDetailsModal = props => {
  const {
    visible,
    onCancel,
  } = props;


  return (
    <EventModal
      visible={visible}
      onCancel={onCancel}
    >
      <ModalContent closeCb={onCancel} />
    </EventModal>
  );
};

EditMeetingDetailsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditMeetingDetailsModal;