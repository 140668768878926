import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import NoFileChosen from 'shared/components/NoFileChosen';
import FileChosen from 'shared/components/FileChosen';
import ChangeFile from 'shared/components/ChangeFile';
import ChooseFile from 'shared/components/ChooseCSVFile';
import '../../../../styles/TeamsCoachesImport.scss';
import Papa from 'papaparse';
import FileSuccess from 'shared/components/FileSuccess';
import ButtonPrimary from 'shared/components/Button/BtnPrimary';
import ButtonSecondary from 'shared/components/Button/BtnSecondary';
import truncateString from 'utils/truncateString';
import {validateImportedCoach} from 'utils/importCSV/validateCoachCSV';
import FileError from 'shared/components/FileError';
import { connect } from 'react-redux';
import {handleInviteImportList, inviteTeamsOnlyFromImport} from 'features/EventContainer/redux/actions/teams';
import {useParams} from 'react-router-dom';
import InviteList from './ImportInviteList';
import InvitedList from './CoachesInvitedList';

const EXPECTED_HEADERS_WITH_COACH = ["CoachEmail", "CoachFirstName", "CoachLastName", "CoachPhone", "TeamName", "TeamNumber", "TeamCountry", "TeamRegion"];
const EXPECTED_HEADERS_WITHOUT_COACH = ["TeamName", "TeamNumber", "TeamCountry", "TeamRegion"];


const TeamsCoachesImport = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isFileImported, setIsFileImported] = useState(false);
  const [fileName, setFileName] = useState('');
  const [importedCoaches, setImportedCoaches] = useState([]);
  const [invalidImportFile, setInvalidImportFile] = useState(false);
  const [fileUpload, setFileUpload] = useState('noFileChosen');
  const [totalErrors, setTotalErrors] = useState(0);

  const {inviteImportListTeamsOnly, inviteImportList, isInviting, invitedCoaches, importErrorsList, importedCoachesNotInvited, withCoach } = props;
  const { t } = useTranslation()

  const EXPECTED_HEADERS = withCoach ? EXPECTED_HEADERS_WITH_COACH : EXPECTED_HEADERS_WITHOUT_COACH;

  const {id: eventId}= useParams();

  useEffect(() => {
    setFileName('');
    setImportedCoaches([])
    setInvalidImportFile(false);
    setFileUpload('noFileChosen')
    setTotalErrors(0);
  }, [withCoach])

  const getVolunteersFromFile = (fileResults) => {

    let errorCount = 0;

    if (fileResults.length > 0) {

      const uploadedVolunteers = fileResults.map((r, i) => {
        
        const lineNumber = i+1;
        const errors = validateImportedCoach(r, lineNumber, withCoach);
        
        errorCount += errors.length;
        return {
          ...r,
          id: i+1,
          errors
        }
      })
      setImportedCoaches(uploadedVolunteers)
    }else{
      errorCount = 1;
    }
    setTotalErrors(errorCount);
  }

  const isValidTemplate = (headers) => {
    if (headers.length !== EXPECTED_HEADERS.length) {
      return false
    } 

    for (let i = 0; i < EXPECTED_HEADERS.length; i+=1 ) {
      if (headers[i] !== EXPECTED_HEADERS[i]) return false
    }

    return true;
  }

  const onChange = (args) => {
    const {file} = args;
    setIsFileImported(false);

    if (file.status !== 'uploading') {
      setFileName(truncateString(file.name, 65))
      setIsFileUploaded(true)

      Papa.parse(file.originFileObj, {
        download: true,
        header: true,
        skipEmptyLines: 'greedy',
        comments: '#',
        complete: (results) => {
          console.log(results)
          // error handling for when the upload csv file does not match the expected headers
          const headers = results.meta.fields;
          const validHeaders = isValidTemplate(headers);
          if(results?.data?.length === 0) {
            setFileUpload('emptyFileError');
          } else {
            if (validHeaders) {
              getVolunteersFromFile(results.data);
              setInvalidImportFile(false);
              setFileUpload('importReady');
            }
            if (!validHeaders) {
              setInvalidImportFile(true);
              setFileUpload('importError');
            }
          }
        }
      });
 
    }
  }

  const handleImport = () => {
    setIsFileImported(true);
    if(fileUpload !== 'emptyFileError'){
      if (totalErrors > 0){ 
        setFileUpload('inviteError')
      }
  
      if (totalErrors === 0) {
        setFileUpload('inviteReady')
      }
    }
  }

  const resetTab = () => {
    setFileName('');
    setIsFileImported(false);
    setIsFileUploaded(false);
    setFileUpload('noFileChosen');
    setTotalErrors(0);
  }


  const deleteCoach = (id) => {

    const filteredCoaches = importedCoaches.filter(c => c.id !== id)
    setImportedCoaches(filteredCoaches)
    
    let totalErrors = 0;
    filteredCoaches.forEach(coach=>{
      const errorCount = coach?.errors?.length;
      totalErrors += errorCount ? errorCount : 0;
    })

    setTotalErrors(totalErrors);
    if(totalErrors === 0){
      setFileUpload('inviteReady');
    }

    if (filteredCoaches.length < 1) {
      resetTab()
    }
  }

  const successCallback = () => {
    setFileUpload('inviteSuccess');
  }

  const handleInvite = () => {
    withCoach ? handleInviteWithCoach() : handleInviteWithoutCoaches()
  }

  const handleInviteWithoutCoaches = () => {
    inviteImportListTeamsOnly(importedCoaches, successCallback);
  }

  const handleInviteWithCoach = () => {
    const coachesList = [];
    importedCoaches.forEach(coach => {
      if(coach.errors.length === 0) {
        let selectedCoach;
        const teamObj = {
          name: coach.TeamName,
          country: coach.TeamCountry,
          number: coach.TeamNumber,
          region: coach.TeamRegion
        }

        const coachAlreadyAddedIndex = coachesList.findIndex(c => c.CoachEmail === coach.CoachEmail);
        const isCoachAlreadyAdded = coachAlreadyAddedIndex > -1;

        if (isCoachAlreadyAdded) {
          selectedCoach = coachesList[coachAlreadyAddedIndex];

          selectedCoach = {
            ...selectedCoach,
            teams: [...selectedCoach.teams, teamObj]
          }

          coachesList[coachAlreadyAddedIndex] = selectedCoach;

        } else {

          selectedCoach = {
            ...coach,
            teams: [teamObj]
          }
          coachesList.push(selectedCoach);
        }
        
      }
    })

    inviteImportList(coachesList, eventId, successCallback);
  }

  const renderChangeFileBtn = () => {

    if (fileUpload === 'importReady' || fileUpload === 'importError' || fileUpload === 'inviteReady' || fileUpload === 'inviteError' || fileUpload === 'emptyFileError') {
      return (
        <ChangeFile onChange={onChange} />
      )
    }
    return null;
  }

  const renderInviteList = (withCoach) => {
    switch(fileUpload) {
      case 'importReady':
        return null;
      case 'noFileChosen':
        return null;
      case 'importError':
        return null;
      case 'emptyFileError':
        return null;
      case 'inviteSuccess':
        return <InvitedList coachesNotInvited={importedCoachesNotInvited} errorsList={importErrorsList} volunteers={invitedCoaches} resetTab={resetTab} withoutCoach={!withCoach}/>;
      default:
        return <InviteList hasErrors={Boolean(totalErrors)} coaches={importedCoaches} resetTab={resetTab} handleDelete={deleteCoach} withoutCoach={!withCoach}/>;
    }
  }

  const renderFileUploadField = () => {
    switch(fileUpload) {
      case 'importReady':
        return <NoFileChosen text={t('fileReadyForImportLabel', { '0': fileName})} />;
      case 'importError':
        return <FileError text={t('headersDontMatchTemplateLabel')} />;
      case 'emptyFileError':
        return <FileError text={"No Records found."} />;
      case 'inviteReady':
        return <FileChosen text={t('readyToInviteLabel', { '0': fileName })} />;
      case 'inviteError':
        return <FileError text={`"${fileName}" has ${totalErrors} error${totalErrors !== 1 ? 's' : '' }.`} />
      case 'noFileChosen':
        return <NoFileChosen />;
        case 'inviteSuccess': {
          if (importErrorsList.length) {
            if (importErrorsList.length > 1) return <FileError text={t('fileErrorsLabel', { '0': fileName, '1': importErrorsList.length})} />
            return <FileError text={t('fileErrorLabel', { '0': fileName, '1': importErrorsList.length})} />
          } 
            return <FileSuccess text={t('importedAndInvitedLabel', { '0': fileName })} />;
          
        }
      default:
        return null;
    }
  }



  const renderFileUploadBtn = () => {
    switch(fileUpload) {
      case 'noFileChosen':
        return <ChooseFile onChange={onChange} />;
      case 'importReady':
        return <ButtonSecondary disabled={invalidImportFile} onClick={handleImport} additionalClassNames='btn--180'>{t('importLabel')}</ButtonSecondary>;
      case 'importError':
        return <ButtonSecondary disabled={invalidImportFile} onClick={handleImport} additionalClassNames='btn--180'>{t('importLabel')}</ButtonSecondary>;
      case 'emptyFileError':
        return <ButtonSecondary disabled={true} onClick={handleImport} additionalClassNames='btn--180'>{t('importLabel')}</ButtonSecondary>;
      case 'inviteReady':
        return <ButtonPrimary loading={isInviting} additionalClassNames='btn--180' onClick={handleInvite}>{t('inviteLabel')}</ButtonPrimary>;
      case 'inviteError':
        return <ButtonPrimary loading={isInviting} disabled={true} additionalClassNames='btn--180' onClick={handleInvite}>{t('inviteLabel')}</ButtonPrimary>;
      case 'inviteSuccess':
        return <ChooseFile onChange={onChange} text={t('importAnotherLabel')} />;
      default:
          return null;
    }
  }

  return (
    <div className='teams-coaches-import'>
      <div className='teams-coaches-import__row'>
        {renderFileUploadField()}
        <span className='teams-coaches-import__choose-file'>

          {renderFileUploadBtn()}

        </span>
      </div>
      {renderChangeFileBtn()}
      {renderInviteList(withCoach)}
    </div>
  );
};

TeamsCoachesImport.propTypes = {
  inviteImportList: PropTypes.func.isRequired,
  isInviting: PropTypes.bool.isRequired,
  invitedCoaches: PropTypes.array.isRequired,
  importErrorsList: PropTypes.array.isRequired,
  importedCoachesNotInvited: PropTypes.array.isRequired
}

const mapDispatchToProps = {
  inviteImportListTeamsOnly: inviteTeamsOnlyFromImport,
  inviteImportList: handleInviteImportList
}

const mapStateToProps = ({event}) => {
  const {
    eventTeams: {
      isInvitingCoach,
      importedCoachesSuccessfullyInvited,
      importErrorsList,
      importedCoachesNotInvited
    },
    eventDetails: {
      eventProgramId
    }
  } = event;
  return {
    eventProgramId,
    isInviting: isInvitingCoach,
    invitedCoaches: importedCoachesSuccessfullyInvited,
    importErrorsList,
    importedCoachesNotInvited
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamsCoachesImport);