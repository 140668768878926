import { notification } from 'antd';
import { CURRENT_EVENT_SEASON } from 'shared/constants/eventConstants';
import * as eventService from 'utils/services/events';
import * as types from '../types';

const createNewEventBegin = () => {
  return {
    type: types.CREATE_NEW_EVENT_BEGIN
  };
};

const createNewEventError = () => {
  return {
    type: types.CREATE_NEW_EVENT_ERROR
  };
};

const createNewEventSuccess = () => {
  return {
    type: types.CREATE_NEW_EVENT_SUCCESS
  };
};

export const handleCreateNewEvent = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      //default new event created to season 2022
      // payload.data.attributes.season = CURRENT_EVENT_SEASON;

      await dispatch(createNewEventBegin())
      await eventService.createNewEvent(payload);
      dispatch(createNewEventSuccess());
      cb();
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error creating the event',
      });
      await dispatch(createNewEventError())
      throw err;
    }
  };
};
