import {
  HEAD_REFEREE_ID,
  REFEREE_ID,
  COACH_ID,
  EVENT_ADMIN_ID
} from 'shared/constants/eventRoleTypes';

export default [
  {
    description: 'robotGameMissionsVideoLabel',
    default: 'https://firstinspires.blob.core.windows.net/fll/challenge/2024-25/fll-challenge-submerged-robot-game-missions-video.mp4',
    "es-MX": "",
    "tr": "",
    "nl": "",
    "he": "",
    roles: [REFEREE_ID, HEAD_REFEREE_ID, COACH_ID, EVENT_ADMIN_ID]
  },
  {
    description: 'matchVideoGuidanceLabel',
    default: 'https://youtu.be/qPbwNQaQt4Y?si=ylPuNREB_MIVFpdw.',
    "es-MX": "",
    "tr": "",
    "nl": "",
    "he": "",
    roles: [REFEREE_ID, HEAD_REFEREE_ID, COACH_ID, EVENT_ADMIN_ID]
  },
]