// import Tabs from 'shared/components/Tabs/ModalTabs';
import React, { useEffect, useState, useCallback } from 'react';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const MatchesFilter = (props) => {

    function callback(key) {
        console.log(key);
    }
    return (
        <div className='m-t-20'>
            <Tabs tabPosition="top" onChange={(e) => { props.onChange(e) }}>
                <TabPane tab="All" key="all">
                    {/* Content of Tab 1 */}
                </TabPane>
                <TabPane tab="Practice Match" key="0">
                    {/* Content of Tab 2 */}
                </TabPane>
                <TabPane tab="Match 1" key="1">
                    {/* Content of Tab 3 */}
                </TabPane>

                <TabPane tab="Match 2" key="2">
                    {/* Content of Tab 3 */}
                </TabPane>

                <TabPane tab="Match 3" key="3">
                    {/* Content of Tab 3 */}
                </TabPane>
            </Tabs>
        </div>
    );
}

export default MatchesFilter