import React from 'react';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button } from 'antd';
import Icon from '@ant-design/icons';
import CloseCircle from 'shared/components/Icon/icons/CloseCircle';
import './import-view.scss';
import { connect } from 'react-redux';
import {handleImportVolunteerDelete} from 'features/EventContainer/redux/actions/volunteersActions';
import ErrorIcon from 'features/EventContainer/components/InviteErrorIcon';
import { useTranslation } from 'react-i18next';

const InviteTile = props => {
  //const {t} = useTranslation()
  const {
    name, email, role, id, deleteVolunteer, resetTab, errors, isInvited, onDelete
  } = props;

  const deleteClick = () => {
    deleteVolunteer(id, resetTab, onDelete)
  }

  const renderErrorMessages = (errorsArray) => {

    if (errorsArray.length < 1) return null;
    const errorsRows = errorsArray.map(e => (
      <div className='import__invitee-error-message-row'>
        <Icon component={ErrorIcon} className='import__invitee-error-icon' />
        <p className='import__invitee-error-message'>{e.message}</p>
      </div>
    ))

    return errorsRows;

  }

  const getRole = (roleValue) => {
    return roleValue
    // return is_FLLC_event ? t('judgeLabel') : t('nonChallengeJudgeLabel') 
    // if (roleValue == "Judge Adviser"){
    //   return is_FLLC_event ? t('judgeAdvisor') : t('nonChallengeJudgeAdvisor')
    // }

    // if (roleValue == "Judge") {
    //   return is_FLLC_event ? t('judgeLabel') : t('nonChallengeJudgeLabel')
    // }

    // return roleValue
  }


  return (
    <div className={errors.length ? 'import__invitee import__invitee--error' : 'import__invitee'}>
      <div className='import__invitee-col'>
        <Avatar size={48} icon={<UserOutlined />} className='import__invitee-avatar' />
        <span>
          <p className='import__invitee-name'>{name}</p>
          {email ? (
            <a className='link--email import__invitee-email' href={`mailto:${email}`}>{email}</a>

          ) : (
            <div style={{height: '20px'}} />

          )}

          
        </span>
        
      </div>
      <div className='import__invitee-role-col'>
        <div>
          <p className='import__invitee-role'>{getRole(role)}</p>
          <div style={{height: '20px'}} />
          
        </div>
        <div>
          {renderErrorMessages(errors)}
        </div>
      </div>
      
      {!isInvited && (
        <span className='import__btn-delete'>
          <Button type='link' onClick={deleteClick}>
            <CloseCircle />

          </Button>
        </span>

      )}
      
    </div>
  );
};

InviteTile.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  deleteVolunteer: PropTypes.func.isRequired,
  resetTab: PropTypes.func,
  errors: PropTypes.array,
  isInvited: PropTypes.bool
};

InviteTile.defaultProps = {
  name: '',
  email: '',
  role: '',
  resetTab: () => {},
  errors: [],
  isInvited: false
}

const mapStateToProps = (state) => {
  const { event: { eventDetails: { is_FLLC_event } } } = state

  return {
    is_FLLC_event
  }
}

export default connect(mapStateToProps, {deleteVolunteer: handleImportVolunteerDelete})(InviteTile);