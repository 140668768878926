const m12Section = {
  id: 'm12',
  name: '2023ScoresheetM12Sub1Label',
  fields: [
    {
      scoring: '2023ScoresheetM12Sub2Label',
      id: 'm12_scoring_1',
      options: [
        {
          description: '0',
          value: 0
        },
        {
          description: '1',
          value: 1
        },
        {
          description: '2',
          value: 2
        },
        {
          description: '3',
          value: 3
        }
      ]
    },
    {
      scoring: '2023ScoresheetM12Sub3Label',
      id: 'm12_scoring_2',
      options: [
        {
          description: '0',
          value: 0
        },
        {
          description: '1',
          value: 1
        },
        {
          description: '2',
          value: 2
        }
      ]
    }
  ]
}

export default m12Section;