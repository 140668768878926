import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import './Link.scss';

const LINK_COLORS = ['white', 'primaryBlue'];

const CustomLink = (props) => {
  const { color, customText, LinkIcon, className, ..._props } = props;
  const cls = `
    c-custom-link 
    c-custom-link--${color}
    ${className}
  `;
  return (
    <Button
      type='link'
      className={cls}
      {..._props}
    >
      <div className='c-custom-link__inner'>
        {LinkIcon ? <Icon component={LinkIcon} className='c-custom-link__icon' /> : ''}
        {customText}
      </div>
    </Button>
  );
};

CustomLink.propTypes = {
  color: PropTypes.oneOf(LINK_COLORS),
  customText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
};

CustomLink.defaultProps = {
  color: 'primaryBlue',
  customText: false
};

export default CustomLink;
