import * as types from '../types/eventSponsorsTypes';
import * as adminEventTypes from 'features/AdminDashboard/redux/types/eventsTypes';

const DEFAULT_STATE = {
  eventId: null,
  sponsors: [],
  isFetchingSponsors: false,
  isSavingSponsor: false,
  newSponsorCreated: false,
  uploadRetry: 0,
};

export default (state = DEFAULT_STATE, action) => {
  const {type} = action;
  switch(type) {
    case adminEventTypes.FETCH_ADMIN_DASHBOARD_EVENTS_BEGIN: {
      return DEFAULT_STATE;
    }
    case types.GET_EVENT_SPONSORS_BEGIN: {
      return {
        ...state,
        eventId: action.payload,
        sponsors: [],
        isFetchingSponsors: true
      };
    }
    case types.GET_EVENT_SPONSORS_SUCCESS: {
      return {
        ...state,
        eventId: null,
        sponsors: action.payload,
        isFetchingSponsors: false
      };
    }
    case types.GET_EVENT_SPONSORS_FAIL: {
      return {
        ...state,
        eventId: null,
        sponsors: [],
        isFetchingSponsors: false
      };
    }
    case types.SAVE_EVENT_SPONSOR_SUCCESS: {
      const sponsor = action.payload;
      const sponsors = state.sponsors;
      const idx = sponsors.findIndex(({ id }) => id === sponsor.id);
      const updatedSponsors = idx > -1
        ? sponsors.map((s, i) => i === idx ? { ...s, ...sponsor } : s)
        : [...sponsors, sponsor];
      return {
        ...state,
        sponsors: updatedSponsors,
        isSavingSponsor: false,
        newSponsorCreated: idx === -1 && sponsor.id
      };
    }
    case types.UPLOAD_SPONSOR_LOGO_RETRY: {
      return {
        ...state,
        uploadRetry: action.payload,
      }
    }
    case types.RESET_SPONSOR_CREATED_STATE: {
      return {
        ...state,
        newSponsorCreated: false
      };
    }
    case types.DELETE_EVENT_SPONSOR_SUCCESS: {
      const sponsorId = action.payload;
      return {
        ...state,
        sponsors: state.sponsors.filter((s) => s.id !== sponsorId)
      };
    }
    default:
      return state;
  }
};
