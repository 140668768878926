import React from 'react';
import PropTypes from 'prop-types';

const RightArrow = ({fill}) => {
  return (
    <svg width="8px" height="14px" viewBox="0 0 8 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      <g id="Volunteers-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.3">
        <g id="My-Volunteers---Add-Volunteers---PDP-Admin" transform="translate(-609.000000, -663.000000)" fill={fill}>
          <g id="Group-25" transform="translate(139.000000, 10.000000)">
            <g id="Group-12" transform="translate(191.000000, 228.000000)">
              <g id="Group-19" transform="translate(0.000000, 408.000000)">
                <g id="Button/Secondary/Basic/Large-with-Icon-RIght" transform="translate(180.000000, 0.000000)">
                  <g id="Icons/FIRST/Drop-Arrow" transform="translate(103.000000, 24.000000) rotate(-90.000000) translate(-103.000000, -24.000000) translate(96.000000, 20.000000)">
                    <polygon id="Path" points="1.645 0 7 4.94466937 12.355 0 14 1.52226721 7 8 0 1.52226721" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

RightArrow.propTypes = {
  fill: PropTypes.string
};

RightArrow.defaultProps = {
  fill: '#0066B3'
}
export default RightArrow;