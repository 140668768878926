import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EventModal from 'features/EventContainer/components/EventModal';
import { handleResetAddToPanelList } from 'features/PanelManagement/redux/actions/judges';
import {
  handleResetTeamsAddToPanelList,
} from 'features/PanelManagement/redux/actions/teams';
import ModalContent from './PanelModalContent';
import '../../../../../../features/PanelManagement/styles/JudgingPanelModal.scss';
import InPersonPanelModal from '../InPersonPanelModal';

const PanelModal = (props) => {
  const {
    visible,
    onCancel,
    resetJudgesList,
    resetTeamsList,
    panelId,
    isInPerson
  } = props;

  const handleCloseModal = () => {
    onCancel();
    resetJudgesList();
    resetTeamsList();
  };

  return (
    <EventModal
      visible={visible}
      onCancel={handleCloseModal}
    >
      {
        isInPerson ?
        <InPersonPanelModal panelId={panelId} closeModal={handleCloseModal}/>
        :
        <ModalContent panelId={panelId} closeModal={handleCloseModal} />
      }
    </EventModal>
  );
};

PanelModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  resetJudgesList: PropTypes.func,
  resetTeamsList: PropTypes.func,
  panelId: PropTypes.string.isRequired
};

PanelModal.defaultProps = {
  visible: false,
  onCancel: () => { },
  resetJudgesList: () => { },
  resetTeamsList: () => { },
};

const mapDispatchToProps = {
  resetTeamsList: handleResetTeamsAddToPanelList,
  resetJudgesList: handleResetAddToPanelList
};

export default connect(null, mapDispatchToProps)(PanelModal);
