import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Avatar, Tooltip } from 'antd';
import { MailOutlined, CopyOutlined, EditOutlined } from '@ant-design/icons';
import { todayMomentObject } from 'utils/dateTimeUtils';
import { addEllipsisMiddle } from 'utils/truncateString';
import { connect } from 'react-redux';
import * as userTypes from 'shared/constants/userTypes';
import * as eventRoleTypes from 'shared/constants/eventRoleTypes';
import { useTranslation } from 'react-i18next';
import CloseCircle from 'shared/components/Icon/icons/CloseCircle';

import './CardTile.scss';
import generateInvitationLink from 'features/EventContainer/redux/actions/volunteers/generateInvitationLink';

// Dummy func
const getRandomImage = (i = 1) => {
  const images = ['discoDude', 'lego-figure-green', 'mad-scientist'];
  return images[i % 3];
}

const CardTile = (props) => {
  const {
    email,
    firstName,
    lastName,
    renderInfo,
    rowClassName = '',
    userEventRoles,
    userType,
    userStatus,
    handleReinviteClick,
    userVerificationCodeTimeout,
    showDeleteBtn,
    handleDelete,
    onEditClick,
    handleGenerateInvitationLink,
    isEditButtonVisible,
    _props,
    closePosition,
    showInvitationLink = false,
  } = props;
  const { t } = useTranslation();

  const [invitationResend, setInvitationResend] = useState(false);
  const [isGeneratingLink, setIsGeneratingLink] = useState(false)
  const fullName = `${firstName} ${lastName}`;
  const showHelper = email.length > 40 || fullName.length > 27;
  const isPartnerAdmin = userType === userTypes.PARTNER_ADMIN || userType === userTypes.FIRST_ADMIN;
  const isEventAdmin = userEventRoles.includes(eventRoleTypes.EVENT_ADMIN_ID);

  const canResendInvitation = isPartnerAdmin || userEventRoles.includes(eventRoleTypes.EVENT_ADMIN_ID);

  const userIsPending = userStatus === 'Pending';

  const userInvitationIsExpired = userVerificationCodeTimeout ? todayMomentObject().isAfter(userVerificationCodeTimeout) : true;

  const showResendBtn = canResendInvitation && userIsPending;

  const prompt = (
    <div>
      <p>{fullName}</p>
      <p>{email}</p>
    </div>
  );

  const handleGetInvitationLink = async () => {
    setIsGeneratingLink(true)
    const link = await handleGenerateInvitationLink(props.id)
    navigator.clipboard.writeText(link);
    setIsGeneratingLink(false)
  }

  const reinviteClick = () => {
    handleReinviteClick();
    setInvitationResend(true);
  };

  const closeButtonClassName = closePosition ? `card-tile__delete-btn-${closePosition}` : 'card-tile__delete-btn'

  return (
    <div className={showResendBtn ? 'card-tile card-tile--pending' : 'card-tile'}>
      <div>
        <div className='card-tile__heading-wrapper'>
          <div className='card-tile__heading-text-wrapper'>
            <Tooltip title={showHelper && prompt}>
              <p className='paragraph--default paragraph--charcoal paragraph--bold'>
                {fullName}
              </p>
              <a className='link--email' href={`mailto:${email}`}>
                {addEllipsisMiddle(email)}
              </a>
            </Tooltip>
          </div>
          <div className='card-tile__buttons-wrapper'>
            <div className='d-flex'>
              {showResendBtn ? (

                <Button title={t('reinviteHover')} type='link' className='card-tile__button card-tile__resend-btn card-tile__resend-btn--expired' onClick={reinviteClick}>
                  {userInvitationIsExpired && !invitationResend ? (
                    <div className='card-tile__resend--expired'>
                      <span className='paragraph--dark-red paragraph--small p-r-8'>Expired</span>
                      <MailOutlined className='card-tile__resend-icon--expired' />
                    </div>
                  ) : (
                    <MailOutlined className='card-tile__resend-icon' />
                  )}
                </Button>
              ) : null}
              {(isPartnerAdmin) && showInvitationLink && (
                <Button title={t('Copy invitation link')} loading={isGeneratingLink} onClick={handleGetInvitationLink} type='link' className='card-tile__edit-btn card-tile__button m-l-5'>
                  <CopyOutlined style={{ color: '#0066b3' }} className='card-tile__edit-icon' />
                </Button>
              )}
            </div>
            {isEditButtonVisible ? (
              <Button onClick={onEditClick} type='link' className='card-tile__edit-btn card-tile__button' title={t('editLabel')}>
                <EditOutlined className='card-tile__edit-icon' />
              </Button>
            ) : null}


          </div>
        </div>
        <div className={`card-tile__last-row ${rowClassName}`}>
          {renderInfo(_props)}
          <Avatar
            className='card-tile__avatar'
            size={60}
            src={`/avatars/${getRandomImage(email.length + fullName.length)}.png`}
          />
        </div>
      </div>
      {showDeleteBtn ? (
        <Button type='link' className={closeButtonClassName} onClick={handleDelete}>
          <CloseCircle />
          {' '}
        </Button>
      ) : null}
    </div>
  );
};

CardTile.propTypes = {
  email: PropTypes.string,
  renderInfo: PropTypes.func,
  userEventRoles: PropTypes.array,
  userType: PropTypes.string,
  userStatus: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  rowClassName: PropTypes.string,
  handleReinviteClick: PropTypes.func,
  userVerificationCodeTimeout: PropTypes.string,
  showDeleteBtn: PropTypes.bool,
  handleDelete: PropTypes.func,
  onEditClick: PropTypes.func,
  isEditButtonVisible: PropTypes.bool,
  closePosition: PropTypes.string,
};

CardTile.defaultProps = {
  email: '',
  renderInfo: () => { },
  userEventRoles: [],
  userType: '',
  userStatus: '',
  firstName: '',
  lastName: '',
  rowClassName: '',
  handleReinviteClick: () => { },
  userVerificationCodeTimeout: '',
  showDeleteBtn: false,
  handleDelete: () => { },
  onEditClick: () => { },
  isEditButtonVisible: false,
  closePosition: '',
};

const mapDispatchToProps = {
  handleGenerateInvitationLink: generateInvitationLink
};

const mapStateToProps = ({ auth }) => {
  const {
    userEventRoles,
    userType
  } = auth;

  return {
    userEventRoles,
    userType
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardTile);
