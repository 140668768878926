import React from 'react';
import Paragraph from '../Paragraph';
import Success from '../Icon/icons/Success';
import './PublishStatusBar.scss';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const { tabName, pendingText, publishable, published, publishText, ..._props } = props;
  const {t} = useTranslation();
  if (published) {
    return (
      <div 
        className={`
          c-publish-status--published 
          ${_props.onClick ? 'c-publish-status--unpublished' : ''}
        `}
        {..._props}
      >
        <Paragraph size='large' color='white'>
          <Success />
          {publishText}
        </Paragraph>
      </div>
    );
  }
  if (!publishable) {
    return (
      <div className='c-publish-status--pending'>
        <Paragraph size='large'>
          {t('pendingResultsLabel')} {pendingText}
        </Paragraph>
      </div>
    );
  }
  return null;
};
