import React from 'react';
import { notification } from 'antd';
import RegistrationLayout from 'shared/components/Layouts/RegistrationPageLayout';
import RegistrationForm from 'shared/components/Layouts/Registration/FormContainer';
import * as userService from 'utils/services/users';
import { useTranslation } from 'react-i18next';
import ForgotPassword from '../Forms/ForgotPassword';


const ForgetPassContainer = (props) => {
  const { history } = props;
  const { t } = useTranslation();

  const handleSendEmail = async (payload) => {
    try {
      await userService.sendResetPassEmail(payload);
      notification.success({
        message: t('successLabel'),
        description:  "Instructions to reset your password will be sent to you if your email is in our system.",
      });
      history.goBack();
    } catch (e) {
      notification.success({
        message: t('successLabel'),
        description:  "Instructions to reset your password will be sent to you if your email is in our system.",
      });
    }
  };

  return (
    <RegistrationLayout>
      <RegistrationForm title={t('forgotPasswordLabel')}>
        <ForgotPassword
          goBack={() => history.goBack()}
          handleSendEmail={handleSendEmail}
        />
      </RegistrationForm>
    </RegistrationLayout>
  );
};

export default ForgetPassContainer;
