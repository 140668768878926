import React from 'react';
import PropTypes from 'prop-types';
import EventModal from 'features/EventContainer/components/EventModal';
import EditVolunteerContent from './EditVolunteerModalContent';

const EditVolunteerModal = props => {
  const {visible, onCancel, selectedVolunteer} = props;

  return (
    <EventModal
      visible={visible}
      onCancel={onCancel}
    >
      {selectedVolunteer ? (
        <EditVolunteerContent selectedVolunteer={selectedVolunteer} closeEditModal={onCancel} />
      ) : null}
    </EventModal>
  );
};

EditVolunteerModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  selectedVolunteer: PropTypes.oneOfType([PropTypes.object, PropTypes.number])
};

EditVolunteerModal.defaultProps = {
  selectedVolunteer: null
};

export default EditVolunteerModal;
