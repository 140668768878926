import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Paragraph from 'shared/components/Paragraph';
import BtnPrimary from 'shared/components/Button/BtnPrimary';
import PublishStatusBar from 'shared/components/PublishStatusBar';
import { PUBLISHED, PUBLISHABLE } from 'shared/constants/publishStatus';
import BlockLoading from 'shared/components/Spinner/BlockLoading';
import {
  fetchEventAwards,
  fetchEventAwardTeams,
  publishEventAwards,
  fetchJudgingFeedbacks
} from 'features/EventContainer/redux/actions/awardsActions';

import AwardNominateCard from './AwardNominateCard';
import AwardConfirmModal from './AwardConfirmModal';
import Instructions from './Instructions';
import '../../styles/EventAwards.scss';

const AwardsContainer = (props) => {
  const {
    eventId,
    scheduleId,
    isFLLC,
    isFTC,
    canEdit,
    status,
    awards,
    teams,
    isFetchingAwards,
    isFetchingJudgings,
    isPublishingJudgings,
    isPublishingAwards,
    scheduleError,
    feedbackPublished,
    feedbackNotPublishable
  } = props;
  const [visible, setVisible] = useState(false);
  const [canUnpublish, setUnpublish] = useState(false);
  const published = status === PUBLISHED || (isFTC && feedbackPublished);
  const publishable = isFTC || status === PUBLISHABLE;
  const canLoadFeedback = publishable || published;
  const feedbackPending = publishable && feedbackNotPublishable;
  const { t } = useTranslation();

  useEffect(() => {
    if (!isFTC) {
      props.fetchEventAwards(eventId);
      props.fetchEventAwardTeams(eventId);
    }
  }, [eventId, isFTC]);

  useEffect(() => {
    if (scheduleId && canLoadFeedback && canEdit) {
      props.fetchJudgingFeedbacks(scheduleId, isFLLC);
    }
  }, [scheduleId, canLoadFeedback, canEdit, isFLLC]);

  const handlePublish = async () => {
    await props.publishEventAwards(eventId, canUnpublish);
    setUnpublish(false);
    setVisible(false);
  };

  const tabName = `${!isFTC ? `${t('awardsLabel')} & ` : ''} ${isFLLC ? t('judgingFeedbackLabel'): t('nonChallengeJudgingFeedbackLabel')}`;
  const feedbackLoading = canLoadFeedback && canEdit && !scheduleError && (!scheduleId || isFetchingJudgings);
  const disablePublish = (canUnpublish ? false : !publishable) || feedbackPending || isFetchingAwards || isFetchingJudgings || feedbackLoading || scheduleError;

  const publishBtnText = isFLLC ? t('publishAwardLabel') : t('nonChallengePublishAwardLabel');
  const unPublishBtnText = `${t('unpublishLabel')} ${tabName}`
  const unPubProp = published && canEdit && { onClick: () => setUnpublish(true) };

  return (
    <div className='c-event-awards'>
      <AwardConfirmModal
        is_FLLC_event={isFLLC}
        visible={visible}
        onCancel={() => setVisible(false)}
        onSubmit={handlePublish}
        isPublished={published}
        loading={isPublishingAwards || isPublishingJudgings}
        tabName={tabName}
        isFTC={isFTC}
      />
      <div className='c-event-awards__actions'>
        <PublishStatusBar
          publishText={`${!isFTC ? `${t('awardsLabel')} & ` : ''} ${isFLLC ? t('judgingFeedbackLabel'): t('nonChallengeJudgingFeedbackLabel')} Published`}
          pendingText={feedbackPending ? isFLLC ? 'and please submit judging feedback before publish.' : 'and please submit reviewing feedback before publish.' : ''}
          published={published && !canUnpublish}
          publishable={feedbackPending ? false : (publishable || canUnpublish)}
          {...unPubProp}
        />
        <BlockLoading
          loading={feedbackLoading}
          text={isFLLC ? t('loadingJudgingFeedbackLabel') : t('nonChallengeLoadingJudgingFeedbackLabel')}
        />
        {scheduleError && (
          <Paragraph size='medium' className='m-l-24 m-r-24'>Failed to load schedule</Paragraph>
        )}
        {(!published || canUnpublish) && canEdit && (
          <BtnPrimary
            loading={isPublishingJudgings || isPublishingAwards}
            onClick={() => setVisible(true)}
            disabled={disablePublish}
          >
            {canUnpublish ? unPublishBtnText : publishBtnText} 
          </BtnPrimary>
        )}
      </div>
      {!isFTC && (
        <>
          <Instructions is_FLLC_event={isFLLC}/>
          {awards.map((award, i) => (
            <AwardNominateCard key={i} {...award} teams={teams} />
          ))}
          {!isFetchingAwards && !awards.length && (
            <Paragraph size='large' className='m-l-24'>No Awards available</Paragraph>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ event }) => {
  const {
    eventAwards: {
      awards,
      teams,
      status,
      judgings,
      isFetchingAwards,
      isFetchingJudgings,
      isPublishingAwards,
      isPublishingJudgings
    },
    eventDetails: { eventScheduleId, scheduleError },
    eventSchedule: { errorFetchingEventSchedule }
  } = event;
  const hasJudgingFeedback = judgings.length > 0;
  const feedbackPublished = hasJudgingFeedback && judgings.some(({ isPublished }) => isPublished);
  const feedbackNotPublishable = hasJudgingFeedback && judgings.every(({ isPublishable }) => !isPublishable);
  return {
    awards,
    teams,
    status,
    feedbackPublished,
    feedbackNotPublishable,
    isFetchingAwards,
    isFetchingJudgings,
    isPublishingAwards,
    isPublishingJudgings,
    scheduleId: eventScheduleId,
    scheduleError: scheduleError || errorFetchingEventSchedule
  };
};

const mapDispatchToProps = {
  fetchEventAwards,
  fetchEventAwardTeams,
  publishEventAwards,
  fetchJudgingFeedbacks
};

export default connect(mapStateToProps, mapDispatchToProps)(AwardsContainer);
