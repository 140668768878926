import React from 'react';
import WarningOutlined from '@ant-design/icons';
import './FileError.scss';
import PropTypes from 'prop-types';

const FileChosen = ({text}) => {
  return (
    <div className='file-error'>
      <WarningOutlined className='file-error__icon' />
      <p className='file-error__text'>{text}</p>
    </div>
  );
};

FileChosen.propTypes = {
  text: PropTypes.string
};

FileChosen.defaultProps = {
  text: 'Error'
}

export default FileChosen;