import React from 'react';
import PropTypes from 'prop-types';
import EventPageHeader from 'shared/components/EventPageHeader';
import './styles/SubpageLayout.scss';
import ScreenLoading from 'shared/components/Spinner/ScreenLoading';


const SubpageLayout = props => {
  const { children, headerData, loading, loadingText } = props;
  if (loading) {
    return <ScreenLoading loading subTitle={loadingText} />
  }
  return (
    <div>
      <EventPageHeader loading={loading} {...headerData} />
    
      <div className='subpage-layout__content'>
        {children}        
      </div>
    
    </div>
  );
};

SubpageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  headerData: PropTypes.object,
  loading: PropTypes.bool,
  loadingText: PropTypes.string
};

SubpageLayout.defaultProps = {
  headerData: {},
  loading: false,
  loadingText: 'Loading'
};

export default SubpageLayout;
