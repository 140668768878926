import * as judgingService from 'utils/services/judging';
import * as sessionService from 'utils/services/sessions';
import SessionDocument from 'models/SessionDocument';
import * as types from '../../types/coachJudgingTypes';


const getJudgingObjectBegin = () => {
  return {
    type: types.GET_COACH_JUDGING_OBJECT_BEGIN
  }
}

const getJudgingObjectError = () => {
  return {
    type: types.GET_COACH_JUDGING_OBJECT_ERROR
  }
}

const getJudgingObjectSuccess = (judgingObject) => {
  return {
    type: types.GET_COACH_JUDGING_OBJECT_SUCCESS,
    payload: {
      judgingObject
    }
  }
}

const setIsJudgingObjectPublished = (isJudgingObjectPublished) => {
  return {
    type: types.SET_IS_COACH_JUDGING_OBJECT_PUBLISHED,
    payload: {
      isJudgingObjectPublished
    }
  }
}


export const getJudgingObject = (digital_rubric) => {
  return async (dispatch) => {
  
    if (!digital_rubric) return;
    await dispatch(getJudgingObjectBegin())
    try {
      const {data} = await judgingService.getJudgingObjectBy(digital_rubric);
      
      if (data.attributes) {
        const judgingObject = data || {};
        const {attributes} = judgingObject;
        await dispatch(getJudgingObjectSuccess(attributes))
        await dispatch(setIsJudgingObjectPublished(attributes.is_published));
      } else {
        await dispatch(setIsJudgingObjectPublished(false));
        await dispatch(getJudgingObjectError())
      }

    } catch(err) {
      await dispatch(setIsJudgingObjectPublished(false));
      await dispatch(getJudgingObjectError())
      throw err;
    }
  }

}

const getSessionRubricBegin = () => {
  return {
    type: types.GET_COACH_JUDGING_SESSION_RUBRIC_BEGIN
  }
}

const getSessionRubricError = () => {
  return {
    type: types.GET_COACH_JUDGING_SESSION_RUBRIC_ERROR
  }
}

const getSessionRubricSuccess = (sessionRubric) => {
  return {
    type: types.GET_COACH_JUDGING_SESSION_RUBRIC_SUCCESS,
    payload: {
      sessionRubric
    }
  }
}

const setIsSessionRubricPublished = (isSessionRubricPublished) => {
  return {
    type: types.SET_IS_COACH_JUDGING_SESSION_RUBRIC_PUBLISHED,
    payload: {
      isSessionRubricPublished
    }
  }
}

export const getSessionRubric = (sessionId = null) => {
  return async (dispatch, getState) => {
    const {eventOverview: {coachDetails}} = getState();
    let {judgingSessionId} = coachDetails;
    if (sessionId) judgingSessionId = sessionId;
    if (!judgingSessionId) return;
    await dispatch(getSessionRubricBegin())
    try {
      const {data, included} = await sessionService.getSessionRubrics(judgingSessionId);
      
      if (data.length) {
        const sessionDocumentObj = data[0];
        const sessionDocumentId = sessionDocumentObj.id;
        const {attributes} = sessionDocumentObj;
        const {
          is_published,
        } = attributes;
        dispatch(setIsSessionRubricPublished(Boolean(is_published)))
        
        const rubricDetails = new SessionDocument(sessionDocumentObj, included);
        const rubricFiles = rubricDetails.getFiles();
        if (rubricFiles.length) {
          const uploadedFile = rubricFiles[0];
          const sessionRubric = {
            ...uploadedFile,
            sessionDocumentId
          }
          dispatch(getSessionRubricSuccess(sessionRubric));
        } else {
          dispatch(getSessionRubricError())
        }
      } else {
        dispatch(getSessionRubricError())
      }
      

    } catch (err) {
      await dispatch(getSessionRubricError())
      throw err;
    }

  }

}