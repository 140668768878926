import { Tooltip, notification } from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FILE_UPLOAD_INTERRUPTED_MESSAGE, UPLOAD_FILE_SIZE_LIMIT } from 'shared/constants/fileUpload';
import Input from 'shared/components/Input';
import BtnPrimary from 'shared/components/Button/BtnPrimary';
import BtnSecondary from 'shared/components/Button/BtnSecondary';
import Paragraph from 'shared/components/Paragraph';
import Heading from 'shared/components/Heading';
import MinusCircle from 'shared/components/Icon/icons/MinusCircle';
import CustomLink from 'shared/components/Link';
import FileCard from 'shared/components/FileCard';
import ChooseFileCard from 'shared/components/ChooseFileCard';
import EventModal from 'features/EventContainer/components/EventModal';
import * as fileExtensions from 'shared/constants/fileExtensions';
import { getSponsorFile, getSponsorStatus } from 'utils/services/eventFiles';

import get from 'lodash/get'
import find from 'lodash/find'
import { connect } from 'react-redux';

const AddSponsor = (props) => {
  const { t } = useTranslation();
  const {
    sponsor,
    onCancel,
    uploadSponsorLogo,
    saveSponsor,
    deleteSponsor,
    setUploadModalVisibility,
    setUploadProgress,
    eventScheduleId
  } = props;

  const { id, name } = sponsor;
  const [sponsorName, setSponsorName] = useState(name || '');
  const [src, setUrl] = useState('');
  const [imgError, setImgError] = useState(false);
  const [fileStatus, setFileStatus] = useState([])
  const [isDelete, setIsDelete] = useState(false)

  const __handleUploadProgress = event => {
    const progress = ~~(event.loaded / event.total * 100)
    setUploadProgress(progress)
  }

  const __handleUploadError = error => {
    if (error && 'data' in error && error.data.error) {
      notification.error({
        message: error.data.error,
        duration: 0,
        onClose: () => setUploadModalVisibility(false)
      });
    } else {
      notification.error({
        message: FILE_UPLOAD_INTERRUPTED_MESSAGE,
        description: 'Please try again later',
        duration: 0,
        onClose: () => setUploadModalVisibility(false)
      });
    }
    console.warn(error)
  }

  const handleUpload = async ({ file }) => {
    if (file.size > UPLOAD_FILE_SIZE_LIMIT) {
      notification.error({
        message: 'File size exceeds upload limit',
        description: 'Cannot upload a file larger than 5GB',
        duration: 5,
      });
    } else {
      setUploadModalVisibility(true)
      await uploadSponsorLogo(id, sponsorName, file, __handleUploadProgress, __handleUploadError);
      // setUrl(true);
      await handleSponsors()
    }
  }

  const handleDelete = () => {
    deleteSponsor(id);
    onCancel();
  };

  useEffect(() => {
    setSponsorName(name || '');
  }, [name]);

  useEffect(() => {
    setUrl('');
    if (id) {
      const getFile = async () => {
        await handleSponsors()
      };
      getFile();
    }
  }, [id]);

  const handleSponsors = async () => {
    try {

      let sponsorsStatus = await getSponsorStatus(eventScheduleId)
      setFileStatus(sponsorsStatus)

      sponsorsStatus.forEach(async sponsor => {
        if (sponsor.sponsorId == id && sponsor.fileStatus == 6) {
          const response = await getSponsorFile(id);
          const presignedUrlObject = response.included && find(response.included, res => res.type === 'pre-signed_url')
          const presignedUrl = get(presignedUrlObject, ['attributes', 'url'], '')
          setUrl(presignedUrl);
          setIsDelete(false)
        }
      });

    } catch (e) {
      console.log(e)
      let sponsorsStatus = await getSponsorStatus(eventScheduleId)
      setFileStatus(sponsorsStatus)
      setIsDelete(false)
    }
  }

  const getStatus = () => {
    let matchedStatus = fileStatus.filter((data) => data.sponsorId == id)
    return matchedStatus[0] !== undefined ? matchedStatus[0] : null
  }

  return (
    <EventModal {...props}>
      <div className='modal__title'>
        <Heading tag={2} type='primary' weight='bold'>
          {t(id ? 'editSponsorLabel' : 'addSponsorLabel')}
        </Heading>
        <Tooltip title={t('newSponsorTooltip')} placement='left'>
          <span><InfoCircleOutlined style={{ color: '#0066B3' }} /></span>
        </Tooltip>
      </div>
      <div className='c-event-sponsors__add-content'>
        <div>
          <div className='c-event-sponsors__add-left'>
            <Paragraph size='large' weight='bold' className='m-b-8'>
              {t('sponsorNameLabel')}
            </Paragraph>
            <Input
              value={sponsorName}
              size='large'
              placeholder={t('sponsorNameLabel')}
              disabled={false}
              onChange={(e) => setSponsorName(e.target.value)}
            />
            {!!id && (
              <div className='c-event-sponsors__delete'>
                <CustomLink
                  customText="Delete Sponsor"
                  onClick={handleDelete}
                  LinkIcon={MinusCircle}
                />
              </div>
            )}
          </div>
          <div className='c-event-sponsors__add-right'>
            <Paragraph size='large' weight='bold' className='m-b-8'>
              {t('sponsorLogoLabel')}
            </Paragraph>
            
            {(id && !isDelete)
              ? (
                <FileCard
                  iconType='image'
                  fileName={`${sponsorName}.jpg`}
                  onDelete={() => { setUrl(''); setIsDelete(true) }}
                  wide
                  hasDeleteBtn
                  imageUrl={src != '' ? src : ''}
                  error={imgError}
                  status={getStatus()}
                  modal={true}
                />
              )
              : (
                <ChooseFileCard
                  fileType='image'
                  disabled={sponsorName === ''}
                  accept={fileExtensions.IMAGE_EXTENSIONS.join(',')}
                  onUpload={handleUpload}
                />
              )}
            <Paragraph size='medium' className='m-t-12'>
              {t('imageInstructionTextLabel')}
              .
            </Paragraph>
          </div>
        </div>
        <div>
          <BtnSecondary key="back" onClick={onCancel} additionalClassNames='m-r-20'>
            {t('cancelLabel')}
          </BtnSecondary>
          <BtnPrimary key="submit" onClick={() => saveSponsor({ id, name: sponsorName })} disabled={!sponsorName || !id || isDelete}>
            {t('saveLabel')}
          </BtnPrimary>
        </div>
      </div>
    </EventModal>
  );
};

AddSponsor.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  setUploadModalVisibility: PropTypes.func,
  setUploadProgress: PropTypes.func,
};

function mapStateToProps(state) {
  const {
    event: { eventDetails: { eventScheduleId } },
  } = state;
  return {
    eventScheduleId
  }
}

export default connect(mapStateToProps)(AddSponsor);
