import React from 'react';
import PropTypes from 'prop-types';
import { DownloadOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { connect } from 'react-redux';
import {
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FTC_ID
} from 'shared/constants/programIds';
import SpreadSheetIcon from './icons/RubricSpreadsheetIcon';
import RubricPDFIcon from './icons/RubricsPdfIcon';
import DownloadIcon from './icons/RubricDownloadIcon';
import i18n from 'utils/i18n';

const DownloadRubricForm = props => {
  const {
    template,
    programId
  } = props;

  const boldClassName = 'paragraph--charcoal paragraph--medium paragraph--bold';
  // const normalClassName = 'paragraph--charcoal paragraph--medium paragraph--normal'

  const FLL_DISCOVER_FILES = {
    'en': 'FLL-Discover-Reviewing.xlsx',
    'pt': 'FLL Discover Review Sheet.xlsx_traduzido.xlsx',
    'ja': 'Japanese_FLL Discover Review Sheet.xlsx',
    'es': 'Spanish_FLL Discover Review Sheet.xlsx',
    'tr': 'TR_FLL Discover Review Sheet.xlsx',
  }

  const FLL_EXPLORE_FILES = {
    'en': 'fll-explore-cargo-connect-reviewing-documents-fillable.pdf',
    // 'en': 'FLL-Explore-Reviewing.xlsx',
    'pt': 'FLL Explore Review Sheet.xlsx_traduzido.xlsx',
    'ja': 'Japanese_FLL Explore Review Sheet.xlsx',
    'es': 'Spanish_ FLL Explore Review Sheet.xlsx',
    'nl': 'Dutch FLL Explore Review Sheet.xlsx',
  }

  const FTC_FILES = {
    'en': 'FTC-Judging-Feedback.xlsx',
  }

  let downloadLink = '';
  let displayLinkText = '';
  const { language } = i18n;

  if (programId === FLL_DISCOVER_ID) {
    downloadLink = FLL_DISCOVER_FILES[language];
    if (!downloadLink) downloadLink = FLL_DISCOVER_FILES.en;
    displayLinkText = 'FLL-Discover-Reviewing.xlsx'
  } else if (programId === FLL_EXPLORE_ID) {
    downloadLink = FLL_EXPLORE_FILES[language];
    if (!downloadLink) downloadLink = FLL_EXPLORE_FILES.en;
    displayLinkText = 'FLL-Explore-Reviewing.pdf'
  } else if (programId === FTC_ID) {
    downloadLink = FTC_FILES[language];
    if (!downloadLink) downloadLink = FTC_FILES.en;
    displayLinkText = 'FTC-Judging-Feedback.xlsx'
  }
  console.log(downloadLink, language, displayLinkText)
  return (
    <a href={`/rubrics/${downloadLink}`} download={displayLinkText} className='c-rubric-upload__download'>
      <div className='c-rubric-upload__download-icons'>
        {programId === FLL_EXPLORE_ID ? (
          <Icon component={RubricPDFIcon} className='p-r-8' />
        ) : (
          <Icon component={SpreadSheetIcon} style={{ color: '#52C41A' }} className='p-r-8' />
        )}
        <DownloadOutlined />
      </div>
      <div className='c-rubric-upload__download-text'>
        <p className={boldClassName}>{displayLinkText}</p>
      </div>
    </a>
  );
};

DownloadRubricForm.propTypes = {
  template: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  const { judgingRubric: { eventDetails } } = state;
  const {
    programId
  } = eventDetails
  return {
    programId
  }
}

export default connect(mapStateToProps)(DownloadRubricForm);