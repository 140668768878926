const m14Section = {
  id: 'm14',
  name: '2025ScoresheetM14Sub1Label',
  fields: [
    {
      scoring: '2025ScoresheetM14Sub2Label',
      id: 'm14_scoring_1',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        },
      ]
    },
    {
      scoring: '2025ScoresheetM14Sub3Label',
      id: 'm14_scoring_2',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        },
      ]
      },
      {
          scoring: '2025ScoresheetM14Sub4Label',
          id: 'm14_scoring_3',
          options: [
              {
                  description: 'noLabel',
                  value: false
              },
              {
                  description: 'yesLabel',
                  value: true
              },
          ]
      },
      {
          scoring: '2025ScoresheetM14Sub5Label',
          id: 'm14_scoring_4',
          options: [
              {
                  description: '0',
                  value: 0
              },
              {
                  description: '1',
                  value: 1
              },
              {
                  description: '2',
                  value: 2
              },
          ]
      },
  ]
}

export default m14Section;

