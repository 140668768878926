import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Pagination, { getPageFilter, initialPage } from 'shared/components/Pagination';
import TitleSortRow from 'shared/components/TitleSortRow';
import PartnersList from 'shared/components/CardsList/Partners';
import AddModal from 'shared/components/Modal';

import { fetchPartnerAdmins, resendUserInvitation, handleSetSelectedEditPartner } from 'features/AdminDashboard/redux/actions/partnersActions';
import { fetchPrograms } from 'features/AdminDashboard/redux/actions/programsActions';
import SearchBar from 'features/AdminDashboard/components/SearchBar';
import AddPartner from './AddPartner';
import EditPartner from './EditPartnerModal';

import '../../styles/AdminDashboard.scss';
import { partnersSortBy } from 'shared/constants/sortOptions';

const PartnersContainer = props => {
  const { partnerUsers, count, isFetchingUsers, newPartnerInvited, reinvitePartnerAdmin, setSelectedPartner } = props;
  const [current, setCurrentPage] = useState(initialPage);
  const [searchText, setSearchText] = useState('');
  const [visible, setVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [sortBy, setSortBy] = useState('');


  const { t } = useTranslation()

  const fetchPagePartners = useCallback((number, nosearch) => {
    const page = getPageFilter(number);
    props.fetchPartnerAdmins(page, !nosearch && searchText, sortBy);
  }, [searchText, sortBy]);

  const handleSearch = (nosearch) => {
    setCurrentPage(pre => {
      if (pre === initialPage && searchText) {
        fetchPagePartners(initialPage, nosearch);
      }
      return initialPage;
    });
  };

  useEffect(() => {
    fetchPagePartners(current);
  }, [current, sortBy]);

  useEffect(() => {
    if (newPartnerInvited) {
      setCurrentPage(pre => {
        if (pre === initialPage) {
          fetchPagePartners(initialPage);
        }
        return initialPage;
      });
    }
  }, [newPartnerInvited]);

  useEffect(() => {
    props.fetchPrograms();
  }, []);

  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  const closeEditModal = () => setEditModalVisible(false);
  const openEditModal = (id) => {

    const selectedPartner = partnerUsers.find(p => p.id === id);
    if (selectedPartner) {
      setSelectedPartner(selectedPartner)
    }
    setEditModalVisible(true)
  
  };

  const formattedPartnerUsers = partnerUsers.map(p => {
    return {
      ...p,
      handleReinviteClick: () => { reinvitePartnerAdmin(p.id)}
    }
  })

  const onSortBySelected = (value)=>{
    setSortBy(value);
  }

  return (
    <div className="admin-dashboard">
      <AddModal
        fullTabs
        visible={visible}
        onCancel={closeModal}
        renderHeader={() => <span>{t('addPartnerLabel')}</span>}
      >
        <AddPartner />
      </AddModal>
      <EditPartner visible={editModalVisible} onCancel={closeEditModal} />
      <SearchBar
        isLinkVisible
        placeholder="Partner Name"
        customText={t('addPartnerLabel')}
        addClick={openModal}
        searchText={searchText}
        setSearchText={setSearchText}
        handleSearch={handleSearch}
      />
      <div className="admin-dashboard__list">
        <TitleSortRow title={t('partnersLabel')} options={partnersSortBy} onSelect={onSortBySelected}/>
        <PartnersList
          isEditButtonVisible
          partners={formattedPartnerUsers}
          loading={isFetchingUsers}
          onEditClick={openEditModal}
        />
        <Pagination
          total={count}
          current={current}
          onChange={setCurrentPage}
          loading={isFetchingUsers}
        />
      </div>
    </div>
  );
};

PartnersContainer.propTypes = {
  partnerUsers: PropTypes.array.isRequired,
  reinvitePartnerAdmin: PropTypes.func.isRequired,
  setSelectedPartner: PropTypes.func.isRequired,
};

const mapStateToProps = ({ adminBoard }) => {
  const { partners: { partnerUsers, count, isFetchingUsers, newPartnerInvited } } = adminBoard;
  return {
    partnerUsers,
    count,
    isFetchingUsers,
    newPartnerInvited
  };
};

const mapDispatchToProps = {
  fetchPrograms,
  fetchPartnerAdmins,
  reinvitePartnerAdmin: resendUserInvitation,
  setSelectedPartner: handleSetSelectedEditPartner
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnersContainer);
