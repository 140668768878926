import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BtnPrimary from 'shared/components/Button/BtnPrimary';
import Checkbox from 'shared/components/Checkbox';
import DeleteEventModal from 'shared/components/DeleteEventModal';
import Paragraph from 'shared/components/Paragraph';
import {
  deleteEvent,
  clearEventDeleteStatus
} from 'features/AdminDashboard/redux/actions/eventsActions';
import { useTranslation } from 'react-i18next';

const DeleteEventSection = (props) => {
  const { canDelete, eventName, isDeletingEvent, hasEventDeleted } = props;
  const [confirmed, setConfirm] = useState(false);
  const [visible, setVisible] = useState(false);
  const { id: eventId } = useParams();
  const browserHistory = useHistory();
  const {t} = useTranslation();

  useEffect(() => {
    if (hasEventDeleted) {
      props.clearEventDeleteStatus();
      browserHistory.push('/admin');
    }
  }, [hasEventDeleted]);

  if (!canDelete) {
    return null;
  }

  const cancelDelete = () => setVisible(false);
  const openDeleteModal = () => setVisible(true);

  const handleDeleteEvent = () => {
    if (eventId) {
      props.deleteEvent(eventId);
    }
  };

  const handleConfirm = (e) => {
    setConfirm(e.target.checked);
  };

  return (
    <section className='event-schedule-section__delete-event'>
      <DeleteEventModal
        visible={confirmed && visible}
        onCancel={cancelDelete}
        onSubmit={handleDeleteEvent}
        loading={isDeletingEvent}
        eventName={eventName}
      />
      <Paragraph weight='bold' className='m-r-12'>{t('ScheduleDeleteEventLabel')} </Paragraph>
      <Checkbox checked={confirmed} onChange={handleConfirm}>
        <Paragraph className='m-r-4'>
          {t('ScheduleDeleteEventCheckLabel')}
        </Paragraph>
      </Checkbox>
      <BtnPrimary
        additionalClassNames='event-schedule-section__delete-btn'
        size='medium'
        disabled={!confirmed}
        onClick={openDeleteModal}
      >
        {t('ScheduleDeleteConfirmLabel')}
      </BtnPrimary>
    </section>
  );
};

DeleteEventSection.propTypes = {
  canDelete: PropTypes.bool
};

DeleteEventSection.defaultProps = {
  canDelete: false
};

const mapStateToProps = (state) => {
  const {
    adminBoard: {
      events: {
        isDeletingEvent,
        hasEventDeleted
      }
    }
  } = state;
  return {
    isDeletingEvent,
    hasEventDeleted
  };
};

export default connect(
  mapStateToProps,
  { deleteEvent, clearEventDeleteStatus }
)(DeleteEventSection);
