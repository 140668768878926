import React from 'react';
import PropTypes from 'prop-types';

const Document = ({fill}) => {
  return (
    <svg viewBox="0 0 45 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      <g id="Judging-and-Awards" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Teams---Single-Event---Judging---Coach" transform="translate(-533.000000, -958.000000)" fill={fill}>
          <g id="Group-32" transform="translate(250.000000, 277.000000)">
            <g id="Group-19" transform="translate(0.000000, 530.000000)">
              <g id="Group-14" transform="translate(0.000000, 56.000000)">
                <g id="Group-11" transform="translate(60.000000, 69.000000)">
                  <g id="Group-39-Copy-8" transform="translate(165.000000, 3.000000)">
                    <g id="noun_Document_1570104" transform="translate(58.000000, 23.000000)">
                      <path d="M1.41935484,-5.50908288e-06 C0.656167742,-5.50908288e-06 -0.000567741935,0.76684186 -3.67832091e-07,1.39538372 L-3.67832091e-07,58.6046651 C-3.67832091e-07,59.3352698 0.676180645,60.000014 1.41935484,60.000014 L42.5806459,60.000014 C43.3238201,60.000014 43.9999996,59.3352698 43.9999996,58.6046651 L43.9999996,13.9535233 C43.9999996,13.5820465 43.8474201,13.2118256 43.5785943,12.9506163 L30.8044007,0.392476744 C30.5403297,0.14132093 30.1739233,-0.00277674419 29.8064523,-5.50908288e-06 L1.41935484,-5.50908288e-06 Z M2.83870968,2.79073256 L28.3870975,2.79073256 L28.3870975,13.9535233 C28.3871685,14.684107 29.0632781,15.3488023 29.8064523,15.3488721 L41.161291,15.3488721 L41.161291,57.2093163 L2.83870968,57.2093163 L2.83870968,2.79073256 Z M31.2258072,4.75294186 L39.1653233,12.5581744 L31.2258072,12.5581744 L31.2258072,4.75294186 Z M9.22580716,23.7209302 C8.44189748,23.7209302 7.80645232,24.3456279 7.80645232,25.1162791 C7.80645232,25.8869302 8.44189748,26.5116279 9.22580716,26.5116279 L34.7741943,26.5116279 C35.5581039,26.5116279 36.1935491,25.8869302 36.1935491,25.1162791 C36.1935491,24.3456279 35.5581039,23.7209302 34.7741943,23.7209302 L9.22580716,23.7209302 Z M9.22580716,34.8837349 C8.44189748,34.8837349 7.80645232,35.5084326 7.80645232,36.2790837 C7.80645232,37.0497349 8.44189748,37.6744326 9.22580716,37.6744326 L34.7741943,37.6744326 C35.5581039,37.6744326 36.1935491,37.0497349 36.1935491,36.2790837 C36.1935491,35.5084326 35.5581039,34.8837349 34.7741943,34.8837349 L9.22580716,34.8837349 Z M9.22580716,46.0465256 C8.44189748,46.0465256 7.80645232,46.6712233 7.80645232,47.4418744 C7.80645232,48.2125256 8.44189748,48.8372233 9.22580716,48.8372233 L34.7741943,48.8372233 C35.5581039,48.8372233 36.1935491,48.2125256 36.1935491,47.4418744 C36.1935491,46.6712233 35.5581039,46.0465256 34.7741943,46.0465256 L9.22580716,46.0465256 Z" id="Shape" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Document.propTypes = {
  fill: PropTypes.string
}

Document.defaultProps = {
  fill: 'currentColor'
}
export default Document;