export const GET_EVENT_REFEREES_BEGIN = 'GET_EVENT_REFEREES_BEGIN';
export const GET_EVENT_REFEREES_ERROR = 'GET_EVENT_REFEREES_ERROR';
export const GET_EVENT_REFEREES_SUCCESS = 'GET_EVENT_REFEREES_SUCCESS';

export const GET_EVENT_SCORING_SESSIONS_BEGIN = 'GET_EVENT_SCORING_SESSIONS_BEGIN';
export const GET_EVENT_SCORING_SESSIONS_ERROR = 'GET_EVENT_SCORING_SESSIONS_ERROR';
export const GET_EVENT_SCORING_SESSIONS_SUCCESS = 'GET_EVENT_SCORING_SESSIONS_SUCCESS';

export const GET_EVENT_SCORING_PANELS_BEGIN = 'GET_EVENT_SCORING_PANELS_BEGIN';
export const GET_EVENT_SCORING_PANELS_ERROR = 'GET_EVENT_SCORING_PANELS_ERROR';
export const GET_EVENT_SCORING_PANELS_SUCCESS = 'GET_EVENT_SCORING_PANELS_SUCCESS';

export const FETCH_SELECTED_SCORING_PANEL_BEGIN = 'FETCH_SELECTED_SCORING_PANEL_BEGIN';
export const FETCH_SELECTED_SCORING_PANEL_ERROR = 'FETCH_SELECTED_SCORING_PANEL_ERROR';
export const FETCH_SELECTED_SCORING_PANEL_SUCCESS = 'FETCH_SELECTED_SCORING_PANEL_SUCCESS';

export const RESET_SELECTED_SCORING_PANEL = 'RESET_SELECTED_SCORING_PANEL';

export const REMOVE_REFEREE_FROM_SCORING_PANEL_BEGIN = 'REMOVE_REFEREE_FROM_SCORING_PANEL_BEGIN';
export const REMOVE_REFEREE_FROM_SCORING_PANEL_ERROR = 'REMOVE_REFEREE_FROM_SCORING_PANEL_ERROR';
export const REMOVE_REFEREE_FROM_SCORING_PANEL_SUCCESS = 'REMOVE_REFEREE_FROM_SCORING_PANEL_SUCCESS';

export const REMOVE_SESSION_FROM_SCORING_PANEL_BEGIN = 'REMOVE_SESSION_FROM_SCORING_PANEL_BEGIN';
export const REMOVE_SESSION_FROM_SCORING_PANEL_ERROR = 'REMOVE_SESSION_FROM_SCORING_PANEL_ERROR';
export const REMOVE_SESSION_FROM_SCORING_PANEL_SUCCESS = 'REMOVE_SESSION_FROM_SCORING_PANEL_SUCCESS';

export const ADD_REFEREES_TO_SCORING_PANEL_BEGIN = 'ADD_REFEREES_TO_SCORING_PANEL_BEGIN';
export const ADD_REFEREES_TO_SCORING_PANEL_ERROR = 'ADD_REFEREES_TO_SCORING_PANEL_ERROR';
export const ADD_REFEREES_TO_SCORING_PANEL_SUCCESS = 'ADD_REFEREES_TO_SCORING_PANEL_SUCCESS';

export const ADD_SESSIONS_TO_SCORING_PANEL_BEGIN = 'ADD_SESSIONS_TO_SCORING_PANEL_BEGIN';
export const ADD_SESSIONS_TO_SCORING_PANEL_ERROR = 'ADD_SESSIONS_TO_SCORING_PANEL_ERROR';
export const ADD_SESSIONS_TO_SCORING_PANEL_SUCCESS = 'ADD_SESSIONS_TO_SCORING_PANEL_SUCCESS';

export const UPDATE_SELECTED_SCORING_PANEL = 'UPDATE_SELECTED_SCORING_PANEL';

export const SET_SCORING_SESSION_TEAMS = 'SET_SCORING_SESSION_TEAMS'


export const CREATE_NEW_SCORING_PANEL_BEGIN = 'CREATE_NEW_SCORING_PANEL_BEGIN';
export const CREATE_NEW_SCORING_PANEL_ERROR = 'CREATE_NEW_SCORING_PANEL_ERROR';
export const CREATE_NEW_SCORING_PANEL_SUCCESS = 'CREATE_NEW_SCORING_PANEL_SUCCESS';

export const EDIT_SCORING_PANEL_BEGIN = 'EDIT_SCORING_PANEL_BEGIN'
export const EDIT_SCORING_PANEL_ERROR = 'EDIT_SCORING_PANEL_ERROR'
export const EDIT_SCORING_PANEL_SUCCESS = 'EDIT_SCORING_PANEL_SUCCESS'

export const SET_SELECTED_SCORING_PANEL_DATA = 'SET_SELECTED_SCORING_PANEL_DATA';
export const SET_SCORING_PANELS_EXPANDS = 'SET_SCORING_PANELS_EXPANDS';
