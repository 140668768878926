
import { Collapse, Row, Col, Input, Form} from 'antd';
import Icon from '@ant-design/icons';
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import UpArrowBlue from 'shared/components/Icon/icons/UpArrowBlue';
import DownArrowBlue from 'shared/components/Icon/icons/DownArrowBlue';
import '../styles/index.scss';
import BlockLoading from 'shared/components/Spinner/BlockLoading';
import { eventTimes } from 'shared/constants/eventTimes';
import Select from 'shared/components/Dropdown';
import {combineDateAndTime, formatDateToReadableStr, formatDateToReadableStrThree/*, formatReadableStrToDate*/ } from 'features/EventContainer/utils/formatTimeString';
//import moment from 'moment';
import Checkbox from 'shared/components/Checkbox';
import { connect } from 'react-redux';

const DisabledOverlay = (checked)=>{
    return checked ? null : <div className="opacity-overlay" />;
}


const EventSessionDurationBlock = (props)=>{
  const { form, isLoadingDays, type, time_zone, days, use_24_hour_format, is_FLLC_event } = props;
  const times_list = eventTimes(0, ['am', 'pm',], 15, use_24_hour_format);
  const { t } = useTranslation();
  const scheduleDetail = (((days||{}).included||[]).filter(i=>i.type === "schedule")[0]||{}).attributes||{};

  const days_list = ((days||{}).data||[]).map(d=>{
    const {id, attributes} = d||{};
    const {start_time, end_time, date} = attributes;
    return {
      date: date ? formatDateToReadableStr(date) : date,
      id,
      start_time,
      end_time,
    }
  });
  const {
    default_judging_session_buffer, 
    default_judging_session_duration, 
    default_scoring_session_buffer, 
    default_scoring_session_duration, 
    default_judging_session_start_time, 
    default_official_scoring_session_start_time,
    default_practice_scoring_session_start_time,
    include_judging_sessions_when_scheduling, 
    include_official_referee_sessions_when_scheduling, 
    include_practice_referee_sessions_when_scheduling, 
    default_judging_session_team_travel_time_when_scheduling,
    default_referee_session_team_travel_time_when_scheduling,
  } = scheduleDetail;

  const formatHoursIntoMinutes = (h)=>{
    const [hour, minute/*, second*/] = (h||'00:00').split(':');
    return parseInt(hour) * 60 + parseInt(minute);
  }

  const DEFAULT_START_TIME = use_24_hour_format ? '08:00' : '08:00 am';

  const defaultJudgingStart = formatDateToReadableStr(default_judging_session_start_time, time_zone);
  const defaultOfficialStart = formatDateToReadableStr(default_official_scoring_session_start_time, time_zone)
  const defaultPracticeStart  = formatDateToReadableStr(default_practice_scoring_session_start_time, time_zone)
  const defaultJudgingStartTime = default_judging_session_start_time ? formatDateToReadableStrThree(default_judging_session_start_time, time_zone, use_24_hour_format): DEFAULT_START_TIME;
  const defaultPracticeStartTime = default_official_scoring_session_start_time ? formatDateToReadableStrThree(default_official_scoring_session_start_time, time_zone, use_24_hour_format): DEFAULT_START_TIME;
  const defaultOfficialStartTime = default_practice_scoring_session_start_time ? formatDateToReadableStrThree(default_practice_scoring_session_start_time, time_zone, use_24_hour_format): DEFAULT_START_TIME;
  const judgingDuration = formatHoursIntoMinutes(default_judging_session_duration);
  const judgingBuffer = formatHoursIntoMinutes(default_judging_session_buffer);
  const scoringDuration = formatHoursIntoMinutes(default_scoring_session_duration);
  const scoringBuffer = formatHoursIntoMinutes(default_scoring_session_buffer);
  const refereeSessionTeamTravelTime = formatHoursIntoMinutes(default_referee_session_team_travel_time_when_scheduling);
  const judgingSessionTeamTravelTime = formatHoursIntoMinutes(default_judging_session_team_travel_time_when_scheduling);

  
  
  const [judgingStart, setJudgingStart] = useState(defaultJudgingStart);

  const [judgingStartTime, setJudgingStartTime] = useState(defaultJudgingStartTime)
  const [officialStart, setOfficialStart] = useState(defaultOfficialStart);
  const [officialStartTime, setOfficialStartTime] = useState(defaultPracticeStartTime)
  const [practiceStart, setPracticeStart] = useState(defaultPracticeStart);
  const [practiceStartTime, setPracticeStartTime] = useState(defaultOfficialStartTime)
  const [jDuration, setJDuration] = useState(judgingDuration)
  const [jBuffer, setJBuffer] = useState(judgingBuffer)
  const [sDuration, setSDuration] = useState(scoringDuration)
  const [sBuffer, setSBuffer] = useState(scoringBuffer)
  const [rTravelTime, setRTravelTime] = useState(refereeSessionTeamTravelTime)
  const [jTravelTime, setJTravelTime] = useState(judgingSessionTeamTravelTime)

  const isJudgingSession = type === 'judging';
  const isScoringSession = type === 'scoring';
 
  const [judgingChecked, setJudgingChecked] = useState(include_judging_sessions_when_scheduling);
  const [practiceChecked, setPracticeChecked] = useState(include_practice_referee_sessions_when_scheduling);
  const [officialChecked, setOfficialChecked] = useState(include_official_referee_sessions_when_scheduling);

  useEffect(()=>{
    setJudgingStart(defaultJudgingStart);
    setJudgingStartTime(defaultJudgingStartTime)
    setOfficialStart(defaultOfficialStart);
    setOfficialStartTime(defaultPracticeStartTime)
    setPracticeStart(defaultPracticeStart);
    setPracticeStartTime(defaultOfficialStartTime)
    setJDuration(judgingDuration)
    setJBuffer(judgingBuffer)
    setSDuration(scoringDuration)
    setSBuffer(scoringBuffer)
    setRTravelTime(refereeSessionTeamTravelTime)
    setJTravelTime(judgingSessionTeamTravelTime)
    setJudgingChecked(include_judging_sessions_when_scheduling);
    setPracticeChecked(include_practice_referee_sessions_when_scheduling);
    setOfficialChecked(include_official_referee_sessions_when_scheduling);
  },[defaultJudgingStart,
    defaultJudgingStartTime,
    defaultOfficialStart,
    defaultPracticeStartTime,
    defaultPracticeStart,
    defaultOfficialStartTime,
    judgingDuration,
    judgingBuffer,
    scoringDuration,
    scoringBuffer,
    refereeSessionTeamTravelTime,
    judgingSessionTeamTravelTime,
    include_judging_sessions_when_scheduling,
    include_practice_referee_sessions_when_scheduling,
    include_official_referee_sessions_when_scheduling])

  const handleExpandIcon = (panelProps) => {
    const {isActive} = panelProps;
    if (isActive) {
      return <Icon component={UpArrowBlue}/>
    }

    return <Icon component={DownArrowBlue} />
  }

  const blockTitle = isJudgingSession ? is_FLLC_event ? t('AutoGenerateJudgingSessionsHeaderLabel') : t('nonChallengeAutoGenerateJudgingSessionsHeaderLabel') : isScoringSession ? t('AutoGenerateScoringSessionsHeaderLabel') : "";

  const setFieldsWhenCheckboxChanged = (key, value)=>{
    const values = form.getFieldsValue();
    const durations = values.durations;
    form.setFieldsValue({'durations': {...durations, [key]:value}})
  }

  const handleJudgingChecked = (e)=>{
    const value = e.target.checked;
    setJudgingChecked(value);
    setFieldsWhenCheckboxChanged("include_judging_sessions_when_scheduling", value)
  }
  const handlePracticeChecked = (e)=>{
    const value = e.target.checked;
    setPracticeChecked(value);
    setFieldsWhenCheckboxChanged("include_practice_referee_sessions_when_scheduling", value)
  }
  const handleOfficialChecked = (e)=>{
    const value = e.target.checked;
    setOfficialChecked(value);
    setFieldsWhenCheckboxChanged("include_official_referee_sessions_when_scheduling", value)
  }

  const Checkboxes = ()=>{
    if(isJudgingSession){
      return (
        <Col xs={24} sm={24} md={8} className="checkbox-column">
        <div onClick={e=>e.stopPropagation()}>
        <Checkbox 
          onClick={e=>e.stopPropagation()} 
          onChange={handleJudgingChecked} 
          checked={judgingChecked}
          >
          {judgingChecked ? "Uncheck to exclude" : "Check to include"}
        </Checkbox>
        </div>
        </Col>
      )
    }
    if(isScoringSession){
      return (
        <>
        <Col xs={24} sm={24} md={8} className="checkbox-column">
        <div onClick={e=>e.stopPropagation()}>
          <Checkbox 
            onClick={e=>e.stopPropagation()} 
            onChange={handlePracticeChecked} 
            checked={practiceChecked}
            >
            {practiceChecked ? "Uncheck to exclude Practice session" : "Check to include Practice session"}
          </Checkbox>
          </div>
          </Col>
          <Col xs={24} sm={24} md={8} className="checkbox-column">
          <div onClick={e=>e.stopPropagation()}>
          <Checkbox 
            onClick={e=>e.stopPropagation()} 
            onChange={handleOfficialChecked} 
            checked={officialChecked}
            >
            {officialChecked ? "Uncheck to exclude Official session" : "Check to include Official session"}
          </Checkbox>
          </div>
          </Col>
        </>
      )
    }
  }

  const Header = (
    <div className="time-blocker-header">
    <Row type="flex" align="middle">
      <Col xs={24} sm={24} md={8}>
      <span className="title">{blockTitle}</span>
      </Col>
      {Checkboxes()}
    </Row>
    </div>
  )

  const ContentBlock = ()=>{

    const formatMunitesIntoHourse = (m)=>{
      const hour = Math.floor(m/60);
      const minute = m % 60;
      const formatted_hour = hour>=10 ? hour : `0${hour}`;
      const formatted_minute = minute>=10 ? minute : `0${minute}`;
      return `${formatted_hour}:${formatted_minute}:00`;
    }

    const updateForm = (key, value)=>{
      const values = form.getFieldsValue();
      const durations = values.durations;
      form.setFieldsValue({'durations': {...durations, [key]:value}})
    }

    if(!default_judging_session_buffer ||
      !default_judging_session_duration ||
      !default_scoring_session_buffer ||
      !default_scoring_session_duration){
        return null
      }

    const onJudingStartDateChange = (id)=>{
      let day = days_list.find(d=>d.id===id);
      if(!day) return;
      const value = day.date;
      setJudgingStart(value)
      const new_date = combineDateAndTime(value, judgingStartTime);
      updateForm('default_judging_session_start_time', new_date)
    }
    
    const onJudingStartTimeChange = (value)=>{
      setJudgingStartTime(value);
      const new_date = combineDateAndTime(judgingStart, value);
      updateForm('default_judging_session_start_time', new_date)
    }

    const onOfficialStartDateChange = (id)=>{
      let day = days_list.find(d=>d.id===id);
      if(!day) return;
      const value = day.date;
      setOfficialStart(value);
      const new_date = combineDateAndTime(value, officialStartTime);
      updateForm('default_official_scoring_session_start_time', new_date)
    }
    
    const onOfficialStartTimeChange = (value)=>{
      setOfficialStartTime(value);
      const new_date = combineDateAndTime(officialStart, value);
      updateForm('default_official_scoring_session_start_time', new_date)
    }

    const onPracticeStartDateChange = (id)=>{
      let day = days_list.find(d=>d.id===id);
      if(!day) return;
      const value = day.date;
      setPracticeStart(value);
      const new_date = combineDateAndTime(value, practiceStartTime);
      updateForm('default_practice_scoring_session_start_time', new_date)
    }
    
    const onPracticeStartTimeChange = (value)=>{
      setPracticeStartTime(value);
      const new_date = combineDateAndTime(practiceStart, value);
      updateForm('default_practice_scoring_session_start_time', new_date)
    }

    const JudgingSessionContent = ()=>{
      const judging_days_list = days_list.filter(d=>d.date !== judgingStart)
      return (
        <div className="judging-session-wrapper">
          <div className="duration-block-title">{is_FLLC_event ? t('JudgingSessionStartsLabel') : t('nonChallengeJudgingSessionStartsLabel')}</div>
          <Row align="middle">
            <Col xm={24} md={9}>
              <div className="duration-select-wrapper">
                <Select value={judgingStart} onChange={onJudingStartDateChange}>
                  {judging_days_list.map(d=>(<Select.Option key={d.id} value={d.id}>{d.date}</Select.Option>))}
                </Select>
              </div>
            </Col>
            <Col xm={24} md={2}>
              <div className="duration-select-at">{t('AutoGenerateAtLabel')}</div>
            </Col>
            <Col xm={24} md={5}>
              <div className="duration-select-wrapper">
                <Select value={judgingStartTime} onChange={onJudingStartTimeChange} disabled={!judgingStart}>
                  {times_list.map(t=>(<Select.Option key={t} value={t}>{t}</Select.Option>))}
                </Select>
              </div>
            </Col>
          </Row>
          <Row className="duration-row">
            <Col xm={24} md={8}>
              <div className="duration-input-block">
                <div className="duration-input-block-label">{is_FLLC_event ? t('JudgeSessionDurationLabel') : t('nonChallengeJudgeSessionDurationLabel')}</div>
                <div className="duration-input-block-input-wrapper">
                  <Input size="small" allowClear placeholder={t('EnterMinsLabel')} addonAfter={t('JudgeSessionTravelTimeSub1Label')} value={jDuration} onChange={({target:{value}})=>{setJDuration(value); updateForm('default_judging_session_duration',formatMunitesIntoHourse(value))}}/>
                </div>
              </div>
            </Col>
            <Col xm={24} md={8}>
              <div className="duration-input-block">
                <div className="duration-input-block-label">{t('JudgeSessionMinsBetweenLabel')}</div>
                <div className="duration-input-block-input-wrapper">
                  <Input size="small" allowClear placeholder={t('EnterMinsLabel')} addonAfter={t('JudgeSessionTravelTimeSub1Label')} value={jBuffer} onChange={({target:{value}})=>{setJBuffer(value); updateForm('default_judging_session_buffer',formatMunitesIntoHourse(value))}}/>
                </div>
              </div>
            </Col>
            <Col xm={24} md={8}>
              <div className="duration-input-block">
                <div className="duration-input-block-label">{t('ScoringSessionTravelTimeLabel')}</div>
                <div className="duration-input-block-input-wrapper">
                  <Input type="number" size="small" allowClear placeholder={t('EnterMinsLabel')} addonAfter={t('JudgeSessionTravelTimeSub1Label')} value={jTravelTime} onChange={({target:{value}})=>{if(value<0) return; setJTravelTime(value); updateForm('default_judging_session_team_travel_time_when_scheduling',formatMunitesIntoHourse(value))}}/>
                </div>
              </div>
            </Col>
          </Row>
          {DisabledOverlay(judgingChecked)}
        </div>
      )
    }

    // hide travel time block until BE is ready from another ticket.

    const ScoringSessionContent = ()=>{
      const practice_days_list = days_list.filter(d=>d.date !== practiceStart)
      const official_days_list = days_list.filter(d=>d.date !== officialStart)
      return (
        <div>
          <div className="practice-session-wrapper">
            <div className="duration-block-title">{t('PracticeRefSessionStartLabel')}</div>
            <Row align="middle">
              <Col xm={24} md={9}>
                <div className="duration-select-wrapper">
                  <Select value={practiceStart} onChange={onPracticeStartDateChange}>
                    {practice_days_list.map(d=>(<Select.Option key={d.id} value={d.id}>{d.date}</Select.Option>))}
                  </Select>
                </div>
              </Col>
              <Col xm={24} md={2}>
                <div className="duration-select-at">{t('PracticeRefSessionStartSub1Label')}</div>
              </Col>
              <Col xm={24} md={5}>
                <div className="duration-select-wrapper">
                  <Select value={practiceStartTime} onChange={onPracticeStartTimeChange} disabled={!practiceStart}>
                    {times_list.map(t=>(<Select.Option key={t} value={t}>{t}</Select.Option>))}
                  </Select>
                </div>
              </Col>
            </Row>
            {DisabledOverlay(practiceChecked)}
          </div>
          <div className="official-session-wrapper">
            <div className="duration-block-title">{t('OfficialRefSessionStartLabel')}</div>
            <Row  align="middle">
              <Col xm={24} md={9}>
                <div className="duration-select-wrapper">
                  <Select value={officialStart} onChange={onOfficialStartDateChange}>
                    {official_days_list.map(d=>(<Select.Option key={d.id} value={d.id}>{d.date}</Select.Option>))}
                  </Select>
                </div>
              </Col>
              <Col xm={24} md={2}>
                <div className="duration-select-at">{t('PracticeRefSessionStartSub1Label')}</div>
              </Col>
              <Col xm={24} md={5}>
                <div className="duration-select-wrapper">
                  <Select value={officialStartTime} onChange={onOfficialStartTimeChange} disabled={!officialStart}>
                    {times_list.map(t=>(<Select.Option key={t} value={t}>{t}</Select.Option>))}
                  </Select>
                </div>
              </Col>
            </Row>
            {DisabledOverlay(officialChecked)}
          </div>
          <div className="scoring-session-wrapper">
            <Row className="duration-row">
              <Col xm={24} md={8}>
                <div className="duration-input-block">
                  <div className="duration-input-block-label">{t('ScoringSessionDurationLabel')}</div>
                  <div className="duration-input-block-input-wrapper">
                    <Input size="small" allowClear placeholder={t('EnterMinsLabel')} addonAfter={t('JudgeSessionTravelTimeSub1Label')} value={sDuration} onChange={({target:{value}})=>{setSDuration(value); updateForm('default_scoring_session_duration',formatMunitesIntoHourse(value))}}/>
                  </div>
                </div>
              </Col>
              <Col xm={24} md={8}>
                <div className="duration-input-block">
                  <div className="duration-input-block-label">{t('ScoringSessionBetweenSessionsLabel')}</div>
                  <div className="duration-input-block-input-wrapper">
                    <Input size="small" allowClear placeholder={t('EnterMinsLabel')} addonAfter={t('JudgeSessionTravelTimeSub1Label')} value={sBuffer} onChange={({target:{value}})=>{setSBuffer(value); updateForm('default_scoring_session_buffer',formatMunitesIntoHourse(value))}}/>
                  </div>
                </div>
              </Col>
              <Col xm={24} md={8}>
                <div className="duration-input-block">
                  <div className="duration-input-block-label">{t('ScoringSessionTravelTimeLabel')}</div>
                  <div className="duration-input-block-input-wrapper">
                    <Input type="number" size="small" allowClear placeholder={t('EnterMinsLabel')} addonAfter={t('JudgeSessionTravelTimeSub1Label')} value={rTravelTime} onChange={({target:{value}})=>{if(value<0) return; setRTravelTime(value); updateForm('default_referee_session_team_travel_time_when_scheduling',formatMunitesIntoHourse(value))}}/>
                  </div>
                </div>
              </Col>
            </Row>
          {DisabledOverlay(practiceChecked||officialChecked)}
          </div>
        </div>
      )
    }
  
    return isJudgingSession ? JudgingSessionContent() : isScoringSession ? ScoringSessionContent() : null;
  }

  const headerDisabled = (isJudgingSession && !judgingChecked) || (isScoringSession && (!practiceChecked || !officialChecked))


  return (
    <Collapse defaultActiveKey={['1']} expandIcon={handleExpandIcon} expandIconPosition={"right"} className={`event-time-blocker ${headerDisabled ? 'header-disabled' : ''}`} bordered={false}>
      <Collapse.Panel key="1" header={Header}>
      {isLoadingDays ? <BlockLoading loading={isLoadingDays} text="loading"/> : ContentBlock()}
      </Collapse.Panel>
    </Collapse>
  )
}

const mapStateToProps = (state) => {
  const { auth: { userInfo: { use_24_hour_format } }, event: { eventDetails: { is_FLLC_event } } } = state;

  return {
    use_24_hour_format,
    is_FLLC_event
  };
};
export default connect(mapStateToProps)(EventSessionDurationBlock);