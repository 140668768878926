import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EventModal from 'features/EventContainer/components/EventModal';
import { handleResetAddToPanelList } from 'features/PanelManagement/redux/actions/judges';
import {
  handleResetTeamsAddToPanelList,
} from 'features/PanelManagement/redux/actions/teams';
import '../../../../../../features/PanelManagement/styles/JudgingPanelModal.scss';
import ModalContent from './PanelModalContent';

const PanelModal = (props) => {
  const {
    visible,
    onCancel,
    resetJudgesList,
    resetTeamsList,
    panelId,
    isUpdatingJudgingPanel
  } = props;

  const handleCloseModal = () => {
    resetJudgesList();
    resetTeamsList();
    onCancel();
  };

  return (
    <EventModal
      visible={visible}
      onCancel={handleCloseModal}
      closable={!isUpdatingJudgingPanel}
    >
      <ModalContent closeModal={handleCloseModal} panelId={panelId} />
    </EventModal>
  );
};

PanelModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  resetJudgesList: PropTypes.func,
  resetTeamsList: PropTypes.func,
  panelId: PropTypes.string.isRequired,
};

PanelModal.defaultProps = {
  visible: false,
  onCancel: () => { },
  resetJudgesList: () => { },
  resetTeamsList: () => { },
};

const mapStateToProps = (state) => {
  const {
    event: { eventDetails: { eventScheduleId, id }, eventJudging: {isUpdatingJudgingPanel}}
  } = state;
  return {
    eventScheduleId,
    eventId: id,
    isUpdatingJudgingPanel
  }
};

const mapDispatchToProps = {
  resetTeamsList: handleResetTeamsAddToPanelList,
  resetJudgesList: handleResetAddToPanelList,
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelModal);
