import {API} from 'utils/api';
//const { http } = API;


const championRankUrl = 'api/championship_ranks/event/';
const judgingRankUrl = 'api/judging_ranks/event/';
const gameScoreRankUrl = 'api/game_score_ranks/event/';

export const getChampionRank = async (eventId) =>{
  const config = { headers: { 'api-version': '2.0' } };
  const url = `${championRankUrl}${eventId}`
  const { data } = await API.GetInstance().http.get(url, config);
  return data;
}

export const getJudgingRank = async (eventId) =>{
  const config = { headers: { 'api-version': '2.0' } };
  const url = `${judgingRankUrl}${eventId}`
  const { data } = await API.GetInstance().http.get(url, config);
  return data;
}

export const getGameScoreRank = async (eventId) =>{
  const config = { headers: { 'api-version': '2.0' } };
  const url = `${gameScoreRankUrl}${eventId}`
  const {data} = await API.GetInstance().http.get(url, config);
  return data;
}