import React from 'react';
import { Avatar } from 'antd';
import './TeamAvatar.scss';
import Paragraph from '../Paragraph';

const TeamAvatar = (props) => {
  const { text, src } = props;
  return (
    <div className='c-team-avatar'>
      <Avatar size={30} src={src} />
      <Paragraph size='large' weight='bold'>{text}</Paragraph>
    </div>
  );
};

export default TeamAvatar;
