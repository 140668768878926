import { API } from 'utils/api';

//const { http } = API;

const baseUrl = '/api/meetings';

export const getMeetingsBy = async (scheduleId) => {
  const {data, included} = await API.GetInstance().http.get(`/api/schedules/${scheduleId}/meetings`);
  return { data, included: included || []}
}

export const getMeetingById = async (meetingId) => {
  const url =  `${baseUrl}/${meetingId}`;
  const {data, included} = await API.GetInstance().http.get(url);
  return { data, included: included || []}
}

export const createNewMeeting = async (payload) => {
  const {data, included} = await API.GetInstance().http.post(baseUrl, payload);
  return { data, included: included || []};
}

export const updateMeeting = async (meetingId, payload) => {
  const url =  `${baseUrl}/${meetingId}`;
  const {data, included} = await API.GetInstance().http.patch(url, payload)
  return { data, included: included || []};
}


export const deleteTeamsFromMeeting = async (meetingId, data) => {
  const url =  `${baseUrl}/${meetingId}/relationships/teams`;
  const response = await API.GetInstance().http.delete(url, {data})
  return response;
}

export const deleteAttendeesFromMeeting = async (meetingId, data) => {
  const url =  `${baseUrl}/${meetingId}/relationships/attendees`;
  const response = await API.GetInstance().http.delete(url, {data})
  return response;
}

export const regenerateMeetingUrl = async (meetingId) => {
  const url = `${baseUrl}/commands`;
  
  const payload = {
    data: {
      type: "generate_external_meeting_link", 
      relationships: {
          meetings: {
            data: [{type: 'meeting', id: meetingId}]
          }
      }
  }
  }
  await API.GetInstance().http.post(url, payload);
}

export const createExternalLink = async (meetingId, meetingLink) => {
  const url = `${baseUrl}/${meetingId}/external_link`;
  
  const payload = {
    data: {
      type: "external_link", 
      attributes: {
        url: meetingLink
      }
  }
  }
  const {data} = await API.GetInstance().http.post(url, payload);
  return data;
}