import PropTypes from 'prop-types';
import { Tooltip as antTooltip } from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Pagination, { getPageFilter, initialPage } from 'shared/components/Pagination';
import TeamsList from 'shared/components/TeamsList';
import TitleSort from 'features/AdminDashboard/components/TitleAndSort';
import EventNav from 'features/EventContainer/components/EventsNav';
import { handleGetEventTeams, handleGetEventAllTeams, handleResetAddCoachTeamModal, handleResetSelectedTeamDetails } from 'features/EventContainer/redux/actions/teams';
import { fetchEventScheduleById, handleRefetchEventScheduleBegin } from 'features/EventContainer/redux/actions/scheduleActions';
import EditTeamModal from './EditTeamModal';
import TeamsCoachesModal from './TeamsCoachesModal';
import getJudgingPanels from 'features/EventContainer/redux/actions/judgingActions/getJudgingPanels';
import { handleGetScoringPanelsNew as getScoringPanels } from 'features/EventContainer/redux/actions/scoringActions/getScoringPanels';
import '../../styles/TeamsContainer/EventTeams.scss';
import {
  FLL_CHALLENGE_ID
} from 'shared/constants/programIds';
import TeamAutocomplete from './AddTeamModal/TeamAutocomplete';
import AddModal from 'shared/components/Modal';
import Tooltip from 'shared/components/Tooltip';
import CreateNewTeamForm from './AddTeamModal/CreateNewTeamForm';
import CreateNewOnlyTeamForm from './AddTeamModal/CreateNewOnlyTeamForm';
import { Col, Row } from 'antd';
import AddList from './TeamsCoachesModal/ManualView/AddList';
import InviteTeamList from './TeamsCoachesModal/ManualView/InviteListTeams';
import Tabs from 'shared/components/Tabs/ModalTabs';
import { updateAddedTeamList } from 'features/EventContainer/redux/actions/teams';
import AddTeamsAndCoachesModal from './AddTeamAndCoachModal';

const EventTeams = (props) => {
  const {
    eventId,
    canAddTeam,
    isFetchingEventTeams,
    getEventTeams,
    getEventAllTeams,
    fetchScheduleDetails,
    eventTeamsList,
    count,
    eventTeamsIncludedResponse,
    newCoachInvited,
    eventScheduleId,
    handleRefetchEventScheduleBegin,
    handleResetSelectedTeamDetails,
    isInvitingCoach,
    eventProgramId,
    canViewJudgingTab,
    canViewScoringTab,
    isFetchingEventAllTeams,
  } = props;
  const [current, setCurrentPage] = useState(initialPage);
  const [searchText, setSearchText] = useState('');
  const [visible, setVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleTeamOnly, setModalVisibleTeamOnly] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null)
  const { t } = useTranslation();

  const fetchPageTeams = useCallback((number, nosearch) => {
    const page = getPageFilter(number);
    getEventTeams(eventId, page, !nosearch && searchText);
  }, [eventId, searchText]);

  const fetchAllTeams = async () => {
    getEventAllTeams(eventId, getPageFilter(1, 500), '');
  }

  const [excelVisible, setExcelVisible] = useState(false);

  const handleSearch = (nosearch) => {
    setCurrentPage(pre => {
      if (pre === initialPage && searchText) {
        fetchPageTeams(initialPage, nosearch);
      }
      return initialPage;
    });
  };

  const onCoachDeletedCallback = useCallback(() => {
    fetchPageTeams(current);
    fetchAllTeams()
  }, [current, searchText])

  useEffect(() => {
    fetchPageTeams(current);
  }, [current]);

  useEffect(() => {
    fetchAllTeams()
  }, [])

  useEffect(() => {
    if (newCoachInvited) {
      fetchAllTeams();
      setCurrentPage(pre => {
        if (pre === initialPage) {
          fetchPageTeams(initialPage);
        }
        return initialPage;
      });
      if ((eventProgramId === FLL_CHALLENGE_ID) && canViewScoringTab) {
        props.getScoringPanels()
      }
      if (canViewJudgingTab) {
        props.getJudgingPanels()
      }

    }
  }, [newCoachInvited]);

  useEffect(() => {
    if (newCoachInvited && eventScheduleId) {
      (async () => {
        await handleRefetchEventScheduleBegin();
        await fetchScheduleDetails(eventScheduleId);
      })();
    }
  }, [newCoachInvited]);

  const handleAddClick = () => {
    setVisible(!visible);
  };


  const handleModalClose = () => {
    setVisible(false);
    props.handleResetAddCoachTeamModal();
    props.handleResetSelectedTeamDetails();
  };

  const handleAddTeamOnlyModalClose = () => {
    setModalVisibleTeamOnly(false)
    props.handleResetSelectedTeamDetails();
    props.handleResetAddCoachTeamModal();
  }

  const info = (
    <antTooltip title={t('addCoachesTooltipText')}>
      <span><InfoCircleOutlined style={{ color: '#0066B3' }} /></span>
    </antTooltip>
  );

  const tabChange = (activeKey) => {
    if (activeKey === 'Import') {
      setExcelVisible(true)
    } else {
      setExcelVisible(false)
    }
  };

  const onTeamsAdded = () => {
    setModalVisibleTeamOnly(false);
    fetchPageTeams(current);
    fetchAllTeams()
  }

  return (
    <div>
      <AddTeamsAndCoachesModal visible={visible} onCancel={handleModalClose} isLoading={isInvitingCoach || isFetchingEventAllTeams}/>
      <EventNav
        isLinkVisible={canAddTeam}
        addClick={handleAddClick}
        customText={t('addTeamsLabel')}
        placeholder={t('teamNameLabel')}
        searchText={searchText}
        setSearchText={setSearchText}
        handleSearch={handleSearch}
      />
      <div className="event-teams">
        <EditTeamModal
          selectedTeamId={selectedTeamId}
          visible={modalVisible}
          onCancel={() => {
            setModalVisible(false);
            setSelectedTeamId(null)
          }}
          onCoachDeletedCallback={onCoachDeletedCallback}
        />
        <TitleSort title={t('teamsLabel')} />
        <TeamsList
          teams={eventTeamsList}
          loading={isFetchingEventTeams}
          included={eventTeamsIncludedResponse}
          isButton={canAddTeam}
          showCoaches={false}
          onTileClick={(teamId) => {
            setModalVisible(true);
            setSelectedTeamId(teamId)
          }}
        />
        <Pagination
          total={count}
          current={current}
          onChange={setCurrentPage}
          loading={isFetchingEventTeams}
        />
      </div>
    </div>
  );
};

EventTeams.propTypes = {
  eventTeamsList: PropTypes.array.isRequired,
  isFetchingEventTeams: PropTypes.bool.isRequired,
  getEventTeams: PropTypes.func.isRequired,
  eventTeamsIncludedResponse: PropTypes.array.isRequired,
  userType: PropTypes.string.isRequired,
  userEventRoleIds: PropTypes.array.isRequired,
  handleRefetchEventScheduleBegin: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    event: {
      eventTeams, eventDetails: { eventScheduleId, eventProgramId }
    }
  } = state;
  const {
    eventTeamsList,
    count,
    eventTeamsIncludedResponse,
    isFetchingEventTeams,
    newCoachInvited,
    isInvitingCoach,
    isFetchingEventAllTeams
  } = eventTeams;
  return {
    count,
    eventTeamsList,
    eventTeamsIncludedResponse,
    isFetchingEventTeams,
    newCoachInvited,
    eventScheduleId,
    eventProgramId,
    isInvitingCoach,
    isFetchingEventAllTeams
  };
}

const mapDispatchToProps = {
  getEventTeams: handleGetEventTeams,
  getEventAllTeams: handleGetEventAllTeams,
  fetchScheduleDetails: fetchEventScheduleById,
  handleResetAddCoachTeamModal,
  handleRefetchEventScheduleBegin,
  handleResetSelectedTeamDetails,
  getJudgingPanels,
  getScoringPanels,
  updateAddedTeamList
};

export default connect(mapStateToProps, mapDispatchToProps)(EventTeams);
