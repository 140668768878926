import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const paragraphClassName = 'paragraph--large paragraph--charcoal paragraph--normal'


const FormFieldLegend = props => {
  const {t} = useTranslation();
  const {values} = props;
  return (
    <div>
      {values.map(v => (
        <p key={v.id} className={paragraphClassName}>{t(v.value)}</p>
      ))}
    </div>
  );
};

FormFieldLegend.propTypes = {
  values: PropTypes.array
};

FormFieldLegend.defaultProps = {
  values: []
}

export default FormFieldLegend;