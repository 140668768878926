import { formatAttributes } from 'utils/query';

export const formatLeaderboardRes = (data) => {
  const { id, attributes } = data;
  const res = formatAttributes(attributes);
  return {
    id, ...res
  };
};

export const formatPublishPayload = (eventId, isPublished) => {
  return {
    data: {
      type: 'leaderboard',
      attributes: {
        is_published: isPublished
      },
      relationships: { 
        event: { 
          data: { 
            type: 'event', 
            id: eventId
          }
        }
      }
    }
  };
};
