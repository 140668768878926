import * as types from 'features/EventContainer/redux/types/teamsTypes';
import * as eventRoleService from 'utils/services/eventRoles';
import { notification } from 'antd';
import { COACH_ID } from 'shared/constants/eventRoleTypes';
import { fetchJudgingSessions } from '../scheduleActions/scheduleJudgingSessions';
import { fetchScoringSessions } from '../scheduleActions/scheduleScoringSessions';
import { updateScoringAndJudgingSessions } from '../scheduleActions';

const removeCoachAssignmentBegin = () => {
  return {
    type: types.DELETE_COACH_EVENT_ASSIGNMENT_BEGIN
  }
}

const removeCoachAssignmentError = () => {
  return {
    type: types.DELETE_COACH_EVENT_ASSIGNMENT_ERROR
  }
}

const removeCoachAssignmentSuccess = (updatedIncludedList = []) => {
  return {
    type: types.DELETE_COACH_EVENT_ASSIGNMENT_SUCCESS,
    payload: {
      updatedIncludedList
    }
  }
}

const updateTeamsListOnRemovingCoach = (data = [], count = 0) => {
  return {
    type: types.UPDATE_EVENT_TEAMS_ON_REMOVING_COACH,
    payload: {
      data,
      count
    }
  }
}

const filterOutJudgingAndScoringSessionsByTeamId = (sessions, teamId) => {
  sessions.judgingSessions = sessions.judgingSessions.filter(session => session.teamId !== teamId);
  sessions.scoringSessions = sessions.scoringSessions.filter(session => session.teamId !== teamId);
  return sessions;
}

const handleRemoveCoachEventAssignment = (args, successCallback = () => { }, errorCallback = () => { }) => {
  const { eventId, userId, teamId } = args;
  return async (dispatch, getState) => {
    const {
      event: {
        eventTeams: { eventTeamsIncludedResponse, eventTeamsList },
        eventSchedule: { sessions }
      } } = getState();
    const copyIncluded = [...eventTeamsIncludedResponse];
    const copyTeamsList = [...eventTeamsList];

    try {
      await dispatch(removeCoachAssignmentBegin());
      const filter = {
        'role.id': `eq:${COACH_ID}`,
        'user.id': `eq:${userId}`,
        'event.id': `eq:${eventId}`,
        'event.teams.id': `eq:${teamId}`
      }
      const { data } = await eventRoleService.getAllUserEventRolesBy(filter);

      if (data.length) {
        const selectedEventRoleAssignmentId = data[0].id;

        const payload = {
          data: [{
            type: 'team',
            id: teamId
          }]
        }
        await eventRoleService.removeTeamFromCoach(selectedEventRoleAssignmentId, payload);

        const newSessions = filterOutJudgingAndScoringSessionsByTeamId(sessions, teamId);
        await dispatch(updateScoringAndJudgingSessions(newSessions))
        const updatedList = copyIncluded.filter(i => i.id === userId);

        notification.success({
          message: 'Success',
          description: 'Coach successfully removed.',
        })
        await dispatch(removeCoachAssignmentSuccess(updatedList))
        successCallback();

      } else {
        notification.error({
          message: 'Error',
          description: 'Coach is not assigned to this team for the event.',
        })
        errorCallback();
        await dispatch(removeCoachAssignmentError());
      }

    } catch (err) {
      console.error({ err })

      await dispatch(removeCoachAssignmentError());
      notification.error({
        message: 'Error',
        description: 'Coach cannot be removed.',
      })
      errorCallback();
      throw err;
    }
  }
}

export default handleRemoveCoachEventAssignment;