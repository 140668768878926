import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TeamsList from 'shared/components/TeamsList';
import TitleSortRow from 'shared/components/TitleSortRow'
import Pagination, { getPageFilter, initialPage } from 'shared/components/Pagination';
import SearchBar from 'features/AdminDashboard/components/SearchBar';
import { requestTeams } from 'features/AdminDashboard/redux/actions/teamsActions';
import EditTeamModal from './EditTeamModal';
import { teamsSortBy } from 'shared/constants/sortOptions';

const TeamsContainer = (props) => {
  const [current, setCurrentPage] = useState(initialPage);
  const [searchText, setSearchText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [sortBy, setSortBy] = useState('');

  const { canEdit, teams, count, isFetchingTeams, included } = props;
  const { t } = useTranslation();

  const fetchTeams = (pageNumber, nosearch) => {
    const page = getPageFilter(pageNumber);
    props.requestTeams(page, !nosearch && searchText, sortBy);
  };

  const handleSearch = (nosearch) => {
    if (current !== initialPage) {
      setCurrentPage(initialPage);
    } else if (searchText) {
      fetchTeams(initialPage, nosearch);
    }
  };

  useEffect(() => {
    fetchTeams(current);
  }, [current, sortBy]);

  const onSortBySelected = (value) => {
    setSortBy(value);
  }

  const onCoachDeletedCallback = useCallback(() => {
    fetchTeams(current);
  }, [current, searchText, sortBy])

  return (
    <div className='admin-dashboard'>
      <EditTeamModal
        selectedTeamId={selectedTeamId}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setSelectedTeamId(null)
        }}
        onCoachDeletedCallback={onCoachDeletedCallback}
      />
      <SearchBar
        placeholder={t('teamNameLabel')}
        searchText={searchText}
        setSearchText={setSearchText}
        handleSearch={handleSearch}
      />
      <div className='admin-dashboard__list'>
        <TitleSortRow title={t('teamsLabel')} options={teamsSortBy} onSelect={onSortBySelected} />
        <TeamsList
          teams={teams}
          loading={isFetchingTeams}
          included={included}
          isButton={canEdit}
          onTileClick={(teamId) => {
            setModalVisible(true);
            setSelectedTeamId(teamId)
          }}
        />
        <Pagination
          total={count}
          current={current}
          onChange={setCurrentPage}
          loading={isFetchingTeams}
        />
      </div>
    </div>
  );
};

TeamsContainer.propTypes = {
  teams: PropTypes.array.isRequired,
  isFetchingTeams: PropTypes.bool.isRequired,
  requestTeams: PropTypes.func.isRequired,
  included: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  const {
    adminBoard: { teams: { teams, count, isFetchingTeams, included } }
  } = state;

  return {
    teams,
    count,
    isFetchingTeams,
    included
  };
}

export default connect(
  mapStateToProps,
  { requestTeams }
)(TeamsContainer);
