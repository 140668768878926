import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';


const RegistrationOptions = props => {
  const { t } = useTranslation()
  const { options } = props;
  return (
    <div className='registration-page-layout__options'>
      {options.map(({ text, handleClick = () => {} }, idx) => (
        <React.Fragment key={text}>
          <div onClick={handleClick}>
            <p className='paragraph--large paragraph--charcoal paragraph--bold'>{text}</p>
          </div>
          {idx < options.length - 1 && 
            <p className='paragraph--medium paragraph--charcoal'>{t('orLabel')}</p>}
        </React.Fragment>
      ))}
    </div>
  );
};

RegistrationOptions.propTypes = {
  options: PropTypes.array.isRequired
};

export default RegistrationOptions;
