import React from 'react';

const FirstText = () => {
  return (
    <span>
      <i>FIRST</i>{' '}<sup>&reg;</sup>
    </span>
  );
};

export default FirstText;
