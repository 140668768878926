import React from 'react';
import PropTypes from 'prop-types';
import {notification} from 'antd';
import {SyncOutlined, CopyOutlined, EditOutlined} from '@ant-design/icons';
import './SessionUrlActions.scss';

const SessionUrlActions = props => {
  const {
    onRegenerateClick,
    copyElementId,
    onEditClick
  } = props;

  function copyToClipboard() {
    const copyText = document.getElementById(copyElementId).innerText;
    const tempElement = document.createElement('input');
    document.body.appendChild(tempElement);
    tempElement.value = copyText;
        
    if (copyText) {
      tempElement.select();
      const isCopySuccessful = document.execCommand('copy');
      document.body.removeChild(tempElement);
      if (isCopySuccessful) {
        notification.success({
          message: 'URL Copied to Clipboard',
   
        });
      }

    }
  }

  return (
    <>
      <section className='c-session-url-actions-container'>
        <button aria-label='Regenerate Microsoft Teams Link' onClick={onRegenerateClick} type='button' className='c-session-url-action'>
          <SyncOutlined style={{color: '#0066b3'}}/>
        </button>
        <button aria-label='Copy Session Link' onClick={copyToClipboard} type='button' className='c-session-url-action'>
          <CopyOutlined style={{color: '#0066b3'}} />
        </button>
        <button aria-label='Edit Session Link' onClick={onEditClick} type='button' className='c-session-url-action'>
          <EditOutlined style={{color: '#0066b3'}} />
        </button>
      </section>
    </>
  );
};

SessionUrlActions.propTypes = {
  onRegenerateClick: PropTypes.func,
  copyElementId: PropTypes.string.isRequired,
  onEditClick: PropTypes.func
};
SessionUrlActions.defaultProps = {
  onRegenerateClick: () => {},
  onEditClick: () => {}

};

export default SessionUrlActions;