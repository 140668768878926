
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, InputNumber, Row, Select } from 'antd';
import AddLink from 'shared/components/TopBar/AddLink';
import Instructions from 'shared/components/Instructions';
import Pagination, { getPageFilter, initialPage } from 'shared/components/Pagination';
import { connect } from 'react-redux';
import handleJudges from 'features/EventContainer/redux/actions/judgingActions/getJudges';
import handleCreateNewPanel, { createNew } from 'features/EventContainer/redux/actions/judgingActions/createNewJudgingPanel';
import '../../styles/JudgingContainer.scss';
import '../../../../features/PanelManagement/styles/PanelManagementContainer.scss';
import LinkButton from 'shared/components/Button/BtnSecondary';
import { fetchJudgingSessions } from 'features/EventContainer/redux/actions/scheduleActions/scheduleJudgingSessions';
import getJudgingPanels, { autoAssignPanels, handleExpands } from 'features/EventContainer/redux/actions/judgingActions/getJudgingPanels';
import { handleGetEventAllTeams } from 'features/EventContainer/redux/actions/teams';
import ListLoading from 'shared/components/Spinner/ListLoading';
import { useTranslation } from 'react-i18next';
import JudgesList from './JudgesListContainer';
import PanelModal from './AddJudgingPanelModal';
import PanelsContainer from './PanelsContainer';
import BtnPrimary from 'shared/components/Button/BtnPrimary';
import ConfirmationModal from 'shared/components/ConfirmModal';
import BtnSecondary from 'shared/components/Button/BtnSecondary';
import { notification } from 'antd';
import * as panelService from 'utils/services/panels';
import Icon from 'shared/components/Icon';
import { JUDGING_REVIEW_PANEL_TYPE_ID } from 'shared/constants/panelTypes';

const { Option } = Select;


const JUDGING_REVIEW_PANEL_ID = 'e90f431a-161b-4e09-a8fb-b8b398a6de74';
const MAX_NUMBER_OF_PANELS = 64;
const JudgingContainer = (props) => {
  const {
    panelType,
    eventId,
    eventJudging,
    eventScheduleId,
    isLoadingJudgingSessions,
    event_name,
    addNew,
    createJudgingPanel,
    manageExpands,
    eventProgramId,
    included,
    getEventAllTeams,
    is_FLLC_event,
  } = props;
  const { t } = useTranslation()
  const {
    isFetchingJudgingPanels,
    judgingPanels, judgeOptions, sessionOptions, isCreatingNewJudgingPanel
  } = eventJudging;
  const [visible, setVisible] = useState(false);
  const [autoAssigning, setAutoAssigning] = useState(false);
  const [showAutoAssignConfirmModal, setShowAutoAssignConfirmModal] = useState(false);

  const [panelNumber, setPanelNumber] = useState(0)
  const [selectedPanels, setSelectedPanels] = useState([]);

  const expandAllRecord = () => {
    manageExpands([])

  }

  const collapseAllRecord = () => {
    let ids = judgingPanels.map(panel => panel.panelId)
    manageExpands(ids)
  }


  const openModal = () => {
    setVisible(true);
  }

  const closeModal = () => {
    setVisible(false);
  }

  const fetchAllTeams = async () => {
    getEventAllTeams(eventId, getPageFilter(1, 1), '');
  }


  useEffect(() => {
    fetchAllTeams()
  }, [])

  useEffect(() => {
    if (eventScheduleId) {
      props.getJudgingPanels(eventScheduleId)
    }
  }, [eventId, eventScheduleId])

  if (isFetchingJudgingPanels) {
    return (
      <ListLoading loading text='Loading Judging' />
    );
  }

  const isSessionAvailable = (data) => {
    return data.panelSessions.length > 0
  }

  const handleAutoAssign = () => {
    let isTeamsAssigned = judgingPanels.some(isSessionAvailable)

    if (isTeamsAssigned) {
      setShowAutoAssignConfirmModal(true);
    } else {
      autoAssign();
    }
  }


  const totalTeams = () => {
    // return sessionOptions.length
    // let teams = included.filter((data) => data.type == 'team')
    return included.length
  }

  const totalPanels = () => {
    return judgingPanels.length
  }


  const autoAssign = async () => {
    try {
      setAutoAssigning(true)
      let assignments = await autoAssignPanels(eventScheduleId)
      props.getJudgingPanels(eventScheduleId)
      setAutoAssigning(false)

      notification.success({
        message: 'Success',
        description: 'Teams were assigned successfully',
      });
    } catch (error) {
      console.log(error);
      setAutoAssigning(false)
      notification.error({
        message: 'Error',
        description: 'Teams weren’t assigned successfully',
      });
    }
  }


  const generateNewPanelName = (panels) => {

    if (panels.length == 0) {
      return 1
    }

    let panelNumbres = [];
    panels.forEach(panel => {
      if (panel && panel.panelName) {
        panelNumbres.push(parseInt(panel.panelName.replace(/\D/g, "")))
      }
    });

    return Math.max(...panelNumbres) + 1;

  }

  // console.log("judgingPanels---------------", judgingPanels);

  const currentJudgingPanelsCount = judgingPanels.length;

  const titleNewPanel = `Panel ${generateNewPanelName(judgingPanels)}`;
  // const titleNewPanel = `Panel ${currentJudgingPanelsCount + 1}`;

  const createTables = async () => {
    await addNew({
      panel_type_id: JUDGING_REVIEW_PANEL_TYPE_ID,
      desired_number_of_panels: panelNumber
    }, successCb)
  }

  const successCb = () => {
    props.getJudgingPanels(eventScheduleId)
  }


  const deleteAllPanels = async () => {
    setPanelNumber(0)
    judgingPanels.forEach(async (panel) => {
      panelService.deletePanelByID(panel.panelId)
    })

    props.getJudgingPanels(eventScheduleId)
  }

  const handlePanelSelect = (e) => {
    setSelectedPanels(e)
  }

  let filterData = () => {
    // return []
    if (selectedPanels.length == 0) {
      return judgingPanels;
    }

    let filter = []
    let data = [...judgingPanels]
    data.forEach((item) => {
      selectedPanels.forEach((panel) => {
        if (item.panelId == panel) {
          filter.push(item);
        }
      })
    });

    return filter
  }

  let filteredData = filterData();
  const currentPanelNumber = judgingPanels?.length ? judgingPanels?.length : 0;
  const maxPanelsNumber = MAX_NUMBER_OF_PANELS - currentPanelNumber ;

  return (
    <>
      <PanelModal
        isEdit={false}
        panelTitle={t('Add Room')}
        roleType="Judge"
        visible={visible}
        onCancel={closeModal}
        newPanelTitle={titleNewPanel}
        judgeOptions={judgeOptions}
        sessionOptions={sessionOptions}
        panelSubtitle={''}

        toolTipInfo={is_FLLC_event ? t('judgePanelTooltip') : t('nonChallengeJudgePanelTooltip')}
      />

      <div className="event-judging-container">

        <div className="panel-management-container__add">
          <div className="title">{is_FLLC_event ? t('judgingLabel') : t('nonChallengeJudgingLabel')}</div>
          {(
            <div className='add-table'>
              <div className='m-r-10'>{t('addJudgePanelLabel')}</div>
              <div>
                <InputNumber
                  className="m-r-20 panel-add-button-size"
                  style={{ width: 60 }}
                  min={0}
                  max={maxPanelsNumber}
                  defaultValue={0}
                  value={panelNumber}
                  onChange={setPanelNumber}>
                </InputNumber>
              </div>
              <div>
                <BtnSecondary
                  type='default'
                  styleType='secondary'
                  size='large'
                  // additionalClassNames='p-r-14 p-l-14'
                  onClick={() => { createTables() }}
                  // disabled={btnDisabled || isInvitingCoach}
                  disabled={(panelNumber > 0 && panelNumber <= maxPanelsNumber) ? false : true}
                >
                  {`${t('Add')}`}
                </BtnSecondary>
              </div>
            </div>
          )}

          <div className='d-flex'>
            {/* <AddLink disabled={isCreatingNewJudgingPanel} className="m-r-10" addClick={() => { createTables()}} color="primaryBlue" customText={t('Add Room')} /> */}
            {/* {(
              judgingPanels.length > 0 && (
                <BtnSecondary
                  type='default'
                  styleType='secondary'
                  // className="m-r-10"
                  size='large'
                  additionalClassNames='m-r-10'
                  onClick={() => { deleteAllPanels() }}
                // disabled={btnDisabled || isInvitingCoach}
                >
                  {`${t('2022DeletePanelLabel')}`}
                </BtnSecondary>
              )
            )} */}

            <BtnPrimary
              onClick={() => { handleAutoAssign() }}
              disabled={(judgingPanels && judgingPanels.length > 0 && totalTeams() > 0) ? false : true}
              loading={autoAssigning}
              additionalClassNames='add-event-modal__btn'>{t('AutoAssignLabel')}</BtnPrimary>
          </div>
        </div>
        <Row type="flex">
          <Instructions
            text={is_FLLC_event ? t('judgePanelInstructionText') : t('nonChallengeJudgePanelInstructionText')}
          />

          {(
            judgingPanels.length > 0 ? (
              <div className='filter-area m-t-30'>
                <LinkButton className="first-button" type="link" onClick={collapseAllRecord}>{t('CollapseAllLabel')}</LinkButton>
                <LinkButton className="first-button m-r-20" type="link" onClick={expandAllRecord}>{t('ExpandAllLabel')}</LinkButton>
                <div>
                  <Select
                    style={{ width: 200 }}
                    // defaultValue={0}
                    showArrow
                    placeholder="Show All"
                    mode="multiple"
                    className='m-r-20 judging-modal-judges-tab__add-select'
                    onChange={(e) => { handlePanelSelect(e) }} suffixIcon={<Icon type='downArrowBlue' />}>

                    {/* {formattedPanels.map(option => { */}
                    {judgingPanels.map(option => {
                      return (
                        <Option
                          value={option.panelId}
                        >
                          {option.panelName}
                        </Option>)
                    })}
                  </Select>
                </div>
              </div>
            ) : (
              <div className='empty-panels m-t-20'>
                <div className='empty-panel-text'>{t('EmptyJudgingPageLabel')}</div>
              </div>
            )
          )}

          <PanelsContainer
            panels={filteredData}
            panelType='judging'
            panelTypeId='e90f431a-161b-4e09-a8fb-b8b398a6de74'
          />
        </Row>
      </div>
      <ConfirmationModal
        visible={showAutoAssignConfirmModal}
        onCancel={() => {
          setShowAutoAssignConfirmModal(false);
        }}
        onSubmit={() => {
          // deletePanel(id)
          autoAssign()
          setShowAutoAssignConfirmModal(false);
        }}
        title={(<div>
          <div className='team-name m-b-20'>{event_name}</div>
          <div className='title'>{`${t('randomizeConfirmLable')}`}</div>
        </div>)}
        // title={`${t('DeleteTeamDeleteLabel')} ${panel.panelName}?`}
        description='Proceeding with this auto assignment will overwritten any existing assignments. Do you wish to continue?'
      />
    </>
  );
};


JudgingContainer.propTypes = {
  addText: PropTypes.string,
  panelType: PropTypes.string,
  eventJudging: PropTypes.object.isRequired,
  getJudges: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  eventScheduleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getJudgingSessions: PropTypes.func,
  judgingSessionsData: PropTypes.array,
  judgingSessionsIncluded: PropTypes.array,
  judgingSessionsTeams: PropTypes.array,
  getJudgingPanels: PropTypes.func,
  createJudgingPanel: PropTypes.func,
  isLoadingJudgingSessions: PropTypes.bool
}
JudgingContainer.defaultProps = {
  addText: 'Add Judge Panel',
  panelType: 'judgingPanel',
  eventScheduleId: undefined,
  getJudgingSessions: () => { },
  judgingSessionsData: [],
  judgingSessionsIncluded: [],
  judgingSessionsTeams: [],
  getJudgingPanels: () => { },
  createJudgingPanel: () => { },
  isLoadingJudgingSessions: false,
}

const mapDispatchToProps = {
  getJudges: handleJudges,
  getJudgingSessions: fetchJudgingSessions,
  getJudgingPanels,
  createJudgingPanel: handleCreateNewPanel,
  addNew: createNew,
  manageExpands: handleExpands,
  getEventAllTeams: handleGetEventAllTeams,
}

const mapStateToProps = (state) => {
  const { event: { eventDetails: { eventScheduleId, attributes, eventProgramId, is_FLLC_event }, eventJudging, eventSchedule, eventTeams: { eventTeamsListAll } } } = state;
  const { title } = attributes;

  const {
    judgingSessionsData,
    judgingSessionsIncluded,
    judgingSessionsTeams,
    isLoadingJudgingSessions
  } = eventSchedule.sessions;
  return {
    is_FLLC_event,
    eventJudging,
    eventScheduleId,
    judgingSessionsData,
    judgingSessionsIncluded,
    judgingSessionsTeams,
    isLoadingJudgingSessions,
    event_name: title,
    eventProgramId,
    included: eventTeamsListAll,
  }
}
//

export default connect(mapStateToProps, mapDispatchToProps)(JudgingContainer);