// Event Teams
export const GET_EVENT_AWARD_TEAMS_BEGIN = 'GET_EVENT_OVERVIEW_AWARD_TEAMS_BEGIN';
export const GET_EVENT_AWARD_TEAMS_SUCCESS = 'GET_EVENT_OVERVIEW_AWARD_TEAMS_SUCCESS'; 
export const GET_EVENT_AWARD_TEAMS_FAIL = 'GET_EVENT_OVERVIEW_AWARD_TEAMS_FAIL'; 

// Event Awards
export const GET_EVENT_AWARDS_BEGIN = 'GET_EVENT_OVERVIEW_AWARDS_BEGIN';
export const GET_EVENT_AWARDS_SUCCESS = 'GET_EVENT_OVERVIEW_AWARDS_SUCCESS'; 
export const GET_EVENT_AWARDS_FAIL = 'GET_EVENT_OVERVIEW_AWARDS_FAIL';

export const CREATE_EVENT_AWARD_BEGIN = 'CREATE_EVENT_AWARD_BEGIN';
export const CREATE_EVENT_AWARD_SUCCESS = 'CREATE_EVENT_AWARD_SUCCESS';
export const CREATE_EVENT_AWARD_FAIL = 'CREATE_EVENT_AWARD_FAIL';

export const ADD_TEAM_TO_AWARD_BEGIN = 'ADD_TEAM_TO_AWARD_BEGIN';
export const ADD_TEAM_TO_AWARD_SUCCESS = 'ADD_TEAM_TO_AWARD_SUCCESS';
export const ADD_TEAM_TO_AWARD_FAIL = 'ADD_TEAM_TO_AWARD_FAIL';

export const REMOVE_TEAM_FROM_AWARD_BEGIN = 'REMOVE_TEAM_FROM_AWARD_BEGIN';
export const REMOVE_TEAM_FROM_AWARD_SUCCESS = 'REMOVE_TEAM_FROM_AWARD_SUCCESS';
export const REMOVE_TEAM_FROM_AWARD_FAIL = 'REMOVE_TEAM_FROM_AWARD_FAIL';

export const UPDATE_TEAM_AWARD_PLACE_BEGIN = 'UPDATE_TEAM_AWARD_PLACE_BEGIN';
export const UPDATE_TEAM_AWARD_PLACE_SUCCESS = 'UPDATE_TEAM_AWARD_PLACE_SUCCESS';
export const UPDATE_TEAM_AWARD_PLACE_FAIL = 'UPDATE_TEAM_AWARD_PLACE_FAIL';

export const SUBMIT_EVENT_AWARDS_BEGIN = 'SUBMIT_EVENT_AWARDS_BEGIN';
export const SUBMIT_EVENT_AWARDS_SUCCESS = 'SUBMIT_EVENT_AWARDS_SUCCESS';
export const SUBMIT_EVENT_AWARDS_FAIL = 'SUBMIT_EVENT_AWARDS_FAIL';

export const GET_EVENT_RANKING_BEGIN = 'GET_EVENT_RANKING_BEGIN';
export const GET_EVENT_RANKING_SUCCESS = 'GET_EVENT_RANKING_SUCCESS';
export const GET_EVENT_RANKING_COMPLETE = 'GET_EVENT_RANKING_COMPLETE';
export const GET_CHAMPIONSHIP_RANKING_LOADING = 'GET_CHAMPIONSHIP_RANKING_LOADING';
export const GET_GAME_SCORE_RANKING_LOADING = 'GET_GAME_SCORE_RANKING_LOADING';
export const GET_JUDGING_RANKING_LOADING = 'GET_JUDGING_RANKING_LOADING';
export const GET_EVENT_RANKING_FAIL = 'GET_EVENT_RANKING_FAIL';

export const GET_EVENT_OPTIONAL_AWARDS_BEGIN = 'GET_EVENT_OPTIONAL_AWARDS_BEGIN';
export const GET_EVENT_OPTIONAL_AWARDS_SUCCESS = 'GET_EVENT_OPTIONAL_AWARDS_SUCCESS';
export const GET_EVENT_OPTIONAL_AWARDS_COMPLETE = 'GET_EVENT_OPTIONAL_AWARDS_COMPLETE';
export const GET_EVENT_OPTIONAL_AWARDS_FAIL = 'GET_EVENT_OPTIONAL_AWARDS_FAIL';


