import React from 'react';
// import PropTypes from 'prop-types';

const DropArrowBlue = () => {
  return (
    <svg width="14px" height="8px" viewBox="0 0 14 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      {/* <title>196FE0F4-21C1-40A5-8B79-62CC18F60BED</title> */}
      <g id="Dashboards" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dashboard---PDP-Admin" transform="translate(-1107.000000, -268.000000)" fill="#0066B3">
          <g id="Group-18" transform="translate(310.000000, 249.000000)">
            <g id="Group-5" transform="translate(642.000000, 5.000000)">
              <g id="Icons/FIRST/Drop-Arrow" transform="translate(155.000000, 14.000000)">
                <polygon id="Path" points="1.645 0 7 4.94466937 12.355 0 14 1.52226721 7 8 0 1.52226721" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};


export const SmallDownArrowBlue = ()=>{
  return (
    <svg width="10px" height="6px" viewBox="0 0 10 6" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
    <title>C622CD49-C673-40A9-93F5-B7F50C250D3F</title>
    <g id="Events" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Event-Management---Single-Event---PDP-Admin---V2" transform="translate(-273.000000, -1222.000000)" fill="#0066B3">
            <g id="Group-55" transform="translate(250.000000, 1031.000000)">
                <g id="Group-50" transform="translate(0.000000, 42.000000)">
                    <g id="Group-49" transform="translate(10.000000, 76.000000)">
                        <g id="Group-47" transform="translate(0.000000, 32.000000)">
                            <g id="Group-14" transform="translate(2.000000, 26.000000)">
                                <g id="Group-3" transform="translate(11.000000, 7.000000)">
                                    <g id="Icons/FIRST/Drop-Arrow" transform="translate(0.000000, 8.000000)">
                                        <polygon id="Path" points="1.175 0 5 3.70850202 8.825 0 10 1.1417004 5 6 0 1.1417004"></polygon>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
  )
}

export default DropArrowBlue;