const m18Section = {
  id: 'GP',
  name: '2023ScoresheetGraciousProfessionalismLabel',
  fields: [
    {
      scoring: '2022ScoresheetGraciousProfessionalismSub1Label',
      id: 'gracious_professionalism',
      options: [
        {
          description: '2022ScoresheetProfessionalismDevelopingLabel',
          value: 2
        },
        {
          description: '2022ScoresheetProfessionalismAccomplishedLabel',
          value: 3
        },
        {
          description: '2022ScoresheetProfessionalismExceedsLabel',
          value: 4
        },
      ]
    },
  ]
}

export default m18Section;