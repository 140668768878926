/* eslint-disable import/prefer-default-export */
import * as userEventRoleService from 'utils/services/eventRoles';
import * as userService from 'utils/services/users';
import { notification } from 'antd';
import {seventyTwoHoursFromNow} from 'utils/dateTimeUtils';
import * as types from '../types/volunteersTypes';
import { VolunteerRoleIdList } from 'shared/constants/eventRoleTypes';

const requestVolunteersBegin = () => {
  return {
    type: types.DASHBOARD_REQUEST_VOLUNTEERS_BEGIN
  };
};

const requestVolunteersError = () => {
  return {
    type: types.DASHBOARD_REQUEST_VOLUNTEERS_ERROR
  };
};

const requestVolunteersSuccess = (volunteers, included, count) => {
  return {
    type: types.DASHBOARD_REQUEST_VOLUNTEERS_SUCCESS,
    payload: {
      included,
      volunteers,
      count
    }
  };
};

export const handleRequestVolunteers = (page, searchText, sort) => {
  return async (dispatch) => {
    await dispatch(requestVolunteersBegin());
    try {
      const searchFilter = searchText ? { 'full_name': `like:${searchText}` } : {};
      const filter = {
        ...searchFilter,
        'event_assignments.role.id': `in:${VolunteerRoleIdList.join(',')}`}
      const { data, meta } = await userService.getUserBySortFilter(filter, page, sort);
      if(data.length > 0){
        const userIds = data.map(d=>d.id)
        const userFilter = {'user.id': `in:${userIds.join(',')}`}
        const userEventRoles = await userEventRoleService.getVolunteerEventRoles({number: 1, size: 500}, userFilter, sort);
        await dispatch(requestVolunteersSuccess(userEventRoles.data, userEventRoles.included, meta.count));
      }else{
        dispatch(requestVolunteersSuccess([], [], 0))
      }
    } catch (err) {
      await dispatch(requestVolunteersError());
      throw err;
    }
  };
};


const resendInvitationBegin = () => {
  return {
    type: types.REINVITE_USER_DASHBOARD_VOLUNTEERS_TAB_BEGIN
  }
}
const resendInvitationError = () => {
  return {
    type: types.REINVITE_USER_DASHBOARD_VOLUNTEERS_TAB_ERROR
  }
}
const resendInvitationSuccess = (updatedIncludedList = []) => {
  return {
    type: types.REINVITE_USER_DASHBOARD_VOLUNTEERS_TAB_SUCCESS,
    payload: {
      updatedIncludedList
    }
  }
}

export const resendUserInvitation = (userId) => {
  return async (dispatch, getState) => {
    const {adminBoard: {volunteers: {included}}} = getState();
    const notificationKey = 'reinvite-volunteer-user'

    const copyIncluded = [...included];
    try {
      await dispatch(resendInvitationBegin())
      await userService.reinviteUser(userId);
      const newTimestamp = seventyTwoHoursFromNow();
 
      const selectedVolunteerUser = copyIncluded.find(i => i.id === userId);
      
      if (selectedVolunteerUser) {
        const selectedVolunteerIndex = copyIncluded.findIndex(i => i.id === userId);
        const updatedVolunteerUser = {
          ...selectedVolunteerUser,
          attributes: {
            ...selectedVolunteerUser.attributes,
            verification_code_timeout: newTimestamp
          }
        }
        copyIncluded[selectedVolunteerIndex] = updatedVolunteerUser;
      }
      // console.log(copyIncluded)
      await dispatch(resendInvitationSuccess(copyIncluded));
      
      notification.destroy(notificationKey);
      notification.success({
        message: 'Success',
        description: 'User successfully reinvited.',
      })
      
    } catch(err) {
      notification.error({
        message: 'Error',
        description: 'Error reinviting user',
      });
      await dispatch(resendInvitationError())
      throw err;
    }
  }
}

const editVolunteerInfoBegin = () => {
  return {
    type: types.EDIT_ADMINBOARD_VOLUNTEER_BEGIN
  }
}

const editVolunteerInfoError = () => {
  return {
    type: types.EDIT_ADMINBOARD_VOLUNTEER_ERROR
  }
}

const editVolunteerInfoSuccess = () => {
  return {
    type: types.EDIT_ADMINBOARD_VOLUNTEER_SUCCESS
  }
}

export const updateVolunteerInfo = (userId, values) => {
  return async (dispatch) => {
    try {
      await dispatch(editVolunteerInfoBegin());
      const userPayload = {
        'first_name': values.firstName,
        'last_name': values.lastName,
        'mobile_phone': values.phone
      };
      await userService.updateUser(userId, userPayload);
      await dispatch(editVolunteerInfoSuccess());
      notification.success({
        message: 'Success',
        description: 'Volunteer information successfully updated.',
      });
    } catch (err) {
      await dispatch(editVolunteerInfoError());
      notification.error({
        message: 'Error',
        description: 'Error updating volunteer information.',
      });
      throw err;
    }
  };
};

const deleteVolunteerBegin = () => {
  return {
    type: types.DELETE_ADMINBOARD_VOLUNTEER_BEGIN
  }
}

const deleteVolunteerError = () => {
  return {
    type: types.DELETE_ADMINBOARD_VOLUNTEER_ERROR
  }
}

const deleteVolunteerSuccess = () => {
  return {
    type: types.DELETE_ADMINBOARD_VOLUNTEER_SUCCESS
  }
}

export const deleteVolunteer = (userId) => {
  return async (dispatch) => {
    try {
      await dispatch(deleteVolunteerBegin());
      const assignmentIds = await userEventRoleService.getAllVolunteerEventRolesById(userId);
      await userEventRoleService.removeMultiUserEventRoles(assignmentIds);
      await dispatch(deleteVolunteerSuccess());
      notification.success({
        message: 'Success',
        description: 'Volunteer successfully deleted.',
      });
    } catch (err) {
      await dispatch(deleteVolunteerError());
      notification.error({
        message: 'Error',
        description: 'Error deleting volunteer.',
      });
      throw err;
    }
  };
};
