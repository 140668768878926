
export default class UserData {
  constructor(responseData) {
    this.userId = responseData.id;
    this.status = responseData.attributes.status;
    this.verificationCodeTimeout = responseData.attributes.verification_code_timeout;
    this.firstName = responseData.attributes.first_name;
    this.lastName = responseData.attributes.last_name;
    this.email = responseData.attributes.email;
  }
  
  userFullNameString = () => {
   
    return  `${this.firstName} ${this.lastName}`;
    
  }



}