import React, { useEffect, useState } from 'react'
import { Modal, Progress } from 'antd'
import Paragraph from 'shared/components/Paragraph'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router'

const UploadParagraphWrapper = ({ children }) => (
  <Paragraph
    size='large'
    weight='bold'
  >
    {children}
  </Paragraph>
)

const UploadModal = (props) => {
  const {globalUploadModal, isFileUploading} = props;
  const {
    wrapClassName,
    visible = false,
    onCancel,
    progressType = 'circle',
    uploadProgress,
    bodyStyle,
    progressStyle,
    disableESC,
    withAlert,
    onUploadStatusChanged,
    modalClosable,
  } = globalUploadModal;
  
  const history = useHistory();
  const location = useLocation();

  const [lastLocation, setLastLocation] = useState(null);

  const currentLocation = `${location.pathname}${location.hash}`

  useEffect(()=>{
    if(isFileUploading && !lastLocation){
      setLastLocation(currentLocation)
    }
    if(!isFileUploading && lastLocation){
      setLastLocation(null);
    }
  }, [isFileUploading, currentLocation])

  useEffect(()=>{
    if(visible && lastLocation !== currentLocation && lastLocation){
      if(!window.confirm('Uploading is in progress, are you sure you want to leave?')){
        history.push(lastLocation);
      }
    }
  }, [lastLocation, currentLocation, visible])

  useEffect(()=>{
    const onbeforeunloadEventHandler = (e)=> {
      const confirmMessage = 'content will not be displayed, but required for this eventlistener to work';
      e.returnValue = confirmMessage;
      return confirmMessage;
    }

    if(withAlert && visible && uploadProgress < 100) {
      window.addEventListener('beforeunload', onbeforeunloadEventHandler);
      onUploadStatusChanged&&onUploadStatusChanged(true);
    }else{
      onUploadStatusChanged&&onUploadStatusChanged(false);
    }
    return ()=>{
      window.removeEventListener('beforeunload', onbeforeunloadEventHandler);
    };
  }, [withAlert, uploadProgress, visible]);
  
  return (<>
    <Modal
      wrapClassName={wrapClassName}
      open={visible}
      onCancel={onCancel}
      closable={uploadProgress === 100 &&  modalClosable}
      destroyOnClose
      maskClosable={false}
      footer={null}
      bodyStyle={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '54px',
        ...bodyStyle,
      }}
      keyboard={disableESC?false:undefined}
    >
      <Progress
        type={progressType}
        percent={uploadProgress}
        style={{ marginBottom: '17px', ...progressStyle }}
      />
      { uploadProgress !== 100
        ? <UploadParagraphWrapper>Uploading file, please wait...</UploadParagraphWrapper>
        : <UploadParagraphWrapper>File uploaded successfully!</UploadParagraphWrapper> }
    </Modal>
  </>);
}

UploadModal.propTypes = {
  wrapClassName: PropTypes.string,
  uploadModalVisibility: PropTypes.bool,
  onCancel: PropTypes.func,
  progressType: PropTypes.string,
  uploadProgress: PropTypes.number,
  bodyStyle: PropTypes.object,
  progressStyle: PropTypes.object,
};

UploadModal.defaultProps = {
  wrapClassName: '',
  uploadModalVisibility: false,
  onCancel: () => {},
  progressType: 'circle',
  uploadProgress: 0,
  bodyStyle: {},
  progressStyle: {},
};

const mapStateToProps=(state)=>{
  const {globalUploadModal, eventOverview: {isFileUploading}} = state;
  return {
    globalUploadModal,
    isFileUploading
  }
}

export default connect(mapStateToProps)(UploadModal)