import React, { useEffect, useState } from 'react';
import Paragraph from 'shared/components/Paragraph';
import {
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FLL_CHALLENGE_ID,
  FTC_ID
} from 'shared/constants/programIds';
import Button from '../Button/BtnPrimary';
import SponsorLogo from './SponsorLogo';
import './EventSponsors.scss';
import { useTranslation } from 'react-i18next';

const globalSponsors = [
  // { name: 'FIRST', width: 150, src: '/FIRST_Horz_RGB.png', programs: [FLL_CHALLENGE_ID, FLL_DISCOVER_ID, FLL_EXPLORE_ID] },
  // { name: 'Lego', width: 270, src: '/logos/lego_education.png', programs: [FLL_CHALLENGE_ID, FLL_DISCOVER_ID, FLL_EXPLORE_ID] },
  // { name: 'Lego Foundation', width: 270, src: '/logos/lego_foundation.png', programs: [FLL_CHALLENGE_ID, FLL_DISCOVER_ID, FLL_EXPLORE_ID] },
  // { name: 'Jhon Deere', width: 270, src: '/logos/lego_foundation.png', programs: [FLL_CHALLENGE_ID] },
  // { name: 'Qualcomm', width: 220, src: '/logos/qualcomm.png', programs: [FTC_ID] },
  // { name: 'Collins Aerospace', width: 220, src: '/logos/collins_aerospace.png', programs: [FTC_ID] },
  // { name: 'ptc', width: 100, src: '/logos/ptc.png', programs: [FTC_ID] },
  // { name: 'Amazon', width: 150, src: '/logos/amazon.png', programs: [FLL_CHALLENGE_ID, FLL_DISCOVER_ID, FLL_EXPLORE_ID] },
  // { name: 'Rockwell Automation', width: 210, src: '/logos/rockwell_automation.png', programs: [FLL_CHALLENGE_ID] }
];

export default (props) => {
  const { renderHeader, seeAllDefault, sponsors, programId,hideErrorImage=true } = props;
  const [showAll, setShowAll] = useState(seeAllDefault);
  const sponsorLen = sponsors.length;
  const [len, setLen] = useState(sponsorLen);

  useEffect(()=>{
    setLen(sponsorLen);
  }, [sponsorLen])

  const { t } = useTranslation();
  return (
    <div className='c-event-sponsors-list'>
      <div className='c-event-sponsors-list__content'>
        {renderHeader()}
        {globalSponsors.length > 0 && <div className='c-event-sponsors-list__global-sponsors'>
          {globalSponsors
            .filter(({ programs }) => programs.includes(programId))
            .map(({ name, src, width }) => (
              <img
                key={name}
                src={src}
                alt={name}
                width={width}
              />
            ))
          }
        </div>}

        <div className='sponsors-logos m-b-40'>
          {(programId == FLL_DISCOVER_ID || programId == FLL_EXPLORE_ID) && (
            <div className='inline-block'>
              <div className='sponsors-title m-b-40 text-center'>FIRST<span className='trademark'>&reg;</span> LEGO<span className='trademark'>&reg;</span> LEAGUE GLOBAL SPONSORS</div>
              <div className='sponsors-logos-inline'>
                <img
                  src='/logos/lego_education.png'
                  alt='education'
                  className='m-r-40'
                />
                <img
                  src='/logos/lego_foundation.png'
                  alt='lego_foundation'
                />
              </div>
            </div>
          )}

          {programId == FLL_CHALLENGE_ID && (
            <div>

              <div className='seperated-sponsors'>
                <div className='right-margin'>
                  <div className='sponsors-title text-center m-b-40'>FIRST<span className='trademark'>&reg;</span> LEGO<span className='trademark'>&reg;</span> LEAGUE GLOBAL SPONSORS</div>
                  <div className='sponsors-logos-inline'>
                    <img
                      src='/logos/lego_education.png'
                      alt='education'
                      className='m-r-40'
                    />
                    <img
                      src='/logos/lego_foundation.png'
                      alt='lego_foundation'
                      className='m-r-20'
                    />
                  </div>
                </div>

                <div>
                  <div className='sponsors-title text-center m-b-40 responsive-top'>FIRST<span className='trademark'>&reg;</span> LEGO<span className='trademark'>&reg;</span> LEAGUE CHALLENGE DIVISION SPONSORS</div>
                  <div className='sponsors-logos-inline'>
                    <img
                      src='/logos/rockwell_automation.png'
                      alt='rockwell_automation'
                      className='m-r-40'
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <br></br>

        {len > 0 && !showAll && (
          <div className='c-event-sponsors-list__see-all'>
            <Button size='medium' onClick={() => setShowAll(true)}>
              {t('seeAllSponsorsLabel')}
            </Button>
          </div>
        )}
        <div
          className={'c-event-sponsors-list__event-sponsors'}
          style={!showAll ? { display: 'none' } : {}}
        >
          <div className='c-event-sponsors-list__sponsor-cards'>
            {sponsors.map(({ id, name }) => (
              <SponsorLogo hideErrorImage={hideErrorImage} setLen={setLen} key={id} id={id} name={name} />
            ))}
          </div>
          {/* <div className='c-event-sponsors-list__sponsor-names'>
            {sponsors.map(({ id, name }) => (
              <Paragraph key={id} size='medium' weight='bold' className='m-b-20'>
                {name}
              </Paragraph>
            ))}
          </div> */}
        </div>
      </div>
    </div>
  );
};
