import * as eventService from 'utils/services/events';
import Event from 'models/Event';
import * as types from '../../types/judgingRubric';

const getEventBegin = () => {
  return {
    type: types.GET_RUBRIC_EVENT_DETAILS_BEGIN
  }
}

const getEventError = () => {
  return {
    type: types.GET_RUBRIC_EVENT_DETAILS_ERROR
  }
}

const getEventSuccess = (eventDetails) => {
  return {
    type: types.GET_RUBRIC_EVENT_DETAILS_SUCCESS,
    payload: {
      eventDetails
    }
  }
}

const getEvent = (eventId) => {
  return async dispatch => {
    await dispatch(getEventBegin());
    try {
      const {included, data} = await eventService.getEventById(eventId);
      const eventDetails = new Event(data, included);
      const formattedEvent = {
        eventLocation: eventDetails.getEventLocation(),
        eventId: eventDetails.eventId,
        eventDate: eventDetails.parseStartEndTimeShortenedString(),
        eventName: eventDetails.name,
        programId: eventDetails.programId
      }
    await dispatch(getEventSuccess(formattedEvent))
    } catch(err) {
      await dispatch(getEventError())
      throw err;
    }

  }

}

export default getEvent;