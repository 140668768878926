import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'shared/components/Tooltip';

import '../../../../../../features/PanelManagement/styles/JudgingPanelModal.scss';


const PanelTitle = props => {
  const {title, panelSubtitle, instructions} = props;

  return (
    <div className='judging-panel-modal__title'>
      <section>
        <h1 className='heading--charcoal h-2 h--bold'>{title}</h1>
      </section>
      {instructions && (
        <Tooltip text={instructions} />
      )}
    </div>
  );
};

PanelTitle.propTypes = {
  title: PropTypes.string,
  panelSubtitle: PropTypes.string,
  instructions: PropTypes.string
};

PanelTitle.defaultProps = {
  title: '',
  panelSubtitle: 'Must assign at least two volunteers',
  instructions: undefined
}
export default PanelTitle;