import * as types from './leaderboardTypes';

const DEFAULT_STATE = {
  leaderboardId: null,
  teams: [],
  leaderboard: {},
  isFetchingLeaderboard: false,
  isPublishingBoard: false
};

export default (state = DEFAULT_STATE, action) => {
  const {type} = action;
  switch(type) {
    case types.GET_EVENT_LEADERBOARD_BEGIN:
      return {
        ...state,
        teams: [],
        leaderboardId: action.payload,
        isFetchingLeaderboard: true
      };
    case types.GET_EVENT_LEADERBOARD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetchingLeaderboard: false
      };
    case types.GET_EVENT_LEADERBOARD_FAIL:
      return {
        ...state,
        teams: [],
        leaderboardId: null,
        isFetchingLeaderboard: false
      };
    case types.PUBLISH_EVENT_LEADERBOARD_BEGIN:
      return {
        ...state,
        isPublishingBoard: true
      };
    case types.PUBLISH_EVENT_LEADERBOARD_SUCCESS:
      return {
        ...state,
        leaderboard: {
          ...state.leaderboard,
          ...action.payload,
        },
        isPublishingBoard: false
      };
    case types.PUBLISH_EVENT_LEADERBOARD_FAIL:
      return {
        ...state,
        isPublishingBoard: false
      };
    default:
      return state;
  }
};
