import { notification } from "antd";
import _ from "lodash";
import { addAllAssociatedDaysToBreaks, createOrUpdateAlScheduleBreaks, deleteAllAssociatedDaysToBreaks, deleteBreakById, generateScheduleSessions, updateAllDayInfoByIds, updateScheduleSessionSetting } from "utils/services/schedules";
import * as types from '../../types/eventScheduleTypes';

const generateBreakDays = (days)=>{
  const break_days_set = {};
  ((days||{}).data||[]).map(d=>{
    const d_id = d.id;
    const breaks = ((((d||{}).relationships||{}).breaks||{}).data||[]);
    breaks.forEach(b => {
      const b_id = b.id;
      if(b_id in break_days_set){
        break_days_set[b_id].push(d_id)
      }else{
        break_days_set[b_id] = [d_id];
      }
    });
  })
  return break_days_set
}

export const extractUpdatedBreaks = (days, original_breaks, new_breaks) =>{
  const break_days_set = generateBreakDays(days);
  const breaks_set = {};
  const original_data = (original_breaks||{}).data;
  const updated_breaks = [];
  original_data.forEach(o=>{
    const {id, attributes} = o;
    breaks_set[id] = attributes;
  });

  new_breaks.forEach(n => {
    const {id, attributes} = n;
    if(id < 0 ){
      updated_breaks.push(n);
    }else{
      const origin = breaks_set[id]||{};
      const old_break_days = break_days_set[id]||[];
      const new_break_days = (attributes.break_days||[]).map(i=>(i||{}).id);
      if(origin.name !== attributes.name || origin.start_time !== attributes.start_time || origin.end_time !== attributes.end_time || !_.isEqual(old_break_days.sort(), new_break_days.sort())){
        updated_breaks.push(n);
      }
    }
  });
  return updated_breaks;
}

export const validateBreakFields = (b)=>{
  const {attributes} = b||{};
  const {name, start_time, end_time, break_days} = attributes || {};
  if(!name) return "Name could not be empty"
  if(!start_time) return "Start time could not be empty"
  if(!end_time) return "End time could not be empty"
  if(!break_days || break_days.length === 0) return "BreakPeriodException"
  return '';
}

export const handleScheduleBreakCreateAndUpdate = (breaks, days) => {
  return async (dispatch, getState) =>{
    const {event: {eventDetails: {eventScheduleId}}} = getState();
    const updated_breaks = [...breaks]
    let errorMessage = '';
    for (const ub of updated_breaks){
      errorMessage = validateBreakFields(ub);
      if(!!errorMessage){
        break;
      }
    }

    if(errorMessage) {
      throw({data:{error: errorMessage}})
    }
    try {
      const days_break_set = generateBreakDays(days);
      const updated_days_break_set = {};
      const formattedPayloads = updated_breaks.map(b=>{
        const break_id = b.id;
        const attributes = b.attributes;
        const break_days = attributes.break_days;
        const days_of_break = (break_days||[]).map(b=>b.id);
        updated_days_break_set[break_id] = days_of_break;
        delete attributes.break_days;
        if(break_id < 0){
          const payload = {
            "data": {
              "type": "schedule_break",
              "id": break_id,
              "attributes": attributes,
              "relationships": {
                  "schedule": {
                      "data": {
                          "type": "schedule",
                          "id": eventScheduleId
                      }
                  }
              }
            }
          }
          return payload
        }else{
          const payload = {
            "data": {
              "type": "schedule_break",
              "id": break_id,
              "attributes": attributes
            }
          }
          return payload;
        }
      })
      const result = await createOrUpdateAlScheduleBreaks(formattedPayloads);

      let addAssociatedDays = [];
      let deleteAssociatedDays = []

      result.forEach(res=>{
        const break_id = res.data.id;
        const prevId = res.prevId;
        const days_of_break = updated_days_break_set[break_id]||updated_days_break_set[prevId]||[];
        const current_days_of_break = days_break_set[break_id]||[];
        const delete_days_of_break = current_days_of_break.filter(c=>!days_of_break.includes(c));
        const add_days_of_break = days_of_break.filter(a=>!current_days_of_break.includes(a));
        const addPayload = add_days_of_break.map(i=>({type:'schedule_day', id:i}))
        const deletePayload = delete_days_of_break.map(i=>({type:'schedule_day', id:i}))
        addPayload && addPayload.length > 0 && addAssociatedDays.push({break_id, data: addPayload})
        deletePayload && deletePayload.length > 0 && deleteAssociatedDays.push({break_id, data: deletePayload})
      });
      await addAllAssociatedDaysToBreaks(addAssociatedDays);
      await deleteAllAssociatedDaysToBreaks(deleteAssociatedDays);
    }catch(e){
      throw(e);
    }
  }
}

export const handleScheduleDaysUpdate = (updated_days, days, cb) =>{
  return async (dispatch, getState)=>{
    const current_days_data = (days||{}).data||[];
    const days_set = {};
    current_days_data.map(u=>{
      days_set[u.id] = {
        start_time: u.attributes.start_time,
        end_time: u.attributes.end_time,
      }
    })
    const days_needs_update = updated_days.filter(c=>{
     const day = days_set[c.id];
     return day.start_time !== c.start_time || day.end_time != c.end_time;
    });

    const payload_list = days_needs_update.map(d=>{
      const {id, end_time, start_time} = d;
      return {
        "data": {
            "type": "schedule_day",
            "id": id,
            "attributes": {
                start_time,
                end_time
            }
        }
      }
    });
    await updateAllDayInfoByIds(payload_list)
  }
}

export const handleScheduleSessionDurationUpdate = (duration) => {
  return async (dispatch, getState)=>{
    const {event: {eventDetails: {eventScheduleId}}} = getState();
    const payload = {
      data: {
        type: 'schedule',
        id: eventScheduleId,
        attributes: {
          ...duration,
        }
      }
    }

    await updateScheduleSessionSetting(eventScheduleId, payload)
  }
}

export const generateScheduleBegin = (payload='')=>{
  return {type: types.GENERATE_SCHEDULE_SESSIONS_BEGIN, payload}
}

export const generateScheduleSuccess = ()=>{
  return {type: types.GENERATE_SCHEDULE_SESSIONS_SUCCESS}
}

export const generateScheduleError = ()=>{
  return {type: types.GENERATE_SCHEDULE_SESSIONS_ERROR}
}

export const resetGenerateScheduleCompleteStatus = ()=>{
  return {type: types.RESET_GENERATE_SCHEDULE_COMPLETE}
}

export const handleScheduleGenerating = (savingSettings, callback)=>{
  return async (dispatch, getState)=>{
    try{
      const {event: {eventDetails: {eventScheduleId}}} = getState();
      await dispatch(generateScheduleBegin("Generating schedule..."));
      await savingSettings();
      const payload = {
        "data": {
            "type": "schedule_generation_request",
            "relationships": {
                "schedule": {
                    "data": {
                        "type": "schedule",
                        "id": eventScheduleId
                    }
                }
            }
        }
      }

      await generateScheduleSessions(payload);
      await dispatch(generateScheduleSuccess());
      callback && callback();
    }catch(e){
      dispatch(deleteBreakSuccess());
      throw(e);
    }
  }
}

export const deleteBreakBegin = (payload = '') =>{
  return {type: types.DELETE_BREAK_BY_ID_BEGIN, payload}
}

export const deleteBreakSuccess = (payload = '') =>{
  return {type: types.DELETE_BREAK_BY_ID_SUCCESS, payload}
}

export const handleDeleteBreakById = (id) => {
  return async (dispatch) => {
    try{
      await dispatch(deleteBreakBegin("Deleting break..."));
      await deleteBreakById(id);
      await dispatch(deleteBreakSuccess());
    }catch(e){
      dispatch(deleteBreakSuccess());
      throw(e);
    }
  }
}

export const savingScheduleSettingBegin = (payload = '') =>{
  return {type: types.SAVING_SCHEDULES_SETTINGS_BEGIN, payload}
}

export const savingScheduleSettingEnd = (payload) => {
  return {type: types.SAVING_SCHEDULES_SETTINGS_END, payload}
}