import * as addEventTypes from 'features/AddEventModal/redux/types';
import * as types from '../types/eventsTypes';

const DEFAULT_EVENTS_STATE = {
  eventsList: [],
  count: 0,
  isLoadingEvents: false,
  newEventCreated: false,
  eventsError: false,
  isDeletingEvent: false,
  hasEventDeleted: false
};

export default (state = DEFAULT_EVENTS_STATE, action) => {
  switch(action.type) {
    case types.FETCH_ADMIN_DASHBOARD_EVENTS_BEGIN:
      return {
        ...state,
        isLoadingEvents: true,
        newEventCreated: false,
        hasEventDeleted: false
      };
    case types.FETCH_ADMIN_DASHBOARD_EVENTS_ERROR:
      return {
        ...state,
        isLoadingEvents: false,
        eventsError: true
      };
    case types.FETCH_ADMIN_DASHBOARD_EVENTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoadingEvents: false,
        eventsError: false
      };
    case addEventTypes.CREATE_NEW_EVENT_SUCCESS:
      return {
        ...state,
        newEventCreated: true
      };
    case types.DELETE_EVENT_BEGIN:
      return {
        ...state,
        isDeletingEvent: true
      };
    case types.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        isDeletingEvent: false,
        hasEventDeleted: true
      };
    case types.DELETE_EVENT_ERROR:
      return {
        ...state,
        isDeletingEvent: false
      };
    case types.CLEAR_EVENT_DELETE_STATUS:
      return {
        ...state,
        hasEventDeleted: false
      };
    default:
      return state;
  }
};
