import React from 'react';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { Avatar} from 'antd';
import '../../../styles/AddTeamModal.scss';

const TeamOption = (props) => {

  const {teamName, country, region, teamNumber} = props;
  const boldText = `paragraph--charcoal paragraph--medium paragraph--bold`
  const regularText = `paragraph--charcoal paragraph--medium paragraph-normal`
  return (
    <div className='add-team-modal__select-option'>
      <span className='add-team-modal__select-name'>
        <Avatar src="/avatars/mad-scientist.png" size={48} icon={<UserOutlined />} className='add-team-modal__select-option__avatar' />
        <p className={`add-team-modal__select-name-child ${boldText}`}>{teamName}</p>
      </span>
      <span className='add-team-modal__select-option__col'>
        <p className={`add-team-modal__select-name-child ${regularText}`}>{country}</p>
      </span>
      <span className='add-team-modal__select-option__col'>
        <p className={`add-team-modal__select-name-child ${regularText}`}>{region}</p>
      </span>
      <span className='add-team-modal__select-option__team-number'>
        <p className={`add-team-modal__select-name-child ${regularText}`}>{teamNumber}</p>
      </span>
    </div>
  );
};


TeamOption.propTypes = {
  teamName: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  teamNumber: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired
}


export default TeamOption;