import React from 'react';
import PropTypes from 'prop-types';
import LawIcon from 'shared/components/Icon/icons/Law';
import Ballot from 'shared/components/Icon/icons/Ballot';
import Trophy from 'shared/components/Icon/icons/Trophy';
import GrandOpening from 'shared/components/Icon/icons/GrandOpening';
import Icon from '@ant-design/icons';
import { connect } from 'react-redux';
import { format24and12Time } from 'utils/dateTimeUtils';
import { useTranslation } from 'react-i18next';

const CompleteTimestamp = props => {
  const { iconType, targetDateString, targetTimeString, cardType, title, use_24_hour_format } = props;
  const { t } = useTranslation();
  const renderIcon = () => {
    switch (iconType) {
      case 'ballot':
        return Ballot;
      case 'trophy':
        return Trophy;
      case 'opening':
        return GrandOpening;
      default:
        return LawIcon
    }
  }
  
  const date = targetDateString ? targetDateString : t('notSetLabel');
  const timeStr = format24and12Time(targetTimeString, use_24_hour_format);
  const time = timeStr ? timeStr : t('notSetLabel');


  return (
    <div>
      <div className="event-schedule-card event-schedule-card--complete">
        <div className={`event-schedule-card__rectangle event-schedule-card__rectangle--complete event-schedule-card__rectangle--${cardType}`}>
          <Icon component={renderIcon()} className='event-schedule-card__event-icon' />
        </div>
        <div className='event-schedule-card__data event-schedule-card__data--complete'>
          <p className='paragraph--default paragraph--bold paragraph--charcoal event-schedule-card__data__title'>{title}</p>
          <div className='event-schedule-card__data__date-time'>
            <p className='paragraph--medium paragraph--charcoal'>{date}</p>
            <p className='paragraph--medium paragraph--charcoal'>{time}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

CompleteTimestamp.propTypes = {
  targetDateString: PropTypes.string,
  targetTimeString: PropTypes.string,
  cardType: PropTypes.string,
  title: PropTypes.string,
  iconType: PropTypes.string
};

CompleteTimestamp.defaultProps = {
  targetDateString: '',
  targetTimeString: '',
  cardType: 'judging',
  title: '',
  iconType: 'judging'
}

const mapStateToProps = (state) => {
  const { auth: { userInfo: { use_24_hour_format } } } = state;

  return {
    use_24_hour_format
  }
}

export default connect(mapStateToProps)(CompleteTimestamp);