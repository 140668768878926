import * as userService from 'utils/services/users';
import * as scoreInputService from 'utils/services/scoreInput';
import { setScoreInputComments } from 'features/EventOverviewContainer/redux/actions/matchScoresheet/scoreInput';
import * as types from '../../types/coachScoringTypes';
import { getScoreComment, getScoreComment2023 } from 'utils/services/scoreComment';
import { convertTimeToTimeAndDateFormat } from 'utils/dateTimeUtils';
import { getScoreById, getScoreById2023, getScoreInputById2023 } from 'utils/services/score';
import { CURRENT_SCORE_INPUT_RESPONSE_TYPE } from 'shared/constants/scoreConstants';
import { CURRENT_EVENT_SEASON } from 'shared/constants/eventConstants';

const getSelectedScoreInputBegin = () => {
  return {
    type: types.GET_SELECTED_MATCH_SCORE_INPUT_BEGIN
  }
}

const getSelectedScoreInputError = () => {
  return {
    type: types.GET_SELECTED_MATCH_SCORE_INPUT_ERROR
  }
}

const getSelectedScoreInputSuccess = (scoreInputData) => {
  return {
    type: types.GET_SELECTED_MATCH_SCORE_INPUT_SUCCESS,
    payload: {
      scoreInputData
    }
  }
}

const resetSelectedScoreInput = () => {
  return {
    type: types.RESET_SELECTED_MATCH_SCORE_INPUT
  }
}

export const resetSelectedMatchScoreInput = () => {
  return async dispatch => {
    await dispatch(resetSelectedScoreInput())
  }
}

const getScoreInputDetails = (scoreId) => {
  return async (dispatch, getState) => {
    const { matchScoresheet: { eventDetails: { season } } } = getState();
    try {
      let scoreData;
      await dispatch(getSelectedScoreInputBegin())
      if (season == CURRENT_EVENT_SEASON) {
        scoreData = await getScoreById2023(scoreId);
      } else {
        scoreData = await getScoreById(scoreId);
      }

      const score_input_id = (((((scoreData || {}).data || {}).relationships || {})[CURRENT_SCORE_INPUT_RESPONSE_TYPE] || {}).data || {}).id || ''

      let data = {};
      if (season == CURRENT_EVENT_SEASON) {
        let responseData = score_input_id ? await scoreInputService.getScoreInputById2023(score_input_id) : {};
        data = responseData.data
      } else {
        let responseData = score_input_id ? await scoreInputService.getScoreInputById(score_input_id) : {};
        data = responseData.data
      }


      const { attributes, relationships } = data || {};
      const totalScore = (attributes || {}).overall_score;
      const scoreInputData = {
        ...attributes,
        totalScore
      }

      const scoreComments = ((relationships || {}).score_comments || {}).data || [];
      let formattedScoreComments = [];
      if (scoreComments.length) {
        let scoreInputComments = [];
        for (const comment of scoreComments) {
          const { id } = (comment || {});
          if (id) {
            if(season == CURRENT_EVENT_SEASON) {
              const { data } = await getScoreComment2023(comment.id);
              scoreInputComments.push(data);
            }else{
              const { data } = await getScoreComment(comment.id);
              scoreInputComments.push(data);
            }
          }
        }

        const refereeCommenters = scoreInputComments.map(c => {
          return ((c || {}).attributes || {}).referee_id;
        });
        // IF THERE ARE ALREADY COMMENTS ASSOCIATED WITH THE SCORE INPUT
        if (refereeCommenters.length) {
          const userFilter = {
            'id': `in:${refereeCommenters.join(',')}`
          }

          const usersResponse = await userService.getUsersBy(userFilter);
          const formattedRefereeCommenters = usersResponse.data.map(d => {
            return {
              firstName: d.attributes.first_name,
              lastName: d.attributes.last_name,
              userId: d.id
            }
          });

          formattedScoreComments = scoreInputComments.map(c => {
            const refereeId = c.attributes.referee_id;
            const commenter = formattedRefereeCommenters.find(r => r.userId === refereeId);
            let time = ((c || {}).attributes || {}).updated_at || ((c || {}).attributes || {}).created_at;
            if (time) {
              time = convertTimeToTimeAndDateFormat(time);
            }
            return {
              comment: c.attributes.comment,
              fullName: commenter ? `${commenter.firstName} ${commenter.lastName}` : '',
              refereeId,
              commentId: c.id,
              dateTime: time,
            }
          })
        }
      }
      await dispatch(setScoreInputComments(formattedScoreComments))
      await dispatch(getSelectedScoreInputSuccess(scoreInputData))
    } catch (err) {
      await dispatch(setScoreInputComments([]))
      await dispatch(getSelectedScoreInputError())
    }
  }

}

export default getScoreInputDetails;