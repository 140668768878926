import {combineReducers} from 'redux';
import judgesReferees from './judgesReducer';
import teams from './teamsReducer';

const panelManagementReducer = combineReducers({
  judgesReferees,
  teams
})


export default panelManagementReducer;