import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../../styles/eventScoringMatch/RefereeScoresheetNotes.scss';
import CommentTile from 'shared/components/CommentTile';
import {connect} from 'react-redux';
import {deleteRefereeNote} from 'features/EventOverviewContainer/redux/actions/matchScoresheet'
import moment from 'moment';

const titleClassName = `h-2 heading--charcoal m-b-8`;

const RefereeNotes = props => {
  const { t } = useTranslation()

  const {matchScoreComments, comments, useComments, use_24_hour_format} = props;

  const commentsData = useComments ? comments : matchScoreComments;

  const formatDateTime = (date) => {
    if(!moment(date, 'h:mma M/D/YYYY').isValid()) return '';
    return moment(date, 'h:mma M/D/YYYY').format(use_24_hour_format ? 'HH:mm M/D/YYYY' : 'h:mma M/D/YYYY')
  }

  return (
    <div className="referee-scoresheet-notes__container">
      <div className="referee-scoresheet-notes__header-readonly">
        <div className="referee-scoresheet-notes__title">
          <h3 className={titleClassName}>{t('refereeNotesLabel')}</h3>
        </div>
      </div>
      <ul className="referee-scoresheet-notes__notes">
        {commentsData.map((c) => (
          <li key={c.commentId} className="referee-scoresheet-notes__comment-wrapper">
            <CommentTile
              userId={c.refereeId}
              key={c.commentId}
              fullName={formatDateTime(c.dateTime)}
              comments={c.comment}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

RefereeNotes.propTypes = {
  matchScoreComments: PropTypes.array.isRequired,
  deleteNote: PropTypes.func.isRequired,
  isPublished: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {
    matchScoresheet: {
      matchScoreComments,
      scoreInputId,
      isMatchScoresheetPublished
    },
    auth: {
      userInfo: {
        use_24_hour_format
      }
    },
  } = state;
  return {
    use_24_hour_format,
    matchScoreComments,
    scoreInputId,
    isPublished: isMatchScoresheetPublished,
  }

}

export default connect(mapStateToProps, {deleteNote: deleteRefereeNote})(RefereeNotes);