import React from 'react';
import PropTypes from 'prop-types';
import Container from 'shared/components/Container';
import {connect} from 'react-redux';
import InnovationProject from './InnovationProject';
import RobotDesign from './RobotDesign';
import OptionalAwardsNomination from './OptionalAwardsNomination';
import FeedbackSection from './FeedbackSection';

const FLLChallengeRubric = props => {
  return (
    <Container additionalclassname='m-t-36'>
      <OptionalAwardsNomination />
      <InnovationProject />
      <RobotDesign />
      <FeedbackSection />
    </Container>
  );
};

FLLChallengeRubric.propTypes = {
  getRubric: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {

  const {judgingRubric} = state;
  return {
    pageDetails: judgingRubric
  }
}

export default connect(mapStateToProps)(FLLChallengeRubric);