const m06Section = {
  id: 'm06',
  name: 'PullupBarLabel',
  fields: [
    {
      scoring: 'm06sub1Label',
      id: 'm06_scoring_1',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        }
      ]
    },
    {
      scoring: 'm06sub2Label',
      id: 'm06_scoring_2',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        }
      ]
    },
  ]
}

export default m06Section;