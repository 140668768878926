import React from 'react';
import PropTypes from 'prop-types';
import { Input as AntInput } from 'antd';
import './input.scss';

const INPUT_SIZES = ['default', 'large', 'small']

const Input = props => {
  const { disabled, size } = props;
  const disabledClassName = 'input--disabled';
  const activeClassName = `input input--${size}`;

  return (
    <AntInput 
      disabled={disabled} 
      className={disabled ? disabledClassName : activeClassName}
      {...props}
    />
  );
};

Input.TextArea = AntInput.TextArea;

Input.Password = AntInput.Password;
Input.propTypes = {
  size: PropTypes.oneOf(INPUT_SIZES),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  additionalclassname: PropTypes.string
};

Input.defaultProps = {
  size: 'large',
  placeholder: undefined,
  disabled: false,
  additionalclassname: ''
};

export default Input;
