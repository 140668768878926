import * as panelService from 'utils/services/panels';
import ScheduleSession from 'models/ScheduleSession';
import UserData from 'models/User';
import * as types from '../../types/judgingTypes';

const fetchPanelBegin = () => {
  return {
    type: types.FETCH_SELECTED_JUDGING_PANEL_BEGIN
  }
}
const fetchPanelError = () => {
  return {
    type: types.FETCH_SELECTED_JUDGING_PANEL_ERROR
  }
}
const fetchPanelSuccess = (selectedPanel) => {
  return {
    type: types.FETCH_SELECTED_JUDGING_PANEL_SUCCESS,
    payload: {
      selectedPanel
    }
  }
}

const fetchSelectedPanel = (panelId) => {
  return async (dispatch, getState) => {
    const {event: {eventJudging, eventSchedule}} = getState();
    const {eventJudgesIncluded} = eventJudging;
    const {sessions: {judgingSessionsData, judgingSessionsIncluded, judgingSessionsTeams}} = eventSchedule;
    await dispatch(fetchPanelBegin());
    try {
      const response = await panelService.fetchPanelById(panelId);
    
      const {judgesIds, sessionsIds} = response;
      const panelJudgesList = [];
      if (Array.isArray(judgesIds) && judgesIds.length) {
        judgesIds.forEach(j => {
          const currentJudge = eventJudgesIncluded.find(i => i.id === j);
          if (currentJudge) {
            const judgeModel = new UserData(currentJudge);
            const formattedJudge = {
              userId: judgeModel.userId,
              fullName: judgeModel.userFullNameString(),
              email: judgeModel.email,
            }
            panelJudgesList.push(formattedJudge);
          }
        } )
      }
      const panelSessionsList = [];

      if (Array.isArray(sessionsIds) && sessionsIds.length) {
        const eventJudgingSessions = judgingSessionsData.map(d => {
          const judgingSession = new ScheduleSession(d, judgingSessionsIncluded, judgingSessionsTeams);
          return judgingSession
        })
        sessionsIds.forEach(i => {
          const session = eventJudgingSessions.find(j => j.sessionId === i);

          if (session) {
            const formattedSession = {
              sessionId: session.sessionId,
              teamName: session.getTeamNumberName(),
              teamId: session.teamId,
              sessionTypeName: session.getSessionTypeName()
            };
            panelSessionsList.push(formattedSession)
          }
        })
      }
      
      const selectedPanel = {
        ...response,
        panelJudgesList,
        panelSessionsList
      }

      const judgeOptions = 
      
      await dispatch(fetchPanelSuccess(selectedPanel))
    } catch(err) {
      await dispatch(fetchPanelError())
      throw err;
    }
  }
}

export default fetchSelectedPanel;