import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';
import WarningIcon from './WarningIcon';
import { useTranslation } from 'react-i18next';

const SessionLinkMessage = props => {
  const {
    boldMessage,
    warningMessage
  } = props;
  const {t} = useTranslation();

  const displayed_bold_message = boldMessage ? boldMessage : t('sessionLinkEditLabel');
  const displayed_warning_message = warningMessage ? warningMessage : t('sessionLinkWarningLabel');

  return (
    <div className='m-b-36'>
      <p className='paragraph--bold paragraph--charcoal paragraph--small'>{displayed_bold_message}</p>
      <span className='c-session-url__helper-text'>
        <Icon className='m-r-8' component={WarningIcon} />
        <span className='paragraph--normal paragraph--charcoal paragraph--small'>{displayed_warning_message}</span>
      </span>
    </div>
  );
};

SessionLinkMessage.propTypes = {
  boldMessage: PropTypes.string,
  warningMessage: PropTypes.string,
};

SessionLinkMessage.defaultProps = {
  boldMessage: '',
  warningMessage: ''
}

export default SessionLinkMessage;