import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import SingleEvent from 'models/Event';
import '../styles/EventModalHeader.scss';

const ModalHeader = (props) => {
  const {
    eventDetails
  } = props;
  const { included } = eventDetails;
  const eventPage = new SingleEvent(eventDetails, included);
  const { name } = eventPage;
  const eventDate = eventPage.parseStartEndTimeShortenedString();
  const eventLocation = eventPage.getEventLocation();

  return (
    <div className='event-modal-header'>
      <p className='paragraph--white  event-modal-header__text'>{name}</p>
      <div className="event-date-wrapper">
      <span className='event-modal-header__point'>&#8226;</span>
      <p className='paragraph--white event-modal-header__text event-modal-header__text--right'>
        {eventDate}
      </p>
      </div>
      <div className="event-location-wrapper">
      <span className='event-modal-header__point'>&#8226;</span>
      <p className='paragraph--white event-modal-header__text event-modal-header__text--right'>
        {eventLocation}
      </p>
      </div>
    </div>
  );
};

ModalHeader.propTypes = {
  eventDetails: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  const { event: { eventDetails } } = state;
  return { eventDetails };
};

export default connect(mapStateToProps)(ModalHeader);
