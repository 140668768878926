import React from 'react';
import PropTypes from 'prop-types';

const WarningIcon = props => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g transform="translate(-491.000000, -361.000000)" fill="#faad14" fillRule="nonzero"><g id="Group-4" transform="translate(490.000000, 177.000000)"><g id="Group-52" transform="translate(0.000000, 96.000000)"><g id="Group-5" transform="translate(1.000000, 65.000000)"><g transform="translate(0.000000, 23.000000)"><path d="M7 0c3.8664062.0 7 3.13359375 7 7 0 3.8664062-3.1335938 7-7 7-3.86640625.0-7-3.1335938-7-7C0 3.13359375 3.13359375.0 7 0zM7 10C6.44771525 10 6 10.4477153 6 11 6 11.5522847 6.44771525 12 7 12 7.55228475 12 8 11.5522847 8 11 8 10.4477153 7.55228475 10 7 10zM7 2C6.44771525 2 6 2.44771525 6 3V3 8C6 8.55228475 6.44771525 9 7 9 7.55228475 9 8 8.55228475 8 8V8 3C8 2.44771525 7.55228475 2 7 2z" id="Combined-Shape" /></g></g></g></g></g></g>
    </svg>
  );
};

WarningIcon.propTypes = {
  
};

export default WarningIcon;