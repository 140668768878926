import React, {useState} from 'react';
import PropTypes from 'prop-types';
import '../../../../../../shared/components/Scoresheet/styles/MatchScoresheetForm.scss';
import {Radio} from 'antd';
import moduleSixOptions from 'shared/constants/scoresheetOptions/m06';
import moduleSevenOptions from 'shared/constants/scoresheetOptions/m07';
import { useTranslation } from 'react-i18next';

/*
M06 and M07 are tied together. They are both end states, 
so it is not possible for the second question in M06 and 
the only question in M07 to both be YES. One of these questions
must be no as the robot cannot be in both locations at once.


*/
const moduleNameClassName = `h-2 heading--white`;
const moduleTitleClassName = `h-2 heading--charcoal`;
const paragraphClassName = 'paragraph--large paragraph--charcoal paragraph--normal'
const errorClassName = 'paragraph--large paragraph--dark-red paragraph--normal'

const moduleSixAndSevenErrorMessage = `M06 and M07 are tied together. It is not possible for the second question in M06 and the only question in M07 to both be YES. One of these questions must be NO as the robot cannot be in both locations at once.`;


const ModuleSection = props => {

  const {moduleName, moduleTitle, fields, scoreInput} = props;
  const [moduleSixError, setModuleSixError] = useState('');
  const [moduleSevenError, setModuleSevenError] = useState('');
  const [form] = Form.useForm();
  const {t} = useTranslation();

  const validateField = (rule, value, callback) => {
    const {field} = rule;
    const moduleSevenQuestion1 = form.getFieldValue('m07_scoring_1');
    const moduleSixQuestion2 = form.getFieldValue('m06_scoring_2');
    if (field !== 'm06_scoring_2' && field !== 'm07_scoring_1') {
      setModuleSixError('');
      setModuleSevenError('');
      callback();
    } else if (field === 'm06_scoring_2' && value && moduleSevenQuestion1) {
      setModuleSixError(moduleSixAndSevenErrorMessage);
      callback(moduleSixAndSevenErrorMessage)
    } else if (field === 'm07_scoring_1' && value && moduleSixQuestion2) {
      setModuleSevenError(moduleSixAndSevenErrorMessage);
      callback(moduleSixAndSevenErrorMessage);
    } else {
      setModuleSixError('')
      setModuleSevenError('')
      callback()
    }



  }
  
  return (
    <>

      <div className="scoresheet-module">
        <div className="scoresheet-module__name">
          <h3 className={moduleNameClassName}>{moduleSixOptions.id}</h3>
        </div>

        <div className="m-l-30 m-t-8 scoresheet-module__content">
          <h2 className={moduleTitleClassName}>{t(moduleSixOptions.name)}</h2>
          <div className="scoresheet-module__fields-wrapper">
            {fields.map((f) => (
              <div key={f.id} className="scoresheet-module__field">
                <span className={`${paragraphClassName} scoresheet-module__field-label`}>
                  {t(f.scoring)}
                  :
                </span>
                {f.type !== 'integer' ? (
                  <span>
                    {form.getFieldDecorator( f.id, {
                      initialValue: scoreInput[f.id],
                      rules: [{
                        validator: validateField
                      }]
                    }
                    )(
                      <Radio.Group
                        buttonStyle="solid"
                        className="match-scoresheet-form__radio-wrapper"
                        optionType="button"
                        disabled={scoreInput.is_published}
                      >
                        {f.options.map((o) => (
                          <Radio.Button
                            buttonStyle="solid"
                            className="match-scoresheet-form__radio-btn"
                            key={`${f.id}-${o.value}`}
                            value={o.value}
                          >
                            {t(o.description)}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    )}
                  </span>
                ) : null}
              </div>
            ))}
          </div>
          <p className={errorClassName}>{moduleSixError}</p>

        </div>
      </div>

      <div className="scoresheet-module">
        <div className="scoresheet-module__name">
          <h3 className={moduleNameClassName}>{moduleSevenOptions.id}</h3>
        </div>

        <div className="m-l-30 m-t-8 scoresheet-module__content">
          <h2 className={moduleTitleClassName}>{t(moduleSevenOptions.name)}</h2>
          <div className="scoresheet-module__fields-wrapper">
            {moduleSevenOptions.fields.map((f) => (
              <div key={f.id} className="scoresheet-module__field">
                <span className={`${paragraphClassName} scoresheet-module__field-label`}>
                  {t(f.scoring)}
                  :
                </span>
                {f.type !== 'integer' ? (
                  <span>
                    {form.getFieldDecorator( f.id, {
                      initialValue: scoreInput[f.id],
                      rules: [{
                        validator: validateField
                      }]
                    }
                    )(
                      <Radio.Group
                        buttonStyle="solid"
                        className="match-scoresheet-form__radio-wrapper"
                        optionType="button"
                        disabled={scoreInput.is_published}
                      >
                        {f.options.map((o) => (
                          <Radio.Button
                            buttonStyle="solid"
                            className="match-scoresheet-form__radio-btn"
                            key={`${f.id}-${o.value}`}
                            value={o.value}
                            id={o.value}
                          >
                            {t(o.description)}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    )}
                  </span>
                ) : null}
              </div>
            ))}
          </div>
          <p className={errorClassName}>{moduleSevenError}</p>

        </div>
      </div>
    </>
  );
};

ModuleSection.propTypes = {
  moduleName: PropTypes.string,
  moduleTitle: PropTypes.string,
  fields: PropTypes.array,
  form: PropTypes.object.isRequired
};

ModuleSection.defaultProps = {
  moduleName: '',
  moduleTitle: '',
  fields: []
};

export default ModuleSection;