import React from 'react';
import PropTypes from 'prop-types';
import CardsList from 'shared/components/CardsList';
import { useTranslation } from 'react-i18next';
import VolunteerTile from './VolunteerTile';

const VolunteersList = props => {
  const { t } = useTranslation()
  const { volunteers, loading, onEditClick, isEditButtonVisible, onGenerateInvitationLink, showInvitationLink } = props;

  return (
    <CardsList
      list={volunteers}
      loading={loading}
      renderTile={(volunteer) => <VolunteerTile {...volunteer} isEditButtonVisible={isEditButtonVisible} onEditClick={() => onEditClick(volunteer.id)} onGenerateInvitationLink={() => onGenerateInvitationLink(volunteer.id)} showInvitationLink={showInvitationLink}/>}
      tabName={t('volunteersLabel')}
    />
  );
};

VolunteersList.propTypes = {
  list: PropTypes.array,
  volunteers: PropTypes.array,
  loading: PropTypes.bool,
  onEditClick: PropTypes.func,
  isEditButtonVisible: PropTypes.bool
};

VolunteersList.defaultProps = {
  loading: false,
  list: [],
  volunteers: [],
  onEditClick: () => {},
  isEditButtonVisible: false
};

export default VolunteersList;
