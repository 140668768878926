import React from 'react';
import Modal from 'shared/components/EventModal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModalHeader from './AddRefereeModalHeader';
import AddNoteForm from './AddNoteForm';


const AddRefereeNote = (props) => {
  const {
    visible,
    onCancel,
    programId,
    commentId,
    extraClass
  } = props;

  return (
    <Modal
      onCancel={onCancel}
      visible={visible}
      programId={programId}
      renderHeader={() => <ModalHeader />}
      extraClass={extraClass}
    >
      <AddNoteForm callback={onCancel} onCancel={onCancel} commentId={commentId} />
    </Modal>
  );
};

AddRefereeNote.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  const { matchScoresheet: { eventDetails } } = state;
  const {
    programId
  } = eventDetails
  return {
    programId
  }
}
export default connect(mapStateToProps)(AddRefereeNote);