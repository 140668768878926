export const formatMatchFile = (uploadedFile) => {
  const {attributes, id, type} = uploadedFile;
  return  {
    eventFileId: id,
    contentType: attributes.content_type,
    fileName: attributes.original_file_name,
    rawUrl: attributes.raw_url,
    remoteUrl: attributes.remote_url,
    type,
    status: attributes.status,
    updatedAt: attributes.updated_at
  }
}

export const formatMatchFiles = (uploadedFiles, included) => {
  const sessionFiles = []; 
  uploadedFiles.forEach(f => {
    const selectedFile = included.find(i => i.id === f.id);
    if (selectedFile) {
      const fileDetails = formatMatchFile(selectedFile)
      sessionFiles.push(fileDetails);
    }
  })
  return sessionFiles;
}