const types = [
  {
    id: '07eaac17-61b8-4f50-8768-552fb9ea78ba',
    keyName: 'engineeringNotebookSubmissionLabel',
    name: 'Engineering Notebook submission (PDF)',
    keyNote: '',
    notes: 'Team uploads specific page from notebook (1),'
  },
  {
    id: '0a086228-4946-4666-8373-c885e7dc9e4b',
    keyName: '',
    name: 'Interactive Engineering Notebook Submission',
    keyNote: '',
    notes: 'Team to specify which pages to review when uploading'
  },
  {
    id: '1d435b41-5231-4dfd-9fbe-4e100f2f74e2',
    keyName: '',
    name: 'Interactive Engineering Notebook Submission',
    keyNote: '',
    notes: 'Team to specific which pages to review when uploading.'
  },
  {
    id: '4ae90895-eaa4-473a-bcce-26f8f6f4d65c',
    keyName: 'reviewingQuestionsLabel',
    name: 'Reviewing Questions',
    keyNote: '',
    notes: 'Download spread sheet template and upload when completed.'
  },
  {
    id: '55cf5e4d-3c97-425c-a5f9-dde5bd3317a2',
    keyName: 'teamMaterialsLabel',
    name: 'Team Materials',
    keyNote: '',
    notes: 'Video or photo upload showing team members being a team (up to 5).'
  },
  {
    id: '671eda71-7c9d-456a-92a7-8400aeb9fea9',
    keyName: 'teamMaterialsLabel',
    name: 'Team Materials',
    keyNote: '',
    notes: 'Video and/or photo upload; includes Team Poster, Team Model and team photos (up to 5 photos total)'
  },
  {
    id: '691bdb05-02f1-45b1-9c01-23bcacb20601',
    keyName: 'InnovationProjectPresentationLabel',
    name: 'Innovation Project Presentation',
    keyNote: 'oneVideoMaxLabel',
    notes: 'One video - 5 minutes max.'
  },
  {
    id: '6a0397bb-f378-4ccb-82fa-ae982979316e',
    keyName: 'coachMentorAwardNominationFormLabel',
    name: 'Coach/Mentor Award Nomination Form',
    keyNote: '',
    notes: ''
  },
  {
    id: 'a9619e3e-c4f8-4a17-911e-580324ed7b65',
    keyName: 'reviewingQuestionsLabel',
    name: 'Reviewing Questions',
    keyNote: '',
    notes: 'Download spread sheet template and upload when completed.'
  },
  {
    id: 'eb237e10-c90f-4980-9e03-03e7e3db5b8a',
    keyName: 'RobotDesignPresentationLabel',
    name: 'Robot Design Presentation',
    keyNote: 'canBeDocumentLabel',
    notes: 'Can be document, photo and/or video; video - 5 minutes max; document - 1 page max; up to 5 photos. Can submit one or all three.'
  },
]

export default types