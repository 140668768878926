import React from 'react';
import PropTypes from 'prop-types';
//import GameChangers from 'shared/components/Icon/logos/GameChangers';
import RoleSelection from 'features/DashboardContainer/RoleSelection';
import './styles/DashboardLayout.scss';
import FirstLogo from '../../../shared/components/Icon/pngs/first-season-logo-current-dark-background.png';

const DashboardLayout = (props) => {
  const { children } = props;

  return (
    <div>
      <div className='dashboard-layout'>
        <div className='dashboard-layout__content'>
          <div className='dashboard-layout__sponsor-wrapper'>
            <RoleSelection /> 
            <img src={FirstLogo} alt="FIRST Season Logo" className="dashboard-layout__sponsor-logo"/>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default DashboardLayout;
