import * as types from '../types/eventSponsorsTypes';
import * as sponsorService from 'utils/services/sponsors';
import * as eventFileService from 'utils/services/eventFiles';
import { notification } from 'antd';
import get from 'lodash/get'
import find from 'lodash/find'

export const resetSponsorCreateState = () => {
  return {
    type: types.RESET_SPONSOR_CREATED_STATE
  };
};

const getEventSponsorsBegin = (payload) => {
  return {
    type: types.GET_EVENT_SPONSORS_BEGIN,
    payload
  };
};

const getEventSponsorsError = () => {
  return {
    type: types.GET_EVENT_SPONSORS_FAIL
  };
};

const getEventSponsorsSuccess = (payload) => {
  return {
    type: types.GET_EVENT_SPONSORS_SUCCESS,
    payload
  };
};

export const fetchEventSponsors = (scheduleId) => async (dispatch) => {
  try {
    dispatch(getEventSponsorsBegin());
    const sponsors = await sponsorService.getAllSponsors(scheduleId);
    dispatch(getEventSponsorsSuccess(sponsors));
  } catch (e) {
    dispatch(getEventSponsorsError());
  }
};

const saveEventSponsorBegin = (payload) => {
  return {
    type: types.SAVE_EVENT_SPONSOR_BEGIN,
    payload
  };
};

const saveEventSponsorError = () => {
  return {
    type: types.SAVE_EVENT_SPONSOR_FAIL
  };
};

const saveEventSponsorSuccess = (payload) => {
  return {
    type: types.SAVE_EVENT_SPONSOR_SUCCESS,
    payload
  };
};

export const saveEventSponsor = (sponsor, callback) => async (dispatch, getState) => {
  try {
    dispatch(saveEventSponsorBegin());
    const { event: { eventDetails: { eventScheduleId } } } = getState();
    const { id, name } = sponsor;
    const res = !id 
      ? await sponsorService.createSponsor({
          sponsorName: name, scheduleId: eventScheduleId
        })
      : await sponsorService.updateSponsor(sponsor);
    notification.success({
      message: 'Success',
      description: 'Sponsorship saved.'
    });
    callback && callback();
    dispatch(saveEventSponsorSuccess(res));
  } catch (e) {
    dispatch(saveEventSponsorError());
  }
};

const uploadSponsorLogoBegin = (payload) => {
  return {
    type: types.UPLOAD_SPONSOR_LOGO_BEGIN,
    payload
  };
};

const uploadSponsorLogoError = () => {
  return {
    type: types.UPLOAD_SPONSOR_LOGO_FAIL
  };
};

const uploadSponsorLogoSuccess = (payload) => {
  return {
    type: types.UPLOAD_SPONSOR_LOGO_SUCCESS,
    payload
  };
};

// const uploadSponsorLogoRetry = (payload) => {
//   return {
//     type: types.UPLOAD_SPONSOR_LOGO_RETRY,
//     payload
//   };
// }

export const uploadSponsorLogo = (id, sponsorName, file, onProgress, onErrorFallback) => async (dispatch, getState) => {
  // const { eventSponsors: { uploadRetry } } = getState();
  try {
    dispatch(uploadSponsorLogoBegin());
    let sponsorId = id;
    let result;
    if (!id) {
      const { event: { eventDetails: { eventScheduleId } } } = getState();
      const sponsor = {
        sponsorName, scheduleId: eventScheduleId
      };
      result = await sponsorService.createSponsor(sponsor);
      notification.success({
        message: 'Success',
        description: 'New sponsorship created.'
      });
      sponsorId = result.id;
    }

    // Initiate logo upload and receive S3 presigned url + sponsor id
    const initLogoUploadResponse = await eventFileService.initiateSponsorLogoUpload(sponsorId, file)
    const presignedUrlObject = initLogoUploadResponse.included && find(initLogoUploadResponse.included, response => response.type === 'pre-signed_url')
    const presignedUrl = get(presignedUrlObject, ['attributes', 'url'], undefined)
    const fileId = get(initLogoUploadResponse, ['data', 'id'], undefined)
    if (!presignedUrl || !fileId) throw 'missingUrl'

    // Upload logo with S3 presigned url, attach caculateProgress callback for progress bar modal
    await eventFileService.uploadFileToS3(presignedUrl, file, onProgress)

    // Edit sponsor event file to have 'Uploaded' status
    const editSponsorResponse = await eventFileService.editSponsorEventFile(sponsorId, fileId, { status: 'Uploaded' })

    if (result) dispatch(saveEventSponsorSuccess(result));

    notification.success({
      message: 'Success',
      description: 'Sponsorship logo uploaded.'
    });
    
    dispatch(uploadSponsorLogoSuccess(editSponsorResponse));
  } catch (error) {
    dispatch(uploadSponsorLogoError());
    onErrorFallback(error)
    // // Retry up to 3 times if upload request stalls or times out
    // if (get(error, ['config', 'onUploadProgress'], false) && uploadRetry < 3) {
    //   dispatch(uploadSponsorLogoError());
    //   dispatch(uploadSponsorLogoRetry(uploadRetry + 1))
    //   await dispatch(handleDocumentUpload(matchId, teamId, file, onProgress, onErrorFallback))
    // } else {
    //   if (error === 'missingUrl') console.warn('Missing presigned url')
    //   dispatch(uploadSponsorLogoError());
    //   onErrorFallback(error)
    // }
  }
};

const deleteEventSponsorBegin = (payload) => {
  return {
    type: types.DELETE_EVENT_SPONSOR_BEGIN,
    payload
  };
};

const deleteEventSponsorError = () => {
  return {
    type: types.DELETE_EVENT_SPONSOR_FAIL
  };
};

const deleteEventSponsorSuccess = (payload) => {
  return {
    type: types.DELETE_EVENT_SPONSOR_SUCCESS,
    payload
  };
};

export const deleteEventSponsor = (sponsorId) => async (dispatch) => {
  try {
    dispatch(deleteEventSponsorBegin());
    await sponsorService.deleteSponsor(sponsorId);
    notification.success({
      message: 'Success',
      description: 'Sponsorship deleted.'
    });
    dispatch(deleteEventSponsorSuccess(sponsorId));
  } catch (e) {
    dispatch(deleteEventSponsorError());
  }
};
