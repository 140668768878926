const m08Section = {
  id: 'm08',
  name: '2022ScoresheetM08Sub1Label',
  fields: [
    {
      scoring: '2022ScoresheetM08Sub2Label',
      id: 'm08_scoring_1',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        }
      ]
    },
    {
      scoring: "2022ScoresheetM08Sub3Label",
      id: 'm08_scoring_2',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        }
      ]
    },
    {
      scoring: "2022ScoresheetM08Sub4Label",
      id: 'm08_scoring_3',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        }
      ]
    },
  ]
}

export default m08Section;