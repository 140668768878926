import React, {useEffect, useState} from 'react';
import Icon from '@ant-design/icons';
import {useParams} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tooltip from 'shared/components/Tooltip';
import PropTypes from 'prop-types';
import * as userService from 'utils/services/users';
import Panel from 'models/Panel';
import * as sessionService from 'utils/services/sessions';
import * as panelService from 'utils/services/panels';
import ListLoading from 'shared/components/Spinner/ListLoading';
import Score from 'shared/components/Icon/icons/Score';
import SessionPanelUsers from 'shared/components/SessionPanelUsers';
import '../../../styles/ScheduleContainer/SessionModal.scss';
import Heading from 'shared/components/Heading';
import ScheduleSession from 'models/ScheduleSession';
import {momentize} from 'utils/dateTimeUtils';
import GrandOpening from 'shared/components/Icon/icons/GrandOpening';
import Trophy from 'shared/components/Icon/icons/Trophy';
import LawIcon from 'shared/components/Icon/icons/Law';
import SessionDetails from './SessionDetails';
import SessionForm from './SessionForm';
import { officialRefereeReviewSessionType, practiceRefereeReviewSessionType } from 'shared/constants/timestampTypes';
import { sessionTypesTranslations } from 'shared/constants/sessionTypesTranslations';

const INITIAL_STATE = {
  isLoading: true,
  isError: false,
  responseData: {

  },
  responseIncluded: [],
  teamName: '',
  teamNumber: '',
  sessionPanels: [],
  sessionUrl: ''
}



const SessionModalContent = (props) => {
  const { t } = useTranslation()

  const [state, setState] = useState(INITIAL_STATE);


  const {sessionId, showPanelInfo, iconType, closeCallback, time_zone, cb, is_FLLC_event} = props;
  
  const {id: eventId}= useParams();

  const refetchSession = async () => {

    try {
      const {data, included} = await sessionService.getSessionById(sessionId);
      const sessionDetails = new ScheduleSession(data, included);
      const updatedSessionLink = sessionDetails.getExternalLink()
      setState(prevState => {
        return {
          ...prevState,
          isLoading: false,
          responseData: data,
          responseIncluded: included,
          sessionUrl: updatedSessionLink
        }
      })
      cb && cb(data);
    } catch(err) {
      setState(prevState => {
        return {...prevState, isLoading: false, isError: true}
      })
      throw err;
    }

  }

  useEffect(() => {
    let isMounted = true;

    const fetchSession = async() => {
      setState(prevState => ({...prevState, isLoading: true}))
      try {
        const {data, included} = await sessionService.getSessionById(sessionId);
         let sessionPanelsList = [];
        const sessionPanelIds = included.filter(i => i.type === 'panel').map(p => p.id);
        if (sessionPanelIds.length) {
          const panelFilter = {
            'id': `in:${sessionPanelIds.join(',')}`
          }
          // GET THE PANELS ASSOCIATED WITH THE SESSION
          const panelRes = await panelService.getPanelsByPanelsIdsAndJudgeId(sessionPanelIds);
          const panelsIncluded = panelRes.included;
          const panelsData = panelRes.data;
          const panelUserIds = panelsIncluded.filter(i => i.type === 'user').map(i => i.id).join(',');

          // GET THE DATA OF THE USERS ASSOCIATED WITH THE PANELS
          const usersResponse = await userService.getUsersByIds(panelUserIds);
          const assignedPanels = panelsData.map(d => panelService.formatPanelData(d));
          sessionPanelsList = assignedPanels.map(p => {
            const panelObj = {}
            const panelJudges = [];
            p.judgesIds.forEach(j => {
              const currentJudge = usersResponse.find(u => u.id === j);
              if (currentJudge) {
                const formattedJudge = {
                  ...currentJudge,
                  fullName: `${currentJudge.firstName} ${currentJudge.lastName}`
                }
                panelJudges.push(formattedJudge);
              }
            })
            p.refereesIds.forEach(j => {
              const currentJudge = usersResponse.find(u => u.id === j);
              if (currentJudge) {
                const formattedJudge = {
                  ...currentJudge,
                  fullName: `${currentJudge.firstName} ${currentJudge.lastName}`
                }
                panelJudges.push(formattedJudge);
              }
            })
            panelObj.judges = panelJudges;
            panelObj.id = p.id;
            panelObj.name = p.name;
            panelObj.panelType = p.panelTypeName;
    
            return panelObj;
          })

 

        }
        const sessionDetails = new ScheduleSession(data, included);

        const teamName = sessionDetails.parseTeamName();
        const teamNumber = sessionDetails.parseTeamNumber();
        const sessionUrl = sessionDetails.getExternalLink();
        if (isMounted) {
          setState(prevState => {
            return {
              ...prevState,
              isLoading: false,
              responseData: data,
              responseIncluded: included,
              teamName,
              teamNumber,
              sessionPanels: sessionPanelsList,
              sessionUrl
            }
          })
        }
      } catch(err) {
        if (isMounted) {
          setState(prevState => {
            return {...prevState, isLoading: false, isError: true}
          })
        }
        console.log({err})
        throw err;
      }
      
    }

    fetchSession();
    return () => {isMounted = false}
  }, [])

  const updateSessionLink = (sessionLink) => {
    setState(prevState => {
      return {...prevState, sessionUrl: sessionLink}
    })
  }
  if (state.isLoading) {
    return (
      <ListLoading loading text={t('loadingSessionLabel')} />
    );
  }
  if (state.isError) {
    return <p>Error</p>
  }


  const renderIcon = () => {
    switch(iconType) {
      case 'opening':
        return GrandOpening;
      case 'trophy':
        return Trophy;
      case 'judging':
        return LawIcon;
    default:
      return Score
    }
  }

  const updatedCloseCallback = (data)=>{
    closeCallback();
    cb && cb(data);
  }


  const sessionData = new ScheduleSession(state.responseData, state.responseIncluded);
  const ceremonyTitle = sessionTypesTranslations(is_FLLC_event)[sessionData.sessionTypeId];

  const sessionTitle =  sessionData.sessionTypeId === practiceRefereeReviewSessionType ? 
  t('practiceReviewLabel') : sessionData.sessionTypeId === officialRefereeReviewSessionType ?
  t('officialReviewLabel') : ceremonyTitle ? t(ceremonyTitle) : sessionData.getSessionTypeName();
  const sessionDate = sessionData.sessionStartTime ? momentize(sessionData.sessionStartTime, time_zone) : undefined;
  const startTime = sessionData.sessionStartTime ? momentize(sessionData.sessionStartTime, time_zone) : undefined;
  const endTime = sessionData.sessionEndTime ? momentize(sessionData.sessionEndTime, time_zone) : undefined;
  const sessionNotes = sessionData.notes;

  const noPanelsText = iconType === 'judging' ? t(is_FLLC_event ? 'No judging room chosen' : 'No reviewing room chosen') : t('No referee table chosen')
  const managePanelsLink = iconType === 'judging' ? `/events/${eventId}#5` : `/events/${eventId}#6`
  const managePanelsText = iconType === 'judging' ? is_FLLC_event ? t('manageJudgePanelLabel') : t('nonChallengeManageJudgePanelLabel') : t('manageRefereePanelsLabel')

  return (
    <>
      <div className="schedule-session-modal__title">
        <Heading tag={2}>
          {sessionTitle}
        </Heading>
        <Tooltip text={t('scheduleTooltip')} placement="left" />
      </div>
      {showPanelInfo ? (
        <>
          <SessionDetails
            teamName={state.teamName}
            teamNumber={state.teamNumber}
            managePanelsLink={managePanelsLink}
            managePanelsText={managePanelsText}
          />
          <>
            {!state.sessionPanels.length ? (
              <div className='m-b-24'>
                <SessionPanelUsers isPanelAssigned={false}  noPanelsText={noPanelsText} hideEmail={false} panelName={ iconType === 'judging' ? 'Room' : 'Table'}/>
              </div>
            ) : (
              state.sessionPanels.map(p => (
                <div key={p.id} className='m-b-24'>
                  <SessionPanelUsers isPanelAssigned={true} noPanelsText={noPanelsText} hideEmail={false} panelName={p.name} users={p.judges}/>
                </div>
                ))
            )}
          </>
        </>
      ) : null}
      <div className="schedule-session-modal__content">
        <div
          className={`schedule-session-modal__rectangle schedule-session-modal__rectangle--${iconType}`}
        >
          {' '}
          <Icon component={renderIcon()} className="schedule-session-modal__icon" />
        </div>
        <SessionForm
          sessionId={sessionId}
          closeCallback={updatedCloseCallback}
          updateSessionLink={updateSessionLink}
          refetchSession={refetchSession}
          data={{
            sessionDate,
            startTime,
            endTime,
            sessionNotes,
            sessionUrl: state.sessionUrl,
          }}
        />
      </div>
    </>
  );
};

SessionModalContent.propTypes = {
  sessionId: PropTypes.string,
  showPanelInfo: PropTypes.bool,
  iconType: PropTypes.string
}

SessionModalContent.defaultProps = {
  sessionId: '',
  showPanelInfo: true,
  iconType: 'judging'
}
export default SessionModalContent;