import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PanelHeader = (props) => {
  const {teamKey} = props;
  const { t } = useTranslation();
  return (
    <div id={`team-${teamKey}`}>
      <h2 className='paragraph--charcoal paragraph--default paragraph--bold'>{t('teamLabel')}</h2>
    </div>
  );
};

PanelHeader.propTypes = {
  teamKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

PanelHeader.defaultProps = {
  teamKey: ''
}
export default PanelHeader;