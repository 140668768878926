import * as types from 'features/EventContainer/redux/types/volunteersTypes';
import * as eventRoleService from 'utils/services/eventRoles';

export const getEventVolunteersBegin = () => {
  return {
    type: types.GET_EVENT_VOLUNTEERS_BEGIN
  };
};

export const getEventVolunteersError = () => {
  return {
    type: types.GET_EVENT_VOLUNTEERS_ERROR
  };
};

export const getEventVolunteersSuccess = (payload) => {
  return {
    type: types.GET_EVENT_VOLUNTEERS_SUCCESS,
    payload
  };
};

export const handleEventVolunteers = (eventId, page, searchText) => {
  return async dispatch => {
    if (!eventId) return;
    await dispatch(getEventVolunteersBegin());
    try {
      const searchFilter = searchText && { 'user.full_name': `like:${searchText}` };
      const { data, included, count } = await eventRoleService.getVolunteerRolesByEventId(eventId, page, searchFilter);
      let transformedIncluded = (included||[]).map(i=>{
        if(i.attributes && i.attributes.role_id){
          i.id = i.attributes.role_id;
          return i
        }else{
          return i
        }
      })
      dispatch(getEventVolunteersSuccess({
        volunteers: data,
        volunteersIncludedResponse: transformedIncluded,
        count
      }));
    } catch (err) {
      dispatch(getEventVolunteersError())
      throw err;
    }
  }
}
