import React from 'react';
import PropTypes from 'prop-types';

const Meeting = props => {
  const {fill} = props;
  return (
    <svg viewBox="0 0 38 41" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      <g id="Event" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Event-Management---Single-Event---PDP-Admin" transform="translate(-888.000000, -1827.000000)" fill={fill}>
          <g id="Group-50" transform="translate(250.000000, 513.000000)">
            <g id="Group-45" transform="translate(0.000000, 1154.000000)">
              <g id="Group-6" transform="translate(0.000000, 42.000000)">
                <g id="Group-31" transform="translate(15.000000, 68.000000)">
                  <g id="Group-7" transform="translate(614.000000, 0.000000)">
                    <g id="businessmen-copy" transform="translate(9.000000, 50.000000)">
                      <path d="M19.5,17 C19.4895,17 19.47725,17 19.46675,17 C19.20425,16.9898 18.9575,16.864 18.8,16.66 L16.4375,13.6 L11.625,13.6 C10.17775,13.6 9,12.4559 9,11.05 L9,2.55 C9,1.1441 10.17775,0 11.625,0 L27.375,0 C28.82225,0 30,1.1441 30,2.55 L30,11.05 C30,12.4559 28.82225,13.6 27.375,13.6 L22.95975,13.6 L20.158,16.7093 C19.99175,16.8946 19.752,17 19.5,17 Z M11.625,1.7 C11.142,1.7 10.75,2.0808 10.75,2.55 L10.75,11.05 C10.75,11.5192 11.142,11.9 11.625,11.9 L16.875,11.9 C17.14975,11.9 17.4105,12.0258 17.575,12.24 L19.5525,14.8002 L21.9045,12.189 C22.07075,12.0054 22.3105,11.9 22.5625,11.9 L27.375,11.9 C27.858,11.9 28.25,11.5192 28.25,11.05 L28.25,2.55 C28.25,2.0808 27.858,1.7 27.375,1.7 L11.625,1.7 Z" id="Shape" />
                      <path d="M31.1947567,19 C31.1845489,19 31.1726397,19 31.1607305,19 C30.9038326,18.9898 30.6656491,18.864 30.5125311,18.6583 L29.1684955,16.8563 C28.8877793,16.4789 28.9660396,15.9468 29.3420292,15.6663 C29.7180189,15.3841 30.2505292,15.4623 30.5329468,15.8397 L31.2474974,16.7985 L33.5306565,14.1907 C33.6939823,14.0054 33.9270619,13.9 34.1720507,13.9 L35.4480338,13.9 C35.9175956,13.9 36.2986892,13.5192 36.2986892,13.05 L36.2986892,4.55 C36.2986892,4.0808 35.9175956,3.7 35.4480338,3.7 L32.0454121,3.7 C31.5758504,3.7 31.1947567,3.3192 31.1947567,2.85 C31.1947567,2.3808 31.5758504,2 32.0454121,2 L35.4480338,2 C36.8550178,2 38,3.1441 38,4.55 L38,13.05 C38,14.4559 36.8550178,15.6 35.4480338,15.6 L34.5582482,15.6 L31.8344496,18.7093 C31.6728251,18.8946 31.4397455,19 31.1947567,19 Z" id="Path" />
                      <path d="M7.80652994,19 C7.56149486,19 7.32837121,18.8946 7.16671613,18.7093 L4.44240252,15.6 L3.55244873,15.6 C2.14519866,15.6 1,14.4559 1,13.05 L1,4.55 C1,3.1441 2.14519866,2 3.55244873,2 L6.9557137,2 C7.42536427,2 7.80652994,2.3808 7.80652994,2.85 C7.80652994,3.3192 7.42536427,3.7 6.9557137,3.7 L3.55244873,3.7 C3.08279816,3.7 2.70163249,4.0808 2.70163249,4.55 L2.70163249,13.05 C2.70163249,13.5192 3.08279816,13.9 3.55244873,13.9 L4.82867309,13.9 C5.07370817,13.9 5.30683182,14.0054 5.46848691,14.1907 L7.7520777,16.7985 L8.46676335,15.8397 C8.74753271,15.464 9.28184531,15.3858 9.65790609,15.6663 C10.0339669,15.9468 10.112242,16.4789 9.8314726,16.8563 L8.48718294,18.6583 C8.33573764,18.864 8.09580746,18.9898 7.84056259,19 C7.82865116,19 7.81673974,19 7.80652994,19 Z" id="Path" />
                      <path d="M31.5,29 C29.56975,29 28,27.43025 28,25.5 C28,23.56975 29.56975,22 31.5,22 C33.43025,22 35,23.56975 35,25.5 C35,27.43025 33.43025,29 31.5,29 Z M31.5,23.75 C30.53575,23.75 29.75,24.53575 29.75,25.5 C29.75,26.46425 30.53575,27.25 31.5,27.25 C32.46425,27.25 33.25,26.46425 33.25,25.5 C33.25,24.53575 32.46425,23.75 31.5,23.75 Z" id="Shape" />
                      <path d="M37.1666667,38 C36.7066667,38 36.3333333,37.608 36.3333333,37.125 L36.3333333,35.375 C36.3333333,33.92775 35.2116667,32.75 33.8333333,32.75 L28.8333333,32.75 C28.3733333,32.75 28,32.358 28,31.875 C28,31.392 28.3733333,31 28.8333333,31 L33.8333333,31 C36.13,31 38,32.9635 38,35.375 L38,37.125 C38,37.608 37.6266667,38 37.1666667,38 Z" id="Path" />
                      <path d="M6.5,29 C4.56975,29 3,27.43025 3,25.5 C3,23.56975 4.56975,22 6.5,22 C8.43025,22 10,23.56975 10,25.5 C10,27.43025 8.43025,29 6.5,29 Z M6.5,23.75 C5.53575,23.75 4.75,24.53575 4.75,25.5 C4.75,26.46425 5.53575,27.25 6.5,27.25 C7.46425,27.25 8.25,26.46425 8.25,25.5 C8.25,24.53575 7.46425,23.75 6.5,23.75 Z" id="Shape" />
                      <path d="M0.833333333,38 C0.373333333,38 0,37.608 0,37.125 L0,35.375 C0,32.9635 1.87,31 4.16666667,31 L9.16666667,31 C9.62666667,31 10,31.392 10,31.875 C10,32.358 9.62666667,32.75 9.16666667,32.75 L4.16666667,32.75 C2.78833333,32.75 1.66666667,33.92775 1.66666667,35.375 L1.66666667,37.125 C1.66666667,37.608 1.29333333,38 0.833333333,38 Z" id="Path" />
                      <path d="M19,30 C16.2433333,30 14,27.7566667 14,25 C14,22.2433333 16.2433333,20 19,20 C21.7566667,20 24,22.2433333 24,25 C24,27.7566667 21.7566667,30 19,30 Z M19,21.6666667 C17.1616667,21.6666667 15.6666667,23.1616667 15.6666667,25 C15.6666667,26.8383333 17.1616667,28.3333333 19,28.3333333 C20.8383333,28.3333333 22.3333333,26.8383333 22.3333333,25 C22.3333333,23.1616667 20.8383333,21.6666667 19,21.6666667 Z" id="Shape" />
                      <path d="M28.1363636,41 C27.6596364,41 27.2727273,40.6416 27.2727273,40.2 L27.2727273,37 C27.2727273,35.6768 26.1102727,34.6 24.6818182,34.6 L14.3181818,34.6 C12.8897273,34.6 11.7272727,35.6768 11.7272727,37 L11.7272727,40.2 C11.7272727,40.6416 11.3403636,41 10.8636364,41 C10.3869091,41 10,40.6416 10,40.2 L10,37 C10,34.7952 11.938,33 14.3181818,33 L24.6818182,33 C27.062,33 29,34.7952 29,37 L29,40.2 C29,40.6416 28.6130909,41 28.1363636,41 Z" id="Path" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Meeting.propTypes = {
  fill: PropTypes.string
};

Meeting.defaultProps = {
  fill: 'currentColor'
}
export default Meeting;