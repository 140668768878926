import React from 'react';
import {Button} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './OrganizationTile.scss'

const OrganizationTile = (props) => {
  const { name, programs, onEditClick } = props;
  const { t } = useTranslation();
  return (
 
    <div className='organization-tile'>
      <div className='organization-tile__header'>
        <p className='paragraph--default paragraph--charcoal paragraph--bold'>{name}</p>
        {true ? (
          <Button onClick={onEditClick} type='link' className='card-tile__edit-btn card-tile__button' title={t('editLabel')}>
            <EditOutlined className='card-tile__edit-icon' /> 
          </Button>
        ) : null}
      </div>
      {programs.length ? (
        <>
          <p className='paragraph--small paragraph--darkGray partner-tile__name'>Regions:</p>
         
      
          <p className='paragraph--xsmall paragraph--darkGray'>
            {programs.map(p => p.regionName
                
                  ). join(', ')}
          </p>
            
       
        </>
      ) : null}
    </div>
      
  );
};

OrganizationTile.propTypes = {
  name: PropTypes.string,
  programs: PropTypes.array,
  onEditClick: PropTypes.func
};

OrganizationTile.defaultProps = {
  name: '',
  programs: [],
  onEditClick: () => {}
};

export default OrganizationTile;
