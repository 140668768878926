import React from 'react';
import PropTypes from 'prop-types';
import '../../../../../features/PanelManagement/styles/JudgesList.scss';
import { useTranslation } from 'react-i18next';

const JudgesListHeader = props => {
  const {
    unAssignedCount,
    totalCount,
    title
  } = props;

  const {t} = useTranslation();

  return (
    <div className='judges-list__header'>
      <h5 className='paragraph--default paragraph--bold paragraph--charcoal paragraph'>{title}</h5>
      <p className='paragraph--small paragraph--charcoal'>
        {unAssignedCount}
        {' '}
        {t('unassignedLabel')}
        {', '}
        {totalCount}
        {' '}
        {t('totalLabel')}
      </p>

    </div>
  );
};

JudgesListHeader.propTypes = {
  unAssignedCount: PropTypes.number,
  totalCount: PropTypes.number,
  title: PropTypes.string,
};

JudgesListHeader.defaultProps = {
  unAssignedCount: 2,
  totalCount: 15,
  title: 'Judges'
}

export default JudgesListHeader;