export const FETCH_FIRST_ADMINS_BEGIN = 'FETCH_FIRST_ADMINS_BEGIN';
export const FETCH_FIRST_ADMINS_SUCCESS = 'FETCH_FIRST_ADMINS_SUCCESS';
export const FETCH_FIRST_ADMINS_ERROR = 'FETCH_FIRST_ADMINS_ERROR';

export const ADD_FIRST_USER_TO_MANUAL_LIST = 'ADD_FIRST_USER_TO_MANUAL_LIST';
export const DELETE_FIRST_USER_FROM_MANUAL_LIST = 'DELETE_FIRST_USER_FROM_LIST';
export const RESET_FIRST_USER_MANUAL_FORM = 'RESET_FIRST_USER_MANUAL_FORM';
export const UPDATE_SELECTED_FIRST_USER = 'UPDATE_SELECTED_FIRST_USER';
export const RESET_FIRST_USERS_MANUAL_LIST = 'RESET_MANUAL_FIRST_USERS_LIST';
export const SET_IS_AUTOCOMPLETE_FIRST_USER_SELECTED = 'SET_IS_AUTOCOMPLETE_FIRST_USER_SELECTED';
export const SET_SELECTED_FIRST_USER = 'SET_SELECTED_FIRST_USER';

export const CREATE_NEW_FIRST_USER_BEGIN = 'CREATE_NEW_FIRST_USER_BEGIN';
export const CREATE_NEW_FIRST_USER_ERROR = 'CREATE_NEW_FIRST_USER_ERROR';
export const CREATE_NEW_FIRST_USER_SUCCESS = 'CREATE_NEW_FIRST_USER_SUCCESS';

export const FIRST_USER_INVITE_BEGIN = 'FIRST_USER_INVITE_BEGIN';
export const FIRST_USER_INVITE_SUCCESS = 'FIRST_USER_INVITE_SUCCESS';
export const FIRST_USER_INVITE_ERROR = 'FIRST_USER_INVITE_ERROR';

export const REINVITE_DASHBOARD_FIRST_USER_BEGIN = 'REINVITE_DASHBOARD_FIRST_USER_BEGIN';
export const REINVITE_DASHBOARD_FIRST_USER_ERROR = 'REINVITE_DASHBOARD_FIRST_USER_ERROR';
export const REINVITE_DASHBOARD_FIRST_USER_SUCCESS = 'REINVITE_DASHBOARD_FIRST_USER_SUCCESS';

export const SET_SELECTED_EDIT_FIRST_USER = 'SET_SELECTED_EDIT_FIRST_USER';
export const RESET_SELECTED_EDIT_FIRST_USER = 'RESET_SELECTED_EDIT_FIRST_USER';

export const EDIT_FIRST_USER_BEGIN = 'EDIT_FIRST_USER_BEGIN';
export const EDIT_FIRST_USER_ERROR = 'EDIT_FIRST_USER_ERROR';
export const EDIT_FIRST_USER_SUCCESS = 'EDIT_FIRST_USER_SUCCESS';

export const DELETE_SELECTED_FIRST_USER_BEGIN = 'DELETE_SELECTED_FIRST_USER_BEGIN';
export const DELETE_SELECTED_FIRST_USER_ERROR = 'DELETE_SELECTED_FIRST_USER_ERROR';
export const DELETE_SELECTED_FIRST_USER_SUCCESS = 'DELETE_SELECTED_FIRST_USER_SUCCESS';
