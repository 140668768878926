import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox as AntCheckbox} from 'antd';
import './styles.scss';

const Checkbox = props => {
  const {children} = props;

  return (
    <AntCheckbox {...props} className='checkbox'>
      {children}
    </AntCheckbox>
  );
};

Checkbox.Group = AntCheckbox.Group;

Checkbox.propTypes = {
    children:  PropTypes.node.isRequired,
};

export default Checkbox;