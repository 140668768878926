import Region from './Region';

export default class Organization {
  constructor(responseData, included) {
    const { id, attributes, relationships } = responseData;

    const programs = relationships.regions && relationships.regions.data
      .map((r) => {
        const region = included.find((item) => item.type === 'region' && item.id === r.id);
        return region && new Region(region);
      })
      .filter(r => r);

    this.id = id;
    this.name = attributes.name;
    this.programs = programs || [];
  }
}
