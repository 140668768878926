import {
  getAttributesPayload, 
  formatDataResult
} from 'utils/query';

export const formatAwardResPayload = (award, included) => {
  const { relationships } = award
  const assignNodeName = 'award_assignment';
  const attr = formatDataResult(award);
  const assignmentsNode = relationships[`${assignNodeName}s`];
  const assignments = (assignmentsNode && assignmentsNode.data) || [];
  return { 
    ...attr,
    assignments: assignments.map((assign) => {
      const attachNode = included.find(({ type, id }) =>
        type === assignNodeName && id === assign.id);
      return attachNode && formatDataResult(attachNode);
    })
  };
};

export const formatAssignmentResPayload = (assignment) => {
  const { relationships } = assignment
  const teamNodeName = 'team';
  const attr = formatDataResult(assignment);
  const teamNode = relationships[teamNodeName];
  const team = teamNode && teamNode.data;
  return { ...attr, teamId: team && team.id };
};

export const formatNewAwardPayload = (award, eventId) => {
  const attributes = getAttributesPayload(award);
  return {
    data: {
      type: 'award',
      attributes,
      relationships: {
        event: {
          data: {
            type: 'event',
            id: eventId
          }
        }
      }
    }
  };
};

export const formatNewAwardAssignment = ({ awardId, userId, teamId, eventId }) => {
  return {
    data: {
      type: 'award_assignment',
      attributes: { place: undefined },
      relationships: {
        award: {
          data: {
            type: 'award',
            id: awardId
          }
        },
        judge: {
          data: {
            type: 'user',
            id: userId
          }
        },
        team: {
          data: {
            type: 'team',
            id: teamId
          }
        },
        event: {
          data: {
            type: 'event',
            id: eventId
          }
        }
      }
    }
  };
};

export const formatPatchAwardAssignment = (attr) => {
  return {
    data: {
      type: 'award_assignment',
      attributes: getAttributesPayload(attr)
    }
  };
};
