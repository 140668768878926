const ip2Section = {
  id: 'IP2',
  label: 'ipv2Label',
  title: 'designLabel',
  description: 'designDescriptionLabel',
  fields: [
    {
      legendTitle: 'ChallengeRubricsPlanningLabel',
      id: 'innovation_project_2_design_planning',
      isCoreValue: false,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricsPlanning1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricsPlanning2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricsPlanning3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricsPlanning4Label',
      }]
    },
    {
      legendTitle: 'ChallengeRubricsIdeasLabel',
      id: 'innovation_project_2_design_process',
      isCoreValue: true,
      legendValues: [
      {
        id: 1,
        value: 'ChallengeRubricsIdeas1Label',
      },
      {
        id: 2,
        value: 'ChallengeRubricsIdeas2Label',
      },
      {
        id: 3,
        value: 'ChallengeRubricsIdeas3Label',
      },
      {
        id: 4,
        value: 'ChallengeRubricsIdeas4Label',
      }]
    }
  ]
}

export default ip2Section;