import languageOptions from 'shared/constants/localizationLanguages';
import shortLangaugeKeys from './shortLangaugeKeys';

const languageCodes = languageOptions.map(l => l.value);

const detectLanguage = (selectedLanguage) => {
  if (!selectedLanguage) return 'en';
  const lang = selectedLanguage || 'en';

  let shortLang = shortLangaugeKeys(lang);
  
  return languageCodes.includes(shortLang) ? shortLang : 'en';
}

export default detectLanguage;