import * as types from './types';

const DEFAULT_STATE = {
  isCreatingNewEvent: false,
  countries: [],
  regions: [],
  programs: [],
  isFetchingProgramTypes: false,
  isFetchingRegions: false,
  isFetchingCountries: false,
  isFetchingPartnerAdmin: false,
  partnerAdminDetails: []
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.CREATE_NEW_EVENT_BEGIN:
      return {
        ...state,
        isCreatingNewEvent: true
      }
    case types.CREATE_NEW_EVENT_ERROR:
      return {
        ...state,
        isCreatingNewEvent: false
      }
    case types.CREATE_NEW_EVENT_SUCCESS:
      return {
        ...state,
        isCreatingNewEvent: false
      }
    case types.GET_NEW_EVENT_PROGRAM_TYPES_BEGIN:
      return {
        ...state,
        isFetchingProgramTypes: true
      }
    case types.GET_NEW_EVENT_PROGRAM_TYPES_ERROR:
      return {
        ...state,
        isFetchingProgramTypes: false
      }
    case types.GET_NEW_EVENT_PROGRAM_TYPES_SUCCESS:
      return {
        ...state,
        isFetchingProgramTypes: false,
        programs: action.payload.programs
      }
    case types.GET_NEW_EVENT_REGIONS_BEGIN:
      return {
        ...state,
        isFetchingRegions: true
      }
    case types.GET_NEW_EVENT_REGIONS_ERROR:
      return {
        ...state,
        isFetchingRegions: false,
        regions: []
      }
    case types.GET_NEW_EVENT_REGIONS_SUCCESS:
      return {
        ...state,
        isFetchingRegions: false,
        regions: action.payload.regions
      }
    case types.GET_NEW_EVENT_COUNTRIES_BEGIN:
      return {
        ...state,
        isFetchingCountries: false,
      }
    case types.GET_NEW_EVENT_COUNTRIES_ERROR:
      return {
        ...state,
        isFetchingCountries: false,
        countries: []
      }
    case types.GET_NEW_EVENT_COUNTRIES_SUCCESS:
      return {
        ...state,
        isFetchingCountries: false,
        countries: action.payload.countries
      }
    case types.GET_PARTNER_ADMIN_DETAILS_BEGIN:
      return {
        ...state,
        isFetchingPartnerAdmin: true
      }
    case types.GET_PARTNER_ADMIN_DETAILS_ERROR:
      return {
        ...state,
        isFetchingPartnerAdmin: false,
        partnerAdminDetails: [],
      }
    case types.GET_PARTNER_ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingPartnerAdmin: false,
        partnerAdminDetails: action.payload.details
      }

    default:
      return state;
  }
}