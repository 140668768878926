import { API } from 'utils/api';
import { parallelRequest } from 'utils/query';

//const { http } = API;

const baseUrl = '/api/score_comments_2022';
const baseUrl2023 = '/api/score_comments';

export const createNewScoreComment = async (payload) => {
  const url = `${baseUrl2023}`;
  const { data, included } = await API.GetInstance().http.post(url, payload);
  return {
    data,
    included: included || []
  }
}

export const createNewScoreComment2023 = async (payload) => {
  const url = `${baseUrl2023}`;
  // payload.type = 'score_comment_2023'
  const { data, included } = await API.GetInstance().http.post(url, payload, { headers: { 'api-version': 2.0 } });
  return {
    data,
    included: included || []
  }
}

export const getScoreComment = async (scoreCommentId) => {
  const url = `${baseUrl}/${scoreCommentId}`;
  const { data, included } = await API.GetInstance().http.get(url);
  return {
    data,
    included: included || []
  }
}

export const getScoreComment2023 = async (scoreCommentId) => {
  const url = `${baseUrl2023}/${scoreCommentId}`;
  const { data, included } = await API.GetInstance().http.get(url, { headers: {'api-version': 2.0}});
  return {
    data,
    included: included || []
  }
}

export const getAllScoreComments = async (scoreCommentIds) => {
  const valid_scoreCommentIds = scoreCommentIds.filter(id => id);
  const result = await parallelRequest(valid_scoreCommentIds.map(scoreCommentId => {
    const url = `${baseUrl2023}/${scoreCommentId}`;
    return API.GetInstance().http.get(url);
  }))
  return result.map(r => r.data)
}

export const getAllScoreComments2023 = async (scoreCommentIds) => {
  const valid_scoreCommentIds = scoreCommentIds.filter(id => id);
  const result = await parallelRequest(valid_scoreCommentIds.map(scoreCommentId => {
    const url = `${baseUrl2023}/${scoreCommentId}`;
    return API.GetInstance().http.get(url, { headers: { 'api-version': 2.0 } });
  }))
  return result.map(r => r.data)
}

export const deleteScoreComment = async (scoreCommentId) => {
  const url = `${baseUrl2023}/${scoreCommentId}`;
  const { data, included } = await API.GetInstance().http.delete(url);
  return {
    data,
    included: included || []
  }
}

export const deleteScoreComment2023 = async (scoreCommentId) => {
  const url = `${baseUrl2023}/${scoreCommentId}`;
  const { data, included } = await API.GetInstance().http.delete(url, { headers: { 'api-version': 2.0 } });
  return {
    data,
    included: included || []
  }
}