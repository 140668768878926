import React from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import i18n from 'utils/i18n'
import Select from 'shared/components/Select';

import localizationLanguages from 'shared/constants/localizationLanguages';
import detectLanguage from 'utils/detectLanguage';
import { Trans, useTranslation } from 'react-i18next';
import { PublicLeaderBoardType } from 'shared/constants/routerTypes';

const { Option } = Select

const sortList = (a, b) => {
  const textA = a.text.toUpperCase();
  const textB = b.text.toUpperCase();
  let comparison = 0;
  if (textA > textB) {
    comparison = 1;
  } else if (textA < textB) {
    comparison = -1;
  }
  return comparison;

}


const RegistrationFooter = () => {
  const contentCls = 'paragraph--small paragraph--charcoal';
  const linkCls = 'paragraph--medium paragraph--blue paragraph--bold m-b-12';

  const selectedLanguage = localStorage.getItem('i18nextLng');
  const lang = detectLanguage(selectedLanguage);
  const currentLocation = useLocation();
  const {pathname} = (currentLocation||{})
  const isPublicLeaderboard = matchPath(pathname, {path: PublicLeaderBoardType, exact: true, strict: true});
  const wrapperName = isPublicLeaderboard ? 'public-leaderboard' : '';
  
  const {t} = useTranslation();

  const handleSelect = (value) => {
    i18n.changeLanguage(value)
  }

  return (
    <footer className={`registration-page-layout__footer-container ${wrapperName}`}>
   
      <div className='registration-page-layout__footer'>
        <Select listItemHeight={5} listHeight={150} virtual={false} theme='gray' onChange={handleSelect} value={lang} additionalClassNames='m-b-20 registration-page-layout__footer-select' allowSearch>
          {localizationLanguages.sort(sortList).map(l => (
            <Option key={l.value} value={l.value}>{l.text}</Option>
            ))}
        </Select>
        <div className='registration-page-layout__footer-logo-container'>
          <img src='/First-Event-Hub-logo.png' alt='logo' className='registration-page-layout__footer-logo' />
        </div>
        <div className='registration-page-layout__footer-content'>
          <div className='registration-page-layout__footer-description' dangerouslySetInnerHTML={{__html: t('copyrightLabel')}} />
          <div>
            <Link to='/terms-and-conditions'>
              <p className={linkCls}>{t('termsOfUseLabel')}</p>
            </Link>
            <Link to='/privacy-policy'>
              <p className={linkCls}>{t('privacyPolicyLabel')}</p>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default RegistrationFooter;
