/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import mtz from 'moment-timezone';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(tz);
dayjs.extend(utc);

export const convertMomentToISO = (momentObject) => {
  let dateString = moment(momentObject).format('YYYY-MM-DD');
  dateString = `${dateString}T00:00:00`;
  return dateString;

}

export const convertMomentToISODateString = (momentObject) => {
  const dateString = momentObject.format('YYYY-MM-DD');
  return dateString;
}

export const convertMomentToTimeString = (momentObject) => {
  const timeString = momentObject.format('HH:mm:ss');
  return timeString;
}

export const parseDateTime = (isoString) => {
  const dateObject = moment(isoString, "YYYY-MM-DD")

  const month = dateObject.format('MMMM');
  const date = dateObject.format('D');
  const year = dateObject.format('YYYY');
  const day = dateObject.format('dddd');
  const dateObj = {
    month,
    date,
    year,
    day
  }
  return dateObj;

}

export const convertTimeToTimeAndDateFormat = (timeString) => {
  return moment(timeString).format('h:mma M/D/YYYY')
}

export const parseDateTimeAbbreviated = (isoString) => {
  if (!isoString) {
    return {};
  }
  // const test =  moment.utc(isoString);
  // console.log(test)
  const month = moment(isoString).format('MM');
  const date = moment(isoString).format('D');
  const year = moment(isoString).format('YYYY');
  const day = moment(isoString).format('ddd');
  const dateObj = {
    month,
    date,
    year,
    day
  }
  return dateObj;

}

export const parseFullDate = (isoString, time_zone) => {
  if (!isoString) {
    return {};
  }
  let dateObject = time_zone ? moment(isoString).tz(time_zone) : moment(isoString);
  const month = dateObject.format('MMMM');
  const date = dateObject.format('D');
  const year = dateObject.format('YYYY');
  const day = dateObject.format('dddd');
  const dateObj = {
    month,
    date,
    year,
    day
  }
  return dateObj;

}

export const parseDate = (isoString) => {
  if (!isoString) {
    return undefined;
  }
  const dateString = moment(isoString).format('MM/DD/YYYY');
  return dateString
}

export const parseAbbreviatedDate = (isoString) => {
  if (!isoString) {
    return undefined;
  }

  const localizedDate = moment(isoString, 'YYYY-MM-DD')

  const month = localizedDate.format('MM');
  const date = localizedDate.format('D');
  const day = localizedDate.format('ddd');
  const dateString = `${day} ${month}/${date}`;
  // example return  Sun 11/15
  return dateString;
}


export const parseDateString = (isoString, time_zone) => {
  if (!isoString) {
    return undefined;
  }
  const month = time_zone ? moment.utc(isoString).tz(time_zone).format('MMM') : moment.utc(isoString).format('MMM');
  const date = time_zone ? moment.utc(isoString).tz(time_zone).format('D') : moment.utc(isoString).format('D');
  const day = time_zone ? moment.utc(isoString).tz(time_zone).format('dddd') : moment.utc(isoString).format('dddd');
  const dateString = `${day}, ${month} ${date}`;

  return dateString;
}

export const parseTimeAndDate = (isoString, time_zone, format = false) => { //false = 12hour, true=24hour
  if (!isoString) {
    return undefined;
  }
  const timeString = time_zone ? moment.utc(isoString).tz(time_zone).format(`dd, M/D, ${format ? 'HH:mm' : 'h:mm a'}`) : moment.utc(isoString).format(`dd, M/D, ${format ? 'HH:mm' : 'h:mm a'}`);
  return timeString;
}

export const formatDate = (isoString, time_zone) => {
  if (!isoString) {
    return "Not Set";
  }
  const timeString = time_zone ? moment.utc(isoString).tz(time_zone).format('dddd, MMMM DD') : moment.utc(isoString).format('dddd, MMMM DD');
  return timeString;
}

export const formatDateShort = (isoString, time_zone) => {
  if (!isoString) {
    return "Not Set";
  }
  const timeString = time_zone ? moment.utc(isoString).tz(time_zone).format('ddd, MM/DD') : moment.utc(isoString).format('ddd, MM/DD');
  return timeString;
}

export const formatTime = (isoString, time_zone) => {
  if (!isoString) {
    return "Not Set";
  }
  const timeString = time_zone ? moment.utc(isoString).tz(time_zone).format('h:mm a') : moment.utc(isoString).format('h:mm a');
  return timeString;
}

export const parseTimeAndDateRaw = (isoString, time_zone) => {
  if (!isoString) {
    return undefined;
  }
  var days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

  let date = new Date(isoString);

  if (time_zone) {
    // console.log("time_zone", time_zone);
    date = changeTimezone(date, time_zone)
  }

  let dateOnly = date.getDate();
  var dayName = days[date.getDay()];
  let month = date.getMonth() + 1;

  let time = formatAMPM(date, time_zone);

  // console.log(dayName + ',' + month + '/' + dateOnly + ', ' + time);
  let timeString = dayName + ', ' + month + '/' + dateOnly + ', ' + time;
  return timeString;
}

function formatAMPM(date, timeZone) {
  let hours = timeZone ? date.getHours() : date.getUTCHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';

  hours %= 12;
  hours = hours || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  const strTime = `${hours}:${minutes} ${ampm}`;

  return strTime;
}

function changeTimezone(date, ianatz) {

  // suppose the date is 12:00 UTC
  var invdate = new Date(date.toLocaleString('en-US', {
    timeZone: ianatz
  }));

  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  var diff = date.getTime() - invdate.getTime();

  // so 12:00 in Toronto is 17:00 UTC
  return new Date(date.getTime() - diff); // needs to substract

}

export const parseTime = (isoString, time_zone, format = false) => { //false = 12 hour, true = 24 hour
  if (!isoString) {
    return undefined;
  }

  const timeString = time_zone ? moment.utc(isoString).tz(time_zone).format(format ? 'HH:mm' : 'h:mm a') : moment.utc(isoString).format(format ? 'HH:mm' : 'h:mm a');
  return timeString;
}

export const format24and12Time = (date, is24Hour) => {
  if (!date) return undefined;
  return moment(date, 'h:mm a').format(is24Hour ? 'HH:mm' : 'h:mm a')
}

export const momentize = (isoString, time_zone) => {
  if (!isoString) {
    return undefined;
  }
  // console.log({isoString})
  // const momentObj = mtz.tz(isoString, mtz.tz.guess());

  if (time_zone) {
    return moment.utc(isoString).tz(time_zone);
  }

  // const momentObj = moment(isoString).utc(true)
  const momentObj = moment.utc(isoString)
  // console.log({momentObj})
  // console.log('FORMAT', momentObj.format())
  return momentObj;
}

export const todayMomentObject = () => {
  return moment();
}

export const parseYear = (isoString, time_zone) => {
  if (!isoString) {
    return undefined;
  }
  const localizedDate = time_zone ? moment.utc(isoString).tz(time_zone) : moment.utc(isoString);

  const year = localizedDate.format('YYYY');
  return year;
}

export const parseDayDateTime = (isoString) => {
  if (!isoString) {
    return undefined;
  }
  const month = moment.utc(isoString).format('MMM');
  const date = moment.utc(isoString).format('D');
  const day = moment.utc(isoString).format('ddd');
  const timeString = moment.utc(isoString).format('h:mm a')
  const dateString = `${day}, ${month} ${date}, ${timeString}`;

  return dateString;
}

export const seventyTwoHoursFromNow = () => {
  const threeDaysLaterDate = moment().add(72, 'hours');
  const newDateTimeString = JSON.stringify(threeDaysLaterDate);
  return newDateTimeString;
}

export const noonMomentObject = () => {
  return moment('12:00', 'h:mm')
}

export const formatTimeInMatchEvidenceView = (iosString, time_zone, is24Hour = false) => {
  if (!iosString) {
    return undefined;
  } else if (time_zone) {
    return moment.utc(iosString).tz(time_zone).format(`dddd, MMM, D, ${is24Hour ? 'HH:mm' : 'h:mm a'}`);
  } else {
    return moment.utc(iosString).format(`dddd, MMM, D, ${is24Hour ? 'HH:mm' : 'h:mm a'}`);
  }
}

export const morningMomentObject = (time_zone) => {
  if (time_zone) {
    return moment.tz('08:00', 'hh:mm', time_zone);
  } else {
    return moment('08:00', 'hh:mm');
  }
}

export const timezoneStartMoment = (time_zone) => {
  if (time_zone) {
    return moment.tz('00:00', 'h:mm', time_zone);
  } else {
    return moment('00:00', 'h:mm');
  }
}

// DO NOT USE THIS FUNCTION
export const formatZuluDateTimeString = (momentDateObj, momentTimeObj) => {
  if (!momentDateObj || !momentTimeObj) {
    return undefined;
  }
  const zuluDate = moment(momentDateObj).utc().format();
  const zuluTime = moment(momentTimeObj).utc().format();
  // const test = moment(momentTimeObj).format();
  // console.log(test, zuluTime)
  let formattedZuluDate;
  let formattedZuluTime;
  if (zuluDate && zuluDate.indexOf('T')) {
    formattedZuluDate = zuluDate.split('T')[0]
  }

  if (zuluTime && zuluTime.indexOf('T')) {
    formattedZuluTime = zuluTime.split('T')[1]

  }

  const formattedZuluTimeStamp = `${formattedZuluDate}T${formattedZuluTime}`;
  return formattedZuluTimeStamp;

}

export const formatZuluTimeStamp = (momentObj, time_zone) => {
  if (!momentObj) {
    return undefined;
  }
  const zuluDate = momentObj.utc().format();
  return zuluDate
}

export const formatEventStartDate = (momentObj) => {
  const startDate = momentObj.format('YYYY-MM-DD')
  return startDate;
}

export const formatEventEndDate = (momentObj) => {
  const endDate = momentObj.format('YYYY-MM-DD')
  return endDate;
}

export const formatRangePickerToTimezone = (momentObj, time_zone) => {
  if (!momentObj) return undefined;
  const format = 'YYYY-MM-DDTHH:mm:ss';
  const timeString = momentObj.format(format);
  if (time_zone) {
    return moment.tz(timeString, format, time_zone).utc().format();
  } else {
    return moment.utc(timeString, format).format();
  }
}

export const convertDateStringToMomentObj = (isoString) => {
  return moment(isoString, 'YYYY-MM-DD');
}

//Written to correctly handle the timezones fixing EH-600
export const convertToDayjs = (timeObj, time_zone) => {
  if (!timeObj) return undefined;
  if(time_zone === 'UTC') {
      const format = 'YYYY-MM-DDTHH:mm:ss';
      return dayjs.tz(timeObj, format, time_zone).utc();
    }

  if (time_zone && time_zone !== 'UTC') {
    return dayjs.utc(timeObj).tz(time_zone);
  } else {
    return dayjs.utc(timeObj);
  }}