import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SingleEvent from 'models/Event';
import {
  convertDateStringToMomentObj,
  formatEventStartDate,
  formatEventEndDate,
} from 'utils/dateTimeUtils';
import '../../../styles/ScheduleContainer/EditEventInfoModal.scss';
import { Col, DatePicker, Row, Form, Button, Select } from 'antd';
import Icon from '@ant-design/icons';
import DropdownIcon from 'shared/components/Icon/icons/DownArrow';
import { CalendarOutlined } from '@ant-design/icons';
//import Tooltip from 'shared/components/Tooltip';
import { urlTransform, urlValidation } from 'utils/formHelpers';
import Input from 'shared/components/Input';
import TextBox from 'shared/components/TextBox';
import { connect } from 'react-redux';
import Container from 'shared/components/Container';
import timezoneOptions from 'shared/constants/timezones';
import editEventGeneralInfo from 'features/EventContainer/redux/actions/scheduleActions/editEventInfo';
import { CURRENT_EVENT_SEASON } from 'shared/constants/eventConstants';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

// const { Option } = Dropdown;
const { RangePicker } = DatePicker;

const FormItem = Form.Item;

const rowProps = {
  type: 'flex',
  gutter: 20,
  className: 'm-t-20',
};

const EditMeetingModalContent = (props) => {
  const {
    eventDetails,
    editEventInfo,
    savingEvent,
    closeCb,
  } = props;
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const { included, time_zone } = eventDetails;

  const eventPage = new SingleEvent(eventDetails, included);
  const programName = eventPage.getProgramName();
  const eventName = eventPage.name;
  const eventUrl = eventPage.url;
  const eventStartDate = eventPage.startDate
    ? convertDateStringToMomentObj(eventPage.startDate)
    : undefined;
  const eventEndDate = eventPage.endDate
    ? convertDateStringToMomentObj(eventPage.endDate)
    : undefined;
  const regionName = eventPage.getRegionName();
  const countryName = eventPage.getCountryName();
  const eventDescription = eventPage.description;
  const { eventCode, eventId } = eventPage;
  const [dates, setDates] = useState(null);
  const [values, setValues] = useState(null);
  const [rangeDates, setRangeDates] = useState({
    start: eventPage.startDate,
    end: eventPage.endDate,
  });

  /*
  const resetFormFields = () => {
    form.resetFields()
  }
  */
  const saveEvent = async (e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields().then(async (values, err) => {
      const eventStartDate = rangeDates.start;
      const eventEndDate = rangeDates.end;
      const payload = {
        title: values.title,
        url: urlTransform(values.url),
        description: values.description,
        start_date: eventStartDate,
        end_date: eventEndDate,
        timezone: values.timezone,
        season: CURRENT_EVENT_SEASON,
      };

      await editEventInfo(payload, eventId, closeCb);
    });
  };

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

   const options = timezoneOptions.map(tz => ({
    value: tz.id,
    label: tz.description
  }));


  return (
    <div>
      <Container type="default">
        <div className="c-edit-event-info__title">
          <h1 className="heading--charcoal h--bold h-2">{t('editGeneralEventInfoLabel')}</h1>
          {/* <Tooltip /> */}
        </div>
      </Container>

      <Container type="medium">
        <Form
          hideRequiredMark
          onFinish={saveEvent}
          onFinishFailed={onFinishFailed}
          name="EditMeeting"
          form={form}
          initialValues={{
            title: eventName,
            program: programName,
            dates: [eventStartDate, eventEndDate],
            timezone: time_zone,
            region: regionName,
            country: countryName,
            code: eventCode,
            url: eventUrl,
            description: eventDescription,
          }}
        >
          <Row {...rowProps}>
            <Col xs={24} md={12}>
              <FormItem
                name="title"
                label={<span>{t('eventLabel')}</span>}
                rules={[{ required: true, message: t('enterEventTitleLabel') }]}
              >
                <Input size="large" placeholder={t('eventLabel')} />
              </FormItem>
            </Col>
            <Col xs={24} md={12}>
              <FormItem name="program" label={<span>{t('programLabel')}</span>}>
                <Input disabled size="large" />
              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col id="edit-event-range" style={{ position: 'relative' }} xs={24} md={10}>
              <Form.Item
                label="Dates"
                // rules={[{ required: true, message: t('selectStartAndEndDatesLabel') }]}
              >
                <RangePicker
                  value={[
                    dayjs(rangeDates.start, 'YYYY-MM-DD'),
                    dayjs(rangeDates.end, 'YYYY-MM-DD'),
                  ]}
                  defaultValue={[
                    dayjs(rangeDates.start, 'YYYY-MM-DD'),
                    dayjs(rangeDates.end, 'YYYY-MM-DD'),
                  ]}
                  onCalendarChange={(val) => {
                    setRangeDates({ start: val[0], end: val[1] === null ? '' : val[1] });
                    console.log('onCalendarChange :>> ', val[0], val[1]);
                  }}
                  onChange={(val) => {
                    setRangeDates({ start: val[0], end: val[1] === null ? '' : val[1] });
                    console.log('onChange :>> ', val);
                  }}
                  onOpenChange={onOpenChange}
                />
                {/* <DatePicker.RangePicker
                    suffixIcon={<CalendarOutlined className="time-picker__icon" />}
                    className="range-picker"
                    format="MM-DD-YYYY"
                    size="large"
                    placeholder="MM-DD-YYYY"
                    getCalendarContainer={() => document.getElementById('edit-event-range')}
                  /> */}
              </Form.Item>
            </Col>
            <Col xs={0} md={1}></Col>
            <Col xs={24} md={13}>
              <FormItem
                name="timezone"
                label={<span>Time zone</span>}
                rules={[{ required: true, message: '' }]}
              >
                {/* <Dropdown placeholder="Select Time Zone">
                  {timezoneOptions.map((tz) => (
                    <Option value={tz.id} key={tz.id}>
                      {tz.description}
                    </Option>
                  ))}
                </Dropdown> */}
                <Select placeholder="Select Time Zone" options={options} suffixIcon={<Icon component={DropdownIcon} style={{color: '#0066B3', pointerEvents: 'none'}} />}/>

              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col xs={24} md={8}>
              <FormItem name="region" label={t('regionLabel')}>
                <Input disabled size="large" />
              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col xs={24} md={8}>
              <FormItem name="country" label={t('countryLabel')}>
                <Input disabled size="large" />
              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col xs={24} md={8}>
              <FormItem name="code" label={<span>Code</span>}>
                <Input disabled size="large" />
              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col xs={24} md={16}>
              <FormItem
                name="url"
                label={<span>{t('eventUrlLabel')}</span>}
                rules={[urlValidation]}
              >
                <Input size="large" placeholder={t('eventUrlLabel')} />
              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col xs={24} md={16}>
              <FormItem name="description" label={<span>{t('descriptionLabel')}</span>}>
                <TextBox size="large" placeholder={t('descriptionLabel')} rows={5} />
              </FormItem>
            </Col>
          </Row>
          <Row {...rowProps} justify="end">
            <Button loading={savingEvent} htmlType="submit">
              {t('saveLabel')}
            </Button>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

EditMeetingModalContent.propTypes = {
  form: PropTypes.object.isRequired,
  eventDetails: PropTypes.object.isRequired,
  editEventInfo: PropTypes.func.isRequired,
  savingEvent: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {
    event: { eventDetails, eventSchedule },
  } = state;
  const { isEditingEventGeneralInfo } = eventSchedule;
  return {
    eventDetails,
    savingEvent: isEditingEventGeneralInfo,
  };
};

export default connect(mapStateToProps, { editEventInfo: editEventGeneralInfo })(
  EditMeetingModalContent
);
