import React from 'react';
import { notification, Button, Form } from 'antd';
import PropTypes from 'prop-types';
import InputPassword from 'shared/components/InputPassword'
import { FormLabel, BackToOptions } from './CreateAccount';
import validatePassword from 'utils/validatePassword';

const FormItem = Form.Item;

const ResetPassword = (props) => {
  const { handleReset, goBack } = props;
  const [form] = Form.useForm();

  const handleSubmit = (e) => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields().then(async (values, err) => {
      const { password } = values;
      const errMsg = validatePassword(password);
      if (errMsg) {
        notification.error({
          message: 'Validation Error',
          description: errMsg
        });
        return;
      }
      handleReset(password);
    });
  };

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const confirmPassword = (rule, value, callback) => {
    const password = form.getFieldValue('password');
    if (value && value !== password) {
      callback('Password does not match');
    } else {
      callback();
    }
  };

  return (
    <div className='registration__reset-pass'>
      <BackToOptions goBack={goBack} text='Cancel and back to options' />
      <Form hideRequiredMark onFinish={handleSubmit} onFinishFailed form={form} name='ResetPassword'>
        <div className='registration__reset-pass-form'>
          <FormItem name='password' label={<FormLabel text='New Password' />} rules={[{ required: true, message: 'Please Input Password' }]}>
              <InputPassword size='large' />
          </FormItem>
          <FormItem name='confirmPassword' label={<FormLabel text='Reenter New Password' />} rules={[{ required: true, message: 'Please Confirm Password' },{ validator: confirmPassword }]}>
              <InputPassword size='large' />
          </FormItem>
        </div>
        <div className='registration__submit'>
          <Button htmlType='submit'>Reset Password</Button>
        </div>
      </Form>
    </div>
  );
};

ResetPassword.propTypes = {
  form: PropTypes.object.isRequired
};

export default (ResetPassword);
