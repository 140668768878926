import { combineReducers } from 'redux'
import auth from 'features/Authentication/redux/authReducer';
import registration from 'features/RegistrationContainer/redux/registrationReducer';
import event from 'features/EventContainer/redux/reducer/index';
import addEvent from 'features/AddEventModal/redux/reducer';
import dashboard from 'features/DashboardContainer/redux/dashboardReducer';
import addFirstUser from 'features/AddFirstUserModal/redux/reducer';
import panelManagement from 'features/PanelManagement/redux/reducers';
import eventOverview from 'features/EventOverviewContainer/redux/reducer/eventOverview';
import adminBoard from 'features/AdminDashboard/redux/reducers';
import assignedAwards from 'features/EventOverviewContainer/redux/reducer/assignedAwards';
import assignedJudgingSessions from 'features/EventOverviewContainer/redux/reducer/assignedJudgingSessions';
import volunteerDashboard from 'features/VolunteerDashboard/redux/volunteerDashboardReducer';
import coachJudging from 'features/EventOverviewContainer/redux/reducer/coachJudging';
import assignedScoringSessions from 'features/EventOverviewContainer/redux/reducer/assignedMatches';
import matchScoresheet from 'features/EventOverviewContainer/redux/reducer/matchScoresheet';
import coachScoring from 'features/EventOverviewContainer/redux/reducer/coachScoring';
import judgingRubric from 'features/EventOverviewContainer/redux/reducer/judgingRubric';
import eventMessaging from 'features/MessagingContainer/redux/eventMessageReducer';
import eventLeaderboard from 'features/LeaderboardContainer/redux/leaderboardReducer';
import header from 'features/Header/redux/headerReducer';
import globalUploadModal from 'features/GlobalUploadModal/redux/reducer';
import publicScoring from './PublicScoresheet/redux/reducer/publicScoring';

export default combineReducers({
  eventOverview,
  assignedAwards,
  assignedJudgingSessions,
  assignedScoringSessions,
  addEvent,
  auth,
  registration,
  event,
  dashboard,
  addFirstUser,
  panelManagement,
  adminBoard,
  volunteerDashboard,
  coachJudging,
  matchScoresheet,
  coachScoring,
  judgingRubric,
  eventMessaging,
  eventLeaderboard,
  header,
  globalUploadModal,
  publicScoring
})
