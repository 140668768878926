const m15Section = {
  id: 'm15',
  name: '2022ScoresheetM15Sub1Label',
  fields: [
    {
      scoring: '2022ScoresheetM15Sub2Label',
      id: 'm15_scoring_1',
      options: [
        {
          description: '0',
          value: 0
        },
        {
          description: '1',
          value: 1
        },
        {
          description: '2022Scoresheet2orMoreLabel',
          value: 2
        }
      ]
    },
    {
      scoring: '2022ScoresheetM15Sub3Label',
      id: 'm15_scoring_2',
      options: [
        {
          description: '0',
          value: 0
        },
        {
          description: '1',
          value: 1
        },
        {
          description: '2022Scoresheet2orMoreLabel',
          value: 2
        }
      ]
    },
    {
      scoring: '2022ScoresheetM15Sub4Label',
      id: 'm15_scoring_3',
      options: [
        {
          description: '0',
          value: 0
        },
        {
          description: '1',
          value: 1
        },
        {
          description: '2022Scoresheet2orMoreLabel',
          value: 2
        }
      ]
    },
  ]
}

export default m15Section;

