import { Table} from 'antd';
import React, { useState } from 'react';
import Icon from 'shared/components/Icon';
import "../../../styles/ScheduleMatches.scss";
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Button/BtnSecondary';
import { officialMatchType, practiceMatchType } from 'shared/constants/matchTypes';
import { parseTimeAndDate } from 'utils/dateTimeUtils';
import SessionModal from '../SessionModal';
import { connect } from 'react-redux';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';

const ItemWrapper = ({ ...props }) => <div {...props} className="table-item-wrapper" />

const MatchesTable = (props) => {
  const { enterFullScreen, fullscreen, matchesData, time_zone, updateMatchTime, pagination, loading, use_24_hour_format } = props;
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [sessionModalId, setSessionModalId] = useState(null)

  const { t } = useTranslation();

  const openModal = (id) => {
    setVisible(!visible)
    setSessionModalId(id)
  }

  const closeModal = () => {
    setVisible(false);
    setSessionModalId(null)
  }

  const expandAllRecord = () => {
    const keys = matchesData.map(d => d.key);
    setExpandedKeys(keys);
  }

  const collapseAllRecord = () => {
    setExpandedKeys([])
  }

  const expandRowOnKey = (key) => {
    setExpandedKeys([...expandedKeys, key])
  }

  const collapseRowOnKey = (key) => {
    setExpandedKeys(expandedKeys.filter(k => k !== key))
  }

  const ExpandArrow = (key) => {
    const isExpanded = expandedKeys.includes(key);
    return isExpanded ? <a className="expanded-arrow" onClick={() => collapseRowOnKey(key)}><Icon type="smallDownBlue" /></a> : <a className="expanded-arrow" onClick={() => expandRowOnKey(key)}><Icon type="smallRightArrowBlue" /></a>
  }

  const columns = [
    {
      width: 300, title: "Team Number and Name", key: 'teamNumberAndName', dataIndex: "teamKey",
      sorter: (a, b) => a.teamKey.localeCompare(b.teamKey),
      render: (text, record) => {
        const { key } = record;
        return (
          <div className="team-title">
            {ExpandArrow(key)}
            <div className="title-text">
              {text}
            </div>
          </div>
        )
      }
    },
    {
      width: 300, title: "Matches", key: 'matches', dataIndex: "matches",
      render: (text, record) => {
        const { key, data } = record;
        const isExpanded = expandedKeys.includes(key);
        if (isExpanded) {
          const result = data.map(d => {
            const { matchTypeId, startTime, matchId } = d || {};
            const formattedTime = startTime ? parseTimeAndDate(startTime, time_zone, use_24_hour_format) : "Not set";
            if (matchTypeId === practiceMatchType.matchTypeId) {
              return <ItemWrapper key={matchId}><span>{`Practice: ${formattedTime}`}</span></ItemWrapper>
            } else if (matchTypeId === officialMatchType.matchTypeId) {
              return <ItemWrapper key={matchId}><span>{`${formattedTime}`}</span></ItemWrapper>
            }
          })
          return <div className="matches-times-wrapper">{result}</div>
        } else {
          return <ItemWrapper><span className="matches-time">1 Practice, 3 Official Matches</span></ItemWrapper>;
        }
      }
    },
    {
      width: 100, title: "Edit", key: 'edit', dataIndex: "edit", render: (text, record) => {
        const { key, data } = record;
        const isExpanded = expandedKeys.includes(key);
        if (isExpanded) {
          return <div className="matches-edits-wrapper">{data.map(d => {
            const { sessionId, matchId } = d;
            return <ItemWrapper key={matchId}><a onClick={() => openModal(sessionId)}><Icon type="edit" /></a></ItemWrapper>
          })}</div>
        } else {
          return <div />
        }
      }
    },
    {
      width: 200, title: "Referee Panel", key: 'refereePanel', dataIndex: "refereePanel", render: (text, record) => {
        const { key, data } = record;
        const isExpanded = expandedKeys.includes(key);
        if (isExpanded) {
          return <div className="matches-panel-wrapper">{data.map(d => {
            const { panelName, matchId } = d || {};
            return <ItemWrapper key={matchId}><div className="panel-text-wrapper">{panelName}</div></ItemWrapper>
          })}</div>
        } else {
          return <div />;
        }
      }
    }
  ]

  return (
    <div>
      <div className="matches-table-control-row">
        <div className="buttons-left">
          <Button className="first-button" type="link" onClick={collapseAllRecord}>{t('CollapseAllLabel')}</Button>
          <Button className="first-button" type="link" onClick={expandAllRecord}>{t('ExpandAllLabel')}</Button>
        </div>
        <div className="buttons-right">
          <Button className="fullscreen-button" type="link" onClick={enterFullScreen}>{fullscreen ? <FullscreenExitOutlined style={{color: "#1677ff"}}/> : <FullscreenOutlined style={{color: "#1677ff"}}/>}</Button>
        </div>
      </div>
      <SessionModal cb={updateMatchTime} visible={visible} onCancel={closeModal} iconType="score" sessionId={sessionModalId} time_zone={time_zone} />
      <Table
        columns={columns}
        dataSource={matchesData}
        rowClassName={(record, index) => index % 2 === 1 ? 'even-row' : 'odd-row'}
        className="matches-table"
        pagination={pagination}
        loading={loading}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  const { auth: { userInfo: { use_24_hour_format } } } = state;
  return { use_24_hour_format }
}

export default connect(mapStateToProps)(MatchesTable);
// export default MatchesTable;