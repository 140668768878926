import { Button, Col, Row, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Tabs from 'shared/components/Tabs/ModalTabs';
import Heading from 'shared/components/Heading';
import {InfoOutlined} from '@ant-design/icons';
import Spreadsheet from 'shared/components/Icon/icons/Spreadsheet';
import EventModal from 'features/EventContainer/components/EventModal';
import '../../../styles/TeamsCoachesModal.scss'
import localizationLanguages from 'shared/constants/localizationLanguages';
import detectLanguage from 'utils/detectLanguage';
import Select from 'shared/components/Select';
import ManualForm from './ManualForm';
import ImportView from '../TeamsCoachesModal/ImportView';

const { Option } = Select

const AddTeamsAndCoachesModal = (props) => {
  const selectedLanguage = localStorage.getItem('i18nextLng');

  const lang = detectLanguage(selectedLanguage);
  const defaultLang = (localizationLanguages.find(i=>i.value===lang)||{}).code
  const [excelVisible, setExcelVisible] = useState(false);
  const [emailLanguage, setEmailLanguage] = useState(defaultLang);
  const [switcher, setSwitcher] = useState(false);
  const { visible, onCancel, isLoading } = props;
  const { t } = useTranslation();

  const sortList = (a, b) => {
    const textA = a.text.toUpperCase();
    const textB = b.text.toUpperCase();
    let comparison = 0;
    if (textA > textB) {
      comparison = 1;
    } else if (textA < textB) {
      comparison = -1;
    }
    return comparison;

  }

  const info = switcher && (
    <Tooltip title={t('addCoachesTooltipText')}>
      <span><InfoOutlined style={{ color: '#0066B3' }} /></span>
    </Tooltip>
  );

  const tabChange = (activeKey) => {
    if (activeKey === 'Import') {
      setExcelVisible(true)
    } else {
      setExcelVisible(false)
    }
  };

  const closeModal = () => {
    onCancel();
  };

  const renderSwitcher = () => {
    const leftActive = !switcher;
    const rightActive = switcher;
    const leftClass = leftActive ? 'switch-button active' : 'switch-button inactive';
    const rightClass = rightActive ? 'switch-button active' : 'switch-button inactive';
    return (
      <div className='switcher-control'>
        <button className={leftClass} onClick={()=>setSwitcher(false)}>Add Teams without Coaches</button>
        <button className={rightClass} onClick={()=>setSwitcher(true)}>Add Teams with Coaches</button>
      </div>
    )
  }

  const templateLink = switcher ? '/coach_team.csv' : '/teams-only.csv';
  const headingText = switcher ? 'Add Teams with Coaches' : 'Add Teams without Coaches'

  return (
    <EventModal
      visible={visible}
      onCancel={closeModal}
      wrapClassName='teams-coaches-modal'
    >
      <div className='teams-coaches-modal-wrapper'>
        <div className='teams-coaches-modal__title'>
          <div className='teams-coaches-modal__top-bar'>
            {renderSwitcher()}
            {excelVisible ? (
              <a href={templateLink} download className='link'>
                <span className='teams-coaches-modal__title__link'>
                  <Icon component={Spreadsheet} className='teams-coaches-modal__title__link__icon' />
                  {t('downloadCsvLinkLabel')}
                </span>
              </a>
            ):
            <div className='invite-language'>
              Send email invitation in
              <Select tabIndex={0} theme='gray' onChange={setEmailLanguage} additionalClassNames='email-language-selection' allowSearch value={emailLanguage}>
                {localizationLanguages.sort(sortList).map(l => (<Option key={l.code} value={l.code}>{l.text}</Option>))}
              </Select> 
            </div>}
          </div>
        </div>
        <div className='teams-coaches-modal__title'>
          <Heading tag={2} type='primary' weight='bold'>{headingText}</Heading>
        </div>
        <Tabs
          tabBarGutter={48}
          overrides={{
            tabBarExtraContent: info,
            onChange: tabChange,
          }}
        >
          <Tabs.TabPane tab={t('manuallyLabel')} key='Manual'>
            <ManualForm withCoach={switcher} emailLanguage={emailLanguage}/>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('importCsvLabel')} key='Import'>
            <ImportView withCoach={switcher} />
          </Tabs.TabPane>
        </Tabs>
        {isLoading && <div className='disabled-overlay' />}
      </div>
    </EventModal>
  );
};

AddTeamsAndCoachesModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default AddTeamsAndCoachesModal;
