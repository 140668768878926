import React from 'react';
import { connect } from 'react-redux';
import Paragraph from 'shared/components/Paragraph';
import Divider from 'shared/components/Divider';
import BlockLoading from 'shared/components/Spinner/BlockLoading';
import { useTranslation } from 'react-i18next';
import AwardTeam from './AwardTeam';
import UnassignedList from './UnassignedList';


const TeamsList = (props) => {
  const { count, assignedTeams, unassignedTeams, isFetchingTeams } = props;
  const { t } = useTranslation()

  return (
    <div>
      <Paragraph size='large' weight='bold'>
        {t('teamsLabel')}
      </Paragraph>
      <Paragraph>
        {assignedTeams.length}
        {' '}
        Assigned,
        {' '}
        {count || '...'}
        {' '}
        teams total
      </Paragraph>
      <Divider width={4} />
      <div className='m-t-16'>
        {assignedTeams.map((team) => (
          <AwardTeam key={team.id} {...team} />
        ))}
      </div>
      <BlockLoading loading={isFetchingTeams} text='Loading Teams' />
      <UnassignedList teams={unassignedTeams} />
    </div>
  );
};

const mapStateToProps = ({ assignedAwards }) => {
  const { awards, teams, meta: { isFetchingTeams } } = assignedAwards;
  const assignedTeams = teams
    .map(team => {
      const filteredAssigns = awards
        .map(({ awardName, assignments }) => {
          const assign = assignments.find(({ teamId }) => teamId === team.id);
          return assign && { awardName, place: assign.place };
        })
        .filter(assign => !!assign);
      return { ...team, assignments: filteredAssigns };
    })
    .filter((team) => team.assignments.length > 0);
  const unassignedTeams = teams
    .filter((team) => !assignedTeams.find(({ id }) => id === team.id));
  return {
    count: teams.length,
    assignedTeams,
    unassignedTeams,
    isFetchingTeams
  };
};

export default connect(mapStateToProps)(TeamsList);
