import * as types from 'features/EventContainer/redux/types/teamsTypes';
import * as userService from 'utils/services/users';
import { notification } from 'antd';
import {seventyTwoHoursFromNow} from 'utils/dateTimeUtils';

const reinviteUserBegin = () => {
  return {
    type: types.REINVITE_EVENT_COACH_USER_BEGIN
  }
}

const reinviteUserError = () => {
  return {
    type: types.REINVITE_EVENT_COACH_USER_ERROR
  }
}

const reinviteUserSuccess = (updatedIncludedList = []) => {
  return {
    type: types.REINVITE_EVENT_COACH_USER_SUCCESS,
    payload: {
      updatedIncludedList
    }
  }
}

const handleReinviteVolunteerUser = (userId) => {
  return async (dispatch, getState) => {
    const {event: {    eventTeams: {eventTeamsIncludedResponse},
  }} = getState();
    const copyIncluded = [...eventTeamsIncludedResponse];
    // const notificationKey = 'reinvite-event-volunteer-user'

    try {
      await dispatch(reinviteUserBegin());
      await userService.reinviteUser(userId);
      const newTimestamp = seventyTwoHoursFromNow();
 
      const selectedVolunteerUser = copyIncluded.find(i => i.id === userId);
      
      if (selectedVolunteerUser) {
        const selectedVolunteerIndex = copyIncluded.findIndex(i => i.id === userId);
        const updatedVolunteerUser = {
          ...selectedVolunteerUser,
          attributes: {
            ...selectedVolunteerUser.attributes,
            verification_code_timeout: newTimestamp
          }
        }
        copyIncluded[selectedVolunteerIndex] = updatedVolunteerUser;
        
      }
      await dispatch(reinviteUserSuccess(copyIncluded));
      
      notification.success({
        message: 'Success',
        description: 'User successfully reinvited.',
      })
    } catch(err) {
      notification.error({
        message: 'Error',
        description: 'Error reinviting user',
      });
      await dispatch(reinviteUserError());
      throw err;
    }
  }
}

export default handleReinviteVolunteerUser;