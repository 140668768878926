import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {Form, Button} from 'antd';
import Input from 'shared/components/Input';
import AddTeamModal from 'features/EventContainer/components/TeamsContainer/AddTeamModal';
import { connect } from 'react-redux';

const FormItem = Form.Item;


const AddTeamForm = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const [visible, setVisible ]  = useState(false);
  const { isAddTeamDisabled} = props;
  const isTeamNumberTouched = Boolean(form.getFieldValue('teamNumber'));
  const isCountryTouched = Boolean(form.getFieldValue('country'));
  const isRegionTouched = Boolean(form.getFieldValue('region'));

  // console.log(isAddTeamDisabled);

  const handleClose = () => {
    setVisible(false)
  }

  const handleOpenModal = () => {
    setVisible(true)
  }

  return (
    <div>
      <AddTeamModal visible={visible} onCancel={handleClose} />
      <Form hideRequiredMark name='AddCoachesTeams' form={form}>
        <Button disabled={isAddTeamDisabled} onClick={handleOpenModal}>{t('addTeamLabel')}</Button>
        <FormItem name='teamNumber' label={<span className={isTeamNumberTouched ? 'form__label--visible' : 'form__label--invisible'}>{t('teamNumberLabel')}</span>}>
          <Input size='large' placeholder={t('teamNumberLabel')} disabled />
        </FormItem>
        <FormItem name='country' label={<span className={isCountryTouched ? 'form__label--visible' : 'form__label--invisible'}>{t('countryLabel')}</span>} rules={[{required: true, message: 'Enter Country.'}]}>
            <Input size='large' placeholder={t('countryLabel')} disabled />
        </FormItem>
        <FormItem name='region' label={<span className={isRegionTouched ? 'form__label--visible' : 'form__label--invisible'}>{t('regionLabel')}</span>}>
            <Input size='large' placeholder={t('regionLabel')} disabled />
        </FormItem>
      </Form>
    </div>
  );
};

AddTeamForm.propTypes = {
  form: PropTypes.object.isRequired,
  isAddTeamDisabled: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {event: {eventTeams}} = state;
  const {isAddTeamDisabled, selectedTeam} = eventTeams;
  return {
    isAddTeamDisabled,
    selectedTeam,
  }
}

export default connect(mapStateToProps)(AddTeamForm);