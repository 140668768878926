import { parseDateTime, parseAbbreviatedDate, parseDateTimeAbbreviated, parseYear } from 'utils/dateTimeUtils';

export default class EventData {
  constructor(responseData, responseIncluded = []) {
    this.name = responseData.attributes.title;
    this.description = responseData.attributes.description;
    this.startDate = responseData.attributes.start_date;
    this.endDate = responseData.attributes.end_date;
    this.subDivision = responseData.attributes.sub_division;
    this.programId = responseData.relationships.program.data.id;
    this.countryId = responseData.relationships.country.data ? responseData.relationships.country.data.id : undefined;
    this.regionId = responseData.relationships.region.data.id;
    this.teams = responseData.relationships.teams.data || [];
    this.included = responseIncluded || [];
    this.eventId = responseData.id;
    this.url = responseData.attributes.url;
    this.eventCode = responseData.attributes.code;
    this.time_zone = responseData.attributes.timezone;
    this.season = responseData.attributes.season;
    this.event_type = responseData.attributes.event_type;

    this.getCountryData = this.getCountryData.bind(this);
  }

  getSeason = () => {
    return this.season
  }

  getCountryData = () => {
    if (!this.countryId) return undefined;
    const eventCountry = this.included.find(d => d.id === this.countryId);
    return eventCountry;
  }

  getCountryName = () => {
    const countryObj = this.getCountryData()
    if (!countryObj) return undefined;
    const countryName = countryObj.attributes.name;
    return countryName;
  }

  getProgramData = () => {
    if (!this.programId) return undefined;
    const eventProgram = this.included.find(d => d.id === this.programId);
    return eventProgram;
  }

  getProgramName = () => {
    const programObj = this.getProgramData();
    if (!programObj) return undefined;
    const programName = programObj.attributes.name;
    return programName;
  }


  getRegionData = () => {
    if (!this.regionId) return undefined;
    const eventRegion = this.included.find(d => d.id === this.regionId);
    return eventRegion;
  }

  getRegionName = () => {
    const regionObj = this.getRegionData(this.included)
    if (!regionObj) return undefined;
    const regionName = regionObj.attributes.name;
    return regionName;
  }

  getEventLocation = () => {
    const eventRegion = this.getRegionName();
    const eventCountry = this.getCountryName();

    const eventLocation = (eventRegion && eventCountry) ? `${eventRegion}, ${eventCountry}` : `${eventRegion}`;

    return eventLocation;
  }

  getTeamsData = () => {
    const eventTeams = this.included.find(d => d.type === 'team');
    return eventTeams;
  }

  formatStartDateAbbreviated = () => {
    const formattedDate = parseDateTimeAbbreviated(this.startDate);
    return formattedDate;
  }

  formatEndDateAbbreviated = () => {
    const formattedDate = parseDateTimeAbbreviated(this.endDate);
    return formattedDate;
  }

  getEndYear = () => {
    const endYear = parseYear(this.endDate);
    return endYear;
  }

  parseStartTimeShortened = () => {
    if (!this.startDate) {
      return undefined;
    }
    const startTimeString = parseAbbreviatedDate(this.startDate);
    return startTimeString;
  }

  parseEndTimeShortened = () => {
    if (!this.endDate) {
      return undefined;
    }
    const endTimeString = parseAbbreviatedDate(this.endDate);
    return endTimeString;
  }

  parseEndYear = () => {
    if (!this.endDate) {
      return undefined;
    }

    const endYear = parseYear(this.endDate);
    return endYear;
  }

  parseStartEndTimeShortenedString = () => {
    const startDate = this.parseStartTimeShortened();
    const endDate = this.parseEndTimeShortened();
    const endYear = this.parseEndYear();

    const dateString = `${startDate} - ${endDate} ${endYear}`;
    return dateString;
  }


  getEventDateObject = () => {
    if (!this.startDate) {
      return {};
    }
    const formattedDate = parseDateTime(this.startDate, this.time_zone);
    return formattedDate;
  }

  getTeamsNumbers = () => {
    return this.teams.map(team => {
      const { id } = team;
      const teamDetail = this.included.find(i => i.type === 'team' && i.id === id);
      return teamDetail ? teamDetail.attributes.team_number : '';
    })
  }
}
