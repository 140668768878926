import { Col, Collapse, Row, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import Checkbox from 'shared/components/Checkbox';
import Select from 'shared/components/Dropdown';
import { eventTimes } from 'shared/constants/eventTimes';
import { DownOutlined, UpOutlined} from '@ant-design/icons';
import '../styles/index.scss';
import BlockLoading from 'shared/components/Spinner/BlockLoading';
import { format24HoursToReadableString, formatDateToReadableStr, formatReadableStringTo24Hours, generateEndTimeList, generateStartTimeList } from 'features/EventContainer/utils/formatTimeString';
import _ from 'lodash';
import { connect } from 'react-redux';

const SelectOption = Select.Option;

const EventTimeBlock = (props) => {
  const { form, days, isLoadingDays, time_zone, use_24_hour_format } = props;
  const times_list = eventTimes(0, ['am', 'pm',], 15, use_24_hour_format);
  const default_start_time = formatReadableStringTo24Hours(use_24_hour_format ? '8:00' : '8:00 am', time_zone, use_24_hour_format);
  const default_end_time = formatReadableStringTo24Hours(use_24_hour_format ? '16:00' : '4:00 pm', time_zone, use_24_hour_format);

  const [daysDetail, setDaysDetail] = useState([]);
  const [timeRange, setTimeRange] = useState({ start: default_start_time, end: default_end_time });
  const [setAll, setSetAll] = useState(false);

  useEffect(() => {
    const days_detail = ((days || {}).data || []).map(d => ({ id: d.id, ...d.attributes }));
    const schedule_setting = (((days || {}).included || []).filter(i => (i || {}).type === 'schedule')[0] || {}).attributes;
    const { default_session_start_time, default_session_end_time } = (schedule_setting || {});
    const start = default_session_start_time ? default_session_start_time : default_start_time;
    const end = default_session_end_time ? default_session_end_time : default_end_time;
    setDaysDetail(days_detail);
    setTimeRange({ start, end });
    const setAllChecked = default_session_start_time && default_session_end_time;
    setSetAll(setAllChecked);
  }, [days])

  const handleExpandIcon = (panelProps) => {
    const { isActive } = panelProps;
    if (isActive) {
      return <UpOutlined />
    }

    return <DownOutlined />
  }

  const handleCheckbox = (e) => {
    setSetAll(e.target.checked);
    if (!e.target.checked) form.setFieldsValue({ 'days': [...daysDetail] });
    else {
      const { start, end } = timeRange;
      const newDays = _.cloneDeep(daysDetail);
      for (const index in newDays) {
        newDays[index].start_time = start;
        newDays[index].end_time = end;
      }
      setDaysDetail(newDays)
      form.setFieldsValue({ 'days': timeRange })
    }
  }

  const Header = (
    <div className="time-picker-header">
      <Row type="flex" align="middle">
        <Col xs={24} sm={24} md={4}>
          <span className="title">Times</span>
        </Col>
        <Col xs={24} sm={24} md={8} className="checkbox-column">
          <div onClick={e => e.stopPropagation()}>
            <Checkbox onChange={handleCheckbox} checked={setAll}>Set all days the same</Checkbox>
          </div>
        </Col>
      </Row>
    </div>
  )

  const changeStartTime = (day_id, time) => {
    
    const newDays = _.cloneDeep(daysDetail);
    const newTime = formatReadableStringTo24Hours(time, time_zone)
    for (const index in newDays) {
      const day = newDays[index];
      const { id } = day || {};
      if (setAll) {
        newDays[index].start_time = newTime;
      } else if (id === day_id) {
        newDays[index].start_time = newTime;
        break;
      }
    }
    if (setAll) {
      const newTimeRange = { ...timeRange, start: newTime };
      setTimeRange(newTimeRange);
      setDaysDetail(newDays)
      form.setFieldsValue({ 'days': newTimeRange })
    } else {
      setDaysDetail(newDays)
      form.setFieldsValue({ 'days': newDays })
    }
  }


  const changesEndTime = (day_id, time) => {
    const newDays = _.cloneDeep(daysDetail);
    const newTime = formatReadableStringTo24Hours(time, time_zone)
    for (const index in newDays) {
      const day = newDays[index];
      const { id } = day || {};
      if (setAll) {
        newDays[index].end_time = newTime;
      } else if (id === day_id) {
        newDays[index].end_time = newTime;
        break;
      }
    }
    if (setAll) {
      const newTimeRange = { ...timeRange, end: newTime };
      setTimeRange(newTimeRange);
      setDaysDetail(newDays)
      form.setFieldsValue({ 'days': newTimeRange })
    } else {
      setDaysDetail(newDays)
      form.setFieldsValue({ 'days': newDays })
    }
  }

  const MultiSelect = daysDetail.map(d => {
    let { date, start_time, end_time, id } = (d || {})
    let start = format24HoursToReadableString(start_time, time_zone, use_24_hour_format);
    let end = format24HoursToReadableString(end_time, time_zone, use_24_hour_format);
    const start_list = generateStartTimeList(end, times_list, use_24_hour_format);
    const end_list = generateEndTimeList(start, times_list, use_24_hour_format);

    return (
      <div className="select-warpper" key={date}>
        <Row type="flex" align="middle">
          <Col xs={24} sm={10} md={8}>
            <div className="select-title">{formatDateToReadableStr(date, time_zone)}</div>
          </Col>
          <Col xs={24} sm={6} md={5} className="select-component">
            <div className="select-start-list">
              <Select value={start ? start : "Not set"} onChange={value => changeStartTime(id, value)}>
                {start_list.map(t => {
                  return (
                    <SelectOption value={t} key={t}>{t}</SelectOption>
                  )
                })}
              </Select>
            </div>
          </Col>
          <Col xs={24} sm={2} md={2} className="select-component">
            <div className="select-to">to</div>
          </Col>
          <Col xs={24} sm={6} md={5} className="select-component">
            <div className="select-end-list">
              <Select value={end ? end : "Not set"} onChange={value => changesEndTime(id, value)}>
                {end_list.map(t => {
                  return (
                    <SelectOption value={t} key={t}>{t}</SelectOption>
                  )
                })}
              </Select>
            </div>
          </Col>
        </Row>
      </div>
    )
  })

  const SingleSelect = () => {
    const { start, end } = (timeRange || {});
    let start_time = format24HoursToReadableString(start, time_zone, use_24_hour_format)
    let end_time = format24HoursToReadableString(end, time_zone, use_24_hour_format)
    const start_list = generateStartTimeList(end_time, times_list, use_24_hour_format);
    const end_list = generateEndTimeList(start_time, times_list, use_24_hour_format);
    return (
      <div className="select-warpper">
        <Row type="flex" align="middle">
          <Col xs={24} sm={4} md={3}>
            <div className="single-select-title">All days:</div>
          </Col>
          <Col xs={24} sm={6} md={5} className="select-component">
            <div className="select-start-list">
              
              <Select value={start_time ? start_time : "Not set"} onChange={value => changeStartTime('', value)}>
                {start_list.map(t => {
                  return (
                    <SelectOption value={t} key={t}>{t}</SelectOption>
                  )
                })}
              </Select>
            </div>
          </Col>
          <Col xs={24} sm={2} md={2} className="select-component">
            <div className="select-to">to</div>
          </Col>
          <Col xs={24} sm={6} md={5} className="select-component">
            <div className="select-end-list">
              <Select value={end_time ? end_time : "Not set"} onChange={value => changesEndTime('', value)}>
                {end_list.map(t => {
                  return (
                    <SelectOption value={t} key={t}>{t}</SelectOption>
                  )
                })}
              </Select>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <Collapse defaultActiveKey={['1']} expandIcon={handleExpandIcon} expandIconPosition={"right"} className="event-time-block" bordered={false}>
      <Collapse.Panel key="1" header={Header}>
        {isLoadingDays ? <BlockLoading loading={true} text="loading" /> : setAll ? SingleSelect() : MultiSelect}
      </Collapse.Panel>
    </Collapse>
  )
}

const mapStateToProps = (state) => {
  const { auth: { userInfo: { use_24_hour_format } } } = state;

  return {
    use_24_hour_format
  };
};

export default connect(mapStateToProps)(EventTimeBlock);
// export default EventTimeBlock;