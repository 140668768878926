import { JUDGE_ID } from 'shared/constants/eventRoleTypes';
import * as userEventRoleService from 'utils/services/eventRoles';
import * as types from '../../types/judgingTypes';

export const getJudgesBegin = () => {
  return {
    type: types.GET_EVENT_JUDGES_BEGIN
  }
}

export const getJudgesError = () => {
  return {
    type: types.GET_EVENT_JUDGES_ERROR
  }
}

export const getJudgesSuccess = (eventJudgesData, eventJudgesIncluded) => {
  return {
    type: types.GET_EVENT_JUDGES_SUCCESS,
    payload: {
      eventJudgesData, eventJudgesIncluded
    }
  }
}

const handleJudges = (eventId) => {
  return async dispatch => {
    try {
      await dispatch(getJudgesBegin());
      const filter = {
        'role.id': `eq:${JUDGE_ID}`,
        'event.id': `eq:${eventId}`
      };
      const { data, included } = await userEventRoleService.getAllUserEventRolesBy(filter);
      await dispatch(getJudgesSuccess(data, included))
    } catch(err) {
      await dispatch(getJudgesError())
      throw err;
    }
  }
}

export default handleJudges;
