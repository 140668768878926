import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import robotDesignOptions from 'shared/constants/fllChallengeRubricOptions/robotDesign';
import Container from 'shared/components/Container';
import FormFieldLegend from './FormFieldLegend';
import { CollapsableHeader } from './CollapsableHeader';
import { Form, Radio } from 'antd';
import CoreValueGear from 'shared/components/Icon/pngs/gear-thin-CV-symbol.png';
import '../../../../styles/judgingRubric/FLLRubricField.scss';

const radioOptions = [
  { value: 1, text: '1' },
  { value: 2, text: '2' },
  { value: 3, text: '3' },
  { value: 4, text: '4' },
]

const FormItem = Form.Item;
const moduleNameClassName = `h-2 heading--white`;
const moduleTitleClassName = `h-2 heading--charcoal m-t-12`;
const paragraphClassName = 'paragraph--large paragraph--charcoal paragraph--normal p-r-40'



const RobotDesign = props => {
  const { rubric, active } = props;
  const { t } = useTranslation();
  const initialFormValues = {};

  robotDesignOptions.map(v => {
    v.fields.map(f => {
      initialFormValues[f.id] = initialFormValues[rubric[f.id]];
      console.log(initialFormValues[f.id]);
    });
  });

  return (
    <Container>
      <h3
        className='h-1 h--400 heading--charcoal m-b-36'
      >
        {t('robotDesignLabel')}
      </h3>
      <CollapsableHeader
        leftTitle={t('robotDesignLabel')}
        rightTitle={t('5questionsLabel')}
        status={active}
      >
        <div>
          <img
            style={{ width: 30 + 'px', display: 'inline' }}
            src={CoreValueGear}
            alt='Core Value'
          />
          <p style={{ display: 'inline', marginInlineStart: 10 + 'px' }} className='paragraph--medium paragraph--charcoal m-t-16'>{t('rubricCoreValueSymbolExplanation')}</p>
        </div>
        <Form hideRequiredMark name='RobotDesignRubric' initialValues={{ initialFormValues }}>
          {robotDesignOptions.map(v => (
            <div className='c-fll-rubric-form__section' key={v.id}>
              <div className="c-fll-rubric-form__section-name">
                <h3 className={moduleNameClassName}>{t(v.label)}</h3>
              </div>
              <div className='c-fll-rubric-form__section-content-wrapper'>
                <p className={moduleTitleClassName}>{t(v.title)}</p>
                <p className={paragraphClassName}>{t(v.description)}</p>
                {v.fields.map(f => (
                  <div className='rubric-line-container'>
                    {f.isCoreValue && <div className='rubric-line-left'>
                      <img
                        style={{ width: 70 + 'px', marginInlineEnd: 0 + 'px' }}
                        src={CoreValueGear}
                        alt='Core Value'
                      />
                    </div>}

                    <div className='rubric-line-right'>
                      <fieldset className='c-fll-rubric-form__section-content-fieldset' key={f.id}>
                        <div className='c-fll-rubric-form__section-content-legend-wrapper rubric-item-container'>
                          <div className='m-t-20 rubric-item-left'>
                            <legend className='c-fll-rubric-form__section-legend'>{t(f.legendTitle)}</legend>
                            <FormFieldLegend values={f.legendValues} />
                          </div>
                          <div className='rubric-item-right'>
                            <FormItem initialValue={rubric[f.id]} name={f.id} >
                              <Radio.Group
                                buttonStyle="solid"
                                optionType="button"
                                className="c-radio-button-wrapper"
                                name={`${t(f.legendTitle)}`}
                                disabled
                                defaultValue={rubric[f.id]}
                              >
                                {radioOptions.map(({ value, text, description }) => (
                                  <Radio.Button
                                    className={`c-radio-button ${f.isCoreValue ? 'core-values-background' : ''}`}
                                    buttonStyle="solid"
                                    key={value}
                                    value={value}
                                  >
                                    {text || t(description)}
                                  </Radio.Button>
                                ))}
                              </Radio.Group>
                            </FormItem>
                          </div>
                        </div>
                        {rubric[f.id] === 4 ? (
                          <div className='c-fll-rubric-form__section-content-reason'>
                            <div className='c-fll-rubric-form__section-content-reason-title'>{t('justificationLabel')}:</div>
                            <div className='c-fll-rubric-form__section-content-reason-text'>{rubric[`${f.id}_justification`]}</div>
                          </div>

                        ) : (null)}
                      </fieldset>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}

        </Form>
      </CollapsableHeader>
    </Container>
  );
};

RobotDesign.propTypes = {
  rubric: PropTypes.object.isRequired,
};


export default RobotDesign;