import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Table from 'shared/components/Table';
import { connect } from 'react-redux';
import getAssignedJudgingSessions, { handlePageChange } from 'features/EventOverviewContainer/redux/actions/getAssignedJudgingSessions';
import SessionPanelUsers from 'shared/components/SessionPanelUsers';
import StatusBadge from 'shared/components/StatusBadge';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import EventModal from 'features/EventOverviewContainer/components/EventModal';
import SearchBar from 'features/AdminDashboard/components/SearchBar';
import Button from 'shared/components/Button/BtnSecondary';
import { officialMatchType, practiceMatchType } from 'shared/constants/matchTypes';
import sessionStatuses from 'shared/constants/judgingSessionStatuses';
import { parseDateString, parseTime, parseDate, formatDate, formatDateShort } from 'utils/dateTimeUtils';
import { parseTimeAndDate, parseTimeAndDateRaw } from 'utils/dateTimeUtils';
import matchStatuses, { NotSetStatus } from 'shared/constants/matchStatuses';
import SharedIcon from 'shared/components/Icon';
import { Switch } from 'antd';
import SessionPaneSelect from 'shared/components/SessionPanelSelect';
import _ from 'lodash';
import moment from 'moment';

const defaultTextClassName = 'paragraph--large paragraph--charcoal paragraph--normal';
const teamClassName = 'paragraph--large paragraph--charcoal paragraph--bold'

const ItemWrapper = ({ ...props }) => <div {...props} className="table-item-wrapper" />

const JUDGING_PAGE_SIZE = 12;
let pageFullLoad = false;

const AssignedSessions = props => {
  const { eventId } = useParams();
  const { t } = useTranslation()

  const { getAssignedSessions, eventOverview, assignedJudgingSessions, userId, use_24_hour_format } = props;
  const { eventScheduleId, time_zone, is_FLLC_event } = eventOverview;
  const [currentPanelFilter, setCurrentPanelFilter] = useState([]);
  const [currentSize, setCurrentSize] = useState(JUDGING_PAGE_SIZE);
  const {
    assignedSessionsCount,
    assignedSessions,
    assignedPanels,
    isFetchingAssignedSessions,
    count
  } = assignedJudgingSessions;
  const [transition, setTransition] = useState(0);
  const tracker = useRef(null);
  const [prevPage, setPrevPage] = useState(1);
  const thumb = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [search, setSearch] = useState('')
  const [tableViewType, setTableViewType] = useState('timeAndDate');
  const [fullscreen, setFullscreen] = useState(false);

  useEffect(() => {
    if (eventScheduleId && userId) {
      getAssignedSessions({ size: 500, number: currentPage })
    }
  }, [eventScheduleId, userId, currentPage]);

  const sortByDate = (myArray) => {
    return myArray.sort(function compare(a, b) {
      var dateA = new Date(a.startTime);
      var dateB = new Date(b.startTime);
      return dateA - dateB;
    });

  }

  const sortByTeamNumberAndName = (myArray) => {
    return myArray.sort(function compare(a,b){
      const aStr = a?.teamTitle ?? '';
      const bStr = b?.teamTitle ?? '';
      return aStr.localeCompare(bStr, undefined, { numeric: true, sensitivity: 'variant'});
    })
  }

  const ExpandArrow = (key) => {
    const isExpanded = expandedKeys.includes(key);
    return isExpanded ? <a className="expanded-arrow" onClick={() => collapseRowOnKey(key)}><SharedIcon type="smallRightArrowBlue" /></a> : <a className="expanded-arrow" onClick={() => expandRowOnKey(key)}><SharedIcon type="smallDownBlue" /></a>
  }

  const expandRowOnKey = (key) => {
    setExpandedKeys([...expandedKeys, key])
  }

  const collapseRowOnKey = (key) => {
    setExpandedKeys(expandedKeys.filter(k => k !== key))
  }

  assignedSessions.forEach(d => {
    d.date = parseDate(d.startTime)
  });

  if (tableViewType == 'timeAndDate') {
    sortByDate(assignedSessions)
  } else {
    sortByTeamNumberAndName(assignedSessions);
  }

  const filterByTeamName = (data) => {
    if (search == '') {
      return data;
    }

    var serachRegx = new RegExp(search, 'gi');

    let clonedData = [...data]

    clonedData.forEach(items => {
      let filteredData = [];
      items.data.forEach(item => {
        if (item.teamName.match(serachRegx)) {
          filteredData.push(item)
        }
      });
      items.data = filteredData;
    });

    return clonedData;
  };

  const filterByMatchPanel = (data) => {
    if (currentPanelFilter.length <= 0) {
      return data;
    }

    let clonedData = [...data]

    clonedData.forEach(items => {
      let filteredData = [];
      items.data.forEach(item => {
        if (currentPanelFilter.includes(item.panelId)) {
          filteredData.push(item)
        }
      });
      items.data = filteredData;
    });

    return clonedData;
  };

  const clearEmpty = () => {
    let newData = [];
    let clonedData = [...formattedData]
    clonedData.forEach((item, index) => {
      if (item.data.length > 0) {
        newData.push(item)
      }
    });

    return newData
  }


  const groupMatchesIntoDates = () => {
    const groupedData = _.groupBy(assignedSessions, 'date');
    const keys = Object.keys(groupedData);
    let result = [];
    keys.forEach(key => {
      const list = groupedData[key];
      const data = list[0] || {};
      const { startIsoString, startTime, sessionId } = data;

      const formattedData = {
        sessionId,
        date: startTime,
        data: list,
        key: sessionId + startIsoString,
        uniqueId: sessionId + startIsoString
      }
      result.push(formattedData);
    })
    return result;
  }

  const groupMatchesIntoTeams = () => {
    const groupedData = _.groupBy(assignedSessions, 'sessionId');
    const keys = Object.keys(groupedData);
    let result = [];
    keys.forEach(key => {
      const list = groupedData[key];
      const data = list[0] || {};
      const { startIsoString, sessionId, startTime } = data;
      const formattedData = {
        sessionId,
        date: startTime,
        data: list,
        key: sessionId + startIsoString,
        uniqueId: sessionId + startIsoString
      }
      result.push(formattedData);
    })
    return result;
  }

  const formattedDataMain = tableViewType == 'timeAndDate' ? groupMatchesIntoDates() : groupMatchesIntoTeams()
  let formattedData = tableViewType == 'timeAndDate' ? groupMatchesIntoDates() : groupMatchesIntoTeams()

  formattedData = filterByMatchPanel(formattedData)
  formattedData = filterByTeamName(formattedData)
  formattedData = clearEmpty()

  const formatTime = (date, time_zone, is24Hour = false) => {
    if (date === undefined || date == null) return 'Not Set'
    // return moment(date).format("h:mm a");
    return time_zone ? moment.utc(date).tz(time_zone).format(is24Hour ? 'HH:mm' : 'h:mm a') : moment.utc(date).format(is24Hour ? 'HH:mm' : 'h:mm a')
  }

  const enterTablefullscreen = () => {
    setFullscreen(!fullscreen);
  }

  const expandAllRecord = () => {
    const keys = formattedData.map(d => d.sessionId);
    setExpandedKeys(keys);
  }

  const collapseAllRecord = () => {
    setExpandedKeys([])
  }

  // if (assignedSessionsCount >= formattedData.length) {
  //   pageFullLoad = true
  //   expandAllRecord()
  // }

  const ShowTotalBlock = (total, range) => {
    const hasMore = total > JUDGING_PAGE_SIZE;
    const showMoreTitle = currentSize > JUDGING_PAGE_SIZE ? "Show Less" : "Show All";
    return (
      <div className="showing-total-wrapper">
        <div className="text">{`Showing ${range[0]} - ${range[1]} of ${total}`}</div>
        <div className="divider">|</div>
        {hasMore ? <a onClick={toggleCurrentPageSize}>{showMoreTitle}</a> : null}
      </div>
    )
  }

  const toggleCurrentPageSize = () => {
    const showingAll = currentSize > JUDGING_PAGE_SIZE;
    const size = showingAll ? JUDGING_PAGE_SIZE : count;
    if (showingAll) {
      props.handlePageChange(prevPage);
    } else {
      setPrevPage(currentPage);
      props.handlePageChange(1)
    }
    setCurrentSize(size);
  }

  // const ShowTotalBlock = (total, range) => {
  //   return (
  //     <div className="showing-total-wrapper">
  //       <div className="text">{`Showing ${range[0]} - ${range[1]} of ${total}`}</div>
  //     </div>
  //   )
  // }

  //FILTER BY DATE AND TIME

  const columnsFilterByDateTime = [
    {
      title: t('Time and Date'),
      dataIndex: 'timeAndDate',
      width: 100,
      className: 'team-name-number selected-column',
      // sorter: (a, b) => a.matchTeam.localeCompare(b.matchTeam),
      render: (val, record) => {
        const { data, sessionId } = record;
        const isExpanded = expandedKeys.includes(sessionId) ? false : true;

        if (isExpanded) {
          const result = data.map(d => {
            const { sessionId, startIsoString } = d || {};
            return <ItemWrapper key={sessionId}><span>
              <span className='time-only m-r-10'>{formatTime(startIsoString, time_zone, use_24_hour_format)}</span>
            </span></ItemWrapper>
          })
          return (
            <>
              <div className={`${tableViewType == 'teamAndNumber' ? 'white-row-bg-judge-team-number' : 'white-row-bg-judge'}`}></div>
              <div className="team-title">
                {ExpandArrow(sessionId)}
                <div className="title-text">
                  {`${formatDate(record.data[0]?.startIsoString, time_zone)} (${data.length})`}
                </div>
              </div>
              <div className="referees-times-wrapper table-first-row">{result}</div>
            </>
          )
        }

        return (
          <>
            <div className={`${tableViewType == 'teamAndNumber' ? 'white-row-bg-judge-team-number' : 'white-row-bg-judge'}`}></div>
            <div className="team-title">
              {ExpandArrow(sessionId)}
              <div className="title-text">
                {`${formatDate(record.data[0]?.startIsoString, time_zone)} (${data.length})`}
              </div>
            </div>
          </>
        )
      }
    },
    {
      title: t('teamNumberAndNameLabel'),
      dataIndex: 'sessionId',
      className: '',
      width: 100,
      render: (val, record) => {
        const { data, sessionId } = record;
        const isExpanded = expandedKeys.includes(sessionId) ? false : true;

        if (isExpanded) {
          const result = data.map(d => {
            const { sessionId } = d || {};
            return <ItemWrapper key={sessionId}>
              <span className='elipsis'>{d.teamName}</span>
            </ItemWrapper>
          })
          return <div className="referees-times-wrapper table-custom-row">{result}</div>
        }
      }
    },
    {
      title: t('Location'),
      dataIndex: 'location',
      width: 100,
      render: (val, record) => {
        const { data, sessionId } = record;
        const isExpanded = expandedKeys.includes(sessionId) ? false : true;

        if (isExpanded) {
          const result = data.map(d => {
            return <ItemWrapper key={sessionId}><span className='elipsis'>{d?.panelName ? d.panelName : '-'}</span></ItemWrapper>
          })
          return <div className="referees-times-wrapper table-custom-row">{result}</div>
        }
      }
    },
    {
      title: t('statusLabel'),
      dataIndex: 'matchStatus',
      width: 150,
      render: (val, row) => {
        const { sessionId, data } = row;
        const isExpanded = expandedKeys.includes(sessionId) ? false : true;
        if (isExpanded) {
          const result = data.map(d => {
            const sessionStatus = sessionStatuses.find(s => s.statusId == d.sessionStatusId) || {};
            const { badgeType } = sessionStatus;
            return <StatusBadge statusText={d.sessionStatusName} badgeType={badgeType} />
          })
          return <div className="referees-status-wrapper expanded table-custom-row">{result}</div>
        }
      },
    },
    {
      title: t('Rubric'),
      dataIndex: 'rubric',
      width: 100,
      align: 'left',
      render: (val, row) => {
        const { sessionId, data } = row;
        const isExpanded = expandedKeys.includes(sessionId) ? false : true;
        if (isExpanded) {
          const result = data.map(d => {
            const { sessionId } = d;
            return <Link to={`/event-overview/${eventId}/rubric/${sessionId}`} className='table-item-wrapper paragraph--large paragraph--blue paragraph--normal'>{t('rubricLabel')}</Link>
          })
          return <div className="referees-times-wrapper table-custom-row">{result}</div>
        }
      }
    }
  ];



  //FILTER BY TEAM NAME AND NUMBER COLUMN
  const columnsFilterByTeam = [
    {
      title: t('Time and Date'),
      dataIndex: 'timeAndDate',
      width: 200,
      render: (val, record) => {
        const { data, sessionId } = record;
        const isExpanded = expandedKeys.includes(sessionId);

        return (
          <>
            <div className="referees-times-wrapper">
              <ItemWrapper key={sessionId}><span>
                <span className='time-only m-r-10'>{formatTime(data[0]?.startIsoString, time_zone, use_24_hour_format)}</span> <span className='date-only'>{formatDateShort(data[0]?.startIsoString, time_zone)}</span>
              </span></ItemWrapper>
            </div>
          </>
        )
      }
    },
    {
      title: t('teamNumberAndNameLabel'),
      dataIndex: 'sessionId',
      className: 'team-name-number selected-column',
      width: 250,
      // sorter: (a, b) => a.matchTeam.localeCompare(b.matchTeam),
      render: (val, record) => {
        const { data, sessionId } = record;
        const isExpanded = expandedKeys.includes(sessionId);
        return <div className="referees-times-wrapper">{data[0]?.teamTitle}</div>
      }
    },
    {
      title: t('Location'),
      dataIndex: 'location',
      width: 200,
      render: (val, record) => {
        const { sessionId, data } = record;
        const isExpanded = expandedKeys.includes(sessionId);

        return <div className="referees-times-wrapper">{data[0]?.panelName || '-'}</div>

      }
    },
    {
      title: t('statusLabel'),
      dataIndex: 'matchStatus',
      render: (val, row) => {
        const { sessionId, data } = row;
        const isExpanded = expandedKeys.includes(sessionId);
        const result = data.map(d => {
          const sessionStatus = sessionStatuses.find(s => s.statusId == d.sessionStatusId) || {};
          const { badgeType } = sessionStatus;
          return <StatusBadge statusText={d.sessionStatusName} badgeType={badgeType} />
        })
        return <div className="referees-status-wrapper expanded">{result}</div>
      },
    },
    {
      title: t('Rubric'),
      dataIndex: 'rubric',
      width: 100,
      align: 'left',
      render: (val, row) => {
        const { sessionId, data } = row;
        const isExpanded = expandedKeys.includes(sessionId);
        let result = <ItemWrapper key={data[0].sessionId}>
          <Link to={`/event-overview/${eventId}/rubric/${data[0]?.sessionId}`} className='paragraph--large paragraph--blue paragraph--normal'>{t('rubricLabel')}</Link>
        </ItemWrapper>
        return <div className="referees-times-wrapper">{result}</div>
      }
    }
  ];

  const columns = [
    {
      title: t('Time and Date'),
      dataIndex: 'teamTitle',
      className: 'teamTitle-colum-mobile',
      // sorter: (a, b) => a.teamTitle.localeCompare(b.teamTitle),
      render: (val) => <p><span className='time-only'>1:00</span> <span>Mon 3/17</span></p>
    },
    {
      title: t('teamNumberAndNameLabel'),
      dataIndex: 'teamTitle',
      className: 'teamTitle-colum-mobile',
      width: 210,
      sorter: (a, b) => a.teamTitle.localeCompare(b.teamTitle),
      render: (val) => <p className={teamClassName}>{val}</p>
    },
    {
      title: t('sessionLabel'),
      dataIndex: 'startTime',
      className: 'startTime-colum-mobile',
      sorter: (a, b) => {
        if (a.startIsoString && b.startIsoString) {
          return a.startIsoString.localeCompare(b.startIsoString)
        }
      },
      render: (val, record) => {
        const { startIsoString } = record || {};
        const startDate = startIsoString ? parseDateString(startIsoString, time_zone) : undefined;
        const startTime = startIsoString ? parseTime(startIsoString, time_zone) : undefined;
        const displayTime = `${startDate ? startDate + ', ' : ''}` + `${startTime ? startTime : ''}`;
        return <p className={defaultTextClassName}>{displayTime}</p>
      },
    },
    {
      title: t('statusLabel'),
      dataIndex: 'sessionStatusName',
      className: 'sessionStatusName-colum-mobile',
      render: (val, row) => {
        const sessionStatus = sessionStatuses.find(s => s.statusId === row.sessionStatusId) || {};
        const { badgeType } = sessionStatus;
        return <StatusBadge statusText={val} badgeType={badgeType} />
      }
    },
    {
      title: '',
      key: 'sessionId',
      dataIndex: 'sessionId',
      className: 'sessionId-colum-mobile',
      fixed: 'right',
      width: 100,
      render: (val) => {
        return (
          <Link to={`/event-overview/${eventId}/rubric/${val}`} className='paragraph--large paragraph--blue paragraph--normal'>{t('rubricLabel')}</Link>
        )
      }
    }
  ]

  const onTableScroll = (e) => {

    const { scrollWidth, clientWidth, scrollLeft } = (e || {}).target || {};
    const trackWidth = ((tracker || {}).current || {}).clientWidth;
    const thumbWidth = ((thumb || {}).current || {}).clientWidth;
    const maxMovement = scrollWidth - clientWidth;
    const maxTransition = trackWidth - thumbWidth;
    setTransition(scrollLeft / maxMovement * maxTransition);
  }

  const scrollbar = () => {
    const activeColor = '#4B4B4B';
    const inActiveColor = '#C6C4C6';
    const reachingLeft = transition === 0;
    const reachingRight = transition + ((thumb || {}).current || {}).clientWidth === ((tracker || {}).current || {}).clientWidth;
    return (
      <div className="scrollbar">
        <Icon type="left" style={{ fontSize: '7.5px', color: reachingLeft ? inActiveColor : activeColor }} />
        <div className="scrollbar-wrapper" ref={tracker}>
          <div className="scrollbar-thumb" style={{ transform: `translate(${transition}px)` }} ref={thumb} />
        </div>
        <Icon type="right" style={{ fontSize: '7.5px', color: reachingRight ? inActiveColor : activeColor }} />
      </div>
    )
  }

  const handlePanelFilter = (data) => {
    let panelIds = data.map((item) => item.id)
    setCurrentPanelFilter(panelIds)
  }

  return (
    <div className='m-t-48'>
      <h2 className='h-2--normal heading--charcoal m-b-24'>{is_FLLC_event ? t('assignedJudgingSessionsLabel') : t('nonChallengeAssignedJudgingSessionsLabel')}</h2>
      <div>
        {/* {assignedPanels.map(p => (
          <div key={p.id} className='m-b-16'>
            <SessionPanelUsers panelName={p.name} users={p.judges} hideAvatar />
          </div>
        ))} */}

        <div className='m-b-40'>
          <SessionPaneSelect panelType="judges" fullData={(e) => { handlePanelFilter(e) }} onChange={() => { }} panelList={assignedPanels}></SessionPaneSelect>
        </div>

        <div>
          {fullscreen ?
            <EventModal
              onCancel={enterTablefullscreen}
              visible={fullscreen}
            >
              <>
                <div className="scoring-table-control-row">
                  <div className='buttons-left'>
                    <SearchBar setSearchText={setSearch} searchText={search} placeholder="Search" hideSearchOnType={true} />
                    {tableViewType == "timeAndDate" && (
                      <>
                        <Button className="first-button" type="link" onClick={expandAllRecord}>{t('CollapseAllLabel')}</Button>
                        <Button className="first-button" type="link" onClick={collapseAllRecord}>{t('ExpandAllLabel')}</Button>
                      </>
                    )}
                  </div>
                  <div className="buttons-right">
                    <Button onClick={enterTablefullscreen} className="fullscreen" type="link"><Icon style={{ fontSize: 20 }} type="fullscreen" /></Button>
                  </div>
                </div>

                <div className='m-t-24 score-table-row-mobile' onScroll={onTableScroll}>
                  <Table
                    //scroll={{ x: 960 }}
                    rowKey='sessionId'
                    columns={tableViewType == 'teamAndNumber' ? columnsFilterByTeam : columnsFilterByDateTime}
                    dataSource={formattedData}
                    pagination={{
                      pageSize: currentSize,
                      // onChange: setCurrentPage,
                      // current: currentPage,
                      total: formattedData.length,
                      showTotal: ShowTotalBlock,
                    }}
                    className="referee-sessions-table"
                    // rowClassName={(record, index) => index % 2 === 1 ? 'even-row' : 'odd-row'}
                    rowClassName={(record, index) => 'each-row'}
                    loading={isFetchingAssignedSessions}
                  // footer={scrollbar}
                  />
                </div>
              </>
            </EventModal>
            :
            <>

              <div className="scoring-table-control-row">
                <div className='buttons-left'>
                  <SearchBar setSearchText={setSearch} searchText={search} placeholder="Search" hideSearchOnType={true} />
                  {tableViewType == "timeAndDate" && (
                    <>
                      <Button className="first-button" type="link" onClick={expandAllRecord}>{t('CollapseAllLabel')}</Button>
                      <Button className="first-button" type="link" onClick={collapseAllRecord}>{t('ExpandAllLabel')}</Button>
                    </>
                  )}
                </div>
                <div className="buttons-right">
                  <Button onClick={enterTablefullscreen} className="fullscreen" type="link"><Icon style={{ fontSize: 20 }} type="fullscreen" /></Button>
                </div>
              </div>

              <div className='sort-section m-t-40 m-b-30'>
                <div className='m-r-10'>Sort by: <span className=''><strong>Time and Date</strong></span> </div>
                <div className=''><Switch className='custom-switch' defaultChecked={false} onChange={(e) => {
                  setTableViewType(e ? 'teamAndNumber' : 'timeAndDate')
                }} /></div>
                <div className='m-l-10'><strong>Team Number and Name</strong></div>
              </div>

              <div className='m-t-24 assigned-judging-sessions-table-mobile' onScroll={onTableScroll}>

                <div className='m-t-24 score-table-row-mobile' onScroll={onTableScroll}>
                  <Table
                    //scroll={{ x: 960 }}
                    rowKey='sessionId'
                    columns={tableViewType == 'teamAndNumber' ? columnsFilterByTeam : columnsFilterByDateTime}
                    dataSource={formattedData}
                    pagination={{
                      pageSize: currentSize,
                      // onChange: setCurrentPage,
                      // current: currentPage,
                      total: formattedData.length,
                      showTotal: ShowTotalBlock,
                    }}
                    className="referee-sessions-table"
                    // rowClassName={(record, index) => index % 2 === 1 ? 'even-row' : 'odd-row'}
                    rowClassName={(record, index) => 'each-row'}
                    loading={isFetchingAssignedSessions}
                    // footer={scrollbar}
                  />
                </div>
                {/* <Table
            // scroll={{ x: 560 }}
            rowKey='sessionId'
            columns={columnsFilterByDateTime}
            dataSource={fakeData}
            pagination={{
              pageSize: JUDGING_PAGE_SIZE,
              onChange: setCurrentPage,
              current: currentPage,
              total: assignedSessionsCount,
              showTotal: ShowTotalBlock,
            }}
            className="referee-sessions-table"
            // rowClassName={(record, index) => index % 2 === 1 ? 'even-row' : 'odd-row'}
            rowClassName={(record, index) => 'each-row'}
            loading={isFetchingAssignedSessions}
            footer={scrollbar}
          /> */}

                {/* <Table
            scroll={{ x: 560 }}
            rowKey='sessionId'
            columns={columns}
            dataSource={assignedSessions}
            pagination={{
              pageSize: JUDGING_PAGE_SIZE,
              onChange: setCurrentPage,
              current: currentPage,
              total: assignedSessionsCount,
              showTotal: ShowTotalBlock,
            }}
            className="judge-sessions-table"
            rowClassName={(record, index) => index % 2 === 1 ? 'even-row' : 'odd-row'}
            loading={isFetchingAssignedSessions}
            footer={scrollbar}
          /> */}
              </div>
            </>
          }



        </div>
      </div>
    </div>
  );
};

AssignedSessions.propTypes = {
  getAssignedSessions: PropTypes.func.isRequired,
  assignedJudgingSessions: PropTypes.object.isRequired,
  eventOverview: PropTypes.object.isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handlePageChange: PropTypes.func.isRequired,
};

AssignedSessions.defaultProps = {
  userId: null
}

const mapDispatchToProps = {
  getAssignedSessions: getAssignedJudgingSessions,
  handlePageChange: handlePageChange
}

const mapStateToProps = (state) => {
  const { eventOverview, assignedJudgingSessions, auth: { userId, userInfo: { use_24_hour_format } } } = state;

  return {
    eventOverview,
    assignedJudgingSessions,
    userId,
    use_24_hour_format
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignedSessions);