import { API } from 'utils/api';
import * as eventRolesService from 'utils/services/eventRoles';
import {formatUserEventRoleTypesList} from 'utils/services/eventRoles/model';
import * as eventRoleIds from 'shared/constants/eventRoleTypes';
import * as teamService from 'utils/services/teams';
import * as scheduleService from 'utils/services/schedules';
import * as userService from 'utils/services/users';
import Team from 'models/Team';
import { formatTeamDetailsRes } from 'utils/services/teams/model';

import * as types from '../types/eventOverviewTypes';
import * as matchTypes from '../types/matchScoresheet';
import * as eventRoleTypes from '../types/userEventOverviewRoleTypes';
import * as eventRoles from 'shared/constants/eventRoleTypes';
import { fetchEventLeaderboard } from 'features/LeaderboardContainer/redux/leaderboardActions';
import { FLL_DISCOVER_ID, FLL_EXPLORE_ID } from 'shared/constants/programIds';
import Event from 'models/Event';
import fetchEventDetails from "./matchScoresheet/getEventDetails";

const getEventOverviewScheduleBegin = () => {
  return {
    type: types.GET_EVENT_OVERVIEW_SCHEDULE_BEGIN
  }
}

const getEventOverviewScheduleError = () => {
  return {
    type: types.GET_EVENT_OVERVIEW_SCHEDULE_ERROR
  }
}

const getEventOverviewScheduleSuccess = (eventScheduleData, eventScheduleIncluded) => {
  return {
    type: types.GET_EVENT_OVERVIEW_SCHEDULE_SUCCESS,
    payload: {
      eventScheduleData, eventScheduleIncluded
    }
  }
}

const getEventRolesBegin = () => {
  return {
    type: eventRoleTypes.GET_USER_EVENT_OVERVIEW_ROLES_BEGIN
  }
}

const getEventRolesError = () => {
  return {
    type: eventRoleTypes.GET_USER_EVENT_OVERVIEW_ROLES_ERROR
  }
}

const getEventRolesSuccess = (userEventRoleIds) => {
  return {
    type: eventRoleTypes.GET_USER_EVENT_OVERVIEW_ROLES_SUCCESS,
    payload: {
      userEventRoleIds
    }
  }
}

const setEventDetails = (eventData, eventIncluded) => {

  return {
    type: types.SET_EVENT_OVERVIEW_DETAILS,
    payload: {
      eventData, eventIncluded
    }
  }
}

export const setEventScheduleId = (eventScheduleId) => {
  return {
    type: types.SET_EVENT_OVERVIEW_SCHEDULE_ID,
    payload: {
      eventScheduleId
    }
  }
}

export const setEventProgramId = (eventProgramId) => {
  return {
    type: types.SET_EVENT_OVERVIEW_PROGRAM_ID,
    payload: {
      eventProgramId
    }
  }
}

const setEventCoachDetails = (data) => {
  return {
    type: types.SET_EVENT_OVERVIEW_COACH_DETAILS,
    payload: {
      ...data
    }
  }
}

const scheduleError = () => {
  return {
    type: types.SET_VOLUNTEER_EVENT_SCHEDULE_ERROR
  }
}

const scheduleSuccess = () => {
  return {
    type: types.SET_VOLUNTEER_EVENT_SCHEDULE_SUCCESS
  }
}

const setCoachTeams = (coachTeams) => {
  return {
    type: types.SET_EVENT_OVERVIEW_COACH_TEAMS_LIST,
    payload: {
      coachTeams
    }
  }
}

const setSelectedTeam = (selectedTeam) => {
  return {
    type: types.SET_EVENT_OVERVIEW_SELECTED_TEAM,
    payload: {
      selectedTeam
    }
  }
}

const getEventDetailsBegin = () => {
  return {
    type: types.GET_EVENT_OVERVIEW_DETAILS_BEGIN
  }
}
const getEventDetailsError = () => {
  return {
    type: types.GET_EVENT_OVERVIEW_DETAILS_ERROR
  }
}
const getEventDetailsSuccess = () => {
  return {
    type: types.GET_EVENT_OVERVIEW_DETAILS_SUCCESS
  }
}

const getMatchEventDetailsSuccess = (eventDetails) => {
  return {
    type: matchTypes.GET_MATCH_EVENT_DETAILS_SUCCESS,
    payload: {
      eventDetails
    }
  }
}

export const getEventDetails = (eventId) => {
  return async (dispatch, getState) => {
    await dispatch(getEventDetailsBegin())
    await dispatch(getEventRolesBegin());
    const {auth: {userId}} = getState();

    try {
      const {included} = await eventRolesService.getUserEventRolesByUserIdAndEventId(userId, eventId);
      const eventRolesList = formatUserEventRoleTypesList(included);

      const eventResponse = await API.GetInstance().http.get(`/api/events/${eventId}`);
      
      const eventData = eventResponse.data;
      const eventIncluded = eventResponse.included || [];

    

      const eventProgramData = eventIncluded.find(({type}) => type === 'program') || {};
      const eventProgramId = eventProgramData.id;
      await dispatch(setEventProgramId(eventProgramId));
      await dispatch(setEventDetails(eventData, eventIncluded));
      await dispatch(getEventRolesSuccess(eventRolesList));
      await dispatch(getEventDetailsSuccess())
    } catch(err) {
      await dispatch(getEventDetailsError())
      await dispatch(getEventRolesError());
      throw err;
    }
  }
}

const setIsEventAdminAction = (value)=>{
  return ({
    type: types.SET_IS_EVENT_ADMIN_ROLE,
    payload: value
  })
}

const setEventOverviewTimezone = (timezone)=>{
  return ({
    type: types.SET_EVENT_OVERVIEW_TIME_ZONE,
    payload: timezone
  })
}

const getEventOverviewSchedule = (eventId) => {
  return async (dispatch, getState) => {
    await dispatch(getEventOverviewScheduleBegin());
    await dispatch(getEventRolesBegin());
    const {auth: {userId, userType, userEventAssignments}} = getState();

    try {
      const {included} = await eventRolesService.getUserEventRolesByUserIdAndEventId(userId, eventId);
      const eventRolesList = formatUserEventRoleTypesList(included);
      await dispatch(getEventRolesSuccess(eventRolesList));

      const eventResponse = await API.GetInstance().http.get(`/api/events/${eventId}`);
  
      
      
      const eventData = eventResponse.data;
      const eventIncluded = eventResponse.included || [];

      const eventDetails = new Event(eventData, eventIncluded);
      const formattedEvent = {
        eventLocation: eventDetails.getEventLocation(),
        eventId: eventDetails.eventId,
        eventDate: eventDetails.parseStartEndTimeShortenedString(),
        eventName: eventDetails.name,
        programId: eventDetails.programId,
        season: eventDetails.getSeason()
      }

      dispatch(getMatchEventDetailsSuccess(formattedEvent)) 

      const eventTimeZone = (((eventData||{}).attributes||{}).timezone);
      await dispatch(setEventOverviewTimezone(eventTimeZone));

      const eventRegionId = ((((eventData||{}).relationships||{}).region||{}).data||{}).id;
      const partnerRoleFilter = {
        'organizations.programs.region.id': `eq: ${eventRegionId}`,
        'id': `eq: ${userId}`,
        'user_type': 'eq:PartnerAdmin',
      }
      const {data} = await userService.getUserBySortFilter(partnerRoleFilter, {size: 500})
      const isPartnerAdmin = (((data||[])[0]||{}).attributes||{}).user_type === 'PartnerAdmin';
      const isFirstAdmin = userType === 'FirstAdmin';
      const eventRoleAssigments = (userEventAssignments||[]).filter(i=>i.eventId === eventId);
      const isEventAdmin = (eventRoleAssigments||[]).some(assignment => assignment.roleId === eventRoles.EVENT_ADMIN_ID);
      const isAdminRole = isPartnerAdmin||isFirstAdmin||isEventAdmin;
      await dispatch(setIsEventAdminAction(isAdminRole));

      const eventProgramData = eventIncluded.find(({type}) => type === 'program') || {};
      const eventProgramId = eventProgramData.id;
      await dispatch(setEventProgramId(eventProgramId));
      await dispatch(setEventDetails(eventData, eventIncluded));
      const scheduleId = ((((eventData||{}).relationships||{}).schedule||{}).data||{}).id;
      const scheduleResponse = await scheduleService.getScheduleById(scheduleId);
      const eventScheduleData = scheduleResponse.data;
      const eventScheduleIncluded = scheduleResponse.included || [];
      const leaderboardId = ((((eventScheduleData||{}).relationships||{}).leaderboard||{}).data||{}).id;

      if(leaderboardId){
        await dispatch(fetchEventLeaderboard(leaderboardId));
      }

      const IS_FLL_D_OR_FLL_E  = eventProgramId == FLL_DISCOVER_ID || eventProgramId === FLL_EXPLORE_ID;

      if (eventScheduleData.id) {
        await dispatch(scheduleSuccess())
        await dispatch(setEventScheduleId(eventScheduleData.id))
        const isCoach = eventRolesList.includes(eventRoleIds.COACH_ID);
    
        if (isCoach) {
          let scoring_sessions;
          let filtered_teams = [];
          
          if(IS_FLL_D_OR_FLL_E) {
            scoring_sessions = await scheduleService.getScheduleJudgingSessions(scheduleId);
            filtered_teams = ((scoring_sessions||{}).included||[]).filter(i=>i.type==='team').map(i=>i.id).join(',');
          }
          else {
            scoring_sessions = await scheduleService.getScheduleScoringSessions(scheduleId);
            filtered_teams = (scoring_sessions||[]).map(i=>i?.team?.id).join(',');
          }

          const teamsFilter = {'id': `in:${filtered_teams}`}
          const teamsResponse = filtered_teams.length ?  await teamService.getTeamsBy(teamsFilter, {number:1, size: 100}) : {data: [], included: []};
          const tempTeamsData = teamsResponse.data;
          const teamsData = tempTeamsData.filter(t=>{
            const coachIds = ((((t||{}).relationships||{}).coaches||{}).data||[]).map(c=>(c||{}).id);
            return coachIds.includes(userId);
          })
          const teamsIncluded = teamsResponse.included;
     
          const coachTeamsList = teamsData.map(team => formatTeamDetailsRes(team, teamsIncluded))
          
          if (coachTeamsList.length) {
            await dispatch(setSelectedTeam(coachTeamsList[0]));
 
            await dispatch(setCoachTeams(coachTeamsList));
          } else {
            await dispatch(setSelectedTeam({}));
            await dispatch(setCoachTeams([]));
          }
          const coachTeam = (Array.isArray(teamsData) && teamsData.length) ? teamsData[0] : undefined;
          if (coachTeam) {
            const teamDetails = new Team(coachTeam, teamsResponse.included);
            const {id} = coachTeam;

            const coachDetails = {
              isCoach,
              teamName: teamDetails.teamName,
              teamNumber: teamDetails.teamNumber,
              teamLocation: teamDetails.getTeamLocation(),
              teamId: id,
            };

            await dispatch(setEventCoachDetails(coachDetails));
          }
  
        } else {
          await dispatch(setCoachTeams([]));
          await dispatch(setSelectedTeam({}));
        }
        
        await dispatch(getEventOverviewScheduleSuccess(eventScheduleData, eventScheduleIncluded))
      } else {
        await dispatch(scheduleError());
      }
    } catch(err) {
      await dispatch(getEventOverviewScheduleError());
      await dispatch(getEventRolesError());
      throw err;
    }
  }
}


export const handleTeamSelect = (teamId) => {
  return async (dispatch, getState) => {
    const {eventOverview: {
      eventCoachTeamsList,
      userEventRoleIds
    }
    } = getState();
    const isCoach = userEventRoleIds.includes(eventRoleIds.COACH_ID);
    const selectedTeam = eventCoachTeamsList.find(i => i.id === teamId);
    if (selectedTeam) {
      await dispatch(setSelectedTeam(selectedTeam))
      const coachDetails = {
        isCoach,
        ...selectedTeam
      };
      await dispatch(setEventCoachDetails(coachDetails));
    }
  }
}

export default getEventOverviewSchedule;