import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ListLoading from 'shared/components/Spinner/ListLoading';
import ScheduleTimestamp from 'models/ScheduleTimestamp';
import ScheduleSession from 'models/ScheduleSession';
import {
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FTC_ID
} from 'shared/constants/programIds';
import {
  handleScheduleDetails
} from 'features/EventContainer/redux/actions/scheduleActions';
import DeleteEventSection from './DeleteEventSection';
import OpeningClosingCeremonies from './OpeningClosingCeremonies';
import JudgingSection from './Judging';
import MeetingsSection from './Meetings';
import ScoringSection from './Scoring';
import Matches from './Matches';
import { IN_PERSON_OFFICIAL, IN_PERSON_TEST } from 'shared/constants/eventTypes';
import { fetchScoringSessions } from 'features/EventContainer/redux/actions/scheduleActions/scheduleScoringSessions';
import { fetchJudgingSessions } from 'features/EventContainer/redux/actions/scheduleActions/scheduleJudgingSessions';

const LIMITED_SECTIONS_PROGRAM_TYPES = [
  FLL_DISCOVER_ID,
  FLL_EXPLORE_ID,
  FTC_ID
];

const ScheduleContent = (props) => {
  const {
    eventSchedule,
    eventProgramId,
    eventScheduleId,
    canDeleteEvent,
    eventName,
    time_zone,
    event_type,
    is_FLLC_event
  } = props;
  const {
    isFetchingEventSchedule,
    errorFetchingEventSchedule,
    scoringTimestampsData,
    scoringTimestampsIncluded,
    judgingTimestampsData,
    judgingTimestampsIncluded,
    openingSessionData,
    openingSessionIncluded,
    closingSessionData,
    closingSessionIncluded,
    sessions,
    isGeneratingScheduleSessionsComplete,
    isFetchingScheduleTabScheduleDetails,
    meetings
  } = eventSchedule;

  const {
    judgingSessions,
    scoringSessions,
  } = sessions;
  const { t } = useTranslation()

  const { meetingsList } = meetings;

  //reload schedule info after successfully run auto generate schedule command endpoint.
  useEffect(() => {
    if (eventScheduleId || isGeneratingScheduleSessionsComplete) {
      props.getScheduleDetails(eventScheduleId);
      isGeneratingScheduleSessionsComplete && props.getScoringSessions(eventScheduleId);
      isGeneratingScheduleSessionsComplete && props.getJudgingSessions(eventScheduleId);
    }
  }, [eventScheduleId, isGeneratingScheduleSessionsComplete]);

  if (errorFetchingEventSchedule) {
    return (
      <div className='event-schedule-container'>
        <p>Error.</p>
      </div>
    );
  }

  const scoringTimestamps = scoringTimestampsData.map(s => {
    const timestamp = new ScheduleTimestamp(s, scoringTimestampsIncluded);
    return timestamp;
  })

  const judgingTimestamps = judgingTimestampsData.map(s => {
    const timestamp = new ScheduleTimestamp(s, judgingTimestampsIncluded);
    return timestamp;
  })

  const openingSession = new ScheduleSession(openingSessionData, openingSessionIncluded);
  const closingSession = new ScheduleSession(closingSessionData, closingSessionIncluded)

  const canViewScoringSection = !LIMITED_SECTIONS_PROGRAM_TYPES.includes(eventProgramId);
  const isInPerson = event_type === IN_PERSON_OFFICIAL || event_type === IN_PERSON_TEST;

  const modifiedJudgingSessions = judgingSessions.map(session => {
    session.title = is_FLLC_event ? t('judgeSessionsLabel') : t('nonChallengeJudgeSessionsLabel');
    return session
  })
  // const modifiedScoringSessions = scoringSessions.map(session=>session.title = t('refereeReviewSessionsLabel'));
  const modifiedScoringSessions = scoringSessions.map(session => {
    session.title = t('refereeReviewSessionsLabel')
    return session
  });

  return (
    <>
      <DeleteEventSection canDelete={canDeleteEvent} eventName={eventName} />
      <OpeningClosingCeremonies openingSession={openingSession} closingSession={closingSession} scheduleId={eventScheduleId} time_zone={time_zone} />
      <JudgingSection judgingTimestamps={judgingTimestamps} data={modifiedJudgingSessions} scheduleId={eventScheduleId} />
      {canViewScoringSection && !isInPerson ? (
        <ScoringSection scoringTimestamps={scoringTimestamps} title={t('scoringLabel')} data={modifiedScoringSessions} scheduleId={eventScheduleId} />
      ) : null}
      {isInPerson && canViewScoringSection ? <Matches /> : null}
      <MeetingsSection data={meetingsList} scheduleId={eventScheduleId} />
    </>
  );
};

const mapDispatchToProps = {
  getScheduleDetails: handleScheduleDetails,
  getScoringSessions: fetchScoringSessions,
  getJudgingSessions: fetchJudgingSessions,
};

const mapStateToProps = (state) => {
  const { event: {
    eventSchedule,
    eventDetails: { eventProgramId, eventScheduleId, time_zone, attributes: { event_type }, is_FLLC_event }
  } } = state;
  return {
    eventSchedule,
    eventProgramId,
    eventScheduleId,
    time_zone,
    event_type,
    is_FLLC_event
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleContent);
