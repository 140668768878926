import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/AddTeamModal.scss';
import {CheckOutlined} from '@ant-design/icons';

const style = {
  paddingRight: '2rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '20%'
}

const TeamOption = (props) => {

  const {teamName, country, region, teamNumber, selected} = props;
  return (
    <div className='add-team-modal__select-option'>
      <span style={{
        ...style,
        textAlign: 'right',
      }}>      
        {teamNumber}
      </span>
      <span style={{
        ...style,
        fontWeight: 'bold',
        width: '35%'
      }}>
        {teamName}
      </span>
      <span style={style}>
        {region}
      </span>
      <span style={style}>
        {country}
      </span>
      <span style={{ width: '5%' }}>
        {selected && <CheckOutlined style={{color:"#52C41A"}}/>}
      </span>
    </div>
  );
};


TeamOption.propTypes = {
  teamName: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  teamNumber: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired
}


export default TeamOption;