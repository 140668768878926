import React from 'react';
import PropTypes from 'prop-types';
import { Button, Avatar, Tooltip } from 'antd';

import CardTile from 'shared/components/CardsList/CardTile';
import './PartnerTile.scss'

const PartnerTile = (props) => {
  const { regions, partnerOrganizationNames, phone } = props;
  const toolTipContent = partnerOrganizationNames ? (
    <>
      <p className='paragraph--small paragraph--white partner-tile__name'>{partnerOrganizationNames}</p>
      <p className='paragraph--xsmall paragraph--white'>{regions}</p>
    </>
  ) : undefined
  return (
    <CardTile
      {...props}
      renderInfo={() => (
        <Tooltip title={toolTipContent || undefined}>
          <div className='partner-tile__meta-content'>
            <p className='paragraph--small paragraph--darkGray partner-tile__name partner-tile__meta-text'>{partnerOrganizationNames}</p>
            <p className='paragraph--xsmall paragraph--darkGray partner-tile__meta-text'>{regions}</p>
            <p className='paragraph--xsmall paragraph--darkGray partner-tile__phone'>{phone}</p>
          </div>

        </Tooltip>
      )}
    />
  );
};

PartnerTile.propTypes = {
  email: PropTypes.string,
  fullName: PropTypes.string,
  roles: PropTypes.array
};

PartnerTile.defaultProps = {
  email: '',
  fullName: '',
  roles: []
};

export default PartnerTile;
