import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './styles/JoinBtn.scss';

const DISABLED_TEXT = 'The Join button will become active 22 hours before this session begins.'
const JoinBtn = props => {
  const { t } = useTranslation()
  const {text, onClick, url, size, isDisabled} = props;


  const displayText = text && text.length ? text : t('joinLabel')
  const handleClick = (e) => {
    e.stopPropagation();
    onClick()
  }

  if (size === 'large') {
    if (isDisabled) {
      return  <p className='paragraph--small paragraph--charcoal paragraph--bold'>{DISABLED_TEXT}</p>
    }
    return (
      <a href={url} target='_blank' rel="noopener noreferrer" onClick={handleClick} className='c-join-btn--large'>{displayText}</a>
    );
  }

  if (isDisabled) {
   return  <p className='c-join-btn c-join-btn--disabled'>{displayText}</p>

  }
  return (

    <a href={url} target='_blank' rel="noopener noreferrer" onClick={handleClick} className='c-join-btn'>{displayText}</a>
  
  );
};

JoinBtn.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  url: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisabled: PropTypes.bool
};

JoinBtn.defaultProps = {
  onClick: () => {},
  size: null,
  isDisabled: false
}
export default JoinBtn;