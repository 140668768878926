import { API } from 'utils/api';
import { queryStringify } from 'utils/query';
import Team from 'models/Team';
import {
  formatNewTeamPayload,
  formatTeamResData,
  formatTeamModelRes,
  formatTeamDetailsRes,
  formatTeamInfo
} from './model';

//const { http } = API;
const baseUrl = '/api/teams';

const emptyResponse = {
  data: [],
  included: [],
  count: 0
};

const config = {headers: {'api-version': '2.0'}};

export const getTeamsBy = async (filter, page, sort = "") => {
  const sortQuery = sort ? `&sort=${encodeURIComponent(sort)}` : '';
  const queryStr = queryStringify({ filter, page });
  const url = `${baseUrl}${queryStr}${sortQuery}&include=region&include=country&include=coaches&include=events`;
  const { data, included, meta } = await API.GetInstance().http.get(url, config);
  return {
    data,
    included: included || [],
    count: meta && meta.count
  };
};

export const getTeamsByWithoutInclude = async (filter, page, sort = "") => {
  const sortQuery = sort ? `&sort=${encodeURIComponent(sort)}` : '';
  const queryStr = queryStringify({ filter, page });
  const url = `${baseUrl}${queryStr}${sortQuery}`;
  const { data, included, meta } = await API.GetInstance().http.get(url, config);
  return {
    data,
    included: included || [],
    count: meta && meta.count
  };
};

export const getTeamsByEventId = async (eventId, page, searchFilter) => {
  if (!eventId) {
    return emptyResponse;
  }
  const teamsFilter = {
    'events.id': `eq:${eventId}`,
    ...searchFilter
  };
  return await getTeamsBy(teamsFilter, page);
};

const getTeamsByEventIdWithoutInclude = async (eventId, page, searchFilter) => {
  if (!eventId) {
    return emptyResponse;
  }
  const teamsFilter = {
    'events.id': `eq:${eventId}`,
    ...searchFilter
  };
  return await getTeamsByWithoutInclude(teamsFilter, page);
};

export const getAllTeamsByEventId = async (eventId) => {
  const pageSize = 50;
  const page = { size: pageSize, number: 1 };
  let res = await getTeamsByEventId(eventId, page);
  const { count } = res;
  if (count > pageSize) {
    res = await getTeamsByEventId(eventId, { size: count, number: 1 });
  }
  return res.data.map(formatTeamResData);
};

export const getAllTeamsByEventIdWithoutInclude = async (eventId) => {
  const pageSize = 50;
  const page = { size: pageSize, number: 1 };
  let res = await getTeamsByEventIdWithoutInclude(eventId, page);
  const { count } = res;
  if (count > pageSize) {
    res = await getTeamsByEventIdWithoutInclude(eventId, { size: count, number: 1 });
  }
  return res.data.map(formatTeamResData);
};

export const getTeamByName = async (teamName) => {
  const filter = { name: `eq:${teamName}` };
  const { data } = await getTeamsBy(filter);
  return data;
};

export const getTeamsLikeName = async (teamName, size) => {
  const filter = { name: `like:${teamName}` };
  const page = size && { size, number: 1 };
  const { data, included } = await getTeamsBy(filter, page);
  return data.map(d => formatTeamModelRes(new Team(d, included)));
};

export const getTeamsByIds = async (teamsIds) => {
  if (!teamsIds) {
    return emptyResponse;
  }
  const filter = { id: `in:${teamsIds}` };
  return await getTeamsBy(filter);
};

export const getAllTeamsByIds = async (teamsIds) => {
  if (!teamsIds || !teamsIds.length) {
    return emptyResponse;
  }
  const filter = { id: `in:${teamsIds.join(',')}` };
  const page = teamsIds.length > 25 && { size: teamsIds.length, number: 1 };
  const { data } = await getTeamsBy(filter, page);
  return Array.isArray(data) ? data.map(formatTeamResData) : [];
};

export const getTeamById = async (teamId) => {
  if (!teamId) {
    return {}
  }
  const { data, included } = await API.GetInstance().http.get(`${baseUrl}/${teamId}`);
  return formatTeamModelRes(new Team(data, included));
};

export const verifyTeamExist = async (team) => {
  try {
    const filter = {};
    if (team.name) {
      filter.name = `eq:${team.name}`
    }
    if (team.number) {
      filter.team_number = `eq:${team.number}`
    }

    const { data } = await getTeamsBy(filter);
    if (data.length) {
      const teamId = data[0].id;
      return teamId;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const createNewTeam = async (team) => {
  const body = formatNewTeamPayload(team)
  const { data, included } = await API.GetInstance().http.post(baseUrl, body);
  return formatTeamModelRes(new Team(data, included));
};

export const createNewTeamWithoutFormat = async (team) => {
  const body = formatNewTeamPayload(team)
  const { data, included } = await API.GetInstance().http.post(baseUrl, body);
  return new Team(data, included);
};

export const updateExistingTeam = async (team) => {
  const body = formatTeamInfo(team)
  const { teamId } = team;
  const { data, included } = await API.GetInstance().http.patch(`${baseUrl}/${teamId}`, body);
  return formatTeamModelRes(new Team(data, included));
}

export const getTeamDetails = async (teamId) => {
  const { data, included } = await API.GetInstance().http.get(`${baseUrl}/${teamId}`);
  return {
    data,
    included: included || []
  };
};

export const getAllTeamsDetails = async (teamIds) => {
  if (!teamIds || !teamIds.length) {
    return emptyResponse;
  }
  const filter = { id: `in:${teamIds.join(',')}` };
  const page = teamIds.length > 25 && { size: teamIds.length, number: 1 };
  const { data, included } = await getTeamsBy(filter, page);
  return Array.isArray(data) ? data.map(d => formatTeamDetailsRes(d, included)) : [];
};

export const deleteTeam = async (teamId) => {
  return await API.GetInstance().http.delete(`${baseUrl}/${teamId}`);
};
