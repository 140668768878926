import * as meetingService from 'utils/services/meetings';
import { notification } from 'antd';
import * as types from '../../types/eventScheduleTypes';

const regenerateUrlBegin = () => {
  return {
    type: types.REGENERATE_MICROSOFT_MEETING_LINK_BEGIN
  }
}

const regenerateUrlSuccess = () => {
  return {
    type: types.REGENERATE_MICROSOFT_MEETING_LINK_SUCCESS
  }
}

const regenerateUrlError = () => {
  return {
    type: types.REGENERATE_MICROSOFT_MEETING_LINK_ERROR
  }
}


const handleRegenerateSessionUrl = (meetingId, cb = () => {}) => {
  return async dispatch => {
    await dispatch(regenerateUrlBegin())
    try {
      await meetingService.regenerateMeetingUrl(meetingId);
      await dispatch(regenerateUrlSuccess());
      cb();
      notification.success({
        message: 'Success',
        description: 'New Microsoft Teams Link Generated',
      });
    } catch(err) {
      notification.error({
        message: 'Error',
        description: 'Error Generating the Microsoft Teams Link',
      });
      await dispatch(regenerateUrlError())
      throw err;
    }
  }

}

export default handleRegenerateSessionUrl;