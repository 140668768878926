import{ API } from 'utils/api';
import ScheduleTimestamp from 'models/ScheduleTimestamp'
import {formatTimestampRes} from './model';

//const { http } = API;


const scheduleBaseUrl = '/api/schedules';


export const getJudgingTimestamps = async (scheduleId) => {
  if (!scheduleId) {
    return []
  }
  const url = `${scheduleBaseUrl}/${scheduleId}/judging_time_stamps`;
  const response = await API.GetInstance().http.get(url);
  const {data, included} = response;
  const includedArr = included || [];
  const judgingTimestamps = data.map(d => formatTimestampRes(new ScheduleTimestamp(d, includedArr)))
  return judgingTimestamps
}

export const getScoringTimestamps = async (scheduleId) => {
  const url = `${scheduleBaseUrl}/${scheduleId}/scoring_time_stamps`;
  const response = await API.GetInstance().http.get(url);
  const {data, included} = response;
  return {
    data,
    included: included || []
  }
}
