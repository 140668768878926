import React from 'react';
import PropTypes from 'prop-types';
import './TeamTile.scss';
import Team from 'models/Team';
import {Tooltip} from 'antd';
import truncateString from 'utils/truncateString';

const TeamTile = (props) => {
  const { team, included, onClick, isButton, showCoaches } = props;

  const teamData = new Team(team, included);

  const country = teamData.getCountryName();
  const region = teamData.getRegionName();

  const coachesList = teamData.coaches;

  const prompt = (
    <div>
      <p>{teamData.teamNumber}</p>
      <p>{teamData.teamName}</p>
    </div>
  );

  const truncatedName = truncateString(teamData.teamName, 28);
  const showHelper = teamData.teamName.length > 28;

  const teamTileContent = (
    <div className='team-tile-inner'>
      <Tooltip title={showHelper && prompt}>
        {teamData.teamNumber ? (
          <h3 className='h-3 h--bold heading--charcoal'>
            {teamData.teamNumber}
          </h3>

        ) : null}
        <p className='paragraph--default paragraph--charcoal paragraph--bold'>
          {truncatedName}
        </p>
      </Tooltip>
      <div className='team-tile__location'>
        <p className='paragraph--small paragraph--charcoal paragraph--400 team-tile__location-text'>
          {region}
        </p>
        <p className='paragraph--small paragraph--charcoal paragraph--400 team-tile__location-text'>
          {country}
        </p>
      </div>

      {showCoaches ? (
        <div className='team-tile__avatar-container'>
          <ul>
            {coachesList.map((a, i) => (
              <li key={`${i + 1}`}>
                <img src='/avatars/discoDude.png' alt='disco lego man' className='team-tile__avatar' />
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  )

  if (isButton) {
    return (
      <button type='button' onClick={() => onClick(team.id)} className='team-tile-btn'>
        {teamTileContent}
      </button>
    )
  }
  return (
    <div className='team-tile'>
      {teamTileContent}
    </div>
  );
};

TeamTile.propTypes = {
  team: PropTypes.object.isRequired,
  included: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  isButton: PropTypes.bool,
  showCoaches: PropTypes.bool,
};

TeamTile.defaultProps = {
  onClick: () => { },
  isButton: false,
  showCoaches: true
};

export default TeamTile;
