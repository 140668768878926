const m07Section = {
  id: 'm07',
  name: 'RobotDanceLabel',
  fields: [
    {
      scoring: 'm07sub1Label',
      id: 'm07_scoring_1',
      options: [
        {
          description: 'noLabel',
          value: false
        },
        {
          description: 'yesLabel',
          value: true
        }
      ]
    },
  ]
}

export default m07Section;