
export default class UserEventRoleData {
  constructor(responseData, responseIncluded = []) {
    this.eventRoleAssignmentId = responseData.id;
    this.userId = responseData.relationships.user.data.id;
    this.eventId = ((((responseData||{}).relationships||{}).event||{}).data||{}).id;
    this.status = responseData.attributes.status;
    this.verificationCodeTimeout = responseData.attributes.verification_code_timeout;
    this.teams = ((((responseData||{}).relationships||{}).teams||{}).data||[]);
    this.roles = responseData.roles;
    this.included = responseIncluded;
  }

  getUserData = () => {
    const userInfo = this.included.find(d => d.id === this.userId);
    return userInfo;
  }

  formatUserData = () => {
    const userInfo = this.getUserData();
    if (!userInfo) return {};
    const userObj = {};
    userObj.id = userInfo.id;
    userObj.email = userInfo.attributes.email;
    userObj.firstName = userInfo.attributes.first_name;
    userObj.lastName = userInfo.attributes.last_name;
    userObj.fullName = `${userInfo.attributes.first_name} ${userInfo.attributes.last_name}`;
    userObj.userIsActive = userInfo.attributes.is_active;
    userObj.userVerificationCodeTimeout = userInfo.attributes.verification_code_timeout;
    userObj.userStatus = userInfo.attributes.status;
    userObj.mobilePhone = userInfo.attributes.mobile_phone
    return userObj;
  }

  getEventRoleInvitationInfo = () => {

  }


  getUserEventRolesList = () => {
    if (!this.roles || !Array.isArray(this.roles)) {
      return [];
    }
    const userEventRoleIds = this.roles.map(role => {
      let id = role.data ? role.data.id : role.id;
      return {
        id: id,
        status: role.status,
        verificationCodeTimeout: role.verification_code_timeout
      }
    });

    const userRoles = userEventRoleIds.map(role => {
      const selectedRole = this.included.find(i => i.id === role.id);
      const roleInfo = {};
      roleInfo.name = selectedRole.attributes.name;
      roleInfo.id = role.id;
      roleInfo.invitationStatus = role.status;
      roleInfo.verificationCodeTimeout = role.verificationCodeTimeout;
      return roleInfo;
    })

    return userRoles;

  }


  


}