export const GET_EVENT_DETAILS_BEGIN = 'GET_EVENT_DETAILS_BEGIN';
export const GET_EVENT_DETAILS_ERROR = 'GET_EVENT_DETAILS_ERROR';
export const GET_EVENT_DETAILS_SUCCESS = 'GET_EVENT_DETAILS_SUCCESS';


export const SET_EVENT_SCHEDULE_ID = 'SET_EVENT_SCHEDULE_ID';

export const SET_EVENT_PROGRAM_ID = 'SET_EVENT_PROGRAM_ID';

export const SET_EVENT_REGION_ID = 'SET_EVENT_REGION_ID';

export const GET_USER_EVENT_ASSIGNMENTS_BEGIN = 'GET_USER_EVENT_ASSIGNMENTS_BEGIN'
export const GET_USER_EVENT_ASSIGNMENTS_ERROR = 'GET_USER_EVENT_ASSIGNMENTS_ERROR'
export const GET_USER_EVENT_ASSIGNMENTS_SUCCESS = 'GET_USER_EVENT_ASSIGNMENTS_SUCCESS';

export const SET_ADMIN_EVENT_SCHEDULE_ERROR = 'SET_ADMIN_EVENT_SCHEDULE_ERROR';
export const SET_ADMIN_EVENT_SCHEDULE_SUCCESS = 'SET_ADMIN_EVENT_SCHEDULE_SUCCESS';

export const SET_ADMIN_EVENT_TIME_ZONE = 'SET_ADMIN_EVENT_TIME_ZONE';

export const START_CHANGING_SCHEDULE_TIME_ZONE = 'START_CHANGING_SCHEDULE_TIME_ZONE';
export const FINISH_CHANGING_SCHEDULE_TIME_ZONE = 'FINISH_CHANGING_SCHEDULE_TIME_ZONE';