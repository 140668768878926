import { API } from 'utils/api';
import { queryStringify, formatDataResult } from 'utils/query';
import {
  formatRolesPayload,
  formatNewChannelPayload,
  formatEditChannelPayload,
  formatChannelResponse
} from './model';

//const { http } = API;
const baseUrl = '/api/messaging_channels';

export const getEventMessageChannels = async (scheduleId) => {
  const url = `api/schedules/${scheduleId}/messaging_channels`;
  const { data, meta: { count } } = await API.GetInstance().http.get(url);
  return {
    channels: data.map(formatChannelResponse),
    count
  };
};

export const updateMessageChannel = async (channel) => {
  const { id, roles, newRoles, delRoles, ...attrs } = channel;
  const payload = formatEditChannelPayload(id, attrs);
  const { data } = await API.GetInstance().http.patch(`${baseUrl}/${id}`, payload);
  const result = formatDataResult(data);
  if (delRoles && delRoles.length) {
    const rolesPayload = formatRolesPayload(delRoles);
    await API.GetInstance().http.delete(`${baseUrl}/${id}/relationships/roles`, { data: rolesPayload });
  }
  if (newRoles && newRoles.length) {
    const rolesPayload = formatRolesPayload(newRoles);
    await API.GetInstance().http.post(`${baseUrl}/${id}/relationships/roles`, rolesPayload);
  }
  return { ...result, roles };
};

export const createMessageChannel = async (channel) => {
  const { roles } = channel;
  const payload = formatNewChannelPayload(channel);
  const { data } = await API.GetInstance().http.post(baseUrl, payload);
  const result = formatDataResult(data);
  const { id } = result;
  if (roles.length) {
    const rolesPayload = formatRolesPayload(roles);
    await API.GetInstance().http.post(`${baseUrl}/${id}/relationships/roles`, rolesPayload);
  }
  return { ...result, roles };
};

export const deleteMessageChannel = async (channelId) => {
  const { data } = await API.GetInstance().http.delete(`${baseUrl}/${channelId}`);
  return data;
};
