import React from 'react';
import Icon from '@ant-design/icons';
import './FileSuccess.scss';
import PropTypes from 'prop-types';
import Success from 'shared/components/Icon/icons/Success';


const FileChosen = ({text}) => {
  return (
    <div className='file-success'>
      <Icon component={Success} className='file-success__icon' />
      <p className='file-success__text'>{text}</p>
    </div>
  );
};

FileChosen.propTypes = {
  text: PropTypes.string
};

FileChosen.defaultProps = {
  text: 'imported and invited'
}

export default FileChosen;