import React from 'react';
import PropTypes from 'prop-types';
import { Select as AntSelect } from 'antd';
import DownOutlined from '@ant-design/icons'
import './select.scss';



const Select = props => {
  const { additionalClassNames, children, size, theme, iconColor = '#231F20', placeholder, ..._props } = props;
  const className = `select select--${size} select--${theme} ${additionalClassNames}`;
  return (
    <AntSelect
      className={className}
      size={size}
      optionFilterProp='children'
      placeholder={placeholder}
      showSearch
      suffixIcon={<DownOutlined />}
      {..._props}
    >
      {children}
    </AntSelect>
  );
};

Select.Option = AntSelect.Option;

Select.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  _props: PropTypes.object,
  size: PropTypes.string,
  theme: PropTypes.string,
  placeholder: PropTypes.string,
  additionalClassNames: PropTypes.string
};

Select.defaultProps = {
  _props: {},
  size: 'large',
  theme: 'default',
  placeholder: undefined,
  additionalClassNames: ''
};

export default Select;
