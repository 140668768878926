const sortSessions = (a, b) => {

  const sessionA = a.teamDetails;
  const sessionB = b.teamDetails;
  let comparison = 0;
  if ((sessionA === sessionB) && b.sessionType && a.sessionType) {
    return b.sessionType.localeCompare(a.sessionType)
  }
  if (sessionA > sessionB) {
    comparison = 1;
  } else if (sessionA < sessionB) {
    comparison = -1;
  }
  return comparison
}

export default sortSessions;