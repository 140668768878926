import React from 'react';
import { Pagination } from 'antd';
import Icon from '@ant-design/icons';
import './pagination.scss';
import { useTranslation } from 'react-i18next';

const DEFAULT_PAGE_SIZE = 12;

export const initialPage = 1;

export const getPageFilter = (number, size) => ({
  number, size: size || DEFAULT_PAGE_SIZE
});

export default (props) => {
  const { total, loading, current, onChange, pageSize } = props;
  if (!total || loading) {
    return null;
  }

  const {i18n} = useTranslation();
  const rtl_direction = i18n.dir() === 'rtl';
  
  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <Icon type={rtl_direction? "right": "left"}/>
    }
    if (type === 'next') {
      return <Icon type={rtl_direction? "left": "right"}/>
    }
    return originalElement;
  }

  return (
    <div className='c-pagination'>
      <Pagination
        itemRender={itemRender}
        showSizeChanger={false}
        onChange={onChange}
        total={total}
        pageSize={pageSize || DEFAULT_PAGE_SIZE}
        current={current}
      />
    </div>
  )
};
