import * as types from '../types/publicScoring';

const DEFAULT_STATE = {
  //m00_scoring_1: false,
  //m01_scoring_1: false,
  //m02_scoring_1: 0,
  //m02_scoring_2: false,
  //m03_scoring_1: false,
  //m04_scoring_1: false,
  //m04_scoring_2: false,
  //m05_scoring_1: false,
  //m06_scoring_1: false,
  //m06_scoring_2: false,
  //m07_scoring_1: false,
  //m08_scoring_1: 0,
  //m09_scoring_1: false,
  //m09_scoring_2: false,
  //m10_scoring_1: 0,
  //m11_scoring_1: 0,
  //m12_scoring_1: false,
  //m12_scoring_2: false,
  //m13_scoring_1: false,
  //m13_scoring_2: false,
  //m14_scoring_1: 0,
  //m14_scoring_2: 0,
  //m15_scoring_1: 0,
  //m16_scoring_1: 0,
  //precision_tokens: 0,
  //gracious_professionalism: 3,
    m00_scoring_1: null,
    m01_scoring_1: null,
    m02_scoring_1: null,
    m02_scoring_2: null,
    m03_scoring_1: null,
    m04_scoring_1: null,
    m04_scoring_2: null,
    m05_scoring_1: null,
    m06_scoring_1: null,
    m06_scoring_2: null,
    m07_scoring_1: null,
    m08_scoring_1: null,
    m09_scoring_1: null,
    m09_scoring_2: null,
    m10_scoring_1: null,
    m11_scoring_1: null,
    m12_scoring_1: null,
    m12_scoring_2: null,
    m13_scoring_1: null,
    m13_scoring_2: null,
    m14_scoring_1: null,
    m14_scoring_2: null,
    m15_scoring_1: null,
    m16_scoring_1: null,
    precision_tokens: null,
    gracious_professionalism: 3,
  totalScore: 0,
  isScoreCalculating: false,
  isScoreEdited: false,
  isScoreReseted: false
}

export default (state = DEFAULT_STATE, action) => {
  const { type } = action;
  switch (type) {
    case types.RESET_PUBLIC_SCORING:
      return { ...DEFAULT_STATE, isScoreReseted: true }
    case types.CLEAR_PUBLIC_SCORING_RESET:
      return { ...state, isScoreReseted: false }
    case types.SET_PUBLIC_SCORING:
      return {
        ...state,
        ...action.payload
      }
    case types.SET_IS_PUBLIC_SCORESHEET_EDITED:
      return {
        ...state,
        isScoreEdited: action.payload.isScoresheetEdited
      }
    default:
      return state;
  }
}