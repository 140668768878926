import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InformationContainer from 'features/RegistrationContainer/components/InformationContainer';
import { acceptTermsAndConditions } from 'features/RegistrationContainer/redux/registrationActions';
import OtherConditions from './OtherConditions';
import TermsSections from './TermsSections';

const TermsAndConditions = (props) => {
  const { userId, hasAcceptedTerms, history } = props;
  const [acceptStep1, setAcceptStep] = useState(false);
  const { t } = useTranslation();

  
  if (acceptStep1) {
    return (
      <OtherConditions 
        handleSubmit={() => props.acceptTermsAndConditions(userId, history)} 
      />
    );
  }

  const handleAcceptContinue = userId && !hasAcceptedTerms
    ? () => setAcceptStep(true) 
    : null;

  return (
    <InformationContainer
      title={t('termsAndConditionsLabel')}
      btnText='Accept and Continue'
      handleSubmit={handleAcceptContinue}
    >
      <TermsSections />
    </InformationContainer>
  );
};

const mapStateToProps = ({ auth: { userId, userInfo } }) => {
  return {
    userId,
    hasAcceptedTerms: userInfo.hasAcceptedTerms
  };
};

const mapDispatchToProps = {
  acceptTermsAndConditions
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
